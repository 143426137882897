import { Box, styled } from '@material-ui/core'

export const HeaderCell = styled(Box)`
  position: sticky;
  top: 0;
  background: white;
`

export const AddLineItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .MuiFormControl-root {
    margin: 0;

    input {
      padding: 8px 16px;
    }

    .MuiSelect-select {
      padding: 8px 16px;
      padding-right: 32px;
    }
  }
`
