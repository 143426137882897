import { Box, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Dispatch, SetStateAction } from 'react'
import * as React from 'react'

import { MessageAttachment } from '../../../../types/dtos/messages'
import {
  convertFileSizeToReadableSize,
  getDocumentIcon,
} from '../../../../utils/helpers/files'
import { Image } from '../../../ui/Image'

import { StyledRowBox } from './AttachmentRow.styles'

interface RichInputAttachmentProps {
  attachment: MessageAttachment | File
  setAttachment?: Dispatch<SetStateAction<MessageAttachment | File | null>>
  isNotSend?: boolean
  onFileDownload?: (fileId: string) => void
}

export const AttachmentRow: React.FC<RichInputAttachmentProps> = ({
  attachment,
  setAttachment,
  isNotSend = false,
  onFileDownload,
}) => {
  const handleDownload = () => {
    if (
      onFileDownload &&
      attachment.type === 'file' &&
      'id' in attachment &&
      attachment.id
    )
      onFileDownload(attachment.id)
    else
      window.open(
        ('url' in attachment && attachment.url) ||
          URL.createObjectURL(attachment),
        '_blank'
      )
  }

  return (
    <StyledRowBox
      isNotSend={isNotSend}
      rowPosition={('position' in attachment && attachment.position) || ''}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          onClick={handleDownload}
        >
          {attachment.type.includes('image') ? (
            <Image
              image={
                ('image' in attachment && attachment.image) ||
                ('url' in attachment && attachment.url) ||
                URL.createObjectURL(attachment)
              }
              imageSize={
                'image' in attachment && attachment.image ? 'small' : 'original'
              }
              style={{ width: 48, height: 48 }}
            />
          ) : (
            <Box>{getDocumentIcon(attachment.name.slice(-3))}</Box>
          )}
          <Box style={{ marginLeft: '17px' }}>
            <Typography variant="body2" fontWeight={500}>
              {attachment.name}
              {isNotSend && attachment.size && (
                <Typography
                  component="span"
                  color={(theme) => theme.palette.grey[200]}
                >
                  <Typography
                    component="span"
                    style={{ marginLeft: '15px', marginRight: '10px' }}
                  >
                    &#9679;
                  </Typography>
                  {convertFileSizeToReadableSize(attachment.size)}
                </Typography>
              )}
            </Typography>
            {isNotSend && (
              <Typography
                style={{ marginTop: '4px' }}
                variant="body2"
                color={(theme) => theme.palette.grey[200]}
              >
                Attached
              </Typography>
            )}
          </Box>
        </Box>
        {isNotSend && setAttachment && (
          <Close
            sx={{
              color: (theme) => theme.palette.grey[200],
              '&:hover': { cursor: 'pointer', opacity: 0.8 },
            }}
            onClick={() => setAttachment(null)}
          />
        )}
      </Box>
      {!isNotSend && !!attachment.text && typeof attachment.text === 'string' && (
        <Typography
          fontSize="16px"
          style={{
            marginTop: '12px',
            paddingTop: '3px',
            borderTop: '1px solid rgba(132, 132, 132, 0.12)',
          }}
          dangerouslySetInnerHTML={{ __html: attachment.text }}
        ></Typography>
      )}
    </StyledRowBox>
  )
}
