import { getPartnerParams, isPartner } from 'constants/partners'

import {
  EventInquiriesCreateRequest,
  EventUpdateRequest,
} from '../../types/dtos/events'
import {
  createEventInquiriesForGuestApiPath,
  createEventInquiriesForUserApiPath,
  updateEventInquiriesApiPath,
} from '../paths'

import { post, put } from './api'

export async function createEventInquiries(
  data: EventInquiriesCreateRequest,
  isAuth: boolean = false
) {
  const apiPath = isAuth
    ? createEventInquiriesForUserApiPath
    : createEventInquiriesForGuestApiPath()
  return post(apiPath, data)
}

export async function updateEventInquiries(data: EventUpdateRequest) {
  return put(updateEventInquiriesApiPath(data.id), {
    ...data,
    ...(isPartner() ? getPartnerParams() : {}),
  })
}
