import { Box, IconButton, Modal } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import * as React from 'react'

import { ModalContentWrapper } from './BottomModal.styles'

export const BottomModal: React.FC<{ open: boolean; onClose: () => void }> = ({
  children,
  onClose,
  open,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.7)' } }}
    >
      <Box>
        <IconButton
          color="secondary"
          style={{ position: 'absolute', right: 0, top: 5 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <ModalContentWrapper>{children}</ModalContentWrapper>
      </Box>
    </Modal>
  )
}
