import { FormHelperText, Typography } from '@material-ui/core'
import { Field } from 'formik'
import * as React from 'react'
import MaskedInput from 'react-text-mask'

import { phoneNumberMask } from '../../../../constants'
import { TextField as FormikTextField } from '../../../formik'

import { PhoneInputWrapper } from './PhoneInput.styles'

interface PhoneInputProps {
  withLabel?: boolean
  mx?: number
  className?: string
  name?: string
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  withLabel = false,
  mx = 0,
  className,
  name = 'phoneNumber',
}) => {
  return (
    <PhoneInputWrapper mx={mx}>
      {withLabel && (
        <FormHelperText id="phoneNumber">
          <Typography variant="overline">Phone Number</Typography>
        </FormHelperText>
      )}
      <Field
        label="Phone Number"
        className={className}
        name={name}
        component={FormikTextField}
        autoComplete="phone"
      >
        {({ field }: any) => (
          <MaskedInput mask={phoneNumberMask} {...field} id="phoneNumber" />
        )}
      </Field>
    </PhoneInputWrapper>
  )
}
