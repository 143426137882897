import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Invoice } from '../types/dtos/inquiry'
import { generateDocumentForInvoice } from '../utils/api/inquiries'
import { getRequestError } from '../utils/helpers/validations'
import { useMessageModalData } from '../utils/providers/MessageModalProvider'

export const useDownloadInvoice = () => {
  const { t } = useTranslation()

  const { showMessage, closeMessageDialog } = useMessageModalData()

  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadInvoice = async (invoice: Invoice) => {
    setIsLoading(true)
    try {
      if (invoice.document_url) {
        window.open(invoice.document_url, '_blank')
      } else {
        const response = await generateDocumentForInvoice(invoice.id.toString())
        const url = response.document_url

        showMessage({
          title: t(
            'messages.documents.invoice',
            'URL for invoice document has been successfully generated'
          ),
          buttons: [
            {
              label: t('messages.documents.action', 'Open in new tab'),
              action: () => {
                window.open(url, '_blank')
                closeMessageDialog()
              },
            },
          ],
        })
      }
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, handleDownloadInvoice }
}
