import * as React from 'react'
import { generatePath, Redirect, useRouteMatch } from 'react-router-dom'

import { Loader } from '../../../components/ui/Loader'
import { useVenue } from '../../../utils/api/venues'
import { venuesMapper } from '../../../utils/mappers/venues'
import { venuePath } from '../../../utils/paths'
import { NotAvailablePage } from '../../NotAvailablePage'

import { Venue } from './Venue'

export const VenueContainer: React.FC = () => {
  const {
    params: { slug },
  } = useRouteMatch<{ slug: string }>()

  const { isLoading, data } = useVenue(slug, { with_exclusive: true })

  if (isLoading) return <Loader />

  if (data) {
    if (data.slug !== slug) {
      return (
        <Redirect
          to={generatePath(venuePath, {
            slug: data.slug,
          })}
        />
      )
    }

    return <Venue venueData={venuesMapper(data)} />
  }

  return <NotAvailablePage />
}
