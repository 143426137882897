import {
  Box,
  Button,
  Paper,
  styled,
  TextField,
  Typography,
} from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

import { poppinsFontFamily } from '../../../theme/createTypography'

export const BlockContainer = styled(Box)`
  padding: 25px;
  box-shadow: 10px 4px 18px rgba(210, 210, 210, 0.4);
  position: relative;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  ${MEDIA_RULES.SMALL} {
    padding: 0;
    box-shadow: none;
    margin-bottom: 0;
  }
`

export const BlockTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  font-family: ${poppinsFontFamily};
`

export const ThinTextField = styled(TextField)`
  input {
    font-size: 14px;
    padding: 8px 16px;
  }
`

export const StyledAddLabelsButton = styled(Button)(
  // @ts-ignore
  ({ isOpen }) => `
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  color:  ${isOpen ? 'white' : 'black'};
  text-transform: none;
  background-color: ${isOpen ? 'black' : '#f5f5f8'};
  width: 100%;
  padding: 12px 20px 12px 10px;
  border-radius: 2px;
  align-content: baseline;
  margin-right: 15px;
    &:hover {
    background-color: #f5f5f8;
  }
`
)

export const StyledLabelsPaper = styled(Paper)`
  margin-top: 10px;
  background: white;
  min-width: 280px;
  padding: 25px;
`

export const RoomItemWrapper = styled(Box)`
  //position: relative;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
  border-radius: 2px;
  margin-top: 10px;
`
export const ScrollContainer = styled(Box)`
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`

export const FormContainer = styled('form')`
  display: flex;
  width: 100%;
  height: 100%;
`

export const FieldLabel = styled(Typography)`
  text-align: left;
  font-weight: 600;
  margin-bottom: 10px;
`

export const SectionHeader = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`

export const FormTitleContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
`

export const FormTitle = styled(Box)`
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 15px;
`

export const CancelButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #9396a3;
  width: 50%;
  margin-right: 15px;
`
