import { SVGProps } from 'react'

const DollarCircle = ({
  fill = '#34C759',
  width = '20',
  height = '20',
  scale = 1,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 20"
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1.25a8.75 8.75 0 1 0 .001 17.501A8.75 8.75 0 0 0 10 1.25Zm0 16.016a7.267 7.267 0 0 1 0-14.532 7.267 7.267 0 0 1 0 14.532Zm.932-7.72-.497-.114V6.809c.743.101 1.202.566 1.28 1.136.01.078.076.135.154.135h.877c.092 0 .164-.08.156-.172-.119-1.217-1.12-1.998-2.459-2.133v-.638a.157.157 0 0 0-.156-.157h-.549a.157.157 0 0 0-.156.157v.644c-1.383.135-2.465.899-2.465 2.324 0 1.32.973 1.957 1.994 2.202l.483.123v2.787c-.864-.115-1.348-.576-1.448-1.197a.156.156 0 0 0-.154-.13H7.09a.157.157 0 0 0-.156.17c.087 1.075.902 2.063 2.64 2.19v.613c0 .086.07.157.156.157h.555c.086 0 .156-.07.156-.159l-.004-.619c1.53-.135 2.623-.953 2.623-2.422-.001-1.355-.863-1.96-2.128-2.273Zm-1.34-.316a2.857 2.857 0 0 1-.293-.097c-.66-.238-.967-.623-.967-1.12 0-.708.537-1.113 1.26-1.204V9.23Zm.843 3.993V10.61c.061.018.116.032.172.043.924.281 1.235.672 1.235 1.272 0 .764-.574 1.222-1.407 1.297Z"
        transform={`scale(${scale})`}
      />
    </svg>
  )
}

export default DollarCircle
