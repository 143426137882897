import { Box, Link as MuiLink } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { HighlightedSearchQuery } from '../../../../../components/HighlightedSearchQuery'
import ArrowDown from '../../../../../components/svg/ArrowDown'
import PromotionStar from '../../../../../components/svg/PromotionStar'
import { usePartner } from '../../../../../hooks/usePartner'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { Vendor, VendorType } from '../../../../../types/Vendor'
import { vendorPath, vendorsCategoryPath } from '../../../../../utils/paths'

import { ListItemWrapper } from './VendorTypes.styles'

export const VendorTypeListItem: React.FC<{
  vendors?: Vendor[]
  query: string
  item: VendorType
  color: string
  onAddHandle: (category: VendorType) => void
}> = ({ vendors = [], query, item, color, onAddHandle }) => {
  const { isMobile } = useWindowSize()
  const { isAbs } = usePartner()
  const { t } = useTranslation()

  return (
    <ListItemWrapper>
      <MuiLink
        component={Link}
        to={() => generatePath(vendorsCategoryPath, { category: item.id })}
        onClick={(e) => {
          e.preventDefault()
          onAddHandle(item)
        }}
        color={color}
        fontSize={isMobile ? '20px' : '32px'}
        fontFamily="Acumin Pro"
        fontWeight="700"
        underline="none"
        width={'100%'}
      >
        {/*@ts-ignore*/}
        <HighlightedSearchQuery
          whiteSpace="normal"
          initialString={item.name}
          searchQuery={query}
          minQueryLength={3}
        />
      </MuiLink>
      {vendors && vendors.length
        ? vendors.map((vendor: Vendor) => (
            <MuiLink
              component={Link}
              key={vendor.slug}
              to={() => generatePath(vendorPath, { slug: vendor.slug })}
              color={color}
              ml={6}
              mt={2}
              fontSize="18px"
              fontFamily="Poppins"
              fontWeight="500"
              underline="none"
              width={'100%'}
            >
              <Box display="flex" alignItems="center">
                {(isAbs ? vendor.hasAdvantages : vendor.hasPromotion) && (
                  <Box mr="12px" display="flex" alignItems="center">
                    <PromotionStar />
                  </Box>
                )}
                <HighlightedSearchQuery
                  whiteSpace="normal"
                  initialString={vendor.name}
                  searchQuery={query}
                  minQueryLength={3}
                />
              </Box>
            </MuiLink>
          ))
        : null}
      {item.vendors_count > 10 ? (
        <MuiLink
          component={Link}
          to={() => generatePath(vendorsCategoryPath, { category: item.id })}
          onClick={(e) => {
            e.preventDefault()
            onAddHandle(item)
          }}
          fontSize={'18px'}
          fontFamily="Poppins"
          fontWeight="600"
          color="#2F54EB"
          underline="none"
          mr={2}
        >
          <Box display="flex" ml={6} mt={2} alignContent="center">
            {t('general.showMore', 'Show more')}
            <Box ml={2} mt="2px">
              <ArrowDown fill="#2F54EB" />
            </Box>
          </Box>
        </MuiLink>
      ) : null}
    </ListItemWrapper>
  )
}
