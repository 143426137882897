import { createPartnerMagicLinkHash } from '../api/business'
import { dashboardPath } from '../paths'

import { isLocalEnv } from './envr'

export const goToBlaceDashboard = async (
  partnerPath: string | null,
  pathname?: string
) => {
  try {
    const linkWithHash = await createPartnerMagicLinkHash()
    const domain = process?.env?.REACT_APP_DOMAIN

    if (partnerPath) {
      if (!isLocalEnv()) {
        window.location.replace(
          `https://${domain}${pathname || dashboardPath}?hash=${linkWithHash}`
        )
      } else {
        window.location.pathname = window.location.pathname.replace(
          `/${partnerPath}`,
          pathname || ''
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
}
