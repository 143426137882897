import { Box } from '@material-ui/core'
import * as React from 'react'

import GradientButton from '../../../../../components/ui/Buttons/GradientButton'
import { SimpleButton } from '../../../../../components/ui/Buttons/SimpleButton'
import { InquiryStatuses } from '../../../../../constants/inquiry'
import { BusinessInquiryActions } from '../../../../../types/dtos/inquiry'
import { t } from '../../../../../utils/i18n'

interface ActionButtonProps {
  status?: InquiryStatuses
  handleAction: (action: BusinessInquiryActions) => void
}

export const PipelineActionButton: React.FC<ActionButtonProps> = ({
  handleAction,
  status,
}) => {
  switch (status) {
    case InquiryStatuses.STATUS_INQUIRING:
      return (
        <Box display="flex">
          <SimpleButton
            style={{
              marginRight: '16px',
              padding: '5px 20px',
              color: '#E04E2F',
              border: '1px solid #E04E2F',
            }}
            onClick={() => handleAction(BusinessInquiryActions.INQUIRY_DECLINE)}
          >
            {t('business.pipeline.reject', 'Reject inquiry')}
          </SimpleButton>
          <GradientButton
            style={{ padding: '5px 20px' }}
            onClick={() => handleAction(BusinessInquiryActions.INQUIRY_ACCEPT)}
          >
            {t('business.pipeline.accept', 'Accept inquiry')}
          </GradientButton>
        </Box>
      )
    default:
      return null
  }
}
