import { RawLineItem } from 'types/RawLineItem'

import lineItems from '../fixtures/lineItems.json'

import { factory, nameSequence, sequence } from './factory'

export const lineItemFactory = () =>
  factory<RawLineItem>({
    id: sequence(),
    name: nameSequence('Line Item'),
    business_type: 'venue',
    user_id: 1,
    moderated: true,
  })

export const lineItemsFactory = () =>
  function* () {
    yield lineItems
  }
