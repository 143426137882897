import * as React from 'react'
import { useState } from 'react'

import { Vendor, VendorType } from '../../types/Vendor'
import { venueListQueryParams } from '../mappers/venueListQueryParamsMapper'

export const InquiryModalControlContext = React.createContext<{
  isOpen: boolean
  category?: VendorType
  vendor?: Vendor
  venueId?: string
  venuesListParams?: Partial<venueListQueryParams>
  openVendorsCategory: (category: VendorType) => void
  openVendor: (vendor: Vendor) => void
  openVenuesList: (params: Partial<venueListQueryParams>) => void
  openVenue: (venueId: string) => void
  closeModal: () => void
  backToPrevious: () => void
}>({
  isOpen: false,
  openVendorsCategory: () => {},
  openVendor: () => {},
  openVenuesList: () => {},
  openVenue: () => {},
  closeModal: () => {},
  backToPrevious: () => {},
})

export const InquiryModalControlProvider: React.FC = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  const [vendorsData, setVendorsData] = useState<{
    category?: VendorType
    vendor?: Vendor
  }>({})
  const [venuesData, setVenuesData] = useState<{
    listParams?: Partial<venueListQueryParams>
    venueId?: string
  }>({})

  const openVenuesList = (listParams: Partial<venueListQueryParams>) => {
    setVenuesData({ listParams })
    setOpen(true)
  }

  const openVenue = (venueId: string) => {
    setVenuesData({ ...venuesData, venueId })
    setOpen(true)
  }

  const openVendorsCategory = (category: VendorType) => {
    setVendorsData({ category })
    setOpen(true)
  }

  const openVendor = (vendor: Vendor) => {
    setVendorsData({ ...vendorsData, vendor })
    setOpen(true)
  }

  const backToPrevious = () => {
    setVendorsData({ ...vendorsData, vendor: undefined })
    setVenuesData({ ...venuesData, venueId: undefined })
    setOpen(true)
  }

  const closeModal = () => {
    setVendorsData({})
    setVenuesData({})
    setOpen(false)
  }

  const context = React.useMemo(
    () => ({
      isOpen,
      openVendorsCategory,
      openVendor,
      closeModal,
      openVenue,
      openVenuesList,
      backToPrevious,
      category: vendorsData.category,
      vendor: vendorsData.vendor,
      venueId: venuesData.venueId,
      venuesListParams: venuesData.listParams,
    }),
    [
      vendorsData,
      venuesData,
      openVenuesList,
      openVenue,
      openVendorsCategory,
      closeModal,
      isOpen,
    ]
  )

  return (
    <InquiryModalControlContext.Provider value={context}>
      {children}
    </InquiryModalControlContext.Provider>
  )
}

export const useInquiryModalControl = () => {
  const context = React.useContext(InquiryModalControlContext)
  if (context === undefined) {
    throw new Error(
      'useInquiryModalControl must be used within an VenuesDataProvider'
    )
  }
  return context
}
