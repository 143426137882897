const FacebookIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 6.55556V15.4444C21 16.9179 20.4147 18.3309 19.3728 19.3728C18.3309 20.4147 16.9179 21 15.4444 21H6.55556C5.08213 21 3.66905 20.4147 2.62718 19.3728C1.58532 18.3309 1 16.9179 1 15.4444V6.55556C1 5.08213 1.58532 3.66905 2.62718 2.62718C3.66905 1.58532 5.08213 1 6.55556 1H15.4444C16.9179 1 18.3309 1.58532 19.3728 2.62718C20.4147 3.66905 21 5.08213 21 6.55556Z"
        stroke="#F7F7F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.88867 21.0001V11.0001C9.88867 8.569 10.4442 6.55566 14.3331 6.55566"
        stroke="#F7F7F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.6665 12.1111H14.3332"
        stroke="#F7F7F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FacebookIcon
