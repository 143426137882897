import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import CeilingHeight from '../../../../../../components/svg/dashboard/CeilingHeight'
import MaxHeadcount from '../../../../../../components/svg/dashboard/MaxHeadcount'
import Square from '../../../../../../components/svg/dashboard/Square'
import { EditButton } from '../../../../../../components/ui/Buttons/EditButton'
import { BlockTitle } from '../../../BusinessDetails.styles'

import { LabelsSelect } from './LabelsSelect'

export const DetailsPreview: React.FC<{
  entity?: any & { name: string; description: string }
  onEdit: () => void
}> = ({ entity, onEdit }) => {
  const { t } = useTranslation()
  const labels = entity?.labels || []
  const labelsControl = React.useState(labels)

  const bestUsedFor = labels.length
    ? labels.filter((label: any) => label.type === 'best used for')
    : []
  const amenities = labels.length
    ? labels.filter((label: any) => label.type === 'amenities')
    : []
  const specialFeatures = labels.length
    ? labels.filter((label: any) => label.type === 'special features')
    : []

  useEffect(() => {
    if (entity?.labels && entity?.labels.length) {
      const [currentLabels, setCurrentLabels] = labelsControl
      setCurrentLabels(entity.labels)
    }
  }, [entity?.labels])

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <BlockTitle>
          {t('dashboard.business.details.title', 'Business Details')}
        </BlockTitle>
        <Box>
          <EditButton onClick={onEdit} />
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Box display="flex" flexDirection="column" width={1}>
          <Typography sx={{ mb: 3 }} color="#252733" fontWeight={500}>
            {t('dashboard.business.details.features', 'Features')}
          </Typography>
          <Box display="flex" mb={4}>
            <Box display="flex" flexDirection="column" width={1}>
              <Typography
                sx={{ mb: 3 }}
                display="flex"
                alignItems="center"
                color="#9396A3"
                variant="body2"
              >
                <Square />
                <span style={{ marginLeft: '10px' }}>
                  {t(
                    'dashboard.business.details.squareFootage',
                    'Square footage'
                  )}
                </span>
              </Typography>
              <Typography variant="body2">
                {entity?.params?.sq_footage || '-'}
              </Typography>
            </Box>
            {/*<Box display="flex" flexDirection="column" width={1}>*/}
            {/*  <Typography sx={{ mb: 3 }} display="flex" alignItems="center" color="#9396A3" variant="body2">*/}
            {/*    <RoomsNumber />*/}
            {/*    <span style={{ marginLeft: '10px' }}>{t('dashboard.business.details.numberOfRooms', 'Number of rooms')}</span>*/}
            {/*  </Typography>*/}
            {/*  <Typography variant="body2">{entity?.description || '-'}</Typography>*/}
            {/*</Box>*/}
          </Box>
          <Box display="flex" mb={4}>
            <Box display="flex" flexDirection="column" width={1}>
              <Typography
                sx={{ mb: 3 }}
                display="flex"
                alignItems="center"
                color="#9396A3"
                variant="body2"
              >
                <CeilingHeight />
                <span style={{ marginLeft: '10px' }}>
                  {t(
                    'dashboard.business.details.ceilingHeight',
                    'Ceiling height'
                  )}
                </span>
              </Typography>
              <Typography variant="body2">
                {entity?.params?.ceiling_height || '-'}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" width={1}>
              <Typography
                sx={{ mb: 3 }}
                display="flex"
                alignItems="center"
                color="#9396A3"
                variant="body2"
              >
                <MaxHeadcount />
                <span style={{ marginLeft: '10px' }}>
                  {' '}
                  {t(
                    'dashboard.business.details.maxHeadcount',
                    'Max headcount'
                  )}
                </span>
              </Typography>
              <Typography variant="body2">
                {entity?.aboutInfo?.maxGuests || '-'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {entity ? (
        <Box display="flex" mt={5}>
          <LabelsSelect
            previewMode={true}
            labels={amenities}
            entity={entity}
            labelsControl={labelsControl}
            attachLabelMethod={() => console.log('preview ')}
            type="amenities"
          />
          <LabelsSelect
            previewMode={true}
            labels={bestUsedFor}
            entity={entity}
            labelsControl={labelsControl}
            attachLabelMethod={() => console.log('preview ')}
            type="best used for"
          />
        </Box>
      ) : null}
    </>
  )
}
