const Location = ({ fill = '#20202C' }) => {
  return (
    <svg width="12" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0A5.507 5.507 0 0 0 .5 5.5a5.444 5.444 0 0 0 1.108 3.3s.15.197.175.226L6 14l4.22-4.976.172-.224v-.002A5.442 5.442 0 0 0 11.5 5.5 5.506 5.506 0 0 0 6 0Zm0 7.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
        fill={fill}
      />
    </svg>
  )
}

export default Location
