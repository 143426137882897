import { Box, CircularProgress, styled } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as React from 'react'

interface LoaderProps {
  size?: number
  height?: number
  zIndex?: number
  absolute?: boolean
  position?: 'inherit' | 'absolute' | 'fixed'
  background?: string
}

const useStyles = makeStyles(() => ({
  circle: {
    stroke: 'url(#linearColors)',
  },
  svg: {
    marginRight: '300px',
    background: 'red',
  },
}))

export const AbsoluteContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; 
    top: 0; 
    bottom: 0; 
    left: 0; 
    right: 0;
`
)

export const Loader: React.FC<LoaderProps> = ({
  size = 70,
  height = 300,
  zIndex = 1400,
  background = 'rgba(122, 120, 120, 0.15)',
  position,
}) => {
  return position ? (
    <AbsoluteContainer style={{ position, background, zIndex }}>
      <GradientProgress size={size} height={height} />
    </AbsoluteContainer>
  ) : (
    <GradientProgress size={size} height={height} />
  )
}

const GradientProgress: React.FC<{ size?: number; height?: number }> = ({
  size,
  height,
}) => {
  const classes = useStyles({})

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box>
        <svg width="300" height={height}>
          <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
            <stop offset="20%" stopColor="rgba(47, 84, 235, 1)" />
            <stop offset="90%" stopColor="rgba(0, 175, 170, 0)" />
          </linearGradient>
        </svg>
      </Box>
      <CircularProgress
        size={size}
        thickness={4}
        classes={{ circle: classes.circle }}
        style={{ position: 'absolute' }}
      />
    </Box>
  )
}
