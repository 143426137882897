import { useQuery } from 'react-query'

import { ADMIN_USERS_QUERY_KEY } from '../../constants'
import { PromotionData, ResponseOrError } from '../../types/general'
import { clearObject } from '../helpers/data'
import { adminUsersListApiPath, settingsApiPath } from '../paths'

import { get } from './api'

async function fetchUsersForAdmin(
  options: { limit?: number } = {}
): Promise<any> {
  const users = await get(adminUsersListApiPath, { ...clearObject(options) })
  if (!users) {
    return []
  }
  return users
}

export function useAdminUsers(
  options: { limit?: number } = {},
  fetchOptions: { enabled: boolean } = { enabled: false }
) {
  return useQuery<any[], Error>(
    [ADMIN_USERS_QUERY_KEY, ...Object.values(options)],
    () => fetchUsersForAdmin(options),
    { ...fetchOptions }
  )
}

// const formatSettings = (settings: SettingsData) => {
//   return {
//     ...settings,
//     stripeFixTax: settings.stripeFixTax / 100,
//   }
// }

// async function fetchSettings(): Promise<any> {
//   const response = await get(settingsApiPath)
//   return formatSettings(response)
// }
//
// export function useSettings(fetchOptions: { enabled: boolean } = { enabled: true }) {
//   const query = useQuery<SettingsData, Error>(['settings'], () => fetchSettings(), { ...fetchOptions })
//   return { ...query, data: query.data || initialSettings }
// }

async function getPromotion(): Promise<ResponseOrError<any>> {
  return await get(settingsApiPath)
}

export function usePromotion(
  fetchOptions: { enabled: boolean } = { enabled: true }
) {
  return useQuery<PromotionData, Error>('promotion', getPromotion, {
    ...fetchOptions,
  })
}
