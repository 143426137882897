import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Splash from '../../../../components/svg/Splash'
import { usePartner } from '../../../../hooks/usePartner'
import { AddNewEventContainer } from '../../EventsList/EventsList.styles'

export const AddNewEventItem: React.FC<{ handleClick: () => void }> = ({
  handleClick,
}) => {
  const { isAbs } = usePartner()
  const { t } = useTranslation()

  return (
    <AddNewEventContainer onClick={handleClick}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Splash />
        <Typography sx={{ marginTop: '10px' }}>
          {isAbs
            ? t('partners.abs.bookings.add', 'Add new booking')
            : t('events.add', 'Add new event')}
        </Typography>
      </Box>
    </AddNewEventContainer>
  )
}
