const Send = ({ fill = '#FF9500' }) => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.192 9.743 1.854 1.552a.312.312 0 0 0-.443.354l1.683 6.878a.309.309 0 0 0 .203.22l2.885.991-2.883.99a.306.306 0 0 0-.201.221L1.41 18.095a.314.314 0 0 0 .443.354l16.339-8.145c.06-.03.11-.08.14-.139a.317.317 0 0 0-.14-.422ZM3.336 16.138l.983-4.016 5.765-1.979a.157.157 0 0 0 0-.296L4.32 7.87l-.979-4 12.266 6.15-12.27 6.118Z"
        fill={fill}
      />
    </svg>
  )
}

export default Send
