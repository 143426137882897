import { Box, Typography } from '@material-ui/core'
import * as React from 'react'

import { CalendarComponent } from '../../../../../components/GoogleCalendar/GoogleCalendar'

const EventsCalendarModal: React.FC<{
  calendarGoogleIds: string
  onClose: () => void
}> = ({ calendarGoogleIds, onClose }) => {
  return (
    <Box
      display="flex"
      width="100%"
      maxWidth={360}
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={5}>
        <Typography fontWeight={600} fontFamily="Poppins" variant="h4">
          My Events
        </Typography>
      </Box>
      <CalendarComponent calendarGoogleId={calendarGoogleIds} />
    </Box>
  )
}

export default EventsCalendarModal
