import { Box, Typography } from '@material-ui/core'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useWindowSize } from '../../hooks/useWindowSize'
import { GroupLineItemBundle } from '../../types/dtos/inquiry'
import { dateWithSeparator } from '../../utils/helpers/date'

import { InvoiceModalData } from './InvoiceDetailsModal'
import { LineItemsTable } from './LineItemsTable'

export interface InvoiceModalContentProps {
  invoice: InvoiceModalData
  lineItemsGroupsAmounts: {
    groupName: GroupLineItemBundle['name']
    amount: number
  }[]
  amount: number
  paymentPercentAmount: number
  totalAmount: string
}

export const InvoiceModalContent: React.FC<InvoiceModalContentProps> = ({
  invoice,
  lineItemsGroupsAmounts,
  amount,
  paymentPercentAmount,
  totalAmount,
}) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()
  const businessName = invoice?.businessName
  const businessType = invoice?.businessType

  return (
    <Box display="flex" width={1} height={1} flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          flex={isMobile ? '0 0 100%' : '0 0 auto'}
          order={isMobile ? 1 : 0}
          pb={1}
        >
          {!isMobile && (
            <Typography variant="h1" fontSize="40px" mb={4}>
              {t('invoice.payment', 'Payment Invoice')}{' '}
              {!!invoice?.id && (
                <Typography component="span" color="#9396A3" fontSize="20px">
                  #{invoice?.id}
                </Typography>
              )}
            </Typography>
          )}
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <Box
              display="flex"
              justifyContent={isMobile ? 'space-between' : 'flex-start'}
              flex={isMobile ? '0 0 100%' : '0 0 auto'}
              mt={isMobile ? '24px' : 0}
            >
              <Trans i18nKey="business.invoices.from">
                <Box flex={isMobile ? 1 : '0 0 auto'}>
                  <Typography
                    variant="body2"
                    color="#9396A3"
                    pb={isMobile ? '8px' : '16px'}
                  >
                    From
                  </Typography>
                  <Typography variant="body1" fontWeight="600">
                    {{ businessName }}
                  </Typography>
                </Box>
              </Trans>
              <Box ml="16px" flex={isMobile ? 1 : '0 0 auto'}>
                <Typography
                  variant="body2"
                  color="#9396A3"
                  pb={isMobile ? '8px' : '16px'}
                >
                  {t('business.invoices.type', 'Type')}
                </Typography>
                <Typography variant="body1" fontWeight="600">
                  {capitalize(invoice?.type)} payment
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent={isMobile ? 'space-between' : 'flex-start'}
              flex={isMobile ? '0 0 100%' : '0 0 auto'}
              mt={isMobile ? '24px' : 0}
            >
              <Box ml={isMobile ? 0 : '16px'} flex={isMobile ? 1 : '0 0 auto'}>
                <Typography
                  variant="body2"
                  color="#9396A3"
                  pb={isMobile ? '8px' : '16px'}
                >
                  {t('business.invoices.date', 'Date')}
                </Typography>
                <Typography variant="body1" fontWeight="600">
                  {dateWithSeparator(invoice?.creationDate, '/')}
                </Typography>
              </Box>
              {invoice?.dueDate && (
                <Box ml="16px" flex={isMobile ? 1 : '0 0 auto'}>
                  <Typography
                    variant="body2"
                    color="#9396A3"
                    pb={isMobile ? '8px' : '16px'}
                  >
                    {t('business.invoices.dueDate', 'Due date')}
                  </Typography>
                  <Typography variant="body1" fontWeight="600">
                    {dateWithSeparator(invoice?.dueDate, '/')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex={isMobile ? '0 0 100%' : '1'}
          alignItems={isMobile ? 'flex-start' : 'flex-end'}
          justifyContent="flex-end"
        >
          {isMobile && (
            <Typography variant="h1" fontSize="18px" mb={4}>
              Payment Invoice{' '}
              {!!invoice?.id && (
                <Typography component="span" color="#b6c0c2" fontSize="12px">
                  #{invoice?.id}
                </Typography>
              )}
            </Typography>
          )}
          <Typography
            variant="body1"
            fontWeight={600}
            mb={1}
            style={{ textTransform: 'uppercase' }}
          >
            {businessName}
          </Typography>
          {invoice?.businessAddress1 && (
            <Typography variant="body1" mb={1}>
              {invoice?.businessAddress1}
            </Typography>
          )}
          {invoice?.businessAddress2 && (
            <Typography variant="body1" mb={1}>
              {invoice?.businessAddress2}
            </Typography>
          )}
          {invoice?.businessPhone && (
            <Typography variant="body1" mb={1}>
              {invoice?.businessPhone}
            </Typography>
          )}
          {invoice?.businessMail && (
            <Typography variant="body1" mb={1}>
              {invoice?.businessMail}
            </Typography>
          )}
        </Box>
      </Box>
      <LineItemsTable
        invoice={invoice}
        paymentPercentAmount={paymentPercentAmount}
        amount={amount}
        lineItemsGroupsAmounts={lineItemsGroupsAmounts}
        totalAmount={totalAmount}
      />
      {(invoice?.message || invoice?.extra) && (
        <Box display="flex" flexWrap="wrap" mt={isMobile ? '24px' : 8}>
          {invoice?.message && (
            <Trans key="business.invoices.messageFrom">
              <Box
                flex={isMobile ? '0 0 100%' : '1'}
                pr={isMobile ? 0 : '12px'}
              >
                <Typography variant="body2" color="#9396A3" pb="16px">
                  Message from {{ businessType }}
                </Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                  {invoice?.message}
                </Typography>
              </Box>
            </Trans>
          )}
          {invoice?.extra && (
            <Box
              flex={isMobile ? '0 0 100%' : '1'}
              pl={isMobile ? 0 : '12px'}
              mt={isMobile ? '24px' : 0}
            >
              <Typography variant="body2" color="#9396A3" pb="16px">
                {t('business.invoices.extra', 'Extra info')}
              </Typography>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                {invoice?.extra}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
