import {
  Box,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core'
import * as React from 'react'

const StyledTextField = styled(TextField)(
  ({ theme }) => `
        input {
            padding: 12px 0 12px 10px;
        }
    `
)

export const TextInput: React.FC<TextFieldProps> = (props) => {
  const { label, ...rest } = props
  return (
    <>
      {label && (
        <Typography
          color="#9396A3"
          textAlign="left"
          variant="body2"
          sx={{ mb: 2 }}
        >
          {label}
        </Typography>
      )}
      <StyledTextField {...rest} />
    </>
  )
}
