import { Box, Typography } from '@material-ui/core'
import { capitalize } from 'lodash'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import img from '../../../assets/images/test-venue-item.jpg'
import CheckedCalendar from '../../../components/svg/CheckedCalendar'
import DollarCircle from '../../../components/svg/DollarCircle'
import EditIcon from '../../../components/svg/EditIcon'
import PeopleOutlined from '../../../components/svg/PeopleOutlined'
import ShareAlt from '../../../components/svg/ShareAlt'
import Ticketed from '../../../components/svg/Ticketed'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { getDefaultEventName } from '../../../constants'
import { usePartner } from '../../../hooks/usePartner'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useVenueById } from '../../../hooks/useVenueById'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { getModalContent } from '../../../utils/helpers/bookings'
import {
  convertDateRangeToReadableTimeRange,
  convertDateToShortTimeString,
  dateHasTime,
  getFormattedDateRange,
} from '../../../utils/helpers/date'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { LsGetInquiryDetails } from '../../../utils/storage'

import {
  DatesStepContainer,
  EditContainer,
  StyledListItem,
} from './Inquiry.styles'

export const InquiryDetailCard = () => {
  const { t } = useTranslation()
  const { isAbs } = usePartner()
  const { isMobile } = useWindowSize()
  const modalControl = useSimpleModal()
  const inquiryDetails = LsGetInquiryDetails()
  const budget = inquiryDetails?.budgets && inquiryDetails?.budgets[0]
  const { venue } = useVenueById(inquiryDetails.venue?.id)
  const { getUserActiveEvent } = useStorageEventsData()
  const { isOpen, toggleModal, modalData } = modalControl
  const userEvent = getUserActiveEvent()
  const inquiryVenue = useMemo(() => {
    if (inquiryDetails.venue?.id) {
      return venue || inquiryDetails.venue
    }

    return {
      name: userEvent?.client_venue?.name || 'My own venue',
      id: 0,
      address: userEvent?.client_venue?.location.description || '',
      pricing: '',
      photo: img,
      client_owned: true,
      client_venue: userEvent?.client_venue,
    }
  }, [userEvent, venue])

  return (
    <>
      <Box display={isMobile ? 'block' : 'grid'} gridTemplateColumns="1fr 1fr">
        <StyledListItem>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              alignContent="center"
              justifyContent="space-between"
              sx={{ mb: '20px' }}
            >
              <Typography variant="h3" color="textPrimary">
                {isAbs
                  ? t(
                      'partners.abs.bookings.general',
                      'General Booking Details'
                    )
                  : t('events.generalDetails', 'General Event Details')}
              </Typography>
              <EditContainer
                onClick={() =>
                  toggleModal({
                    name: 'inquiryCreate',
                    venue: inquiryVenue,
                    rooms: inquiryDetails.rooms,
                    step: 0,
                  })
                }
              >
                <EditIcon />
              </EditContainer>
            </Box>
            <Box display="flex" mb={3} justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  minWidth="170px"
                >
                  <Box sx={{ width: '26px' }}>
                    <CheckedCalendar />
                  </Box>
                  <Typography
                    color="#BDBDBD"
                    sx={{ ml: '10px' }}
                    fontSize="12px"
                  >
                    {isAbs
                      ? t('partners.abs.bookings.name', 'Booking name')
                      : t('events.name', 'Event name')}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ ml: '5px' }}>
                  <Typography
                    sx={{ ml: '5px', fontWeight: '500' }}
                    variant="body2"
                  >
                    {inquiryDetails?.name || getDefaultEventName(!!isAbs)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" minWidth="145px" mb={3} alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                alignContent="center"
                minWidth="170px"
              >
                <ShareAlt />
                <Typography color="#BDBDBD" sx={{ ml: '10px' }} fontSize="12px">
                  {isAbs
                    ? t('partners.abs.bookings.type', 'Booking type')
                    : t('events.type', 'Event type')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ ml: '5px' }}>
                <Typography
                  sx={{ ml: '5px', fontWeight: '500' }}
                  variant="body2"
                >
                  {capitalize(
                    inquiryDetails.type || inquiryDetails.eventType || '-'
                  )}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" mb={3} alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                alignContent="center"
                minWidth="170px"
              >
                <Box sx={{ width: '26px' }}>
                  <Ticketed />
                </Box>
                <Typography color="#BDBDBD" sx={{ ml: '10px' }} fontSize="12px">
                  {isAbs
                    ? t('partners.abs.bookings.style', 'Booking style')
                    : t('events.style', 'Event style')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ ml: '5px' }}>
                <Typography
                  sx={{ ml: '5px', fontWeight: '500' }}
                  variant="body2"
                >
                  {capitalize(inquiryDetails?.style || '-')}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" mb={3} alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                alignContent="center"
                minWidth="170px"
              >
                <Box sx={{ width: '26px' }}>
                  <PeopleOutlined />
                </Box>
                <Typography color="#BDBDBD" sx={{ ml: '10px' }} fontSize="12px">
                  {t('events.headCount', 'Head count')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ ml: '5px' }}>
                <Typography
                  sx={{ ml: '5px', fontWeight: '500' }}
                  variant="body2"
                >
                  {inquiryDetails?.guests || '-'}{' '}
                  {inquiryDetails?.guests ? t('events.guests', 'guests') : ''}
                </Typography>
              </Box>
            </Box>
            {budget && (
              <Box
                display="flex"
                justifyContent="space-between"
                mb={3}
                alignItems="center"
              >
                <Box display="flex">
                  <Box
                    display="flex"
                    alignItems="center"
                    alignContent="center"
                    minWidth="170px"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      sx={{ width: '26px' }}
                    >
                      <DollarCircle fill="#9396A3" />
                    </Box>
                    <Typography
                      color="#BDBDBD"
                      sx={{ ml: '10px' }}
                      fontSize="12px"
                    >
                      {budget.title}
                    </Typography>
                  </Box>
                  {budget.value && (
                    <Box display="flex" alignItems="center" sx={{ ml: '5px' }}>
                      <Typography
                        sx={{ ml: '5px', fontWeight: '500' }}
                        variant="body2"
                      >
                        {budget.value}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {inquiryDetails.notes && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  minWidth="170px"
                >
                  <Box sx={{ width: '26px' }}>
                    <CheckedCalendar />
                  </Box>
                  <Typography
                    color="#BDBDBD"
                    sx={{ ml: '10px' }}
                    fontSize="12px"
                  >
                    {isAbs
                      ? t(
                          'partners.abs.bookings.additional',
                          'Additional booking details'
                        )
                      : t('events.additional', 'Additional event details')}
                  </Typography>
                </Box>
                <Typography
                  sx={{ ml: '5px', fontWeight: '500', wordBreak: 'break-all' }}
                  variant="body2"
                >
                  {inquiryDetails?.notes}
                </Typography>
              </>
            )}
          </Box>
        </StyledListItem>
        <StyledListItem sx={{ ml: '10px' }}>
          <Box
            display="flex"
            ml={isMobile ? 0 : 5}
            justifyContent="space-between"
          >
            <DatesStepContainer>
              <Box
                display="flex"
                alignItems="center"
                alignContent="center"
                justifyContent="space-between"
                sx={{ mb: '20px' }}
              >
                <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
                  {isAbs
                    ? t('partners.abs.bookings.dates', 'Booking dates')
                    : t('events.dates.eventDates', 'Event dates')}
                </Typography>
                <EditContainer
                  onClick={() =>
                    toggleModal({
                      name: 'inquiryCreate',
                      rooms: inquiryDetails.rooms,
                      venue: inquiryVenue,
                      step: 1,
                    })
                  }
                >
                  <EditIcon />
                </EditContainer>
              </Box>
              <Box style={{ position: 'relative' }}>
                {!inquiryDetails.is_flexible_date &&
                  inquiryDetails.load_in_start_date && (
                    <Box display="flex" justifyContent="space-between">
                      <Box mb={2}>
                        <Typography
                          color="#9396A3"
                          minWidth="80px"
                          marginBottom="9px"
                          variant="body2"
                        >
                          {t('events.dates.loadIn', 'Load in')}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          {inquiryDetails.load_in_start_date && (
                            <Box display="flex" minWidth="176px">
                              <Typography variant="body2">
                                {getFormattedDateRange(
                                  inquiryDetails.load_in_start_date,
                                  inquiryDetails?.load_in_end_date
                                )}
                              </Typography>
                            </Box>
                          )}
                          {dateHasTime(inquiryDetails.load_in_start_date) && (
                            <Box display="flex" alignItems="center" ml="27px">
                              <Typography
                                color="#BDBDBD"
                                fontSize="12px"
                                sx={{ mr: '27px' }}
                                variant="body2"
                              >
                                {t('events.at', 'at')}
                              </Typography>
                              <Typography variant="body2">
                                {convertDateRangeToReadableTimeRange([
                                  inquiryDetails.load_in_start_date,
                                  inquiryDetails?.load_in_end_date,
                                ])}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                <Box display="flex" justifyContent="space-between">
                  <Box mb={2} width="100%">
                    <Typography
                      color="#9396A3"
                      minWidth="80px"
                      marginBottom="16px"
                      variant="body2"
                    >
                      {!inquiryDetails.start_date
                        ? '-'
                        : inquiryDetails.is_flexible_date
                        ? t('events.dates.flexible', 'Flexible dates')
                        : isAbs
                        ? t('partners.abs.bookings.booking', 'Booking')
                        : t('events.event', 'Event')}
                    </Typography>
                    <Box display="flex" alignItems="center" mb="16px">
                      {inquiryDetails.start_date && (
                        <Box
                          display="flex"
                          alignItems="center"
                          minWidth="176px"
                        >
                          <Typography
                            color="#BDBDBD"
                            fontSize="12px"
                            minWidth="60px"
                            variant="body2"
                          >
                            {t('events.dates.start', 'Start -')}
                          </Typography>
                          <Typography variant="body2">
                            {getFormattedDateRange(inquiryDetails.start_date)}
                          </Typography>
                        </Box>
                      )}
                      {dateHasTime(inquiryDetails.start_date) && (
                        <Box display="flex" alignItems="center" ml="27px">
                          <Typography
                            color="#BDBDBD"
                            fontSize="12px"
                            sx={{ mr: '27px' }}
                            variant="body2"
                          >
                            {t('events.at', 'at')}
                          </Typography>
                          <Typography variant="body2">
                            {convertDateToShortTimeString(
                              inquiryDetails.start_date
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box display="flex" alignItems="center">
                      {inquiryDetails.end_date && (
                        <Box
                          display="flex"
                          alignItems="center"
                          minWidth="176px"
                        >
                          <Typography
                            color="#BDBDBD"
                            fontSize="12px"
                            minWidth="60px"
                            variant="body2"
                          >
                            {t('events.dates.end', 'End -')}
                          </Typography>
                          <Typography variant="body2">
                            {getFormattedDateRange(inquiryDetails?.end_date)}
                          </Typography>
                        </Box>
                      )}
                      {dateHasTime(inquiryDetails.start_date) && (
                        <Box display="flex" alignItems="center" ml="27px">
                          <Typography
                            color="#BDBDBD"
                            fontSize="12px"
                            sx={{ mr: '27px' }}
                            variant="body2"
                          >
                            {t('events.at', 'at')}
                          </Typography>
                          <Typography variant="body2">
                            {convertDateToShortTimeString(
                              inquiryDetails?.end_date
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                {!inquiryDetails.is_flexible_date &&
                  inquiryDetails.load_out_start_date && (
                    <Box>
                      <Box minWidth="80px" display="flex" alignItems="center">
                        <Typography
                          color="#9396A3"
                          marginBottom="9px"
                          variant="body2"
                        >
                          {t('events.dates.loadOut', 'Loud out')}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Box display="flex" minWidth="176px">
                          <Typography variant="body2">
                            {getFormattedDateRange(
                              inquiryDetails.load_out_start_date,
                              inquiryDetails?.load_out_end_date
                            )}{' '}
                          </Typography>
                        </Box>
                        {dateHasTime(inquiryDetails.load_out_start_date) && (
                          <Box display="flex" alignItems="center" ml="27px">
                            <Typography
                              color="#BDBDBD"
                              fontSize="12px"
                              sx={{ mr: '27px' }}
                              variant="body2"
                            >
                              {t('events.at', 'at')}
                            </Typography>
                            <Typography variant="body2">
                              {convertDateRangeToReadableTimeRange([
                                inquiryDetails.load_out_start_date,
                                inquiryDetails?.load_out_end_date,
                              ])}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
              </Box>
            </DatesStepContainer>
          </Box>
        </StyledListItem>
      </Box>
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl)}
      </SimpleModal>
    </>
  )
}
