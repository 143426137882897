import axios from 'axios'
import * as React from 'react'

import { bookingQueryParams } from '../constants/bookings'
import { InquiryEvent } from '../types/Inquiry'
import { fetchBusinessBookings } from '../utils/api/events'
import { prepareQueryParams } from '../utils/helpers/bookings'
import { useAuthData } from '../utils/providers/AuthProvider'

interface UseBookings {
  isListLoading: boolean
  bookings: any
  fetchBookings: () => void
  lastPage: number
}

export function useBookings(
  businessId: string,
  queryParams: bookingQueryParams,
  isQueryEnabled = true
): UseBookings {
  const { authUser } = useAuthData()
  const [bookings, setBookings] = React.useState<any[]>([])
  const [isListLoading, setIsListLoading] = React.useState<boolean>(false)
  const [lastPage, setLastPage] = React.useState<number>(0)

  const fetchBookings = async () => {
    if (!businessId) return
    try {
      setIsListLoading(true)
      const params = prepareQueryParams(queryParams)
      const data = await fetchBusinessBookings(businessId, params)
      if (data.data) {
        const events = data.data.length
          ? data.data?.map((event: InquiryEvent) => ({
              ...event,
              inquiry: event.inquiries_related[0],
            }))
          : data.data
        setBookings(events)
        setLastPage(data.last_page)
      }
      setIsListLoading(false)
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.log(error)
      }
    }
  }

  React.useEffect(() => {
    if (!authUser?.id || !isQueryEnabled) return

    fetchBookings()
  }, [authUser?.id, businessId, queryParams, isQueryEnabled])

  return {
    isListLoading,
    bookings,
    fetchBookings,
    lastPage,
  }
}
