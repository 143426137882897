import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default function VQueryClientProvider(props: {
  children: React.ReactChild
}): JSX.Element {
  return (
    <QueryClientProvider client={client}>{props.children}</QueryClientProvider>
  )
}
