import { Box, Divider, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import { Loader } from '../../components/ui/Loader'
import { DashboardTitle, PageTitle } from '../../components/ui/PageTitle'
import { SideMenu } from '../../components/ui/SideMenu'
import { getProfileMenuItems, UserRoles } from '../../constants/profile'
import { usePartner } from '../../hooks/usePartner'
import { useWindowSize } from '../../hooks/useWindowSize'
import { EventVenue } from '../../types/Event'
import {
  dashboardProfilePath,
  dashboardProfilePersonalInfoPath,
  dashboardProfileSecurityPath,
  profilePath,
  profilePersonalInfoPath,
  profileSecurityPath,
} from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../utils/providers/EventsProvider'
import { MessageModalProvider } from '../../utils/providers/MessageModalProvider'
import { BlockContainer } from '../dashboard/BusinessDetails/BusinessDetails.styles'

import { PersonalInfo } from './components/personal-info'
import { Security } from './components/security'

const StyledContainer = styled(Box)`
  width: 100%;

  ${MEDIA_RULES.SMALL} {
    min-height: calc(100vh - 100px);
  }
`

export const Profile = () => {
  const { isMobile } = useWindowSize()
  const userName = JSON.parse(localStorage.getItem('currentUser') || '{}')?.name
  const { authUser } = useAuthData()
  const isClient = UserRoles.isClient(authUser?.role)
  const isLandlord = UserRoles.isLandlord(authUser?.role)
  const { path } = useRouteMatch()
  const location = useLocation()
  const { pathname } = location
  const [activeItem, setActiveItem] = useState<string>()
  const [isComponentRendered, setIsComponentRendered] = useState<boolean>(false)
  const [currentAbsVenue, setCurrentAbsVenue] =
    React.useState<EventVenue | null>(null)
  const profilePathname = isClient ? profilePath : dashboardProfilePath
  const profilePersonalInfoPathname = isClient
    ? profilePersonalInfoPath
    : dashboardProfilePersonalInfoPath
  const profileSecurityPathname = isClient
    ? profileSecurityPath
    : dashboardProfileSecurityPath
  const { chosenAbsVenue } = useStorageEventsData()
  const { isAbs } = usePartner()

  useLayoutEffect(() => {
    setIsComponentRendered(pathname.includes(profilePathname))
  }, [profilePathname])

  useEffect(() => {
    setActiveItem(
      isMobile
        ? pathname
        : pathname !== profileSecurityPathname
        ? profilePersonalInfoPathname
        : pathname
    )
  }, [pathname, profileSecurityPathname, profilePersonalInfoPathname, isMobile])

  useEffect(() => {
    if (!chosenAbsVenue) return
    setCurrentAbsVenue(chosenAbsVenue)
  }, [chosenAbsVenue])

  const isMenuShown = useMemo(() => {
    return isMobile ? activeItem === profilePathname : true
  }, [activeItem, isMobile, profilePathname])

  if (!isComponentRendered) return <Loader />

  return (
    <MessageModalProvider>
      <StyledContainer mx={!isClient && !isMobile ? 6 : 0}>
        <Box className={isClient || isMobile ? 'container' : ''}>
          {isMenuShown &&
            (isClient ? (
              <>
                <PageTitle
                  title={
                    isMobile
                      ? `Hi${userName ? `, ${userName}!` : '!'}`
                      : 'Account profile'
                  }
                />
                {!isMobile && <Divider sx={{ mt: 3, mb: 7 }} />}
              </>
            ) : (
              <DashboardTitle
                showListingPicker={false}
                title={
                  isMobile
                    ? `Hi${userName ? `, ${userName}!` : '!'}`
                    : 'Account profile'
                }
              />
            ))}
          <Box display="flex">
            {isMenuShown && (
              <SideMenu
                businessType={
                  !isClient
                    ? isLandlord
                      ? UserRoles.Landlord
                      : UserRoles.Vendor
                    : undefined
                }
                withAddListing={!isClient}
                items={getProfileMenuItems(isClient, isMobile)}
                activeItem={activeItem}
                onItemClick={(path) => setActiveItem(path)}
              />
            )}
            {((isMobile && !isMenuShown) || !isMobile) && (
              <BlockContainer flex={1} minHeight={isMobile ? 'auto' : '732px'}>
                <Switch>
                  <Route
                    exact
                    path={
                      isMobile
                        ? `${path}/personal-info`
                        : [path, `${path}/personal-info`]
                    }
                  >
                    <PersonalInfo
                      isAbs={!!isAbs}
                      currentAbsVenue={currentAbsVenue}
                      setCurrentAbsVenue={setCurrentAbsVenue}
                      onClose={
                        isMobile
                          ? () => setActiveItem(profilePathname)
                          : undefined
                      }
                    />
                  </Route>
                  <Route exact path={`${path}/security`}>
                    <Security
                      isAbs={!!isAbs}
                      onClose={
                        isMobile
                          ? () => setActiveItem(profilePathname)
                          : undefined
                      }
                    />
                  </Route>
                </Switch>
              </BlockContainer>
            )}
          </Box>
        </Box>
      </StyledContainer>
    </MessageModalProvider>
  )
}
