import { Box, Link, Typography } from '@material-ui/core'
import { LanguageOutlined } from '@material-ui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useWindowSize } from '../../hooks/useWindowSize'
import { Vendor } from '../../types/Vendor'
import { VenueDetails } from '../../types/Venue'
import { useAuthData } from '../../utils/providers/AuthProvider'
import User from '../svg/dashboard/User'
import InstaIcon from '../svg/InstagrammIcon'
import InstaSmallIcon from '../svg/InstaSmallIcon'
import Mail from '../svg/Mail'
import Marker from '../svg/Marker'
import PhoneOutlined from '../svg/PhoneOutlined'

import { ContactInfoWrapper } from './BusinessContactInfo.styles'

export const BusinessContactInfo: React.FC<{
  business?: VenueDetails | Vendor | null
}> = ({ business }) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const instaLinkName = business?.contact.instagram
    ? business?.contact.instagram.split('/')[3].toString()
    : 'instagram'
  const website = business?.contact.website
    ? business?.contact.website.includes('http')
      ? business?.contact.website
      : `http://${business?.contact.website}`
    : ''

  return (
    <ContactInfoWrapper>
      <Typography variant="h2" mb={!isMobile ? '24px' : '32px'}>
        {t('business.contactInfo.heading', 'Contact information')}
      </Typography>
      {/* @ts-ignore
      {!!business?.contact.name && business?.is_exclusive && (
        <Box display="flex" alignItems="center" mb="18px">
          <Box width={20}>
            <User color="#20202C" />
          </Box>
          <Typography variant="subtitle2" ml="15px">
            {business?.contact.name}
          </Typography>
        </Box>
      )} */}
      {!!business?.contact.address.description && (
        <Box display="flex" alignItems="center" mb="18px">
          <Box width={20}>
            <Marker fill="#000" />
          </Box>
          <Typography variant="subtitle2" ml="15px">
            {business?.address
              ? business?.address
              : business?.contact.address.description}
          </Typography>
        </Box>
      )}
      {/*@ts-ignore*/}
      {/* {!!business?.contact.phone && business?.is_exclusive && (
        <Link underline="none" href={`tel:${business?.contact.phone}`}>
          <Box sx={{ '&:hover': { opacity: 0.8 } }} display="flex" alignItems="center" mb="18px">
            <Box width={20}>
              <PhoneOutlined fill="#2F54EB" />
            </Box>
            <Typography ml="15px" color="#2F54EB" fontFamily="Poppins" fontWeight="500">
              {business?.contact.phone}
            </Typography>
          </Box>
        </Link>
      )} */}
      {/*@ts-ignore*/}
      {/* {!!business?.contact.email && business?.is_exclusive && (
        <Link underline="none" href={`mailto:${business?.contact.email}`}>
          <Box display="flex" sx={{ '&:hover': { opacity: 0.8 } }} alignItems="center" mb="18px">
            <Box width={20}>
              <Mail />
            </Box>
            <Typography ml="15px" color="#2F54EB" fontFamily="Poppins" fontWeight="500" sx={{ wordBreak: 'break-all' }}>
              {business?.contact.email}
            </Typography>
          </Box>
        </Link>
      )} */}
      {/*@ts-ignore*/}
      {/* {!!business?.contact.website && business?.is_exclusive && (
        <Link sx={{ textTransform: 'lowercase', '&:hover': { opacity: 0.8 } }} underline="none" href={business?.contact.website} target="_blank">
          <Box display="flex" alignItems="center" mb="18px">
            <Box width={20} display="flex" justifyContent="center">
              <LanguageOutlined sx={{ color: '#2F54EB' }} fill="#2F54EB" />
            </Box>
            <Typography color="#2F54EB" ml="15px" fontWeight="500">
              {website}
            </Typography>
          </Box>
        </Link>
      )} */}
      {/*@ts-ignore*/}
      {/* {!!business?.contact.instagram && business?.is_exclusive && (
        <Link sx={{ textTransform: 'lowercase', '&:hover': { opacity: 0.8 } }} underline="none" href={business?.contact.instagram} target="_blank">
          <Box display="flex" alignItems="center" mb="18px">
            <Box width={20}>
              <InstaSmallIcon />
            </Box>
            <Typography color="#2F54EB" ml="15px" fontWeight="500">
              {instaLinkName}
            </Typography>
          </Box>
        </Link>
      )} */}
    </ContactInfoWrapper>
  )
}
