import Button from '@material-ui/core/Button'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosOutlined'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

interface ExpandMapButtonProps {
  doExpandMap: (param: any) => void
  isExpandedMap: boolean
}

export const ExpandMapButton: React.FC<ExpandMapButtonProps> = ({
  doExpandMap,
  isExpandedMap,
}) => {
  const { t } = useTranslation()

  return (
    <Button
      onClick={() => {
        doExpandMap(!isExpandedMap)
      }}
      variant="contained"
      style={{
        fontFamily: 'Poppins',
        position: 'absolute',
        lineHeight: '28px',
      }}
      color="primary"
      startIcon={
        isExpandedMap ? (
          <ArrowForwardIosIcon style={{ fontSize: '14' }} />
        ) : (
          <ArrowBackIosIcon style={{ fontSize: '14' }} />
        )
      }
    >
      {isExpandedMap
        ? t('venues.showList', 'Show list')
        : t('venues.hideList', 'Hide list')}
    </Button>
  )
}
