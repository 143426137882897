import { CheckboxProps } from '@material-ui/core'

import { Selection } from './PartialCheckbox'
export * from './PartialCheckbox'

export const useCheckbox = (
  props: CheckboxProps & { value: boolean | Selection }
) => {
  return {
    ...props,
    checked: [true, 'ALL', 'PARTIAL'].includes(props.value),
    indeterminate: props.value === 'PARTIAL',
  }
}
