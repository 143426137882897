import { Box, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import GradientButton from '../components/ui/Buttons/GradientButton'
import { PageTitle } from '../components/ui/PageTitle'
import { UserRoles } from '../constants/profile'
import { usePartner } from '../hooks/usePartner'
import { useWindowSize } from '../hooks/useWindowSize'
import { dashboardPath, marketplacePath, vendorsPath } from '../utils/paths'
import { useAuthData } from '../utils/providers/AuthProvider'

const StyledContainer = styled(Box)`
  background: rgba(32, 32, 44, 0.9);
  width: 100%;
  position: relative;

  .container > .MuiBox-root:first-of-type {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);

    .MuiTypography-root {
      font-size: 311px;
      line-height: 0;
      letter-spacing: 50px;
      font-family: Poppins;
      color: #40414c;

      ${MEDIA_RULES.SMALL} {
        font-size: 125px;
        letter-spacing: 20px;
      }
    }
  }
`

const StyledContent = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
`

interface notFound {
  type: '403' | '404'
}

export const NotAvailablePage: React.FC<{ type?: notFound['type'] }> = ({
  type = '404',
}) => {
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()
  const { isAbs } = usePartner()
  const { authUser, isLoading } = useAuthData()
  const isClient = !authUser?.role || UserRoles.isClient(authUser?.role)

  useEffect(() => {
    if (isLoading || authUser) return

    if (
      (!authUser &&
        history.location.pathname.includes('details') &&
        history.location.pathname.includes('my-events')) ||
      (!authUser &&
        history.location.pathname.includes('details') &&
        history.location.pathname.includes('bookings'))
    ) {
      history.push(location.pathname + '?action=login')
    }
  }, [authUser, isLoading])

  return (
    <StyledContainer
      mb={isAbs ? 0 : isMobile ? '-160px' : '-180px'}
      height={isClient ? 'calc(100vh - 100px)' : '100vh'}
    >
      <Box className="container" height="100%">
        <PageTitle title={type} />
        <StyledContent>
          <Box>
            {type === '403' ? (
              <Typography
                variant="h2"
                mb="8px"
                fontFamily="Poppins"
                fontWeight="500"
                lineHeight={isMobile ? '50px' : '72px'}
                fontSize={isMobile ? '32px' : '56px'}
              >
                {t(
                  'common.notAvailable.title',
                  'Page is not available for you'
                )}
              </Typography>
            ) : (
              <Typography
                variant="h2"
                mb="8px"
                fontFamily="Poppins"
                fontWeight="500"
                lineHeight={isMobile ? '50px' : '72px'}
                fontSize={isMobile ? '32px' : '56px'}
              >
                {t('common.notFound.title', 'Page not found')}
              </Typography>
            )}
            {isClient &&
              (isAbs ? (
                <Typography
                  variant="h3"
                  mb="20px"
                  fontFamily="Poppins"
                  fontWeight="400"
                  color="#9396A3"
                  fontSize="16px"
                >
                  {t(
                    'partners.abs.notFound.subtitle',
                    'But we found your perfect vendors'
                  )}
                </Typography>
              ) : (
                <Typography
                  variant="h3"
                  mb="20px"
                  fontFamily="Poppins"
                  fontWeight="400"
                  color="#9396A3"
                  fontSize="16px"
                >
                  {t(
                    'common.notFound.subtitle',
                    'But we found your perfect venue'
                  )}
                </Typography>
              ))}
            {isClient ? (
              isAbs ? (
                <GradientButton
                  sx={{ width: '280px', margin: '0 auto' }}
                  onClick={() => history.push(vendorsPath)}
                >
                  {t('partners.abs.notFound.action', 'Show vendors')}
                </GradientButton>
              ) : (
                <GradientButton
                  sx={{ width: '280px', margin: '0 auto' }}
                  onClick={() => history.push(marketplacePath)}
                >
                  {t('common.notFound.action', 'Browse venues')}
                </GradientButton>
              )
            ) : (
              <GradientButton
                sx={{ width: '280px', margin: '0 auto' }}
                onClick={() => history.push(dashboardPath)}
              >
                {t('business.notAvailable.action', 'Go to dashboard')}
              </GradientButton>
            )}
          </Box>
        </StyledContent>
      </Box>
    </StyledContainer>
  )
}
