export const COLUMN_WIDTH_60 = 60
export const COLUMN_WIDTH_70 = 70
export const COLUMN_WIDTH_80 = 80
export const COLUMN_WIDTH_100 = 100
export const COLUMN_WIDTH_120 = 120
export const COLUMN_WIDTH_130 = 130
export const COLUMN_WIDTH_140 = 140
export const COLUMN_WIDTH_160 = 160
export const COLUMN_WIDTH_180 = 180
export const COLUMN_WIDTH_200 = 200
export const COLUMN_WIDTH_300 = 300
export const COLUMN_WIDTH_480 = 480

export const COLUMN_WIDTH_MAX_CONTENT = 'max-content'
