import { Box, Button, IconButton, styled } from '@material-ui/core'

export const StyledMessageBox = styled('div')<{
  withoutAttachments: boolean
  position: string
}>(
  (props) => `
  position: relative;
  margin: ${
    props.position === 'right' ? '30px 0 24px 30px' : '30px 30px 24px 4px'
  };
  background-color:  ${
    props.position === 'right' ? '#f7f7f8' : props.theme.palette.common.white
  };
  padding: ${props.withoutAttachments ? '16px' : 0};
  border-radius: 4px;
  box-shadow: ${
    props.position === 'left' ? '4px 4px 31px rgba(0, 0, 0, 0.05)' : 'none'
  };
  cursor: pointer;
  
  * {
    margin: 0;
  }
  
  & .date-row {
    display: none;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    justify-content: space-between;
  }
  
  &:hover .date-row  {
    display: flex;
  }
`
)

export const StyledEmptyBox = styled(Box)`
  background-color: #f7f7f8;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledIconButton = styled(IconButton)`
  background-color: #f5f5f8;
  border-radius: 2px;
  width: 44px;
  height: 44px;
  margin-left: 16px;

  &:hover {
    opacity: 0.95;
  }
`

export const StyledSaveTemplateButton = styled(Button)`
  background: rgba(47, 84, 235, 0.05);
  border-radius: 2px;
  min-width: 159px;
  text-transform: none;
`

export const StyledChatBox = styled(Box)(
  ({ theme }) => `
  position: relative;
  height: calc(100% - 110px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .MuiSelect-root {
    width: 171px;
    margin: 0 16px;
    background: rgba(47, 84, 235, 0.05);
    font-size: 14px;
    color: ${theme.palette.primary.main};
  }

  .MuiInput-root:after,
  .MuiInput-root:before,
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .MuiInput-input:focus {
    background: none;
  }
`
)
