import {
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { AttachFile, ExitToAppOutlined } from '@material-ui/icons'
import * as React from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { useCurrentBusinessListingId } from '../../hooks/useCurrentBusinessListingId'
import {
  dashboardBookingsPath,
  dashboardBusinessDetailsPath,
  dashboardBusinessFilesPath,
  dashboardListingPath,
  dashboardPath,
  dashboardProfilePath,
  venuesPath,
} from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../utils/providers/EventsProvider'
import { VendorPackagesGray } from '../svg/dashboard/VendorPackagesWhite'
import House from '../svg/House'
import UserIconFilled from '../svg/UserIconFilled'
import VenuesIcon from '../svg/VenuesIcon'

type NavListItemProps = ListItemProps & {
  button?: any
  component?: any
  to?: any
  isOpen?: boolean
}

export const StyledListItem: React.FC<NavListItemProps> = styled(
  ListItem
)<NavListItemProps>(
  (props) => `
  padding-left: ${props.theme.spacing(4)}px;
  color: #9FA2B4;
  margin: 10px;
  overflow: hidden;
  
  &:hover {
   background: linear-gradient(180deg, #4e4bec 0%, #3f91f0 100%);
   width: 230px;
   path {
    fill: white;
   }
   .MuiListItemText-root {
      color: white;
   }
  }
  svg: {
    color: #9FA2B4;
  }
  &.active {
    color: #ffff;
    path {
      fill: ${props.isOpen ? '#ffff' : '#597EF7'};
    }
  }
`
)

const StyledList = styled(List)`
  .active {
    color: #ffff;

    path {
      fill: #ffff;
    }
  }

  overflow: visible;

  &.collapsed .MuiListItemText-root {
    color: transparent;
  }

  &.opened .active {
    background: linear-gradient(180deg, #4e4bec 0%, #3f91f0 100%);
  }
`

export const SideNavList: React.FC<{ isOpen?: boolean }> = ({ isOpen }) => {
  const { logout } = useAuthData()
  const { clearEventsFromStorage } = useStorageEventsData()
  const history = useHistory()

  const className = isOpen ? 'opened' : 'collapsed'
  const { businessId } = useCurrentBusinessListingId()

  const isActive = (value: string) => {
    if (
      value.replace(`${businessId}`, ':listingId') ===
      dashboardBusinessDetailsPath
    )
      return history.location.pathname.indexOf(value) !== -1
    else if (
      value.replace(`${businessId}`, ':listingId') === dashboardBookingsPath
    )
      return history.location.pathname.indexOf(value) !== -1
    else if (
      value.replace(`${businessId}`, ':listingId') === dashboardListingPath &&
      history.location.pathname === dashboardPath
    )
      return true
    else if (value === dashboardProfilePath)
      return history.location.pathname.indexOf(value) !== -1
    else return history.location.pathname === value
  }
  const handleLogout = async () => {
    const successLogout = await logout()
    if (successLogout) {
      clearEventsFromStorage()
      history.push(venuesPath)
    }
  }

  const getNavLinkProps = (path: string) => {
    return {
      onClick: () => history.push(path),
      className: isActive(path) ? 'active' : '',
    }
  }

  return (
    <StyledList className={className}>
      <StyledListItem
        isOpen={isOpen}
        button
        key="Dashboard"
        {...getNavLinkProps(
          businessId
            ? generatePath(dashboardListingPath, { listingId: businessId })
            : dashboardPath
        )}
      >
        <ListItemIcon>
          <House />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </StyledListItem>
      {businessId && (
        <>
          <StyledListItem
            isOpen={isOpen}
            button
            key="Bookings"
            {...getNavLinkProps(
              generatePath(dashboardBookingsPath, { listingId: businessId })
            )}
          >
            <ListItemIcon>
              <VenuesIcon />
            </ListItemIcon>
            <ListItemText primary="Bookings" />
          </StyledListItem>
          <StyledListItem
            isOpen={isOpen}
            button
            key="Business"
            {...getNavLinkProps(
              generatePath(dashboardBusinessDetailsPath, {
                listingId: businessId,
              })
            )}
          >
            <ListItemIcon>
              <VendorPackagesGray />
            </ListItemIcon>
            <ListItemText primary="Business details" />
          </StyledListItem>
          <StyledListItem
            isOpen={isOpen}
            button
            key="Files"
            {...getNavLinkProps(dashboardBusinessFilesPath)}
          >
            <ListItemIcon>
              <AttachFile style={{ color: '#9FA2B4', marginLeft: -2 }} />
            </ListItemIcon>
            <ListItemText primary="Files" />
          </StyledListItem>
        </>
      )}
      <StyledListItem
        isOpen={isOpen}
        button
        key="Profile"
        {...getNavLinkProps(dashboardProfilePath)}
      >
        <ListItemIcon>
          <UserIconFilled />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </StyledListItem>
      <StyledListItem
        isOpen={isOpen}
        button
        key="Logout"
        onClick={handleLogout}
      >
        <ListItemIcon>
          <ExitToAppOutlined style={{ color: '#9FA2B4' }} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </StyledListItem>
    </StyledList>
  )
}
