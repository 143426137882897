import {
  Box,
  Link as MuiLink,
  Popper,
  PopperProps,
  TextField,
  Typography,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { throttle } from 'lodash'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useHistory } from 'react-router-dom'

import { usePartner } from '../../../hooks/usePartner'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { useSearchValues } from '../../../utils/api/search'
import {
  getInitialValuesWithType,
  LABELS_TYPES,
} from '../../../utils/helpers/common'
import {
  dashboardBookingDetailsPath,
  eventPath,
  marketplacePath,
  vendorPath,
  vendorsPath,
  venuePath,
} from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { HighlightedSearchQuery } from '../../HighlightedSearchQuery'

import {
  AutocompleteOption,
  StyledAutocomplete,
  StyledChip,
} from './GlobalSearch.styles'

const StyledPopper = (props: PopperProps) => {
  return (
    <Popper
      {...props}
      style={{
        width: 'fit-content',
        minWidth: '330px',
      }}
      placement="bottom-start"
    />
  )
}

export const GlobalSearch: React.FC<{
  setRenderSearch?: (renderSearch: boolean) => void
}> = ({ setRenderSearch }) => {
  const { authUser } = useAuthData()
  const { t } = useTranslation()
  const history = useHistory()
  const { isAbs } = usePartner()
  const [query, setQuery] = React.useState('')
  const { isMobile } = useWindowSize()
  const { isLoading, data } = useSearchValues(query, Boolean(authUser))
  const [searchValues, setSearchValues] = React.useState<any[]>([])
  const [selectedOption, setSelectedOption] = React.useState<
    AutocompleteOption | undefined
  >(undefined)

  const handleSearch = React.useCallback(
    throttle((query: string) => {
      setQuery(query)
    }, 200),
    []
  )

  const getRedirectUrl = (option: AutocompleteOption) => {
    switch (option.type) {
      case 'venues':
        return generatePath(venuePath, {
          slug: option.value,
        })
      case 'vendors':
        return generatePath(vendorPath, {
          slug: option.value,
        })
      case 'events':
        return option.value && authUser?.role
          ? generatePath(dashboardBookingDetailsPath, {
              id: option.value,
              listingId: option.businessId,
            })
          : option.value
          ? generatePath(eventPath, {
              id: option.value,
            })
          : ''
      case 'best used for':
      case 'amenities':
        return {
          pathname: generatePath(marketplacePath),
          search: `labels=${option.value}`,
        }
      case 'special features':
        return {
          pathname: generatePath(vendorsPath),
          search: `labels=${option.value}`,
        }
    }
  }

  const handleKeyPress = React.useCallback((event: any) => {
    setQuery(event.target.value)
  }, [])

  useEffect(() => {
    setSearchValues(getInitialValuesWithType(data, !!isAbs))
  }, [data])

  useEffect(() => {
    if (query && query.length > 3) {
      setSelectedOption(searchValues.find((option) => option.title === query))
    }
  }, [query])

  useEffect(() => {
    if (selectedOption) {
      // @ts-ignore
      history.push(getRedirectUrl(selectedOption))
    }
  }, [selectedOption])

  // @ts-ignore
  return (
    // @ts-ignore
    <StyledAutocomplete
      freeSolo={Boolean(query.length < 3)}
      disableClearable
      color="secondary"
      autoHighlight
      options={searchValues}
      renderTags={() => null}
      loading={isLoading}
      style={{ width: isMobile ? '100%' : 'inherit' }}
      loadingText={t('general.loading', 'Loading...')}
      // @ts-ignore
      getOptionLabel={(option) => (option.title ? option.title : '')}
      PopperComponent={StyledPopper}
      noOptionsText={!isLoading && t('search.noResults', 'No Results')}
      renderInput={(params: any) => (
        <TextField
          {...params}
          className="search"
          variant="standard"
          color="secondary"
          placeholder={
            isMobile ? t('global.searchPlaceholder', 'Type here') : ''
          }
          label={
            isMobile ? (
              <Box display="flex">
                <Typography fontWeight={500} fontSize={16} mr={2}>
                  {t('general.search', 'Search ')}
                </Typography>
                <Typography color="#9396A3">
                  {isAbs
                    ? t('partners.abs.searchHint', '(Vendors, Bookings)')
                    : t('general.searchHint', '(Venues, Vendors, Events)')}
                </Typography>
              </Box>
            ) : (
              ''
            )
          }
          fullWidth={isMobile}
          InputProps={{
            ...params.InputProps,
            className: 'contrast-text',
            autoFocus: Boolean(isMobile),
            disableUnderline: !isMobile,
            startAdornment: !isMobile && (
              <Search style={{ fontSize: 36, color: '#ffffff' }} />
            ),
            endAdornment: isMobile && (
              <Search style={{ fontSize: 36, color: '#ffffff' }} />
            ),
          }}
          onChange={(e) => handleSearch(e.target.value)}
          // @ts-ignore
          onSelect={handleKeyPress}
        />
      )}
      // @ts-ignore
      groupBy={(option) => String(option.type).toUpperCase()}
      renderOption={(props: any, option: any) => (
        // @ts-ignore
        <li
          {...props}
          onClick={() => isMobile && setRenderSearch && setRenderSearch(false)}
        >
          {/*@ts-ignore*/}
          <MuiLink
            component={Link}
            to={() => getRedirectUrl(option)}
            color="textPrimary"
            underline="none"
            width="100%"
          >
            {LABELS_TYPES.includes(option?.type) ? (
              <StyledChip style={{ cursor: 'pointer' }} label={option.title} />
            ) : (
              // @ts-ignore
              <HighlightedSearchQuery
                initialString={option.title}
                searchQuery={query}
                minQueryLength={3}
              />
            )}
          </MuiLink>
        </li>
      )}
    />
  )
}
