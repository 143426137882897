import { css } from '@emotion/react'
import { styled } from '@material-ui/core'
import MuiDialog from '@material-ui/core/Dialog'

import { Header } from './Header'

export type { DialogProps } from '@material-ui/core/Dialog'

const CustomDialog = styled(MuiDialog)(
  ({ theme }) => css`
    & .MuiDialog-paper {
      background-color: ${theme.palette.background.default};
    }
  `
)

export const Dialog = Object.assign(CustomDialog, {
  Header,
})
