import { Box, BoxProps, Link } from '@material-ui/core'
import * as React from 'react'
import { useMemo } from 'react'

import { CheckBoxField } from '../../../../../components/formik/CheckboxField'
import { FieldLabel } from '../VenuesFilter.styles'

interface LabelsSectionProps {
  allLabels: any[]
  title: string
}

export const LabelsSection: React.FC<LabelsSectionProps & BoxProps> = ({
  children,
  title,
  allLabels,
  ...boxProps
}) => {
  const [showAllLabels, setShowAllLabels] = React.useState<boolean>(false)

  const labels = useMemo(() => {
    if (showAllLabels) {
      return allLabels
    }

    return allLabels?.slice(0, 4) || []
  }, [showAllLabels, allLabels])

  return (
    <Box width="100%" display="flex" flexDirection="column" {...boxProps}>
      <Box display="flex" alignItems="baseline" alignContent="center">
        <FieldLabel variant="body2">{title}</FieldLabel>
        <Link
          sx={{ ml: 2, fontSize: '14px' }}
          underline="none"
          color="#4091F0"
          rel="noopener noreferrer"
          noWrap
          onClick={() => setShowAllLabels(!showAllLabels)}
        >
          <Box display="inline-flex" alignItems="center" mr={1}>
            <Box style={{ cursor: 'pointer' }} mr={1}>
              {!showAllLabels ? `Show all (${allLabels?.length})` : 'Hide all'}
            </Box>
          </Box>
        </Link>
      </Box>
      <Box display="grid" style={{ gridTemplateColumns: '1fr 1fr' }}>
        {labels.map((item: { name: string; slug: string }) => (
          <CheckBoxField
            key={item.slug}
            name={`label_${item.slug}`}
            label={item.name}
          />
        ))}
      </Box>
    </Box>
  )
}
