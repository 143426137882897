import { Formik } from 'formik'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { v4 as uuid } from 'uuid'

import { EventDraftStatus } from '../../../../../../constants'
import { InquiryStatuses } from '../../../../../../constants/inquiry'
import { useCreateOrDuplicateEvent } from '../../../../../../hooks/useCreateOrDuplicateEvent'
import { InquiryDetailFormValues } from '../../../../../../types/Inquiry'
import { Room } from '../../../../../../types/Venue'
import { updateEventInquiries } from '../../../../../../utils/api/marketplace'
import { getDateFromString } from '../../../../../../utils/helpers/date'
import { getRequestError } from '../../../../../../utils/helpers/validations'
import {
  convertFormValuesToJSON,
  convertInquiryEventToUserEvent,
  createInquiryMapper,
  generateEventFormInitialValues,
  prepareInquiryData,
} from '../../../../../../utils/mappers/inquiries'
import { inquiryPath } from '../../../../../../utils/paths'
import { useAuthData } from '../../../../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../../../../utils/providers/EventsProvider'
import { useMessageModalData } from '../../../../../../utils/providers/MessageModalProvider'
import {
  LsGetInquiryDetails,
  LsSetInquiryDetails,
  LsSetInquiryFormValues,
} from '../../../../../../utils/storage'
import tracking from '../../../../../../utils/tracking'

import {
  FormContainer,
  ScrollContainer,
  SectionHeader,
} from './ABSInquiryCreate.styles'
import { StepsConstructor } from './components/StepsConstructor'
import { InquirySchema } from './components/validationSchema'

type InquiryCreateFormProps = {
  onClose: () => void
  venue: any
  step?: number
  isEdit?: boolean
  isDuplicate?: boolean
  eventFromId?: number
  rooms?: Room[]
}

export const AbsInquiryCreateForm = ({
  onClose,
  venue,
  isEdit,
  isDuplicate,
  eventFromId,
  rooms,
  step = 0,
}: InquiryCreateFormProps) => {
  const { authUser } = useAuthData()
  const {
    updateUserActiveEvent,
    getUserActiveEvent,
    isLoading,
    setIsLoading,
    chosenAbsVenue,
  } = useStorageEventsData()
  const { showMessage } = useMessageModalData()
  const history = useHistory()
  const userEvent = getUserActiveEvent()
  const { t } = useTranslation()
  const { handleCreateOrDuplicateEvent } = useCreateOrDuplicateEvent()

  const initialValues: InquiryDetailFormValues = generateEventFormInitialValues(
    { isAbs: true }
  )

  const [formValues, setFormValues] =
    React.useState<InquiryDetailFormValues>(initialValues)
  const [copyQuestionaries, setCopyQuestionaries] =
    React.useState<boolean>(false)

  const maxGuests = useMemo(() => {
    if (rooms) {
      const roomsCapacity = rooms.reduce((prev, cur) => prev + cur.capacity, 0)
      return roomsCapacity || undefined
    }

    return venue?.aboutInfo ? venue?.aboutInfo?.maxGuests : undefined
  }, [rooms, venue?.aboutInfo?.maxGuests])

  const prepareDraftEvent = (inquiryDetails: any) => {
    setIsLoading(true)

    delete inquiryDetails.vendors
    delete inquiryDetails.client_venue

    inquiryDetails.start_date = getDateFromString(inquiryDetails.start_date)
    inquiryDetails.end_date = getDateFromString(inquiryDetails.end_date)
    inquiryDetails.load_in_end_date = getDateFromString(
      inquiryDetails.load_in_end_date
    )
    inquiryDetails.load_in_start_date = getDateFromString(
      inquiryDetails.load_in_start_date
    )
    inquiryDetails.load_out_end_date = getDateFromString(
      inquiryDetails.load_out_end_date
    )
    inquiryDetails.load_out_start_date = getDateFromString(
      inquiryDetails.load_out_start_date
    )

    const eventVenue = chosenAbsVenue

    updateUserActiveEvent({
      ...inquiryDetails,
      id: uuid(),
      name: formValues.name,
      venue: { ...eventVenue, rooms },
    })
    tracking.createdEvent({
      ...inquiryDetails,
      id: uuid(),
      name: formValues.name,
      venue: { ...eventVenue, rooms },
    })
    setIsLoading(false)
  }

  const saveInquiryToLocalStorage = (inquiryDetails: any) => {
    const convertedValues = convertFormValuesToJSON(formValues)

    LsSetInquiryFormValues(convertedValues)
    !isDuplicate
      ? LsSetInquiryDetails({
          ...inquiryDetails,
          rooms,
          venue,
        })
      : LsSetInquiryDetails({
          ...inquiryDetails,
          rooms,
          venue,
          original_event_id: eventFromId,
          copy_questionary: +copyQuestionaries,
        })
  }

  const updateEvent = async (inquiryDetails: any, withRequest: boolean) => {
    setIsLoading(true)
    const inquiryData = prepareInquiryData(userEvent, inquiryDetails)

    try {
      const data = await updateEventInquiries({
        ...inquiryData,
        id: userEvent?.id,
        status: userEvent?.status,
      })
      const event = convertInquiryEventToUserEvent({
        ...data,
        inquiry: data.inquiries_related[0],
      })

      updateUserActiveEvent({ changed: false, ...event })
      {
        if (withRequest) {
          showMessage({
            title: t(
              'messages.inquiry.request.sent',
              'Your request has been successfully sent!'
            ),
          })
        } else {
          showMessage({
            title: t(
              'bookings.update',
              'Your booking details have been successfully updated!'
            ),
          })
        }
      }
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    }
    setIsLoading(false)
  }

  const saveInquiry = async () => {
    if (!formValues.isMultipleDates) {
      delete formValues.loadInDates
      delete formValues.loadOutDates
    }
    const inquiryDetails = createInquiryMapper(
      formValues,
      authUser,
      venue
    ) as any

    const vendors = userEvent?.vendors || []

    const inquiryHasAcceptedVendors = vendors.some(
      (vendor: any) =>
        vendor.status !== InquiryStatuses.STATUS_DRAFT &&
        vendor.status !== InquiryStatuses.STATUS_PENDING_SUBMIT &&
        vendor.status !== InquiryStatuses.STATUS_INQUIRING
    )
    const inquiryHasAcceptedVenue =
      inquiryDetails.venue?.status !== InquiryStatuses.STATUS_DRAFT &&
      inquiryDetails.venue?.status !== InquiryStatuses.STATUS_PENDING_SUBMIT &&
      inquiryDetails.venue?.status !== InquiryStatuses.STATUS_INQUIRING
    const shouldCreateUpdateRequest =
      inquiryHasAcceptedVenue || inquiryHasAcceptedVendors
    saveInquiryToLocalStorage(inquiryDetails)
    if (!isEdit || userEvent?.status === EventDraftStatus) {
      prepareDraftEvent(inquiryDetails)
      if (!inquiryDetails.original_event_id) {
        console.log('is', LsGetInquiryDetails())
        const inquiryData = prepareInquiryData(userEvent, LsGetInquiryDetails())
        await handleCreateOrDuplicateEvent(inquiryData)
      } else {
        history.push(inquiryPath)
      }
    } else {
      if (shouldCreateUpdateRequest) {
        showMessage({
          title: t(
            'messages.inquiry.request.update',
            'Update your event details?'
          ),
          text: t(
            'messages.inquiry.request.updateSend',
            'If you click "Yes" your change request will be submitted for review. You will be contacted if there are any issues with your changes.'
          ),
          onAccept: () => updateEvent(inquiryDetails, true),
        })
      } else {
        await updateEvent(inquiryDetails, false)
      }
    }
    onClose()
    setIsLoading(false)
  }

  console.log(t('bookings.details', 'Booking details'))

  return (
    <Formik
      validationSchema={InquirySchema({ maxGuests })}
      initialValues={initialValues}
      onSubmit={(values) => setFormValues(values)}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
    >
      {({ handleSubmit, values }) => {
        setFormValues(values)
        return (
          <FormContainer onSubmit={handleSubmit}>
            <ScrollContainer display="flex" flexDirection="column" width="100%">
              <SectionHeader variant="h3" mb={isDuplicate ? 0 : '20px'}>
                {isDuplicate
                  ? t('events.duplicate', 'Duplicate event')
                  : t('bookings.details', 'Booking details')}
              </SectionHeader>
              <StepsConstructor
                isEdit={isEdit}
                maxGuests={maxGuests}
                currentStepIndex={step}
                isLoading={isLoading}
                isDuplicate={isDuplicate}
                copyQuestionaries={copyQuestionaries}
                handleCopyQuestionaries={(copy: boolean) =>
                  setCopyQuestionaries(copy)
                }
                onSubmit={saveInquiry}
              />
            </ScrollContainer>
          </FormContainer>
        )
      }}
    </Formik>
  )
}
