import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderCell } from '../LineItemsAndPricing.styles'

export const LineItemsHeader = () => {
  const { t } = useTranslation()

  return (
    <>
      <HeaderCell>
        <Typography color="#BDBDBD" variant="body2">
          {t('business.lineItems.number2', '#')}
        </Typography>
      </HeaderCell>
      <HeaderCell>
        <Typography color="#BDBDBD" variant="body2">
          {t('business.lineItems.name', 'Name')}
        </Typography>
      </HeaderCell>
      <HeaderCell>
        <Typography color="#BDBDBD" variant="body2">
          {t('business.lineItems.type', 'Type')}
        </Typography>
      </HeaderCell>
    </>
  )
}
