const FloorNumber = () => {
  return (
    <svg width="17" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.339.093a.667.667 0 0 0-.678 0L.328 4.427a.667.667 0 0 0 0 1.146L7.66 9.907a.667.667 0 0 0 .678 0l7.333-4.334a.667.667 0 0 0 0-1.146L8.339.093Zm-.34 8.467L1.978 5 8 1.44 14.023 5 8 8.56Zm-7.906.435a.667.667 0 0 1 .912-.235L8 12.893l6.995-4.133a.667.667 0 1 1 .677 1.147L8.339 14.24a.667.667 0 0 1-.678 0L.328 9.907a.667.667 0 0 1-.236-.912h.001Zm0 4a.666.666 0 0 1 .912-.236L8 16.892l6.995-4.133a.667.667 0 0 1 .677 1.148L8.339 18.24a.667.667 0 0 1-.678 0L.328 13.907a.666.666 0 0 1-.236-.912h.001Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default FloorNumber
