import { Box, Typography } from '@material-ui/core'
import { keys, values } from 'lodash'
import React from 'react'

import { JotFormSubmission } from '../../../../../../types/Event'

export const JotFormInformation: React.FC<{ event: any; jotForms: any }> = ({
  event,
  jotForms,
}) => {
  return (
    <>
      {!!jotForms?.length &&
        jotForms.map((jotFormGroup: JotFormSubmission[], i: number) => (
          <Box mb={3} key={i}>
            {jotFormGroup
              .filter((item) => {
                if (
                  item.title !== 'blaceEnv' &&
                  item.title !== 'blaceEventId' &&
                  item.answer
                ) {
                  return item
                }
              })
              .sort((a, b) => Number(a.order) - Number(b.order))
              .map((jotForm) => (
                <Box key={jotForm.id} width={1}>
                  <Box display="flex" alignItems="center">
                    <Box
                      minWidth="12px"
                      mr={2}
                      minHeight="12px"
                      sx={{ backgroundColor: '#597EF7', borderRadius: '1px' }}
                    />
                    <Typography
                      sx={{ marginTop: '10px', marginBottom: '10px' }}
                    >
                      {jotForm.title}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Box display="flex" flexDirection="column">
                      {typeof jotForm.answer !== 'string' ? (
                        keys(jotForm.answer).map((question, index) =>
                          !Number(question) && Number(question) !== 0 ? (
                            <Typography
                              sx={{ marginRight: '20px' }}
                              key={index}
                            >
                              {question}
                            </Typography>
                          ) : null
                        )
                      ) : (
                        <Typography color="#9396A3" fontSize={14}>
                          {jotForm.answer}
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex" flexDirection="column">
                      {typeof jotForm.answer !== 'string' &&
                        values(jotForm.answer).map((answer, i) => (
                          <Typography color="#9396A3" fontSize={14} key={i}>
                            {answer}
                          </Typography>
                        ))}
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        ))}
    </>
  )
}
