function getConfigOption(name: string): string
function getConfigOption(name: string, defaultValue: null): string | null
function getConfigOption(name: string, defaultValue: string): string

function getConfigOption(name: string, defaultValue?: string | null) {
  const value = process.env[`REACT_APP_${name}`]

  if (value !== undefined) return value

  if (defaultValue !== undefined) return defaultValue

  throw new Error(`Missing configuration REACT_APP_${name}`)
}

function isEnvVarTrue(str: string) {
  return str === '1' || str === 'true' || str === 'enabled'
}

export const rootUrl = () => getConfigOption('ROOT_URL')
export const apiUrl = () => getConfigOption('API_URL')

const flodeskId = getConfigOption('FLODESK_ID', null)
const gtmId = getConfigOption('GTM_ID', null)
const gtmEnv = getConfigOption('GTM_ENV', null)
const gtmAuth = getConfigOption('GTM_AUTH', null)
const gaId = getConfigOption('GA_ID', null)
const heapId = getConfigOption('HEAP_ID', null)
const facebookId = getConfigOption('FACEBOOK_PIXEL_ID', null)
const linkedinId = getConfigOption('LINKEDIN_PARTNER_ID', null)
const venuesEmail = getConfigOption('VENUES_EMAIL', 'venues@blace.com')
const cookieDomain = getConfigOption('COOKIE_DOMAIN', '.blace.com')

const mswEnabled = isEnvVarTrue(getConfigOption('MSW_ENABLED', 'false'))

const config = {
  msw: {
    enabled: mswEnabled,
  },
  flodesk: {
    id: flodeskId,
  },
  tracking: {
    gtm: {
      id: gtmId,
      env: gtmEnv,
      auth: gtmAuth,
    },
    ga: {
      id: gaId,
    },
    heap: {
      id: heapId,
    },
    facebook: {
      id: facebookId,
    },
    linkedin: {
      id: linkedinId,
    },
  },
  venuesEmail,
  cookieDomain,
}

export default config
