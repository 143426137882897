import { Box, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AchPay,
  CreditCardInput,
  SquarePaymentsForm,
} from 'react-square-web-payments-sdk'

import { UserRoles } from '../../constants/profile'
import { useInvoiceModalData } from '../../hooks/useInvoiceModalData'
import { useWindowSize } from '../../hooks/useWindowSize'
import { GroupLineItemBundle, LineItemBundle } from '../../types/dtos/inquiry'
import { createInvoicePayment } from '../../utils/api/inquiries'
import { getRequestError } from '../../utils/helpers/validations'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { useMessageModalData } from '../../utils/providers/MessageModalProvider'
import tracking from '../../utils/tracking'
import ArrowBack from '../svg/ArrowBack'
import GradientButton from '../ui/Buttons/GradientButton/GradientButton'
import { TextInput } from '../ui/Inputs/TextInput'
import { Loader } from '../ui/Loader'
import { SimpleModalWrapper } from '../ui/Modals/SimpleModal/SimpleModal.styles'

import {
  Footer,
  ModalContentWrapper,
  StyledBox,
} from './InvoiceDetailsModal.styles'
import { InvoiceModalContent } from './InvoiceModalContent'

export interface InvoiceModalData {
  id?: number
  dueDate?: Date
  creationDate: Date
  businessType: string
  businessName: string
  businessAddress1?: string
  businessAddress2?: string
  businessPhone?: string
  businessMail?: string
  groupedLineItems: GroupLineItemBundle[]
  lineItemsAll: LineItemBundle[]
  message: string
  extra: string
  securityDeposit?: { price?: number; clarification?: string }
  paymentPercent?: number
  total?: number
  type?: string
}

interface InvoiceModalModalProps {
  isOpen: boolean
  onClose: () => void
  invoice: InvoiceModalData | null
  actionText: string
  onSuccessPayment?: () => void
  onAction?: () => void
  withFooter?: boolean
  availablePaymentMethod?: string
  isPaymentAvailable?: boolean
}

const invoiceData = {
  dueDate: new Date(),
  creationDate: new Date(),
  groupedLineItems: [],
  lineItemsAll: [],
  message: '',
  extra: '',
  type: '',
  securityDeposit: { price: 0, clarification: '' },
  businessType: '',
  businessName: '',
  businessAddress1: '',
  businessAddress2: '',
  businessPhone: '',
  businessMail: '',
  paymentPercent: 0,
} as InvoiceModalData

export const InvoiceDetailsModal: React.FC<InvoiceModalModalProps> = ({
  isOpen,
  actionText,
  onAction,
  onClose,
  onSuccessPayment,
  withFooter = true,
  invoice = invoiceData,
  availablePaymentMethod = 'both',
  isPaymentAvailable,
}) => {
  const { role } = useAuthData()
  const { showMessage } = useMessageModalData()
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  const [openPayment, setOpenPayment] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ACHName, setACHName] = useState('')
  const [ACHError, setACHError] = useState('')
  const withPayment = UserRoles.isClient(role)

  const { groupsAmounts, amount, paymentPercentAmount, totalAmount } =
    useInvoiceModalData(invoice)

  const handleAction = () => {
    if (onAction) {
      onAction()
    } else {
      onClose()
    }
  }

  const makePayment = () => {
    if (isPaymentAvailable) {
      setOpenPayment(true)
    } else {
      showMessage({
        title: t(
          'inquiry.payment.signContractBefore',
          'You must sign your contract before you can make a payment'
        ),
        type: 'error',
      })
    }
  }

  const AHCField = (
    <>
      <TextInput
        style={{ width: '100%', marginBottom: !!ACHError ? 17 : 40 }}
        placeholder={t('inquiry.payment.cardHolderName', 'Card holder name')}
        error={!!ACHError}
        helperText={ACHError}
        value={ACHName}
        onChange={(event) => {
          const val = event.target.value
          if (val) {
            setACHError('')
          }
          setACHName(event.target.value)
        }}
      />
      <div
        onClick={(e) => {
          if (!ACHName) {
            e.preventDefault()
            e.stopPropagation()
            setACHError(
              t(
                'messages.payment.emptyCardHolder',
                'Card holder name is required for ACH!'
              )
            )
          }
        }}
      >
        <AchPay accountHolderName={ACHName} />
      </div>
    </>
  )

  const showPaymentMethods = () => {
    switch (availablePaymentMethod) {
      case 'both':
        return (
          <>
            {AHCField}
            <Box display="flex" justifyContent="center" mb={4} mt={4}>
              <Typography fontWeight={600}>OR</Typography>
            </Box>
            <CreditCardInput text="Pay with card" />
          </>
        )
      case 'cc':
        return <CreditCardInput text="Pay with card" />
      case 'ach':
        return AHCField
    }
  }

  return (
    invoice && (
      <>
        <SimpleModalWrapper
          open={isOpen}
          onClose={onClose}
          BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.4)' } }}
        >
          <ModalContentWrapper>
            <Box className="container">
              <Box
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: isMobile ? 24 : 30,
                  top: isMobile ? 24 : 30,
                  zIndex: 5,
                }}
                onClick={onClose}
              >
                <Close style={{ fontSize: isMobile ? 24 : 35 }} />
              </Box>
              <InvoiceModalContent
                invoice={invoice}
                lineItemsGroupsAmounts={groupsAmounts}
                amount={amount}
                paymentPercentAmount={paymentPercentAmount}
                totalAmount={totalAmount}
              />
              {withFooter && (
                <Footer>
                  <Box
                    className="container"
                    height={1}
                    width={1}
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                  >
                    {!withPayment && (
                      <StyledBox
                        display="flex"
                        flex={1}
                        alignItems="center"
                        onClick={onClose}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ '&:hover': { opacity: 0.8 } }}
                        >
                          <ArrowBack />
                          <Typography sx={{ marginLeft: '5px' }}>
                            {t('business.invoices.back', 'Back to editing')}
                          </Typography>
                        </Box>
                      </StyledBox>
                    )}
                    <Box
                      alignItems="center"
                      justifyContent={isMobile ? 'space-between' : 'flex-end'}
                      display="flex"
                      flex={1}
                    >
                      <Box mr={isMobile ? 4 : 6}>
                        <Typography variant="body2">
                          <b>{totalAmount}</b> /{' '}
                          {t('business.invoices.total', 'Invoice total')}
                        </Typography>
                      </Box>
                      {withPayment ? (
                        <Box width={isMobile ? '50%' : 'unset'}>
                          <GradientButton onClick={makePayment}>
                            {actionText}
                          </GradientButton>
                        </Box>
                      ) : (
                        <Box width={isMobile ? '50%' : 'unset'}>
                          <GradientButton onClick={handleAction}>
                            {actionText}
                          </GradientButton>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Footer>
              )}
            </Box>
          </ModalContentWrapper>
        </SimpleModalWrapper>
        <SimpleModalWrapper
          open={openPayment}
          onClose={() => setOpenPayment(false)}
        >
          <Box style={{ background: '#fff', padding: 25, width: 350 }}>
            {/*@ts-ignore*/}
            <SquarePaymentsForm
              applicationId={process.env.REACT_APP_SQUARE_APP_ID || ''}
              locationId={process.env.REACT_APP_SQUARE_LOCATION || ''}
              cardTokenizeResponseReceived={async (
                response: any,
                buyer: any
              ) => {
                if (response.token && invoice.id) {
                  setIsLoading(true)
                  try {
                    await createInvoicePayment(invoice.id, {
                      token: response.token,
                    })

                    setOpenPayment(false)
                    onClose()
                    if (onSuccessPayment) {
                      tracking.paidInvoice(invoice)
                      onSuccessPayment()
                    }
                    showMessage({
                      title: t(
                        'messages.payment.accepted',
                        'Payment has been successfully accepted!'
                      ),
                    })
                  } catch (e) {
                    showMessage({
                      title: getRequestError(e),
                      type: 'error',
                    })
                  }
                  setIsLoading(false)
                }
              }}
            >
              {showPaymentMethods()}
              {isLoading && <Loader position="fixed" />}
            </SquarePaymentsForm>
          </Box>
        </SimpleModalWrapper>
      </>
    )
  )
}
