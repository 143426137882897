export const formatToCurrency = (
  value?: string | number | null,
  isFloat = true
) => {
  let amount = value || 0

  if (typeof amount === 'string' && amount[0] === '$') {
    return amount
  }

  if (typeof amount === 'number') {
    amount = isFloat ? amount.toFixed(2) : amount.toString()
  }

  return '$' + amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
}

export const currencyConvertToDecimal = (value: string | number) => {
  if (typeof value === 'string') {
    value = value.replace(/\$|,/g, '')

    return parseFloat(value) || 0
  }
  return value
}

export const getPercentFromPrice = (
  price: number | string,
  percent: number
) => {
  if (typeof price === 'string') {
    price = currencyConvertToDecimal(price)
  }
  const value = (percent / 100) * price

  return parseFloat(value.toFixed(2))
}

export const percentSubstraction = (
  price: number | string,
  percent: number
) => {
  if (typeof price === 'string') {
    price = currencyConvertToDecimal(price)
  }

  return price - getPercentFromPrice(price, percent)
}
