const LogoutIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3c-4.963 0-9 4.038-9 9.001l5-4v3h7v2H8v3l-5-4C3.001 16.964 7.037 21 12 21s9-4.037 9-9-4.037-9-9-9Z"
        fill="#D7D7D7"
      />
    </svg>
  )
}

export default LogoutIcon
