import { Box, styled, Typography } from '@material-ui/core'
import * as React from 'react'

const InfoBoxWrapper = styled(Box)`
  color: black;

  .MuiTypography-root {
    margin-left: 10px;
  }
`

export const InfoBox: React.FC<{
  icon: React.ReactElement
  text: string
  color?: string
  mr?: number | string
}> = ({ icon, text, color, mr = '24px' }) => {
  return (
    <InfoBoxWrapper display="flex" alignItems="center" mr={mr}>
      <Box>{icon}</Box>
      <Typography
        fontSize={16}
        variant="body1"
        color={color ? color : 'textPrimary'}
      >
        {text}
      </Typography>
    </InfoBoxWrapper>
  )
}
