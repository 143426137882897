import { css } from '@emotion/react'
import { Button, alpha, styled } from '@material-ui/core'
import { spacing } from '@material-ui/system'

export const TertiaryButton = styled(Button)(
  ({ theme }) => css`
    padding: 8px 16px;

    text-transform: none;

    background: ${alpha(theme.palette.primary.light, 0.07)};
    border-radius: 4px;
  `,
  spacing
)
