import { generatePath } from 'react-router-dom'

import Contacts from '../components/svg/dashboard/Contacts'
import Description from '../components/svg/dashboard/Description'
import Details from '../components/svg/dashboard/Details'
import LineItems from '../components/svg/dashboard/LineItems'
import Picture from '../components/svg/dashboard/Picture'
import Rooms from '../components/svg/dashboard/Rooms'
import { SideMenuItem } from '../components/ui/SideMenu'
import {
  dashboardBusinessContactsPath,
  dashboardBusinessDescriptionDetailsPath,
  dashboardBusinessDescriptionPath,
  dashboardBusinessDetailsPath,
  dashboardBusinessLineItemsPath,
  dashboardBusinessRoomsPath,
} from '../utils/paths'

export const getLandlordBusinessDetailsMenu = (
  listingId: string
): SideMenuItem[] => {
  return [
    {
      name: 'Name & Photo',
      path: generatePath(dashboardBusinessDetailsPath, { listingId }),
      icon: <Picture />,
    },
    {
      name: 'Description',
      path: generatePath(dashboardBusinessDescriptionPath, { listingId }),
      icon: <Description />,
    },
    {
      name: 'Details',
      path: generatePath(dashboardBusinessDescriptionDetailsPath, {
        listingId,
      }),
      icon: <Details />,
    },
    {
      name: 'Rooms',
      path: generatePath(dashboardBusinessRoomsPath, { listingId }),
      icon: <Rooms />,
    },
    {
      name: 'Pricing & Line items',
      path: generatePath(dashboardBusinessLineItemsPath, { listingId }),
      icon: <LineItems />,
    },
    {
      name: 'Business contacts',
      path: generatePath(dashboardBusinessContactsPath, { listingId }),
      icon: <Contacts />,
    },
  ]
}

export const getVendorBusinessDetailsMenu = (
  listingId: string
): SideMenuItem[] => {
  return [
    {
      name: 'Name & Photo',
      path: generatePath(dashboardBusinessDetailsPath, { listingId }),
      icon: <Picture />,
    },
    {
      name: 'Description',
      path: generatePath(dashboardBusinessDescriptionPath, { listingId }),
      icon: <Description />,
    },
    {
      name: 'Pricing & Line items',
      path: generatePath(dashboardBusinessLineItemsPath, { listingId }),
      icon: <LineItems />,
    },
    {
      name: 'Business contacts',
      path: generatePath(dashboardBusinessContactsPath, { listingId }),
      icon: <Contacts />,
    },
  ]
}

export const EMPTY_LINE_ITEM = {
  value: '',
  quantity: 1,
  price: undefined,
  clarification: '',
  groupName: '',
  groupDescription: '',
}
