import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { Trans } from 'react-i18next'
// @ts-ignore
import ImageGallery from 'react-image-gallery'

import img from '../../assets/images/no-venues-images.png'
import { useWindowSize } from '../../hooks/useWindowSize'
import { GalleryImage, ImageItem } from '../../types/general'
import { PromotionLabel } from '../cards/labels/PromotionLabel'
import { ImageGalleryComponent } from '../ImageGallery'
import { Image } from '../ui/Image'

import {
  GalleryContainer,
  NoPhotoContainer,
  StyledBackground,
  StyledGalleryContainer,
  StyledImageContainer,
  StyledImg,
  StyledNumberOfImages,
} from './GalleryForEntities.styles'
// @ts-ignore

export interface GalleryPhotos {
  firstPhoto?: ImageItem
  secondPhoto?: ImageItem
  thirdPhoto?: ImageItem
}

interface GalleryForEntitiesProps {
  photos?: GalleryPhotos
  entity: string
  vendorCategory?: string
  galleryImages?: GalleryImage[]
  showGallery: boolean
  setShowGallery: (showGallery: boolean) => void
  isPromotion?: boolean
}

export const FullGallery = ({
  photos,
  entity,
  galleryImages = [],
  showGallery,
  setShowGallery,
  vendorCategory,
  isPromotion,
}: GalleryForEntitiesProps) => {
  const { isMobile } = useWindowSize()

  return (
    <StyledGalleryContainer
      className={isMobile ? '' : 'container'}
      id="event-photos"
      flexDirection="row"
      display="flex"
    >
      {photos &&
      (Object.values(photos).filter((value) => !!value).length ||
        entity === 'vendors') ? (
        <>
          <Box
            sx={{
              position: 'relative',
              width:
                (!!photos.secondPhoto || entity === 'vendors') && !isMobile
                  ? '70%'
                  : '100%',
              marginRight:
                (!!photos.secondPhoto || entity === 'vendors') && !isMobile
                  ? '24px'
                  : 0,
              '&:hover': { cursor: 'pointer', opacity: 0.95 },
            }}
          >
            {isPromotion && <PromotionLabel />}
            <Image
              image={photos.firstPhoto}
              imageSize="original"
              imageMobileSize="medium"
              staticImageType={entity === 'vendors' ? 'vendor' : undefined}
              vendorCategory={entity === 'vendor' ? vendorCategory : undefined}
              onClick={() => setShowGallery(true)}
              style={{ height: isMobile ? 318 : 624 }}
            />
          </Box>
          {(!!photos.secondPhoto || entity === 'vendors') && !isMobile && (
            <Box
              flexDirection="column"
              width="30%"
              justifyContent="flex-end"
              onClick={() => setShowGallery(true)}
            >
              <Box sx={{ '&:hover': { cursor: 'pointer', opacity: 0.95 } }}>
                <Image
                  image={photos.secondPhoto}
                  imageSize="medium"
                  staticImageType={entity === 'vendors' ? 'gallery' : undefined}
                  vendorCategory={
                    entity === 'vendors' ? vendorCategory : undefined
                  }
                  style={{ height: 300, marginBottom: 20 }}
                />
              </Box>
              {!!photos.thirdPhoto || entity === 'vendors' ? (
                <StyledImageContainer>
                  <Image
                    image={photos.thirdPhoto}
                    imageSize="medium"
                    staticImageType={
                      entity === 'vendors' ? 'galleryTwo' : undefined
                    }
                    vendorCategory={
                      entity === 'vendors' ? vendorCategory : undefined
                    }
                    style={{ height: 300 }}
                  />
                  <StyledBackground />
                  <StyledNumberOfImages color="secondary">
                    {galleryImages.length && galleryImages.length > 3
                      ? `+ ${galleryImages.length - 3}`
                      : null}
                  </StyledNumberOfImages>
                </StyledImageContainer>
              ) : (
                <Box
                  sx={{
                    bgcolor: '#F7F7F8',
                    '&:hover': { cursor: 'pointer', opacity: 0.95 },
                  }}
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  alignContent="center"
                >
                  <Trans i18nKey="gallery.noMorePhoto">
                    <Typography sx={{ mt: 10 }} align="center" color="#9396A3">
                      There is no more photo of {{ entity }} yet.
                    </Typography>
                  </Trans>
                  <StyledImg height={230} src={img} alt="" />
                </Box>
              )}
            </Box>
          )}
        </>
      ) : (
        <NoPhotoContainer>
          <Trans i18nKey="gallery.noPhoto">
            <Typography sx={{ mt: 15 }} align="center" color="#9396A3">
              There is no photo of {{ entity }} yet.
            </Typography>
          </Trans>
          <img height={250} src={img} alt="" />
        </NoPhotoContainer>
      )}
      {showGallery && galleryImages && !!galleryImages.length && (
        <GalleryContainer>
          <ImageGalleryComponent
            images={galleryImages}
            onClose={() => setShowGallery(false)}
          />
        </GalleryContainer>
      )}
    </StyledGalleryContainer>
  )
}

const SwipableGallery = ({ galleryImages = [] }: GalleryForEntitiesProps) => {
  return (
    <ImageGallery
      additionalClass="mobile-gallery-content"
      showThumbnails={false}
      items={galleryImages}
      showPlayButton={false}
      showIndex={true}
    />
  )
}

export const GalleryForEntities = (props: GalleryForEntitiesProps) => {
  const { isMobile, isPortrait } = useWindowSize()

  if (isMobile && isPortrait) return <SwipableGallery {...props} />
  return <FullGallery {...props} />
}
