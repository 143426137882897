import { Box, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import * as React from 'react'

import GradientButton from '../../../../components/ui/Buttons/GradientButton'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { StyledAlert } from '../Events.styles'

export const ContinuePlanningAlert: React.FC<{
  actionText?: string
  title: string
  subtitle?: string
  handleGoToEdit?: () => void
}> = ({ handleGoToEdit, actionText, title, subtitle }) => {
  const { isMobile } = useWindowSize()

  return (
    <StyledAlert
      icon={<ErrorOutline />}
      action={
        actionText ? (
          <GradientButton
            onClick={handleGoToEdit}
            style={{
              fontSize: 12,
              height: 'fit-content',
              paddingTop: 6,
              paddingBottom: 6,
            }}
          >
            {actionText}
          </GradientButton>
        ) : (
          <></>
        )
      }
      severity="warning"
    >
      <Box>
        <Typography
          variant="body2"
          color="#F3BD00"
          textAlign={isMobile ? 'center' : 'left'}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            color="#9396A3"
            variant="body2"
            textAlign={isMobile ? 'center' : 'left'}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </StyledAlert>
  )
}
