import * as React from 'react'

import { useInquiryById } from '../../hooks/useInquiryById'
import { InquiryWithDetails } from '../../types/Inquiry'

export interface InquiryDetailsContextProps {
  loading: boolean
  inquiryDetails?: InquiryWithDetails
  updateInquiryDetails?: (details: Partial<InquiryWithDetails>) => void
  refetch: () => void
}

export const InquiryDetailsDataContext =
  React.createContext<InquiryDetailsContextProps>({
    refetch: () => {},
    inquiryDetails: undefined,
    loading: false,
    updateInquiryDetails: () => {},
  })

export const InquiryDetailsProvider: React.FC<{ id: string }> = ({
  children,
  id,
}) => {
  const { loading, inquiryDetails, updateInquiryDetails, refetch } =
    useInquiryById(id)
  const context = React.useMemo(
    () => ({
      loading,
      inquiryDetails,
      refetch,
      updateInquiryDetails,
    }),
    [loading, inquiryDetails, refetch]
  )

  return (
    <InquiryDetailsDataContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </InquiryDetailsDataContext.Provider>
  )
}

export const useInquiryDetailsData = () => {
  const context = React.useContext(InquiryDetailsDataContext)
  if (context === undefined) {
    throw new Error(
      'useInquiryDetailsData must be used within an InquiryDetailsProvider'
    )
  }
  return context
}
