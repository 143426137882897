import { Box } from '@material-ui/core'
import * as React from 'react'

import { BottomModal } from '../../../../components/ui/Modals/BottomModal'
import { EventVenue } from '../../../../types/Event'
import { useInquiryModalControl } from '../../../../utils/providers/InquiryModalControlProvider'
import { VendorDetails } from '../../../vendors/Vendor'
import { Venue } from '../../../venues/Venue'

import { CategoryVendorsList } from './CategoryVendorsList'
import { ChangeVenueList } from './ChangeVenueList'

export interface VendorsListQueryParams {
  type?: string
  venue_id?: number
  name?: string
  orderBy?: string
  orderDirection?: string
  labels?: string
  order_price_min?: string
  page?: number | string
}

export const InquiryBottomModal = () => {
  const { isOpen, closeModal, category, vendor, venuesListParams, venueId } =
    useInquiryModalControl()

  const renderModalContent = () => {
    if (venueId) {
      return <Venue venueId={venueId} />
    }

    if (venuesListParams) {
      return <ChangeVenueList initialParams={venuesListParams} />
    }

    if (vendor) {
      return (
        <VendorDetails
          vendorId={vendor.id.toString()}
          onSelectComplete={closeModal}
          isExclusive={vendor.exclusive}
        />
      )
    }

    return <CategoryVendorsList category={category} />
  }

  return (
    <BottomModal open={isOpen} onClose={closeModal}>
      <Box display="block">{renderModalContent()}</Box>
    </BottomModal>
  )
}
