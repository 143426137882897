import { BusinessType, UserRoles } from 'constants/profile'

import { isNil } from 'lodash'
import React from 'react'
import { BusinessGeneralInfo } from 'types/general'
import { Vendor } from 'types/Vendor'
import { VenueDetails } from 'types/Venue'
import { useLineItems, useLineItemsTypes } from 'utils/api/lineItems'
import { useAuthData } from 'utils/providers/AuthProvider'

import { BlockContainer } from '../../BusinessDetails.styles'

import { Pricing } from './components/Pricing'
import { LineItems } from './LineItems'

interface LineItemsAndPricingProps {
  businessDetails: VenueDetails | Vendor | null
  onPriceSave: (data: Partial<BusinessGeneralInfo>) => void
  onSave?: (data: Partial<BusinessGeneralInfo>) => void
  orderPrice?: string | number
  pricingDetails?: string
  shortPricingDescription?: {
    value: string
    label: string
  }
  displayPricing?: boolean
}

export const LineItemsAndPricing: React.FC<LineItemsAndPricingProps> = ({
  businessDetails,
  onPriceSave,
  orderPrice,
  pricingDetails,
  shortPricingDescription,
  displayPricing,
}) => {
  const { authUser } = useAuthData()
  const lineItemParams = {
    id: authUser?.listing_id!,
    businessType: UserRoles.isLandlord(authUser?.role)
      ? BusinessType.Venue
      : BusinessType.Vendor,
  }
  const lineItemsQuery = useLineItems(lineItemParams, {
    enabled: !(isNil(authUser?.role) || isNil(authUser?.listing_id)),
  })

  const { data: lineItems } = lineItemsQuery
  const { data: lineItemsType } = useLineItemsTypes()

  return (
    <BlockContainer display="flex" flexDirection="column">
      <Pricing
        orderPrice={orderPrice}
        pricingDetails={pricingDetails}
        shortPricingDescription={shortPricingDescription}
        displayPricing={displayPricing}
        onSave={onPriceSave}
      />
      <LineItems
        businessDetails={businessDetails}
        lineItems={lineItems}
        lineItemsType={lineItemsType}
        lineItemsQuery={lineItemsQuery}
      />
    </BlockContainer>
  )
}
