import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core'
import { ComponentProps } from 'react'

const LocationOutlined = ({
  fillColor = '',
  ...props
}: { fillColor?: string } & SvgIconProps) => {
  const theme = useTheme()
  const fill = fillColor ? fillColor : theme.palette.primary.light

  return (
    <SvgIcon
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path color={fill} d="M12 13.5A3.75 3.75 0 1 1 12 6a3.75 3.75 0 0 1 0 7.5Zm0-6a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
      <path color={fill} d="m12 22.5-6.327-7.462a26.322 26.322 0 0 1-.261-.338A8.167 8.167 0 0 1 3.75 9.75a8.25 8.25 0 1 1 16.5 0 8.164 8.164 0 0 1-1.661 4.948l-.001.002s-.225.296-.259.335L12 22.5Zm-5.391-8.704c.002 0 .176.231.215.28L12 20.182l5.183-6.113c.032-.04.208-.273.209-.274A6.676 6.676 0 0 0 18.75 9.75a6.75 6.75 0 0 0-13.5 0 6.679 6.679 0 0 0 1.36 4.046h-.001Z" />
    </SvgIcon>
  )
}

export default LocationOutlined
