import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import { ParseableDate } from '@material-ui/lab/internal/pickers/constants/prop-types'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import StaticDatePicker from '@material-ui/lab/StaticDatePicker'
import React, { ReactElement, useState } from 'react'

import { useWindowSize } from '../../../../hooks/useWindowSize'
import {
  convertDateTimeToReadableString,
  convertDateToShortString,
  dateHasTime,
  getStartOfDate,
  setTimeToDate,
} from '../../../../utils/helpers/date'
import CloseIcon from '../../../svg/CloseIcon'
import GradientButton from '../../Buttons/GradientButton/GradientButton'
import {
  FooterContainer,
  ResetButtonContainer,
} from '../DateRangePicker/DateRangePicker.styles'
import { TimePicker } from '../TimePicker'

import {
  DateTimeContainer,
  DateTimeFieldContainer,
} from './DateTimeField.styles'

interface props {
  value: null | undefined | Date
  endTimeDate?: null | Date
  setValue: (value: Date | null, endTime?: Date | null) => void
  renderDateInput?: (value: string) => ReactElement
  className?: string
  label?: string
  placeholder?: string
  error?: boolean
  withTime?: boolean
  helperText?: string
  formatDate?: (value: Date) => string
  disabled?: boolean
  withPlaceholder?: boolean
}

export default function DateTimeField(props: props) {
  const {
    value,
    setValue,
    label,
    disabled = false,
    placeholder,
    error,
    helperText,
    withTime = false,
    formatDate,
    endTimeDate = null,
    withPlaceholder = true,
  } = props
  const [time, setTime] = useState<Date | null>(null)
  const [endTime, setEndTime] = useState<Date | null>(endTimeDate)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [showTime, setShowTime] = useState(
    dateHasTime(value) || dateHasTime(endTimeDate)
  )
  const { isMobile } = useWindowSize()

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleReset = () => {
    handleDateChange(null)
    setTime(null)
    setEndTime(null)
  }

  const getHumanReadableString = (): string => {
    if (!value) return ''

    if (formatDate) {
      return formatDate(value)
    }
    if (Array.isArray(value)) {
      return withTime
        ? convertDateTimeToReadableString(
            new Date(value[0]),
            new Date(value[1])
          )
        : convertDateToShortString(new Date(value[0]))
    }
    return withTime
      ? convertDateTimeToReadableString(value, endTime)
      : convertDateToShortString(value)
  }

  const getPlaceholderString = (): string => {
    if (placeholder) {
      return placeholder
    }

    return withTime
      ? convertDateTimeToReadableString(new Date())
      : convertDateToShortString(new Date())
  }

  const handleDateChange = (date: Date | null) => {
    if (!date) {
      setValue(null)
      return
    }
    date = getStartOfDate(date)
    const dateTime = time && withTime ? setTimeToDate(date, time) : date
    setValue(dateTime, endTime)
  }

  const handleOnChangeTime = (time: Date) => {
    if (value) {
      const dateTime = setTimeToDate(value, time)
      setValue(dateTime)
    }
    setTime(time)
  }

  const handleOnChangeEndTime = (time: Date) => {
    setValue(value || null, time)
    setEndTime(time)
  }

  const getDateString = (dateValue?: ParseableDate<Date>) => {
    const date = dateValue || new Date()
    const shortDate = convertDateToShortString(date, true)
    const parts = shortDate.split(' ')

    if (!dateValue) {
      parts[1] = '__'
    }
    return (
      <Typography
        variant="body2"
        fontSize={12}
        color={showTime ? '#000' : '#9396A3'}
        style={{ textTransform: 'uppercase' }}
      >
        {parts[0]}{' '}
        <span
          style={{
            color: showTime ? '#2F54EB' : '#9396A3',
            fontSize: 24,
            fontFamily: 'Poppins',
          }}
        >
          {parts[1]}
        </span>{' '}
        {parts[2]}
      </Typography>
    )
  }

  const handleInputClick = (e: React.MouseEvent) => {
    if (!disabled) return
    e.stopPropagation()
  }

  return (
    <DateTimeFieldContainer className={props.className}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormControl onClick={handleClick}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {label && (
              <Typography
                onClick={handleInputClick}
                color={disabled ? '#BDBDBD' : '#000'}
                textAlign="left"
                variant="body2"
                sx={{ mb: 3 }}
              >
                {label}
              </Typography>
            )}
            <TextField
              error={error}
              helperText={helperText}
              variant={'outlined'}
              value={getHumanReadableString()}
              inputProps={{
                placeholder: withPlaceholder ? getPlaceholderString() : '-',
              }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <ExpandMoreOutlined
                      style={{ color: disabled ? '#BDBDBD' : 'black' }}
                    />
                  </InputAdornment>
                ),
              }}
              onClick={handleInputClick}
              disabled={disabled}
            />
          </LocalizationProvider>
        </FormControl>
        <DateTimeContainer
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
            <Box className="styled-calendar">
              <StaticDatePicker
                className="date-calendar"
                value={value}
                onChange={handleDateChange}
                disablePast
                displayStaticWrapperAs="desktop"
                renderInput={() => <TextField />}
              />
            </Box>
            {withTime && (
              <Box
                display="flex"
                flexDirection="column"
                pr={isMobile ? '24px' : 5}
                pb={4}
                pl={isMobile ? '24px' : 5}
                pt={2}
                style={{ borderLeft: '1px solid #F7F7F8' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showTime}
                      onChange={() => setShowTime(!showTime)}
                    />
                  }
                  label={
                    <Typography color="textPrimary" variant="body2">
                      Set time
                    </Typography>
                  }
                />
                <Typography
                  variant="body2"
                  fontSize={12}
                  color="#9396A3"
                  style={{ marginTop: 10, textTransform: 'uppercase' }}
                >
                  From
                </Typography>
                <Box>{getDateString(value || time)}</Box>
                <TimePicker
                  disabled={!showTime}
                  time={value || time}
                  onTimeChange={handleOnChangeTime}
                />
                <Typography
                  variant="body2"
                  fontSize={12}
                  color="#9396A3"
                  style={{ marginTop: 15, textTransform: 'uppercase' }}
                >
                  to
                </Typography>
                <Box>{getDateString(value || time)}</Box>
                <TimePicker
                  disabled={!showTime}
                  time={endTime}
                  onTimeChange={handleOnChangeEndTime}
                />
              </Box>
            )}
          </Box>
          <FooterContainer>
            <ResetButtonContainer onClick={handleReset}>
              <CloseIcon />
              <Typography variant="caption" fontWeight={500}>
                Reset Dates
              </Typography>
            </ResetButtonContainer>
            <GradientButton
              style={{
                padding: '4px 20px',
                height: isMobile ? '48px' : 'auto',
              }}
              onClick={handleClose}
            >
              Submit
            </GradientButton>
          </FooterContainer>
        </DateTimeContainer>
      </LocalizationProvider>
    </DateTimeFieldContainer>
  )
}
