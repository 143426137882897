import { Box, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { FormikErrors } from 'formik'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import GradientButton from '../../../components/ui/Buttons/GradientButton'

import { InvoiceFormSteps } from './GenerateDefaultInvoices/constans'

interface StepContentProps {
  steps: {
    slug: InvoiceFormSteps
    name: string
    fields: string[]
  }[]
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
  renderStep: (step: number) => React.ReactNode | null
  getButtonText: () => string
  errors: FormikErrors<any>
  handleSaveButtonClick: (values?: any) => void
  isNextBtnDisabled?: boolean
  isPossibleMoreVariants?: boolean
  moreButtonText?: string
  sendButtonText?: string
  handleSendButtonClick?: () => void
  handleBackButtonClick?: () => void
}

export const StepContent: React.FC<StepContentProps> = ({
  steps,
  activeStep,
  setActiveStep,
  renderStep,
  getButtonText,
  errors,
  handleSaveButtonClick,
  isNextBtnDisabled,
  isPossibleMoreVariants,
  moreButtonText,
  sendButtonText,
  handleSendButtonClick,
  handleBackButtonClick,
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  const stepIsValid =
    !isNextBtnDisabled &&
    steps[activeStep].fields.every((fieldName: any) => !errors[fieldName])

  const handleBackClick = () => {
    if (activeStep === 0) {
      history.goBack()
    } else {
      setActiveStep(activeStep - 1)
    }
  }

  return (
    <Box
      height="100%"
      display="flex"
      flex={1}
      flexDirection="column"
      width={1}
      p="24px"
      pb="100px"
      position="relative"
    >
      {renderStep(activeStep)}
      <Box
        sx={{ background: (theme) => theme.palette.common.white }}
        p="24px 24px 32px"
        display="flex"
        flex={1}
        justifyContent="space-between"
        position="absolute"
        bottom={0}
        left={0}
        right={0}
      >
        <Box
          onClick={() => {
            handleBackClick()
            handleBackButtonClick && handleBackButtonClick()
          }}
          style={{ cursor: 'pointer', color: '#9396A3' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ArrowBack />
          <Typography variant="body1" fontWeight={500}>
            {t('common.buttons.back', 'Back')}
          </Typography>
        </Box>
        <Box display="flex">
          {isPossibleMoreVariants &&
            moreButtonText &&
            activeStep === steps.length - 1 && (
              <GradientButton
                style={{
                  marginRight: '20px',
                  padding: '6px 20px',
                  minWidth: 150,
                  height: 'fit-content',
                }}
                onClick={() => setActiveStep(0)}
              >
                {moreButtonText}
              </GradientButton>
            )}
          <GradientButton
            style={{
              padding: '6px 20px',
              minWidth: 150,
              height: 'fit-content',
            }}
            disabled={!stepIsValid}
            onClick={handleSaveButtonClick}
          >
            {getButtonText()}
          </GradientButton>
          {handleSendButtonClick && sendButtonText && (
            <GradientButton
              style={{
                marginLeft: '20px',
                padding: '6px 20px',
                minWidth: 150,
                height: 'fit-content',
              }}
              disabled={!stepIsValid}
              onClick={handleSendButtonClick}
            >
              {sendButtonText}
            </GradientButton>
          )}
        </Box>
      </Box>
    </Box>
  )
}
