const AscSort = ({ fill = '#F5F5F8' }) => {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 2a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Z"
        fill={fill}
      />
      <path
        d="M13.25 13.25a.75.75 0 1 0-1.5 0v13.19l-1.72-1.721a.752.752 0 0 0-1.061 1.062l3 2.998.01.011a.745.745 0 0 0 1.05-.009l3-3a.75.75 0 1 0-1.06-1.062l-1.72 1.72V13.25Zm6 .75a.75.75 0 1 1 0-1.5h1.5a.75.75 0 1 1 0 1.5h-1.5Zm0 4.5a.75.75 0 1 1 0-1.5h4.5a.75.75 0 1 1 0 1.5h-4.5Zm0 4.5a.75.75 0 1 1 0-1.5h7.5a.75.75 0 1 1 0 1.5h-7.5Zm-.75 3.75a.75.75 0 0 0 .75.75h10.5a.75.75 0 1 0 0-1.5h-10.5a.75.75 0 0 0-.75.75Z"
        fill="#000"
      />
    </svg>
  )
}

export default AscSort
