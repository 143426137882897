import { Box, styled, Typography } from '@material-ui/core'

import { poppinsFontFamily } from '../../../theme/createTypography'

export const BlockTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  font-family: ${poppinsFontFamily};
  margin-bottom: 20px;
`

export const NotificationsContainer = styled(Box)(
  (props) => `
  overflow: auto;
  position: relative;
  height: 400px;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  &::-webkit-scrollbar-thumb {
    background: #d7d7d7;
  }
`
)

export const SeeAllContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  margin: 0 -15px -15px;
  box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
  color: #2f54eb;
`

export const DetailsSeparator = styled(Box)`
  width: 5px;
  height: 5px;
  margin-top: 12px;
  margin-left: 10px;
  background-color: #9396a3;
  border-radius: 50%;
`
