import { Box, Typography } from '@material-ui/core'
import GradientButton from 'components/ui/Buttons/GradientButton/GradientButton'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const InquiryAcceptedModal: React.FC<{ onViewDetails: () => void }> = ({
  onViewDetails,
}) => {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      width="100%"
      height={180}
      maxWidth={430}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Typography
        fontWeight={600}
        fontFamily="Poppins"
        variant="h4"
        textAlign={'center'}
      >
        {t('business.inquiry.accepted', 'Inquiry Accepted!')}
      </Typography>
      <Typography variant="body2" textAlign={'center'}>
        {t(
          'business.inquiry.accepted_message',
          'We’ve sent the client an email notifying them that you accepted their inquiry.'
        )}
      </Typography>
      <GradientButton onClick={onViewDetails}>
        {t('business.inquiry.view_details', 'View Inquiry Details')}
      </GradientButton>
    </Box>
  )
}

export default InquiryAcceptedModal
