import { Box, BoxProps } from '@material-ui/core'
import escapeRegExp from 'lodash/escapeRegExp'
import * as React from 'react'

interface HighlightedSearchQueryProps {
  initialString: string
  searchQuery: string
  minQueryLength: number
  whiteSpace?: BoxProps['whiteSpace']
}

export const HighlightedSearchQuery: React.FC<HighlightedSearchQueryProps> = ({
  initialString = '',
  searchQuery,
  minQueryLength,
  whiteSpace = 'pre',
}) => {
  const searchQueryFormatted = escapeRegExp(searchQuery.trim().toLowerCase())

  const getChunks = () =>
    initialString.split(new RegExp(`(${searchQueryFormatted})`, 'i'))

  return (
    <Box component="span" whiteSpace={whiteSpace}>
      {!searchQueryFormatted.length ||
      searchQueryFormatted.length < minQueryLength
        ? initialString
        : getChunks().map((text: string, index: number) => {
            return searchQueryFormatted === text.toLowerCase() ? (
              <Box key={index} component="span" fontWeight="bold">
                {text}
              </Box>
            ) : (
              text
            )
          })}
    </Box>
  )
}
