import { FormError } from './FormError'
import { FormField, FormFieldInline } from './FormField'
import { FormHelperText } from './FormHelperText'
import { FormInput } from './FormInput'
import { FormLabel } from './FormLabel'
import { FormSelect } from './FormSelect'

export const Form = {
  Field: FormField,
  FieldInline: FormFieldInline,
  Label: FormLabel,
  Input: FormInput,
  Select: FormSelect,
  HelperText: FormHelperText,
  Error: FormError,
}
