const Filter = () => {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="2" fill="#F7F7F8" />
      <path
        d="M22.684 21.668v-.136l.069-.118 4.541-7.72a.124.124 0 0 0-.104-.186H12.81c-.088 0-.154.1-.105.187l4.542 7.72.07.117v4.836c0 .072.056.124.12.124h5.126a.122.122 0 0 0 .12-.125v-4.699Zm-.399 3.918v.5h-4.572V22.039h4.572v3.547Zm-4.072-3.797h-.29l-.144-.252-.184-.32-3.87-6.548-.447-.755h13.444l-.446.755-3.871 6.547-.184.321-.144.252h-3.864Z"
        fill="#262626"
        stroke="#20202C"
      />
    </svg>
  )
}

export default Filter
