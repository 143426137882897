import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { Close, Remove } from '@material-ui/icons'
import { Formik } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleButton } from '../../../../../components/ui/Buttons/SimpleButton'
import { priceVariants } from '../../../../../constants'
import { useLabels } from '../../../../../utils/api/labels'
import { LabelsSection } from '../../../../marketplace/components/VenuesFilter/components/LabelsSection'
import {
  FormContainer,
  ScreenContent,
  ScrollContainer,
  SectionHeader,
} from '../../../../marketplace/components/VenuesFilter/VenuesFilter.styles'

interface MoreFiltersModalProps {
  onCancel: () => void
  onClear: () => void
  showResults: (values: any) => void
  initialValues: { [key: string]: string | number[]; costLevel: number[] }
}

export const VendorsMoreFiltersModal: React.FC<MoreFiltersModalProps> = ({
  onCancel,
  onClear,
  showResults,
  initialValues,
}) => {
  const { data: allLabels } = useLabels({
    perPage: 100,
    exclusivity: 'vendors',
  })
  const specialFeatures =
    allLabels?.filter((label: any) => label.type === 'special features') || []
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={showResults}
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
    >
      {({ handleSubmit, values, setFieldValue, resetForm }) => {
        const onPriceLevelChange = (value: number) => {
          if (values.costLevel.includes(value)) {
            setFieldValue(
              'costLevel',
              values.costLevel.filter((val) => val !== value)
            )
          } else {
            setFieldValue('costLevel', [...values.costLevel, value])
          }
        }

        return (
          <FormContainer onSubmit={handleSubmit}>
            <ScrollContainer display="flex" flexDirection="column" width="100%">
              <Box maxHeight={800}>
                <ScreenContent>
                  <SectionHeader variant="h3">
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        left: '-15px',
                        top: '3px',
                        '&:hover': { opacity: 0.8 },
                      }}
                    >
                      <Remove sx={{ fill: '#2F54EB' }} />
                      <Typography
                        onClick={() => {
                          resetForm()
                          onClear()
                        }}
                        component="span"
                        color="#2F54EB"
                        variant="body1"
                      >
                        Clear
                      </Typography>
                    </Box>
                    {t('common.filters.more', 'More filters')}
                  </SectionHeader>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    mb={4}
                  >
                    <Typography textAlign="left" gutterBottom variant="body2">
                      Cost level
                    </Typography>
                    <Box
                      display="grid"
                      mb={2}
                      style={{ gridTemplateColumns: '1fr 1fr' }}
                    >
                      {priceVariants.map(
                        (variant: { value: number; label: string }) => (
                          <FormControlLabel
                            key={variant.value}
                            control={
                              <Checkbox
                                checked={values.costLevel.includes(
                                  variant.value
                                )}
                                onChange={() =>
                                  onPriceLevelChange(variant.value)
                                }
                              />
                            }
                            label={
                              <Typography color="textPrimary" variant="body2">
                                {variant.label}
                              </Typography>
                            }
                          />
                        )
                      )}
                    </Box>
                    <LabelsSection
                      title="Special features"
                      allLabels={specialFeatures}
                      mb={3}
                    />
                  </Box>
                </ScreenContent>
                <Box
                  display="flex"
                  style={{
                    background: 'white',
                    padding: '24px 0 0 0',
                    position: 'sticky',
                    bottom: 0,
                    borderTop: '1px solid rgb(210 210 210 / 40%)',
                  }}
                >
                  <Box
                    onClick={onCancel}
                    display="flex"
                    flex={1}
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography color="#2F54EB" variant="body1" align="center">
                      Close
                    </Typography>
                    <Close style={{ color: '#2F54EB' }} />
                  </Box>
                  <SimpleButton variant="contained" type="submit">
                    Show results
                  </SimpleButton>
                </Box>
              </Box>
            </ScrollContainer>
          </FormContainer>
        )
      }}
    </Formik>
  )
}
