import { TableCell } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ArrowDown from '../../svg/ArrowDown'
import ArrowUp from '../../svg/ArrowUp'

import {
  StyledHelperRow,
  StyledIconButton,
  StyledTableRow,
} from './CollapsibleDocumentRow.styles'

interface CollapsibleDocumentRowProps {
  colSpan: number
  title?: string
}

export const CollapsibleDocumentRow: React.FC<CollapsibleDocumentRowProps> = ({
  title,
  colSpan,
  children,
}) => {
  const [isRowExpanded, setIsRowExpanded] = React.useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <>
      <StyledTableRow>
        <TableCell style={{ color: '#9396A3' }} colSpan={colSpan}>
          {title || isRowExpanded
            ? t('inquiry.versions.previous.hide', 'Hide old versions')
            : t('inquiry.versions.previous.show', 'Show old versions')}
        </TableCell>
        <TableCell
          align="right"
          style={{ color: '#9396A3', marginLeft: '10px' }}
        >
          <StyledIconButton
            color="inherit"
            onClick={() => setIsRowExpanded(!isRowExpanded)}
          >
            {isRowExpanded ? <ArrowUp fill="#2D2D2D" /> : <ArrowDown />}
          </StyledIconButton>
        </TableCell>
      </StyledTableRow>
      <StyledHelperRow
        className={isRowExpanded ? 'show-after' : 'hide-after'}
      />
      {children}
    </>
  )
}
