import { Box, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

import img from '../../../../assets/images/event-form-background.jpg'
import imgWebp from '../../../../assets/images/event-form-background.webp'

export const EventFormWrapper = styled(Box)`
  border-radius: 0;
`

export const EventFormContainer = styled('form')`
  background: white;
  padding: 5px 15px 5px 0;
  width: fit-content;
  position: relative;
  z-index: 2;

  .event-type-select .event-form-field-wrapper {
    border-left: none;
  }

  ${MEDIA_RULES.SMALL} {
    padding: 5px 0 10px;
    width: 100%;
  }
`

export const AbsFormContainer = styled('form')(
  ({ theme }) => `
  background: white;
  padding: 8px 11px 8px 0;
  max-width: 759px;
  position: relative;
  z-index: 2;

  .MuiInput-root {
    font-size: 18px;
    font-weight: 500;
    color: ${theme.palette.secondary.dark}
   }

  .MuiInput-root:after,
  .MuiInput-root:before,
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  
  .MuiInput-input:focus {
    background: none;
  }

  ${MEDIA_RULES.SMALL} {
    padding: 0;
    width: 100%;
  }
`
)

export const BlackBlock = styled(Box)<{ isMobile: boolean; isAbs: boolean }>(
  (props) => `
  width: 100%;
  z-index: 2;
  background-color: ${props.theme.palette.secondary.dark};
  border-radius: 0;
  height:  ${props.isAbs || props.isMobile ? '100%' : '35vw'};
  position: relative;
`
)

export const StyledTypography = styled(Typography)`
  color: white;
`

export const BackgroundImage = styled(Box)<{ isMobile: boolean }>(
  (props) => `
  background-image: url(${img});
  background-image: image-set(url(${imgWebp}) type("image/webp"), url(${img}) type("image/jpg"));
  background-position: center;
  background-size: cover;
  border-radius: 0;
  position: absolute;
  right: 0;
  top: ${props.isMobile ? 0 : '20px'};;
  width: ${props.isMobile ? '100%' : '70%'};
  height: ${props.isMobile ? '100%' : '35vw'};
  z-index: -1;
`
)

export const StyledSliderBlock = styled(Box)<{ isMobile: boolean }>(
  (props) => `
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${props.isMobile ? '100%' : '55%'};
  
  ${MEDIA_RULES.SMALL} {
    top: auto;
    height: 60%;
  }
`
)
