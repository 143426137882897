import { Box, Button, Toolbar, Typography } from '@material-ui/core'
import { SxProps } from '@material-ui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import ABSLogo from '../../../assets/images/abs-logo.png'
import { dashboardPath, vendorsPath, venuesPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { NavLink } from '../../NavLink'
import { AccountMenu } from '../AccountMenu'
import EventPicker from '../EventPicker'
import { GlobalSearch } from '../GlobalSearch'
import { HeaderBar, StyledAppBar } from '../Header.styles'

import { LoginSignUpMenu } from './LoginSignUpMenu'

export const PartnerHeader: React.FC<
  SxProps & { setRenderSearch?: (renderSearch: boolean) => void }
> = ({ setRenderSearch, ...sxProps }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { authUser, isLoading } = useAuthData()
  const pagePath = history.location.pathname

  const isActive = (currentPath: string) => currentPath === pagePath
  const isBusinessUser = !isLoading && !!authUser?.role

  return (
    <HeaderBar sx={{ ...sxProps }}>
      <StyledAppBar isAbs={true}>
        <Box className="container">
          <Toolbar
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Box display="flex">
              {pagePath !== venuesPath && (
                <Box display="flex" alignItems="center">
                  <NavLink to={venuesPath} className="logo-wrapper">
                    <img src={ABSLogo} height={66} alt="ABS logo" />
                  </NavLink>
                </Box>
              )}
              <Box
                position="absolute"
                left="160px"
                top="50%"
                sx={{ transform: 'translateY(-50%)' }}
              >
                <GlobalSearch />
              </Box>
            </Box>
            <Box display="flex">
              <Box display="flex" alignItems="center">
                <NavLink to={vendorsPath}>
                  <Button
                    className={
                      isActive(vendorsPath)
                        ? 'header-button active'
                        : 'header-button'
                    }
                  >
                    <Typography
                      sx={{ textTransform: 'uppercase' }}
                      variant="body1"
                      fontFamily="Acumin Pro"
                    >
                      {t('header.browseVendors', 'Browse vendors')}
                    </Typography>
                  </Button>
                </NavLink>
                {isBusinessUser && (
                  <NavLink to={dashboardPath}>
                    <Button className="header-button" sx={{ paddingRight: 6 }}>
                      <Typography
                        sx={{ textTransform: 'uppercase' }}
                        variant="body1"
                        fontFamily="Acumin Pro"
                      >
                        {t('partners.abs.header.dashboard', 'Dashboard')}
                      </Typography>
                    </Button>
                  </NavLink>
                )}
              </Box>
              <Box display="flex">
                {!isBusinessUser && <EventPicker />}
                {authUser ? <AccountMenu /> : <LoginSignUpMenu />}
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </StyledAppBar>
    </HeaderBar>
  )
}
