const GalleryIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2 7L16 12H4L7 8.1L9.1 10.8L12 7H12.2ZM6.5 7C7.3 7 8 6.3 8 5.5C8 4.7 7.3 4 6.5 4C5.7 4 5 4.7 5 5.5C5 6.3 5.7 7 6.5 7ZM20 2V14C20 15.1 19.1 16 18 16H2C0.9 16 0 15.1 0 14V2C0 0.9 0.9 0 2 0H18C19.1 0 20 0.9 20 2ZM18 4.8V2H2V14H18V4.8Z"
        fill="#20202C"
      />
    </svg>
  )
}

export default GalleryIcon
