import { Box, styled, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import MaskedInput from 'react-text-mask'

import { TextInput } from '../../../components/ui/FormComponents/TextInput'
import {
  GoogleLocationSelect,
  GooglePlace,
} from '../../../components/ui/Inputs/GoogleLocationSelect'
import { WizardStep } from '../../../components/Wizard'
import { EmptyGooglePlace, phoneNumberMask } from '../../../constants'
import { OnboardingStep } from '../../../types/Auth'

import { createProfileValidationSchema } from './validations'

const CreateProfileWrapper = styled(Box)`
  .phone-input {
    font-family: Poppins;
    padding: 12px;
    display: flex;
    flex: 1;
    margin-top: 5px;
    font-size: 16px;
    line-height: 28px;
    border: 1px solid #d7d7d7;

    &::placeholder {
      font-weight: 300;
      opacity: 0.8;
    }
  }
`

interface CreateProfileData {
  contact_name: string
  contact_email: string
  phone: string
  office: GooglePlace
}

export const CreateProfile: React.FC<{ step: OnboardingStep }> = ({ step }) => {
  const { t } = useTranslation()

  const formik = useFormik<CreateProfileData>({
    initialValues: {
      contact_name: '',
      contact_email: '',
      phone: '',
      office: EmptyGooglePlace,
    },
    validationSchema: createProfileValidationSchema(),
    onSubmit: () => {},
  })

  const { values, setFieldValue, isValid, errors, touched, handleBlur, dirty } =
    formik

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('phone', event.target.value)
  }

  const stepData = {
    ...values,
    office: values.office.place_id,
    phone: values.phone.replace(/\D+/g, ''),
  }

  const getTextFieldErrors = (name: keyof CreateProfileData) => {
    return {
      error: touched[name] && !!errors[name],
      helperText: touched[name] && errors[name] ? errors[name] : '',
    }
  }

  return (
    <WizardStep
      title={step.label}
      index={step.index}
      buttonLabel={'Submit'}
      buttonDisabled={!dirty || !isValid}
      stepData={stepData}
    >
      <CreateProfileWrapper width="85%">
        <TextInput
          thirdlyMode
          onBlur={handleBlur}
          {...getTextFieldErrors('contact_name')}
          name="contact_name"
          placeholder={t(
            'onboarding.venue.contact.name.placeholder',
            'Enter name'
          )}
          value={values.contact_name}
          onChange={(value) => setFieldValue('contact_name', value)}
          label={t('onboarding.venue.contact.name.label', '*Contact name')}
        />
        <Box mt={2}>
          <TextInput
            thirdlyMode
            onBlur={handleBlur}
            {...getTextFieldErrors('contact_email')}
            name="contact_email"
            placeholder={t(
              'onboarding.venue.contact.email.placeholder',
              'Contact e-Mail'
            )}
            value={values.contact_email}
            onChange={(value) => setFieldValue('contact_email', value)}
            label={t('onboarding.venue.contact.email.label', '*Contact email')}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={2} mt={2}>
          <Typography
            gutterBottom
            textAlign="left"
            variant="body2"
            color="#bdbdbd"
          >
            {t('onboarding.venue.contact.phone.label', '*Contact phone')}
          </Typography>
          <MaskedInput
            onBlur={handleBlur}
            className="phone-input"
            name="phone"
            value={values.phone}
            onChange={handleChangePhone}
            placeholder={t(
              'onboarding.venue.contact.phone.placeholder',
              'Enter mobile number'
            )}
            mask={[...phoneNumberMask]}
            id="phoneNumber"
          />
        </Box>
        <GoogleLocationSelect
          withAdornment={false}
          {...getTextFieldErrors('office')}
          value={values.office}
          onChange={(value) => setFieldValue('office', value)}
          name="office"
          placeholder={t(
            'onboarding.venue.contact.address.placeholder',
            'Enter office address'
          )}
          label={t('onboarding.venue.contact.address.label', 'Office Number')}
        />
      </CreateProfileWrapper>
    </WizardStep>
  )
}
