import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

import { paramsToObject } from '../utils/helpers/urlParamsToObject'

import useScript from './useScript'

const { REACT_APP_JOTFORM_API_KEY: apiKey } = process.env

export const useJotFormInitializer = () => {
  const [JF, setJF] = useState<any>({})
  useEffect(() => {
    if (JF.initialize) {
    }
  }, [status])
  const setGlobalJF = useCallback(() => {
    // @ts-ignore
    setJF(window.JF)
    // @ts-ignore
    window.JF.initialize({ apiKey })
    // @ts-ignore
  }, [window.JF])
  useScript('https://js.jotform.com/JotForm.js', setGlobalJF)

  return { JF }
}
