import { getPartnerParams, isPartner } from 'constants/partners'

import { CancelToken } from 'axios'

import {
  AuthLoginRequest,
  AuthLoginResponse,
  SimpleRegistrationRequest,
} from '../../types/dtos/auth'
import { clearObject } from '../helpers/data'
import {
  forgotPasswordApiPath,
  getAdminLoginAsUserApiPath,
  getMagicSignInApiPath,
  signInApiPath,
  signOutApiPath,
  signUpApiPath,
} from '../paths'

import { get, post } from './api'

export async function signIn(
  data: AuthLoginRequest,
  cancelToken: CancelToken
): Promise<AuthLoginResponse> {
  return post(signInApiPath(), data, cancelToken)
}

export async function magicSignIn(
  hash: string,
  cancelToken: CancelToken
): Promise<AuthLoginResponse> {
  return get(getMagicSignInApiPath(hash), undefined, { cancelToken })
}

export async function forgotPassword(email: string): Promise<void> {
  return post(forgotPasswordApiPath, {
    email,
    ...(isPartner() ? getPartnerParams() : {}),
  })
}

export async function signOut(): Promise<void> {
  return get(signOutApiPath)
}

export async function signUp(
  data: SimpleRegistrationRequest
): Promise<AuthLoginResponse> {
  return post(signUpApiPath(), clearObject(data))
}

export async function signInAsUser(userId: string): Promise<AuthLoginResponse> {
  return get(getAdminLoginAsUserApiPath(userId))
}
