import { useMemo } from 'react'

import { InquiryContractStatuses } from '../constants/inquiry'
import { InquiryContractResponse } from '../types/dtos/inquiry'

interface ContractsByStatus {
  completedContract: InquiryContractResponse | null
  outdatedContracts: InquiryContractResponse[]
  notOutdatedContract: InquiryContractResponse | null
}

export function useContractsByStatuses(
  contracts?: InquiryContractResponse[]
): ContractsByStatus {
  const completedContract = useMemo(() => {
    if (!contracts?.length) return null
    return (
      contracts?.find(
        (contract) =>
          contract.status === InquiryContractStatuses.STATUS_COMPLETED
      ) || null
    )
  }, [contracts])

  const outdatedContracts = useMemo(() => {
    if (!contracts?.length) return []
    return contracts?.filter(
      (contract) => contract.status === InquiryContractStatuses.STATUS_OUTDATED
    )
  }, [contracts])

  const notOutdatedContract = useMemo(() => {
    if (!contracts?.length) return null
    return (
      contracts?.find(
        (contract) =>
          contract.status !== InquiryContractStatuses.STATUS_OUTDATED
      ) || null
    )
  }, [contracts])

  return {
    completedContract,
    outdatedContracts,
    notOutdatedContract,
  }
}
