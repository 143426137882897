import { TableHead, TableRow, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledHeaderCell } from '../InquiryTables.styles'

export const Header: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TableHead>
      <TableRow>
        <StyledHeaderCell sx={{ width: '40px' }}>
          <Typography
            variant="body2"
            color={(theme) => theme.palette.grey['100']}
          >
            {t('business.contracts.table.number', '#')}
          </Typography>
        </StyledHeaderCell>
        <StyledHeaderCell>
          <Typography
            variant="body2"
            color={(theme) => theme.palette.grey['100']}
          >
            {t('business.contracts.table.generated', 'Generated')}
          </Typography>
        </StyledHeaderCell>
        <StyledHeaderCell align="center">
          <Typography
            variant="body2"
            color={(theme) => theme.palette.grey['100']}
          >
            {t('business.contracts.table.version', 'Version')}
          </Typography>
        </StyledHeaderCell>
        <StyledHeaderCell align="right">
          <Typography
            variant="body2"
            color={(theme) => theme.palette.grey['100']}
          >
            {t('business.contracts.table.status', 'Status')}
          </Typography>
        </StyledHeaderCell>
        <StyledHeaderCell align="right">
          <Typography
            variant="body2"
            color={(theme) => theme.palette.grey['100']}
          >
            {t('business.contracts.table.actions', 'Actions')}
          </Typography>
        </StyledHeaderCell>
      </TableRow>
    </TableHead>
  )
}
