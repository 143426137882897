import { Box, ImageListItem, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { range } from 'lodash'
import React from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { UserRoles } from '../../constants/profile'
import { MAX_PRICE_VALUE } from '../../constants/vendors'
import { usePartner } from '../../hooks/usePartner'
import { useVendorCategory } from '../../hooks/useVendorCategory'
import { StyledItemBottomBar } from '../../pages/venues/components/VenueListItem/VenueListItem.styles'
import { EventVendor } from '../../types/Event'
import { Vendor } from '../../types/Vendor'
import { vendorPath } from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { Image } from '../ui/Image'
import { Loader } from '../ui/Loader'
import { TooltipComponent } from '../ui/Tooltip'

import { AddedToEventLabel } from './labels/AddedToEventLabel'
import { PromotionLabel } from './labels/PromotionLabel'

interface Props {
  vendor: Vendor
  navigateAction?: (vendor: Vendor) => void
  toggleVendorLike?: (vendorId: string) => void
  itemsWithFooter?: boolean
  isLiked?: boolean
  isSelected?: boolean
  eventId?: string
  pickedVendors?: EventVendor[]
  withCategoryName?: boolean
}

const StyledVendorListItem = styled(ImageListItem)`
  margin: 10px;
  position: relative;
  box-shadow: 0 4px 16px rgba(210, 210, 210, 0.4);

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
`

const getListItemFooter = (
  item: Vendor,
  vendorCategoryName = '',
  withCategoryName = false
) => {
  const emptyRows = range(
    MAX_PRICE_VALUE.split('').length - item.orderPrice.length
  )
  return (
    <Box display="flex" px={3} justifyContent="space-between" mt={1}>
      {withCategoryName && (
        <Typography color="#9396A3">{vendorCategoryName}</Typography>
      )}
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            borderRadius: '50%',
            width: '5px',
            height: '5px',
            backgroundColor: '#9396A3',
            marginRight: '5px',
          }}
        />
        <Typography fontWeight={500}>{item.orderPrice}</Typography>
        {emptyRows?.map((emptyRow, i) => (
          <Typography key={i} color="#9396A3">
            {'$'}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}

export const VendorListItem: React.FC<Props> = ({
  vendor,
  navigateAction,
  itemsWithFooter = true,
  pickedVendors = [],
  withCategoryName = false,
}) => {
  const { isLoading, getVendorCategory } = useVendorCategory()
  const { authUser } = useAuthData()
  const history = useHistory()
  const { isAbs } = usePartner()

  const isItemInBasket = React.useMemo(
    () => Boolean(pickedVendors.find((item) => item.id === vendor.id)),
    [pickedVendors]
  )

  const isBasketAvailable = !authUser || authUser?.role === UserRoles.Client

  const navigateToVendor = (slug: string) => {
    if (navigateAction) {
      navigateAction(vendor)
    } else {
      history.push(generatePath(vendorPath, { slug }))
    }
  }

  const getListItemTitle = () => {
    return (
      <Box
        display="flex"
        px={3}
        mb={2}
        justifyContent="space-between"
        width={1}
      >
        <TooltipComponent placement="top-start" title={vendor.name}>
          <Typography
            variant="h4"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {vendor.name}
          </Typography>
        </TooltipComponent>
      </Box>
    )
  }

  return (
    <StyledVendorListItem key={vendor.id}>
      <Box
        height="280px"
        sx={{
          position: 'relative',
          '&:hover': { cursor: 'pointer', opacity: 0.8 },
        }}
      >
        {((vendor?.hasAdvantages && isAbs) || vendor?.hasPromotion) && (
          <PromotionLabel />
        )}
        <Image
          image={vendor?.images?.[0]}
          imageSize="medium"
          alt={vendor.name}
          staticImageType="vendor"
          vendorCategory={getVendorCategory(vendor.type)?.id}
          onClick={() => navigateToVendor(vendor.slug)}
        />
        {isBasketAvailable && isItemInBasket && <AddedToEventLabel />}
      </Box>
      {itemsWithFooter &&
        (isLoading ? (
          <Loader />
        ) : (
          <StyledItemBottomBar
            title={getListItemTitle()}
            subtitle={getListItemFooter(
              vendor,
              getVendorCategory(vendor.type)?.name,
              withCategoryName
            )}
            position="below"
          />
        ))}
    </StyledVendorListItem>
  )
}
