import { TableHead, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledTableCell, StyledTableRow } from '../../../DocumentForms.styles'

export const LineItemsTableHeader = () => {
  const { t } = useTranslation()
  return (
    <TableHead>
      <StyledTableRow
        sx={{
          background: (theme) => theme.palette.background.paper,
          borderBottom: '15px solid #fff',
        }}
      >
        <StyledTableCell
          sx={{ width: 0, paddingTop: '15px', paddingLeft: '20px' }}
        >
          <Typography variant="body2" color="#BDBDBD">
            {t('business.lineItems.number', '#')}
          </Typography>
        </StyledTableCell>
        <StyledTableCell sx={{ width: 0, paddingTop: '15px' }}>
          <Typography variant="body2" color="#BDBDBD">
            {t('business.lineItems.name2', 'Title')}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ paddingTop: '15px' }}>
          <Typography variant="body2" color="#BDBDBD">
            {t('business.lineItems.descriptionTitle', 'Description')}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ width: 0, paddingTop: '15px' }}>
          <Typography variant="body2" color="#BDBDBD">
            {t('business.lineItems.units', 'Units')}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ width: 0, paddingTop: '15px' }}>
          <Typography variant="body2" color="#BDBDBD">
            {t('business.lineItems.startingPrice', 'Rate')}
          </Typography>
        </StyledTableCell>
        <StyledTableCell sx={{ width: 0, paddingTop: '15px' }}>
          <Typography variant="body2" color="#BDBDBD">
            {t('business.lineItems.subtotal', 'Subtotal')}
          </Typography>
        </StyledTableCell>
        <StyledTableCell sx={{ paddingTop: '15px', paddingRight: '20px' }} />
      </StyledTableRow>
    </TableHead>
  )
}
