import { Box, Modal, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const SimpleModalContentWrapper = styled(Box)`
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  min-height: 200px;
  max-height: 98%;
  min-width: 430px;
  //height: 550px;
  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }

  & > .MuiBox-root {
    width: 100%;
  }

  ${MEDIA_RULES.SMALL} {
    min-width: auto;
    width: 100%;
    height: 100%;
    max-height: 100%;

    & > .MuiBox-root {
      height: 100%;
      max-width: 600px;
      position: relative;
    }
  }
`

export const CustomModalContentWrapper = styled(Box)`
  background: white;
  position: relative;
  display: flex;
  padding: 24px;

  ${MEDIA_RULES.SMALL} {
    width: 100%;
  }
`

export const SimpleModalWrapper = styled(Modal)(
  // @ts-ignore
  ({ visibility }) => `
 display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  visibility: ${visibility};
`
)
