import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@material-ui/core'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'
import { generatePath } from 'react-router-dom'

import LinkText from '../../../../components/ui/Buttons/LinkText/LinkText'
import { SimpleModal } from '../../../../components/ui/Modals/SimpleModal'
import {
  StyledSeeAll,
  StyledTableName,
} from '../../../../components/ui/TableComponent/TableComponent.styles'
import { UserRoles } from '../../../../constants/profile'
import { useCurrentBusinessListingId } from '../../../../hooks/useCurrentBusinessListingId'
import { useSimpleModal } from '../../../../hooks/useSimpleModal'
import { useUpdateEventStatus } from '../../../../hooks/useUpdateEventStatus'
import { ResponseOrError } from '../../../../types/general'
import { InquiryEvent } from '../../../../types/Inquiry'
import { ProfileVendor, ProfileVenue } from '../../../../types/Profile'
import { getModalContent } from '../../../../utils/helpers/bookings'
import { joinUrl } from '../../../../utils/helpers/urls'
import {
  dashboardBookingDetailsPath,
  dashboardBookingsPath,
  dashboardProfilePath,
} from '../../../../utils/paths'
import { useAuthData } from '../../../../utils/providers/AuthProvider'
import { useMessageModalData } from '../../../../utils/providers/MessageModalProvider'
import { StyledPaper } from '../../Dashboard.styles'

import { InquiryEventItem } from './InquiryEventItem'

interface TableComponentProps {
  data: InquiryEvent[]
  count?: number
  onChangeBookingStatus: () => void
}

export const InquiryEventTable: React.FC<TableComponentProps> = ({
  data = [],
  onChangeBookingStatus,
  count = 0,
}) => {
  const { updateInquiryStatusByFlow } = useUpdateEventStatus()
  const history = useHistory()
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const { showMessage, closeMessageDialog } = useMessageModalData()
  const { authUser } = useAuthData()
  const isLandlord = UserRoles.isLandlord(authUser?.role)
  const allListings: (ProfileVenue | ProfileVendor)[] =
    (isLandlord ? authUser?.venues : authUser?.vendors) || []

  const { businessId } = useCurrentBusinessListingId()

  const activeBusiness = useMemo(() => {
    if (!allListings?.length) return
    return (
      allListings?.find((listing) => listing.id === businessId) ||
      allListings?.[0]
    )
  }, [allListings, businessId])

  const modalControl = useSimpleModal()
  const { isOpen, toggleModal, modalData } = modalControl
  const firstEvents = data.slice(0, 3)

  const dataPartnersCount = useMemo(() => {
    if (isLandlord) return 1

    return activeBusiness && 'partners' in activeBusiness
      ? activeBusiness?.partners?.length
      : 1
  }, [activeBusiness])

  const handleOnMenuOptionSelect = async (
    action: string,
    event: InquiryEvent
  ) => {
    switch (action) {
      case 'details':
        history.push(
          generatePath(dashboardBookingDetailsPath, {
            id: event.inquiry.id,
            listingId: businessId,
          })
        )
        break
      case 'edit':
        history.push({
          pathname: joinUrl(url, event.inquiry.id.toString()),
        })
        break
      case 'accept':
        console.log('acc')
        if (!!authUser?.payment_info || authUser?.venues?.[0]?.is_exclusive) {
          try {
            const updatedInquiry = await updateInquiryStatusByFlow(
              event.inquiry,
              true
            )
            if (!ResponseOrError.isError(updatedInquiry)) {
              toggleModal({
                name: 'inquiryAccept',
                event: { ...event, inquiry: updatedInquiry },
              })
              onChangeBookingStatus()
            }
          } catch (e) {
            console.log('error', e)
          }
          break
        }

        showMessage({
          type: 'warning',
          title: t(
            'bookings.messageTitle',
            'You can not to accept inquiry right now'
          ),
          text: t(
            'bookings.messageText',
            'You need to connect square payment account firstly before you start to work.'
          ),
          buttons: [
            { label: 'Cancel', action: closeMessageDialog },
            {
              label: 'Connect account',
              action: () => {
                closeMessageDialog()
                history.push(dashboardProfilePath)
              },
            },
          ],
        })
        break
      case 'decline':
        toggleModal({ name: 'inquiryReject', event })
        break
      default:
        console.log('event', event)
    }
  }

  return (
    <Box component={StyledPaper} width="100%" sx={{ position: 'relative' }}>
      <TableContainer sx={{ overflowX: 'hidden' }}>
        <Box
          display="flex"
          ml={4}
          width={1}
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <StyledTableName variant="h4">
            {t('inquiry.new', 'New Inquiries')}
          </StyledTableName>
          <StyledSeeAll
            sx={{ '&:hover': { opacity: 0.8 } }}
            color="primary"
            onClick={() =>
              history.push(
                generatePath(dashboardBookingsPath, { listingId: businessId })
              )
            }
          >
            {t('common.links.seeAll', 'See all')} ({count})
          </StyledSeeAll>
        </Box>
        {!!firstEvents.length ? (
          <Table>
            <TableBody>
              {firstEvents.map((item) => (
                <InquiryEventItem
                  key={item.id}
                  inquiriesPartnersCount={dataPartnersCount}
                  event={item}
                  handleOnMenuOptionSelect={handleOnMenuOptionSelect}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box
            sx={{
              borderBottom: 'none',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            display="flex"
            ml={4}
          >
            <Typography variant="body2">
              {t('inquiry.noNew', 'No new inbound inquiries.')}
            </Typography>
            <LinkText
              sx={{
                display: 'inline-block',
              }}
              onClick={() =>
                history.push(
                  generatePath(dashboardBookingsPath, {
                    listingId: businessId,
                  })
                )
              }
              mx={1}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  '&:first-letter': { textTransform: 'capitalize' },
                }}
                variant="body2"
              >
                {t('common.links.click', 'click here')}
              </Typography>
            </LinkText>
            <Typography variant="body2">
              {t('inquiry.all', 'to view all of your past and open inquiries.')}
            </Typography>
          </Box>
        )}
      </TableContainer>
      <SimpleModal open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl)}
      </SimpleModal>
    </Box>
  )
}
