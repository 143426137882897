import { Box, BoxProps, Typography } from '@material-ui/core'
import * as React from 'react'
import { ReactElement } from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { Loader } from '../../../../components/ui/Loader'
import SwipeSlider, {
  Slide,
} from '../../../../components/ui/SwipeSlider/SwipeSlider'
import { usePartner } from '../../../../hooks/usePartner'
import { useVendorCategory } from '../../../../hooks/useVendorCategory'
import { ImageType } from '../../../../types/general'
import { Vendor } from '../../../../types/Vendor'
import { vendorPath } from '../../../../utils/paths'
import { StyledItemBottomBar } from '../../../venues/components/VenueListItem/VenueListItem.styles'

interface VendorsSliderProps extends BoxProps {
  data: Vendor[]
  sliderTitle?: string
  slidesPerView?: number
  slidesPerGroup?: number
  withoutEndFrame?: boolean
  navigationPosition?: 'bottom' | 'top' | 'center'
  withCategoryName?: boolean
  tooltipBox?: JSX.Element
  nextNavButtonClass?: string
  prevNavButtonClass?: string
  slideWidth?: number
  slideImageHeight?: number
  renderSlide?: (item: Slide) => ReactElement
  withPagination?: boolean
}

const getListItemFooter = (
  item: Vendor,
  vendorCategoryName = '',
  withCategoryName = false
) => {
  return (
    <Box display="flex" justifyContent="space-between" mt={1}>
      <Typography>{item.orderPrice}</Typography>
      {withCategoryName && <Typography>{vendorCategoryName}</Typography>}
    </Box>
  )
}

const getListItemTitle = (item: Vendor) => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        width={1}
        sx={{ '&:hover': { opacity: 0.95 } }}
      >
        <Typography style={{ maxWidth: 150 }} variant="h4">
          {item?.name}
        </Typography>
      </Box>
    </Box>
  )
}

const VendorsSlider: React.FC<VendorsSliderProps> = (props) => {
  const {
    withPagination = false,
    withCategoryName = false,
    tooltipBox,
    navigationPosition,
    sliderTitle,
    slidesPerView,
    slidesPerGroup = slidesPerView,
    withoutEndFrame,
    mt = '108px',
    data,
    slideWidth,
    slideImageHeight,
    ...boxProps
  } = props

  const history = useHistory()
  const { isAbs } = usePartner()

  const { isLoading, getVendorCategory } = useVendorCategory(withCategoryName)

  const itemList = data.map((vendor: Vendor) => ({
    id: vendor.id,
    image: vendor?.images?.[0],
    promotionDescription: isAbs
      ? vendor?.advantageDescription
      : vendor?.promotionDescription,
    vendorCategory: vendor?.type,
    imageStaticType: 'vendor' as ImageType['static'],
    imageSize: 'medium' as ImageType['size'],
    onClick: () => navigateToVendor(vendor.slug),
    footer: isLoading ? (
      <Loader />
    ) : (
      <StyledItemBottomBar
        title={getListItemTitle(vendor)}
        subtitle={getListItemFooter(
          vendor,
          getVendorCategory(vendor.type)?.name,
          withCategoryName
        )}
        position="below"
      />
    ),
  }))

  const navigateToVendor = (slug: string) => {
    history.push(generatePath(vendorPath, { slug }))
  }

  return (
    <SwipeSlider
      withPagination={withPagination}
      nextNavButtonClass={props.nextNavButtonClass}
      prevNavButtonClass={props.prevNavButtonClass}
      tooltipBox={tooltipBox}
      navigationPosition={navigationPosition}
      withoutEndFrame={withoutEndFrame}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      data={itemList}
      title={sliderTitle}
      slideWidth={slideWidth}
      slideImageHeight={slideImageHeight}
      {...boxProps}
    />
  )
}

export default VendorsSlider
