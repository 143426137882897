import * as yup from 'yup'

import { websiteRegExp } from '../../../../../constants'
import { validateEmail } from '../../../../../utils/helpers/validations'
import { t } from '../../../../../utils/i18n'

export const createValidationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(100)
      .required(
        t('common.validation.contactName', 'Contact name is required!')
      ),
    email: yup
      .string()
      .required(t('common.validation.email', 'Email is required'))
      .test(
        'email',
        t('common.validation.emailInvalid', 'Invalid email address'),
        (email) => {
          return validateEmail(email || '')
        }
      ),
    phone: yup
      .string()
      .required(t('common.validation.phone', 'Phone is required')),
    address: yup
      .object()
      .shape({
        place_id: yup.string(),
        description: yup.string(),
      })
      .test(
        'address',
        t('common.validation.address', 'Address is required'),
        (address) => !!address.description
      ),
    website: yup
      .string()
      .trim()
      .matches(
        websiteRegExp,
        t('common.validation.siteInvalid', 'Invalid website format')
      ),
    facebook: yup
      .string()
      .trim()
      .matches(
        websiteRegExp,
        t('common.validation.siteInvalid', 'Invalid website format')
      ),
    instagram: yup
      .string()
      .trim()
      .matches(
        websiteRegExp,
        t('common.validation.siteInvalid', 'Invalid website format')
      ),
  })
