import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, Route, Switch, useParams } from 'react-router-dom'

import { Documents } from '../../../components/Documents'
import { Messenger } from '../../../components/Messenger'
import { BreadcrumbsProps } from '../../../components/ui/Breadcrumbs'
import { Loader } from '../../../components/ui/Loader'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { DashboardTitle } from '../../../components/ui/PageTitle'
import { SideMenu } from '../../../components/ui/SideMenu'
import { StyledAdd } from '../../../components/ui/TableComponent/TableComponent.styles'
import { getBookingEventMenu } from '../../../constants/bookings'
import { useBusinessInquiryActions } from '../../../hooks/useBusinessInquiryActions'
import { useCurrentBusinessListingId } from '../../../hooks/useCurrentBusinessListingId'
import { useEventList } from '../../../hooks/useEventList'
import { useLatestInquiryFlowElements } from '../../../hooks/useLatestInquiryFlowElements'
import { BusinessInquiryActions } from '../../../types/dtos/inquiry'
import { InquiryWithDetails } from '../../../types/Inquiry'
import {
  fetchJotForms,
  useEventInquiriesStatuses,
} from '../../../utils/api/events'
import {
  updateInquiry,
  useInquiryContractsById,
} from '../../../utils/api/inquiries'
import { getModalContent } from '../../../utils/helpers/bookings'
import {
  dashboardBookingActivityPath,
  dashboardBookingChatPath,
  dashboardBookingContractsListPath,
  dashboardBookingDetailsPath,
  dashboardBookingDocumentsPath,
  dashboardBookingInvoiceListPath,
  dashboardBookingNotesPath,
  dashboardBookingsPath,
  dashboardBookingVendorsInfoPath,
} from '../../../utils/paths'
import { useInquiryDetailsData } from '../../../utils/providers/InqueryDetailsProvider'
import { BookingContracts } from '../BookingContracts'
import { BookingInvoices } from '../BookingInvoices'

import { BlockContainer } from './BookingDetails.styles'
import { Activity } from './components/Activity'
import { EventProgress } from './components/EventProgress'
import { GeneralEventInfo } from './components/GeneralEventInfo'
import { JotFormSubmissions } from './components/JotFormSubmissions'
import { Notes } from './components/Notes'

const useBreadcrumbs = (
  businessId: number,
  inquiryDetails?: InquiryWithDetails
): BreadcrumbsProps => {
  const { data: statusesData } = useEventInquiriesStatuses()

  const status =
    statusesData?.inquiryStatus?.status_info[inquiryDetails?.status || '']
      ?.stage

  return {
    steps: [
      {
        label: 'Bookings',
        route: generatePath(`${dashboardBookingsPath}#${status}`, {
          listingId: businessId,
        }),
      },
    ],
    current: inquiryDetails?.event?.name || '',
  }
}

export const BookingDetailsContent = () => {
  const { id } = useParams<{ id: string }>()
  const { eventsList } = useEventList()
  const { loading, inquiryDetails, updateInquiryDetails } =
    useInquiryDetailsData()
  const { businessId } = useCurrentBusinessListingId()
  const { isLoading, handleBusinessInquiryActions, modalControl } =
    useBusinessInquiryActions()
  const { data: contracts, refetch } = useInquiryContractsById(+id, {
    staleTime: 5000,
  })
  const { latestContract } = useLatestInquiryFlowElements(inquiryDetails)
  const breadcrumbs = useBreadcrumbs(businessId, inquiryDetails)

  const [jotForms, setJotForms] = useState([])

  const vendorInquiryEventVenue = useMemo(() => {
    if (inquiryDetails?.venue_id) return
    return eventsList?.find((event) => event.id === inquiryDetails?.event_id)
      ?.venues?.[0]
  }, [eventsList, inquiryDetails?.event_id])

  useEffect(() => {
    if (inquiryDetails?.event_id) {
      fetchJotForms(inquiryDetails?.event_id).then((response: any) => {
        //use answers for my vendor category only
        const jotFormsByCategory = response.filter(
          (answer: any) =>
            answer.vendor_type === inquiryDetails?.vendor?.type.toString()
        )
        setJotForms(jotFormsByCategory)
      })
    }
  }, [inquiryDetails?.event_id])

  return (
    <Box mr={6} ml={6} width={1}>
      <DashboardTitle breadcrumbs={breadcrumbs} />
      <Box display="flex" mb={4} width={1}>
        <SideMenu
          items={getBookingEventMenu(
            id,
            Boolean(inquiryDetails?.vendor && jotForms && jotForms.length),
            businessId
          )}
        />
        <Box flex="1" display="grid" gridTemplateColumns="2fr 1fr">
          <BlockContainer>
            <Switch>
              <Route
                exact
                path={generatePath(dashboardBookingDetailsPath, {
                  id,
                  listingId: businessId,
                })}
              >
                <EventProgress
                  inquiryDetails={inquiryDetails}
                  handleAction={handleBusinessInquiryActions}
                />
              </Route>
              <Route
                exact
                path={generatePath(dashboardBookingContractsListPath, {
                  id,
                  listingId: businessId,
                })}
              >
                <BookingContracts
                  inquiryDetails={inquiryDetails}
                  contracts={contracts}
                  onGenerate={() =>
                    handleBusinessInquiryActions(
                      BusinessInquiryActions.CONTRACT_GENERATE
                    )
                  }
                />
              </Route>
              <Route
                path={generatePath(dashboardBookingInvoiceListPath, {
                  id,
                  listingId: businessId,
                })}
              >
                <BookingInvoices
                  inquiryDetails={inquiryDetails}
                  onGenerate={(invoicesBundleId?: number) =>
                    handleBusinessInquiryActions(
                      BusinessInquiryActions.INVOICES_GENERATE,
                      { invoicesBundleId }
                    )
                  }
                  onSend={(invoicesBundleId: number) =>
                    handleBusinessInquiryActions(
                      BusinessInquiryActions.INVOICES_BUNDLE_SEND,
                      { invoicesBundleId }
                    )
                  }
                />
              </Route>
              <Route
                path={generatePath(dashboardBookingVendorsInfoPath, {
                  id,
                  listingId: businessId,
                })}
              >
                <JotFormSubmissions jotForms={jotForms} />
              </Route>
              <Route
                path={generatePath(dashboardBookingDocumentsPath, {
                  id,
                  listingId: businessId,
                })}
              >
                <Documents
                  itemDetails={inquiryDetails}
                  updateItemDetails={updateInquiryDetails}
                  updateHandler={updateInquiry}
                />
              </Route>
              <Route
                path={generatePath(dashboardBookingChatPath, {
                  id,
                  listingId: businessId,
                })}
              >
                <Box m={-6}>
                  <Messenger data={inquiryDetails} isModal={false} />
                </Box>
              </Route>
              <Route
                path={generatePath(dashboardBookingActivityPath, {
                  id,
                  listingId: businessId,
                })}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h4">Activity</Typography>
                  <StyledAdd color="primary">View all</StyledAdd>
                </Box>
                <Activity />
              </Route>
              <Route
                path={generatePath(dashboardBookingNotesPath, {
                  id,
                  listingId: businessId,
                })}
              >
                <Notes />
              </Route>
            </Switch>
          </BlockContainer>
          <GeneralEventInfo
            inquiryDetails={inquiryDetails}
            vendorInquiryEventVenue={vendorInquiryEventVenue}
          />
        </Box>
      </Box>
      {(loading || isLoading) && <Loader zIndex={1000} position="fixed" />}
      {inquiryDetails && (
        <SimpleModal
          open={modalControl.isOpen}
          onClose={modalControl.toggleModal}
        >
          {getModalContent(modalControl.modalData?.name, modalControl, {
            latestContract,
            refetch,
          })}
        </SimpleModal>
      )}
    </Box>
  )
}
