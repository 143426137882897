import { Box, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleButton } from '../../../../../../components/ui/Buttons/SimpleButton'
import { BusinessGeneralInfo } from '../../../../../../types/general'
import { useLabels } from '../../../../../../utils/api/labels'
import { isServiceVendor } from '../../../../../../utils/helpers/vendors'
import { BlockTitle, ThinTextField } from '../../../BusinessDetails.styles'
import { LabelsSelect } from '../../Details/components/LabelsSelect'
import { createValidationSchema } from '../validationSchema'

interface DescriptionFormProps {
  onCancel: () => void
  onSuccess?: () => void
  onSave: (data: BusinessGeneralInfo) => void
  entity?: any & { name: string; description: string }
  attachLabelMethod: any
}

interface DescriptionFormData {
  description: string
}

export const GeneralInfoForm: React.FC<DescriptionFormProps> = ({
  onSave,
  onSuccess,
  onCancel,
  entity,
  attachLabelMethod,
}) => {
  const { t } = useTranslation()

  const labels = entity?.labels || []
  const labelsControl = React.useState(labels)
  const exclusivity = isServiceVendor(entity) ? 'vendors' : 'venues'

  const { data: specialFeaturesByType } = isServiceVendor(entity)
    ? useLabels({
        perPage: 100,
        exclusivity,
        type: 'special features',
        vendor_type: entity.type,
        orderBy: 'name',
      })
    : { data: null }
  const { data: specialFeaturesGlobal } = isServiceVendor(entity)
    ? useLabels({
        perPage: 100,
        exclusivity,
        type: 'special features',
        vendor_type: '-all-',
        orderBy: 'name',
      })
    : { data: null }

  const handleFormSubmit = async (values: any) => {
    try {
      const data: BusinessGeneralInfo = {
        description: values.description,
      }
      await onSave(data)
      onSuccess ? onSuccess() : null
    } catch (e) {
      console.error(e)
    }
    onCancel()
  }

  const { handleSubmit, values, handleChange, errors, touched } =
    useFormik<DescriptionFormData>({
      initialValues: {
        description: entity?.description || '',
      },
      validationSchema: createValidationSchema(),
      onSubmit: handleFormSubmit,
    })

  const getTextFieldErrors = (name: keyof DescriptionFormData) => {
    return {
      error: touched[name] && !!errors[name],
      helperText: (touched[name] && errors[name]) || '',
    }
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <BlockTitle>
          {t('dashboard.business.description.title', 'Business Description')}
        </BlockTitle>
        <Box display="flex">
          <Box
            display="flex"
            alignItems="center"
            mr={3}
            sx={{
              cursor: 'pointer',
              color: '#9396A3',
              '&:hover': { opacity: 0.8 },
            }}
            onClick={onCancel}
          >
            {t('common.buttons.cancel', 'Cancel')}{' '}
            <ClearIcon style={{ marginLeft: 10 }} />
          </Box>
          <Box>
            <SimpleButton
              style={{ fontSize: 16 }}
              onClick={() => handleSubmit()}
              variant="outlined"
            >
              {t('common.buttons.save', 'Save')}
            </SimpleButton>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box display="flex" flexDirection="column" mb={5} width={1}>
            <Typography sx={{ mb: 3 }} color="#252733" fontWeight={500}>
              {t('dashboard.business.description.description', 'Description')}
            </Typography>
            <Box display="flex">
              <ThinTextField
                name="description"
                value={values.description}
                placeholder={t(
                  'dashboard.business.description.placeholder',
                  'Type here your service description'
                )}
                onChange={handleChange}
                fullWidth
                multiline
                rows={3}
                {...getTextFieldErrors('description')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {entity && isServiceVendor(entity) && entity.id && (
        <Box display="flex" mt={5}>
          {specialFeaturesByType && specialFeaturesGlobal && (
            <LabelsSelect
              labels={[...specialFeaturesByType, ...specialFeaturesGlobal]}
              entity={entity}
              labelsControl={labelsControl}
              attachLabelMethod={attachLabelMethod}
              type="special features"
            />
          )}
        </Box>
      )}
    </>
  )
}
