import { Box, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { FastTextField } from '../../../../../components/formik'
import { SimpleButton } from '../../../../../components/ui/Buttons/SimpleButton'
import { BlockTitle } from '../../BusinessDetails.styles'

interface FormProps {
  onCancel: () => void
  onSave: (data: { name: string }) => void
  name?: string
}

const createValidationSchema = () =>
  yup.object().shape({
    name: yup.string().trim().max(100).required('Service name is required!'),
  })

export const ServiceNameForm: React.FC<FormProps> = ({
  onSave,
  onCancel,
  name = '',
}) => {
  const { t } = useTranslation()

  return (
    <Formik
      validationSchema={createValidationSchema()}
      initialValues={{ name }}
      onSubmit={onSave}
      enableReinitialize
    >
      {({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <BlockTitle sx={{ marginBottom: '20px' }}>
              Business Name And Photo
            </BlockTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={1}
            >
              <Typography
                sx={{ marginBottom: '15px' }}
                fontSize={14}
                color="#9396A3"
              >
                {t('business.service.name', 'Sevice name')}
              </Typography>
              <Box display="flex" mb={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  mr={3}
                  sx={{
                    cursor: 'pointer',
                    color: '#9396A3',
                    '&:hover': { opacity: 0.8 },
                  }}
                  onClick={onCancel}
                >
                  {t('common.buttons.cancel', 'Cancel')}{' '}
                  <ClearIcon style={{ marginLeft: 10 }} />
                </Box>
                <Box>
                  <SimpleButton
                    style={{ fontSize: 16 }}
                    onClick={() => onSave(values)}
                    type="submit"
                    variant="outlined"
                  >
                    {t('common.buttons.save', 'Save')}
                  </SimpleButton>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mb={5} width={1}>
              <FastTextField
                name="name"
                style={{ fontSize: 14 }}
                placeholder="Type here your service name)"
                fullWidth
              />
            </Box>
          </form>
        )
      }}
    </Formik>
  )
}
