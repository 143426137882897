import { Box, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import House from '../../../../../components/svg/House'
import Plus from '../../../../../components/svg/Plus'
import { TooltipComponent } from '../../../../../components/ui/Tooltip'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { AuthMenuActions } from '../../../../../types/Auth'
import { VendorType } from '../../../../../types/Vendor'
import { vendorsPath } from '../../../../../utils/paths'
import { useAuthData } from '../../../../../utils/providers/AuthProvider'
import {
  CategoryContainer,
  IconContainer,
  StyledCategoryName,
} from '../../Events.styles'
import { InHouseTooltip } from '../tooltips/InHouseTooltip'

interface VendorTypeRowProps {
  category: VendorType
  isInListOfInHouseVendorTypes: boolean
  toggleInHouseVendorModal: () => void
}

export const VendorTypeRow: React.FC<VendorTypeRowProps> = ({
  category,
  isInListOfInHouseVendorTypes,
  toggleInHouseVendorModal,
}) => {
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const { authUser, authModalControl } = useAuthData()

  const { t } = useTranslation()
  const theme = useTheme()

  const { toggleModal: toggleAuthModal } = authModalControl

  const handleVendorSearch = () => {
    if (authUser) {
      history.push(`/d/en/discover/vendor`, {
        insideVenue: true,
      })
    } else {
      toggleAuthModal({
        step: AuthMenuActions.Signin,
        message: 'Please, log in in order to be able to further event planning',
      })
    }
  }

  return isInListOfInHouseVendorTypes ? (
    <CategoryContainer
      justifyContent="flex-start"
      bgcolor={!isMobile && 'rgba(132, 132, 132, 0.12)'}
      sx={{ border: 'none' }}
    >
      <Box
        display="flex"
        pb={0}
        mt={isMobile ? 4 : 0}
        alignItems={isMobile ? 'flex-start' : 'center'}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="flex-start"
      >
        <StyledCategoryName
          isMobile={Boolean(isMobile)}
          isInHouseVendor={isInListOfInHouseVendorTypes}
          variant="body2"
        >
          {category.name}
        </StyledCategoryName>
        <InHouseTooltip toggleInHouseVendorModal={toggleInHouseVendorModal} />
      </Box>
    </CategoryContainer>
  ) : (
    <CategoryContainer>
      <Box display="flex">
        <Typography
          variant="body2"
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            marginRight: '40px',
            minWidth: '140px',
          }}
        >
          {category.name}
        </Typography>
      </Box>
      <Box
        display="flex"
        sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
        onClick={handleVendorSearch}
      >
        <Plus fill={theme.palette.primary.light} />
        <Typography
          sx={{
            marginLeft: '5px',
            color: (theme) => theme.palette.primary.light,
            fontSize: '14px',
            '&:hover': { opacity: 0.8 },
          }}
        >
          {t('common.search', 'Search')}
        </Typography>
      </Box>
    </CategoryContainer>
  )
}
