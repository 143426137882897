const NoDocuments = () => {
  return (
    <svg width="61" height="78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="31" cy="30.39" r="29.97" fill="#F1F1F1" />
      <rect
        x="19.797"
        y="30.385"
        width="28.18"
        height="39.916"
        rx="1"
        transform="rotate(2.5 19.797 30.385)"
        fill="#EEE"
        stroke="#9396A3"
        strokeWidth="2"
      />
      <rect
        x="1.148"
        y="26.822"
        width="32.18"
        height="43.916"
        rx="3"
        transform="rotate(-7.5 1.148 26.822)"
        fill="#FAFAFA"
        stroke="#9396A3"
        strokeWidth="2"
      />
      <path
        d="M2.53 29.666a2 2 0 0 1 1.722-2.244l25.956-3.418a2 2 0 0 1 2.244 1.722l.997 7.576L3.528 37.24l-.997-7.575Z"
        fill="#EEE"
      />
      <rect
        width="1.257"
        height="4.192"
        rx=".629"
        transform="rotate(82.5 5.095 34.564)"
        fill="#9396A3"
      />
      <rect
        width="1.257"
        height="4.192"
        rx=".629"
        transform="rotate(-7.5 151.358 -198.58)"
        fill="#9396A3"
      />
      <rect
        width="1.257"
        height="4.192"
        rx=".629"
        transform="rotate(37.5 -10.991 60.539)"
        fill="#9396A3"
      />
      <rect
        x="6.656"
        y="38.52"
        width="24.311"
        height="1.677"
        rx=".838"
        transform="rotate(-7.5 6.656 38.52)"
        fill="#DCDCDC"
      />
      <rect
        x="7.75"
        y="46.832"
        width="24.311"
        height="1.677"
        rx=".838"
        transform="rotate(-7.5 7.75 46.832)"
        fill="#DCDCDC"
      />
      <rect
        x="8.844"
        y="55.144"
        width="24.311"
        height="1.677"
        rx=".838"
        transform="rotate(-7.5 8.844 55.144)"
        fill="#DCDCDC"
      />
      <rect
        x="7.093"
        y="41.845"
        width="8.802"
        height="1.677"
        rx=".838"
        transform="rotate(-7.5 7.093 41.845)"
        fill="#EEE"
      />
      <rect
        x="8.188"
        y="50.157"
        width="8.802"
        height="1.677"
        rx=".838"
        transform="rotate(-7.5 8.188 50.157)"
        fill="#EEE"
      />
      <rect
        x="9.282"
        y="58.468"
        width="8.802"
        height="1.677"
        rx=".838"
        transform="rotate(-7.5 9.282 58.468)"
        fill="#EEE"
      />
    </svg>
  )
}

export default NoDocuments
