import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FastTextField } from '../../../../../components/formik'

export const InvoicesAdditionalInfoStep: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box mb={3} display="flex" width={1} justifyContent="space-between">
        <Typography variant="body1" fontWeight={600}>
          {t('business.invoices.message', 'Message to client')}
        </Typography>
        <Typography variant="body2" color="#BDBDBD">
          {t('business.invoices.forBoth', 'For both invoices*')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" mb={5}>
        <Box display="flex">
          <FastTextField
            name="message"
            style={{ fontSize: 14 }}
            placeholder="Type here (optional)"
            fullWidth
            multiline
            minRows={2}
            maxRows={4}
          />
        </Box>
      </Box>
      <Box mb={3} display="flex" width={1}>
        <Typography variant="body1" fontWeight={600}>
          {t(
            'business.invoices.extraAny',
            'Any extra things that client should know'
          )}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" mb={5}>
        <Box display="flex">
          <FastTextField
            name="extra"
            style={{ fontSize: 14 }}
            placeholder="Type here (optional)"
            fullWidth
            multiline
            minRows={2}
            maxRows={4}
          />
        </Box>
      </Box>
    </Box>
  )
}
