const Photo = ({ fill = '#9396A3' }) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 2.8125H16.0625L15.3031 0.684375C15.2507 0.538753 15.1545 0.412879 15.0278 0.323947C14.9011 0.235016 14.7501 0.187363 14.5953 0.1875H7.40469C7.08828 0.1875 6.80469 0.386719 6.69922 0.684375L5.9375 2.8125H2.75C1.71406 2.8125 0.875 3.65156 0.875 4.6875V15.375C0.875 16.4109 1.71406 17.25 2.75 17.25H19.25C20.2859 17.25 21.125 16.4109 21.125 15.375V4.6875C21.125 3.65156 20.2859 2.8125 19.25 2.8125ZM19.4375 15.375C19.4375 15.4781 19.3531 15.5625 19.25 15.5625H2.75C2.64688 15.5625 2.5625 15.4781 2.5625 15.375V4.6875C2.5625 4.58438 2.64688 4.5 2.75 4.5H7.12578L7.52656 3.37969L8.06328 1.875H13.9344L14.4711 3.37969L14.8719 4.5H19.25C19.3531 4.5 19.4375 4.58438 19.4375 4.6875V15.375ZM11 6C8.92813 6 7.25 7.67812 7.25 9.75C7.25 11.8219 8.92813 13.5 11 13.5C13.0719 13.5 14.75 11.8219 14.75 9.75C14.75 7.67812 13.0719 6 11 6ZM11 12C9.75781 12 8.75 10.9922 8.75 9.75C8.75 8.50781 9.75781 7.5 11 7.5C12.2422 7.5 13.25 8.50781 13.25 9.75C13.25 10.9922 12.2422 12 11 12Z"
        fill={fill}
      />
    </svg>
  )
}

export default Photo
