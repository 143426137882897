import {
  camelCase,
  isArray,
  isObject,
  map,
  mapKeys,
  mapValues,
  pipe,
} from 'lodash/fp'

/**
 * @see https://medium.com/weekly-webtips/how-to-camelize-object-typing-in-typescript-without-any-library-3fe5165e18c9
 */
export type Camelize<T extends string> = T extends `${infer F}_${infer R}`
  ? `${F}${Capitalize<Camelize<R>>}`
  : T

export type CamelizeKeys<GenericObject> = {
  [ObjectProperty in keyof GenericObject as Camelize<
    ObjectProperty & string
  >]: GenericObject[ObjectProperty] extends Array<infer ArrayItem>
    ? ArrayItem extends Record<string, unknown>
      ? Array<CamelizeKeys<ArrayItem>>
      : GenericObject[ObjectProperty]
    : GenericObject[ObjectProperty] extends Record<string, unknown>
    ? CamelizeKeys<GenericObject[ObjectProperty]>
    : GenericObject[ObjectProperty]
}

export function camelizeKeys<T extends object>(o: T): CamelizeKeys<T> {
  if (!isObject(o)) return o as CamelizeKeys<T>

  return pipe(
    mapKeys((k: string) => camelCase(k)),
    mapValues((v) => {
      if (isArray(v)) {
        return map(camelizeKeys, v)
      } else if (isObject(v)) {
        return camelizeKeys(v)
      } else {
        return v
      }
    })
  )(o) as CamelizeKeys<T>
}
