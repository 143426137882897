import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import DeclineSmall from '../../components/svg/DeclineSmall'
import { ConfirmDialog } from '../../components/ui/Modals/ConfirmDialog'
import { messagesSuccessDeactivatePath } from '../../utils/paths'

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    line-height: 28px;
  }
`

export const WarningDeactivate = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <ConfirmDialog
      isWarning={true}
      confirmLabel={t(
        'messages.account.deactivate.title',
        'Deactivate account'
      )}
      dialogTitle={'Deactivate account?'}
      cancelLabel={'label'}
      onClose={() => history.push(messagesSuccessDeactivatePath)}
      open={true}
    >
      <List>
        <ListItem disableGutters>
          <ListItemIcon>
            <DeclineSmall />
          </ListItemIcon>
          <StyledListItemText
            primary={t(
              'messages.account.deactivate.subtitle',
              'The profile and listings associated with this account will disappear.'
            )}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon>
            <DeclineSmall />
          </ListItemIcon>
          <StyledListItemText
            primary={t(
              'messages.account.deactivate.description',
              'You won’t be able to access the account info or past reservations.'
            )}
          />
        </ListItem>
      </List>
    </ConfirmDialog>
  )
}
