import { Box, Typography, useTheme } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { vendorsPath } from '../../../../../utils/paths'

export const AddMoreVendorsButton = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box
      m="20px"
      display="flex"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
      onClick={() => history.push(vendorsPath)}
    >
      <AddCircle style={{ color: theme.palette.primary.light }} />
      <Typography
        variant="body2"
        fontWeight={500}
        sx={{
          marginLeft: '5px',
          color: (theme) => theme.palette.primary.light,
          '&:hover': { opacity: 0.8 },
        }}
      >
        {t('events.vendors.add', 'Add more vendors')}
      </Typography>
    </Box>
  )
}
