import { Box, InputAdornment, Typography } from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import * as React from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { MessageTemplateItem } from '../../../../types/dtos/messages'
import { PopoverFieldRefProps, PopperField } from '../../../ui/PopoverField'

import { PopoverWrapper, TemplatesInput } from './TemplatePicker.styles'

export const TemplatePicker: React.FC<{
  templates: MessageTemplateItem[]
  onSelect: (templateId: number) => void
}> = ({ templates, onSelect }) => {
  const { t } = useTranslation()
  const popoverRef = useRef<PopoverFieldRefProps>(null)
  const insertTemplate = (id?: number) => {
    popoverRef.current?.onClose()
    if (id) onSelect(id)
  }

  const templatesInput = (
    <TemplatesInput
      endAdornment={
        <InputAdornment position={'end'}>
          <ExpandMoreOutlined sx={{ color: 'secondary.dark' }} />
        </InputAdornment>
      }
      placeholder={t('chat.templates.insert', 'Insert template')}
      readOnly
    />
  )

  const popover = (
    <Box>
      <PopoverWrapper>
        {templates.map((template) => (
          <Box
            display="flex"
            alignItems="center"
            p="12px 16px"
            key={template.id}
            sx={{
              cursor: 'pointer',
              '&:hover': { background: 'rgba(47, 84, 235, 0.05)' },
            }}
            onClick={() => insertTemplate(template.id)}
          >
            <Typography variant="body2">{template.name}</Typography>
          </Box>
        ))}
      </PopoverWrapper>
    </Box>
  )

  return (
    <Box ml="16px">
      <PopperField
        scrollable={false}
        ref={popoverRef}
        input={templatesInput}
        content={popover}
        placement="top-start"
      />
    </Box>
  )
}
