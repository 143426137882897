import {
  Box,
  styled,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'

export const StyledTableRow = styled(TableRow)<any>(
  ({ theme, disabled, hovered }) => `
    background-color: ${theme.palette.common.white};
    .MuiTableCell-root {
        border-bottom: none;
        opacity: ${disabled ? '50%' : '100%'};

        &:last-of-type {
          width: 0;
        }
    }
    
    ${MEDIA_RULES.SMALL} {
      background-color: inherit;
      position: relative;

      .MuiTableCell-root {
        width: 0;

        &:last-of-type > div {
          justify-content: flex-end
        }
      }
    }
`
)

export const NotificationButton = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: center; 
  align-items: center;
  cursor: pointer; 
  background: #F7F7F8; 
  padding: 8px 16px;
  
  .MuiTypography-root {
    margin-left: 5px;
  }
`
)

export const MessagesHeaderBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px;
    box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
    `
)

export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      enterTouchDelay={0}
      arrow
      classes={{ popper: className }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14,
  },
}))

export const IconContainer = styled(Box)`
  z-index: 1;
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: linear-gradient(180deg, #4e4bec 0%, #3f91f0 100%), #4091f0;
`
