export const trimUrl = (url: string): string => {
  const trimmedUrl = url.replace(/(https?:\/)?\/+/gm, '$1/')
  return trimmedUrl.replace(/([^/])\/$/gm, '$1')
}
/**
 * Joins url, removes unnecessary slashes.
 * @see https://github.com/ReactTraining/react-router/issues/4841
 */
export const joinUrl = (
  ...urlParts: (string | number | undefined)[]
): string => {
  return trimUrl(
    urlParts.filter((part) => typeof part !== 'undefined').join('/')
  )
}
