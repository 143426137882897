const Promotion = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 31L5.3 29.5L2.05 28L1 31Z" fill="#F7B600" />
      <path
        d="M13.4499 18.6996L7.3999 12.5996L6.3999 15.3996L13.4499 18.6996Z"
        fill="#FFDD7D"
      />
      <path
        d="M6.40005 15.4004L5.30005 18.5504L18.7 24.8004L19.35 24.6004L13.45 18.7004L6.40005 15.4004Z"
        fill="#F7B600"
      />
      <path
        d="M4.25 21.6998L14.25 26.3498L18.7 24.7998L5.3 18.5498L4.25 21.6998Z"
        fill="#FFDD7D"
      />
      <path
        d="M3.1499 24.8502L9.7499 27.9502L14.2499 26.3502L4.2499 21.7002L3.1499 24.8502Z"
        fill="#F7B600"
      />
      <path
        d="M3.15005 24.8496L2.05005 27.9996L5.30005 29.4996L9.75005 27.9496L3.15005 24.8496Z"
        fill="#FFDD7D"
      />
      <path
        d="M15.95 16.0999C19.3 19.3999 21.05 23.0999 19.85 24.2999C18.6 25.4999 14.9 23.7999 11.5 20.4499C8.15002 17.1499 6.40002 13.4499 7.60002 12.2499C8.85002 11.0499 12.55 12.7999 15.95 16.0999Z"
        fill="#493816"
      />
      <path
        d="M11.75 7.75001C10.95 6.60001 11.8 6.10001 12.9 6.30001C11.85 5.05001 12.5 4.15001 14.15 4.50001C14.65 4.60001 13.95 5.45001 13.5 5.45001C14.85 6.45001 14.1 7.55001 12.65 7.30001C13.95 9.05001 11.75 8.60001 10.75 8.70001C10.5 10 12 11.5 11.5 11.5C10.4 11.5 8.60003 7.35001 11.75 7.75001Z"
        fill="#42ADE2"
      />
      <path
        d="M22.25 10.1498C21.5 10.4998 19.4 7.19983 22 7.14983C20.5 5.79983 20.7 5.14983 22.7 5.09983C20.4 2.79983 24.05 1.99983 24.4 3.19983C24.5 3.54983 23.3 2.89983 22.9 3.54983C22.45 4.29983 25.7 6.24983 22.35 6.09983C23.6 7.34983 23.65 7.94983 21.7 8.14983C21.95 8.54983 22.75 9.94983 22.25 10.1498Z"
        fill="#FF8736"
      />
      <path
        d="M23.1001 17.9498L23.8501 17.2998C23.8501 17.2998 24.5501 18.3498 25.0501 18.7498C25.4501 16.9498 25.3501 15.8998 27.4001 17.0998C26.2501 13.9998 28.1501 15.1498 30.0001 15.9998C29.9001 15.1998 30.0001 15.2998 30.8001 15.0498C31.5001 17.6998 29.6001 16.8998 28.1001 16.0498C29.0001 18.4498 28.0501 18.2998 26.1501 17.4998C26.1001 18.4998 25.8001 19.6498 25.2001 19.7498C24.5001 19.9498 23.1001 17.9498 23.1001 17.9498Z"
        fill="#ED4C5C"
      />
      <path
        d="M17.5001 10.55C16.6001 11.75 15.1501 12.4 14.1001 13.45C13.0001 14.55 12.3501 17.55 12.3501 17.55C12.3501 17.55 12.7501 14.4 13.8001 13.2C14.7501 12.1 16.1501 11.3 16.9001 10.05C18.2001 7.75 17.0001 4.75 15.3001 3C15.6501 2.7 16.1501 2.3 16.4001 2C18.0501 4.05 19.4501 8 17.5001 10.55Z"
        fill="#C28FEF"
      />
      <path
        d="M19.05 13.1C17.75 14.05 16.8 15.45 15.9 16.75C15.1 17.9 12.55 19.35 12.55 19.35C12.55 19.35 14.95 17.7 15.7 16.5C16.6 15 17.5 13.45 18.9 12.35C21.7 10.2 25.75 10.4 28.9 11.55C28.7 12 28.35 12.95 28.35 12.95C28.35 12.95 21.7 11.15 19.05 13.1Z"
        fill="#FF8736"
      />
      <path
        d="M24.6 12.85C23.75 13.95 23.35 15.3 22.7 16.55C22.1 17.7 21.3 18.8 20.15 19.4C18.85 20.05 16 19.85 16 19.85C16 19.85 18.85 19.8 20.05 19C21.25 18.2 21.9 16.8 22.35 15.5C23.25 13 24.35 10.3 26.95 9.20005C27.1 9.65005 27.45 10.6 27.45 10.6C27.45 10.6 26 11 24.6 12.85ZM1.60498 7.66255L3.01898 6.24805L4.43348 7.66205L3.01948 9.07705L1.60498 7.66255Z"
        fill="#42ADE2"
      />
      <path
        d="M3.58643 12.0986L4.99993 10.6846L6.41393 12.0986L4.99993 13.5126L3.58643 12.0986Z"
        fill="#FF8736"
      />
      <path
        d="M7.17896 5.41107L8.59296 3.99707L10.007 5.41107L8.59296 6.82507L7.17896 5.41107Z"
        fill="#ED4C5C"
      />
      <path
        d="M22.6025 22.3481L24.0165 20.9336L25.4305 22.3481L24.0165 23.7621L22.6025 22.3481Z"
        fill="#C28FEF"
      />
      <path
        d="M19.4514 27.1952L20.8654 25.7812L22.2799 27.1957L20.8654 28.6098L19.4514 27.1952Z"
        fill="#ED4C5C"
      />
      <path
        d="M25.6394 28.3032L27.0534 26.8887L28.4674 28.3032L27.0534 29.7172L25.6394 28.3032Z"
        fill="#FF8736"
      />
      <path
        d="M27.039 21.8656L28.453 20.4516L29.867 21.8656L28.453 23.2801L27.039 21.8656ZM24.678 6.91157L26.092 5.49707L27.5065 6.91157L26.0925 8.32557L24.678 6.91157Z"
        fill="#42ADE2"
      />
      <path
        d="M9.52197 15.3964L10.9365 13.9824L12.3505 15.3964L10.9365 16.8109L9.52197 15.3964Z"
        fill="#ED4C5C"
      />
    </svg>
  )
}

export default Promotion
