import { Box, BoxProps, Typography } from '@material-ui/core'
import * as React from 'react'
import { useHistory } from 'react-router'

import { PromotionsLink } from '../../../../../components/cards/labels/PromotionsLink'
import Navigation from '../../../../../components/svg/Navigation'
import { ActionButton } from '../../../../../components/ui/Buttons/ActionButton'
import { Loader } from '../../../../../components/ui/Loader'
import { useVendorTypesWithVendors } from '../../../../../hooks/useVendorTypesWithVendors'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { Vendor, VendorType } from '../../../../../types/Vendor'
import { vendorsMapper } from '../../../../../utils/mappers/vendors'
import { vendorsPath } from '../../../../../utils/paths'

import { VendorTypeListItem } from './VendorTypeListItem'
import { VendorTypesContainer } from './VendorTypes.styles'

interface VendorTypesProps {
  onAddHandle: (category: VendorType) => void
  venue?: number
  titleSize?: number
  title?: string
  containerProps?: BoxProps
  showPickedVendors?: boolean
}

export const VendorTypes: React.FC<VendorTypesProps> = ({
  onAddHandle,
  venue,
  containerProps = {},
  titleSize = 40,
  title = 'Vendors',
  showPickedVendors = true,
}) => {
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const queryParams = { venue }
  const { isLoading, filteredTypes } = useVendorTypesWithVendors(queryParams)

  const [query, setQuery] = React.useState('')

  const onChangeView = () => {
    history.push(vendorsPath)
  }

  return (
    <VendorTypesContainer minHeight={670}>
      {isLoading && <Loader size={30} position="fixed" />}
      {filteredTypes && filteredTypes.length > 0 && (
        <Box {...containerProps} flex={1}>
          <Typography
            fontSize={isMobile ? 24 : titleSize}
            variant="h1"
            sx={{ color: (theme) => theme.palette.secondary.dark }}
          >
            {title}
          </Typography>
          <Box
            display="flex"
            my={4}
            width={1}
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            justifyContent={isMobile ? 'flex-start' : 'flex-end'}
          >
            <ActionButton
              style={{ alignSelf: 'flex-end', marginLeft: 0 }}
              onClick={onChangeView}
              icon={<Navigation size={48} />}
            />
            <Box ml="21px">
              <PromotionsLink toVendors />
            </Box>
          </Box>
          <Box
            display={isMobile ? 'flex' : 'grid'}
            width={1}
            flexDirection="column"
            gridTemplateColumns="auto auto auto auto"
          >
            {filteredTypes.map((item: VendorType) => (
              <VendorTypeListItem
                vendors={item?.vendors
                  ?.filter((vendor: Vendor) => vendor.type === item.id)
                  ?.map((vendor: Vendor) => vendorsMapper(vendor))}
                onAddHandle={onAddHandle}
                item={item}
                key={item.id}
                query={query}
                color={'#003366'}
              />
            ))}
          </Box>
        </Box>
      )}
    </VendorTypesContainer>
  )
}
