import { List, ListItem, ListItemText, styled } from '@material-ui/core'
import { isNil } from 'lodash'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { Loader } from '../../components/ui/Loader'
import { ConfirmDialog } from '../../components/ui/Modals/ConfirmDialog'
import { UserRoles } from '../../constants/profile'
import { usePartner } from '../../hooks/usePartner'
import { useUrlParams } from '../../hooks/useUrlParams'
import { Status } from '../../types/general'
import { goToBlaceDashboard } from '../../utils/helpers/goToBlaceDashboard'
import { dashboardPath, eventPath, getEventListPath } from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'

import { MagicLinkExpired } from './MagicLinkExpired'

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    line-height: 28px;
  }
`

export const DefaultMessage: React.FC<{
  title: string
  message: string
  status?: string
}> = ({ message, title }) => {
  const history = useHistory()
  const { isLoading, authUser, status } = useAuthData()
  const isClient = UserRoles.isClient(authUser?.role)
  const { t } = useTranslation()
  const { urlParams } = useUrlParams()
  const { isPartner, isAbs, partnerSlug } = usePartner()

  const buttonText = useMemo(() => {
    if (isClient) {
      return isAbs
        ? t('partners.abs.bookings.toAll', 'Go to my bookings')
        : urlParams.eventToSubmit
        ? t('common.buttons.toMyNewEvent', 'Go to my new event')
        : t('common.buttons.toEvents', 'Go to my events')
    }
    return t('common.buttons.toDashboard', 'Go to dashboard')
  }, [isClient, urlParams])

  const handleConfirmAction = async () => {
    if (UserRoles.isClient(authUser?.role)) {
      if (!isNil(localStorage.getItem('newEventId'))) {
        const id = JSON.parse(localStorage.getItem('newEventId') as string)
        history.push(
          generatePath(eventPath, {
            id,
          })
        )
        localStorage.removeItem('newEventId')
      } else {
        history.push(getEventListPath())
      }
    } else if (isPartner) {
      await goToBlaceDashboard(partnerSlug, dashboardPath)
    } else {
      history.push(dashboardPath)
    }
  }

  if (status === Status.REQUEST) {
    return <Loader />
  } else if (status === Status.ERROR && urlParams.hash) {
    return <MagicLinkExpired />
  }

  return (
    <ConfirmDialog
      confirmLabel={buttonText}
      dialogTitle={title}
      cancelLabel={'label'}
      onClose={handleConfirmAction}
      open={true}
    >
      <List>
        <ListItem disableGutters>
          <StyledListItemText primary={message} />
        </ListItem>
      </List>
      {isLoading && <Loader position="absolute" />}
    </ConfirmDialog>
  )
}
