import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useWindowSize } from '../../../../hooks/useWindowSize'
import { VenueDetails } from '../../../../types/Venue'
import { AboutWrapper } from '../Venue.styles'

const lineHeight = 20

export const AboutTheSpace: React.FC<{ venue?: VenueDetails | null }> = ({
  venue,
}) => {
  const [readMore, setReadMore] = useState(false)
  const elementRef = useRef<HTMLDivElement | null>(null)
  const [elementHeight, setElementHeight] = useState<number>()

  const { isMobile } = useWindowSize()

  const handleReadMore = () => {
    setReadMore(!readMore)
  }

  useEffect(() => {
    if (!elementRef?.current?.clientHeight) return
    setElementHeight(elementRef?.current?.clientHeight)
  }, [elementRef?.current?.clientHeight])

  const contentHeight = useMemo(() => {
    return isMobile ? lineHeight * 7 : lineHeight * 4
  }, [isMobile])

  return (
    <AboutWrapper>
      {!!venue?.description && (
        <Box display="flex" flexDirection="column" className="section-block">
          <Typography variant="h2">About the Space</Typography>
          <Box>
            <Box
              className={`about ${readMore && 'read-more'} ${
                elementRef?.current &&
                elementHeight &&
                elementHeight > contentHeight &&
                'possible-more'
              }`}
              height={`${
                elementRef?.current &&
                elementHeight &&
                elementHeight > contentHeight
                  ? contentHeight
                  : elementHeight
              }px`}
            >
              <Typography
                ref={elementRef}
                variant="body1"
                style={{
                  lineHeight: `${lineHeight}px`,
                  whiteSpace: 'pre-wrap',
                }}
              >
                {venue?.description}
              </Typography>
            </Box>
            {elementRef?.current &&
              elementHeight &&
              elementHeight > contentHeight && (
                <Typography className="less-more" onClick={handleReadMore}>
                  {readMore ? 'Read Less' : 'Read More'}
                </Typography>
              )}
          </Box>
        </Box>
      )}
    </AboutWrapper>
  )
}
