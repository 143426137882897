import { Box, Link, Typography } from '@material-ui/core'
import * as React from 'react'

import img from '../../assets/images/maintenance.png'

type Props = {
  error?: Error
  errorInfo?: React.ErrorInfo
}

export const ErrorCrash: React.FC<Props> = ({ error, errorInfo }) => {
  React.useEffect(() => {
    if (error) {
      console.error(error, errorInfo)
    }
  }, [error, errorInfo])

  return (
    <Box
      flex={1}
      sx={{ backgroundColor: '#20202C' }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={10}
      margin="auto"
      textAlign="center"
      minHeight="80vh"
    >
      <img height={180} src={img} alt="" />
      <Typography
        fontSize={39}
        sx={{ marginTop: '55px' }}
        fontWeight={500}
        color="#ffffff"
        paragraph
      >
        {"We're down for maintenance."}
      </Typography>
      <Box>
        <Typography
          paragraph
          color="#BDBDBD"
          fontSize={24}
          fontWeight={500}
          sx={{ marginTop: '20px', marginRight: '20px' }}
        >
          Please check back in tomorrow to see our new and improved site.
        </Typography>
      </Box>
      <Box mt={5} display="flex">
        <Typography sx={{ marginRight: '40px', color: '#FFFFFF' }}>
          Need help now?
        </Typography>
        <Link href="mailto:info@blace.co">Email to Blace</Link>
      </Box>
    </Box>
  )
}
