import type { StandardTextFieldProps } from '@material-ui/core/TextField'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import * as React from 'react'

import { useWindowSize } from '../../../../hooks/useWindowSize'

import { StyledTextField } from './OptionSelect.styles'

export type OptionSelectProps = Omit<
  StandardTextFieldProps,
  | 'autoComplete'
  | 'multiline'
  | 'placeholder'
  | 'rows'
  | 'rowsMax'
  | 'select'
  | 'size'
  | 'type'
  | 'select'
  | 'variant'
  // Making these required for accessibility
  | 'id'
> &
  Required<Pick<StandardTextFieldProps, 'id'>> &
  Partial<StandardTextFieldProps>

export const OptionSelect = React.forwardRef(function OptionSelect(
  {
    className,
    InputProps,
    InputLabelProps,
    SelectProps,
    select = true,
    ...props
  }: OptionSelectProps,
  ref: OptionSelectProps['ref']
) {
  const { isMobile } = useWindowSize()
  return (
    <StyledTextField
      {...props}
      isMobile={isMobile}
      className={className}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        classes: {
          ...InputProps?.classes,
          root: InputProps?.classes?.root,
          input: InputProps?.classes?.input,
          focused: InputProps?.classes?.focused,
        },
      }}
      SelectProps={{
        IconComponent: KeyboardArrowDownIcon,
        ...SelectProps,
        native: false,
        classes: {
          ...SelectProps?.classes,
          icon: SelectProps?.classes?.icon,
        },
        MenuProps: {
          ...SelectProps?.MenuProps,
          className: SelectProps?.MenuProps?.className,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          ...InputLabelProps?.classes,
          root: InputLabelProps?.classes?.root,
          disabled: InputLabelProps?.classes?.disabled,
        },
        shrink: false,
      }}
      variant="standard"
      ref={ref}
      select={select}
    />
  )
})
