import { Box, styled, TableCell, TableRow } from '@material-ui/core'

export const BookingInfoWrapper = styled(Box)`
  box-shadow: 0 4px 18px rgba(210, 210, 210, 0.4);
  border-radius: 4px;
  flex-direction: column;
  margin-right: 20px;
  display: flex;
  flex: 1;
`

export const StepNameContainer = styled(Box)`
  .step-number {
    width: 24px;
    height: 24px;
    background: #bdbdbd;
    border-radius: 50%;
    font-family: Poppins;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .step-name {
    color: #bdbdbd;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
  }

  &.active-step {
    .step-number {
      background: #2f54eb;
    }

    .step-name {
      color: #2f54eb;
      font-size: 16px;
      font-weight: 600;
    }
  }
`

export const StyledTableCell = styled(TableCell)`
  padding: 0 20px 15px 0;
  border-bottom: none;
  margin-right: 20px;
`

export const StyledTableRow = styled(TableRow)`
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;

  .MuiTableCell-root:last-child {
    padding-left: 0;
    padding-right: 0;
  }

  .MuiTableCell-root input {
    min-width: 60px;
    // max-width: 100px;
    padding: 8px;
  }
  .MuiTableCell-root .MuiSelect-select {
    padding: 8px 30px 8px 8px;
    width: 250px;
    box-sizing: border-box;
  }
`
