import { Box, styled } from '@material-ui/core'

export const StyledStageContainer = styled(Box)<{
  isActive: boolean
  bgColor: string
  borderColor: string
}>(
  ({ theme, isActive, bgColor, borderColor }) => ` 
  padding: 18px 11px;
  border-radius: 4px;
  width: 96px;
  height: 112px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${isActive ? bgColor : theme.palette.background.paper};
  border: ${isActive ? `1px solid ${borderColor}` : 'none'}; 
`
)

export const StyledStageCircle = styled(Box)<{ bgColor: string }>(
  ({ bgColor }) => `
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  background: ${bgColor};
`
)
