import { styled, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { CheckboxCard } from '../../components/ui/CheckboxCard'
import { WizardProvider, WizardStep } from '../../components/Wizard'
import { onboardingRolePath } from '../../utils/paths'

const SectionHeader = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`

export const ChooseRole: React.FC = () => {
  const history = useHistory()
  const [role, setRole] = useState('')
  const { t } = useTranslation()

  const handleRole = () => {
    const params = location.search || ''

    history.push(`${onboardingRolePath}/${role}` + params)
  }

  return (
    <WizardProvider onFinish={handleRole}>
      <WizardStep
        index={0}
        title={t('onboarding.welcome.title', 'Welcome to BLACE!')}
        subtitle={t(
          'onboarding.welcome.subtitle',
          'Thank you for your interest in working with us. Please fill out and submit this form and a BLACE representative will be in touch.'
        )}
        buttonDisabled={!role}
      >
        <SectionHeader variant="body2">
          {t('onboarding.qst1', 'Are you providing a place or a service?')}
        </SectionHeader>
        <CheckboxCard
          label="Vendor"
          staticImageType="galleryTwo"
          onChange={() => setRole('vendor')}
          checked={role === 'vendor'}
          alt="vendor"
          name="vendor"
        />
        <CheckboxCard
          label="Venue"
          staticImageType="venue"
          onChange={() => setRole('venue')}
          checked={role === 'venue'}
          alt="venue"
          name="venue"
        />
      </WizardStep>
    </WizardProvider>
  )
}
