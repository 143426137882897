import React from 'react'

import { VendorType } from '../../../../types/Vendor'
import {
  StyledCategoryBlock,
  StyledCategoryTypography,
} from '../../PromotionItem/PromotionItem.styles'

export const VendorCategoryLabel: React.FC<{
  vendorCategory: VendorType['name']
}> = ({ vendorCategory }) => {
  return (
    <StyledCategoryBlock>
      <StyledCategoryTypography variant="body1">
        {vendorCategory}
      </StyledCategoryTypography>
    </StyledCategoryBlock>
  )
}
