import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceDetailsModal } from '../../../../../../components/PaymentModal'
import {
  InquiryStatuses,
  UNLOCK_VENDOR_STATUSES,
} from '../../../../../../constants/inquiry'
import { useInvoiceModalData } from '../../../../../../hooks/useInvoiceModalData'
import {
  InquiryInvoiceResponse,
  Invoice,
} from '../../../../../../types/dtos/inquiry'
import { InquiryWithDetails } from '../../../../../../types/Inquiry'

import { Invoices } from './Invoices'

type PaymentInvoicesProps = {
  inquiryDetails?: InquiryWithDetails
  refetch: () => void
  openMessengerHandler: () => void
  fetchEvent: () => void
}

export const PaymentInvoices = ({
  inquiryDetails,
  refetch,
  openMessengerHandler,
  fetchEvent,
}: PaymentInvoicesProps) => {
  const { t } = useTranslation()
  const { formatInvoiceForDetailsModal } = useInvoiceModalData()
  const [openedInvoicesBundle, setOpenedInvoicesBundle] =
    useState<InquiryInvoiceResponse | null>(null)
  const [openedInvoice, setOpenedInvoice] = useState<Invoice | null>(null)
  const availablePaymentMethod = inquiryDetails?.venue
    ? inquiryDetails?.venue.payment_type
    : inquiryDetails?.vendor?.payment_type

  const viewAndPay = (
    invoicesBundle: InquiryInvoiceResponse,
    invoice: Invoice
  ) => {
    setOpenedInvoicesBundle(invoicesBundle)
    setOpenedInvoice(invoice)
  }
  const onSuccessPayment = React.useCallback(async () => {
    let count = 0
    const updateAfterPayment = setInterval(() => {
      refetch()
      fetchEvent()
      count += 1
      if (count === 10) {
        clearInterval(updateAfterPayment)
      }
    }, 6000)
  }, [])

  return (
    <>
      <Invoices
        openMessengerHandler={openMessengerHandler}
        inquiryDetails={inquiryDetails}
        viewAndPay={viewAndPay}
      />
      <InvoiceDetailsModal
        isPaymentAvailable={UNLOCK_VENDOR_STATUSES.includes(
          inquiryDetails?.status as InquiryStatuses
        )}
        availablePaymentMethod={availablePaymentMethod}
        isOpen={!!openedInvoice}
        onClose={() => setOpenedInvoice(null)}
        actionText={t('inquiry.payment.action', 'Make a payment')}
        invoice={formatInvoiceForDetailsModal(
          openedInvoicesBundle,
          openedInvoice
        )}
        onSuccessPayment={onSuccessPayment}
      />
    </>
  )
}
