import { Box, Paper } from '@material-ui/core'
import GoogleMapReact from 'google-map-react'
import * as React from 'react'

import { VenueLocation } from '../../../../types/Venue'
import { venueListQueryParams } from '../../../../utils/mappers/venueListQueryParamsMapper'

import { ExpandMapButton } from './ExpandMapButton'
import { mapStyles } from './mapStyles'
import Marker from './Marker'
import { ShowVenuesInThisAreaButton } from './ShowVenuesInThisAreaButton'

interface VenuesMapProps {
  updateQueryParams: (updatedQueryParams: any) => void
  initialValues: venueListQueryParams
  doExpandMap: (param: any) => void
  isExpandedMap: boolean
  venues: VenueLocation[]
}

const formatLocationPoint = (lat: number, lng: number) => {
  return `${lat},${lng}`
}

const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || ''
export const MapSearch: React.FC<VenuesMapProps> = ({
  venues,
  updateQueryParams,
  initialValues,
  doExpandMap,
  isExpandedMap,
}) => {
  const [map, setMap] = React.useState(null)
  const [markerInfo, setMarkerInfo] = React.useState<VenueLocation | null>(null)

  let defaultCenter = {
      lat: 40.66545,
      lng: -74.0543,
    },
    defaultZoom = 11

  if (!map && initialValues.location) {
    const coords = initialValues.location.split(',')
    defaultCenter = {
      lat: parseFloat(coords[0]),
      lng: parseFloat(coords[1]),
    }
  }
  if (!map && initialValues.zoom) {
    defaultZoom = Number(initialValues.zoom)
  }

  const onRefreshVenues = () => {
    doFetch(map)
  }
  // @ts-ignore
  const onMapLoad = ({ map, maps }) => {
    setMap(map)
  }

  const onChildClickCallback = (key: any) => {
    setMarkerInfo(venues[key])
  }

  const handleClickAway = () => {
    setMarkerInfo(null)
  }

  const doFetch = function (map: any) {
    const ne = map.getBounds().getNorthEast()
    // @ts-ignore
    const sw = map.getBounds().getSouthWest()
    const params = {
      ...initialValues,
      zoom: map.getZoom(),
      perPage: 50,
      location: formatLocationPoint(
        map.getCenter().lat(),
        map.getCenter().lng()
      ),
    }
    updateQueryParams(params)
  }

  return (
    <Box display="flex" flex={1} width="100%">
      <Paper variant="outlined" style={{ width: '100%' }}>
        <Box width="100%" height="100%" position="relative">
          <Box
            position="absolute"
            display="flex"
            flexDirection="row"
            width="100%"
            padding="15px"
            zIndex={100}
          >
            <ExpandMapButton
              isExpandedMap={isExpandedMap}
              doExpandMap={doExpandMap}
            />
            <Box margin="0 auto">
              <ShowVenuesInThisAreaButton onRefreshVenues={onRefreshVenues} />
            </Box>
          </Box>
          <GoogleMapReact
            options={{
              styles: mapStyles,
              fullscreenControl: false,
            }}
            onChildClick={onChildClickCallback}
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{ key: googleMapsKey }}
            onGoogleApiLoaded={onMapLoad}
            defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
          >
            {venues.map((item: VenueLocation, index: number) => {
              return (
                item.location_address && (
                  <Marker
                    handleClickAway={handleClickAway}
                    id={item.id}
                    markerInfo={item}
                    key={index}
                    lat={item.location_address.lat}
                    lng={item.location_address.lng}
                  />
                )
              )
            })}
          </GoogleMapReact>
        </Box>
      </Paper>
    </Box>
  )
}
