import { TableCell } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import * as React from 'react'
import { useRouteMatch } from 'react-router'
import { generatePath } from 'react-router-dom'

import { PopperMenu } from '../../../../components/ui/PopperMenu'
import { ItemDates } from '../../../../components/ui/TableComponent/table-cell-components/ItemDates'
import { ItemName } from '../../../../components/ui/TableComponent/table-cell-components/ItemName'
import { ItemPersonsQuantity } from '../../../../components/ui/TableComponent/table-cell-components/ItemPersonsQuantity'
import { StyledTableRow } from '../../../../components/ui/TableComponent/TableComponent.styles'
import { TABLE_MENU_ACTIONS } from '../../../../constants/profile'
import {
  COLUMN_WIDTH_160,
  COLUMN_WIDTH_300,
} from '../../../../constants/tables'
import { InquiryEvent } from '../../../../types/Inquiry'
import { dashboardBookingDetailsPath } from '../../../../utils/paths'
import { useAuthData } from '../../../../utils/providers/AuthProvider'
import { InquiryActionButton } from '../InquiryActionButton'

interface InquiryItemProps {
  event: InquiryEvent
  inquiriesPartnersCount: number
  handleOnMenuOptionSelect: (action: string, data: any) => void
}

export const InquiryEventItem: React.FC<InquiryItemProps> = ({
  event,
  inquiriesPartnersCount,
  handleOnMenuOptionSelect,
}) => {
  const inquiry = event.inquiry
  const { authUser } = useAuthData()

  const {
    params: { listingId },
  } = useRouteMatch<{ listingId: string }>()

  const handleMenuSelect = (action: string) => {
    handleOnMenuOptionSelect(action, event)
  }

  const handleInquiryItemSubmit = async () => {
    handleOnMenuOptionSelect('accept', event)
  }

  return (
    <StyledTableRow id={inquiry.id.toString()}>
      <TableCell sx={{ maxWidth: COLUMN_WIDTH_300 }}>
        <ItemName
          item={event}
          isInquiry={true}
          inquiriesPartnersCount={inquiriesPartnersCount}
          linkToNavigate={generatePath(dashboardBookingDetailsPath, {
            id: inquiry.id,
            listingId: authUser?.listing_id || +listingId,
          })}
        />
      </TableCell>
      <TableCell>
        <ItemDates item={event} />
      </TableCell>
      <TableCell padding="checkbox" style={{ width: COLUMN_WIDTH_160 }}>
        <ItemPersonsQuantity quantity={event.guests?.toString() || ''} />
      </TableCell>
      <TableCell>
        <InquiryActionButton
          sx={{ '&:hover': { opacity: 0.95 } }}
          onClick={handleInquiryItemSubmit}
          status={inquiry.status}
        />
      </TableCell>
      <TableCell color="textSecondary">
        <PopperMenu
          options={TABLE_MENU_ACTIONS}
          onSelectAction={handleMenuSelect}
        >
          <MoreHoriz
            className="event-action"
            sx={{ '&:hover': { opacity: 0.8 } }}
          />
        </PopperMenu>
      </TableCell>
    </StyledTableRow>
  )
}
