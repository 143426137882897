import {
  BecomeLandlordRequest,
  BecomeVendorRequest,
} from '../../types/dtos/profile'

export const becomeVendorMapper = (item: Partial<BecomeVendorRequest>) => {
  const result = <Record<string, string | number>>{}
  if (item.contact_email) result.contact_email = item.contact_email
  if (item.contact_name) result.contact_name = item.contact_name
  if (item.name) result.name = item.name
  if (item.description) result.description = item.description
  if (item.phone) result.phone = item.phone
  if (item.type) result.type = item.type
  if (item.website) result.website = item.website
  if (item.legal_entity) result.legal_entity = item.legal_entity
  if (item.employees) result.employees_string = item.employees
  if (item.office) result.office = item.office.place_id
  if (item.order_price_min) result.order_price_min = item.order_price_min

  return result
}

export const becomeLandlordMapper = (item: Partial<BecomeLandlordRequest>) => {
  const result = <Record<string, string | number>>{}
  if (item.contact_email) result.contact_email = item.contact_email
  if (item.contact_name) result.contact_name = item.contact_name
  if (item.name) result.name = item.name
  if (item.phone) result.phone = item.phone
  if (item.website) result.website = item.website
  if (item.legal_entity) result.legal_entity = item.legal_entity
  if (item.employees) result.employees_string = item.employees
  if (item.office) result.office = item.office
  if (item.locations?.length)
    result.google_place_id = item.locations[0].place_id

  return result
}
