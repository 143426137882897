import { isAfter, startOfDay } from 'date-fns'

import { InquiryInvoiceStatuses } from '../../constants/inquiry'
import { Invoice } from '../../types/dtos/inquiry'

const isPaid = (invoice: Invoice) =>
  invoice.status === InquiryInvoiceStatuses.STATUS_PAID
export type InvoicePaymentDueState = 'paid' | 'pass-due' | 'pending'

export const invoicePaymentDueState =
  (date: Date) =>
  (invoice: Invoice): InvoicePaymentDueState =>
    isPaid(invoice)
      ? 'paid'
      : isAfter(date, startOfDay(new Date(invoice.expiration_date)))
      ? 'pass-due'
      : 'pending'
