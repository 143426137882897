import { List, ListItem, ListItemText, styled } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ConfirmDialog } from '../../components/ui/Modals/ConfirmDialog'
import { venuesPath } from '../../utils/paths'

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    line-height: 28px;
  }
`

export const MagicLinkExpired = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <ConfirmDialog
      isWarning={true}
      confirmLabel={t('common.go_home', 'Go Home')}
      dialogTitle={t('common.magic_link_expired', 'Magic Link Expired')}
      cancelLabel={'label'}
      onClose={() => history.push(venuesPath)}
      open={true}
    >
      <List>
        <ListItem disableGutters>
          <StyledListItemText
            primary={t(
              'common.magic_link_expired_text',
              'Magic link expired. Please request a new one'
            )}
          />
        </ListItem>
      </List>
    </ConfirmDialog>
  )
}
