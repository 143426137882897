import vendorTypes from '../fixtures/vendorTypes.json'

import { factory } from './factory'

const vendorTypeFactory = () =>
  factory({
    type: 'Catering',
    description: ' ',
    slug: 'catering',
    vendors_count: 3,
  })

export const vendorTypesFactory = () =>
  function* () {
    yield vendorTypes
  }
