import { Box, styled } from '@material-ui/core'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router'

const Tabs = styled(Box)`
  .tab {
    padding: 9px 16px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background: #f9f9f9;

    &.active {
      background: #fff;
      box-shadow: 0px 10px 10px 0px white,
        0px 0px 10px 0px rgba(34, 60, 80, 0.2);
    }
  }
`

export const BookmarkTabs: React.FC<{
  tabs: Array<{ value: string; label: string }>
  onChange?: (tab: string) => void
}> = ({ tabs, onChange = () => {} }) => {
  const location = useLocation()
  const history = useHistory()
  const initialValue = location.hash
    ? location.hash.replace('#', '')
    : tabs[0].value

  const [navValue, setNavValue] = React.useState(initialValue)
  const onChangeNavValue = (value: string) => {
    setNavValue(value)
    history.replace({
      hash: `${value}`,
    })
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Tabs display="flex" width={1} alignItems="center">
      {tabs.map((tab, index) => (
        <Box
          key={index}
          className={`tab ${navValue === tab.value ? 'active' : ''}`}
          sx={{ '&:hover': { opacity: 0.8 } }}
          onClick={() => onChangeNavValue(tab.value)}
        >
          {tab.label.toUpperCase()}
        </Box>
      ))}
    </Tabs>
  )
}
