import i18n from 'i18next'
import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { generatePath } from 'react-router-dom'
import { InquiryContractResponse } from 'types/dtos/inquiry'
import { dashboardBookingDetailsPath } from 'utils/paths'

import { bookingQueryParams } from '../../constants/bookings'
import { simpleModalControl } from '../../hooks/useSimpleModal'
import { AddContractModal } from '../../pages/dashboard/components/modals/AddContract'
import EventsCalendarModal from '../../pages/dashboard/components/modals/EventsCalendar/EventsCalendar'
import InquiryAcceptedModal from '../../pages/dashboard/components/modals/InquiryAccepted/InquiryAccepted'
import { InquiryCreateForm } from '../../pages/dashboard/components/modals/InquiryCreate'
import InquiryRejectedModal from '../../pages/dashboard/components/modals/InquiryRejected/InquiryRejected'
import SelectAbsVenueModal from '../../pages/dashboard/components/modals/SelectVenueType/SelectAbsVenue'
import SelectVenueTypeModal from '../../pages/dashboard/components/modals/SelectVenueType/SelectVenueType'
import { EventVenue } from '../../types/Event'
import { useAuthData } from '../providers/AuthProvider'

import { addDays, addMonth, addWeeks, urlDateFormat } from './date'

export const getModalContent = (
  name: string,
  modalControl: simpleModalControl,
  extra?: {
    isAbs?: boolean
    shouldUpdateDefaultAbsVenue?: boolean
    shouldOpenCreateEventForm?: boolean
    setIsCreateAbsEventFormOpen?: Dispatch<SetStateAction<boolean>>
    setCurrentAbsVenue?: Dispatch<SetStateAction<EventVenue | null>>
    latestContract?: InquiryContractResponse
    refetch?: () => void
  }
) => {
  const { toggleModal, modalData } = modalControl
  const history = useHistory()
  const { authUser } = useAuthData()
  const {
    params: { listingId },
  } = useRouteMatch<{ listingId: string }>()

  switch (name) {
    case 'inquiryCreate':
      return (
        <InquiryCreateForm
          key={modalData.venue.id}
          onClose={toggleModal}
          venue={modalData.venue}
          rooms={modalData.rooms}
          step={modalData.step}
        />
      )
    case 'inquiryEdit':
      return (
        <InquiryCreateForm
          key={modalData.venue.id}
          onClose={toggleModal}
          venue={modalData.venue}
          rooms={modalData.rooms}
          isEdit={true}
        />
      )
    case 'inquiryAccept':
      return (
        <InquiryAcceptedModal
          onViewDetails={() => {
            if (modalData?.event?.inquiry?.id) {
              toggleModal()
              history.push(
                generatePath(dashboardBookingDetailsPath, {
                  id: modalData.event.inquiry.id,
                  listingId: authUser?.listing_id || parseInt(listingId),
                })
              )
            }
          }}
        />
      )
    case 'inquiryReject':
      return (
        <InquiryRejectedModal
          onReject={toggleModal}
          data={modalData.event}
          onRejectAction={modalData.onRejectAction}
        />
      )
    case 'selectVenueType':
      return <SelectVenueTypeModal modalControl={modalControl} />
    case 'selectAbsVenue':
      return (
        <SelectAbsVenueModal
          modalControl={modalControl}
          shouldUpdateDefaultAbsVenue={extra?.shouldUpdateDefaultAbsVenue}
          shouldOpenCreateEventForm={extra?.shouldOpenCreateEventForm}
          setIsCreateAbsEventFormOpen={extra?.setIsCreateAbsEventFormOpen}
          setCurrentAbsVenue={extra?.setCurrentAbsVenue}
        />
      )
    case 'calendar':
      return (
        <EventsCalendarModal
          onClose={toggleModal}
          calendarGoogleIds={modalData.clientCalendars}
        />
      )
    case 'addContract':
      return (
        <AddContractModal
          onClose={() => {
            toggleModal()
            extra?.refetch?.()
          }}
          latestContract={extra?.latestContract}
        />
      )
    default:
      return null
  }
}

const getOrderBy = (field: string) => {
  switch (field) {
    case 'date':
      return 'start_date'
    case 'status':
      return 'inquiry_status'
    case 'Submission Date':
      return 'created_at'
    case 'Last Modified Date':
      return 'updated_at'
    default:
      return field
  }
}

export const prepareQueryParams = (options: bookingQueryParams) => {
  let to_date = addWeeks(1)
  const preparedParams = {
    ...options,
    orderBy: options.orderBy ? getOrderBy(options.orderBy) : '',
    stage: options.stage !== 'all' && options.stage ? options.stage : '',
  }

  switch (options.viewBy) {
    case 'day':
      to_date = addDays(1)
      // @ts-ignore
      delete preparedParams.viewBy
      return {
        ...preparedParams,
        to_date: urlDateFormat(to_date),
        // from_date: urlDateFormat(today),
      }
    case 'week':
      // @ts-ignore
      delete preparedParams.viewBy
      return {
        ...preparedParams,
        to_date: urlDateFormat(to_date),
        // from_date: urlDateFormat(today),
      }
    case 'month':
      // @ts-ignore
      delete preparedParams.viewBy
      to_date = addMonth(1)
      return {
        ...preparedParams,
        to_date: urlDateFormat(to_date),
        // from_date: urlDateFormat(today),
      }
    case 'all':
      // @ts-ignore
      delete preparedParams.viewBy
      return {
        ...preparedParams,
      }
    default:
      return preparedParams
  }
}
