import * as yup from 'yup'

const MAX_NAME_LENGTH = 45
const MAX_NOTES_LENGTH = 1024
const DEFAULT_MAX_GUESTS = 32767

export const InquirySchema = ({ maxGuests }: { maxGuests: number }) =>
  yup.object().shape({
    name: yup.string().max(MAX_NAME_LENGTH).required('Event name is required'),
    style: yup.string(),
    guests: maxGuests
      ? yup
          .number()
          .min(1)
          .max(maxGuests)
          .required('Number of guests is required')
      : yup
          .number()
          .min(1)
          .max(DEFAULT_MAX_GUESTS)
          .required('Number of guests is required'),
    typeId: yup.string().required('Event type is required'),
    isMultipleDates: yup.boolean(),
    isDatesDecided: yup.boolean(),
    notes: yup.string().trim().max(MAX_NOTES_LENGTH),
  })
