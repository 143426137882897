const OpenWindow = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4682 7.55649L10.4682 7.55654L10.4707 7.55404L14.65 3.37476V7.04167C14.65 7.23395 14.7264 7.41835 14.8623 7.55432C14.9983 7.69028 15.1827 7.76667 15.375 7.76667C15.5673 7.76667 15.7517 7.69028 15.8877 7.55432C16.0236 7.41835 16.1 7.23395 16.1 7.04167V1.625C16.1 1.43272 16.0236 1.24831 15.8877 1.11235L15.8169 1.18306L15.8877 1.11235C15.7517 0.976384 15.5673 0.9 15.375 0.9H9.95833C9.76605 0.9 9.58165 0.976384 9.44568 1.11235C9.30972 1.24831 9.23333 1.43272 9.23333 1.625C9.23333 1.81728 9.30972 2.00169 9.44568 2.13765C9.58165 2.27362 9.76605 2.35 9.95833 2.35H13.6252L9.44596 6.52929L9.44591 6.52925L9.44351 6.53183C9.31544 6.66926 9.24572 6.85104 9.24904 7.03887C9.25235 7.22669 9.32844 7.4059 9.46127 7.53873C9.5941 7.67156 9.77331 7.74765 9.96113 7.75096C10.149 7.75428 10.3307 7.68456 10.4682 7.55649ZM2.74785 2.74785C3.00258 2.49311 3.34808 2.35 3.70833 2.35H7.04167C7.23395 2.35 7.41835 2.27362 7.55432 2.13765C7.69028 2.00169 7.76667 1.81728 7.76667 1.625C7.76667 1.43272 7.69028 1.24831 7.55432 1.11235C7.41835 0.976384 7.23395 0.9 7.04167 0.9H3.70833C2.96352 0.9 2.24921 1.19588 1.72254 1.72254C1.19588 2.24921 0.9 2.96352 0.9 3.70833V13.2917C0.9 14.0365 1.19588 14.7508 1.72254 15.2775C2.24921 15.8041 2.96352 16.1 3.70833 16.1H13.2917C14.0365 16.1 14.7508 15.8041 15.2775 15.2775C15.8041 14.7508 16.1 14.0365 16.1 13.2917V9.95833C16.1 9.76605 16.0236 9.58165 15.8877 9.44568C15.7517 9.30972 15.5673 9.23333 15.375 9.23333C15.1827 9.23333 14.9983 9.30972 14.8623 9.44568C14.7264 9.58165 14.65 9.76605 14.65 9.95833V13.2917C14.65 13.6519 14.5069 13.9974 14.2522 14.2522C13.9974 14.5069 13.6519 14.65 13.2917 14.65H3.70833C2.95777 14.65 2.35 14.0415 2.35 13.2917V3.70833C2.35 3.34808 2.49311 3.00258 2.74785 2.74785Z"
        fill="#2F54EB"
        stroke="#2F54EB"
        strokeWidth="0.2"
      />
    </svg>
  )
}

export default OpenWindow
