import { Box, styled } from '@material-ui/core'

export const VendorDetailsWrapper = styled(Box)(
  ({ theme }) => `
    .vendor-media {
        margin-bottom: 16px;
    }
    
    .vendor-about {
        // margin-right: 24px;
        // background: gray;
    }
    
    .packages {
        box-shadow: 10px 10px 50px 3px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }
`
)
