import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useMemo } from 'react'

import Idea from '../../../components/svg/Idea'
import Stars from '../../../components/svg/Stars'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { Vendor } from '../../../types/Vendor'

export const VendorAbout: React.FC<{ vendor: Vendor | null }> = ({
  vendor,
}) => {
  const { isMobile } = useWindowSize()
  const shouldRenderLabelsProTipBlock = useMemo(() => {
    return (
      (!!vendor?.labels &&
        !!vendor?.labels?.filter((label) => label.type === 'special features')
          .length) ||
      !!vendor?.info?.proTip
    )
  }, [vendor])

  return (
    <>
      {shouldRenderLabelsProTipBlock && (
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          className="section-block"
        >
          {!!vendor?.labels &&
            !!vendor?.labels?.filter(
              (label) => label.type === 'special features'
            ).length && (
              <Box display="flex" flex={1} mr={5} flexDirection="column">
                <Box
                  display="flex"
                  alignItems="center"
                  minHeight="28px"
                  mb="32px"
                >
                  <Box>
                    <Stars />
                  </Box>
                  <Typography
                    variant="h2"
                    sx={{
                      color: (theme) => theme.palette.secondary.dark,
                      marginLeft: 5,
                      marginBottom: 0,
                    }}
                  >
                    Special Features
                  </Typography>
                </Box>
                <Box sx={{ paddingLeft: 4 }}>
                  {vendor?.labels
                    ?.filter((label) => label.type === 'special features')
                    .map((label) => (
                      <Typography
                        key={label.id}
                        sx={{
                          textTransform: 'capitalize',
                          display: 'list-item',
                          my: '5px',
                          mb: '10px',
                          fontWeight: 500,
                        }}
                        variant="body1"
                      >
                        {label.name}
                      </Typography>
                    ))}
                </Box>
              </Box>
            )}
          {!!vendor?.info?.proTip && (
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              mt={isMobile ? 5 : 0}
            >
              <Box display="flex" alignItems="center" mb="32px">
                <Box>
                  <Idea />
                </Box>
                <Typography
                  variant="h2"
                  sx={{
                    color: (theme) => theme.palette.secondary.dark,
                    marginLeft: 5,
                    marginBottom: 0,
                  }}
                >
                  BLACE Pro-Tip
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                  {vendor?.info.proTip}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {!!vendor?.description && (
        <Box
          display="flex"
          className="section-block"
          flex={1}
          flexDirection="column"
        >
          <Typography
            sx={{ color: (theme) => theme.palette.secondary.dark }}
            variant="h2"
          >
            About
          </Typography>
          <Typography style={{ whiteSpace: 'pre-wrap' }}>
            {vendor?.description}
          </Typography>
        </Box>
      )}
    </>
  )
}
