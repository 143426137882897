import { Box, styled, Typography } from '@material-ui/core'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import LinkText from '../../components/ui/Buttons/LinkText/LinkText'
import { ConfirmDialog } from '../../components/ui/Modals/ConfirmDialog'
import { eventPath } from '../../utils/paths'
import { useStorageEventsData } from '../../utils/providers/EventsProvider'

const MessageWrapper = styled(Box)`
  p {
    display: inline;
    text-align: left;
  }
`

export const SuccessSubmitInquiryLink = () => {
  const { getUserActiveEvent } = useStorageEventsData()
  const history = useHistory<{ email: string }>()
  const email = history.location.state?.email || ''
  const { t } = useTranslation()

  const activeEvent = getUserActiveEvent()

  return (
    <ConfirmDialog
      dialogTitle={t('messages.there', "You're almost there!")}
      cancelLabel={'label'}
      textProps={{ style: { align: 'left' } }}
      onClose={() =>
        history.push(
          generatePath(eventPath, {
            id: activeEvent?.id,
          })
        )
      }
      open={true}
    >
      <MessageWrapper mb={3}>
        <Trans i18nKey="messages.inquiry.success.link" shouldUnescape={true}>
          <Typography variant="body1">We&apos;ve sent an email to </Typography>
          <LinkText variant="body1">{{ email }}</LinkText>
          <Typography variant="body1">
            , containing an authorization link that will automatically log you
            in, so you can continue planning your event.
            <br />
          </Typography>
          <Typography variant="body1">Enjoy!</Typography>
        </Trans>
      </MessageWrapper>
    </ConfirmDialog>
  )
}
