import { rest } from 'msw'
import { eventFactory } from 'test/factories/event'
import { build, buildMany } from 'test/factories/factory'
import { inquiryAndEventStatusesFactory } from 'test/factories/inquiryStatus'
import { lineItemsFactory } from 'test/factories/line-item'
import { locationsFactory } from 'test/factories/locations'
import { settingsFactory } from 'test/factories/settings'
import { vendorTypesFactory } from 'test/factories/vendorTypes'
import {
  clientEventsApiPath,
  getEventTypesApiPath,
  inquiryStatusesApiPath,
  jotFormsPath,
  lineItemsApiPath,
  lineItemsTypesApiPath,
  locationsApiPath,
  settingsApiPath,
  vendorTypesApiPath,
} from 'utils/paths'

import { eventTypesFactory } from '../../../test/factories/eventTypes'

const testHandlers = [
  rest.get(clientEventsApiPath, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(buildMany(3, eventFactory())))
  }),

  rest.all(/.*/, (req, res, ctx) => {
    // Fail unhandled requests for tests to avoid having to add handlers for every existing request
    return res(ctx.status(404))
  }),
]

const devHandlers = [
  rest.all(/.*/, (req, res, ctx) => {
    return req.passthrough()
  }),
]

export const handlers = [
  rest.get(jotFormsPath, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]))
  }),

  rest.get(locationsApiPath, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(build(locationsFactory())))
  }),

  rest.get(lineItemsApiPath, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(build(lineItemsFactory())))
  }),

  rest.get(lineItemsTypesApiPath, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        hard_goods: 'Hard Goods',
        service: 'Service',
      })
    )
  }),

  rest.get(settingsApiPath, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(build(settingsFactory())))
  }),

  rest.get(vendorTypesApiPath().replace(/\?.*$/, ''), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(build(vendorTypesFactory())))
  }),

  rest.get(inquiryStatusesApiPath, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(build(inquiryAndEventStatusesFactory()))
    )
  }),

  rest.get(getEventTypesApiPath(), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(build(eventTypesFactory())))
  }),

  ...(process.env.NODE_ENV === 'test' ? testHandlers : []),
  ...(process.env.NODE_ENV === 'development' ? devHandlers : []),
]
