const Plus = ({ fill = '#9396A3' }) => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.414 2.969h1.172c.104 0 .156.052.156.156v13.75c0 .104-.052.156-.156.156H9.414c-.104 0-.156-.052-.156-.156V3.125c0-.104.052-.156.156-.156Z"
        fill={fill}
      />
      <path
        d="M3.438 9.258h13.124c.105 0 .157.052.157.156v1.172c0 .104-.052.156-.157.156H3.438c-.104 0-.156-.052-.156-.156V9.414c0-.104.052-.156.156-.156Z"
        fill={fill}
      />
    </svg>
  )
}

export default Plus
