import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  styled,
  Typography,
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import * as React from 'react'
import { useHistory } from 'react-router'
import { CSSObject } from 'styled-components'

import BlaceLogo from '../../assets/images/blace.png'
import { homeUrl, venuesPath } from '../../utils/paths'
import Burger from '../svg/Burger'
import LogoWhite from '../svg/LogoWhite'

import { SideNavList } from './SideNavList'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(15)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(15)} + 1px)`,
    overflow: 'visible',
  },
})

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '.MuiDrawer-paper': {
    background: theme.palette.secondary.dark,
    color: theme.palette.common.white,
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export const DrawerHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
  // @ts-ignore
})(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(7),
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

export const SideNav: React.FC<{}> = ({}) => {
  const [openSideNav, setOpenSideNav] = React.useState(true)
  const history = useHistory()

  return (
    <StyledDrawer variant="permanent" anchor="left" open={openSideNav}>
      {/*@ts-ignore*/}
      <DrawerHeader open={openSideNav}>
        {openSideNav ? (
          <Link
            underline="none"
            href={homeUrl}
            className="logo-wrapper"
            component="a"
            width={122}
            height={22}
          >
            <img src={BlaceLogo} width={122} />
          </Link>
        ) : (
          <Link sx={{ display: 'flex' }} underline="none" href={venuesPath}>
            <IconButton>
              <LogoWhite />
            </IconButton>
          </Link>
        )}
      </DrawerHeader>
      <Box display="flex" justifyContent="center">
        <IconButton
          onClick={() => setOpenSideNav(!openSideNav)}
          style={{ padding: '15px 10px' }}
        >
          <Burger fill={openSideNav ? '#597EF7' : '#ffff'} />
        </IconButton>
      </Box>
      <SideNavList isOpen={openSideNav} />
      <Divider />
    </StyledDrawer>
  )
}
