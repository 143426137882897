import { Box, Typography } from '@material-ui/core'
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik'
import { keys } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FastTextField } from '../../../../../../../components/formik'
import { RadioButtonsSwitcher } from '../../../../../../../components/formik/RadioButtonsSwitcher'
import CheckedCalendar from '../../../../../../../components/svg/CheckedCalendar'
import DollarCircle from '../../../../../../../components/svg/DollarCircle'
import { CurrencyTextInput } from '../../../../../../../components/ui/Inputs/CurrencyInput'
import { useWizard } from '../../../../../../../components/Wizard'
import { usePartner } from '../../../../../../../hooks/usePartner'
import { InquiryCreationStep } from '../../../../../../../types/Inquiry'
import { FieldLabel, FieldWrapper } from '../ABSInquiryCreate.styles'

import { WizardStep } from './WizardStep'

export const InquiryBudget: React.FC<{
  step: InquiryCreationStep
  isLoading: boolean
  isDuplicate?: boolean
}> = ({ step, isLoading, isDuplicate }) => {
  const { wizardData } = useWizard()
  const { t } = useTranslation()
  const { isAbs } = usePartner()
  const { values, errors } = useFormikContext<FormikValues>()
  const isSaveButtonDisabled = Boolean(keys(errors)?.length)

  return (
    <WizardStep
      isLoading={isLoading}
      title={isDuplicate ? '' : step.name}
      index={step.index}
      buttonDisabled={isSaveButtonDisabled}
      stepData={wizardData}
    >
      <Box display="flex">
        <DollarCircle fill="#9396A3" />
        <FieldLabel variant="body2" sx={{ ml: '5px' }}>
          {t('events.budget.title', 'Budget')}
        </FieldLabel>
      </Box>
      <Box display="flex" flexDirection="column" mb={2}>
        <Typography variant="body2" sx={{ fontWeight: 500, mt: '10px' }}>
          {t('events.budget.overall', 'Do you have an overall budget?')}
        </Typography>
        <RadioButtonsSwitcher name="isOverAllBudget" />
      </Box>
      {values.isOverAllBudget && (
        <>
          <FieldWrapper
            display="flex"
            flexDirection="column"
            mb={4}
            width="100%"
          >
            <Typography variant="body2" sx={{ fontWeight: 500, mb: '10px' }}>
              {t('events.budget.target', 'What is the target budget?')}
            </Typography>
            <Field name="price">
              {({ form: { touched, errors, handleChange } }: FieldProps) => {
                return (
                  <CurrencyTextInput
                    error={touched.price && !!errors.price}
                    name="price"
                    placeholder={'$50,000'}
                    value={values.price}
                    onChange={handleChange}
                  />
                )
              }}
            </Field>
          </FieldWrapper>
        </>
      )}
      <FieldWrapper display="flex" flexDirection="column" mb={10}>
        <Box display="flex" mb="10px">
          <CheckedCalendar />
          <FieldLabel variant="body2" sx={{ ml: '5px' }}>
            {isAbs
              ? t('bookings.additional', 'Additional booking details')
              : t('events.additional', 'Additional event details')}
          </FieldLabel>
        </Box>
        <FastTextField
          name="notes"
          multiline
          rows={3}
          placeholder="Type here"
        />
      </FieldWrapper>
    </WizardStep>
  )
}
