import { get } from 'lodash'
import config from 'utils/config'
import { v4 as uuid } from 'uuid'

export interface ICookieManager {
  get(name: string): string
  getAll(): Record<string, string>
  set(name: string, value: string, days: number): void
}

export default class CookieManager implements ICookieManager {
  get(name: string) {
    const cookies = this.getAll()

    return cookies[name]
  }

  getAll() {
    const pairs = document.cookie.split(';')
    const cookies: Record<string, string> = {}

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=')
      cookies[(pair[0] + '').trim()] = decodeURIComponent(pair[1])
    }

    return cookies
  }

  set(name: string, value = '', days = 30, domain?: string) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)

    const chunks = [`${name}=${value}`]
    if (days) chunks.push(`expires=${date.toUTCString()}`)
    if (domain) chunks.push(`domain=${domain}`)
    chunks.push('path=/')
    chunks.push('samesite=lax')
    chunks.push('secure')

    document.cookie = chunks.join('; ')
  }
}

const manager = new CookieManager()

const buildTrackingCookie = ({ isAccepted }: { isAccepted: boolean }) =>
  encodeURIComponent(
    encodeURIComponent(
      JSON.stringify({
        id: uuid(),
        consents: {
          analytics: isAccepted,
          essential: isAccepted,
          marketing: isAccepted,
          personalization: isAccepted,
          uncategorized: isAccepted,
        },
      })
    )
  )

const CONSENT_COOKIE_NAME = 'fs-cc'
const parseConsentsCookie = () => {
  try {
    const trackingCookie = manager.get(CONSENT_COOKIE_NAME)
    return trackingCookie ? JSON.parse(decodeURIComponent(trackingCookie)) : {}
  } catch (e) {
    return {}
  }
}
let consents = parseConsentsCookie()

export const consentCookie = {
  isTrackingEnabled: () => get(consents, ['consents', 'analytics'], false),
  setCookie: ({ isAccepted }: { isAccepted: boolean }): void => {
    manager.set(
      CONSENT_COOKIE_NAME,
      buildTrackingCookie({ isAccepted }),
      100 * 365, // Valid for approx. 100 years
      config.cookieDomain
    )
    consents = parseConsentsCookie()
  },
}
