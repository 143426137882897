import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { Close, Remove } from '@material-ui/icons'
import { Formik } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { CheckBoxField } from '../../../../components/formik/CheckboxField'
import { RadioButtonsSwitcher } from '../../../../components/formik/RadioButtonsSwitcher'
import { SimpleButton } from '../../../../components/ui/Buttons/SimpleButton'
import { RANGE_VARIANTS } from '../../../../constants/events'
import { useLabels } from '../../../../utils/api/labels'
import {
  CEILING_HEIGHT_RANGE_VARIANTS,
  getInitialFormValues,
} from '../../../../utils/helpers/venue'

import { LabelsSection } from './components/LabelsSection'
import { SliderField } from './components/SliderField'
import {
  FieldLabel,
  FormContainer,
  ScreenContent,
  ScrollContainer,
  SectionHeader,
} from './VenuesFilter.styles'

interface MoreFiltersModalProps {
  onCancel: () => void
  onClear: () => void
  showResults: (values: any) => void
  initialValues: any
}

export const MoreFiltersModal: React.FC<MoreFiltersModalProps> = ({
  onCancel,
  onClear,
  showResults,
  initialValues,
}) => {
  const { data: allLabels } = useLabels({ perPage: 100, exclusivity: 'venues' })
  const bestUsedFor = allLabels?.filter(
    (label: any) => label.type === 'best used for'
  )
  const amenities = allLabels?.filter(
    (label: any) => label.type === 'amenities'
  )
  const other = allLabels?.filter((label: any) => label.type === 'other')
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={getInitialFormValues(initialValues)}
      onSubmit={showResults}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      enableReinitialize
    >
      {({ handleSubmit, resetForm, values, setFieldValue }) => {
        return (
          <FormContainer onSubmit={handleSubmit}>
            <ScrollContainer display="flex" flexDirection="column" width="100%">
              <Box maxHeight={800}>
                <ScreenContent>
                  <SectionHeader variant="h3">
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        left: '-15px',
                        top: '3px',
                        '&:hover': { opacity: 0.8 },
                      }}
                    >
                      <Remove sx={{ fill: '#2F54EB' }} />
                      <Typography
                        onClick={() => {
                          resetForm()
                          onClear()
                        }}
                        component="span"
                        color="#2F54EB"
                        variant="body1"
                      >
                        Clear
                      </Typography>
                    </Box>
                    {t('common.filters.more', 'More filters')}
                  </SectionHeader>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    pr={2}
                    mb={4}
                  >
                    <Box display="flex" mb={4}>
                      <FieldLabel variant="body2">Square feet</FieldLabel>
                    </Box>
                    <SliderField name="squareFeet" />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    mb={4}
                  >
                    <FieldLabel variant="body2">Ceiling height</FieldLabel>
                    <RadioGroup
                      aria-label="ceiling-height"
                      name="ceiling_height"
                      value={values.ceiling_height}
                      onChange={(e) =>
                        setFieldValue('ceiling_height', e.target.value)
                      }
                    >
                      {CEILING_HEIGHT_RANGE_VARIANTS.map((item) => (
                        <FormControlLabel
                          key={item.value}
                          value={item.value}
                          control={<Radio color="primary" />}
                          label={item.label}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                  <LabelsSection
                    title="Amenities"
                    allLabels={amenities}
                    mb={3}
                  />
                  <LabelsSection
                    title="Best Used For"
                    allLabels={bestUsedFor}
                    mb={3}
                  />
                  <LabelsSection title="Other" allLabels={other} mb={3} />
                </ScreenContent>
                <Box
                  display="flex"
                  style={{
                    background: 'white',
                    padding: '24px 0 0 0',
                    position: 'sticky',
                    bottom: 0,
                    borderTop: '1px solid rgb(210 210 210 / 40%)',
                  }}
                >
                  <Box
                    onClick={onCancel}
                    display="flex"
                    flex={1}
                    alignItems="center"
                    sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                  >
                    <Typography color="#2F54EB" variant="body1" align="center">
                      Close
                    </Typography>
                    <Close style={{ color: '#2F54EB' }} />
                  </Box>
                  <SimpleButton variant="contained" type="submit">
                    Show results
                  </SimpleButton>
                </Box>
              </Box>
            </ScrollContainer>
          </FormContainer>
        )
      }}
    </Formik>
  )
}
