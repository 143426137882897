import * as React from 'react'
import { useQueryClient } from 'react-query'

import { ResponseOrError, Status } from '../types/general'
import { Inquiry, InquiryEvent } from '../types/Inquiry'
import {
  useEventInquiriesStatuses,
  useServerEventUpdate,
} from '../utils/api/events'
import { getRequestError } from '../utils/helpers/validations'
import { useAuthData } from '../utils/providers/AuthProvider'

export const useUpdateEventStatus = () => {
  const queryClient = useQueryClient()
  const { authUser } = useAuthData()
  const { data: statusesData } = useEventInquiriesStatuses()
  const mutation = useServerEventUpdate(authUser, queryClient)
  const [status, setStatus] = React.useState<Status>(Status.INACTIVE)
  const [error, setError] = React.useState('')

  const getNewStatusByFlow = (status: string, isAccepted: boolean): string => {
    const flow = statusesData?.inquiryStatus.flow || {}
    const possibleStatuses = flow[status]

    if (!possibleStatuses) {
      throw new Error('Wrong inquiry status')
    }
    return isAccepted ? possibleStatuses[0] : possibleStatuses[1]
  }
  const defaultRequest = async (request: Promise<any>) => {
    setStatus(Status.REQUEST)

    try {
      const response = await request
      setStatus(Status.SUCCESS)

      return response
    } catch (e) {
      const errorMessage = getRequestError(e)
      setError(errorMessage)
      setStatus(Status.ERROR)

      return { error: errorMessage }
    }
  }

  const updateInquiry = async (
    data: Partial<InquiryEvent>
  ): Promise<ResponseOrError<any>> => {
    return await defaultRequest(mutation.mutateAsync(data))
  }

  const updateInquiryStatusByFlow = async (
    inquiry: Partial<Inquiry>,
    isAccepted: boolean
  ): Promise<ResponseOrError<any>> => {
    if (!inquiry?.status) {
      return Promise.resolve({ error: 'Incorrect data for update' })
    }

    try {
      const updatedStatus = getNewStatusByFlow(inquiry.status, isAccepted)

      return updateInquiry({ id: inquiry.id, status: updatedStatus })
    } catch (e) {
      return Promise.resolve({ error: e.message })
    }
  }

  return { status, error, updateInquiry, updateInquiryStatusByFlow }
}
