import { useMemo } from 'react'

import { InquiryInvoiceBundleStatuses } from '../constants/inquiry'
import { InquiryInvoiceResponse } from '../types/dtos/inquiry'

interface InvoicesBundlesByStatus {
  notDraftInvoicesBundles: InquiryInvoiceResponse[]
  sentInvoicesBundles: InquiryInvoiceResponse[]
  outdatedInvoicesBundles: InquiryInvoiceResponse[]
  notOutdatedInvoicesBundles: InquiryInvoiceResponse[]
}

export function useInvoicesBundlesByStatuses(
  bundles?: InquiryInvoiceResponse[]
): InvoicesBundlesByStatus {
  const notDraftInvoicesBundles = useMemo(() => {
    if (!bundles?.length) return []
    return bundles?.filter(
      (bundle) => bundle.status !== InquiryInvoiceBundleStatuses.STATUS_DRAFT
    )
  }, [bundles])

  const sentInvoicesBundles = useMemo(() => {
    if (!bundles?.length) return []
    return bundles?.filter(
      (bundle) => bundle.status === InquiryInvoiceBundleStatuses.STATUS_SENT
    )
  }, [bundles])

  const outdatedInvoicesBundles = useMemo(() => {
    if (!bundles?.length) return []
    return bundles?.filter(
      (bundle) => bundle.status === InquiryInvoiceBundleStatuses.STATUS_OUTDATED
    )
  }, [bundles])

  const notOutdatedInvoicesBundles = useMemo(() => {
    if (!bundles?.length) return []
    return bundles?.filter(
      (bundle) => bundle.status !== InquiryInvoiceBundleStatuses.STATUS_OUTDATED
    )
  }, [bundles])

  return {
    notDraftInvoicesBundles,
    sentInvoicesBundles,
    outdatedInvoicesBundles,
    notOutdatedInvoicesBundles,
  }
}
