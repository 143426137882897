import { styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const Container = styled('div')<{ height: number; width: number }>(
  (props) => `
  height: ${props.height}px;
  width: ${props.width ? `${props.width}px` : `100%`};
  min-width: 350px;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;

  ${MEDIA_RULES.SMALL} {
    min-width: unset;
    width: 100%;
  }
`
)
interface DropzoneProps {
  isDragActive: boolean
}

interface UploaderState extends DropzoneProps {
  hasFiles: boolean
  disabled?: boolean
  height: number
  width?: number
}

export const UploadField = styled('div')<UploaderState>(
  (props) => `
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: ${
    !props.hasFiles ? props.height - (props.height / 2 + 35) + 'px' : '0'
  };
  justify-content: center;
`
)

export const DefaultMessage = styled('div')`
  width: 100%;
  text-align: center;
  cursor: pointer;
  .MuiTypography-root {
    text-transform: capitalize;
    margin-top: 20px;
    a {
      color: #2f54eb;
    }
  }
`
