import { Box, Typography } from '@material-ui/core'
import { Email } from '@material-ui/icons'
import React from 'react'

import Google from '../../svg/GoogleIcon'
import LinkText from '../../ui/Buttons/LinkText/LinkText'
import { LoginServiceContainer } from '../Auth.styles'

const WelcomeBack: React.FC<{
  onComplete?: () => void
  onForgot?: () => void
}> = ({ onComplete, onForgot }) => {
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={4}>
        <Typography variant="h3">Log in</Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="h4">Welcome back, Kattie!</Typography>
      </Box>
      <Box display="flex" mb={5} minWidth={400} color="#9396A3">
        <Email sx={{ mr: 2 }} />
        <Typography color="inherit" variant="body2">
          katti********ham@gmail.com
        </Typography>
      </Box>
      <LoginServiceContainer onClick={() => {}}>
        <Google mr={2} />
        <Typography variant="body2">Continue with Google</Typography>
      </LoginServiceContainer>
      <LinkText
        onClick={onForgot}
        fontSize={14}
        display="inline"
        text={'Use another account'}
      />
    </Box>
  )
}

export default WelcomeBack
