import * as React from 'react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { EventDraftStatus, userDefaultEvent } from '../../constants'
import { INITIAL_EVENT_SEARCH_PARAMS } from '../../constants/events'
import { usePartner } from '../../hooks/usePartner'
import { useStorageEvents } from '../../hooks/useStorageEvents'
import { useVenueById } from '../../hooks/useVenueById'
import { EventVendor, EventVenue, LocalEvent } from '../../types/Event'
import { addVendorToServerEvent } from '../api/events'
import { submitInquiryToServerEvent } from '../api/inquiries'
import { updateEventInquiries } from '../api/marketplace'
import { addVendorToEvent, removeVendorFromEvent } from '../helpers/events'
import { getRequestError } from '../helpers/validations'
import { eventListQueryParams } from '../mappers/eventListQueryParamsMapper'
import {
  convertInquiryEventToUserEvent,
  venueToEventVenueMapper,
} from '../mappers/inquiries'

import { useAuthData } from './AuthProvider'
import { useMessageModalData } from './MessageModalProvider'

export const StorageEventsDataContext = React.createContext<{
  events: LocalEvent[]
  updateUserActiveEvent: (event: Partial<LocalEvent>) => void
  getUserActiveEvent: () => LocalEvent
  updateEventById: (userEvent: Partial<LocalEvent>, id: string) => void
  setActiveEvent: (id: string) => void
  removeEventById: (id: string) => void
  getNewStatusByFlow: (status: string, isAccepted: boolean) => string
  clearEventsFromStorage: () => void
  createNewEvent: () => LocalEvent | null
  getEventById: (id: string) => LocalEvent | null
  duplicateEvent: (id: string) => LocalEvent | null
  updateQueryParams: Dispatch<SetStateAction<eventListQueryParams>>
  queryParams: eventListQueryParams
  chosenAbsVenue: EventVenue | null
  updateChosenAbsVenue: Dispatch<SetStateAction<EventVenue | null>>
  isLoading: boolean
  setIsLoading: (loading: boolean) => void

  addVendor: (vendor: EventVendor, showError?: boolean) => void
  removeVendor: (vendor: EventVendor, showError?: boolean) => void
  submitInquiryToActiveEvent: (id: number | string) => void
}>({
  events: [],
  updateUserActiveEvent: () => {},
  getUserActiveEvent: () => userDefaultEvent,
  updateEventById: () => {},
  removeEventById: () => {},
  duplicateEvent: () => null,
  setActiveEvent: () => {},
  getNewStatusByFlow: () => '',
  clearEventsFromStorage: () => {},
  createNewEvent: () => null,
  getEventById: () => null,
  updateQueryParams: () => {},
  queryParams: INITIAL_EVENT_SEARCH_PARAMS,
  chosenAbsVenue: null,
  updateChosenAbsVenue: () => {},
  isLoading: false,
  setIsLoading: () => {},

  addVendor: () => {},
  removeVendor: () => {},
  submitInquiryToActiveEvent: () => {},
})

export const StorageEventsDataProvider: React.FC = ({ children }) => {
  const { showMessage } = useMessageModalData()
  const [queryParams, updateQueryParams] = useState<eventListQueryParams>({
    ...INITIAL_EVENT_SEARCH_PARAMS,
  })

  const [chosenAbsVenue, updateChosenAbsVenue] = useState<EventVenue | null>(
    null
  )

  const {
    events,
    updateUserActiveEvent,
    getUserActiveEvent,
    setActiveEvent,
    updateEventById,
    createNewEvent,
    removeEventById,
    getNewStatusByFlow,
    duplicateEvent,
    getEventById,
    clearEventsFromStorage,
    isLoading,
    setIsLoading,
  } = useStorageEvents(queryParams, chosenAbsVenue)
  const activeEvent = getUserActiveEvent()
  const { isAbs } = usePartner()
  const { authUser } = useAuthData()
  const { default_client_venue_id } = authUser || {
    default_client_venue_id: null,
  }
  const { venue } = useVenueById(default_client_venue_id?.toString() || '')

  useEffect(() => {
    if (!authUser || authUser?.role || !isAbs || !venue) return
    if (venue.status === 'active') {
      const eventVenue = venueToEventVenueMapper(venue)
      updateChosenAbsVenue(eventVenue)
      events.forEach((event) => {
        if (event.status === EventDraftStatus && !event.venue)
          updateEventById({ venue: eventVenue }, event.id)
      })
    } else updateChosenAbsVenue(null)
  }, [authUser, isAbs, venue])

  const addVendor = async (vendor: EventVendor, showError: boolean = false) => {
    if (activeEvent && activeEvent?.status !== EventDraftStatus) {
      try {
        setIsLoading(true)
        const updatedEvent = await addVendorToServerEvent(
          activeEvent?.id,
          vendor.id
        )
        updateUserActiveEvent(convertInquiryEventToUserEvent(updatedEvent))
        setIsLoading(false)
      } catch (e) {
        if (showError) {
          showMessage({
            type: 'warning',
            title: getRequestError(e),
          })
        }
      } finally {
        setIsLoading(false)
      }
    } else {
      const updatedEvent = activeEvent
        ? addVendorToEvent(activeEvent, vendor)
        : addVendorToEvent(createNewEvent(), vendor)
      if (updatedEvent) {
        updateUserActiveEvent(updatedEvent)
      }
    }
  }

  const removeVendor = async (
    vendor: EventVendor,
    showError: boolean = false
  ) => {
    if (activeEvent?.status !== EventDraftStatus) {
      try {
        setIsLoading(true)
        const eventVendors = activeEvent?.vendors?.filter(
          (v) => v.id !== vendor.id
        )
        const vendors = eventVendors?.map((vendor) => vendor.id) || []
        const updatedEvent = await updateEventInquiries({
          vendors,
          packages: [],
          id: activeEvent?.id,
        })

        updateUserActiveEvent(convertInquiryEventToUserEvent(updatedEvent))
      } catch (e) {
        if (showError) {
          showMessage({
            type: 'warning',
            title: getRequestError(e),
          })
        }
      } finally {
        setIsLoading(false)
      }
    } else {
      const updatedEvent = removeVendorFromEvent(activeEvent, vendor)
      if (updatedEvent) {
        updateUserActiveEvent(updatedEvent)
      }
    }
  }

  const submitInquiryToActiveEvent = async (inquiryId: string | number) => {
    setIsLoading(true)
    const updatedInquiry = await submitInquiryToServerEvent(inquiryId)
    const vendors = activeEvent?.vendors?.map((vendor) => {
      if (vendor.id === updatedInquiry.vendor_id) {
        return { ...vendor, status: updatedInquiry.status }
      }
      return vendor
    })
    updateUserActiveEvent({ vendors })

    setIsLoading(false)
  }

  const context = React.useMemo(
    () => ({
      events,
      updateUserActiveEvent,
      getUserActiveEvent,
      setActiveEvent,
      updateEventById,
      removeEventById,
      createNewEvent,
      getEventById,
      duplicateEvent,
      clearEventsFromStorage,
      getNewStatusByFlow,
      queryParams,
      updateQueryParams,
      chosenAbsVenue,
      updateChosenAbsVenue,
      isLoading,
      setIsLoading,

      addVendor,
      removeVendor,
      submitInquiryToActiveEvent,
    }),
    [
      submitInquiryToActiveEvent,
      addVendor,
      removeVendor,
      events,
      updateUserActiveEvent,
      getUserActiveEvent,
      removeEventById,
      setActiveEvent,
      updateEventById,
      createNewEvent,
      getEventById,
      duplicateEvent,
      getNewStatusByFlow,
      clearEventsFromStorage,
      queryParams,
      updateQueryParams,
      chosenAbsVenue,
      updateChosenAbsVenue,
      isLoading,
    ]
  )

  return (
    <StorageEventsDataContext.Provider value={context}>
      {children}
    </StorageEventsDataContext.Provider>
  )
}

export const useStorageEventsData = () => {
  const context = React.useContext(StorageEventsDataContext)
  if (context === undefined) {
    throw new Error(
      'useStorageEventsData must be used within an StorageEventsDataProvider'
    )
  }
  return context
}
