import CreateNewEventIcon from '../components/svg/mobileMenu/CreateNewEvent'
import EventsIcon from '../components/svg/mobileMenu/Events'
import LoginIcon from '../components/svg/mobileMenu/Login'
import LogoutIcon from '../components/svg/mobileMenu/Logout'
import ProfileIcon from '../components/svg/mobileMenu/Profile'
import SignupIcon from '../components/svg/mobileMenu/Signup'
import VendorsIcon from '../components/svg/mobileMenu/Vendors'
import VenuesIconMobile from '../components/svg/mobileMenu/Venues'
import { PopperMenuItem } from '../components/ui/PopperMenu'
import {
  AccountMenuActions,
  AuthMenuActions,
  MobileMenuAccountActions,
} from '../types/Auth'
import { t } from '../utils/i18n'
import { eventsListPath, vendorsPath, venuesPath } from '../utils/paths'

import { UserRoles } from './profile'

export const loginOptions: PopperMenuItem[] = [
  { label: 'Log in', value: AuthMenuActions.Signin, linkAction: 'login' },
  { label: 'Sign up', value: AuthMenuActions.Signup, linkAction: 'signup' },
  {
    label: 'Become a partner',
    value: AccountMenuActions.BecomeAPartner,
    secondary: true,
    className: 'primary-blue',
    isButton: true,
  },
]

export const adminAccountOptions: PopperMenuItem[] = [
  { label: 'Admin Dashboard', value: AccountMenuActions.AdminDashboard },
  { label: 'Login as user', value: AccountMenuActions.UserLogin },
]

export const accountOptionsByRole: Record<string, PopperMenuItem[]> = {
  [UserRoles.Client]: [
    { label: 'Profile', value: AccountMenuActions.Profile },
    { label: 'Become a partner', value: AccountMenuActions.BecomeAPartner },
    { label: 'Logout', value: AccountMenuActions.Logout, secondary: true },
  ],
  [UserRoles.Vendor]: [
    { label: 'Dashboard', value: AccountMenuActions.Dashboard },
    { label: 'Profile', value: AccountMenuActions.Profile },
    { label: 'Logout', value: AccountMenuActions.Logout, secondary: true },
  ],
  [UserRoles.Landlord]: [
    { label: 'Dashboard', value: AccountMenuActions.Dashboard },
    { label: 'Profile', value: AccountMenuActions.Profile },
    { label: 'Logout', value: AccountMenuActions.Logout, secondary: true },
  ],
}

export const mobileOptions: PopperMenuItem[] = [
  {
    label: 'Venues',
    value: MobileMenuAccountActions.Venues,
    link: venuesPath,
    secondary: true,
    icon: <VenuesIconMobile />,
  },
  {
    label: 'Vendors',
    value: MobileMenuAccountActions.Vendors,
    link: vendorsPath,
    icon: <VendorsIcon />,
  },
  {
    label: 'Log in',
    value: AuthMenuActions.Signin,
    linkAction: 'login',
    secondary: true,
    icon: <LoginIcon />,
  },
  {
    label: 'Sign up',
    value: AuthMenuActions.Signup,
    linkAction: 'signup',
    icon: <SignupIcon />,
  },
  {
    label: 'Become a partner',
    value: AccountMenuActions.BecomeAPartner,
    className: 'primary-blue',
    isButton: true,
  },
]

export const getMobileAccountOptionsByRole = (
  isAbs: boolean
): Record<string, PopperMenuItem[]> => {
  return {
    [UserRoles.Client]: [
      {
        label: isAbs
          ? t('partners.abs.header.bookings.my', 'My bookings')
          : t('header.events.my', 'My events'),
        value: MobileMenuAccountActions.MyEvents,
        link: eventsListPath,
        icon: <EventsIcon />,
      },
      {
        label: t('header.venues', 'Venues'),
        value: MobileMenuAccountActions.Venues,
        link: venuesPath,
        secondary: true,
        icon: <VenuesIconMobile />,
      },
      {
        label: t('header.vendors', 'Vendors'),
        value: MobileMenuAccountActions.Vendors,
        link: vendorsPath,
        icon: <VendorsIcon />,
      },
      {
        label: t('header.profile', 'Profile'),
        value: AccountMenuActions.Profile,
        secondary: true,
        icon: <ProfileIcon />,
      },
      {
        label: t('header.logout', 'Logout'),
        value: AccountMenuActions.Logout,
        icon: <LogoutIcon />,
      },
      {
        label: t('header.partner', 'Become a partners'),
        value: AccountMenuActions.BecomeAPartner,
        className: 'primary-blue',
        isButton: true,
      },
    ],
    [UserRoles.Vendor]: [
      {
        label: t('header.venues', 'Venues'),
        value: MobileMenuAccountActions.Venues,
        link: venuesPath,
        icon: <VenuesIconMobile />,
      },
      {
        label: t('header.vendors', 'Vendors'),
        value: MobileMenuAccountActions.Vendors,
        link: vendorsPath,
        icon: <VendorsIcon />,
      },
      {
        label: t('header.profile', 'Profile'),
        value: AccountMenuActions.Profile,
        secondary: true,
        icon: <ProfileIcon />,
      },
      {
        label: t('header.logout', 'Logout'),
        value: AccountMenuActions.Logout,
        icon: <LogoutIcon />,
      },
    ],
    [UserRoles.Landlord]: [
      {
        label: t('header.venues', 'Venues'),
        value: MobileMenuAccountActions.Venues,
        link: venuesPath,
        icon: <VenuesIconMobile />,
      },
      {
        label: t('header.vendors', 'Vendors'),
        value: MobileMenuAccountActions.Vendors,
        link: vendorsPath,
        icon: <VendorsIcon />,
      },
      {
        label: t('header.profile', 'Profile'),
        value: AccountMenuActions.Profile,
        secondary: true,
        icon: <ProfileIcon />,
      },
      {
        label: t('header.logout', 'Logout'),
        value: AccountMenuActions.Logout,
        icon: <LogoutIcon />,
      },
    ],
  }
}
