const ChatBubbleUnread = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.25 8a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0Zm3.125 0a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0Zm-6.25 0a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0Zm10.331-2.713A7.002 7.002 0 0 0 8 1H7.97A6.984 6.984 0 0 0 1 8.033a7.022 7.022 0 0 0 .75 3.123v2.375a.719.719 0 0 0 .719.719h2.376c.97.488 2.039.745 3.124.75h.033c.936 0 1.843-.181 2.698-.536a6.946 6.946 0 0 0 2.231-1.487A6.985 6.985 0 0 0 15 8.032a6.973 6.973 0 0 0-.544-2.745Zm-2.36 6.844A5.782 5.782 0 0 1 8 13.812h-.026a5.834 5.834 0 0 1-2.705-.68l-.131-.07h-2.2v-2.2l-.07-.13a5.833 5.833 0 0 1-.68-2.705 5.777 5.777 0 0 1 1.68-4.122 5.769 5.769 0 0 1 4.107-1.717h.027a5.785 5.785 0 0 1 4.112 1.701 5.784 5.784 0 0 1 1.702 4.138 5.79 5.79 0 0 1-1.72 4.104Z"
        fill="#9396A3"
      />
      <path
        d="M15.5 3.333a2.833 2.833 0 1 1-5.667 0 2.833 2.833 0 0 1 5.667 0Z"
        fill="#E04E2F"
        stroke="#F7F7F8"
      />
    </svg>
  )
}

export default ChatBubbleUnread
