const BurgerMobile = () => {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.3 7.967h21.333M5.3 15.967h21.333M5.3 23.967h21.333"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BurgerMobile
