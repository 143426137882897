import { SVGProps } from 'react'

const CheckedCalendar = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" fill="#9396A3" {...props}>
      <path d="M20.625 4.313h-3.938v-1.5a.188.188 0 0 0-.187-.188h-1.313a.188.188 0 0 0-.187.188v1.5H9v-1.5a.188.188 0 0 0-.188-.188H7.5a.188.188 0 0 0-.188.188v1.5H3.376a.75.75 0 0 0-.75.75v15.562c0 .415.335.75.75.75h17.25a.75.75 0 0 0 .75-.75V5.062a.75.75 0 0 0-.75-.75Zm-.938 15.375H4.313V6h3v1.125c0 .103.085.188.188.188h1.313A.188.188 0 0 0 9 7.125V6h6v1.125c0 .103.084.188.188.188H16.5a.188.188 0 0 0 .188-.188V6h3v13.688Zm-3.562-9.844h-1.294a.38.38 0 0 0-.304.154l-3.537 4.87-1.517-2.085a.377.377 0 0 0-.304-.155H7.875a.188.188 0 0 0-.152.298l2.962 4.078a.375.375 0 0 0 .476.114.375.375 0 0 0 .133-.114l4.983-6.86a.19.19 0 0 0-.152-.3Z" />
    </svg>
  )
}

export default CheckedCalendar
