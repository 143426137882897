export const getFormData = (obj: any) =>
  Object.keys(obj).reduce((formData, key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any, arrayIndex: number) => {
        if (item && typeof item === 'object' && !(item instanceof File)) {
          Object.keys(item).forEach((itemKey) => {
            formData.append(`${key}[${arrayIndex}][${itemKey}]`, item[itemKey])
          })
        } else {
          formData.append(`${key}[]`, item)
        }
      })
    } else {
      formData.append(key, obj[key])
    }
    return formData
  }, new FormData())
