import { Box, Typography } from '@material-ui/core'
import React from 'react'

import NoDocuments from '../svg/NoDocuments'

import { DocumentsContainer } from './Documents.styles'

interface DocumentsEmptyStateProps {
  title: string
}

export const DocumentsEmptyState: React.FC<DocumentsEmptyStateProps> = ({
  title,
}) => {
  return (
    <>
      <DocumentsContainer>
        <NoDocuments />
      </DocumentsContainer>
      <Box display="flex" justifyContent="center" mt={3}>
        <Typography variant="body2" align="center" color="secondaryText">
          {title}
        </Typography>
      </Box>
    </>
  )
}
