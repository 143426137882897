// Import Swiper styles
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/thumbs/thumbs.min.css'

import { Box, BoxProps, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Question from '../../../components/svg/Question'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { ImageItem, ImageType } from '../../../types/general'
import { TooltipComponent } from '../Tooltip'

import NavigationButtons from './NavigationButtons'
import SlideComponent from './SlideComponent'
import { SliderHeader, SwiperWrapper } from './SwipeSlider.styles'

// install Swiper modules
SwiperCore.use([Pagination, Navigation])

export interface Slide {
  id: number | string
  onClick?: (id: number | string) => void
  title?: string
  image?: ImageItem
  imageSize: ImageType['size']
  imageStaticType: ImageType['static']
  footer?: ReactElement
  vendorCategory?: string
  promotionDescription?: string
}

interface SwipeSliderProps extends BoxProps {
  data: Slide[]
  title?: string
  slidesPerView?: number
  slidesPerGroup?: number
  titleVariant?: Variant
  spaceBetween?: number
  renderSlide?: (item: Slide) => ReactElement
  withoutEndFrame?: boolean
  isLoading?: boolean
  navigationPosition?: 'top' | 'bottom' | 'center'
  tooltipBox?: JSX.Element
  flexItems?: boolean
  absPosNavigation?: boolean
  activeSlideIndex?: number
  handleActiveVenueIndex?: (index: number) => void
  nextNavButtonClass?: string
  prevNavButtonClass?: string
  withPagination?: boolean
  slideWidth?: number
  slideImageHeight?: number
}

const SwipeSlider: React.FC<SwipeSliderProps> = (props) => {
  const { windowSize, isMobile, isLessThenMaxContainerWidth } = useWindowSize()
  const { width: windowWidth } = windowSize
  const space = isMobile ? 16 : 24
  const {
    withPagination = false,
    activeSlideIndex,
    handleActiveVenueIndex,
    flexItems = false,
    absPosNavigation = false,
    data,
    title,
    renderSlide,
    slidesPerView,
    slidesPerGroup,
    withoutEndFrame,
    spaceBetween = space,
    navigationPosition = 'bottom',
    titleVariant = 'h2',
    tooltipBox,
    slideImageHeight,
    slideWidth,
    mr,
    ml,
    mb,
    mt,
    py = 0,
    px = 0,
  } = props
  const [isComponentRendered, setIsRendered] = useState<boolean>(false)
  const [withoutEndFrameSlides, setWithoutEndFrameSlides] = useState<number>(0)
  const [disabledPrev, setDisabledPrev] = useState<boolean>(true)
  const [disabledNext, setDisabledNext] = useState<boolean>(false)
  const prevRef = useRef<HTMLButtonElement | null>(null)
  const nextRef = useRef<HTMLButtonElement | null>(null)

  const [swiper, setSwiper] = useState<SwiperCore>()

  useEffect(() => {
    if (activeSlideIndex === undefined) return
    swiper?.slideTo(activeSlideIndex)
  }, [activeSlideIndex, swiper])

  const handleNavDisabled = (activeSlideIndex: number) => {
    if (activeSlideIndex + 1 === data.length) {
      setDisabledPrev(false)
      setDisabledNext(true)
    } else if (activeSlideIndex === 0) {
      setDisabledNext(false)
      setDisabledPrev(true)
    } else {
      setDisabledNext(false)
      setDisabledPrev(false)
    }
  }

  useEffect(() => {
    if (activeSlideIndex === undefined) return
    handleNavDisabled(activeSlideIndex)
  }, [activeSlideIndex])

  useEffect(() => {
    setIsRendered(true)
  }, [])

  useEffect(() => {
    if (!withoutEndFrame || !windowWidth || !slideWidth) return
    if (!isLessThenMaxContainerWidth) {
      setWithoutEndFrameSlides(
        (windowWidth - (windowWidth - 1224) / 2) / slideWidth
      )
    } else if (!isMobile && isLessThenMaxContainerWidth) {
      setWithoutEndFrameSlides((windowWidth - 50) / slideWidth)
    } else {
      setWithoutEndFrameSlides((windowWidth - 24) / slideWidth)
    }
  }, [
    withoutEndFrame,
    windowWidth,
    slideWidth,
    isMobile,
    isLessThenMaxContainerWidth,
  ])

  const renderElement = (item: Slide) => {
    if (renderSlide) {
      return renderSlide(item)
    }

    return <SlideComponent item={item} />
  }

  const slidesGroup = withoutEndFrame
    ? !!withoutEndFrameSlides
      ? Math.floor(withoutEndFrameSlides)
      : undefined
    : slidesPerGroup

  const showTopNavigation =
    navigationPosition === 'top' && !!slidesGroup && data.length > slidesGroup
  const showBottomNavigation =
    navigationPosition === 'bottom' &&
    !!slidesGroup &&
    data.length > slidesGroup
  const showCenterNavigation =
    navigationPosition === 'center' &&
    !!slidesGroup &&
    data.length > slidesGroup

  return (
    <SwiperWrapper
      className={py ? '' : 'section-block'}
      withPagination={withPagination}
      flexItems={flexItems}
      imageMaxHeight={slideImageHeight ? slideImageHeight : undefined}
      imageMaxWidth={slideWidth ? slideWidth : undefined}
      withoutEndFrame={withoutEndFrame}
      mb={mb}
      ml={ml}
      mr={mr}
      mt={mt}
      py={py}
      px={px}
      windowWidth={windowSize.width}
      isMobile={isMobile}
      isLessThenMaxContainerWidth={isLessThenMaxContainerWidth}
      absPosNavigation={absPosNavigation}
      navigationPosition={navigationPosition}
    >
      {showCenterNavigation && (
        <NavigationButtons
          navigationPosition={navigationPosition}
          absPosNavigation
          disabledPrev={disabledPrev}
          disabledNext={disabledNext}
          prevNavButtonClass={props.prevNavButtonClass}
          nextNavButtonClass={props.nextNavButtonClass}
          prevButton={prevRef}
          nextButton={nextRef}
        />
      )}
      {(!!title || tooltipBox || showTopNavigation) && (
        <SliderHeader>
          <Box display="flex">
            {!!title && (
              <Typography
                align="left"
                variant={titleVariant}
                sx={{ color: (theme) => theme.palette.secondary.dark }}
              >
                {title}
              </Typography>
            )}
            {tooltipBox && (
              <TooltipComponent placement="top" title={tooltipBox}>
                <Box style={{ cursor: 'pointer' }} ml="2px" mt="-5px">
                  <Question fill="#9396A3" />
                </Box>
              </TooltipComponent>
            )}
          </Box>
          {showTopNavigation && (
            <NavigationButtons
              navigationPosition={navigationPosition}
              absPosNavigation={absPosNavigation}
              disabledPrev={disabledPrev}
              disabledNext={disabledNext}
              prevNavButtonClass={props.prevNavButtonClass}
              nextNavButtonClass={props.nextNavButtonClass}
              prevButton={prevRef}
              nextButton={nextRef}
              marginBottom="32px"
            />
          )}
        </SliderHeader>
      )}
      {isComponentRendered && !!slidesGroup && data.length > slidesGroup ? (
        <Swiper
          onSwiper={(swiper) => setSwiper(swiper)}
          onSlideChange={(swiper) => {
            handleActiveVenueIndex && handleActiveVenueIndex(swiper.activeIndex)
            const index =
              swiper.activeIndex === 0
                ? 0
                : withoutEndFrame
                ? swiper.activeIndex + slidesGroup - 2
                : swiper.activeIndex + slidesGroup - 1
            handleNavDisabled(index)
          }}
          slidesPerView={
            withoutEndFrameSlides ? withoutEndFrameSlides : slidesPerView
          }
          spaceBetween={spaceBetween}
          slidesPerGroup={slidesGroup}
          navigation={{
            prevEl: props.prevNavButtonClass
              ? `.${props.prevNavButtonClass}`
              : '.prev',
            nextEl: props.nextNavButtonClass
              ? `.${props.nextNavButtonClass}`
              : '.next',
          }}
          pagination={{ clickable: true }}
        >
          {data.map((item: Slide) => (
            <SwiperSlide key={item.id}>{renderElement(item)}</SwiperSlide>
          ))}
          {withoutEndFrame && <SwiperSlide key={9999} />}
        </Swiper>
      ) : (
        <Box display="flex" height="100%">
          {data.map((item: Slide) => (
            <SwiperSlide
              key={item.id}
              style={{ marginRight: isMobile ? '16px' : '24px' }}
            >
              {renderElement(item)}
            </SwiperSlide>
          ))}
        </Box>
      )}
      {showBottomNavigation && (
        <NavigationButtons
          navigationPosition={navigationPosition}
          absPosNavigation={absPosNavigation}
          disabledPrev={disabledPrev}
          disabledNext={disabledNext}
          prevNavButtonClass={props.prevNavButtonClass}
          nextNavButtonClass={props.nextNavButtonClass}
          prevButton={prevRef}
          nextButton={nextRef}
        />
      )}
    </SwiperWrapper>
  )
}

export default SwipeSlider
