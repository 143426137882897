import './index.css'

import { WindowSizeVariables } from 'hooks/useWindowSize'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter, Redirect } from 'react-router-dom'
import config from 'utils/config'

import App from './App'
import { SUBFOLDERS_ARRAY } from './constants/partners'
import reportWebVitals from './reportWebVitals'

const basename =
  window.location.pathname &&
  SUBFOLDERS_ARRAY.includes(window.location.pathname.split('/')[1])
    ? window.location.pathname.split('/')[1]
    : ''

if (config.msw.enabled) {
  const { worker } = require('./utils/api/mocks/browser')
  worker.start()
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={basename}>
      <WindowSizeVariables />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
