import { Selection, SelectionState } from 'components/ui/Checkbox'
import {
  all,
  concat,
  difference,
  filter,
  isEqual,
  keyBy,
  keys,
  map,
  mapValues,
  pipe,
  prop,
  uniq,
} from 'lodash/fp'
import {
  City,
  District,
  LocationData,
  Locations,
  Neighborhood,
} from 'utils/api/locations'
import { isOneOf } from 'utils/helpers/array'
import { useAppliedFilters, useUrlFilters } from 'utils/helpers/filters'
import { coerceToArray } from 'utils/helpers/zod'
import { z } from 'zod'

import { usePreferredCity } from './usePreferredCity'

const toSlug = prop('slug')

const districtSelectionState = (
  district: District,
  selectedNeighborhoods: number
) => {
  if (selectedNeighborhoods === 0) return SelectionState.None
  if (selectedNeighborhoods === district.neighborhoods.length)
    return SelectionState.All
  return SelectionState.Partial
}

const makeLocationAccessors = (locations: Locations) => ({
  city: (slug: string) => locations.cities[slug],
  district: (slug: string) => locations.districts[slug],
  neighborhood: (slug: string) => locations.neighborhoods[slug],
})

type LocationState = {
  values: {
    city: string
    districts: Record<string, Selection>
    neighborhoods: Record<string, boolean>
  }
  options: City[]
  selected: {
    city: City
    districts: District[]
    neighborhoods: Neighborhood[]
  }
  entities: Locations
  getLocation: ReturnType<typeof makeLocationAccessors>

  onChange: (key: 'city' | 'district' | 'neighborhood', slug: string) => void
  onClear: () => void
}

export const locationFiltersSchema = (locations: LocationData) =>
  z.object({
    city: z.string().refine(isOneOf(locations.result.cities), (val) => ({
      message: `Invalid city ${val}`,
    })),
    neighborhood: coerceToArray(
      z.string().refine(isOneOf(locations.result.neighborhoods), (val) => ({
        message: `Invalid neighborhood ${val}`,
      }))
    )
      .default([])
      .transform(uniq),
  })

const locationFilterStore = <TStore extends FilterStore>(store: TStore) => {
  return { ...store, clear: () => store.clear(['neighborhood']) }
}
export const useAppliedLocationFilters = (locations: LocationData) =>
  locationFilterStore(useAppliedFilters(locationFiltersSchema(locations)))
export const useLocationFilters = (locations: LocationData) =>
  locationFilterStore(useUrlFilters(locationFiltersSchema(locations)))

type LocationFilters = { city: string; neighborhood: string[] }
type FilterStore = {
  values: LocationFilters
  update: (
    key: keyof LocationFilters,
    value: LocationFilters[keyof LocationFilters]
  ) => void
  updateMany: (values: Partial<LocationFilters>) => void
  clear: (keys?: (keyof LocationFilters)[]) => void
}

export const useLocationState = (
  locations: LocationData,
  store: FilterStore = useLocationFilters(locations)
): LocationState | null => {
  const { setPreferredCity } = usePreferredCity()

  const getLocation = makeLocationAccessors(locations.entities)
  if (!store.values.city) return null

  const currentCity = getLocation.city(store.values.city)
  const currentDistricts = currentCity.districts
  const currentNeighborhoods = currentDistricts.flatMap((d) => d.neighborhoods)

  const isNeighborhoodSelected = (slug: string) =>
    store.values.neighborhood.includes(slug)

  const neighborhoodValues: Record<string, boolean> = pipe(
    keyBy('slug'),
    mapValues((n: Neighborhood) => isNeighborhoodSelected(n.slug))
  )(currentNeighborhoods)

  const districtValues: Record<string, Selection> = pipe(
    keyBy('slug'),
    mapValues((d: District) => {
      const selectedNeighborhoods = d.neighborhoods
        .map((n) => neighborhoodValues[n.slug])
        .filter(isEqual(true)).length

      return districtSelectionState(d, selectedNeighborhoods)
    })
  )(currentDistricts)

  const selected = {
    city: currentCity,
    districts: pipe(
      filter(isEqual(SelectionState.All)),
      keys,
      map(getLocation.district)
    )(districtValues),
    neighborhoods: store.values.neighborhood.map(getLocation.neighborhood),
  }

  const updateCity = (city: string) => {
    if (city === store.values.city) return

    store.updateMany({ city, neighborhood: [] })
    setPreferredCity(city)
  }

  const addNeighborhoods = (neighborhoods: string[]) =>
    concat(store.values.neighborhood, neighborhoods)
  const removeNeighborhoods = (neighborhoods: string[]) =>
    difference(store.values.neighborhood, neighborhoods)

  const toggleDistrict = (slug: string) => {
    const district = getLocation.district(slug)

    const value = districtValues[slug]
    const districtNeighborhoods = district.neighborhoods.map(toSlug)
    const neighborhoods =
      value === SelectionState.None
        ? addNeighborhoods(districtNeighborhoods)
        : removeNeighborhoods(districtNeighborhoods)

    store.update('neighborhood', neighborhoods)
  }

  const toggleNeighborhood = (slug: string) => {
    const neighborhoods = neighborhoodValues[slug]
      ? removeNeighborhoods([slug])
      : addNeighborhoods([slug])
    store.update('neighborhood', neighborhoods)
  }

  const handleChange = (
    key: 'city' | 'district' | 'neighborhood',
    slug: string
  ) => {
    switch (key) {
      case 'city':
        updateCity(slug)
        break
      case 'district':
        toggleDistrict(slug)
        break
      case 'neighborhood':
        toggleNeighborhood(slug)
        break
    }
  }

  const values = {
    city: currentCity.slug,
    districts: districtValues,
    neighborhoods: neighborhoodValues,
  }

  return {
    selected,
    options: locations.cities,
    entities: locations.entities,
    getLocation,
    values: values,
    onChange: handleChange,
    onClear: store.clear,
  }
}
