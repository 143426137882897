import { getUrlPartnerParam, isPartner } from 'constants/partners'

import { CancelToken } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import {
  InquiryContractRequest,
  InquiryContractResponse,
  InquiryDefaultInvoicesRequest,
  InquiryInvoiceCreateRequest,
} from '../../types/dtos/inquiry'
import { ResponseOrError } from '../../types/general'
import { InquiryFormPostRequest, InquiryWithDetails } from '../../types/Inquiry'
import { clearObject } from '../helpers/data'
import { getFormData } from '../helpers/objectToFormData'
import {
  confirmInvoicePath,
  createInvoiceDocumentApiPath,
  createInvoicePaymentApiPath,
  eventApiPath,
  eventDuplicateApiPath,
  getContractApiPath,
  getContractDownloadApiPath,
  getDefaultInvoicesApiPath,
  getInquiryApiPath,
  getInquirySubmitApiPath,
  getInvoiceApiPath,
  getSendContractAndInvoicesBundleApiPath,
  getSendInvoicesBundleApiPath,
  inquiryApiPathForGuests,
  syncContractApiPath,
} from '../paths'

import { get, post, put, putFormData } from './api'

export async function getInquiryById(
  inquiryId: string,
  cancelToken?: CancelToken
): Promise<InquiryWithDetails> {
  return await get(getInquiryApiPath(inquiryId), undefined, {
    forceUpdate: true,
    cancelToken,
  })
}

export function useInquiryDetails(
  inquiryId: string,
  cancelToken: CancelToken,
  options: UseQueryOptions<InquiryWithDetails, Error> = {}
) {
  return useQuery<InquiryWithDetails, Error>(
    ['inquiry', JSON.stringify(options)],
    () => getInquiryById(inquiryId, cancelToken),
    { ...options }
  )
}

export async function generateDocumentForInvoice(
  invoiceId: string
): Promise<{ id: number; inquiry_id: number; document_url: string }> {
  return post(createInvoiceDocumentApiPath(invoiceId.toString()))
}

export async function updateInquiryStatus(
  inquiryId: string,
  data: { status: string }
): Promise<any> {
  return put(getInquiryApiPath(inquiryId), data)
}

export async function generateInquiryContract({
  inquiry_id,
  ...data
}: InquiryContractRequest): Promise<InquiryContractResponse> {
  return post(getContractApiPath(inquiry_id.toString()), clearObject(data))
}

export async function generateInquiryInvoice({
  inquiry_id,
  ...data
}: InquiryInvoiceCreateRequest): Promise<any> {
  return post(getInvoiceApiPath(inquiry_id.toString()), data)
}

export async function generateDefaultInvoices({
  inquiry_id,
  ...data
}: InquiryDefaultInvoicesRequest): Promise<any> {
  return post(
    getDefaultInvoicesApiPath(inquiry_id.toString()),
    clearObject(data)
  )
}

export async function confirmInvoicePayment(data: {
  checkout_id: string
  order_id: string
}): Promise<any> {
  return post(confirmInvoicePath, data)
}

export async function createInvoicePayment(
  id: number | string,
  data: { token: string }
): Promise<any> {
  return post(createInvoicePaymentApiPath(id), data)
}

export async function getInquiryContractsById(
  inquiryId: number
): Promise<ResponseOrError<any>> {
  return get(getContractApiPath(inquiryId.toString()), undefined, {
    cache: { ignoreCache: true },
    clearCacheEntry: true,
  })
}

export function useInquiryContractsById(
  inquiryId: number,
  fetchOptions: UseQueryOptions<InquiryContractResponse[], Error> = {
    enabled: true,
  }
) {
  return useQuery<InquiryContractResponse[], Error>(
    ['inquiry', inquiryId],
    () => getInquiryContractsById(inquiryId),
    fetchOptions
  )
}

export async function syncInquiryContractById(
  contractId: string
): Promise<InquiryContractResponse> {
  return get(syncContractApiPath(contractId))
}

export async function downloadInquiryContractById(
  contractId: string
): Promise<InquiryContractResponse> {
  return get(
    getContractDownloadApiPath(contractId.toString()),
    {},
    { responseType: 'blob' }
  )
}

export async function sendContractAndInvoicesBundle(
  inquiryId: string
): Promise<any> {
  return post(getSendContractAndInvoicesBundleApiPath(inquiryId))
}

export async function sendContract(
  inquiryId: string,
  data: FormData
): Promise<any> {
  return post(getContractApiPath(inquiryId), data)
}

export async function sendInvoicesBundle(
  inquiryId: string,
  bundleId: number
): Promise<any> {
  return post(getSendInvoicesBundleApiPath(inquiryId, bundleId))
}

export async function updateInquiry(
  inquiryId: string,
  data: any
): Promise<any> {
  const formData = getFormData(data)
  return putFormData(getInquiryApiPath(inquiryId), formData)
}

export async function createInquiry(
  inquiryDetails: InquiryFormPostRequest
): Promise<any> {
  const apiUrl = isPartner()
    ? `${eventApiPath}?${getUrlPartnerParam()}`
    : eventApiPath
  return post(apiUrl, { ...clearObject(inquiryDetails) })
}

export async function createInquiryForDuplicate(
  inquiryDetails: InquiryFormPostRequest
): Promise<any> {
  const apiUrl = isPartner()
    ? `${eventDuplicateApiPath}?${getUrlPartnerParam()}`
    : eventDuplicateApiPath
  return post(apiUrl, inquiryDetails)
}

export async function createInquiryForGuests(
  inquiryDetails: InquiryFormPostRequest
): Promise<any> {
  return post(inquiryApiPathForGuests(), { ...clearObject(inquiryDetails) })
}

export function submitInquiryToServerEvent(inquiry_id: string | number) {
  return post(getInquirySubmitApiPath(inquiry_id))
}
