import { useQuery } from 'react-query'

import { Vendor } from '../../types/Vendor'
import { clearObject } from '../helpers/data'
import { labelsApiPath } from '../paths'

import { get } from './api'

async function fetchLabels(options: any = {}): Promise<Vendor> {
  const response = await get(labelsApiPath, { ...clearObject(options) })
  return response.data
}

export function useLabels(options: any = {}) {
  return useQuery<any, Error>(['labels', ...Object.values(options)], () =>
    fetchLabels(options)
  )
}
