import { Box, Link, Typography } from '@material-ui/core'
import { formatDistance } from 'date-fns'
import * as React from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { useCurrentBusinessListingId } from '../../../hooks/useCurrentBusinessListingId'
import { ServerNotification } from '../../../types/dtos/Notifications'
import { getInquiryTab } from '../../../utils/helpers/events'
import { notificationIconANdColorMapping } from '../../../utils/mappers/notifications'
import {
  dashboardBookingChatPath,
  dashboardBookingDetailsPath,
  eventPath,
} from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { SimpleButton } from '../../ui/Buttons/SimpleButton'

import { DetailsSeparator } from './Notifications.styles'

export const NotificationItem: React.FC<{ item: ServerNotification }> = ({
  item,
}) => {
  const { color, icon } = notificationIconANdColorMapping(item.data.name)
  const { authUser } = useAuthData()
  const history = useHistory()

  const { businessId } = useCurrentBusinessListingId()

  const getAction = () => {
    const eventId =
      item.event_id || item.data.event?.id || item.data.data.event_id
    const inquiryId = item.inquiry_id || item.data.data.inquiry_id
    const isNewMessage = item.data.name === 'UserHasNewMessage'
    const tab = getInquiryTab(item.type)

    if (authUser?.role && inquiryId) {
      if (isNewMessage) {
        history.push(
          generatePath(dashboardBookingChatPath, {
            id: inquiryId,
            listingId: businessId,
          })
        )
      } else
        history.push(
          generatePath(dashboardBookingDetailsPath, {
            id: inquiryId,
            listingId: businessId,
          })
        )
    } else {
      if (inquiryId) {
        const paramsWithChat = {
          inquiryId,
          openChat: true.toString(),
        }

        history.push({
          pathname: generatePath(eventPath, {
            id: eventId,
          }),
          search: isNewMessage
            ? new URLSearchParams(paramsWithChat).toString()
            : tab
            ? `inquiryId=${inquiryId}&tab=${tab}`
            : `inquiryId=${inquiryId}`,
        })
      } else {
        history.push({
          pathname: generatePath(eventPath, {
            id: eventId,
          }),
          search: isNewMessage ? 'openChat=true' : '',
        })
      }
    }
  }

  return (
    <Box mb={4}>
      <Box minWidth="300px" display="flex" mb={2}>
        <Box display="flex" mr={2} height={56} width={56}>
          <Box
            flex={1}
            style={{ background: color }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {icon}
          </Box>
        </Box>
        <Box
          display="flex"
          flex={1}
          height={1}
          width={1}
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" mb={1}>
            <Typography fontWeight={500} fontSize={14}>
              {item.data.subject}
            </Typography>
            <Box display="flex">
              <Typography
                color="#2F54EB"
                sx={{ marginTop: '5px' }}
                fontSize={14}
              >
                {item.event?.name}
              </Typography>
              <DetailsSeparator />
              <Typography
                color="#9396A3"
                sx={{ marginTop: '5px', marginLeft: '10px' }}
                fontSize={14}
              >
                {formatDistance(new Date(item.created_at), new Date(), {
                  addSuffix: true,
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {(item.data.data.inquiry ||
        item.data.data.id ||
        item.data.data.inquiry_id ||
        item.data.data.event_id) && (
        <SimpleButton
          onClick={getAction}
          variant="outlined"
          sx={{ padding: 0, fontSize: '16px' }}
          fullWidth
        >
          <Link underline="none">View</Link>
        </SimpleButton>
      )}
    </Box>
  )
}
