import { Box, styled, Typography } from '@material-ui/core'
import { FormatListBulleted } from '@material-ui/icons'
import { locationFiltersSchema, useLocationState } from 'hooks/useLocationState'
import { usePreferredCity } from 'hooks/usePreferredCity'
import { requireParams, requireValidParams } from 'hooks/useSearchParams'
import { keys } from 'lodash'
import { compact, compose, defaultTo, isNil, join, pick, pipe } from 'lodash/fp'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath, useParams } from 'react-router-dom'
import {
  City,
  LocationsQuery,
  usePrefetchedLocations,
} from 'utils/api/locations'
import { waitForQueries } from 'utils/helpers/query'
import { withHooks } from 'utils/helpers/render'
import { issueKeys, mapError } from 'utils/helpers/zod'

import { PromotionsLink } from '../../../components/cards/labels/PromotionsLink'
import { SearchValuesChips } from '../../../components/SearchValuesChips'
import ArrowDown from '../../../components/svg/ArrowDown'
import ArrowUp from '../../../components/svg/ArrowUp'
import Filter from '../../../components/svg/venues/Filter'
import { ActionButton } from '../../../components/ui/Buttons/ActionButton'
import { StyledBadge } from '../../../components/ui/FormComponents/MultiSelectField'
import { Loader } from '../../../components/ui/Loader'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { PageTitle } from '../../../components/ui/PageTitle'
import {
  INITIAL_VENDORS_SEARCH_PARAMS,
  VENDORS_SORTING_FIELDS,
} from '../../../constants/vendors'
import { useSearchParams } from '../../../hooks/useSearchParams'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useUrlParams } from '../../../hooks/useUrlParams'
import { useVendorTypesWithVendorCount } from '../../../hooks/useVendorTypesWithVendorCount'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { useVendors } from '../../../utils/api/vendors'
import { getFiltersCount } from '../../../utils/helpers/common'
import { SortOrder } from '../../../utils/helpers/sort'
import { isExclusive } from '../../../utils/helpers/vendors'
import { getQueryFromMoreFilters } from '../../../utils/helpers/venue'
import { vendorsMapper } from '../../../utils/mappers/vendors'
import {
  vendorsCategoryPath,
  vendorsPath,
  vendorsTreePath,
} from '../../../utils/paths'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { SortingSelect } from '../../marketplace/components/SortingSelect'
import { StyledMoreFiltersButton } from '../../marketplace/components/VenuesFilter/VenuesFilter.styles'
import { VendorsList } from '../components/VendorsList'

import { VendorLocationFilter } from './components/Filters/VendorLocationFilter'
import { VendorsMoreFiltersModal } from './components/VendorsMoreFilters'

export const StyledEmptyImage = styled(Box)`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #20202c;
  opacity: 80%;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  margin-right: -10px;
`

const vendorSearchParams = pick([
  ...keys(INITIAL_VENDORS_SEARCH_PARAMS),
  'type',
  'venue_id',
])

const vendorTitle = (
  selectedCategoryName: string | null,
  selectedCity: City | null
) => {
  const vendorCategory = selectedCategoryName || 'Vendors'

  return pipe(compact, join(' '))([selectedCity?.name, vendorCategory])
}

export const VendorsListByCategory: React.FC = compose(
  waitForQueries([LocationsQuery], Loader),
  withHooks({
    events: useStorageEventsData,
    preferredCity: usePreferredCity,
    locations: usePrefetchedLocations,
  }),
  requireValidParams(({ locations }) =>
    pipe(
      locationFiltersSchema(locations).partial().safeParse,
      mapError(issueKeys),
      defaultTo([])
    )
  ),
  requireParams(
    ['city', 'page', 'perPage', 'orderBy'],
    ({ events, preferredCity }) => ({
      city:
        events.getUserActiveEvent().venue?.city?.slug || preferredCity.citySlug,
      ...pick(['page', 'perPage', 'orderBy'], INITIAL_VENDORS_SEARCH_PARAMS),
    })
  )
)(function VendorsListByCategory() {
  const history = useHistory()
  const { t } = useTranslation()
  const { category } = useParams<{ category: string }>()
  const { urlParams } = useUrlParams()
  const params = useSearchParams()

  const locations = usePrefetchedLocations()
  const locationState = useLocationState(locations)

  const { getUserActiveEvent, isLoading: eventsLoading } =
    useStorageEventsData()
  const activeEvent = getUserActiveEvent()
  const { filteredTypes: vendorTypes } = useVendorTypesWithVendorCount()

  const [lastPage, setLastPage] = useState(1)
  const [total, setTotal] = useState(0)

  const { isOpen, toggleModal } = useSimpleModal()
  const { data, isLoading } = useVendors(
    vendorSearchParams({
      venue_id: activeEvent?.venue?.id,
      type: category,
      ...urlParams,
    })
  )
  const { isMobile } = useWindowSize()
  const [selectedCategoryName, setSelectedCategoryName] = useState<
    string | null
  >(null)
  const [filterInitialValues, setFilterInitialValues] = useState<{
    [key: string]: string | number[]
    costLevel: number[]
  }>({ costLevel: [] })

  const exclusive = isExclusive(data?.exclusivity)
  const pickedVendors = activeEvent?.vendors || []
  const vendors = data?.data.map((v: any) => vendorsMapper({ ...v, exclusive }))

  const [orderDirection, setOrderDirection] = useState<SortOrder>('asc')

  const vendorCategoryName = vendorTitle(
    selectedCategoryName,
    !isNil(locationState) ? locationState.selected.city : null
  )

  const showResultsByMoreFilters = (moreFilterFormValues: any) => {
    const { order_price_min, labels } =
      getQueryFromMoreFilters(moreFilterFormValues)
    const labelsQueryString = labels.join(',')
    const priceQueryString = order_price_min.join(',')

    params.setParams({
      page: 1,
      order_price_min: priceQueryString,
      labels: labelsQueryString,
    })
    toggleModal()
  }

  const onPaginationChange = (event: any, page: number) => {
    params.setParam('page', page)
    window.scrollTo(0, 0)
  }

  const onSort = (field: string) => {
    if (field !== 'Sort by') {
      params.setParam('orderBy', field)
    } else {
      params.setParam('orderBy', null)
    }
  }

  const onChangeDirection = (direction: SortOrder) => {
    setOrderDirection(direction)
    params.setParam('orderDirection', direction)
  }

  const onSearchByCategory = (item: string) => {
    const newSearchCategoryName = item === selectedCategoryName ? '' : item
    setSelectedCategoryName(newSearchCategoryName)
    const type = vendorTypes?.find(
      (type) => type.name === newSearchCategoryName
    )
    if (type) {
      history.push(generatePath(vendorsCategoryPath, { category: type?.id }))
      params.setParam('page', 1)
    } else {
      history.push(vendorsPath)
      params.setParam('page', 1)
    }
  }

  const onChangeView = () => {
    history.push(generatePath(vendorsTreePath))
  }

  useEffect(() => {
    const values: { [key: string]: string | number[]; costLevel: number[] } = {
      costLevel:
        (urlParams.order_price_min &&
          urlParams.order_price_min.split(',').map((val: string) => +val)) ||
        [],
    }

    urlParams.labels
      ?.split(',')
      .filter((label: string) => label)
      .forEach((label: string) => {
        values[`label_${label}`] = `label_${label}`
      })
    // @ts-ignore
    setFilterInitialValues(values)
  }, [urlParams.labels, urlParams.order_price_min])

  const categoryDescription = useMemo(() => {
    if (exclusive) {
      return 'This is exclusive vendor that included in the selected venue. If you want to buyout it, please contact our support.'
    }

    return data?.exclusivity === 'preferred'
      ? 'Vendors list limited by preferred vendors from BLACE platform'
      : ''
  }, [data?.exclusivity])

  const clearFilter = () => {
    params.setParams({ labels: null, order_price_min: null })
  }

  useEffect(() => {
    if (data?.last_page && data.last_page !== lastPage) {
      setLastPage(data?.last_page)
    }
  }, [data?.last_page])

  useEffect(() => {
    if (data?.total && data.total !== total) {
      setTotal(data?.total)
    } else setTotal(0)
  }, [data?.total])

  useEffect(() => {
    if (category) {
      setSelectedCategoryName(
        vendorTypes?.find((type) => type.id === category)?.name || ''
      )
    } else {
      setSelectedCategoryName('')
    }
  }, [category, vendorTypes])

  return (
    <Box display="flex" flexDirection="column">
      <Box className="container">
        <PageTitle title={vendorCategoryName || t('vendor.title', 'Vendors')} />
        {categoryDescription && (
          <PageTitle mx={0} captionText={categoryDescription} />
        )}
        {isMobile && <VendorLocationFilter />}
        <Box mx={-1}>
          {/*@ts-ignore*/}
          <SearchValuesChips
            mt={4}
            size="large"
            selected={selectedCategoryName as any}
            onClick={onSearchByCategory as any}
            previewMode
            onDeleteSearchValue={() => console.log('test')}
            searchValues={vendorTypes?.map((type) => type.name)}
          />
        </Box>
        <Box
          mt="32px"
          mb={4}
          width={1}
          display={isMobile ? 'block' : 'flex'}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" gap="25px">
            <ActionButton
              style={{
                width: isMobile ? 40 : 48,
                height: isMobile ? 40 : 48,
                marginLeft: 0,
              }}
              icon={<FormatListBulleted sx={{ color: '#000' }} />}
              onClick={onChangeView}
            />
            {!isMobile && (
              <>
                <VendorLocationFilter />
                <StyledBadge
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  style={{ margin: 0 }}
                  badgeContent={getFiltersCount(urlParams)}
                  color="primary"
                >
                  <StyledMoreFiltersButton
                    style={{ height: isMobile ? 40 : 48 }}
                    isOpen={isOpen}
                    endIcon={isOpen ? <ArrowUp /> : <ArrowDown />}
                    onClick={toggleModal}
                  >
                    {t('common.filters.more', 'More filters')}
                  </StyledMoreFiltersButton>
                </StyledBadge>
              </>
            )}
            <Box display="flex" alignItems="center" alignContent="center">
              <SortingSelect
                size="small"
                sortingFields={VENDORS_SORTING_FIELDS}
                orderBy={urlParams.orderBy}
                orderDirection={orderDirection}
                onChangeDirection={onChangeDirection}
                onSort={onSort}
              />
              {isMobile && (
                <Box
                  onClick={toggleModal}
                  alignSelf="end"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { opacity: 0.8 },
                    marginLeft: '25px',
                    height: isMobile ? 40 : 48,
                  }}
                >
                  <Filter />
                </Box>
              )}
            </Box>
          </Box>
          <Box mt={isMobile ? 3 : 0} display="inline-block">
            <PromotionsLink toVendors />
          </Box>
        </Box>
        {!isLoading ? (
          <Typography color="#9396A3" variant="caption">
            {total} results
          </Typography>
        ) : null}
        <VendorsList
          withCategoryName
          isLoading={isLoading}
          pickedVendors={pickedVendors}
          page={urlParams.page}
          lastPage={lastPage}
          onPaginationChange={onPaginationChange}
          vendors={vendors}
        />
      </Box>
      {!isLoading && eventsLoading && <Loader position="fixed" />}
      <SimpleModal
        minWidth={isMobile ? '100%' : 450}
        maxWidth="inherit"
        open={isOpen}
        onClose={toggleModal}
      >
        <VendorsMoreFiltersModal
          onClear={clearFilter}
          initialValues={filterInitialValues}
          showResults={showResultsByMoreFilters}
          onCancel={toggleModal}
        />
      </SimpleModal>
    </Box>
  )
})
