import axios, { AxiosRequestConfig, CancelToken } from 'axios'
import { setupCache } from 'axios-cache-adapter'

import { refreshSessionPath } from '../paths'
import { LsRemoveAuth } from '../storage'

const { REACT_APP_API_URL: apiPath } = process.env

const cache = setupCache({
  maxAge: 60 * 60 * 1000, // 1 hour
  // maxAge: 1, // disable
})

const noCache = setupCache({
  maxAge: 1, // disable
})

export const api = axios.create({
  baseURL: `${window.location.protocol}${apiPath}`,
  withCredentials: true,
  adapter: cache.adapter,
  // params,
})

export const setAuthorizationToken = (token?: string) => {
  if (token) {
    api.defaults.headers = {
      ...api.defaults.headers,
      Authorization: `Bearer ${token}`,
    }
  } else {
    delete api.defaults.headers.Authorization
    LsRemoveAuth()
  }
}

api.interceptors.response.use(undefined, (error) => {
  if (
    error.config &&
    !error.config.refreshSession &&
    error.response &&
    error.response.status === 419
  ) {
    return api
      .get(`${refreshSessionPath}`)
      .then(() => api({ ...error.config, refreshSession: 1 }))
  }
  return Promise.reject(error)
})

export async function get(
  uri: string,
  params?: any,
  config?: AxiosRequestConfig & { forceUpdate?: boolean }
): Promise<any> {
  const response = await api.get(uri, {
    params,
    adapter: config?.forceUpdate ? noCache.adapter : undefined,
    ...config,
  })
  return response.data
}

export async function post(
  uri: string,
  payload?: any,
  cancelToken?: CancelToken
): Promise<any> {
  try {
    const response = await api.post(uri, payload, { cancelToken })
    return response.data
  } catch (e: any) {
    if (e?.response?.status !== 422) {
      //TODO: add error handling for datadog
    }

    throw e
  }
}

export async function postFormData(
  uri: string,
  payload?: any,
  cancelToken?: CancelToken
): Promise<any> {
  try {
    const response = await api.post(uri, payload, {
      cancelToken,
      headers: {
        ...api.defaults.headers,
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (e: any) {
    if (e?.response?.status !== 422) {
      //TODO: add error handling for datadog
    }
    throw e
  }
}

export async function put(
  uri: string,
  payload?: any,
  config?: AxiosRequestConfig
): Promise<any> {
  try {
    const response = await api.put(uri, payload, config)
    return response.data
  } catch (e: any) {
    if (e?.response?.status !== 422) {
      //TODO: add error handling for datadog
    }
    throw e
  }
}

export async function putFormData(
  uri: string,
  payload?: any,
  cancelToken?: CancelToken
): Promise<any> {
  try {
    payload.append('_method', 'PUT')
    const response = await api.post(uri, payload, {
      cancelToken,
      headers: {
        ...api.defaults.headers,
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (e: any) {
    if (e?.response?.status !== 422) {
      //TODO: add error handling for datadog
    }
    throw e
  }
}

export async function deleteRequest(
  uri: string,
  config?: AxiosRequestConfig
): Promise<any> {
  const response = await api.delete(uri, config)
  return response.data
}
