import React, { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { Loader } from '../../../components/ui/Loader'
import { EventDraftStatus } from '../../../constants'
import { useCreateOrDuplicateEvent } from '../../../hooks/useCreateOrDuplicateEvent'
import { LocalEvent } from '../../../types/Event'
import { prepareInquiryData } from '../../../utils/mappers/inquiries'
import { eventPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { InquiryModalControlProvider } from '../../../utils/providers/InquiryModalControlProvider'
import { VendorBasketDataProvider } from '../../../utils/providers/VendorBasketProvider'
import { LsGetInquiryDetails } from '../../../utils/storage'
import { NotAvailablePage } from '../../NotAvailablePage'
import { InquiryBottomModal } from '../components/InquiryBottomModal'

import { InquiryWrapper } from './Inquiry.styles'
import { InquiryForm } from './InquiryForm'

export const Inquiry: React.FC<{ isAbs: boolean }> = ({ isAbs }) => {
  const history = useHistory()
  const { authUser } = useAuthData()
  const { getUserActiveEvent } = useStorageEventsData()
  const { handleCreateOrDuplicateEvent } = useCreateOrDuplicateEvent()

  const [loading, setLoading] = useState(false)
  const userEvent: LocalEvent = getUserActiveEvent()
  const isEventDraft = userEvent?.status === EventDraftStatus

  const submitInquiry = async () => {
    if (authUser) {
      setLoading(true)
      const inquiryData = prepareInquiryData(userEvent, LsGetInquiryDetails())
      await handleCreateOrDuplicateEvent(inquiryData)
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (authUser && !isEventDraft && userEvent?.id) {
      history.push(
        generatePath(eventPath, {
          id: userEvent?.id,
        })
      )
    }
  }, [authUser, isEventDraft, userEvent?.id])

  if ((!isAbs && !userEvent?.venue) || (isAbs && !userEvent?.vendors?.length))
    return <NotAvailablePage type="403" />

  return (
    <VendorBasketDataProvider>
      <InquiryWrapper justifyContent="center" alignItems="center">
        <InquiryModalControlProvider>
          <InquiryForm
            isAbs={isAbs}
            setLoading={setLoading}
            event={userEvent}
            submitInquiry={submitInquiry}
          />
          <InquiryBottomModal />
          {loading && <Loader position="fixed" />}
        </InquiryModalControlProvider>
      </InquiryWrapper>
    </VendorBasketDataProvider>
  )
}
