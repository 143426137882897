import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'
import { generatePath } from 'react-router-dom'

import {
  BusinessInquiryActions,
  InquiryContractResponse,
} from '../types/dtos/inquiry'
import { InquiryEvent } from '../types/Inquiry'
import {
  sendInvoicesBundle,
  useInquiryContractsById,
} from '../utils/api/inquiries'
import { getFirstElement } from '../utils/helpers/data'
import { getRequestError } from '../utils/helpers/validations'
import { inquiryToInquiryEventMapper } from '../utils/mappers/inquiries'
import {
  dashboardBookingDetailsPath,
  dashboardBookingInvoiceListPath,
  dashboardProfilePath,
  generateInvoicesPath,
} from '../utils/paths'
import { useAuthData } from '../utils/providers/AuthProvider'
import { useInquiryDetailsData } from '../utils/providers/InqueryDetailsProvider'
import { useMessageModalData } from '../utils/providers/MessageModalProvider'

import { useCurrentBusinessListingId } from './useCurrentBusinessListingId'
import { useSimpleModal } from './useSimpleModal'
import { useSnackbar } from './useSnackbar'
import { useUpdateEventStatus } from './useUpdateEventStatus'

export function useBusinessInquiryActions() {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const { inquiryDetails, refetch } = useInquiryDetailsData()
  const { data: contracts, refetch: refetchContracts } =
    useInquiryContractsById(+id, { enabled: !!id })
  const { updateInquiryStatusByFlow } = useUpdateEventStatus()
  const { authUser } = useAuthData()
  const modalControl = useSimpleModal()
  const { toggleModal } = modalControl
  const { t } = useTranslation()
  const { showMessage, closeMessageDialog } = useMessageModalData()
  const { businessId } = useCurrentBusinessListingId()
  const history = useHistory()
  const showSnackbar = useSnackbar()

  const [isLoading, setIsLoading] = useState(false)

  const inquiryEvent = useMemo(() => {
    return inquiryDetails ? inquiryToInquiryEventMapper(inquiryDetails) : null
  }, [inquiryDetails])

  const latestContract = React.useMemo(() => {
    if (!contracts?.length) return

    return getFirstElement(contracts)
  }, [contracts])

  const handleBusinessInquiryActions = async (
    action: BusinessInquiryActions,
    extra?: {
      contract?: InquiryContractResponse
      contractId?: number
      invoicesBundleId?: number
      event?: InquiryEvent
      refetchBookings?: () => void
    }
  ) => {
    const inquiryId = extra?.event?.inquiry?.id || id

    switch (action) {
      case BusinessInquiryActions.INQUIRY_DETAILS:
        if (!inquiryId) return

        history.push(
          generatePath(dashboardBookingDetailsPath, {
            id: inquiryId,
            listingId: businessId,
          })
        )
        break
      case BusinessInquiryActions.INQUIRY_ACCEPT:
        if (!!authUser?.payment_info || authUser?.venues?.[0]?.is_exclusive) {
          // if (!!authUser?.payment_info) {
          setIsLoading(true)
          try {
            await updateInquiryStatusByFlow(
              inquiryDetails || extra?.event?.inquiry || {},
              true
            )
            !!extra?.refetchBookings && !!extra?.event
              ? await extra?.refetchBookings()
              : await refetch()
            toggleModal({
              name: 'inquiryAccept',
              event: inquiryEvent || extra?.event,
            })
          } catch (e) {
            console.log('error', e)
          } finally {
            setIsLoading(false)
          }
          break
        }

        showMessage({
          type: 'warning',
          title: t(
            'messages.inquiry.accept.error',
            'You can not to accept inquiry right now'
          ),
          text: t(
            'messages.inquiry.square',
            'You need to connect square payment account firstly before you start to work.'
          ),
          buttons: [
            { label: 'Cancel', action: closeMessageDialog },
            {
              label: 'Connect account',
              action: () => {
                closeMessageDialog()
                history.push(dashboardProfilePath)
              },
            },
          ],
        })
        break
      case BusinessInquiryActions.INQUIRY_DECLINE:
        toggleModal({
          name: 'inquiryReject',
          event: inquiryEvent || extra?.event,
          onRejectAction:
            !!extra?.refetchBookings && !!extra?.event
              ? extra?.refetchBookings
              : refetch,
        })
        break
      case BusinessInquiryActions.CONTRACT_GENERATE:
        toggleModal({ name: 'addContract' })
        break
      case BusinessInquiryActions.INVOICES_GENERATE:
        if (!inquiryId) return

        history.push(
          generatePath(generateInvoicesPath, {
            id: inquiryId,
            listingId: businessId,
          }),
          { invoiceBundleId: extra?.invoicesBundleId }
        )
        break
      case BusinessInquiryActions.INVOICES_BUNDLE_SEND:
        if (!extra?.invoicesBundleId) return

        setIsLoading(true)
        try {
          await sendInvoicesBundle(id, extra.invoicesBundleId)
          await refetch()
          history.push(
            generatePath(dashboardBookingInvoiceListPath, {
              id: inquiryId,
              listingId: businessId,
            })
          )
          showSnackbar({
            severity: 'success',
            message: t(
              'messages.inquiry.invoices.sent',
              'The invoices have been successfully sent'
            ),
          })
        } catch (e) {
          showMessage({
            title: getRequestError(e),
            type: 'error',
          })
        } finally {
          setIsLoading(false)
        }
        break
    }
  }

  return {
    isLoading,
    modalControl,
    handleBusinessInquiryActions,
  }
}
