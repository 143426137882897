import { Box, BoxProps } from '@material-ui/core'

const Google = (props: BoxProps) => {
  return (
    <Box display="flex" {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.4399 11.7138H24.5002V11.6654H14.0002V16.332H20.5936C19.6317 19.0486 17.0469 20.9987 14.0002 20.9987C10.1344 20.9987 7.00016 17.8644 7.00016 13.9987C7.00016 10.1329 10.1344 6.9987 14.0002 6.9987C15.7846 6.9987 17.408 7.67186 18.6441 8.77145L21.944 5.47153C19.8603 3.52961 17.0732 2.33203 14.0002 2.33203C7.55725 2.33203 2.3335 7.55578 2.3335 13.9987C2.3335 20.4416 7.55725 25.6654 14.0002 25.6654C20.4431 25.6654 25.6668 20.4416 25.6668 13.9987C25.6668 13.2164 25.5863 12.4529 25.4399 11.7138Z"
          fill="#20202C"
        />
        <path
          d="M3.67822 8.56845L7.51131 11.3795C8.54847 8.8117 11.0603 6.9987 13.9997 6.9987C15.7841 6.9987 17.4076 7.67186 18.6436 8.77145L21.9436 5.47153C19.8599 3.52961 17.0727 2.33203 13.9997 2.33203C9.51856 2.33203 5.63239 4.86195 3.67822 8.56845Z"
          fill="#FF3D00"
        />
        <path
          d="M14 25.6651C17.0135 25.6651 19.7517 24.5119 21.8219 22.6364L18.2111 19.5809C17.0004 20.5017 15.521 20.9997 14 20.9984C10.9655 20.9984 8.38894 19.0635 7.41827 16.3633L3.61377 19.2945C5.5446 23.0728 9.46577 25.6651 14 25.6651Z"
          fill="#4CAF50"
        />
        <path
          d="M25.4398 11.7164H24.5V11.668H14V16.3346H20.5934C20.1333 17.6275 19.3045 18.7573 18.2093 19.5844L18.2111 19.5832L21.8219 22.6387C21.5664 22.8709 25.6667 19.8346 25.6667 14.0013C25.6667 13.2191 25.5862 12.4555 25.4398 11.7164Z"
          fill="#1976D2"
        />
      </svg>
    </Box>
  )
}

export default Google
