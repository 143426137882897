import React, { Dispatch, SetStateAction } from 'react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import FileIcon from '../../../svg/File'
import GalleryIcon from '../../../svg/Gallery'

import { RichInputProps } from './RichInputFieldContainer'

const CustomToolbar: React.FC<{ withAttachment: boolean }> = ({
  withAttachment,
}) => {
  return (
    <div id="toolbar">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-link"></button>
      <button type="button" className="ql-list" value="ordered"></button>
      <button type="button" className="ql-list" value="bullet"></button>
      {withAttachment && (
        <>
          <button className="ql-addImage" style={{ marginLeft: '10px' }}>
            <GalleryIcon />
          </button>
          <button className="ql-addFile" style={{ marginLeft: '10px' }}>
            <FileIcon />
          </button>
        </>
      )}
    </div>
  )
}

interface RichInputFileProps {
  onAddFile: () => void
  onAddImage: () => void
  fileLimit: string
  setIsFileLimitError: Dispatch<SetStateAction<boolean>>
}

class RichInputField extends React.Component<
  RichInputProps & RichInputFileProps
> {
  modules = {
    toolbar: {
      container: '#toolbar',
      handlers: {
        addFile: () => (this.props.setAttachment ? this.props.onAddFile() : {}),
        addImage: () =>
          this.props.setAttachment ? this.props.onAddImage() : {},
      },
    },
  }

  constructor(props: any) {
    super(props)
    // @ts-ignore
    this.quillRef = null
    // @ts-ignore
    this.reactQuillRef = null
  }

  componentDidMount() {
    this.attachQuillRefs()
  }

  componentDidUpdate() {
    this.attachQuillRefs()
  }

  attachQuillRefs = () => {
    if (
      // @ts-ignore
      !this.reactQuillRef ||
      // @ts-ignore
      typeof this.reactQuillRef.getEditor !== 'function'
    )
      return
    // @ts-ignore
    this.quillRef = this.reactQuillRef.getEditor()
  }

  onChangeText = (value: any) => {
    // @ts-ignore
    const editor = this.quillRef
    if (editor) {
      const inputLength = editor.getLength() - 1
      if (inputLength === 0) {
        this.props.setValue(null)
      } else if (inputLength > this.props.maxLength) {
        editor.deleteText(this.props.maxLength, inputLength)
      } else this.props.setValue(value)
    } else this.props.setValue(value)
  }

  render() {
    return (
      <div className="text-editor">
        <CustomToolbar withAttachment={!!this.props.setAttachment} />
        <ReactQuill
          value={this.props.value || ''}
          // @ts-ignore
          ref={(el) => {
            // @ts-ignore
            this.reactQuillRef = el
          }}
          onChange={this.onChangeText}
          modules={this.modules}
        />
      </div>
    )
  }
}

export default RichInputField
