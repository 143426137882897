const Ticketed = () => {
  return (
    <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.206 3.008a2.98 2.98 0 0 1 3.648 2.107L21.8 16.102a2.978 2.978 0 0 1-2.107 3.649l-6.8 1.823a2.981 2.981 0 0 1-3.651-2.107L6.297 8.48A2.98 2.98 0 0 1 8.405 4.83l6.8-1.823Zm-8.92 9.62 1.91 7.118a4.042 4.042 0 0 0 1.085 1.857l-.48-.026a2.979 2.979 0 0 1-2.819-3.13l.305-5.818Zm9.34-8.05L8.825 6.401a1.354 1.354 0 0 0-.958 1.657l2.944 10.989a1.354 1.354 0 0 0 1.658.957l6.802-1.823a1.354 1.354 0 0 0 .958-1.657l-2.944-10.99a1.354 1.354 0 0 0-1.658-.958h-.001Zm-10.341 6.45L4.9 18.39a4.04 4.04 0 0 0 .46 2.102l-.45-.175a2.98 2.98 0 0 1-1.713-3.849l2.088-5.44Zm5.268-3.409a1.083 1.083 0 1 1 .56 2.093 1.083 1.083 0 0 1-.56-2.093Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default Ticketed
