const FileIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H12C13.1 18 14 17.1 14 16V4.6L9.4 0H2ZM12 16H2V2H8V6H12V16Z"
        fill="#20202C"
      />
    </svg>
  )
}

export default FileIcon
