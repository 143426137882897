const PictureOutlined = () => {
  return (
    <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.313 18.663h.1v-.85l-.024-.027L16 11.395l-.077-.09-.076.09-5.396 6.397-3.442-4.08-.076-.092-.077.091-3.246 3.85-.024.029v1.072h17.725Zm-.077-3.078.177.209V5.338H3.587V15.57l.176-.209 3.103-3.68a.086.086 0 0 1 .133 0l3.375 4.001.077.09.076-.09 5.33-6.319a.086.086 0 0 1 .133 0l5.245 6.22ZM2.75 3.85h19.5c.36 0 .65.29.65.65v15c0 .36-.29.65-.65.65H2.75a.65.65 0 0 1-.65-.65v-15c0-.36.29-.65.65-.65Zm5.626 6.588a1.963 1.963 0 1 1-1.502-3.627 1.963 1.963 0 0 1 1.502 3.627Zm.005-1.813a.755.755 0 1 0-1.512 0 .755.755 0 1 0 1.512 0Z"
        fill="#9396A3"
        stroke="#FAFAFA"
        strokeWidth=".2"
      />
    </svg>
  )
}

export default PictureOutlined
