import { GroupLineItemBundle, LineItemBundle } from '../../types/dtos/inquiry'

export const getAllLineItemsFromGroups = (
  groupedLineItems: GroupLineItemBundle[]
) => {
  const lineItems: LineItemBundle[] = []
  if (groupedLineItems?.length) {
    groupedLineItems.forEach((group) => {
      lineItems.push(...group.line_items)
    })
  }

  return lineItems
}
