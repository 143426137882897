import { ToggleButton, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import {
  vendorsPromotionsPath,
  venuesPromotionsPath,
} from '../../../../utils/paths'

import { StyledToggleButtonGroup } from './PromotionsSwitcher.styles'

export const PromotionsSwitcher: React.FC = () => {
  const { t } = useTranslation()

  return (
    <StyledToggleButtonGroup exclusive>
      <ToggleButton value="venues">
        <NavLink to={venuesPromotionsPath} className="type-link">
          <Typography>{t('promotions.venues', 'Venues')}</Typography>
        </NavLink>
      </ToggleButton>
      <ToggleButton value="vendors">
        <NavLink to={vendorsPromotionsPath} className="type-link">
          <Typography>{t('promotions.vendors', 'Vendors')}</Typography>
        </NavLink>
      </ToggleButton>
    </StyledToggleButtonGroup>
  )
}
