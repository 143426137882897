import { Vendor } from 'types/Vendor'

import { factory, sequence } from './factory'

export const vendorFactory = () =>
  factory<Vendor>({
    id: sequence(),
    name: 'Vendor',
    slug: 'vendor',
    description: '',
    orderPrice: '',
    exclusiveVenueId: 1,
    type: '',
    info: {},
    packages: [],
    contact: {} as any,
    status: 'active',
    hasAdvantages: false,
    advantageDescription: '',
    hasPromotion: false,
    promotionDescription: '',
  })
