import { GooglePlace } from '../components/ui/Inputs/GoogleLocationSelect'

import { ImageItem, ServerCalendar } from './general'

export interface HouseRules {
  allows_cooking: boolean
  allows_fog_haze_machine: boolean
  allows_glitter_confetti: boolean
  allows_music: boolean
  allows_open_flame: boolean
  allows_red_wine: boolean
  allows_smoking: boolean
  allows_ticket_sales_at_all: boolean
  allows_ticket_sales_on_prem: boolean
  allows_underage: boolean
  created_at: string
  guest_curfew_add_details: string | null
  has_guest_curfew: boolean
  has_load_out_curfew: boolean
  has_music_curfew: boolean
  has_show_restriction: boolean
  id: number
  load_out_curfew_add_details: string | null
  music_curfew_add_details: string | null
  shoe_restriction_add_details: string | null
  updated_at: string
}

export interface VenueCapacity {
  seated: string
  standing: string
  theater: string
}

export interface VenueFeatures {
  back_of_house: boolean
  breakout_rooms: boolean
  commercial_kitchen: boolean
  dressing_room: boolean
  fireplace: boolean
  glass_frontage: boolean
  grill: boolean
  high_ceilings: boolean
  kitchen: boolean
  outdoor_kitchen: boolean
  outdoor_space: boolean
  prep_kitchen: boolean
  rooftop: boolean
  security_system: boolean
  showroom_kitchen: boolean
  storage_space: boolean
}

export interface ServerVenueParams {
  minimum_starting_at: number
  inhouse_vendor_types: string[]
  sq_footage: string
  ceiling_height: number
  re_walkthrough: string
  things_you_should_know: string
  number_of_floors_label: string
  cancellation_policy: string
  notes_to_sales: string
  instagram: string
  display_pricing: boolean
  short_pricing_details: null | string
  short_pricing_details_label: null | string
  // facebook: string
}

export interface ServerVenue {
  id: number
  city_id: number
  name: string
  slug: string
  description: string
  address?: {
    formatted_address: string
  }
  location_address?: {
    formatted_address: string
  }
  status: string
  current_referral_fee: string
  floorplans: string[]
  house_rules: HouseRules
  capacity: VenueCapacity
  form_wp_images: string[]
  from_google_images: string[]
  images: ImageItem[]
  google_images: { path: string; id: number }[]
  features: Record<string, boolean>
  access: Record<string, boolean>
  labels?: any
  calendar?: ServerCalendar
  website?: string
  params: ServerVenueParams
  exclusive_vendors: any[]
  primary_contact: {
    email: string
    first_name: string
    legal_entity: string
    office: string
    phone_number: string
  }
  rooms: Room[]
  is_exclusive?: boolean
  max_head_count: number
  payment_structure: {
    pricing_details: string
  }
  partner?: string | null
  has_promotion?: boolean
  promotion_description?: string | null
}

export interface VenueDetails {
  id: number
  city_id: number
  name: string
  slug: string
  status: string
  description: string
  address?: string
  cost: string
  client_owned?: boolean
  floorplans: string[]
  pricing: number
  shortPricingText: string
  shortPricingTextLabel: string
  displayPricing: boolean
  salesNotes: string
  houseRules: string[]
  capacity: VenueCapacity
  rating?: number
  cancellationPolicy?: string
  mainImage?: ImageItem
  photo?: ImageItem
  photos: string[]
  images: ImageItem[]
  exclusiveVendors?: any[]
  inhouseVendorTypes: string[]
  labels: any
  features: Record<string, boolean>
  accessabilities: Record<string, boolean>
  calendar?: ServerCalendar
  website?: string
  params: ServerVenueParams
  contact: {
    name: string
    email: string
    phone: string
    website: string
    // facebook: string
    instagram: string
    address: GooglePlace
  }
  aboutInfo: {
    numberOfFloors: string
    sqFootage: string
    proTip: string
    about: string
    maxGuests: number
  }
  rooms?: Room[]
  is_exclusive?: boolean
  paymentStructure: {
    pricingDetails: string
  }
  partner?: string | null
  hasPromotion?: boolean
  promotionDescription?: string | null
}

export interface VenueItem {
  id: number
  name: string
  slug: string
  address?: {
    // old venues
    formatted_address: string
    address_line_2?: string
  }
  city: { slug: string }
  website?: string
  location_address?: {
    formatted_address: string
    address_line_2?: string
    route?: string
    street_number?: string
    city?: string
    city_long?: string
    zip?: number
  }
  params: {
    minimum_starting_at?: number
    instagram?: string
    display_pricing?: boolean
    short_pricing_details?: string | null
    short_pricing_details_label?: string | null
  }
  payment_type: string
  floorplans?: string[]
  security_deposit?: string
  rating?: number
  max_head_count?: number
  location?: string
  form_wp_images: string[]
  rooms?: Room[]
  images: ImageItem[]
  photo?: ImageItem[]
  form_google_images?: string[]
  status?: string
  client_owned?: boolean
  is_exclusive?: boolean
  primary_contact?: any
  inhouse_exclusive_vendors: Array<{ id: number; name: string; type: string }>
  partner?: string | null
  has_promotion: boolean
  promotion_description: string
  payment_structure?: {
    pricing_details: string | null
  }
}

export interface VenuesQueryOptions {
  name?: string
  location?: string[]
  corners?: object
  perPage?: number
  page?: number | string
  price?: string
  orderBy?: string
  scope?: number
}

export interface VenuesQueryLocationOptions {
  location?: object
}

export interface VenueLocation {
  id: number
  name: string
  location_address: Location
}

export interface Location {
  formatted_address?: string
  lat?: number
  lng?: number
}

export interface VenueCategoriesOptions {
  legacy: number
}

export interface Room {
  floors: number
  capacity: number
  space: number
  created_at: string
  details: string
  id: number
  linked_room?: Room
  linked_room_id: number
  name: string
  updated_at: string
  venue_id: number
  image?: ImageItem
  images?: ImageItem[]
  price: number | null
  short_pricing_details: string | null
  short_pricing_details_label: string | null
  pricing_details: string | null
  display_pricing: boolean
}

export enum SelectVenueStep {
  FindVenue = 'findVenue',
  CreateVenue = 'createVenue',
}
