import {
  Box,
  BoxProps,
  Divider,
  Paper,
  styled,
  TextField,
  Typography,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'

import Chain from '../../../../../components/svg/Chain'
import Copy from '../../../../../components/svg/Copy'
import {
  OptionSelect,
  OptionSelectProps,
} from '../../../../../components/ui/Inputs/OptionSelect'

export const StyledTypography = styled(Typography)`
  margin-bottom: 15px;
`
type StyledOptionSelectProps = OptionSelectProps & {
  label?: string
}

export const StyledOptionSelect = styled(OptionSelect)<StyledOptionSelectProps>(
  (props) => `
  margin-right: 15px;
    .MuiInput-input.MuiSelect-select {
      height: 0
    }
      .MuiSelect-icon {
        &.MuiSelect-iconOpen {
        color: black;
    }
  }
    div[aria-expanded='true'] {
    background-color: #f5f5f8;
    svg {
        fill: #fff;
        color: #fff;
    }
  }
`
)
const StyledPaper = styled(Paper)`
  background: white;
  border-radius: 0;
  min-width: 350px;
  margin-bottom: -8px;
  margin-top: -8px;
  padding: 20px;
  height: 100%;

  .MuiFormControlLabel-root {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .MuiFormGroup-root {
    margin-left: 24px;
    margin-right: 24px;
  }

  ${MEDIA_RULES.SMALL} {
    min-width: unset;
    width: 100%;
  }
`

interface CopyUrlComponentProps extends OptionSelectProps {
  calendarUrl: string
  onCopyCalendarLink: () => void
  id: string
}

export const CopyUrlComponent: React.FC<CopyUrlComponentProps> = ({
  calendarUrl,
  id,
  onCopyCalendarLink,
}) => {
  const renderLabel = () => {
    return <Chain />
  }

  return (
    <StyledOptionSelect
      value={''}
      SelectProps={{
        displayEmpty: true,
        renderValue: () => renderLabel(),
      }}
      id={id}
    >
      <StyledPaper>
        <StyledTypography lineHeight="24px">
          Copy URL to connect to other calendars{' '}
        </StyledTypography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <TextField fullWidth type="string" value={calendarUrl} disabled />
          <Box ml={4} onClick={onCopyCalendarLink}>
            <Copy />
          </Box>
        </Box>
        {/*<Divider />*/}
        {/*<Box display="flex" alignItems="center" justifyContent="space-between" my={4}>*/}
        {/*  <Typography lineHeight="24px">Connect with notifications</Typography>*/}
        {/*  <Chain fill="#9396A3" />*/}
        {/*</Box>*/}
      </StyledPaper>
    </StyledOptionSelect>
  )
}
