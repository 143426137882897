import { isPartner } from '../../constants/partners'
import { LineItem } from '../../pages/dashboard/DocumentForms/GenerateDefaultInvoices/FormSteps/LineItems'
import { LineItemBundle } from '../../types/dtos/inquiry'
import {
  ClientVenue,
  EventVendor,
  EventVenue,
  LocalEvent,
} from '../../types/Event'
import {
  Inquiry,
  InquiryDetailFormValues,
  InquiryEvent,
  InquiryFormPostRequest,
  InquiryWithDetails,
} from '../../types/Inquiry'
import { ProfileData } from '../../types/Profile'
import { Room, VenueDetails } from '../../types/Venue'
import { convertDateTimeToServerFormat } from '../helpers/date'
import {
  buildBudgets,
  getVendorTypesAndPriceByBudget,
  INITIAL_BUDGET,
  TARGET_BUDGET,
  VENUE_BUDGET,
} from '../helpers/inquiries'
import { currencyConvertToDecimal } from '../helpers/price'
import { getCostLevel } from '../helpers/vendors'
import { convertInquiryToEventVenue } from '../helpers/venue'
import { LsGetInquiryFormValues, LsGetSearch } from '../storage'
import tracking from '../tracking'

export const inquiryToInquiryEventMapper = (
  inquiryDetails: InquiryWithDetails
): InquiryEvent => {
  return {
    ...inquiryDetails.event,
    inquiry: inquiryDetails,
    inquiries_related: [inquiryDetails],
  }
}

export const parseCreateInquiryDate = (
  date?: string | string[] | null,
  index?: number
) => {
  if (date && (index || index === 0) && Array.isArray(date)) {
    return convertDateTimeToServerFormat(new Date(date[index]))
  }
  if (date && !Array.isArray(date)) {
    return convertDateTimeToServerFormat(new Date(date))
  }
  return undefined
}

export const createInquiryMapper = (
  inquiryDetails: InquiryDetailFormValues,
  user: ProfileData | null,
  venue: VenueDetails & { client_owned?: boolean; client_venue?: ClientVenue }
): InquiryFormPostRequest => ({
  name: inquiryDetails.name,
  user_name: user?.name || undefined,
  email: user?.email || undefined,
  vendors: inquiryDetails.vendors?.map((vendor) => vendor.id) || [],
  packages: [],
  venue_id: venue?.id || undefined,
  venue_type: venue?.client_owned ? 'client' : 'blace-app',
  // @ts-ignore TODO fix type
  client_venue: venue?.client_venue
    ? {
        ...venue.client_venue,
        placeId: venue?.client_venue?.location.place_id,
        location: venue?.client_venue?.location.description,
      }
    : {
        ...inquiryDetails.client_venue,
        placeId: inquiryDetails.client_venue?.location?.place_id,
        location: inquiryDetails?.client_venue?.location?.description,
      },
  venue,
  notes: inquiryDetails.notes,
  start_date:
    (inquiryDetails.flexibleDates && inquiryDetails.flexibleDates[0]
      ? parseCreateInquiryDate(inquiryDetails.flexibleDates, 0)
      : parseCreateInquiryDate(inquiryDetails.eventDates, 0)) ||
    convertDateTimeToServerFormat(new Date()),
  end_date:
    (inquiryDetails.flexibleDates && inquiryDetails.flexibleDates[1]
      ? parseCreateInquiryDate(inquiryDetails.flexibleDates, 1)
      : parseCreateInquiryDate(inquiryDetails.eventDates, 1)) ||
    convertDateTimeToServerFormat(new Date()),
  type: inquiryDetails.typeId?.toString() || inquiryDetails.type || '',
  is_flexible_date: Boolean(inquiryDetails.flexibleDates),
  phone: user?.phone ? user?.phone : undefined,
  guests: Number(inquiryDetails.guests),
  load_in_start_date: parseCreateInquiryDate(inquiryDetails.loadInDates, 0),
  load_in_end_date: parseCreateInquiryDate(inquiryDetails.loadInDates, 1),
  load_out_start_date: parseCreateInquiryDate(inquiryDetails.loadOutDates, 0),
  load_out_end_date: parseCreateInquiryDate(inquiryDetails.loadOutDates, 1),
  style: inquiryDetails.style,
  budgets: buildBudgets(inquiryDetails),
})

export const createGuestInquiryMapper = (
  inquiryDetails: InquiryFormPostRequest,
  userData: ProfileData
) => ({
  ...inquiryDetails,
  user_name: userData?.name || undefined,
  email: userData?.email || undefined,
  phone: userData?.phone ? userData?.phone : undefined,
})

export const convertFormValuesToJSON = (values: any): string => {
  const parseConvertingDates = (date?: string | string[] | null) => {
    if (date && Array.isArray(date)) {
      return [
        convertDateTimeToServerFormat(new Date(date[0])),
        convertDateTimeToServerFormat(new Date(date[1])),
      ]
    }
    if (date) {
      return [
        convertDateTimeToServerFormat(new Date(date)),
        convertDateTimeToServerFormat(new Date(date)),
      ]
    }
    return undefined
  }

  const data = {
    ...values,
    loadInDates: parseConvertingDates(values.loadInDates),
    eventDates: parseConvertingDates(values.eventDates),
    loadOutDates: parseConvertingDates(values.loadOutDates),
  }
  return JSON.stringify(data)
}

export const checkAndParseDates = (
  date1?: Date | string,
  date2?: Date | string
) => {
  if (!date1 || !date2) return null

  if (typeof date1 === 'string' && typeof date2 === 'string') {
    return [date1, date2]
  }

  return [
    convertDateTimeToServerFormat(date1 as Date),
    convertDateTimeToServerFormat(date2 as Date),
  ]
}

const convertInquiryToEventVendors = (vendorsInquiry: Inquiry[]) => {
  return (
    vendorsInquiry?.map(
      (inquiry: Inquiry) =>
        ({
          id: inquiry?.vendor?.id,
          inquiryId: inquiry?.id,
          name: inquiry?.vendor?.name,
          venueId: inquiry?.vendor?.exclusive_venue_id?.toString(),
          type: inquiry?.vendor?.type,
          photo: inquiry?.vendor?.images?.[0],
          status: inquiry?.status,
          package: inquiry?.package,
          contracts: inquiry?.contracts,
          unread_messages: inquiry?.user_message_thread?.unread || 0,
          exclusive:
            inquiry?.exclusivity_type === 'standard' ||
            inquiry?.exclusivity_type === 'in-house'
              ? inquiry?.exclusivity_type
              : '',
          orderPrice: getCostLevel(inquiry?.vendor?.order_price_min || 0),
        } as EventVendor)
    ) || []
  )
}

const getEventBudget = (event: any) => {
  const priceBudgetName = isPartner() ? TARGET_BUDGET : VENUE_BUDGET

  return event.budgets?.[0]?.title === priceBudgetName
    ? event.budgets[0].value
    : ''
}

export const createEventToFormValuesMapper = (
  event: any
): InquiryDetailFormValues => ({
  name: event.name || '',
  style: event.style || '',
  typeId: event.type || event.eventType || '',
  guests: event.guests || 0,
  isMultipleDates: !!(event.load_in_start_date || event.load_out_start_date),
  isDatesDecided: !event.is_flexible_date,
  loadInDates: checkAndParseDates(
    event.load_in_start_date,
    event.load_in_end_date
  ),
  eventDates: checkAndParseDates(event.start_date, event.end_date),
  loadOutDates: checkAndParseDates(
    event.load_out_start_date,
    event.load_out_end_date
  ),
  isOverAllBudget: !(
    event.budgets && event.budgets[0]?.title === INITIAL_BUDGET
  ),
  isAllocateBudget: !(
    event.budgets && event.budgets[0]?.title === INITIAL_BUDGET
  ),
  price: getEventBudget(event),
  venueBudget: getEventBudget(event) || '',
  flexibleDates: event.is_flexible_date
    ? checkAndParseDates(event.start_date, event.end_date)
    : null,
  priceRange:
    event.budgets && event.budgets[0]?.title === INITIAL_BUDGET
      ? event.budgets[0].value
      : getVendorTypesAndPriceByBudget(event.budgets).priceRange,
  vendorTypes: getVendorTypesAndPriceByBudget(event.budgets).vendorTypes,
  venue: event.venue,
  notes: event.notes || '',
  id: event.id,
})

export const convertInquiryEventToUserEvent = (
  data: InquiryEvent
): LocalEvent => {
  const vendorsInquiry = data.inquiries_related?.filter(
    (item) => !!item.vendor_id
  )
  return {
    end_date: data.end_date ? new Date(data.end_date) : null,
    eventType: data.type,
    guests: data.guests?.toString() || null,
    id: data.id.toString(),
    neighborhood: undefined,
    is_flexible_date: data.is_flexible_date || false,
    name: data.name,
    start_date: data.start_date ? new Date(data.start_date) : null,
    status: data.status,
    unread_messages: data.inquiries_related?.reduce((sum, item) => {
      const count = item?.user_message_thread?.unread || 0
      return count + sum
    }, 0),
    vendors: convertInquiryToEventVendors(vendorsInquiry),
    venue: convertInquiryToEventVenue(data),
    calendar: data.calendar,
    companyName: data.company_name,
    notes: data.notes,
    load_in_end_date: data.load_in_end_date
      ? new Date(data.load_in_end_date)
      : null,
    load_in_start_date: data.load_in_start_date
      ? new Date(data.load_in_start_date)
      : null,
    load_out_end_date: data.load_out_end_date
      ? new Date(data.load_out_end_date)
      : null,
    load_out_start_date: data.load_out_start_date
      ? new Date(data.load_out_start_date)
      : null,
    userName: data.user_name || '',
    phone: data.phone || '',
    budgets: data.budgets || [],
    style: data.style || '',
  }
}
export const venueToEventVenueMapper = (venue: VenueDetails): EventVenue => {
  return {
    name: venue.name,
    id: venue.id,
    address: venue.address,
    rating: venue.rating,
    pricing: venue.params?.minimum_starting_at
      ? venue.params?.minimum_starting_at.toString()
      : '',
    pricingDetails: venue.paymentStructure?.pricingDetails || '',
    displayPricing: venue.displayPricing,
    photo: venue.mainImage || venue.photo,
    primary_contact: venue.contact,
    client_owned: venue.client_owned,
    status: venue.status,
    partner: venue.partner,
  } as EventVenue
}

export const prepareInquiryData = (
  userEvent: LocalEvent,
  inquiryDetails: any
): InquiryFormPostRequest => {
  const vendors = userEvent?.vendors?.map((vendor) => vendor.id) || []
  const rooms =
    inquiryDetails?.rooms?.map((room: Room) => room.id) ||
    inquiryDetails?.venue?.rooms?.map((room: Room) => room.id)
  const filteredInquiryDetails = Object.fromEntries(
    Object.entries(inquiryDetails).filter(([key]) => key !== 'venue')
  )
  const inquiryData = {
    ...filteredInquiryDetails,
    packages: [],
    vendors,
    rooms,
  }
  tracking.submittedInquiry(inquiryData)
  // @ts-ignore
  return inquiryData
}

export const generateEventFormInitialValues = (
  options: { isAbs?: boolean } = {}
) => {
  if (LsGetInquiryFormValues()) {
    return typeof LsGetInquiryFormValues() === 'string'
      ? JSON.parse(LsGetInquiryFormValues())
      : LsGetInquiryFormValues()
  }

  const heroSearchData = LsGetSearch()
  const loadInDates = null
  const guests = heroSearchData.guests?.replace('s', '') || null
  return {
    name: '',
    style: '',
    typeId: heroSearchData.eventType || '',
    guests,
    loadInDates,
    isMultipleDates: !options.isAbs,
    isDatesDecided: true,
    flexibleDates: null,
    eventDates: null,
    loadOutDates: null,
    isOverAllBudget: true,
    isAllocateBudget: true,
    price: '',
    priceRange: '',
    notes: '',
  }
}

export const LineItemBundleMapper = (lineItem: LineItemBundle): LineItem => {
  return {
    value: lineItem.line_item_id.toString(),
    label: lineItem.name,
    quantity: lineItem.quantity,
    price: lineItem.price.toString(),
    clarification: lineItem.clarification || '',
    groupName: lineItem.group_name || '',
    groupDescription: lineItem.group_description || '',
  }
}

export const LineItemValueMapper = (lineItem: LineItem): LineItemBundle => {
  return {
    line_item_id: +lineItem.value,
    price: currencyConvertToDecimal(lineItem.price || 0),
    quantity: +lineItem.quantity,
    name: lineItem.label || '',
    clarification: lineItem.clarification ? lineItem.clarification : '',
    group_name: lineItem.groupName ? lineItem.groupName : '',
    group_description: lineItem.groupDescription
      ? lineItem.groupDescription
      : '',
  }
}
