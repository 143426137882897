const Details = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.778 3.332H2.222a1.111 1.111 0 0 0-1.11 1.111v11.111a1.111 1.111 0 0 0 1.11 1.111h15.556a1.111 1.111 0 0 0 1.111-1.11V4.442a1.111 1.111 0 0 0-1.111-1.111Zm0 12.222H2.222V4.444h15.556v11.11Z"
        fill="#9396A3"
      />
      <path
        d="M5 7.78h10a.556.556 0 0 0 0-1.112H5a.556.556 0 0 0 0 1.111ZM5 10.002h10a.556.556 0 1 0 0-1.111H5A.556.556 0 0 0 5 10ZM5 12.22h5.555a.556.556 0 1 0 0-1.11H5a.555.555 0 1 0 0 1.11Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default Details
