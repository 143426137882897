import { common } from '@material-ui/core/colors'

const blue = '#003366'
const buttonBg = '#003366'
const white = common.white
const grey = '#20202c'

const themePalette = {
  palette: {
    primary: {
      main: blue,
      light: blue,
    },
    common: {
      footerBg: white,
      buttonBg: buttonBg,
      headerIconBg: white,
      headerIconColor: grey,
      headerIconHover: 'rgba(255, 255, 255, 0.8)',
      linkLight: '#0080FF',
    },
  },
}

export default themePalette
