import { Box, Typography, useTheme } from '@material-ui/core'
import { TertiaryButton } from 'components/ui/Buttons/TertiaryButton'
import React from 'react'

import Plus from '../../../../components/svg/Plus'

interface AddNewInquiryFlowElementButtonProps {
  onClick: () => void
  btnText: string
}

export const AddNewInquiryFlowElementButton: React.FC<
  AddNewInquiryFlowElementButtonProps
> = ({ btnText, onClick }) => {
  const theme = useTheme()

  return (
    <Box display="flex" justifyContent="flex-end">
      <TertiaryButton
        mt="20px"
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
      >
        <Plus fill={theme.palette.primary.light} />
        <Typography variant="body2" fontWeight={500} ml={1}>
          {btnText}
        </Typography>
      </TertiaryButton>
    </Box>
  )
}
