const XLSXDocument = () => {
  return (
    <svg width="44" height="45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.667 39.819a2 2 0 0 1-2 2H9.333a2 2 0 0 1-2-2v-34.5a2 2 0 0 1 2-2h17.339a2 2 0 0 1 1.414.586L36.08 11.9a2 2 0 0 1 .586 1.414v26.505Z"
        fill="#848484"
        fillOpacity=".12"
      />
      <path d="M35.292 13.402h-8.709V4.694l8.709 8.708Z" fill="#DFE0EB" />
      <path
        d="M14.667 19.819H30.25v1.833H14.667V19.82ZM14.667 23.485h11.916v1.834H14.667v-1.834ZM14.667 27.152H30.25v1.833H14.667v-1.833ZM14.667 30.819h11.916v1.833H14.667V30.82ZM4.152 10.285 5.88 13.06H4.856L3.6 11.045 2.424 13.06H1.408l1.728-2.776L1.4 7.5h1.024l1.264 2.032L4.872 7.5h1.016l-1.736 2.784Zm3.641 2.04h1.88v.736H6.881V7.5h.912v4.824Zm4.475.792c-.373 0-.71-.064-1.008-.192a1.683 1.683 0 0 1-.704-.56c-.17-.24-.256-.52-.256-.84h.976a.89.89 0 0 0 .28.592c.17.155.408.232.712.232.315 0 .56-.075.736-.224a.753.753 0 0 0 .264-.592.633.633 0 0 0-.168-.456 1.055 1.055 0 0 0-.408-.272 5.87 5.87 0 0 0-.672-.208 7.004 7.004 0 0 1-.888-.288 1.502 1.502 0 0 1-.576-.464c-.16-.208-.24-.485-.24-.832 0-.32.08-.6.24-.84.16-.24.384-.424.672-.552a2.442 2.442 0 0 1 1-.192c.539 0 .979.136 1.32.408.347.267.539.635.576 1.104h-1.008a.73.73 0 0 0-.288-.52c-.176-.144-.408-.216-.696-.216-.261 0-.475.067-.64.2-.165.133-.248.325-.248.576 0 .17.05.312.152.424.107.107.24.192.4.256.16.064.379.133.656.208.368.101.667.203.896.304.235.101.432.259.592.472.165.208.248.488.248.84 0 .283-.077.55-.232.8-.15.25-.37.453-.664.608-.288.15-.63.224-1.024.224Zm5.548-2.832 1.728 2.776H18.52l-1.256-2.016-1.176 2.016h-1.016l1.728-2.776L15.064 7.5h1.024l1.264 2.032L18.536 7.5h1.016l-1.736 2.784Z"
        fill="#597EF7"
      />
    </svg>
  )
}

export default XLSXDocument
