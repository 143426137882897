import { Box, FormHelperText, styled, Typography } from '@material-ui/core'
import React from 'react'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const CurrencyInputWrapper = styled(Box)`
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: #141414;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  border-radius: 4px;

  p {
    color: #9396a3;
  }

  input {
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    border: 1.5px solid lightgray;
    border-radius: 4px;
    box-sizing: content-box;
    height: 1.4375em;
    display: block;
    padding: 12px 0 12px 5px;
    width: -webkit-fill-available;

    &:focus {
      outline: #4e4bec auto 1px;
    }
  }

  &.error {
    p {
      color: #f16547;
    }
    input {
      border-color: #f16547;
    }
  }
`

interface MaskInputProps {
  prefix: string
  suffix: string
  includeThousandsSeparator: boolean
  thousandsSeparatorSymbol: string
  allowDecimal: boolean
  decimalSymbol: string
  decimalLimit: number
  requireDecimal: boolean
  allowNegative: boolean
  allowLeadingZeroes: boolean
  integerLimit: number
}

const defaultOptions: Partial<MaskInputProps> = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 9, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

export const CurrencyTextInput: React.FC<
  Partial<MaskedInputProps> & {
    maskOptions?: Partial<MaskInputProps>
    error?: boolean
    helperText?: string
    label?: string
  }
> = ({ maskOptions, label, helperText, error, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultOptions,
    ...maskOptions,
  })

  return (
    <CurrencyInputWrapper className={error ? 'error' : ''}>
      {label && (
        <Typography textAlign="left" variant="body2" sx={{ mb: 2 }}>
          {label}
        </Typography>
      )}
      <MaskedInput {...inputProps} mask={currencyMask} type="text" />
      {helperText && (
        <FormHelperText sx={{ ml: 3, mr: 3 }} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </CurrencyInputWrapper>
  )
}
