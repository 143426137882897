import { Box, Typography } from '@material-ui/core'
import { Close, CheckCircle, ErrorOutlineRounded } from '@material-ui/icons'
import * as CSS from 'csstype'
import React from 'react'

import ExclamationCircle from '../../../svg/ExclamationCircle'
import { SimpleButton } from '../../Buttons/SimpleButton'

import {
  ConfirmModalContentWrapper,
  ConfirmModalText,
  ConfirmModalCloseButton,
  ConfirmModalInfoContainer,
  ConfirmModalWrapper,
} from './ConfirmModal.styles'

export interface ConfirmModalProps {
  open: boolean
  onClose: () => void
  style?: CSS.Properties
  type?: 'success' | 'error' | 'warning' | 'none'
  title: string
  text?: string
  onAccept?: () => void
  buttons?: Array<{
    label: string
    action: () => void
    variant?: 'contained' | 'outlined'
    shouldCloseAfterAction?: boolean
  }>
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  onClose,
  type = 'warning',
  title,
  text,
  onAccept,
  style,
  buttons,
}) => {
  const handleAcceptClick = () => {
    if (onAccept) {
      onAccept()
    }
    onClose()
  }

  const onCustomButtonClick = (button: {
    label: string
    action: () => void
    variant?: 'contained' | 'outlined'
    shouldCloseAfterAction?: boolean
  }) => {
    button.action()
    if (button.shouldCloseAfterAction) {
      onClose()
    }
  }

  const renderIcon = () => {
    switch (type) {
      case 'warning':
        return <ExclamationCircle />
      case 'success':
        return <CheckCircle color="success" fontSize="inherit" />
      case 'error':
        return <ErrorOutlineRounded color="error" fontSize="inherit" />
      case 'none':
        return null
      default:
        return <ExclamationCircle />
    }
  }

  return (
    <ConfirmModalWrapper
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.4)' } }}
    >
      <ConfirmModalContentWrapper sx={style}>
        <ConfirmModalCloseButton color="inherit" onClick={onClose}>
          <Close />
        </ConfirmModalCloseButton>
        <ConfirmModalInfoContainer>
          {type !== 'none' && <Box mb={5}>{renderIcon()}</Box>}
          <Box mb={text ? 3 : 5}>
            <ConfirmModalText fontWeight={600} variant="body1">
              {title}
            </ConfirmModalText>
          </Box>
          {text && (
            <Box mb={5}>
              <ConfirmModalText fontSize={14} variant="body1">
                {text}
              </ConfirmModalText>
            </Box>
          )}
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="end"
            alignSelf="stretch"
          >
            {buttons ? (
              <Box width="100%" display="flex" alignItems="center">
                {buttons.map((button) => (
                  <SimpleButton
                    key={button.label}
                    style={{ padding: 10 }}
                    sx={{
                      marginRight: '15px',
                      '&:last-of-type': { marginRight: 0 },
                    }}
                    variant={button?.variant || 'contained'}
                    onClick={() => onCustomButtonClick(button)}
                  >
                    <Typography fontWeight={500}>{button.label}</Typography>
                  </SimpleButton>
                ))}
              </Box>
            ) : onAccept ? (
              <Box width="100%" display="flex">
                <SimpleButton
                  style={{ marginRight: 25 }}
                  variant="outlined"
                  onClick={onClose}
                >
                  No
                </SimpleButton>
                <SimpleButton variant="contained" onClick={handleAcceptClick}>
                  Yes
                </SimpleButton>
              </Box>
            ) : (
              <Box width="100%" display="flex">
                <SimpleButton variant="contained" onClick={onClose}>
                  Got it
                </SimpleButton>
              </Box>
            )}
          </Box>
        </ConfirmModalInfoContainer>
      </ConfirmModalContentWrapper>
    </ConfirmModalWrapper>
  )
}
