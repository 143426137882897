import { Box, ImageListItem } from '@material-ui/core'
import React, { ReactElement } from 'react'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { PromotionLabel } from '../../cards/labels/PromotionLabel'
import PromotionStar from '../../svg/PromotionStar'
import { Image } from '../Image'

import { Slide } from './SwipeSlider'
import { SlideTitle } from './SwipeSlider.styles'

const SliderComponent = ({ item }: { item: Slide }): ReactElement => {
  const { isMobile } = useWindowSize()

  return (
    <ImageListItem>
      <Box
        height="100%"
        sx={{
          position: 'relative',
          '&:hover': {
            cursor: item.onClick ? 'pointer' : 'default',
            opacity: item.onClick ? 0.8 : 1,
          },
        }}
      >
        <Image
          onClick={() => item.onClick && item.onClick(item.id)}
          image={item.image}
          imageSize={item.imageSize}
          staticImageType={item.imageStaticType}
          alt={item.title}
        />
        {!!item.promotionDescription &&
          (!isMobile ? (
            <PromotionLabel />
          ) : (
            <Box position="absolute" left={6} top={6}>
              <PromotionStar />
            </Box>
          ))}
      </Box>
      {item.title && (
        <SlideTitle
          position="top"
          onClick={() => item.onClick && item.onClick(item.id)}
          title={item.title}
        />
      )}
      {item.footer}
    </ImageListItem>
  )
}

export default SliderComponent
