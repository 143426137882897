const GrayExclamationCircle = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1a7 7 0 1 0 .001 14.001A7 7 0 0 0 8 1Zm0 12.813A5.813 5.813 0 0 1 8 2.187a5.813 5.813 0 0 1 0 11.626Z"
        fill="#9396A3"
      />
      <path
        d="M7.25 10.75a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0ZM7.625 9h.75a.125.125 0 0 0 .125-.125v-4.25a.125.125 0 0 0-.125-.125h-.75a.125.125 0 0 0-.125.125v4.25c0 .069.056.125.125.125Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default GrayExclamationCircle
