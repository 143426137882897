import { useSearchParams } from 'hooks/useSearchParams'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { UserRoles } from '../../constants/profile'
import { AuthMenuActions, AuthMethods } from '../../types/Auth'
import {
  dashboardPath,
  eventsListPath,
  marketplacePath,
  messagesSuccessMagicLinkPath,
} from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { LsGetLastPath, LsRemoveLastPath } from '../../utils/storage'
import { SimpleModal } from '../ui/Modals/SimpleModal'

import ForgotPassword from './SignIn/ForgotPassword'
import LogIn from './SignIn/LogIn'
import ResetPassword from './SignIn/ResetPassword'
import WelcomeBack from './SignIn/WelcomeBack'
import PersonalInfoForm from './SignUp/PersonalInfoForm'
import PhoneVerification from './SignUp/PhoneVerification'
import SignUp from './SignUp/SignUp'

export const AuthModals = () => {
  const history = useHistory()
  const { params } = useSearchParams()
  const action = params.get('action')

  const { authUser, authModalControl } = useAuthData()

  const { isOpen, toggleModal, modalData, updateModalData } = authModalControl

  useEffect(() => {
    if (action === 'login') {
      toggleModal({ step: AuthMenuActions.Signin })
    } else if (action === 'signup') {
      toggleModal({ step: AuthMenuActions.Signup })
    } else if (isOpen) toggleModal()
  }, [action])

  const handleSignUpComplete = (method: AuthMethods) => {
    switch (method) {
      case AuthMethods.Magic:
        toggleModal()
        history.push(messagesSuccessMagicLinkPath)
        break
      case AuthMethods.Simple:
      default:
        updateModalData({ step: 'info' })
        break
    }
  }

  const handleLoginComplete = (method: AuthMethods) => {
    toggleModal()
    const savedPath = LsGetLastPath()
    if (modalData.onLoginSuccess) {
      modalData.onLoginSuccess()
    } else if (savedPath) {
      LsRemoveLastPath()
      history.push(savedPath)
    } else if (method === AuthMethods.Magic) {
      history.push(messagesSuccessMagicLinkPath)
    } else if (
      authUser?.role === UserRoles.Vendor ||
      authUser?.role === UserRoles.Landlord
    ) {
      history.push(dashboardPath)
    } else if (authUser?.role === UserRoles.Client) {
      history.push(eventsListPath)
    } else {
      history.push(marketplacePath)
    }
  }

  const handleToggleModal = () => {
    history.replace({
      search: '',
    })
    toggleModal()
  }

  const renderModalContent = () => {
    switch (modalData?.step as AuthMenuActions) {
      case AuthMenuActions.Signin:
        return (
          <LogIn
            initialEmail={modalData?.email}
            message={modalData?.message}
            onComplete={handleLoginComplete}
            onForgot={() => updateModalData({ step: AuthMenuActions.Forgot })}
          />
        )
      case AuthMenuActions.WelcomeBack:
        return (
          <WelcomeBack
            onComplete={toggleModal}
            onForgot={() => updateModalData({ step: AuthMenuActions.Forgot })}
          />
        )
      case AuthMenuActions.Forgot:
        return (
          <ForgotPassword
            onComplete={() => {
              toggleModal()
              history.push(messagesSuccessMagicLinkPath)
            }}
          />
        )
      case AuthMenuActions.Reset:
        return <ResetPassword onComplete={toggleModal} />
      case AuthMenuActions.Signup:
        return <SignUp onComplete={handleSignUpComplete} />
      case AuthMenuActions.Verification:
        return (
          <PhoneVerification
            onComplete={() => {
              if (modalData.afterVerification) {
                updateModalData({ step: modalData.afterVerification })
              } else {
                toggleModal()
              }
            }}
          />
        )
      case AuthMenuActions.Info:
        return (
          <PersonalInfoForm
            onComplete={() => {
              toggleModal()
              history.push(messagesSuccessMagicLinkPath)
            }}
          />
        )
    }
  }

  if (modalData?.step as AuthMenuActions) {
    return (
      <>
        {renderModalContent()}
      </>
    )
  }

  return (
    <SimpleModal open={isOpen} onClose={handleToggleModal}>
      {renderModalContent()}
    </SimpleModal>
  )
}
