import * as React from 'react'

import { useVendorBasket } from '../../hooks/useVendorBasket'
import { EventVendor } from '../../types/Event'

export const VendorBasketDataContext = React.createContext<{
  pickedVendors: Array<EventVendor>
  toggleVendorPick: (vendor: EventVendor) => void
}>({
  pickedVendors: [],
  toggleVendorPick: () => {},
})

export const VendorBasketDataProvider: React.FC = ({ children }) => {
  const { pickedVendors, toggleVendorPick } = useVendorBasket()
  const context = React.useMemo(
    () => ({
      pickedVendors,
      toggleVendorPick,
    }),
    [pickedVendors, toggleVendorPick]
  )

  return (
    <VendorBasketDataContext.Provider value={context}>
      {children}
    </VendorBasketDataContext.Provider>
  )
}

export const useVendorBasketData = () => {
  const context = React.useContext(VendorBasketDataContext)
  if (context === undefined) {
    throw new Error(
      'useVendorBasketData must be used within an VendorBasketDataProvider'
    )
  }
  return context
}
