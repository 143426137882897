import { Box, Typography } from '@material-ui/core'
import { Lock, Star } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import LinkText from '../../../../../components/ui/Buttons/LinkText/LinkText'
import { TooltipComponent } from '../../../../../components/ui/Tooltip'
import { IconContainer } from '../EventServices/EventServices.styles'

export const LockedTooltip: React.FC<{
  toggleLockedVendorModal: (e: React.MouseEvent) => void
}> = ({ toggleLockedVendorModal }) => {
  const { t } = useTranslation()

  const lockedTooltipBox = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="body2">
        {t('vendor.locked.until', 'Locked until contract')}
      </Typography>
      <LinkText
        style={{ fontSize: 14 }}
        variant="body2"
        onClick={toggleLockedVendorModal}
      >
        {t('common.buttons.more', 'Know more')}
      </LinkText>
    </Box>
  )
  return (
    <TooltipComponent
      arrow={true}
      placement="top"
      title={lockedTooltipBox}
      onClick={(e) => {
        e.stopPropagation()
        toggleLockedVendorModal(e)
      }}
    >
      <IconContainer
        style={{ outlineOffset: '-2px', outline: '2px solid #FFE870' }}
      >
        <Lock style={{ fontSize: 16, color: '#FFE870' }} />
      </IconContainer>
    </TooltipComponent>
  )
}
