const DocumentWarning = ({ fill = '#F3BD00', width = 18 }) => {
  return (
    <svg
      width={width}
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0297 5.76406L11.9859 0.720313C11.8453 0.579688 11.6555 0.5 11.4562 0.5H1.5C1.08516 0.5 0.75 0.835156 0.75 1.25V20.75C0.75 21.1648 1.08516 21.5 1.5 21.5H16.5C16.9148 21.5 17.25 21.1648 17.25 20.75V6.29609C17.25 6.09687 17.1703 5.90469 17.0297 5.76406ZM15.5203 6.64062H11.1094V2.22969L15.5203 6.64062ZM15.5625 19.8125H2.4375V2.1875H9.51562V7.25C9.51562 7.51107 9.61934 7.76145 9.80394 7.94606C9.98855 8.13066 10.2389 8.23438 10.5 8.23438H15.5625V19.8125ZM8.0625 16.4375C8.0625 16.6861 8.16127 16.9246 8.33709 17.1004C8.5129 17.2762 8.75136 17.375 9 17.375C9.24864 17.375 9.4871 17.2762 9.66291 17.1004C9.83873 16.9246 9.9375 16.6861 9.9375 16.4375C9.9375 16.1889 9.83873 15.9504 9.66291 15.7746C9.4871 15.5988 9.24864 15.5 9 15.5C8.75136 15.5 8.5129 15.5988 8.33709 15.7746C8.16127 15.9504 8.0625 16.1889 8.0625 16.4375ZM8.4375 14H9.5625C9.66562 14 9.75 13.9156 9.75 13.8125V9.5C9.75 9.39688 9.66562 9.3125 9.5625 9.3125H8.4375C8.33437 9.3125 8.25 9.39688 8.25 9.5V13.8125C8.25 13.9156 8.33437 14 8.4375 14Z"
        fill={fill}
      />
    </svg>
  )
}

export default DocumentWarning
