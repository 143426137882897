import {
  Box,
  Divider,
  TableBodyProps,
  TableCell,
  TableRowProps,
} from '@material-ui/core'
import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'

import { StyledTableRow } from '../../pages/events/Event/components/EventServices/EventServices.styles'

interface TabsNavigationProps {
  activeTab: string
  setActiveTab: Dispatch<SetStateAction<string>>
}

export interface CollapsibleRowProps extends Pick<TableRowProps, 'selected'> {
  summaryRow?: React.ReactElement<TableRowProps>
  expanded?: boolean
  expandable?: boolean
  tabs?: React.ReactElement<TabsNavigationProps>
  children: TableBodyProps['children']
  onChange: () => void
}
export const CollapsibleRow = function CollapsibleRow({
  children,
  expanded = false,
  expandable = true,
  summaryRow = <></>,
  tabs,
  onChange,
}: CollapsibleRowProps) {
  const [open, setOpen] = React.useState(expanded)

  React.useEffect(() => {
    setOpen(expanded)
  }, [expanded])

  return (
    <>
      {summaryRow}
      {expandable && tabs && (
        <>
          <StyledTableRow hovered onClick={onChange}>
            <TableCell colSpan={8}>
              <Divider
                style={{
                  color: '#F0F0F0',
                  marginTop: '-15px',
                  marginBottom: '10px',
                }}
              />
              {tabs}
            </TableCell>
          </StyledTableRow>
          <Box sx={{ height: '10px', width: '100%' }} />
        </>
      )}
      {open && children}
    </>
  )
}
