import { FormControlLabel, Switch } from '@material-ui/core'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface PricingSwitcherProps {
  showPricing: boolean
  setShowPricing: any
  setSwitchTouched?: Dispatch<SetStateAction<boolean>>
}

export const LandlordPricingSwitcher: React.FC<PricingSwitcherProps> = ({
  showPricing,
  setShowPricing,
  setSwitchTouched,
}) => {
  const { t } = useTranslation()

  return (
    <FormControlLabel
      control={
        <Switch
          checked={showPricing}
          onChange={() => {
            setSwitchTouched && setSwitchTouched(true)
            setShowPricing(!showPricing)
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      label={t('business.pricing.landlord.display', 'Display Pricing')}
    />
  )
}
