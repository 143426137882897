import { List, ListItem, ListItemText, styled } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import { ConfirmDialog } from '../../components/ui/Modals/ConfirmDialog'

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    line-height: 28px;
  }
`

export const SuccessMagicLink = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { url } = useRouteMatch()

  const trimUrl = (url: string): string => {
    const trimmedUrl = url.replace(/(https?:\/)?\/+/gm, '$1/')
    return trimmedUrl.replace(/([^/])\/$/gm, '$1')
  }

  const backUrl = (url: string, times = 1): string => {
    const urlParts = trimUrl(url).split('/')

    return urlParts.slice(0, urlParts.length - times).join('/') || '/'
  }

  return (
    <ConfirmDialog
      confirmLabel={'Back to home'}
      dialogTitle={t('messages.emailCheck', 'Check Your Email')}
      cancelLabel={'label'}
      onClose={() =>
        history.push({
          pathname: backUrl(url),
          search: '',
        })
      }
      open={true}
    >
      <List>
        <ListItem disableGutters>
          <StyledListItemText
            primary={t(
              'messages.signIn.successSent',
              "We've sent you an email with a verification link that is active for 10 minutes. Please click through that link to sign in and continue."
            )}
          />
        </ListItem>
      </List>
    </ConfirmDialog>
  )
}
