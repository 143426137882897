const TXTDocument = () => {
  return (
    <svg width="44" height="45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.667 39.819a2 2 0 0 1-2 2H9.333a2 2 0 0 1-2-2v-34.5a2 2 0 0 1 2-2h17.339a2 2 0 0 1 1.414.586L36.08 11.9a2 2 0 0 1 .586 1.414v26.505Z"
        fill="#848484"
        fillOpacity=".12"
      />
      <path d="M35.292 13.402h-8.709V4.694l8.709 8.708Z" fill="#DFE0EB" />
      <path
        d="M14.667 19.819H30.25v1.833H14.667V19.82ZM14.667 23.485h11.916v1.834H14.667v-1.834ZM14.667 27.152H30.25v1.833H14.667v-1.833ZM14.667 30.819h11.916v1.833H14.667V30.82ZM5.192 7.5v.745h-1.48v4.816H2.8V8.245H1.312V7.5h3.88Zm3.476 2.785 1.728 2.776H9.372l-1.256-2.016L6.94 13.06H5.924l1.728-2.776L5.916 7.5H6.94l1.264 2.032L9.388 7.5h1.016l-1.736 2.784Zm6.32-2.784v.744h-1.48v4.816h-.911V8.245h-1.488V7.5h3.88Z"
        fill="#10239E"
      />
    </svg>
  )
}

export default TXTDocument
