import { mapValues } from 'lodash/fp'
import React, { ComponentType, PropsWithChildren } from 'react'
import { createElement } from 'react'

export const wrapWith =
  <P extends PropsWithChildren<{}>>(Wrapper: React.ComponentType<P>) =>
  <PC extends {}>(Component: React.ComponentType<PC>) =>
    function Wrapped(props: Omit<P, 'children'> & PC) {
      return (
        <Wrapper {...(props as unknown as P)}>
          <Component {...props} />
        </Wrapper>
      )
    }

export const renderNothing = () => null
export const renderChildren = ({ children }: PropsWithChildren<{}>) => children

export const withProps =
  <TProps extends {} = {}>(props: TProps) =>
  <T extends TProps>(Component: ComponentType<T>) =>
    function WithProps(ownProps: PropsWithChildren<Omit<T, keyof TProps>>) {
      return createElement(Component, { ...props, ...ownProps } as T)
    }

export const withHooks =
  <TData extends Record<string, any>, TProps extends object>(hooks: {
    [K in keyof TData]: () => TData[K]
  }) =>
  (Component: ComponentType<TProps & TData>) =>
    function WithHooks(props: TProps) {
      const data = mapValues((hook) => hook(), hooks) as TData
      return <Component {...props} {...data} />
    }
