import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { VenueDetails } from '../../../../types/Venue'
import { formatToCurrency } from '../../../../utils/helpers/price'

export const VenuePricing: React.FC<{ venue?: VenueDetails | null }> = ({
  venue,
}) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box
        id="pricing"
        display="flex"
        flexDirection="column"
        flex={1}
        className="section-block"
      >
        <Typography variant="h2">
          {t('business.pricing.landlord.heading', 'Pricing')}
        </Typography>
        {(venue?.displayPricing ||
          (!venue?.displayPricing &&
            !venue?.paymentStructure.pricingDetails)) && (
          <Box>
            {venue?.paymentStructure.pricingDetails && (
              <Typography
                variant="h3"
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="normal"
                mb="16px"
              >
                {t('business.pricing.landlord.staring', 'Minimum Rate')}
              </Typography>
            )}
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {venue?.displayPricing
                ? `${formatToCurrency(venue?.pricing, false)} / ${
                    venue?.shortPricingTextLabel
                  }`
                : venue?.paymentStructure.pricingDetails
                ? ''
                : t(
                    'business.pricing.landlord.notDisplay',
                    'Inquire for Pricing'
                  )}
            </Typography>
          </Box>
        )}
        {venue?.paymentStructure.pricingDetails && (
          <Box mt={venue?.displayPricing ? 5 : 0}>
            {venue?.displayPricing && (
              <Typography
                variant="h3"
                fontFamily="Poppins"
                fontSize={16}
                mb="16px"
                fontWeight="normal"
              >
                {t('business.pricing.landlord.details', 'Pricing Details')}
              </Typography>
            )}
            <Typography
              dangerouslySetInnerHTML={{
                __html: venue?.paymentStructure.pricingDetails,
              }}
            />
          </Box>
        )}
      </Box>
      {/* <Box id="rules" display="flex" flexDirection="column" flex={1} mr={6} mb={10}>
        <Typography variant="h2">
          Space Rules
        </Typography>
        <Box sx={{ paddingLeft: 5 }} display="grid" gridTemplateColumns="auto auto">
          {venue?.houseRules.map((rule, index) => (
            <Typography key={index} sx={{ display: 'list-item', my: '5px', fontWeight: 500 }} variant="body1">
              {rule}
            </Typography>
          ))}
        </Box>
      </Box> */}
      {!!venue?.aboutInfo.proTip && (
        <Box className="section-block">
          <Typography variant="h2">Things you should know</Typography>
          <Box>
            {venue?.aboutInfo.proTip.split(/\r\n|\r|\n/).map((item, i) => (
              <Typography
                sx={{ my: '5px', mb: '10px' }}
                variant="body2"
                fontWeight={500}
                key={i}
                mt={1}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
      {!!venue?.cancellationPolicy && (
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          className="section-block"
        >
          <Typography variant="h2">
            {t('venue.cancellationPolicy', 'Cancellation Policy')}
          </Typography>
          <Typography variant="body2">{venue?.cancellationPolicy}</Typography>
        </Box>
      )}
    </Box>
  )
}
