import { Box, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
// @ts-ignore
import ImageGallery from 'react-image-gallery'

import { GalleryImage } from '../../types/general'

import './ImageGallery.css'
interface ImageGalleryComponentProps {
  images: GalleryImage[]
  onClose: () => void
}

export const ImageGalleryComponent: React.FC<ImageGalleryComponentProps> = ({
  images,
  onClose,
}) => {
  return (
    <div className="gallery-container">
      <Box>
        <Box className="close-button" onClick={onClose}>
          <Typography>Close</Typography>
          <Close />
        </Box>
        <ImageGallery
          showThumbnails={false}
          additionalClass="custom-gallery-content"
          items={images}
          showPlayButton={false}
          showIndex={true}
          onImageError={() => console.log('error')}
        />
      </Box>
    </div>
  )
}
