import * as yup from 'yup'

import { validateEmail } from '../../../../../utils/helpers/validations'

export const personalInfoValidationSchema = () =>
  yup.object().shape({
    name: yup.string().trim().max(60).required('Name is required'),
    email: yup
      .string()
      .required('Email is required')
      .test('email', 'Invalid email address', (email) => {
        return validateEmail(email || '')
      }),
    phone: yup.string().required('Phone is required'),
    company: yup.string().trim(),
  })
