import { Box } from '@material-ui/core'
import { Selection } from 'components/ui/Checkbox'
import { forwardRef } from 'react'
import { City, District, Neighborhood } from 'utils/api/locations'

import { CityDropdown } from './CityDropdown'
import {
  NeighborhoodsDropdown,
  useNeighborhoodsPopup,
} from './NeighborhoodsDropdown'

export type VenueLocationFilterProps = {
  options: City[]
  values: {
    city: string
    districts: Record<string, Selection>
    neighborhoods: Record<string, boolean>
  }
  getLocation: {
    city: (slug: string) => City
    district: (slug: string) => District
    neighborhood: (slug: string) => Neighborhood
  }
  selected: {
    city: City
    districts: District[]
    neighborhoods: Neighborhood[]
  }
  onChange: (key: 'city' | 'district' | 'neighborhood', slug: string) => void
  onClear: () => void
}

export const VenueLocationFilter = forwardRef(
  (props: VenueLocationFilterProps, ref) => {
    const { options, values, getLocation, onChange, onClear } = props
    const { district, neighborhoods, onDistrictHover, ...neighborhoodsPopup } =
      useNeighborhoodsPopup({ getDistrict: getLocation.district })

    const onNeighborhoodChange = (slug: string) =>
      onChange('neighborhood', slug)
    const onDistrictChange = (slug: string) => onChange('district', slug)
    const onCityChange = (slug: string) => {
      neighborhoodsPopup.onClose()
      onChange('city', slug)
    }

    return (
      <Box display="flex" flexDirection="row">
        <CityDropdown
          options={options}
          values={values}
          onCityClick={onCityChange}
          onDistrictHover={onDistrictHover}
          onDistrictToggle={onDistrictChange}
          onReset={onClear}
        />
        {neighborhoodsPopup.isOpen && neighborhoods.length && (
          <NeighborhoodsDropdown
            title={district!.name || ''}
            options={neighborhoods}
            values={values.neighborhoods}
            onToggle={onNeighborhoodChange}
          />
        )}
      </Box>
    )
  }
)

VenueLocationFilter.displayName = 'VenueLocationFilter'
