import { guestsVariants } from 'constants/common'
import { INITIAL_QUERY_PARAMS } from 'constants/marketplace'

import { Box } from '@material-ui/core'
import { ClearSharp } from '@material-ui/icons'
import { PromotionsLink } from 'components/cards/labels/PromotionsLink'
import ArrowDown from 'components/svg/ArrowDown'
import ArrowUp from 'components/svg/ArrowUp'
import ListIcon from 'components/svg/ListIcon'
import Map from 'components/svg/Map'
import {
  MultiSelectField,
  StyledBadge,
} from 'components/ui/FormComponents/MultiSelectField'
import { SimpleModal } from 'components/ui/Modals/SimpleModal'
import { TooltipComponent } from 'components/ui/Tooltip'
import { useSearchParams } from 'hooks/useSearchParams'
import { useWindowSize } from 'hooks/useWindowSize'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
// @ts-ignore
import { useTranslation } from 'react-i18next'
import { getFiltersCount } from 'utils/helpers/common'
import { getQueryFromMoreFilters } from 'utils/helpers/venue'
import { venueListQueryParams } from 'utils/mappers/venueListQueryParamsMapper'
import { mapGuestQueryParamsToFormValues } from 'utils/mappers/venues'

import {
  StyledChip,
  VenuesFilterWrapper,
} from '../../Marketplace/Marketplace.styles'

import { VenueLocationFilterController as VenueLocationFilter } from './components/VenueLocationFilter/VenueLocationFilter.controller'
import { MoreFiltersModal } from './MoreFilters'
import {
  StyledIconButton,
  StyledMoreFiltersButton,
} from './VenuesFilter.styles'

interface VenuesFilterProps {
  updateQueryParams: (updatedQueryParams: any) => void
  initialValues: venueListQueryParams
  toggleMapView: () => void
  isMapVisible: boolean
  categories: any
  isOpen: boolean
  toggleModal: () => void
}

const SelectMenuProps = {
  MenuListProps: { style: { padding: 0 } },
  PaperProps: { style: { marginTop: 20 } },
}

export const VenuesFilter: React.FC<VenuesFilterProps> = ({
  updateQueryParams,
  initialValues,
  isMapVisible,
  toggleMapView,
  categories,
  isOpen,
  toggleModal,
}) => {
  const [categorySelectedOptions, setCategorySelectedOptions] = useState<
    string[]
  >([])
  const venueCategories: string[] = categories ? Object.values(categories) : []
  const { isMobile } = useWindowSize()
  const params = useSearchParams()
  const { t } = useTranslation()

  const sortedVenueCategories = useMemo(() => {
    return venueCategories ? venueCategories.sort() : []
  }, [venueCategories])

  const handleCategoriesSelect = (value: string[]) => {
    setCategorySelectedOptions([...value])
    const query = []
    for (const k in categories) {
      if (value.includes(categories[k])) {
        query.push(k)
      }
    }
    const queryString = query.join(',')
    updateQueryParams({ ...initialValues, page: 1, categories: queryString })
  }

  const handleGuestsSelect = (value: string) => {
    if (value !== undefined) {
      updateQueryParams({ ...initialValues, page: 1, guests: value })
    }
  }

  useEffect(() => {
    if (initialValues.categories) {
      const categoryNames = []
      for (const k in categories) {
        if (initialValues.categories.includes(k)) {
          categoryNames.push(categories[k])
        }
      }
      setCategorySelectedOptions(categoryNames)
    } else {
      setCategorySelectedOptions([])
    }
  }, [initialValues.categories, categorySelectedOptions?.length])

  const showResultsByMoreFilters = (moreFilterFormValues: any) => {
    const {
      labels,
      ceiling_height_min,
      ceiling_height_max,
      sq_footage_min,
      sq_footage_max,
    } = getQueryFromMoreFilters(moreFilterFormValues)
    const labelsQueryString = labels.join(',')
    updateQueryParams({
      ...initialValues,
      page: 1,
      labels: labelsQueryString,
      ceiling_height_min,
      ceiling_height_max,
      sq_footage_min,
      sq_footage_max,
    })
    toggleModal()
  }

  const clearFilter = () => {
    updateQueryParams({
      ...initialValues,
      labels: '',
      page: 1,
      ceiling_height_min: '',
      ceiling_height_max: '',
      sq_footage_min: '',
      sq_footage_max: '',
    })
  }
  const resetAllFilters = () => {
    // @ts-ignore
    params.setParams(INITIAL_QUERY_PARAMS)
  }

  return (
    <>
      {isMobile ? (
        <>
          <Box mb={4}>
            <PromotionsLink />
          </Box>
          <Box mb={4} width={1}>
            <VenueLocationFilter />
          </Box>
        </>
      ) : (
        <VenuesFilterWrapper>
          <Box className="container">
            <Box justifyContent="space-between" display="flex">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
              >
                <VenueLocationFilter />
                <MultiSelectField
                  selectSize="small"
                  style={{ marginLeft: 15 }}
                  multiple={true}
                  id="categories"
                  defaultLabel="Venues"
                  options={sortedVenueCategories}
                  selectedOptions={categorySelectedOptions}
                  onChange={(value: string[]) => handleCategoriesSelect(value)}
                />
                <MultiSelectField
                  selectSize="small"
                  id="guests"
                  MenuProps={SelectMenuProps}
                  defaultLabel="Guests"
                  options={guestsVariants}
                  onChange={(value: string) => handleGuestsSelect(value)}
                  selectedOptions={
                    initialValues.guests ||
                    mapGuestQueryParamsToFormValues(initialValues)
                  }
                  isRadio={true}
                />
                {/*@ts-ignore*/}
                <StyledBadge
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  badgeContent={getFiltersCount(initialValues)}
                  color="primary"
                >
                  <StyledMoreFiltersButton
                    isOpen={isOpen}
                    endIcon={isOpen ? <ArrowUp /> : <ArrowDown />}
                    onClick={toggleModal}
                  >
                    {t('common.filters.more', 'More filters')}
                  </StyledMoreFiltersButton>
                </StyledBadge>
                <StyledChip
                  onDelete={resetAllFilters}
                  deleteIcon={<ClearSharp />}
                  onClick={resetAllFilters}
                  label={t('venues.resetAllFilters', 'Reset all filters')}
                />
              </Box>
              <Box display="flex">
                <PromotionsLink />
                {isMapVisible ? (
                  <TooltipComponent title="Back to list" placement="top">
                    <StyledIconButton onClick={toggleMapView}>
                      <ListIcon />
                    </StyledIconButton>
                  </TooltipComponent>
                ) : (
                  <TooltipComponent title="Map view" placement="top">
                    <StyledIconButton onClick={toggleMapView}>
                      <Map />
                    </StyledIconButton>
                  </TooltipComponent>
                )}
              </Box>
            </Box>
          </Box>
        </VenuesFilterWrapper>
      )}
      <SimpleModal
        minWidth={isMobile ? '100%' : 450}
        maxWidth="inherit"
        open={isOpen}
        onClose={toggleModal}
      >
        <MoreFiltersModal
          onClear={clearFilter}
          initialValues={initialValues}
          showResults={showResultsByMoreFilters}
          onCancel={toggleModal}
        />
      </SimpleModal>
    </>
  )
}
