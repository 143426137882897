import {
  Typography,
  TypographyOptions,
  TypographyStyleOptions,
  Variant as TypographyVariant,
} from '@material-ui/core/styles/createTypography'

import { usePartner } from '../hooks/usePartner'
import { useWindowSize } from '../hooks/useWindowSize'

export const defaultFontFamily = 'Frank Ruhl Libre'
export const poppinsFontFamily = 'Poppins'
export const absFontFamily = 'Acumin Pro'

const round = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100

type Variant = TypographyVariant | 'title'

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    pxToEm: (value: number, fontSize: number) => string
  }
}

declare module '@material-ui/core/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true
  }
}

export const createTypography = (
  typography: TypographyOptions = {}
): Typography => {
  const { isMobile } = useWindowSize()
  const { isAbs } = usePartner()

  const {
    fontFamily = !isAbs ? defaultFontFamily : absFontFamily,
    fontSize = 16,
    fontWeightLight = 300,
    fontWeightRegular = 400,
    fontWeightMedium = 500,
    fontWeightBold = 700,
    htmlFontSize = 16,
  } = typography
  const coef = fontSize / 16
  const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem`
  const pxToEm = (value: number, fontSize: number) =>
    `${round(value / fontSize)}em`

  const body: TypographyStyleOptions = {
    fontFamily: poppinsFontFamily,
    fontWeight: fontWeightRegular,
    fontSize: pxToRem(fontSize),
    lineHeight: pxToRem(20),
    letterSpacing: pxToEm(0.5, fontSize),
  }

  const body2: TypographyStyleOptions = {
    fontFamily: poppinsFontFamily,
    fontWeight: fontWeightRegular,
    fontSize: pxToRem(14),
    lineHeight: 1.43,
    letterSpacing: pxToEm(0.2, 14),
  }

  const variants: { [key in Variant]: TypographyStyleOptions } = {
    h1: {
      fontWeight: fontWeightBold,
      fontSize: !isMobile ? pxToRem(36) : pxToRem(24),
      lineHeight: 1.12,
      letterSpacing: pxToEm(-0.5, fontSize),
      ...typography.h1,
    },
    h2: {
      fontFamily,
      fontWeight: fontWeightBold,
      fontSize: !isMobile ? pxToRem(24) : pxToRem(18),
      lineHeight: !isMobile ? pxToRem(31) : pxToRem(24),
      letterSpacing: pxToEm(0.3, fontSize),
      ...typography.h2,
      marginBottom: '32px',
    },
    h3: {
      fontFamily,
      fontWeight: fontWeightBold,
      fontSize: pxToRem(24),
      lineHeight: 1.59,
      ...typography.h3,
    },
    h4: {
      fontFamily,
      fontWeight: fontWeightBold,
      fontSize: pxToRem(18),
      lineHeight: 1.39,
      ...typography.h4,
    },
    //Poppins from UI KIT Heading3
    h5: {
      fontFamily: poppinsFontFamily,
      fontWeight: fontWeightMedium,
      fontSize: pxToRem(56),
      lineHeight: 1.39,
      ...typography.h5,
    },
    // from UI KIT Heading4
    h6: {
      fontFamily: poppinsFontFamily,
      fontWeight: fontWeightMedium,
      fontSize: pxToRem(39),
      lineHeight: 1.39,
      ...typography.h6,
    },
    title: {
      fontFamily: poppinsFontFamily,
      fontWeight: 600,
      fontSize: pxToRem(16),
      lineHeight: 1.5,
    },
    subtitle1: {
      fontFamily: poppinsFontFamily,
      ...typography.subtitle1,
    },
    // from UI KIT Heading6
    subtitle2: {
      fontFamily: poppinsFontFamily,
      ...typography.subtitle2,
    },
    body1: { ...body, ...typography.body1 },
    body2: { ...body2, ...typography.body2 },
    caption: {
      fontFamily: poppinsFontFamily,
      ...typography.caption,
    },
    // Button big from UI KIT
    button: {
      fontFamily,
      // fontWeight: fontWeightMedium,
      // fontSize: pxToRem(18),
      // lineHeight: 1.25,
      ...typography.button,
    },
    // The smallest from UI KIT
    overline: {
      fontFamily: poppinsFontFamily,
      fontWeight: fontWeightMedium,
      fontSize: pxToRem(12),
      lineHeight: 1.67,
      textTransform: 'none',
      ...typography.overline,
    },
  }

  return {
    htmlFontSize: fontSize,
    fontSize,
    pxToEm: pxToEm,
    pxToRem,
    fontFamily,
    fontWeightLight,
    fontWeightMedium,
    fontWeightRegular,
    fontWeightBold,
    ...variants,
  }
}
