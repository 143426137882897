import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { NavLink } from '../../NavLink'
import ArrowDown from '../../svg/ArrowDown'
import ArrowUp from '../../svg/ArrowUp'
import { UserIconOutlined } from '../../svg/venues/UserIconOutlined'
import { PopperMenu } from '../../ui/PopperMenu'

export const LoginSignUpMenu: React.FC = () => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <PopperMenu
      placement="bottom"
      options={[]}
      rightPointer={true}
      renderOptions={
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width={1}
          minWidth={235}
        >
          <NavLink to={`?action=login`}>
            <Button
              className="header-button"
              style={{ width: '100%', justifyContent: 'flex-start' }}
            >
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  color: '#20202C',
                  fontWeight: 500,
                }}
                variant="body1"
                fontFamily="Poppins"
              >
                {t('header.logIn', 'Log in')}
              </Typography>
            </Button>
          </NavLink>
          <Divider
            style={{ background: '#848484', marginBottom: 5, marginTop: 5 }}
          />
          <NavLink to={`?action=signup`}>
            <Button
              className="header-button"
              style={{ width: '100%', justifyContent: 'flex-start' }}
            >
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  color: '#20202C',
                  fontWeight: 500,
                }}
                variant="body1"
                fontFamily="Poppins"
              >
                {t('header.signUp', 'Sign up')}
              </Typography>
            </Button>
          </NavLink>
        </Box>
      }
      onSelectAction={(item) => console.log(item)}
    >
      <ClickAwayListener
        onClickAway={() => {
          setIsOpen(false)
        }}
      >
        <IconButton
          sx={{
            borderRadius: '2px',
            width: !isMobile ? '66px' : 'unset',
            height: !isMobile ? '44px' : 'unset',
            background: isOpen ? '#FFFFFF' : 'none',
            '&:hover': { background: (theme) => theme.palette.text.disabled },
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Box display="flex" alignItems="center">
            <UserIconOutlined fill={isOpen ? '#20202C' : '#fff'} />
            {isOpen ? <ArrowUp fill="#000" /> : <ArrowDown fill="#fff" />}
          </Box>
        </IconButton>
      </ClickAwayListener>
    </PopperMenu>
  )
}
