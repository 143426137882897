import { Box, styled, Typography } from '@material-ui/core'
import React from 'react'

import { formatToCurrency } from '../../../../../../utils/helpers/price'

interface TotalChipProps {
  color: string
  total: number
  bgColor: string
  title: string
}

const ChipContainer = styled(Box)<{ color: string; bgColor: string }>(
  ({ theme, color, bgColor }) => `
  display: flex;
  justify-content: center;
  flex: 1;
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 4px;
  border: 1px solid ${color};
  background-color: ${bgColor};
  
  &:last-of-type {
    margin-right: 0;
  }
`
)

export const TotalChip: React.FC<TotalChipProps> = ({
  color,
  total,
  bgColor,
  title,
}) => {
  return (
    <ChipContainer color={color} bgColor={bgColor}>
      <Box mr={1} display="flex" alignItems="center">
        <Typography
          whiteSpace="nowrap"
          variant="body2"
          fontSize={12}
          color="#9396A3"
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" color="#20202C" fontWeight={600}>
          {formatToCurrency(Math.round(total), false)}
        </Typography>
      </Box>
    </ChipContainer>
  )
}
