import * as yup from 'yup'

import { phoneRegExp } from '../../../../constants'

export const createValidationSchema = () =>
  yup.object().shape({
    firstName: yup.string().trim(),
    lastName: yup.string().trim(),
    phoneNumber: yup
      .string()
      .transform((value) => (value ? value.replace(/\D+/g, '') : ''))
      .matches(phoneRegExp, 'Phone number is not valid'),
    email: yup.string().trim().email('Email is not valid'),
    birthDate: yup.string().trim(),
  })
