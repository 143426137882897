/** @jsxImportSource @emotion/react */
import { Loader } from 'components/ui/Loader'
import { useDownloadContract } from 'hooks/useDownloadContract'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import { InquiryContractResponse } from 'types/dtos/inquiry'
import { sendContract } from 'utils/api/inquiries'
import { getRequestError } from 'utils/helpers/validations'
import { useAuthData } from 'utils/providers/AuthProvider'
import { useMessageModalData } from 'utils/providers/MessageModalProvider'

import { AddContractForm } from './AddContractForm'

type AddContractModalProps = {
  onClose: () => void
  latestContract?: InquiryContractResponse
}

export const AddContractModal = ({
  latestContract,
  onClose,
}: AddContractModalProps) => {
  const {
    params: { id: inquiryId },
  } = useRouteMatch<{ id: string }>()

  const { t } = useTranslation()
  const { isLoading: authLoading } = useAuthData()
  const { isLoading: isDownloadContractLoading } = useDownloadContract()
  const { showMessage } = useMessageModalData()
  const [loading, setLoading] = useState(false)

  const onSubmit = (data: FormData) => {
    setLoading(true)
    if (latestContract) data.append('parent_id', latestContract.id.toString())

    sendContract(inquiryId, data)
      .then(() => {
        onClose()
        showMessage({
          title: t(
            'messages.inquiry.contract.sent',
            'Your contract has been successfully sent!'
          ),
        })
      })
      .catch((err) => {
        showMessage({
          title: getRequestError(err),
          type: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <AddContractForm onSubmit={onSubmit} onCancel={onClose} />

      {(loading || authLoading || isDownloadContractLoading) && (
        <Loader position="fixed" />
      )}
    </>
  )
}
