import { Box, Link, styled, Typography } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'

import BlaceLogo from '../../assets/images/blace.png'
import { venuesPath } from '../../utils/paths'
import { SimpleButton } from '../ui/Buttons/SimpleButton'
import { Loader } from '../ui/Loader'

import { useWizard } from './WizardProvider'

const WizardScreenWrapper = styled(Box)`
  background: white;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const ScreenNameContainer = styled(Box)`
  background: #20202c;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px 120px;
`

const BackButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`

const ScreenContent = styled(Box)`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  padding: 40px 115px;
  overflow: auto;
`

export interface WizardScreenProps {
  title: string
  subtitle?: string
  index: number
  buttonLabel?: string
  buttonDisabled?: boolean
  stepData?: any
  isLoading?: boolean
  showStepButton?: boolean
}

export const WizardStep: React.FC<WizardScreenProps> = ({
  children,
  index,
  title,
  subtitle,
  buttonLabel,
  buttonDisabled,
  isLoading,
  stepData,
  showStepButton = true,
}) => {
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { getStep, activeStep, maxSteps, onFinish, onNext } = useWizard()

  const stepNumber = activeStep + 1 // index to number
  const { nextStep, previousStep, isActive } = getStep(index)
  const loading = isLoading || isSubmitting

  const handleOnNext = async () => {
    setIsSubmitting(true)
    try {
      if (stepNumber === maxSteps) {
        await onFinish(stepData)
      } else {
        await onNext(stepData)
        nextStep()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleOnBack = () => {
    if (index === 0) {
      history.goBack()
    } else {
      previousStep()
    }
  }

  return isActive ? (
    <WizardScreenWrapper display="flex">
      <ScreenNameContainer>
        <Box
          display="flex"
          alignItems="center"
          style={{ position: 'relative' }}
        >
          <Link
            underline="none"
            href={venuesPath}
            className="logo-wrapper"
            width={122}
            component="a"
            height={22}
            onClick={(e) => {
              e.preventDefault()
              history.push(venuesPath)
            }}
          >
            <img src={BlaceLogo} width={122} />
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flex={1}
          style={{ animation: `fadeIn 2s` }}
        >
          <Typography variant="h5" color="secondary" align="center">
            {title}
          </Typography>
          <Typography variant="body2" color="secondary" align="center">
            {subtitle}
          </Typography>
        </Box>
        <BackButtonContainer onClick={handleOnBack}>
          <ArrowBack color="secondary" />
          <Typography variant="body1" color="secondary" align="center">
            Back
          </Typography>
        </BackButtonContainer>
      </ScreenNameContainer>
      <ScreenContent>
        {maxSteps > 1 && stepNumber && (
          <Box style={{ alignSelf: 'end' }}>
            <Box display="flex" alignItems="baseline">
              <Typography variant="h6" fontSize={32}>
                {stepNumber}
              </Typography>
              <Typography color="#9396A3" variant="caption" fontSize={16}>
                /{maxSteps}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          justifyContent="center"
          alignItems="flex-start"
          style={{ animation: `fadeIn 2s` }}
        >
          {children}
        </Box>
        {showStepButton && (
          <Box
            style={{
              alignSelf: 'end',
              position: 'fixed',
              bottom: 30,
              right: 50,
            }}
          >
            <SimpleButton
              disabled={buttonDisabled}
              variant="contained"
              type="submit"
              onClick={handleOnNext}
            >
              {buttonLabel || (
                <>
                  Next <ArrowForward />
                </>
              )}
            </SimpleButton>
          </Box>
        )}
        {loading && <Loader position="absolute" />}
      </ScreenContent>
    </WizardScreenWrapper>
  ) : null
}
