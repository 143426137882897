import { Box, ImageListItemBar, Typography, useTheme } from '@material-ui/core'
import { CalendarTodayOutlined, MoreVert } from '@material-ui/icons'
import * as React from 'react'

import PictureOutlined from '../../../../components/svg/PictureOutlined'
import { Image } from '../../../../components/ui/Image'
import { Loader } from '../../../../components/ui/Loader'
import { ConfirmModal } from '../../../../components/ui/Modals/ConfirmModal'
import { PopperMenu } from '../../../../components/ui/PopperMenu'
import { getDefaultEventName } from '../../../../constants'
import { getMenuActionsByStatus } from '../../../../constants/events'
import { useConfirmModal } from '../../../../hooks/useConfirmModal'
import { usePartner } from '../../../../hooks/usePartner'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { LocalEvent } from '../../../../types/Event'
import { useEventInquiriesStatuses } from '../../../../utils/api/events'
import { convertDateToShortString } from '../../../../utils/helpers/date'
import { useStorageEventsData } from '../../../../utils/providers/EventsProvider'

import {
  EventItemStatus,
  EventItemWrapper,
  PhotoPlaceholderContainer,
  SubtitleWrapper,
  TitleTextWrapper,
  TitleWrapper,
} from './EventsItem.styles'

export const EventItem: React.FC<{
  event: LocalEvent
  isLoading: boolean
  handleOnEventSelect: (action: string, event: LocalEvent) => void
  handleEventClick: (event: LocalEvent) => void
}> = ({ event, isLoading, handleOnEventSelect, handleEventClick }) => {
  const theme = useTheme()
  const { isAbs } = usePartner()
  const { getUserActiveEvent } = useStorageEventsData()
  const { data: statusesData } = useEventInquiriesStatuses()
  const inquiryStatuses = statusesData?.inquiryStatus?.stages || {}
  const { isMobile } = useWindowSize()
  const {
    isOpen: isChooseVenueModalOpen,
    toggleModal: toggleChooseVenueModel,
    modalData: chooseVenueModalData,
  } = useConfirmModal()
  const userEvent = getUserActiveEvent()

  const title = (
    <TitleWrapper>
      <TitleTextWrapper variant="h4" onClick={() => handleEventClick(event)}>
        {event?.name || getDefaultEventName(!!isAbs)}
      </TitleTextWrapper>
      <Box display="flex" alignItems="baseline">
        <PopperMenu
          options={getMenuActionsByStatus(
            event?.status,
            theme.palette.secondary.dark
          )}
          isFullScreen={false}
          onSelectAction={(action) => handleOnEventSelect(action, event)}
        >
          <MoreVert className="event-action" />
        </PopperMenu>
      </Box>
    </TitleWrapper>
  )

  const subtitle = (
    <SubtitleWrapper isMobile={Boolean(isMobile)}>
      <Box display="flex" alignItems="center">
        <CalendarTodayOutlined sx={{ width: 15 }} />
        <Typography
          style={{ textTransform: 'uppercase', marginLeft: '10px' }}
          fontSize={14}
        >
          {event?.start_date
            ? convertDateToShortString(event?.start_date, true)
            : 'MM DD YYYY'}
        </Typography>
      </Box>
    </SubtitleWrapper>
  )

  return (
    <EventItemWrapper
      isActive={event?.id === userEvent?.id}
      isMobile={Boolean(isMobile)}
    >
      {/*@ts-ignore*/}
      <EventItemStatus
        borderColor={inquiryStatuses[event?.status]?.border_color as any}
      >
        <Typography
          fontSize={12}
          color={inquiryStatuses[event?.status]?.text_color}
        >
          {event?.status}
        </Typography>
      </EventItemStatus>
      {event?.venue?.photo?.path ? (
        <Box
          height="100%"
          paddingBottom="130px"
          sx={{ '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
        >
          <Image
            image={event?.venue?.photo}
            imageSize="medium"
            alt="event image"
            onClick={() => handleEventClick(event)}
          />
        </Box>
      ) : (
        <PhotoPlaceholderContainer onClick={() => handleEventClick(event)}>
          <PictureOutlined />
          <Typography variant="body2" fontSize={12} color="#9396A3">
            No image here
          </Typography>
        </PhotoPlaceholderContainer>
      )}
      <ImageListItemBar
        sx={{ height: '130px' }}
        position="bottom"
        title={title}
        subtitle={subtitle}
      />
      <ConfirmModal
        buttons={[
          { label: 'Venue listing', action: chooseVenueModalData.onAccept },
        ]}
        open={isChooseVenueModalOpen}
        onClose={toggleChooseVenueModel}
        type={chooseVenueModalData.type}
        title={chooseVenueModalData.title}
        text={chooseVenueModalData.text}
        onAccept={chooseVenueModalData.onAccept}
      />
      {isLoading && <Loader position="fixed" />}
    </EventItemWrapper>
  )
}
