const Picture = () => {
  return (
    <svg width="18" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.125.125H.875A.624.624 0 0 0 .25.75v12.5c0 .346.28.625.625.625h16.25c.346 0 .625-.28.625-.625V.75a.624.624 0 0 0-.625-.625Zm-.781 12.344H1.656v-.78L4.361 8.48l2.932 3.477 4.56-5.406 4.49 5.324v.594Zm0-2.535L11.973 4.75a.155.155 0 0 0-.239 0l-4.441 5.266L4.48 6.682a.155.155 0 0 0-.238 0L1.656 9.748V1.531h14.688v8.403ZM4.937 5.906a1.719 1.719 0 1 0 0-3.437 1.719 1.719 0 0 0 0 3.437Zm0-2.265a.546.546 0 1 1 0 1.093.546.546 0 1 1 0-1.093Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default Picture
