import { SVGProps } from 'react'

const CalendarBlack = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 20 20" width="20" height="20" fill="#262626" {...props}>
      <path d="M14.188 2.313v.5H18.624a.25.25 0 0 1 .25.25v15.562a.25.25 0 0 1-.25.25H1.375a.25.25 0 0 1-.25-.25V3.062a.25.25 0 0 1 .25-.25H5.813V1.125H6.5v1.688h7V1.125h.688v1.188Zm3.5 15.874h.5V8.281H1.813v9.906h15.874Zm-15.875-11v.5H18.187V3.5h-4v1.313H13.5V3.5h-7v1.313h-.688V3.5h-4v3.688Z" />
    </svg>
  )
}

export default CalendarBlack
