import { isNil } from 'lodash'

import AbsIcon from '../assets/images/abs-icon.png'
import BlaceIcon from '../assets/images/blace-icon.png'

const SUBDOMAINS = process.env.REACT_APP_SUBDOMAINS
const SUBFOLDERS = process.env.REACT_APP_ABS_SUBFOLDERS

export const SUBDOMAINS_ARRAY = SUBDOMAINS ? SUBDOMAINS.split(',') : []
export const SUBFOLDERS_ARRAY = SUBFOLDERS ? SUBFOLDERS.split(',') : []

export const getPartnerPath = () => {
  const path = window.location && window.location.pathname.split('/')

  return path && path[1]
}

export const getPartnerSubdomain = () => {
  const host = window.location.host
  const parts = host.split('.')

  return parts[0]
}

export const isPartnerPath = () => {
  const partnerPath = getPartnerPath()

  return !isNil(partnerPath) && SUBFOLDERS_ARRAY.includes(partnerPath)
}
export const isPartnerSubdomain = () => {
  const subdomain = getPartnerSubdomain()

  return !isNil(subdomain) && SUBDOMAINS_ARRAY.includes(subdomain)
}
export const isPartner = () => isPartnerPath() || isPartnerSubdomain()

export const partnerSlug = () =>
  isPartnerPath()
    ? getPartnerPath()
    : isPartnerSubdomain()
    ? getPartnerSubdomain()
    : null
export const partnerUrl = () => {
  if (isPartnerPath()) {
    return `${window.location.origin}/${partnerSlug()}`
  }
  if (isPartnerSubdomain()) {
    return window.location.origin
  }
  return null
}

export const getPartnerImageBySlug = (partner?: string): string => {
  switch (partner) {
    case 'abs':
      return AbsIcon
    default:
      return BlaceIcon
  }
}

const getPartnerParam = () => {
  if (
    isPartnerPath() ||
    isPartnerSubdomain() ||
    location.search.includes('landing_source')
  ) {
    return 'abs'
  }

  return 'blace'
}

export const getPartnerParams = () => ({ partner: getPartnerParam() })

export const getUrlPartnerParam = () =>
  isPartner() ? `partner=${getPartnerParam()}` : ''
