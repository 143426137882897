import { Box, ImageList, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'

import { BottomFixedActionBlock } from '../../../components/BottomFixedActionBlock'
import { RoomCard } from '../../../components/cards/RoomCard'
import GradientButton from '../../../components/ui/Buttons/GradientButton/GradientButton'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { PageTitle } from '../../../components/ui/PageTitle'
import { WarningBox } from '../../../components/ui/WarningBox'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useVenueById } from '../../../hooks/useVenueById'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { Room } from '../../../types/Venue'
import { getModalContent } from '../../../utils/helpers/bookings'

export const VenueRooms: React.FC = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()
  const { venue } = useVenueById(id)
  const history = useHistory()
  const [isNotified, setIsNotified] = useState(false)
  const [selectedRooms, setSelectedRooms] = useState<Room[]>([])
  const rooms = venue?.rooms || []
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  const togglePickedRoom = (selected: Room) => {
    let updatedRooms = []

    if (selectedRooms.find((item) => selected.id === item.id)) {
      updatedRooms = selectedRooms.filter((item) => item.id !== selected.id)
    } else {
      updatedRooms = [...selectedRooms, selected]
    }

    setSelectedRooms(updatedRooms)
  }

  const modalControl = useSimpleModal()
  const { isOpen, toggleModal, modalData } = modalControl

  const openCreateInquiryForm = () => {
    localStorage.removeItem('inquiryFormValues')
    toggleModal({ name: 'inquiryCreate', venue, rooms: selectedRooms })
  }

  return (
    <Box display="flex" flexDirection="column" className="container">
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={!isMobile ? 'center' : 'flex-start'}
      >
        <PageTitle title={t('rooms.pageTitle', 'Rooms')} />
        {!isNotified && (
          <Box
            width={isMobile ? '100%' : 'unset'}
            flex={isMobile ? '0 0 auto' : 1}
            ml={isMobile ? 0 : '41px'}
          >
            <WarningBox maxWidth={530} onCancel={() => setIsNotified(true)}>
              <Typography variant="body2">
                {t(
                  'events.roomWarning',
                  'To create inquiry choose some current room(s) for your event'
                )}
              </Typography>
            </WarningBox>
          </Box>
        )}
      </Box>
      <Box>
        <ImageList
          style={{
            width: '100%',
            overflowY: 'visible',
          }}
          cols={isMobile ? 1 : 4}
        >
          {rooms.map((room) => (
            <RoomCard
              key={room.id}
              shouldBackToRooms
              room={room}
              onSelect={togglePickedRoom}
              isSelected={Boolean(selectedRooms.find((r) => r.id === room.id))}
            />
          ))}
        </ImageList>
      </Box>
      <BottomFixedActionBlock>
        <Box
          className="container"
          height={1}
          justifyContent="space-between"
          alignItems="center"
          display="flex"
          flex={1}
        >
          {!isMobile && (
            <Box
              display="flex"
              sx={{ '&:hover': { opacity: 0.8 } }}
              alignItems="center"
              onClick={() => history.goBack()}
            >
              <ArrowBack sx={{ color: '#2F54EB' }} />
              <Typography
                sx={{
                  color: '#2F54EB',
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              >
                {t('rooms.backToVenue', 'Back to Venue')}
              </Typography>
            </Box>
          )}
          <Box width={isMobile ? '100%' : 'unset'}>
            <GradientButton
              style={{ paddingLeft: 75, paddingRight: 75 }}
              disabled={!selectedRooms.length}
              onClick={openCreateInquiryForm}
            >
              {t('events.createInquiry', 'Create Inquiry')}
            </GradientButton>
          </Box>
        </Box>
      </BottomFixedActionBlock>
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl)}
      </SimpleModal>
    </Box>
  )
}
