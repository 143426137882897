const TopBottomArrow = () => {
  return (
    <svg
      width="16"
      height="34"
      viewBox="0 0 16 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.42 10.7652L8.2888 4.81523C8.14192 4.64492 7.85911 4.64492 7.71067 4.81523L2.57942 10.7652C2.3888 10.9871 2.56067 11.3121 2.86849 11.3121H13.131C13.4388 11.3121 13.6107 10.9871 13.42 10.7652Z"
        fill="#9396A3"
      />
      <path
        d="M2.57995 23.2348L7.7112 29.1848C7.85808 29.3551 8.14089 29.3551 8.28933 29.1848L13.4206 23.2348C13.6112 23.0129 13.4393 22.6879 13.1315 22.6879H2.86901C2.5612 22.6879 2.38933 23.0129 2.57995 23.2348Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default TopBottomArrow
