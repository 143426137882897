export interface venueListQueryParams {
  guests: string | null
  startDate: string | null
  endDate: string | null
  categories: string | null
  labels: string | null
  eventType: string | null
  neighborhood: string | null
  city: string | null
  map: boolean
  mapExpanded: boolean
  location: string | null //latLng
  zoom: number | string | null
  perPage: number
  name: string | null
  page: number | string
  orderBy: string
  price: string | null
  orderDirection: string | null
  includeParams: boolean
  corners: object
  ceiling_height_min: string | null
  ceiling_height_max: string | null
  guests_max: string | null
  guests_min: string | null
  price_max: string | null
  price_min: string | null
  sq_footage_min: string | null
  sq_footage_max: string | null
}

export const venueListQueryParamsMapper = (
  item: venueListQueryParams | Partial<venueListQueryParams>
) => {
  const result = <Record<string, string | any>>{}

  if (item.startDate) result.startDate = item.startDate
  if (item.endDate) result.endDate = item.endDate
  if (item.categories) result.categories = item.categories.toString()
  if (item.eventType) result.eventType = item.eventType
  if (item.neighborhood) result.neighborhood = item.neighborhood
  if (item.city) result.city = item.city
  if (item.orderBy) result.orderBy = item.orderBy
  if (item.orderDirection) result.orderDirection = item.orderDirection
  if (item.map) result.map = item.map.toString()
  if (item.mapExpanded) result.mapExpanded = item.mapExpanded.toString()
  if (item.location) result.location = item.location
  if (item.zoom) result.zoom = item.zoom.toString()
  if (item.name) result.name = item.name
  if (item.page) result.page = item.page.toString()
  if (item.perPage) result.perPage = item.perPage.toString()
  if (item.corners) result.corners = JSON.stringify(item.corners)
  if (item.labels) result.labels = item.labels.toString()
  if (item.ceiling_height_min)
    result.ceiling_height_min = item.ceiling_height_min
  if (item.ceiling_height_max)
    result.ceiling_height_max = item.ceiling_height_max
  if (item.sq_footage_min) result.sq_footage_min = item.sq_footage_min
  if (item.sq_footage_max) result.sq_footage_max = item.sq_footage_max
  if (item.guests_max) result.guests_max = item.guests_max
  if (item.guests_min) result.guests_min = item.guests_min
  if (item.price_max) result.price_max = item.price_max
  if (item.price_min) result.price_min = item.price_min

  if (item.price) {
    switch (item.price) {
      case '<$5000':
        result.price_max = '4999'
        result.price_min = '0'
        break
      case '$5001-$15000':
        result.price_min = '5001'
        result.price_max = '15000'
        break
      case '$15000-$30000':
        result.price_min = '15000'
        result.price_max = '30000'
        break
      case '>$30000':
        result.price_min = '30000'
    }
  }

  if (item.guests) {
    switch (item.guests) {
      case 's80':
        result.guests_max = '79'
        result.guests_min = '0'
        break
      case 's150':
        result.guests_min = '80'
        result.guests_max = '150'
        break
      case 's250':
        result.guests_min = '151'
        result.guests_max = '250'
        break
      case 's400':
        result.guests_min = '251'
        result.guests_max = '400'
        break
      case 's401':
        result.guests_min = '401'
        break
    }
  }
  return result
}
