import { Box } from '@material-ui/core'

import { Image } from '../../../../components/ui/Image'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { useVenueMap } from '../../../../utils/api/venues'

export const StaticMap: React.FC<{ venueId: string }> = ({ venueId }) => {
  const { isMobile } = useWindowSize()

  const { data: map } = useVenueMap(venueId)

  return (
    <Box width={isMobile ? '100%' : 'auto'}>
      <Image
        image={map}
        imageSize="medium"
        imageMobileSize="original"
        staticImageType="map"
        style={{
          marginTop: 44,
          borderRadius: isMobile ? 0 : 270,
          height: isMobile ? '127px' : 'auto',
          width: isMobile ? '100%' : 270,
        }}
        alt="venue-map"
      />
    </Box>
  )
}
