import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { WizardProvider } from '../../components/Wizard'
import { UserRoles } from '../../constants/profile'
import { OnboardingStep, RegistrationSteps } from '../../types/Auth'
import {
  createNewVendorForClients,
  createNewVendorForGuests,
  createNewVenueForClients,
  createNewVenueForGuests,
} from '../../utils/api/profile'
import { getRequestError } from '../../utils/helpers/validations'
import i18n from '../../utils/i18n'
import { dashboardBusinessDetailsPath, messagesPath } from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { useMessageModalData } from '../../utils/providers/MessageModalProvider'

import { BusinessDetails } from './Steps/BusinessDetails'
import { CreateProfile } from './Steps/CreateProfile'
import { SpaceLocation } from './Steps/SpaceLocation'
import { VendorCongrats } from './Steps/VendorCongrats'
import { VendorInformation } from './Steps/VendorInformation'

const typeRole: Record<string, UserRoles> = {
  vendor: UserRoles.Vendor,
  venue: UserRoles.Landlord,
}

const getStepsForRegistration = (
  isAuthUser: boolean
): Record<string, OnboardingStep[]> => {
  const venueSteps = [
    {
      name: RegistrationSteps.SpaceLocation,
      label: i18n.t('onboarding.venue.step0', 'Where is your space located?'),
      index: 0,
    },
    {
      name: RegistrationSteps.BusinessDetails,
      label: i18n.t('onboarding.venue.step1', 'Listing details'),
      index: 1,
    },
  ]
  if (!isAuthUser)
    venueSteps.push({
      name: RegistrationSteps.CreateProfile,
      label: i18n.t('onboarding.venue.step2', 'Main point of contact'),
      index: 2,
    })

  return {
    vendor: [
      {
        name: RegistrationSteps.VendorInformation,
        label: i18n.t('onboarding.vendor.step0', 'Tell us about your services'),
        index: 0,
      },
      // {
      //   name: RegistrationSteps.VendorCategory,
      //   label: 'Category Info!',
      //   index: 1,
      // },
      {
        name: RegistrationSteps.VendorCongrats,
        label: i18n.t('onboarding.vendor.step1', 'Congrats!'),
        index: 1,
      },
    ],
    venue: venueSteps,
  }
}

export const StepsConstructor: React.FC<{ type: string }> = ({ type }) => {
  const { authUser, getProfile } = useAuthData()
  const steps = getStepsForRegistration(!!authUser)[type]
  const history = useHistory()
  const [data, setData] = useState({})
  const role = typeRole[type]
  const isVendor = UserRoles.isVendor(role)

  const { showMessage } = useMessageModalData()

  const handleNextStep = (values: any) => {
    // if (values.office) {
    //   values.office = values.office.description
    // }

    setData({ ...data, ...values })
  }

  const onSubmit = async (values: any) => {
    const businessData = { ...data, ...values }

    try {
      const response: any = isVendor
        ? await (!!authUser
            ? createNewVendorForClients(businessData)
            : createNewVendorForGuests(businessData))
        : await (!!authUser
            ? createNewVenueForClients(businessData)
            : createNewVenueForGuests(businessData))
      const id = isVendor ? response.data.vendor.id : response.data.venue.id

      if (authUser) {
        // updateUser({ role })
        await getProfile(id.toString())
        history.push(
          generatePath(dashboardBusinessDetailsPath, {
            listingId: id.toString(),
          })
        )
      } else {
        history.push(messagesPath + '/success-business-magic-link')
      }
    } catch (e) {
      showMessage({
        type: 'warning',
        title: getRequestError(e),
      })
    }
  }

  return (
    <WizardProvider
      onNext={handleNextStep}
      onFinish={onSubmit}
      wizardData={{ type, data }}
    >
      {steps?.map((step) => renderStep(step, !!authUser))}
    </WizardProvider>
  )
}

const renderStep = (step: OnboardingStep, isAuthUser: boolean) => {
  switch (step.name) {
    case RegistrationSteps.VendorInformation:
      return (
        <VendorInformation
          key={step.index}
          step={step}
          isAuthUser={isAuthUser}
        />
      )
    case RegistrationSteps.SpaceLocation:
      return <SpaceLocation key={step.index} step={step} />
    case RegistrationSteps.BusinessDetails:
      return (
        <BusinessDetails key={step.index} step={step} isAuthUser={isAuthUser} />
      )
    case RegistrationSteps.CreateProfile:
      return <CreateProfile key={step.index} step={step} />
    case RegistrationSteps.VendorCongrats:
      return (
        <VendorCongrats key={step.index} step={step} isAuthUser={isAuthUser} />
      )
    // case RegistrationSteps.VendorCategory:
    //   return <VendorCategoryInfo key={step.index} step={step} />
    default:
      return (
        <VendorInformation
          key={step.index}
          isAuthUser={isAuthUser}
          step={step}
        />
      )
  }
}
