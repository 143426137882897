import { Box, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const ModalContentWrapper = styled(Box)(
  ({ theme }) => `
    background: white;
    width: 455px;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    scroll-behavior: smooth;
    overflow: scroll;
    overflow-x: hidden;

    ${MEDIA_RULES.SMALL} {
      width: 100%;
      height: 100%;
    }

    &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }

    &::-webkit-scrollbar-thumb {
        background: #ff0000;
    }
    `
)

export const CloseBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    cursor: pointer;
    bottom: 0;
    position: absolute;
    box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);

    ${MEDIA_RULES.SMALL} {
      position: fixed;
      width: auto;
      top: 5px;
      bottom: auto;
      left: 10px;
      box-shadow: none;
    }
    `
)
