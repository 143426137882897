/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  DialogProps,
  Stack,
  Theme,
  Typography,
} from '@material-ui/core'
import Check from 'components/svg/Check'
import LocationIcon from 'components/svg/LocationOutlined'
import { Dialog } from 'components/ui/Modals/Dialog'
import { ReactNode } from 'react'
import { City } from 'utils/api/locations'

import { SelectableItem } from '../Dropdown'

const toggleStyles = {
  root: (theme: Theme) =>
    css`
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: start;

      padding: 12px 16px;

      border: 1px solid;
      border-color: ${theme.palette.grey[50]};
      border-radius: 4px;
    `,
  icon: css`
    fill: currentColor;
  `,
}

export const CitySelectToggle = ({
  children,
  ...props
}: { children: ReactNode } & ButtonBaseProps) => (
  <ButtonBase css={toggleStyles.root} {...props}>
    <LocationIcon css={toggleStyles.icon} />
    {children}
  </ButtonBase>
)

type ItemProps = {
  children: ReactNode
  checked: boolean
  onClick: () => void
  className?: string
}

const itemStyles = {
  root: css`
    padding: 0 24px;
  `,
  label: css`
    display: inline-block;
    flex: 1;
  `,
  check: css`
    margin-left: auto;
    flex: 0 0 auto;
  `,
}

const Item = ({ children, checked, onClick }: ItemProps) => (
  <SelectableItem
    css={itemStyles.root}
    onClick={onClick}
    aria-current={checked}
  >
    <Typography variant="body1" css={itemStyles.label}>
      {children}
    </Typography>
    {checked && <Check width={16} fill="black" css={itemStyles.check} />}
  </SelectableItem>
)

const styles = {
  body: css`
    overflow: auto;
    flex: 1;
    padding: 24px;
  `,
  toggle: (theme: Theme) => css`
    color: ${theme.palette.grey[200]};
  `,
}

type CityDialogProps = {
  options: City[]
  selected: City
  onCityClick: (slug: string) => void
  onClose: () => void
} & DialogProps

export const CityDialog = ({
  options,
  selected,
  onCityClick,
  onClose,
  ...props
}: CityDialogProps) => {
  return (
    <Dialog
      fullScreen
      {...props}
      data-testid="venue-location-city-dialog"
      aria-labelledby="venue-location-city-dialog-title"
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Dialog.Header onClose={onClose}>
          <Typography
            variant="title"
            align="center"
            id="venue-location-city-dialog-title"
          >
            Location
          </Typography>
        </Dialog.Header>
        <Stack direction="column" spacing="16px" css={styles.body}>
          <CitySelectToggle disabled css={styles.toggle}>
            <Typography variant="body1">Select a city</Typography>
          </CitySelectToggle>
          <Box flex="1">
            {options.map((city) => (
              <Item
                key={city.slug}
                checked={city.slug === selected.slug}
                onClick={() => onCityClick(city.slug)}
              >
                {city.name}
              </Item>
            ))}
          </Box>
        </Stack>
      </Box>
    </Dialog>
  )
}
