import { curry } from 'lodash/fp'

interface When {
  <T, R = T>(pred: (x: T) => boolean, fn: (x: T) => R, x: T): T | R
  <T, R = T>(pred: (x: T) => boolean): When1<T, R>
  <T, R = T>(pred: (x: T) => boolean, fn: (x: T) => R): When2<T, R>
}

interface When1<T, R> {
  (fn: (x: T) => R, x: T): T | R
  (fn: (x: T) => R): When2<T, R>
}

interface When2<T, R> {
  (x: T): T | R
}

export const when: When = curry(
  <T, R>(pred: (x: T) => boolean, fn: (x: T) => R, x: T): T | R =>
    pred(x) ? fn(x) : x
)
