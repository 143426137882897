import { Box, Typography } from '@material-ui/core'
import { FormikValues, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DateField } from '../../../../../../../components/formik/DateField'
import { RadioButtonsSwitcher } from '../../../../../../../components/formik/RadioButtonsSwitcher'
import CalendarBlack from '../../../../../../../components/svg/CalendarBlack'
import DateTimeField from '../../../../../../../components/ui/Inputs/DateTimeField'
import { useWizard } from '../../../../../../../components/Wizard'
import { usePartner } from '../../../../../../../hooks/usePartner'
import { InquiryCreationStep } from '../../../../../../../types/Inquiry'
import { setTimeToDate } from '../../../../../../../utils/helpers/date'
import { FieldLabel } from '../ABSInquiryCreate.styles'

import { WizardStep } from './WizardStep'

export const InquiryDates: React.FC<{
  step: InquiryCreationStep
  isDuplicate?: boolean
}> = ({ step, isDuplicate }) => {
  const { wizardData } = useWizard()
  const { isAbs } = usePartner()
  const { values, setFieldValue, touched, errors } =
    useFormikContext<FormikValues>()
  const isDisabled = !values.eventDates?.[0] && !values.flexibleDates
  const { t } = useTranslation()

  return (
    <WizardStep
      isLoading={false}
      title={isDuplicate ? '' : step.name}
      index={step.index}
      buttonDisabled={isDisabled}
      stepData={wizardData}
    >
      <Box display="flex" mt={2} mb={2}>
        <CalendarBlack fill="#9396a3" />
        <FieldLabel sx={{ ml: '10px' }} variant="body2">
          {t('events.dates.dates', 'Dates')}
        </FieldLabel>
      </Box>
      <Box display="flex" flexDirection="column" mb={2}>
        <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
          {t('events.dates.areDecided', 'Are the dates decided?')}
        </Typography>
        <RadioButtonsSwitcher
          name="isDatesDecided"
          customNoLabel={t('events.dates.noFlexible', 'No, I am flexible')}
        />
      </Box>
      {values.isDatesDecided ? (
        <>
          <Box display="flex" flexDirection="column" mb={3}>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
              {t(
                'events.requireMultiple',
                'Will your booking require multiple days?'
              )}
            </Typography>
            <RadioButtonsSwitcher name="isMultipleDates" />
          </Box>
          {values.isMultipleDates && !isAbs && (
            <Box display="flex" flexDirection="column" mb={2} width="100%">
              <DateField
                name="loadInDates"
                label={t('events.dates.loadInDates', 'Load in dates')}
              />
            </Box>
          )}
          <Box display="flex" flexDirection="column" mb={2} width="100%">
            {values.isMultipleDates ? (
              <DateField
                name="eventDates"
                label={
                  isAbs
                    ? t('bookings.dates', 'Booking dates')
                    : t('events.dates.eventDates', 'Event dates')
                }
              />
            ) : (
              <DateTimeField
                label={
                  isAbs
                    ? t('bookings.date', 'Booking date')
                    : t('events.dates.date', 'Event date')
                }
                error={touched.eventDates && !!errors.eventDates}
                withTime
                placeholder="MM/DD/YY"
                value={
                  values.eventDates?.[0] ? new Date(values.eventDates[0]) : null
                }
                endTimeDate={
                  values.eventDates?.[1] ? new Date(values.eventDates[1]) : null
                }
                setValue={(value, endTime) => {
                  let endDate = value
                  if (endTime) {
                    endDate = setTimeToDate(value, endTime)
                  }
                  setFieldValue('eventDates', [value, endDate])
                }}
              />
            )}
          </Box>
          {values.isMultipleDates && !isAbs && (
            <Box display="flex" flexDirection="column" mb={10} width="100%">
              <DateField
                name="loadOutDates"
                label={t('events.dates.loadOutDates', 'Load out dates')}
              />
            </Box>
          )}
        </>
      ) : (
        <Box display="flex" flexDirection="column" mb={10} width="100%">
          <DateField
            name="flexibleDates"
            label={t('events.dates.choose', 'Choose flexible date range')}
          />
        </Box>
      )}
    </WizardStep>
  )
}
