import { BadgeProps, Box, Divider, Typography } from '@material-ui/core'
import ArrowDownIcon from 'components/svg/ArrowDown'
import ArrowUpIcon from 'components/svg/ArrowUp'
import LocationIcon from 'components/svg/Location'
import { StyledBadge } from 'components/ui/FormComponents/MultiSelectField'
import { useWindowSize } from 'hooks/useWindowSize'
import { StyledMoreFiltersButton } from 'pages/marketplace/components/VenuesFilter/VenuesFilter.styles'
import { ComponentProps, ReactNode } from 'react'
import { City } from 'utils/api/locations'

export type LocationButtonProps = {
  isOpen: boolean
  label: string
} & ComponentProps<typeof StyledMoreFiltersButton>

export const LocationButton = ({
  isOpen,
  label,
  ...props
}: LocationButtonProps) => {
  const adornment = (
    <Box ml={3} display="flex" alignItems="center">
      <LocationIcon fill={isOpen ? 'white' : 'black'} />
      <Box mx={2}>
        <Typography>{label}</Typography>
      </Box>
      <Divider
        style={{
          borderColor: isOpen ? 'white' : 'black',
          paddingTop: '8px',
          paddingBottom: '8px',
        }}
        orientation="vertical"
      />
    </Box>
  )
  return (
    <StyledMoreFiltersButton
      {...props}
      isOpen={isOpen}
      endIcon={isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      startIcon={adornment}
    >
      Location
    </StyledMoreFiltersButton>
  )
}

export const SelectionBadge = ({
  count,
  children,
  ...props
}: {
  count: number
  children: ReactNode
} & BadgeProps) => {
  const { isMobile } = useWindowSize()
  return (
    <StyledBadge
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      style={{ marginRight: isMobile ? 0 : '15px' }}
      badgeContent={count > 0 ? count : null}
      color="primary"
      {...props}
    >
      {children}
    </StyledBadge>
  )
}
