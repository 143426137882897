export interface UploaderFileState {
  status: string
  progress?: number
  cancelUpload?: () => void
}

export interface FileAsset {
  fileRef: any
  fileBlob?: File
  previewUrl?: any
  uploadFailed?: boolean
  uploadCanceled?: boolean
  aboveFileLimit?: boolean
  publicUrl?: string
}

export interface FileUpload {
  asset: FileAsset
}

export interface UploaderFilesState {
  [key: string]: UploaderFileState
}

export type FileUploadError = { [K in keyof FileUpload]?: string }

export enum ImageFormat {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  ALL = 'image/*',
}

export const ALLOWED_DOCUMENT_FORMATS = [
  '.doc',
  '.docx',
  '.xlsx',
  '.xls',
  '.pdf',
]
