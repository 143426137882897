import { Tab, TabProps } from '@material-ui/core'
import * as React from 'react'
import { ChangeEvent } from 'react'
import { useLocation } from 'react-router'

import { StyledTabs } from './FullLineTabs.styles'

export const FullLineTabs: React.FC<{
  withoutInitial?: boolean
  value?: string
  tabs: Array<{ value: string; label: string }>
  tabProps?: TabProps
  onChange?: (tab: string) => void
  onChooseTab?: () => void
}> = ({ tabs, tabProps = {}, onChange = () => {}, onChooseTab }) => {
  const location = useLocation()
  const initialValue = location.hash
    ? location.hash.replace('#', '')
    : tabs[0].value
  const [navValue, setNavValue] = React.useState(initialValue)
  const onChangeNavValue = (event: ChangeEvent<{}>, newValue: string) => {
    onChange(newValue)
    setNavValue(newValue)
    if (onChooseTab) {
      onChooseTab()
    }
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <StyledTabs
      className="tabs"
      aria-label="styled tabs"
      value={navValue !== ' ' ? navValue : false}
      onChange={onChangeNavValue}
    >
      {tabs.map((tab) => (
        <Tab
          style={tabProps.style}
          sx={{ '&:hover': { opacity: 0.8 } }}
          className="tab-item"
          key={tab.value}
          component="a"
          href={`#${tab.value}`}
          value={tab.value}
          label={tab.label}
        />
      ))}
    </StyledTabs>
  )
}
