import { Autocomplete, Chip, styled } from '@material-ui/core'

import { poppinsFontFamily } from '../../../theme/createTypography'

export interface AutocompleteOption {
  type: string
  value: string
  title: string
  businessId?: number
}

export const StyledAutocomplete = styled(Autocomplete)<
  AutocompleteOption & { isMobile: boolean }
>(
  (props) => `

  .MuiFormLabel-root.MuiInputLabel-root {
     color: #ffffff;
     &:hover {
       background-color: transparent;
     }
  }
  .Mui-focused {
   background-color: transparent;
  }
  .MuiInput-root.MuiInput-underline {
    border-bottom: 1px solid white;
  }
  .MuiAutocomplete-listbox {
    background-color: red;
  }
  .MuiAutocomplete-root {
   ${props.isMobile && 'width: 100%;'}
  }

`
)

export const StyledChip = styled(Chip)<{ selected?: boolean }>(
  (props) => `
  border-radius: 2px;
  cursor: pointer;
  margin: 0 5px;
  min-height: 36px;
  color: '#20202C';
  background-color: rgba(47, 84, 235, 0.07);
 
  .MuiChip-deleteIcon {
    color: black;
  }
  .MuiChip-label {
    font-family: ${poppinsFontFamily};
    color: '#20202C';
  }

  &:hover {
    background-color: ${props.selected ? 'rgba(32, 32, 44, 0.8)' : '#ceced4'};
  }`
)
