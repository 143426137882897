import { VenueDetails, VenueItem } from 'types/Venue'

import { factory, nameSequence, sequence } from './factory'

export const venueFactory = () =>
  factory<VenueDetails>({
    id: 23,
    city_id: 1,
    name: 'Venue',
    slug: 'venue',
    description: '',
    status: 'active',
    is_exclusive: false,
    capacity: { seated: '0', standing: '0', theater: '0' },
    cost: '0',
    pricing: 0,
    shortPricingText: '',
    shortPricingTextLabel: '',
    displayPricing: false,
    features: {},
    labels: [],
    images: [],
    rooms: [],
    floorplans: [],
    salesNotes: '',
    houseRules: [],
    photos: [],
    inhouseVendorTypes: [],
    accessabilities: {},
    params: {} as any,
    contact: {} as any,
    aboutInfo: {} as any,
    paymentStructure: {} as any,
  })

export const venueItemFactory = () =>
  factory<VenueItem>({
    id: sequence(),
    name: nameSequence('Test Venue'),
    slug: 'test-venue',
    address: {
      formatted_address: '',
      address_line_2: '',
    },
    city: {
      slug: 'new-york',
    },
    website: '',
    location_address: {
      formatted_address: '135 W 18th St, New York',
      address_line_2: '',
      route: '',
      street_number: '',
      city: '',
      city_long: '',
      zip: 1,
    },
    params: {
      minimum_starting_at: 1,
      instagram: '',
      display_pricing: true,
      short_pricing_details: '',
      short_pricing_details_label: 'Day',
    },
    payment_type: '',
    floorplans: [],
    security_deposit: '',
    rating: 1,
    max_head_count: 1,
    location: '',
    form_wp_images: [],
    rooms: [],
    images: [],
    photo: [],
    form_google_images: [],
    status: '',
    client_owned: false,
    is_exclusive: true,
    primary_contact: '',
    inhouse_exclusive_vendors: [],
    partner: '',
    has_promotion: false,
    promotion_description: '',
    payment_structure: {
      pricing_details: '',
    },
  })
