import { Box, BoxProps, Divider, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { UserRoles } from '../../../constants/profile'
import { useCurrentBusinessListingId } from '../../../hooks/useCurrentBusinessListingId'
import { statusInfo } from '../../../pages/dashboard/BusinessDetails/components/Status'
import { ListingPicker } from '../../../pages/dashboard/components/ListingPicker'
import {
  dashboardBusinessDetailsPath,
  dashboardProfilePath,
} from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { Notifications } from '../../Header/Notifications'
import { Breadcrumbs, BreadcrumbsProps } from '../Breadcrumbs'
import { TooltipComponent } from '../Tooltip'
import { WarningBox } from '../WarningBox'

import {
  CheckboxLabel,
  DashboardTypography,
  StepCheckbox,
  StepCheckboxContainer,
  StyledTypography,
} from './PageTitle.styles'

interface PageTitleProps {
  title?: string
  captionText?: string
  mx?: number
  textVariant?: boolean
  fontSize?: number
}

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  captionText,
  mx,
  textVariant,
  fontSize,
}) => {
  return (
    <Box mx={mx}>
      <Box display="flex" alignItems="center">
        <StyledTypography
          variant={!textVariant ? 'h1' : 'body1'}
          fontSize={fontSize ? fontSize : 36}
        >
          {title}
        </StyledTypography>
      </Box>
      {captionText && (
        <Typography variant="caption" fontSize={16}>
          {captionText}
        </Typography>
      )}
    </Box>
  )
}

interface DashboardTitleProps {
  title?: string
  showDivider?: boolean
  breadcrumbs?: BreadcrumbsProps
  showListingPicker?: boolean
}

export const DashboardTitle: React.FC<DashboardTitleProps & BoxProps> = ({
  title,
  breadcrumbs,
  showDivider = true,
  showListingPicker = true,
  ...boxProps
}) => {
  const { authUser } = useAuthData()
  const isVendor = UserRoles.isVendor(authUser?.role)

  return (
    <Box mb={5} {...boxProps}>
      <Box
        mt={3}
        mb={4}
        display="flex"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="baseline">
          {breadcrumbs && (
            <Breadcrumbs columnStyle fontFamily="Poppins" {...breadcrumbs} />
          )}
          {title && (
            <DashboardTypography variant="h1" fontFamily="Poppins">
              {title}
            </DashboardTypography>
          )}
        </Box>
        <Box display="flex">
          {/*<SearchOutlined />*/}
          {showListingPicker &&
            (isVendor
              ? !!authUser?.vendors?.length && authUser?.vendors?.length > 1
              : !!authUser?.venues?.length && authUser?.venues?.length > 1) && (
              <ListingPicker />
            )}
          <Notifications color="black" />
        </Box>
      </Box>
      {showDivider && (
        <Divider style={{ background: 'rgba(33, 34, 36, 0.1)' }} />
      )}
      <BusinessOnboardNotification />
    </Box>
  )
}

interface OnboardingStepData {
  checked: boolean
  label: string
  link: string
  tooltip?: string
}

export const BusinessOnboardNotification = () => {
  const { isLoading: authLoading, authUser } = useAuthData()
  const history = useHistory()
  const { t } = useTranslation()

  const vendor = authUser?.vendors?.find(
    (vendor) => vendor.id === authUser?.listing_id
  )
  const venue = authUser?.venues?.find(
    (venue) => venue.id === authUser?.listing_id
  )
  const business = vendor || venue

  const { businessId } = useCurrentBusinessListingId()

  const isExclusiveVenue = authUser?.venues?.[0]?.is_exclusive

  const getOnboardingSteps = () => {
    const steps: OnboardingStepData[] = []
    if (!isExclusiveVenue) {
      steps.push({
        checked: !!authUser?.payment_info,
        label: t('business.guide.stepConnect', 'Connect square account'),
        link: dashboardProfilePath,
      })
    }

    steps.push({
      checked: !!business?.images?.[0],
      label: t('business.guide.stepPhoto', 'Add business photo'),
      link: generatePath(dashboardBusinessDetailsPath, {
        listingId: businessId,
      }),
    })

    if (!(business?.status === statusInfo.disabled.value))
      steps.push({
        checked: business?.status === statusInfo.active.value,
        label: t('business.guide.activate', 'Activate account'),
        link: generatePath(dashboardBusinessDetailsPath, {
          listingId: businessId,
        }),
      })

    return steps
  }

  const isFinished = getOnboardingSteps().every((step) => step.checked)
  const showNotification =
    !UserRoles.isClient(authUser?.role) && !authLoading && !isFinished

  const steps = getOnboardingSteps().map((step, index) => (
    <TooltipComponent key={index} title={step.tooltip || ''}>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" color="#F3BD00">
          {index + 1}.
        </Typography>
        <StepCheckboxContainer>
          <StepCheckbox disabled checked={step.checked} color="primary" />
          <CheckboxLabel
            variant="body2"
            color="#2F54EB"
            onClick={() => history.push(step.link)}
          >
            {step.label}
          </CheckboxLabel>
        </StepCheckboxContainer>
      </Box>
    </TooltipComponent>
  ))

  return showNotification ? (
    <WarningBox extra={steps}>
      <Typography variant="body2">
        {t('business.guide.title', 'Add more info to start your business')}
      </Typography>
      <Typography variant="body2">
        {t('business.guide.subtitle', 'Please, please follow these steps:')}
      </Typography>
    </WarningBox>
  ) : null
}
