import { styled, ToggleButtonGroup } from '@material-ui/core'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => `
  margin-top: 25px;
  border: none;
  
 .MuiToggleButton-root {
    border-radius: 0;
    padding: 0;
    border: none;
    
    &:hover {
      background: none;
    }
        
    .type-link {
      padding: 10px;
      min-width: 120px;
      text-decoration: none;
      text-transform: none;
      font-weight: 500 !important;
      color: ${theme.palette.common.black};
      border: 1px solid ${theme.palette.common.black};
      border-radius: 2px;
      
      &.active {
        background: ${theme.palette.common.black};
        font-weight: 600 !important;
        color: ${theme.palette.common.white};
      }
    }
    
     &:first-of-type {
       .type-link {
         border-right: none;
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
       }
    }
    
     &:not(:first-of-type) {
        border-left: none;
        margin-left: 0;
      
       .type-link {
         border-left: none;
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
       }
    }
 }
`
)
