import { ThemeProvider } from '@emotion/react'
import {
  createTheme,
  Theme as MuiTheme,
  ThemeOptions as MuiThemeOptions,
} from '@material-ui/core/styles'
import { OverridesStyleRules } from '@material-ui/core/styles/overrides'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'

import { createPalette } from './createPalette'
import { createTypography } from './createTypography'

declare module '@emotion/react' {
  export interface Theme extends MuiTheme {}
}

export interface ThemeOptions extends Omit<MuiThemeOptions, 'overrides'> {
  overrides?: OverridesStyleRules | ((theme: MuiTheme) => OverridesStyleRules)
}

export const createBlaceTheme = ({
  overrides,
  ...options
}: ThemeOptions = {}) => {
  const palette = createPalette(options.palette)
  const typography = createTypography(
    typeof options.typography === 'function'
      ? options.typography(palette)
      : options.typography
  )

  return createTheme({
    ...options,
    palette,
    typography,
    spacing: 5,
  })
}

export const BlaceThemeProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const theme = createBlaceTheme()
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  )
}
