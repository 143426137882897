import { Box, BoxProps, styled } from '@material-ui/core'

type StatusButtonProps = BoxProps & {
  bgColor?: string
  borderColor?: string
  textColor?: string
}

export const StatusBox = styled(Box)<StatusButtonProps>(
  (props) => `
  width: max-content;
  position: relative;
  background: ${props.bgColor};
  border: 1px solid ${props.borderColor};
  color: ${props.textColor};
  padding: 1px 8px;
  border-radius: 2px;
  display: inline-block;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
`
)
