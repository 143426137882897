const Food = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12H20V14H0V12ZM11.84 2.79C11.9693 2.48547 12.0213 2.15369 11.9913 1.82421C11.9613 1.49473 11.8503 1.17777 11.6682 0.901586C11.486 0.625397 11.2384 0.398547 10.9473 0.241242C10.6563 0.0839379 10.3308 0.00106316 10 0C9.66916 0.00106316 9.34372 0.0839379 9.05266 0.241242C8.76161 0.398547 8.51398 0.625397 8.33183 0.901586C8.14969 1.17777 8.03868 1.49473 8.0087 1.82421C7.97872 2.15369 8.0307 2.48547 8.16 2.79C4.25 3.6 1.27 6.93 1 11H19C18.73 6.93 15.75 3.6 11.84 2.79Z"
        fill="black"
      />
    </svg>
  )
}

export default Food
