import { T, always } from 'lodash/fp'
import React, { HTMLProps, useRef, useState } from 'react'

import { AssetLoadingStatus, AssetType } from '../../constants'
import { AssetRefDownload, UploadResponse } from '../../types/FileUploadHandler'

import { assert } from './assert'

const DEFAULT_FILE_METADATA = { width: 0, height: 0, duration: 0 }

export const generateFileMetadata = (
  localFiles: File[],
  assetType: AssetType
): AssetRefDownload[] => {
  const createUpdatedDate = new Date().toISOString()
  return localFiles.map((file: File): AssetRefDownload => {
    const { width, height, duration } = DEFAULT_FILE_METADATA
    const previewUrl = URL.createObjectURL(file)
    return {
      $assetRef: previewUrl,
      size: file.size,
      signedUrl: previewUrl,
      metadata: {
        fileName: file.name,
        assetType,
        width,
        height,
        duration,
        fileBlob: file,
        previewUrl,
      },
      createdDate: createUpdatedDate,
      updatedDate: createUpdatedDate,
    }
  })
}

export const getFileMetadataFromAsset = async (asset: any) => {
  const blob = await fetch(asset.s3_path).then((r) => r.blob())
  return new File([blob], asset.filename, { type: 'application/pdf' })
  // const imageUploadFiles: AssetRefDownload[] = await generateFileMetadata([file], AssetType.DEFAULT_PACKAGE_IMG)
}

export const hasValidationErrors = (
  schema: any,
  asset: AssetRefDownload
): boolean => {
  try {
    schema.validateSync(asset)
  } catch (err) {
    return true
  }
  return false
}

export interface UploadFilesProps {
  acceptedFiles: File[]
  assetType: AssetType
  schema: any
  onChange: (imageUploadFiles: AssetRefDownload[]) => void
  uploadFile?: (asset: AssetRefDownload) => Promise<UploadResponse>
  nestedAsset?: boolean
  componentEl: React.RefObject<null>
}

export const uploadFiles = async ({
  acceptedFiles,
  assetType,
  schema,
  onChange,
  uploadFile,
  componentEl,
}: UploadFilesProps) => {
  /**
   * When a user drags or selects multiple files, but multiple is false,
   * Dropzone will reject all files, so acceptedFiles will be empty
   * In this situation, we do not process images or display an error
   */
  if (acceptedFiles.length === 0) return

  /**
   * Create preview URL for local file, measure image dimensions
   */
  const imageUploadFiles = generateFileMetadata(acceptedFiles, assetType)

  /** Display dropped files and any errors */
  onChange(imageUploadFiles)

  /**
   * Upload any files without errors
   */
  if (uploadFile) {
    await Promise.all(
      imageUploadFiles.map(async (file: AssetRefDownload) => {
        const hasSchemaErrors = hasValidationErrors(schema, file)
        if (!hasSchemaErrors) {
          const response = await uploadFile(file)
          console.log(response, 'File uploaded response')
          return response.data
        }
      })
    )

    // Check that the component is still mounted
    if (componentEl.current) {
      onChange(imageUploadFiles)
    }
  }
}

export interface UploadFileResponse {
  status: AssetLoadingStatus
  data: any
}

export const Files = {
  preview(file: File) {
    const url = URL.createObjectURL(file)
    const popup = window.open(url, '_blank')
    if (popup) {
      popup.onbeforeunload = () => {
        URL.revokeObjectURL(url)
      }
    } else {
      URL.revokeObjectURL(url)
    }
  },
}

export const useFileUpload = ({
  validate = always(null),
  onSubmit,
}: {
  validate?: (file?: File) => string | null
  onSubmit: (data: FormData) => void
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<string | null>(null)

  const reset = () => {
    setFile(null)
    formRef.current?.reset()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    const file = e.target.files?.[0]

    const error = validate(file)
    if (error) setError(error)
    else setFile(file || null)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.currentTarget
    const data = new FormData(form)
    onSubmit(data)
  }

  return {
    getInputProps: (props?: HTMLProps<HTMLInputElement>) => ({
      ref: fileInputRef,
      onChange: handleChange,
      type: 'file',
      ...props,
    }),
    getFormProps: (props?: HTMLProps<HTMLFormElement>) => ({
      ref: formRef,
      onSubmit: handleSubmit,
      encType: 'multipart/form-data',
      ...props,
    }),
    file,
    error,
    reset,
    ref: fileInputRef,
  }
}

export function formatBytes(bytes: number, decimals = 2) {
  assert(decimals >= 0, 'decimals must be a positive integer')
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const sizeIdx = Math.floor(Math.log(bytes) / Math.log(1024))
  const unit = sizes[sizeIdx]
  const value = parseFloat((bytes / Math.pow(1024, sizeIdx)).toFixed(decimals))
  return `${value}${unit}`
}
