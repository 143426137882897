import { Box, Link, styled, Typography } from '@material-ui/core'
import {
  LanguageOutlined,
  LocationOnOutlined,
  MailOutlined,
  PersonOutlined,
  PhoneEnabledOutlined,
} from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import InstaSmallIcon from '../../../../../components/svg/InstaSmallIcon'
import { EditButton } from '../../../../../components/ui/Buttons/EditButton'
import { BusinessContactData } from '../../../../../types/general'
import { formatPhoneNumber } from '../../../../../utils/helpers/common'
import { BlockTitle } from '../../BusinessDetails.styles'

const InfoWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  svg {
    color: #9396a3;
  }
`

export const BusinessContactInfo: React.FC<{
  data: BusinessContactData
  onEdit: () => void
}> = ({ onEdit, data }) => {
  const { t } = useTranslation()
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <BlockTitle>
          {t('business.contact.title', 'Business Contacts')}
        </BlockTitle>
        <Box>
          <EditButton onClick={onEdit} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <InfoWrapper>
          <Box display="flex" mr={3}>
            <PersonOutlined />
          </Box>
          <Typography variant="body2">{data.name || 'Contact Name'}</Typography>
        </InfoWrapper>
        <InfoWrapper>
          <Box display="flex" mr={3}>
            <PhoneEnabledOutlined />
          </Box>
          <Typography variant="body2">
            {formatPhoneNumber(data.phone) || '___ ___ ____'}
          </Typography>
        </InfoWrapper>
        <InfoWrapper>
          <Box display="flex" mr={3}>
            <MailOutlined />
          </Box>
          <Typography variant="body2">
            {data.email || 'Contact Email'}
          </Typography>
        </InfoWrapper>
        <InfoWrapper>
          <Box display="flex" mr={3}>
            <LocationOnOutlined />
          </Box>
          <Typography variant="body2">
            {data.address?.description || '-'}
          </Typography>
        </InfoWrapper>
        <Link
          sx={{
            textTransform: 'lowercase',
            '&:hover': { opacity: 0.8 },
            color: '#252733',
          }}
          underline="none"
          href={data.instagram}
        >
          <InfoWrapper>
            <Box display="flex" mr={3}>
              <LanguageOutlined fill="#9396A3" />
            </Box>
            <Typography variant="body2">{data.website || '-'}</Typography>
          </InfoWrapper>
        </Link>
        {/*<Link sx={{ textTransform: 'lowercase', '&:hover': { opacity: 0.8 }, color: '#252733' }} underline="none" href={data.facebook}>*/}
        {/*<InfoWrapper>*/}
        {/*  <Box display="flex" mr={3}>*/}
        {/*    <LanguageOutlined />*/}
        {/*  </Box>*/}
        {/*  <Typography variant="body2">{data.facebook || '-'}</Typography>*/}
        {/*</InfoWrapper>*/}
        {/*</Link>*/}
        <Link
          sx={{
            textTransform: 'lowercase',
            '&:hover': { opacity: 0.8 },
            color: '#252733',
          }}
          underline="none"
          href={data.instagram}
        >
          <InfoWrapper>
            <Box display="flex" mr={3} ml={0.5}>
              <InstaSmallIcon fill="#9396A3" />
            </Box>
            <Typography variant="body2">{data.instagram || '-'}</Typography>
          </InfoWrapper>
        </Link>
      </Box>
    </>
  )
}
