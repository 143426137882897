import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import GradientButton from '../../../../components/ui/Buttons/GradientButton'
import { getEventListTableColumns } from '../../../../constants/events'
import { usePartner } from '../../../../hooks/usePartner'
import { LocalEvent } from '../../../../types/Event'

import { EventListTableRow } from './EventListTableRow'

export const EventListTable: React.FC<{
  events: LocalEvent[]
  isLoading: boolean
  handleOnEventSelect: (action: string, event: LocalEvent) => void
  createEventHandler: () => void
}> = ({ events, isLoading, handleOnEventSelect, createEventHandler }) => {
  const { t } = useTranslation()
  const { isAbs } = usePartner()

  return (
    <>
      <GradientButton onClick={createEventHandler}>
        {t('events.createEvent', 'Create new')}
      </GradientButton>
      <Box my={6} width={1} display="flex">
        <TableContainer sx={{ overflowX: 'inherit' }}>
          <Table>
            <TableHead style={{ background: '#F7F7F8' }}>
              <TableRow>
                {getEventListTableColumns(!!isAbs).map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      textTransform: 'capitalize',
                      minWidth: column.minWidth,
                      color: '#9FA2B4',
                      fontSize: 12,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {events?.map((event, index) =>
                event ? (
                  <EventListTableRow
                    key={index}
                    handleOnEventSelect={handleOnEventSelect}
                    data={event}
                    isLoading={isLoading}
                  />
                ) : null
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
