import { Box } from '@material-ui/core'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { usePartner } from '../../../hooks/usePartner'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { useUrlParams } from '../../../hooks/useUrlParams'
import { VendorType } from '../../../types/Vendor'
import { vendorsCategoryPath } from '../../../utils/paths'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { LikesDataProvider } from '../../../utils/providers/LikesProvider'

import { VendorTypes } from './components/VendorTypes'

export const Vendors: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { urlParams } = useUrlParams()
  const { isAbs } = usePartner()

  const handleVendorListOpen = (category: VendorType) => {
    history.push(generatePath(vendorsCategoryPath, { category: category.id }))
  }

  const showPickedVendors = urlParams.showPicked === '1'
  const showSnackbar = useSnackbar()
  const { getUserActiveEvent } = useStorageEventsData()
  const activeEvent = getUserActiveEvent()
  const { vendors: eventVendors } = activeEvent

  useEffect(() => {
    if (!isAbs || eventVendors?.length) return
    showSnackbar({
      severity: 'info',
      message: t(
        'partners.abs.bookings.vendors.noVendors',
        'Please add vendors to continue planning your booking'
      ),
    })
  }, [isAbs, eventVendors?.length])

  return (
    <LikesDataProvider>
      <Box className="container">
        <VendorTypes
          showPickedVendors={showPickedVendors}
          onAddHandle={handleVendorListOpen}
          title={t('vendor.title', 'Vendors')}
          containerProps={{ mt: '32px' }}
        />
      </Box>
    </LikesDataProvider>
  )
}
