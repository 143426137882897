import { Box, Radio, RadioGroup, Typography } from '@material-ui/core'
import { ArrowForward, Close } from '@material-ui/icons'
import * as React from 'react'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { SimpleButton } from '../../../../../components/ui/Buttons/SimpleButton'
import { Image } from '../../../../../components/ui/Image'
import { EventDraftStatus } from '../../../../../constants'
import { simpleModalControl } from '../../../../../hooks/useSimpleModal'
import { useUrlParams } from '../../../../../hooks/useUrlParams'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { SelectVenueStep } from '../../../../../types/Venue'
import { userEventRecordMapper } from '../../../../../utils/mappers/userEvent'
import { getMarketplacePath } from '../../../../../utils/paths'
import { useStorageEventsData } from '../../../../../utils/providers/EventsProvider'
import { LsGetSearch } from '../../../../../utils/storage'

import { AddOwnVenue } from './components/AddOwnVenue'
import { VenueTypeContent } from './SelectVenueType.styles'

const SelectVenueTypeModal: React.FC<{ modalControl: simpleModalControl }> = ({
  modalControl,
}) => {
  const history = useHistory()
  const { urlParams } = useUrlParams()
  const { createNewEvent, getUserActiveEvent } = useStorageEventsData()
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  const { toggleModal } = modalControl

  const activeEvent = getUserActiveEvent()

  const [option, setOption] = React.useState('')
  const [isCreateVenue, setIsCreateVenue] = React.useState<boolean>(
    !!urlParams.partnerLocation
  )

  const navigateToMarketplace = () => {
    toggleModal()
    const heroSearchData = LsGetSearch()
    const userEventStr = userEventRecordMapper(heroSearchData)
    history.push(getMarketplacePath(userEventStr))
  }

  const handleChangeSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setOption(value)
  }

  const handleOnNext = () => {
    if (option === SelectVenueStep.FindVenue) {
      navigateToMarketplace()
    } else {
      if (activeEvent?.status !== EventDraftStatus) {
        createNewEvent()
      }
      setIsCreateVenue(true)
    }
  }

  return isCreateVenue ? (
    <AddOwnVenue modalControl={modalControl} />
  ) : (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={4}>
        <Typography variant="h3">Do you need a venue?</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <RadioGroup value={option} onChange={handleChangeSelected}>
          <VenueTypeContent
            active={option === SelectVenueStep.FindVenue}
            width={1}
            mb={5}
            onClick={() => setOption(SelectVenueStep.FindVenue)}
          >
            <Box className="event-img">
              <Image
                staticImageType="vendor"
                imageSize="original"
                vendorCategory="decor"
              />
            </Box>
            <Box
              display="flex"
              width={1}
              alignItems="center"
              style={{ marginLeft: 10 }}
            >
              <Box display="flex" width={1} flexDirection="column">
                <Typography
                  fontSize={isMobile ? '14px' : '1rem'}
                  fontWeight={600}
                  color="black"
                >
                  Yes, I need a venue
                </Typography>
                <Typography variant="body2" color="#9396A3">
                  Rent a space for your event
                </Typography>
              </Box>
            </Box>
            <Box>
              <Radio value="findVenue" color="primary" />
            </Box>
          </VenueTypeContent>
          <VenueTypeContent
            active={option === SelectVenueStep.CreateVenue}
            width={1}
            onClick={() => setOption(SelectVenueStep.CreateVenue)}
          >
            <Box className="event-img">
              <Image
                staticImageType="vendor"
                imageSize="original"
                vendorCategory="decor"
              />
            </Box>
            <Box
              display="flex"
              width={1}
              alignItems="center"
              style={{ marginLeft: 10 }}
            >
              <Box display="flex" width={1} flexDirection="column">
                <Typography
                  fontSize={isMobile ? '14px' : '1rem'}
                  fontWeight={600}
                  color="black"
                >
                  No, I have my own venue
                </Typography>
                <Typography variant="body2" color="#9396A3">
                  Your place for event
                </Typography>
              </Box>
            </Box>
            <Box>
              <Radio value="createVenue" color="primary" />
            </Box>
          </VenueTypeContent>
        </RadioGroup>
        <Box
          display="flex"
          style={{
            background: 'white',
            padding: '24px 0 0 0',
            position: 'sticky',
            bottom: 0,
            borderTop: '1px solid rgb(210 210 210 / 40%)',
          }}
        >
          <Box
            onClick={toggleModal}
            display="flex"
            flex={1}
            alignItems="center"
            style={{ cursor: 'pointer' }}
          >
            <Close style={{ color: '#9396A3' }} />
            <Typography color="#9396A3" variant="body1" align="center">
              {t('common.buttons.cancel', 'Cancel')}
            </Typography>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end">
            <SimpleButton
              disabled={!option}
              style={{ flex: 'initial' }}
              variant="contained"
              type="submit"
              onClick={handleOnNext}
            >
              {t('common.buttons.next', 'Next')} <ArrowForward />
            </SimpleButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SelectVenueTypeModal
