const Refresh = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.307 4.905 19.985 5.94a9.82 9.82 0 0 0-7.753-3.783 9.837 9.837 0 0 0-9.841 9.832c-.007 5.442 4.401 9.856 9.841 9.856 4.25 0 7.87-2.696 9.248-6.471a.188.188 0 0 0-.114-.242l-1.33-.457a.187.187 0 0 0-.236.113 8.018 8.018 0 0 1-1.866 2.913 8.01 8.01 0 0 1-5.698 2.363A8.004 8.004 0 0 1 6.539 17.7a8.02 8.02 0 0 1-1.726-2.564 8.02 8.02 0 0 1-.633-3.138c0-1.09.213-2.145.633-3.139a8.02 8.02 0 0 1 1.727-2.564 8.01 8.01 0 0 1 5.698-2.362c1.09 0 2.144.213 3.136.633a8.004 8.004 0 0 1 2.561 1.73c.232.231.45.477.652.735l-1.411 1.102a.187.187 0 0 0 .07.33l4.116 1.008a.188.188 0 0 0 .232-.18l.019-4.24a.189.189 0 0 0-.305-.146Z"
        fill="#20202C"
      />
    </svg>
  )
}

export default Refresh
