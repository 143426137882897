import { useMemo } from 'react'

import { InvoiceModalData } from '../components/PaymentModal'
import { venueDepositLineItemId } from '../constants'
import { GenerateInvoiceFormValues } from '../pages/dashboard/DocumentForms/GenerateDefaultInvoices/GenerateInvoicesForm'
import {
  GroupLineItemBundle,
  InquiryInvoiceResponse,
  Invoice,
  LineItemBundle,
} from '../types/dtos/inquiry'
import { getInquiryBusinessContact } from '../utils/helpers/inquiries'
import { formatToCurrency } from '../utils/helpers/price'
import { useInquiryDetailsData } from '../utils/providers/InqueryDetailsProvider'

export const useInvoiceModalData = (
  invoiceModalData?: InvoiceModalData | null
) => {
  const { inquiryDetails } = useInquiryDetailsData()

  const contact = getInquiryBusinessContact(inquiryDetails)

  const contacts = {
    businessType: contact.type,
    businessName: contact.name,
    businessAddress1: contact.address.address1,
    businessAddress2: contact.address.address2,
    businessPhone: contact.phone,
    businessMail: contact.email,
  }

  const groupsAmounts = useMemo(() => {
    if (!invoiceModalData?.groupedLineItems?.length) return []

    const amounts: {
      groupName: GroupLineItemBundle['name']
      amount: number
    }[] = []

    invoiceModalData.groupedLineItems.forEach((group: GroupLineItemBundle) => {
      amounts.push({
        groupName: group.name,
        amount: group.line_items.reduce((sum: number, item: LineItemBundle) => {
          const price = item.price
            ? Math.round(Math.round(item.price * 100) * item.quantity)
            : 0
          return price / 100 + sum
        }, 0),
      })
    })

    return amounts
  }, [invoiceModalData?.groupedLineItems?.length])

  const amount = useMemo(() => {
    if (!invoiceModalData) return 0

    return groupsAmounts
      .map((item) => item.amount)
      .reduce((sum: number, groupAmount) => {
        return sum + groupAmount
      }, 0)
  }, [invoiceModalData])

  const lineItemsGroupsPaymentsAmounts = useMemo(() => {
    if (invoiceModalData?.total || !invoiceModalData) return []

    const payments: {
      groupName: GroupLineItemBundle['name']
      amount: number
    }[] = []

    invoiceModalData.groupedLineItems.forEach((group: GroupLineItemBundle) => {
      payments.push({
        groupName: group.name,
        amount: group.line_items.reduce((sum: number, item: LineItemBundle) => {
          const price = item.price
            ? Math.round(
                Math.round(
                  item.price *
                    ((invoiceModalData.paymentPercent || 100) / 100) *
                    100
                ) * item.quantity
              )
            : 0
          return price / 100 + sum
        }, 0),
      })
    })

    return payments
  }, [invoiceModalData])

  const paymentPercentAmount = useMemo(() => {
    if (!invoiceModalData) return 0

    return (
      invoiceModalData?.total ||
      lineItemsGroupsPaymentsAmounts
        .map((item) => item.amount)
        .reduce((sum: number, groupAmount) => {
          return sum + groupAmount
        }, 0)
    )
  }, [invoiceModalData])

  const totalAmount = useMemo(() => {
    if (!invoiceModalData) return ''

    return invoiceModalData?.total
      ? formatToCurrency(invoiceModalData?.total)
      : invoiceModalData?.securityDeposit?.price
      ? formatToCurrency(
          paymentPercentAmount + invoiceModalData?.securityDeposit.price
        )
      : formatToCurrency(paymentPercentAmount)
  }, [invoiceModalData])

  const formatInvoiceForDetailsModal = (
    invoiceBundle: InquiryInvoiceResponse | null,
    invoice: Invoice | null
  ) => {
    if (!invoice || !invoiceBundle) return null

    const invoiceLineItemsAll: LineItemBundle[] = []
    invoice.grouped_line_items_bundle.forEach((group: GroupLineItemBundle) => {
      invoiceLineItemsAll.push(...group.line_items)
    })

    const securityDepositItem = invoiceLineItemsAll.find(
      (item: LineItemBundle) => item.line_item_id === venueDepositLineItemId
    )

    return {
      id: invoice.id,
      dueDate: new Date(invoice.expiration_date),
      creationDate: new Date(invoiceBundle.created_at),
      groupedLineItems: invoice.grouped_line_items_bundle.map(
        (group: GroupLineItemBundle) => ({
          ...group,
          line_items: group.line_items.filter(
            (item: LineItemBundle) =>
              item.line_item_id !== venueDepositLineItemId
          ),
        })
      ),
      lineItemsAll: invoiceLineItemsAll,
      securityDeposit: {
        price: securityDepositItem
          ? securityDepositItem?.price * securityDepositItem?.quantity
          : 0,
        clarification: securityDepositItem
          ? securityDepositItem?.clarification
          : '',
      },
      total: invoice.price,
      type: invoice.type,
      message: invoiceBundle.message || '',
      extra: invoiceBundle.extra || '',
      ...contacts,
      paymentPercent:
        invoice.price_modifier < 1
          ? +(invoice.price_modifier * 100).toFixed(2)
          : undefined,
    } as InvoiceModalData
  }

  const formatInvoiceValuesForDetailsModal = (
    values: GenerateInvoiceFormValues,
    groupedLineItems: GroupLineItemBundle[],
    lineItemsAll: LineItemBundle[],
    previewStep: 0 | 1
  ) => {
    const generalData = {
      creationDate: new Date(),
      groupedLineItems: groupedLineItems.map((group) => ({
        ...group,
        line_items: group.line_items.filter(
          (item) => +item.line_item_id !== venueDepositLineItemId
        ),
      })) as GroupLineItemBundle[],
      lineItemsAll,
      message: values.message,
      extra: values.extra,
      ...contacts,
    }

    const securityDepositItem = lineItemsAll.find(
      (item: LineItemBundle) => +item.line_item_id === venueDepositLineItemId
    )

    const securityDeposit = {
      price: securityDepositItem
        ? securityDepositItem?.price * securityDepositItem?.quantity
        : 0,
      clarification: securityDepositItem
        ? securityDepositItem?.clarification
        : '',
    }

    const initialDisabled =
      +values.initialPaymentPercent === 0 ||
      +values.initialPaymentPercent === 100

    if (previewStep === 0) {
      return {
        ...generalData,
        dueDate: values.initialDueDate,
        securityDeposit,
        type: 'initial',
        paymentPercent: +values.initialPaymentPercent,
      }
    }

    return {
      ...generalData,
      dueDate: values.finalDueDate,
      securityDeposit: initialDisabled ? securityDeposit : undefined,
      type: 'final',
      paymentPercent: 100 - +values.initialPaymentPercent,
    }
  }

  return {
    formatInvoiceForDetailsModal,
    formatInvoiceValuesForDetailsModal,
    groupsAmounts,
    amount,
    paymentPercentAmount,
    totalAmount,
  }
}
