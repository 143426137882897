import { Box, styled } from '@material-ui/core'

export const StyledRowBox = styled(Box)<{
  isNotSend: boolean
  rowPosition: string
}>(
  (props) => `
  background-color: ${
    !!props.rowPosition && props.rowPosition === 'left'
      ? props.theme.palette.common.white
      : '#f7f7f8'
  }; 
  padding: ${props.isNotSend ? '8px 24px' : '8px 10px'};
  margin-bottom: 4px;
  box-shadow: ${
    props.isNotSend ? '0px 4px 18px rgba(210, 210, 210, 0.4)' : 'none'
  };
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`
)

export const StyledImg = styled('img')`
  width: 48px;
  height: 48px;
`
