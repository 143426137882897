import { flatten, keys, values } from 'lodash'

import { vendorsListQueryParams } from '../mappers/vendorsQueryParamsMapper'
import { venueListQueryParams } from '../mappers/venueListQueryParamsMapper'

export type DeepPartial<T> = T extends Function
  ? T
  : T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T

export const imagesToGalleryImages = (images: string[]) => {
  return images
    .filter((item) => item)
    .map((item) => ({
      original: item,
      thumbnail: item,
    }))
}

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return ''
}

export const adjustColor = (color: string, amount: number) => {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  )
}

export const getInitialValuesWithType = (data: any, isAbs: boolean) => {
  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key] = data[key]
        .map((item: any) => {
          const option = {} as any
          option.type = key
          option.title = item.name
          option.value = item.inquiry_id
            ? item.inquiry_id
            : item.slug
            ? item.slug
            : item.id
          option.businessId = item.business_id || undefined
          return option
        })
        .filter((v: any) => (isAbs ? v.type !== 'venues' : v))
    } else {
      for (const k in data[key]) {
        if (k && data[key][k]) {
          data[k] = data[key][k]
            .map((item: any) => {
              const option = {} as any
              option.type = k
              option.title = item.name
              option.value = item.inquiry_id
                ? item.inquiry_id
                : item.slug
                ? item.slug
                : item.id
              return option
            })
            .filter((v: any) => (isAbs ? v.type !== 'venues' : v))
        }
      }
    }
  }
  return flatten(values(data)) || []
}

export const mapQueryParams = (field: string) => {
  switch (field) {
    case 'Name':
      return 'name'
    case 'Price':
      return 'price'
    case 'Submission Date':
      return 'Submission Date'
    case 'Last Modified Date':
      return 'Last Modified Date'
    case 'Date':
      return 'date'
    case 'Status':
      return 'status'
    case 'None':
      return ''
    default:
      return ''
  }
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

export const generateHash = (s: string) => {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
}
export const LABELS_TYPES = ['amenities', 'special features', 'best used for']

export const getFiltersCount = (
  queryParams: venueListQueryParams | vendorsListQueryParams
) => {
  let labelsCount = 0
  let priceCount = 0
  let squareCount = 0
  const commonFilterValuesCount = keys(queryParams).filter(
    // @ts-ignore
    (key) => ['ceiling_height_min'].includes(key) && queryParams[key]
  ).length
  if (queryParams.labels) {
    labelsCount = queryParams.labels.split(',').length
  }
  // @ts-ignore
  if (queryParams.order_price_min) {
    // @ts-ignore
    priceCount = queryParams.order_price_min.split(',').length
  }
  if (
    // @ts-ignore
    queryParams.sq_footage_min &&
    // @ts-ignore
    (queryParams.sq_footage_min != 0 || queryParams.sq_footage_max)
  ) {
    squareCount = 1
  }
  return (
    Number(commonFilterValuesCount) +
    Number(labelsCount) +
    Number(priceCount) +
    squareCount
  )
}
