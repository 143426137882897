import { Box, styled } from '@material-ui/core'

export const TotalsWrapper = styled(Box)`
  overflow-x: auto;
  display: flex;
  align-items: center;
  margin: 0 -15px 30px -15px;
`

export const TotalBlock = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 16px;
  margin: 0 15px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
`
