import { Box, styled, TextField } from '@material-ui/core'

export const FooterWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.common.footerBg};
    display: flex;
    flex: 1;
    color: ${theme.palette.grey['100']};      
    
    .title {
      color: ${theme.palette.common.white};
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 10px;
      text-transform: uppercase;
    }
            
    .left-block {
      display: flex;
      flex-direction: column;
      flex: 1;
      font-size: 14px;
      
      .links-table-grid {
        display: flex;
        flex: 1;
        flex-direction: column;
        
        .row {
            display: flex;
            flex-direction: row;
            
            &:first-of-type {
                margin-bottom: 50px;
            }
        }
        
        .cell {
            flex: 1;
            color: ${theme.palette.grey['100']};
            display: flex;
            flex-direction: column;
        }
      }
      
    }
    
    .right-block {
      display: flex;
      flex: 0.7;
      flex-direction: column;
      font-size: 14px;
    }
    
    .socials {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }
    
    .privacy {
      font-size: 14px;
      .MuiTypography-root {
        font-size: 14px;
        display: inline-block;
      }
    }
      
    .logo-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      target: _blank;
    }
    `
)

export const SubscribeInput = styled(TextField)(
  ({ theme }) => `
    .Mui-focused .MuiOutlinedInput-notchedOutline {
       border: none;
    }
    
    .MuiOutlinedInput-root {
       color: white;
       font-weight: 400;
       font-size: 14px;
       line-height: 21px;
       height: 50px;
            
       border-radius: 8px;
       background: #2C2C37;
       box-shadow: 0px 20px 70px rgba(86, 89, 146, 0.1);
     }
      
     input {
       box-sizing: border-box;
     }
    `
)

export const SocialWrapper = styled('div')(
  ({ theme }) => `
    margin-right: 15px;
    cursor: pointer;
`
)
