import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { paramsToObject } from '../utils/helpers/urlParamsToObject'

export function useUrlParams() {
  const location = useLocation()

  const urlParams = useMemo(() => {
    const query = new URLSearchParams(location.search)
    const entries = query.entries()
    return paramsToObject(entries)
  }, [location.search])

  return { urlParams }
}
