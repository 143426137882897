const PeopleOutlined = ({ width = 24, height = 24, scale = 1 }) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.317 16.404a7.068 7.068 0 0 0-2.025-1.415 4.677 4.677 0 0 0 1.739-3.645c0-2.597-2.166-4.727-4.763-4.687a4.69 4.69 0 0 0-2.878 8.332 7.054 7.054 0 0 0-2.025 1.415 6.986 6.986 0 0 0-2.053 4.78.188.188 0 0 0 .188.191h1.312c.1 0 .185-.08.188-.18a5.297 5.297 0 0 1 1.563-3.598 5.313 5.313 0 0 1 3.78-1.565c1.428 0 2.77.555 3.78 1.565a5.319 5.319 0 0 1 1.564 3.598c.002.1.087.18.188.18h1.312a.184.184 0 0 0 .134-.056.189.189 0 0 0 .054-.136 6.97 6.97 0 0 0-2.058-4.779Zm-4.974-2.06a2.974 2.974 0 0 1-2.12-.879 2.974 2.974 0 0 1-.88-2.151 3.005 3.005 0 0 1 2.97-2.97 3.017 3.017 0 0 1 2.133.858c.58.57.9 1.331.9 2.142 0 .802-.312 1.554-.88 2.121a2.985 2.985 0 0 1-2.123.88Zm-5.87-2.381a5.964 5.964 0 0 1 .068-1.708.189.189 0 0 0-.106-.207 2.961 2.961 0 0 1-.865-.588 2.99 2.99 0 0 1-.907-2.236 2.985 2.985 0 0 1 .85-2.006 2.984 2.984 0 0 1 2.185-.907 2.996 2.996 0 0 1 2.494 1.378.187.187 0 0 0 .218.075 5.816 5.816 0 0 1 1.296-.29.188.188 0 0 0 .148-.273 4.696 4.696 0 0 0-4.118-2.575c-2.6-.04-4.765 2.09-4.765 4.685 0 1.472.677 2.784 1.739 3.644a7.046 7.046 0 0 0-2.028 1.416 6.97 6.97 0 0 0-2.057 4.781.188.188 0 0 0 .187.192h1.315c.1 0 .185-.08.187-.18a5.298 5.298 0 0 1 1.564-3.598 5.29 5.29 0 0 1 2.454-1.399.187.187 0 0 0 .14-.204Z"
        fill="#9396A3"
        transform={`scale(${scale})`}
      />
    </svg>
  )
}

export default PeopleOutlined
