export const EXTERNAL_LINKS = {
  ABS: {
    FAQ: 'https://blacellc.zendesk.com/hc/en-us/categories/10483071713677-ABS-Vendor-Amenity-Portal-FAQs',
  },
  MEDIA: {
    FACEBOOK: 'https://www.facebook.com/blaceco/',
    TWITTER: 'https://twitter.com/blaceco',
    INSTAGRAM: 'https://www.instagram.com/blace.co/?hl=en',
    LINKEDIN: 'https://www.linkedin.com/company/blaceco/',
  },
  ABOUT_US: 'https://www.blace.com/about-us',
  CONTACT_US: 'https://www.blace.com/contact-us',
  FAQ: 'https://blacellc.zendesk.com/hc/en-us/categories/10512298381709-BLACE-Site-FAQs',
  PRIVACY: 'https://www.blace.com/privacy-policy',
  TERMS_AND_CONDITIONS: 'https://www.blace.com/terms-conditions',
}
