const TotalSquare = () => {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.68.719H1.344a.624.624 0 0 0-.625.625v4.375c0 .172.14.312.312.312h.938c.172 0 .312-.14.312-.312V2.28H5.68c.172 0 .312-.14.312-.312V1.03A.313.313 0 0 0 5.68.72Zm11.289 11.25h-.938a.313.313 0 0 0-.312.312v3.438H12.32a.313.313 0 0 0-.312.312v.938c0 .172.14.312.312.312h4.336c.346 0 .625-.279.625-.625v-4.375a.313.313 0 0 0-.312-.312Zm-11.29 3.75H2.282V12.28a.313.313 0 0 0-.312-.312H1.03a.313.313 0 0 0-.312.312v4.375c0 .346.279.625.625.625H5.68c.172 0 .312-.14.312-.312v-.938a.313.313 0 0 0-.312-.312Zm10.977-15H12.32a.313.313 0 0 0-.312.312v.938c0 .172.14.312.312.312h3.399V5.72c0 .172.14.312.312.312h.938c.172 0 .312-.14.312-.312V1.344a.624.624 0 0 0-.625-.625Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default TotalSquare
