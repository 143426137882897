import { styled, Box } from '@material-ui/core'

export const ModalContentWrapper = styled(Box)(
  ({ theme }) => `
    background: white;
    width: 100%;
    height: 95%;
    position: absolute;
    bottom: 0;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }

    &::-webkit-scrollbar-thumb {
        background: #ff0000;
    }
    `
)
