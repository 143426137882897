import React, { useState } from 'react'

import { EventVendor } from '../types/Event'

export function useVendorBasket(): {
  pickedVendors: Array<EventVendor>
  toggleVendorPick: (vendor: EventVendor) => void
} {
  const [pickedVendors, setPickedVendors] = useState(
    localStorage.getItem('vendorsBasket')
      ? JSON.parse(localStorage.getItem('vendorsBasket') as string)
      : []
  )

  const toggleVendorPick = (vendor: EventVendor) => {
    let updatedVendors = []

    if (pickedVendors.find((item: EventVendor) => vendor.id === item.id)) {
      updatedVendors = pickedVendors.filter(
        (item: EventVendor) => item.id !== vendor.id
      )
    } else {
      updatedVendors = [...pickedVendors, vendor]
    }

    localStorage.setItem('vendorsBasket', JSON.stringify(updatedVendors))
    setPickedVendors(updatedVendors)
  }

  return { pickedVendors, toggleVendorPick }
}
