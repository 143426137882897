const CheckedCircleFilled = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.5C4.13438 1.5 1 4.63438 1 8.5C1 12.3656 4.13438 15.5 8 15.5C11.8656 15.5 15 12.3656 15 8.5C15 4.63438 11.8656 1.5 8 1.5ZM8 14.3125C4.79063 14.3125 2.1875 11.7094 2.1875 8.5C2.1875 5.29063 4.79063 2.6875 8 2.6875C11.2094 2.6875 13.8125 5.29063 13.8125 8.5C13.8125 11.7094 11.2094 14.3125 8 14.3125Z"
        fill="#2F54EB"
      />
      <path
        d="M8 2.6875C4.79063 2.6875 2.1875 5.29063 2.1875 8.5C2.1875 11.7094 4.79063 14.3125 8 14.3125C11.2094 14.3125 13.8125 11.7094 13.8125 8.5C13.8125 5.29063 11.2094 2.6875 8 2.6875ZM11.0219 6.21406L7.73125 10.7766C7.68526 10.8408 7.62463 10.8931 7.55439 10.9291C7.48414 10.9652 7.40631 10.9841 7.32734 10.9841C7.24838 10.9841 7.17054 10.9652 7.1003 10.9291C7.03006 10.8931 6.96943 10.8408 6.92344 10.7766L4.97656 8.07656C4.91719 7.99375 4.97656 7.87813 5.07812 7.87813H5.81094C5.97187 7.87813 6.12187 7.95625 6.21562 8.08594L7.32812 9.62969L9.78438 6.22344C9.87813 6.09219 10.0297 6.01562 10.1891 6.01562H10.9219C11.0234 6.01562 11.0828 6.13125 11.0219 6.21406Z"
        fill="#2F54EB"
        fillOpacity="0.2"
      />
      <path
        d="M10.9211 6.01562H10.1883C10.0289 6.01562 9.87736 6.09219 9.78361 6.22344L7.32735 9.62969L6.21485 8.08594C6.1211 7.95625 5.9711 7.87813 5.81016 7.87813H5.07735C4.97578 7.87813 4.91641 7.99375 4.97578 8.07656L6.92267 10.7766C6.96866 10.8408 7.02929 10.8931 7.09953 10.9291C7.16977 10.9652 7.2476 10.9841 7.32657 10.9841C7.40554 10.9841 7.48337 10.9652 7.55362 10.9291C7.62386 10.8931 7.68449 10.8408 7.73048 10.7766L11.0211 6.21406C11.0821 6.13125 11.0227 6.01562 10.9211 6.01562Z"
        fill="#2F54EB"
      />
    </svg>
  )
}

export default CheckedCircleFilled
