import {
  Box,
  Button,
  Checkbox,
  Paper,
  styled,
  Typography,
} from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const SectionHeader = styled(Typography)`
  font-weight: 600;
  text-align: center;

  ${MEDIA_RULES.SMALL} {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const FormContainer = styled('form')`
  display: flex;
  width: 100%;
`

export const FieldLabel = styled(Typography)`
  color: #000;
  text-align: left;
`

export const FieldWrapper = styled(Box)`
  width: 100%;

  .MuiFormControl-root input {
    padding: 11px 16px;
  }

  .MuiFormControl-root .MuiSelect-select {
    padding: 11px 16px;
  }
`
export const ScreenContent = styled(Box)`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  ${MEDIA_RULES.SMALL} {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 24px;
    top: 80px;
    bottom: 100px;
  }
`
export const ScrollContainer = styled(Box)`
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`
export const StyledExpandButton = styled(Button)(
  // @ts-ignore
  ({ isOpen }) => `
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  color:  ${isOpen ? 'white' : 'black'};
  text-transform: none;
  background-color: ${isOpen ? 'black' : '#f5f5f8'};
  min-width: 100px;
  padding: 12px 20px 12px 10px;
  border-radius: 2px;
  align-content: baseline;
  margin-right: 15px;
    &:hover {
    background-color:  ${isOpen ? 'black' : 'white'};
  }
`
)

export const StyledPaper = styled(Paper)`
  background: white;
  border-radius: 0;
  width: 400px;
  //height: 300px;
  box-shadow: rgba(210, 210, 210, 0.4);

  .MuiFormControlLabel-root {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .MuiFormGroup-root {
    margin-left: 24px;
    margin-right: 24px;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  background-color: #f7f7f8;
  height: 28px;
  border-radius: 2px;
  width: 28px;
`

export const CheckboxLabel = styled(Typography)`
  color: #9396a3;
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
`
