import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Breadcrumbs,
  BreadcrumbsProps,
} from '../../../components/ui/Breadcrumbs'
import { MultiSelectField } from '../../../components/ui/FormComponents/MultiSelectField'
import { DashboardTitle } from '../../../components/ui/PageTitle'
import { CALENDAR_VIEW_BY, getCalendarUrl } from '../../../constants/events'
import { UserRoles } from '../../../constants/profile'
import { MediaLibraryProvider } from '../../../utils/providers/MediaLibraryProvider'
import { CalendarPage } from '../../calendar'

import { CopyUrlComponent } from './components/CopyUrlComponent'
import { LandlordBusinessDetails } from './LandlordBusinessDetails'
import { VendorBusinessDetails } from './VendorBusinessDetails'

export const BusinessDetails: React.FC<{ role?: UserRoles }> = ({ role }) => {
  const [calendarGoogleId, setCalendarGoogleId] = React.useState<string>('')
  const { t } = useTranslation()
  const breadcrumbs: BreadcrumbsProps = {
    steps: [
      {
        label: t('business.details.title', 'Business details'),
        route: '',
        onClick: () => setCalendarGoogleId(''),
      },
    ],
    current: t('breadcrumbs.calendar', 'Full calendar view'),
  }
  const [viewBy, setViewBy] = React.useState(CALENDAR_VIEW_BY[0])
  const calendarUrl = getCalendarUrl(calendarGoogleId)

  return (
    <MediaLibraryProvider>
      <Box
        width={1}
        minHeight={800}
        style={{ paddingLeft: 30, paddingRight: 30, position: 'relative' }}
      >
        <DashboardTitle
          mb={3}
          title={t('business.details.title', 'Business details')}
        />
        {calendarGoogleId ? (
          <Box mt={5}>
            <Box display="flex" justifyContent="space-between">
              <Breadcrumbs columnStyle {...breadcrumbs} />
              <Box display="flex" alignItems="center">
                <CopyUrlComponent
                  id="calendar-link"
                  calendarUrl={calendarUrl}
                  onCopyCalendarLink={() => {
                    navigator.clipboard.writeText(calendarUrl)
                  }}
                />
                <MultiSelectField
                  id="viewBy"
                  sx={{ minWidth: 120 }}
                  defaultLabel={`View by: ${viewBy}`}
                  options={CALENDAR_VIEW_BY}
                  onChange={(value: any) => setViewBy(value)}
                />
              </Box>
            </Box>
            <CalendarPage
              viewBy={viewBy}
              calendarGoogleId={calendarGoogleId}
              onCloseFullCalendar={() => setCalendarGoogleId('')}
            />
          </Box>
        ) : (
          <>
            {UserRoles.isLandlord(role) ? (
              <LandlordBusinessDetails
                onOpenFullCalendar={(calendarGoogleId: string) =>
                  setCalendarGoogleId(calendarGoogleId)
                }
              />
            ) : (
              <VendorBusinessDetails
                onOpenFullCalendar={(calendarGoogleId: string) =>
                  setCalendarGoogleId(calendarGoogleId)
                }
              />
            )}
          </>
        )}
      </Box>
    </MediaLibraryProvider>
  )
}
