import {
  Box,
  BoxProps,
  ClickAwayListener,
  Divider,
  styled,
  Typography,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import React, { ChangeEvent, useMemo, useState } from 'react'

const MenuContainer = styled(Box)`
  z-index: 2;
  padding: 24px;
  position: absolute;
  right: 0;
  left: 0;
  top: 110%;
  background: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
`

const StyledMenuItem = styled(MenuItem)`
  border-radius: 1px;
  padding-left: 0px;
  width: 100%;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    background-color: #ceced4;
  }
`

export const StyledTextField = styled(TextField)`
  background-color: transparent;
  min-width: 100px;
  display: flex;
  align-content: baseline;
  font-size: 14px;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    border-radius: 2px;
    background: #f5f5f8;
    width: 100%;
  }

  .search {
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiDivider-root {
      background: black;
      width: 1px;
      height: 35px;
      margin-right: 10px;
      margin-left: 5px;
    }
  }
`

interface AutocompleteItem {
  label: string
  value: string
}

interface AutocompleteProps {
  onChange: (value: string) => void
  options: Array<AutocompleteItem>
  listTitle: string
  inputValue?: string
  icon?: React.ReactElement
  placeholder?: string
  clearOnChange?: boolean
  onKeyPress?: (value: string) => void
  style?: React.CSSProperties
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  style,
  clearOnChange,
  onChange,
  icon,
  placeholder,
  inputValue,
  options = [],
  listTitle = 'Choose item',
  onKeyPress,
}) => {
  const [touched, setTouched] = useState(false)
  const [search, setSearch] = useState(inputValue || '')
  const isOpen = options.length > 0 && touched

  const predictions = useMemo(
    () =>
      options && options.length
        ? options.filter((item) => item.label.includes(search))
        : [],
    [search, options]
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleSelect = (item: AutocompleteItem) => {
    onChange(item.value)
    if (clearOnChange) {
      setSearch('')
    } else {
      setSearch(item.label)
    }
    setTouched(false)
  }

  const handleKeyPress = (event: any) => {
    if (!onKeyPress) return
    if (event.key === 'Enter') {
      console.log('enter press here! ')
      onKeyPress(event.target.value)
    }
  }

  const StartAdornment = () => {
    return (
      <Box display="flex" alignItems="center">
        {icon}
        <Divider
          variant="middle"
          orientation="vertical"
          style={{ borderRightWidth: 'inherit' }}
        />
      </Box>
    )
  }
  return (
    <ClickAwayListener onClickAway={() => setTouched(false)}>
      <FormControl
        style={style}
        margin="dense"
        sx={{ width: '100%', position: 'relative' }}
      >
        <StyledTextField
          placeholder={placeholder}
          value={search}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          onFocus={() => setTouched(true)}
          InputProps={{
            startAdornment: <StartAdornment />,
            classes: {
              root: 'search',
            },
          }}
        />
        {isOpen && (
          <MenuContainer>
            <Typography variant="body2" color="#9396A3">
              {listTitle}
            </Typography>
            {predictions.map((item) => (
              <StyledMenuItem
                key={item.value}
                onClick={() => handleSelect(item)}
              >
                <Typography>{item.label}</Typography>
              </StyledMenuItem>
            ))}
          </MenuContainer>
        )}
      </FormControl>
    </ClickAwayListener>
  )
}
