import { assignWith, isFunction } from 'lodash/fp'

type Transformations<T, R extends { [K in keyof T]: any } = T> = {
  [K in keyof T]?: (x: T[K]) => R[K]
}

export const evolve =
  <T extends object, R extends { [K in keyof T]: any } = T>(
    transformations: Transformations<T, R>
  ) =>
  (obj: T): R =>
    assignWith(
      (objValue, srcValue) =>
        isFunction(srcValue) ? srcValue(objValue) : objValue,
      obj,
      transformations
    ) as R

export const propIs =
  <T extends object, K extends keyof T>(
    prop: K,
    matcher: (value: T[K]) => boolean
  ) =>
  (obj: T) =>
    matcher(obj[prop])
