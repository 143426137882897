const UserSmallFilled = ({ fill = '#20202C' }) => {
  return (
    <svg width="12" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.2 14S0 14 0 12.833c0-1.166 1.2-4.666 6-4.666s6 3.5 6 4.666C12 14 10.8 14 10.8 14H1.2ZM6 7c.955 0 1.87-.369 2.546-1.025A3.451 3.451 0 0 0 9.6 3.5c0-.928-.38-1.819-1.054-2.475A3.653 3.653 0 0 0 6 0c-.955 0-1.87.369-2.546 1.025A3.451 3.451 0 0 0 2.4 3.5c0 .928.38 1.819 1.054 2.475A3.653 3.653 0 0 0 6 7Z"
        fill={fill}
      />
    </svg>
  )
}

export default UserSmallFilled
