const Progress = ({ color = '#9396A3' }: { color?: string }) => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.834 1.691v1.684a6.662 6.662 0 0 1 5.8 7.433c-.384 3.033-2.767 5.442-5.8 5.8v1.667a8.313 8.313 0 0 0 7.458-9.109c-.375-3.958-3.517-7.083-7.459-7.475Zm-1.667.025A8.19 8.19 0 0 0 4.725 3.55l1.192 1.233a6.663 6.663 0 0 1 3.25-1.4V1.716ZM3.55 4.725A8.237 8.237 0 0 0 1.71 9.166h1.666a6.68 6.68 0 0 1 1.367-3.25L3.55 4.725Zm9.367 2.358L8.85 11.15 7.084 9.383l-.884.883 2.65 2.65 4.95-4.95-.883-.883Zm-11.2 3.75a8.366 8.366 0 0 0 1.841 4.442l1.184-1.192a6.668 6.668 0 0 1-1.358-3.25H1.717Zm4.2 4.475L4.725 16.45a8.328 8.328 0 0 0 4.442 1.883v-1.667a6.668 6.668 0 0 1-3.25-1.358Z"
        fill={color}
      />
    </svg>
  )
}

export default Progress
