import { FormControl, Popover, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const DateTimeFieldContainer = styled(FormControl)`
  width: 100%;
  margin-bottom: 15px;

  input {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .MuiSelect-select {
    text-align: left;
  }

  .MuiSelect-icon {
    color: black;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root:not(&.Mui-error) fieldset {
    border: 1px solid #efefef;
  }

  legend span {
    display: none;
  }

  &.error .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
  &.error fieldset {
    border: 1px solid red;
  }
`

export const DateTimeContainer = styled(Popover)`
  margin-top: 20px;

  .MuiPaper-root,
  .MuiPaper-root > div {
    background: white;
  }

  .MuiPaper-root {
    ${MEDIA_RULES.SMALL} {
      bottom: 100px;
      top: 100px !important;
    }
  }

  .MuiBox-root,
  .MuiBox-root > div {
    background: white;
  }

  .MuiPaper-root .MuiBox-root:first-of-type > div > div:first-child {
    & > div {
      ${MEDIA_RULES.SMALL} {
        width: 100%;
      }
    }
  }

  .PrivatePickersFadeTransitionGroup-root {
    max-height: 270px;
    overflow: hidden;
  }

  .PrivatePickersSlideTransition-root > div > div {
    border-right: none;
    border-radius: 0;
  }

  .MuiCalendarPicker-root,
  .PrivatePickersSlideTransition-root > div > div,
  .MuiCalendarPicker-root > div:nth-of-type(2) > div {
    ${MEDIA_RULES.SMALL} {
      width: 100%;
      margin: 0;
    }
  }

  .PrivatePickersSlideTransition-root > div > div > div,
  .MuiCalendarPicker-root > div:nth-of-type(2) span {
    ${MEDIA_RULES.SMALL} {
      flex: 1;
    }
  }

  .PrivatePickersSlideTransition-root {
    min-height: 250px;
    min-width: 285px;
    overflow: hidden;
  }

  .PrivatePickersSlideTransition-root button {
    border-radius: 0;
    border-width: 1px;
    background: transparent;
    &.Mui-selected {
      background: #4e4bec;
    }

    ${MEDIA_RULES.SMALL} {
      width: 100%;
      border: 1px solid #f7f7f8;
      margin: 0;
    }
  }

  .styled-calendar {
    ${MEDIA_RULES.SMALL} {
      padding: 0 24px;
      & > div {
        min-width: auto;
      }
    }
  }
`
