import {
  FormHelperText as MuiFormHelperText,
  FormHelperTextProps as MuiFormHelperTextProps,
  styled,
} from '@material-ui/core'

export type FormHelperTextProps = MuiFormHelperTextProps

export const FormHelperText = styled(MuiFormHelperText)({
  marginLeft: 0,
})
