import { Box, Portal, Typography } from '@material-ui/core'
import { Star } from '@material-ui/icons'
import { differenceBy } from 'lodash'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link, generatePath } from 'react-router-dom'

import JotformEmbed from '../../../../../components/ReactJotFormEmbed/ReactJotFormEmbed'
import Calendar from '../../../../../components/svg/Calendar'
import GradientButton from '../../../../../components/ui/Buttons/GradientButton'
import { Loader } from '../../../../../components/ui/Loader'
import { SimpleModal } from '../../../../../components/ui/Modals/SimpleModal'
import { WarningBox } from '../../../../../components/ui/WarningBox'
import { EventDraftStatus } from '../../../../../constants'
import {
  EVENT_STAGES_PROGRESS,
  InquiryStages,
  InquiryStatuses,
  UNLOCK_VENDOR_STATUSES,
} from '../../../../../constants/inquiry'
import { useJotFormInitializer } from '../../../../../hooks/useJotFormInitializer'
import { usePartner } from '../../../../../hooks/usePartner'
import { useSimpleModal } from '../../../../../hooks/useSimpleModal'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { EventVendor, LocalEvent } from '../../../../../types/Event'
import { VendorType } from '../../../../../types/Vendor'
import {
  postJotForm,
  useEventInquiriesStatuses,
  useJotForms,
} from '../../../../../utils/api/events'
import { useVendorTypes } from '../../../../../utils/api/vendors'
import {
  getABSJotFormIdByCategory,
  getJotFormIdByCategory,
} from '../../../../../utils/helpers/events'
import { clientCalendarPath, vendorsPath } from '../../../../../utils/paths'
import { useAuthData } from '../../../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../../../utils/providers/EventsProvider'
import { usePortalData } from '../../../../../utils/providers/PortalProvider'
import { InformationModalContent } from '../../../components/InformationModalContent'
import {
  IconContainer,
  MessageButton,
  ServicesTableWrapper,
} from '../../Events.styles'

import { AddMoreVendorsButton } from './AddMoreVendorsButton'
import { ServicesTable } from './ServicesTable'
import { VendorTypeRow } from './VendorTypeRow'

interface EventDetailsTableProps {
  eventData: LocalEvent | null
  isLoading: boolean
  onUpdateEventServicesAction: (removedVendor?: EventVendor) => void
  onToggleMessenger: () => void
  withWarning: boolean
  fetchEvent: () => void
}

const { REACT_APP_ENV: blaceenv } = process.env

export const EventServices: React.FC<EventDetailsTableProps> = ({
  eventData,
  isLoading,
  onUpdateEventServicesAction,
  onToggleMessenger,
  withWarning,
  fetchEvent,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authUser } = useAuthData()
  const { isAbs } = usePartner()
  const { JF } = useJotFormInitializer()
  const { container } = usePortalData({
    style: { position: 'fixed', right: 24, bottom: 180 },
  })
  const { isLoading: isVendorTypesLoading, data: categories } = useVendorTypes()
  const { updateUserActiveEvent, submitInquiryToActiveEvent } =
    useStorageEventsData()
  const { data: statusesData } = useEventInquiriesStatuses()
  const { toggleModal: toggleJotFormModal, isOpen: isJotFormModalOpen } =
    useSimpleModal()
  const { toggleModal: toggleThankYouModal, isOpen: isThankYouModalOpen } =
    useSimpleModal()
  const {
    isOpen: isInHouseVendorModalOpen,
    toggleModal: toggleInHouseVendorModal,
  } = useSimpleModal()
  const [isFormVisible, setIsFormVisible] = React.useState(true)
  const [selectedCategoryId, setSelectedCategoryId] = React.useState('')
  const [jotFormId, setJotFormId] = React.useState('')
  const { data: serverJotForms, refetch: refetchJotForms } = useJotForms(
    Number(eventData?.id),
    !!authUser
  )

  const categoriesWithVendors = useMemo(() => {
    return categories?.filter(
      (category: VendorType) => !!category.vendors_count
    )
  }, [categories?.length])

  const vendors = eventData?.vendors || []
  const venue = !eventData?.venue?.client_owned ? eventData?.venue : undefined
  const inHouseVendorTypes =
    eventData?.venue?.params?.inhouse_vendor_types || []

  const isVendorsLocked =
    venue &&
    !eventData?.venue?.partner &&
    !UNLOCK_VENDOR_STATUSES.includes(venue?.status as InquiryStatuses)

  const { isMobile } = useWindowSize()

  const categoriesWithTypeField =
    (categoriesWithVendors &&
      categoriesWithVendors.map((item) => {
        item.type = item.id
        return item
      })) ||
    []
  const vendorCategoriesWithoutFilled =
    categoriesWithVendors &&
    categoriesWithVendors.length &&
    differenceBy(categoriesWithTypeField, vendors, 'type')

  useEffect(() => {
    if (authUser && eventData && eventData.changed) {
      onUpdateEventServicesAction()
    }
  }, [eventData?.vendors?.length])

  useEffect(() => {
    refetchJotForms()
  }, [eventData?.id])

  const handleSelectedCategoryClick = (
    category: VendorType,
    isSubmitClicked?: boolean,
    serviceId?: string
  ) => {
    const JOTFORM_ID = isAbs
      ? getABSJotFormIdByCategory(category.id)
      : getJotFormIdByCategory(category.id)
    setJotFormId(JOTFORM_ID)
    setSelectedCategoryId(category.id)
    toggleJotFormModal()
    setIsFormVisible(true)
    window.addEventListener('message', (evt) => {
      if (
        evt.origin.includes('submit.jotform') &&
        evt.data.formID == JOTFORM_ID
      ) {
        setIsFormVisible(false)
        toggleThankYouModal()
        JF.getFormSubmissions(
          JOTFORM_ID,
          { vendorTypeId: category.id },
          async (response: any) => {
            if (
              response[0] &&
              !eventData?.vendors?.some(
                (vendor) =>
                  vendor.type === category.id && vendor.withSubmittedJotForm
              )
            ) {
              const updatedUserEventVendors = eventData?.vendors?.map(
                (vendor) => {
                  if (vendor.type === category.id) {
                    vendor.withSubmittedJotForm = true
                  }
                  return vendor
                }
              )
              updateUserActiveEvent({
                ...eventData,
                vendors: updatedUserEventVendors,
              })
              if (isSubmitClicked && serviceId) {
                await submitInquiryToActiveEvent(serviceId)
                fetchEvent()
              }
              submitJotForm(
                {
                  ...response[0],
                  vendorTypeId: category.id,
                  vendorId: serviceId,
                  isSubmitClicked,
                },
                JOTFORM_ID
              )
            }
          }
        )
      }
    })
  }

  const submitJotForm = (submittedJotForm: any, jotFormId?: string) => {
    const data = {
      questionnaire_type: 'vendor',
      form_id: jotFormId,
      event_id: Number(eventData?.id),
      vendor_type: submittedJotForm.vendorTypeId,
      data: JSON.stringify(submittedJotForm),
      submission_id: submittedJotForm.id,
    }
    postJotForm(data, Boolean(authUser))
      .then((response: any) => {
        refetchJotForms()
      })
      .catch((e: Error) => {
        console.log(e, 'error')
      })
  }

  const vendorsByCategory = useMemo(() => {
    const groupedVendors: Record<string, EventVendor[]> = {}
    vendors.forEach((vendor) => {
      if (!groupedVendors[vendor.type]) {
        groupedVendors[vendor.type] = []
      }

      groupedVendors[vendor.type].push(vendor)
    })

    return groupedVendors
  }, [vendors])

  const isEventDraft = useMemo(() => {
    return eventData?.status === EventDraftStatus
  }, [eventData])

  const isProposingStage = EVENT_STAGES_PROGRESS[
    InquiryStages.STAGE_PROPOSING
  ].includes(venue?.status as InquiryStatuses)

  return (
    <ServicesTableWrapper
      sx={{
        maxHeight: isMobile
          ? '100%'
          : withWarning
          ? 'calc(100vh - 272px)'
          : 'calc(100vh - 164px)',
      }}
    >
      {eventData && venue && !eventData?.venue?.partner && (
        <>
          <ServicesTable
            fetchEvent={fetchEvent}
            isEventDraft={isEventDraft}
            title="Venue"
            onToggleMessenger={onToggleMessenger}
            eventData={eventData}
            services={[venue]}
          />
          {isProposingStage && (
            <WarningBox
              sx={{ border: isMobile ? '1px solid #4E4BEC' : 'none' }}
              mb={4}
              type="info"
              background="#fff"
              color="#000"
            >
              <Typography variant="body2">
                <Trans i18nKey={'venue.browseVendors_1'}>
                  Your inquiry has been accepted!
                </Trans>
              </Typography>
              <Typography variant="body2">
                <Trans i18nKey={'venue.browseVendors_2'}>
                  The venue will get in touch with you soon via email or phone.
                </Trans>
              </Typography>
              <Typography variant="body2">
                <Trans i18nKey={'venue.browseVendors_3'}>
                  Feel free to browse our
                  <Link to={generatePath(vendorsPath)}>
                    selection of vendors
                  </Link>
                  to continue planning your event.
                </Trans>
              </Typography>
            </WarningBox>
          )}
        </>
      )}

      {eventData &&
        vendors &&
        Object.keys(vendorsByCategory).map((category) => {
          const categoryVendors = vendorsByCategory[category]
          if (!!categoryVendors.length) {
            return (
              <ServicesTable
                isEventDraft={isEventDraft}
                isInListOfInHouseVendorTypes={inHouseVendorTypes.includes(
                  category.toString()
                )}
                toggleInHouseVendorModal={toggleInHouseVendorModal}
                isVendorsLocked={isVendorsLocked}
                serverJotForms={serverJotForms}
                onRemove={onUpdateEventServicesAction}
                onHandleSelectedCategoryClick={handleSelectedCategoryClick}
                categories={categoriesWithVendors}
                key={category}
                eventData={eventData}
                services={categoryVendors}
                title={category.toString()}
                onToggleMessenger={onToggleMessenger}
                fetchEvent={fetchEvent}
              />
            )
          }

          return null
        })}
      {vendorCategoriesWithoutFilled &&
        !!vendorCategoriesWithoutFilled?.length &&
        !eventData?.venue?.partner &&
        vendorCategoriesWithoutFilled.map((category) => (
          <VendorTypeRow
            toggleInHouseVendorModal={toggleInHouseVendorModal}
            isInListOfInHouseVendorTypes={inHouseVendorTypes.includes(
              category.id
            )}
            category={category}
            key={category.id}
          />
        ))}
      {eventData?.venue?.partner && !eventData?.vendors?.length && (
        <GradientButton
          onClick={() => history.push(vendorsPath)}
          style={{ marginLeft: isMobile ? 0 : '20px' }}
        >
          Add vendors
        </GradientButton>
      )}
      {eventData?.venue?.partner && !!eventData?.vendors?.length && (
        <AddMoreVendorsButton />
      )}
      {(isLoading || isVendorTypesLoading) && <Loader position="fixed" />}
      {isFormVisible && (
        <SimpleModal
          minWidth={isMobile ? 'unset' : 730}
          maxWidth="inherit"
          open={isJotFormModalOpen}
          onClose={toggleJotFormModal}
        >
          {/*@ts-ignore*/}
          <JotformEmbed
            id={selectedCategoryId}
            src={`https://form.jotformeu.com/${jotFormId}?blaceeventid=${eventData?.id}&blaceenv=${blaceenv}`}
          />
        </SimpleModal>
      )}
      <SimpleModal
        minWidth={isMobile ? 'unset' : 730}
        maxWidth="inherit"
        open={isThankYouModalOpen}
        onClose={toggleThankYouModal}
      >
        <Box
          display="flex"
          style={{
            backgroundColor: 'rgba(132, 132, 132, 0.12)',
            margin: '10px',
          }}
          justifyContent="center"
          flexDirection="column"
          width={isMobile ? 'unset' : 730}
          height={isMobile ? '100%' : 300}
        >
          <Box width={1} display="flex" justifyContent="center" mb={5}>
            <Typography variant="h1">
              {t('questionnaire.thankYouTitle', 'Thank you!')}
            </Typography>
          </Box>
          {/*<Box width={1} display="flex" justifyContent="center" mx={5}>*/}
          <Typography style={{ textAlign: 'center' }} mx={5}>
            {t(
              'questionnaire.thankYouDescription',
              'Success! Your inquiry has been submitted. We may need to contact you for further information before creating your proposal, so please keep an eye out for a message from us.\n'
            )}
          </Typography>
          {/*</Box>*/}
        </Box>
      </SimpleModal>
      <SimpleModal
        open={isInHouseVendorModalOpen}
        onClose={toggleInHouseVendorModal}
      >
        <InformationModalContent
          isEventDraft={isEventDraft}
          description={t(
            'vendor.inhouses.description',
            'The vendors who are directly connected with this event. They are obliged to participate in the event. But if you want to replace them with others or refuse their services, you have to pay the Buy out fee'
          )}
          icon={
            <IconContainer mb={4}>
              <Star style={{ fontSize: 28, color: '#F0B440' }} />
            </IconContainer>
          }
          title={t('vendor.inhouses.name', 'In-house vendors')}
          onToggleMessenger={onToggleMessenger}
          onClose={toggleInHouseVendorModal}
        />
      </SimpleModal>
      {!isMobile && container.current && (
        <Portal container={container.current}>
          <MessageButton
            startIcon={<Calendar />}
            onClick={() => history.push(clientCalendarPath)}
          />
        </Portal>
      )}
    </ServicesTableWrapper>
  )
}
