const Chain = ({ fill = '#20202C' }: { fill?: string }) => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.546 8.404a.188.188 0 0 0 .264 0l2.724-2.723c1.26-1.261 3.389-1.395 4.781 0 1.395 1.394 1.261 3.52 0 4.78l-2.723 2.724a.188.188 0 0 0 0 .265l.933.933a.188.188 0 0 0 .265 0l2.723-2.723a5.067 5.067 0 0 0 0-7.172 5.07 5.07 0 0 0-7.172 0L9.618 7.21a.188.188 0 0 0 0 .265l.928.928Zm-6.061 11.11a5.067 5.067 0 0 0 7.172 0l2.725-2.724a.188.188 0 0 0 0-.265l-.93-.93a.188.188 0 0 0-.265 0l-2.723 2.723c-1.261 1.261-3.39 1.395-4.782 0-1.394-1.395-1.26-3.52 0-4.781l2.724-2.724a.188.188 0 0 0 0-.264l-.933-.933a.188.188 0 0 0-.265 0l-2.723 2.723a5.074 5.074 0 0 0 0 7.174Zm5.215-4.24a.188.188 0 0 0 .264 0l5.309-5.307a.188.188 0 0 0 0-.265l-.928-.928a.188.188 0 0 0-.265 0l-5.306 5.307a.188.188 0 0 0 0 .264l.926.928Z"
        fill={fill}
      />
    </svg>
  )
}

export default Chain
