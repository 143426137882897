const Navigation = ({ size = 48, scale = 1 }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2a2 2 0 0 1 2-2h44a2 2 0 0 1 2 2v44a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Z"
        fill="#20202C"
        transform={`scale(${scale})`}
      />
      <path
        d="M31.5 15h-15a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5Z"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinejoin="round"
        transform={`scale(${scale})`}
      />
      <path
        d="M18.5 18.5h4v4h-4v-4ZM25.5 18.5h4v4h-4v-4ZM18.5 25.5h4v4h-4v-4ZM25.5 25.5h4v4h-4v-4Z"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinejoin="round"
        transform={`scale(${scale})`}
      />
    </svg>
  )
}

export default Navigation
