import { Box, List, Typography } from '@material-ui/core'
import { unionBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSnackbar } from '../../hooks/useSnackbar'
import { ALLOWED_DOCUMENT_FORMATS } from '../../types/FileUploader'
import { InquiryWithDetails } from '../../types/Inquiry'
import { removeUserFile } from '../../utils/api/profile'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { useMessageModalData } from '../../utils/providers/MessageModalProvider'
import { DocumentsUploaderField } from '../ui/DocumentsUploaderField'
import { Loader } from '../ui/Loader'
import { StyledAdd } from '../ui/TableComponent/TableComponent.styles'

import { ContractsDocumentsSection } from './Contracts/ContractsDocumentsSection'
import { DocumentListItem } from './DocumentListItem'
import { DocumentsEmptyState } from './DocumentsEmptyState'
import { InvoicesDocumentsSection } from './Invoices/InvoicesDocumentsSection'

interface DocumentsProps {
  itemDetails?: InquiryWithDetails
  updateItemDetails?: (details: Partial<InquiryWithDetails>) => void
  updateHandler: (id: string, data: any) => Promise<any>
}

export const Documents: React.FC<DocumentsProps> = ({
  itemDetails,
  updateHandler,
}) => {
  const inputFileRef = React.useRef(null)
  const { t } = useTranslation()
  const showSnackbar = useSnackbar()
  const { authUser } = useAuthData()
  const { showMessage, closeMessageDialog } = useMessageModalData()

  const [isLoading, setIsLoading] = useState(false)
  const [documents, setDocuments] = React.useState<any[]>([])

  useEffect(() => {
    if (itemDetails) {
      const mergedDocs = unionBy(documents, [...itemDetails.files], 'id')
      setDocuments(mergedDocs)
    }
  }, [itemDetails?.files])

  const showEmptyState =
    !itemDetails?.contracts?.length &&
    !itemDetails?.invoice_bundles?.length &&
    !documents?.length

  const onAddClick = () => {
    // toggleModal()
    if (inputFileRef && inputFileRef.current) {
      // @ts-ignore
      inputFileRef.current.click()
    }
  }

  const onRemoveDocument = React.useCallback(
    async (fileID: number) => {
      try {
        setIsLoading(true)
        const response = await removeUserFile(fileID)
        const files = documents.filter(
          (document) => document.id !== response.id
        )
        setDocuments(files)
        setIsLoading(false)
      } catch (e) {
        console.error(e)
        showSnackbar({ severity: 'error', message: e.message })
        setIsLoading(false)
      } finally {
        console.log('Document removed')
      }
    },
    [documents]
  )

  const handleRemove = (id: number) => {
    showMessage({
      type: 'warning',
      style: { minHeight: 'auto' },
      title: t(
        'business.files.delete.title',
        'Are you sure you want to delete this file?'
      ),
      buttons: [
        {
          label: t('business.files.delete.no', 'No, back to files'),
          variant: 'outlined',
          action: () => {
            closeMessageDialog()
          },
        },
        {
          label: t('business.files.delete.yes', 'Yes'),
          action: async () => {
            await onRemoveDocument(id)
            closeMessageDialog()
          },
        },
      ],
    })
  }

  const onUploadDocument = React.useCallback(async (event, itemDetails) => {
    const data = {
      ...itemDetails,
      'attachments[0]': event.target.files[0],
    }
    try {
      setIsLoading(true)
      const response: any = await updateHandler(itemDetails?.id, data)
      const files =
        response.files && response.files.length ? [...response.files] : []
      setDocuments([...documents, ...files])
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      showSnackbar({ severity: 'error', message: e.message })
      setIsLoading(false)
    } finally {
      console.log('Document uploaded')
    }
  }, [])

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h4"
          fontFamily="Poppins"
          fontWeight={600}
          mb="24px"
        >
          {t('business.documents.list', 'Documents')}
        </Typography>
        <DocumentsUploaderField
          accept={ALLOWED_DOCUMENT_FORMATS}
          onChange={(e) => onUploadDocument(e, itemDetails)}
          inputFileRef={inputFileRef}
        >
          {itemDetails && authUser && (
            <StyledAdd color="primary" onClick={onAddClick}>
              + Add
            </StyledAdd>
          )}
        </DocumentsUploaderField>
      </Box>
      {showEmptyState ? (
        <DocumentsEmptyState
          title={t(
            'inquiry.documents.emptyState',
            'Here will be displayed documents'
          )}
        />
      ) : (
        <>
          {!!itemDetails?.contracts?.length && (
            <ContractsDocumentsSection contracts={itemDetails.contracts} />
          )}
          {!!itemDetails?.invoice_bundles?.length && (
            <InvoicesDocumentsSection bundles={itemDetails.invoice_bundles} />
          )}
          {!!documents.length && (
            <>
              <Typography mb={4}>
                {t('inquiry.documents.other.section', 'Other documents')}
              </Typography>
              <List style={{ padding: 0 }}>
                {documents.map((item: any) => (
                  <DocumentListItem
                    removeIsPossible={
                      !item.contract_id &&
                      !!item.user_id &&
                      item.user_id === authUser?.id
                    }
                    onRemove={() => handleRemove(item.id)}
                    key={item.id}
                    document={item}
                  />
                ))}
              </List>
            </>
          )}
        </>
      )}
      {isLoading && <Loader position="fixed" />}
    </>
  )
}
