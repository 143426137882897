/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { OutlinedInput, OutlinedInputProps, styled } from '@material-ui/core'

export type FormInputProps = OutlinedInputProps

export const FormInput = styled(OutlinedInput)(css`
  & .MuiOutlinedInput-notchedOutline {
    border-color: #d3d3d3;
  }
`)
