import { Box, Typography } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Photo from '../../../../../components/svg/dashboard/Photo'
import Guests from '../../../../../components/svg/Guests'
import { Image } from '../../../../../components/ui/Image'
import { PopperMenu } from '../../../../../components/ui/PopperMenu'
import { ImageItem } from '../../../../../types/general'
import { Room } from '../../../../../types/Venue'
import { useMessageModalData } from '../../../../../utils/providers/MessageModalProvider'
import { RoomItemWrapper } from '../../BusinessDetails.styles'

const actions = [
  { label: 'Edit', value: 'edit', color: 'secondary.dark' },
  { label: 'Delete', value: 'delete', color: 'secondary.dark' },
]

export const RoomItem: React.FC<{
  room: any
  onEditRoom: (room: Room) => void
  onDeleteRoom: (id: number) => void
}> = ({ room, onEditRoom, onDeleteRoom }) => {
  const { showMessage, closeMessageDialog } = useMessageModalData()
  const { t } = useTranslation()

  const handleActionSelect = (action: string) => {
    switch (action) {
      case 'delete':
        showMessage({
          type: 'warning',
          style: { minHeight: 'auto' },
          title: t(
            'business.rooms.delete.title',
            'Are you sure you want to delete this room?'
          ),
          buttons: [
            {
              label: t('business.rooms.delete.no', 'No, back to details'),
              variant: 'outlined',
              action: () => {
                closeMessageDialog()
              },
            },
            {
              label: t('business.rooms.delete.yes', 'Yes'),
              action: () => {
                onDeleteRoom(room.id)
                closeMessageDialog()
              },
            },
          ],
        })
        break
      case 'edit':
        onEditRoom(room)
        break
      default:
        onEditRoom(room)
    }
  }

  const roomImage = useMemo(
    () => room.images?.find((img: ImageItem) => img.order === 1),
    [room]
  )

  return (
    <RoomItemWrapper>
      <Box display="flex" mr={2} height={100} width={100}>
        {roomImage?.path ? (
          <Image image={roomImage} imageSize="small" alt="image" />
        ) : (
          <Box
            flex={1}
            style={{ background: '#D7D7D7' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Photo />
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        width={1}
        mt={2}
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
        >
          <Box display="flex" mb={1}>
            <Typography lineHeight="24px">{room.name}</Typography>
          </Box>
          <Box display="flex" mb={1}>
            <Guests />
            <Typography
              sx={{ marginLeft: '5px' }}
              lineHeight="24px"
              variant="body2"
              color="#9396A3"
            >
              {room.capacity} guests max
            </Typography>
          </Box>
        </Box>
        <PopperMenu options={actions} onSelectAction={handleActionSelect}>
          <MoreVert className="event-action" />
        </PopperMenu>
      </Box>
    </RoomItemWrapper>
  )
}
