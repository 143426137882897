import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { MultiSelectField } from '../../../components/ui/FormComponents/MultiSelectField'
import { Loader } from '../../../components/ui/Loader'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { DashboardTitle } from '../../../components/ui/PageTitle'
import { PaginationComponent } from '../../../components/ui/Pagination'
import { BookmarkTabs } from '../../../components/ui/Tabs/BookmarkTabs'
import {
  BOOKING_INITIAL_SEARCH_PARAMS,
  BOOKING_SORTING_FIELDS,
  BOOKING_VIEW_BY,
  bookingQueryParams,
  getBookingColumns,
} from '../../../constants/bookings'
import { UserRoles } from '../../../constants/profile'
import { useBookings } from '../../../hooks/useBookings'
import { useBusinessInquiryActions } from '../../../hooks/useBusinessInquiryActions'
import { useCurrentBusinessListingId } from '../../../hooks/useCurrentBusinessListingId'
import { useIsAbsUser } from '../../../hooks/useIsAbsUser'
import { useUrlParams } from '../../../hooks/useUrlParams'
import { ProfileVendor, ProfileVenue } from '../../../types/Profile'
import { useEventInquiriesStatuses } from '../../../utils/api/events'
import { getModalContent } from '../../../utils/helpers/bookings'
import { mapQueryParams } from '../../../utils/helpers/common'
import { SortOrder } from '../../../utils/helpers/sort'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { SortingSelect } from '../../marketplace/components/SortingSelect'

import { BookingRowItem } from './BookingRowItem'

export const StyledTablesContainer = styled(Box)`
  background-color: #ffffff;
  opacity: 1;

  ${MEDIA_RULES.SMALL} {
    height: 100%;
  }
`

export const Bookings = () => {
  const { urlParams } = useUrlParams()
  const initialQueryParams = {
    ...BOOKING_INITIAL_SEARCH_PARAMS,
    ...urlParams,
  }
  const history = useHistory()
  const currentStage = history.location.hash.replace('#', '')
  const { authUser } = useAuthData()
  const isLandlord = UserRoles.isLandlord(authUser?.role)
  const { handleBusinessInquiryActions, modalControl, isLoading } =
    useBusinessInquiryActions()
  const { isOpen, toggleModal, modalData } = modalControl

  const [queryParams, updateQueryParams] = useState<bookingQueryParams>({
    ...initialQueryParams,
  })

  const { data: statusesData } = useEventInquiriesStatuses()
  const [orderDirection, setOrderDirection] = useState<SortOrder>(
    initialQueryParams.sortDirection
  )
  const [isQueryEnabled, setIsQueryEnabled] = useState(false)

  const { businessId } = useCurrentBusinessListingId()
  const isAbsUser = useIsAbsUser()

  const {
    bookings: eventsList,
    fetchBookings,
    lastPage,
    isListLoading: loading,
  } = useBookings(businessId.toString(), queryParams, isQueryEnabled)

  const allListings: (ProfileVenue | ProfileVendor)[] =
    (isLandlord ? authUser?.venues : authUser?.vendors) || []

  const activeBusiness = useMemo(() => {
    if (!allListings?.length) return
    return (
      allListings?.find((listing) => listing.id === businessId) ||
      allListings?.[0]
    )
  }, [allListings, businessId])

  const dataPartnersCount = useMemo(() => {
    if (isLandlord) return 1

    return activeBusiness && 'partners' in activeBusiness
      ? activeBusiness?.partners?.length
      : 1
  }, [activeBusiness])

  const inquiryStages = useMemo(() => {
    return statusesData
      ? Object.keys(statusesData?.inquiryStatus.stages).map((key: string) => ({
          value: key,
          label: statusesData.inquiryStatus.stages[key].label,
        }))
      : []
  }, [statusesData])

  const stagesTabs = [{ label: 'All', value: 'all' }, ...inquiryStages]

  useEffect(() => {
    if (
      !stagesTabs
        ?.map((tab) => tab.value)
        ?.find((value) => value === currentStage) ||
      queryParams.stage === currentStage
    )
      return
    updateQueryParams({ ...queryParams, stage: currentStage })
  }, [currentStage])

  useEffect(() => {
    setIsQueryEnabled(true)
  }, [])

  const onSort = (field: string) => {
    const orderBy = mapQueryParams(field)
    updateQueryParams({ ...queryParams, orderBy })
  }

  const onChangeDirection = (direction: SortOrder) => {
    setOrderDirection(direction)
    updateQueryParams({ ...queryParams, orderDirection: direction })
  }

  const handleSearch = (field: string, value: string) => {
    updateQueryParams({ ...queryParams, [field]: value.toLowerCase() })
  }

  const onPaginationChange = (event: any, page: number) => {
    updateQueryParams({ ...queryParams, page })
  }

  const onChangeTab = (stage: string) => {
    updateQueryParams({ ...queryParams, stage, page: 1 })
  }

  return (
    <Box
      width={1}
      minHeight={800}
      style={{ paddingLeft: 30, paddingRight: 30, position: 'relative' }}
    >
      <DashboardTitle title={'Bookings'} />
      <Box display="flex" mb={15} width={1}>
        <TableContainer
          sx={{
            overflowX: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box display="flex" justifyContent="space-between" mr={20} mb={2}>
            <Box display="flex">
              <MultiSelectField
                id="viewBy"
                defaultLabel={`View by: ${queryParams.viewBy}`}
                options={BOOKING_VIEW_BY}
                onChange={(value: any) => handleSearch('viewBy', value)}
              />
              <Box>
                <SortingSelect
                  sortingFields={BOOKING_SORTING_FIELDS}
                  orderBy={queryParams.orderBy}
                  orderDirection={orderDirection}
                  onChangeDirection={onChangeDirection}
                  onSort={onSort}
                />
              </Box>
            </Box>
          </Box>
          <BookmarkTabs
            tabs={stagesTabs}
            onChange={(inquiry_status) => onChangeTab(inquiry_status)}
          />
          <StyledTablesContainer>
            <Table
              stickyHeader
              style={{
                boxShadow:
                  '0px 0px 10px 0px white, 0px 0px 10px 0px rgba(34, 60, 80, 0.2)',
              }}
            >
              <TableHead>
                <TableRow>
                  {getBookingColumns().map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {eventsList.map((event: any, index: number) => (
                  <BookingRowItem
                    key={index}
                    inquiriesPartnersCount={dataPartnersCount}
                    event={event}
                    refetchBookings={fetchBookings}
                    handleOnMenuOptionSelect={handleBusinessInquiryActions}
                  />
                ))}
              </TableBody>
            </Table>
          </StyledTablesContainer>
          <PaginationComponent
            style={{ alignSelf: 'center', marginTop: '20px' }}
            page={queryParams.page}
            size="large"
            onChange={onPaginationChange}
            count={lastPage}
            shape="rounded"
          />
        </TableContainer>
      </Box>
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl)}
      </SimpleModal>
      {(loading || isLoading) && <Loader position="absolute" />}
    </Box>
  )
}
