import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, generatePath, useRouteMatch } from 'react-router-dom'

import { Loader } from '../../../components/ui/Loader'
import { BottomModal } from '../../../components/ui/Modals/BottomModal'
import { SideMenu } from '../../../components/ui/SideMenu'
import { EmptyGooglePlace } from '../../../constants'
import { getVendorBusinessDetailsMenu } from '../../../constants/business'
import { UserRoles } from '../../../constants/profile'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useVendorById } from '../../../hooks/useVendorById'
import { VendorUpdateRequest } from '../../../types/dtos/vendors'
import { AssetRefDownload } from '../../../types/FileUploadHandler'
import {
  BusinessContactData,
  BusinessDetailsInfo,
  BusinessGeneralInfo,
} from '../../../types/general'
import { updateBusinessImages, updateVendor } from '../../../utils/api/vendors'
import { getRequestError } from '../../../utils/helpers/validations'
import { vendorsMapper } from '../../../utils/mappers/vendors'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useMessageModalData } from '../../../utils/providers/MessageModalProvider'
import { VendorDetails } from '../../vendors/Vendor'

import { BusinessContact } from './components/BusinessContact'
import { GeneralInfo } from './components/GeneralInfo'
import { LineItemsAndPricing } from './components/LineItemsAndPricing'
import { PhotoUpload } from './components/PhotoUpload'

export const VendorBusinessDetails: React.FC<{
  onOpenFullCalendar: (calendarGoogleId: string) => void
}> = ({ onOpenFullCalendar }) => {
  const {
    isLoading: authLoading,
    authUser,
    isAuthorized,
    updateUser,
  } = useAuthData()
  const isAuthCompleted = !authLoading && isAuthorized

  const {
    path,
    params: { listingId },
  } = useRouteMatch<{ listingId: string }>()

  const previewModal = useSimpleModal()
  const { showMessage } = useMessageModalData()

  const [, vendor, setVendor] = useVendorById(listingId, {})
  const { t } = useTranslation()

  const handleBusinessPublish = async () => {
    try {
      const vendor = await updateVendor(listingId, { statusChange: true })
      setVendor(vendorsMapper(vendor))

      showMessage({
        title: t(
          'business.publishMessage.title',
          'Your business listing has been submitted for review.'
        ),
        text: t(
          'business.publishMessage.text',
          'The BLACE team is currently reviewing your profile. We will reach out to confirm activation of your listing upon approval. Thank you!'
        ),
        type: 'warning',
      })
    } catch (e) {
      showMessage({
        title: 'Error occurred',
        text: getRequestError(e),
        type: 'error',
      })
    }
  }

  const contactData: BusinessContactData = vendor?.contact || {
    name: '',
    phone: '',
    email: '',
    address: EmptyGooglePlace,
    website: '',
    // facebook: '',
    instagram: '',
  }

  const onContactSave = async (info: BusinessContactData) => {
    const updateData: VendorUpdateRequest = {
      contact_email: info.email,
      contact_name: info.name,
      website: info.website,
      phone: info.phone,
      placeId: info.address.place_id,
      params: {
        instagram: info.instagram,
      },
    }

    const vendor = await updateVendor(listingId, updateData)
    setVendor(
      vendorsMapper({
        ...vendor,
        location: { formatted_address: info.address.description },
      })
    )
  }

  const onGeneralInfoSave = async (
    info:
      | BusinessGeneralInfo
      | Partial<BusinessGeneralInfo>
      | BusinessDetailsInfo
  ) => {
    // @ts-ignore
    const vendor = await updateVendor(listingId, info)
    setVendor(vendorsMapper(vendor))
  }

  const onUpdatePhotos = async (images: AssetRefDownload[]) => {
    if (!listingId) return
    try {
      const response = await updateBusinessImages(
        listingId,
        images.map((image, index) => ({
          id: image.metadata.imageId,
          order: index + 1,
        }))
      )

      setVendor(vendorsMapper(response))

      if (authUser?.vendors && response.images) {
        const vendors = authUser?.vendors.map((vendor) => {
          if (vendor.id === authUser?.listing_id) {
            return { ...vendor, images: response.images }
          }
          return vendor
        })
        updateUser({ vendors })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const pathname = generatePath(path, {
    listingId: authUser?.listing_id || +listingId,
  })

  return (
    <>
      <Box display="flex" mb={4} width={1}>
        <SideMenu
          businessType={UserRoles.Vendor}
          businessStatus={vendor?.status}
          handleBusinessPublish={handleBusinessPublish}
          previewModal={previewModal}
          items={getVendorBusinessDetailsMenu(listingId)}
        />
        <Box flex="1" display="grid" gridTemplateColumns={'2fr 0'}>
          <Switch>
            <Route exact path={pathname}>
              <PhotoUpload
                onSave={onGeneralInfoSave}
                businessName={vendor?.name || ''}
                businessType={UserRoles.Vendor}
                vendorType={vendor?.type}
                onUpdatePhotos={onUpdatePhotos}
                images={vendor?.images}
              />
            </Route>
            <Route path={`${pathname}/description`}>
              <GeneralInfo
                onSave={onGeneralInfoSave}
                attachLabelMethod={updateVendor}
                entity={vendor}
              />
            </Route>
            <Route path={`${pathname}/line-items`}>
              <LineItemsAndPricing
                businessDetails={vendor}
                onPriceSave={onGeneralInfoSave}
                orderPrice={vendor?.orderPrice.length || ''}
              />
            </Route>
            <Route path={`${pathname}/contacts`}>
              <BusinessContact onSave={onContactSave} data={contactData} />
            </Route>
          </Switch>
          {/*{!!vendor?.calendar?.google_id && <Calendar onOpenFullCalendar={onOpenFullCalendar} calendarGoogleId={vendor?.calendar?.google_id} />}*/}
        </Box>
      </Box>
      {vendor && (
        <BottomModal
          open={previewModal.isOpen}
          onClose={previewModal.toggleModal}
        >
          <Box display="block" pb="32px">
            <VendorDetails
              vendorId={vendor.id.toString()}
              showBottomBar={false}
            />
          </Box>
        </BottomModal>
      )}
      {!isAuthCompleted && <Loader position="fixed" />}
    </>
  )
}
