import { Box, IconButton, Typography } from '@material-ui/core'
import { Add, Done } from '@material-ui/icons'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { usePartner } from '../../../../hooks/usePartner'
import { EventVenue } from '../../../../types/Event'
import { VenueItem } from '../../../../types/Venue'
import { formatToCurrency } from '../../../../utils/helpers/price'
import {
  convertVenueToEventVenue,
  getAddressString,
} from '../../../../utils/helpers/venue'
import { useStorageEventsData } from '../../../../utils/providers/EventsProvider'

import {
  Footer,
  SubtitleWrapper,
  TitleTextWrapper,
  TitleWrapper,
} from './VenueListItem.styles'

interface VenueListItemFooterProps {
  data: VenueItem
  onClick?: (slug: string) => void
  addMode?: boolean
  showSnackbar?: (item: EventVenue) => void
  flexItems?: boolean
}

const VenueListItemFooter: React.FC<VenueListItemFooterProps> = ({
  data,
  addMode,
  showSnackbar,
  onClick,
  flexItems = false,
}) => {
  const { updateUserActiveEvent, getUserActiveEvent } = useStorageEventsData()
  const addressString = getAddressString(
    data.location_address?.formatted_address || data.address?.formatted_address
  )
  const { isAbs } = usePartner()
  const { t } = useTranslation()

  const activeUserEvent = getUserActiveEvent()
  const isSelected = activeUserEvent?.venue?.id === data.id

  const priceString = useMemo(() => {
    return data?.params?.display_pricing
      ? `From ${formatToCurrency(data?.params?.minimum_starting_at, false)} / ${
          data?.params?.short_pricing_details_label
        }`
      : data?.payment_structure?.pricing_details
      ? ''
      : t('business.pricing.landlord.notDisplay', 'Inquire for Pricing')
  }, [data?.params])

  const replaceVenueInEvent = () => {
    if (isSelected) return
    const venue = convertVenueToEventVenue(data)
    updateUserActiveEvent({ venue })

    if (showSnackbar) {
      showSnackbar(venue)
    }
  }

  const subtitle = (
    <SubtitleWrapper>
      <Typography
        variant="body2"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {addressString}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body1"
          sx={{
            color: (theme) =>
              flexItems
                ? theme.palette.grey['200']
                : theme.palette.common.white,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: flexItems ? '14px' : 0,
          }}
        >
          {priceString}
        </Typography>
        {addMode ? (
          <VenueAddButton
            isSelected={isSelected}
            onClick={replaceVenueInEvent}
          />
        ) : null}
      </Box>
    </SubtitleWrapper>
  )

  const title = (
    <TitleWrapper
      sx={{
        borderBottom: flexItems ? 'none' : '1px solid rgba(255, 255, 255, 0.5)',
      }}
    >
      {isAbs ? (
        <Typography
          color="#fff"
          variant="body1"
          fontSize="18px"
          fontWeight={600}
          sx={{ position: 'absolute', bottom: '20px', left: '20px' }}
        >
          {data.name}
        </Typography>
      ) : (
        <TitleTextWrapper sx={{ fontSize: flexItems ? '16px' : '18px' }}>
          {data.name}
        </TitleTextWrapper>
      )}
    </TitleWrapper>
  )

  return isAbs ? (
    <>
      <Box
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        onClick={replaceVenueInEvent}
      >
        <Box sx={{ position: 'absolute', top: '17px', left: '20px' }}>
          <VenueAddButton isSelected={isSelected} />
        </Box>
        {title}
      </Box>
    </>
  ) : (
    <Footer
      title={title}
      subtitle={subtitle}
      onClick={() => onClick && onClick(data.slug)}
      sx={{ '&:hover': { cursor: onClick ? 'pointer' : 'default' } }}
    />
  )
}

const addButtonStyle = {
  color: 'white',
  padding: '4px',
}

const VenueAddButton: React.FC<{
  isSelected: boolean
  onClick?: () => void
}> = ({ isSelected, onClick }) => {
  return (
    <IconButton
      style={{ padding: 0 }}
      aria-label={`select venue`}
      onClick={onClick && onClick}
    >
      {isSelected ? (
        <Done
          sx={{
            ...addButtonStyle,
            background: (theme) => theme.palette.common.buttonBg,
          }}
        />
      ) : (
        <Add
          sx={{
            ...addButtonStyle,
            background: (theme) => theme.palette.common.buttonBg,
          }}
        />
      )}
    </IconButton>
  )
}

export default VenueListItemFooter
