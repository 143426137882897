import { Box, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'
import { useHistory } from 'react-router'

import { FullCalendarComponent } from '../../components/FullCalendar'
import ArrowBack from '../../components/svg/ArrowBack'
import { useWindowSize } from '../../hooks/useWindowSize'
import {
  BlockContainer,
  BlockTitle,
} from '../dashboard/BusinessDetails/BusinessDetails.styles'

const CalendarContainer = styled(Box)`
  margin-top: 20px;
  width: 350px;

  ${MEDIA_RULES.SMALL} {
    width: 100%;
  }
`

const StyledTypography = styled(Typography)`
  color: #2f54eb;
  margin-left: 10px;
`

const StyledBox = styled(Box)`
  float: right;
`

interface CalendarPageProps {
  calendarGoogleId: string | string[]
  viewBy: string
  onCloseFullCalendar?: () => void
  withBackButton?: boolean
}

export const CalendarPage: React.FC<CalendarPageProps> = ({
  calendarGoogleId,
  viewBy,
  onCloseFullCalendar,
  withBackButton = true,
}) => {
  const { isMobile } = useWindowSize()
  const history = useHistory()

  const getCalendarInitialView = (viewBy: string) => {
    switch (viewBy) {
      case 'Month':
        return 'dayGridMonth'
      case 'Week':
        return 'timeGridWeek'
      case 'Day':
        return 'timeGridDay'
    }
  }

  return (
    <BlockContainer my={4}>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        {calendarGoogleId && withBackButton && (
          <StyledBox
            sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
            display="flex"
            alignItems="center"
            onClick={
              onCloseFullCalendar ? onCloseFullCalendar : () => history.goBack()
            }
          >
            <ArrowBack />
            <StyledTypography>Back to details</StyledTypography>
          </StyledBox>
        )}
        {calendarGoogleId && (
          <Box mt={isMobile ? 4 : 0} textAlign={isMobile ? 'center' : 'left'}>
            <BlockTitle>Full calendar view</BlockTitle>
          </Box>
        )}
      </Box>
      {calendarGoogleId && (
        <Box width="100%">
          <FullCalendarComponent
            calendarGoogleId={calendarGoogleId}
            initialView={getCalendarInitialView(viewBy)}
          />
        </Box>
      )}
    </BlockContainer>
  )
}
