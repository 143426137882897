import { Box, styled, Typography } from '@material-ui/core'
import * as React from 'react'

import NoActivity from '../../../../../components/svg/NoActivity'

const ActivityContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`

export const Activity = () => {
  return (
    <>
      <ActivityContainer>
        <NoActivity />
      </ActivityContainer>
      <Box display="flex" justifyContent="center" mt={3}>
        <Typography variant="body2" align="center" color="secondaryText">
          All activity of the event will be displayed here
        </Typography>
      </Box>
    </>
  )
}
