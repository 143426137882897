import { Box, Popover, styled, TextField } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const DatesField = styled(TextField)`
  .Mui-disabled input {
    -webkit-text-fill-color: black;
  }
`

export const DateRangeContainer = styled(Popover)`
  margin-top: 20px;

  .MuiPaper-root,
  .MuiPaper-root > div > div {
    background: white;
  }

  .MuiPaper-root {
    ${MEDIA_RULES.SMALL} {
      bottom: 100px;
      top: 100px !important;
    }
  }

  .date-range-calendar {
    max-height: 345px;
    background: white;
    margin: 0 5px;

    ${MEDIA_RULES.SMALL} {
      margin: 0;
      padding: 0 24px;

      .MuiTypography-root,
      .MuiDateRangePickerDay-root {
        flex: 1;
      }

      .MuiDateRangePickerDay-day {
        width: 100%;
        border: 1px solid #f7f7f8;
        transform: none;
      }
    }
  }

  .date-range-calendar > div > div {
    border-right: none;
    border-radius: 0;

    ${MEDIA_RULES.SMALL} {
      width: 100%;
    }
  }

  .date-range-calendar div {
    border-width: 1px;
    border-radius: 0;

    ${MEDIA_RULES.SMALL} {
      border: none;
      margin: 0;
      text-align: center;
    }
  }

  .date-range-calendar .PrivatePickersSlideTransition-root {
    min-height: 250px;
    min-width: 285px;
  }

  .date-range-calendar button,
  .date-range-calendar .MuiDateRangePickerDay-rangeIntervalDayPreview {
    border-radius: 0;
    border-width: 1px;
  }
`

export const FooterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: #f7f7f8 solid 1px;
  padding-top: 10px;
  padding-bottom: 10px;

  margin-right: 24px;
  margin-left: 24px;

  ${MEDIA_RULES.SMALL} {
    position: fixed;
    background: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 16px 24px 40px;
    height: 100px;
  }
`

export const ResetButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;

  ${MEDIA_RULES.SMALL} {
    padding: 0;
    width: 50%;
    margin-right: 20px;
  }
`
