import { SVGProps } from 'react'

const Eye = ({ fill = '#9396A3', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.402 6.496C15.55 2.596 12.752.633 8.999.633S2.45 2.596.598 6.498a1.178 1.178 0 0 0 0 1.006c1.852 3.9 4.65 5.863 8.402 5.863 3.754 0 6.551-1.963 8.403-5.865.15-.316.15-.684 0-1.006Zm-8.403 5.465c-3.15 0-5.457-1.598-7.083-4.961 1.626-3.363 3.933-4.96 7.083-4.96 3.15 0 5.457 1.597 7.085 4.96-1.625 3.363-3.932 4.96-7.085 4.96Zm-.078-8.399a3.438 3.438 0 1 0 0 6.876 3.438 3.438 0 0 0 0-6.876Zm0 5.626A2.186 2.186 0 0 1 6.734 7a2.186 2.186 0 1 1 4.375 0A2.186 2.186 0 0 1 8.92 9.188Z" />
    </svg>
  )
}

export default Eye
