import { Box, ImageList, styled, Typography } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'

import { VendorListItem } from '../../../components/cards/VendorListItem'
import Question from '../../../components/svg/Question'
import { Loader } from '../../../components/ui/Loader'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { PaginationComponent } from '../../../components/ui/Pagination'
import { CustomSnackbar } from '../../../components/ui/Snackbar'
import { TooltipComponent } from '../../../components/ui/Tooltip'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { EventVendor } from '../../../types/Event'
import { Vendor } from '../../../types/Vendor'
import { getModalContent } from '../../../utils/helpers/bookings'
import { convertVendorToEventVendor } from '../../../utils/helpers/vendors'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { LikesDataProvider } from '../../../utils/providers/LikesProvider'

interface VendorsListProps {
  vendors: Vendor[]
  pickedVendors?: EventVendor[]
  itemsWithFooter?: boolean
  title?: string
  isLoading?: boolean
  page?: number | string
  lastPage?: number
  onPaginationChange?: (event: object, page: number) => void
  navigateAction?: (vendor: Vendor) => void
  withCategoryName?: boolean
  tooltipBox?: JSX.Element
}

export const StyledTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 16px;
  text-transform: capitalize;
`

export const VendorsList: React.FC<VendorsListProps> = ({
  vendors = [],
  page,
  isLoading,
  lastPage,
  onPaginationChange,
  navigateAction,
  itemsWithFooter,
  title,
  pickedVendors = [],
  withCategoryName,
  tooltipBox,
}) => {
  const modalControl = useSimpleModal()
  const { isOpen, toggleModal, modalData } = modalControl
  const pageNumber = typeof page === 'string' ? parseInt(page) : page

  return (
    <LikesDataProvider>
      <Box>
        <Box display="flex">
          <StyledTitle align="left" variant="h3" width="100%">
            {title}
          </StyledTitle>
          {tooltipBox && (
            <TooltipComponent placement="top" title={tooltipBox}>
              <Box style={{ cursor: 'pointer' }}>
                <Question fill="#9396A3" />
              </Box>
            </TooltipComponent>
          )}
        </Box>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={780}
            width={1}
            style={{ position: 'relative' }}
          >
            <Loader position="absolute" background="transparent" />
          </Box>
        ) : (
          <Box mx={-2}>
            <ImageList
              style={{
                marginTop: 0,
                width: '100%',
                gridTemplateColumns: 'repeat(auto-fill, minmax(288px, 1fr))',
              }}
            >
              {vendors?.map((vendor: Vendor) => (
                <VendorListItem
                  withCategoryName={withCategoryName}
                  pickedVendors={pickedVendors}
                  navigateAction={navigateAction}
                  itemsWithFooter={itemsWithFooter}
                  key={vendor.id}
                  vendor={vendor}
                />
              ))}
            </ImageList>
          </Box>
        )}
        {onPaginationChange && (
          <Box mb={5} flex={1} display="flex" justifyContent="center">
            <PaginationComponent
              page={pageNumber}
              size="large"
              onChange={onPaginationChange}
              count={lastPage}
              shape="rounded"
            />
          </Box>
        )}
      </Box>
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl)}
      </SimpleModal>
    </LikesDataProvider>
  )
}
