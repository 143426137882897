import DocumentWarning from '../../components/svg/clientDashboard/DocumentWarning'
import DollarCircle from '../../components/svg/DollarCircle'
import Reminder from '../../components/svg/Reminder'

export const notificationIconANdColorMapping = (name: string) => {
  switch (name) {
    case 'InquiryStatusChanged':
      return { color: '#FEF8E6', icon: <DocumentWarning /> }
    case 'InquiryFeeAssessment':
      return { color: '#FEF8E6', icon: <DollarCircle fill="#F3BD00" /> }
    case 'InquirySubmit':
      return { color: '#EBF9EE', icon: <DocumentWarning fill="#27AE60" /> }
    case 'InquiryVendorInsert':
      return { color: '#FEF8E6', icon: <DocumentWarning /> }
    case 'InquiryVendorStatusChanged':
      return { color: '#FEF8E6', icon: <DocumentWarning /> }
    case 'InquiryVenueInsert':
      return { color: '#FEF8E6', icon: <DocumentWarning /> }
    case 'InquiryVenueUpdate':
      return { color: '#FEF8E6', icon: <DocumentWarning /> }
    case 'Notification':
      return { color: '#FFF4E6', icon: <Reminder /> }
    case 'PaymentRequired':
      return { color: '#FFF4E6', icon: <DollarCircle fill="#FF9500" /> }
    case 'UserHasNewMessage':
      return { color: '#FFF4E6', icon: <Reminder /> }
    case 'VenueAssign':
      return { color: '#FEF8E6', icon: <DocumentWarning /> }
    default:
      return { color: '#FEF8E6', icon: <DocumentWarning /> }
  }
}
