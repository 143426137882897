import { styled } from '@material-ui/core'
import * as React from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import { v4 as uuid } from 'uuid'

// @ts-ignore
import {
  DraggableElementWrapper,
  DragItemWrapper,
  ItemsContainer,
} from './DragAndDrop.styles'

interface DragAndDropProps {
  onDragComplete: (from: number, to: number) => void
  children?: React.ReactNode
  isDragDisabled?: boolean
  isTableView?: boolean
}

interface DraggableElementProps {
  index: number
  item: React.ReactNode
  isDragDisabled: boolean
}

interface DraggableListProps {
  items: React.ReactNodeArray
  isDragDisabled: boolean
}

const calculateColumnsTemplate = (items: number) => {
  if (items === 1) {
    return 'fr1'
  }

  if (items <= 3) {
    return '1fr 1fr'
  }

  return '1fr 1fr 1fr'
}

const ImagesGrid = styled('div')<{ count: number }>(
  (props) => `
    display: grid;
    grid-template-columns: ${calculateColumnsTemplate(props.count)};
`
)

export const reorder = (
  list: React.ReactNodeArray,
  startIndex: number,
  endIndex: number
): React.ReactNodeArray => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

/**
 * Moves an item from one list to another list.
 */
export const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result: any = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const DraggableElement = ({
  item,
  index,
  isDragDisabled,
}: DraggableElementProps) => {
  return (
    <DraggableElementWrapper>
      <Draggable
        isDragDisabled={isDragDisabled}
        draggableId={uuid()}
        index={index}
      >
        {/* @ts-ignore */}
        {(provided) => (
          <DragItemWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {item}
          </DragItemWrapper>
        )}
      </Draggable>
    </DraggableElementWrapper>
  )
}

// eslint-disable-next-line react/display-name
const DraggableList = React.memo(
  // @ts-ignore
  ({ items, isDragDisabled }: DraggableListProps) => {
    return items.map((item: React.ReactNode, index: number) => (
      <DraggableElement
        item={item}
        index={index}
        // @ts-ignore
        key={item.key}
        isDragDisabled={isDragDisabled}
      />
    ))
  }
)

export const DragAndDrop = ({
  onDragComplete,
  isTableView = false,
  children,
  isDragDisabled = false,
}: DragAndDropProps) => {
  const [items, setItems] = React.useState(React.Children.toArray(children))
  const [prevChildren, setPrevChildren] = React.useState(children)

  if (prevChildren !== children) {
    setItems(React.Children.toArray(children))
    setPrevChildren(children)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    if (result.destination.index === result.source.index) {
      return
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    )
    onDragComplete(result.source.index, result.destination.index)

    // @ts-ignore
    setItems(reorderedItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {/* @ts-ignore */}
        {(provided) => (
          <ItemsContainer
            isTableView={isTableView}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <DraggableList items={items} isDragDisabled={isDragDisabled} />
            {provided.placeholder}
          </ItemsContainer>
        )}
      </Droppable>
    </DragDropContext>
  )
}
