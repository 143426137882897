const ActivityGray = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 11H10.375V5.6875C10.375 3.48281 8.74531 1.66094 6.625 1.35781V0.75C6.625 0.404687 6.34531 0.125 6 0.125C5.65469 0.125 5.375 0.404687 5.375 0.75V1.35781C3.25469 1.66094 1.625 3.48281 1.625 5.6875V11H1.25C0.973437 11 0.75 11.2234 0.75 11.5V12C0.75 12.0688 0.80625 12.125 0.875 12.125H4.25C4.25 13.0906 5.03438 13.875 6 13.875C6.96562 13.875 7.75 13.0906 7.75 12.125H11.125C11.1938 12.125 11.25 12.0688 11.25 12V11.5C11.25 11.2234 11.0266 11 10.75 11ZM6 12.875C5.58594 12.875 5.25 12.5391 5.25 12.125H6.75C6.75 12.5391 6.41406 12.875 6 12.875ZM2.75 11V5.6875C2.75 4.81875 3.0875 4.00312 3.70156 3.38906C4.31562 2.775 5.13125 2.4375 6 2.4375C6.86875 2.4375 7.68437 2.775 8.29844 3.38906C8.9125 4.00312 9.25 4.81875 9.25 5.6875V11H2.75Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default ActivityGray
