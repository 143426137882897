import {
  Box,
  IconButton,
  InputAdornment,
  styled,
  Typography,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Formik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { SimpleRegistrationRequest } from '../../../types/dtos/auth'
import { Status } from '../../../types/general'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { SimpleButton } from '../../ui/Buttons/SimpleButton'
import { TextInput } from '../../ui/FormComponents/TextInput'
import { Loader } from '../../ui/Loader'
import { personalInfoValidationSchema } from '../authValidations'

const initialFormData = {
  name: '',
  password: '',
  confirm: '',
}

const NameInput = styled(TextInput)`
  input {
    text-transform: capitalize;
  }
`

const PersonalInfoForm: React.FC<{ onComplete?: () => void }> = ({
  onComplete = () => {},
}) => {
  const { register, registrationData, status, isLoading, error, resetStatus } =
    useAuthData()

  const { isMobile } = useWindowSize()

  useEffect(() => {
    if (status === Status.SUCCESS) {
      onComplete()
    }
  }, [status])

  useEffect(() => {
    return resetStatus
  }, [])

  const onSubmit = React.useCallback(
    async (values: SimpleRegistrationRequest) => {
      register(values)
    },
    []
  )

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={6}>
        <Typography variant="h3">Personal info</Typography>
      </Box>
      <Formik
        validationSchema={personalInfoValidationSchema()}
        initialValues={{
          ...initialFormData,
          email: registrationData.email,
          default_client_venue_id: registrationData.default_client_venue_id,
          agreed: registrationData.agreed,
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box
              display="flex"
              mb={3}
              flexDirection="column"
              maxWidth={isMobile ? 'unset' : 450}
            >
              <FormField />
              <SimpleButton type="submit" sx={{ mt: 6 }} variant="contained">
                Create Account
              </SimpleButton>
            </Box>
          </form>
        )}
      </Formik>
      <Box display="flex" justifyContent="center">
        <Typography color="#F52222" variant="body2">
          {error}
        </Typography>
      </Box>
      {isLoading && <Loader position="absolute" />}
    </Box>
  )
}

const FormField = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext<any>()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const handleChangeTextField = (value: string, name: string) => {
    setFieldValue(name, value)
  }

  const fieldHaveError = (field: string) => {
    return touched[field] && !!errors[field]
  }

  const getFieldHelperText = (field: string) => {
    return fieldHaveError(field) && errors[field]
  }

  return (
    <>
      <NameInput
        error={fieldHaveError('name')}
        helperText={getFieldHelperText('name')}
        name="name"
        placeholder="Alex Clare"
        value={values.name}
        label="Name"
        onChange={handleChangeTextField}
      />
      <Box mt={2}>
        <TextInput
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                }}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          error={fieldHaveError('password')}
          helperText={getFieldHelperText('password')}
          name="password"
          placeholder="********"
          value={values.password}
          type={showPassword ? 'text' : 'password'}
          onChange={handleChangeTextField}
          label="Password"
        />
      </Box>
      <Box mt={2}>
        <TextInput
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowConfirm(!showConfirm)}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                }}
                edge="end"
              >
                {showConfirm ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          error={fieldHaveError('confirm')}
          helperText={getFieldHelperText('confirm')}
          name="confirm"
          placeholder="********"
          value={values.confirm}
          type={showConfirm ? 'text' : 'password'}
          onChange={handleChangeTextField}
          label="Confirm password"
        />
      </Box>
    </>
  )
}

export default PersonalInfoForm
