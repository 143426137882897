import { BusinessType, UserRoles } from 'constants/profile'

import { Table, TableBody, TableContainer } from '@material-ui/core'
import { FieldArrayRenderProps, useFormikContext } from 'formik'
import { isNil } from 'lodash'
import React, { useEffect, useMemo } from 'react'

import { useLineItems } from '../../../../../../utils/api/lineItems'
import { useAuthData } from '../../../../../../utils/providers/AuthProvider'
import { GenerateInvoiceFormValues } from '../../GenerateInvoicesForm'

import { LineItemsTableHeader } from './LineItemsTableHeader'
import { LineItemsTableRow } from './LineItemsTableRow'

interface LineItemsTableProps {
  arrayHelpers: FieldArrayRenderProps
  shouldBlockRental: boolean
}

export const LineItemsTable: React.FC<LineItemsTableProps> = ({
  arrayHelpers,
  shouldBlockRental,
}) => {
  const { authUser } = useAuthData()
  const { values, validateField } =
    useFormikContext<GenerateInvoiceFormValues>()

  const lineItemParams = {
    id: authUser?.listing_id!,
    businessType: UserRoles.isLandlord(authUser?.role)
      ? BusinessType.Venue
      : BusinessType.Vendor,
  }
  const { data: lineItems } = useLineItems(lineItemParams, {
    enabled: !(isNil(authUser?.role) || isNil(authUser?.listing_id)),
  })

  useEffect(() => {
    validateField('lineItems')
  }, [])

  const lineItemsList = useMemo(() => {
    const lineItemsOptions = lineItems?.map((item) => ({
      value: item.id,
      label: item.name || '',
    }))
    return lineItemsOptions || []
  }, [lineItems?.length, values.lineItems?.length])

  return (
    <TableContainer style={{ width: '100%', overflowX: 'hidden' }}>
      <Table>
        <LineItemsTableHeader />
        <TableBody>
          {values.lineItems.map((lineItem, index) => (
            <LineItemsTableRow
              lineItemsList={lineItemsList}
              lineItem={lineItem}
              index={index}
              key={index}
              shouldBlockRental={shouldBlockRental}
              onDeleteLineItem={() => {
                arrayHelpers.remove(index)
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
