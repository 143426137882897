import { AxiosError } from 'axios'
import { values } from 'lodash'

import { emailRegex, passwordRegex, phoneRegExp } from '../../constants'

const getArrayOfErrors = (error: AxiosError) => {
  const errorsArray = error?.response?.data?.errors
    ? values(error?.response?.data?.errors)
    : values(error?.response?.data?.data)
  return !!errorsArray.length ? errorsArray : null
}

const getArrayOfSquareErrors = (error: AxiosError) => {
  return Array.isArray(error?.response?.data)
    ? error?.response?.data.map((err) => [err?.detail])
    : null
}

export const getRequestError = (error: AxiosError): string => {
  if (error.response?.status === 413) {
    return 'This file is too large. Please use files weighing less than 15 mb'
  }
  const errorsArray = getArrayOfErrors(error)
  const squareArray = getArrayOfSquareErrors(error)

  const serverErrors =
    squareArray ||
    errorsArray ||
    error.response?.data.error ||
    error.response?.data?.message
  if (!serverErrors) {
    return error.message
  }
  const errorsFromBackend: string | null = serverErrors
    ? Array.isArray(serverErrors)
      ? serverErrors[serverErrors.length - 1]?.[0]
      : serverErrors
    : null

  return errorsFromBackend || error.message
}

// union email and password validation to 1 method with 2 params?
export const validateEmail = (email: string) => {
  return emailRegex.test(String(email).toLowerCase())
}

export const validatePhone = (phone: string) => {
  return phoneRegExp.test(String(phone).toLowerCase())
}

export const validatePassword = (password: string) => {
  return passwordRegex.test(String(password).toLowerCase())
}
