import { filter, isArray, keys, pipe } from 'lodash/fp'

export type Param = null | number | boolean | string | string[]

const change =
  <TArgs extends any[] = []>(
    fn: (params: URLSearchParams, ...args: TArgs) => void
  ) =>
  (params: URLSearchParams, ...args: TArgs) => {
    const newParams = new URLSearchParams(params)
    fn(newParams, ...args)
    return newParams
  }

const updateParam = (params: URLSearchParams, key: string, value: Param) => {
  if (value === null || value === false) {
    params.delete(key)
  } else if (isArray(value) && value.length === 0) {
    params.delete(key)
  } else if (isArray(value)) {
    params.set(key, value.join(','))
  } else {
    params.set(key, value.toString())
  }
}

export const setParam = change(updateParam)

export const deleteParam = change((params: URLSearchParams, key: string) => {
  params.delete(key)
})

export const setParams = change(
  (params: URLSearchParams, newParams: Record<string, Param>) => {
    Object.entries(newParams).forEach(([key, value]) => {
      updateParam(params, key, value)
    })
  }
)

export const deleteParams = change(
  (params: URLSearchParams, keys: string[]) => {
    keys.forEach((key) => {
      params.delete(key)
    })
  }
)

export const validateParams = (
  params: URLSearchParams,
  validations: Record<string, (value: string) => boolean>
) => {
  const isValid = (key: string, value: string) => validations[key](value)

  return pipe(
    keys,
    filter((key: string) => params.has(key) && !isValid(key, params.get(key)!))
  )(validations)
}

export const setDefaultParams = change(
  (params: URLSearchParams, defaults: Record<string, Param>) => {
    Object.entries(defaults).forEach(([key, value]) => {
      if (!params.has(key)) {
        updateParam(params, key, value)
      }
    })
  }
)

export const toObject = (params: URLSearchParams) =>
  Object.fromEntries(params.entries())
