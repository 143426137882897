import { Box, Typography } from '@material-ui/core'
import React from 'react'

import { formatToCurrency } from '../../utils/helpers/price'

interface TotalDotProps {
  total: number
  bgColor: string
  title: string
}
export const TotalDot: React.FC<TotalDotProps> = ({
  total,
  bgColor,
  title,
}) => {
  return (
    <Box display="flex" flexDirection="column" my={2}>
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            borderRadius: '50%',
            backgroundColor: bgColor,
            width: '8px',
            height: '8px',
          }}
        />
        <Typography
          whiteSpace="nowrap"
          variant="body2"
          mx={2}
          fontSize={12}
          color="#9396A3"
        >
          {title}
        </Typography>
      </Box>

      <Typography variant="body2" color="#20202C" mt={2} fontWeight={600}>
        {formatToCurrency(Math.round(total), false)}
      </Typography>
    </Box>
  )
}
