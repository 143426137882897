import { Box, TableHead, TableRow, Typography } from '@material-ui/core'
import * as React from 'react'
import { Trans } from 'react-i18next'

import { StatusBox } from '../../../../../../components/ui/StatusBox'
import { InquiryInvoiceResponse } from '../../../../../../types/dtos/inquiry'
import { dateWithSeparator } from '../../../../../../utils/helpers/date'
import { formatToCurrency } from '../../../../../../utils/helpers/price'
import { t } from '../../../../../../utils/i18n'
import {
  StyledInvoiceHeaderFirstRowCell,
  StyledInvoiceHeaderSecondRowCell,
} from '../../InquiryTables.styles'

export const Header: React.FC<{
  invoicesBundle: InquiryInvoiceResponse
  total: number
}> = ({ invoicesBundle, total }) => {
  const generatedDate = dateWithSeparator(
    new Date(invoicesBundle.created_at),
    '/'
  )

  return (
    <TableHead>
      <TableRow>
        <StyledInvoiceHeaderFirstRowCell colSpan={5}>
          <Box display="flex" alignItems="center">
            <Trans i18nKey="business.invoices.table.generated">
              <Typography variant="body2" fontWeight={600}>
                Generated from {{ generatedDate }}
              </Typography>
            </Trans>
            <StatusBox
              ml="13px"
              borderColor={invoicesBundle.status_info.border_color}
              textColor={invoicesBundle.status_info.text_color}
              bgColor={invoicesBundle.status_info.bg_color}
            >
              {invoicesBundle.status_info.label}
            </StatusBox>
          </Box>
        </StyledInvoiceHeaderFirstRowCell>
        <StyledInvoiceHeaderFirstRowCell align="right" colSpan={2}>
          <Typography variant="body2">
            {t('business.invoices.table.total', 'Total')}{' '}
            <Typography
              component="span"
              fontWeight={600}
              color={(theme) => theme.palette.common.black}
            >
              {formatToCurrency(total)}
            </Typography>
          </Typography>
        </StyledInvoiceHeaderFirstRowCell>
      </TableRow>
      <TableRow>
        <StyledInvoiceHeaderSecondRowCell sx={{ width: '40px' }}>
          <Typography variant="body2">
            {t('business.invoices.table.number', '#')}
          </Typography>
        </StyledInvoiceHeaderSecondRowCell>
        <StyledInvoiceHeaderSecondRowCell>
          <Typography variant="body2">
            {t('business.invoices.table.invoiceId', 'Invoice ID')}
          </Typography>
        </StyledInvoiceHeaderSecondRowCell>
        <StyledInvoiceHeaderSecondRowCell>
          <Typography variant="body2">
            {t('business.invoices.table.dueDate', 'Due date')}
          </Typography>
        </StyledInvoiceHeaderSecondRowCell>
        <StyledInvoiceHeaderSecondRowCell align="center">
          <Typography variant="body2">
            {t('business.invoices.table.qty', '# of items')}
          </Typography>
        </StyledInvoiceHeaderSecondRowCell>
        <StyledInvoiceHeaderSecondRowCell align="right">
          <Typography variant="body2">
            {t('business.invoices.table.status', 'Status')}
          </Typography>
        </StyledInvoiceHeaderSecondRowCell>
        <StyledInvoiceHeaderSecondRowCell align="right">
          <Typography variant="body2">
            {t('business.invoices.table.amount', 'Amount')}
          </Typography>
        </StyledInvoiceHeaderSecondRowCell>
        <StyledInvoiceHeaderSecondRowCell align="right">
          <Typography variant="body2">
            {t('business.invoices.table.actions', 'Actions')}
          </Typography>
        </StyledInvoiceHeaderSecondRowCell>
      </TableRow>
    </TableHead>
  )
}
