import { Button, styled } from '@material-ui/core'

export const StyledButton = styled(Button)`
  height: 100%;

  border-radius: 0;
  box-shadow: none;

  display: flex;
  flex: 1;

  font-size: 18px;
  font-weight: 500;
  font-family: Poppins;
  text-transform: none;
`
