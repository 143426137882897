const EmptySearchIcon = () => {
  return (
    <svg
      width="334"
      height="334"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="159.943" cy="165.927" r="71.775" fill="#F1F1F1" />
      <circle
        cx="159.356"
        cy="166.05"
        r="41.919"
        fill="#F1F1F1"
        stroke="#9396A3"
        strokeWidth="2"
      />
      <circle
        cx="159.356"
        cy="166.05"
        r="29.657"
        fill="#FFFEFF"
        stroke="#9396A3"
        strokeWidth="2"
      />
      <path
        d="M159.356 143.511a.794.794 0 0 0-.815-.801 23.353 23.353 0 0 0-22.419 20.984.793.793 0 0 0 .745.866.84.84 0 0 0 .882-.758 21.731 21.731 0 0 1 6.763-13.613 21.717 21.717 0 0 1 14.029-5.847.84.84 0 0 0 .815-.831Z"
        fill="#9396A3"
      />
      <rect
        x="192.033"
        y="211.563"
        width="14"
        height="42"
        rx="3"
        transform="rotate(-45 192.033 211.563)"
        fill="#F1F1F1"
        stroke="#9396A3"
        strokeWidth="2"
      />
      <rect
        x="206.809"
        y="214.934"
        width="1.948"
        height="16.336"
        rx=".974"
        transform="rotate(-45 206.809 214.934)"
        fill="#fff"
      />
      <path
        fill="#9396A3"
        d="m190.611 201.656 1.414-1.414 5.657 5.657-1.414 1.414z"
      />
      <rect
        x="211.831"
        y="161.013"
        width="20"
        height="2"
        rx="1"
        fill="#9396A3"
      />
      <rect
        x="88.169"
        y="177.558"
        width="20"
        height="2"
        rx="1"
        fill="#9396A3"
      />
      <rect
        x="235.831"
        y="161.013"
        width="10"
        height="2"
        rx="1"
        fill="#9396A3"
      />
      <rect
        x="216.831"
        y="168.351"
        width="10"
        height="2"
        rx="1"
        fill="#9396A3"
      />
      <rect
        x="93.169"
        y="184.896"
        width="10"
        height="2"
        rx="1"
        fill="#9396A3"
      />
    </svg>
  )
}

export default EmptySearchIcon
