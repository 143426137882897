const Reminder = ({ fill = '#FF9500' }) => {
  return (
    <svg width="19" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.862 13.795h-1.354a.385.385 0 0 0-.326.18 7.52 7.52 0 0 1-3.417 2.907 7.427 7.427 0 0 1-2.907.585 7.434 7.434 0 0 1-5.28-2.187A7.433 7.433 0 0 1 2.391 10a7.434 7.434 0 0 1 2.187-5.28 7.434 7.434 0 0 1 5.28-2.187 7.435 7.435 0 0 1 5.28 2.187c.4.398.746.834 1.044 1.305.07.113.195.18.326.18h1.354a.214.214 0 0 0 .193-.31A9.166 9.166 0 0 0 9.969.838C4.904.775.697 4.92.687 9.982c-.009 5.07 4.1 9.182 9.17 9.182a9.168 9.168 0 0 0 8.198-5.057.215.215 0 0 0-.193-.312Z"
        fill={fill}
      />
    </svg>
  )
}

export default Reminder
