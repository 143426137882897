import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import House from '../../../../../components/svg/House'
import LinkText from '../../../../../components/ui/Buttons/LinkText/LinkText'
import { TooltipComponent } from '../../../../../components/ui/Tooltip'
import { IconContainer } from '../../Events.styles'

export const InHouseTooltip: React.FC<{
  toggleInHouseVendorModal?: () => void
}> = ({ toggleInHouseVendorModal }) => {
  const { t } = useTranslation()
  const inHouseTooltip = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="body2">
        {t('vendor.inhouses.name', 'In-house vendors')}
      </Typography>
      <LinkText
        style={{ fontSize: 14 }}
        variant="body2"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          toggleInHouseVendorModal && toggleInHouseVendorModal()
        }}
      >
        {t('common.buttons.more', 'Know more')}
      </LinkText>
    </Box>
  )
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <TooltipComponent
        sx={{ cursor: 'pointer' }}
        placement="top"
        title={inHouseTooltip}
      >
        <IconContainer
          style={{
            width: 24,
            height: 24,
            outlineOffset: '-2px',
            outline: '2px solid #FFE870',
          }}
        >
          <House scale={0.6} fill="#FFD25A" />
        </IconContainer>
      </TooltipComponent>
      <Typography
        variant="body2"
        align="center"
        style={{ marginLeft: '5px', color: '#597EF7', fontWeight: 500 }}
      >
        {t('events.services.inHouseNotification', 'In-house vendor')}
      </Typography>
    </Box>
  )
}
