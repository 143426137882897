import { css } from '@emotion/react'
import { styled } from '@material-ui/core'
import { AnchorHTMLAttributes, HTMLProps } from 'react'
import { Link } from 'react-router-dom'

const styles = css`
  text-decoration: none;
  letter-spacing: 0.5px;
  color: inherit;
  &:hover {
    opacity: 0.8;
  }
`

export const NavLink = styled(Link)(styles)
export const NavExternalLink = styled('a')(styles)
