import { Box, FormHelperText, styled, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MaskedInput from 'react-text-mask'

import { SimpleButton } from '../../../../../components/ui/Buttons/SimpleButton'
import { GoogleLocationSelect } from '../../../../../components/ui/Inputs/GoogleLocationSelect'
import { phoneNumberMask } from '../../../../../constants'
import { BusinessContactData } from '../../../../../types/general'
import { BlockTitle, ThinTextField } from '../../BusinessDetails.styles'

import { createValidationSchema } from './validationSchema'

const FieldWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;

  & > .MuiTypography-root {
    margin-bottom: 10px;
    color: #bdbdbd;
  }

  .phone-input {
    font-family: Poppins;
    padding: 8px 16px;
    display: flex;
    flex: 1;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #d7d7d7;

    &.error {
      border: 1px solid #f16547;
    }
  }
`

export const BusinessContactForm: React.FC<{
  data: BusinessContactData
  onSave: (values: BusinessContactData) => void
  onChangeMode: () => void
}> = ({ onSave, data, onChangeMode }) => {
  const handleFormSubmit = async (values: BusinessContactData) => {
    try {
      // ACTION
      const replacedPhoneNumber = values.phone.replace(/\D+/g, '')
      await onSave({ ...values, phone: replacedPhoneNumber })
      onChangeMode()
    } catch (e) {
      console.error(e)
    }
  }

  const {
    handleSubmit,
    handleBlur,
    values,
    setFieldValue,
    handleChange,
    errors,
    touched,
  } = useFormik<BusinessContactData>({
    initialValues: {
      name: data.name || '',
      phone: data.phone || '',
      email: data.email || '',
      address: data.address || '',
      website: data.website || '',
      // facebook: data.facebook || '',
      instagram: data.instagram || '',
    },
    validationSchema: createValidationSchema(),
    onSubmit: handleFormSubmit,
  })

  const getTextFieldErrors = (name: keyof BusinessContactData) => {
    return {
      error: touched[name] && !!errors[name],
      helperText: (touched[name] && errors[name]) || '',
    }
  }

  const phoneErrors = getTextFieldErrors('phone')
  const { t } = useTranslation()

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <BlockTitle>Business Contacts</BlockTitle>
        <Box display="flex">
          <Box
            display="flex"
            alignItems="center"
            mr={3}
            sx={{
              cursor: 'pointer',
              color: '#9396A3',
              '&:hover': { opacity: 0.8 },
            }}
            onClick={onChangeMode}
          >
            {t('common.buttons.cancel', 'Cancel')}{' '}
            <ClearIcon style={{ marginLeft: 10 }} />
          </Box>
          <SimpleButton
            style={{ fontSize: 16 }}
            onClick={() => handleSubmit()}
            variant="outlined"
          >
            {t('common.buttons.save', 'Save')}
          </SimpleButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <FieldWrapper>
          <Typography textAlign="left" variant="body2">
            {t('business.contact.name', 'Contact Name')}
          </Typography>
          <ThinTextField
            name="name"
            fullWidth
            value={values.name}
            onChange={handleChange}
            placeholder="Alex Clave"
            {...getTextFieldErrors('name')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Typography textAlign="left" variant="body2">
            {t('business.contact.phone', 'Contact Phone')}
          </Typography>
          <Box display="flex" flexDirection="column" width={1}>
            <MaskedInput
              onBlur={handleBlur}
              className={`phone-input ${phoneErrors.error ? 'error' : ''}`}
              name="phone"
              value={values.phone}
              onChange={handleChange}
              placeholder={'Enter Mobile number'}
              mask={phoneNumberMask}
              id="phoneNumber"
            />
            {phoneErrors.helperText && (
              <FormHelperText sx={{ ml: 3, mr: 3 }} error={true}>
                {phoneErrors.helperText}
              </FormHelperText>
            )}
          </Box>
        </FieldWrapper>
        <FieldWrapper>
          <Typography textAlign="left" variant="body2">
            {t('business.contact.mail', 'Contact Mail')}
          </Typography>
          <ThinTextField
            name="email"
            fullWidth
            value={values.email}
            onChange={handleChange}
            placeholder="alex@gmail.com"
            {...getTextFieldErrors('email')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Typography textAlign="left" variant="body2">
            {t('business.contact.address', 'Contact Address')}
          </Typography>
          <GoogleLocationSelect
            FieldComponent={ThinTextField}
            withAdornment={false}
            {...getTextFieldErrors('address')}
            value={values.address}
            onChange={(place) => setFieldValue('address', place)}
            name="address"
            placeholder="231 Rose str, NY"
          />
        </FieldWrapper>
        <FieldWrapper>
          <Typography textAlign="left" variant="body2">
            {t('business.contact.site', 'Business Site')}
          </Typography>
          <ThinTextField
            name="website"
            fullWidth
            value={values.website}
            onChange={handleChange}
            placeholder="https://www.example.com"
            {...getTextFieldErrors('website')}
          />
        </FieldWrapper>
        {/*<FieldWrapper>*/}
        {/*  <Typography textAlign="left" variant="body2">*/}
        {/*    {t('business.contact.facebook', 'Facebook link')}*/}
        {/*  </Typography>*/}
        {/*  <ThinTextField name="facebook" fullWidth value={values.facebook} onChange={handleChange} placeholder="https://www.facebook.com" {...getTextFieldErrors('facebook')} />*/}
        {/*</FieldWrapper>*/}
        <FieldWrapper>
          <Typography textAlign="left" variant="body2">
            {t('business.contact.instagram', 'Instagram link')}
          </Typography>
          <ThinTextField
            name="instagram"
            fullWidth
            value={values.instagram}
            onChange={handleChange}
            placeholder="https://www.instagram.com/"
            {...getTextFieldErrors('instagram')}
          />
        </FieldWrapper>
      </Box>
    </>
  )
}
