import { IconButton, styled, TableRow } from '@material-ui/core'

export const StyledTableRow = styled(TableRow)(
  ({ theme }) => `
  border: 1px solid rgba(226, 226, 226, 1);
  background-color: ${theme.palette.common.white};
    .MuiTableCell-root {
        border-bottom: none;
        padding: 10px 15px;
    }
`
)

export const StyledIconButton = styled(IconButton)`
  background-color: #f7f7f8;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;

  &:hover {
    opacity: 0.95;
  }
`

export const StyledHelperRow = styled(TableRow)`
  &.show-after ~ * {
    visibility: visible;
  }
  &.hide-after ~ tr {
    visibility: hidden;
    border: none !important;
  }
`
