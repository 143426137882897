const Square = ({ fill = '#9396A3' }) => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.344 1.375H1.875a.5.5 0 0 0-.5.5v3.5c0 .138.113.25.25.25h.75a.25.25 0 0 0 .25-.25v-2.75h2.719a.25.25 0 0 0 .25-.25v-.75a.25.25 0 0 0-.25-.25Zm9.031 9h-.75a.25.25 0 0 0-.25.25v2.75h-2.719a.25.25 0 0 0-.25.25v.75c0 .137.113.25.25.25h3.469a.5.5 0 0 0 .5-.5v-3.5a.25.25 0 0 0-.25-.25Zm-9.031 3H2.625v-2.75a.25.25 0 0 0-.25-.25h-.75a.25.25 0 0 0-.25.25v3.5a.5.5 0 0 0 .5.5h3.469a.25.25 0 0 0 .25-.25v-.75a.25.25 0 0 0-.25-.25Zm8.781-12h-3.469a.25.25 0 0 0-.25.25v.75c0 .138.113.25.25.25h2.719v2.75c0 .138.113.25.25.25h.75a.25.25 0 0 0 .25-.25v-3.5a.5.5 0 0 0-.5-.5Z"
        fill={fill}
      />
    </svg>
  )
}

export default Square
