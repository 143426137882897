import { Box, styled, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'
import { generatePath } from 'react-router-dom'

import { BottomFixedActionBlock } from '../../../../../components/BottomFixedActionBlock'
import GradientButton from '../../../../../components/ui/Buttons/GradientButton'
import { Loader } from '../../../../../components/ui/Loader'
import { usePartner } from '../../../../../hooks/usePartner'
import { useVendorCategory } from '../../../../../hooks/useVendorCategory'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { EventVendor } from '../../../../../types/Event'
import { Vendor } from '../../../../../types/Vendor'
import { vendorsCategoryPath, vendorsPath } from '../../../../../utils/paths'
import { useMessageModalData } from '../../../../../utils/providers/MessageModalProvider'

interface VendorFooterProps {
  pickedVendors: EventVendor[]
  onRemoveVendor: (vendors: EventVendor) => void
  withActionButton?: boolean
  vendor?: Vendor | null
  onSelectVendor?: () => void
  category?: string
  showOrderPrice?: boolean
  shouldCreateAbsEvent?: boolean
}

const StyledBox = styled(Box)(
  ({ theme }) => `
  color: ${theme.palette.primary.light};
  cursor: pointer;
`
)

export const VendorsFooter: React.FC<VendorFooterProps> = ({
  showOrderPrice,
  pickedVendors,
  onRemoveVendor,
  withActionButton = false,
  vendor,
  onSelectVendor,
  category,
  shouldCreateAbsEvent,
}) => {
  const { showMessage } = useMessageModalData()
  const { t } = useTranslation()
  const history = useHistory()
  const { isAbs } = usePartner()

  const { isLoading, getVendorCategory } = useVendorCategory()

  const vendorCategoryName = getVendorCategory(vendor?.type || '')?.name

  const removeFromBasket = (vendorId: number) => {
    const removedVendor = pickedVendors.find(
      (vendor: EventVendor) => vendor.id === vendorId
    )
    if (removedVendor && removedVendor.exclusive) {
      showMessage({
        type: 'warning',
        title: t(
          'vendor.removeExclusiveMessage.title',
          'You can not remove an exclusive vendor'
        ),
        text: t(
          'vendor.removeExclusiveMessage.text',
          'This is exclusive vendor for your event. If you want to remove this vendor, please, contact with support.'
        ),
      })
    } else if (removedVendor) {
      onRemoveVendor(removedVendor)
    }
  }

  const isBasketContainVendor = !!pickedVendors.find((v) => v.id === vendor?.id)
  // @ts-ignore
  const shouldBackToEvent = history.location.state?.insideVenue

  const { isMobile } = useWindowSize()

  if (isLoading) return <Loader />

  return (
    <BottomFixedActionBlock
      height={isMobile && !showOrderPrice && !withActionButton ? 0 : 100}
    >
      <Box
        className="container"
        height={1}
        justifyContent="space-between"
        display="flex"
        position="relative"
      >
        {!isMobile && (category || shouldBackToEvent) && (
          <StyledBox
            sx={{ '&:hover': { opacity: 0.8 } }}
            display="flex"
            alignItems="center"
            onClick={() =>
              category
                ? history.push(
                    generatePath(vendorsCategoryPath, {
                      category: getVendorCategory(vendor?.type || '')?.id,
                    })
                  )
                : shouldBackToEvent
                ? history.goBack()
                : history.push(vendorsPath)
            }
          >
            <ArrowBack />
            <Typography sx={{ marginLeft: '5px' }}>
              {category ? (
                <Trans i18nKey="vendor.backToCategory">
                  Back to {{ vendorCategoryName }}
                </Trans>
              ) : (
                t('vendor.backToEvent', 'Back to Event')
              )}
            </Typography>
          </StyledBox>
        )}
        <Box
          display="flex"
          flex={isMobile ? 1 : '0 0 auto'}
          alignItems="center"
          justifyContent={isMobile ? 'space-between' : 'flex-start'}
        >
          {showOrderPrice && (
            <Typography
              variant="body1"
              mr={withActionButton && vendor ? (isMobile ? '16px' : '44px') : 0}
              color=""
              width={isMobile ? '50%' : 'unset'}
            >
              {vendor?.orderPrice}
              <Typography component="span" color="#D7D7D7">
                {'$$$$'.slice(vendor?.orderPrice.length)}
              </Typography>{' '}
              <Typography component="span" color="#9396A3">
                /{t('vendor.priceRange', 'price range')}
              </Typography>
            </Typography>
          )}
          {withActionButton && vendor && (
            <GradientButton
              style={{
                width: isMobile ? (!showOrderPrice ? '100%' : '50%') : 'unset',
              }}
              onClick={() =>
                !isBasketContainVendor
                  ? onSelectVendor && onSelectVendor()
                  : removeFromBasket(vendor.id)
              }
            >
              {!isBasketContainVendor
                ? isAbs && shouldCreateAbsEvent
                  ? t('events.createInquiry', 'Create Inquiry')
                  : isAbs
                  ? t(
                      'partners.abs.bookings.vendors.add',
                      '+ Add to my booking'
                    )
                  : t('vendor.addToMyEvent', '+ Add to my event')
                : isAbs
                ? t(
                    'partners.abs.bookings.vendors.remove',
                    'Remove from my booking'
                  )
                : t('vendor.removeFromMyEvent', 'Remove from my event')}
            </GradientButton>
          )}
        </Box>
      </Box>
    </BottomFixedActionBlock>
  )
}
