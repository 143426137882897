const BackIcon = () => {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity=".3" width="40" height="40" rx="2" fill="#20202C" />
      <path
        d="M23.875 14.03a.4.4 0 0 1-.074.458L18.375 20l5.426 5.512a.4.4 0 0 1 .074.457l-.751 1.526a.4.4 0 0 1-.644.104l-7.204-7.318a.4.4 0 0 1 0-.562l7.204-7.318a.4.4 0 0 1 .644.104l.75 1.526Z"
        fill="#fff"
      />
    </svg>
  )
}

export default BackIcon
