const Home = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1843 10.8363L11.5304 1.18943C11.4609 1.11979 11.3783 1.06453 11.2874 1.02683C11.1965 0.989132 11.0991 0.969727 11.0007 0.969727C10.9023 0.969727 10.8049 0.989132 10.714 1.02683C10.6231 1.06453 10.5405 1.11979 10.471 1.18943L0.81709 10.8363C0.53584 11.1176 0.376465 11.4996 0.376465 11.898C0.376465 12.7254 1.04912 13.398 1.87646 13.398H2.89365V20.2816C2.89365 20.6965 3.22881 21.0316 3.64365 21.0316H9.50068V15.7816H12.1257V21.0316H18.3577C18.7726 21.0316 19.1077 20.6965 19.1077 20.2816V13.398H20.1249C20.5233 13.398 20.9054 13.241 21.1866 12.9574C21.7702 12.3715 21.7702 11.4222 21.1843 10.8363Z"
        fill="#262626"
      />
    </svg>
  )
}

export default Home
