import { useSearchParams } from 'hooks/useSearchParams'
import { omit } from 'lodash/fp'
import { useRef, useState } from 'react'
import { Param, deleteParams, toObject } from 'utils/helpers/searchParams'
import { ZodObjectAny } from 'utils/helpers/zod'
import { z } from 'zod'

type ParamsSchema = ZodObjectAny<Record<string, Param>, Record<string, unknown>>

const parseParams = <Schema extends ParamsSchema>(
  schema: Schema,
  params: URLSearchParams
) => schema.parse(toObject(params))
const parseValue = <
  Schema extends ParamsSchema,
  K extends keyof z.infer<Schema>
>(
  schema: Schema,
  key: K,
  value: z.infer<Schema>[K]
) => schema.shape[key as string].parse(value)

export const useUrlFilters = <Schema extends ParamsSchema>(schema: Schema) => {
  type TOutput = z.infer<Schema>
  type TKey = keyof TOutput

  const { params, setParam, setParams, apply } = useSearchParams()
  const schemaKeys: TKey[] = schema.keyof().options
  const values: TOutput = parseParams(schema, params)

  const update = <K extends TKey>(key: K, value: TOutput[K]) => {
    setParam(key as string, parseValue(schema, key, value))
  }

  const updateMany = (values: Partial<TOutput>) => {
    setParams(schema.parse(values))
  }

  const clear = (keys: TKey[] = schemaKeys) =>
    apply(deleteParams(params, keys as string[]))

  return { values, update, updateMany, clear }
}

export const useAppliedFilters = <Schema extends ParamsSchema>(
  schema: Schema
) => {
  type TOutput = z.infer<Schema>
  type TKey = keyof TOutput

  const { params, setParams } = useSearchParams()

  const initialValues: TOutput = parseParams(schema, params)
  const schemaKeys: TKey[] = schema.keyof().options
  const [values, setValues] = useState(initialValues)

  const update = <K extends TKey>(key: K, value: TOutput[K]) => {
    setValues((values) => ({
      ...values,
      [key]: parseValue(schema, key, value),
    }))
  }

  const updateMany = (newValues: Partial<TOutput>) => {
    setValues((values) => ({ ...values, ...schema.parse(newValues) }))
  }

  const apply = () => setParams(values)
  const clear = (keys: TKey[] = schemaKeys) =>
    setValues(schema.parse(omit(keys, values)))
  const reset = () => setValues(initialValues)

  return { values, update, updateMany, apply, clear, reset }
}
