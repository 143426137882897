import { Box, Paper } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useRef, useState } from 'react'

import TopBottomArrow from '../../../svg/TopBottomArrow'
import { TextInput } from '../../FormComponents/TextInput'
import { PopoverFieldRefProps, PopperField } from '../../PopoverField'

import { TimePickerContainer } from './TimePicker.styles'

export const getDateAMPMParams = (date: Date) => {
  const dateHours = date.getHours() % 12
  const dateMinutes = date.getMinutes()
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
  const hoursNumber = dateHours ? dateHours : 12
  const hours = hoursNumber < 10 ? `0${hoursNumber}` : hoursNumber.toString()
  const minutes = dateMinutes < 10 ? `0${dateMinutes}` : dateMinutes.toString()

  return { hours, minutes, ampm }
}

const createDateFromTimeParts = (
  hours: string,
  minutes: string,
  ampm: string
) => {
  if (hours === '12') {
    hours = '0'
  }
  const dateHours = ampm === 'PM' ? parseInt(hours) + 12 : parseInt(hours)
  const dateMinutes = parseInt(minutes)
  const dateTime = new Date()
  dateTime.setHours(dateHours)
  dateTime.setMinutes(dateMinutes)

  return dateTime
}

export const TimePicker: React.FC<{
  time: Date | null
  disabled?: boolean
  onTimeChange: (date: Date) => void
}> = ({ time, disabled, onTimeChange }) => {
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [ampm, setAmpm] = useState('AM')
  const timePickerRef = useRef<PopoverFieldRefProps>(null)

  useEffect(() => {
    if (time) {
      const {
        hours: timeHours,
        minutes: timeMinutes,
        ampm: timeDayPart,
      } = getDateAMPMParams(time)
      setHours(timeHours)
      setMinutes(timeMinutes)
      setAmpm(timeDayPart)
    }
  }, [])

  useEffect(() => {
    updateParentTime()
  }, [ampm])

  const startDayPicked =
    ampm === 'AM' &&
    hours &&
    parseInt(hours) === 12 &&
    minutes &&
    parseInt(minutes) === 0

  const handleClose = () => {
    if (!!timePickerRef.current) {
      timePickerRef.current?.onClose()
    }
  }

  const updateParentTime = () => {
    if (hours && ampm) {
      const mins = minutes || '0'
      const dateTime = startDayPicked
        ? createDateFromTimeParts(hours, '1', ampm)
        : createDateFromTimeParts(hours, mins, ampm)
      onTimeChange(dateTime)
    }
  }

  const handleChangeHours = (value: string) => {
    const isValid = /^[1-9]$|^1[0-2]$/.test(String(value)) || !value
    if (isValid) {
      setHours(value)
    }
  }

  const handleChangeMinutes = (value: string) => {
    const isValid = /^[0-5]$|^[0-5][0-9]$/.test(String(value)) || !value
    if (isValid) {
      setMinutes(value)
    }
  }

  return (
    <TimePickerContainer display="flex" alignItems="center">
      <TextInput
        disabled={disabled}
        onBlur={updateParentTime}
        placeholder="12"
        value={hours}
        onChange={handleChangeHours}
      />
      <Box mr={2} ml={2} style={{ fontSize: 18 }}>
        :
      </Box>
      <TextInput
        disabled={disabled}
        onBlur={updateParentTime}
        value={minutes}
        onChange={handleChangeMinutes}
        placeholder="00"
      />
      <Box mr={2} ml={2} style={{ fontSize: 18 }}>
        :
      </Box>
      {disabled ? (
        <Box>
          <TextInput
            className="ampm gray"
            disabled
            value={ampm}
            onChange={setAmpm}
            placeholder="AM"
            endAdornment={<TopBottomArrow />}
          />
        </Box>
      ) : (
        <PopperField
          input={
            <TextInput
              className="ampm"
              disabled
              value={ampm}
              onChange={setAmpm}
              placeholder="AM"
              endAdornment={<TopBottomArrow />}
            />
          }
          placement="bottom"
          ref={timePickerRef}
          content={
            <Paper style={{ marginTop: 5 }}>
              <MenuItem
                onClick={() => {
                  setAmpm('AM')
                  handleClose()
                }}
              >
                <em>AM</em>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAmpm('PM')
                  handleClose()
                }}
              >
                <em>PM</em>
              </MenuItem>
            </Paper>
          }
        />
      )}
    </TimePickerContainer>
  )
}
