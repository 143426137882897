import { Box } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { PromotionItem } from '../../components/cards/PromotionItem'
import { Loader } from '../../components/ui/Loader'
import { PageTitle } from '../../components/ui/PageTitle'
import { PaginationComponent } from '../../components/ui/Pagination'
import { usePartner } from '../../hooks/usePartner'
import { useWindowSize } from '../../hooks/useWindowSize'
import { Vendor } from '../../types/Vendor'
import { useVendors } from '../../utils/api/vendors'
import { useVenues } from '../../utils/api/venues'
import { vendorsMapper } from '../../utils/mappers/vendors'
import { absAdvantagePath, vendorsPromotionsPath } from '../../utils/paths'

import { PromotionsSwitcher } from './components/PromotionsSwitcher'

const initialValues = { page: 1, perPage: 8 }

export const PromotionsList: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const { isAbs } = usePartner()

  const [queryParams, updateQueryParams] = useState(initialValues)
  const [lastPage, setLastPage] = useState(1)
  const [isQueryEnabled, setIsQueryEnabled] = useState(false)
  const pageNumber = queryParams.page

  const listingType = useMemo(() => {
    if (
      location.pathname === vendorsPromotionsPath ||
      location.pathname === absAdvantagePath
    )
      return 'vendor'
    else return 'venue'
  }, [location])

  const { data, isLoading } =
    listingType === 'vendor'
      ? useVendors(queryParams, { enabled: isQueryEnabled })
      : useVenues(queryParams, { enabled: isQueryEnabled })

  useEffect(() => {
    if (isAbs === undefined) return
    const values = isAbs
      ? { ...initialValues, has_advantages: true }
      : { ...initialValues, has_promotion: true }
    updateQueryParams(values)
    setIsQueryEnabled(true)
  }, [isAbs])

  useEffect(() => {
    if (data?.last_page && data.last_page !== lastPage) {
      setLastPage(data?.last_page)
    }
  }, [data?.last_page])

  const promotionsData = useMemo(() => {
    if (listingType === 'vendor')
      return data?.data?.map((vendor: Vendor) => vendorsMapper(vendor))
    else return data?.data
  }, [data, listingType])

  const onPaginationChange = (event: any, page: number) => {
    updateQueryParams({ ...queryParams, page })
    window.scrollTo(0, 0)
  }

  if (isLoading) return <Loader />

  return (
    <Box className="container">
      <Box
        display={isMobile ? 'block' : 'flex'}
        justifyContent="space-between"
        alignItems="center"
      >
        <PageTitle
          title={
            isAbs
              ? t('partners.abs.advantage.title', 'ABS Advantage')
              : t('promotions.offers', 'Special Offers')
          }
          captionText={
            isAbs
              ? t(
                  'partners.abs.advantage.subtitle',
                  'The ABS Advantage is a collection of exclusive discounts offered to ABS tenants from premier vendor partners'
                )
              : ''
          }
        />
        {!isAbs && <PromotionsSwitcher />}
      </Box>
      {promotionsData?.map((vendor: Vendor) => (
        <Box
          mt={isMobile ? '32px' : '54px'}
          pb={isMobile ? '32px' : 0}
          key={vendor.id}
        >
          <PromotionItem listing={vendor} listingType={listingType} />
        </Box>
      ))}
      <Box mt={5} flex={1} display="flex" justifyContent="center">
        <PaginationComponent
          page={pageNumber}
          size="large"
          onChange={onPaginationChange}
          count={lastPage}
          shape="rounded"
        />
      </Box>
    </Box>
  )
}
