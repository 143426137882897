import { Box, Typography } from '@material-ui/core'
import { groupBy, keys, values } from 'lodash'
import * as React from 'react'

import { JotFormSubmission } from '../../../../../types/Event'
import { BlockContainer } from '../../BookingDetails.styles'

export const JotFormSubmissions: React.FC<{
  jotForms: JotFormSubmission[]
}> = ({ jotForms }) => {
  const jotFormsGroupedById = values(groupBy(jotForms, 'id'))

  return !!jotFormsGroupedById?.length ? (
    <Box display="flex" width={1} mb={5} flexDirection="column">
      <BlockContainer display="flex" flexDirection="column">
        <Typography
          variant="h4"
          fontFamily="Poppins"
          fontSize={18}
          fontWeight={600}
          mb="20px"
        >
          Client Q&A
        </Typography>
        {jotFormsGroupedById.map(
          (jotFormGroup: JotFormSubmission[], i: number) => (
            <Box mb={3} key={i}>
              {jotFormGroup
                .filter((item) => {
                  if (
                    item.title !== 'blaceEnv' &&
                    item.title !== 'blaceEventId' &&
                    item.answer
                  ) {
                    return item
                  }
                })
                .sort((a, b) => Number(a.order) - Number(b.order))
                .map((jotForm) => (
                  <Box
                    key={jotForm.id}
                    width={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center" flex="0 0 47%">
                      <Box
                        minWidth="12px"
                        mr="16px"
                        minHeight="12px"
                        sx={{ backgroundColor: '#597EF7', borderRadius: '1px' }}
                      />
                      <Typography
                        sx={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        {jotForm.title}
                      </Typography>
                    </Box>
                    <Box display="flex" flex="0 0 47%">
                      <Box display="flex" flexDirection="column">
                        {typeof jotForm.answer !== 'string' ? (
                          keys(jotForm.answer).map((question, index) =>
                            !Number(question) && Number(question) !== 0 ? (
                              <Typography
                                key={index}
                                sx={{ marginRight: '20px' }}
                              >
                                {question}
                              </Typography>
                            ) : null
                          )
                        ) : (
                          <Typography color="#9396A3" fontSize={14}>
                            {jotForm.answer}
                          </Typography>
                        )}
                      </Box>
                      <Box display="flex" flexDirection="column">
                        {typeof jotForm.answer !== 'string' &&
                          values(jotForm.answer).map((answer, i) => (
                            <Typography color="#9396A3" fontSize={14} key={i}>
                              {answer}
                            </Typography>
                          ))}
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          )
        )}
      </BlockContainer>
    </Box>
  ) : null
}
