const PhoneOutlined = ({ fill = '#20202C' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1309 2.66211L13.0508 0.583984C12.7969 0.330078 12.457 0.1875 12.0977 0.1875C11.7383 0.1875 11.3984 0.328125 11.1445 0.583984L8.9043 2.82031C8.65039 3.07422 8.50781 3.41602 8.50781 3.77539C8.50781 4.13672 8.64844 4.47461 8.9043 4.73047L10.6543 6.48242C10.2556 7.4113 9.68282 8.25527 8.9668 8.96875C8.25 9.68945 7.41211 10.2578 6.48242 10.6602L4.73242 8.9082C4.47852 8.6543 4.13867 8.51172 3.7793 8.51172C3.60204 8.51106 3.42643 8.54579 3.26276 8.61387C3.0991 8.68196 2.95067 8.78202 2.82617 8.9082L0.583984 11.1445C0.330078 11.3984 0.1875 11.7402 0.1875 12.0996C0.1875 12.4609 0.328125 12.7988 0.583984 13.0547L2.66211 15.1328C3.0957 15.5664 3.69336 15.8145 4.30664 15.8145C4.43359 15.8145 4.55664 15.8047 4.68164 15.7832C7.26758 15.3574 9.83398 13.9805 11.9062 11.9102C13.9766 9.83594 15.3516 7.26953 15.7812 4.68164C15.9043 3.94727 15.6582 3.19141 15.1309 2.66211ZM14.3965 4.44922C14.0156 6.75195 12.7773 9.04883 10.9121 10.9141C9.04688 12.7793 6.75195 14.0176 4.44922 14.3984C4.16016 14.4473 3.86328 14.3496 3.65234 14.1406L1.61133 12.0996L3.77539 9.93359L6.11523 12.2773L6.13281 12.2949L6.55469 12.1387C7.83384 11.6684 8.99544 10.9255 9.95897 9.96168C10.9225 8.99781 11.6649 7.83596 12.1348 6.55664L12.291 6.13477L9.93164 3.77734L12.0957 1.61133L14.1367 3.65234C14.3477 3.86328 14.4453 4.16016 14.3965 4.44922Z"
        fill={fill}
      />
    </svg>
  )
}

export default PhoneOutlined
