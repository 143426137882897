import { Box, IconButton, InputAdornment, Typography } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Formik } from 'formik'
import React, { useState } from 'react'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { postPersonalInfo } from '../../../utils/api/profile'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { SimpleButton } from '../../ui/Buttons/SimpleButton'
import { TextInput } from '../../ui/FormComponents/TextInput'
import { Loader } from '../../ui/Loader'
import { resetPasswordValidationSchema } from '../authValidations'

interface ResetPasswordFormValues {
  password: string
  confirm: string
}

const initialFormData = {
  password: '',
  confirm: '',
}

const ResetPassword: React.FC<{ onComplete?: () => void }> = ({
  onComplete,
}) => {
  const { authUser } = useAuthData()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const { isMobile } = useWindowSize()

  const onSubmit = React.useCallback(
    async (values: ResetPasswordFormValues, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const response =
          authUser && (await postPersonalInfo({ password: values.password }))

        if (onComplete) {
          onComplete()
        }
      } catch (e) {
        console.error(e)
      } finally {
        setSubmitting(false)
      }
    },
    [authUser]
  )

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={5}>
        <Typography variant="h3">Reset password</Typography>
      </Box>
      <Box display="flex" mb={4} flexDirection="column">
        <Typography textAlign="left" variant="body2">
          Create new password, different from used
        </Typography>
      </Box>
      <Formik
        validationSchema={resetPasswordValidationSchema()}
        initialValues={initialFormData}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box
              display="flex"
              mb={3}
              flexDirection="column"
              minWidth={isMobile ? 'unset' : 450}
            >
              <Box display="flex" flexDirection="column" mb={5}>
                <TextInput
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          event.preventDefault()
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  name="password"
                  placeholder="********"
                  value={values.password}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(value) => setFieldValue('password', value)}
                  label="Password"
                />
                <Box mt={2}>
                  <TextInput
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirm(!showConfirm)}
                          onMouseDown={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            event.preventDefault()
                          }}
                          edge="end"
                        >
                          {showConfirm ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={Boolean(touched.confirm && errors.confirm)}
                    helperText={touched.confirm && errors.confirm}
                    name="confirm"
                    placeholder="********"
                    value={values.confirm}
                    type={showConfirm ? 'text' : 'password'}
                    onChange={(value) => setFieldValue('confirm', value)}
                    label="Confirm password"
                  />
                </Box>
              </Box>
              <SimpleButton type="submit" sx={{ mb: 1 }} variant="contained">
                Reset
              </SimpleButton>
            </Box>
            {isSubmitting && <Loader position="absolute" />}
          </form>
        )}
      </Formik>
    </Box>
  )
}

export default ResetPassword
