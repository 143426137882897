import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'

import LinkText from '../../ui/Buttons/LinkText/LinkText'
import { SimpleButton } from '../../ui/Buttons/SimpleButton'
import VerificationInput from '../../ui/Inputs/VerificationInput/VerificationInput'

const PhoneVerification: React.FC<{ onComplete?: () => void }> = ({
  onComplete,
}) => {
  const [code, setCode] = useState('')

  const handleVerificationCode = () => {
    if (onComplete) {
      onComplete()
    }
  }
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={6}>
        <Typography variant="h3">Verifying</Typography>
      </Box>
      <Box display="flex" mb={5} flexDirection="column">
        <Box mb={3}>
          <Typography
            color="#BDBDBD"
            gutterBottom
            textAlign="left"
            variant="body2"
          >
            Enter code from massage from your phone
          </Typography>
        </Box>
        <Box mb={3}>
          <VerificationInput value={code} onChange={setCode} />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize={12} variant="body2">
            Didn’t get code?
          </Typography>
          <LinkText fontSize={12}>Send code again</LinkText>
        </Box>
      </Box>
      <SimpleButton
        disabled={code.length < 6}
        onClick={handleVerificationCode}
        sx={{ mb: 5 }}
        variant="contained"
      >
        Verify
      </SimpleButton>
    </Box>
  )
}

export default PhoneVerification
