import { SVGProps } from 'react'

const People = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" width="16" height="16" fill="#20202C" {...props}>
      <path d="M12.878 10.936a4.713 4.713 0 0 0-1.35-.944 3.118 3.118 0 0 0 1.16-2.43 3.144 3.144 0 0 0-3.176-3.125 3.127 3.127 0 0 0-1.919 5.555 4.704 4.704 0 0 0-1.35.944 4.657 4.657 0 0 0-1.368 3.186.125.125 0 0 0 .125.128h.875A.124.124 0 0 0 6 14.13a3.532 3.532 0 0 1 1.042-2.399 3.543 3.543 0 0 1 2.52-1.043c.952 0 1.847.37 2.52 1.043a3.546 3.546 0 0 1 1.043 2.399c.001.067.057.12.125.12h.875a.125.125 0 0 0 .125-.128 4.647 4.647 0 0 0-1.372-3.186ZM9.562 9.563a1.983 1.983 0 0 1-1.414-.586 1.982 1.982 0 0 1-.586-1.435 2.003 2.003 0 0 1 1.98-1.98 2.011 2.011 0 0 1 1.422.572c.387.38.6.888.6 1.428 0 .535-.208 1.036-.586 1.415a1.99 1.99 0 0 1-1.416.585ZM5.648 7.974a4.007 4.007 0 0 1 .045-1.14.126.126 0 0 0-.07-.137 1.993 1.993 0 0 1-1.181-1.883 1.99 1.99 0 0 1 .567-1.337 1.99 1.99 0 0 1 1.456-.605 1.998 1.998 0 0 1 1.663.92.125.125 0 0 0 .145.05c.275-.096.566-.163.864-.195a.125.125 0 0 0 .098-.18A3.13 3.13 0 0 0 6.49 1.75c-1.733-.027-3.176 1.394-3.176 3.123 0 .982.451 1.857 1.159 2.43-.497.23-.955.547-1.352.944a4.647 4.647 0 0 0-1.371 3.187.125.125 0 0 0 .125.129h.876a.124.124 0 0 0 .125-.12 3.532 3.532 0 0 1 1.042-2.4 3.527 3.527 0 0 1 1.636-.932.125.125 0 0 0 .094-.136Z" />
    </svg>
  )
}

export default People
