import { Box, styled } from '@material-ui/core'
import { __, includes, negate } from 'lodash/fp'
import { excludeProps } from 'utils/styles/styled'

type ActionButtonsContainerProps = {
  isMobile: boolean
}

export const ActionButtonsContainer = styled(Box, {
  shouldForwardProp: excludeProps<ActionButtonsContainerProps>(['isMobile']),
})<ActionButtonsContainerProps>(
  (props) => `
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  position:  ${props.isMobile ? 'fixed' : 'absolute'};
  bottom: 0;
  right: 0;
  left: 0;
  height: 100px;
`
)

export const PartnerItemWrapper = styled(Box)`
  padding: 20px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f7f7f8;
  }
`
