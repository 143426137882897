import { rulesStrings } from '../../constants'
import { EventVenue } from '../../types/Event'
import { InquiryEvent } from '../../types/Inquiry'
import { HouseRules, VenueItem } from '../../types/Venue'

export const generateSpaceRules = (rules: HouseRules) => {
  const spaceRules: string[] = []
  if (rules) {
    Object.keys(rules).forEach((ruleName) => {
      const ruleValue = rules[ruleName as keyof HouseRules]
      if (typeof ruleValue === 'string' && rulesStrings[ruleName]) {
        spaceRules.unshift(`${rulesStrings[ruleName]}: ${ruleValue}`)
      }

      if (ruleValue === false && rulesStrings[ruleName]) {
        spaceRules.push(rulesStrings[ruleName])
      }
    })
  }

  return spaceRules
}

export const getAddressString = (address: string | undefined) => {
  if (!address) return ''

  const addressArray = address.split(',').filter((item) => item)
  return addressArray[1]
    ? `${addressArray[0]}, ${addressArray[1]}`
    : addressArray[0]
}

export const convertVenueToEventVenue = (venue: VenueItem): EventVenue => {
  return {
    name: venue?.name,
    id: venue?.id,
    address:
      venue?.address?.formatted_address ||
      venue?.location_address?.formatted_address,
    rating: venue?.rating,
    pricing: venue?.params?.minimum_starting_at,
    photo: venue?.images?.[0],
    primary_contact: venue?.primary_contact,
    unread_messages: 0,
    partner: venue?.partner,
  } as EventVenue
}

export const convertEventVenueToVenue = (venue: EventVenue): VenueItem => {
  return {
    name: venue.name,
    id: venue.id,
    address: { formatted_address: venue.address || '' },
    rating: venue.rating,
    params: { minimum_starting_at: venue.pricing },
    images: [venue.photo],
    primary_contact: venue.primary_contact,
    partner: venue.partner,
  } as VenueItem
}

export const convertInquiryToEventVenue = (
  event: InquiryEvent
): EventVenue | undefined => {
  const venueInquiry = event.inquiries_related?.find((inquiry) => inquiry.venue)
  if (venueInquiry && venueInquiry.venue) {
    return {
      name: venueInquiry.venue.name,
      inquiryId: venueInquiry.id,
      id: venueInquiry.venue.id,
      status: venueInquiry.status,
      address: getAddressString(
        venueInquiry.venue.address?.formatted_address ||
          venueInquiry.venue.location_address?.formatted_address
      ),
      city: venueInquiry.venue.city,
      rating: 4.9,
      pricing: venueInquiry.venue.params?.minimum_starting_at,
      displayPricing: venueInquiry.venue.params?.display_pricing,
      photo: venueInquiry.venue.images?.[0],
      contracts: venueInquiry.contracts,
      primary_contact: venueInquiry.primary_contact,
      client_owned: venueInquiry.venue.client_owned,
      rooms: venueInquiry.venue_rooms,
      unread_messages: venueInquiry.user_message_thread?.unread || 0,
      partner: venueInquiry.venue.partner || '',
      params: venueInquiry.venue.params || {},
      pricingDetails:
        venueInquiry.venue.payment_structure?.pricing_details || '',
      maxGuests: venueInquiry.venue.max_head_count,
    } as EventVenue
  }

  return undefined
}

export const getQueryFromMoreFilters = (moreFilterFormValues: any) => {
  const labels = []
  let ceiling_height_min = ''
  let ceiling_height_max = ''
  let sq_footage_min = ''
  let sq_footage_max = ''
  let order_price_min = []
  for (const k in moreFilterFormValues) {
    if (moreFilterFormValues[k] && k.includes('label_')) {
      labels.push(k.split('_')[1])
    }
    if (k === 'costLevel') {
      order_price_min = moreFilterFormValues[k]
    }
    if (moreFilterFormValues.ceiling_height === '<10ft') {
      ceiling_height_min = '0'
      ceiling_height_max = '9'
    }
    if (moreFilterFormValues.ceiling_height === '10ft-20ft') {
      ceiling_height_min = '10'
      ceiling_height_max = '19'
    }
    if (moreFilterFormValues.ceiling_height === '20ft-50ft') {
      ceiling_height_min = '20'
      ceiling_height_max = '49'
    }
    if (moreFilterFormValues.ceiling_height === '50ft+') {
      ceiling_height_min = '50'
      ceiling_height_max = ''
    }
    if (k === 'squareFeet') {
      sq_footage_min = moreFilterFormValues[k][0].toString()
      sq_footage_max =
        moreFilterFormValues[k][1] !== 100000
          ? moreFilterFormValues[k][1].toString()
          : ''
    }
  }
  return {
    labels,
    ceiling_height_min,
    ceiling_height_max,
    sq_footage_min,
    sq_footage_max,
    order_price_min,
  }
}

export const getInitialFormValues = (params: any) => {
  const formValues = {
    squareFeet:
      params.sq_footage_min && params.sq_footage_max
        ? [Number(params.sq_footage_min), Number(params.sq_footage_max)]
        : [0, 100000],
    ceiling_height: getInitialCeilingHeight(params),
  }
  const labels = params.labels ? params.labels.split(',') : []
  labels.forEach((label: string) => {
    // @ts-ignore
    formValues[`label_${label}`] = `label_${label}`
  })
  return formValues
}

const getInitialCeilingHeight = (params: any) => {
  if (params.ceiling_height_min === '0' && params.ceiling_height_max === '9')
    return '<10ft'
  if (params.ceiling_height_min === '10' && params.ceiling_height_max === '19')
    return '10ft-20ft'
  if (params.ceiling_height_min === '20' && params.ceiling_height_max === '49')
    return '20ft-50ft'
  if (params.ceiling_height_min === '50' && params.ceiling_height_max === '')
    return '50ft+'
  return ''
}

export const formatPricingTypes = (data?: any) => {
  const formatPricingTypes = []
  if (data) {
    for (const k in data) {
      formatPricingTypes.push({
        label: data[k],
        value: k,
      })
    }
  }
  return formatPricingTypes
}

export const CEILING_HEIGHT_RANGE_VARIANTS = [
  { value: '<10ft', label: '< 10 ft' },
  { value: '10ft-20ft', label: '10 ft - 20 ft' },
  { value: '20ft-50ft', label: '20 ft - 50 ft' },
]
