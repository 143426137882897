import * as yup from 'yup'

import { websiteRegExp } from '../../../../../../constants'

export const addOwnVenueValidationSchema = () =>
  yup.object().shape({
    sqFootage: yup.number().min(1).max(2147483647),
    office: yup.object().required('Venue address is required'),
    name: yup.string(),
  })
