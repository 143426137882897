import { Box, BoxProps, styled, TextFieldProps } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { excludeProps } from 'utils/styles/styled'

type StyledTextFieldProps = TextFieldProps & {
  isMobile?: boolean
}

export const StyledTextField = styled(TextField, {
  shouldForwardProp: excludeProps<StyledTextFieldProps>(['isMobile']),
})<StyledTextFieldProps>(
  (props) => `
  background-color: transparent;
  min-width: 100px;
  display: flex;
  align-content: baseline;

  .MuiSelect-select {
    padding: ${props.isMobile ? '9px' : '15px'};
    border-radius: 2px;
    background: #f5f5f8;
    padding-right: 40px !important;
    &:hover {
      opacity: 0.9;
      background-color: rgba(20, 20, 20, 0.16);
    }
  }

  label {
    padding: 15px;
  }

  .MuiTypography-root {
    margin-right: 10px;
  }

  .MuiSelect-icon {
    color: black;
    bottom: 10px;
    right: 10px;
    &.MuiSelect-iconOpen {
      color: white;
    }
  }

  div[aria-expanded='true'] {
    background: black;
    color: white;
    svg {
      fill: white;
    }
  }

  .light-expanded {
    min-width: 260px;
    .MuiSelect-icon {
      display: none;
    }
    div[aria-expanded='true'] {
      background: #f5f5f8;
      color: black;
      svg {
        fill: white;
      }
    }
  }

  .search {
    background: #f5f5f8;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    .MuiDivider-root {
      background: black;
      width: 1px;
      height: 35px;
      margin-right: 10px;
      margin-left: 5px;
    }
  }
`
)
