import { Box } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { INITIAL_SEARCH_PARAMS } from '../../constants'
import { usePartner } from '../../hooks/usePartner'
import { useUrlParams } from '../../hooks/useUrlParams'
import { useWindowSize } from '../../hooks/useWindowSize'
import { venueListQueryParams } from '../../utils/mappers/venueListQueryParamsMapper'
import { LikesDataProvider } from '../../utils/providers/LikesProvider'
import { VendorsCategories } from '../vendors/components/VendorsCategories/VendorsCategories'
import VenuesSlider from '../venues/components/VenuesSlider/VenuesSlider'

import { EventForm } from './components/EventForm/EventForm'

export const Home: React.FC = () => {
  const { urlParams } = useUrlParams()
  const { t } = useTranslation()

  const { isMobile } = useWindowSize()

  const [queryParams, updateQueryParams] = useState<venueListQueryParams>({
    ...INITIAL_SEARCH_PARAMS,
    ...urlParams,
  })

  const onSearchVenues = (name: string) => {
    updateQueryParams({ ...queryParams, name })
  }

  const { isAbs } = usePartner()

  return (
    <LikesDataProvider>
      <Box
        style={{
          width: '100%',
          marginBottom: isAbs ? 0 : isMobile ? '-160px' : '-180px',
        }}
      >
        <EventForm onSearchVenues={onSearchVenues} />
        <VenuesSlider
          nextNavButtonClass="exclusive_next_button"
          prevNavButtonClass="exclusive_prev_button"
          params={{ exclusive: true }}
          slideWidth={!isMobile ? 392 : 288}
          slideImageHeight={!isMobile ? 470 : 380}
          withoutEndFrame
          sliderTitle={t('home.venue.exclusive', 'Exclusive BLACE venues')}
        />
        <Box className={isMobile ? '' : 'container'}>
          <VenuesSlider
            params={{ orderBy: 'id', orderDirection: 'desc' }}
            slideWidth={288}
            slideImageHeight={380}
            slidesPerView={4}
            sliderTitle={t('home.venue.recently', 'Recently Added')}
            withoutEndFrame={!!isMobile}
          />
        </Box>
        <VendorsCategories />
      </Box>
    </LikesDataProvider>
  )
}
