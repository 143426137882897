import axios from 'axios'
import * as React from 'react'

import { Status } from '../types/general'
import { Vendor } from '../types/Vendor'
import { getVendorById } from '../utils/api/vendors'
import { vendorsMapper } from '../utils/mappers/vendors'

export function useVendorById(
  vendorId: string,
  params: any = {}
): [Status, Vendor | null, (vendor: Vendor) => void] {
  const [status, setStatus] = React.useState<Status>(Status.REQUEST)
  const [vendor, setVendor] = React.useState<Vendor | null>(null)
  const source = React.useMemo(() => axios.CancelToken.source(), [])

  React.useEffect(() => {
    if (vendorId) {
      setStatus(Status.REQUEST)

      getVendorById(vendorId, source.token, params)
        .then((fetchedVendor: Vendor) => {
          if (fetchedVendor) {
            setVendor(vendorsMapper(fetchedVendor))
          }
          setStatus(Status.SUCCESS)
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            setStatus(Status.ERROR)
          }
        })
    }
  }, [vendorId])

  return [status, vendor, setVendor]
}
