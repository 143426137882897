import { useQuery, useQueryClient } from 'react-query'

import { Vendor } from '../../types/Vendor'
import { clearObject } from '../helpers/data'
import { getFormData } from '../helpers/objectToFormData'
import { imagesApiPath } from '../paths'

import { get, postFormData } from './api'

async function fetchMediaLibrary(options: any = {}): Promise<Vendor> {
  const response = await get(imagesApiPath, { ...clearObject(options) })
  return response.data || []
}

export function useUserMedia(options: any = {}, queryOptions: any = {}) {
  const queryClient = useQueryClient()
  const key = ['userImages', ...Object.values(options)]
  const query = useQuery<any, Error>(
    key,
    () => fetchMediaLibrary(options),
    queryOptions
  )

  return { queryClient, key, ...query }
}

export function uploadImage(image: any) {
  const formData = getFormData({ image })

  return postFormData(imagesApiPath, formData)
}
