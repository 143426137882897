export const BUDGETS = [
  '0-5000',
  '1000-5000',
  '5000-20000',
  '20000-50000',
  '50000-100000',
  '100000+',
  'unknown',
] as const
export type Budget = typeof BUDGETS[number]

export const BUDGET_LABELS: { [key in Budget]: string } = Object.freeze({
  '0-5000': '$0-$5,000',
  '1000-5000': '$1,000-$5,000',
  '5000-20000': '$5,000-$20,000',
  '20000-50000': '$20,000-$50,000',
  '50000-100000': '$50,000-$100,000',
  '100000+': 'Over $100,000',
  unknown: "I don't know yet",
})
