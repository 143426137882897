export const VENDORS_SORTING_FIELDS = [
  { label: 'Name', value: 'name' },
  { label: 'Price', value: 'order_price_min' },
]

export const INITIAL_VENDORS_SEARCH_PARAMS = {
  city: null,
  page: 1,
  perPage: 8,
  order_price_min: null,
  orderBy: 'name',
  orderDirection: null,
  name: null,
  labels: null,
}

export const MAX_PRICE_VALUE = '$$$$'
