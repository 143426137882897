const UserIconFilled = ({ fill = '#9396A3' }) => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.407 16.346a11.44 11.44 0 0 0-16.814 0c-.165.183-.257.42-.26.667v4a1 1 0 0 0 1 .986h15.334a1 1 0 0 0 1-1v-4a1.007 1.007 0 0 0-.26-.653ZM12 11.333A4.667 4.667 0 1 0 12 2a4.667 4.667 0 0 0 0 9.333Z"
        fill={fill}
      />
    </svg>
  )
}

export default UserIconFilled
