import { Box, styled, Typography, TypographyProps } from '@material-ui/core'
import * as React from 'react'

interface LinkTextProps {
  onClick?: (e: React.MouseEvent) => void
  text?: string
  disabled?: boolean
}

const TypographyLink = styled('span')(
  ({ theme }) => `
  cursor: pointer;
  width: auto;
  font-weight: 500;
  color: ${theme.palette.primary.light};
  &:hover {
    opacity: 0.8;
  }
`
)

const BoxLink = styled(Box)(
  ({ theme }) => `
  cursor: pointer;
  width: auto;
  font-weight: 500;
  color: ${theme.palette.primary.light};
  &:hover {
    opacity: 0.8;
  }
`
)

export default function LinkText(props: LinkTextProps & TypographyProps) {
  const { text, onClick, disabled, children, ...componentProps } = props

  const onClickHandle = (e: React.MouseEvent) => {
    if (!disabled && onClick) {
      onClick(e)
    }
  }

  return children ? (
    <BoxLink
      onClick={onClickHandle}
      color={disabled ? '#9396a3' : undefined}
      {...componentProps}
    >
      {children}
    </BoxLink>
  ) : (
    <Typography
      component={TypographyLink}
      onClick={onClickHandle}
      color={disabled ? '#9396a3' : undefined}
      {...componentProps}
    >
      {text}
    </Typography>
  )
}
