import { Box, Typography } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import SquareLogo from '../../../../assets/images/square-logo.png'
import { TextField as FormikTextField } from '../../../../components/formik'
import EditIcon from '../../../../components/svg/EditIcon'
import Google from '../../../../components/svg/GoogleIcon'
import { PhoneInput } from '../../../../components/ui/Inputs/PhoneInput'
import { Loader } from '../../../../components/ui/Loader'
import { SimpleModal } from '../../../../components/ui/Modals/SimpleModal'
import { MAX_NAME_LENGTH, MIN_NAME_LENGTH } from '../../../../constants'
import {
  PERSONAL_INFO_FORM_VIEW_STATE,
  UserRoles,
} from '../../../../constants/profile'
import { usePartner } from '../../../../hooks/usePartner'
import { useSimpleModal } from '../../../../hooks/useSimpleModal'
import { useSquareActions } from '../../../../hooks/useSquareActions'
import { useUrlParams } from '../../../../hooks/useUrlParams'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { UpdateProfileRequest } from '../../../../types/dtos/profile'
import { EventVenue } from '../../../../types/Event'
import { connectTestSquareAccount } from '../../../../utils/api/payments'
import { disconnectGoogle } from '../../../../utils/api/profile'
import { getModalContent } from '../../../../utils/helpers/bookings'
import { isProductionServer } from '../../../../utils/helpers/envr'
import { getRequestError } from '../../../../utils/helpers/validations'
import { googleSignInPath, squareOauthUrl } from '../../../../utils/paths'
import { useAuthData } from '../../../../utils/providers/AuthProvider'
import { useMessageModalData } from '../../../../utils/providers/MessageModalProvider'
import { LsGetAuth } from '../../../../utils/storage'
import {
  FieldNameTypography,
  FormWrapper,
  ProfileDivider,
  StyledCancelTypography,
  StyledEditTypography,
  StyledGradientButton,
  TestAccountConnect,
} from '../../Profile.styles'
import { ActionButtons } from '../ui/ActionButtons'

// @ts-ignore
export const PersonalInfoForm: React.FC<{
  currentAbsVenue: EventVenue | null
  setCurrentAbsVenue?: Dispatch<SetStateAction<EventVenue | null>>
}> = ({ currentAbsVenue, setCurrentAbsVenue }) => {
  const { isMobile } = useWindowSize()
  const { urlParams } = useUrlParams()
  const { t } = useTranslation()
  const { isAbs } = usePartner()

  const { isLoading, isAuthorized, authUser } = useAuthData()
  const { connectBusinessAccountToUser, loading: squareLoading } =
    useSquareActions()
  const { showMessage } = useMessageModalData()
  const [customLoading, setLoading] = useState(false)
  const [formFieldsViewState, setFormFieldsViewState] = React.useState(
    PERSONAL_INFO_FORM_VIEW_STATE
  )
  const [squareTestLoading, setSquareTestLoading] = React.useState(false)
  const { values, setFieldValue, errors, isValid, handleSubmit } =
    useFormikContext<UpdateProfileRequest>()
  const modalControl = useSimpleModal()
  const { isOpen, toggleModal, modalData } = modalControl

  const loading =
    customLoading ||
    squareTestLoading ||
    isLoading ||
    !isAuthorized ||
    squareLoading

  useEffect(() => {
    if (!!authUser && urlParams.code && !UserRoles.isClient(authUser?.role)) {
      connectBusinessAccountToUser(urlParams.code)
    }
  }, [authUser?.id])

  const isExclusiveVenue = useMemo(() => {
    const venue =
      authUser?.venues && authUser?.venues.length
        ? authUser?.venues?.[0]
        : ({} as any)
    return venue?.is_exclusive
  }, [authUser])

  const squareConnect = () => {
    window.open(`${squareOauthUrl}?user_id=${authUser?.id}`, '_self')
  }

  const onGoogleConnect = () => {
    const token = LsGetAuth()
    window.open(googleSignInPath + '?token=' + token, '_self')
  }

  const onGoogleDisconnect = async () => {
    try {
      setLoading(true)
      await disconnectGoogle()
      window.location.reload()
    } catch (e) {
      showMessage({
        type: 'error',
        title: getRequestError(e),
      })
    } finally {
      setLoading(false)
    }
  }

  const squareConnectTestAccount = async () => {
    if (authUser?.id) {
      setSquareTestLoading(true)
      try {
        await connectTestSquareAccount(authUser?.id)
        window.location.reload()
      } catch (e) {
        showMessage({
          title: getRequestError(e),
          type: 'error',
        })
      } finally {
        setSquareTestLoading(false)
      }
    }
  }

  const onSwitchMode = (fieldName: string, isViewMode: boolean) => {
    setFormFieldsViewState({ ...formFieldsViewState, [fieldName]: isViewMode })
  }
  const valuesPrev = React.useMemo(() => {
    return values
  }, [formFieldsViewState])

  return (
    <FormWrapper>
      <Box
        display="flex"
        position="relative"
        justifyContent="space-between"
        my={5}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          width={isMobile ? '100%' : 'auto'}
        >
          <FieldNameTypography>
            {t('profile.info.name', 'User name')}
          </FieldNameTypography>
          {formFieldsViewState.name ? (
            <Typography className="input-box">{values.name}</Typography>
          ) : (
            <Field
              name="name"
              autoComplete="name"
              component={FormikTextField}
              className="input-box"
              inputProps={{
                minLength: MIN_NAME_LENGTH,
                maxLength: MAX_NAME_LENGTH,
              }}
            />
          )}
        </Box>
        <ActionButtons
          withSaveMode
          field="name"
          value={valuesPrev.name}
          setFieldValue={setFieldValue}
          className="action-btn"
          onSwitchMode={(isViewMode) => onSwitchMode('name', isViewMode)}
        />
      </Box>
      <ProfileDivider />
      <Box
        display="flex"
        position="relative"
        justifyContent="space-between"
        my={5}
      >
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
          <FieldNameTypography>
            {t('profile.info.email', 'Email address')}
          </FieldNameTypography>
          <Typography className="input-box">{authUser?.email}</Typography>
        </Box>
      </Box>
      <ProfileDivider />
      <Box
        display="flex"
        position="relative"
        justifyContent="space-between"
        my={5}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          width={isMobile ? '100%' : 'auto'}
        >
          <FieldNameTypography>
            {t('profile.info.phone', 'Phone number')}
          </FieldNameTypography>
          {formFieldsViewState.phone ? (
            <Typography className={values.phone ? 'input-box' : ''}>
              {values.phone}
            </Typography>
          ) : (
            <>
              <Box ml={isMobile ? 0 : 12} mt={isMobile ? '16px' : 0}>
                <PhoneInput
                  className={errors.phone ? 'input-box error' : 'input-box'}
                  name="phone"
                />
                <Box display="flex" mt={2} justifyContent="center">
                  <Typography color="#F52222" variant="body2">
                    {errors.phone}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <ActionButtons
          withSaveMode
          field="phone"
          value={valuesPrev.phone}
          setFieldValue={setFieldValue}
          className="action-btn"
          onSwitchMode={(isViewMode) => onSwitchMode('phone', isViewMode)}
        />
      </Box>
      <ProfileDivider />
      {authUser && !UserRoles.isClient(authUser?.role) && !isExclusiveVenue && (
        <>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            my={5}
          >
            <FieldNameTypography>
              {t('profile.info.payment', 'Payment system')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </FieldNameTypography>
            <Box ml={isMobile ? 0 : 12} width={1}>
              <Box
                display="flex"
                my={isMobile ? 5 : 0}
                alignItems="center"
                justifyContent="space-between"
              >
                <img alt="standing" width={100} src={SquareLogo} />
                <Box>
                  {authUser?.payment_info ? (
                    <Typography variant="body1" color="#34C759">
                      {t('profile.info.square.connected', 'Connected')}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      color="primary.main"
                      sx={{ '&:hover': { opacity: 0.8 } }}
                      onClick={squareConnect}
                    >
                      {t('profile.info.square.connect', 'Connect')}
                    </Typography>
                  )}
                </Box>
              </Box>
              {!authUser?.payment_info && !isProductionServer() && (
                <Box
                  display="flex"
                  my={isMobile ? 5 : 0}
                  justifyContent="flex-end"
                >
                  <TestAccountConnect
                    style={{
                      border: '1px dashed #4E4BEC',
                      padding: 10,
                      cursor: 'pointer',
                    }}
                    onClick={squareConnectTestAccount}
                  >
                    <StyledEditTypography>
                      Connect test account
                    </StyledEditTypography>
                  </TestAccountConnect>
                </Box>
              )}
            </Box>
          </Box>
          <ProfileDivider />
        </>
      )}
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        my={5}
      >
        <FieldNameTypography>
          {t('profile.info.networks', 'Social networks')}
        </FieldNameTypography>
        <Box ml={isMobile ? 0 : 12} width={1}>
          <Box
            display="flex"
            mt={isMobile ? 5 : 0}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="center"
            >
              <Google mr={2} />
              <Typography variant="body2">Google</Typography>
            </Box>
            <Box>
              {values.google ? (
                <StyledCancelTypography
                  onClick={onGoogleDisconnect}
                  sx={{ '&:hover': { opacity: 0.8 } }}
                >
                  {t('profile.info.google.disconnect', 'Disconnect')}
                </StyledCancelTypography>
              ) : (
                <StyledEditTypography
                  onClick={onGoogleConnect}
                  sx={{ '&:hover': { opacity: 0.8 }, cursor: 'pointer' }}
                >
                  {t('profile.info.google.connect', 'Connect')}
                </StyledEditTypography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {isAbs && UserRoles.isClient(authUser?.role) && (
        <>
          <ProfileDivider />
          <Box
            display="flex"
            position="relative"
            justifyContent="space-between"
            my={5}
          >
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              width={isMobile ? '100%' : 'auto'}
            >
              <FieldNameTypography>
                {t('partners.abs.profile.building', 'Building')}
              </FieldNameTypography>
              <Typography variant="body2" ml={isMobile ? 0 : 12}>
                {currentAbsVenue?.name || '-'}
              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{ '&:hover': { opacity: 0.8, cursor: 'pointer' } }}
              onClick={() => toggleModal({ name: 'selectAbsVenue' })}
            >
              <StyledEditTypography>Edit</StyledEditTypography>
              <EditIcon />
            </Box>
          </Box>
        </>
      )}
      <Box display="flex" justifyContent="center">
        <StyledGradientButton
          type="submit"
          onClick={() => handleSubmit()}
          disabled={!isValid || (isAbs ? !currentAbsVenue : false)}
        >
          {t('profile.info.confirm', 'Confirm')}
        </StyledGradientButton>
      </Box>
      {loading && <Loader position="absolute" />}
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl, {
          isAbs,
          shouldUpdateDefaultAbsVenue: true,
          shouldOpenCreateEventForm: false,
          setCurrentAbsVenue,
        })}
      </SimpleModal>
    </FormWrapper>
  )
}
