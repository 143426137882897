import { Typography } from '@material-ui/core'
import React from 'react'

import { usePartner } from '../../../hooks/usePartner'
import { usePromotion } from '../../../utils/api/admin'
import PromotionStar from '../../svg/PromotionStar'

import { StyledPromotionDescriptionBlock } from './PromotionItem.styles'

export const PromotionDescriptionBlock: React.FC<{
  promotionDescription: string
}> = ({ promotionDescription }) => {
  const { isAbs } = usePartner()
  const { data } = usePromotion({ enabled: !isAbs && isAbs !== undefined })

  if (!data?.special_offers_switcher && !isAbs) return <></>

  return (
    <StyledPromotionDescriptionBlock className="section-block">
      <PromotionStar />
      <Typography
        style={{ marginLeft: '16px', marginTop: '3px' }}
        dangerouslySetInnerHTML={{ __html: promotionDescription }}
      />
    </StyledPromotionDescriptionBlock>
  )
}
