import { Checkbox, FormControlLabel } from '@material-ui/core'
import { isNil } from 'lodash/fp'
import { useState } from 'react'
import { District, Neighborhood } from 'utils/api/locations'

import {
  Dropdown,
  DropdownTitle,
  Item,
  ItemText,
  SelectableItem,
} from './Dropdown'

export const useNeighborhoodsPopup = (props: {
  getDistrict: (slug: string) => District
}) => {
  const [districtSlug, setDistrictSlug] = useState<string | null>(null)

  const district = districtSlug ? props.getDistrict(districtSlug) : null
  const neighborhoods = district?.neighborhoods || []

  const handleDistrictHover = (slug: string) => setDistrictSlug(slug)
  const handleClose = () => setDistrictSlug(null)

  return {
    isOpen: !isNil(district),
    district,
    neighborhoods,
    onDistrictHover: handleDistrictHover,
    onClose: handleClose,
  }
}

type NeighborhoodsDropdownProps = {
  title: string
  options: Neighborhood[]
  values: Record<string, boolean>
  onToggle: (slug: string) => void
}

export const NeighborhoodsDropdown = ({
  title,
  options,
  values,
  onToggle,
}: NeighborhoodsDropdownProps) => {
  return (
    <Dropdown>
      <>
        <Item>
          <ItemText>
            <DropdownTitle>{title}</DropdownTitle>
          </ItemText>
        </Item>
        {options.map((item) => (
          <SelectableItem
            key={item.slug}
            value={item.slug}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onToggle(item.slug)
            }}
          >
            <ItemText>
              <FormControlLabel
                control={
                  <Checkbox checked={values[item.slug]} name="neighborhood" />
                }
                label={item.name}
              />
            </ItemText>
          </SelectableItem>
        ))}
      </>
    </Dropdown>
  )
}
