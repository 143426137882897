import { styled, TableRow, Typography } from '@material-ui/core'
import * as React from 'react'

import GradientButton from '../Buttons/GradientButton'

export const StyledSeeAll = styled(Typography)(
  ({ theme }) => `
    padding-right: 70px;
    cursor: pointer;
`
)

export const StyledAdd = styled(Typography)(
  ({ theme }) => `
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`
)

export const StyledTableName = styled(Typography)(
  ({ theme }) => `
    font-weight: bold;
`
)

export const CellGradientButton = styled(GradientButton)`
  border-radius: 2px;
  width: 75px;
  height: 20px;
  font-size: 12px;
`

export const StyledTableRow = styled(TableRow)(
  ({ theme }) => `
    background-color: ${theme.palette.common.white};
    .MuiTableCell-root {
        border-bottom: none;
    }
`
)
