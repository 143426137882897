import { Box, BoxProps, styled, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { MEDIA_RULES } from 'hooks/useWindowSize'

import GradientButton from '../../../components/ui/Buttons/GradientButton'

export const EventDetailsWrapper = styled(Box)`
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  height: 100%;
  background-size: cover;

  .event-item-title {
    padding-bottom: 16px;
    border-bottom: 1px solid #d7d7d7;
  }

  .event-items-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
  }

  .event-wishlist-content,
  .event-details-content {
    min-height: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`

export const StyledBox = styled(Box)<{
  isMobile: boolean
  eventImage?: string
  eventImageWebp?: string
}>(
  (props) => `
  transform: ${props.isMobile ? 'none' : 'scale(1.1)'};
  filter: ${props.isMobile ? 'none' : 'blur(5px)'};
  position: ${props.isMobile ? 'relative' : 'absolute'};
  inset: 0;
  //background-image: url(${props.eventImage});
  background-image: image-set(url(${
    props.eventImageWebp
  }) type("image/webp"), url(${props.eventImage}) type("image/jpg"));
  background-position: center center;
  background-size: cover;
`
)

export const StyledDetailsBlock = styled(Box)(
  ({ theme }) => `
  color: #fff;
  padding: 20px 25px;
  width: 315px;
  flex-shrink: 0;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.75));
  backdrop-filter: blur(50px);
  height: calc(100vh - 100px);
  overflow: auto;
  
  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: #d7d7d7;
  }

  ${MEDIA_RULES.SMALL} {
    width: 100%;
    height: auto;
    background: #fff;
    backdrop-filter: unset;
    color: #000;
    opacity: 1;
    padding: 0;
    overflow: hidden;
  }

  .MuiSvgIcon-root {
    color: #9396a3;
  }

  .MuiLinearProgress-root {
    display: flex;
    width: 100%;
    flex: 1;
    background: rgba(147, 150, 163, 0.5);

    .MuiLinearProgress-bar {
      width: 100%;
      background-color: #2f54eb;
    }
  }
`
)

export const EventServicesWrapper = styled(Box)`
  width: 100%;

  ${MEDIA_RULES.SMALL} {
    margin-top: 0;
    margin-left: 0;
  }

  .title {
    text-transform: uppercase;
    color: #9396a3;
  }
`

export const EventServicesContainer = styled(Box)`
  background-color: #ffffff;
  opacity: 1;
  position: relative;
  height: 100%;

  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: #d7d7d7;
  }
`

export const ServicesTableWrapper = styled(Box)`
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: #d7d7d7;
  }
`

export const StyledAlert: any = styled(Alert)`
  align-items: center;
  position: relative;
  border-radius: 3px;

  ${MEDIA_RULES.SMALL} {
    flex-direction: column;
  }

  .MuiAlert-icon {
    padding: 0;
  }

  .MuiAlert-action {
    ${MEDIA_RULES.SMALL} {
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
`

export const StyledConciergeSupportTypography = styled(Typography)(
  ({ theme }) => `
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.primary.light};
  margin-right: 5px;
  &:hover {
    opacity: 0.8;
  }
`
)

export const StyledCategoryName = styled(Typography)<{
  isInHouseVendor: boolean
  isMobile: boolean
}>(
  ({ theme, isMobile, isInHouseVendor }) => `
  width: ${isInHouseVendor ? 245 : 'auto'}
  margin-right: ${isMobile ? '10px' : 0}
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
`
)

export const IconContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: linear-gradient(180deg, #4e4bec 0%, #3f91f0 100%), #4091f0;
`

export const TableContainerWrapper = styled(Box)<any>(
  ({ theme, bgColor }) => `
    background: ${bgColor};
    padding: 15px 20px 15px 20px;
    margin: 5px 20px;
    overflow-x: auto;

    ${MEDIA_RULES.SMALL} {
      padding: 0;
      margin: 0;
    }
`
)

type StatusButtonProps = BoxProps & {
  isFormFilled?: boolean
  isSubmitClicked: boolean
}

export const FormStatusBox = styled(Box)<StatusButtonProps>(
  (props) => `
  width: fit-content;
  position: relative;
  margin-right: 20px;
  background: rgba(47, 84, 235, 0.1);
  border: 1px solid rgba(47, 84, 235, 0.2);
  color: #2F54EB;
  cursor: pointer;
  padding: 0 8px;
  border-radius: 2px;
  display: flex;
  font-size: 12px;
  align-items: center;
  &:hover {
    background: #fff
  }
`
)

export const CategoryContainer = styled(Box)<any>(
  ({ theme }) => `
    padding: 15px;
    margin: 20px;
    display: flex;
    background-color: #fffff;
    border: 1px dashed #D7D7D7;
    border-radius: 4px;
    justify-content: space-between;

    ${MEDIA_RULES.SMALL} {
      margin: 20px 0;
    }
`
)

export const MessageButton = styled(GradientButton)`
  border-radius: 50%;
  width: 43px;
  height: 60px;
  padding: 0;

  z-index: 1000;

  ${MEDIA_RULES.SMALL} {
    width: 48px;
    height: 48px;
    min-width: unset;
  }

  .MuiButton-startIcon {
    margin: 0;
    padding: 0;
  }
`
