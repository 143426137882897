import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { NavLink } from '../../../NavLink'
import GradientButton from '../../../ui/Buttons/GradientButton'
import { StyledEmptyBox } from '../../ChatBox.styles'

export const WarningForGuests: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box px="24px" height="100%" onClick={(e) => e.stopPropagation()}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <StyledEmptyBox>
          <Trans i18nKey="chat.notAuthorize">
            <Typography
              variant="body2"
              color="#9396A3"
              sx={{ marginBottom: '10px' }}
            >
              You need to authorize
            </Typography>
            <Typography variant="body2" color="#9396A3">
              To continue messaging you need to{' '}
              <NavLink to={`?action=login`}>
                <Typography
                  component="span"
                  fontWeight={500}
                  variant="body1"
                  fontFamily="Acumin Pro"
                  color="primary.light"
                >
                  Log In
                </Typography>
              </NavLink>{' '}
              or{' '}
              <NavLink to={`?action=signup`}>
                <Typography
                  component="span"
                  fontWeight={500}
                  variant="body1"
                  fontFamily="Acumin Pro"
                  color="primary.light"
                >
                  Sign Up
                </Typography>
              </NavLink>
            </Typography>
          </Trans>
          <GradientButton
            style={{ marginTop: '15px' }}
            onClick={() => history.push(location.pathname + '?action=login')}
          >
            {t('auth.signUp.resendLink', 'Resend authorization link')}
          </GradientButton>
        </StyledEmptyBox>
      </Box>
    </Box>
  )
}
