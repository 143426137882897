export function clearObject(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, v]) => v !== null && v !== undefined && v !== ''
    )
  )
}

export function removeObjectFromArray<T extends Record<string, any>>(
  items: T[],
  property: string,
  value: string | number
) {
  return items.splice(
    items.findIndex(function (i: T) {
      return i[property] === value
    }),
    1
  )
}

export function removeElementFromArray(items: any[], index: number) {
  return items.splice(index, 1)
}

export const normilizeArrayByProp = <T extends Record<string, any>>(
  array: T[],
  property: string
) => {
  if (!array) return {}
  const normilizedObject: Record<string, T[]> = {}

  array.forEach((item) => {
    if (!normilizedObject[item[property]]) {
      normilizedObject[item[property]] = []
    }

    normilizedObject[item[property]].push(item)
  })

  return normilizedObject
}

export const getFirstElement = (data?: any[]): any => {
  if (!data) {
    return
  }

  return data[0]
}

export const getLastElement = (data?: any[]): any => {
  if (!data || !data.length) {
    return
  }

  return data[data.length - 1]
}
