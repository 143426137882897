const ListingIcon = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33366 0.695312C6.92563 0.695313 6.53432 0.8574 6.2458 1.14592C5.95728 1.43444 5.7952 1.82575 5.7952 2.23377V2.74659H2.20545C1.79743 2.74659 1.40611 2.90868 1.1176 3.1972C0.82908 3.48572 0.666992 3.87703 0.666992 4.28506L0.666992 5.70454L8.47622 7.78659C8.73563 7.85568 9.00861 7.85568 9.26802 7.78659L17.0772 5.70454V4.28506C17.0772 3.87703 16.9152 3.48572 16.6266 3.1972C16.3381 2.90868 15.9468 2.74659 15.5388 2.74659H11.949V2.23377C11.949 1.82575 11.787 1.43444 11.4984 1.14592C11.2099 0.8574 10.8186 0.695313 10.4106 0.695312H7.33366ZM7.33366 1.72095H10.4106C10.5466 1.72095 10.677 1.77498 10.7732 1.87116C10.8694 1.96733 10.9234 2.09777 10.9234 2.23377V2.74659H6.82084V2.23377C6.82084 2.09777 6.87487 1.96733 6.97104 1.87116C7.06721 1.77498 7.19765 1.72095 7.33366 1.72095Z"
        fill="#2F54EB"
      />
      <path
        d="M0.666992 12.4902C0.666992 12.8982 0.82908 13.2895 1.1176 13.578C1.40611 13.8666 1.79743 14.0286 2.20545 14.0286H15.5388C15.9468 14.0286 16.3381 13.8666 16.6266 13.578C16.9152 13.2895 17.0772 12.8982 17.0772 12.4902V6.69531L9.00443 8.84608C8.91774 8.86923 8.8265 8.86923 8.73981 8.84608L0.666992 6.69531V12.4902Z"
        fill="#2F54EB"
      />
    </svg>
  )
}

export default ListingIcon
