/** @jsxImportSource @emotion/react */
import { FilterDropdown } from 'components/Filters/FilterDropdown'
import {
  useAppliedLocationFilters,
  useLocationFilters,
  useLocationState,
} from 'hooks/useLocationState'
import { useSimpleModal } from 'hooks/useSimpleModal'
import { useWindowSize } from 'hooks/useWindowSize'
import { isNil } from 'lodash/fp'
import { forwardRef } from 'react'
import { LocationsQuery } from 'utils/api/locations'
import { usePrefetchedQuery } from 'utils/helpers/query'
import { wFull } from 'utils/styles'

import { LocationButton, SelectionBadge } from './LocationButton'
import { MobileVenueLocationFilter } from './Mobile/MobileVenueLocationFilter'
import { VenueLocationFilter } from './VenueLocationFilter'

const MobileController = () => {
  const locations = usePrefetchedQuery(LocationsQuery)
  const currentFilters = useLocationFilters(locations)
  const currentNeighborhods = currentFilters.values.neighborhood
  const currentCity = locations.entities.cities[currentFilters.values.city]

  const appliedFilters = useAppliedLocationFilters(locations)
  const state = useLocationState(locations, appliedFilters)

  const modal = useSimpleModal()
  if (isNil(state)) return null

  const onApply = () => {
    modal.toggleModal()
    appliedFilters.apply()
  }

  const onClose = () => {
    modal.toggleModal()
    appliedFilters.reset()
  }

  return (
    <>
      <SelectionBadge
        css={wFull}
        count={currentNeighborhods.length}
        aria-label="selected neighborhood count"
      >
        <LocationButton
          isOpen={modal.isOpen}
          label={currentCity.shortName}
          onClick={modal.toggleModal}
        />
      </SelectionBadge>

      <MobileVenueLocationFilter
        {...state}
        isOpen={modal.isOpen}
        onClose={onClose}
        onApply={onApply}
      />
    </>
  )
}

// eslint-disable-next-line react/display-name
const DesktopController = forwardRef((_props, ref) => {
  const locations = usePrefetchedQuery(LocationsQuery)
  const filters = useLocationFilters(locations)
  const currentNeighborhoods = filters.values.neighborhood
  const currentCity = locations.entities.cities[filters.values.city]
  const state = useLocationState(locations, filters)

  if (isNil(state)) return null

  return (
    <>
      <FilterDropdown
        renderTrigger={({ isOpen, toggleOpen }) => (
          <SelectionBadge
            count={currentNeighborhoods.length}
            aria-label="selected neighborhood count"
          >
            <LocationButton
              isOpen={isOpen}
              label={currentCity.shortName}
              onClick={toggleOpen}
            />
          </SelectionBadge>
        )}
        ref={ref}
      >
        <VenueLocationFilter ref={ref} {...state} />
      </FilterDropdown>
    </>
  )
})

export const VenueLocationFilterController = forwardRef((_props, ref) => {
  const { isMobile } = useWindowSize()
  const Component = isMobile ? MobileController : DesktopController
  return <Component ref={ref} />
})

VenueLocationFilterController.displayName = 'VenueLocationFilterController'
