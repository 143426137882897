import { Box, Popper, styled, Typography } from '@material-ui/core'

export const BlockContainer = styled(Box)`
  padding: 30px;
  box-shadow: 0 4px 18px rgba(210, 210, 210, 0.4);
`

export const StyledTitle = styled(Typography)`
  margin-right: 30px;
  margin-bottom: 30px;
`

export const StyledTooltipPopper = styled(Popper)`
  .MuiTooltip-tooltip {
    background-color: #20202c;
    border-radius: 2px;
    color: #d7d7d7;
    padding: 10px 15px;
    line-height: 114%;
    font-size: 14px;
  }
  .MuiTooltip-arrow {
    color: #20202c;
  }
`

export const NotesContainer = styled(Box)`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 25px;
  padding-top: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 16px;
  max-height: 150px;

  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: #d7d7d7;
  }
`

export const NoteBox = styled(Box)`
  padding: 16px;
  box-shadow: 4px 4px 31px rgba(0, 0, 0, 0.05);
`
