const MaxHeadcount = ({ fill = '#9396A3' }) => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.878 10.936a4.712 4.712 0 0 0-1.35-.943 3.118 3.118 0 0 0 1.16-2.43 3.144 3.144 0 0 0-3.175-3.125 3.127 3.127 0 0 0-1.92 5.555 4.703 4.703 0 0 0-1.35.943 4.657 4.657 0 0 0-1.368 3.186.125.125 0 0 0 .125.128h.875A.124.124 0 0 0 6 14.13a3.532 3.532 0 0 1 1.042-2.398 3.541 3.541 0 0 1 2.52-1.044c.952 0 1.847.37 2.52 1.044a3.546 3.546 0 0 1 1.043 2.398c.002.067.058.12.125.12h.875a.127.127 0 0 0 .09-.037.126.126 0 0 0 .035-.09 4.646 4.646 0 0 0-1.372-3.187ZM9.563 9.563a1.983 1.983 0 0 1-1.415-.586 1.982 1.982 0 0 1-.585-1.434 2.003 2.003 0 0 1 1.98-1.98 2.011 2.011 0 0 1 1.421.572c.388.38.6.887.6 1.428 0 .534-.208 1.036-.586 1.414a1.99 1.99 0 0 1-1.415.586ZM5.648 7.975a4.004 4.004 0 0 1 .046-1.139.126.126 0 0 0-.07-.137 1.993 1.993 0 0 1-1.181-1.883 1.99 1.99 0 0 1 .566-1.338 1.99 1.99 0 0 1 1.457-.604 1.998 1.998 0 0 1 1.662.919.125.125 0 0 0 .145.05c.275-.096.566-.163.865-.194a.125.125 0 0 0 .098-.181A3.13 3.13 0 0 0 6.491 1.75c-1.733-.026-3.177 1.394-3.177 3.124 0 .981.452 1.856 1.16 2.43-.497.23-.955.546-1.352.943a4.647 4.647 0 0 0-1.372 3.188.125.125 0 0 0 .125.128h.877a.124.124 0 0 0 .125-.12 3.532 3.532 0 0 1 1.042-2.399 3.527 3.527 0 0 1 1.636-.933.125.125 0 0 0 .093-.136Z"
        fill={fill}
      />
    </svg>
  )
}

export default MaxHeadcount
