export const Contract = ({ fill = '#9396A3' }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5918 12.0703C10.5773 12.0502 10.5583 12.0338 10.5362 12.0226C10.5141 12.0114 10.4896 12.0057 10.4648 12.0059H9.60156C9.47461 12.0059 9.40039 12.1504 9.47461 12.2539L10.916 14.248C10.9785 14.334 11.1055 14.334 11.168 14.248L13.3984 11.1621C13.4727 11.0586 13.3984 10.9141 13.2715 10.9141H12.4062C12.3555 10.9141 12.3086 10.9375 12.2793 10.9785L11.0391 12.6934L10.5918 12.0703ZM11.4375 4.97656V4.03906C11.4375 3.95312 11.3672 3.88281 11.2812 3.88281H3.78125C3.69531 3.88281 3.625 3.95312 3.625 4.03906V4.97656C3.625 5.0625 3.69531 5.13281 3.78125 5.13281H11.2812C11.3672 5.13281 11.4375 5.0625 11.4375 4.97656ZM3.78125 6.69531C3.69531 6.69531 3.625 6.76562 3.625 6.85156V7.78906C3.625 7.875 3.69531 7.94531 3.78125 7.94531H7.375C7.46094 7.94531 7.53125 7.875 7.53125 7.78906V6.85156C7.53125 6.76562 7.46094 6.69531 7.375 6.69531H3.78125ZM7.375 15.6406H2.0625V1.89062H13V7.67188C13 7.75781 13.0703 7.82812 13.1562 7.82812H14.25C14.3359 7.82812 14.4062 7.75781 14.4062 7.67188V1.10938C14.4062 0.763672 14.127 0.484375 13.7812 0.484375H1.28125C0.935547 0.484375 0.65625 0.763672 0.65625 1.10938V16.4219C0.65625 16.7676 0.935547 17.0469 1.28125 17.0469H7.375C7.46094 17.0469 7.53125 16.9766 7.53125 16.8906V15.7969C7.53125 15.7109 7.46094 15.6406 7.375 15.6406ZM15.2383 9.375L11.4883 8.07227C11.4707 8.06641 11.4551 8.06445 11.4375 8.06445C11.4199 8.06445 11.4023 8.06641 11.3867 8.07227L7.63672 9.375C7.60608 9.38541 7.57945 9.40511 7.56055 9.43137C7.54164 9.45763 7.5314 9.48913 7.53125 9.52148V14.4258C7.53125 14.4746 7.55273 14.5195 7.5918 14.5488L11.3418 17.4824C11.3691 17.5039 11.4043 17.5156 11.4375 17.5156C11.4707 17.5156 11.5059 17.5039 11.5332 17.4824L15.2832 14.5488C15.3203 14.5195 15.3438 14.4746 15.3438 14.4258V9.52148C15.3438 9.45508 15.3008 9.39648 15.2383 9.375ZM14.1328 13.916L11.4375 16.0234L8.74219 13.916V10.2695L11.4375 9.33203L14.1328 10.2695V13.916Z"
        fill={fill}
      />
    </svg>
  )
}
