import { Box, Checkbox, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const StyledTypography = styled(Typography)(
  ({ theme }) => `
    margin-top: 25px;
    text-transform: capitalize;
    line-height: 1.59;
    font-weight: 700;

    ${MEDIA_RULES.SMALL} {
      font-size: 24px;
    }
`
)

export const DashboardTypography = styled(Typography)(
  ({ theme }) => `
    margin-top: 15px;
    margin-right: 15px;
    font-size: 32px;
    font-weight:600;
    color: #0F123F;
    
    ${MEDIA_RULES.SMALL} {
      font-size: 24px;
    }
`
)

export const NotificationIndicator = styled(Box)`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #f16547;
  position: absolute;
  right: 3px;
  top: 5px;
  border: 1.5px solid #20202c;
`

export const StepCheckbox = styled(Checkbox)`
  padding: 4px 8px;

  &:hover {
    background-color: transparent;
  }

  &.Mui-disabled.Mui-checked {
    color: #2f54eb;
  }
`

export const CheckboxLabel = styled(Typography)`
  color: #2f54eb;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const StepCheckboxContainer = styled(Box)`
  background: #ffffff;
  flex: 1;
  padding-right: 15px;
  margin-left: 10px;
  margin-right: 20px;

  display: flex;
  align-items: center;
`
