import { Box, InputAdornment } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { AddCircleOutline, ExpandMoreOutlined } from '@material-ui/icons'
import React, { ChangeEvent, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { getDefaultEventName } from '../../../constants'
import { usePartner } from '../../../hooks/usePartner'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useUrlParams } from '../../../hooks/useUrlParams'
import { LocalEvent } from '../../../types/Event'
import { getModalContent } from '../../../utils/helpers/bookings'
import { dateWithSeparator } from '../../../utils/helpers/date'
import {
  eventPath,
  eventsListPath,
  marketplacePath,
  vendorsPath,
} from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import Calendar from '../../svg/Calendar'
import EventIcon from '../../svg/EventIcon'
import { Image } from '../../ui/Image'
import { SimpleModal } from '../../ui/Modals/SimpleModal'
import { PopoverFieldRefProps, PopperField } from '../../ui/PopoverField'
import {
  EventCardContent,
  EventCardWrapper,
  MyEventsInput,
  MyEventsTitle,
  NameField,
  NewEventCardWrapper,
  Pointer,
  PopoverWrapper,
} from '../Header.styles'

const EventCard: React.FC<{
  event: LocalEvent
  index: number
  cardImageClick: (event: LocalEvent) => void
}> = ({ event, cardImageClick }) => {
  const { active, name, start_date: startDate, venue } = event
  const { updateEventById } = useStorageEventsData()

  const [eventName, setName] = useState(name)
  const [editMode, setEditMode] = useState(false)
  const { isAbs } = usePartner()

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 40) {
      setName(e.target.value)
    }
  }

  const date = startDate && dateWithSeparator(startDate, '/')

  const onBlur = () => {
    updateEventById(
      {
        ...event,
        name: eventName || getDefaultEventName(!!isAbs),
        customName: '1',
      },
      event.id
    )
    setEditMode(false)
  }

  const cardClickEvent = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    cardImageClick(event)
  }

  return (
    <EventCardWrapper active={Boolean(active)} onClick={cardClickEvent}>
      <EventCardContent width={1}>
        <Box className="event-img">
          {venue?.photo?.path ? (
            <Image image={venue?.photo} imageSize="small" />
          ) : (
            <EventIcon />
          )}
        </Box>
        <Box
          display="flex"
          width={1}
          alignItems="center"
          style={{ marginLeft: 10 }}
        >
          <Box display="flex" width={1} flexDirection="column">
            {editMode ? (
              <NameField
                autoFocus
                value={eventName || getDefaultEventName(!!isAbs)}
                onFocus={(event) => {
                  event.stopPropagation()
                  event.target.select()
                }}
                onChange={onNameChange}
                variant={'outlined'}
                onBlur={onBlur}
              />
            ) : (
              <Box className="info">
                <Typography fontWeight={500} variant="subtitle1" color="black">
                  {eventName || getDefaultEventName(!!isAbs)}
                </Typography>
              </Box>
            )}
            <Typography variant="subtitle2">{date}</Typography>
          </Box>
        </Box>
      </EventCardContent>
    </EventCardWrapper>
  )
}

export default function EventPicker() {
  const {
    events: eventsList,
    createNewEvent,
    getUserActiveEvent,
    setActiveEvent,
  } = useStorageEventsData()
  const popoverRef = useRef<PopoverFieldRefProps>(null)
  const activeEvent = getUserActiveEvent()
  const { authUser } = useAuthData()

  const history = useHistory()
  const { isAbs } = usePartner()
  const { t } = useTranslation()
  const { urlParams } = useUrlParams()
  const modalControl = useSimpleModal()
  const { isOpen, toggleModal, modalData } = modalControl
  const [shouldPickerClose, setShouldPickerClose] = useState<boolean>(false)

  const createEventHandler = () => {
    setShouldPickerClose(true)

    if (isAbs && !authUser) history.push('?action=signup')
    else {
      createNewEvent()
      if (!isOpen) {
        if (isAbs) {
          history.push(vendorsPath)
        } else toggleModal({ name: 'selectVenueType' })
      }
    }
  }

  const pushToEvent = (event: LocalEvent) => {
    popoverRef.current?.onClose()

    if (isAbs) {
      if (!event.vendors?.length) {
        history.push(vendorsPath)
      } else
        history.push(
          generatePath(eventPath, {
            id: event.id,
          })
        )
    } else {
      if (!event.venue) {
        history.push(marketplacePath)
      } else {
        history.push(
          generatePath(eventPath, {
            id: event.id,
          })
        )
      }
    }
    setActiveEvent(event.id)
  }

  const events = useMemo(
    () => doEvents(eventsList, pushToEvent),
    [eventsList, pushToEvent]
  )
  const eventFromUrl = eventsList.find((item) => item.id == urlParams.id)
  const filteredEvents = useMemo(
    () => [
      events.find((event) => event.props.event.id === activeEvent?.id),
      ...events.filter((event) => event.props.event.id !== activeEvent?.id),
    ],
    [events, activeEvent]
  )

  const eventsInput = (
    <MyEventsInput
      className="my-event-picker"
      startAdornment={
        <InputAdornment position={'start'}>
          <Calendar />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position={'end'}>
          <ExpandMoreOutlined />
        </InputAdornment>
      }
      placeholder={
        eventFromUrl?.name || activeEvent?.name || getDefaultEventName(!!isAbs)
      }
      readOnly
    />
  )

  const popover = (
    <Box>
      <Pointer />
      <PopoverWrapper>
        <MyEventsTitle>
          <Typography variant="subtitle1" color="black" gutterBottom>
            {isAbs
              ? t('partners.abs.bookings.your', 'Your Bookings')
              : t('events.your', 'Your Events')}
          </Typography>
          <Typography textAlign="left" variant="body2" color="#9396A3">
            {isAbs
              ? t(
                  'partners.abs.bookings.choose',
                  'Choose a booking to work with'
                )
              : t('events.choose', 'Choose an event to work with')}
          </Typography>
        </MyEventsTitle>
        {filteredEvents}
        <NewEventCardWrapper mb={5} onClick={createEventHandler}>
          <Box mr={4}>
            <AddCircleOutline />
          </Box>
          <Typography>
            {isAbs
              ? t('partners.abs.bookings.create', 'Create new booking')
              : t('events.create', 'Create new event')}
          </Typography>
        </NewEventCardWrapper>
        <Typography
          className="my-events-link"
          variant="subtitle1"
          onClick={() => {
            popoverRef.current?.onClose()
            history.push(eventsListPath)
          }}
        >
          {isAbs
            ? t('partners.abs.bookings.toAllPage', 'Go to bookings page')
            : t('events.toAllPage', 'Go to events page')}
        </Typography>
      </PopoverWrapper>
    </Box>
  )

  return (
    <>
      <PopperField
        scrollable={false}
        ref={popoverRef}
        input={eventsInput}
        content={popover}
        shouldClose={shouldPickerClose}
        setShouldClose={setShouldPickerClose}
      />
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl, { isAbs })}
      </SimpleModal>
    </>
  )
}

const doEvents = (
  events: LocalEvent[],
  cardImageClick: (event: LocalEvent) => void
) => {
  return events.map((event, index) => (
    <EventCard
      key={index}
      index={index}
      event={event}
      cardImageClick={cardImageClick}
    />
  ))
}
