import { Box, BoxProps, styled, Typography } from '@material-ui/core'
import {
  AccessTime,
  CalendarTodayOutlined,
  EventAvailableOutlined,
  PeopleAltOutlined,
} from '@material-ui/icons'
import * as React from 'react'

import { useEventTypes } from '../../utils/api/events'
import {
  convertDateToShortString,
  convertDateToShortTimeString,
} from '../../utils/helpers/date'

const DetailsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  color: #9396a3;
`

const DetailsItem = styled(Box)`
  display: flex;
  align-items: center;
`

interface InitialEventData extends BoxProps {
  endDate?: string | Date
  startDate?: string | Date
  eventType?: string
  guests?: string | number | null
}

export const InitialEventData: React.FC<InitialEventData> = (props) => {
  const { endDate, startDate, eventType, guests } = props
  const start = startDate ? new Date(startDate) : ''
  const end = endDate ? new Date(endDate) : ''
  const getDates = () =>
    `${convertDateToShortString(start)} - ${convertDateToShortString(end)}`
  const getTime = () =>
    `${convertDateToShortTimeString(start)} - ${convertDateToShortTimeString(
      end
    )}`
  const { data: eventTypes } = useEventTypes()
  const eventTypeName = eventType
    ? Object.values({ ...eventTypes }).find((item) => item.slug === eventType)
        ?.name
    : ''
  return (
    <DetailsContainer {...props}>
      <DetailsItem>
        <Box display="flex" mr={3}>
          <CalendarTodayOutlined />
        </Box>
        <Typography variant="body2">{getDates()}</Typography>
      </DetailsItem>
      <DetailsItem>
        <Box display="flex" mr={3}>
          <EventAvailableOutlined />
        </Box>
        <Typography variant="body2">{eventTypeName}</Typography>
      </DetailsItem>
      <DetailsItem>
        <Box display="flex" mr={3}>
          <AccessTime />
        </Box>
        <Typography variant="body2">{getTime()}</Typography>
      </DetailsItem>
      <DetailsItem>
        <Box display="flex" mr={3}>
          <PeopleAltOutlined />
        </Box>
        <Typography variant="body2">{guests} Guests</Typography>
      </DetailsItem>
    </DetailsContainer>
  )
}
