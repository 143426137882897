import { Box } from '@material-ui/core'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentsEmptyState } from '../../../../../../components/Documents/DocumentsEmptyState'
import { EventServiceTab } from '../../../../../../constants/events'
import { useContractsByStatuses } from '../../../../../../hooks/useContractsByStatus'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { InquiryContractResponse } from '../../../../../../types/dtos/inquiry'
import { TabsTypography } from '../../../ServiceBookingDetails.styles'

import { ContractsTable } from './ContractsTable'

export const Contracts: React.FC<{
  contracts?: InquiryContractResponse[]
  setActiveTab: Dispatch<SetStateAction<EventServiceTab>>
  openMessengerHandler: () => void
  files?: any[]
}> = ({ contracts, openMessengerHandler, setActiveTab, files }) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const { completedContract } = useContractsByStatuses(contracts)

  return (
    <Box display="flex" flexDirection="column">
      {!isMobile && (
        <Box display="flex" alignItems="flex-start" mb={4}>
          <TabsTypography>
            {t('inquiry.contracts.title', 'Contract documents')}
          </TabsTypography>
        </Box>
      )}
      {completedContract ? (
        <ContractsTable files={files} contracts={contracts} />
      ) : (
        <DocumentsEmptyState
          title={t(
            'inquiry.contracts.emptyState',
            'Contracts will be displayed here'
          )}
        />
      )}
    </Box>
  )
}
