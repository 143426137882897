import { useFormikContext } from 'formik'
import React, { useMemo } from 'react'

import { SelectField } from '../../../../../../components/ui/FormComponents/SelectField'
import {
  venueDefaultDepositLineItemValue,
  venueDepositLineItemId,
} from '../../../../../../constants'
import { GenerateInvoiceFormValues } from '../../GenerateInvoicesForm'

import { LineItem, LineItemOption } from './types'

interface LineItemsSelectProps {
  lineItem: LineItem
  lineItemsList: LineItemOption[]
  index: number
  isVenueRentalDefault: boolean
}

export const LineItemsSelect: React.FC<LineItemsSelectProps> = ({
  lineItem,
  lineItemsList,
  isVenueRentalDefault,
  index,
}) => {
  const lineItemFieldLabel =
    lineItemsList.find((item) => item.value === lineItem.value)?.label ||
    lineItem.label
  const { values, setFieldValue } =
    useFormikContext<GenerateInvoiceFormValues>()

  const selectOptions = useMemo(() => {
    if (!lineItemsList?.length) return []
    return !!values.lineItems.find(
      (item) =>
        item.value === venueDefaultDepositLineItemValue ||
        +item.value === venueDepositLineItemId
    )
      ? [...lineItemsList].filter(
          (option) => +option.value !== venueDepositLineItemId
        )
      : [...lineItemsList]
  }, [values.lineItems, lineItemsList?.length])

  return (
    <SelectField
      lineItemFieldLabel={lineItemFieldLabel}
      ContainerProps={{ style: { margin: 0 } }}
      label=""
      options={selectOptions.sort((option1, option2) =>
        option1.label < option2.label ? -1 : 1
      )}
      value={`${lineItem.value}`}
      formValues={values.lineItems}
      placeholder="Choose one"
      onChange={(value) => {
        const currentLineItem = selectOptions.find(
          (item) => item.value === value
        )
        setFieldValue(`lineItems.${index}`, {
          ...lineItem,
          label: currentLineItem?.label || '',
          value,
        })
      }}
      disabled={isVenueRentalDefault}
      allowToUseSameOptionTwice={true}
    />
  )
}
