import { Box } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { TextInput } from '../../../components/ui/FormComponents/TextInput'
import { WizardStep } from '../../../components/Wizard'
import { OnboardingStep } from '../../../types/Auth'

import { businessDetailsValidationSchema } from './validations'

interface BusinessDetailsData {
  name: string
  website: string
  legal_entity: string
}

export const BusinessDetails: React.FC<{
  step: OnboardingStep
  isAuthUser: boolean
}> = ({ step, isAuthUser }) => {
  const { t } = useTranslation()

  const formik = useFormik<BusinessDetailsData>({
    initialValues: {
      name: '',
      website: '',
      legal_entity: '',
    },
    validationSchema: businessDetailsValidationSchema(),
    onSubmit: () => {},
  })

  const { values, setFieldValue, isValid, errors, touched, handleBlur, dirty } =
    formik

  const getTextFieldErrors = (name: keyof BusinessDetailsData) => {
    return {
      error: touched[name] && !!errors[name],
      helperText: touched[name] && errors[name],
    }
  }

  return (
    <WizardStep
      title={step.label}
      index={step.index}
      buttonLabel={isAuthUser ? 'Submit' : undefined}
      buttonDisabled={!dirty || !isValid}
      stepData={values}
    >
      <Box width="85%">
        <TextInput
          thirdlyMode
          onBlur={handleBlur}
          {...getTextFieldErrors('name')}
          name="name"
          placeholder={t('onboarding.venue.name.placeholder', 'Enter name')}
          value={values.name}
          onChange={(value) => setFieldValue('name', value)}
          label={t('onboarding.venue.name.label', '*Listing name')}
        />
        <Box mt={2}>
          <TextInput
            thirdlyMode
            onBlur={handleBlur}
            {...getTextFieldErrors('website')}
            name="website"
            placeholder={t(
              'onboarding.venue.website.placeholder',
              'https://example.com'
            )}
            value={values.website}
            onChange={(value) => setFieldValue('website', value)}
            label={t('onboarding.venue.website.label', '*Listing website')}
          />
        </Box>
        <Box mt={2}>
          <TextInput
            thirdlyMode
            onBlur={handleBlur}
            {...getTextFieldErrors('legal_entity')}
            name="legal_entity"
            placeholder={t(
              'onboarding.venue.entity.placeholder',
              'Enter legal entity'
            )}
            value={values.legal_entity}
            onChange={(value) => setFieldValue('legal_entity', value)}
            label={t('onboarding.venue.entity.label', 'Legal Entity - Name')}
          />
        </Box>
      </Box>
    </WizardStep>
  )
}
