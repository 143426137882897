import { Divider, MenuItem, MenuList, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

import { NavLink } from '../../NavLink'
import { SimpleButton } from '../Buttons/SimpleButton'

export const PopperContentWrapper = styled(MenuList)<{
  isMobile?: boolean
  isFullScreen?: boolean
  isPlacementTop?: boolean
}>(
  (props) => `
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 125px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: ${props.isMobile && props.isFullScreen ? '16px 0' : '16px'};
  margin-bottom: ${props.isPlacementTop ? '15px' : 0};
  margin-top: ${props.isPlacementTop ? 0 : '15px'};

  ${MEDIA_RULES.SMALL} {
    width: 100%;
  }
  .MuiAutocomplete-paper {
      li:hover {
        background-color: #f7f7f8;
      }
    }


  &.dark {
    background: #000014;
    color: #ffffff;
  }
  &.dark .secondary-item {
    //color: #9396a3;
    color: #ffffff;
  }

  .primary-blue {
    color: #2f54eb;
  }

  &.dark li:hover {
    background-color: rgba(247, 247, 248, 0.1);
  }
  
  .MuiMenuItem-root {
      padding: 6px 0;
      &>:first-of-type {
        padding: ${props.isMobile && props.isFullScreen ? 0 : '0 16px'};
      }
  }
`
)

export const Pointer = styled('div')`
  left: 35%;
  bottom: 0;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-bottom: 20px solid #fff;
  border-top: 0 solid transparent;
  transform: rotate(-135deg);
`

export const PopperArrow = styled('span')`
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(40px, 0px);
  margin-bottom: -0.71em;
  overflow: hidden;

  &:before {
    content: '';
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-origin: 100% 0;
  }
`

export const PointerTop = styled('div')<{ rightPointer: boolean }>(
  (props) => `
  left: ${props.rightPointer ? '58%' : '43%'};
  top: 0px;
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-bottom: 20px solid #fff;
  border-top: 0px solid transparent;
  transform: rotate(45deg);
`
)

export const StyledSimpleButton = styled(SimpleButton)`
  margin-top: 5px;
  padding: 5px 25px;
  border-color: #2f54eb;
  font-size: 16px;

  &:hover {
    background: transparent;
  }

  .MuiTouchRipple-root {
    display: none;
  }
`

export const StyledMenuItemWithLinkAction = styled(MenuItem)`
  color: #ffffff;
  display: flex;
  align-items: center;
  align-content: center;
`

export const StyledDivider = styled(Divider)`
  background: rgba(132, 132, 132, 0.12);
  margin-bottom: 10px;
`

export const StyledNavLink = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
`

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  align-content: center;
`
