import {
  Box,
  FormControlLabel,
  ListSubheader,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import { groupBy } from 'lodash'
import React, { ChangeEvent, forwardRef, ReactElement, useMemo } from 'react'

import CloseIcon from '../../../../components/svg/CloseIcon'
import {
  FooterContainer,
  ResetButtonContainer,
} from '../../../../components/ui/Inputs/DateRangePicker/DateRangePicker.styles'
import { Loader } from '../../../../components/ui/Loader'
import { PopperField } from '../../../../components/ui/PopoverField'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { EventType, EventTypesRecord } from '../../../../types/Event'
import { useEventTypes } from '../../../../utils/api/events'

import EventFormField from './EventFormField'

interface props {
  setValue: (value: string) => void
  reset: () => void
  value?: string
  onChange?: (field: string, value: string) => void
}

const RadioGroupWrapper = styled(RadioGroup)`
  background: white;
  border-radius: 0;
  display: flex;
  flex-direction: row;
`

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 10px;
  margin-bottom: 24px;
  border-left: 1px solid #d7d7d7;
`

const ColumnHeader = styled(ListSubheader)`
  text-transform: uppercase;
  text-align: center;
  background: white;
  min-height: 48px;
`

const StyledBox = styled(Box)`
  display: grid;
  gridtemplatecolumns: 1fr 1fr;
  ${MEDIA_RULES.SMALL} {
    display: block;
    & > .MuiFormControlLabel-root {
      width: 100%;
    }
  }
`

export const EventTypeSelect = forwardRef((props: props, ref) => {
  const { isLoading, data } = useEventTypes()
  const { isMobile } = useWindowSize()
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setValue(event.target.value as string)
    if (props.onChange) {
      props.onChange('eventType', event.target.value as string)
    }
  }

  const items = useMemo(() => doMenuItems(data), [data])
  const fieldValue = useMemo(
    () => getFieldValue(data, props.value),
    [data, props.value]
  )

  const renderItems = () => {
    if (!data) return null

    const keys = Object.keys(items)
    return keys.map((key: string, index: number) => (
      <Column key={key + 'column'}>{items[key]}</Column>
    ))
  }

  const popover = (
    <Paper style={{ background: 'white' }}>
      <RadioGroupWrapper
        aria-label="event-type"
        name="event"
        value={props.value}
        onChange={handleChange}
      >
        {renderItems()}
      </RadioGroupWrapper>
      <FooterContainer>
        <ResetButtonContainer onClick={() => props.reset()}>
          <CloseIcon />
          <Typography variant="caption" color="secondaryText">
            Reset Filters
          </Typography>
        </ResetButtonContainer>
      </FooterContainer>
    </Paper>
  )

  // TODO: need to escape of minWidth
  const input = (
    <EventFormField
      style={{ margin: isMobile ? 0 : 5, minWidth: isMobile ? 100 : 230 }}
      value={fieldValue}
      label="Event Type"
      placeholder="What are you planning?"
    />
  )

  return (
    <>
      {isLoading && <Loader size={30} height={4} />}
      {!isLoading && data && items && (
        <PopperField
          width={isMobile ? '100%' : 'unset'}
          ref={ref}
          placement="bottom-end"
          className={'event-type-select'}
          input={input}
          content={popover}
        />
      )}
    </>
  )
})

EventTypeSelect.displayName = 'EventTypeSelect'

const getFieldValue = function (
  data: EventTypesRecord | undefined,
  selectedValue: string | undefined
) {
  if (!data || !selectedValue) return ''

  return data[selectedValue]?.name || ''
}

const doMenuItems = function (data: EventTypesRecord | undefined) {
  const items: Record<string, ReactElement[]> = {}
  if (data) {
    const dataList = groupBy(Object.values(data), 'category')

    Object.keys(dataList).map((category) => {
      items[category] = []
      items[category].push(
        <ColumnHeader key={category + '_sub'}>{category}</ColumnHeader>
      )

      if (dataList[category].length >= 8) {
        const doubledList = dataList[category].map(
          (item: EventType, index: number) => {
            return (
              <FormControlLabel
                key={index}
                value={item.slug}
                control={<Radio color="primary" />}
                label={item.name}
              />
            )
          }
        )
        items[category].push(
          <StyledBox key={category + '_list'}>{doubledList}</StyledBox>
        )
      } else {
        dataList[category].map((item: EventType, index: number) => {
          items[category].push(
            <FormControlLabel
              key={index}
              value={item.slug}
              control={<Radio color="primary" />}
              label={item.name}
            />
          )
        })
      }
    })
  }

  return items
}
