const ShareAlt = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.625 15.563a2.8 2.8 0 0 0-1.767.625l-4.856-3.513a3.767 3.767 0 0 0 0-1.35l4.856-3.513a2.8 2.8 0 0 0 1.767.625 2.815 2.815 0 0 0 2.813-2.812 2.815 2.815 0 0 0-2.813-2.813 2.815 2.815 0 0 0-2.702 3.593l-4.613 3.34A3.749 3.749 0 0 0 3.562 12a3.749 3.749 0 0 0 6.748 2.255l4.613 3.34a2.815 2.815 0 0 0 2.702 3.593 2.815 2.815 0 0 0 2.813-2.813 2.815 2.815 0 0 0-2.813-2.813Zm0-11.157a1.22 1.22 0 1 1-.001 2.439 1.22 1.22 0 0 1 .001-2.439ZM7.312 14.063A2.066 2.066 0 0 1 5.25 12c0-1.137.926-2.063 2.063-2.063 1.136 0 2.062.926 2.062 2.063a2.066 2.066 0 0 1-2.063 2.063Zm10.313 5.53a1.22 1.22 0 1 1 .001-2.438 1.22 1.22 0 0 1-.001 2.439Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default ShareAlt
