import { t } from '../utils/i18n'

export enum EventStatus {
  STATUS_DRAFT = 'draft',
  STATUS_SEARCH = 'search',
  STATUS_DORMANT = 'dormant',
  STATUS_PLANNING = 'planning',
  STATUS_ABANDONED = 'abandoned',
  STATUS_PRODUCTION = 'production',
  STATUS_COMPLETED = 'completed',
  STATUS_CANCELED = 'canceled',
  STATUS_CANCELED_BY_ADMIN = 'canceled-by-admin',
  STATUS_POSTPROD = 'postprod',
  STATUS_CLOSED = 'closed',
}

export const INITIAL_EVENT_SEARCH_PARAMS = {
  fromDate: '',
  toDate: '',
  page: 1,
  orderBy: 'date',
  orderDirection: 'asc',
  name: '',
}

export const getCalendarUrl = (calendarGoogleId: string) =>
  `https://calendar.google.com/calendar/ical/${calendarGoogleId}/public/basic.ics`
export const CALENDAR_VIEW_BY = ['Month', 'Week', 'Day']

interface Column {
  id: string
  label: string
  minWidth?: number
  format?: (value: number) => string
}
export interface Tab {
  id: EventServiceTab
  label: string
}

export const getEventListTableColumns = (isAbs: boolean): readonly Column[] => {
  return [
    { id: 'cover', label: 'Cover' },
    {
      id: 'eventName',
      label: isAbs
        ? t('partners.abs.bookings.name', 'Booking name')
        : t('events.name', 'Event name'),
    },
    {
      id: 'eventType',
      label: isAbs
        ? t('partners.abs.bookings.type', 'Booking type')
        : t('events.type', 'Event type'),
    },
    {
      id: 'date',
      label: 'Date',
    },
    {
      id: 'time',
      label: 'Time',
    },
    {
      id: 'guests',
      label: 'Guests',
    },
    {
      id: 'totalAmount',
      label: 'Total Amount',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'renewal',
      label: 'Renewal',
    },
    {
      id: 'more',
      label: 'More',
    },
  ]
}

export enum EventServiceTab {
  BOOKING_INFO = 'info',
  CONTRACTS = 'contracts',
  PAYMENTS = 'invoices',
  ADDITIONAL_INFO = 'additional_info',
}

export const EVENT_SERVICE_TABS: Tab[] = [
  {
    id: EventServiceTab.BOOKING_INFO,
    label: 'Booking info',
  },
  {
    id: EventServiceTab.CONTRACTS,
    label: 'Contract',
  },
  {
    id: EventServiceTab.PAYMENTS,
    label: 'Price / Payments',
  },
]

export const VENDOR_SERVICE_TAB: Tab = {
  id: EventServiceTab.ADDITIONAL_INFO,
  label: 'Additional info',
}

export const getMenuActionsByStatus = (status: string, color: string) => {
  const actions = [
    { label: 'Details', value: 'details', color: color },
    { label: 'Delete', value: 'delete', color: color },
  ]
  if (status === EventStatus.STATUS_DRAFT)
    actions.unshift({ label: 'Edit', value: 'edit', color: color })

  return actions
}

export const RANGE_VARIANTS = [
  { value: '$5,000 - $14,999', label: '$5,000 - $14,999' },
  { value: '$15,000 - $29,999', label: '$15,000 - $29,999' },
  { value: '$30,000 - $49,999', label: '$30,000 - $49,999' },
  { value: '$50,000 - $74,999', label: '$50,000 - $74,999' },
  { value: '$75,000 - $149,999', label: '$75,000 - $149,999' },
  { value: '$150,000 - $499,999', label: '$150,000 - $499,999' },
  { value: '$500,000 - $999,999', label: '$500,000 - $999,999' },
  { value: '+$1,000,000', label: '+$1,000,000' },
]
