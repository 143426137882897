import { useMemo } from 'react'

import {
  InquiryContractResponse,
  InquiryInvoiceResponse,
} from '../types/dtos/inquiry'
import { InquiryWithDetails } from '../types/Inquiry'
import { useInquiryContractsById } from '../utils/api/inquiries'
import { getFirstElement } from '../utils/helpers/data'

interface inquiryFlowLatestElements {
  latestContract: InquiryContractResponse
  latestInvoicesBundle: InquiryInvoiceResponse
}

export function useLatestInquiryFlowElements(
  inquiryDetails?: InquiryWithDetails
): inquiryFlowLatestElements {
  const { data: contracts } = useInquiryContractsById(inquiryDetails?.id || 0, {
    enabled: !!inquiryDetails?.id,
  })

  const latestContract = useMemo(() => {
    if (!contracts?.length && !inquiryDetails?.contracts?.length) return
    return contracts?.length
      ? getFirstElement(contracts)
      : getFirstElement(inquiryDetails?.contracts)
  }, [inquiryDetails?.contracts, contracts])

  const latestInvoicesBundle = useMemo(() => {
    if (!inquiryDetails?.invoice_bundles?.length) return
    return getFirstElement(inquiryDetails?.invoice_bundles)
  }, [inquiryDetails?.invoice_bundles])

  return {
    latestContract,
    latestInvoicesBundle,
  }
}
