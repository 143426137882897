import { Box, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RawLineItem } from 'types/RawLineItem'
import { Vendor } from 'types/Vendor'
import { VenueDetails } from 'types/Venue'
import { canAddLineItems } from 'utils/helpers/permissions'

import { LineItemsQuery } from '../../../../../utils/api/lineItems'
import { BlockTitle } from '../../BusinessDetails.styles'

import { AddLineItem } from './components/AddLineItem'
import { LineItemRow } from './components/LineItemRow'
import { LineItemsHeader } from './components/LineItemsHeader'

interface LineItemsProps {
  businessDetails: VenueDetails | Vendor | null
  lineItems?: RawLineItem[]
  lineItemsType?: Record<string, string>
  lineItemsQuery: LineItemsQuery
}

export const LineItems: React.FC<LineItemsProps> = ({
  businessDetails,
  lineItems,
  lineItemsType,
  lineItemsQuery,
}) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={{ lineItems: [] }}
      onSubmit={() => {}}
      enableReinitialize
    >
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <BlockTitle>
            {t('business.lineItems.title', 'Business Line Items')}
          </BlockTitle>
        </Box>

        {businessDetails && canAddLineItems(businessDetails) && (
          <>
            <Typography
              textAlign="left"
              color="#9396A3"
              variant="body2"
              sx={{ mb: 2 }}
            >
              {t('business.lineItems.add', 'Add more line items')}
            </Typography>
            <AddLineItem
              lineItemTypes={lineItemsType}
              lineItemsQuery={lineItemsQuery}
            />
          </>
        )}

        <Box
          display="grid"
          gridTemplateColumns="50px 1fr 1fr"
          style={{ maxHeight: 400, overflowY: 'auto' }}
        >
          <LineItemsHeader />
          {lineItems?.map((lineItem, index) => (
            <LineItemRow lineItem={lineItem} index={index} key={lineItem.id} />
          ))}
        </Box>
      </>
    </Formik>
  )
}
