import { Box, Typography } from '@material-ui/core'
import { Field, Formik, FormikHelpers } from 'formik'
import { keys } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import MaskedInput from 'react-text-mask'
import { InquiryFormPostRequest } from 'types/Inquiry'

import { TermsAndConditions } from '../../../../../components/Auth/common/TermsAndConditions'
import {
  FastTextField,
  TextField as FormikTextField,
} from '../../../../../components/formik'
import { SimpleButton } from '../../../../../components/ui/Buttons/SimpleButton'
import { phoneNumberMask } from '../../../../../constants'
import { AuthMenuActions } from '../../../../../types/Auth'
import { LocalEvent } from '../../../../../types/Event'
import { Room } from '../../../../../types/Venue'
import { createInquiryForGuests } from '../../../../../utils/api/inquiries'
import {
  convertInquiryEventToUserEvent,
  createGuestInquiryMapper,
} from '../../../../../utils/mappers/inquiries'
import { messagesSuccessNotLoggedInquiryPath } from '../../../../../utils/paths'
import { useAuthData } from '../../../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../../../utils/providers/EventsProvider'
import {
  LsGetInquiryDetails,
  LsGetInquiryFormValues,
  LsRemoveInquiryDetails,
  LsRemoveInquiryFormValues,
} from '../../../../../utils/storage'
import {
  VenueInquiry,
  serializeInquiry,
} from '../InquiryCreate/InquiryCreate.controller'

import {
  FieldLabel,
  FieldWrapper,
  FormContainer,
  SectionHeader,
} from './PersonalInfo.styles'
import { personalInfoValidationSchema } from './validationSchema'

interface PersonalInfoFormValues {
  name: string
  email: string
  phone: string
  company: string
}

const initialValues: PersonalInfoFormValues = {
  name: '',
  email: '',
  phone: '',
  company: '',
}

const PersonalInfoModal: React.FC<{
  onSubmit: () => void
  onSuccess: () => void
  onError: () => void
}> = ({ onSubmit, onError, onSuccess }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { updateUserActiveEvent, getUserActiveEvent } = useStorageEventsData()
  const { authModalControl } = useAuthData()
  const { toggleModal } = authModalControl
  const [agreed, setAgreed] = useState(false)

  const userEvent: LocalEvent = getUserActiveEvent()

  const submitGuestEvent: (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => void | Promise<any> = async (values: any, { setErrors }) => {
    onSubmit()

    const inquiryFormValues: VenueInquiry = LsGetInquiryFormValues()
    const inquiryFormDetails = LsGetInquiryDetails()
    const vendors = userEvent?.vendors?.map((vendor) => vendor.id) || []
    const rooms = inquiryFormDetails.rooms?.map((room: Room) => room.id)
    const inquiryDetails = createGuestInquiryMapper(
      {
        ...serializeInquiry(inquiryFormValues),
        packages: [],
        vendors,
        rooms,
      } as InquiryFormPostRequest,
      values
    )

    try {
      const inquiryData = {
        ...inquiryDetails,
        agreed: +agreed,
      }

      const { data } = await createInquiryForGuests(inquiryData)

      const storageEvent = convertInquiryEventToUserEvent(data)

      updateUserActiveEvent(storageEvent)
      LsRemoveInquiryFormValues()
      LsRemoveInquiryDetails()
      history.push(messagesSuccessNotLoggedInquiryPath, { email: values.email })
      onSuccess()
    } catch (e) {
      if (e.response.data?.code === 'EMAIL_EXISTS') {
        toggleModal({
          step: AuthMenuActions.Signin,
          email: values.email,
          message:
            'We found that account with this email address already exists. Please log in your client account for continue.',
        })
      }
      if (e.response?.data?.errors) {
        setErrors(e.response.data.errors)
      }
      onError()
    }
  }

  return (
    <Formik
      validationSchema={personalInfoValidationSchema()}
      initialValues={initialValues}
      onSubmit={submitGuestEvent}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
    >
      {({ handleSubmit, errors, touched }) => {
        return (
          <FormContainer onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" width="100%">
              <SectionHeader variant="h2">
                {t('profile.personalInfo', 'Personal info')}
              </SectionHeader>
              <FieldLabel sx={{ mb: '20px' }}>
                {t('profile.contactInfo', 'Please provide contact information')}
              </FieldLabel>
              <FieldWrapper display="flex" flexDirection="column" mb={4}>
                <Box display="flex">
                  <FieldLabel variant="body2" id="full-name-label">
                    {t('profile.fullName', 'Full name')}
                  </FieldLabel>
                </Box>
                <FastTextField
                  inputProps={{ 'aria-labelledby': 'full-name-label' }}
                  name="name"
                  placeholder={t(
                    'profile.fullNamePlaceholder',
                    'Type your full name'
                  )}
                />
              </FieldWrapper>
              <FieldWrapper display="flex" flexDirection="column" mb={4}>
                <Box display="flex">
                  <FieldLabel variant="body2" id="email-label">
                    {t('profile.email', 'Email')}
                  </FieldLabel>
                </Box>
                <FastTextField
                  inputProps={{ 'aria-labelledby': 'email-label' }}
                  name="email"
                  placeholder="example@mail.com"
                />
              </FieldWrapper>
              <FieldWrapper display="flex" flexDirection="column" mb={4}>
                <Box display="flex">
                  <FieldLabel variant="body2" id="phone-number-label">
                    {t('profile.phoneNumber', 'Phone Number')}
                  </FieldLabel>
                </Box>
                <Field
                  name="phone"
                  component={FormikTextField}
                  autoComplete="phone"
                >
                  {({ field }: any) => (
                    <MaskedInput
                      aria-labelledby="phone-number-label"
                      mask={phoneNumberMask}
                      {...field}
                      className={errors.phone && touched.phone ? 'error' : ''}
                      placeholder="(999) 999-9999"
                      id="phoneNumber"
                    />
                  )}
                </Field>
                {errors.phone && touched.phone && (
                  <Typography
                    style={{ margin: '3px 14px 0px 14px', color: '#F16547' }}
                    textAlign="left"
                    fontSize={12}
                  >
                    {errors.phone}
                  </Typography>
                )}
              </FieldWrapper>
              <FieldWrapper display="flex" flexDirection="column" mb={4}>
                <Box display="flex">
                  <FieldLabel variant="body2" id="company-name-label">
                    {t('profile.companyName', 'Company name')}
                  </FieldLabel>
                </Box>
                <FastTextField
                  inputProps={{ 'aria-labelledby': 'company-name-label' }}
                  name="company"
                  placeholder={t(
                    'profile.companyNamePlaceholder',
                    'Type your company name'
                  )}
                />
              </FieldWrapper>
              <TermsAndConditions agreed={agreed} setAgreed={setAgreed} />
              <SimpleButton
                sx={{ mt: 4 }}
                variant="contained"
                type="submit"
                disabled={Boolean(keys(errors).length) || !agreed}
                onClick={() => {}}
              >
                {t('common.buttons.continue', 'Continue')}
              </SimpleButton>
            </Box>
          </FormContainer>
        )
      }}
    </Formik>
  )
}

export default PersonalInfoModal
