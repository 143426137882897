import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Send from '../../../../components/svg/Send'
import GradientButton from '../../../../components/ui/Buttons/GradientButton'

interface InformationModalContentProps {
  isEventDraft: boolean
  description: string
  title: string
  icon: React.ReactElement
  onToggleMessenger: () => void
  onClose: () => void
  detailsHeading?: string
  ctaText?: string
}

export const InformationModalContent: React.FC<
  InformationModalContentProps
> = ({
  isEventDraft,
  description,
  icon,
  title,
  onToggleMessenger,
  onClose,
  detailsHeading = useTranslation().t(
    'common.information.modal.more',
    'For more details:'
  ),
  ctaText = useTranslation().t('common.links.support', 'Concierge support'),
}) => {
  return (
    <Box
      display="flex"
      width={1}
      height={1}
      flex={1}
      flexDirection="column"
      alignItems="center"
    >
      {icon}
      <Box mb={3}>
        <Typography fontWeight={600}>&quot;{title}&quot;</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="body2" textAlign="center">
          {description}
        </Typography>
      </Box>
      <Box mb={3} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body2" color={(theme) => theme.palette.grey[200]}>
          {detailsHeading}
        </Typography>
        {isEventDraft && (
          <Typography
            color={(theme) => theme.palette.grey[200]}
            variant="body2"
          >
            {useTranslation().t(
              'common.information.modal.needSubmit',
              'Please, submit your inquiry before open chat support'
            )}
          </Typography>
        )}
      </Box>
      <Box
        flex={1}
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <GradientButton
          style={{ height: 44 }}
          fullWidth
          disabled={isEventDraft}
          onClick={() => {
            onClose()
            onToggleMessenger()
          }}
        >
          {ctaText}
          <Box display="flex" ml={5}>
            <Send fill="#FFFFFF" />
          </Box>
        </GradientButton>
      </Box>
    </Box>
  )
}
