import { Field, FieldProps, getIn } from 'formik'
import * as React from 'react'

import { GoogleLocationSelect } from '../../../../components/ui/Inputs/GoogleLocationSelect'

export const AddressField: React.FC<{ initialLocation?: string }> = ({
  initialLocation,
}) => {
  return (
    <Field name="office">
      {({ field, form: { touched, errors, setFieldValue } }: FieldProps) => {
        const fieldError = getIn(errors, field.name)
        const showError = getIn(touched, field.name) && Boolean(fieldError)
        return (
          <GoogleLocationSelect
            initialLocation={initialLocation}
            withAdornment={false}
            error={showError}
            value={field.value}
            helperText={showError && fieldError}
            onChange={(value) => setFieldValue('office', value)}
            name="office"
            placeholder="Type address here"
          />
        )
      }}
    </Field>
  )
}
