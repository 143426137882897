import { InputAdornment } from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider/LocalizationProvider'
import { FastField, FieldProps } from 'formik'
import * as React from 'react'

import { setTimeToDate } from '../../utils/helpers/date'
import { TextInput } from '../ui/FormComponents/TextInput'
import DateRangePicker from '../ui/Inputs/DateRangePicker'

export const DateField: React.FC<{
  name: string
  label: string
  isCleanable?: boolean
}> = ({ name, label, isCleanable }) => {
  return (
    <FastField name={name}>
      {({ field, form: { setFieldValue } }: FieldProps) => {
        const calculatedValue =
          field.value && Array.isArray(field.value)
            ? field.value.map((val: string | Date) => {
                if (typeof val === 'string') {
                  return new Date(val)
                }
                return val
              })
            : field.value
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              className="inquiry-input"
              closeButton
              isCleanable={isCleanable}
              calendars={1}
              setValue={(range) => {
                setFieldValue(name, range)
              }}
              setTimeValue={(range) => {
                if (calculatedValue) {
                  const start = setTimeToDate(calculatedValue[0], range[0])
                  const end = setTimeToDate(calculatedValue[1], range[1])
                  setFieldValue(name, [start, end])
                }
              }}
              timeValue={calculatedValue}
              value={calculatedValue}
              renderDateInput={(value: string) => {
                return (
                  <TextInput
                    label={label}
                    value={value}
                    placeholder={'MM/DD/YY - MM/DD/YY'}
                    InputProps={{ readOnly: true }}
                    onChange={() => {}}
                    endAdornment={
                      <InputAdornment position="end">
                        <ExpandMoreOutlined style={{ color: 'black' }} />
                      </InputAdornment>
                    }
                  />
                )
              }}
            />
          </LocalizationProvider>
        )
      }}
    </FastField>
  )
}
