import {
  InquiryContractStatuses,
  InquiryInvoiceBundleStatuses,
  InquiryInvoiceStatuses,
} from '../../constants/inquiry'
import { EventType } from '../Event'
import { AssetRefDownload } from '../FileUploadHandler'
import { DocumentFile } from '../general'

export interface CreateRequestLineItem {
  id: string
  name?: string
  quantity: number
  price: number
  clarification: string
  group_name: string
  group_description: string
}

export interface GroupLineItemBundle {
  name: string
  description?: string
  line_items: LineItemBundle[]
}

export interface LineItemBundle {
  line_item_id: number
  price: number
  quantity: number
  name: string
  clarification?: string
  group_name?: string
  group_description?: string
  fee_percent?: number
  id?: number
  invoice_bundle_id?: number
  invoice_id?: number
  is_exclusive?: number
  is_fee_assessable?: number
}

export interface EventSuggestions {
  created_at?: string
  end_date?: string
  event_id?: number
  guests?: number
  id?: number
  load_in_end_date?: string
  load_in_start_date?: string
  load_out_end_date?: string
  load_out_start_date?: string
  start_date?: string
  updated_at?: string
}

export interface InquiryContractRequest {
  price?: number
  inquiry_id: number
  parent_id?: number
}

export interface ContractPayment {
  id: number
  type: string
  status: string
}

export interface InquiryContractResponse {
  id: number
  external_document_id: string
  price: number
  type: string
  inquiry_id: number
  client_user_id: number
  business_user_id: number
  client_session_id: string | null
  business_session_id: string | null
  provider: string
  created_at: string
  status: InquiryContractStatuses
  status_info: {
    label: string
    value: InquiryContractStatuses
    bg_color: string
    border_color: string
    text_color: string
  }
  payments: Array<ContractPayment>
  version: number
}

export interface InquiryDefaultInvoicesRequest {
  inquiry_id: number
  initial_payment_percent: number
  initial_due_date: string
  final_due_date: string
  message?: string
  extra?: string
}

export interface InquiryDefaultInvoicesResponse {
  final: InquiryInvoiceResponse
  initial: InquiryInvoiceResponse
}

export interface InquiryInvoiceCreateRequest {
  line_items?: CreateRequestLineItem[]
  inquiry_id: number
  expiration_date: string
  message?: string
  pricing_caveats?: string
  payment_terms?: string
  extra?: string
}

export interface Invoice {
  id: number
  status: InquiryInvoiceStatuses
  status_info: {
    label: string
    value: InquiryInvoiceStatuses
    bg_color: string
    border_color: string
    text_color: string
  }
  grouped_line_items_bundle: Array<GroupLineItemBundle>
  price: number
  price_modifier: number
  type: 'initial' | 'final'
  relations: {
    checkout_url?: string
    order_id?: string
  }
  document_url: string
  expiration_date: string
  invoice_bundle_id: number
  version: number
}

export interface InquiryInvoiceResponse {
  created_at: string
  updated_at: string
  id: number
  inquiry_id: number
  price: number
  message?: string
  document_url?: string
  pricing_caveats?: string
  payment_terms?: string
  extra?: string
  checkout_url?: string
  invoices: Invoice[]
  status: InquiryInvoiceBundleStatuses
  status_info: {
    label: string
    value: InquiryInvoiceBundleStatuses
    bg_color: string
    border_color: string
    text_color: string
  }
  version: number
  grouped_line_items_bundle: Array<GroupLineItemBundle>
}

export enum BusinessInquiryActions {
  INQUIRY_DETAILS = 'inquiryDetails',
  INQUIRY_ACCEPT = 'inquiryAccept',
  INQUIRY_DECLINE = 'inquiryDecline',
  CONTRACT_GENERATE = 'contractGenerate',
  INVOICES_GENERATE = 'invoicesGenerate',
  CONTRACT_INVOICES_BUNDLE_SEND = 'contractAndInvoicesBundleSend',
  INVOICES_BUNDLE_SEND = 'invoicesBundleSend',
}
