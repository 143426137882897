import {
  Box,
  Checkbox,
  CheckboxProps,
  styled,
  Typography,
} from '@material-ui/core'
import * as React from 'react'

import { ImageType } from '../../../types/general'
import { Image } from '../Image'

const CheckboxCardWrapper = styled(Box)`
  padding: 16px;
  width: 80%;
  min-width: 200px;
  margin: 15px 0;
  display: flex;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid #d7d7d7;

  &.selected {
    border: 2px solid #2f54eb;
  }
`

export interface CheckboxCardProps {
  label: string
  staticImageType: ImageType['static']
  alt?: string
}

export const CheckboxCard: React.FC<CheckboxCardProps & CheckboxProps> = ({
  label,
  staticImageType,
  alt,
  checked,
  ...props
}) => {
  return (
    <CheckboxCardWrapper className={checked ? 'selected' : ''}>
      <Box display="flex" mr={2}>
        <Image
          imageSize="original"
          staticImageType={staticImageType}
          style={{ height: 55, width: 55, objectFit: 'cover' }}
          alt={alt}
        />
      </Box>
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          fontWeight={600}
          fontSize={16}
          style={{ textTransform: 'capitalize' }}
          variant="subtitle1"
        >
          {label}
        </Typography>
        <Checkbox checked={checked} color="primary" {...props} />
      </Box>
    </CheckboxCardWrapper>
  )
}
