import * as yup from 'yup'

import { phoneRegExp, websiteRegExp } from '../../../constants'
import { post } from '../../../utils/api/api'
import { validateEmail } from '../../../utils/helpers/validations'
import { t } from '../../../utils/i18n'
import { uniqueEmailCheckApiPath } from '../../../utils/paths'

let prevEmail = ''
let prevResult = true

export const businessDetailsValidationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(60)
      .required(
        t('business.details.validation.name', 'Listing name is required')
      ),
    website: yup
      .string()
      .trim()
      .matches(
        websiteRegExp,
        t(
          'business.details.validation.websiteInvalid',
          'Invalid website format'
        )
      )
      .required(
        t('business.details.validation.websiteRequired', 'Website is required')
      ),
    legal_entity: yup.string().trim().max(100),
  })

// TODO outdated, need remove
export const createProfileValidationSchema = () =>
  yup.object().shape({
    contact_name: yup
      .string()
      .trim()
      .max(60)
      .required(t('profile.validation.name', 'Name is required')),
    contact_email: yup
      .string()
      .required(t('profile.validation.emailRequired', 'Email is required'))
      .test(
        'email',
        t('profile.validation.emailNotValid', 'Invalid email address'),
        (email) => {
          return validateEmail(email || '')
        }
      ),
    phone: yup
      .string()
      .required(t('profile.validation.phone', 'Phone is required')),
    // office: yup.string().trim(),
  })

export const createVendorInformationValidationSchema = (
  isAuthUser: boolean
) => {
  const emailCheckDebounced = (email?: string) => {
    const schema = yup.string().email()

    if (email && schema.isValidSync(email) && prevEmail !== email) {
      prevEmail = email
      return post(uniqueEmailCheckApiPath, { email })
        .then(() => {
          return (prevResult = true)
        })
        .catch(() => {
          return (prevResult = false)
        })
    } else {
      return prevResult
    }
  }

  return yup.object().shape({
    phone: isAuthUser
      ? yup.string()
      : yup
          .string()
          .transform((value) => (value ? value.replace(/\D+/g, '') : ''))
          .matches(
            phoneRegExp,
            t(
              'onboarding.vendor.form.validation.phoneNotValid',
              'Phone number is not valid'
            )
          )
          .required(
            t(
              'onboarding.vendor.form.validation.phoneRequired',
              'Primary Contact Phone is required'
            )
          ),
    type: yup
      .string()
      .required(
        t(
          'onboarding.vendor.form.validation.service',
          'Service type is required'
        )
      ),
    contactEmail: isAuthUser
      ? yup.string().trim()
      : yup
          .string()
          .trim()
          .required(
            t(
              'onboarding.vendor.form.validation.emailRequired',
              'Email is required'
            )
          )
          .email(
            t(
              'onboarding.vendor.form.validation.emailNotValid',
              'Email is not valid'
            )
          )
          .test(
            'contactEmail',
            t(
              'onboarding.vendor.form.validation.emailTaken',
              'This email is already taken'
            ),
            (email?: string) => {
              return emailCheckDebounced(email)
            }
          ),
    contactName: isAuthUser
      ? yup.string().trim()
      : yup
          .string()
          .trim()
          .required(
            t(
              'onboarding.vendor.form.validation.contactName',
              'Contact name is required'
            )
          ),
    name: yup
      .string()
      .trim()
      .max(60)
      .required(
        t(
          'onboarding.vendor.form.validation.businessName',
          'Listing name is required'
        )
      ),
    description: yup
      .string()
      .trim()
      .required(
        t(
          'onboarding.vendor.form.validation.businessDescription',
          'Listing description is required'
        )
      ),
    website: yup
      .string()
      .trim()
      .matches(
        websiteRegExp,
        t('onboarding.vendor.form.validation.website', 'Invalid website format')
      ),
    costLevel: yup
      .number()
      .required(
        t(
          'onboarding.vendor.form.validation.pricingLevel',
          'Pricing level is required'
        )
      ),
  })
}
