import { Box, Link, TableCell, Typography, useTheme } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Download from '../../../../../../components/svg/clientDashboard/Download'
import { StatusBox } from '../../../../../../components/ui/StatusBox'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { InquiryContractResponse } from '../../../../../../types/dtos/inquiry'
import { DocumentFile } from '../../../../../../types/general'
import { InquiryWithDetails } from '../../../../../../types/Inquiry'
import { downloadInquiryContractById } from '../../../../../../utils/api/inquiries'
import { dateWithSeparator } from '../../../../../../utils/helpers/date'
import { downloadUserFile } from '../../../../../../utils/helpers/files'
import { getRequestError } from '../../../../../../utils/helpers/validations'
import { StyledIconButton } from '../../../../../dashboard/components/InquiryFlowTables/InquiryTables.styles'
import {
  StyledDocumentsIconButton,
  StyledTableRow,
} from '../../../ServiceBookingDetails.styles'

interface ContractTableRowProps {
  contract: InquiryContractResponse
  index: number
  inquiryDetails?: InquiryWithDetails
  onDownload: (contractId: number) => void
  addendum?: DocumentFile
}

export const ContractsTableRow: React.FC<ContractTableRowProps> = ({
  contract,
  onDownload,
  addendum,
}) => {
  const theme = useTheme()
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()
  const [addendumUrl, setAddendumUrl] = useState('')
  const [contractUrl, setContractUrl] = useState('')

  React.useMemo(async () => {
    if (!addendum) return
    try {
      const response = await downloadUserFile(addendum.id)
      setAddendumUrl(response)
    } catch (e) {
      console.log(e)
    }
  }, [addendum])

  React.useMemo(async () => {
    if (!contract) return
    try {
      const response = await downloadInquiryContractById(contract.id.toString())
      const url = window.URL.createObjectURL(response)
      setContractUrl(url)
    } catch (e) {
      console.log(e)
    }
  }, [contract])

  return (
    <StyledTableRow
      id={contract.id?.toString()}
      style={{ border: '1px solid rgba(226, 226, 226, 1)' }}
    >
      <TableCell>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
          <Typography fontWeight={500} variant="body2">
            {contract.version}.
          </Typography>
          {isMobile && (
            <Box display="flex">
              <Typography variant="body2" fontSize="12px" color="textSecondary">
                {t(
                  'inquiry.contract.table.generationDate',
                  'Date of generation'
                )}
              </Typography>
              <Typography fontWeight={500} variant="body2">
                {dateWithSeparator(new Date(contract.created_at), '/')}
              </Typography>
            </Box>
          )}
        </Box>
      </TableCell>
      {!isMobile && (
        <TableCell>
          <Typography fontWeight={500} variant="body2">
            {dateWithSeparator(new Date(contract.created_at), '/')}
          </Typography>
        </TableCell>
      )}
      <TableCell>
        <Box flexDirection="column">
          <StatusBox
            borderColor={contract.status_info.border_color}
            textColor={contract.status_info.text_color}
            bgColor={contract.status_info.bg_color}
          >
            {contract.status_info.label}
          </StatusBox>
          {isMobile && (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Link
                  href={contractUrl}
                  underline="none"
                  target="_blank"
                  variant="body2"
                >
                  <StyledDocumentsIconButton>
                    <Download fill={theme.palette.primary.light} />
                  </StyledDocumentsIconButton>
                </Link>
                {addendumUrl && (
                  <Link
                    href={addendumUrl}
                    underline="none"
                    target="_blank"
                    variant="body2"
                  >
                    <StyledDocumentsIconButton>
                      <Download fill={theme.palette.primary.light} />
                    </StyledDocumentsIconButton>
                  </Link>
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body2"
                  fontSize="8px"
                  color="textSecondary"
                >
                  ({t('inquiry.contract.table.contract', 'Contract')})
                </Typography>
                {addendum && (
                  <Typography
                    variant="body2"
                    fontSize="8px"
                    color="textSecondary"
                  >
                    ({t('inquiry.contract.table.addendum', 'Addendum')})
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
      </TableCell>
      {!isMobile && (
        <TableCell>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mr={2}>
              <Link
                href={contractUrl}
                underline="none"
                target="_blank"
                variant="body2"
              >
                <StyledDocumentsIconButton>
                  <Download fill={theme.palette.primary.light} />
                </StyledDocumentsIconButton>
                <Typography
                  variant="body2"
                  fontSize="12px"
                  color="textSecondary"
                >
                  ({t('inquiry.contract.table.contract', 'Contract')})
                </Typography>
              </Link>
            </Box>
            {addendumUrl && (
              <Box display="flex" alignItems="center">
                <Link
                  href={addendumUrl}
                  underline="none"
                  target="_blank"
                  variant="body2"
                >
                  <StyledDocumentsIconButton>
                    <Download fill={theme.palette.primary.light} />
                  </StyledDocumentsIconButton>
                  <Typography
                    variant="body2"
                    fontSize="12px"
                    color="textSecondary"
                  >
                    ({t('inquiry.contract.table.addendum', 'Addendum')})
                  </Typography>
                </Link>
              </Box>
            )}
          </Box>
        </TableCell>
      )}
    </StyledTableRow>
  )
}
