import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import GradientButton from '../../../../components/ui/Buttons/GradientButton'
import { UserRoles } from '../../../../constants/profile'
import { usePartner } from '../../../../hooks/usePartner'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import {
  onboardingVendorPath,
  onboardingVenuePath,
} from '../../../../utils/paths'

export const NoBusiness: React.FC<{ role?: UserRoles }> = ({ role }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const { isAbs } = usePartner()
  const isLandlord = UserRoles.isLandlord(role)
  const listingType = isLandlord ? 'venue' : 'vendor'

  return (
    <Box
      sx={{ background: 'rgba(32, 32, 44, 0.9)' }}
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mb={isAbs ? 0 : isMobile ? '-160px' : '-180px'}
    >
      <Typography
        variant="h1"
        fontWeight={500}
        fontFamily="Poppins"
        color="#fff"
        fontSize={isMobile ? '32px' : '56px'}
        lineHeight={isMobile ? '50px' : '72px'}
      >
        {t('business.listings.noListings.title', 'No listings found')}
      </Typography>
      <Typography
        variant="h2"
        fontSize="16px"
        fontFamily="Poppins"
        fontWeight="400"
        color="#9396A3"
        mt="8px"
        mb={4}
      >
        {t(
          'business.listings.noListings.subtitle',
          'Please add listing to continue'
        )}
      </Typography>
      <GradientButton
        sx={{ width: '280px', margin: '0 auto' }}
        onClick={() =>
          history.push(isLandlord ? onboardingVenuePath : onboardingVendorPath)
        }
      >
        <Trans i18nKey="business.listings.noListings.create">
          Create {{ listingType }}
        </Trans>
      </GradientButton>
    </Box>
  )
}
