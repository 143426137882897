/** @jsxImportSource @emotion/react */

import { Box, Stack, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { Document } from 'components/Documents/Document'
import Plus from 'components/svg/Plus'
import GradientButton from 'components/ui/Buttons/GradientButton'
import { SimpleButton } from 'components/ui/Buttons/SimpleButton'
import { TertiaryButton } from 'components/ui/Buttons/TertiaryButton'
import { Trans } from 'react-i18next'
import { Files, formatBytes, useFileUpload } from 'utils/helpers/uploadFiles'
import { t } from 'utils/i18n'
import { visuallyHidden } from 'utils/styles/aria'

const validateFile = (options: { maxFileSize: number }) => (file?: File) => {
  if (!file) return null
  if (file.size > options.maxFileSize || file.type !== 'application/pdf') {
    return t('business.contracts.upload.errors.file', {
      maxSize: formatBytes(options.maxFileSize),
    })
  }
  return null
}

export type AddContractFormProps = {
  maxFileSize?: number
  onSubmit: (data: FormData) => void
  onCancel: () => void
}

export const AddContractForm = ({
  // allow override for testing
  maxFileSize = 25 * 1024 * 1024,
  onSubmit,
  onCancel,
}: AddContractFormProps) => {
  const {
    ref: fileRef,
    getInputProps,
    getFormProps,
    file,
    error,
    reset,
  } = useFileUpload({
    validate: validateFile({ maxFileSize }),
    onSubmit,
  })

  return (
    <>
      <form {...getFormProps()} css={{ mt: '32px' }}>
        <Stack height="530px" display="flex" justifyContent="space-between">
          <Stack direction="column" spacing={4}>
            <Typography align="center" fontSize={18} fontWeight={600}>
              {t('business.contracts.upload.title', 'Contract uploading')}
            </Typography>
            <Typography variant="body1">
              <Trans i18nKey="business.contracts.upload.description">
                Please upload your new contract. The client will receive an
                email notifying them that a new contract is ready to view.
              </Trans>
            </Typography>
            <Box>
              <Trans i18nKey="business.contracts.upload.requirements">
                <Typography
                  mb="4px"
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.grey[200] }}
                >
                  File requirements:
                </Typography>
                <Stack
                  m={0}
                  spacing="4px"
                  component="ul"
                  sx={{ color: (theme) => theme.palette.grey[200] }}
                >
                  <Typography component="li" variant="body2">
                    Type: PDF
                  </Typography>
                  <Typography component="li" variant="body2">
                    Size: Less than 25MB
                  </Typography>
                </Stack>
              </Trans>
            </Box>

            <input
              css={visuallyHidden}
              aria-label="contract"
              id="contract"
              name="contract"
              accept=".pdf"
              {...getInputProps()}
            />

            {file && (
              <Document date={new Date()} name={file.name}>
                <Document.Actions>
                  <Document.Actions.Preview
                    onClick={() => Files.preview(file)}
                  />
                  <Document.Actions.Delete onClick={reset} />
                </Document.Actions>
              </Document>
            )}

            {!file && (
              <TertiaryButton
                type="button"
                onClick={() => fileRef.current?.click()}
              >
                <Plus fill="currentColor" />
                <Typography variant="body2" fontWeight={500} ml={1}>
                  {t('business.contracts.add', 'Add contract')}
                </Typography>
              </TertiaryButton>
            )}

            {Boolean(error) && (
              <Typography color="error.main" variant="body2">
                {error}
              </Typography>
            )}
          </Stack>

          <Stack direction="row" spacing={2}>
            <SimpleButton
              variant="text"
              color="muted"
              onClick={onCancel}
              endIcon={<ClearIcon style={{ marginLeft: 10 }} />}
            >
              {t('common.buttons.cancel', 'Cancel')}
            </SimpleButton>
            <GradientButton
              type="submit"
              disabled={!file}
              style={{ width: '50%' }}
            >
              {t('business.contracts.send', 'Send')}
            </GradientButton>
          </Stack>
        </Stack>
      </form>
    </>
  )
}
