import { Box, Typography } from '@material-ui/core'
import { NavLink } from 'components/NavLink/NavLink'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { usePromotion } from '../../utils/api/admin'
import { venuesPromotionsPath } from '../../utils/paths'
import Promotion from '../svg/Promotion'
import { Loader } from '../ui/Loader'

export const PromotionBanner: React.FC = () => {
  const { isLoading, data } = usePromotion()
  const { t } = useTranslation()

  if (isLoading) return null
  if (!data?.promotion_banner_switcher) return null

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p="11.5px 15.5px"
      sx={{ background: (theme) => theme.palette.common.promotionBanner }}
    >
      <Promotion />
      <Typography
        ml="16px"
        color={(theme) => theme.palette.common.white}
        fontFamily="Frank Ruhl Libre"
        fontSize={20}
        fontWeight={500}
      >
        {data?.promotion_banner_text}
        {data?.special_offers_switcher && (
          <NavLink
            to={venuesPromotionsPath}
            sx={{
              textDecoration: 'underline',
              color: (theme) => theme.palette.common.white,
            }}
          >
            <Typography
              ml="32px"
              component="span"
              fontFamily="Frank Ruhl Libre"
              fontSize={20}
              fontWeight={500}
            >
              {t('promotions.see', 'See offers')}
            </Typography>
          </NavLink>
        )}
      </Typography>
    </Box>
  )
}
