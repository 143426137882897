import { Box } from '@material-ui/core'
import * as React from 'react'
import { useMemo, useState } from 'react'

import { Loader } from '../../components/ui/Loader'
import { INITIAL_SEARCH_PARAMS } from '../../constants'
import { useUrlParams } from '../../hooks/useUrlParams'
import { Vendor } from '../../types/Vendor'
import { useVendors, useVendorTypes } from '../../utils/api/vendors'
import { vendorsMapper } from '../../utils/mappers/vendors'
import { venueListQueryParams } from '../../utils/mappers/venueListQueryParamsMapper'
import { LikesDataProvider } from '../../utils/providers/LikesProvider'
import { EventForm } from '../home/components/EventForm/EventForm'
import { PromotionsBlock } from '../promotions/components/PromotionsBlock'
import { VendorsCategories } from '../vendors/components/VendorsCategories/VendorsCategories'

export const Home: React.FC = () => {
  const { urlParams } = useUrlParams()
  const { data, isLoading } = useVendors({ has_advantages: true, perPage: 6 })
  const { data: vendorTypes, isLoading: isVendorTypesLoading } =
    useVendorTypes()

  const promotionsVendors = useMemo(() => {
    return data?.data?.map((vendor: Vendor) => vendorsMapper(vendor))
  }, [data])

  const [queryParams, updateQueryParams] = useState<venueListQueryParams>({
    ...INITIAL_SEARCH_PARAMS,
    ...urlParams,
  })

  const onSearchVenues = (name: string) => {
    updateQueryParams({ ...queryParams, name })
  }

  return (
    <LikesDataProvider>
      <Box style={{ width: '100%' }}>
        <EventForm onSearchVenues={onSearchVenues} />
        {isLoading ? (
          <Loader />
        ) : promotionsVendors?.length ? (
          <PromotionsBlock vendors={promotionsVendors} />
        ) : (
          <></>
        )}
      </Box>
      <VendorsCategories
        vendorTypes={{ isLoading: isVendorTypesLoading, data: vendorTypes }}
      />
    </LikesDataProvider>
  )
}
