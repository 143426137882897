const HouseFilledSmall = ({ fill = '#262626' }) => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.79 7.89 8.354 1.46a.499.499 0 0 0-.706 0L1.212 7.89a1 1 0 0 0 .706 1.708h.678v4.59a.5.5 0 0 0 .5.5h3.905v-3.5h1.75v3.5h4.155a.5.5 0 0 0 .5-.5v-4.59h.678a1.001 1.001 0 0 0 .706-1.708Z"
        fill={fill}
      />
    </svg>
  )
}

export default HouseFilledSmall
