import { Box } from '@material-ui/core'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import ArrowBack from '../../../../components/svg/ArrowBack'
import { Loader } from '../../../../components/ui/Loader'
import { getPartnerParams } from '../../../../constants/partners'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { postPersonalInfo } from '../../../../utils/api/profile'
import { getRequestError } from '../../../../utils/helpers/validations'
import { useMessageModalData } from '../../../../utils/providers/MessageModalProvider'
import { ProfileDivider } from '../../Profile.styles'
import { TabSubtitle } from '../ui/TabSubtitle'

import { SecurityForm } from './SecurityForm'
import { createValidationSchema } from './validationSchema'

export interface SecurityFormValue {
  currentPassword: string
  newPassword: string
  twoFactorAuth: boolean
  isActive: boolean
}

//TODO add default value from session
const defaultSecurityFormValue: SecurityFormValue = {
  currentPassword: '',
  newPassword: '',
  twoFactorAuth: false,
  isActive: true,
}

export const Security: React.FC<{ isAbs: boolean; onClose?: () => void }> = ({
  isAbs,
  onClose,
}) => {
  const { isMobile } = useWindowSize()
  const { showMessage } = useMessageModalData()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()

  const onSubmit = React.useCallback(
    async (values: SecurityFormValue, { setSubmitting, resetForm }) => {
      setLoading(true)

      const securityInfo = {
        password: values.newPassword,
        ...(isAbs ? getPartnerParams() : {}),
      }
      try {
        await postPersonalInfo(securityInfo)

        showMessage({
          title: t(
            'profile.password.updated',
            'Password has been successfully updated!'
          ),
        })
      } catch (e) {
        setSubmitting(false)
        showMessage({
          title: getRequestError(e),
          type: 'error',
        })
      }

      setLoading(false)
      resetForm()
    },
    [isAbs]
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      maxWidth={isMobile ? '100%' : 810}
    >
      <Box display="flex" alignItems="baseline" mt={isMobile ? 5 : 0}>
        {isMobile && (
          <Box
            pr={2}
            onClick={() => {
              history.goBack()
              onClose && onClose()
            }}
          >
            <ArrowBack fill="#000" />
          </Box>
        )}
        <TabSubtitle
          subtitle={t('profile.tabs.security.subtitle', 'Security')}
        />
      </Box>
      {isMobile ? <></> : <ProfileDivider />}
      <Formik
        initialValues={defaultSecurityFormValue}
        validationSchema={createValidationSchema()}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} autoComplete="on" noValidate>
            <SecurityForm />
          </form>
        )}
      </Formik>
      {loading && <Loader position="absolute" />}
    </Box>
  )
}
