import { Table, TableBody } from '@material-ui/core'
import React from 'react'

import { CollapsibleDocumentRow } from '../../../../../../components/ui/CollapsibleDocumentRow'
import { Loader } from '../../../../../../components/ui/Loader'
import { useContractsByStatuses } from '../../../../../../hooks/useContractsByStatus'
import { useDownloadContract } from '../../../../../../hooks/useDownloadContract'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { InquiryContractResponse } from '../../../../../../types/dtos/inquiry'

import { ContractsTableHeader } from './ContractsTableHeader'
import { ContractsTableRow } from './ContractsTableRow'

interface ContractTableProps {
  contracts?: InquiryContractResponse[]
  files?: any[]
}

export const ContractsTable = ({ contracts, files }: ContractTableProps) => {
  const { completedContract, outdatedContracts } =
    useContractsByStatuses(contracts)
  const currentContract = completedContract
  const notActiveContracts = outdatedContracts
  const { isLoading, handleDownloadContract } = useDownloadContract()
  const { isMobile } = useWindowSize()

  return (
    <>
      <Table>
        <ContractsTableHeader />
        <TableBody>
          {currentContract ? (
            <>
              <ContractsTableRow
                addendum={files?.find(
                  (file) => file.contract_id === currentContract.id
                )}
                key={currentContract.id}
                index={0}
                contract={currentContract}
                onDownload={handleDownloadContract}
              />
            </>
          ) : null}
          {notActiveContracts.length ? (
            <CollapsibleDocumentRow colSpan={isMobile ? 1 : 3}>
              {notActiveContracts.map((contract, index) => (
                <ContractsTableRow
                  addendum={files?.find(
                    (file) => file.contract_id === contract.id
                  )}
                  key={contract.id}
                  index={index}
                  contract={contract}
                  onDownload={handleDownloadContract}
                />
              ))}
            </CollapsibleDocumentRow>
          ) : null}
        </TableBody>
      </Table>
      {isLoading && <Loader position="fixed" />}
    </>
  )
}
