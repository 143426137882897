const Decline = () => {
  return (
    <svg width="72" height="72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="36"
        cy="36"
        r="32.677"
        transform="rotate(-96.17 36 36)"
        fill="#F16547"
      />
      <path
        d="m38.023 36 10.254-12.223a.31.31 0 0 0-.238-.511h-3.117a.637.637 0 0 0-.48.222L35.983 33.57l-8.457-10.082a.624.624 0 0 0-.48-.222H23.93a.31.31 0 0 0-.239.511L33.945 36 23.691 48.223a.31.31 0 0 0 .07.463.31.31 0 0 0 .169.048h3.117c.183 0 .36-.082.48-.222l8.457-10.082 8.457 10.082c.117.14.293.222.48.222h3.118a.31.31 0 0 0 .238-.511L38.023 36Z"
        fill="#fff"
      />
    </svg>
  )
}

export default Decline
