import * as React from 'react'
import { Dispatch, SetStateAction, useState } from 'react'

export interface UseWizard {
  wizardData: any
  activeStep: number
  maxSteps: number
  goToStep: (stepIndex: number) => void
  nextStep: () => void
  previousStep: () => void
  getStep: (stepIndex: number) => Step
  onFinish: (data?: any) => void
  onNext: (data?: any) => void
}

export interface Step {
  index: number
  isActive: boolean
  hasBeenActive: boolean
  nextStep: () => void
  previousStep: () => void
  goToStep: (index: number) => void
}

export const WizardContext = React.createContext<UseWizard>({
  wizardData: {},
  activeStep: 0,
  maxSteps: 0,
  goToStep: () => {},
  nextStep: () => {},
  previousStep: () => {},
  onFinish: () => {},
  onNext: () => {},
  getStep: () => ({
    index: 0,
    isActive: false,
    hasBeenActive: false,
    nextStep: () => {},
    previousStep: () => {},
    goToStep: () => {},
  }),
})

interface WizardProps {
  initialStep?: number
  onFinish?: (data?: any) => void
  onNext?: (data?: any) => void
  wizardData?: any
}

export const WizardProvider: React.FC<WizardProps> = ({
  initialStep = 0,
  onFinish,
  wizardData,
  onNext,
  children,
}) => {
  const [activeStep, setActiveStep] = useState(initialStep)
  const maxSteps = React.Children.count(children)
  const maxIndex = maxSteps - 1

  const getStep = (stepIndex: number) => {
    return {
      index: stepIndex,
      isActive: activeStep === stepIndex,
      hasBeenActive: maxIndex >= stepIndex,
      nextStep: () => goToStep(stepIndex + 1),
      previousStep: () => goToStep(Math.max(stepIndex - 1, 0)),
      goToStep: (index: number) => goToStep(index),
    }
  }

  const goToStep = (stepIndex: number) => {
    if (activeStep !== stepIndex && stepIndex <= maxIndex) {
      setActiveStep(stepIndex)
    }
  }

  const nextStep = () => {
    goToStep(activeStep + 1)
  }

  const previousStep = () => {
    goToStep(Math.max(activeStep - 1, 0))
  }

  const context = React.useMemo(
    () => ({
      wizardData,
      activeStep,
      getStep,
      maxSteps,
      goToStep,
      nextStep,
      previousStep,
      onFinish: onFinish ? onFinish : () => {},
      onNext: onNext ? onNext : () => {},
    }),
    [
      activeStep,
      getStep,
      maxSteps,
      goToStep,
      nextStep,
      previousStep,
      onFinish,
      onNext,
      wizardData,
    ]
  )

  return (
    <WizardContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </WizardContext.Provider>
  )
}

export const useWizard = () => {
  return React.useContext(WizardContext)
}
