const Burger = ({ fill = '#597EF7' }) => {
  return (
    <svg width="24" height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="2" rx="1" fill={fill} />
      <rect y="5" width="24" height="2" rx="1" fill={fill} />
    </svg>
  )
}

export default Burger
