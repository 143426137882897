import { Box, styled } from '@material-ui/core'

export const DraggableElementWrapper = styled('div')`
  width: 100%;
  position: relative;
  overflow-x: visible;
`

export const DragItemWrapper = styled('div')`
  overflow-x: visible;
  width: 100%;
  height: 100%;
  > div {
    cursor: default;
  }
`
export const ItemsContainer = styled('div')<{ isTableView: boolean }>(
  (props) => `
  display: flex;
  flex-direction: ${props.isTableView ? 'column' : 'row'}
`
)
