import { Box, styled } from '@material-ui/core'

export const PhoneInputWrapper = styled(Box)`
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: #141414;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  border-radius: 4px;
  input {
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    border: 1.5px solid lightgray;
    border-radius: 4px;
    box-sizing: content-box;
    height: 1.4375em;
    display: block;
    width: 100%;
    padding: 15.5px 14px;
    &:focus {
      outline: #4e4bec auto 1px;
    }
  }
`
