import Description from '../components/svg/dashboard/Description'
import User from '../components/svg/dashboard/User'
import { SideMenuItem } from '../components/ui/SideMenu'
import { t } from '../utils/i18n'
import {
  dashboardProfilePersonalInfoPath,
  dashboardProfileSecurityPath,
  profilePersonalInfoPath,
  profileSecurityPath,
} from '../utils/paths'

export const getProfileMenuItems = (
  isClient: boolean,
  isMobile?: boolean
): SideMenuItem[] => {
  return [
    {
      name: t('profile.tabs.info.title', 'Personal info'),
      path: isClient
        ? profilePersonalInfoPath
        : dashboardProfilePersonalInfoPath,
      icon: <User color={isMobile ? '#000' : '#9396A3'} />,
    },
    {
      name: t('profile.tabs.security.title', 'Security'),
      path: isClient ? profileSecurityPath : dashboardProfileSecurityPath,
      icon: <Description fill={isMobile ? '#000' : '#9396A3'} />,
    },
  ]
}

export const PERSONAL_INFO_FORM_VIEW_STATE = {
  name: true,
  phone: true,
}

export const SECURITY_FORM_VIEW_STATE = {
  currentPassword: true,
  newPassword: true,
  twoFactorAuth: true,
  isActive: true,
}

export const PAYMENT_FORM_VIEW_STATE = {
  paymentMethod: true,
}

export const MIN_PASSWORD_LENGTH = 8

export const TABLE_MENU_ACTIONS = [
  { label: 'Details', value: 'details', color: 'secondary.dark' },
  { label: 'Accept', value: 'accept', color: 'secondary.dark' },
  { label: 'Decline', value: 'decline', color: 'secondary.dark' },
]

export enum UserRoles {
  Client = '',
  Vendor = 'vendor',
  Landlord = 'landlord',
}

export enum BusinessType {
  Vendor = 'vendor',
  Venue = 'venue',
}

export namespace UserRoles {
  export function isVendor(role?: UserRoles): boolean {
    return role === UserRoles.Vendor
  }

  export function isLandlord(role?: UserRoles): boolean {
    return role === UserRoles.Landlord
  }

  export function isClient(role?: UserRoles): boolean {
    return role === UserRoles.Client
  }
}
