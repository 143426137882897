import { Box } from '@material-ui/core'
import * as React from 'react'

import { LocalEvent } from '../../../../types/Event'

import { EventListMobileTableViewItem } from './EventListMobileTableViewItem'

export const EventListMobileTableView: React.FC<{
  events: LocalEvent[]
  isLoading: boolean
  handleOnEventSelect: (action: string, event: LocalEvent) => void
  handleEventClick: (event: LocalEvent) => void
}> = ({ events, isLoading, handleOnEventSelect, handleEventClick }) => {
  return (
    <Box my={6} width={1} display="flex" flexDirection="column">
      {events.map((event) => (
        <EventListMobileTableViewItem
          key={event.id}
          isLoading={isLoading}
          event={event}
          handleOnEventSelect={handleOnEventSelect}
          handleEventClick={handleEventClick}
        />
      ))}
    </Box>
  )
}
