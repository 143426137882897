import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Image } from '../../../../../components/ui/Image'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { EventServiceProps } from '../../../../../types/Event'
import { ExclusiveTooltip } from '../tooltips/ExclusiveTooltip'
import { LockedTooltip } from '../tooltips/LockedTooltip'

export const ServiceItemName: React.FC<{
  data: EventServiceProps
  isVendorsLocked?: boolean
  openExclusiveInfo: () => void
  toggleLockedVendorModal: (e: React.MouseEvent) => void
}> = ({
  data,
  isVendorsLocked,
  openExclusiveInfo,
  toggleLockedVendorModal,
}) => {
  const isNotInProductionStage = !data.isVenue && isVendorsLocked
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  const dataImage = Array.isArray(data.image) ? data.image?.[0] : data.image

  const roomsString = useMemo(() => {
    if (data.rooms?.length) {
      const rooms = data.rooms
      if (rooms.length > 2) {
        return rooms[0].name + ` +${rooms.length - 2} another`
      }
      return `${rooms[0].name}${rooms[1] ? `, ${rooms[1].name}` : ''}`
    }
    return ''
  }, [data])

  return (
    <Box display="flex" alignItems="center" style={{ position: 'relative' }}>
      {data.exclusive && (
        <ExclusiveTooltip openExclusiveInfo={openExclusiveInfo} />
      )}
      {isNotInProductionStage && (
        <LockedTooltip toggleLockedVendorModal={toggleLockedVendorModal} />
      )}
      <Image
        style={{
          opacity: isNotInProductionStage ? '50%' : '100%',
          width: isMobile ? 48 : 56,
          height: isMobile ? 48 : 56,
        }}
        image={dataImage}
        imageSize="small"
        staticImageType={data.isVenue ? 'venue' : 'vendor'}
        vendorCategory={
          data.isVenue ? undefined : data.service_type.toLowerCase()
        }
      />
      <Box
        style={{
          opacity: isNotInProductionStage ? '50%' : '100%',
          objectFit: 'cover',
        }}
        ml={isMobile ? '16px' : 6}
        width={1}
      >
        {roomsString ? (
          <>
            <Typography fontWeight={600} variant="body2">
              {roomsString}
            </Typography>
            <Typography
              style={{ marginTop: 5 }}
              color="#9FA2B4"
              variant="body2"
            >
              {data.name}
            </Typography>
          </>
        ) : (
          <>
            <Typography fontWeight={600} variant="body2">
              {data.name}
            </Typography>
            {isMobile && (
              <Typography fontWeight={600} variant="body2">
                {data.displayPricing
                  ? data.price
                  : data.pricingDetails
                  ? ''
                  : t(
                      'business.pricing.landlord.notDisplay',
                      'Inquire for Pricing'
                    )}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}
