import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { FastTextField } from '../../../../../../../components/formik'
import CheckedCalendar from '../../../../../../../components/svg/CheckedCalendar'
import PeopleOutlined from '../../../../../../../components/svg/PeopleOutlined'
import ShareAlt from '../../../../../../../components/svg/ShareAlt'
import Ticketed from '../../../../../../../components/svg/Ticketed'
import { SelectField } from '../../../../../../../components/ui/FormComponents/SelectField'
import { useWizard } from '../../../../../../../components/Wizard'
import { usePartner } from '../../../../../../../hooks/usePartner'
import { useWindowSize } from '../../../../../../../hooks/useWindowSize'
import { InquiryCreationStep } from '../../../../../../../types/Inquiry'
import {
  useEventStyles,
  useEventTypes,
} from '../../../../../../../utils/api/events'
import {
  formatEventStyles,
  formatEventTypes,
} from '../../../../../../../utils/helpers/events'
import { FieldLabel, FieldWrapper } from '../ABSInquiryCreate.styles'

import { WizardStep } from './WizardStep'

export const InquiryAbout: React.FC<{
  step: InquiryCreationStep
  currentStepIndex: number
  isDuplicate?: boolean
  copyQuestionaries?: boolean
  handleCopyQuestionaries?: (copy: boolean) => void
  maxGuests?: number
}> = ({
  step,
  currentStepIndex,
  isDuplicate,
  copyQuestionaries,
  handleCopyQuestionaries,
  maxGuests,
}) => {
  const { isMobile } = useWindowSize()
  const { wizardData, goToStep } = useWizard()
  const { data: eventTypes } = useEventTypes()
  const { data: eventStyles } = useEventStyles()
  const { isPartner } = usePartner()
  const { values, setFieldValue, errors } = useFormikContext<FormikValues>()
  const eventStylesOptions = React.useMemo(
    () => formatEventStyles(eventStyles),
    [eventStyles]
  )
  const nextButtonDisabled = !(
    !errors.name &&
    !errors.typeId &&
    values.style?.length &&
    !errors.guests
  )
  const { isAbs } = usePartner()
  const eventTypesOptions = React.useMemo(
    () => formatEventTypes(eventTypes, isAbs),
    [eventTypes, isAbs]
  )
  const { t } = useTranslation()

  React.useEffect(() => {
    goToStep(currentStepIndex)
  }, [])

  React.useEffect(() => {
    if (!eventStylesOptions[0]?.value || eventStylesOptions.length > 1) return
    setFieldValue('style', eventStylesOptions[0]?.value)
  }, [eventStylesOptions])

  return (
    // @ts-ignore
    <WizardStep
      isLoading={false}
      buttonDisabled={nextButtonDisabled}
      title={isDuplicate ? '' : step.name}
      index={step.index}
      stepData={wizardData}
    >
      {isDuplicate && handleCopyQuestionaries && (
        <FormControlLabel
          sx={{ margin: '0 auto 10px' }}
          control={
            <Checkbox
              checked={copyQuestionaries}
              onChange={() => handleCopyQuestionaries(!copyQuestionaries)}
            />
          }
          label={
            <Typography
              sx={{ '&:hover': { opacity: 0.8 } }}
              color="textPrimary"
              variant="body1"
            >
              {t(
                'events.vendors.duplicate.copy',
                'Copy vendors additional info'
              )}
            </Typography>
          }
        />
      )}
      <FieldWrapper display="flex" flexDirection="column" mb={4}>
        <Box display="flex" alignItems="center" mb={3}>
          <CheckedCalendar />
          <FieldLabel
            sx={{ ml: '5px' }}
            variant="body2"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {isAbs
              ? t('bookings.name', 'Booking name')
              : t('events.name', 'Event name')}
          </FieldLabel>
        </Box>
        <FastTextField
          name="name"
          placeholder={
            isAbs
              ? t('bookings.createName', 'Create name for your booking')
              : t('events.createName', 'Create name for your event')
          }
        />
      </FieldWrapper>
      <FieldWrapper display="flex" flexDirection="column" mb={4}>
        <Box display="flex" alignItems="center" mb={3}>
          <PeopleOutlined />
          <FieldLabel
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              ml: '5px',
            }}
            variant="body2"
            fontSize={isMobile ? '14px' : '16px'}
          >
            <Typography component="span" fontSize={isMobile ? '14px' : '16px'}>
              {t('events.guestsNumber', 'Number of guests')}
            </Typography>
            {!!maxGuests && (
              <Trans i18nKey="events.guestsCountWarning">
                <Typography fontSize="12px" color="primary.light">
                  Max {{ maxGuests }} in this venue*
                </Typography>
              </Trans>
            )}
          </FieldLabel>
        </Box>
        <FastTextField name="guests" placeholder="Type here" />
      </FieldWrapper>
      {!isAbs && (
        <FieldWrapper display="flex" flexDirection="column" mb={4}>
          <Box display="flex" alignItems="center" mb={3}>
            <Ticketed />
            <FieldLabel
              style={{ marginBottom: 0, marginLeft: '5px' }}
              variant="body2"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {t('events.style', 'Event style')}
            </FieldLabel>
          </Box>
          <Field name="style">
            {({ field, form: { setFieldValue } }: FieldProps) => {
              return (
                <SelectField
                  label=""
                  options={eventStylesOptions}
                  value={field.value}
                  placeholder={t(
                    'events.styleChoose',
                    'Choose one  from the list'
                  )}
                  onChange={(value) => {
                    setFieldValue(field.name, value, true)
                  }}
                />
              )
            }}
          </Field>
        </FieldWrapper>
      )}
      <FieldWrapper display="flex" flexDirection="column" mb={4}>
        <Box display="flex" alignItems="center" mb={3}>
          <ShareAlt />
          <FieldLabel
            style={{ marginBottom: 0, marginLeft: '5px' }}
            variant="body2"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {isAbs
              ? t('bookings.type', 'Booking type')
              : t('events.type', 'Event type')}
          </FieldLabel>
        </Box>
        <Field name="typeId">
          {({ field, form: { setFieldValue } }: FieldProps) => {
            return (
              <SelectField
                label=""
                options={eventTypesOptions}
                value={field.value}
                withListSubHeader={!isPartner}
                placeholder={t(
                  'events.typeSelect',
                  'Choose one  from the list'
                )}
                onChange={(value) => {
                  setFieldValue(field.name, value, true)
                }}
              />
            )
          }}
        </Field>
      </FieldWrapper>
    </WizardStep>
  )
}
