import { Box, TextField } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React, { useMemo, useState } from 'react'

import GradientButton from '../../../../../../components/ui/Buttons/GradientButton'
import { SelectField } from '../../../../../../components/ui/FormComponents/SelectField'
import { Loader } from '../../../../../../components/ui/Loader'
import { UserRoles } from '../../../../../../constants/profile'
import { LineItemCreateRequest } from '../../../../../../types/dtos/lineItems'
import { RawLineItem } from '../../../../../../types/RawLineItem'
import {
  LineItemsQuery,
  storeLineItem,
} from '../../../../../../utils/api/lineItems'
import { getRequestError } from '../../../../../../utils/helpers/validations'
import { useAuthData } from '../../../../../../utils/providers/AuthProvider'
import { AddLineItemWrapper } from '../LineItemsAndPricing.styles'

interface AddLineItemsProps {
  lineItemsQuery: LineItemsQuery
  lineItemTypes?: Record<string, string>
}

export const AddLineItem: React.FC<AddLineItemsProps> = ({
  lineItemTypes,
  lineItemsQuery,
}) => {
  const { key: queryKey, queryClient } = lineItemsQuery

  const { authUser } = useAuthData()
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const isDisabled = !type || !name

  const itemTypesOptions = useMemo(() => {
    if (lineItemTypes) {
      return Object.keys(lineItemTypes).map((key) => ({
        label: lineItemTypes[key],
        value: key,
      }))
    }
    return []
  }, [lineItemTypes])

  const getLineItemData = () => {
    const data: LineItemCreateRequest = {
      name,
      item_type: type,
    }

    if (UserRoles.isVendor(authUser?.role)) {
      const vendor = authUser?.vendors.find(
        (vendor) => vendor.id === authUser?.listing_id
      )
      data.vendor_id = authUser?.listing_id
      data.vendor_type = vendor?.type
    } else {
      data.venue_id = authUser?.listing_id
    }
    return data
  }

  const updateLineItemsQuery = (lineItem: RawLineItem) => {
    const previousItems = queryClient.getQueryData<{
      list: RawLineItem[]
      initial_payment_exclusives: number[]
    }>(queryKey)

    if (previousItems) {
      const mergedList = [lineItem, ...previousItems.list]
      queryClient.setQueryData(queryKey, { ...previousItems, list: mergedList })
    } else {
      queryClient.setQueryData(queryKey, { list: [lineItem] })
    }
  }

  const handleReset = () => {
    setName('')
    setType('')
    setError('')
  }

  const handleSubmit = async () => {
    if (!name) return
    setError('')
    setLoading(true)

    try {
      const data: LineItemCreateRequest = getLineItemData()

      const storedLineItem = await storeLineItem(data)
      updateLineItemsQuery(storedLineItem)
      handleReset()
    } catch (e) {
      setError(getRequestError(e))
    } finally {
      setLoading(false)
    }
  }

  return (
    <AddLineItemWrapper>
      <Box display="flex" flex={1} mr={3}>
        <TextField
          aria-label="Add line item"
          inputProps={{
            maxLength: 64,
          }}
          fullWidth
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!error}
          helperText={error}
          placeholder="Type name"
        />
      </Box>
      <Box display="flex" flex={1} mr={3} minWidth={50}>
        <SelectField
          label=""
          options={itemTypesOptions}
          value={type}
          placeholder="Choose type"
          onChange={(value) => setType(value)}
        />
      </Box>
      <Box width="24px" height="24px">
        <GradientButton
          disabled={isDisabled}
          style={{ minWidth: 24, height: 24, padding: 0 }}
          onClick={handleSubmit}
        >
          <Add style={{ color: 'white' }} />
        </GradientButton>
      </Box>
      {loading && <Loader position="fixed" />}
    </AddLineItemWrapper>
  )
}
