const ListIcon = ({ borderRadius = '0' }) => {
  return (
    <svg
      width="48"
      height="48"
      fill="none"
      style={{ borderRadius: borderRadius }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2a2 2 0 0 1 2-2h44a2 2 0 0 1 2 2v44a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Z"
        fill="#20202C"
      />
      <path
        d="M33.375 16.5H19.687a.188.188 0 0 0-.187.188V18c0 .103.084.188.188.188h13.687a.188.188 0 0 0 .188-.188v-1.313a.188.188 0 0 0-.188-.187Zm0 6.656H19.687a.188.188 0 0 0-.187.188v1.312c0 .103.084.188.188.188h13.687a.188.188 0 0 0 .188-.188v-1.312a.188.188 0 0 0-.188-.188Zm0 6.657H19.687A.188.188 0 0 0 19.5 30v1.313c0 .103.084.187.188.187h13.687a.188.188 0 0 0 .188-.188V30a.188.188 0 0 0-.188-.188Zm-18.938-12.47a1.311 1.311 0 1 0 2.623.001 1.311 1.311 0 0 0-2.622 0Zm0 6.657a1.312 1.312 0 1 0 2.624 0 1.312 1.312 0 0 0-2.623 0Zm0 6.656a1.312 1.312 0 1 0 2.624 0 1.312 1.312 0 0 0-2.623 0Z"
        fill="#fff"
      />
    </svg>
  )
}

export default ListIcon
