import { Box, Checkbox, Typography } from '@material-ui/core'
import { capitalize } from 'lodash'
import * as React from 'react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { SearchValuesChips } from '../../../../../../components/SearchValuesChips'
import ArrowDown from '../../../../../../components/svg/ArrowDown'
import ArrowUp from '../../../../../../components/svg/ArrowUp'
import {
  StyledAddLabelsButton,
  StyledLabelsPaper,
} from '../../../BusinessDetails.styles'

interface LabelsSelectProps {
  labels: any
  entity: any
  labelsControl: [any, Dispatch<SetStateAction<any>>]
  attachLabelMethod: any
  type: string
  previewMode?: boolean
}

export const LabelsSelect: React.FC<LabelsSelectProps> = ({
  labels,
  entity,
  labelsControl,
  attachLabelMethod,
  type,
  previewMode,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [currentLabels, setCurrentLabels] = labelsControl
  const { id: entityId } = entity
  const initialVendorLabels = currentLabels?.length
    ? currentLabels
        .filter((label: any) => label.type === type)
        .map((label: any) => label.name)
    : []
  const [currentSearchValues, setCurrentSearchValues] =
    useState<any[]>(initialVendorLabels)

  useEffect(() => {
    if (previewMode && (!currentLabels.length || labels.length))
      setCurrentLabels(labels)
    else return
  }, [labels.length, currentLabels.length])

  const onDeleteSearchValue = React.useCallback(
    async (value: string) => {
      const labelsToUpdate = currentLabels.filter(
        (item: { name: string }) => item.name !== value
      )

      try {
        const response = await attachLabelMethod(entityId, {
          labels: labelsToUpdate,
        })
        setCurrentLabels([...labelsToUpdate])
        const updatedLabels = response.labels
          .filter((label: any) => label.type === type)
          .map((label: any) => label.name)
        setCurrentSearchValues([...updatedLabels])
      } catch (e) {
        console.error(e)
      } finally {
        console.log('Label was deleted')
      }
    },
    [currentLabels, currentSearchValues]
  )

  const assignLabel = React.useCallback(
    async (labelId: string) => {
      const data = {
        labels: [...currentLabels, { id: Number(labelId) }],
      }
      try {
        const response = await attachLabelMethod(entity.id, data)
        setCurrentLabels([...response.labels])
        const updatedLabels = response.labels
          .filter((label: any) => label.type === type)
          .map((label: any) => label.name)
        setCurrentSearchValues([...updatedLabels])
      } catch (e) {
        console.error(e)
      } finally {
        console.log('Label was assigned to entity')
      }
    },
    [currentLabels]
  )
  const isLabelSelected = (name: string) =>
    currentLabels.map((item: any) => item.name).includes(name)

  const onChange = (label: any) => {
    if (isLabelSelected(label.name)) {
      onDeleteSearchValue(label.name)
    } else {
      assignLabel(label.id)
    }
  }

  useEffect(() => {
    if (initialVendorLabels && initialVendorLabels.length) {
      setCurrentSearchValues([...initialVendorLabels])
    }
  }, [initialVendorLabels.length])

  return (
    <Box width="100%" mr={2}>
      <Typography sx={{ mb: 3 }} color="#252733" fontWeight={500}>
        {capitalize(type)}
      </Typography>
      {!previewMode ? (
        <>
          {React.createElement(
            StyledAddLabelsButton,
            {
              // @ts-ignore
              isOpen,
              endIcon: isOpen ? <ArrowUp /> : <ArrowDown />,
              onClick: () => setIsOpen(!isOpen),
            },
            'Select from the list'
          )}
        </>
      ) : null}
      {isOpen ? (
        <StyledLabelsPaper>
          <Box width="100%">
            <Typography color="#9396A3" variant="body2">
              {capitalize(type)}
            </Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr">
            {labels.map((label: any) => (
              <Box key={label.id} display="flex" flex={1} alignItems="center">
                <Checkbox
                  onChange={() => onChange(label)}
                  checked={isLabelSelected(label.name)}
                  color="primary"
                />
                <Typography fontSize={14}>{label.name}</Typography>
              </Box>
            ))}
          </Box>
        </StyledLabelsPaper>
      ) : (
        <Box my={4}>
          {currentSearchValues.length ? (
            <Box mx={-1}>
              <SearchValuesChips
                previewMode={previewMode}
                searchValues={currentSearchValues}
                onDeleteSearchValue={onDeleteSearchValue}
              />
            </Box>
          ) : (
            '-'
          )}
        </Box>
      )}
    </Box>
  )
}
