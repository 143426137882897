import { isPartner, partnerSlug, partnerUrl } from '../constants/partners'

export function usePartner() {
  const partner = isPartner()

  return {
    isPartner: partner,
    partnerSlug: partnerSlug(),
    partnerUrl: partnerUrl(),
    isAbs: partner,
  }
}
