import { partnerSlug } from 'constants/partners'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enABS from '../locales/en/abs.json'
import en from '../locales/en/translation.json'

const resources = {
  en: {
    common: en,
    abs: enABS,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['common', 'abs'],
    defaultNS: partnerSlug() || 'common',
    fallbackNS: 'common',
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n

export const t = (name: string, params = {}) => {
  return i18n.t(name, params)
}
