import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { getUrlPartnerParam } from '../../../constants/partners'
import { usePartner } from '../../../hooks/usePartner'
import { AuthMenuActions, AuthMethods } from '../../../types/Auth'
import { Status } from '../../../types/general'
import { VenueItem } from '../../../types/Venue'
import { useVenues } from '../../../utils/api/venues'
import { validateEmail } from '../../../utils/helpers/validations'
import { googleSignInPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import Google from '../../svg/GoogleIcon'
import LinkText from '../../ui/Buttons/LinkText/LinkText'
import { SimpleButton } from '../../ui/Buttons/SimpleButton'
import { SelectField } from '../../ui/FormComponents/SelectField'
import { TextInput } from '../../ui/FormComponents/TextInput'
import { Loader } from '../../ui/Loader'
import { PhoneInputWrapper } from '../Auth.styles'
import { TermsAndConditions } from '../common/TermsAndConditions'

const SignUp: React.FC<{ onComplete?: (method: AuthMethods) => void }> = ({
  onComplete = () => {},
}) => {
  const {
    status,
    resetStatus,
    isLoading,
    register,
    updateRegisterData,
    error,
    authModalControl,
  } = useAuthData()
  const { isAbs } = usePartner()
  const [magicLink, setMagicLink] = useState(false)
  const [agreed, setAgreed] = useState(false)
  const [email, setEmail] = useState('')
  const [venuesForSelectField, setVenuesForSelectField] = React.useState<any[]>(
    []
  )
  const [currentAbsVenue, setCurrentAbsVenue] = useState<string>('')

  const isEmailValid = validateEmail(email)
  const showEmailError = Boolean(email && !isEmailValid)
  const method = magicLink ? AuthMethods.Magic : AuthMethods.Simple
  const canContinue =
    isEmailValid && agreed && (isAbs ? !!currentAbsVenue : true)
  const { data } = useVenues({}, { enabled: !!isAbs })
  const venues = data?.data || []

  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    return resetStatus
  }, [])

  useEffect(() => {
    if (status === Status.SUCCESS) {
      onComplete(method)
    }
  }, [status])

  useEffect(() => {
    if (!venues?.length) return
    setVenuesForSelectField(
      venues?.map((item: VenueItem) => ({
        label: item.name,
        value: item.id.toString(),
      }))
    )
  }, [venues?.length])

  const handleVenueChange = (value: string) => {
    setCurrentAbsVenue(value)
  }

  const handleSignUp = () => {
    setMagicLink(true)
    register({
      email,
      default_client_venue_id: isAbs ? +currentAbsVenue : null,
      magicLink: true,
      agreed: +agreed,
    })
  }

  const handleSignUpUsingEmail = () => {
    setMagicLink(false)
    updateRegisterData({
      email,
      default_client_venue_id: isAbs ? +currentAbsVenue : null,
      agreed: +agreed,
    })
    onComplete(AuthMethods.Simple)
  }

  const googleSignUpApiPath = useMemo(() => {
    if (isAbs && !+currentAbsVenue) return
    return isAbs
      ? `${googleSignInPath}?${getUrlPartnerParam()}&default_client_venue_id=${+currentAbsVenue}`
      : googleSignInPath
  }, [isAbs, currentAbsVenue])

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={4}>
        <Typography variant="h3">
          {t('auth.signUp.signUp', 'Sign Up')}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="h4">
          {t('auth.welcome', 'Welcome to BLACE!')}
        </Typography>
      </Box>
      <Box display="flex" mb={5}>
        <PhoneInputWrapper>
          <Typography color="#BDBDBD" textAlign="left" variant="body2">
            {t('auth.email.label', 'Email')}
          </Typography>
          <Box mt={1}>
            <TextInput
              error={showEmailError}
              name="email"
              placeholder={t('auth.email.placeholder', 'alex@gmail.com')}
              value={email}
              onChange={setEmail}
            />
          </Box>
          {isAbs && (
            <Box mt={2}>
              <Typography color="#BDBDBD" textAlign="left" variant="body2">
                {t('partners.abs.auth.venue.label', '*Building')}
              </Typography>
              <Box mt={1}>
                <SelectField
                  dropdownHeight="500px"
                  optionsWithoutPlaceholder
                  placeholder={t(
                    'partners.abs.auth.venue.placeholder',
                    'Select Building'
                  )}
                  options={venuesForSelectField}
                  value={currentAbsVenue}
                  onChange={handleVenueChange}
                />
              </Box>
            </Box>
          )}
          <Box mt={4}>
            <TermsAndConditions agreed={agreed} setAgreed={setAgreed} />
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography color="#F52222" variant="body2">
              {error}
            </Typography>
          </Box>
        </PhoneInputWrapper>
      </Box>
      <SimpleButton
        disabled={!canContinue}
        sx={{ mb: 5 }}
        variant="contained"
        onClick={handleSignUp}
      >
        {t('auth.signUp.sendMeLink', 'Send sign up link')}
      </SimpleButton>
      <Box mb={5} display="flex" justifyContent="center">
        <Typography variant="h4">Or</Typography>
      </Box>
      <SimpleButton
        disabled={!canContinue}
        sx={{ mb: 5, minHeight: 48, borderWidth: 1.6 }}
        variant="outlined"
        onClick={handleSignUpUsingEmail}
      >
        {t('auth.signUp.usingEmail', 'Sign up via email')}
      </SimpleButton>
      <SimpleButton
        variant="outlined"
        disabled={!agreed || (isAbs ? !currentAbsVenue : false)}
        sx={{ mb: 5, minHeight: 48, borderWidth: 1.6 }}
        onClick={() => {
          window.open(googleSignUpApiPath, '_self')
        }}
      >
        <Google mr={2} />
        <Typography variant="body1" fontSize={18} fontWeight={500}>
          {t('auth.signUp.google', 'Continue with Google')}
        </Typography>
      </SimpleButton>
      <Box mt="17px" textAlign="right" fontSize="14px">
        <LinkText
          style={{ display: 'inline-block' }}
          onClick={() => {
            history.push(history.location.pathname + '?action=login')
            authModalControl.toggleModal({ step: AuthMenuActions.Signin })
          }}
        >
          {t('auth.signUp.toLogIn', 'Already have an account? Go to Log In')}
        </LinkText>
      </Box>
      {isLoading && <Loader position="absolute" />}
    </Box>
  )
}

export default SignUp
