const LoginIcon = ({ fill = '#9396A3' }) => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3c-4.625 0-8.442 3.507-8.941 8.001h6.94v-3l5 4-5 4v-3H3.06C3.56 17.494 7.377 21 12 21c4.964 0 9-4.037 9-9s-4.036-9-9-9Z"
        fill="#D7D7D7"
      />
    </svg>
  )
}

export default LoginIcon
