import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Typography,
} from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import * as React from 'react'

import { ImageItem } from '../../../types/general'
import { Image } from '../Image'

const StyledSnackbar = styled(Snackbar)(
  ({ theme }) => `
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);
  background: white;
   
  .MuiSnackbarContent-root {
    background-color: white;
    min-height: 110px;
    min-width: 650px;
    color:${theme.palette.text.primary},
  }
  .MuiSnackbarContent-message {
      margin-left: 40px;
  }
`
)

interface AddVenueSnackbarProps {
  text?: string
  image?: ImageItem | string
  isFormImage?: boolean
  action?: () => void
  open: boolean
  onClose: () => void
}

export const CustomSnackbar: React.FC<AddVenueSnackbarProps> = ({
  text,
  image,
  isFormImage = false,
  action,
  open,
  onClose,
}) => {
  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={2500}
    >
      <ListItem alignItems="center" style={{ padding: 16 }}>
        <ListItemAvatar>
          {
            <Image
              style={{ height: 80, maxWidth: 80 }}
              image={image}
              imageSize={isFormImage ? 'original' : 'small'}
              staticImageType="gallery"
            />
          }
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box display="flex" justifyContent="space-between" ml={3}>
              <Box display="flex" flexDirection="column" mr={5}>
                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                  {text}
                </Typography>
              </Box>
              {/*{action && <GradientButton onClick={action}>Revert</GradientButton>}*/}
            </Box>
          }
        />
      </ListItem>
    </StyledSnackbar>
  )
}
