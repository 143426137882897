import { TableFooter, TableRow, Typography, useTheme } from '@material-ui/core'
import * as React from 'react'
import { Trans } from 'react-i18next'

import EditIcon from '../../../../../../components/svg/EditIcon'
import Send from '../../../../../../components/svg/Send'
import { InquiryInvoiceBundleStatuses } from '../../../../../../constants/inquiry'
import { dateWithSeparator } from '../../../../../../utils/helpers/date'
import {
  StyledIconButton,
  StyledInvoiceFooterCell,
} from '../../InquiryTables.styles'

import { BundleInvoicesTableProps } from './InvoicesBundleTable'

export const Footer: React.FC<
  BundleInvoicesTableProps & { isPossibleEdit: boolean }
> = ({ invoicesBundle, onEdit, onSend, isPossibleEdit }) => {
  const updatedDate = dateWithSeparator(
    new Date(invoicesBundle.updated_at),
    '/'
  )
  const version = invoicesBundle.version
  const theme = useTheme()

  return (
    <TableFooter>
      <TableRow>
        <StyledInvoiceFooterCell colSpan={6}>
          <Trans i18nKey="business.invoices.table.lastUpdate">
            <Typography variant="body2" fontWeight={600}>
              Last update {{ updatedDate }} (v: {{ version }})
            </Typography>
          </Trans>
        </StyledInvoiceFooterCell>
        <StyledInvoiceFooterCell align="right">
          {isPossibleEdit && (
            <StyledIconButton onClick={() => onEdit(invoicesBundle.id)}>
              <EditIcon />
            </StyledIconButton>
          )}
          {invoicesBundle.status ===
            InquiryInvoiceBundleStatuses.STATUS_DRAFT && (
            <StyledIconButton onClick={() => onSend(invoicesBundle.id)}>
              <Send fill={theme.palette.primary.light} />
            </StyledIconButton>
          )}
        </StyledInvoiceFooterCell>
      </TableRow>
    </TableFooter>
  )
}
