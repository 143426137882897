import { TextField, Typography } from '@material-ui/core'
import { RemoveCircle } from '@material-ui/icons'
import { FormikErrors, useFormikContext } from 'formik'
import { isObject } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CurrencyTextInput } from '../../../../../../components/ui/Inputs/CurrencyInput'
import {
  venueDefaultRentalLineItemValue,
  venueRentalLineItemId,
} from '../../../../../../constants'
import { currencyConvertToDecimal } from '../../../../../../utils/helpers/price'
import { StyledTableCell, StyledTableRow } from '../../../DocumentForms.styles'
import { GenerateInvoiceFormValues } from '../../GenerateInvoicesForm'

import { LineItemsSelect } from './LineItemsSelect'
import { LineItem, LineItemOption } from './types'

interface LineItemsTableRowProps {
  lineItemsList: LineItemOption[]
  lineItem: LineItem
  index: number
  onDeleteLineItem: () => void
  shouldBlockRental: boolean
}

export const LineItemsTableRow: React.FC<LineItemsTableRowProps> = ({
  lineItemsList,
  lineItem,
  index,
  onDeleteLineItem,
  shouldBlockRental,
}) => {
  const { errors, setFieldValue } =
    useFormikContext<GenerateInvoiceFormValues>()
  const { t } = useTranslation()

  const total = useMemo(() => {
    return lineItem.price
      ? Math.round(
          lineItem.quantity * currencyConvertToDecimal(lineItem.price) * 100
        ) / 100
      : ''
  }, [lineItem.price, lineItem.quantity])

  const isVenueRentalDefault = useMemo(() => {
    return (
      index === 0 &&
      shouldBlockRental &&
      (lineItem.value === venueDefaultRentalLineItemValue ||
        +lineItem.value === venueRentalLineItemId)
    )
  }, [index, shouldBlockRental, lineItem.value])

  const getErrors = (
    index: number,
    name: 'price' | 'quantity' | 'value' | 'groupName'
  ) => {
    const lineItemsErrors = errors.lineItems as Array<
      FormikErrors<{
        value: string
        quantity: number
        price?: string
        groupName: string
      }>
    >
    return isObject(lineItemsErrors?.[index])
      ? lineItemsErrors?.[index]?.[name]
      : ''
  }

  return (
    <StyledTableRow>
      <StyledTableCell sx={{ width: 0, paddingLeft: '20px' }}>
        <Typography variant="body2">{index + 1}.</Typography>
      </StyledTableCell>
      <StyledTableCell sx={{ width: 0 }}>
        <LineItemsSelect
          lineItemsList={lineItemsList}
          lineItem={lineItem}
          isVenueRentalDefault={isVenueRentalDefault}
          index={index}
        />
      </StyledTableCell>
      <StyledTableCell>
        <TextField
          value={lineItem.clarification}
          placeholder={t(
            'business.lineItems.descriptionPlaceholder',
            'Add description'
          )}
          type="text"
          color="secondary"
          fullWidth
          InputProps={{ inputProps: { maxLength: 255 } }}
          onChange={(event) =>
            setFieldValue(`lineItems.${index}`, {
              ...lineItem,
              clarification: event.target.value,
            })
          }
          sx={{ minWidth: '200px' }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <TextField
          error={!!getErrors(index, 'quantity')}
          value={lineItem.quantity}
          type="number"
          color="secondary"
          InputProps={{ inputProps: { min: 0 } }}
          placeholder="Qty"
          onChange={(event) => {
            setFieldValue(`lineItems.${index}`, {
              ...lineItem,
              quantity:
                event.target.value.split('.')[1]?.length > 2
                  ? parseFloat(event.target.value).toFixed(2)
                  : event.target.value,
            })
          }}
          sx={{ width: 'fit-content' }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <CurrencyTextInput
          error={!!getErrors(index, 'price')}
          value={lineItem.price}
          placeholder={'$0.00'}
          onChange={(event) =>
            setFieldValue(`lineItems.${index}`, {
              ...lineItem,
              price: event.target.value,
            })
          }
          style={{ width: 0 }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <CurrencyTextInput
          value={total}
          disabled
          placeholder={'$0.00'}
          onChange={() => {}}
          style={{ width: 0 }}
        />
      </StyledTableCell>
      <StyledTableCell
        style={{ position: 'relative', paddingRight: '20px', width: 0 }}
      >
        {!isVenueRentalDefault && (
          <RemoveCircle
            sx={{
              cursor: 'pointer',
              '&:hover': { opacity: 0.8 },
              color: '#9396A3',
              fontSize: 14,
            }}
            onClick={onDeleteLineItem}
          />
        )}
      </StyledTableCell>
    </StyledTableRow>
  )
}
