import { TableCell } from '@material-ui/core'
import React, { lazy, Suspense } from 'react'

import { Loader } from '../../../../components/ui/Loader'
import { StyledTableRow } from '../../../../components/ui/TableComponent/TableComponent.styles'
import { useIsAbsUser } from '../../../../hooks/useIsAbsUser'

const RowItem = lazy(() =>
  import('./BookingRowItem').then(({ BookingRowItem }) => ({
    default: BookingRowItem,
  }))
)
const AbsRowItem = lazy(() =>
  import('./AbsBookingRowItem').then(({ AbsBookingRowItem }) => ({
    default: AbsBookingRowItem,
  }))
)

export const BookingRowItem = ({
  event,
  ...props
}: React.ComponentProps<typeof RowItem | typeof AbsRowItem>) => {
  const isAbsUser = useIsAbsUser()
  const RowItemComponent = isAbsUser ? AbsRowItem : RowItem

  return (
    <StyledTableRow id={event.id.toString()}>
      <Suspense
        fallback={
          <TableCell colSpan={10}>
            <Loader />
          </TableCell>
        }
      >
        <RowItemComponent event={event} {...props} />
      </Suspense>
    </StyledTableRow>
  )
}
