const VenuesIconMobile = ({ fill = '#D7D7D7' }) => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.126 11.183a.3.3 0 0 0-.126.244v8.637a.3.3 0 0 0 .3.3h3.945a.3.3 0 0 0 .3-.3v-4.855a.3.3 0 0 1 .3-.3h3.037a.3.3 0 0 1 .3.3v4.855a.3.3 0 0 0 .3.3h3.945a.3.3 0 0 0 .3-.3v-8.637a.3.3 0 0 0-.125-.244L8.538 6.852a.3.3 0 0 0-.349 0l-6.063 4.331Z"
        fill={fill}
      />
      <path
        d="M10.482 4a.3.3 0 0 0-.3.3v1.337a.3.3 0 0 0 .125.244l6.113 4.366a.3.3 0 0 1 .125.244v.482a.3.3 0 0 0 .3.3h1.219a.3.3 0 0 1 .3.3v1.218a.3.3 0 0 1-.3.3h-1.218a.3.3 0 0 0-.3.3v1.218a.3.3 0 0 0 .3.3h1.218a.3.3 0 0 1 .3.3v1.218a.3.3 0 0 1-.3.3h-1.218a.3.3 0 0 0-.3.3v3.037a.3.3 0 0 0 .3.3H21.7a.3.3 0 0 0 .3-.3V4.3a.3.3 0 0 0-.3-.3H10.482Zm7.882 5.155a.3.3 0 0 1-.3.3h-1.218a.3.3 0 0 1-.3-.3V7.936a.3.3 0 0 1 .3-.3h1.218a.3.3 0 0 1 .3.3v1.219Z"
        fill={fill}
      />
    </svg>
  )
}

export default VenuesIconMobile
