import { BoxProps, Typography } from '@material-ui/core'
import * as React from 'react'

import {
  InquiryStages,
  mapStageToTitle,
} from '../../../../../constants/inquiry'

import {
  StyledStageCircle,
  StyledStageContainer,
} from './EventProgressItem.styles'

interface EventProgressItemProps {
  number: number
  stage: string
  currentStage?: string
  colorsInfo: any
}

export const EventProgressItem: React.FC<EventProgressItemProps & BoxProps> = ({
  number,
  stage,
  currentStage,
  colorsInfo,
}) => {
  const stageColors = colorsInfo?.[stage] ?? {}
  const isActive = currentStage === stage

  return (
    <StyledStageContainer
      borderColor={stageColors.border_color}
      bgColor={stageColors.bg_color}
      isActive={isActive}
    >
      <StyledStageCircle bgColor={stageColors?.text_color || ''}>
        <Typography
          variant="body2"
          fontWeight={600}
          color={(theme) => theme.palette.common.white}
        >
          {number}
        </Typography>
      </StyledStageCircle>
      <Typography
        variant="h4"
        fontFamily="Poppins"
        align="center"
        fontSize={12}
        fontWeight={600}
      >
        {mapStageToTitle[stage as InquiryStages]}
      </Typography>
    </StyledStageContainer>
  )
}
