import { useTheme } from '@material-ui/core'

import { usePartner } from '../../hooks/usePartner'

const Splash = () => {
  const { isAbs } = usePartner()
  const theme = useTheme()

  return (
    <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="40"
        cy="40"
        transform="rotate(-96.17 40 40)"
        fill={isAbs ? theme.palette.primary.main : '#4091F0'}
        r="36.308"
      />
      <circle
        cx="40"
        cy="40"
        transform="rotate(-96.17 40 40)"
        fill="url(#a)"
        r="36.308"
      />
      <path
        d="M39.516 23.755h2.66c.236 0 .354.118.354.355V55.31c0 .237-.118.355-.354.355h-2.66c-.236 0-.354-.118-.354-.355V24.11c0-.237.118-.355.354-.355Z"
        fill="#fff"
      />
      <path
        d="M25.954 38.026h29.784c.236 0 .354.119.354.355v2.66c0 .236-.118.354-.354.354H25.954c-.236 0-.354-.118-.354-.355v-2.66c0-.235.118-.354.354-.354Z"
        fill="#fff"
      />
      {!isAbs && (
        <defs>
          <linearGradient
            id="a"
            x1="40"
            y1="3.692"
            x2="40"
            y2="76.308"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4E4BEC" />
            <stop offset="1" stopColor="#3F91F0" />
          </linearGradient>
        </defs>
      )}
    </svg>
  )
}

export default Splash
