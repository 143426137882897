const Contacts = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.607 11.748c.367-.403.57-.93.569-1.475A2.181 2.181 0 0 0 10 8.086a2.181 2.181 0 0 0-2.176 2.187c0 .569.215 1.084.569 1.475a3.088 3.088 0 0 0-1.457 2.463.156.156 0 0 0 .156.164h.857a.156.156 0 0 0 .155-.146 1.91 1.91 0 0 1 1.898-1.768c1 0 1.824.781 1.898 1.768a.156.156 0 0 0 .155.146h.855a.157.157 0 0 0 .147-.102.155.155 0 0 0 .01-.062 3.087 3.087 0 0 0-1.46-2.463ZM10 11.289c-.557 0-1.01-.455-1.01-1.016 0-.56.453-1.015 1.01-1.015.557 0 1.01.455 1.01 1.015 0 .561-.453 1.016-1.01 1.016Zm8.125-6.914H15V3.281a.157.157 0 0 0-.156-.156H13.75a.157.157 0 0 0-.156.156v1.094h-2.89V3.281a.157.157 0 0 0-.157-.156H9.453a.157.157 0 0 0-.156.156v1.094h-2.89V3.281a.157.157 0 0 0-.157-.156H5.156A.157.157 0 0 0 5 3.281v1.094H1.875A.624.624 0 0 0 1.25 5v11.25c0 .346.28.625.625.625h16.25c.346 0 .625-.28.625-.625V5a.624.624 0 0 0-.625-.625Zm-.781 11.094H2.656V5.78H5v1.094c0 .086.07.156.156.156H6.25c.086 0 .156-.07.156-.156V5.781h2.89v1.094c0 .086.071.156.157.156h1.094c.086 0 .156-.07.156-.156V5.781h2.89v1.094c0 .086.071.156.157.156h1.094c.086 0 .156-.07.156-.156V5.781h2.344v9.688Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default Contacts
