import { Box, Link as MuiLink } from '@material-ui/core'
import * as React from 'react'
import { generatePath, Link } from 'react-router-dom'

import { HighlightedSearchQuery } from '../../../../../../../components/HighlightedSearchQuery'
import Location from '../../../../../../../components/svg/Location'
import { VenueItem } from '../../../../../../../types/Venue'
import { venuePath } from '../../../../../../../utils/paths'

import { PartnerItemWrapper } from './PartnersProperties.styles'

export const PartnerItem: React.FC<{
  query: string
  item: VenueItem
  color: string
  onSelectPartner: (item: VenueItem) => void
}> = ({ query, item, color, onSelectPartner }) => {
  return (
    <MuiLink
      component={Link}
      onClick={(e) => {
        e.preventDefault()
        onSelectPartner(item)
      }}
      to={() =>
        generatePath(venuePath, {
          slug: item.slug,
        })
      }
      color={color}
      fontSize="18px"
      fontFamily="Poppins"
      underline="none"
      width="100%"
    >
      <PartnerItemWrapper>
        <Box mr={2}>
          <Location fill={color} />
        </Box>
        {/*@ts-ignore*/}
        <HighlightedSearchQuery
          whiteSpace="normal"
          initialString={item.name}
          searchQuery={query}
          minQueryLength={3}
        />
      </PartnerItemWrapper>
    </MuiLink>
  )
}
