const Rooms = () => {
  return (
    <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.063.813H2.125a.313.313 0 0 0-.313.312v5.938c0 .171.141.312.313.312h5.938c.171 0 .312-.14.312-.313V1.125a.313.313 0 0 0-.313-.313ZM7.046 6.046H3.14V2.14h3.906v3.906ZM15.875.813H9.937a.313.313 0 0 0-.312.312v5.938c0 .171.14.312.313.312h5.937c.172 0 .313-.14.313-.313V1.125a.313.313 0 0 0-.313-.313Zm-1.016 5.234h-3.906V2.14h3.906v3.906Zm1.016 2.578H9.937a.313.313 0 0 0-.312.313v5.937c0 .172.14.313.313.313h5.937c.172 0 .313-.141.313-.313V8.937a.313.313 0 0 0-.313-.312Zm-1.016 5.234h-3.906V9.953h3.906v3.906Zm-7.578-1.953h-2.5v-2.5a.157.157 0 0 0-.156-.156h-.938a.157.157 0 0 0-.156.156v2.5h-2.5a.157.157 0 0 0-.156.156V13c0 .086.07.156.156.156h2.5v2.5c0 .086.07.156.156.156h.938c.086 0 .156-.07.156-.156v-2.5h2.5c.086 0 .157-.07.157-.156v-.938a.157.157 0 0 0-.157-.156Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default Rooms
