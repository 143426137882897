import {
  AssetLoadingStatus,
  AssetType,
  URLString,
  WidthHeight,
} from '../constants'

import { ImageItem } from './general'

// The minimal link object needed to reference an asset in a payload
export interface AssetRef {
  $assetRef: string
}
export type PayloadPrimitive = string | number | boolean | null
export type PayloadValue =
  | PayloadPrimitive
  | PayloadObject
  | AssetRef
  | AssetRefDownload
export type PayloadObject = { [member: string]: PayloadValue } | File | any

// The asset ref with returned in the payload with the expanded asset data
export interface AssetRefDownload<T = PayloadObject> extends AssetRef {
  size: number
  metadata: T
  signedUrl: string
  createdDate: string
  updatedDate: string
}

// EXTEND CMS IMPORTS
export interface AssetRefDownloadMetadata extends WidthHeight {
  assetType: AssetType
  fileName?: string
  fileBlob?: File
  previewUrl?: URLString
  uploadFailed?: boolean
  uploadCanceled?: boolean
  aboveFileLimit?: boolean
  duration?: number
  signedUrl?: string
}

export interface FileState {
  asset: AssetRefDownload
  progress?: number
  status?: AssetLoadingStatus
  cancelUpload?: () => void
}

export type AssetRefDownloadError = AssetRefDownload

// UI Specific
export interface MediaAsset {
  media: AssetRefDownload
  altText?: string
  coverImage?: AssetRefDownload
}

export type MediaAssetUploadError = { [K in keyof MediaAsset]?: string }

export interface UploadResponse {
  status: AssetLoadingStatus
  data: AssetRefDownloadError
}

export interface ProgressPayload {
  status: AssetLoadingStatus
  progress: number
}

// @ts-ignore
type ProgressCallback = ({ status: Loadingstatus, progress: number }) => void

export interface UploadAction {
  asset: AssetRefDownload
  progress?: ProgressCallback
}

export type UploaderFileState = Pick<
  FileState,
  'status' | 'progress' | 'cancelUpload'
>
export interface UploaderFilesState {
  [key: string]: UploaderFileState
}

export const getEmptyAsset = (
  assetType: AssetType,
  fileName = '',
  image = {} as ImageItem,
  metadata: Record<string, any> = {}
): AssetRefDownload => ({
  $assetRef: '',
  size: 0,
  metadata: {
    ...metadata,
    assetType,
    fileName,
    image,
  },
  signedUrl: '',
  createdDate: '',
  updatedDate: '',
})

export const getEmptyImageAsset = (image?: ImageItem): AssetRefDownload =>
  getEmptyAsset(AssetType.DASHBOARD_IMG, image?.filename, image, {
    imageId: image?.id,
  })

export const isEmptyAsset = (asset: AssetRefDownload): boolean =>
  !asset.$assetRef || (!asset.metadata.previewUrl && !asset.metadata.image.path)

export const isBlobUrl = (url: string): boolean => url.includes('blob')

export const isPreviewBlob = (src: string | undefined): boolean => {
  return Boolean(src && isBlobUrl(src))
}
