const VenuesIcon = ({ fill = '#9396A3', stroke = 'none' }) => {
  return (
    <svg width="20" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.126 7.183A.3.3 0 0 0 0 7.427v8.637a.3.3 0 0 0 .3.3h3.945a.3.3 0 0 0 .3-.3v-4.855a.3.3 0 0 1 .3-.3h3.037a.3.3 0 0 1 .3.3v4.855a.3.3 0 0 0 .3.3h3.945a.3.3 0 0 0 .3-.3V7.427a.3.3 0 0 0-.125-.244L6.538 2.852a.3.3 0 0 0-.349 0L.126 7.183Z"
        fill={stroke === 'none' ? fill : 'none'}
        stroke={stroke}
        strokeWidth="2"
      />
      <path
        d="M8.482 0a.3.3 0 0 0-.3.3v1.337a.3.3 0 0 0 .125.244l6.113 4.366a.3.3 0 0 1 .126.244v.482a.3.3 0 0 0 .3.3h1.218a.3.3 0 0 1 .3.3V8.79a.3.3 0 0 1-.3.3h-1.219a.3.3 0 0 0-.3.3v1.218a.3.3 0 0 0 .3.3h1.219a.3.3 0 0 1 .3.3v1.218a.3.3 0 0 1-.3.3h-1.219a.3.3 0 0 0-.3.3v3.037a.3.3 0 0 0 .3.3H19.7a.3.3 0 0 0 .3-.3V.3a.3.3 0 0 0-.3-.3H8.482Zm7.882 5.155a.3.3 0 0 1-.3.3h-1.219a.3.3 0 0 1-.3-.3V3.936a.3.3 0 0 1 .3-.3h1.219a.3.3 0 0 1 .3.3v1.219Z"
        fill={fill}
      />
    </svg>
  )
}

export default VenuesIcon
