import { Box, TextField, Typography } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { unionBy } from 'lodash'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { VENDORS_SORTING_FIELDS } from '../../../../constants/vendors'
import { LocalEvent } from '../../../../types/Event'
import { Vendor, VendorType } from '../../../../types/Vendor'
import { useVendors } from '../../../../utils/api/vendors'
import { SortOrder } from '../../../../utils/helpers/sort'
import { isExclusive } from '../../../../utils/helpers/vendors'
import { vendorsMapper } from '../../../../utils/mappers/vendors'
import { useStorageEventsData } from '../../../../utils/providers/EventsProvider'
import { useInquiryModalControl } from '../../../../utils/providers/InquiryModalControlProvider'
import { useVendorBasketData } from '../../../../utils/providers/VendorBasketProvider'
import { LsGetInquiryDetails } from '../../../../utils/storage'
import { SortingSelect } from '../../../marketplace/components/SortingSelect'
import { VendorsList } from '../../../vendors/components/VendorsList'

import { VendorsListQueryParams } from './InquiryBottomModal'

export const CategoryVendorsList: React.FC<{ category?: VendorType }> = ({
  category,
}) => {
  const eventDetails = LsGetInquiryDetails()
  const defaultQueryParams = {
    type: category?.id,
    venue_id: eventDetails?.venue?.id,
  }

  const [queryParams, updateQueryParams] = useState<VendorsListQueryParams>({
    ...defaultQueryParams,
  })

  const [lastPage, setLastPage] = useState(1)
  const [orderBy, setOrderBy] = useState<string>('Sort by')
  const [orderDirection, setOrderDirection] = useState<SortOrder>('asc')

  const { data } = useVendors(queryParams)
  const { openVendor } = useInquiryModalControl()
  const { pickedVendors } = useVendorBasketData()
  const { getUserActiveEvent } = useStorageEventsData()

  const userEvent: LocalEvent = getUserActiveEvent()

  const eventVendors = userEvent?.vendors || []

  const exclusive = isExclusive(data?.exclusivity)
  const vendors = data?.data.map((v: any) => vendorsMapper({ ...v, exclusive }))

  useEffect(() => {
    if (data?.last_page && data.last_page !== lastPage) {
      setLastPage(data?.last_page)
    }
  }, [data?.last_page])

  const onSort = (field: string) => {
    setOrderBy(field)
    if (field !== 'Sort by') {
      updateQueryParams({ ...queryParams, orderBy: field })
    } else {
      updateQueryParams({ ...queryParams, orderBy: '' })
    }
  }

  const onChangeDirection = (direction: SortOrder) => {
    setOrderDirection(direction)
    updateQueryParams({ ...queryParams, orderDirection: direction })
  }

  const onPaginationChange = (event: any, page: number) => {
    updateQueryParams({ ...queryParams, page })
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateQueryParams({ ...queryParams, name: event.target.value })
  }

  const navigateToDetails = (vendor: Vendor) => openVendor(vendor)

  const categoryDescription = useMemo(() => {
    if (exclusive) {
      return 'This is exclusive vendor that included in the selected venue. If you want to buyout it, please contact our support.'
    }

    return data?.exclusivity === 'preferred'
      ? 'Vendors list limited by preferred vendors from BLACE platform'
      : 'Select the vendors you are interested in by clicking the plus sign'
  }, [data?.exclusivity])

  const picked = unionBy(pickedVendors, eventVendors, 'id')

  return (
    <Box ml={20} mr={20} mb={8} mt={8}>
      <Box display="flex" flexDirection="column">
        <Typography
          style={{ textTransform: 'capitalize' }}
          variant="h4"
          color="textPrimary"
        >
          {category?.name}
        </Typography>
        <Typography variant="body2" color="textPrimary">
          {categoryDescription}
        </Typography>
      </Box>
      <Box mt={3} justifyContent="space-between" display="flex">
        <TextField
          className="vendors-search"
          variant="standard"
          color="secondary"
          placeholder="Search"
          fullWidth
          style={{ display: 'contents', marginRight: '20px' }}
          InputProps={{
            disableUnderline: true,
            startAdornment: <Search />,
          }}
          onChange={handleSearch}
        />
        {/*<Select value="Filter" id="filter" label="Filter" sx={{ m: 1, minWidth: 200 }}>*/}
        {/*  <MenuItem key={0} value="Filter">*/}
        {/*    <em>Filter</em>*/}
        {/*  </MenuItem>*/}
        {/*</Select>*/}
        <Box mt={1} minWidth={200}>
          <SortingSelect
            sortingFields={VENDORS_SORTING_FIELDS}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onChangeDirection={onChangeDirection}
            onSort={onSort}
          />
        </Box>
      </Box>
      <VendorsList
        page={queryParams.page}
        lastPage={lastPage}
        onPaginationChange={onPaginationChange}
        navigateAction={navigateToDetails}
        vendors={vendors}
        pickedVendors={picked}
      />
    </Box>
  )
}
