const House = ({ fill = '#9396A3', scale = 1 }) => {
  return (
    <svg
      width="16"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`scale(${scale})`}
    >
      <path
        d="M8.62.492a1 1 0 0 0-1.24 0l-7 5.543a1 1 0 0 0-.38.784V17a1 1 0 0 0 1 1h3.504a1 1 0 0 0 1-1v-5.042a1 1 0 0 1 1-1h2.992a1 1 0 0 1 1 1V17a1 1 0 0 0 1 1H15a1 1 0 0 0 1-1V6.819a1 1 0 0 0-.38-.784l-7-5.543Z"
        fill={fill}
      />
    </svg>
  )
}

export default House
