import { Box, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EditButton } from '../../../../../../components/ui/Buttons/EditButton'
import { SimpleButton } from '../../../../../../components/ui/Buttons/SimpleButton'
import { CostLevelInput } from '../../../../../../components/ui/Inputs/CostLevelInput'
import { CurrencyInputWrapper } from '../../../../../../components/ui/Inputs/CurrencyInput'
import { Loader } from '../../../../../../components/ui/Loader'
import { UserRoles } from '../../../../../../constants/profile'
import { BusinessGeneralInfo } from '../../../../../../types/general'
import {
  currencyConvertToDecimal,
  formatToCurrency,
} from '../../../../../../utils/helpers/price'
import { getCostLevel } from '../../../../../../utils/helpers/vendors'
import { useAuthData } from '../../../../../../utils/providers/AuthProvider'
import { BlockTitle } from '../../../BusinessDetails.styles'

import { LandlordEditPricing } from './LandlordEditPricing'
import { LandlordPricingSwitcher } from './LandlordPricingSwitcher'

interface PricingProps {
  onSave?: (data: Partial<BusinessGeneralInfo>) => void
  orderPrice?: string | number
  pricingDetails?: string
  shortPricingDescription?: {
    value: string
    label: string
  }
  displayPricing?: boolean
}

export const Pricing: React.FC<PricingProps> = ({
  onSave,
  orderPrice,
  pricingDetails,
  shortPricingDescription,
  displayPricing,
}) => {
  const { role } = useAuthData()
  const [isEditMode, setEditMode] = useState(false)
  const [pricing, setPricing] = useState(orderPrice ? `${orderPrice}` : '')
  const [longPricingText, setLongPricingText] = useState(
    pricingDetails ? pricingDetails : ''
  )
  const [shortPricingText, setShortPricingText] = useState(
    shortPricingDescription
      ? shortPricingDescription
      : {
          value: '',
          label: '',
        }
  )
  const [showPricing, setShowPricing] = useState(
    displayPricing ? displayPricing : false
  )
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [switchTouched, setSwitchTouched] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!pricing && orderPrice) {
      setPricing(orderPrice.toString())
    }
  }, [orderPrice])

  useEffect(() => {
    if (!pricingDetails?.length) return
    setLongPricingText(pricingDetails)
  }, [pricingDetails?.length])

  useEffect(() => {
    if (!displayPricing) return
    setShowPricing(displayPricing)
  }, [displayPricing])

  useEffect(() => {
    if (!shortPricingDescription?.value) return
    setShortPricingText({
      value: shortPricingDescription.value,
      label: shortPricingDescription.label,
    })
  }, [shortPricingDescription?.value])

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (submitted) {
      setSubmitted(false)
    }
    setPricing(e.target.value)
  }

  const handleSubmit = async () => {
    setSubmitted(true)
    setLoading(true)
    if (
      (UserRoles.isVendor(role) && pricing) ||
      (UserRoles.isLandlord(role) &&
        (!showPricing ||
          (showPricing &&
            pricing &&
            pricing !== '$0' &&
            shortPricingText.value)))
    ) {
      const payload = {} as Partial<BusinessGeneralInfo>
      if (UserRoles.isLandlord(role)) {
        payload.params = {
          display_pricing: showPricing,
        }
        payload.payment_structure = {
          pricing_details: longPricingText || '',
        }

        if (showPricing) {
          payload.params.short_pricing_details = shortPricingText.value
          payload.params.minimum_starting_at = currencyConvertToDecimal(pricing)
        }
      } else {
        payload.order_price_min = currencyConvertToDecimal(pricing)
      }
      onSave && (await onSave(payload))
      setEditMode(false)
    }
    setLoading(false)
  }

  const renderPricingInput = () => {
    if (UserRoles.isLandlord(role)) {
      return (
        <LandlordEditPricing
          pricing={pricing}
          showPricing={showPricing}
          handlePriceChange={(e: ChangeEvent<HTMLInputElement>) =>
            handlePriceChange(e)
          }
          submitted={submitted}
          shortPricingTextValue={shortPricingText.value}
          setShortPricingTextValue={(value: string) =>
            setShortPricingText((prevShortText) => {
              return {
                ...prevShortText,
                value,
              }
            })
          }
          longPricingText={longPricingText}
          setLongPricingText={setLongPricingText}
        />
      )
    }
    return (
      <CostLevelInput
        value={pricing}
        renderInput={(fieldValue) => {
          return (
            <CurrencyInputWrapper>
              <Typography textAlign="left" variant="body2" sx={{ mb: 2 }}>
                {t('business.pricing.vendor.starting', 'Starting Cost')}
              </Typography>
              <input
                value={fieldValue || ''}
                readOnly
                onChange={() => {}}
                type="text"
              />
            </CurrencyInputWrapper>
          )
        }}
        setValue={(value) => {
          setPricing(value.toString())
        }}
      />
    )
  }

  const pricingString = useMemo(() => {
    if (UserRoles.isVendor(role) && !pricing) {
      return t('business.pricing.vendor.notSet', 'Pricing not set yet')
    } else if (UserRoles.isLandlord(role) && !showPricing) {
      return t('business.pricing.landlord.notDisplay', 'Inquire for Pricing')
    }
    return UserRoles.isLandlord(role)
      ? `${formatToCurrency(pricing)} / ${shortPricingDescription?.label}`
      : `${getCostLevel(pricing)} / ${t('business.pricing.perDay', 'per day')}`
  }, [pricing, showPricing, shortPricingDescription?.label])

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <BlockTitle>{t('business.pricing.title', 'Pricing')}</BlockTitle>
        {isEditMode ? (
          <Box display="flex">
            {UserRoles.isLandlord(role) && (
              <LandlordPricingSwitcher
                showPricing={showPricing}
                setShowPricing={setShowPricing}
                setSwitchTouched={setSwitchTouched}
              />
            )}
            <Box
              display="flex"
              alignItems="center"
              mr={3}
              style={{ cursor: 'pointer', color: '#9396A3' }}
              onClick={() => {
                if (switchTouched && showPricing !== displayPricing)
                  setShowPricing(!showPricing)
                setSwitchTouched(false)
                setPricing(orderPrice ? `${orderPrice}` : '')
                setLongPricingText(pricingDetails ? pricingDetails : '')
                setShortPricingText((prevShortText) => {
                  return {
                    ...prevShortText,
                    value: shortPricingDescription?.value
                      ? shortPricingDescription?.value
                      : '',
                  }
                })
                setEditMode(false)
              }}
            >
              {t('common.actions.cancel', 'Cancel')}{' '}
              <ClearIcon style={{ marginLeft: 10 }} />
            </Box>
            <Box>
              <SimpleButton
                style={{ fontSize: 16 }}
                onClick={handleSubmit}
                variant="outlined"
              >
                {t('common.actions.save', 'Save')}
              </SimpleButton>
            </Box>
          </Box>
        ) : (
          <Box>
            <EditButton onClick={() => setEditMode(true)} />
          </Box>
        )}
      </Box>
      <Box display="flex" mb={isEditMode ? 4 : 10} flexDirection="column">
        {isEditMode ? (
          renderPricingInput()
        ) : (
          <>
            <Typography sx={{ mb: 2 }} color="#9396A3" variant="body2">
              {UserRoles.isLandlord(role)
                ? t('business.pricing.landlord.staring', 'Minimum Rate')
                : t('business.pricing.vendor.starting', 'Starting Cost')}
            </Typography>
            <Typography variant="body2">{pricingString}</Typography>
            {UserRoles.isLandlord(role) && (
              <Box mt={3}>
                <Typography sx={{ mb: 2 }} color="#9396A3" variant="body2">
                  {t('business.pricing.landlord.details', 'Pricing Details')}
                </Typography>
                {longPricingText ? (
                  <Typography
                    dangerouslySetInnerHTML={{ __html: longPricingText }}
                  />
                ) : (
                  <Typography variant="body2">-</Typography>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
      {loading && <Loader position="fixed" />}
    </>
  )
}
