import { Box, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const SectionHeader = styled(Typography)`
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`

export const FormContainer = styled('form')`
  display: flex;
  width: 100%;
`

export const FieldLabel = styled(Typography)`
  color: #9396a3;
  text-align: left;
  margin-bottom: 15px;
`

export const FieldWrapper = styled(Box)`
  width: 100%;

  .MuiFormControl-root input {
    padding: 11px 16px;
  }

  .MuiFormControl-root .MuiSelect-select {
    padding: 11px 16px;
  }
`
export const ScreenContent = styled(Box)`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
export const ScrollContainer = styled(Box)`
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`
export const VenueTypeContent = styled(Box)`
  border: 1px solid #d7d7d7;
  display: flex;
  align-items: center;

  ${MEDIA_RULES.SMALL} {
    padding: 12px;
    border: none;
    background: #f7f7f8;
    box-shadow: 0 4px 18px rgba(210, 210, 210, 0.4);
    border-radius: 2px;
  }

  ${(p: { active?: boolean }) => p.active && `border: 1px solid #2F54EB;`}

  .event-img {
    cursor: pointer;
    background: #2f54eb;
    margin: 10px 15px;
    width: 60px;
    height: 60px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${MEDIA_RULES.SMALL} {
      width: 40px;
      height: 40px;
      min-width: 40px;
      margin: 0;
    }
  }

  img {
    object-fit: cover;
  }
`

export const AddVenueFormLabel = styled(Typography)`
  font-weight: 600;
  text-align: left;
  margin-bottom: 15px;
`
