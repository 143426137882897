import { Box } from '@material-ui/core'
import { flatten } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentsEmptyState } from '../../../../../../components/Documents/DocumentsEmptyState'
import { InvoicesTotals } from '../../../../../../components/InvoicesTotals/InvoicesTotals'
import { useInvoicesBundlesByStatuses } from '../../../../../../hooks/useInvoicesBunslesByStatuses'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import {
  InquiryInvoiceResponse,
  Invoice,
} from '../../../../../../types/dtos/inquiry'
import { InquiryWithDetails } from '../../../../../../types/Inquiry'
import { getInvoiceFromBundleAndInvoice } from '../../../../../../utils/helpers/inquiries'
import { TabsTypography } from '../../../ServiceBookingDetails.styles'

import { InvoicesTable } from './InvoicesTable'

interface InvoicesProps {
  inquiryDetails?: InquiryWithDetails
  viewAndPay: (invoiceBundle: InquiryInvoiceResponse, invoice: Invoice) => void
  openMessengerHandler: () => void
}

export const Invoices: React.FC<InvoicesProps> = ({
  inquiryDetails,
  viewAndPay,
  openMessengerHandler,
}) => {
  const { t } = useTranslation()
  const { sentInvoicesBundles, outdatedInvoicesBundles } =
    useInvoicesBundlesByStatuses(inquiryDetails?.invoice_bundles)
  const [notOutdatedInvoices, setNotOutdatedInvoices] = useState<any[]>([])
  const [outdatedInvoices, setOutdatedInvoices] = useState<any[]>([])
  const { isMobile } = useWindowSize()

  useEffect(() => {
    setNotOutdatedInvoices(
      flatten(
        sentInvoicesBundles.map((bundle) => {
          return bundle.invoices.map((invoice) =>
            getInvoiceFromBundleAndInvoice(bundle, invoice)
          )
        }) || []
      )
    )
  }, [sentInvoicesBundles, inquiryDetails?.status])

  useEffect(() => {
    setOutdatedInvoices(
      flatten(
        outdatedInvoicesBundles.map((bundle) => {
          return bundle.invoices.map((invoice) =>
            getInvoiceFromBundleAndInvoice(bundle, invoice)
          )
        }) || []
      )
    )
  }, [outdatedInvoicesBundles])

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={1}
      mb={isMobile ? '20px' : 0}
    >
      {!isMobile && (
        <Box display="flex" alignItems="flex-start" mb={4}>
          <TabsTypography>
            {t('inquiry.invoices.title', 'Invoices')}
          </TabsTypography>
        </Box>
      )}
      <InvoicesTotals invoices={notOutdatedInvoices} />
      {notOutdatedInvoices.length ? (
        <div style={{ overflowX: 'auto' }}>
          <InvoicesTable
            viewAndPay={viewAndPay}
            openMessengerHandler={openMessengerHandler}
            notOutdatedInvoices={notOutdatedInvoices}
            outdatedInvoices={outdatedInvoices}
            inquiryDetails={inquiryDetails}
          />
        </div>
      ) : (
        <DocumentsEmptyState
          title={t(
            'business.invoices.emptyState',
            'Invoices will be displayed here'
          )}
        />
      )}
    </Box>
  )
}
