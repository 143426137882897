import { Box, styled } from '@material-ui/core'
import * as React from 'react'
import { RefObject } from 'react'

export interface ImageUploaderFieldProps {
  accept: string[]
  children: any
  inputFileRef: RefObject<HTMLInputElement>

  onChange(event: React.ChangeEvent): void
}

const HiddenFileInput = styled('input')`
  opacity: 0;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  width: 0;
`

export const DocumentsUploaderField: React.FC<ImageUploaderFieldProps> = ({
  accept,
  children,
  inputFileRef,
  onChange,
}) => {
  return (
    <Box>
      <HiddenFileInput
        type="file"
        ref={inputFileRef}
        accept={accept.toString()}
        onChange={onChange}
      />
      {children}
    </Box>
  )
}
