import {
  styled,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { DocumentsStyledHeaderRow } from '../../../ServiceBookingDetails.styles'

export const ContractsTableHeader = () => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  return (
    <TableHead>
      <DocumentsStyledHeaderRow>
        <TableCell style={{ width: 30 }}>
          <Typography variant="body2" color="textSecondary">
            {t('inquiry.contract.table.version', 'Version')}
          </Typography>
        </TableCell>
        {!isMobile && (
          <TableCell>
            <Typography variant="body2" color="textSecondary">
              {t('inquiry.contract.table.generationDate', 'Date of generation')}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2" color="textSecondary">
            {t('inquiry.contract.table.status', 'Status')}
          </Typography>
        </TableCell>
        {!isMobile && (
          <TableCell>
            <Typography variant="body2" color="textSecondary">
              {t('inquiry.contract.table.actions', 'Actions')}
            </Typography>
          </TableCell>
        )}
      </DocumentsStyledHeaderRow>
    </TableHead>
  )
}
