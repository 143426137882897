import { Box } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const StyledPromotionLabel = styled(Box)`
  position: absolute;
  left: 0;
  top: 25px;
  background: #ffe870;
  display: flex;
  align-items: center;

  ${MEDIA_RULES.SMALL} {
    top: 0;
    right: 0;
    left: auto;
    padding: 10px 9px 10px 12px;
    width: 160px;
  }
`
