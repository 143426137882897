import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { downloadInquiryContractById } from '../utils/api/inquiries'
import { getRequestError } from '../utils/helpers/validations'
import { useMessageModalData } from '../utils/providers/MessageModalProvider'

export const useDownloadContract = () => {
  const { t } = useTranslation()

  const { showMessage, closeMessageDialog } = useMessageModalData()

  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadContract = async (contractId: number) => {
    setIsLoading(true)

    try {
      const response = await downloadInquiryContractById(contractId.toString())
      const url = window.URL.createObjectURL(response)

      showMessage({
        title: t(
          'messages.documents.contract.url',
          'URL for contract has been successfully generated'
        ),
        buttons: [
          {
            label: t('messages.documents.action', 'Open in new tab'),
            action: () => {
              window.open(url, '_blank')
              closeMessageDialog()
            },
          },
        ],
      })
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, handleDownloadContract }
}
