import { css, Global } from '@emotion/react'
import * as React from 'react'
import { ToastContainer, ToastContainerProps } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { TOAST_NOTIFICATION_CONTAINER_ID } from '../../constants'

const globalStyles = css`
  #toast-notification {
    .toast-container {
      width: auto;
      max-width: 950px;

      .toast {
        min-height: 60px;
        padding: 0;
        background: transparent;
        pointer-events: none;
        box-shadow: none;
      }

      .body {
        padding: 0;
      }

      .alert-container {
        align-items: center;
        min-height: 60px;
        pointer-events: auto;
      }
    }
  }
`

/**
 * ToastContainer for toast rendering
 * all the props could be overridden by toast function call invocation
 */
export const ToastNotificationContainer: React.FC<ToastContainerProps> = (
  props
) => (
  <>
    <Global styles={globalStyles} />
    <ToastContainer
      closeButton={false}
      hideProgressBar
      className="toast-container"
      toastClassName="toast"
      bodyClassName="body"
      progressClassName="progress"
      enableMultiContainer
      containerId={TOAST_NOTIFICATION_CONTAINER_ID}
      limit={1}
      closeOnClick={false}
      draggable={false}
      {...props}
    />
  </>
)
