import { Checkbox, CheckboxProps } from '@material-ui/core'

export const SelectionState = Object.freeze({
  All: 'ALL',
  Partial: 'PARTIAL',
  None: 'NONE',
} as const)
export type Selection = typeof SelectionState[keyof typeof SelectionState]

export const PartialCheckbox = (
  props: CheckboxProps & { value: Selection }
) => (
  <Checkbox
    checked={props.value !== SelectionState.None}
    indeterminate={props.value === SelectionState.Partial}
    {...props}
  />
)
