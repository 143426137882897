import { Box, Typography } from '@material-ui/core'
import * as React from 'react'

import { EventDraftStatus } from '../../../constants'
import { EventStatus } from '../../../constants/events'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { LocalEvent } from '../../../types/Event'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { EventItem } from '../components/EventItem'
import { AddNewEventItem } from '../components/EventItem/AddNewEventItem'

import { EventList } from './EventsList'

type EventList = Record<
  string,
  { type: string; title?: string; data: LocalEvent[] }
>

export const RenderEventItems = ({
  events,
  handleOnEventSelect,
  handleEventClick,
  createEventHandler,
  isLoading,
}: any) => {
  const { getUserActiveEvent } = useStorageEventsData()
  const activeEvent = getUserActiveEvent()

  const { isMobile } = useWindowSize()
  const eventSectionsByStatus: EventList = {
    active: { type: 'active', data: [] },
    finished: { type: 'finished', title: 'Finished', data: [] },
  }

  const getSectionByStatus = (active: boolean, status: EventStatus) => {
    if (
      !active &&
      (status === EventStatus.STATUS_CANCELED ||
        status === EventStatus.STATUS_CLOSED ||
        status === EventStatus.STATUS_ABANDONED ||
        status === EventStatus.STATUS_COMPLETED ||
        status === EventStatus.STATUS_CANCELED_BY_ADMIN)
    )
      return 'finished'
    else return 'active'
  }

  events.forEach((event: any) => {
    event.status = event.status || EventDraftStatus
    const section = getSectionByStatus(!!event.active, event.status)
    eventSectionsByStatus[section].data.push(event)
  })

  const sectionsKeys = Object.keys(eventSectionsByStatus)
  const isEmptyStatus = sectionsKeys.every(
    (key) => eventSectionsByStatus[key]?.data.length <= 0
  )

  if (isEmptyStatus) {
    return (
      <Box className="events-grid" mt={2}>
        <AddNewEventItem handleClick={createEventHandler} />
      </Box>
    )
  }

  return sectionsKeys.map((key) => {
    const eventSection = eventSectionsByStatus[key]

    if (eventSection?.data.length > 0) {
      return (
        <Box
          key={key}
          sx={{ marginBottom: '50px', '&:last-of-type': { marginBottom: 0 } }}
        >
          {eventSection.title && (
            <Typography
              variant="body2"
              color="#9396A3"
              className="period-title"
            >
              {eventSection.title}
            </Typography>
          )}
          <Box className="events-grid">
            {eventSection.type === 'active' && (
              <EventItem
                handleOnEventSelect={handleOnEventSelect}
                handleEventClick={handleEventClick}
                isLoading={isLoading}
                event={activeEvent}
              />
            )}
            {eventSection.data
              .filter((event) => {
                if (eventSection.data.length >= 1) {
                  return event.id !== activeEvent?.id
                } else {
                  return event
                }
              })
              .map((event, index) => (
                <EventItem
                  key={index}
                  handleOnEventSelect={handleOnEventSelect}
                  handleEventClick={handleEventClick}
                  isLoading={isLoading}
                  event={event}
                />
              ))}
            {eventSection.type === 'active' && !isMobile && (
              <AddNewEventItem handleClick={createEventHandler} />
            )}
          </Box>
        </Box>
      )
    }
    return null
  })
}
