const PDFDocument = () => {
  return (
    <svg width="45" height="45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.534 39.446a2 2 0 0 1-2 2H10.277a2 2 0 0 1-2-2V5.538a2 2 0 0 1 2-2h17.294a2 2 0 0 1 1.404.577l7.964 7.862a2 2 0 0 1 .595 1.423v26.046Z"
        fill="#848484"
        fillOpacity=".12"
      />
      <path d="M36.162 13.467h-8.685V4.892l8.685 8.575Z" fill="#DFE0EB" />
      <path
        d="M15.592 19.785h15.542v1.805H15.592v-1.805ZM15.592 23.395h11.885V25.2H15.592v-1.805ZM15.592 27.005h15.542v1.805H15.592v-1.805ZM15.592 30.615h11.885v1.805H15.592v-1.805ZM3.473 10.269V8.453H4.48c.712 0 1.032.336 1.032.912 0 .56-.32.904-1.032.904H3.473Zm2.976-.904c0-.904-.648-1.656-1.968-1.656H2.56v5.56h.912v-2.256H4.48c1.448 0 1.968-.856 1.968-1.648Zm2.686-1.656H7.319v5.56h1.816c1.784 0 2.952-1.048 2.952-2.76 0-1.72-1.168-2.8-2.952-2.8Zm-.904 4.816V8.453h.904c1.312 0 2.016.784 2.016 2.056 0 1.264-.704 2.016-2.016 2.016H8.23Zm4.76.744h.912v-2.432h1.84v-.744h-1.84v-1.64h2.36v-.744H12.99v5.56Z"
        fill="#2F54EB"
      />
    </svg>
  )
}

export default PDFDocument
