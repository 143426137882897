import { css } from '@emotion/react'

export const flexRow = css`
  display: flex;
  flex-direction: row;
`
export const flexCol = css`
  display: flex;
  flex-direction: column;
`

export const centerX = css`
  ${flexRow};
  align-items: center;
`

export const centerY = css`
  ${flexCol};
  align-items: center;
`
