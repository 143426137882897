import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import CheckedCircleFilled from '../../../svg/CheckedCircleFilled'

import { StyledAddedLabel } from './AddedToEvent.styles'

export const AddedToEventLabel: React.FC = () => {
  const { t } = useTranslation()

  return (
    <StyledAddedLabel>
      <Box>
        <CheckedCircleFilled />
      </Box>
      <Typography
        ml="7px"
        color={(theme) => theme.palette.primary.light}
        fontWeight={500}
        fontSize={12}
        letterSpacing={0}
      >
        {t('events.items.added', 'Added')}
      </Typography>
    </StyledAddedLabel>
  )
}
