const Stars = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55 12.5L7.5 7L5.45 12.5H0L4.45 15.9L2.4 22L7.5 18.25L12.6 22L10.55 15.85L15 12.5H9.55ZM9.6 16.2L10.65 19.35L8.05 17.45L7.5 17L6.9 17.45L4.3 19.35L5.35 16.2L5.6 15.5L5.05 15.05L2.95 13.5H6.15L6.4 12.85L7.5 9.9L8.6 12.85L8.85 13.5H12.05L9.95 15.1L9.4 15.5L9.6 16.2ZM22 4H18L16.5 0L15 4H11L14.25 6.5L12.75 11L16.5 8.25L20.25 11L18.75 6.5L22 4ZM17.1 7.45L16.5 7L15.9 7.45L14.7 8.35L15.2 6.85L15.45 6.2L14.9 5.75L13.95 5H15.7L15.95 4.35L16.5 2.85L17.05 4.35L17.3 5H19.05L18.1 5.7L17.55 6.15L17.75 6.8L18.25 8.3L17.1 7.45Z"
        fill="black"
      />
    </svg>
  )
}

export default Stars
