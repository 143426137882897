import { Box } from '@material-ui/core'
import { Formik } from 'formik'
import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import ArrowBack from '../../../../components/svg/ArrowBack'
import { getPartnerParams } from '../../../../constants/partners'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { UpdateProfileRequest } from '../../../../types/dtos/profile'
import { EventVenue } from '../../../../types/Event'
import { postPersonalInfo } from '../../../../utils/api/profile'
import { getRequestError } from '../../../../utils/helpers/validations'
import { useAuthData } from '../../../../utils/providers/AuthProvider'
import { useMessageModalData } from '../../../../utils/providers/MessageModalProvider'
import { ProfileDivider, StyledCaption } from '../../Profile.styles'
import { TabSubtitle } from '../ui/TabSubtitle'

import { PersonalInfoForm } from './PersonalInfoForm'
import { createValidationSchema } from './validationSchema'

export const PersonalInfo: React.FC<{
  isAbs: boolean
  currentAbsVenue: EventVenue | null
  setCurrentAbsVenue?: Dispatch<SetStateAction<EventVenue | null>>
  onClose?: () => void
}> = ({ isAbs, currentAbsVenue, setCurrentAbsVenue, onClose }) => {
  const { isMobile } = useWindowSize()
  const { authUser, updateUser } = useAuthData()
  const { showMessage } = useMessageModalData()
  const { t } = useTranslation()
  const history = useHistory()

  const defaultPersonalInfoFormValue: UpdateProfileRequest = {
    name: authUser?.name || '',
    phone: authUser?.phone || '',
    google: !!authUser?.google_id,
  }

  const onSubmit = React.useCallback(
    async (values: UpdateProfileRequest, { setSubmitting, resetForm }) => {
      const replacedPhoneNumber = values.phone?.replace(/\D+/g, '')
      const personalInfo = {
        name: values.name,
        phone: replacedPhoneNumber,
        default_client_venue_id: isAbs ? currentAbsVenue?.id : null,
        ...(isAbs ? getPartnerParams() : {}),
      }
      try {
        const response: any = await postPersonalInfo(personalInfo)
        updateUser(response.data)
        showMessage({
          title: t(
            'profile.info.saved',
            'Personal info has been successfully saved!'
          ),
        })
      } catch (e) {
        setSubmitting(false)
        showMessage({
          title: getRequestError(e),
          type: 'error',
        })
      } finally {
        console.log('personal info has been updated')
      }
      resetForm()
    },
    [currentAbsVenue?.id, isAbs]
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      maxWidth={isMobile ? '100%' : 810}
    >
      <Box display="flex" justifyContent="space-between" mb={isMobile ? 0 : 3}>
        <Box>
          {isMobile ? (
            <Box display="flex" alignItems="baseline" mt={isMobile ? 5 : 0}>
              <Box
                pr={2}
                onClick={() => {
                  history.goBack()
                  onClose && onClose()
                }}
              >
                <ArrowBack fill="#000" />
              </Box>
              <TabSubtitle
                subtitle={t('profile.tabs.info.title', 'Personal info')}
              />
            </Box>
          ) : (
            <>
              <TabSubtitle
                subtitle={t('profile.tabs.info.title', 'Personal info')}
              />
              <Box display="flex" alignItems="baseline">
                <StyledCaption variant="caption">
                  {t(
                    'profile.info.update',
                    'Update your info and find out how it’s used'
                  )}
                </StyledCaption>
                {/*<StyledLink>View profile</StyledLink>*/}
              </Box>
            </>
          )}
        </Box>
      </Box>
      {isMobile ? <></> : <ProfileDivider />}
      <Formik
        initialValues={defaultPersonalInfoFormValue}
        validationSchema={createValidationSchema()}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <PersonalInfoForm
          currentAbsVenue={currentAbsVenue}
          setCurrentAbsVenue={setCurrentAbsVenue}
        />
      </Formik>
    </Box>
  )
}
