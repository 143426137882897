import React from 'react'

import { CustomModal } from '../../components/ui/Modals/SimpleModal'
import { MediaSelectModal } from '../../pages/dashboard/components/modals/MediaLibrary'
import { AssetRefDownload } from '../../types/FileUploadHandler'
import { ImageItem } from '../../types/general'
import { useUserMedia } from '../api/images'

import { useAuthData } from './AuthProvider'

export interface MediaModalConfig {
  onSave?: (images: AssetRefDownload[]) => void
  initialImages?: AssetRefDownload[]
  isMulti?: boolean
}

export const MediaLibraryContext = React.createContext<{
  isOpen: boolean
  openMediaModal: (cfg?: MediaModalConfig) => void
  closeMediaModal: () => void
  images: ImageItem[]
  addToMediaLibrary: (item: ImageItem) => void
}>({
  isOpen: false,
  openMediaModal: () => {},
  closeMediaModal: () => {},
  images: [],
  addToMediaLibrary: () => {},
})

export const MediaLibraryProvider: React.FC = ({ children }) => {
  const { authUser } = useAuthData()
  const {
    isLoading,
    data,
    key: queryKey,
    queryClient,
  } = useUserMedia({}, { enabled: !!authUser })
  const [isOpen, setOpen] = React.useState(false)
  const [currentConfig, setConfig] = React.useState({})

  const addToMediaLibrary = (item: ImageItem) => {
    const previousItems = queryClient.getQueryData<ImageItem[]>(queryKey)

    if (previousItems) {
      queryClient.setQueryData(queryKey, [item, ...previousItems])
    } else {
      queryClient.setQueryData(queryKey, [item])
    }
  }

  const openMediaModal = (config: MediaModalConfig = {}) => {
    setConfig(config)
    setOpen(true)
  }

  const closeMediaModal = () => {
    setConfig({})
    setOpen(false)
  }

  const context = React.useMemo(
    () => ({
      isOpen,
      openMediaModal,
      closeMediaModal,
      addToMediaLibrary,
      images: data,
    }),
    [isOpen, openMediaModal, addToMediaLibrary, data, closeMediaModal]
  )

  return (
    <MediaLibraryContext.Provider value={context}>
      {children}
      <CustomModal open={isOpen} onClose={closeMediaModal}>
        <MediaSelectModal config={currentConfig} images={data || []} />
      </CustomModal>
    </MediaLibraryContext.Provider>
  )
}

export const useMediaModalData = () => {
  const context = React.useContext(MediaLibraryContext)
  if (context === undefined) {
    throw new Error(
      'useMediaModalData must be used within an MediaLibraryContext'
    )
  }
  return context
}
