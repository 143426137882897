const NavigationMobile = ({ size = 48, scale = 1 }) => {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 2a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Z"
        fill="#20202C"
      />
      <path
        d="M27.5 11h-15a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5Z"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 14.5h4v4h-4v-4ZM21.5 14.5h4v4h-4v-4ZM14.5 21.5h4v4h-4v-4ZM21.5 21.5h4v4h-4v-4Z"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NavigationMobile
