import { Box, BoxProps, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import * as React from 'react'

import ExclamationCircle from '../../svg/ExclamationCircle'

const warningBoxColorScheme = {
  warn: {
    background: 'rgba(243, 189, 0, 0.1)',
    color: '#F3BD00',
  },
  info: {
    background: 'rgba(47, 84, 235, 0.25)',
    color: '#2F54EB',
  },
}

export const WarningBox: React.FC<
  {
    children: React.ReactNode
    extra?: React.ReactElement | React.ReactElement[]
    onCancel?: () => void
    type?: 'warn' | 'info'
    flex?: number
    color?: string
    background?: string
  } & BoxProps
> = ({
  children,
  onCancel,
  extra = null,
  type = 'warn',
  flex = 1,
  color,
  background,
  ...boxBrops
}) => {
  return (
    <Box
      style={{
        background: background
          ? background
          : warningBoxColorScheme[type].background,
        position: 'relative',
        flex: `${flex}`,
        padding: '8px 20px',
      }}
      mt={4}
      display="flex"
      alignItems="center"
      {...boxBrops}
    >
      <Box display="flex" alignItems="center" mr={3}>
        <ExclamationCircle
          fill={color ? color : warningBoxColorScheme[type].color}
          size={20}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={'5px'}
        mr={!!extra ? 10 : 0}
        color={warningBoxColorScheme[type].color}
        style={{ color: color ? color : '' }}
      >
        {children}
      </Box>
      <Box display="flex">{extra}</Box>
      {onCancel && (
        <Close
          onClick={onCancel}
          style={{
            position: 'absolute',
            right: 10,
            color: warningBoxColorScheme[type].color,
            fontSize: 16,
            cursor: 'pointer',
          }}
        />
      )}
    </Box>
  )
}
