import { Box } from '@material-ui/core'
import { useFormikContext } from 'formik'
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useRouteMatch } from 'react-router'

import { Loader } from '../../../../components/ui/Loader'
import { useBusinessInquiryActions } from '../../../../hooks/useBusinessInquiryActions'
import { useCurrentBusinessListingId } from '../../../../hooks/useCurrentBusinessListingId'
import { useSnackbar } from '../../../../hooks/useSnackbar'
import { BusinessInquiryActions } from '../../../../types/dtos/inquiry'
import { generateDefaultInvoices } from '../../../../utils/api/inquiries'
import { clearObject, getFirstElement } from '../../../../utils/helpers/data'
import { convertDateTimeToServerFormat } from '../../../../utils/helpers/date'
import { getAllLineItemsFromGroups } from '../../../../utils/helpers/lineItems'
import { getRequestError } from '../../../../utils/helpers/validations'
import { LineItemValueMapper } from '../../../../utils/mappers/inquiries'
import { dashboardBookingInvoiceListPath } from '../../../../utils/paths'
import { useAuthData } from '../../../../utils/providers/AuthProvider'
import { useInquiryDetailsData } from '../../../../utils/providers/InqueryDetailsProvider'
import { useMessageModalData } from '../../../../utils/providers/MessageModalProvider'
import { StepContent } from '../StepContent'

import { FORM_STEPS, InvoiceFormSteps } from './constans'
import { InvoicesAdditionalInfoStep } from './FormSteps/AdditionalInfoStep'
import { LineItem, LineItems } from './FormSteps/LineItems'
import { GenerateInvoicesPaymentStep } from './FormSteps/PaymentStep'
import { GenerateInvoicesPreviewStep } from './FormSteps/PreviewStep'
import { StepsColumn } from './StepsColumn'

export interface GenerateInvoiceFormValues {
  initialDueDate?: Date
  finalDueDate?: Date
  message: string
  extra: string
  initialPaymentPercent: number | string
  lineItems: LineItem[]
}

interface GenerateInvoicesFormProps {
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
  parentId?: number
}

export const GenerateInvoicesForm: React.FC<GenerateInvoicesFormProps> = ({
  activeStep,
  setActiveStep,
  parentId,
}) => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()
  const history = useHistory()
  const { showMessage } = useMessageModalData()
  const { t } = useTranslation()

  const { values, errors } = useFormikContext<GenerateInvoiceFormValues>()
  const {
    inquiryDetails,
    refetch: refetchInquiryDetails,
    loading,
  } = useInquiryDetailsData()
  const { handleBusinessInquiryActions } = useBusinessInquiryActions()
  const { isLoading: authLoading } = useAuthData()
  const showSnackbar = useSnackbar()

  const { businessId } = useCurrentBusinessListingId()

  const [isLoading, setIsLoading] = useState(false)

  const lineItemsGroups = useMemo(() => {
    const groups: { name: string; description: string }[] = []
    values.lineItems.forEach((item) => {
      if (!groups.find((group) => group.name === item.groupName || 'General'))
        groups.push({
          name: item.groupName || 'General',
          description: item.groupDescription || '',
        })
    })

    return groups
  }, [values.lineItems])

  const groupedLineItems = lineItemsGroups.map((group) => ({
    name: group.name,
    description: group.description,
    line_items: values.lineItems
      .filter((item) => group.name === (item.groupName || 'General'))
      .map(LineItemValueMapper),
  }))

  const lineItemsAll = getAllLineItemsFromGroups(groupedLineItems)

  const lineItemsTotal = useMemo(() => {
    return lineItemsAll.reduce((sum: number, item) => {
      const price = Math.round(Math.round(item.price * 100) * item.quantity)
      return sum + price / 100
    }, 0)
  }, [lineItemsAll])

  const getButtonText = () => {
    if (FORM_STEPS[activeStep].submit) {
      return t('common.buttons.save', 'Save')
    }

    return t('common.buttons.next', 'Next')
  }

  const renderStep = (stepIndex: number) => {
    const step = FORM_STEPS[stepIndex]
    switch (step.slug) {
      case InvoiceFormSteps.Payment:
        return (
          <GenerateInvoicesPaymentStep
            lineItemsAll={lineItemsAll}
            lineItemsTotal={lineItemsTotal}
          />
        )
      case InvoiceFormSteps.LineItems:
        return <LineItems shouldBlockRental={false} />
      case InvoiceFormSteps.AdditionalInfo:
        return <InvoicesAdditionalInfoStep />
      case InvoiceFormSteps.Preview:
        return (
          <GenerateInvoicesPreviewStep
            groupedLineItems={groupedLineItems}
            lineItemsAll={lineItemsAll}
          />
        )
      default:
        return null
    }
  }

  const handleGenerateInvoice = async (
    values: GenerateInvoiceFormValues,
    shouldSend: boolean
  ) => {
    if (!inquiryDetails) return

    const invoicesData = {
      message: values.message,
      extra: values.extra,
      initial_due_date: convertDateTimeToServerFormat(values.initialDueDate),
      final_due_date: convertDateTimeToServerFormat(values.finalDueDate),
      initial_payment_percent: +values.initialPaymentPercent,
      inquiry_id: inquiryDetails.id,
      line_items: values.lineItems.map(LineItemValueMapper).map((item) => {
        return clearObject({
          ...item,
          line_item_id: null,
          id: item.line_item_id,
        })
      }),
      parent_id: parentId || null,
    }

    setIsLoading(true)

    try {
      const response = await generateDefaultInvoices(invoicesData)
      if (!response.error) {
        if (!shouldSend) {
          history.push(
            generatePath(dashboardBookingInvoiceListPath, {
              id,
              listingId: businessId,
            })
          )
          showSnackbar({
            severity: 'success',
            message: t(
              'messages.inquiry.invoices.generate',
              'The invoices have been successfully generated'
            ),
          })
        } else {
          const details = await refetchInquiryDetails()
          // @ts-ignore
          if (details.status === 'success') {
            const generatedInvoicesBundle = getFirstElement(
              // @ts-ignore
              details.data.invoice_bundles
            )
            await handleBusinessInquiryActions(
              BusinessInquiryActions.INVOICES_BUNDLE_SEND,
              { invoicesBundleId: generatedInvoicesBundle.id }
            )
          }
        }
      }
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleSaveButtonClick = async () => {
    if (FORM_STEPS[activeStep].submit) {
      await handleGenerateInvoice(values, false)
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const handleSendButtonClick = async () => {
    await handleGenerateInvoice(values, true)
  }

  return (
    <>
      <Box display="flex" flex={1}>
        <StepsColumn
          title={t('business.invoices.info', 'Invoice Info')}
          steps={FORM_STEPS}
          activeStep={activeStep}
        />
        <StepContent
          renderStep={renderStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          getButtonText={getButtonText}
          steps={FORM_STEPS}
          errors={errors}
          handleSaveButtonClick={handleSaveButtonClick}
          sendButtonText={t('business.invoices.send', 'Save and send')}
          handleSendButtonClick={
            FORM_STEPS[activeStep].submit ? handleSendButtonClick : undefined
          }
        />
      </Box>
      {(authLoading || loading || isLoading) && <Loader position="fixed" />}
    </>
  )
}
