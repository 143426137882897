import { SVGProps } from 'react'

const PDFDocument = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.6673 39.25C36.6673 40.3546 35.7719 41.25 34.6673 41.25H9.33399C8.22942 41.25 7.33398 40.3546 7.33398 39.25V4.75C7.33398 3.64543 8.22941 2.75 9.33398 2.75H26.6722C27.2027 2.75 27.7114 2.96071 28.0864 3.33579L36.0815 11.3309C36.4566 11.706 36.6673 12.2147 36.6673 12.7451V39.25Z"
        fill="#848484"
        fillOpacity="0.12"
      />
      <path d="M35.2923 12.8333H26.584V4.125L35.2923 12.8333Z" fill="#DFE0EB" />
      <path d="M14.666 19.25H30.2493V21.0833H14.666V19.25Z" fill="#2F54EB" />
      <path
        d="M14.666 22.9167H26.5827V24.7501H14.666V22.9167Z"
        fill="#2F54EB"
      />
      <path
        d="M14.666 26.5833H30.2493V28.4166H14.666V26.5833Z"
        fill="#2F54EB"
      />
      <path d="M14.666 30.25H26.5827V32.0833H14.666V30.25Z" fill="#2F54EB" />
      <path
        d="M5.488 8.58819C5.488 8.87085 5.42133 9.13752 5.288 9.38819C5.15467 9.63885 4.94133 9.84419 4.648 10.0042C4.35467 10.1589 3.97867 10.2362 3.52 10.2362H2.512V12.4922H1.6V6.93219H3.52C3.94667 6.93219 4.30667 7.00685 4.6 7.15619C4.89867 7.30019 5.12 7.49752 5.264 7.74819C5.41333 7.99885 5.488 8.27885 5.488 8.58819ZM3.52 9.49219C3.86667 9.49219 4.12533 9.41485 4.296 9.26019C4.46667 9.10019 4.552 8.87619 4.552 8.58819C4.552 7.98019 4.208 7.67619 3.52 7.67619H2.512V9.49219H3.52ZM8.17381 6.93219C8.76581 6.93219 9.28315 7.04685 9.72581 7.27619C10.1738 7.50019 10.5178 7.82552 10.7578 8.25219C11.0031 8.67352 11.1258 9.16685 11.1258 9.73219C11.1258 10.2975 11.0031 10.7882 10.7578 11.2042C10.5178 11.6202 10.1738 11.9402 9.72581 12.1642C9.28315 12.3829 8.76581 12.4922 8.17381 12.4922H6.35781V6.93219H8.17381ZM8.17381 11.7482C8.82448 11.7482 9.32315 11.5722 9.66981 11.2202C10.0165 10.8682 10.1898 10.3722 10.1898 9.73219C10.1898 9.08685 10.0165 8.58285 9.66981 8.22019C9.32315 7.85752 8.82448 7.67619 8.17381 7.67619H7.26981V11.7482H8.17381ZM15.3017 6.93219V7.67619H12.9417V9.31619H14.7817V10.0602H12.9417V12.4922H12.0297V6.93219H15.3017Z"
        fill="#2F54EB"
      />
    </svg>
  )
}

export default PDFDocument
