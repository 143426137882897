import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Link as MuiLink,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { generatePath, Link, useHistory } from 'react-router-dom'

import ArrowBack from '../../../../../components/svg/ArrowBack'
import Send from '../../../../../components/svg/Send'
import { EventServiceTab } from '../../../../../constants/events'
import { useUrlParams } from '../../../../../hooks/useUrlParams'
import { InquiryContractResponse } from '../../../../../types/dtos/inquiry'
import { EventServiceProps } from '../../../../../types/Event'
import { InquiryWithDetails } from '../../../../../types/Inquiry'
import { eventPath, getEventDetailsPath } from '../../../../../utils/paths'
import {
  MobileBookingDetailsTitle,
  StyledAccordion,
} from '../../ServiceBookingDetails.styles'
import { AdditionalInfo } from '../Tabs/AdditionalInfo'
import { BookingInfo } from '../Tabs/BookingInfo'
import { Contracts } from '../Tabs/Contracts'
import { PaymentInvoices } from '../Tabs/PaymentInvoices'

export interface ServiceBookingDetailsProps {
  data: EventServiceProps
  activeTab: EventServiceTab
  setActiveTab: Dispatch<SetStateAction<EventServiceTab>>
  jotForms: any[]
  fetchEvent: () => void
  onToggleMessenger: () => void
  inquiryDetails?: InquiryWithDetails
  openMessengerHandler: () => void
  refetch: () => void
  contracts?: InquiryContractResponse[]
}

export const MobileServiceBookingDetails: React.FC<
  ServiceBookingDetailsProps
> = ({
  data,
  inquiryDetails,
  openMessengerHandler,
  refetch,
  contracts,
  activeTab,
  setActiveTab,
  jotForms,
  fetchEvent,
  onToggleMessenger,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { urlParams } = useUrlParams()

  const onChangeNavValue = (value: EventServiceTab) => {
    setActiveTab(value)
    const params = { ...urlParams, tab: value }
    history.push(
      getEventDetailsPath(
        location.pathname,
        // @ts-ignore
        params
      )
    )
  }

  return (
    <Box className="container" my={6}>
      <MobileBookingDetailsTitle>
        <Typography fontWeight={500} variant="body2">
          {data?.isVenue ? 'Venue' : 'Vendor'} booking details
        </Typography>
      </MobileBookingDetailsTitle>
      <StyledAccordion
        defaultExpanded={activeTab === EventServiceTab.BOOKING_INFO}
        onClick={() => onChangeNavValue(EventServiceTab.BOOKING_INFO)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          Booking details
        </AccordionSummary>
        <AccordionDetails>
          <BookingInfo inquiryDetails={inquiryDetails} event={data} />
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        defaultExpanded={activeTab === EventServiceTab.CONTRACTS}
        onClick={() => onChangeNavValue(EventServiceTab.CONTRACTS)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          Contracts
        </AccordionSummary>
        <AccordionDetails>
          <Contracts
            openMessengerHandler={openMessengerHandler}
            files={inquiryDetails?.files}
            contracts={contracts}
            setActiveTab={setActiveTab}
          />
        </AccordionDetails>
      </StyledAccordion>
      {jotForms && jotForms.length ? (
        <StyledAccordion
          defaultExpanded={activeTab === EventServiceTab.ADDITIONAL_INFO}
          onClick={() => onChangeNavValue(EventServiceTab.ADDITIONAL_INFO)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            Additional info
          </AccordionSummary>
          <AccordionDetails>
            <AdditionalInfo
              event={data}
              jotForms={jotForms}
              inquiryDetails={inquiryDetails}
            />
          </AccordionDetails>
        </StyledAccordion>
      ) : null}
      <StyledAccordion
        defaultExpanded={activeTab === EventServiceTab.PAYMENTS}
        onClick={() => onChangeNavValue(EventServiceTab.PAYMENTS)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          Price/Payments
        </AccordionSummary>
        <AccordionDetails>
          <PaymentInvoices
            fetchEvent={fetchEvent}
            openMessengerHandler={openMessengerHandler}
            refetch={refetch}
            inquiryDetails={inquiryDetails}
          />
        </AccordionDetails>
      </StyledAccordion>
      <Box display="flex" mt={3} onClick={onToggleMessenger}>
        <Typography color="#2F54EB" mr={2} fontWeight={500} variant="body2">
          {t('inquiry.typeAMessage', 'Type a message')}
        </Typography>
        <Send fill="#2F54EB" />
      </Box>

      <MuiLink
        component={Link}
        to={generatePath(eventPath, {
          id: data.eventId,
        })}
        color="textPrimary"
        underline="none"
        width="100%"
      >
        <Box display="flex" mt={6} alignItems="center">
          <ArrowBack fill="#2F54EB" />
          <Typography color="#2F54EB" ml={2} fontWeight={500} variant="body2">
            {t('inquiry.backToEvent', 'Back to event')}
          </Typography>
        </Box>
      </MuiLink>
    </Box>
  )
}
