import { FormControl } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

export const StyledFormControl = styled(FormControl)<{
  inputWidth: boolean
  selectPaddingLeft: string
  selectPaddingVertical: string
  selectHeight: string
  maxSelectHeight: string
}>(
  (props) => `
  width: 100%;
  z-index: 1200;

  input {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .placeholder {
    color: #bdbdbd;
    opacity: 1;
  }

  .MuiSelect-root.placeholder {
    color: inherit;
  }
   
  .MuiSelect-select {
    text-align: left;
    padding-left: ${props.selectPaddingLeft};
    padding-top: ${props.selectPaddingVertical};
    padding-bottom: ${props.selectPaddingVertical};
    display: inline-block;
    text-overflow: ellipsis;
  }
  
  .MuiInput-root {
    height: ${props.selectHeight};
    max-height: ${props.maxSelectHeight};
  }

  .MuiSelect-icon {
    color: black;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root:not(&.Mui-error) fieldset {
    border: 1px solid #efefef;
  }

  legend span {
    display: none;
  }

  &.secondary {
    p {
      color: #bdbdbd;
    }
    .MuiSelect-select,
    .MuiInputBase-root {
      color: black;
      opacity: 1;
    }
    input::placeholder {
      color: black;
      opacity: 1;
    }
  }
  
  &.thirdly {
     p {
      color: #bdbdbd;
    }
  }

  &.error .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
  &.error fieldset {
    border: 1px solid red;
  }
  
  .MuiOutlinedInput-root:not(.MuiSelect-root) .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
`
)
