import { Formik } from 'formik'
import { keys } from 'lodash'
import React from 'react'

import { useWizard, WizardStep } from '../../../components/Wizard'
import { OnboardingStep } from '../../../types/Auth'
import { VendorType } from '../../../types/Vendor'
import { useVendorTypes } from '../../../utils/api/vendors'

import { createVendorInformationValidationSchema } from './validations'
import { VendorInformationForm } from './VendorInformationForm'

export interface VendorInformationFormValues {
  phone: string
  website: string
  type: string
  name: string
  description: string
  contactEmail: string
  contactName: string
  office: string
  costLevel: number | null
  companySize: string
}

const initialValues: VendorInformationFormValues = {
  phone: '',
  website: '',
  type: '',
  name: '',
  description: '',
  contactEmail: '',
  contactName: '',
  office: '',
  costLevel: null,
  companySize: '',
}

export const VendorInformation: React.FC<{
  step: OnboardingStep
  isAuthUser: boolean
}> = ({ step, isAuthUser }) => {
  const { data: categories, isLoading } = useVendorTypes()
  const { wizardData } = useWizard()

  const initialData = wizardData?.data || initialValues
  const [categoriesForSelectField, setCategoriesForSelectField] =
    React.useState<any[]>([])
  const [isFormValid, setIsFormValid] = React.useState(false)
  const [formValues, setFormValues] =
    React.useState<VendorInformationFormValues>(initialData)

  React.useEffect(() => {
    if (categories?.length) {
      setCategoriesForSelectField(
        categories?.map((item: VendorType) => ({
          ...item,
          label: item.name,
          value: item.id,
        }))
      )
    }
  }, [categories?.length])

  return (
    <WizardStep
      isLoading={isLoading}
      title={step.label}
      index={step.index}
      buttonDisabled={!isFormValid}
      stepData={formValues}
    >
      <Formik
        validationSchema={createVendorInformationValidationSchema(isAuthUser)}
        initialValues={initialData}
        onSubmit={(values) => setFormValues(values)}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
      >
        {({ handleSubmit, errors, values }) => {
          setIsFormValid(!Boolean(keys(errors).length))
          setFormValues(values)
          return (
            <form
              style={{ width: '100%', minWidth: 200 }}
              onSubmit={handleSubmit}
            >
              <VendorInformationForm
                categories={categoriesForSelectField}
                isAuthUser={isAuthUser}
              />
            </form>
          )
        }}
      </Formik>
    </WizardStep>
  )
}
