import { PaletteMode } from '@material-ui/core'
import { common } from '@material-ui/core/colors'
import muiCreatePalette, {
  PaletteOptions,
} from '@material-ui/core/styles/createPalette'
import { alpha } from '@material-ui/system'
// @ts-ignore
import { deepmerge } from '@material-ui/utils'

const black = '#141414'
const grey = '#000014'
const white = common.white

declare module '@material-ui/core' {
  interface ButtonPropsColorOverrides {
    muted: true
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    muted?: PaletteColorOptions
  }
  interface CommonColors {
    footerBg: string
    buttonBg: string
    headerIconBg: string
    headerIconColor: string
    headerIconHover: string
    promotionColor: string
    promotionBanner: string
    linkLight?: string
  }
}

const darkColors: PaletteOptions = {
  background: {
    default: '#EEF4F4',
    paper: '#272727',
  },
  secondary: {
    main: '#3A3A3A',
    dark: grey,
  },
  divider: alpha(white, 0.16),
  text: {
    primary: white,
    secondary: alpha(white, 0.72),
  },
  action: {
    active: alpha(white, 0.24),
    hover: alpha(white, 0.16),
    focus: alpha(white, 0.16),
    selected: alpha(white, 0.08),
  },
}

const lightColors: PaletteOptions = {
  background: {
    default: white,
    paper: '#F7F7F8',
  },
  secondary: {
    main: '#F5F5F8',
    dark: grey,
  },
  divider: alpha(black, 0.16),
  text: {
    primary: black,
    secondary: alpha(black, 0.72),
    disabled: '#878A97',
  },
  action: {
    active: alpha(black, 0.24),
    hover: alpha(black, 0.16),
    focus: alpha(black, 0.16),
    selected: alpha(black, 0.08),
  },
}

const getDefaultColors = (mode: PaletteMode) =>
  deepmerge<PaletteOptions>(
    {
      mode,
      primary: {
        main: '#4E4BEC',
        light: '#2F54EB',
      },
      success: {
        main: '#0B873A',
        light: '#11CF59',
      },
      warning: {
        main: '#F0B440',
        light: '#FFCB8F',
        contrastText: common.black,
      },
      error: {
        main: '#F16547',
        light: '#E97780',
      },
      info: {
        main: '#0074E4',
        light: '#339BFF',
      },
      muted: {
        main: '#9396a3',
      },
      common: {
        black,
        footerBg: grey,
        buttonBg: 'linear-gradient(180deg, #4e4bec 0%, #3f91f0 100%)',
        headerIconBg: 'none',
        headerIconColor: white,
        promotionColor: '#003366',
        promotionBanner: '#10239E',
        white: "#FFF",
      },
      action: {
        hoverOpacity: 0.16,
        focusOpacity: 0.16,
        selectedOpacity: 0.08,
        disabledOpacity: 0.5,
        activatedOpacity: 0.24,
      },
      grey: {
        50: '#F0F0F0',
        100: '#BDBDBD',
        200: '#9396A3',
        300: '#9FA2B4',
      },
    },
    mode === 'dark' ? darkColors : lightColors,
    { clone: false }
  )

export const createPalette = ({
  mode = 'light',
  ...options
}: PaletteOptions = {}) =>
  muiCreatePalette(deepmerge(getDefaultColors(mode), options, { clone: false }))
