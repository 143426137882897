import { Box, Typography } from '@material-ui/core'
import { PeopleAltOutlined } from '@material-ui/icons'
import React, { useMemo, useState } from 'react'

import { useAdminUsers } from '../../../utils/api/admin'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { InputsWrapper } from '../../Auth/Auth.styles'
import { SimpleButton } from '../../ui/Buttons/SimpleButton'
import { Autocomplete } from '../../ui/Inputs/Autocomplete'
import { Loader } from '../../ui/Loader'

export const AdminUsersList: React.FC<{ onSuccess: () => void }> = ({
  onSuccess,
}) => {
  const { isLoading: authLoading, authUser, loginAsUser } = useAuthData()
  const { clearEventsFromStorage } = useStorageEventsData()
  const { isLoading, data: users } = useAdminUsers(
    {},
    { enabled: !!authUser?.is_admin }
  )

  const [selectedUser, setSelectedUser] = useState('')

  const userOptions = useMemo(
    () =>
      users?.map((user) => ({
        label: `${user.email} (${user.role || 'client'})`,
        value: user.id,
      })) || [],
    [users]
  )

  const handleLogin = async () => {
    const user = await loginAsUser(selectedUser)

    if (user?.id) {
      clearEventsFromStorage()
      onSuccess()
    }
  }

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={5}>
        <Typography variant="h3">Users List</Typography>
      </Box>
      <Box display="flex" mb={4} flexDirection="column">
        <Typography textAlign="left" variant="body2">
          To login as user pick one from the list and click &apos;Login&apos;
        </Typography>
      </Box>
      <Box display="flex" mb={5}>
        <InputsWrapper>
          <Autocomplete
            placeholder="Start typing..."
            icon={<PeopleAltOutlined />}
            onChange={setSelectedUser}
            options={userOptions}
            listTitle="Choose user"
          />
        </InputsWrapper>
      </Box>

      <SimpleButton onClick={handleLogin} sx={{ mb: 1 }} variant="contained">
        Login as user
      </SimpleButton>
      {(isLoading || authLoading) && <Loader position="absolute" />}
    </Box>
  )
}
