import { filter, isFunction } from 'lodash/fp'
import { ComponentType, useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Param,
  deleteParams,
  setParam as set,
  setParams as setAll,
  setDefaultParams,
  toObject,
  validateParams,
} from 'utils/helpers/searchParams'

export const useSearchParams = () => {
  const location = useLocation()
  const history = useHistory()
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  const apply = useCallback(
    (params: URLSearchParams) => {
      history.push({ search: params.toString() })
    },
    [history]
  )

  const setParam = (key: string, value: Param) => {
    const currentParams = new URLSearchParams(history.location.search)
    apply(set(currentParams, key, value))
  }

  const setParams = (newParams: Record<string, Param>) => {
    const currentParams = new URLSearchParams(history.location.search)
    apply(setAll(currentParams, newParams))
  }

  return { params, setParam, setParams, apply }
}

export const requireValidParams =
  <TProps extends {} = {}>(
    validator: (
      props: TProps
    ) =>
      | ((values: Record<string, string>) => string[])
      | Record<string, (value: string) => boolean>
  ) =>
  (Component: ComponentType<TProps>) =>
    function RequireValidParams(props: TProps) {
      const { apply, params } = useSearchParams()
      const invalidParams = useMemo(() => {
        const validate = validator(props)
        return isFunction(validate)
          ? validate(toObject(params))
          : validateParams(params, validate)
      }, [params, validator])

      useEffect(() => {
        if (invalidParams.length > 0) {
          apply(deleteParams(params, invalidParams))
        }
      }, [invalidParams, params])

      if (invalidParams.length > 0) return null

      return <Component {...props} />
    }

export const requireParams =
  <P extends {} = {}, TKeys extends string = string>(
    requiredParams: TKeys[],
    defaultParams: (props: P) => { [K in TKeys]: Param }
  ) =>
  (Component: ComponentType<P>) =>
    function RequireParams(props: P) {
      const { params, apply } = useSearchParams()
      const missingParams = useMemo(
        () => filter((param) => !params.has(param), requiredParams),
        [params, requiredParams]
      )

      useEffect(() => {
        if (missingParams.length > 0) {
          apply(setDefaultParams(params, defaultParams(props)))
        }
      }, [missingParams, apply, defaultParams])

      if (missingParams.length > 0) return null

      return <Component {...props} />
    }
