import { GooglePlace } from '../components/ui/Inputs/GoogleLocationSelect'

export enum Status {
  REQUEST = 'REQUEST',
  INACTIVE = 'INACTIVE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type ErrorResponse = {
  error: string
  status?: boolean
  message?: string
}

export type ResponseOrError<T> = T | ErrorResponse

export namespace ResponseOrError {
  export function isError(
    response: ResponseOrError<any>
  ): response is ErrorResponse {
    const data = response as ErrorResponse
    return !!data?.error || data?.status === false
  }
}

export interface ImageItem {
  path: string
  filename: string
  id: number
  order: number
  thumbs_list: {
    medium: string
    'medium-retina': string
    small: string
    'small-retina': string
    'webp-medium': string | null
    'webp-medium-retina': string | null
    'webp-small': string | null
    'webp-small-retina': string | null
    webp: string | null
  }
}

export interface ImageType {
  static:
    | 'vendor'
    | 'venue'
    | 'gallery'
    | 'galleryTwo'
    | 'onboarding'
    | 'map'
    | 'neighborhood'
  size: 'small' | 'medium' | 'original'
}

export interface GalleryImage {
  original: string
  thumbnail: string
}

export interface ServerCalendar {
  created_at: string
  event_id?: number
  google_id: string
  id: number
  type: string
  updated_at: string
  user_id: number
  vendor_id?: number
  venue_id?: number
}

export interface BusinessContactData {
  name: string
  phone: string
  email: string
  address: GooglePlace
  website: string
  // facebook: string
  instagram: string
}

export interface BusinessGeneralInfo {
  name?: string
  description?: string
  order_price_min?: number
  website?: string
  params?: {
    minimum_starting_at?: number
    display_pricing?: boolean
    short_pricing_details?: string
    short_pricing_details_label?: string
  }
  payment_structure?: {
    pricing_details?: string
  }
}

export interface BusinessDetailsInfo {
  params: {
    ceiling_height?: number
    // number_of_rooms?: number
    sq_footage?: number
  }
  max_head_count?: number
}

export interface SettingsData {
  stripeFeePercent: number
  stripeFixTax: number
  defaultAppFee: number
}

export interface PromotionData {
  promotion_banner_switcher: boolean
  promotion_banner_text: string | null
  special_offers_switcher: boolean
}

export interface DocumentFile {
  filename: string
  id: number
  inquiry_id: number
  path: string
  user_id: number
  s3_path?: string
  is_validated?: boolean
}

export enum ExpandType {
  less,
  more,
}
