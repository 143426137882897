import { getDefaultEventName } from '../../constants'
import { EventServiceTab } from '../../constants/events'
import {
  EventServiceProps,
  EventType,
  EventTypesRecord,
  EventVendor,
  EventVenue,
  LocalEvent,
} from '../../types/Event'
import { VendorType } from '../../types/Vendor'
import { LsGetUserEvents } from '../storage'

import { getLastElement } from './data'
import { currencyConvertToDecimal, formatToCurrency } from './price'
import { isServiceVendor } from './vendors'

export const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2)
}

export const generateEventName = (
  isAbs: boolean,
  eventType?: string,
  types?: EventTypesRecord
): string => {
  if (eventType && !types) return getDefaultEventName(isAbs)

  const existsEvents = LsGetUserEvents()
  const eventName =
    eventType && types ? types?.[eventType].name : getDefaultEventName(isAbs)
  let counter = 1

  existsEvents.forEach((item: LocalEvent) => {
    if (item.name?.includes(eventName)) {
      counter++
    }
  })

  return eventName + (counter > 0 ? ` ${counter}` : '')
}

export const isVendorShouldBePreferred = (
  list: EventVendor[],
  vendor: EventVendor
) => {
  const categoryList = list.filter((item) => item.type === vendor.type)

  return categoryList.length === 0
}

export const addVendorToEvent = (
  event: LocalEvent | null,
  vendor: EventVendor
) => {
  if (event) {
    const vendorsList: EventVendor[] =
      event.vendors?.filter((item) => item.id !== vendor.id) || []
    vendor.preferred = isVendorShouldBePreferred(vendorsList, vendor)
      ? '1'
      : undefined
    vendorsList.push(vendor)

    return { ...event, vendors: vendorsList }
  }

  return null
}

const setPreferToFirstVendorOfCategory = (
  list: EventVendor[],
  typeId: string | number
) => {
  const firstOfCategoryIndex = list.findIndex(
    (vendor) => vendor.type === typeId
  )

  if (firstOfCategoryIndex > -1) {
    list[firstOfCategoryIndex].preferred = '1'
  }

  return list
}

export const removeVendorFromEvent = (
  event: LocalEvent | null,
  vendor: EventVendor
) => {
  if (event) {
    const vendorsList = event.vendors || []
    let updatedList = vendorsList.filter(
      (item) => item.id.toString() !== vendor.id.toString()
    )

    if (vendor?.preferred && updatedList.length > 0) {
      updatedList = setPreferToFirstVendorOfCategory(updatedList, vendor?.type)
    }

    return { ...event, vendors: updatedList }
  }

  return null
}

export const removeVenueFromEvent = (
  event: LocalEvent | null
): LocalEvent | null => {
  const venue: EventVenue | undefined = event?.venue

  if (event && venue) {
    const vendors: EventVendor[] | undefined = event?.vendors
    const vendorsExceptExclusive = vendors?.filter(
      (vendor) => vendor.venueId !== venue.id.toString()
    )

    delete event.venue
    return { ...event, vendors: vendorsExceptExclusive }
  }

  return null
}

const getInquiryPriceProposedPrice = (data?: EventVenue | EventVendor) => {
  const lastContract = getLastElement(data?.contracts)

  if (lastContract) {
    return lastContract.price
  }

  return null
}

export const getUserEvenVendorsTotalPrice = (eventData: LocalEvent) => {
  const vendors = eventData?.vendors || []

  return vendors.reduce((sum, vendor) => {
    const price = getInquiryPriceProposedPrice(vendor) || 0
    return price + sum
  }, 0)
}

export const getUserEventTotalPrice = (eventData: LocalEvent) => {
  const vendorsPrice = getUserEvenVendorsTotalPrice(eventData)
  const venuePrice =
    getInquiryPriceProposedPrice(eventData?.venue) ||
    (eventData?.venue?.pricing
      ? currencyConvertToDecimal(eventData.venue.pricing)
      : 0)

  if (eventData?.vendors && !vendorsPrice) {
    return 0
  }
  return vendorsPrice + venuePrice
}

export const formatEventService = (
  event: LocalEvent,
  data: EventVenue | EventVendor,
  vendorCategories?: VendorType[]
): EventServiceProps => {
  const serviceData: EventServiceProps = {
    id: data?.id?.toString(),
    inquiry_id: data.inquiryId?.toString(),
    eventId: event.id,
    name: data.name,
    image: data.photo || undefined,
    unread_messages: data.unread_messages || 0,
    service_type: '',
    address_package: '',
    price: '',
    status: '',
    isVenue: false,
    displayPricing: false,
    pricingDetails: '',
    inquiryDetails: data,
  }

  if (isServiceVendor(data)) {
    const vendorDefaultPrice = data.orderPrice?.toString()
    serviceData.address_package = ''
    serviceData.service_type =
      vendorCategories?.find((category) => category.id === data.type)?.name ||
      'Vendors'
    serviceData.price = getInquiryPriceProposedPrice(data)
      ? formatToCurrency(getInquiryPriceProposedPrice(data))
      : vendorDefaultPrice || ''
    serviceData.status = data.status || ''
    serviceData.exclusive = !!data.exclusive
    serviceData.displayPricing = true
  } else {
    serviceData.address_package = data.address || ''
    serviceData.service_type = 'Venues'
    serviceData.price = formatToCurrency(
      getInquiryPriceProposedPrice(data) || data?.pricing
    )
    serviceData.status = data.status || ''
    serviceData.isVenue = true
    serviceData.rooms = data.rooms
    serviceData.displayPricing = data.displayPricing || false
    serviceData.pricingDetails = data.pricingDetails || ''
  }

  return serviceData
}

export const formatEventTypes = (data?: EventTypesRecord, isAbs?: boolean) => {
  if (data) {
    const dataList = Object.values(data)
    return dataList.map((item: EventType) => ({
      category: isAbs ? '' : item.category,
      label: item.name,
      value: item.slug,
    }))
  }
  return []
}

export const formatEventStyles = (data?: any) => {
  const eventStyleList = []
  if (data) {
    for (const k in data) {
      eventStyleList.push({
        label: data[k],
        value: k,
      })
    }
  }
  return eventStyleList
}

export const getJotFormIdByCategory = (id: string) => {
  switch (id) {
    case 'catering':
      return '221213564370346'
    case 'floral':
    case 'decor':
    case 'design':
      return '221094935345458'
    case 'entertainment':
      return '221094996681468'
    case 'rentals':
      return '221095018205446'
    case 'photo-video':
      return '221095084153451'
    case 'event-production':
      return '221095341557455'
    default:
      return '221095341557455'
  }
}

export const getABSJotFormIdByCategory = (id: string) => {
  switch (id) {
    case 'catering':
      return '222794561317157'
    case 'decor':
      return '222685376931162'
    case 'entertainment':
      return '222685986848176'
    case 'creative-services':
      return '222686065137055'
    case 'wellness-beauty':
      return '222686177760061'
    case 'specialty-food-pantry':
      return '222686664148163'
    case 'photo-video':
      return '222685860668167'
    case 'health-safety':
      return '222696052259360'
    case 'other':
      return '222774366342358'
    default:
      return '222774366342358'
  }
}

export const getInquiryTab = (type: string) => {
  switch (type) {
    case 'App\\Notifications\\InvoiceReceived':
      return EventServiceTab.PAYMENTS
    case 'App\\Notifications\\ContractToSign':
      return EventServiceTab.CONTRACTS
    default:
      return ''
  }
}
