import { ButtonProps } from '@material-ui/core'
import * as React from 'react'

import { CellGradientButton } from '../../../../components/ui/TableComponent/TableComponent.styles'
import { getInquiryActionText } from '../../../../utils/helpers/inquiries'

export const InquiryActionButton: React.FC<
  ButtonProps & { status: string }
> = ({ status, ...props }) => {
  return (
    <CellGradientButton {...props}>
      {getInquiryActionText(status)}
    </CellGradientButton>
  )
}
