import * as yup from 'yup'

export const createValidationSchema = () =>
  yup.object().shape({
    // numberOfRooms: yup.number().max(500),
    ceilingHeight: yup
      .number()
      .transform((value) => (isNaN(value) ? 1 : value))
      .min(1)
      .max(99.99),
    squareFootage: yup
      .number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .max(2147483647),
    maxHeadcount: yup
      .number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .max(2147483647),
  })
