import img from '../assets/images/test-venue-item.jpg'

export const venuePlaceholder = window.location.origin + img

export const venueRentalLineItemId = 1
export const venueDefaultRentalLineItemValue = 'venueRental'
export const venueDepositLineItemId = 12
export const venueDefaultDepositLineItemValue = 'venueDeposit'

export const VENUE_RENTAL_LINE_ITEM = {
  value: venueDefaultRentalLineItemValue,
  quantity: 1,
  clarification: '',
  groupName: '',
  groupDescription: '',
  label: 'Space Rental Fee',
}

export const VENUE_DEPOSIT_LINE_ITEM = {
  value: venueDefaultDepositLineItemValue,
  quantity: 1,
  clarification: '',
  groupName: '',
  groupDescription: '',
  label: 'Security Deposit',
}

export const DEFAULT_LANDLORD_LINE_ITEMS = [
  VENUE_RENTAL_LINE_ITEM,
  VENUE_DEPOSIT_LINE_ITEM,
]

export const getVenueTabs = (
  withVendors: boolean = true,
  withLabels: boolean = true
) => {
  const venueTabs = [
    { label: 'About', value: 'about' },
    { label: 'Pricing', value: 'pricing' },
  ]

  if (withVendors) {
    venueTabs.push({ label: 'Vendors', value: 'vendors' })
  }
  if (withLabels) {
    venueTabs[1] = { label: 'Amenities', value: 'amenities' }
  }

  return venueTabs
}

export const rulesStrings: Record<string, string> = {
  allows_cooking: 'No cooking',
  allows_music: 'No music',
  allows_open_flame: 'No open flame',
  allows_red_wine: 'No red wine',
  allows_smoking: 'No smoking',
  allows_ticket_sales_on_prem: 'No ticket sales on premises',
  allows_underage: 'No underage',
  allows_glitter_confetti: 'No glitter/confetti/sparklers',
  allows_fog_haze_machine: 'No fog haze machines',
  allows_ticket_sales_at_all: 'No ticket sales at all',
  has_guest_curfew: 'No guest curfew',
  has_load_out_curfew: 'No load out curfew',
  has_music_curfew: 'No music curfew',
  has_show_restriction: 'No show restriction',
  guest_curfew_add_details: 'Guest curfew',
  load_out_curfew_add_details: 'Load out curfew',
  music_curfew_add_details: 'Music curfew',
  shoe_restriction_add_details: 'Shoe restriction',
}
