import {
  Badge,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  MenuProps,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import capitalize from 'lodash/capitalize'
import * as React from 'react'
import { ChangeEvent } from 'react'
import { excludeProps } from 'utils/styles/styled'

import { OptionSelect, OptionSelectProps } from '../../Inputs/OptionSelect'

export const StyledMenuItem = styled(MenuItem)`
  margin: 10px 15px 10px 15px;
  background-color: transparent;
  border-radius: 1px;
  &:hover {
    background-color: #e9e9ec;
  }
  &.Mui-focusVisible {
    background: #e9e9ec;
  }
`
const StyledPaper = styled(Paper)`
  background: white;
  border-radius: 0;
  min-width: 350px;

  .MuiFormControlLabel-root {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .MuiFormGroup-root {
    margin-left: 24px;
    margin-right: 24px;
  }

  ${MEDIA_RULES.SMALL} {
    min-width: unset;
    width: 100%;
  }
`

type StyledBadgeProps = {
  selectSize?: 'small' | 'default'
}

export const StyledBadge = styled(Badge, {
  shouldForwardProp: excludeProps<StyledBadgeProps>(['selectSize']),
})<StyledBadgeProps>(
  ({ selectSize }) => `
  margin-right: 15px;
  .MuiBadge-badge {
    background-color: #2f54eb;
    font-size: 14px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin: 0;
  }
  .MuiTextField-root .MuiSelect-select {
    padding: 0 15px;
    height: ${!selectSize || selectSize === 'default' ? '53px' : '48px'};
    display: flex;
    align-items: center;
    
    ${MEDIA_RULES.SMALL} {
      height: 40px;
    }
  }
`
)

interface MultiSelectFieldProps extends OptionSelectProps {
  options: any[]
  selectedOptions?: string | string[]
  onChange: (value: any) => void
  defaultLabel: string
  className?: string
  id: string
  multiple?: boolean
  isRadio?: boolean
  isCheckbox?: boolean
  selectSize?: 'small' | 'default'
  MenuProps?: Partial<MenuProps>
}

export const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  className,
  defaultLabel,
  MenuProps = {},
  selectedOptions = [],
  options,
  onChange,
  id,
  multiple = false,
  isRadio = false,
  isCheckbox = false,
  selectSize = 'default',
}) => {
  const handleChangeSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    onChange(value)
  }

  const renderStringValue = (value: string) => {
    return typeof options[0] === 'string'
      ? capitalize(value)
      : options.find((opt) => opt.value === value)?.label || defaultLabel
  }

  const renderSelectedValues = (selectedValues: string | string[]) => {
    if (isRadio) {
      return (
        options?.find((item) => item.value === selectedValues)?.label ||
        defaultLabel
      )
    }
    if (selectedValues.length) {
      const lastSelectedOption = selectedValues[selectedValues.length - 1]
      return multiple
        ? defaultLabel || lastSelectedOption
        : typeof selectedValues === 'string'
        ? renderStringValue(selectedValues)
        : lastSelectedOption
    }
    return defaultLabel
  }

  return (
    <FormControl className={className}>
      <StyledBadge
        selectSize={selectSize}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        badgeContent={multiple ? selectedOptions.length : null}
        color="primary"
      >
        <OptionSelect
          value={selectedOptions}
          onChange={handleChangeSelected}
          SelectProps={{
            MenuProps: {
              className: 'selectMenu',
              ...MenuProps,
            },
            multiple,
            displayEmpty: true,
            // @ts-ignore
            renderValue: renderSelectedValues,
          }}
          id={id}
        >
          {isRadio ? (
            <StyledPaper>
              <RadioGroup
                value={selectedOptions}
                onChange={handleChangeSelected}
              >
                {options.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio color="primary" />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </StyledPaper>
          ) : (
            options.map((option, index) => {
              const value = typeof option === 'string' ? option : option.value
              const label = typeof option === 'string' ? option : option.label
              return (
                <StyledMenuItem
                  key={index}
                  value={value}
                  sx={{ padding: isCheckbox ? '0 10px 0 0' : '10px' }}
                >
                  <Box display="flex" alignItems="center">
                    {isCheckbox && (
                      <Checkbox
                        value={value}
                        checked={
                          !!(Array.isArray(selectedOptions)
                            ? selectedOptions.find((option) => option === value)
                            : selectedOptions === value)
                        }
                      />
                    )}
                    <Typography variant="body2">{capitalize(label)}</Typography>
                  </Box>
                </StyledMenuItem>
              )
            })
          )}
        </OptionSelect>
      </StyledBadge>
    </FormControl>
  )
}
