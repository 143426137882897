import { Box, Divider, TextField, Typography } from '@material-ui/core'
import * as React from 'react'
import { ChangeEvent, useState } from 'react'

import { VenueCard } from '../../../../../components/cards/VenueCard'
import { InitialEventData } from '../../../../../components/InitialEventData'
import { SimpleButton } from '../../../../../components/ui/Buttons/SimpleButton'
import { useUpdateEventStatus } from '../../../../../hooks/useUpdateEventStatus'
import { InquiryEvent } from '../../../../../types/Inquiry'
import { convertInquiryToEventVenue } from '../../../../../utils/helpers/venue'
import { useInquiryDetailsData } from '../../../../../utils/providers/InqueryDetailsProvider'

const InquiryRejectedModal: React.FC<{
  onReject: () => void
  data: InquiryEvent
  onRejectAction?: () => void
}> = ({ onReject, data, onRejectAction }) => {
  const [reason, setReason] = useState('')
  const [error, setError] = useState('')
  const { updateInquiryStatusByFlow } = useUpdateEventStatus()
  const { refetch } = useInquiryDetailsData()

  const handleReasonChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (error) {
      setError('')
    }
    setReason(e.target.value)
  }

  const handleOnReject = async () => {
    if (!reason) {
      setError('Reason is required')
      return
    }

    const response = await updateInquiryStatusByFlow(data.inquiry, false)
    if (onRejectAction) {
      onRejectAction()
    }
    if (!response?.error) {
      await refetch()
      onReject()
    }
  }

  const eventVenue = convertInquiryToEventVenue(data)

  return (
    <Box
      display="flex"
      width="100%"
      maxWidth={400}
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={5}>
        <Typography fontWeight={600} fontFamily="Poppins" variant="h4">
          Rejection
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" width={1}>
        {!!eventVenue && <VenueCard withActions={false} venue={eventVenue} />}
        <Box mt={4} mb={3}>
          <Typography variant="body2" fontWeight={600} fontSize={16}>
            Inquiry Recap
          </Typography>
        </Box>
        <Divider sx={{ mb: 3 }} />
        <InitialEventData
          endDate={data.end_date}
          startDate={data.start_date}
          eventType={data.type}
          guests={data.guests}
          mb={6}
        />
        <Box mt={4} mb={3} display="flex" justifyContent="space-between">
          <Typography variant="body2" fontWeight={600} fontSize={16}>
            Reason*
          </Typography>
          <Typography variant="body2" color="#9396A3">
            (Only Blace team can see your answer)
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" mb={5}>
          <Box display="flex">
            <TextField
              placeholder="Type reason here"
              error={!!error}
              helperText={error}
              onChange={handleReasonChange}
              inputProps={{ maxLength: 100 }}
              value={reason}
              fullWidth
              multiline
              rows={1}
            />
          </Box>
        </Box>
      </Box>
      <SimpleButton
        variant="contained"
        style={{ background: '#E04E2F' }}
        onClick={handleOnReject}
      >
        Reject
      </SimpleButton>
    </Box>
  )
}

export default InquiryRejectedModal
