import { MessageItem } from '../../types/dtos/messages'

export const messagesMapping = (
  messages: any[] = [],
  authUserId?: number
): MessageItem[] => {
  return messages.map((message: any) => {
    return {
      position: message.user_id == authUserId ? 'right' : 'left',
      text: message.body,
      images: message.images,
      files: message.files,
      date: new Date(message.created_at),
    }
  })
}
