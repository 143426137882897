import { Box, IconButton, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { EventVenue, LocalEvent } from '../../types/Event'
import { removeVenueFromEvent } from '../../utils/helpers/events'
import { formatToCurrency } from '../../utils/helpers/price'
import { getAddressString } from '../../utils/helpers/venue'
import { useStorageEventsData } from '../../utils/providers/EventsProvider'
import { Image } from '../ui/Image'

export const StyledCard = styled(Box)`
  background: black;
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  min-height: 100px;

  .venue-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 80%;
  }

  .package-expander {
    display: flex;
    align-items: center;
    font-size: 14px;

    .MuiSvgIcon-root {
      font-size: 24px;
    }
  }

  .MuiIconButton-root {
    color: white;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const VenueCard: React.FC<{
  userEvent?: LocalEvent
  venue: EventVenue
  withActions?: boolean
}> = ({ userEvent, withActions, venue }) => {
  const { updateEventById } = useStorageEventsData()
  const { t } = useTranslation()

  const handleVenueRemove = () => {
    if (userEvent) {
      const updatedEvent = removeVenueFromEvent(userEvent)

      if (updatedEvent) {
        updateEventById(updatedEvent, userEvent?.id)
      }
    }
  }

  return (
    <StyledCard>
      <Box display="flex" sx={{ marginRight: '16px', height: 100, width: 100 }}>
        <Image
          image={venue?.photo}
          imageSize="small"
          staticImageType="venue"
          alt={venue.name}
        />
      </Box>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box display="flex" width="90%" mb={1}>
          <Typography className="venue-name" variant="h4" color="secondary">
            {venue.name}
          </Typography>
          {withActions && (
            <IconButton
              aria-label={`remove varic`}
              onClick={() => handleVenueRemove()}
            >
              <Close style={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Box>
        <Box display="flex" flex={1} mb={1}>
          <Typography fontSize={14} color="secondary">
            {getAddressString(venue.address)}
          </Typography>
        </Box>
        <Box
          display="flex"
          width="100%"
          flex={1}
          justifyContent="space-between"
        >
          <Typography fontSize={14} color="secondary">
            {venue.displayPricing
              ? `From ${formatToCurrency(venue.pricing)}/day`
              : venue.pricingDetails
              ? ''
              : t(
                  'business.pricing.landlord.notDisplay',
                  'Inquire for Pricing'
                )}
          </Typography>
          <Box display="flex">
            {/*<Star fontSize="small" style={{ color: '#F0B440' }} />*/}
            {/*<Typography fontSize={14} color="secondary" style={{ marginRight: 10 }}>*/}
            {/*  4.9*/}
            {/*</Typography>*/}
          </Box>
        </Box>
      </Box>
    </StyledCard>
  )
}
