import React, { lazy, Suspense } from 'react'

import { useIsAbsUser } from '../../../../../hooks/useIsAbsUser'
import { Loader } from '../../../Loader'

const Dates = lazy(() =>
  import('./ItemDates').then(({ ItemDates }) => ({
    default: ItemDates,
  }))
)
const AbsDates = lazy(() =>
  import('./AbsItemDates').then(({ AbsItemDates }) => ({
    default: AbsItemDates,
  }))
)

export const ItemDates = (
  props: React.ComponentProps<typeof Dates | typeof AbsDates>
) => {
  const isAbsUser = useIsAbsUser()
  const DatesComponent = isAbsUser ? AbsDates : Dates

  return (
    <Suspense fallback={<Loader />}>
      <DatesComponent {...props} />
    </Suspense>
  )
}
