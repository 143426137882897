import { Alert, AlertColor, AlertProps, Typography } from '@material-ui/core'
import * as React from 'react'
import { toast, ToastOptions, ToastPosition } from 'react-toastify'

import { TOAST_NOTIFICATION_CONTAINER_ID } from '../constants'

const SNACKBAR_AUTO_HIDE_DURATION = 5000

const toastOptions: ToastOptions = {
  autoClose: SNACKBAR_AUTO_HIDE_DURATION,
  containerId: TOAST_NOTIFICATION_CONTAINER_ID,
}

export interface SnackbarParams {
  severity: AlertColor | undefined
  message: string | React.ReactNode
  position?: ToastPosition
  alertProps?: AlertProps
}

export type ShowMessage = (params: SnackbarParams) => void

export const useSnackbar =
  (): ShowMessage =>
  ({
    severity,
    message,
    position = toast.POSITION.BOTTOM_LEFT,
    alertProps,
  }) => {
    toast(
      () => (
        <Alert
          sx={{ mb: 1, ml: 4 }}
          className="alert-container"
          severity={severity}
          variant="filled"
          {...alertProps}
        >
          <Typography mr={2}>{message}</Typography>
        </Alert>
      ),
      {
        ...toastOptions,
        position,
      }
    )
  }
