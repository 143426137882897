import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@material-ui/core'
import { Star } from '@material-ui/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import Exclamation from '../../../../../components/svg/Exclamation'
import { SimpleModal } from '../../../../../components/ui/Modals/SimpleModal'
import { InquiryExclusivityTypes } from '../../../../../constants/inquiry'
import { useSimpleModal } from '../../../../../hooks/useSimpleModal'
import { useUrlParams } from '../../../../../hooks/useUrlParams'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { AuthMenuActions } from '../../../../../types/Auth'
import { EventVendor, EventVenue, LocalEvent } from '../../../../../types/Event'
import { StageInfo } from '../../../../../types/Inquiry'
import { VendorType } from '../../../../../types/Vendor'
import { useEventInquiriesStatuses } from '../../../../../utils/api/events'
import { updateInquiryStatus } from '../../../../../utils/api/inquiries'
import { formatEventService } from '../../../../../utils/helpers/events'
import { isServiceVendor } from '../../../../../utils/helpers/vendors'
import { vendorsCategoryPath } from '../../../../../utils/paths'
import { useAuthData } from '../../../../../utils/providers/AuthProvider'
import tracking from '../../../../../utils/tracking'
import { InformationModalContent } from '../../../components/InformationModalContent'
import {
  FormStatusBox,
  IconContainer,
  StyledCategoryName,
  TableContainerWrapper,
} from '../../Events.styles'
import { InHouseTooltip } from '../tooltips/InHouseTooltip'

import { AddServiceButton } from './AddServiceButton'
import { ServicesTableRow } from './ServicesTableRow'

interface TableRowBlockProps {
  isEventDraft: boolean
  onToggleMessenger: () => void
  eventData: LocalEvent
  categories?: VendorType[]
  services: Array<EventVenue | EventVendor>
  title: string
  toggleInHouseVendorModal?: () => void
  onHandleSelectedCategoryClick?: (
    category: VendorType,
    isSubmitClicked?: boolean,
    serviceId?: string
  ) => void
  onRemove?: (removedVendor?: EventVendor) => void
  serverJotForms?: any[]
  isVendorsLocked?: boolean
  isInListOfInHouseVendorTypes?: boolean
  fetchEvent: () => void
}

export const ServicesTable: React.FC<TableRowBlockProps> = ({
  isEventDraft,
  isVendorsLocked,
  serverJotForms,
  services,
  title,
  eventData,
  onToggleMessenger,
  categories = [],
  onHandleSelectedCategoryClick,
  isInListOfInHouseVendorTypes,
  toggleInHouseVendorModal,
  onRemove,
  fetchEvent,
}) => {
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const { urlParams } = useUrlParams()
  const inquiryId = urlParams.inquiryId
  const openChat = urlParams.openChat
  const {
    isOpen: isLockedVendorModalOpen,
    toggleModal: toggleLockedVendorModal,
  } = useSimpleModal()
  const { authUser, authModalControl } = useAuthData()
  const { data: statusesData } = useEventInquiriesStatuses()

  const [exclusiveInfoOpen, setExclusiveInfoOpen] = useState(false)
  const [jotForms, setJotForms] = useState<any[]>([])
  const [isFormFilled, setIsFormFilled] = useState<boolean>(true)
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false)

  const { toggleModal: toggleAuthModal } = authModalControl

  const { t } = useTranslation()

  const isExclusiveVendor = useMemo(() => {
    const service = services[0]
    return isServiceVendor(service) && service.exclusive
  }, [services])

  const isInHouseVendor = useMemo(() => {
    return (
      (isExclusiveVendor &&
        isExclusiveVendor === InquiryExclusivityTypes.IN_HOUSE) ||
      isInListOfInHouseVendorTypes
    )
  }, [isExclusiveVendor])

  const serviceBg = useMemo(() => {
    const status = formatEventService(eventData, services[0], categories).status

    const colorsInfo = statusesData?.inquiryStatus?.stages
    const statusInfo = statusesData?.inquiryStatus?.status_info[status]
    const stage = statusInfo?.stage
    const stageInfo =
      stage && colorsInfo ? colorsInfo[stage] : ({} as StageInfo)

    return stageInfo?.label
      ? `${stageInfo?.bg_color.split(', ').slice(0, 3).toString()}, 0.05)`
      : 'rgba(189, 189, 189, 0.05)'
  }, [services])

  const handleVendorSearch = () => {
    if (authUser) {
      history.push(
        generatePath(vendorsCategoryPath, { category: title.toLowerCase() }),
        { insideVenue: true }
      )
    } else {
      toggleAuthModal({
        step: AuthMenuActions.Signin,
        message: t(
          'auth.logIn.need',
          'Please, log in in order to be able to further event planning'
        ),
      })
    }
  }

  const onCategoryClick = (serviceId?: string, isSubmitClicked?: boolean) => {
    if (!isFormFilled) {
      const selectedCategory = categories.find((item) => item.id === title)
      if (selectedCategory && onHandleSelectedCategoryClick) {
        onHandleSelectedCategoryClick(
          selectedCategory,
          isSubmitClicked,
          serviceId
        )
      }
    }
  }

  useEffect(() => {
    if (serverJotForms) {
      const service = services[0]
      const jotFormsByCategory = serverJotForms.filter(
        // @ts-ignore
        (item: any) => item.vendor_type === service?.type
      )
      setJotForms([...jotFormsByCategory])
    }
  }, [serverJotForms])

  useEffect(() => {
    if (authUser && !authUser?.name) {
      setIsFormFilled(false)
    }
  }, [authUser?.name])

  useEffect(() => {
    if (jotForms.length) {
      tracking.filledQuestionary(jotForms)
      setIsFormFilled(true)
    } else if (eventData?.vendors?.length && jotForms.length) {
      setIsFormFilled(
        Boolean(
          eventData?.vendors?.find(
            (vendor) => vendor.type === title && vendor.withSubmittedJotForm
          )
        )
      )
    } else if (!!eventData?.vendors && !!jotForms && !!authUser) {
      setIsFormFilled(false)
    }
  }, [eventData?.vendors, jotForms.length, authUser])

  useEffect(() => {
    if (openChat && !inquiryId) onToggleMessenger()
  }, [openChat, inquiryId])

  const categoryName = categories.find((item) => item.id === title)?.name || ''

  const onChangeStatus = async (service: EventVendor | EventVenue) => {
    if (service.inquiryId) {
      const res = await updateInquiryStatus(service.inquiryId.toString(), {
        status: 'inquiring',
      })
      console.log(res, 'STATUS CHANGED')
    }
  }

  return (
    <Box display="flex" mb={4} width={1}>
      <TableContainer sx={{ overflowX: 'inherit' }}>
        <TableContainerWrapper
          bgColor={
            !isMobile
              ? title === 'Venue'
                ? 'rgba(255, 149, 0, 0.12)'
                : 'rgba(132, 132, 132, 0.12)'
              : serviceBg
          }
        >
          <Box
            display="flex"
            justifyContent="space-between"
            position={isMobile ? 'relative' : 'static'}
          >
            {title !== 'Venue' && (
              <Box
                width="100%"
                display="flex"
                mt={isMobile ? 4 : 0}
                mx={isMobile ? '16px' : 0}
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={isMobile ? 'flex-start' : 'center'}
                pb={!isInHouseVendor ? 3 : 0}
              >
                <StyledCategoryName
                  isMobile={Boolean(isMobile)}
                  isInHouseVendor={Boolean(isInHouseVendor)}
                  variant="body2"
                >
                  {categoryName}
                </StyledCategoryName>
                {!isInHouseVendor && !isFormFilled ? (
                  <FormStatusBox
                    isSubmitClicked={isSubmitClicked}
                    isFormFilled={isFormFilled}
                    onClick={() => onCategoryClick()}
                  >
                    <Typography style={{ marginLeft: '5px', fontSize: '12px' }}>
                      {t('vendor.interviewForm', 'Provide additional info')}
                    </Typography>
                  </FormStatusBox>
                ) : null}
                {isInHouseVendor && (
                  <InHouseTooltip
                    toggleInHouseVendorModal={toggleInHouseVendorModal}
                  />
                )}
              </Box>
            )}
            {!eventData?.venue?.partner &&
              title !== 'Venue' &&
              !isExclusiveVendor && (
                <AddServiceButton handleVendorSearch={handleVendorSearch} />
              )}
          </Box>
          {!isInHouseVendor && (
            <Table>
              <TableBody>
                {services.map((service) => {
                  return (
                    <ServicesTableRow
                      key={service.id}
                      isEventDraft={isEventDraft}
                      jotForms={jotForms}
                      onCategoryClick={onCategoryClick}
                      onChangeStatus={() => onChangeStatus(service)}
                      isVendorsLocked={isVendorsLocked}
                      toggleLockedVendorModal={(e: React.MouseEvent) => {
                        e.stopPropagation()
                        toggleLockedVendorModal()
                      }}
                      submitAvailable={isFormFilled}
                      fetchEvent={fetchEvent}
                      setIsSubmitClicked={setIsSubmitClicked}
                      openExclusiveInfo={() => setExclusiveInfoOpen(true)}
                      handleOnClick={() =>
                        isLockedVendorModalOpen && toggleLockedVendorModal()
                      }
                      data={formatEventService(eventData, service, categories)}
                      onRemove={
                        !isExclusiveVendor
                          ? (removedVendor) => {
                              if (onRemove) {
                                onRemove(removedVendor)
                              }
                            }
                          : undefined
                      }
                    />
                  )
                })}
              </TableBody>
            </Table>
          )}
        </TableContainerWrapper>
      </TableContainer>
      <SimpleModal
        open={exclusiveInfoOpen}
        onClose={() => setExclusiveInfoOpen(false)}
      >
        <InformationModalContent
          isEventDraft={isEventDraft}
          description={t(
            'vendor.exclusives.description',
            'The vendors who are directly connected with this event. They are obliged to participate in the event. But if you want to replace them with others or refuse their services, you have to pay the Buy out fee'
          )}
          icon={
            <IconContainer mb={4}>
              <Star style={{ fontSize: 28, color: '#F0B440' }} />
            </IconContainer>
          }
          title={t('vendor.exclusives.name', 'Exclusive vendors')}
          onToggleMessenger={onToggleMessenger}
          onClose={() => setExclusiveInfoOpen(false)}
        />
      </SimpleModal>
      <SimpleModal
        open={isLockedVendorModalOpen}
        onClose={toggleLockedVendorModal}
      >
        <InformationModalContent
          isEventDraft={isEventDraft}
          description={t(
            'events.venue.secure',
            'You’ll need to secure an event venue before you are able to submit inquiries to vendors. While you are finalizing your venue, feel free to browse vendors and save them to your dashboard.'
          )}
          detailsHeading={t(
            'vendor.question',
            'Have a question about a vendor now?'
          )}
          ctaText="Email us"
          icon={
            <IconContainer mb={4}>
              <Exclamation />
            </IconContainer>
          }
          title={t('vendor.locked.name', 'Locked vendor')}
          onToggleMessenger={onToggleMessenger}
          onClose={toggleLockedVendorModal}
        />
      </SimpleModal>
    </Box>
  )
}
