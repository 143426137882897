/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  ButtonBase,
  ButtonBaseProps,
  Stack,
  Theme,
  Typography,
  styled,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import PreviewIcon from 'components/svg/dashboard/Eye'
import DeleteIcon from 'components/svg/DeleteOutlined'
import { assign } from 'lodash/fp'
import { ReactNode } from 'react'

import { convertDateToShortString } from '../../utils/helpers/date'
import DownloadIcon from '../svg/clientDashboard/Download'
import PDFDocument from '../svg/clientDashboard/PDFDocument'

const DocumentAction = styled(ButtonBase)`
  width: 35px;
  aspect-ratio: 1;

  border-radius: 50%;
  background-color: #ffffff;

  &:hover {
    opacity: 0.8;
  }
`

const Actions = ({ children }: { children: ReactNode }) => (
  <Stack direction="row" spacing="5px">
    {children}
  </Stack>
)

const Preview = (props: ButtonBaseProps) => (
  <DocumentAction {...props}>
    <PreviewIcon css={(theme) => ({ fill: theme.palette.primary.light })} />
  </DocumentAction>
)
const Download = (props: ButtonBaseProps) => (
  <DocumentAction {...props}>
    <DownloadIcon css={(theme) => ({ fill: theme.palette.primary.light })} />
  </DocumentAction>
)
const Delete = (props: ButtonBaseProps) => (
  <DocumentAction {...props}>
    <DeleteIcon css={(theme) => ({ fill: theme.palette.primary.light })} />
  </DocumentAction>
)

Actions.Preview = Preview
Actions.Download = Download
Actions.Delete = Delete

type DocumentProps = {
  date: Date
  name: string
  icon?: ReactNode
  className?: string
  children?: ReactNode
}

const styles = {
  root: (theme: Theme) => css`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: ${theme.spacing(4)};

    padding: 12px;
    background: #f7f7f8;
  `,
  name: css`
    word-wrap: break-word;
  `,
  date: css`
    color: #9fa2b4;
    font-size: 12px;
  `,
}

const Component = styled(
  ({ date, name, icon, className, children }: DocumentProps) => (
    <div css={styles.root} className={className}>
      {icon || <PDFDocument />}
      <Stack sx={{ minWidth: '0' }}>
        <Typography variant="body2" gutterBottom css={styles.name}>
          {name}
        </Typography>
        <Typography css={styles.date}>
          Formed: {convertDateToShortString(date, true)}
        </Typography>
      </Stack>
      {children}
    </div>
  )
)(spacing)

export const Document = assign(Component, { Actions, Action: DocumentAction })
