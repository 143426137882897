import { Box, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { InvoiceDetailsModal } from '../../../../../components/PaymentModal'
import PDFDocument from '../../../../../components/svg/clientDashboard/PDFDocument'
import Eye from '../../../../../components/svg/dashboard/Eye'
import { useInvoiceModalData } from '../../../../../hooks/useInvoiceModalData'
import { useSimpleModal } from '../../../../../hooks/useSimpleModal'
import {
  GroupLineItemBundle,
  LineItemBundle,
} from '../../../../../types/dtos/inquiry'
import { dateWithSeparator } from '../../../../../utils/helpers/date'
import { GenerateInvoiceFormValues } from '../GenerateInvoicesForm'

export const GenerateInvoicesPreviewStep: React.FC<{
  groupedLineItems: Array<GroupLineItemBundle>
  lineItemsAll: Array<LineItemBundle>
}> = ({ groupedLineItems, lineItemsAll }) => {
  const { isOpen: isInvoicePreview, toggleModal: toggleInvoicePreview } =
    useSimpleModal()
  const { values } = useFormikContext<GenerateInvoiceFormValues>()
  const { formatInvoiceValuesForDetailsModal } = useInvoiceModalData()
  const { t } = useTranslation()
  const lastUpdated = dateWithSeparator(new Date(), '/')

  const [previewStep, setPreviewStep] = useState<0 | 1>(0)

  const initialDisabled =
    +values.initialPaymentPercent === 0 || +values.initialPaymentPercent === 100

  const invoicePreviewData = formatInvoiceValuesForDetailsModal(
    values,
    groupedLineItems,
    lineItemsAll,
    previewStep
  )

  return (
    <>
      <Box display="flex" mb={4}>
        <Typography fontWeight={600}>Preview invoices</Typography>
      </Box>
      {!initialDisabled && (
        <Box
          display="flex"
          alignItems="center"
          mb={5}
          justifyContent="space-between"
          style={{ padding: 12, background: '#F7F7F8', maxWidth: 352 }}
        >
          <Box display="flex" alignItems="center">
            <Box mr={4}>
              <PDFDocument />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="body2" gutterBottom>
                {t('business.invoices.initial', 'Initial payment invoice')}
              </Typography>
              <Trans i18nKey="business.invoices.lastUpdated">
                <Typography color="#9FA2B4" fontSize={12}>
                  {{ lastUpdated }}
                </Typography>
              </Trans>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              mr={2}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPreviewStep(0)
                toggleInvoicePreview()
              }}
            >
              <Eye />
            </Box>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        mb={5}
        justifyContent="space-between"
        style={{ padding: 12, background: '#F7F7F8', maxWidth: 352 }}
      >
        <Box display="flex" alignItems="center">
          <Box mr={4}>
            <PDFDocument />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="body2" gutterBottom>
              {t('business.invoices.final', 'Final payment invoice')}
            </Typography>
            <Trans i18nKey="business.invoices.lastUpdated">
              <Typography color="#9FA2B4" fontSize={12}>
                {{ lastUpdated }}
              </Typography>
            </Trans>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            mr={2}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setPreviewStep(1)
              toggleInvoicePreview()
            }}
          >
            <Eye />
          </Box>
        </Box>
      </Box>
      <InvoiceDetailsModal
        isOpen={isInvoicePreview}
        onClose={() => {
          toggleInvoicePreview()
          setPreviewStep(0)
        }}
        onAction={() => {
          if (previewStep === 1) {
            toggleInvoicePreview()
            setPreviewStep(0)
          } else {
            setPreviewStep(1)
          }
        }}
        invoice={invoicePreviewData}
        actionText={
          previewStep === 1
            ? t('business.invoices.confirm', 'Confirm')
            : t('business.invoices.next', 'Next invoice')
        }
      />
    </>
  )
}
