import { BusinessType } from 'constants/profile'

import {
  QueryClient,
  UseQueryResult,
  useQuery,
  useQueryClient,
} from 'react-query'

import { LineItemCreateRequest } from '../../types/dtos/lineItems'
import { RawLineItem } from '../../types/RawLineItem'
import { clearObject } from '../helpers/data'
import {
  getLineItemsApiPath,
  lineItemsApiPath,
  lineItemsTypesApiPath,
} from '../paths'

import { deleteRequest, get, post, put } from './api'

export type LineItemsQuery = UseQueryResult<RawLineItem[], Error> & {
  itemsNames: Record<number, string>
  queryClient: QueryClient
  initialPaymentExclusives: number[]
  key: Array<string | number | boolean>
}

type LineItemsParams = {
  businessType: BusinessType
  id: number
}
type LineItemsResponse = {
  list: RawLineItem[]
  initial_payment_exclusives: number[]
}

async function getLineItems(
  options: LineItemsParams
): Promise<LineItemsResponse> {
  return get(lineItemsApiPath, { ...clearObject(options) })
}

export function useLineItems(
  params: LineItemsParams,
  fetchOptions: { enabled: boolean } = { enabled: false }
): LineItemsQuery {
  const queryClient = useQueryClient()
  const key = ['line-items', ...Object.values(params)]

  const query = useQuery<LineItemsResponse, Error, LineItemsResponse>(
    key,
    () => getLineItems(params),
    fetchOptions
  )

  const initialPaymentExclusives =
    query.data?.initial_payment_exclusives || ([] as number[])
  const data = query.data?.list
  const itemsNames: Record<number, string> = {}
  data?.forEach((item) => {
    itemsNames[item.id] = item.name
  })

  return {
    ...query,
    data,
    itemsNames,
    initialPaymentExclusives,
    queryClient,
    key,
  } as LineItemsQuery
}

export async function storeLineItem(
  data: LineItemCreateRequest
): Promise<RawLineItem> {
  return post(lineItemsApiPath, data)
}

export async function updateLineItem(
  id: string,
  data: LineItemCreateRequest
): Promise<RawLineItem> {
  return put(getLineItemsApiPath(id), data)
}

export async function deleteLineItem(id: string): Promise<RawLineItem> {
  return deleteRequest(getLineItemsApiPath(id))
}

async function getLineItemsTypes(): Promise<Record<string, string>> {
  return get(lineItemsTypesApiPath)
}

export function useLineItemsTypes() {
  return useQuery<Record<string, string>, Error>(
    ['line-items-types'],
    getLineItemsTypes
  )
}
