const Mail = ({ fill = '#2F54EB' }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.125 0.125H0.875C0.529297 0.125 0.25 0.404297 0.25 0.75V13.25C0.25 13.5957 0.529297 13.875 0.875 13.875H17.125C17.4707 13.875 17.75 13.5957 17.75 13.25V0.75C17.75 0.404297 17.4707 0.125 17.125 0.125ZM16.3438 2.28906V12.4688H1.65625V2.28906L1.11719 1.86914L1.88477 0.882813L2.7207 1.5332H15.2812L16.1172 0.882813L16.8848 1.86914L16.3438 2.28906ZM15.2812 1.53125L9 6.41406L2.71875 1.53125L1.88281 0.880859L1.11523 1.86719L1.6543 2.28711L8.32617 7.47461C8.51805 7.62367 8.7541 7.70459 8.99707 7.70459C9.24004 7.70459 9.4761 7.62367 9.66797 7.47461L16.3438 2.28906L16.8828 1.86914L16.1152 0.882813L15.2812 1.53125Z"
        fill={fill}
      />
    </svg>
  )
}

export default Mail
