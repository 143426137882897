import { Box, Typography, useTheme } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { FastTextField } from '../../../../../components/formik'
import Description from '../../../../../components/svg/dashboard/Description'
import DollarCircle from '../../../../../components/svg/DollarCircle'
import FloorNumber from '../../../../../components/svg/FloorNumber'
import PeopleOutlined from '../../../../../components/svg/PeopleOutlined'
import TotalSquare from '../../../../../components/svg/TotalSquare'
import { SimpleButton } from '../../../../../components/ui/Buttons/SimpleButton'
import { Image } from '../../../../../components/ui/Image'
import { Loader } from '../../../../../components/ui/Loader'
import { AssetRefDownload } from '../../../../../types/FileUploadHandler'
import { useMediaModalData } from '../../../../../utils/providers/MediaLibraryProvider'
import { CustomImage } from '../../../Dashboard.styles'
import {
  CancelButton,
  FieldLabel,
  FormContainer,
  ThinTextField,
} from '../../BusinessDetails.styles'
import { LandlordEditPricing } from '../LineItemsAndPricing/components/LandlordEditPricing'
import { LandlordPricingSwitcher } from '../LineItemsAndPricing/components/LandlordPricingSwitcher'

import { RoomsFormValues } from './Rooms'
import { createRoomsValidationSchema } from './validation'

interface RoomsFormProps {
  onSubmit: (
    values: RoomsFormValues,
    { setSubmitting, resetForm }: any
  ) => Promise<void>
  initialValues: RoomsFormValues
  photos?: AssetRefDownload[]
  setPhotos: Dispatch<SetStateAction<AssetRefDownload[] | undefined>>
  onCancel: () => void
  isEditing: boolean
}

export const RoomsForm: React.FC<RoomsFormProps> = ({
  onSubmit,
  initialValues,
  photos,
  setPhotos,
  onCancel,
  isEditing,
}) => {
  const { t } = useTranslation()
  const { openMediaModal } = useMediaModalData()

  const theme = useTheme()

  const onPhotoChange = (changedPhotos: AssetRefDownload[]) => {
    setPhotos(changedPhotos)
  }

  const handleOpenMediaModal = () => {
    openMediaModal({ initialImages: photos, onSave: onPhotoChange })
  }

  return (
    <Formik
      validationSchema={createRoomsValidationSchema()}
      autoComplete="on"
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        handleSubmit,
        isValid,
        values,
        isSubmitting,
        handleChange,
      }) => {
        return (
          <FormContainer onSubmit={handleSubmit}>
            <Box display="flex" width={1} flexDirection="column" flex={1}>
              <Box display="flex" flexDirection="column" mb={5}>
                <FieldLabel variant="body2">
                  {t('business.rooms.roomName.label', '*Room Name')}
                </FieldLabel>
                <FastTextField
                  name="name"
                  placeholder={t(
                    'business.rooms.roomName.placeholder',
                    'Type room name here'
                  )}
                />
              </Box>
              <Box mb={3} display="flex" justifyContent="space-between">
                <FieldLabel variant="body2">Room Photo</FieldLabel>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                mb={3}
                width={420}
                height={120}
                style={{ background: '#FAFAFA' }}
              >
                {!!photos?.length ? (
                  <CustomImage>
                    <Image
                      image={
                        photos[0].metadata.image ||
                        photos[0].metadata.previewUrl
                      }
                      imageSize={
                        photos[0].metadata.image ? 'medium' : 'original'
                      }
                      style={{ position: 'absolute', top: 0, left: 0 }}
                    />
                    <Box
                      display="flex"
                      style={{
                        padding: '10px 32px',
                        border: '1.5px dashed #2F54EB',
                        cursor: 'pointer',
                        background: 'rgba(250, 250, 250, 0.5)',
                        zIndex: 1,
                      }}
                      onClick={handleOpenMediaModal}
                    >
                      <Typography color="#2F54EB">
                        {t(
                          'business.rooms.manageRoomPhoto',
                          'Manage room photo'
                        )}
                      </Typography>
                    </Box>
                  </CustomImage>
                ) : (
                  <Box
                    display="flex"
                    style={{
                      padding: '10px 32px',
                      border: '1.5px dashed #2F54EB',
                      cursor: 'pointer',
                    }}
                    onClick={handleOpenMediaModal}
                  >
                    <Typography color="#2F54EB">
                      {t('business.rooms.addRoomPhoto', 'Add room photo')}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" mb={5}>
                <FieldLabel variant="body2">
                  {t('business.rooms.roomDetail', 'Room Detail')}
                </FieldLabel>
                <Box display="flex" mb={2} mt={4}>
                  <FloorNumber />
                  <Typography sx={{ marginLeft: '10px' }} variant="body2">
                    {t('business.rooms.floorNumber.label', '*Floor Number')}
                  </Typography>
                </Box>
                <FastTextField
                  name="floors"
                  type="number"
                  placeholder={t(
                    'business.rooms.floorNumber.placeholder',
                    'Number of floors'
                  )}
                />
                <Box display="flex" mb={2} mt={4}>
                  <TotalSquare />
                  <Typography sx={{ marginLeft: '10px' }} variant="body2">
                    {t(
                      'business.rooms.totalSquare.label',
                      '*Total Square Footage'
                    )}
                  </Typography>
                </Box>
                <FastTextField
                  name="space"
                  type="number"
                  placeholder={t(
                    'business.rooms.totalSquare.placeholder',
                    'Square feet'
                  )}
                />
                <Box display="flex" mt={5} mb={2}>
                  <PeopleOutlined />
                  <Typography sx={{ marginLeft: '10px' }} variant="body2">
                    {t('business.rooms.maxCapacity.label', '*Max capacity')}
                  </Typography>
                </Box>
                <FastTextField
                  name="capacity"
                  placeholder={t(
                    'business.rooms.maxCapacity.placeholder',
                    'Number of people'
                  )}
                  type="number"
                />
                <Box display="flex" mb={2} mt={4}>
                  <Description />
                  <Typography sx={{ marginLeft: '10px' }} variant="body2">
                    {t('business.rooms.details.label', 'Details')}
                  </Typography>
                </Box>
                <Box display="flex">
                  <ThinTextField
                    name="details"
                    value={values.details}
                    placeholder={t(
                      'business.rooms.details.placeholder',
                      'Type room description here'
                    )}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={3}
                    InputProps={{ inputProps: { maxLength: 65535 } }}
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                  mt={4}
                >
                  <Box display="flex">
                    <DollarCircle fill={theme.palette.grey[200]} />
                    <Typography sx={{ marginLeft: '10px' }} variant="body2">
                      {t('business.pricing.landlord.heading', 'Pricing')}
                    </Typography>
                  </Box>
                  <LandlordPricingSwitcher
                    showPricing={!!values.display_pricing}
                    setShowPricing={(value: boolean) =>
                      setFieldValue('display_pricing', value)
                    }
                  />
                </Box>
                <LandlordEditPricing
                  showPricing={!!values.display_pricing}
                  pricing={`${values.price}`}
                  handlePriceChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('price', e.target.value)
                  }
                  shortPricingTextValue={values.short_pricing_details || ''}
                  setShortPricingTextValue={(value: string) =>
                    setFieldValue('short_pricing_details', value)
                  }
                  longPricingText={values.pricing_details || ''}
                  setLongPricingText={(value: string | null) =>
                    setFieldValue('pricing_details', value)
                  }
                />
              </Box>
              <Box display="flex">
                <CancelButton mr={3} onClick={onCancel}>
                  Cancel <ClearIcon style={{ marginLeft: 10 }} />
                </CancelButton>
                <SimpleButton
                  disabled={!isValid || isEmpty(values)}
                  type="submit"
                  variant="contained"
                >
                  {!isEditing
                    ? t('business.rooms.addRoom', 'Add room')
                    : t('business.rooms.saveChanges', 'Save changes')}
                </SimpleButton>
              </Box>
            </Box>
            {isSubmitting && <Loader position="fixed" />}
          </FormContainer>
        )
      }}
    </Formik>
  )
}
