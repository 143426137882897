import { Box, Typography } from '@material-ui/core'
import * as React from 'react'

import People from '../../../svg/People'

export interface ItemPersonsQuantityProps {
  quantity: string
}

export const ItemPersonsQuantity: React.FC<ItemPersonsQuantityProps> = ({
  quantity,
}) => {
  return (
    <Box display="flex" alignItems="center" pl={1} pr={4}>
      <People />
      <Typography style={{ marginLeft: '10px' }} variant="caption">
        {quantity}
      </Typography>
    </Box>
  )
}
