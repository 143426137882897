import { List, ListItem, ListItemProps } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'

type NavListItemProps = ListItemProps & {
  button?: any
  component?: any
  to?: any
}

export const StyledListItem: React.FC<NavListItemProps> = styled(
  ListItem
)<NavListItemProps>(
  ({ theme }) => `
  color: #9396A3;
  margin: 10px 0;
  font-size: 14px;
  .MuiListItemIcon-root {
    min-width: 35px;
  }
  &:hover {
   background: #FFFFFF;
  }
  &.active {
  box-shadow: 0px, 4px rgba(231, 230, 230, 0.4);
    color: #20202C;
    path {
      fill: #20202C;
    }
  }
  
  ${MEDIA_RULES.SMALL} {
    padding: 24px 0;
    margin: 0;
    border-bottom: 1px solid rgba(132, 132, 132, 0.12);
    color: #000;
  }
`
)

export const StyledList = styled(List)`
  height: 100%;
  font-size: 14px;

  .active {
    color: #20202c;
    background: #ffffff;
    path {
      fill: #20202c;
    }
  }

  &.opened .active {
    background: linear-gradient(180deg, #4e4bec 0%, #3f91f0 100%);
  }

  ${MEDIA_RULES.SMALL} {
    padding: 0;
  }
`

export const MenuFooterListItem: React.FC<NavListItemProps> = styled(
  ListItem
)<NavListItemProps>(
  ({ theme }) => `
  color: #2F54EB;
  margin: 10px 0;
  font-size: 14px;
  .MuiListItemIcon-root {
    min-width: 35px;
  }
  &:hover {
   background: #FFFFFF;
  }
`
)
