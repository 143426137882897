import { Box, BoxProps, IconButton, styled, TextField } from '@material-ui/core'

export const EventListWrapper = styled(Box)<BoxProps & { isMobile: boolean }>(
  (props) => `
  .event-list-content {
    ${!props.isMobile && 'min-height: 1000px;'}

    .period-title {
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    .events-grid {
      display: grid;
      ${!props.isMobile && 'grid-template-columns: 1fr 1fr 1fr 1fr;'} 
      ${!props.isMobile && 'grid-gap: 24px;'}
      ${props.isMobile && 'flex-direction: column;'} 
    }
  }
`
)

export const StyledTextField = styled(TextField)(
  ({ theme }) => `
        background: ${theme.palette.secondary.main};
          padding: 12px;
          height: auto;
          margin-bottom: 4px;
          
          .input-text {
            color: ${theme.palette.secondary.dark}
            font-size: 14px;
            line-height: 21px
            
            input { padding: 0; }
          }
    `
)

export const StyledIconButton = styled(IconButton)`
  background: rgb(245, 245, 248);
  padding: 14px;
  margin-left: 10px;
  border-radius: 2px;

  & :hover {
    background-color: transparent;
  }
`

export const AddNewEventContainer = styled(Box)(
  ({ theme }) => `display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(89, 126, 247, 0.07);
  color: ${theme.palette.primary.light};
  border: dashed 2px rgba(89, 126, 247, 0.3);
  cursor: pointer;
  min-height: 450px;
  max-height: 460px;
`
)
