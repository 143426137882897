const LinkedInIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 12.4022V16.271H14.857V12.6607C14.857 11.7545 14.547 11.136 13.771 11.136C13.179 11.136 12.826 11.5525 12.671 11.9564C12.615 12.1008 12.6 12.3018 12.6 12.5027V16.271H10.456C10.456 16.271 10.485 10.1576 10.456 9.52445H12.6V10.4799L12.586 10.5018H12.6V10.4809C12.885 10.0205 13.393 9.36539 14.532 9.36539C15.942 9.36539 17 10.3302 17 12.4022ZM8.213 6.271C7.48 6.271 7 6.77539 7 7.43676C7 8.08556 7.466 8.60356 8.185 8.60356H8.199C8.947 8.60356 9.412 8.08452 9.412 7.43676C9.398 6.77539 8.947 6.271 8.213 6.271ZM7.127 16.271H9.271V9.52445H7.127V16.271Z"
        fill="#F7F7F8"
      />
      <path
        d="M22 7.55556V16.4444C22 17.9179 21.4147 19.3309 20.3728 20.3728C19.3309 21.4147 17.9179 22 16.4444 22H7.55556C6.08213 22 4.66905 21.4147 3.62718 20.3728C2.58532 19.3309 2 17.9179 2 16.4444V7.55556C2 6.08213 2.58532 4.66905 3.62718 3.62718C4.66905 2.58532 6.08213 2 7.55556 2H16.4444C17.9179 2 19.3309 2.58532 20.3728 3.62718C21.4147 4.66905 22 6.08213 22 7.55556Z"
        stroke="#F7F7F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkedInIcon
