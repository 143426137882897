import { styled, TextFieldProps } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField/TextField'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'

import { useWindowSize } from '../../../../hooks/useWindowSize'

const FieldWrapper = styled(FormControl)`
  justify-content: center;
  border-left: 1px solid #d7d7d7;
  padding-top: 10px;
  width: 100%;

  ${MEDIA_RULES.SMALL} {
    border-left: none;
  }
`

const StyledTextField = styled(TextField)<
  TextFieldProps & { isMobile: boolean }
>(
  (props) => `
  border: none;
  input {
    padding: 10px 0 0 13px;
    font-size: c
    text-transform: capitalize;
  }
  input::placeholder {
    color: black;
    opacity: 1;
    font-weight: 500;
    font-size: ${props.isMobile ? '12px' : '16px'};
    line-height: ${props.isMobile ? '12px' : '24px'};
  }
  label {
    font-style: normal;
    font-weight: normal;
    font-size: ${props.isMobile ? '12px' : '14px'};
    line-height: ${props.isMobile ? '12px' : '18px'};
    color: #828282;
  }
  & fieldset {
    border: none;
  }
`
)

export default function EventFormField(props: TextFieldProps) {
  const { isMobile } = useWindowSize()
  return (
    <FieldWrapper className="event-form-field-wrapper" sx={{ m: 1 }}>
      <StyledTextField
        isMobile={Boolean(isMobile)}
        className="event-form-field"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        {...props}
      />
    </FieldWrapper>
  )
}
