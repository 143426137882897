import React, { useState } from 'react'

import { ResponseOrError } from '../types/general'
import { connectSquareByCode } from '../utils/api/payments'
import { getRequestError } from '../utils/helpers/validations'
import { useAuthData } from '../utils/providers/AuthProvider'

export function useSquareActions(): {
  connectBusinessAccountToUser: (code: string) => Promise<any>
  loading: boolean
} {
  const [loading, setLoading] = useState(false)
  const { updateUser } = useAuthData()

  const connectBusinessAccountToUser = async (
    code: string
  ): Promise<ResponseOrError<any>> => {
    setLoading(true)
    try {
      const response = await connectSquareByCode(code)
      updateUser({ payment_info: response })
      return response
    } catch (e) {
      return { error: getRequestError(e) }
    } finally {
      setLoading(false)
    }
  }

  return { connectBusinessAccountToUser, loading }
}
