import { useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { useAuthData } from '../utils/providers/AuthProvider'

export function useCurrentBusinessListingId() {
  const {
    params: { listingId },
  } = useRouteMatch<{ listingId: string }>()
  const { authUser } = useAuthData()

  const businessId = useMemo(() => {
    return authUser?.listing_id || +listingId
  }, [authUser?.listing_id, listingId])

  return { businessId }
}
