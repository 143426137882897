import { Box, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import CeilingHeight from '../../../../../../components/svg/dashboard/CeilingHeight'
import MaxHeadcount from '../../../../../../components/svg/dashboard/MaxHeadcount'
import Square from '../../../../../../components/svg/dashboard/Square'
import { SimpleButton } from '../../../../../../components/ui/Buttons/SimpleButton'
import { BusinessDetailsInfo } from '../../../../../../types/general'
import { useLabels } from '../../../../../../utils/api/labels'
import { isServiceVendor } from '../../../../../../utils/helpers/vendors'
import { BlockTitle, ThinTextField } from '../../../BusinessDetails.styles'
import { createValidationSchema } from '../validationSchema'

import { LabelsSelect } from './LabelsSelect'

interface DetailsFormProps {
  onCancel: () => void
  onSuccess?: () => void
  onSave: (data: BusinessDetailsInfo) => void
  entity?: any & { name: string; description: string }
  attachLabelMethod: any
}

interface DetailsFormData {
  numberOfRooms?: number
  ceilingHeight?: number
  squareFootage?: number
  maxHeadcount?: number
}

export const DetailsForm: React.FC<DetailsFormProps> = ({
  onSave,
  onSuccess,
  onCancel,
  entity,
  attachLabelMethod,
}) => {
  const { t } = useTranslation()
  const labels = entity?.labels || []
  const labelsControl = React.useState(labels)
  const exclusivity = isServiceVendor(entity) ? 'vendors' : 'venues'

  const { data: bestUsedFor } = !isServiceVendor(entity)
    ? useLabels({
        perPage: 100,
        exclusivity,
        type: 'best used for',
        orderBy: 'name',
      })
    : { data: null }
  const { data: amenities } = !isServiceVendor(entity)
    ? useLabels({
        perPage: 100,
        exclusivity,
        type: 'amenities',
        orderBy: 'name',
      })
    : { data: null }
  const { data: specialFeaturesByType } = isServiceVendor(entity)
    ? useLabels({
        perPage: 100,
        exclusivity,
        type: 'special features',
        vendor_type: entity.type,
        orderBy: 'name',
      })
    : { data: null }
  const { data: specialFeaturesGlobal } = isServiceVendor(entity)
    ? useLabels({
        perPage: 100,
        exclusivity,
        type: 'special features',
        vendor_type: '-all-',
        orderBy: 'name',
      })
    : { data: null }

  const handleFormSubmit = async (values: DetailsFormData) => {
    try {
      const data: BusinessDetailsInfo = {
        params: {
          ceiling_height: values.ceilingHeight,
          sq_footage: values.squareFootage,
        },
        max_head_count: values.maxHeadcount,
      }
      await onSave(data)
      onSuccess ? onSuccess() : null
    } catch (e) {
      console.error(e)
    }
    onCancel()
  }

  const { handleSubmit, values, handleChange, errors, touched } =
    useFormik<DetailsFormData>({
      initialValues: {
        // numberOfRooms: 0,
        ceilingHeight: entity?.params?.ceiling_height,
        squareFootage: entity?.params?.sq_footage,
        maxHeadcount: entity?.aboutInfo?.maxGuests,
      },
      validationSchema: createValidationSchema(),
      onSubmit: handleFormSubmit,
    })

  const getTextFieldErrors = (name: keyof DetailsFormData) => {
    return {
      error: touched[name] && !!errors[name],
      helperText: (touched[name] && errors[name]) || '',
    }
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <BlockTitle>
          {t('dashboard.business.details.title', 'Business Details')}
        </BlockTitle>
        <Box display="flex">
          <Box
            display="flex"
            alignItems="center"
            mr={3}
            sx={{
              cursor: 'pointer',
              color: '#9396A3',
              '&:hover': { opacity: 0.8 },
            }}
            onClick={onCancel}
          >
            {t('common.buttons.cancel', 'Cancel')}{' '}
            <ClearIcon style={{ marginLeft: 10 }} />
          </Box>
          <Box>
            <SimpleButton
              style={{ fontSize: 16 }}
              onClick={() => handleSubmit()}
              variant="outlined"
            >
              {t('common.buttons.save', 'Save')}
            </SimpleButton>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="flex-start">
          <Box display="flex" flexDirection="column" width={1}>
            <Typography sx={{ mb: 3 }} color="#252733" fontWeight={500}>
              {t('dashboard.business.details.features', 'Features')}
            </Typography>
            <Box display="flex" mb={4}>
              <Box display="flex" flexDirection="column" width={1} mr={4}>
                <Typography
                  sx={{ mb: 3 }}
                  display="flex"
                  alignItems="center"
                  color="#9396A3"
                  variant="body2"
                >
                  <Square />
                  <span style={{ marginLeft: '10px' }}>
                    {t(
                      'dashboard.business.details.squareFootage',
                      'Square footage'
                    )}
                  </span>
                </Typography>
                <ThinTextField
                  name="squareFootage"
                  type="number"
                  value={values.squareFootage}
                  onChange={handleChange}
                  fullWidth
                  {...getTextFieldErrors('squareFootage')}
                />
              </Box>
              {/*<Box display="flex" flexDirection="column" width={1}>*/}
              {/*  <Typography sx={{ mb: 3 }} display="flex" alignItems="center" color="#9396A3" variant="body2">*/}
              {/*    <RoomsNumber />*/}
              {/*    <span style={{ marginLeft: '10px' }}>{t('dashboard.business.details.numberOfRooms', 'Number of rooms')}</span>*/}
              {/*  </Typography>*/}
              {/*  <ThinTextField name="numberOfRooms" type="number" value={values.numberOfRooms} onChange={handleChange} fullWidth {...getTextFieldErrors('numberOfRooms')} />*/}
              {/*</Box>*/}
            </Box>
            <Box display="flex" mb={4}>
              <Box display="flex" flexDirection="column" width={1} mr={4}>
                <Typography
                  sx={{ mb: 3 }}
                  display="flex"
                  alignItems="center"
                  color="#9396A3"
                  variant="body2"
                >
                  <CeilingHeight width={16} height={16} />
                  <span style={{ marginLeft: '10px' }}>
                    {t(
                      'dashboard.business.details.ceilingHeight',
                      'Ceiling height'
                    )}
                  </span>
                </Typography>
                <ThinTextField
                  name="ceilingHeight"
                  type="number"
                  value={values.ceilingHeight}
                  onChange={handleChange}
                  fullWidth
                  {...getTextFieldErrors('ceilingHeight')}
                />
              </Box>
              <Box display="flex" flexDirection="column" width={1}>
                <Typography
                  sx={{ mb: 3 }}
                  display="flex"
                  alignItems="center"
                  color="#9396A3"
                  variant="body2"
                >
                  <MaxHeadcount />
                  <span style={{ marginLeft: '10px' }}>
                    {' '}
                    {t(
                      'dashboard.business.details.maxHeadcount',
                      'Max headcount'
                    )}
                  </span>
                </Typography>
                <ThinTextField
                  name="maxHeadcount"
                  type="number"
                  value={values.maxHeadcount}
                  onChange={handleChange}
                  fullWidth
                  {...getTextFieldErrors('maxHeadcount')}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {entity && !isServiceVendor(entity) && entity.id ? (
          <Box display="flex" mt={5}>
            {amenities && (
              <LabelsSelect
                labels={amenities}
                entity={entity}
                labelsControl={labelsControl}
                attachLabelMethod={attachLabelMethod}
                type="amenities"
              />
            )}
            {bestUsedFor && (
              <LabelsSelect
                labels={bestUsedFor}
                entity={entity}
                labelsControl={labelsControl}
                attachLabelMethod={attachLabelMethod}
                type="best used for"
              />
            )}
          </Box>
        ) : (
          <Box display="flex" mt={5}>
            {specialFeaturesByType && specialFeaturesGlobal && (
              <LabelsSelect
                labels={[...specialFeaturesByType, ...specialFeaturesGlobal]}
                entity={entity}
                labelsControl={labelsControl}
                attachLabelMethod={attachLabelMethod}
                type="special features"
              />
            )}
          </Box>
        )}
      </Box>
    </>
  )
}
