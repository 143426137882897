import { Box, TableCell } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import {
  StyledAcceptButton,
  StyledRequestChangesButton,
} from '../../../ServiceBookingDetails.styles'

interface InvoiceActionsProps {
  viewAndPay: () => void
  openMessengerHandler: () => void
}

export const InvoiceActions: React.FC<InvoiceActionsProps> = ({
  viewAndPay,
  openMessengerHandler,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  return (
    <TableCell colSpan={8}>
      <Box
        display="flex"
        width={isMobile ? '100%' : '70%'}
        ml="auto"
        justifyContent="flex-end"
        mb={4}
      >
        <StyledRequestChangesButton
          sx={{ fontSize: isMobile ? '12px' : '16px' }}
          variant="contained"
          onClick={openMessengerHandler}
        >
          {t('inquiry.documents.request.changes', 'Request changes')}
        </StyledRequestChangesButton>
        <StyledAcceptButton
          sx={{ fontSize: isMobile ? '12px' : '16px' }}
          variant="contained"
          onClick={viewAndPay}
        >
          {t('client.invoice.viewAndPay', 'View and pay')}
        </StyledAcceptButton>
      </Box>
    </TableCell>
  )
}
