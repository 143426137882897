import { Box, Typography } from '@material-ui/core'
import { Field, FieldProps, useFormikContext } from 'formik'
import React, { ChangeEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FastTextField } from '../../../../../components/formik'
import DateTimeField from '../../../../../components/ui/Inputs/DateTimeField/DateTimeField'
import { venueDepositLineItemId } from '../../../../../constants'
import { LineItemBundle } from '../../../../../types/dtos/inquiry'
import { dateWithSeparator } from '../../../../../utils/helpers/date'
import {
  currencyConvertToDecimal,
  formatToCurrency,
  getPercentFromPrice,
} from '../../../../../utils/helpers/price'
import { GenerateInvoiceFormValues } from '../GenerateInvoicesForm'

export const GenerateInvoicesPaymentStep: React.FC<{
  lineItemsAll: Array<LineItemBundle>
  lineItemsTotal: number
}> = ({ lineItemsAll, lineItemsTotal }) => {
  const { values, setFieldValue } =
    useFormikContext<GenerateInvoiceFormValues>()
  const { t } = useTranslation()

  const percentTotals = useMemo(() => {
    const totals = { initial: 0, final: 0 }
    const finalPercent = 100 - +values.initialPaymentPercent

    if (
      !!+values.initialPaymentPercent &&
      +values.initialPaymentPercent !== 100
    ) {
      lineItemsAll.forEach((item: LineItemBundle) => {
        const price = item.price
          ? Math.round(
              currencyConvertToDecimal(item.price) * item.quantity * 100
            ) / 100
          : 0
        if (+item.line_item_id === venueDepositLineItemId) {
          totals.initial += price
        } else {
          totals.initial += getPercentFromPrice(
            price,
            +values.initialPaymentPercent
          )
          totals.final += getPercentFromPrice(price, finalPercent)
        }
      })
    }
    if (
      !+values.initialPaymentPercent ||
      +values.initialPaymentPercent === 100
    ) {
      totals.final = lineItemsTotal
    }

    return totals
  }, [lineItemsAll, values.initialPaymentPercent])

  const initialDisabled =
    +values.initialPaymentPercent === 0 || +values.initialPaymentPercent === 100

  return (
    <Box>
      <Box mb={4}>
        <Box display="flex" width={1}>
          <Typography variant="body1" fontWeight={600} pr="5px">
            {t(
              'business.invoices.percentage.title',
              'Initial payment percentage'
            )}
          </Typography>
        </Box>
        <Typography
          mt="8px"
          variant="body2"
          color={(theme) => theme.palette.grey[200]}
        >
          {t(
            'business.invoices.percentage.subtitle',
            'Set the percentage amount for the initial payment. If only one payment is required, set to 100%.'
          )}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={5}>
        <Box display="flex" flex={1}>
          <FastTextField
            inputProps={{ min: 0, max: 100 }}
            onInput={(e: ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '')
              if (!e.target.value) return

              const value = parseInt(e.target.value)
              if (isNaN(value)) {
                e.target.value = ''
              }
              if (value > 100) {
                e.target.value = '100'
              }
              setFieldValue('initialPaymentPercent', value)
            }}
            type="number"
            name="initialPaymentPercent"
            style={{ fontSize: 14 }}
            placeholder={t('business.invoices.percentage.placeholder', '100%')}
            fullWidth
          />
        </Box>
        <Box flex={1}></Box>
      </Box>
      <Typography variant="body1" fontWeight={600} mb={4}>
        {t('business.invoices.dueDates', 'Invoices due dates')}
      </Typography>
      <Box display="flex" flexDirection="row" mb={5}>
        <Box>
          <Field name="initialDueDate">
            {({ field, form: { errors, setFieldValue } }: FieldProps) => {
              return (
                <DateTimeField
                  withPlaceholder={false}
                  disabled={initialDisabled}
                  withTime={false}
                  formatDate={(date) => dateWithSeparator(date, '/')}
                  label="Initial payment Invoice due date"
                  value={field.value}
                  setValue={(value) => setFieldValue(field.name, value)}
                  error={!!errors.initialDueDate}
                />
              )
            }}
          </Field>
          <Typography
            variant="body2"
            fontWeight={500}
            color={initialDisabled ? '#BDBDBD' : '#000'}
          >
            {formatToCurrency(percentTotals.initial)} /{' '}
            {t('business.invoices.initialTotal', 'Initial total')}
          </Typography>
        </Box>
        <Box pl="16px">
          <Field name="finalDueDate">
            {({ field, form: { errors, setFieldValue } }: FieldProps) => {
              return (
                <DateTimeField
                  withPlaceholder={false}
                  withTime={false}
                  formatDate={(date) => dateWithSeparator(date, '/')}
                  label="Final payment Invoice due date"
                  value={field.value}
                  setValue={(value) => setFieldValue(field.name, value)}
                  error={!!errors.finalDueDate}
                />
              )
            }}
          </Field>
          <Typography variant="body2" fontWeight={500} color="#000">
            {formatToCurrency(percentTotals.final)} /{' '}
            {t('business.invoices.finalTotal', 'Final total')}
          </Typography>
        </Box>
      </Box>
      <Typography
        mt="8px"
        mb={4}
        variant="body2"
        color={(theme) => theme.palette.grey[200]}
      >
        {t(
          'business.invoices.percentage.initial',
          'Initial payment invoice always includes 100% of security deposit.'
        )}
      </Typography>
    </Box>
  )
}
