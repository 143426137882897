import { INQUIRY_STAGE_PIPELINE } from 'constants/inquiry'

import { Box, Typography } from '@material-ui/core'
import GrayExclamationCircle from 'components/svg/dashboard/GrayExclamationCircle'
import { TooltipComponent } from 'components/ui/Tooltip'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BusinessInquiryActions } from 'types/dtos/inquiry'
import { InquiryWithDetails } from 'types/Inquiry'
import { useEventInquiriesStatuses } from 'utils/api/events'

import { StyledTooltipPopper } from '../../BookingDetails.styles'

import { EventProgressItem } from './EventProgressItem'
import { PipelineActionButton } from './PipelineActionButton'
import { StageHint } from './StageHint'

interface EventProgressProps {
  inquiryDetails?: InquiryWithDetails
  handleAction: (action: BusinessInquiryActions) => void
}

export const EventProgress: React.FC<EventProgressProps> = ({
  inquiryDetails,
  handleAction,
}) => {
  const { data: statusesData } = useEventInquiriesStatuses()
  const { t } = useTranslation()

  const currentStage = useMemo(() => {
    if (!inquiryDetails?.status) return ''
    return statusesData?.inquiryStatus?.status_info[inquiryDetails?.status]
      ?.stage
  }, [inquiryDetails?.status])

  return (
    <Box display="flex" width={1} flexDirection="column">
      <Box display="flex" width={1} justifyContent="space-between" mb="17px">
        <Box display="flex">
          <Typography fontSize={18} fontWeight={600}>
            {t('business.inquiry.stages', 'Stages')}
          </Typography>
          {React.createElement(
            TooltipComponent,
            {
              // @ts-ignore
              popperComponent: StyledTooltipPopper,
              arrow: true,
              placement: 'top',
              title: 'The progress ot the deal is displayed here',
            },

            <Box ml={2}>
              <GrayExclamationCircle />
            </Box>
          )}
        </Box>
      </Box>
      <Box mb={5} mr={6} width={1}>
        <Box display="flex" justifyContent="space-between">
          {INQUIRY_STAGE_PIPELINE.map((pipelineStage, index) => (
            <EventProgressItem
              stage={pipelineStage}
              currentStage={currentStage}
              colorsInfo={statusesData?.inquiryStatus?.stages}
              key={index}
              number={index + 1}
            />
          ))}
        </Box>
        {currentStage && <StageHint stage={currentStage} />}
      </Box>
      <Box mt={4} display="flex" justifyContent="flex-end">
        <PipelineActionButton
          status={inquiryDetails?.status}
          handleAction={handleAction}
        />
      </Box>
    </Box>
  )
}
