import { Box, Typography } from '@material-ui/core'
import * as React from 'react'

import { MultiSelectField } from '../../components/ui/FormComponents/MultiSelectField'
import { CALENDAR_VIEW_BY, getCalendarUrl } from '../../constants/events'
import { useStorageEventsData } from '../../utils/providers/EventsProvider'
import { CalendarPage } from '../calendar'
import { CopyUrlComponent } from '../dashboard/BusinessDetails/components/CopyUrlComponent'

export const ClientCalendarPage: React.FC = () => {
  const { events } = useStorageEventsData()
  const clientCalendars = events
    .filter((event) => event.calendar && event.calendar.google_id)
    .map((event) => event?.calendar?.google_id || '')
  const calendarUrl = getCalendarUrl(clientCalendars[0])
  const [viewBy, setViewBy] = React.useState(CALENDAR_VIEW_BY[0])

  return (
    <Box className="container" mt="32px">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <CopyUrlComponent
            id="calendar-link"
            calendarUrl={calendarUrl}
            onCopyCalendarLink={() => {
              navigator.clipboard.writeText(calendarUrl)
            }}
          />
          <MultiSelectField
            id="viewBy"
            sx={{ minWidth: 120 }}
            defaultLabel={`View by: ${viewBy}`}
            options={CALENDAR_VIEW_BY}
            onChange={(value: any) => setViewBy(value)}
          />
        </Box>
      </Box>
      {clientCalendars?.length ? (
        <CalendarPage viewBy={viewBy} calendarGoogleId={clientCalendars} />
      ) : null}
    </Box>
  )
}
