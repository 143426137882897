import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, generatePath, useRouteMatch } from 'react-router-dom'

import { Loader } from '../../../components/ui/Loader'
import { BottomModal } from '../../../components/ui/Modals/BottomModal'
import { SideMenu } from '../../../components/ui/SideMenu'
import { EmptyGooglePlace } from '../../../constants'
import { getLandlordBusinessDetailsMenu } from '../../../constants/business'
import { UserRoles } from '../../../constants/profile'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useVenueById } from '../../../hooks/useVenueById'
import { AssetRefDownload } from '../../../types/FileUploadHandler'
import {
  BusinessContactData,
  BusinessDetailsInfo,
  BusinessGeneralInfo,
} from '../../../types/general'
import { ServerVenue } from '../../../types/Venue'
import {
  addLabelToVenues,
  updateBusinessImages,
} from '../../../utils/api/venues'
import { DeepPartial } from '../../../utils/helpers/common'
import { venuesMapper } from '../../../utils/mappers/venues'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useMessageModalData } from '../../../utils/providers/MessageModalProvider'
import { Venue } from '../../venues/Venue'

import { BusinessContact } from './components/BusinessContact'
import { Details } from './components/Details'
import { GeneralInfo } from './components/GeneralInfo'
import { LineItemsAndPricing } from './components/LineItemsAndPricing'
import { PhotoUpload } from './components/PhotoUpload'
import { Rooms } from './components/Rooms'

export const LandlordBusinessDetails: React.FC<{
  onOpenFullCalendar: (calendarGoogleId: string) => void
}> = ({ onOpenFullCalendar }) => {
  const { authUser, updateUser } = useAuthData()

  const previewModal = useSimpleModal()
  const { showMessage } = useMessageModalData()

  const {
    path,
    params: { listingId },
  } = useRouteMatch<{ listingId: string }>()

  const { isLoading, venue, updateVenue, setVenue } = useVenueById(listingId)

  const { t } = useTranslation()
  const handleBusinessPublish = async () => {
    await updateVenue({ statusChange: true })

    showMessage({
      title: t(
        'business.publishMessage.title',
        'Your business listing has been submitted for review.'
      ),
      text: t(
        'business.publishMessage.text',
        'The BLACE team is currently reviewing your profile. We will reach out to confirm activation of your listing upon approval. Thank you!'
      ),
      type: 'warning',
    })
  }

  const contactData: BusinessContactData = venue?.contact || {
    name: '',
    phone: '',
    email: '',
    address: EmptyGooglePlace,
    website: '',
    // facebook: '',
    instagram: '',
  }

  const onContactSave = async (info: BusinessContactData) => {
    const updateData: DeepPartial<ServerVenue> & { placeId?: string } = {
      primary_contact: {
        email: info.email,
        first_name: info.name,
        phone_number: info.phone,
      },
      website: info.website,
      placeId: info.address.place_id,
      location_address: { formatted_address: info.address.description },
      params: {
        instagram: info.instagram,
      },
    }

    await updateVenue(updateData)
  }

  const onGeneralInfoSave = async (
    info: BusinessGeneralInfo | { name: string } | BusinessDetailsInfo
  ) => {
    // @ts-ignore
    await updateVenue(info)
  }

  const onUpdatePhotos = async (images: AssetRefDownload[]) => {
    const venueId =
      venue?.id.toString() || authUser?.listing_id?.toString() || ''
    if (!venueId) return
    try {
      // TODO use update venue
      const response = await updateBusinessImages(
        venueId,
        images.map((image, index) => ({
          id: image.metadata.imageId,
          order: index + 1,
        }))
      )

      setVenue(venuesMapper(response))

      if (authUser?.venues && response.images) {
        const venues = authUser?.venues.map((venue) => {
          if (venue.id === authUser?.listing_id) {
            return { ...venue, images: response.images }
          }
          return venue
        })
        updateUser({ venues })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const pathname = generatePath(path, {
    listingId: authUser?.listing_id || +listingId,
  })

  return (
    <>
      <Box display="flex" mb={4} width={1}>
        <SideMenu
          businessType={UserRoles.Landlord}
          businessStatus={venue?.status}
          handleBusinessPublish={handleBusinessPublish}
          previewModal={previewModal}
          items={getLandlordBusinessDetailsMenu(listingId)}
        />
        <Box flex="1" display="grid" gridTemplateColumns={'2fr 0'}>
          <Switch>
            <Route exact path={pathname}>
              <PhotoUpload
                onSave={onGeneralInfoSave}
                businessName={venue?.name || ''}
                businessType={UserRoles.Landlord}
                onUpdatePhotos={onUpdatePhotos}
                images={venue?.images}
              />
            </Route>
            <Route path={`${pathname}/description`}>
              <GeneralInfo
                onSave={onGeneralInfoSave}
                attachLabelMethod={addLabelToVenues}
                entity={venue}
              />
            </Route>
            <Route path={`${pathname}/details`}>
              <Details
                onSave={onGeneralInfoSave}
                entity={venue}
                attachLabelMethod={addLabelToVenues}
              />
            </Route>
            <Route path={`${pathname}/rooms`}>
              {venue?.id && <Rooms venueId={venue.id} />}
            </Route>
            <Route path={`${pathname}/line-items`}>
              <LineItemsAndPricing
                businessDetails={venue}
                onPriceSave={updateVenue}
                orderPrice={venue?.pricing || ''}
                pricingDetails={venue?.paymentStructure?.pricingDetails}
                shortPricingDescription={{
                  value: venue?.shortPricingText || '',
                  label: venue?.shortPricingTextLabel || '',
                }}
                displayPricing={venue?.displayPricing}
              />
            </Route>
            <Route path={`${pathname}/contacts`}>
              <BusinessContact onSave={onContactSave} data={contactData} />
            </Route>
          </Switch>
          {/*{!!venue?.calendar?.google_id && <Calendar onOpenFullCalendar={onOpenFullCalendar} calendarGoogleId={venue?.calendar?.google_id} />}*/}
        </Box>
      </Box>

      {venue && (
        <BottomModal
          open={previewModal.isOpen}
          onClose={previewModal.toggleModal}
        >
          <Box display="block" pb="32px">
            <Venue venueId={venue?.id.toString()} showBottomBar={false} />
          </Box>
        </BottomModal>
      )}
      {isLoading && <Loader position="fixed" />}
    </>
  )
}
