import { css } from '@emotion/react'
import { styled } from '@material-ui/core'
import { excludeProps } from 'utils/styles/styled'

import { SimpleButton } from './SimpleButton'

type FilterButtonProps = {
  active?: boolean
}

export const FilterButton = styled(SimpleButton, {
  shouldForwardProp: excludeProps<FilterButtonProps>(['active']),
})(
  ({ active }: FilterButtonProps) => css`
    color: #000;

    border: ${active ? '2px solid #000' : '1px solid #e3e3e3'};
    border-radius: 4px;

    width: 100%;

    text-align: left;
    justify-content: flex-start;
  `
)
