import { Box } from '@material-ui/core'
import { Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath, useParams } from 'react-router-dom'

import { BreadcrumbsProps } from '../../../../components/ui/Breadcrumbs'
import { DashboardTitle } from '../../../../components/ui/PageTitle'
import { useCurrentBusinessListingId } from '../../../../hooks/useCurrentBusinessListingId'
import { useEventList } from '../../../../hooks/useEventList'
import { useLatestInquiryFlowElements } from '../../../../hooks/useLatestInquiryFlowElements'
import { usePossibleInvoiceGeneration } from '../../../../hooks/usePossibleInvoiceGeneration'
import { InquiryInvoiceResponse } from '../../../../types/dtos/inquiry'
import { getAllLineItemsFromGroups } from '../../../../utils/helpers/lineItems'
import { LineItemBundleMapper } from '../../../../utils/mappers/inquiries'
import { dashboardBookingDetailsPath } from '../../../../utils/paths'
import { useInquiryDetailsData } from '../../../../utils/providers/InqueryDetailsProvider'
import { GeneralEventInfo } from '../../BookingDetails/components/GeneralEventInfo'
import { BookingInfoWrapper } from '../DocumentForms.styles'
import { generateInvoiceValidationSchema } from '../formValidations'

import {
  GenerateInvoiceFormValues,
  GenerateInvoicesForm,
} from './GenerateInvoicesForm'

export const GenerateDefaultInvoices: React.FC<{
  editableInvoicesBundleId?: number
}> = ({ editableInvoicesBundleId }) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const { t } = useTranslation()
  const { eventsList } = useEventList()
  const { loading, inquiryDetails } = useInquiryDetailsData()
  const { businessId } = useCurrentBusinessListingId()
  const { latestInvoicesBundle } = useLatestInquiryFlowElements(inquiryDetails)
  const isPossibleInvoicesGeneration =
    usePossibleInvoiceGeneration(inquiryDetails)

  const [activeStep, setActiveStep] = useState(0)
  const [invoicesBundleDataFrom, setInvoicesBundleDataFrom] =
    useState<InquiryInvoiceResponse>()

  const breadcrumbs: BreadcrumbsProps = {
    steps: [
      {
        label: 'Booking details',
        route: generatePath(dashboardBookingDetailsPath, {
          listingId: businessId,
          id,
        }),
      },
    ],
    current: t('business.invoices.generateInvoice', 'Generate Invoice'),
  }

  useEffect(() => {
    if (loading) return

    if (!isPossibleInvoicesGeneration) {
      history.replace(
        generatePath(dashboardBookingDetailsPath, {
          id: inquiryDetails?.id,
          listingId: businessId,
        })
      )
    }
  }, [loading, isPossibleInvoicesGeneration])

  const vendorInquiryEventVenue = useMemo(() => {
    if (inquiryDetails?.venue_id) return

    return eventsList?.find((event) => event.id === inquiryDetails?.event_id)
      ?.venues?.[0]
  }, [eventsList, inquiryDetails?.event_id])

  const editableInvoicesBundle = useMemo(() => {
    if (!editableInvoicesBundleId || !inquiryDetails?.invoice_bundles?.length)
      return

    return inquiryDetails?.invoice_bundles?.find(
      (bundle) => bundle.id === editableInvoicesBundleId
    )
  }, [editableInvoicesBundleId, inquiryDetails?.invoice_bundles])

  useEffect(() => {
    if (loading) return

    setInvoicesBundleDataFrom(editableInvoicesBundle || latestInvoicesBundle)
  }, [loading, latestInvoicesBundle, editableInvoicesBundle])

  const lineItems = useMemo(() => {
    if (!invoicesBundleDataFrom?.grouped_line_items_bundle?.length) return

    return getAllLineItemsFromGroups(
      invoicesBundleDataFrom?.grouped_line_items_bundle
    )
  }, [invoicesBundleDataFrom?.grouped_line_items_bundle])

  const initialPaymentPercent = useMemo(() => {
    if (!invoicesBundleDataFrom?.invoices?.length) return

    const initialPaymentInvoice =
      invoicesBundleDataFrom?.invoices?.length > 1
        ? invoicesBundleDataFrom?.invoices?.find(
            (invoice) => invoice.type === 'initial'
          )
        : invoicesBundleDataFrom?.invoices.find(
            (invoice) => invoice.type === 'final'
          )
    if (!initialPaymentInvoice) return

    const initialPaymentPercent = initialPaymentInvoice.price_modifier
    return initialPaymentPercent < 1
      ? +(initialPaymentInvoice.price_modifier * 100).toFixed(2)
      : 100
  }, [invoicesBundleDataFrom?.invoices?.length])

  const initialValues: GenerateInvoiceFormValues = useMemo(() => {
    return {
      message: invoicesBundleDataFrom?.message || '',
      extra: invoicesBundleDataFrom?.extra || '',
      initialPaymentPercent: initialPaymentPercent || '',
      lineItems: lineItems?.length ? lineItems.map(LineItemBundleMapper) : [],
    }
  }, [invoicesBundleDataFrom, lineItems?.length])

  return (
    <>
      <Box mr={6} ml={6} width={1} minHeight={1000}>
        <DashboardTitle breadcrumbs={breadcrumbs} />
        <Box
          display="grid"
          gridTemplateColumns={activeStep !== 0 ? '2.65fr 1fr' : 'auto'}
        >
          <BookingInfoWrapper
            sx={{ marginRight: activeStep !== 0 ? '20px' : 0 }}
          >
            <Formik
              validationSchema={generateInvoiceValidationSchema()}
              initialValues={initialValues}
              onSubmit={() => {}}
              enableReinitialize
            >
              <GenerateInvoicesForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                parentId={editableInvoicesBundleId}
              />
            </Formik>
          </BookingInfoWrapper>
          {activeStep !== 0 && (
            <GeneralEventInfo
              inquiryDetails={inquiryDetails}
              vendorInquiryEventVenue={vendorInquiryEventVenue}
              withMessage
            />
          )}
        </Box>
      </Box>
    </>
  )
}

export default GenerateDefaultInvoices
