import { Box, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const VendorsCategoriesContainer = styled(Box)(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
`
)

export const VendorsCategoriesGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 194px;
  grid-gap: 15px;

  div {
    // background: red;
  }

  .first {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    display: flex;
    align-items: center;

    .MuiTypography-root {
      padding: 16px 24px;
      font-size: 32px;

      ${MEDIA_RULES.SMALL} {
        font-size: 24px;
        padding: 8px 16px;
      }
    }
  }

  ${MEDIA_RULES.SMALL} {
    display: block;
  }
`

export const CategoryTitle = styled(Box)`
  background: rgba(32, 32, 44, 0.7);
  width: 100%;
  position: absolute;
  top: 5%;
  left: 10%;

  ${MEDIA_RULES.SMALL} {
    left: 0;
    width: auto;
  }

  .MuiTypography-root {
    text-transform: capitalize;
    padding: 8px 16px;
  }
`

export const StyledCategoryBox = styled(Box)`
  width: 100%;
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;

  &:hover {
    opacity: 0.8;
  }
`
