import { Box, styled } from '@material-ui/core'
import * as React from 'react'

import { useWindowSize } from '../../hooks/useWindowSize'

export const BottomFixedActionBlock: React.FC<{
  height?: number
  choosenTab?: boolean
}> = ({ children, choosenTab, height }) => {
  const [offset, setOffset] = React.useState<number>(0)
  const { isMobile } = useWindowSize()
  //
  // React.useEffect(() => {
  //   if (isMobile) return
  //   window.onscroll = () => {
  //     handleOffset(window.pageYOffset)
  //   }
  // }, [isMobile, window.pageYOffset])
  //
  // const handleOffset = React.useCallback(
  //   throttle((offset) => {
  //     setOffset(offset)
  //   }, 200),
  //   []
  // )

  const StyledBox = styled(Box)`
    background: white;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    position: fixed;
    left: 0;
    right: 0;
    // bottom: ${!isMobile
      ? offset > 180 || (choosenTab && offset > 180)
        ? 'auto'
        : 0
      : 0};
    // top: ${!isMobile
      ? offset > 180 || (choosenTab && offset > 180)
        ? 0
        : 'auto'
      : 'auto'};
    // height: ${height || height === 0 ? height : 100}px;
    bottom: 0;
    height: ${height || height === 0 ? height : 100}px;
    z-index: 2;
  `

  return <StyledBox>{children}</StyledBox>
}
