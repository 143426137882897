// eslint-disable-next-line import/order
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Box, Link, Typography } from '@material-ui/core'
import React, { useState } from 'react'

import { useSimpleModal } from '../../hooks/useSimpleModal'
import { SimpleModal } from '../ui/Modals/SimpleModal'

const { REACT_APP_GOOGLE_CALENDAR_API_KEY: apiKey } = process.env

interface CalendarComponentProps {
  calendarGoogleId: string | string[]
  initialView?: string
}

export const FullCalendarComponent: React.FC<CalendarComponentProps> = ({
  calendarGoogleId,
  initialView = 'timeGridDay',
}) => {
  const calendarIds = Array.isArray(calendarGoogleId)
    ? calendarGoogleId
    : [calendarGoogleId]
  const color = '#4e4bec'
  const calendars = calendarIds.map((googleCalendarId) => ({
    googleCalendarId,
    textColor: 'white',
  }))
  const calendarRef = React.createRef<FullCalendar>()
  const { toggleModal, isOpen } = useSimpleModal()
  const [eventInfo, setEventInfo] = useState<any>({})

  React.useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi()
    calendarApi?.changeView(initialView)
  }, [initialView])

  const onEventClick = (info: any) => {
    info.jsEvent.preventDefault()
    info.jsEvent.stopPropagation()
    toggleModal()
    setEventInfo(info.event._def)
  }

  return (
    <>
      <FullCalendar
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next',
        }}
        eventClick={onEventClick}
        plugins={[
          dayGridPlugin,
          googleCalendarPlugin,
          listPlugin,
          timeGridPlugin,
        ]}
        initialView={initialView}
        ref={calendarRef}
        timeZone="America/New_York"
        contentHeight="auto"
        slotDuration="01:00:00"
        slotMinTime="07:00:00"
        dayHeaderFormat={{ weekday: 'short', omitCommas: true }}
        slotLabelFormat={[{ timeStyle: 'short' }]}
        googleCalendarApiKey={apiKey}
        eventSources={calendars}
      />
      <SimpleModal
        minWidth={230}
        maxWidth="inherit"
        open={isOpen}
        onClose={toggleModal}
      >
        <Box height={200}>
          <Typography variant="h4">{eventInfo.title}</Typography>
          {eventInfo.extendedProps && (
            <Box>
              <Box
                mb={6}
                dangerouslySetInnerHTML={{
                  __html: eventInfo.extendedProps.description,
                }}
              />
              <Link href={eventInfo.url}>Copy to Calendar</Link>
            </Box>
          )}
        </Box>
      </SimpleModal>
    </>
  )
}
