// https://github.com/stackworx/formik-material-ui/blob/master/packages/formik-material-ui/src/TextField.tsx
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField'
import { FieldProps, getIn } from 'formik'
import * as React from 'react'

export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {}

export function fieldToTextField({
  disabled,
  field,
  form: { isSubmitting, touched, errors },
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && Boolean(fieldError)

  return {
    ...props,
    ...field,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: disabled ?? isSubmitting,
    variant: props.variant,
  }
}

export const TextField: React.FC<TextFieldProps> = ({ children, ...props }) => {
  return <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>
}
