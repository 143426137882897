import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from '@material-ui/core'
import { FastField, FieldProps } from 'formik'
import * as React from 'react'

const ControlLabelWrapper = styled(FormControlLabel)`
  .MuiRadio-root {
    padding: 0;
    margin: 9px;
  }
  &:hover {
    opacity: 0.8;
  }
`

export const RadioButtonsSwitcher: React.FC<{
  name: string
  customNoLabel?: string
}> = ({ name, customNoLabel }) => {
  const onChange = (e: any, setFieldValue: any) => {
    if (e.target.value === 'true') {
      setFieldValue(name, true)
      setFieldValue('eventDates', null)
      setFieldValue('flexibleDates', null)
    } else {
      setFieldValue(name, false)
    }
  }
  return (
    <FastField name={name}>
      {({ field, form: { setFieldValue } }: FieldProps) => {
        return (
          <RadioGroup
            aria-label="dates"
            name="isMultipleDates"
            value={field.value}
            onChange={(e) => onChange(e, setFieldValue)}
          >
            <Box display="flex">
              <ControlLabelWrapper
                value={true}
                control={<Radio color="primary" />}
                componentsProps={{
                  typography: {
                    color: !!field.value ? 'primary.main' : undefined,
                  },
                }}
                label="Yes"
              />
              <ControlLabelWrapper
                value={false}
                control={<Radio color="primary" />}
                componentsProps={{
                  typography: {
                    color: !field.value ? 'primary.main' : undefined,
                  },
                }}
                label={customNoLabel || 'No'}
              />
            </Box>
          </RadioGroup>
        )
      }}
    </FastField>
  )
}
