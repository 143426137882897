import { Box, BoxProps, capitalize, Chip, styled } from '@material-ui/core'
import { ClearSharp } from '@material-ui/icons'
import * as React from 'react'

import { poppinsFontFamily } from '../../theme/createTypography'

export const StyledChip = styled(Chip)<{ selected?: boolean }>(
  (props) => `
  border-radius: 2px;
  margin: 0 5px;
  min-height: 36px;
  
  background-color: ${props.selected ? '#20202C' : '#F7F7F8'};
  .MuiChip-root {
   background-color: ${props.selected ? '#20202C' : '#F7F7F8'};
  }
 
  .MuiChip-deleteIcon {
    color: black;
  }
  .MuiChip-label {
    font-family: ${poppinsFontFamily};
    color: ${props.selected ? '#FFFFFF' : '#20202C'};
  }

  &:hover {
    background-color: ${props.selected ? 'rgba(32, 32, 44, 0.8)' : '#ceced4'};
  }`
)

export const ChipContainer = styled(Box)<{ size?: string }>(
  (props) => `
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${
    props.size && props.size === 'large' ? '220px' : '120px'
  }, 1fr));
  grid-auto-flow: dense;
  grid-gap: 10px;
  margin: 10px 0;
  `
)

interface SearchValuesChipsProps {
  searchValues: string[]
  onDeleteSearchValue: (event: any) => void
  previewMode?: boolean
  onClick?: (value: string) => void
  selected?: string
  size?: string
}

export const SearchValuesChips: React.FC<SearchValuesChipsProps & BoxProps> = ({
  searchValues,
  onDeleteSearchValue,
  previewMode,
  size,
  onClick,
  selected,
  ...boxProps
}) => {
  const handleClick = (value: string) => {
    if (onClick) {
      onClick(value)
    }
  }

  return (
    <ChipContainer size={size} mt={6} {...boxProps}>
      {searchValues && searchValues.length
        ? searchValues.map((value, index) => {
            return value ? (
              <StyledChip
                style={{
                  fontWeight: size === 'large' ? 600 : 'normal',
                  fontSize: size === 'large' ? '18px' : '16px',
                  height: size === 'large' ? '47px' : '36px',
                }}
                selected={selected === value}
                onClick={() => handleClick(value)}
                key={index}
                deleteIcon={!previewMode ? <ClearSharp /> : undefined}
                onDelete={
                  !previewMode ? () => onDeleteSearchValue(value) : undefined
                }
                label={capitalize(value)}
              />
            ) : null
          })
        : null}
    </ChipContainer>
  )
}
