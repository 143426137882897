import {
  Accordion,
  Box,
  IconButton,
  styled,
  TableRow,
  Typography,
} from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

import LinkText from '../../../components/ui/Buttons/LinkText/LinkText'
import { SimpleButton } from '../../../components/ui/Buttons/SimpleButton'

export const StyledAccordion = styled(Accordion)`
  background: white;
  box-shadow: none;

  &:before {
    content: none;
  }
`

export const ServiceBookingWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);

  ${MEDIA_RULES.SMALL} {
    position: fixed;
  }
`

export const StyledBlock = styled(Box)`
  width: 100%;

  ${MEDIA_RULES.SMALL} {
    .MuiAccordionDetails-root,
    .MuiAccordionSummary-root {
      padding: 0;
    }
  }
`

export const ServiceDetailsContainer = styled(Box)<{ isMobile: boolean }>(
  (props) => `
   height: ${props.isMobile ? 'calc(100vh - 212px)' : 'calc(100vh - 180px)'};
   max-height: ${props.isMobile ? '-webkit-fill-available' : '100%'};
   padding: ${props.isMobile ? '12px 24px 24px' : '12px 20px 24px'};
   overflow-y: auto;
`
)

export const StyledLinkText = styled(LinkText)`
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const StyledRequestChangesButton = styled(SimpleButton)`
  font-size: 16px;
  color: #ff9500;
  padding: 5px;
  background-color: rgba(255, 149, 0, 0.07);
  &:hover {
    background-color: rgba(255, 149, 0, 0.12);
  }
`

export const StyledAcceptButton = styled(SimpleButton)`
  font-size: 16px;
  margin-left: 20px;
  color: #2f54eb;
  padding: 5px;
  background-color: rgba(47, 84, 235, 0.07);
  &:hover {
    background-color: rgba(47, 84, 235, 0.12);
  }
`

export const StyledRequestCancelButton = styled(SimpleButton)(
  ({ theme }) => `
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  background-color: rgba(147, 150, 163, 0.07);
  color: ${theme.palette.common.black};
  &:hover {
    opacity: 0.8;
  }
`
)

export const StyledRequestSendButton = styled(SimpleButton)(
  ({ theme }) => `
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  background-color: rgba(47, 84, 235, 0.07);
  color: ${theme.palette.primary.light};
  margin-left: 13px;
  &:hover {
    opacity: 0.8;
  }
`
)

export const DetailsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const StyledIconButton = styled(IconButton)`
  background-color: #f7f7f8;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;

  &:hover {
    opacity: 0.95;
  }
`

export const StyledDocumentsIconButton = styled(Box)`
  background-color: #ffffff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    background-color: #ffffff;
  }
`

export const InfoIconWrapper = styled(Box)`
  background-color: rgba(224, 78, 47, 0.07);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  &:hover {
    opacity: 0.95;
  }
`

export const Tab = styled(Box)`
  padding: 10px 25px 20px 25px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  cursor: pointer;
  color: #9396a3;
  margin-bottom: -15px;

  &:hover {
    color: #2f54eb;
    opacity: 0.8;
  }

  &.active {
    color: #2f54eb;
    border-bottom: 1px solid #2f54eb;
  }
`

export const TabsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
`

export const TabsTypography = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  color: #9396a3;
  font-family: Poppins;
`

export const StyledInfoBox = styled(Box)`
  padding: 20px 26px;
  margin-top: 15px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  width: 100%;
  align-items: center;
  min-height: 200px;
`
export const DocumentsStyledHeaderRow = styled(TableRow)`
  background-color: #f7f7f8;
  th {
    padding: 10px 15px;
    font-size: 12px;
    font-family: Poppins;
  }
`

export const StyledTableRow = styled(TableRow)(
  ({ theme }) => `
  border: 1px solid rgba(226, 226, 226, 1);
    background-color: ${theme.palette.common.white};
    .MuiTableCell-root {
        border-bottom: none;
        padding: 10px 15px;
    }
`
)

export const ScrollContainer = styled(Box)`
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`

export const MobileBookingDetailsTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`
