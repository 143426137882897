import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from 'react-i18next'

import GradientButton from '../../../../../components/ui/Buttons/GradientButton'

interface InquiryFlowTableWarningProps {
  isGenerateBtnDisabled: boolean
  onGenerate: () => void
  type: 'invoice' | 'contract'
}

export const InquiryFlowTableWarning: React.FC<
  InquiryFlowTableWarningProps
> = ({ isGenerateBtnDisabled, onGenerate, type }) => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 20px',
        background: '#FAFAFA',
        border: '1px dashed #D9D9D9',
      }}
    >
      <Box>
        <Trans key="business.inquiry.table.empty">
          <Typography fontWeight={500} variant="body2" color="#9396A3">
            Here will be table with inquiry {{ type }}
          </Typography>
        </Trans>
        <Trans key="business.inquiry.table.generateFirst">
          <Typography variant="body2" color="#9396A3">
            Generate your first {{ type }}
          </Typography>
        </Trans>
      </Box>
      <Box>
        <GradientButton
          disabled={isGenerateBtnDisabled}
          style={{ padding: '6px 16px', height: 'fit-content' }}
          onClick={(e) => {
            e.stopPropagation()
            onGenerate()
          }}
        >
          <Trans key="business.inquiry.table.generate">
            <Typography variant="body1" fontWeight={500}>
              Generate {{ type }}
            </Typography>
          </Trans>
        </GradientButton>
      </Box>
    </Box>
  )
}
