import { Box, Divider, Typography } from '@material-ui/core'
import Send from 'components/svg/Send'
import { debounce } from 'lodash'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAuthData } from 'utils/providers/AuthProvider'

import 'react-quill/dist/quill.snow.css'

import { useWindowSize } from '../../hooks/useWindowSize'
import {
  DraftMessage,
  MessageAttachment,
  MessageItem,
  MessagesThreadData,
  MessageTemplateData,
  MessageTemplateItem,
} from '../../types/dtos/messages'
import { getShortDateRange } from '../../utils/helpers/date'
import { getRequestError } from '../../utils/helpers/validations'
import { useMessageModalData } from '../../utils/providers/MessageModalProvider'
import {
  LsGetDraftChatMessages,
  LsUpdateDraftChatMessages,
} from '../../utils/storage'
import Refresh from '../svg/Refresh'
import GradientButton from '../ui/Buttons/GradientButton'
import { RichInputFieldContainer } from '../ui/FormComponents/RichInputField/RichInputFieldContainer'
import { getDateAMPMParams } from '../ui/Inputs/TimePicker'
import { Loader } from '../ui/Loader'

import {
  StyledChatBox,
  StyledEmptyBox,
  StyledIconButton,
  StyledMessageBox,
} from './ChatBox.styles'
import { AttachmentRow } from './components/AttachmentRow'
import { TemplatesRow } from './components/TemplatesRow'
import { WarningForGuests } from './components/WarningForGuests'

interface ChatBoxProps {
  contextId: number
  isModal: boolean
  messages: MessageItem[]
  templates?: MessageTemplateItem[]
  onRefreshThread: () => void
  onSendMessage: (message: MessagesThreadData) => Promise<void>
  onSaveTemplate: (template: MessageTemplateData) => Promise<void>
  unread: number
  onFileDownload: (fileId: string) => void
}

export const ChatBox: React.FC<ChatBoxProps> = ({
  contextId,
  messages,
  templates,
  onRefreshThread,
  onSendMessage,
  onSaveTemplate,
  unread,
  isModal,
  onFileDownload,
}) => {
  const [message, setMessage] = useState<string | null>(null)
  const [attachment, setAttachment] = useState<MessageAttachment | File | null>(
    null
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [templateName, setTemplateName] = useState<string>('')
  const messagesEndRef = useRef<HTMLDivElement | null>(null)
  const { authUser, isLoading: authLoading } = useAuthData()
  const isBusinessUser = !authLoading && !!authUser?.role
  const { isMobile } = useWindowSize()
  const draftMessages = LsGetDraftChatMessages()?.messages
  const { showMessage } = useMessageModalData()
  const { t } = useTranslation()

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollTo({
      top: messagesEndRef?.current?.scrollHeight,
      behavior: 'smooth',
    })
  }

  const sendMessageByEnterClick = (event: any, callback: any) => {
    if (event.key === 'Enter' && event.shiftKey) {
      callback()
      event.preventDefault()
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const updateDraftMessages = debounce(
    (updatedMessages) => LsUpdateDraftChatMessages(updatedMessages),
    1000
  )

  useEffect(() => {
    if (!contextId || (!draftMessages?.length && !message)) return

    const updatedDraftMessages = [
      ...(draftMessages?.filter((message) => message.contextId !== contextId) ||
        []),
      {
        contextId,
        message,
      },
    ] as DraftMessage[]
    updateDraftMessages(updatedDraftMessages)
  }, [contextId, message, draftMessages?.length])

  useEffect(() => {
    if (!contextId || !draftMessages?.length) return
    const inquiryDraft = draftMessages?.find(
      (message) => message.contextId === contextId
    )
    setMessage(inquiryDraft?.message || message || null)
  }, [contextId, draftMessages?.length])

  const renderMessage = (item: MessageItem, i: number) => {
    const showNewDivider =
      i == messages.length - unread - 1 && i != messages.length - 1
    const file = item?.files?.[0] || null
    const image = item?.images?.[0] || undefined

    const attachment = {
      name: image?.filename || file?.filename,
      position: item.position,
      type: file ? 'file' : 'image',
      id: image?.id || file?.id,
      url: image?.path || file?.s3_path,
      image,
      text: item.text,
    } as MessageAttachment

    return (
      <>
        <StyledMessageBox
          withoutAttachments={!image && !file}
          position={item.position}
          key={i}
        >
          {!!item.text && !image && !file && (
            <Typography
              dangerouslySetInnerHTML={{ __html: item.text }}
            ></Typography>
          )}
          {(image || file) && (
            <AttachmentRow
              onFileDownload={file ? onFileDownload : undefined}
              attachment={attachment}
            />
          )}
          <Box className="date-row">
            <Typography
              variant="body2"
              fontSize={12}
              color={(theme) => theme.palette.grey[200]}
            >
              {getShortDateRange(item.date)}
            </Typography>
            <Typography
              variant="body2"
              fontSize={12}
              color={(theme) => theme.palette.grey[200]}
            >
              {`${getDateAMPMParams(new Date(item.date)).hours}:${
                getDateAMPMParams(new Date(item.date)).minutes
              } ${getDateAMPMParams(new Date(item.date)).ampm}`}
            </Typography>
          </Box>
        </StyledMessageBox>
        {showNewDivider && (
          <Divider style={{ color: '#9396A3', marginBottom: 10 }}>New</Divider>
        )}
      </>
    )
  }

  const sendMessage = async () => {
    setIsLoading(true)

    try {
      await onSendMessage({ message, attachment })
      setMessage(null)
      setAttachment(null)
      setTemplateName('')
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (!authUser) {
    return <WarningForGuests />
  }

  return (
    <StyledChatBox onClick={(e) => e.stopPropagation()}>
      {isLoading && <Loader position="absolute" />}
      {messages.length ? (
        <Box
          ref={messagesEndRef}
          height={isModal ? '100%' : '300px'}
          margin="20px 24px"
          sx={{ overflowY: 'auto' }}
        >
          {messages.map((item, i) => renderMessage(item, i))}
        </Box>
      ) : (
        <Box
          px="24px"
          height={isModal ? '100%' : '300px'}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <StyledEmptyBox>
            <Trans i18nKey="chat.noMessages">
              <Typography
                variant="body2"
                color="#9396A3"
                sx={{ marginBottom: '10px' }}
              >
                No messages here yet...
              </Typography>
              <Typography variant="body2" color="#9396A3">
                Send the first message by texting below.
              </Typography>
            </Trans>
          </StyledEmptyBox>
        </Box>
      )}
      <Box>
        {attachment && (
          <AttachmentRow
            isNotSend
            attachment={attachment}
            setAttachment={setAttachment}
          />
        )}
        <Box
          px="24px"
          pt="19px"
          pb="38px"
          sx={{
            position: 'relative',
            boxShadow: '0px 4px 18px rgba(210, 210, 210, 0.4)',
          }}
        >
          <Box
            display="flex"
            justifyContent={isBusinessUser ? 'space-between' : 'flex-end'}
            alignItems="center"
            mb={isMobile ? 0 : '16px'}
          >
            {isBusinessUser && (
              <TemplatesRow
                setIsLoading={setIsLoading}
                templates={templates}
                templateName={templateName}
                setTemplateName={setTemplateName}
                onSaveTemplate={onSaveTemplate}
                message={message}
                setMessage={setMessage}
                attachment={attachment}
                setAttachment={setAttachment}
              />
            )}
            <StyledIconButton onClick={onRefreshThread}>
              {<Refresh />}
            </StyledIconButton>
          </Box>
          <RichInputFieldContainer
            value={message}
            maxLength={500}
            setValue={setMessage}
            attachment={attachment}
            setAttachment={setAttachment}
          />
          <Box display="flex" justifyContent="flex-end" mt="16px">
            <GradientButton
              style={{ height: '44px', width: '134px' }}
              disabled={messages.length ? !(message || attachment) : !message}
              onClick={sendMessage}
              startIcon={<Send fill="#FFFFFF" />}
            >
              {t('chat.send', 'Send')}
            </GradientButton>
          </Box>
        </Box>
      </Box>
    </StyledChatBox>
  )
}
