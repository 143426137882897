import { Box, Typography } from '@material-ui/core'
import * as React from 'react'

import { Image } from '../../../../components/ui/Image'
import { getPartnerImageBySlug } from '../../../../constants/partners'
import { ImageItem, ImageType } from '../../../../types/general'

import { ListingRowBox, ListingRowContent } from './ListingPicker.styles'

interface ListingRowProps {
  isVendor: boolean
  isActive: boolean
  name: string
  partners: string[]
  image: ImageItem
  imageSize: ImageType['size']
  staticImageType: ImageType['static']
  vendorCategory?: string
  handleClick: () => void
}

export const ListingRow: React.FC<ListingRowProps> = ({
  isVendor,
  partners,
  isActive,
  name,
  image,
  imageSize,
  staticImageType,
  vendorCategory,
  handleClick,
}) => {
  return (
    <ListingRowBox onClick={handleClick} className={isActive ? 'active' : ''}>
      <Box sx={{ position: 'relative' }}>
        <Image
          image={image}
          imageSize={imageSize}
          staticImageType={staticImageType}
          vendorCategory={vendorCategory}
          style={{ width: 72, height: 72 }}
        />
        {isVendor && (
          <Box sx={{ position: 'absolute', right: '-30%', bottom: '-12px' }}>
            <Box display="flex">
              {partners.map((partner, i) => (
                <img
                  key={partner}
                  src={getPartnerImageBySlug(partner)}
                  style={{
                    width: '50px',
                    height: '50px',
                    marginLeft: i !== 0 ? '-20px' : 0,
                  }}
                  alt={partner}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <ListingRowContent>
        <Typography variant="h4" fontWeight={500} fontFamily="Poppins">
          {name}
        </Typography>
      </ListingRowContent>
    </ListingRowBox>
  )
}
