import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { PromotionItem } from '../../../../components/cards/PromotionItem'
import { NavLink } from '../../../../components/NavLink'
import { PageTitle } from '../../../../components/ui/PageTitle'
import { Slide } from '../../../../components/ui/SwipeSlider/SwipeSlider'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { Vendor } from '../../../../types/Vendor'
import { absAdvantagePath } from '../../../../utils/paths'
import VendorsSlider from '../../../vendors/components/VendorsSlider/VendorsSlider'

import { StyledPromotionsVendorsBlock } from './PromotionsBlock.styles'

export const PromotionsBlock: React.FC<{ vendors: Vendor[] }> = ({
  vendors,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  const renderVendorItem = (item: Slide) => {
    const vendor = vendors.find((vendor: Vendor) => vendor.id === item.id)
    if (!vendor) return <></>

    return <PromotionItem listing={vendor} listingType="vendor" />
  }

  return (
    <StyledPromotionsVendorsBlock className="section-block" position="relative">
      <Box className="container" position="relative">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <PageTitle
            fontSize={isMobile ? 24 : 47}
            title={t('partners.abs.advantage.title', 'ABS Advantage')}
            captionText={t(
              'partners.abs.advantage.subtitle',
              'The ABS Advantage is a collection of exclusive discounts offered to ABS tenants from premier vendor partners'
            )}
          />
          <Box
            sx={{
              position: isMobile ? 'absolute' : 'static',
              bottom: 0,
              right: '24px',
              zIndex: 2,
            }}
          >
            <NavLink to={absAdvantagePath}>
              <Typography
                variant="body1"
                fontWeight={700}
                color={(theme) => theme.palette.primary.main}
                sx={{ textTransform: 'uppercase' }}
              >
                {t('partners.abs.advantage.all', 'See all advantages')}
              </Typography>
            </NavLink>
          </Box>
        </Box>
        <VendorsSlider
          ml={isMobile ? 0 : '-18px'}
          mr={isMobile ? 0 : '-18px'}
          px={isMobile ? 0 : '18px'}
          py={isMobile ? '37px' : '47px'}
          data={vendors}
          withPagination
          slidesPerView={1}
          renderSlide={(item) => renderVendorItem(item)}
          prevNavButtonClass="advanced-prev"
          nextNavButtonClass="advanced-next"
          navigationPosition="center"
        />
      </Box>
    </StyledPromotionsVendorsBlock>
  )
}
