import neighborhoodImg from '../../assets/images/neighborhood.jpg'
import neighborhoodImgWebp from '../../assets/images/neighborhood.webp'
import noMapImg from '../../assets/images/nomap.jpg'
import noMapImgWebp from '../../assets/images/nomap.webp'
import onboardingImg from '../../assets/images/onboarding.jpg'
import onboardingImgWebp from '../../assets/images/onboarding.webp'
import venueImg from '../../assets/images/test-venue-item.jpg'
import venueImgWebp from '../../assets/images/test-venue-item.webp'
import galleryImg from '../../assets/images/vendor-media-2.jpg'
import galleryImgWebp from '../../assets/images/vendor-media-2.webp'
import galleryImgTwo from '../../assets/images/vendor-media-3.jpg'
import galleryImgTwoWebp from '../../assets/images/vendor-media-3.webp'
import { MessageImageItem } from '../../types/dtos/messages'
import { ImageItem, ImageType } from '../../types/general'

import { getCategoryImageBySlug } from './vendors'

export const getImageByType = (
  image: ImageItem | MessageImageItem | string | undefined,
  type: string,
  staticImageType: ImageType['static'] | undefined,
  vendorCategory: string | undefined,
  isAbs: boolean
): ImageItem | string => {
  if (typeof image === 'string')
    return type.includes('webp')
      ? ''
      : image || getStaticImage(staticImageType, 'jpg', vendorCategory, isAbs)
  else if (image?.thumbs_list && 'message-small' in image.thumbs_list)
    return type.includes('webp')
      ? ''
      : // @ts-ignore
        image?.thumbs_list?.[`message-${type}`] || image?.path
  else {
    if (type.includes('original')) {
      return type.includes('webp')
        ? // @ts-ignore
          image?.thumbs_list?.webp ||
            (!image?.path
              ? getStaticImage(staticImageType, 'webp', vendorCategory, isAbs)
              : '')
        : image?.path ||
            getStaticImage(staticImageType, 'jpg', vendorCategory, isAbs)
    } else {
      return (
        (type.includes('retina')
          ? getRetinaImage(image, type)
          : // @ts-ignore
            image?.thumbs_list?.[type]) ||
        (type.includes('webp')
          ? !image?.path
            ? getStaticImage(staticImageType, 'webp', vendorCategory, isAbs)
            : ''
          : image?.path ||
            getStaticImage(staticImageType, 'jpg', vendorCategory, isAbs))
      )
    }
  }
}

const getRetinaImage = (
  image: ImageItem | MessageImageItem | string | undefined,
  type: string
) => {
  return (
    // @ts-ignore
    image?.thumbs_list?.[type] ||
    // @ts-ignore
    image?.thumbs_list?.[type.replace('-retina', '')]
  )
}

const getStaticImage = (
  staticImageType: ImageType['static'] | undefined,
  imageType: 'webp' | 'jpg',
  vendorCategory: string | undefined,
  isAbs: boolean
): string => {
  switch (staticImageType) {
    case 'venue':
      return imageType === 'webp' ? venueImgWebp : venueImg
    case 'vendor':
      return imageType === 'webp'
        ? getCategoryImageBySlug(vendorCategory, isAbs)?.imageWebp
        : getCategoryImageBySlug(vendorCategory, isAbs)?.image
    case 'gallery':
      return imageType === 'webp' ? galleryImgWebp : galleryImg
    case 'galleryTwo':
      return imageType === 'webp' ? galleryImgTwoWebp : galleryImgTwo
    case 'onboarding':
      return imageType === 'webp' ? onboardingImgWebp : onboardingImg
    case 'map':
      return imageType === 'webp' ? noMapImgWebp : noMapImg
    case 'neighborhood':
      return imageType === 'webp' ? neighborhoodImgWebp : neighborhoodImg
    default:
      return ''
  }
}
