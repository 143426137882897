import { Box, styled, Typography } from '@material-ui/core'
import * as React from 'react'
import { useMemo, useState } from 'react'

import { EditButton } from '../../../../../components/ui/Buttons/EditButton'
import { Image } from '../../../../../components/ui/Image'
import { UserRoles } from '../../../../../constants/profile'
import {
  AssetRefDownload,
  getEmptyImageAsset,
} from '../../../../../types/FileUploadHandler'
import { ImageItem } from '../../../../../types/general'
import { useMediaModalData } from '../../../../../utils/providers/MediaLibraryProvider'
import { BlockContainer, BlockTitle } from '../../BusinessDetails.styles'

import { ServiceNameForm } from './ServiceNameForm'

const ImagePreviewContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  grid-auto-rows: 1fr;
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
  }

  .MuiBox-root {
    max-height: 130px;
  }

  .first {
    max-height: 275px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
`

export const PhotoUpload: React.FC<{
  businessType: UserRoles
  vendorType?: string
  onUpdatePhotos: (photos: AssetRefDownload[]) => void
  onSave: (data: { name: string }) => void
  images?: ImageItem[]
  businessName: string
}> = ({
  businessType,
  vendorType,
  onUpdatePhotos,
  onSave,
  images,
  businessName,
}) => {
  const { openMediaModal } = useMediaModalData()
  const [isEditMode, setEditMode] = useState(false)

  const onEditServiceName = () => {
    if (businessName) {
      setEditMode(true)
    }
  }

  const onSaveServiceName = (data: { name: string }) => {
    onSave(data)
    setEditMode(false)
  }

  const onSavePhotos = (photos: AssetRefDownload[]) => {
    onUpdatePhotos(photos)
  }

  const onEditPhotos = () => {
    openMediaModal({
      onSave: onSavePhotos,
      initialImages: images?.map(getEmptyImageAsset),
    })
  }

  const firstImages = useMemo(() => {
    if (images && images.length < 3) {
      return [...images, null, null].slice(0, 3)
    }
    return images?.slice(0, 3)
  }, [images])

  return (
    <BlockContainer display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between"
        width={1}
        alignItems="baseline"
        mb={3}
      >
        <Box display="flex" flexDirection="column" width={1}>
          {!isEditMode ? (
            <>
              <BlockTitle sx={{ marginBottom: '20px' }}>
                Business Name And Photo
              </BlockTitle>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={1}
              >
                <Typography
                  sx={{ marginBottom: '15px' }}
                  fontSize={14}
                  color="#9396A3"
                >
                  Service name
                </Typography>
                <Box>
                  <EditButton
                    sx={{ '&:hover': { opacity: 0.8 } }}
                    onClick={onEditServiceName}
                  />
                </Box>
              </Box>
              <Typography
                sx={{ marginBottom: '20px' }}
                fontWeight={500}
                fontSize={14}
                color="#252733"
              >
                {businessName}
              </Typography>
            </>
          ) : (
            <ServiceNameForm
              onSave={onSaveServiceName}
              name={businessName}
              onCancel={() => setEditMode(false)}
            />
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width={1}
          >
            <Typography fontSize={14} color="#9396A3">
              Service photo
            </Typography>
            <Box>
              <EditButton
                sx={{ '&:hover': { opacity: 0.8 } }}
                onClick={onEditPhotos}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" width={1}>
        {!!images?.length ? (
          <ImagePreviewContainer>
            {firstImages?.slice(0, 3).map((image, index) => (
              <Box
                key={index}
                display="flex"
                width={1}
                bgcolor="#FAFAFA"
                className={index === 0 ? 'first' : ''}
              >
                {image && (
                  <Image
                    image={image}
                    imageSize="medium"
                    staticImageType={
                      businessType === UserRoles.Vendor ? 'vendor' : 'venue'
                    }
                    vendorCategory={
                      businessType === UserRoles.Vendor ? vendorType : undefined
                    }
                  />
                )}
              </Box>
            ))}
          </ImagePreviewContainer>
        ) : (
          <Box
            display="flex"
            width={1}
            bgcolor="#FAFAFA"
            height={130}
            justifyContent="center"
            alignItems="center"
          >
            <Box
              display="flex"
              style={{
                padding: '10px 32px',
                border: '1.5px dashed #2F54EB',
                cursor: 'pointer',
                background: 'rgba(250, 250, 250, 0.5)',
              }}
              onClick={onEditPhotos}
            >
              <Typography color="#2F54EB">Add service photo</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </BlockContainer>
  )
}
