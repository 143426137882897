import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { getPartnerParams } from '../../../../../constants/partners'
import { simpleModalControl } from '../../../../../hooks/useSimpleModal'
import { EventVenue } from '../../../../../types/Event'
import { VenueItem } from '../../../../../types/Venue'
import { postPersonalInfo } from '../../../../../utils/api/profile'
import { useVenues } from '../../../../../utils/api/venues'
import { getRequestError } from '../../../../../utils/helpers/validations'
import {
  convertEventVenueToVenue,
  convertVenueToEventVenue,
} from '../../../../../utils/helpers/venue'
import { useAuthData } from '../../../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../../../utils/providers/EventsProvider'
import { useMessageModalData } from '../../../../../utils/providers/MessageModalProvider'
import { AbsInquiryCreateForm } from '../InquiryCreate/ABS/ABSInquiryCreate'

import { PartnersProperties } from './components/PartnersProperties'

const SelectAbsVenueModal: React.FC<{
  modalControl: simpleModalControl
  shouldUpdateDefaultAbsVenue?: boolean
  shouldOpenCreateEventForm?: boolean
  setIsCreateAbsEventFormOpen?: Dispatch<SetStateAction<boolean>>
  submitAfterContinue?: boolean
  setCurrentAbsVenue?: Dispatch<SetStateAction<EventVenue | null>>
}> = ({
  modalControl,
  shouldUpdateDefaultAbsVenue,
  shouldOpenCreateEventForm,
  setIsCreateAbsEventFormOpen,
  setCurrentAbsVenue,
}) => {
  const { isLoading, data } = useVenues({})
  const { toggleModal } = modalControl
  const venues = data?.data || []
  const { updateUserActiveEvent, getUserActiveEvent, chosenAbsVenue } =
    useStorageEventsData()
  const [currentVenue, setCurrentVenue] = React.useState<VenueItem | null>(null)
  const [shouldCreateEvent, setShouldCreateEvent] =
    React.useState<boolean>(false)
  const { t } = useTranslation()
  const { showMessage } = useMessageModalData()
  const activeEvent = getUserActiveEvent()
  const { updateUser } = useAuthData()

  React.useEffect(() => {
    if (!venues?.length) return

    if (shouldUpdateDefaultAbsVenue) {
      if (!chosenAbsVenue) setCurrentVenue(venues[0])
      else {
        const venue = convertEventVenueToVenue(chosenAbsVenue)
        setCurrentVenue(venue)
      }
    } else {
      if (!activeEvent?.venue) setCurrentVenue(venues[0])
      else {
        const venue = convertEventVenueToVenue(activeEvent?.venue)
        setCurrentVenue(venue)
      }
    }
  }, [venues?.length, activeEvent?.venue, chosenAbsVenue])

  const updateUserHandler = async (venue: EventVenue) => {
    try {
      const response: any = await postPersonalInfo({
        default_client_venue_id: venue?.id,
        ...getPartnerParams(),
      })
      updateUser(response.data)
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    } finally {
      console.log('personal info has been updated')
    }
  }

  const continueHandler = () => {
    if (!currentVenue)
      showMessage({
        type: 'warning',
        title: t(
          'partners.abs.bookings.noVenue',
          'Please choose venue to continue'
        ),
      })
    else {
      const eventVenue = convertVenueToEventVenue(currentVenue)
      if (shouldUpdateDefaultAbsVenue) {
        if (setCurrentAbsVenue) setCurrentAbsVenue(eventVenue)
        else updateUserHandler(eventVenue)
      } else updateUserActiveEvent({ venue: eventVenue })
      if (shouldOpenCreateEventForm) {
        localStorage.removeItem('inquiryFormValues')
        setShouldCreateEvent(true)
        setIsCreateAbsEventFormOpen && setIsCreateAbsEventFormOpen(true)
      } else toggleModal()
    }
  }

  return shouldCreateEvent ? (
    <AbsInquiryCreateForm onClose={toggleModal} venue={currentVenue} />
  ) : (
    <PartnersProperties
      currentVenue={currentVenue}
      updateCurrentVenue={setCurrentVenue}
      handleContinue={continueHandler}
      isLoading={isLoading}
      venues={venues}
    />
  )
}

export default SelectAbsVenueModal
