import { UserPaymentInfo } from '../../types/Profile'
import {
  connectSquareApiPath,
  connectTestSquareApiPath,
  createPaymentApiPath,
  getPaymentCheckoutApiPath,
} from '../paths'

import { get, post } from './api'

export async function createStripePayment(contract_id: number): Promise<any> {
  return post(createPaymentApiPath, { contract_id, payment_gateway: 'stripe' })
}

export async function getStripeCheckout(paymentId: number): Promise<any> {
  return get(getPaymentCheckoutApiPath(paymentId.toString()))
}

export async function createStripeCheckout(paymentId: number): Promise<any> {
  return post(getPaymentCheckoutApiPath(paymentId.toString()), {})
}

export async function connectSquareByCode(
  code: string
): Promise<UserPaymentInfo> {
  return post(connectSquareApiPath, { code })
}

export async function connectTestSquareAccount(
  userId: number | string
): Promise<UserPaymentInfo> {
  return post(connectTestSquareApiPath(userId))
}
