import { InquiryEvent } from 'types/Inquiry'

import { EventStatus } from '../../constants/events'

import { build, factory, nameSequence, sequence } from './factory'
import { inquiryFactory } from './inquiry'
import { venueItemFactory } from './venue'

export const eventFactory = () => {
  const venue = build(venueItemFactory())
  const inquiry = build(inquiryFactory(), { venue })
  return factory<InquiryEvent>({
    id: sequence(),
    name: nameSequence('Event'),
    type: 'Cocktail',
    start_date: '2020-01-01',
    end_date: '2020-01-01',
    guests: 100,
    style: 'Casual',
    status: EventStatus.STATUS_PLANNING,
    notes: 'Notes',
    inquiry: inquiry,
    inquiries_related: [inquiry],
    venues: [venue],
    budgets: [],
    partner: '',
  })
}
