import { BoxProps } from '@material-ui/core'
import * as React from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { Loader } from '../../../../components/ui/Loader'
import SwipeSlider from '../../../../components/ui/SwipeSlider/SwipeSlider'
import { VenueItem } from '../../../../types/Venue'
import { useVenues } from '../../../../utils/api/venues'
import { venuePath } from '../../../../utils/paths'
import VenueListItemFooter from '../VenueListItem/VenueListItemFooter'

interface VenuesSliderProps extends BoxProps {
  sliderTitle?: string
  slidesPerView?: number
  slidesPerGroup?: number
  withoutEndFrame?: boolean
  params?:
    | Record<string, string>
    | Record<string, boolean>
    | Record<string, null | number | boolean>
  venueId?: number
  flexItems?: boolean
  withoutFooter?: boolean
  absPosNavigation?: boolean
  nextNavButtonClass?: string
  prevNavButtonClass?: string
  dataLimit?: number
  activeVenueIndex?: number
  handleActiveVenueIndex?: (index: number) => void
  isAbs?: boolean
  withPagination?: boolean
  slideWidth?: number
  slideImageHeight?: number
}

const defaultParams = {
  page: 1,
}

const VenuesSlider: React.FC<VenuesSliderProps> = (props) => {
  const {
    withPagination = false,
    isAbs,
    handleActiveVenueIndex,
    activeVenueIndex,
    dataLimit,
    flexItems = false,
    withoutFooter = false,
    absPosNavigation = false,
    sliderTitle,
    slidesPerView,
    slidesPerGroup = slidesPerView,
    withoutEndFrame = false,
    params = {},
    venueId,
    slideWidth,
    slideImageHeight,
    ...boxProps
  } = props
  const { isLoading, data } = useVenues({ ...defaultParams, ...params })
  const history = useHistory()
  const route = history.location.pathname + history.location.search

  const onVenueClick = (slug: string) =>
    history.push(
      generatePath(venuePath, {
        slug,
      }),
      { breadcrumbs: [{ label: `Marketplace`, route }] }
    )

  const venuesItems =
    params.similar && venueId
      ? data?.data.filter((venue: VenueItem) => venue.id !== venueId)
      : data?.data
  const venues = dataLimit ? venuesItems?.slice(0, dataLimit) : venuesItems

  const itemList =
    venues?.map((venue: VenueItem) => ({
      id: venue.slug,
      image: venue.images?.[0],
      promotionDescription: venue.promotion_description,
      imageStaticType: 'venue',
      imageSize: !flexItems && !isAbs ? 'medium' : isAbs ? 'original' : 'small',
      onClick: !isAbs ? onVenueClick : undefined,
      footer: !withoutFooter && (
        <VenueListItemFooter
          flexItems={flexItems}
          onClick={!isAbs ? onVenueClick : undefined}
          data={venue}
        />
      ),
    })) || []
  return isLoading ? (
    <div>
      <Loader size={30} />
    </div>
  ) : !!itemList.length ? (
    <SwipeSlider
      withPagination={withPagination}
      activeSlideIndex={activeVenueIndex}
      handleActiveVenueIndex={handleActiveVenueIndex}
      absPosNavigation={absPosNavigation}
      flexItems={flexItems}
      withoutEndFrame={withoutEndFrame}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      data={itemList}
      title={sliderTitle}
      slideWidth={slideWidth}
      slideImageHeight={slideImageHeight}
      {...boxProps}
    />
  ) : (
    <></>
  )
}

export default VenuesSlider
