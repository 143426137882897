import { setupCache } from 'axios-cache-adapter'
import { useQuery, useQueryClient } from 'react-query'

import { getPartnerParams } from '../../constants/partners'
import {
  MessagesThreadData,
  MessageTemplateData,
} from '../../types/dtos/messages'
import { clearObject } from '../helpers/data'
import { getFormData } from '../helpers/objectToFormData'
import {
  getMessageDownloadFileApiPath,
  getMessagesApiPath,
  getSalesMessageDownloadFileApiPath,
  getSalesMessagesApiPath,
  messagesApiPath,
  messagesTemplatesApiPath,
  salesMessagesApiPath,
} from '../paths'

import { get, post, postFormData, putFormData } from './api'

const cache = setupCache({
  maxAge: 1, // disable
})

export async function createMessagesThread(
  data: MessagesThreadData,
  isSales: boolean,
  isBusiness: boolean = false
): Promise<void> {
  const apiPath = isSales ? salesMessagesApiPath : messagesApiPath
  const type = isBusiness ? 'business_sales' : 'client_sales'

  const body = isBusiness
    ? { ...data, type }
    : {
        event_id: data.inquiry_id,
        ...data,
        type,
      }
  const formData = getFormData(clearObject(isSales ? body : data))
  return postFormData(apiPath, formData)
}

export async function updateMessagesThread(
  threadId: string,
  isSales: boolean,
  data: MessagesThreadData
): Promise<any> {
  const apiPath = isSales
    ? getSalesMessagesApiPath(threadId)
    : getMessagesApiPath(threadId)
  const formData = getFormData(clearObject(data))
  return putFormData(apiPath, formData)
}

export async function getMessages(
  threadId: string,
  isSales: boolean
): Promise<any> {
  const apiPath = isSales
    ? getSalesMessagesApiPath(threadId)
    : getMessagesApiPath(threadId)
  return get(apiPath, {}, { adapter: cache.adapter })
}

export function useMessagesByThread(
  enabled: boolean,
  threadId: string,
  isSales: boolean
) {
  const queryClient = useQueryClient()
  const key = ['messages-by-thread', threadId]

  const query = useQuery<any, Error>(
    key,
    async () => await getMessages(threadId, isSales),
    { enabled, cacheTime: 0 }
  )
  return { ...query, queryClient, key }
}

export async function getThreads(params: {
  event_id?: number
  inquiry_id?: number
  isSales: boolean
}): Promise<any> {
  const apiPath = params.isSales ? salesMessagesApiPath : messagesApiPath
  return get(apiPath, { ...clearObject(params) }, { adapter: cache.adapter })
}

export function useThreads(
  enabled: boolean,
  params: { event_id?: number; inquiry_id?: number; isSales: boolean }
) {
  const queryClient = useQueryClient()
  const { inquiry_id } = params

  const queryKey = inquiry_id ? ['get-threads', inquiry_id] : ['get-threads']

  const query = useQuery<any[], Error>(
    queryKey,
    async () => await getThreads({ ...params }),
    {
      enabled,
      cacheTime: 0,
    }
  )

  return { ...query, queryClient }
}

export async function saveTemplate(data: MessageTemplateData): Promise<any> {
  const formData = getFormData(clearObject(data))
  return postFormData(messagesTemplatesApiPath, formData)
}

export async function getTemplates(): Promise<any> {
  return get(messagesTemplatesApiPath, {}, { adapter: cache.adapter })
}

export function useMessagesTemplates(enabled: boolean) {
  const queryClient = useQueryClient()

  const query = useQuery<any[], Error>(
    'messages-templates',
    async () => await getTemplates(),
    {
      enabled,
      cacheTime: 0,
    }
  )

  return { ...query, queryClient }
}

export function downloadThreadFile(
  threadId: string,
  isSales: boolean,
  fileId: string
) {
  const apiPath = isSales
    ? getSalesMessageDownloadFileApiPath(threadId, fileId)
    : getMessageDownloadFileApiPath(threadId, fileId)
  return post(apiPath, getPartnerParams())
}
