import * as React from 'react'

interface ModalDataProps {
  type?: 'success' | 'error' | 'warning'
  title: string
  text?: string
  onAccept: () => void
}

export interface confirmModalProps {
  isOpen: boolean
  toggleModal: () => void
  setModal: (data: ModalDataProps) => void
  modalData: ModalDataProps
}

export function useConfirmModal(): confirmModalProps {
  const [isOpen, setOpen] = React.useState(false)
  const [modalData, setModalData] = React.useState<ModalDataProps>({
    title: '',
    text: '',
    onAccept: () => {},
    type: 'warning',
  })

  function setModal(data: ModalDataProps) {
    setModalData({ type: 'warning', ...data })
    setOpen(true)
  }

  function toggleModal() {
    setOpen(!isOpen)
  }

  return { isOpen, toggleModal, modalData, setModal }
}
