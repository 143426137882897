import { Typography, useTheme } from '@material-ui/core'
import { capitalize } from 'lodash/fp'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Download from '../svg/clientDashboard/Download'

import { Document } from './Document'

interface InquiryFlowDocumentProps {
  date: string
  name?: string
  type: 'contract' | 'invoice'
  onDownload?: () => void
}

export const InquiryFlowDocumentItem: React.FC<InquiryFlowDocumentProps> = ({
  date,
  name,
  type,
  onDownload,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Document name={name || capitalize(type)} date={new Date(date)} mb={5}>
      <Document.Actions>
        {onDownload ? (
          <Document.Action onClick={onDownload}>
            <Download fill={theme.palette.primary.light} />
          </Document.Action>
        ) : (
          <Typography variant="body2">
            {t('inquiry.documents.noUrl', 'Waiting for generation...')}
          </Typography>
        )}
      </Document.Actions>
    </Document>
  )
}
