import React from 'react'

import { useDownloadContract } from '../../../hooks/useDownloadContract'
import { InquiryContractResponse } from '../../../types/dtos/inquiry'
import { Loader } from '../../ui/Loader'
import { InquiryFlowDocumentItem } from '../InquiryFlowDocumentItem'

export const ContractDocument: React.FC<{
  contract: InquiryContractResponse
}> = ({ contract }) => {
  const { isLoading, handleDownloadContract } = useDownloadContract()

  return (
    <>
      <InquiryFlowDocumentItem
        type="contract"
        date={contract.created_at}
        onDownload={() => handleDownloadContract(contract.id)}
      />
      {isLoading && <Loader position="fixed" />}
    </>
  )
}
