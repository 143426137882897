import React from 'react'

import { useDownloadInvoice } from '../../../hooks/useDownloadInvoice'
import { Invoice } from '../../../types/dtos/inquiry'
import { Loader } from '../../ui/Loader'
import { InquiryFlowDocumentItem } from '../InquiryFlowDocumentItem'

export const InvoiceDocument: React.FC<{ invoice: Invoice; date: string }> = ({
  invoice,
  date,
}) => {
  const { isLoading, handleDownloadInvoice } = useDownloadInvoice()

  return (
    <>
      <InquiryFlowDocumentItem
        type="invoice"
        date={date}
        onDownload={() => handleDownloadInvoice(invoice)}
      />
      {isLoading && <Loader position="fixed" />}
    </>
  )
}
