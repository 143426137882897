import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { usePartner } from '../../../hooks/usePartner'
import { useVendorCategory } from '../../../hooks/useVendorCategory'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { Vendor } from '../../../types/Vendor'
import { VenueItem } from '../../../types/Venue'
import { vendorPath, venuePath } from '../../../utils/paths'
import { NavLink } from '../../NavLink'
import { Image } from '../../ui/Image'
import { Loader } from '../../ui/Loader'
import { TooltipComponent } from '../../ui/Tooltip'
import { PromotionLabel } from '../labels/PromotionLabel'

import {
  StyledButton,
  StyledDescriptionTypography,
  StyledFooterLine,
  StyledNameTypography,
  StyledPromotionItem,
} from './PromotionItem.styles'

export const PromotionItem: React.FC<{
  listing: Vendor | VenueItem
  listingType: 'vendor' | 'venue'
}> = ({ listing, listingType }) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()
  const { isLoading, getVendorCategory } = useVendorCategory(
    listingType === 'vendor'
  )
  const { isAbs } = usePartner()
  const elementRef = useRef<HTMLDivElement | null>(null)
  const [elementHeight, setElementHeight] = useState<number>()

  useEffect(() => {
    if (!elementRef?.current?.clientHeight) return
    setElementHeight(elementRef?.current?.clientHeight)
  }, [elementRef?.current?.clientHeight])

  const fontSize = useMemo(() => {
    return isMobile ? 16 : 18
  }, [isMobile])

  const lineHeight = useMemo(() => {
    return 2 * fontSize
  }, [fontSize])

  const contentHeight = useMemo(() => {
    return lineHeight * 3
  }, [lineHeight])

  const listingDescription = useMemo(() => {
    return isAbs && 'advantageDescription' in listing
      ? listing.advantageDescription
      : listingType === 'venue'
      ? 'promotion_description' in listing
        ? listing.promotion_description
        : ''
      : 'promotionDescription' in listing
      ? listing.promotionDescription
      : ''
  }, [isAbs, listing, listingType])

  if (isLoading) return <Loader />

  return (
    <NavLink
      to={generatePath(listingType === 'vendor' ? vendorPath : venuePath, {
        slug: listing.slug,
      })}
      style={{ width: '100%' }}
    >
      <StyledPromotionItem>
        <Box width={isMobile ? '100%' : '45%'} position="relative">
          <PromotionLabel />
          <Image
            image={listing.images?.[0]}
            imageSize="medium"
            staticImageType={listingType === 'vendor' ? 'vendor' : 'venue'}
            vendorCategory={
              listingType === 'vendor' && 'type' in listing ? listing.type : ''
            }
            style={{ height: isMobile ? '263px' : '360px' }}
            alt={listing.name}
          />
        </Box>
        <Box
          position="relative"
          p={isMobile ? '24px 16px' : '44px 40px'}
          flex={1}
        >
          <TooltipComponent placement="top-start" title={listing.name}>
            <StyledNameTypography variant="h3">
              {listing.name}
            </StyledNameTypography>
          </TooltipComponent>
          {listingType === 'vendor' && 'type' in listing && (
            <Typography
              mb="17px"
              fontSize={isMobile ? 16 : 18}
              color={(theme) => theme.palette.grey[200]}
            >
              {getVendorCategory(listing.type)?.name}
            </Typography>
          )}
          <Box
            height={`${
              elementRef?.current &&
              elementHeight &&
              elementHeight > contentHeight
                ? contentHeight
                : elementHeight
            }px`}
            overflow="hidden"
            position="relative"
          >
            <StyledDescriptionTypography
              ref={elementRef}
              className={
                elementRef?.current &&
                elementHeight &&
                elementHeight > contentHeight
                  ? 'more'
                  : ''
              }
              sx={{ fontSize: fontSize, lineHeight: `${lineHeight}px` }}
              dangerouslySetInnerHTML={{ __html: listingDescription }}
            />
          </Box>
          <StyledFooterLine>
            <StyledButton>
              <Typography fontSize={18} fontWeight={500} letterSpacing="0.01em">
                {t('promotions.details', 'View details')}
              </Typography>
            </StyledButton>
          </StyledFooterLine>
        </Box>
      </StyledPromotionItem>
    </NavLink>
  )
}
