import { Box, Typography, useTheme } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Plus from '../../../../../components/svg/Plus'
import { useWindowSize } from '../../../../../hooks/useWindowSize'

export const AddServiceButton: React.FC<{ handleVendorSearch: () => void }> = ({
  handleVendorSearch,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  return (
    <Box
      display="flex"
      position={isMobile ? 'absolute' : 'static'}
      top="22px"
      right="16px"
      sx={{ cursor: 'pointer' }}
      onClick={handleVendorSearch}
    >
      <Plus fill={theme.palette.primary.light} />
      <Typography
        variant="body2"
        sx={{
          marginLeft: '5px',
          color: (theme) => theme.palette.primary.light,
          '&:hover': { opacity: 0.8 },
        }}
      >
        {t('common.search', 'Search')}
      </Typography>
    </Box>
  )
}
