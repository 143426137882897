import { Box, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const StyledGalleryContainer = styled(Box)`
  margin-top: 32px;
  ${MEDIA_RULES.SMALL} {
    margin-top: 0;
  }
`

export const StyledImageContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(10, 10, 10, 0.45),
    rgba(10, 10, 10, 0.45)
  );

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
`

export const StyledBackground = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    0deg,
    rgba(10, 10, 10, 0.45),
    rgba(10, 10, 10, 0.45)
  );
`

export const StyledNumberOfImages = styled(Typography)`
  cursor: pointer;
  position: absolute;
  top: 40%;
  font-size: 98px;
  font-weight: 600;
`

export const StyledImg = styled('img')`
  object-fit: cover;
`

export const NoPhotoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #f7f7f8;
`

export const GalleryContainer = styled(Box)`
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
