import { Box, OutlinedInput, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'

export const ListingsInput = styled(OutlinedInput)(
  ({ theme }) => `
  background: ${theme.palette.background.paper};
  color:  ${theme.palette.secondary.dark};
  cursor: pointer;
  padding: 10px 16px;
  width: 224px;
  margin-right: 26px;
  
  ${MEDIA_RULES.SMALL} {
    width: 200px;
  }

  &:hover {
    opacity: 0.8;
  }

  fieldset {
    border: none;
  }

  input {
    padding: 0;
    cursor: pointer;

    &::placeholder {
        opacity: 1;
        font-size: 14px;
    }
  }

  .MuiInputAdornment-root {
    height: 100%;
  }
`
)

export const PopoverWrapper = styled(Box)(
  ({ theme }) => `
      width: 452px;
      max-height: 550px;
      overflow-y: auto;
      padding: 24px;
      position: relative;
      margin-top: 13px;
      margin-right: 10px;
      z-index: 1;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      background: ${theme.palette.common.white};
      
      ${MEDIA_RULES.SMALL} {
        width: 100%;
      }
    `
)

export const Pointer = styled('div')`
  left: 48%;
  top: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
  border-top: 20px solid transparent;
  transform: rotate(45deg);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
`

export const ListingRowBox = styled(Box)(
  ({ theme }) => `
    padding: 12px 14px;
    background: ${theme.palette.background.paper};
    box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
    border-radius: 2px;
    display: flex;
    margin-bottom: 19px;
    color: ${theme.palette.secondary.dark};
    
    &.active {
        background: ${theme.palette.primary.light};
        color: ${theme.palette.common.white};
    }
    
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`
)

export const ListingRowContent = styled(Box)`
  margin-left: 19px;
  flex: 1;
  display: flex;
  justify-content: space-between;
`
