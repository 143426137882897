import {
  styled,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { DocumentsStyledHeaderRow } from '../../../ServiceBookingDetails.styles'

export const InvoicesTableHeader = () => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  return (
    <TableHead>
      <DocumentsStyledHeaderRow>
        {!isMobile && (
          <>
            <TableCell style={{ width: 30 }}>
              <Typography variant="body2" color="textSecondary">
                {t('business.invoices.table.number', '#')}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {t('business.invoices.table.invoiceId', 'Invoice ID')}
              </Typography>
            </TableCell>
          </>
        )}
        <TableCell>
          <Typography variant="body2" color="textSecondary">
            {t('business.invoices.table.dueDate', 'Due date')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="textSecondary">
            {t('business.invoices.table.amount', 'Amount')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="textSecondary">
            {t('business.invoices.table.status', 'Status')}
          </Typography>
        </TableCell>
        {!isMobile && (
          <TableCell>
            <Typography variant="body2" color="textSecondary">
              {t('business.invoices.table.actions', 'Actions')}
            </Typography>
          </TableCell>
        )}
      </DocumentsStyledHeaderRow>
    </TableHead>
  )
}
