import { TableRow, Typography, useTheme } from '@material-ui/core'
import * as React from 'react'

import { InvoiceDetailsModal } from '../../../../../../components/PaymentModal'
import Download from '../../../../../../components/svg/clientDashboard/Download'
import Eye from '../../../../../../components/svg/dashboard/Eye'
import { Loader } from '../../../../../../components/ui/Loader'
import { StatusBox } from '../../../../../../components/ui/StatusBox'
import { useDownloadInvoice } from '../../../../../../hooks/useDownloadInvoice'
import { useInvoiceModalData } from '../../../../../../hooks/useInvoiceModalData'
import { useSimpleModal } from '../../../../../../hooks/useSimpleModal'
import {
  InquiryInvoiceResponse,
  Invoice,
} from '../../../../../../types/dtos/inquiry'
import { Inquiry } from '../../../../../../types/Inquiry'
import { dateWithSeparator } from '../../../../../../utils/helpers/date'
import { getAllLineItemsFromGroups } from '../../../../../../utils/helpers/lineItems'
import { formatToCurrency } from '../../../../../../utils/helpers/price'
import { StyledIconButton, StyledInvoiceCell } from '../../InquiryTables.styles'

interface BundleInvoicesTableRowProps {
  invoicesBundle: InquiryInvoiceResponse
  invoice: Invoice
  number: number
  inquiryDetails?: Inquiry
}

export const InvoiceRow: React.FC<BundleInvoicesTableRowProps> = ({
  number,
  invoicesBundle,
  invoice,
}) => {
  const { isOpen: isInvoicePreview, toggleModal: toggleInvoicePreview } =
    useSimpleModal()
  const { formatInvoiceForDetailsModal } = useInvoiceModalData()
  const invoicePreviewData = formatInvoiceForDetailsModal(
    invoicesBundle,
    invoice
  )
  const { isLoading, handleDownloadInvoice } = useDownloadInvoice()
  const theme = useTheme()

  return (
    <>
      <TableRow>
        <StyledInvoiceCell>
          <Typography fontWeight={500} variant="body2">
            {number}.
          </Typography>
        </StyledInvoiceCell>
        <StyledInvoiceCell>
          <Typography fontWeight={500}>{invoice.id}</Typography>
        </StyledInvoiceCell>
        <StyledInvoiceCell>
          <Typography fontWeight={500} variant="body2">
            {dateWithSeparator(new Date(invoice.expiration_date), '/')}
          </Typography>
        </StyledInvoiceCell>
        <StyledInvoiceCell>
          <Typography align="center" fontWeight={500} variant="body2">
            {getAllLineItemsFromGroups(invoice.grouped_line_items_bundle)
              ?.length || 0}
          </Typography>
        </StyledInvoiceCell>
        <StyledInvoiceCell align="right">
          <StatusBox
            borderColor={invoice.status_info.border_color}
            textColor={invoice.status_info.text_color}
            bgColor={invoice.status_info.bg_color}
          >
            {invoice.status_info.label}
          </StatusBox>
        </StyledInvoiceCell>
        <StyledInvoiceCell align="right">
          <Typography fontWeight={500} variant="body2">
            {formatToCurrency(invoice?.price || 0)}
          </Typography>
        </StyledInvoiceCell>
        <StyledInvoiceCell align="right">
          <StyledIconButton onClick={toggleInvoicePreview}>
            <Eye fill={theme.palette.primary.light} />
          </StyledIconButton>
          <StyledIconButton onClick={() => handleDownloadInvoice(invoice)}>
            <Download fill={theme.palette.primary.light} />
          </StyledIconButton>
        </StyledInvoiceCell>
      </TableRow>

      <InvoiceDetailsModal
        isOpen={isInvoicePreview}
        onClose={() => {
          toggleInvoicePreview()
        }}
        onAction={() => {
          toggleInvoicePreview()
        }}
        invoice={invoicePreviewData}
        actionText="Confirm"
        withFooter={false}
      />

      {isLoading && <Loader position="fixed" />}
    </>
  )
}
