import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { api } from '../utils/api/api'

import { Maintenance } from './Maintenance'

const checkRequests = (Wrapped: any) => {
  function CheckRequests(props: any) {
    const [isMaintenanceError, setIsMaintenanceError] = useState(false)
    const [isNotAuth, setIsNotAuth] = useState(false)

    useEffect(() => {
      api.interceptors.response.use(
        function (response) {
          setIsMaintenanceError(false)
          return response
        },
        function (error) {
          switch (error?.response?.status) {
            case 503:
              setIsMaintenanceError(true)
              break
            case 401:
              setIsNotAuth(true)
              break
            default:
              break
          }
          return Promise.reject(error)
        }
      )
    }, [])
    return isMaintenanceError ? (
      <Maintenance />
    ) : (
      <Wrapped isNotAuth={isNotAuth} {...props} />
    )
  }

  return withRouter(CheckRequests)
}

export default checkRequests
