import { Box, styled } from '@material-ui/core'
import React, { useRef, useState, useEffect, ChangeEvent } from 'react'

import { keyCodes } from '../../../../constants'

interface VerificationInputProps {
  value?: string
  length?: number
  validChars?: string
  placeholder?: string
  autoFocus?: boolean
  inputProps?: object
  onChange?: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
}

const VerificationInputWrapper = styled(Box)(
  ({ theme }) => `
  position: relative;

  .vi {
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    left: -2000px;
    opacity: 0;
    transform: scale(0);
  }

  .vi__container {
    display: flex;
    height: 50px;
    margin-left: 36px;
    margin-right: 36px;
  }

  .vi__character {
    height: 100%;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;

    font-size: 36px;
    line-height: 48px;
    width: 40px;
    color: black;
    background-color: ${theme.palette.background.paper};
    margin-left: 8px;
    cursor: default;
    user-select: none;
    box-sizing: border-box;
  }
  
  .vi__character:first-of-type {
    margin-left: 0;
  }

  .vi__character--inactive {
    color: dimgray;
  }

  .vi__character--selected {
    outline: 2px solid cornflowerblue;
    color: cornflowerblue;
  }
`
)

const VerificationInput: React.FC<VerificationInputProps> = ({
  value,
  length = 6,
  validChars,
  placeholder,
  autoFocus,
  inputProps,
  onChange,
  onFocus,
  onBlur,
}) => {
  const [localValue, setLocalValue] = useState('')
  const [isActive, setActive] = useState(false)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  const handleClick = () => {
    inputRef.current?.focus()
  }

  const handleKeyDown = (event: any) => {
    if (
      [
        keyCodes.ARROW_LEFT,
        keyCodes.ARROW_RIGHT,
        keyCodes.ARROW_UP,
        keyCodes.ARROW_DOWN,
      ].includes(event.keyCode)
    ) {
      // do not allow to change cursor position
      event.preventDefault()
    }
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newInputVal = event.target.value.replace(/\s/g, '')

    if (RegExp(`^[${validChars}]{0,${length}}$`).test(newInputVal)) {
      if (onChange) {
        onChange?.(newInputVal)
      }
      setLocalValue(newInputVal)
    }
  }

  const getValue = () => {
    return value || localValue
  }

  const getIsActive = (index: number) => {
    return (
      (getValue().length === index ||
        (getValue().length === index + 1 && length === index + 1)) &&
      isActive
    )
  }

  return (
    <VerificationInputWrapper className="vi__wrapper">
      <input
        ref={inputRef}
        value={getValue()}
        onChange={handleInputChange}
        className={'vi'}
        onKeyDown={handleKeyDown}
        onFocus={(e) => {
          setActive(true)
          const val = e.target.value
          e.target.setSelectionRange(val.length, val.length)
          onFocus?.()
        }}
        onBlur={() => {
          setActive(false)
          onBlur?.()
        }}
        {...inputProps}
      />
      <div
        data-testid="container"
        className={'vi__container'}
        onClick={() => inputRef.current?.focus()}
      >
        {[...Array(length)].map((_, i) => (
          <Box display="flex" alignItems="center" flexGrow={1} key={i}>
            <div
              key={i}
              className={`vi__character ${
                getIsActive(i)
                  ? 'vi__character--selected'
                  : 'vi__character--inactive'
              }`}
              onClick={handleClick}
              id={`field-${i}`}
              data-testid={`character-${i}`}
            >
              {getValue()[i] || placeholder}
            </div>
            {i !== length - 1 && (
              <Box
                style={{
                  border: '1px solid #D7D7D7',
                  width: 8,
                  height: 0,
                  marginLeft: 4,
                  marginRight: 4,
                }}
              />
            )}
          </Box>
        ))}
      </div>
    </VerificationInputWrapper>
  )
}

VerificationInput.displayName = 'VerificationInput'

VerificationInput.defaultProps = {
  length: 6,
  validChars: '0-9',
  placeholder: '',
  autoFocus: false,
  inputProps: {},
}

export default VerificationInput
