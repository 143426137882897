import { Box } from '@material-ui/core'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'
import { generatePath } from 'react-router-dom'

import { PromotionDescriptionBlock } from '../../../components/cards/PromotionItem/PromotionDescriptionBlock'
import {
  GalleryForEntities,
  GalleryPhotos,
} from '../../../components/GalleryForEntities'
import { Loader } from '../../../components/ui/Loader'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { PageTitle } from '../../../components/ui/PageTitle'
import { InquiryStatuses } from '../../../constants/inquiry'
import { UserRoles } from '../../../constants/profile'
import { usePartner } from '../../../hooks/usePartner'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useVendorById } from '../../../hooks/useVendorById'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { EventVendor, LocalEvent } from '../../../types/Event'
import { GalleryImage, ImageItem } from '../../../types/general'
import { Vendor } from '../../../types/Vendor'
import { useVendors } from '../../../utils/api/vendors'
import { getModalContent } from '../../../utils/helpers/bookings'
import { imagesToGalleryImages } from '../../../utils/helpers/common'
import { convertVendorToEventVendor } from '../../../utils/helpers/vendors'
import { vendorsMapper } from '../../../utils/mappers/vendors'
import { eventPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { LikesDataProvider } from '../../../utils/providers/LikesProvider'
import { LsSetDefaultEvents } from '../../../utils/storage'
import tracking from '../../../utils/tracking'
import { VendorAbout } from '../components/VendorAbout'
import VendorsSlider from '../components/VendorsSlider/VendorsSlider'
import { VendorsFooter } from '../Vendors/components/VendorFooter'

import { VendorDetailsWrapper } from './Vendor.styles'

const otherVendorsLimit = 8

export const VendorDetails: React.FC<{
  vendorId?: string
  onSelectComplete?: () => void
  isExclusive?: boolean
  vendorUpdates?: Partial<Vendor>
  vendorInput?: Vendor
  showBottomBar?: boolean
}> = ({
  vendorId,
  onSelectComplete,
  isExclusive,
  vendorUpdates = {},
  vendorInput,
  showBottomBar = true,
}) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()
  const {
    isLoading,
    getUserActiveEvent,
    addVendor,
    removeVendor,
    chosenAbsVenue,
  } = useStorageEventsData()
  const modalControl = useSimpleModal()

  const [galleryImages, setGalleryImages] = React.useState<GalleryImage[]>([])
  const [vendorPhotos, setVendorPhotos] = React.useState<GalleryPhotos>()
  const [showGallery, setShowGallery] = React.useState<boolean>(false)
  const [isCreateAbsEventFormOpen, setIsCreateAbsEventFormOpen] =
    React.useState<boolean>(false)

  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const history = useHistory()
  const { isLoading: authLoading, authUser } = useAuthData()

  let vendor: Vendor | null | undefined = undefined
  const [, rawVendor] = useVendorById(vendorId || id)
  vendor = vendorInput
    ? vendorInput
    : rawVendor
    ? { ...rawVendor, ...vendorUpdates }
    : null

  // if (vendorInput) {
  //   vendor = vendorInput
  //   id = vendorInput.id.toString()
  // } else {
  //   const [status, rawVendor] = useVendorById(vendorId || id)
  //   vendor = rawVendor ? { ...rawVendor, ...vendorUpdates } : null
  // }

  const { data } = useVendors({ type: vendor?.type })
  const { isOpen, toggleModal, modalData } = modalControl
  const vendors = data?.data.map(vendorsMapper)
  const isBasketAvailable =
    (!authLoading && !authUser) || authUser?.role === UserRoles.Client
  const userEvent: LocalEvent | null = getUserActiveEvent()
  const pickedVendors = userEvent?.vendors || []
  const { isAbs } = usePartner()
  const shouldVendorAboutRender =
    (vendor?.labels &&
      !!vendor?.labels?.filter((label) => label.type === 'special features')
        .length) ||
    !!vendor?.info?.proTip ||
    !!vendor?.description
  const shouldCreateAbsEvent =
    (userEvent?.status === InquiryStatuses.STATUS_DRAFT &&
      !userEvent?.vendors?.length &&
      !userEvent?.start_date) ||
    !userEvent

  useEffect(() => {
    if (userEvent) return
    LsSetDefaultEvents()
  }, [userEvent])

  const otherVendors = React.useMemo(() => {
    if (!vendors || !vendor) return []

    const equalPriceVendors = vendors?.filter(
      (item: Vendor) =>
        item.orderPrice.length === (vendor?.orderPrice.length || 0)
    )
    const smallerSimilarPriceVendors = vendors?.filter(
      (item: Vendor) =>
        (vendor?.orderPrice.length || 0) - item.orderPrice.length === 1
    )
    const biggerSimilarPriceVendors = vendors?.filter(
      (item: Vendor) =>
        item.orderPrice.length - (vendor?.orderPrice.length || 0) === 1
    )

    const allOtherVendors = [
      ...equalPriceVendors,
      ...biggerSimilarPriceVendors,
      ...smallerSimilarPriceVendors,
    ]
    return allOtherVendors
      .filter((item: Vendor) => item.id !== vendor?.id)
      .slice(0, otherVendorsLimit)
  }, [vendors, vendor])

  const handleVendorRemove = async (eventVendor: EventVendor) => {
    await removeVendor(eventVendor, true)
  }

  //TODO: refactoring
  const handleVendorSelect = async () => {
    if (isAbs && !authUser) {
      history.push('?action=signup')
    } else {
      if (isAbs && shouldCreateAbsEvent) {
        if (!chosenAbsVenue) toggleModal({ name: 'selectAbsVenue' })
        else {
          localStorage.removeItem('inquiryFormValues')
          toggleModal({
            name: 'inquiryCreate',
            venue: chosenAbsVenue,
          })
        }
      }
      if (vendor) {
        const eventVendor: EventVendor = convertVendorToEventVendor(vendor)
        await addVendor(eventVendor, true)
        tracking.pickedVendor(eventVendor)

        if ((userEvent?.venue && !isAbs) || (isAbs && !shouldCreateAbsEvent)) {
          history.push(
            generatePath(eventPath, {
              id: userEvent?.id,
            })
          )
        } else if (userEvent && !userEvent?.venue && !isAbs) {
          toggleModal({ name: 'selectVenueType' })
        }

        if (onSelectComplete) {
          onSelectComplete()
        }
      }
    }
  }

  useEffect(() => {
    if (vendor?.images?.length) {
      const preparedGalleryImages = imagesToGalleryImages(
        vendor?.images.map((image: ImageItem) => image.path)
      )
      setGalleryImages(preparedGalleryImages)
      const firstPhoto = vendor?.images?.[0]
      const secondPhoto = vendor?.images?.[1]
      const thirdPhoto = vendor?.images?.[2]

      setVendorPhotos({
        firstPhoto,
        secondPhoto,
        thirdPhoto,
      })
    }
  }, [vendor?.images])

  if (!vendor) {
    return <Loader />
  }

  return (
    <LikesDataProvider>
      <Box display="flex" flexDirection="column">
        <Box className="container" order={isMobile ? 1 : 0}>
          <PageTitle title={vendor?.name} />
        </Box>
        <GalleryForEntities
          entity="vendors"
          isPromotion={isAbs ? !!vendor?.hasAdvantages : !!vendor?.hasPromotion}
          vendorCategory={vendor?.type}
          showGallery={showGallery}
          setShowGallery={(value) => setShowGallery(value)}
          photos={vendorPhotos}
          galleryImages={galleryImages}
        />
      </Box>
      <VendorDetailsWrapper>
        <Box className="container">
          {((vendor?.hasAdvantages && isAbs) || vendor.hasPromotion) && (
            <PromotionDescriptionBlock
              promotionDescription={
                isAbs
                  ? vendor?.advantageDescription
                  : vendor?.promotionDescription
              }
            />
          )}
          {shouldVendorAboutRender && (
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              className="vendor-about"
            >
              <VendorAbout vendor={vendor} />
            </Box>
          )}
          {/*<Box className="gallery">*/}
          {/*  <SwipeSlider mr={0} ml={0} navigationPosition="top" slidesPerView={4} data={gallery} title="Gallery" />*/}
          {/*</Box>*/}
          {/*<Comments />*/}
        </Box>
        {!!otherVendors?.length && (
          <Box className={isMobile ? '' : 'container'}>
            {!isExclusive ? (
              <VendorsSlider
                navigationPosition="top"
                slidesPerView={!isMobile ? 4 : undefined}
                withoutEndFrame={!!isMobile}
                data={otherVendors}
                sliderTitle={t('vendor.similar', 'Similar Vendors')}
                slideWidth={288}
                slideImageHeight={276}
              />
            ) : (
              <Box height="100px" mt={8}></Box>
            )}
          </Box>
        )}
      </VendorDetailsWrapper>
      {showBottomBar && (
        <VendorsFooter
          showOrderPrice
          shouldCreateAbsEvent={shouldCreateAbsEvent}
          pickedVendors={pickedVendors}
          category={vendor?.type}
          vendor={vendor}
          withActionButton={isBasketAvailable}
          onSelectVendor={handleVendorSelect}
          onRemoveVendor={handleVendorRemove}
        />
      )}
      {isLoading && <Loader position="fixed" />}
      <SimpleModal
        maxWidth="inherit"
        open={isOpen}
        onClose={
          modalData?.name === 'selectAbsVenue' && !isCreateAbsEventFormOpen
            ? undefined
            : toggleModal
        }
      >
        {getModalContent(modalData?.name, modalControl, {
          isAbs,
          shouldUpdateDefaultAbsVenue: true,
          shouldOpenCreateEventForm: true,
          setIsCreateAbsEventFormOpen,
        })}
      </SimpleModal>
    </LikesDataProvider>
  )
}
