import {
  Box,
  BoxProps,
  ImageListItem,
  ImageListItemProps,
  styled,
  Typography,
} from '@material-ui/core'

export const EventItemWrapper = styled(ImageListItem)<
  ImageListItemProps & { isMobile: boolean } & { isActive: boolean }
>(
  (props) => `
  min-width: ${props.isMobile ? '100%' : '140px'};
  min-height: ${props.isMobile ? '400px' : '450px'};
  max-height: ${props.isMobile ? '420px' : '460px'};
  margin-bottom: ${props.isMobile ? '20px' : 0};
  border: ${
    props.isActive ? `2px solid ${props.theme.palette.primary.main}` : 'none'
  }; 

  .MuiImageListItemBar-root {
    box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
    background: #ffffff;
  }
`
)

export const SubtitleWrapper = styled(Box)<BoxProps & { isMobile: boolean }>(
  (props) => `
      min-height: 80px;
      color: #9396a3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      ${props.isMobile && 'height: 90px'};
`
)

export const TitleWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;

  .event-action {
    color: #20202c;
    cursor: pointer;
    &:hover {
      color: #9396a3;
      opacity: 0.8;
    }
  }
`

export const PhotoPlaceholderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f7f7f8;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export const TitleTextWrapper = styled(Typography)`
  display: flex;
  flex: 1;
  cursor: pointer;
  width: fit-content;
  color: #20202c;
`

export const EventItemStatus = styled(Box)<{ borderColor: boolean }>(
  (props) => `
  background: #f7f7f8;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 8px;
  border: 1px solid ${props.borderColor};
  border-radius: 2px;
  z-index: 1;

  position: absolute;
  right: 20px;
  top: 16px;
`
)
