import { TableRow, Typography, useTheme } from '@material-ui/core'
import * as React from 'react'

import Download from '../../../../../components/svg/clientDashboard/Download'
import { DocumentFile } from '../../../../../types/general'
import { downloadUserFile } from '../../../../../utils/helpers/files'
import { StyledIconButton, StyledSecondRowCell } from '../InquiryTables.styles'

export const ContractAddendumRow: React.FC<{ addendum?: DocumentFile }> = ({
  addendum,
}) => {
  const theme = useTheme()

  const downloadAddendumHandler = async (item: DocumentFile) => {
    const response = await downloadUserFile(item.id)
    window.open(response, '_blank')
  }

  if (!addendum) return <></>

  return (
    <TableRow>
      <StyledSecondRowCell />
      <StyledSecondRowCell colSpan={3}>
        <Typography variant="body2" color={(theme) => theme.palette.grey[200]}>
          {addendum?.filename}
        </Typography>
      </StyledSecondRowCell>
      <StyledSecondRowCell align="right">
        <StyledIconButton onClick={() => downloadAddendumHandler(addendum)}>
          <Download fill={theme.palette.primary.light} />
        </StyledIconButton>
      </StyledSecondRowCell>
    </TableRow>
  )
}
