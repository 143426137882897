import { useTheme } from '@material-ui/core'

const EditIcon = ({ fill = '' }) => {
  const theme = useTheme()

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.249 3.62982L6.25286 11.626L3.07094 12.184C3.05055 12.1869 3.03911 12.1875 3.0332 12.1875C2.99732 12.1875 2.95878 12.1754 2.91842 12.1371C2.90139 12.1198 2.8887 12.0986 2.88138 12.0754L2.40454 12.2258L2.88138 12.0754C2.87384 12.0515 2.87224 12.0261 2.87673 12.0014L2.87725 11.9985L3.43653 8.80964L11.4335 0.81264L14.249 3.62982ZM14.4647 3.84564C14.4647 3.84561 14.4646 3.84558 14.4646 3.84555L14.4647 3.84564ZM3.9961 8.92765L3.88439 9.03933L3.85712 9.19493L3.55243 10.9332L3.42924 11.636L4.13188 11.5119L5.86821 11.2053L6.02334 11.1779L6.13475 11.0665L13.2187 3.98446L13.5724 3.63091L13.2188 3.27731L11.7871 1.84567L11.4336 1.49216L11.0801 1.84562L3.9961 8.92765ZM0.8125 14.8281H15.1875C15.2571 14.8281 15.3125 14.8836 15.3125 14.9531V15.3125H0.6875V14.9531C0.6875 14.8836 0.742939 14.8281 0.8125 14.8281Z"
        fill={fill || theme.palette.primary.light}
        stroke={fill || theme.palette.primary.light}
      />
    </svg>
  )
}

export default EditIcon
