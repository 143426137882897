import { Box, Divider, Link, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { alpha } from '@material-ui/system'
import { MEDIA_RULES } from 'hooks/useWindowSize'

import GradientButton from '../../components/ui/Buttons/GradientButton'

export const StyledCaption = styled(Typography)(
  ({ theme }) => `
    color: #9396A3;
    margin-right: 10px;
    font-size: 16px;
`
)

export const StyledLink = styled(Link)(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    font-family: Poppins
`
)

export const StyledEditTypography = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    font-size: 16px;
    margin-right: 10px;
    font-family: Poppins;
    cursor: pointer;
`
)

export const TestAccountConnect = styled(Box)(
  ({ theme }) => `
    border: 1px dashed ${theme.palette.primary.main};
    cursor: pointer;
    &:hover {
        background: ${alpha(theme.palette.primary.main, 0.2)}
    }
`
)

export const FieldNameTypography = styled(Typography)(
  ({ theme }) => `
   min-width: 180px;
`
)
export const StyledCancelTypography = styled(Typography)(
  ({ theme }) => `
    cursor: pointer;
    color: ${theme.palette.error.main};
    font-size: 16px;
    font-family: Poppins;
`
)

export const FormWrapper = styled(Box)`
  input {
    background-color: white;
    min-width: 400px;
    height: 16px;

    ${MEDIA_RULES.SMALL} {
      min-width: auto;
      width: 100%;
    }
  }

  .MuiTextField-root {
    ${MEDIA_RULES.SMALL} {
      width: 100%;
    }
  }

  .action-btn {
    ${MEDIA_RULES.SMALL} {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  button {
    height: 36px;
  }

  .phone {
    padding: 16px 14px;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
  }

  .input-box {
    margin-left: 60px;

    ${MEDIA_RULES.SMALL} {
      margin-left: 0;
      margin-top: 16px;
    }

    .error {
      input {
        border: 2px solid red;
      }
    }
  }
`

export const StyledGradientButton = styled(GradientButton)`
  margin-top: 25px;
  border-radius: 2px;
  min-width: 430px;
  &:hover {
    opacity: 0.95;
  }

  ${MEDIA_RULES.SMALL} {
    min-width: auto;
    width: 100%;
  }

  .MuiButton-root {
    height: 36px;
  }
`

export const ProfileDivider = styled(Divider)`
  border: 1px solid #f7f7f8;
`
