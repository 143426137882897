import {
  Box,
  Divider,
  FormControlLabel,
  styled,
  Typography,
} from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'
import CloseIcon from 'components/svg/CloseIcon'
import { FilterButton } from 'components/ui/Buttons/FilterButton'
import { PartialCheckbox, Selection } from 'components/ui/Checkbox'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { City } from 'utils/api/locations'

import {
  Dropdown,
  DropdownTitle,
  Item,
  ItemText,
  SelectableItem,
} from './Dropdown'

const BoroughsItemText = styled(ItemText)`
  padding: 10px 0;
`
const ClearContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #bdbdbd;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

type CityDropdownProps = {
  options: City[]
  values: {
    city: string | null
    districts: Record<string, Selection>
  }
  onCityClick: (slug: string) => void
  onDistrictToggle: (slug: string) => void
  onDistrictHover: (slug: string) => void
  onReset: () => void
}

export const CityDropdown = ({
  options,
  values,
  onCityClick,
  onDistrictToggle,
  onDistrictHover,
  onReset,
}: CityDropdownProps) => {
  const { t } = useTranslation()
  const isSelected = (city: City) => values.city === city.slug

  return (
    <Dropdown>
      <>
        <Item>
          <DropdownTitle>{t('venue.location.city', 'CITY')}</DropdownTitle>
        </Item>
        {options
          .filter((city) => city.districts.length > 0)
          .map((city) => (
            <Fragment key={city.slug}>
              {options.length > 1 && (
                <Item>
                  <FilterButton
                    active={isSelected(city)}
                    onClick={() => onCityClick(city.slug)}
                  >
                    {city.name}
                  </FilterButton>
                </Item>
              )}
              {isSelected(city) &&
                city.districts
                  .filter((district) => district.neighborhoods.length > 0)
                  .map((district) => (
                    <SelectableItem
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onDistrictToggle(district.slug)
                      }}
                      onMouseOver={() => onDistrictHover(district.slug)}
                      key={district.slug}
                      value={district.slug}
                    >
                      <BoroughsItemText style={{ cursor: 'auto' }}>
                        <FormControlLabel
                          control={
                            <PartialCheckbox
                              value={values.districts[district.slug]}
                              name="district"
                            />
                          }
                          label={district.name}
                        />
                      </BoroughsItemText>
                      <ArrowRight />
                    </SelectableItem>
                  ))}
            </Fragment>
          ))}

        <Divider style={{ background: '#F7F7F8', margin: 10 }} />
        <ClearContainer onClick={onReset}>
          <Typography style={{ marginRight: 5 }}>
            {t('common.filters.clear', 'Clear filter')}
          </Typography>
          <CloseIcon color="#BDBDBD" />
        </ClearContainer>
      </>
    </Dropdown>
  )
}
