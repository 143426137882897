import { useTheme } from '@material-ui/core'

import { usePartner } from '../../hooks/usePartner'

const Complete = () => {
  const { isAbs } = usePartner()
  const theme = useTheme()

  return (
    <svg width="72" height="72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="36"
        cy="36"
        r="32.677"
        transform="rotate(-96.17 36 36)"
        fill={isAbs ? theme.palette.primary.main : '#4091F0'}
      />
      <circle
        cx="36"
        cy="36"
        r="32.677"
        transform="rotate(-96.17 36 36)"
        fill="url(#a)"
      />
      <path
        d="M24.35 36.239a1.702 1.702 0 0 0-2.407 2.406l8.51 8.51a1.702 1.702 0 0 0 2.406 0L51.58 28.434a1.702 1.702 0 0 0-2.406-2.407L31.656 43.545l-7.306-7.306Z"
        fill="#fff"
      />
      <mask
        id="b"
        maskUnits="userSpaceOnUse"
        x="21"
        y="25"
        width="32"
        height="23"
      >
        <path
          d="M24.35 36.239a1.702 1.702 0 0 0-2.407 2.406l8.51 8.51a1.702 1.702 0 0 0 2.406 0L51.58 28.434a1.702 1.702 0 0 0-2.406-2.407L31.656 43.545l-7.306-7.306Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#b)">
        <path fill="#fff" d="M16.338 15.317h40.846v40.846H16.338z" />
      </g>
      {!isAbs && (
        <defs>
          <linearGradient
            id="a"
            x1="36"
            y1="3.323"
            x2="36"
            y2="68.677"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4E4BEC" />
            <stop offset="1" stopColor="#3F91F0" />
          </linearGradient>
        </defs>
      )}
    </svg>
  )
}

export default Complete
