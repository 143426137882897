import { Box, TableCell, Typography, useTheme } from '@material-ui/core'
import * as React from 'react'

import Download from '../../../../../../components/svg/clientDashboard/Download'
import { StatusBox } from '../../../../../../components/ui/StatusBox'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { Invoice } from '../../../../../../types/dtos/inquiry'
import { InquiryWithDetails } from '../../../../../../types/Inquiry'
import { dateWithSeparator } from '../../../../../../utils/helpers/date'
import { formatToCurrency } from '../../../../../../utils/helpers/price'
import {
  StyledDocumentsIconButton,
  StyledTableRow,
} from '../../../ServiceBookingDetails.styles'

interface InvoicesTableRowProps {
  invoice: Invoice
  index: number
  inquiryDetails?: InquiryWithDetails
  onDownload: (invoice: Invoice) => void
}

export const InvoicesTableRow: React.FC<InvoicesTableRowProps> = ({
  index,
  invoice,
  onDownload,
}) => {
  const theme = useTheme()
  const { isMobile } = useWindowSize()

  return (
    <StyledTableRow
      id={invoice.id?.toString()}
      style={{ border: '1px solid rgba(226, 226, 226, 1)' }}
    >
      {!isMobile && (
        <>
          <TableCell>
            <Typography fontWeight={500} variant="body2">
              {index + 1}.
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={500}>{invoice.id}</Typography>
          </TableCell>
        </>
      )}
      <TableCell>
        <Typography fontWeight={500} variant="body2">
          {dateWithSeparator(new Date(invoice.expiration_date), '/')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight={500} variant="body2">
          {formatToCurrency(invoice?.price || 0)}
        </Typography>
      </TableCell>
      <TableCell>
        <StatusBox
          borderColor={invoice.status_info.border_color}
          textColor={invoice.status_info.text_color}
          bgColor={invoice.status_info.bg_color}
        >
          {invoice.status_info.label}
        </StatusBox>
        {isMobile && (
          <Box display="flex" alignItems="center">
            <StyledDocumentsIconButton onClick={() => onDownload(invoice)}>
              <Download fill={theme.palette.primary.light} />
            </StyledDocumentsIconButton>
          </Box>
        )}
      </TableCell>
      {!isMobile && (
        <TableCell>
          <StyledDocumentsIconButton onClick={() => onDownload(invoice)}>
            <Download fill={theme.palette.primary.light} />
          </StyledDocumentsIconButton>
        </TableCell>
      )}
    </StyledTableRow>
  )
}
