const CheckCircle = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.652 6.895h-.916c-.2 0-.39.095-.506.26l-3.07 4.257-1.391-1.93a.625.625 0 0 0-.506-.26h-.916a.157.157 0 0 0-.127.249l2.434 3.375a.621.621 0 0 0 1.01 0l4.113-5.703a.156.156 0 0 0-.125-.248Z"
        fill="#5856D6"
      />
      <path
        d="M10 1.25a8.75 8.75 0 1 0 .001 17.501A8.75 8.75 0 0 0 10 1.25Zm0 16.016a7.267 7.267 0 0 1 0-14.532 7.267 7.267 0 0 1 0 14.532Z"
        fill="#5856D6"
      />
    </svg>
  )
}

export default CheckCircle
