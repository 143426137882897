import * as React from 'react'
import { useState } from 'react'

import { BusinessGeneralInfo } from '../../../../../types/general'
import { BlockContainer } from '../../BusinessDetails.styles'

import { GeneralInfoForm } from './components/GeneralInfoForm'
import { GeneralInfoPreview } from './components/GeneralInfoPreview'

export const GeneralInfo: React.FC<{
  onSave: (data: BusinessGeneralInfo) => void
  entity?: any
  attachLabelMethod: any
}> = ({ onSave, entity, attachLabelMethod }) => {
  const [isEditMode, setEditMode] = useState(false)
  const onEdit = () => {
    if (entity) {
      setEditMode(true)
    }
  }

  return (
    <BlockContainer display="flex" flexDirection="column" flex={1}>
      {isEditMode ? (
        <GeneralInfoForm
          attachLabelMethod={attachLabelMethod}
          onSave={onSave}
          entity={entity}
          onCancel={() => setEditMode(false)}
        />
      ) : (
        <GeneralInfoPreview entity={entity} onEdit={onEdit} />
      )}
    </BlockContainer>
  )
}
