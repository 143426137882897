import { SimpleModal } from 'components/ui/Modals/SimpleModal'
import { simpleModalControl, useSimpleModal } from 'hooks/useSimpleModal'
import { OfferToLoginModal } from 'pages/inquiry/components/OfferToLoginModal'

import PersonalInfoModal from '../PersonalInfo/PersonalInfo'

type InquiryCreateAuthModalsProps = {
  isOpen: boolean
  onGuestSubmit: () => void
  onGuestSuccess: () => void
  onGuestError: () => void
  onLogin: () => void
} & simpleModalControl

export const InquiryCreateAuthModals = ({
  isOpen,
  toggleModal,
  onGuestSubmit,
  onGuestSuccess,
  onGuestError,
  onLogin,
}: InquiryCreateAuthModalsProps) => {
  const newAccountModal = useSimpleModal()

  return (
    <>
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        <OfferToLoginModal
          onLogin={toggleModal}
          onLoginSuccess={onLogin}
          onSignup={() => {
            toggleModal()
            newAccountModal.toggleModal()
          }}
        />
      </SimpleModal>
      <SimpleModal
        maxWidth="inherit"
        open={newAccountModal.isOpen}
        onClose={newAccountModal.toggleModal}
      >
        <PersonalInfoModal
          onSubmit={onGuestSubmit}
          onSuccess={onGuestSuccess}
          onError={onGuestError}
        />
      </SimpleModal>
    </>
  )
}
