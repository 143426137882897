import { RangeInput } from '@material-ui/lab/DateRangePicker/RangeTypes'
import { ParseableDate } from '@material-ui/lab/internal/pickers/constants/prop-types'
import i18n from 'i18next'

import { months } from '../../constants'

export const getWeekYear = (date: Date) => {
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))

  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
  // Calculate full weeks to nearest Thursday
  const float = ((Number(date) - Number(yearStart)) / 86400000 + 1) / 7
  const weekNo = Math.ceil(float)

  return weekNo
}

export const getStartOfDate = (date?: ParseableDate<Date>): Date => {
  let parsedDate: Date
  if (!date) {
    parsedDate = new Date()
  } else {
    parsedDate = new Date(date)
  }

  parsedDate.setMinutes(0)
  parsedDate.setSeconds(0)
  parsedDate.setHours(0)

  return parsedDate
}

export const getDateFromString = (date?: string) => {
  if (!date) return null

  return new Date(date)
}

export const getDateSafely = (date: ParseableDate<Date>, withOffset = true) => {
  if (!date) return null

  if (typeof date === 'object') {
    return date as Date
  }

  const parsedDate = new Date(date)

  if (withOffset) {
    const offset = parsedDate.getTimezoneOffset()

    return new Date(parsedDate.getTime() + offset * 60 * 1000)
  }

  return parsedDate
}

export const urlDateFormat = (date: Date | null): string => {
  if (!date) return ''

  const year = date.getFullYear()
  const month = getNumberWithLeadingZero(date.getMonth() + 1)
  const day = getNumberWithLeadingZero(date.getDate())

  return `${year}-${month}-${day}`
}

export const dateWithSeparator = (date: Date | null, separator: string) => {
  const parsedDate = getDateSafely(date, false)

  if (!parsedDate) {
    return ''
  }

  const year = parsedDate.getFullYear()
  const month = getNumberWithLeadingZero(parsedDate.getMonth() + 1)
  const day = getNumberWithLeadingZero(parsedDate.getDate())

  return [month, day, year].join(separator)
}

export const getShortDateRange = (
  dateStart: ParseableDate<Date>,
  dateEnd?: ParseableDate<Date>
) => {
  const start = getDateSafely(dateStart, false)
  const end = getDateSafely(dateEnd, false)

  if (!start) {
    return ''
  }

  const year = start.getFullYear().toString().slice(2, 4)
  const month = getNumberWithLeadingZero(start.getMonth() + 1)
  const startDay = getNumberWithLeadingZero(start.getDate())
  const endDay = end ? getNumberWithLeadingZero(end.getDate()) : ''

  const day = `${startDay}${endDay && startDay !== endDay ? `-${endDay}` : ''}`
  return [month, day, year].join('/')
}

export const convertDateToShortString = (
  date: ParseableDate<Date>,
  withYear: boolean = false
): string => {
  if (Array.isArray(date)) {
    return ''
  }
  if (date && typeof date === 'object') {
    const sliceEnd = withYear ? 15 : 10

    return date.toDateString().slice(4, sliceEnd)
  }
  return ''
}

export const convertDateTimeToReadableString = (
  date: Date,
  endTime?: Date | null
): string => {
  const dateString = convertDateToShortString(date)

  if (dateHasTime(date)) {
    const timeString = convertDateToShortTimeString(date)
    if (endTime) {
      const endTimeString = convertDateToShortTimeString(endTime)
      return `${dateString} / ${timeString} - ${endTimeString}`
    }
    return `${dateString} / ${timeString}`
  }
  return dateString
}

export const convertDateToShortTimeString = (
  date: ParseableDate<Date>
): string => {
  if (date && typeof date === 'string') {
    date = new Date(date)
  }
  if (date && typeof date === 'object') {
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  }

  return ''
}

export const convertDateRangeToShortString = (range: RangeInput<Date>) => {
  const start = convertDateToShortString(range[0])
  const end = convertDateToShortString(range[1])

  if (!start && !end) return ''

  if ((start && !end) || start === end) {
    return start
  }

  return `${start} - ${end}`
}

export const convertDateRangeToReadableTimeRange = (
  range: RangeInput<Date>
) => {
  const start = convertDateToShortTimeString(range[0])
  const end = convertDateToShortTimeString(range[1])

  if (!start && !end) return ''

  if ((start && !end) || start === end || !dateHasTime(range[1])) {
    return start
  }

  return `${start} - ${end}`
}

export const isSameWeek = (date: Date | null, secondDate: Date | null) => {
  const first = getDateSafely(date)
  const second = getDateSafely(secondDate)

  if (!first || !second) return false

  try {
    const firstYear = first.getFullYear()
    const firstWeek = getWeekYear(first)
    const secondYear = second.getFullYear()
    const secondWeek = getWeekYear(second)

    return firstYear === secondYear && firstWeek === secondWeek
  } catch (e) {
    //TODO: add error handling for datadog
    return false
  }
}

export const isSameDay = (first: Date | '', second: Date | '') => {
  if (!first || !second) {
    return
  } else {
    return (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    )
  }
}

export const isSameMonth = (date: Date | null, secondDate: Date | null) => {
  const first = getDateSafely(date)
  const second = getDateSafely(secondDate)

  if (!first || !second) return false

  try {
    const firstYear = first.getFullYear()
    const firstMonth = first.getMonth()
    const secondYear = second.getFullYear()
    const secondMonth = second.getMonth()

    return firstYear === secondYear && firstMonth === secondMonth
  } catch (e) {
    return false
  }
}

export const setTimeToDate = (
  date: Date | null | undefined,
  time: ParseableDate<Date>
) => {
  if (!date || !time) {
    return null
  }

  if (typeof time !== 'object') {
    time = new Date(time)
  }

  const hours = time ? time.getHours() : 0
  const minutes = time ? time.getMinutes() : 0
  const seconds = time ? time.getSeconds() : 0

  const copyDate = new Date(date.getTime())
  copyDate.setHours(hours)
  copyDate.setMinutes(minutes)
  copyDate.setSeconds(seconds)

  return copyDate
}

export const dateHasTime = (date: ParseableDate<Date>) => {
  if (!date) {
    return false
  }

  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date)
  }

  const hours = date.getHours()
  const minutes = date.getMinutes()

  return hours !== 0 || minutes !== 0
}

export const getNumberWithLeadingZero = (value: number) => {
  return ('0' + value).slice(-2)
}

export const convertDateTimeToServerFormat = (
  date: Date | null | undefined
): string => {
  if (!date) {
    return ''
  }
  const dateObj = typeof date === 'string' ? new Date(date) : date

  const year = dateObj.getFullYear()
  const month = getNumberWithLeadingZero(dateObj.getMonth() + 1)
  const day = getNumberWithLeadingZero(dateObj.getDate())
  const hours = getNumberWithLeadingZero(dateObj.getHours())
  const minutes = getNumberWithLeadingZero(dateObj.getMinutes())
  const seconds = getNumberWithLeadingZero(dateObj.getSeconds())

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export const addWeeks = (weeks: number, date = new Date()) => {
  date.setDate(date.getDate() + weeks * 7)

  return date
}

export const addDays = (days: number, date = new Date()) => {
  date.setDate(date.getDate() + days)

  return date
}

export const addMonth = (months: number, date = new Date()) => {
  date.setMonth(date.getMonth() + months)

  return date
}

export const addYears = (years: number, date = new Date()) => {
  date.setFullYear(date.getFullYear() + years)

  return date
}

export const getDifferenceInDays = (
  date1: Date | string,
  date2: Date | string,
  includeStartDay?: boolean
) => {
  const start = getStartOfDate(date1)
  const end = getStartOfDate(date2)
  const diffInMs = Math.abs(end.getTime() - start.getTime())
  const includeStart = includeStartDay ? 1 : 0
  return Math.round(diffInMs / (1000 * 60 * 60 * 24)) + includeStart
}

export const compareDates = (date1: Date | string, date2: Date | string) => {
  const first = getStartOfDate(date1)
  const second = getStartOfDate(date2)

  if (first.getTime() > second.getTime()) return 1
  if (first.getTime() < second.getTime()) return -1

  return 0
}

export const getFormattedDateRange = (
  dateStart: Date | string | null,
  dateEnd?: Date | string | null
) => {
  const start = getDateSafely(dateStart, false)
  const end = getDateSafely(dateEnd, false)

  if (!start) {
    return ''
  }

  const year = start.getFullYear().toString()
  const month = months[start.getMonth()]
  const startDay = getNumberWithLeadingZero(start.getDate())
  const endDay = end ? getNumberWithLeadingZero(end.getDate()) : ''

  const day = `${startDay}${endDay && startDay !== endDay ? `-${endDay}` : ''}`
  return `${month} ${day}, ${year}`
}

export const getDateAndTimeForEvent = (
  start_date?: string,
  end_date?: string,
  withYear?: boolean
) => {
  const start = start_date ? new Date(start_date) : ''
  const end = end_date ? new Date(end_date) : ''
  const isSingleDayEvent = isSameDay(start, end)

  const getDates = () =>
    isSingleDayEvent
      ? convertDateToShortString(start, withYear)
      : `${convertDateToShortString(start)} - ${convertDateToShortString(
          end,
          withYear
        )}`
  const getTime = () =>
    convertDateToShortTimeString(start) === convertDateToShortTimeString(end)
      ? i18n.t('business.event.info.time', 'All day')
      : `${convertDateToShortTimeString(
          start
        )} - ${convertDateToShortTimeString(end)}`
  return { getTime, getDates, isSingleDayEvent }
}
