import * as yup from 'yup'

import { passwordRegex } from '../../constants'
import { validateEmail } from '../../utils/helpers/validations'

export const personalInfoValidationSchema = () =>
  yup.object().shape({
    name: yup.string().trim().required('Name is required'),
    // dob: yup.date().required('Date of birth is required'),
    password: yup
      .string()
      .min(6)
      .matches(
        passwordRegex,
        'The password field must contain at least one capital letter, lower case letter and number'
      )
      .required('Password is required'),
    confirm: yup
      .string()
      .oneOf([yup.ref('password'), null], "Passwords don't match")
      .required('Confirm password is required'),
  })

export const resetPasswordValidationSchema = () =>
  yup.object().shape({
    password: yup
      .string()
      .min(6)
      .matches(
        passwordRegex,
        'The password field must contain at least one capital letter, lower case letter and number'
      )
      .required('Password is required'),
    confirm: yup
      .string()
      .oneOf([yup.ref('password'), null], "Passwords don't match")
      .required('Confirm password is required'),
  })

export const signInValidationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .test('email', 'Invalid email address', (email) => {
        return validateEmail(email || '')
      }),
    phone: yup.string(),
    magicLink: yup.boolean(),
    password: yup.string().when('magicLink', {
      is: (magicLink: boolean) => magicLink,
      then: yup.string(),
      otherwise: yup.string().min(6).required('Password is required'),
    }),
  })
