import { isNil } from 'lodash'

import { dayInMilliseconds, userDefaultEvent } from '../constants'
import { DraftMessage } from '../types/dtos/messages'
import { EventVenue, LocalEvent, userEventRecord } from '../types/Event'
import { ProfileData } from '../types/Profile'
import { Vendor } from '../types/Vendor'

import {
  userEventRecordBackMapper,
  userEventRecordMapper,
} from './mappers/userEvent'

export const LsSetUserEvents = (events?: userEventRecord[]) =>
  localStorage.setItem('userEvents', JSON.stringify(events))
export const LsClearUserEvents = () => localStorage.removeItem('userEvents')

export const LsGetUserEvents = () => {
  const userEvents = localStorage.getItem('userEvents')

  if (!userEvents) {
    return LsSetDefaultEvents()
  }

  const eventsParsed = userEvents ? JSON.parse(userEvents) : []

  return eventsParsed.map(userEventRecordBackMapper)
}

export const LsUpdateDraftChatMessages = (messages: DraftMessage[]) => {
  const draftMessages = LsGetDraftChatMessages()

  if (!draftMessages || !draftMessages.hasOwnProperty('version'))
    localStorage.setItem(
      'chatDraftMessages',
      JSON.stringify({
        version: process.env.REACT_APP_VERSION,
        messages,
      })
    )
  else
    localStorage.setItem(
      'chatDraftMessages',
      JSON.stringify({ version: draftMessages?.version, messages })
    )
}

export const LsGetDraftChatMessages = (): {
  messages: DraftMessage[]
  version: string
} => {
  const chatDraftMessages = localStorage.getItem('chatDraftMessages')
  return chatDraftMessages ? JSON.parse(chatDraftMessages) : []
}

export const LsSetDefaultEvents = () => {
  const defaultEventRecord = userEventRecordMapper(userDefaultEvent)
  const defaultEventsList: userEventRecord[] = [defaultEventRecord]

  LsSetUserEvents(defaultEventsList)
  return defaultEventsList
}

export const LsGetLikedVendors = () => {
  const likedList = localStorage.getItem('likedVendors')

  return likedList ? JSON.parse(likedList) : []
}

export const LsSetLikedVendors = (likedList: Vendor[]) =>
  localStorage.setItem('likedVendors', JSON.stringify(likedList))

export const LsGetLikedVenues = () => {
  const likedList = localStorage.getItem('likedVenues')

  return likedList ? JSON.parse(likedList) : []
}

export const LsSetLikedVenues = (likedList: EventVenue[]) =>
  localStorage.setItem('likedVenues', JSON.stringify(likedList))

export const LsSetAuth = (token: string, expiry?: number) => {
  const now = new Date()
  const expTime = expiry || dayInMilliseconds

  const auth = {
    value: token,
    expiry: now.getTime() + expTime,
  }
  localStorage.setItem('auth', JSON.stringify(auth))
}

export const LsRemoveAuth = () => {
  localStorage.removeItem('auth')
  localStorage.removeItem('currentUser')
  localStorage.removeItem('inquiryFormValues')
  localStorage.removeItem('inquiryDetails')
  localStorage.removeItem('inquiryStatus')
  localStorage.removeItem('userEvents')
  localStorage.clear()
}

export const LsGetAuth = () => {
  const authJson = localStorage.getItem('auth')
  if (!authJson) {
    // LsRemoveAuth()
    return null
  }

  try {
    const auth = JSON.parse(authJson)
    const now = new Date().getTime()

    if (now > auth.expiry) {
      LsRemoveAuth()
      throw new Error('Token expired')
    }

    return auth.value
  } catch (e) {
    LsRemoveAuth()
    return null
  }
}

export const LsSetCurrentUser = (authUser: ProfileData | string) => {
  if (typeof authUser !== 'string') {
    for (const k in authUser) {
      // @ts-ignore
      if (!authUser[k] || k == 'events') {
        // @ts-ignore
        authUser[k] = ''
      }
    }
  }

  localStorage.setItem(
    'currentUser',
    typeof authUser === 'string' ? authUser : JSON.stringify(authUser)
  )
}

export const LsGetCurrentUser = () =>
  JSON.parse(localStorage.getItem('currentUser') || '{}')

export const LsSetInquiryDetails = (data: any) => {
  localStorage.setItem('inquiryDetails', JSON.stringify(data))
}
export const LsGetInquiryDetails = () => {
  return !isNil(localStorage.getItem('inquiryDetails'))
    ? JSON.parse(localStorage.getItem('inquiryDetails') as string)
    : {}
}

export const LsRemoveInquiryDetails = () => {
  localStorage.removeItem('inquiryDetails')
}

export const LsSetInquiryFormValues = (data: any) => {
  localStorage.setItem('inquiryFormValues', JSON.stringify(data))
}
export const LsGetInquiryFormValues = () => {
  return !isNil(localStorage.getItem('inquiryFormValues'))
    ? JSON.parse(localStorage.getItem('inquiryFormValues') as string)
    : ''
}

export const LsRemoveInquiryFormValues = () => {
  localStorage.removeItem('inquiryFormValues')
}

export const LsSetJotFormValues = (data: any) => {
  localStorage.setItem('jotFormValues', JSON.stringify(data))
}
export const LsGetJotFormValues = () => {
  return !isNil(localStorage.getItem('jotFormValues'))
    ? JSON.parse(localStorage.getItem('jotFormValues') as string)
    : ''
}

export const LsRemoveJotFormValues = () => {
  localStorage.removeItem('jotFormValues')
}

export const LsSaveLastPath = (path: string) => {
  const lastPath = path.includes('?action=login')
    ? path.split('?action=login')[0]
    : path
  localStorage.setItem('lastAuthPath', lastPath)
}

export const LsGetLastPath = () => {
  return localStorage.getItem('lastAuthPath')
}

export const LsRemoveLastPath = () => {
  localStorage.removeItem('lastAuthPath')
}

export const LsGetPreferredCity = () => localStorage.getItem('preferredCity')
export const LsSetPreferredCity = (citySlug: string) =>
  localStorage.setItem('preferredCity', citySlug)

export const LsSetSearch = (searchData: Partial<LocalEvent>) => {
  const prevDataStr = localStorage.getItem('heroSearch')

  if (!prevDataStr) {
    localStorage.setItem('heroSearch', JSON.stringify(searchData))
  } else {
    const prevData = JSON.parse(prevDataStr)

    localStorage.setItem(
      'heroSearch',
      JSON.stringify({ ...prevData, ...searchData })
    )
  }
}

export const LsGetSearch = () => {
  const prevDataStr = localStorage.getItem('heroSearch')
  return prevDataStr ? JSON.parse(prevDataStr) : ({} as Partial<LocalEvent>)
}
