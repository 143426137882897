const CeilingHeight = ({
  fill = '#9396A3',
  width = 24,
  height = 24,
  scale = 1,
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.436 2.625H2.564a.126.126 0 0 0-.127.125v.938c0 .068.057.124.127.124h10.872c.07 0 .127-.056.127-.124V2.75a.126.126 0 0 0-.127-.125ZM8.098 5.547a.125.125 0 0 0-.196 0L6.152 7.76a.125.125 0 0 0 .098.202h1.155v5.287c0 .069.056.125.125.125h.937a.125.125 0 0 0 .125-.125V7.964H9.75c.105 0 .162-.12.098-.202l-1.75-2.215Z"
        fill={fill}
        transform={`scale(${scale})`}
      />
    </svg>
  )
}

export default CeilingHeight
