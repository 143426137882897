const RoomsIcon = ({ width = '16', height = '15', scale = 1 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0625 0.3125H1.125C0.953125 0.3125 0.8125 0.453125 0.8125 0.625V6.5625C0.8125 6.73438 0.953125 6.875 1.125 6.875H7.0625C7.23438 6.875 7.375 6.73438 7.375 6.5625V0.625C7.375 0.453125 7.23438 0.3125 7.0625 0.3125ZM6.04688 5.54688H2.14062V1.64062H6.04688V5.54688ZM14.875 0.3125H8.9375C8.76562 0.3125 8.625 0.453125 8.625 0.625V6.5625C8.625 6.73438 8.76562 6.875 8.9375 6.875H14.875C15.0469 6.875 15.1875 6.73438 15.1875 6.5625V0.625C15.1875 0.453125 15.0469 0.3125 14.875 0.3125ZM13.8594 5.54688H9.95312V1.64062H13.8594V5.54688ZM7.0625 8.125H1.125C0.953125 8.125 0.8125 8.26562 0.8125 8.4375V14.375C0.8125 14.5469 0.953125 14.6875 1.125 14.6875H7.0625C7.23438 14.6875 7.375 14.5469 7.375 14.375V8.4375C7.375 8.26562 7.23438 8.125 7.0625 8.125ZM6.04688 13.3594H2.14062V9.45312H6.04688V13.3594ZM14.875 8.125H8.9375C8.76562 8.125 8.625 8.26562 8.625 8.4375V14.375C8.625 14.5469 8.76562 14.6875 8.9375 14.6875H14.875C15.0469 14.6875 15.1875 14.5469 15.1875 14.375V8.4375C15.1875 8.26562 15.0469 8.125 14.875 8.125ZM13.8594 13.3594H9.95312V9.45312H13.8594V13.3594Z"
        fill="#9396A3"
        transform={`scale(${scale})`}
      />
    </svg>
  )
}

export default RoomsIcon
