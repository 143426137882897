import { useMutation, useQuery, useQueryClient, QueryClient } from 'react-query'

import {
  InquiryContractRequest,
  InquiryContractResponse,
} from '../../types/dtos/inquiry'
import { ResponseOrError } from '../../types/general'
import { VendorPackageData } from '../../types/Vendor'
import { DeepPartial } from '../helpers/common'
import { clearObject } from '../helpers/data'
import { getFormData } from '../helpers/objectToFormData'
import {
  generatePartnerMagicLinkHash,
  getContractApiPath,
  getVendorPackageApiPath,
  vendorPackageApiPath,
  venuePricingTypesApiPath,
} from '../paths'

import { get, post, postFormData, putFormData } from './api'

const queryKey = ['vendor-packages']

const onMutationSuccess = (
  queryClient: QueryClient,
  updatedPackage: VendorPackageData
) => {
  const previousPackages =
    queryClient.getQueryData<VendorPackageData[]>(queryKey)
  let isUpdated = false

  const mergedPackages = previousPackages
    ? previousPackages.map((item) => {
        if (item.id === updatedPackage.id) {
          isUpdated = true
          return updatedPackage
        }
        return item
      })
    : [updatedPackage]

  if (previousPackages && !isUpdated) {
    mergedPackages.unshift(updatedPackage)
  }

  queryClient.setQueryData(queryKey, mergedPackages)
}

export async function updateOrCreateVendorPackage(
  data: DeepPartial<VendorPackageData>
): Promise<any> {
  const formData = getFormData(data)
  if (data.id) {
    return putFormData(getVendorPackageApiPath(data.id.toString()), formData)
  }

  return postFormData(vendorPackageApiPath, formData)
}

export function useVendorPackagesUpdate() {
  const queryClient = useQueryClient()

  return useMutation(queryKey, updateOrCreateVendorPackage, {
    onSuccess: (updatedPackage: VendorPackageData) =>
      onMutationSuccess(queryClient, updatedPackage),
  })
}

export async function fetchVendorPackages(vendor_id?: number): Promise<any> {
  return get(vendorPackageApiPath, { ...clearObject({ vendor_id }) })
}

export function useVendorPackages(
  fetchOptions: { enabled: boolean } = { enabled: false }
) {
  return useQuery<VendorPackageData[], Error>(
    ['vendor-packages'],
    () => fetchVendorPackages(),
    fetchOptions
  )
}

async function getPricingTypes(): Promise<Promise<ResponseOrError<any>>> {
  return await get(venuePricingTypesApiPath)
}

export function usePricingTypes() {
  return useQuery({ queryKey: 'eventTypes', queryFn: getPricingTypes })
}

export async function createPartnerMagicLinkHash(): Promise<any> {
  return post(generatePartnerMagicLinkHash, { partner: 'blace' })
}
