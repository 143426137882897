import { css } from '@emotion/react'
import { GlobalStyles } from '@material-ui/core'
import { mapValues } from 'lodash/fp'
import { useEffect, useState } from 'react'

import { BREAKPOINTS } from '../constants'

function clientWidth(): number {
  return window.document.documentElement.clientWidth
}

function clientHeight(): number {
  return window.document.documentElement.clientHeight
}

function isSmallScreen(): boolean {
  return clientWidth() <= BREAKPOINTS.SMALL
}

function isPortrait(): boolean {
  return clientHeight() > clientWidth()
}

function isMediumScreen(): boolean {
  return (
    clientWidth() > BREAKPOINTS.SMALL && clientWidth() <= BREAKPOINTS.MEDIUM
  )
}

function isLargeScreen(): boolean {
  return clientWidth() > BREAKPOINTS.LARGE
}

function currentScreen() {
  return {
    windowSize: {
      width: clientWidth(),
      height: clientHeight(),
    },
    isMobile: isSmallScreen(),
    isTablet: isMediumScreen(),
    isLessThenMaxContainerWidth: !isLargeScreen(),
    isPortrait: isPortrait(),
  }
}

export const MEDIA_RULES = {
  SMALL: `@media (max-width: ${BREAKPOINTS.SMALL}px)`,
  MEDIUM: `@media (min-width: ${BREAKPOINTS.SMALL + 1}px)`,
  LARGE: `@media (min-width: ${BREAKPOINTS.LARGE}px)`,
}

export function useWindowSize() {
  const [state, setState] = useState(currentScreen())

  function handleResize() {
    setState(currentScreen())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return state
}

export const WindowSizeVariables = () => {
  const { windowSize } = useWindowSize()

  return (
    <GlobalStyles
      styles={css`
        html {
          --window-available-height: ${windowSize.height}px;
          --window-available-width: ${windowSize.width}px;
        }
      `}
    />
  )
}
