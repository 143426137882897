const Schedule = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.125 4.375H15V3.281a.157.157 0 0 0-.156-.156H13.75a.157.157 0 0 0-.156.156v1.094h-2.89V3.281a.157.157 0 0 0-.157-.156H9.453a.157.157 0 0 0-.156.156v1.094h-2.89V3.281a.157.157 0 0 0-.157-.156H5.156A.157.157 0 0 0 5 3.281v1.094H1.875A.624.624 0 0 0 1.25 5v11.25c0 .346.28.625.625.625h16.25c.346 0 .625-.28.625-.625V5a.624.624 0 0 0-.625-.625Zm-.781 11.094H2.656V5.78H5v1.094c0 .086.07.156.156.156H6.25c.086 0 .156-.07.156-.156V5.781h2.89v1.094c0 .086.071.156.157.156h1.094c.086 0 .156-.07.156-.156V5.781h2.89v1.094c0 .086.071.156.157.156h1.094c.086 0 .156-.07.156-.156V5.781h2.344v9.688ZM8.125 9.688H4.531a.157.157 0 0 0-.156.156v.937c0 .086.07.156.156.156h3.594c.086 0 .156-.07.156-.156v-.937a.157.157 0 0 0-.156-.156Zm0 2.656H4.531a.157.157 0 0 0-.156.156v.938c0 .085.07.156.156.156h3.594c.086 0 .156-.07.156-.156V12.5a.157.157 0 0 0-.156-.156Zm6.02-3.465-2.024 2.806-1.031-1.427a.308.308 0 0 0-.252-.13H9.766a.157.157 0 0 0-.127.249l2.228 3.09a.312.312 0 0 0 .504 0L15.594 9a.157.157 0 0 0-.127-.248h-1.072a.306.306 0 0 0-.25.127Z"
        fill="#F3BD00"
      />
    </svg>
  )
}

export default Schedule
