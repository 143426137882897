import {
  Box,
  ClickAwayListener,
  PopoverOrigin,
  PopperProps,
} from '@material-ui/core'
import React, {
  ReactElement,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'

import { PopoverContainer } from './PopoverField.styles'

interface props {
  className?: string
  zIndex?: number
  width?: number | string
  scrollable?: boolean
  content?: ReactNode
  input: ReactNode
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  shouldClose?: boolean
  setShouldClose?: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
}

export type PopperFieldProps = Partial<PopperProps> & props

export interface PopoverFieldRefProps {
  onClose: () => void
  onOpen: () => void
}

export const PopperField = forwardRef((props: PopperFieldProps, ref) => {
  const inputBoxRef = useRef(null)
  const {
    content,
    input,
    className,
    zIndex = 'unset',
    placement,
    width,
    scrollable = true,
    shouldClose = false,
    setShouldClose,
    onClose,
  } = props
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
    setShouldClose && setShouldClose(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (onClose) onClose()
  }

  useImperativeHandle(ref, () => ({
    onClose: handleClose,
    onOpen: () => setAnchorEl(inputBoxRef.current),
  }))

  useEffect(() => {
    if (!shouldClose) return
    if (shouldClose) handleClose()
  }, [shouldClose])

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => handleClose()}
    >
      <Box zIndex={zIndex} width={width ? width : 'unset'}>
        <Box ref={inputBoxRef} className={className} onClick={handleClick}>
          {input}
        </Box>
        <PopoverContainer
          scrollable={scrollable}
          disablePortal
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement={placement}
        >
          {content}
        </PopoverContainer>
      </Box>
    </ClickAwayListener>
  )
})

PopperField.displayName = 'PopperField'
