import { Box, IconButton, Modal, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const ConfirmModalContentWrapper = styled(Box)`
  padding: 24px;
  background: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 40px;

  min-width: 300px;
  min-height: 200px;

  max-width: 460px;

  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }

  ${MEDIA_RULES.SMALL} {
    height: 100%;
    width: 100%;
    max-width: unset;
  }
`

export const ConfirmModalInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  ${MEDIA_RULES.SMALL} {
    & > .MuiBox-root {
      max-width: 600px;
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }
  }
`

export const ConfirmModalWrapper = styled(Modal)`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const ConfirmModalText = styled(Typography)`
  padding: 0 16px;
  align-self: stretch;
  text-align: center;

  ${MEDIA_RULES.SMALL} {
    padding: 0;
  }
`

export const ConfirmModalCloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 5px;

  ${MEDIA_RULES.SMALL} {
    position: fixed;
    padding: 0;
    right: 3px;
  }

  &:hover {
    opacity: 0.4;
    border-radius: 0;
    background: transparent;
  }
`
