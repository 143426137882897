import {
  BecomeLandlordRequest,
  BecomeVendorRequest,
  UpdateProfileRequest,
  ProfileResponse,
} from '../../types/dtos/profile'
import { DocumentFile } from '../../types/general'
import { clearObject } from '../helpers/data'
import { getFormData } from '../helpers/objectToFormData'
import { becomeLandlordMapper, becomeVendorMapper } from '../mappers/userData'
import {
  disconnectGoogleApiPath,
  getRemoveFileApiPath,
  guestsOnboardingLandlordApiPath,
  guestsOnboardingVendorApiPath,
  profileApiPath,
  uploadUserFileApiPath,
  usersOnboardingLandlordApiPath,
  usersOnboardingVendorApiPath,
} from '../paths'

import { deleteRequest, get, post, postFormData } from './api'

export async function postPersonalInfo(
  data: UpdateProfileRequest
): Promise<ProfileResponse> {
  return post(profileApiPath, clearObject(data))
}

export async function createNewVendorForGuests(
  data: BecomeVendorRequest
): Promise<void> {
  return post(guestsOnboardingVendorApiPath(), becomeVendorMapper(data))
}

export async function createNewVenueForGuests(
  data: BecomeLandlordRequest
): Promise<void> {
  return post(guestsOnboardingLandlordApiPath(), becomeLandlordMapper(data))
}

export async function createNewVendorForClients(
  data: BecomeVendorRequest
): Promise<void> {
  return post(usersOnboardingVendorApiPath(), becomeVendorMapper(data))
}

export async function createNewVenueForClients(
  data: BecomeLandlordRequest
): Promise<void> {
  return post(usersOnboardingLandlordApiPath(), becomeLandlordMapper(data))
}

export async function disconnectGoogle(): Promise<void> {
  return post(disconnectGoogleApiPath, {})
}

export async function getUserProfile(): Promise<ProfileResponse> {
  return get(profileApiPath, {}, { forceUpdate: true })
}

export async function uploadUserFile(attachment: any): Promise<DocumentFile> {
  const formData = getFormData({ attachment })

  return postFormData(uploadUserFileApiPath, formData)
}

export async function removeUserFile(
  attachmentId: number
): Promise<DocumentFile> {
  return deleteRequest(getRemoveFileApiPath(attachmentId.toString()))
}
