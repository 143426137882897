import { Box } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'

import ResetPassword from '../../components/Auth/SignIn/ResetPassword'
import { usePartner } from '../../hooks/usePartner'
import { goToBlaceDashboard } from '../../utils/helpers/goToBlaceDashboard'
import { dashboardProfilePath, profilePath } from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'

const MagicPasswordChange: React.FC = () => {
  const history = useHistory()
  const { authUser } = useAuthData()
  const isBusinessUser = !!authUser?.role
  const { isAbs, partnerSlug } = usePartner()

  return (
    <Box
      display="flex"
      width="100%"
      maxWidth={500}
      flexDirection="column"
      justifyContent="center"
    >
      <ResetPassword
        onComplete={() =>
          isAbs && isBusinessUser
            ? goToBlaceDashboard(partnerSlug, dashboardProfilePath)
            : history.push(isBusinessUser ? dashboardProfilePath : profilePath)
        }
      />
    </Box>
  )
}

export default MagicPasswordChange
