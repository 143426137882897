import AV from '../../assets/images/av-category.jpg'
import AVWebp from '../../assets/images/av-category.webp'
import Beverage from '../../assets/images/beverage-category.jpg'
import BeverageWebp from '../../assets/images/beverage-category.webp'
import Catering from '../../assets/images/catering-category.jpg'
import CateringWebp from '../../assets/images/catering-category.webp'
import CreativeServices from '../../assets/images/creative-services-category.jpg'
import CreativeServicesWebp from '../../assets/images/creative-services-category.webp'
import Decor from '../../assets/images/decor-category.jpg'
import DecorWebp from '../../assets/images/decor-category.webp'
import Design from '../../assets/images/design-category.jpg'
import DesignWebp from '../../assets/images/design-category.webp'
import Entertainment from '../../assets/images/entertainment-category.jpg'
import EntertainmentWebp from '../../assets/images/entertainment-category.webp'
import EventPlanner from '../../assets/images/event-planner.jpg'
import EventPlannerWebp from '../../assets/images/event-planner.webp'
import EventProduction from '../../assets/images/event-production.jpg'
import EventProductionWebp from '../../assets/images/event-production.webp'
import Floral from '../../assets/images/floral-category.jpg'
import FloralWebp from '../../assets/images/floral-category.webp'
import FloralPlants from '../../assets/images/floral-plants-category.jpg'
import FloralPlantsWebp from '../../assets/images/floral-plants-category.webp'
import HealthSafety from '../../assets/images/health-safety-category.jpg'
import HealthSafetyWebp from '../../assets/images/health-safety-category.webp'
import Other from '../../assets/images/other-category.jpg'
import OtherWebp from '../../assets/images/other-category.webp'
import Photo from '../../assets/images/photo-category.jpg'
import PhotoWebp from '../../assets/images/photo-category.webp'
import ProductionAgency from '../../assets/images/production-agency.jpg'
import ProductionAgencyWebp from '../../assets/images/production-agency.webp'
import Rentals from '../../assets/images/rental-category.jpg'
import RentalsWebp from '../../assets/images/rental-category.webp'
import SpecialFood from '../../assets/images/special-food-pantry-category.jpg'
import SpecialFoodWebp from '../../assets/images/special-food-pantry-category.webp'
import Staffing from '../../assets/images/staffing-category.jpg'
import StaffingWebp from '../../assets/images/staffing-category.webp'
import Wellness from '../../assets/images/wellness-category.jpg'
import WellnessWebp from '../../assets/images/wellness-category.webp'
import { InquiryExclusivityTypes } from '../../constants/inquiry'
import { EventVendor, EventVenue } from '../../types/Event'
import { Vendor, VendorType } from '../../types/Vendor'

import has = Reflect.has

export const isExclusive = (exclusivity?: string) =>
  exclusivity === InquiryExclusivityTypes.STANDARD ||
  exclusivity === InquiryExclusivityTypes.IN_HOUSE

// TMP method for mock
export const getCategoryImageBySlug = (
  name: string | undefined,
  isAbs: boolean
): { image: string; imageWebp: string } => {
  switch (name) {
    case 'catering':
      return {
        image: Catering,
        imageWebp: CateringWebp,
      }
    case 'entertainment':
      return {
        image: Entertainment,
        imageWebp: EntertainmentWebp,
      }
    case 'photo-video':
    case 'photo/video':
      return {
        image: Photo,
        imageWebp: PhotoWebp,
      }
    case 'staffing':
      return {
        image: Staffing,
        imageWebp: StaffingWebp,
      }
    case 'av-lightning':
      return {
        image: AV,
        imageWebp: AVWebp,
      }
    case 'floral':
      return {
        image: Floral,
        imageWebp: FloralWebp,
      }
    case 'decor':
      return isAbs
        ? {
            image: FloralPlants,
            imageWebp: FloralPlantsWebp,
          }
        : {
            image: Decor,
            imageWebp: DecorWebp,
          }
    case 'beverage':
      return {
        image: Beverage,
        imageWebp: BeverageWebp,
      }
    case 'rentals':
      return {
        image: Rentals,
        imageWebp: RentalsWebp,
      }
    case 'design':
      return {
        image: Design,
        imageWebp: DesignWebp,
      }
    case 'event-planner':
      return {
        image: EventPlanner,
        imageWebp: EventPlannerWebp,
      }
    case 'event-production':
      return {
        image: EventProduction,
        imageWebp: EventProductionWebp,
      }
    case 'production-agency':
      return {
        image: ProductionAgency,
        imageWebp: ProductionAgencyWebp,
      }
    case 'creative-services':
      return {
        image: CreativeServices,
        imageWebp: CreativeServicesWebp,
      }
    case 'wellness-beauty':
      return {
        image: Wellness,
        imageWebp: WellnessWebp,
      }
    case 'other':
      return {
        image: Other,
        imageWebp: OtherWebp,
      }
    case 'health-safety':
      return {
        image: HealthSafety,
        imageWebp: HealthSafetyWebp,
      }
    case 'specialty-food-pantry':
      return {
        image: SpecialFood,
        imageWebp: SpecialFoodWebp,
      }
    default:
      return {
        image: Beverage,
        imageWebp: BeverageWebp,
      }
  }
}

export const convertVendorToEventVendor = (vendor: Vendor): EventVendor => {
  const eventVendor = {
    name: vendor.name,
    venueId: vendor.exclusiveVenueId?.toString(),
    exclusive: vendor.exclusive,
    type: vendor.type,
    id: vendor.id,
    photo: vendor.images?.[0],
    orderPrice: vendor.orderPrice,
    unread_messages: 0,
    primary_contact: vendor.contact,
  } as EventVendor

  return eventVendor
}

export const formatVendorsByCategory = (
  vendors: EventVendor[] | undefined
): Record<string, EventVendor[]> => {
  if (!vendors) return {}
  const vendorsByCategory: Record<string, EventVendor[]> = {}

  vendors.forEach((vendor) => {
    if (!vendorsByCategory[vendor.type]) {
      vendorsByCategory[vendor.type] = []
    }

    vendorsByCategory[vendor.type].push(vendor)
  })

  return vendorsByCategory
}

export const isServiceVendor = (
  service: EventVenue | EventVendor
): service is EventVendor => {
  return service && has(service, 'type')
}

export const formatVendorCategories = (categories?: VendorType[]) => {
  if (categories) {
    return categories.map((item: VendorType) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
  } else {
    return []
  }
}

export const formatCustomCategories = (categories?: string[]) => {
  if (categories) {
    return categories.map((item: string) => ({ label: item, value: item }))
  } else {
    return []
  }
}

export const getCostLevel = (priceLevel: number | string) => {
  priceLevel = +priceLevel
  const level = priceLevel > 4 ? 4 : priceLevel
  return '$'.repeat(level) || '$'
}

export const isBasketContainVendor = (vendorId: number) => {
  const basket = localStorage.getItem('vendorsBasket')
    ? JSON.parse(localStorage.getItem('vendorsBasket') as string)
    : []
  const vendorIds = basket.map((vendor: Vendor) => vendor.id)
  return vendorIds.includes(vendorId)
}
