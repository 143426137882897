import { SVGProps } from 'react'

const Check = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="11"
      fill="#2F54EB"
      {...props}
    >
      <path
        strokeWidth=".3"
        d="M2.146 4.903a.615.615 0 0 1 .487.24l-.487-.24Zm0 0H1.118a.27.27 0 0 0-.211.434l4.028 5.174a.618.618 0 0 0 .976 0l7.181-9.227a.268.268 0 0 0-.21-.434h-1.028c-.19 0-.37.088-.487.24L5.421 8.726M2.146 4.903l3.276 3.822m0 0L2.633 5.143l2.79 3.582Z"
      />
    </svg>
  )
}

export default Check
