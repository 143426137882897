import { Box, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const ContactInfoWrapper = styled(Box)`
  width: 100%;
  padding: 24px 24px 32px;
  box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
  box-sizing: border-box;
  margin-top: 32px;
  ${MEDIA_RULES.SMALL} {
    box-shadow: none;
    padding: 0;
    margin-top: 56px;
  }
`
