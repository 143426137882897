const Guests = ({ color = 'white' }) => {
  return (
    <svg width="16" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.097 12.17a5.89 5.89 0 0 0-1.687-1.18 3.898 3.898 0 0 0 1.449-3.037c0-2.164-1.805-3.94-3.969-3.906a3.908 3.908 0 0 0-2.398 6.943c-.628.291-1.199.69-1.688 1.18a5.821 5.821 0 0 0-1.71 3.982.156.156 0 0 0 .156.16h1.093a.156.156 0 0 0 .157-.15 4.415 4.415 0 0 1 1.302-2.998 4.428 4.428 0 0 1 3.15-1.305c1.19 0 2.31.463 3.151 1.305a4.432 4.432 0 0 1 1.303 2.998c.002.084.072.15.156.15h1.094a.156.156 0 0 0 .156-.16 5.808 5.808 0 0 0-1.715-3.982Zm-4.144-1.717c-.668 0-1.297-.26-1.768-.732a2.478 2.478 0 0 1-.732-1.793 2.504 2.504 0 0 1 2.474-2.475 2.514 2.514 0 0 1 1.778.715c.484.475.75 1.11.75 1.785 0 .668-.26 1.295-.733 1.768a2.486 2.486 0 0 1-1.77.732ZM5.06 8.47a5.007 5.007 0 0 1 .057-1.424.157.157 0 0 0-.088-.172 2.468 2.468 0 0 1-.72-.49 2.491 2.491 0 0 1-.757-1.864c.018-.626.27-1.222.71-1.671a2.487 2.487 0 0 1 1.82-.756A2.497 2.497 0 0 1 8.16 3.24c.039.06.115.086.181.063.344-.12.707-.203 1.08-.242a.157.157 0 0 0 .123-.227A3.913 3.913 0 0 0 6.113.687C3.947.654 2.143 2.43 2.143 4.592c0 1.226.564 2.32 1.448 3.037a5.872 5.872 0 0 0-1.69 1.18 5.81 5.81 0 0 0-1.714 3.984.156.156 0 0 0 .156.16H1.44a.156.156 0 0 0 .156-.15 4.415 4.415 0 0 1 1.303-2.998 4.41 4.41 0 0 1 2.045-1.166.156.156 0 0 0 .117-.17Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default Guests
