import { setupCache } from 'axios-cache-adapter'
import { useQuery, useQueryClient } from 'react-query'

import { NOTIFICATIONS_REFETCH_INTERVAL } from '../../constants'
import {
  ServerNotification,
  ServerNotificationResponse,
} from '../../types/dtos/Notifications'
import {
  businessNotificationsApiPath,
  clientNotificationsApiPath,
  notificationsReadAllApiPath,
} from '../paths'

import { get } from './api'

const cache = setupCache({
  maxAge: 1, // disable
})

export async function getNotifications(
  isClient: boolean,
  page: number,
  businessId?: string
): Promise<ServerNotificationResponse> {
  return await get(
    !isClient && businessId
      ? businessNotificationsApiPath(businessId)
      : clientNotificationsApiPath,
    { page },
    { adapter: cache.adapter }
  )
}

export async function readNotifications(): Promise<any> {
  return get(notificationsReadAllApiPath, {}, { adapter: cache.adapter })
}

export function useNotifications(
  enabled: boolean,
  isClient: boolean,
  page: number,
  refetchInterval: number = NOTIFICATIONS_REFETCH_INTERVAL,
  businessId?: string
) {
  const queryClient = useQueryClient()
  const key = businessId
    ? ['get-notifications', businessId]
    : ['get-notifications']
  const query = useQuery<ServerNotificationResponse, Error>(
    key,
    async () => await getNotifications(isClient, page, businessId),
    { enabled, cacheTime: 0, refetchInterval }
  )

  return { ...query, queryClient }
}
