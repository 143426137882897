import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { InquiryDetailsProvider } from '../../../../utils/providers/InqueryDetailsProvider'
import { MediaLibraryProvider } from '../../../../utils/providers/MediaLibraryProvider'
import { MessageModalProvider } from '../../../../utils/providers/MessageModalProvider'

import GenerateDefaultInvoices from './GenerateDefaultInvoices'

export const GenerateDefaultInvoicesContainer = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string; listingId: string }>()
  const history = useHistory<{ invoiceBundleId?: number }>()

  return (
    <MessageModalProvider>
      <InquiryDetailsProvider id={id}>
        <MediaLibraryProvider>
          <GenerateDefaultInvoices
            editableInvoicesBundleId={history.location.state?.invoiceBundleId}
          />
        </MediaLibraryProvider>
      </InquiryDetailsProvider>
    </MessageModalProvider>
  )
}
