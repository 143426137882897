import * as React from 'react'
import { generatePath } from 'react-router-dom'

import { Contract } from '../components/svg/Contract'
import Documents from '../components/svg/dashboard/Documents'
import LineItems from '../components/svg/dashboard/LineItems'
import Progress from '../components/svg/dashboard/Progress'
import DollarCircle from '../components/svg/DollarCircle'
import Send from '../components/svg/Send'
import { SideMenuItem } from '../components/ui/SideMenu'
import { t } from '../utils/i18n'
import {
  dashboardBookingChatPath,
  dashboardBookingContractsListPath,
  dashboardBookingDetailsPath,
  dashboardBookingDocumentsPath,
  dashboardBookingInvoiceListPath,
  dashboardBookingVendorsInfoPath,
} from '../utils/paths'

import { isPartner } from './partners'

interface Column {
  id: string
  label: string
  minWidth?: number
  format?: (value: number) => string
}

export const getBookingColumns = (): readonly Column[] => [
  { id: 'venueName', label: 'Venue name', minWidth: 170 },
  {
    id: 'event',
    label: 'Event',
  },
  {
    id: 'dateTime',
    label: isPartner() ? 'Date&Time' : 'Date',
  },
  {
    id: 'guests',
    label: 'Guests',
  },
  {
    id: 'message',
    label: 'Type Message',
  },
  {
    id: 'submissionDate',
    label: 'Submission Date',
  },
  {
    id: 'lastUpdate',
    label: 'Last Update',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'action',
    label: 'Action',
  },
  {
    id: 'more',
    label: 'More',
  },
]

export interface bookingQueryParams {
  to_date: string
  page: number
  orderBy?: string
  orderDirection?: string
  limit?: number
  viewBy: string
  withPagination?: boolean
  stage?: string
}

export const BOOKING_INITIAL_SEARCH_PARAMS = {
  from_date: '',
  to_date: '',
  viewBy: 'all',
  page: 1,
  stage: '',
  withPagination: true,
  orderBy: 'Submission Date',
  orderDirection: 'desc',
}

export interface ClientEventsQueryParams {
  from_date: string
  to_date: string
  orderBy?: string
}

export const CLIENT_EVENTS_INITIAL_SEARCH_PARAMS = {
  from_date: '',
  to_date: '',
}

export const BOOKING_SORTING_FIELDS = [
  'Name',
  'Date',
  'Submission Date',
  'Last Modified Date',
  'Status',
  'None',
]
export const BOOKING_VIEW_BY = ['Day', 'Week', 'Month', 'All']

export const getBookingEventMenu = (
  id: string,
  withVendor: boolean,
  listingId: number
): SideMenuItem[] => {
  const items = [
    {
      name: t('business.bookings.progress', 'Event Progress'),
      path: generatePath(dashboardBookingDetailsPath, {
        id,
        listingId,
      }),
      icon: <Progress />,
    },
    {
      name: t('business.bookings.contracts', 'Contracts'),
      path: generatePath(dashboardBookingContractsListPath, {
        id,
        listingId,
      }),
      icon: <Contract />,
    },
    {
      name: t('business.bookings.payment', 'Invoice/Payment'),
      path: generatePath(dashboardBookingInvoiceListPath, {
        id,
        listingId,
      }),
      icon: <DollarCircle fill="#9396A3" />,
    },
    {
      name: t('business.bookings.documents', 'Documents'),
      path: generatePath(dashboardBookingDocumentsPath, {
        id,
        listingId,
      }),
      icon: <Documents />,
    },
    {
      name: t('business.bookings.chat', 'Chat'),
      path: generatePath(dashboardBookingChatPath, {
        id,
        listingId,
      }),
      icon: <Send fill="#9396a3" />,
    },
    // {
    //   name: t('business.bookings.activity','Activity'),
    //   path: generatePath(dashboardBookingActivityPath, {
    //     id,
    //     listingId,
    //   }),
    //   icon: <Activity />,
    // },
    // {
    //   name: t('business.bookings.notes','Notes'),
    //   path: generatePath(dashboardBookingNotesPath, {
    //     id,
    //     listingId,
    //   }),
    //   icon: <Notes />,
    // },
  ]
  if (withVendor) {
    items.splice(1, 0, {
      name: t('business.bookings.info', 'Info for vendors'),
      path: generatePath(dashboardBookingVendorsInfoPath, {
        id,
        listingId,
      }),
      icon: <LineItems />,
    })
  }
  return items
}
