import {
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  styled,
} from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'
import { ChangeEvent } from 'react'
import { useMemo } from 'react'

import { priceVariants } from '../../../../constants'
import { PopperField } from '../../PopoverField'

const CostLevelPopoverWrapper = styled(Paper)`
  background: white;
  border-radius: 0;
  min-width: 350px;
  z-index: 1400;

  .MuiFormControlLabel-root {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .MuiFormGroup-root {
    margin-left: 24px;
    margin-right: 24px;
  }

  ${MEDIA_RULES.SMALL} {
    min-width: unset;
    width: 100%;
  }
`

export const CostLevelInput: React.FC<{
  value?: number | string
  renderInput: (value: string | null | number) => React.ReactElement
  setValue: (value: number) => void
}> = (props) => {
  const setValueFromVariants = (e: ChangeEvent<HTMLInputElement>) => {
    props.setValue(parseInt(e.target.value))
  }

  const fieldValue = useMemo(() => getFieldValue(props.value), [props.value])

  const popover = useMemo(
    () => (
      <CostLevelPopoverWrapper>
        <RadioGroup
          aria-label="event-guests"
          name="guests"
          value={props.value}
          onChange={setValueFromVariants}
        >
          {priceVariants.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={
                <Radio
                  color="primary"
                  checked={`${item.value}` === `${props.value}`}
                />
              }
              label={item.label}
            />
          ))}
        </RadioGroup>
      </CostLevelPopoverWrapper>
    ),
    [props.value]
  )

  return (
    <PopperField
      width={1}
      input={props.renderInput(fieldValue)}
      placement="bottom-start"
      content={popover}
    />
  )
}

const getFieldValue = function (selectedValue: string | number | undefined) {
  if (!selectedValue) return null

  return (
    priceVariants?.find(
      (item) => item.value.toString() === selectedValue?.toString()
    )?.label || selectedValue
  )
}
