/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Checkbox,
  CheckboxProps,
  Divider,
  FormHelperText,
  MenuItem,
} from '@material-ui/core'
import { Form } from 'components/Form'
import { FormInputProps } from 'components/Form/FormInput'
import { FormSelectProps } from 'components/Form/FormSelect'
import CalendarBlack from 'components/svg/CalendarBlack'
import { ReactComponent as CelebrationIcon } from 'components/svg/celebration.svg'
import CheckedCalendar from 'components/svg/CheckedCalendar'
import DollarCircle from 'components/svg/DollarCircle'
import People from 'components/svg/People'
import { SimpleButton } from 'components/ui/Buttons/SimpleButton'
import { addDays, addYears } from 'date-fns'
import { useFormik } from 'formik'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import { compact, isObject, merge, prop } from 'lodash/fp'
import * as React from 'react'
import { Ref, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Room, VenueDetails } from 'types/Venue'
import {
  shouldDisplayError,
  useSyncedField,
  validateFromZodSchema,
} from 'utils/helpers/formik'
import { mobileOnly } from 'utils/styles/responsive'

import { spaceY } from '../../../../../utils/styles/space'

import { DatePicker } from './components/DatePicker'
import { BUDGET_LABELS, BUDGETS } from './constants'
import {
  FormContainer,
  ScrollContainer,
  SectionHeader,
} from './InquiryCreate.styles'
import {
  venueCapacity,
  VenueInquiryFormValues,
  VenueInquirySchema,
} from './validationSchema'

export type InquiryCreateFormProps = {
  initialValues?: Partial<VenueInquiryFormValues>
  onSubmit: (values: VenueInquiryFormValues) => void
  venue: VenueDetails
  rooms?: Room[]
}

const mergeEventHandlers =
  <E extends React.SyntheticEvent>(
    ...handlers: (undefined | ((e: E) => void))[]
  ) =>
  (e: E) =>
    compact(handlers).forEach((handler) => handler(e))

const styles = {
  scrollContainer: css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  form: css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;

    ${MEDIA_RULES.SMALL} {
      grid-template-columns: 1fr;
    }
  `,
  fullWidth: css`
    ${MEDIA_RULES.MEDIUM} {
      grid-column: 1 / -1;
    }
  `,
  guestsHelperText: css`
    text-align: right;
    margin: 0;
    margin-top: 4px;
  `,
  icon: css`
    stroke: #9396a3;
    /* Needed to unify the look of our SVG icons with Material icons */
    fill: #9396a3;
    stroke-width: 0.5px;
  `,
}

const INITIAL_VALUES = {
  name: '',
  guests: '',
  budget: '',
  dates: { start: null, end: null, flexible: false },
  notes: '',
}

export const InquiryCreateForm = ({
  initialValues,
  onSubmit,
  venue,
  rooms,
}: InquiryCreateFormProps) => {
  /* avoid re-rendering when initialValues change */
  const initialValuesRef = useRef(initialValues)
  const { t } = useTranslation()
  const schema = VenueInquirySchema(venue, rooms)

  const form = useFormik({
    onSubmit,
    validate: validateFromZodSchema(schema),
    initialValues: merge(INITIAL_VALUES as any, initialValuesRef.current), // empty values don't fit the validated type
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  })

  const minDate = addDays(new Date(), 1)
  const maxDate = addYears(new Date(), 3)

  const getInputId = (path: string) => `inquiry-create-${venue.id}-${path}`
  const getInputProps = (
    path: string,
    overrides: Partial<FormInputProps> = {}
  ): FormInputProps => ({
    name: path,
    value: prop(path, form.values),
    onChange: form.handleChange,
    ...overrides,
    onBlur: mergeEventHandlers(form.handleBlur, overrides.onBlur),
  })

  useSyncedField(form, 'dates.start', 'dates.end')

  return (
    <FormContainer onSubmit={form.handleSubmit} css={spaceY('16px')}>
      <SectionHeader variant="h3">
        {t('inquiry.form.title', 'Event details')}
      </SectionHeader>

      <ScrollContainer css={styles.scrollContainer}>
        <div css={styles.form}>
          <Form.Field
            error={shouldDisplayError(form, 'name')}
            css={styles.fullWidth}
          >
            <Form.Label htmlFor={getInputId('name')}>
              <CelebrationIcon width="20px" height="20px" css={styles.icon} />
              <span>{t('inquiry.form.fields.name.label')} *</span>
            </Form.Label>
            <Form.Input
              id={getInputId('name')}
              placeholder={t('inquiry.form.fields.name.placeholder')}
              {...getInputProps('name')}
            />
            <Form.Error visible={shouldDisplayError(form, 'name')}>
              {form.errors.name}
            </Form.Error>
          </Form.Field>

          <Form.Field error={shouldDisplayError(form, 'dates')}>
            <Form.Label htmlFor={getInputId('dates.start')}>
              <CalendarBlack
                width="20px"
                height="20px"
                css={[styles.icon, { strokeWidth: '1px' }]}
              />
              <span>{t('inquiry.form.fields.startDate.label')}</span>
            </Form.Label>
            <DatePicker
              value={form.values.dates.start}
              onChange={(date) => form.setFieldValue('dates.start', date)}
              defaultCalendarMonth={minDate}
              minDate={minDate}
              maxDate={maxDate}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Form.Input
                  {...getInputProps('dates.start', {
                    ...(inputProps as FormInputProps),
                    ...InputProps,
                  })}
                  ref={inputRef as Ref<HTMLInputElement>}
                  id={getInputId('dates.start')}
                  placeholder="MM/DD/YYYY"
                />
              )}
              PopperProps={{ 'data-testid': 'date-picker-start' } as any}
            />
            <Form.Error visible={shouldDisplayError(form, 'dates.start')}>
              {form.errors.dates?.start}
            </Form.Error>
          </Form.Field>

          <Form.Field error={shouldDisplayError(form, 'dates')}>
            <Form.Label htmlFor={getInputId('dates.end')}>
              <CalendarBlack
                width="20px"
                height="20px"
                css={[styles.icon, { strokeWidth: '1px' }]}
              />
              <span>{t('inquiry.form.fields.endDate.label')}</span>
            </Form.Label>
            <DatePicker
              value={form.values.dates.end}
              onChange={(date) => form.setFieldValue('dates.end', date)}
              minDate={form.values.dates.start || minDate}
              maxDate={maxDate}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Form.Input
                  {...getInputProps('dates.end', {
                    ...(inputProps as FormInputProps),
                    ...InputProps,
                  })}
                  ref={inputRef as Ref<HTMLInputElement>}
                  id={getInputId('dates.end')}
                  placeholder="MM/DD/YYYY"
                />
              )}
              PopperProps={{ 'data-testid': 'date-picker-end' } as any}
            />
            <Form.Error visible={shouldDisplayError(form, 'dates.end')}>
              {form.errors.dates?.end}
            </Form.Error>
          </Form.Field>

          <div css={[styles.fullWidth, { marginTop: '-16px' }]}>
            {shouldDisplayError(form, 'dates') && (
              <Form.Error>
                {!isObject(form.errors.dates) && form.errors.dates}
              </Form.Error>
            )}

            <Form.FieldInline
              error={shouldDisplayError(form, 'dates.flexible')}
            >
              <Checkbox
                id={getInputId('dates.flexible')}
                sx={{ marginLeft: '-9px', marginRight: '-9px' }}
                {...(getInputProps('dates.flexible') as CheckboxProps)}
                checked={form.values.dates.flexible}
              />
              <Form.Label htmlFor={getInputId('dates.flexible')}>
                <span>{t('inquiry.form.fields.flexible.label')}</span>
              </Form.Label>
              <Form.Error visible={shouldDisplayError(form, 'dates.flexible')}>
                {form.errors.dates?.flexible}
              </Form.Error>
            </Form.FieldInline>
          </div>

          <Form.Field error={shouldDisplayError(form, 'budget')}>
            <Form.Label id={getInputId('budget')}>
              <DollarCircle width="20px" height="20px" css={styles.icon} />
              <span>{t('inquiry.form.fields.budget.label')} *</span>
            </Form.Label>
            <Form.Select
              MenuProps={{ 'data-testid': 'menu-budget' } as any}
              aria-labelledby={getInputId('budget')}
              {...(getInputProps('budget') as FormSelectProps)}
            >
              {BUDGETS.map((budget) => (
                <MenuItem
                  sx={{ py: '16px' }}
                  divider
                  value={budget}
                  key={budget}
                >
                  {BUDGET_LABELS[budget]}
                </MenuItem>
              ))}
            </Form.Select>
            <Form.Error visible={shouldDisplayError(form, 'budget')}>
              {form.errors.budget}
            </Form.Error>
          </Form.Field>

          <Form.Field error={shouldDisplayError(form, 'guests')}>
            <Form.Label htmlFor={getInputId('guests')}>
              <People width="20px" height="20px" css={styles.icon} />
              <span>{t('inquiry.form.fields.guests.label')} *</span>
            </Form.Label>
            <Form.Input
              id={getInputId('guests')}
              inputMode="numeric"
              {...getInputProps('guests')}
            />
            {shouldDisplayError(form, 'guests') ? (
              <Form.Error css={styles.guestsHelperText}>
                {form.errors.guests}*
              </Form.Error>
            ) : (
              <Form.HelperText
                css={[
                  styles.guestsHelperText,
                  (theme) => ({ color: theme.palette.primary.main }),
                ]}
              >
                {t('inquiry.form.fields.guests.helper', {
                  capacity: venueCapacity(venue, rooms),
                })}
                *
              </Form.HelperText>
            )}
          </Form.Field>

          <Form.Field
            error={shouldDisplayError(form, 'notes')}
            css={styles.fullWidth}
          >
            <Form.Label htmlFor={getInputId('notes')}>
              <CheckedCalendar width="20px" height="20px" css={styles.icon} />
              <span>{t('inquiry.form.fields.notes.label')} *</span>
            </Form.Label>
            <Form.Input
              id={getInputId('notes')}
              multiline
              rows={3}
              placeholder={t('inquiry.form.fields.notes.placeholder')}
              {...getInputProps('notes')}
            />
            <Form.Error visible={shouldDisplayError(form, 'notes')}>
              {form.errors.notes}
            </Form.Error>
          </Form.Field>
        </div>
      </ScrollContainer>

      <Divider css={mobileOnly} />

      <SimpleButton
        sx={{ alignSelf: 'center', marginTop: '16px' }}
        disabled={!form.isValid}
        variant="contained"
        type="submit"
      >
        {t('inquiry.form.actions.submit')}
      </SimpleButton>
    </FormContainer>
  )
}
