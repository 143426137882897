import * as React from 'react'

export interface simpleModalControl {
  isOpen: boolean
  toggleModal: (extra?: any) => void
  modalData?: any
  updateModalData: (extra?: any) => void
}

// TODO: add generic type for modal data
export function useSimpleModal(): simpleModalControl {
  const [isOpen, setOpen] = React.useState(false)
  const [modalData, setExtraData] = React.useState<any>(null)

  function toggleModal(extra?: any) {
    if (extra) {
      setExtraData(extra)
    }
    setOpen(!isOpen)
  }

  function updateModalData(extra?: any) {
    setExtraData(extra)
  }

  return { isOpen, toggleModal, modalData, updateModalData }
}
