import { Box, Typography } from '@material-ui/core'
import React from 'react'

import { RawLineItem } from '../../../../../../types/RawLineItem'
import { useLineItemsTypes } from '../../../../../../utils/api/lineItems'

export const LineItemRow: React.FC<{
  lineItem: RawLineItem
  index: number
}> = ({ lineItem, index }) => {
  const { data: lineItemsType } = useLineItemsTypes()
  return (
    <>
      <Box
        pt={2}
        pb={2}
        style={{ borderBottom: '1px solid rgba(132, 132, 132, 0.12)' }}
      >
        <Typography variant="body2">{index + 1}.</Typography>
      </Box>
      <Box
        pt={2}
        pb={2}
        style={{ borderBottom: '1px solid rgba(132, 132, 132, 0.12)' }}
      >
        <Typography variant="body2">{lineItem.name}</Typography>
      </Box>
      <Box
        pt={2}
        pb={2}
        style={{ borderBottom: '1px solid rgba(132, 132, 132, 0.12)' }}
      >
        <Typography variant="body2">
          {lineItemsType?.[lineItem.item_type || ''] ||
            lineItem.item_type ||
            '-'}
        </Typography>
      </Box>
    </>
  )
}
