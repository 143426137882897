const SignupIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.798 2.398a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6Zm-5.989 10.8a2.403 2.403 0 0 0-2.41 2.4c0 2.03.999 3.56 2.561 4.557 1.539.98 3.612 1.443 5.838 1.443.492 0 .98-.022 1.452-.068a6.573 6.573 0 0 1-1.452-4.132c0-1.596.567-3.06 1.509-4.2H4.81Zm12.59 9.6a5.4 5.4 0 1 0 0-10.8 5.4 5.4 0 0 0 0 10.8Zm0-8.4a.6.6 0 0 1 .6.6v1.8h1.8a.6.6 0 0 1 0 1.2h-1.8v1.8a.6.6 0 1 1-1.2 0v-1.8h-1.8a.6.6 0 1 1 0-1.2h1.8v-1.8a.6.6 0 0 1 .6-.6Z"
        fill="#D7D7D7"
      />
    </svg>
  )
}

export default SignupIcon
