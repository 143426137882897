import { TableCell } from '@material-ui/core'
import * as React from 'react'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { CollapsibleRow } from '../../../../../components/CollapsibleRow'
import { Messenger } from '../../../../../components/Messenger'
import ArrowDown from '../../../../../components/svg/ArrowDown'
import { SimpleButton } from '../../../../../components/ui/Buttons/SimpleButton'
import { RightSideModal } from '../../../../../components/ui/Modals/RightSideModal'
import { EventServiceTab } from '../../../../../constants/events'
import { InquiryStatuses } from '../../../../../constants/inquiry'
import { useSimpleModal } from '../../../../../hooks/useSimpleModal'
import { useUrlParams } from '../../../../../hooks/useUrlParams'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { EventServiceProps, EventVendor } from '../../../../../types/Event'
import { isServiceVendor } from '../../../../../utils/helpers/vendors'
import { eventPath, getEventDetailsPath } from '../../../../../utils/paths'
import { useStorageEventsData } from '../../../../../utils/providers/EventsProvider'
import { InquiryDetailsProvider } from '../../../../../utils/providers/InqueryDetailsProvider'
import { useMessageModalData } from '../../../../../utils/providers/MessageModalProvider'
import tracking from '../../../../../utils/tracking'
import { InquiryActionButton } from '../../../../dashboard/components/InquiryActionButton'
import { ServiceBookingDetails } from '../../../ServiceBookingDetails'
import { TabsNavigation } from '../../../ServiceBookingDetails/components/Tabs/TabsNavigation'

import { StyledTableRow } from './EventServices.styles'
import { ServiceItem } from './ServiceItem'

export const ServicesTableRow: React.FC<{
  isEventDraft: boolean
  data: EventServiceProps
  isVendorsLocked?: boolean
  submitAvailable?: boolean
  setIsSubmitClicked: Dispatch<SetStateAction<boolean>>
  openExclusiveInfo: () => void
  handleOnClick: () => void
  onChangeStatus: () => void
  onCategoryClick: (serviceId?: string, isSubmitClicked?: boolean) => void
  toggleLockedVendorModal: (e: React.MouseEvent) => void
  fetchEvent: () => void
  onRemove?: (removedVendor?: EventVendor) => void
  jotForms?: any[]
}> = ({
  data,
  isEventDraft,
  isVendorsLocked,
  openExclusiveInfo,
  handleOnClick,
  submitAvailable = true,
  toggleLockedVendorModal,
  onRemove,
  setIsSubmitClicked,
  onChangeStatus,
  jotForms = [],
  fetchEvent,
  onCategoryClick,
}) => {
  const { isOpen: isMessagesModalOpen, toggleModal: toggleMessagesModal } =
    useSimpleModal()
  const {
    isOpen: isServiceBookingDetailsOpen,
    toggleModal: toggleServiceBookingDetailsModal,
  } = useSimpleModal()
  const { showMessage } = useMessageModalData()
  const { submitInquiryToActiveEvent } = useStorageEventsData()
  const { getUserActiveEvent } = useStorageEventsData()
  const userEvent = getUserActiveEvent()
  const { urlParams } = useUrlParams()
  const history = useHistory()

  const [unreadCount, setUnreadCount] = React.useState(data?.unread_messages)
  const [expandedServiceId, setExpandedServiceId] = React.useState<
    string | null
  >(null)
  const [isRowExpanded, setIsRowExpanded] = React.useState<boolean>(false)
  const [activeTab, setActiveTab] = React.useState(
    urlParams.tab ?? EventServiceTab.BOOKING_INFO
  )
  const isNotInProductionStage = !data.isVenue && isVendorsLocked
  const shouldShowAdditionalInfo = Boolean(
    data.inquiryDetails && isServiceVendor(data.inquiryDetails)
  )

  const openChat = urlParams.openChat
  const inquiryId = urlParams.inquiryId

  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  const onToggleMessenger = () => {
    setUnreadCount(0)
    toggleMessagesModal()
  }

  const onSubmitInquiry = async (e: React.MouseEvent) => {
    setIsSubmitClicked(true)
    e.stopPropagation()
    if (!submitAvailable) {
      showMessage({
        type: 'warning',
        buttons: [
          {
            label: t('vendor.interviewForm', 'Provide additional info'),
            action: () => onCategoryClick(data.inquiry_id, true),
            shouldCloseAfterAction: true,
          },
        ],
        title: t(
          'vendor.additional',
          'Please provide additional info to the vendor to submit your inquiry'
        ),
      })
      return
    }
    if (data.inquiry_id) {
      tracking.submittedInquiry(data)
      await submitInquiryToActiveEvent(data.inquiry_id)
      fetchEvent()
    }
  }

  const toggleMessagesRightModal = () => {
    if (openChat && isMessagesModalOpen) {
      history.replace(
        generatePath(eventPath, {
          id: userEvent?.id,
        })
      )
    }
    toggleMessagesModal()
  }

  const onChangeTab = () => {
    setIsRowExpanded(true)
    if (expandedServiceId !== data?.id) {
      setExpandedServiceId(data?.id)
    }
  }

  const onOpenServiceBookingDetails = () => {
    const params = data?.inquiry_id
      ? { inquiryId: data?.inquiry_id, tab: activeTab }
      : {}
    history.push(
      getEventDetailsPath(
        location.pathname,
        // @ts-ignore
        params
      )
    )
    toggleServiceBookingDetailsModal()
  }

  useEffect(() => {
    setUnreadCount(data?.unread_messages)
  }, [data?.unread_messages])

  useEffect(() => {
    if (openChat && inquiryId === data.inquiry_id) toggleMessagesModal()
  }, [openChat, inquiryId])

  /*
  useEffect(() => {
    if (
      urlParams.tab &&
      urlParams.inquiryId === data?.inquiry_id &&
      isMobile &&
      !isServiceBookingDetailsOpen
    ) {
      toggleServiceBookingDetailsModal()
      setActiveTab(urlParams.tab)
    } else if (
      !urlParams.tab &&
      !urlParams.inquiryId &&
      isServiceBookingDetailsOpen
    ) {
      toggleServiceBookingDetailsModal()
      setActiveTab(EventServiceTab.BOOKING_INFO)
    }
  }, [urlParams.tab, isMobile, urlParams.inquiryId])
  */

  return (
    <InquiryDetailsProvider id={data?.inquiry_id?.toString() || ''}>
      {!isMobile ? (
        <CollapsibleRow
          key={data?.id}
          expanded={isRowExpanded}
          expandable={true}
          onChange={onChangeTab}
          tabs={
            <TabsNavigation
              inquiryId={data?.inquiry_id || ''}
              setIsRowExpanded={setIsRowExpanded}
              inquiryDetails={data.inquiryDetails}
              shouldShowAdditionalInfo={shouldShowAdditionalInfo}
              isRowExpanded={isRowExpanded}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          }
          summaryRow={
            <StyledTableRow onClick={handleOnClick} id={data?.id?.toString()}>
              <ServiceItem
                toggleLockedVendorModal={toggleLockedVendorModal}
                data={data}
                isEventDraft={isEventDraft}
                onToggleMessenger={onToggleMessenger}
                unreadCount={unreadCount}
                openExclusiveInfo={openExclusiveInfo}
                isVendorsLocked={isVendorsLocked}
                onSubmitInquiry={onSubmitInquiry}
              />
            </StyledTableRow>
          }
        >
          <StyledTableRow hovered>
            <ServiceBookingDetails
              onToggleMessenger={onToggleMessenger}
              fetchEvent={fetchEvent}
              jotForms={jotForms}
              setIsRowExpanded={setIsRowExpanded}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={data}
            />
          </StyledTableRow>
        </CollapsibleRow>
      ) : (
        <>
          <StyledTableRow onClick={onOpenServiceBookingDetails}>
            <ServiceItem
              toggleLockedVendorModal={toggleLockedVendorModal}
              data={data}
              isEventDraft={isEventDraft}
              onToggleMessenger={onToggleMessenger}
              unreadCount={unreadCount}
              openExclusiveInfo={openExclusiveInfo}
              isVendorsLocked={isVendorsLocked}
              onSubmitInquiry={onSubmitInquiry}
            />
          </StyledTableRow>
          <StyledTableRow onClick={onOpenServiceBookingDetails}>
            <TableCell colSpan={7}>
              <SimpleButton
                variant="outlined"
                sx={{ fontSize: '12px' }}
                fullWidth
                endIcon={<ArrowDown fill="#2F54EB" />}
              >
                {t('inquiry.details', 'Inquiry Details')}
              </SimpleButton>
            </TableCell>
          </StyledTableRow>
        </>
      )}
      {isMobile &&
        (data.status === InquiryStatuses.STATUS_PENDING_SUBMIT ||
          data.status === InquiryStatuses.STATUS_PROPOSED) && (
          <StyledTableRow
            style={{ width: '100%', height: '50px' }}
            onClick={onChangeStatus}
          >
            {data.status === InquiryStatuses.STATUS_PENDING_SUBMIT && (
              <TableCell colSpan={3} sx={{ padding: '0 16px' }}>
                <InquiryActionButton
                  disabled={isNotInProductionStage}
                  sx={{ width: '100%', '&:hover': { opacity: 0.95 } }}
                  onClick={onSubmitInquiry}
                  status={data.status}
                />
              </TableCell>
            )}
            {data.status === InquiryStatuses.STATUS_PROPOSED && (
              <TableCell colSpan={3} sx={{ padding: '0 16px' }}>
                <InquiryActionButton
                  disabled={isNotInProductionStage}
                  sx={{ width: '100%', '&:hover': { opacity: 0.95 } }}
                  status={data.status}
                  onClick={handleOnClick}
                />
              </TableCell>
            )}
          </StyledTableRow>
        )}
      {isMobile && (
        <RightSideModal
          open={isServiceBookingDetailsOpen}
          onClose={toggleServiceBookingDetailsModal}
        >
          <ServiceBookingDetails
            onToggleMessenger={onToggleMessenger}
            fetchEvent={fetchEvent}
            jotForms={jotForms}
            setIsRowExpanded={setIsRowExpanded}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            data={data}
          />
        </RightSideModal>
      )}
      <RightSideModal
        open={isMessagesModalOpen}
        onClose={toggleMessagesRightModal}
        withCloseButton={false}
      >
        <Messenger data={data} onToggleMessenger={toggleMessagesRightModal} />
      </RightSideModal>
    </InquiryDetailsProvider>
  )
}
