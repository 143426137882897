import { Box, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React from 'react'
import { useHistory } from 'react-router'

import { usePartner } from '../../../hooks/usePartner'
import { useWindowSize } from '../../../hooks/useWindowSize'

export interface BreadcrumbStep {
  label: string
  route: string
  onClick?: () => void
}

export interface BreadcrumbsProps {
  steps: BreadcrumbStep[]
  current: string
  mt?: number
  mb?: number
  currentStepColor?: string
  columnStyle?: boolean
  fontFamily?: string
}

export function Breadcrumbs(props: BreadcrumbsProps) {
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const {
    steps,
    current,
    mt = isMobile ? '24px' : '32px',
    mb = isMobile ? '20px' : '26px',
    currentStepColor,
    columnStyle = false,
    fontFamily,
  } = props
  const { isAbs } = usePartner()

  const fontFamilyDefault = isAbs ? 'Acumin Pro' : 'Frank Ruhl Libre'

  const onRouteClick = (step: BreadcrumbStep) => {
    if (step.onClick) {
      step.onClick()
    } else {
      history.push(step.route)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection={columnStyle ? 'column' : 'row'}
      mb={mb}
      mt={mt}
      alignItems={columnStyle ? 'flex-start' : 'center'}
    >
      {!isMobile && (
        <Box display="flex">
          {steps.map((item, index) => (
            <Box
              display="flex"
              alignItems="center"
              onClick={() => onRouteClick(item)}
              key={item.label}
              sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
            >
              {index === 0 && columnStyle && (
                <ArrowBack sx={{ color: (theme) => theme.palette.grey[200] }} />
              )}
              <Typography
                variant={'caption'}
                sx={{
                  color: (theme) => theme.palette.grey[200],
                  textTransform: 'capitalize',
                }}
              >
                {columnStyle && index === steps.length - 1
                  ? item.label
                  : item.label + ' /'}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      <Typography
        style={{
          marginLeft: !isMobile && !columnStyle ? 5 : 0,
          textTransform: 'capitalize',
        }}
        variant="h1"
        fontFamily={fontFamily || fontFamilyDefault}
        lineHeight={columnStyle ? 1.5 : 1.12}
        sx={{
          color: (theme) => currentStepColor || theme.palette.secondary.dark,
        }}
      >
        {current}
      </Typography>
    </Box>
  )
}
