import { INITIAL_SEARCH_PARAMS } from 'constants/marketplace'

import { Loader } from 'components/ui/Loader'
import { useSearchParams } from 'hooks/useSearchParams'
import { useUrlParams } from 'hooks/useUrlParams'
import { keys } from 'lodash'
import { __, pick } from 'lodash/fp'
import { useVenues } from 'utils/api/venues'

import { VenueList } from './VenueList'
import { VenueListVertical } from './VenueListVertical'

const venueSearchParams = pick(keys(INITIAL_SEARCH_PARAMS))

export const VenueListController = (props: {
  direction: 'vertical' | 'grid'
}) => {
  const { urlParams } = useUrlParams()

  const { data, isLoading } = useVenues(venueSearchParams(urlParams))

  const params = useSearchParams()

  const venues = data?.data || []
  const lastPage = data?.last_page

  const onPaginationChange = (event: any, page: number) => {
    params.setParam('page', page)
    window.scrollTo(0, 0)
  }
  if (isLoading) return <Loader height={500} />

  return props.direction == 'vertical' ? (
    <VenueListVertical venues={venues} />
  ) : (
    <VenueList
      page={urlParams.page || 1}
      onPaginationChange={onPaginationChange}
      venues={venues}
      lastPage={lastPage}
    />
  )
}

VenueListController.displayName = 'VenueListController'
