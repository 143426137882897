import {
  ListSubheader,
  Radio,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { differenceBy } from 'lodash'
import * as React from 'react'
import { ReactElement, useMemo, useState } from 'react'

import { TooltipComponent } from '../../Tooltip'
import { StyledFormControl } from '../Components.styles'

// @ts-ignore
interface SelectFieldProps extends SelectProps {
  label?: string
  value: string
  options: any[]
  onChange: (value: string) => void
  onOpen?: () => void
  disabled?: boolean
  secondaryMode?: boolean
  className?: string
  error?: string
  errorState?: boolean
  ContainerProps?: any
  placeholder?: string
  lineItemFieldLabel?: string
  formValues?: any[]
  optionsWithoutPlaceholder?: boolean
  withRadio?: boolean
  dropdownWidth?: string
  dropdownHeight?: string
  selectPaddingLeft?: string
  selectPaddingVertical?: string
  dropdownHorizontalPosition?: 'center' | 'left' | 'right'
  dropdownVerticalPosition?: 'top' | 'bottom'
  withListSubHeader?: boolean
  selectHeight?: string
  maxSelectHeight?: string
  allowToUseSameOptionTwice?: boolean
}

export const SelectField: React.FC<SelectFieldProps> = ({
  optionsWithoutPlaceholder = false,
  withRadio = false,
  className,
  value,
  label,
  options,
  disabled,
  error,
  errorState,
  onChange,
  secondaryMode,
  placeholder,
  ContainerProps,
  lineItemFieldLabel,
  formValues = [],
  selectHeight = 'unset',
  maxSelectHeight = 'unset',
  dropdownWidth = 'unset',
  dropdownHeight = 'unset',
  selectPaddingLeft = '16px',
  selectPaddingVertical = '4px',
  dropdownHorizontalPosition = 'center',
  dropdownVerticalPosition = 'bottom',
  withListSubHeader = true,
  allowToUseSameOptionTwice = true,
  ...props
}) => {
  const items = useMemo(
    () =>
      doMenuItems(
        allowToUseSameOptionTwice,
        withRadio,
        value,
        options,
        formValues,
        dropdownWidth,
        withListSubHeader
      ),
    [options, value, allowToUseSameOptionTwice]
  )
  const pickedValue = useMemo(
    () => doPickedItem(options, value, dropdownWidth),
    [options, value]
  )
  const [showTooltip, setShowTooltip] = useState<boolean | undefined>(true)
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value as string)
  }
  const colorClass = secondaryMode ? 'secondary' : ''
  const isDefaultValue = !value || (Array.isArray(value) && !value.length)

  return (
    <StyledFormControl
      fullWidth
      selectHeight={selectHeight}
      maxSelectHeight={maxSelectHeight}
      selectPaddingLeft={selectPaddingLeft}
      selectPaddingVertical={selectPaddingVertical}
      className={`${className || ''} ${colorClass || ''} ${
        error || errorState ? 'error' : ''
      }`}
      {...ContainerProps}
    >
      {!!label && (
        <Typography gutterBottom={!!label} textAlign="left" variant="body2">
          {label}
        </Typography>
      )}

      <TooltipComponent
        title={
          lineItemFieldLabel && showTooltip && value ? lineItemFieldLabel : ''
        }
        placement="top"
      >
        {/*@ts-ignore*/}
        <Select
          sx={{ zIndex: 2000, color: (theme) => theme.palette.secondary.dark }}
          {...props}
          onOpen={() => setShowTooltip(false)}
          onClose={() => setShowTooltip(true)}
          className={
            isDefaultValue
              ? 'placeholder'
              : lineItemFieldLabel
              ? 'lineItem-field'
              : ''
          }
          displayEmpty={true}
          IconComponent={ExpandMoreOutlined}
          label={lineItemFieldLabel ? lineItemFieldLabel : label}
          onSelect={(value) => console.log(value)}
          disabled={disabled}
          value={value}
          onChange={handleChange}
          renderValue={(value) => {
            const item = options.find(
              (item) => item.id === value || item.value === value
            )
            return value
              ? lineItemFieldLabel
                ? lineItemFieldLabel
                : item?.label
              : placeholder || 'Select value'
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: dropdownVerticalPosition,
              horizontal: dropdownHorizontalPosition,
            },
            transformOrigin: {
              vertical: dropdownVerticalPosition === 'top' ? 'bottom' : 'top',
              horizontal: dropdownHorizontalPosition,
            },
            sx: {
              maxHeight: dropdownHeight,
            },
          }}
        >
          {!optionsWithoutPlaceholder && (
            <MenuItem disabled key={0} value="" sx={{ width: dropdownWidth }}>
              <em>{placeholder || 'Select value'}</em>
            </MenuItem>
          )}
          {options && items}
        </Select>
      </TooltipComponent>
      {error && (
        <Typography
          style={{ margin: '3px 14px 0px 14px', color: '#F16547' }}
          textAlign="left"
          fontSize={12}
        >
          {error}
        </Typography>
      )}
    </StyledFormControl>
  )
}

const doMenuItems = function (
  allowToUseSameOptionTwice: boolean,
  withRadio: boolean,
  value: string,
  data: any[] | undefined,
  formValues?: any[],
  dropdownWidth?: string,
  withListSubheader?: boolean
) {
  const items: ReactElement[] = []
  if (data) {
    let lastCategory: string | undefined
    const dataList = Object.values(data)
    const notSelectedLineItems = differenceBy(
      // @ts-ignore
      dataList,
      // @ts-ignore
      formValues,
      'label'
    )
    const options = allowToUseSameOptionTwice ? dataList : notSelectedLineItems
    options.map(
      (
        item: { category?: string; value: string; label: string },
        index: number
      ) => {
        if ((index === 0 && item.category) || lastCategory !== item.category) {
          lastCategory = item.category
          items.push(
            <ListSubheader key={index + 'sub'} sx={{ width: dropdownWidth }}>
              {withListSubheader && item.category}
            </ListSubheader>
          )
        }

        items.push(
          <MenuItem
            key={index}
            value={item.value}
            sx={{ width: dropdownWidth }}
          >
            {withRadio && <Radio checked={item.value === value} />}
            {item.label}
          </MenuItem>
        )
      }
    )
  }
  return items
}

const doPickedItem = function (
  data: any[] | undefined,
  value: any,
  dropdownWidth?: string
) {
  let component: ReactElement | null = null
  if (data) {
    const dataList = Object.values(data)
    const item = dataList.find(
      (item: { category?: string; value: string; label: string }) =>
        `${item.value}` === `${value}`
    )

    if (item) {
      component = (
        <MenuItem value={item.value} sx={{ width: dropdownWidth }}>
          {item.label}
        </MenuItem>
      )
    }
  }

  return component
}
