import { Box, TextField, Typography } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { ChangeEvent, useState } from 'react'
import * as React from 'react'

import { MultiSelectField } from '../../../../components/ui/FormComponents/MultiSelectField'
import { INITIAL_SEARCH_PARAMS } from '../../../../constants'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { useVenues } from '../../../../utils/api/venues'
import { SortOrder } from '../../../../utils/helpers/sort'
import { venueListQueryParams } from '../../../../utils/mappers/venueListQueryParamsMapper'
import { useInquiryModalControl } from '../../../../utils/providers/InquiryModalControlProvider'
import { SortingSelect } from '../../../marketplace/components/SortingSelect'
import { VenueList } from '../../../venues/Venues/VenueList'

export const ChangeVenueList: React.FC<{
  initialParams: Partial<venueListQueryParams>
}> = ({ initialParams = {} }) => {
  const [queryParams, updateQueryParams] = useState<
    Partial<venueListQueryParams>
  >({ ...INITIAL_SEARCH_PARAMS, ...initialParams })

  const [orderBy, setOrderBy] = useState<string>('DescSort by')
  const [orderDirection, setOrderDirection] = useState<SortOrder>('asc')

  const { data } = useVenues({})
  const { openVenue } = useInquiryModalControl()

  const venues = data?.data
  const lastPage = data?.last_page

  const { isMobile } = useWindowSize()

  const onSort = (field: string) => {
    setOrderBy(field)
    if (field !== 'DescSort by') {
      updateQueryParams({ ...queryParams, orderBy: field })
    } else {
      updateQueryParams({ ...queryParams, orderBy: '' })
    }
  }

  const onChangeDirection = (direction: SortOrder) => {
    setOrderDirection(direction)
    updateQueryParams({ ...queryParams, orderDirection: direction })
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    updateQueryParams({ ...queryParams, name: event.target.value })
  }

  const onPaginationChange = (event: any, page: number) => {
    updateQueryParams({ ...queryParams, page })
  }

  return (
    <Box ml={20} mr={20} mb={8} mt={8}>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="body1"
          fontSize={!isMobile ? '24px' : '18px'}
          color="textPrimary"
        >
          Change venues
        </Typography>
        <Typography variant="body1" color="#9396A3">
          Select the vendors you are interested in by clicking the plus sign
        </Typography>
      </Box>
      <Box mt={3} justifyContent="space-between" display="flex">
        <TextField
          className="vendors-search"
          variant="standard"
          color="secondary"
          placeholder="Search"
          fullWidth
          style={{ display: 'contents', marginRight: '20px' }}
          InputProps={{
            disableUnderline: true,
            startAdornment: <Search />,
          }}
          onChange={handleSearch}
        />
        <Box mt={1}>
          <MultiSelectField
            sx={{ minWidth: 120 }}
            multiple={false}
            id="sort-by"
            defaultLabel="Filter"
            options={['Filter1', 'Filter2', 'Filter3']}
            onChange={() => {}}
          />
        </Box>
        <Box mt={1} minWidth={200}>
          <SortingSelect
            sortingFields={['name']}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onChangeDirection={onChangeDirection}
            onSort={onSort}
          />
        </Box>
      </Box>
      {venues && (
        <VenueList
          onVenueClick={(venue) => openVenue(venue.id.toString())}
          addMode
          page={queryParams.page}
          onPaginationChange={onPaginationChange}
          venues={venues}
          lastPage={lastPage}
        />
      )}
    </Box>
  )
}
