import { Box } from '@material-ui/core'
import * as React from 'react'
import { useHistory } from 'react-router'

import { useUrlParams } from '../../hooks/useUrlParams'
import { messagesPath } from '../../utils/paths'

import { DefaultMessage } from './DefaultMessage'
import { MagicLinkExpired } from './MagicLinkExpired'
import MagicPasswordChange from './MagicPasswordChange'
import { SuccessAuth } from './SuccessAuth'
import { SuccessDeactivate } from './SuccessDeactivate'
import { SuccessRegistered } from './SuccessRegistred'
import { SuccessSubmitInquiryLink } from './SuccessSubmitInquiryLink'
import { SuccessBusinessMagicLink } from './SucessBusinessMagicLink'
import { SuccessMagicLink } from './SucessMagicLink'
import { WarningDeactivate } from './WarningDeactivate'

export const Messages = () => {
  const history = useHistory()
  const { urlParams } = useUrlParams()
  const initialPath = history.location.pathname
    ? history.location.pathname.replace(messagesPath + '/', '')
    : 'success'

  const getDialogComponent = () => {
    if (urlParams.title && urlParams.message) {
      return (
        <DefaultMessage title={urlParams.title} message={urlParams.message} />
      )
    }
    switch (initialPath) {
      case 'success-deactivate':
        return <SuccessDeactivate />
      case 'warning-deactivate':
        return <WarningDeactivate />
      case 'magic-sign-up':
        return <SuccessRegistered />
      case 'magic-sign-in':
        return <SuccessAuth />
      case 'success-magic-link':
        return <SuccessMagicLink />
      case 'expired-magic-link':
        return <MagicLinkExpired />
      case 'success-business-magic-link':
        return <SuccessBusinessMagicLink />
      case 'success-inquiry-link':
        return <SuccessSubmitInquiryLink />
      case 'magic-password-change':
        return <MagicPasswordChange />
      default:
        return <SuccessRegistered />
    }
  }

  return (
    <Box minHeight={700} display="flex" justifyContent="center" mb={20}>
      {getDialogComponent()}
    </Box>
  )
}
