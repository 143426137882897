import { Box, Link as MuiLink, styled, Typography } from '@material-ui/core'
import * as React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { getPartnerImageBySlug } from '../../../../constants/partners'
import { InquiryEvent } from '../../../../types/Inquiry'
import { joinUrl } from '../../../../utils/helpers/urls'
import { Image } from '../../Image'

export const NameLink = styled(MuiLink)(
  ({ theme }) => `
  margin-left: 15px;
  transition: color ${theme.transitions.easing.easeInOut}
    ${theme.transitions.duration.short}ms;
  
  &:hover {
    color: ${theme.palette.text.secondary}};
  }
`
)

interface ItemNameProps {
  item: InquiryEvent | any
  withSubTitle?: boolean
  linkToNavigate?: string
  isInquiry?: boolean
  inquiriesPartnersCount: number
}

export const ItemName = ({
  item,
  withSubTitle = true,
  linkToNavigate,
  isInquiry,
  inquiriesPartnersCount,
}: ItemNameProps) => {
  const { url } = useRouteMatch()
  const { name, venue, partner } = item
  const navigationLink = !linkToNavigate
    ? React.useMemo(
        () => joinUrl(url, `${item?.inquiry?.id}/details`),
        [item?.inquiry?.id, url]
      )
    : linkToNavigate

  const getRoomsString = () => {
    if (item?.inquiry?.venue_rooms?.length) {
      const rooms = item.inquiry.venue_rooms
      if (rooms.length > 2) {
        return rooms[0].name + ` +${rooms.length - 2} another`
      }
      return `${rooms[0].name}${rooms[1] ? `, ${rooms[1].name}` : ''}`
    }
    return ''
  }

  const getVenueName = () => {
    if (item?.venues?.length) {
      return item?.venues[0]?.name
    }

    return item?.inquiry?.venue?.name
  }

  return (
    <Box display="flex" alignItems="center">
      <Box sx={{ position: 'relative' }}>
        <Image
          image={venue ? venue?.images?.[0] : item?.venues[0]?.images?.[0]}
          imageSize="small"
          staticImageType="venue"
          style={{ width: 72, height: 72 }}
        />
        {inquiriesPartnersCount > 1 && (
          <img
            src={getPartnerImageBySlug(partner)}
            alt={partner || 'blace'}
            style={{
              width: '50px',
              height: '50px',
              position: 'absolute',
              right: '-19px',
              bottom: '-12px',
            }}
          />
        )}
      </Box>
      <NameLink
        // @ts-ignore
        component={Link}
        to={navigationLink}
        underline="none"
      >
        <Box width={1} sx={{ '&:hover': { opacity: 0.8 } }}>
          <Box display="flex" component="span" color="text.primary">
            {!isInquiry ? getVenueName() : name}
          </Box>
          {withSubTitle && (
            <Typography color="textSecondary" variant="caption">
              {getRoomsString()}
            </Typography>
          )}
        </Box>
      </NameLink>
    </Box>
  )
}
