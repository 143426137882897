export type LocaleComparatorOptions = {
  numeric?: boolean
  caseFirst?: string
}

export type Comparator<T = any> = (a: T, b: T) => number

export type StringComparator = Comparator<string>

export const createLocaleComparator =
  (locale: string, options: LocaleComparatorOptions): StringComparator =>
  (a: string, b: string): number =>
    a.localeCompare(b, locale, options)

export type DateComparator = Comparator<Date>
export type SortOrder = 'desc' | 'asc'

export const createDateComparator =
  (order: SortOrder): DateComparator =>
  (a: Date, b: Date): number => {
    const date1 = new Date(a)
    const date2 = new Date(b)
    return order === 'asc'
      ? date1.getTime() - date2.getTime()
      : date2.getTime() - date1.getTime()
  }

export const multilevelSort = <T>(
  arrayForSorting: T[],
  comparators: Comparator[],
  keysToCompare: Array<keyof T>
): T[] => {
  if (comparators.length !== keysToCompare.length) {
    throw new Error(
      'The quantity of comparators should be equal the quantity of the keysToCompare'
    )
  }

  return [...arrayForSorting].sort((a: T, b: T) => {
    let compareResult = 0
    let i = 0

    try {
      do {
        compareResult = comparators[i](a[keysToCompare[i]], b[keysToCompare[i]])

        i += 1
      } while (compareResult === 0 && comparators.length > i)
    } catch {
      console.error(`The ${keysToCompare[i]} is not comparable`)
    }

    return compareResult
  })
}
