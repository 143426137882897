import { Box, Typography, useTheme } from '@material-ui/core'
import { FormatListBulleted, Search } from '@material-ui/icons'
import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import CalendarBlack from '../../../components/svg/CalendarBlack'
import Navigation from '../../../components/svg/Navigation'
import NavigationMobile from '../../../components/svg/NavigationMobile'
import Plus from '../../../components/svg/Plus'
import { ActionButton } from '../../../components/ui/Buttons/ActionButton'
import { SimpleButton } from '../../../components/ui/Buttons/SimpleButton'
import { MultiSelectField } from '../../../components/ui/FormComponents/MultiSelectField'
import { ConfirmModal } from '../../../components/ui/Modals/ConfirmModal'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { EventDraftStatus } from '../../../constants'
import {
  CALENDAR_VIEW_BY,
  EventStatus,
  getCalendarUrl,
} from '../../../constants/events'
import { InquiryStatuses } from '../../../constants/inquiry'
import { useConfirmModal } from '../../../hooks/useConfirmModal'
import { usePartner } from '../../../hooks/usePartner'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { LocalEvent } from '../../../types/Event'
import { removeEvent } from '../../../utils/api/events'
import { getModalContent } from '../../../utils/helpers/bookings'
import {
  Comparator,
  createDateComparator,
  createLocaleComparator,
  multilevelSort,
  SortOrder,
} from '../../../utils/helpers/sort'
import { getRequestError } from '../../../utils/helpers/validations'
import { createEventToFormValuesMapper } from '../../../utils/mappers/inquiries'
import { eventPath, inquiryPath, vendorsPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { useMessageModalData } from '../../../utils/providers/MessageModalProvider'
import { CalendarPage } from '../../calendar'
import { CopyUrlComponent } from '../../dashboard/BusinessDetails/components/CopyUrlComponent'
import { SortingSelect } from '../../marketplace/components/SortingSelect'
import { EventListMobileTableView } from '../components/EventListMobileTableView'
import { EventListTable } from '../components/EventListTable'

import { EventListWrapper, StyledTextField } from './EventsList.styles'
import { RenderEventItems } from './RenderEventItems'

export const STATUS_ORDER = Object.values(EventStatus)

export type StatusComparator = Comparator<EventStatus>

export const createStatusComparator =
  (order: SortOrder): StatusComparator =>
  (a, b): number =>
    order === 'asc'
      ? STATUS_ORDER.indexOf(a) - STATUS_ORDER.indexOf(b)
      : STATUS_ORDER.indexOf(b) - STATUS_ORDER.indexOf(a)

export const EventList: React.FC = () => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [listView, setListView] = useState<boolean>(false)
  const [direction, setDirection] = useState<SortOrder>('asc')
  const modalControl = useSimpleModal()
  const { t, i18n } = useTranslation()
  const { isMobile } = useWindowSize()
  const { isAbs } = usePartner()
  const { showMessage } = useMessageModalData()
  const { isOpen, toggleModal, modalData } = modalControl
  const { toggleModal: toggleDuplicateModal } = modalControl
  const {
    isOpen: isConfirmOpen,
    toggleModal: toggleConfirmModal,
    setModal,
    modalData: confirmModalData,
  } = useConfirmModal()
  const {
    events,
    updateQueryParams,
    queryParams,
    createNewEvent,
    setActiveEvent,
    chosenAbsVenue,
    removeEventById,
  } = useStorageEventsData()
  const [sortedEvents, setSortedEvents] = useState<any[]>([])
  const clientCalendars = events
    .filter((event) => event.calendar && event.calendar.google_id)
    .map((event) => event?.calendar?.google_id || '')
  const calendarUrl = getCalendarUrl(clientCalendars[0])
  const [viewBy, setViewBy] = React.useState(CALENDAR_VIEW_BY[0])
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const history = useHistory()
  const { authUser } = useAuthData()

  const localeComparator = createLocaleComparator(i18n.language, {
    numeric: true,
    caseFirst: 'upper',
  })

  const statusComparator = createStatusComparator('desc')
  const dateComparator = createDateComparator(direction)

  const onSort = (field: string) => {
    if (field === 'date') {
      updateQueryParams({ ...queryParams, orderBy: field })
      setSortedEvents(
        multilevelSort<any>(
          events,
          [statusComparator, dateComparator],
          ['status', 'start_date']
        )
      )
    } else if (field !== 'Sort by') {
      updateQueryParams({ ...queryParams, orderBy: field })
      const sortedEvents = multilevelSort<any>(
        events,
        [localeComparator],
        [field]
      )
      setSortedEvents(sortedEvents)
    } else {
      updateQueryParams({ ...queryParams, orderBy: '' })
    }
  }

  const onChangeDirection = (direction: SortOrder) => {
    updateQueryParams({ ...queryParams, orderDirection: direction })
    setDirection(direction)
    if (queryParams.orderBy === 'date') {
      const statusComparator = createStatusComparator(direction)
      setSortedEvents(
        multilevelSort<any>(
          events,
          [statusComparator, dateComparator],
          ['status', 'start_date']
        )
      )
    } else {
      setSortedEvents([...orderBy(events, [queryParams.orderBy], [direction])])
    }
  }

  const toggleShowCalendar = () => {
    setListView(false)
    setShowCalendar(!showCalendar)
  }

  const toggleListView = () => {
    if (showCalendar) {
      setListView(true)
    }
    setShowCalendar(false)
    setListView(!listView)
  }

  const searchByName = (query: string) => {
    if (query.length > 2) {
      setSortedEvents(
        sortedEvents.filter((event) =>
          event.name?.toLowerCase().includes(query.toLocaleLowerCase())
        )
      )
    } else {
      setSortedEvents(events)
    }
  }

  const handleOnEventSelect = (action: string, event: LocalEvent) => {
    const isDraft = event?.status === EventDraftStatus

    switch (action) {
      case 'details':
        history.push(
          generatePath(eventPath, {
            id: event.id,
          })
        )
        break
      case 'delete':
        const message = isDraft
          ? t(
              'messages.events.delete.action',
              'Are you sure you want to delete event?'
            )
          : t(
              'messages.events.delete.request',
              'Are you sure you want to create request for delete your event?'
            )
        setModal({
          title: message,
          onAccept: async () => {
            if (isDraft) {
              return removeEventById(event.id)
            }
            setIsLoading(true)
            try {
              await removeEvent(event.id)
              showMessage({
                title: t(
                  'messages.events.delete.requestSend',
                  'Your request has been successfully sent!'
                ),
              })
            } catch (e) {
              showMessage({
                title: getRequestError(e),
                type: 'error',
              })
            }
            setIsLoading(false)
          },
        })
        break
      case 'edit':
        if (event.id) {
          setActiveEvent(event.id)
          const inquiryFormValues = createEventToFormValuesMapper(event)
          localStorage.setItem(
            'inquiryFormValues',
            JSON.stringify(inquiryFormValues)
          )
          localStorage.setItem('inquiryDetails', JSON.stringify(event))
          if (
            isDraft &&
            ((!isAbs && !event.venue) || (isAbs && !event.vendors?.length))
          ) {
            if (isAbs) history.push(vendorsPath)
            else modalControl.toggleModal({ name: 'selectVenueType' })
          } else history.push(inquiryPath)
        }
        break
      case 'duplicate':
        const inquiryFormValues = createEventToFormValuesMapper(event)
        localStorage.setItem(
          'inquiryFormValues',
          JSON.stringify(inquiryFormValues)
        )
        createNewEvent()
        toggleDuplicateModal({
          name: 'inquiryDuplicate',
          venue: isAbs
            ? chosenAbsVenue
            : {
                ...event.venue,
                mainImage: event.venue?.photo,
                status: InquiryStatuses.STATUS_DRAFT,
              },
          rooms: event.venue?.rooms,
          eventFromId: +event.id,
        })
        break
      default:
        console.log('event', event)
    }
  }

  const createEventHandler = () => {
    if (isAbs && !authUser) history.push('?action=signup')
    else {
      createNewEvent()

      if (isAbs) {
        history.push(vendorsPath)
      } else toggleModal({ name: 'selectVenueType' })
    }
  }

  const handleEventClick = (event: LocalEvent) => {
    const isDraft = event?.status === EventDraftStatus

    setActiveEvent(event?.id)
    if (
      isDraft &&
      ((!isAbs && !event?.venue) || (isAbs && !event?.vendors?.length))
    ) {
      if (!isAbs) modalControl.toggleModal({ name: 'selectVenueType' })
      else history.push(vendorsPath)
    } else {
      history.push(
        generatePath(eventPath, {
          id: event?.id,
        })
      )
    }
  }

  useEffect(() => {
    if (queryParams.orderBy === 'date') {
      setSortedEvents(
        multilevelSort<any>(
          events,
          [statusComparator, dateComparator],
          ['status', 'start_date']
        )
      )
    } else {
      setSortedEvents([
        ...orderBy(events, [queryParams.orderBy], [queryParams.orderDirection]),
      ])
    }
  }, [events.length])

  return (
    <EventListWrapper
      isMobile={Boolean(isMobile)}
      className="container"
      mt={!isMobile ? 10 : 5}
    >
      <Box
        display="flex"
        width={1}
        justifyContent={!isMobile ? 'flex-start' : 'space-between'}
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="flex-start"
        mb={6}
      >
        <Box
          display="flex"
          justifyContent={!isMobile ? 'flex-start' : 'space-between'}
          width={1}
          mb={isMobile ? 3 : 0}
          alignItems={!isMobile ? 'flex-start' : 'center'}
          flexDirection={!isMobile ? 'column' : 'row'}
        >
          <Typography
            variant="h1"
            sx={{
              textTransform: 'uppercase',
              color: (theme) => theme.palette.secondary.dark,
            }}
          >
            {isAbs
              ? t('partners.abs.bookings.my', 'My bookings')
              : t('header.events.my', 'My events')}
          </Typography>
          {!isMobile ? (
            <Typography variant="body2" color="#9396A3">
              {isAbs
                ? t('partners.abs.bookings.manage', 'Manage your bookings')
                : t('events.manageYourEvents', 'Manage your events')}
            </Typography>
          ) : (
            <SimpleButton
              variant="outlined"
              onClick={createEventHandler}
              style={{ marginLeft: '40px' }}
            >
              <Plus fill={`${theme.palette.primary.main}`} />{' '}
              {t('events.createEvent', 'Create new')}
            </SimpleButton>
          )}
        </Box>
        <Box
          display="flex"
          width={1}
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
        >
          <StyledTextField
            variant="standard"
            color="secondary"
            InputProps={{
              className: 'input-text',
              disableUnderline: true,
              startAdornment: <Search />,
            }}
            onChange={(e) => searchByName(e.target.value)}
            sx={{
              mr: isMobile ? 0 : 5,
              width: isMobile ? '100%' : 400,
              mb: isMobile ? 3 : 0,
            }}
            placeholder="Key words"
          />
          {!isMobile ? (
            <>
              <SortingSelect
                sortingFields={['name', 'date']}
                orderBy={queryParams.orderBy}
                orderDirection={queryParams.orderDirection}
                onChangeDirection={onChangeDirection}
                onSort={onSort}
              />
              {listView && !showCalendar ? (
                <ActionButton
                  onClick={toggleListView}
                  icon={<Navigation size={53} scale={1.1} />}
                />
              ) : (
                <ActionButton
                  p={'14.5px'}
                  icon={<FormatListBulleted sx={{ color: '#000' }} />}
                  onClick={toggleListView}
                />
              )}
              {showCalendar ? (
                <ActionButton
                  onClick={toggleShowCalendar}
                  icon={<Navigation size={53} scale={1.1} />}
                />
              ) : (
                <ActionButton
                  p={'16.5px'}
                  onClick={toggleShowCalendar}
                  icon={<CalendarBlack />}
                />
              )}
            </>
          ) : (
            <Box
              display="flex"
              width={1}
              justifyContent="space-between"
              alignItems="center"
            >
              {listView && !showCalendar ? (
                <ActionButton
                  onClick={toggleListView}
                  icon={<NavigationMobile />}
                />
              ) : (
                <ActionButton
                  p={'8px'}
                  sx={{ marginLeft: 0 }}
                  icon={<FormatListBulleted sx={{ color: '#000' }} />}
                  onClick={toggleListView}
                />
              )}
              <SortingSelect
                sortingFields={['name', 'date']}
                orderBy={queryParams.orderBy}
                orderDirection={queryParams.orderDirection}
                onChangeDirection={onChangeDirection}
                onSort={onSort}
              />
            </Box>
          )}
        </Box>
      </Box>
      {showCalendar ? (
        <>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <MultiSelectField
                id="viewBy"
                sx={{ minWidth: 120 }}
                defaultLabel={`View by: ${viewBy}`}
                options={CALENDAR_VIEW_BY}
                onChange={(value: any) => setViewBy(value)}
              />
              <CopyUrlComponent
                id="calendar-link"
                calendarUrl={calendarUrl}
                onCopyCalendarLink={() => {
                  navigator.clipboard.writeText(calendarUrl)
                }}
              />
            </Box>
          </Box>
          <CalendarPage
            viewBy={viewBy}
            calendarGoogleId={clientCalendars}
            withBackButton={false}
          />
        </>
      ) : !listView ? (
        <Box className="event-list-content">
          {
            // @ts-ignore
            React.createElement(RenderEventItems, {
              events: sortedEvents,
              isLoading,
              handleOnEventSelect,
              handleEventClick,
              createEventHandler,
              modalControl,
            })
          }
        </Box>
      ) : !isMobile ? (
        <EventListTable
          events={sortedEvents}
          handleOnEventSelect={handleOnEventSelect}
          createEventHandler={createEventHandler}
          isLoading={isLoading}
        />
      ) : (
        <EventListMobileTableView
          events={sortedEvents}
          isLoading={isLoading}
          handleOnEventSelect={handleOnEventSelect}
          handleEventClick={handleEventClick}
        />
      )}
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl, { isAbs })}
      </SimpleModal>
      <ConfirmModal
        open={isConfirmOpen}
        onClose={toggleConfirmModal}
        type={confirmModalData.type}
        title={confirmModalData.title}
        text={confirmModalData.text}
        onAccept={confirmModalData.onAccept}
      />
    </EventListWrapper>
  )
}
