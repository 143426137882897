const EventsIcon = ({ fill = '#9396A3' }) => {
  return (
    <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.125 20.625c0 .415.335.75.75.75h17.25a.75.75 0 0 0 .75-.75v-9.844H3.125v9.844Zm18-16.313h-3.938v-1.5A.188.188 0 0 0 17 2.626h-1.313a.188.188 0 0 0-.187.188v1.5h-6v-1.5a.188.188 0 0 0-.188-.188H8a.188.188 0 0 0-.188.188v1.5H3.876a.75.75 0 0 0-.75.75v4.125h18.75V5.061a.75.75 0 0 0-.75-.75Z"
        fill={fill}
      />
    </svg>
  )
}

export default EventsIcon
