import { useQuery } from 'react-query'

import { clearObject } from '../helpers/data'
import { globalGuestSearchPath, globalSearchPath } from '../paths'

import { get } from './api'

async function fetchSearchValues(
  name: string = '',
  isAuthorized: boolean
): Promise<any[]> {
  if (name.length < 3) return []
  const options = { name }
  const path = isAuthorized ? globalSearchPath() : globalGuestSearchPath()
  return (await get(path, { ...clearObject(options) })) || []
}

export function useSearchValues(name: string = '', isAuthorized: boolean) {
  return useQuery<any[], Error>(['search', name], () =>
    fetchSearchValues(name, isAuthorized)
  )
}
