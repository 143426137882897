import React from 'react'

import { WizardProvider } from '../../../../../../../components/Wizard'
import { usePartner } from '../../../../../../../hooks/usePartner'
import {
  getInquirySteps,
  InquiryCreationStep,
} from '../../../../../../../types/Inquiry'

import { InquiryAbout } from './InquiryAbout'
import { InquiryBudget } from './InquiryBudget'
import { InquiryDates } from './InquiryDates'
import { JotFormStep } from './JotFormStep'

const getInquiryCreationSteps = (
  isAbs: boolean,
  isDuplicate?: boolean,
  copyQuestionaries?: boolean,
  isEdit?: boolean
): InquiryCreationStep[] => {
  const steps = [
    {
      name: getInquirySteps(isAbs).AdvancedInfo,
      index: 0,
    },
    {
      name: getInquirySteps(isAbs).Dates,
      index: 1,
    },
    {
      name: getInquirySteps(isAbs).Budget,
      index: 2,
    },
  ]
  const absSteps = [
    ...steps,
    {
      name: getInquirySteps(isAbs).JotForm,
      index: 3,
    },
  ]
  // @ts-ignore
  return !isAbs || isEdit || (isDuplicate && copyQuestionaries)
    ? steps
    : absSteps
}

export const StepsConstructor: React.FC<{
  onSubmit: () => void
  isLoading: boolean
  isDuplicate?: boolean
  copyQuestionaries?: boolean
  handleCopyQuestionaries?: (copy: boolean) => void
  currentStepIndex: number
  maxGuests?: number
  setIsLoading?: (isLoading: boolean) => void
  isEdit?: boolean
}> = ({
  onSubmit,
  isLoading,
  isDuplicate,
  copyQuestionaries,
  handleCopyQuestionaries,
  currentStepIndex,
  maxGuests,
  setIsLoading,
  isEdit = false,
}) => {
  const { isAbs } = usePartner()
  const steps = getInquiryCreationSteps(
    !!isAbs,
    isDuplicate,
    copyQuestionaries,
    isEdit
  )
  const [data, setData] = React.useState({})

  const handleNextStep = (values?: any) => {
    setData({ ...data, ...values })
  }

  return (
    <WizardProvider
      onNext={handleNextStep}
      onFinish={onSubmit}
      wizardData={{ data }}
    >
      {steps?.map((step) =>
        renderStep(
          !!isAbs,
          step,
          isLoading,
          currentStepIndex,
          isDuplicate,
          copyQuestionaries,
          handleCopyQuestionaries,
          maxGuests
        )
      )}
    </WizardProvider>
  )
}

const renderStep = (
  isAbs: boolean,
  step: InquiryCreationStep,
  isLoading: boolean,
  currentStepIndex: number,
  isDuplicate?: boolean,
  copyQuestionaries?: boolean,
  handleCopyQuestionaries?: (copy: boolean) => void,
  maxGuests?: number,
  setIsLoading?: (isLoading: boolean) => void
) => {
  switch (step.name) {
    // @ts-ignore
    case getInquirySteps(isAbs).AdvancedInfo:
      return (
        <InquiryAbout
          maxGuests={maxGuests}
          isDuplicate={isDuplicate}
          key={step.index}
          step={step}
          currentStepIndex={currentStepIndex}
          copyQuestionaries={copyQuestionaries}
          handleCopyQuestionaries={handleCopyQuestionaries}
        />
      )
    // @ts-ignore
    case getInquirySteps(isAbs).Dates:
      return (
        <InquiryDates isDuplicate={isDuplicate} key={step.index} step={step} />
      )
    // @ts-ignore
    case getInquirySteps(isAbs).Budget:
      return (
        <InquiryBudget
          isDuplicate={isDuplicate}
          key={step.index}
          step={step}
          isLoading={isLoading}
        />
      )
    // @ts-ignore
    case getInquirySteps(isAbs).JotForm:
      return (
        <JotFormStep
          isDuplicate={isDuplicate}
          key={step.index}
          step={step}
          isLoading={isLoading}
        />
      )

    default:
      return (
        <InquiryAbout
          isDuplicate={isDuplicate}
          key={step.index}
          step={step}
          currentStepIndex={currentStepIndex}
        />
      )
  }
}
