import React, { useMemo } from 'react'

import { BREAKPOINTS } from '../../../constants'
import { usePartner } from '../../../hooks/usePartner'
import { MessageImageItem } from '../../../types/dtos/messages'
import { ImageItem, ImageType } from '../../../types/general'
import { getImageByType } from '../../../utils/helpers/images'

interface ImageProps {
  imageSize: ImageType['size']
  image?: ImageItem | MessageImageItem | string
  imageMobileSize?: ImageType['size']
  staticImageType?: ImageType['static']
  vendorCategory?: string
  alt?: string
  style?: React.CSSProperties
  onClick?: () => void
}

export const Image: React.FC<ImageProps> = ({
  image,
  imageSize,
  imageMobileSize,
  staticImageType,
  vendorCategory,
  alt,
  style,
  onClick,
}) => {
  const { isAbs } = usePartner()

  const imageUrls = useMemo(() => {
    return {
      mobileWebpUrl: imageMobileSize
        ? getImageByType(
            image,
            `webp-${imageMobileSize}`,
            staticImageType,
            vendorCategory,
            !!isAbs
          )
        : '',
      mobileUrl: imageMobileSize
        ? getImageByType(
            image,
            imageMobileSize,
            staticImageType,
            vendorCategory,
            !!isAbs
          )
        : '',
      mobileRetinaWebpUrl: imageMobileSize
        ? getImageByType(
            image,
            `webp-${imageMobileSize}-retina`,
            staticImageType,
            vendorCategory,
            !!isAbs
          )
        : '',
      mobileRetinaUrl: imageMobileSize
        ? getImageByType(
            image,
            `${imageMobileSize}-retina`,
            staticImageType,
            vendorCategory,
            !!isAbs
          )
        : '',
      imageWebpUrl: getImageByType(
        image,
        `webp-${imageSize}`,
        staticImageType,
        vendorCategory,
        !!isAbs
      ),
      imageUrl: getImageByType(
        image,
        imageSize,
        staticImageType,
        vendorCategory,
        !!isAbs
      ),
      imageRetinaWebpUrl: getImageByType(
        image,
        `webp-${imageSize}-retina`,
        staticImageType,
        vendorCategory,
        !!isAbs
      ),
      imageRetinaUrl: getImageByType(
        image,
        `${imageSize}-retina`,
        staticImageType,
        vendorCategory,
        !!isAbs
      ),
    }
  }, [image, isAbs])

  return (
    <picture onClick={onClick} className="image-responsive" style={style}>
      {!!imageUrls?.mobileWebpUrl && (
        <source
          type="image/webp"
          srcSet={`${imageUrls?.mobileWebpUrl} 1x, ${imageUrls?.mobileRetinaWebpUrl} 2x`}
          media={`(max-width:${BREAKPOINTS.SMALL}px)`}
        />
      )}
      {!!imageUrls?.mobileUrl && (
        <source
          srcSet={`${imageUrls?.mobileUrl} 1x, ${imageUrls?.mobileRetinaUrl} 2x`}
          media={`(max-width:${BREAKPOINTS.SMALL}px)`}
        />
      )}
      {!!imageUrls?.imageWebpUrl && (
        <source
          type="image/webp"
          srcSet={`${imageUrls?.imageWebpUrl} 1x, ${imageUrls?.imageRetinaWebpUrl} 2x`}
        />
      )}
      <source
        srcSet={`${imageUrls?.imageUrl} 1x, ${imageUrls?.imageRetinaUrl} 2x`}
      />
      <img
        srcSet={`${imageUrls?.imageUrl} 1x, ${imageUrls?.imageRetinaUrl} 2x`}
        className="image-responsive"
        style={style}
        alt={alt ? alt : ''}
      />
    </picture>
  )
}
