import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useContractsByStatuses } from '../../../hooks/useContractsByStatus'
import { InquiryContractResponse } from '../../../types/dtos/inquiry'
import GradientButton from '../../ui/Buttons/GradientButton'

import { ContractDocument } from './ContractDocument'

export const ContractsDocumentsSection: React.FC<{
  contracts: InquiryContractResponse[]
}> = ({ contracts }) => {
  const { t } = useTranslation()
  const { notOutdatedContract, outdatedContracts } =
    useContractsByStatuses(contracts)
  const [showAll, setShowAll] = useState(false)

  return (
    <Box>
      <Typography>
        {t('inquiry.documents.contracts.section', 'Contracts')}
      </Typography>
      {!!notOutdatedContract && (
        <Box mt={4}>
          <Typography variant="body2" mb={2}>
            {t('inquiry.contracts.active', 'Active')}
          </Typography>
          <ContractDocument contract={notOutdatedContract} />
        </Box>
      )}
      {!!outdatedContracts?.length && (
        <>
          <Box display="flex" justifyContent="flex-end" mb={5}>
            <GradientButton onClick={() => setShowAll(!showAll)}>
              {showAll
                ? t('inquiry.versions.previous.hide', 'Hide old versions')
                : t('inquiry.versions.previous.show', 'Show old versions')}
            </GradientButton>
          </Box>
          {showAll && (
            <>
              <Typography variant="body2" mb={2}>
                {t('inquiry.contracts.outdated', 'Outdated')}
              </Typography>
              {outdatedContracts.map((contract) => (
                <ContractDocument key={contract.id} contract={contract} />
              ))}
            </>
          )}
        </>
      )}
    </Box>
  )
}
