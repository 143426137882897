import { useEffect, useState } from 'react'

import { VendorType, VendorTypesQueryOptions } from '../types/Vendor'
import { getVendorTypesWithVendors, useVendorTypes } from '../utils/api/vendors'

export const useVendorTypesWithVendors = (
  queryParams: VendorTypesQueryOptions = {}
): { isLoading: boolean; filteredTypes: VendorType[] } => {
  const { isLoading, data: vendorTypes } = getVendorTypesWithVendors({
    ...queryParams,
  })
  const [filteredTypes, setFilteredTypes] = useState<VendorType[]>([])

  useEffect(() => {
    if (vendorTypes && vendorTypes.length) {
      setFilteredTypes(
        vendorTypes?.filter(
          (category: VendorType) => !!category.vendors_count
        ) || []
      )
    }
  }, [vendorTypes?.length])

  return { isLoading, filteredTypes }
}
