import * as React from 'react'

import { useLikes } from '../../hooks/useLikes'
import { EventVenue } from '../../types/Event'
import { Vendor } from '../../types/Vendor'

export const LikesDataContext = React.createContext<{
  likedVendors: Array<Vendor>
  likedVenues: Array<EventVenue>
  toggleVendorLike: (venue: Vendor) => void
  toggleVenueLike: (venue: EventVenue) => void
}>({
  likedVendors: [],
  likedVenues: [],
  toggleVendorLike: () => {},
  toggleVenueLike: () => {},
})

export const LikesDataProvider: React.FC = ({ children }) => {
  const { likedVendors, likedVenues, toggleVendorLike, toggleVenueLike } =
    useLikes()
  const context = React.useMemo(
    () => ({
      likedVendors,
      likedVenues,
      toggleVendorLike,
      toggleVenueLike,
    }),
    [likedVendors, likedVenues, toggleVendorLike, toggleVenueLike]
  )

  return (
    <LikesDataContext.Provider value={context}>
      {children}
    </LikesDataContext.Provider>
  )
}

export const useLikesData = () => {
  const context = React.useContext(LikesDataContext)
  if (context === undefined) {
    throw new Error('useLikesData must be used within an VenuesDataProvider')
  }
  return context
}
