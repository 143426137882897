import { Box, Link, styled, Typography } from '@material-ui/core'
import { StarRounded } from '@material-ui/icons'
import React from 'react'
import { Trans } from 'react-i18next'

import LinkText from '../../../../components/ui/Buttons/LinkText/LinkText'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { ExclusiveVendorsTextWrapper } from '../Venue.styles'

const IconBox = styled(Box)`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: linear-gradient(180deg, #4e4bec 0%, #3f91f0 100%), #4091f0;
  margin-right: 16px;
`

export const ExclusiveVendorsText: React.FC<{
  openExclusiveInfo: (open: boolean, title: string, subtitle: string) => void
  title: string
  subtitle: string
  type: string
  textAttached: string
  textAction: string
}> = ({
  openExclusiveInfo,
  title,
  subtitle,
  type,
  textAttached,
  textAction,
}) => {
  const { isMobile } = useWindowSize()

  return (
    <Box display="flex" justifyContent="center">
      <Box>
        <ExclusiveVendorsTextWrapper
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <IconBox mb={isMobile ? '8px' : 0}>
            <StarRounded style={{ fontSize: 18, color: '#FFD25A' }} />
          </IconBox>
          <Trans i18nKey="vendor.inVenue">
            <Typography fontSize="14px" lineHeight="24px" letterSpacing="0">
              The venue has{' '}
              <Link
                sx={{ textTransform: 'lowercase', '&:hover': { opacity: 0.8 } }}
                underline="none"
                href={`#${type}`}
              >
                {{ title }}
              </Link>
              . {{ textAttached }}{' '}
              <LinkText
                sx={{ display: 'inline-block', fontWeight: 400 }}
                onClick={() => openExclusiveInfo(true, title, subtitle)}
              >
                <Trans i18nKey="common.links.click">click here</Trans>
              </LinkText>{' '}
              {{ textAction }}
            </Typography>
          </Trans>
        </ExclusiveVendorsTextWrapper>
      </Box>
    </Box>
  )
}
