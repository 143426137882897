import { isBefore as isAfter, isEqual, isPast } from 'date-fns'
import { isNil, sumBy } from 'lodash/fp'
import { Room, VenueDetails } from 'types/Venue'
import { t } from 'utils/i18n'
import z, { ZodIssueCode } from 'zod'

import { BUDGETS } from './constants'

const MAX_NAME_LENGTH = 45
const MAX_NOTES_LENGTH = 1024
const DEFAULT_MAX_GUESTS = 32767

const mapError =
  (map: Partial<Record<z.ZodIssueCode, string>>): z.ZodErrorMap =>
  (issue, ctx) => {
    const message = map[issue.code]
    return !isNil(message) ? { message } : { message: ctx.defaultError }
  }

const roomCapacity = (rooms: Room[]) => sumBy('capacity', rooms)
export const venueCapacity = (venue: VenueDetails, rooms?: Room[]) =>
  (rooms?.length ? roomCapacity(rooms) : venue.aboutInfo?.maxGuests) ||
  DEFAULT_MAX_GUESTS

const futureDate = z
  .date()
  .refine((date) => !isPast(date), t('inquiry.form.errors.dates.future'))

function startBeforeEnd(start: Date, end: Date): boolean {
  return isEqual(start, end) || isAfter(start, end)
}

const DatesSchema = z
  .object({
    start: futureDate.nullable(),
    end: futureDate.nullable(),
    flexible: z.boolean(),
  })
  .refine(
    (dates): dates is { flexible: boolean; start: Date; end: Date } =>
      isNil(dates.start) === isNil(dates.end),
    t('inquiry.form.errors.dates.bothRequired')
  )
  .refine(({ start, end }) => {
    if (isNil(start) || isNil(end)) return true
    return startBeforeEnd(start, end)
  }, t('inquiry.form.errors.dates.sequential'))

export const VenueInquirySchema = (venue: VenueDetails, rooms?: Room[]) => {
  const capacity = venueCapacity(venue, rooms)

  return z.object({
    name: z
      .string({
        required_error: '',
        errorMap: mapError({
          [ZodIssueCode.too_small]: '',
        }),
      })
      .min(1)
      .max(
        MAX_NAME_LENGTH,
        t('inquiry.form.errors.name.maxLength', { maxLength: MAX_NAME_LENGTH })
      ),
    guests: z
      .number({
        coerce: true,
        required_error: '',
        errorMap: mapError({
          [ZodIssueCode.too_small]: '',
          [ZodIssueCode.invalid_type]: t('inquiry.form.errors.guests.type'),
        }),
      })
      .min(1)
      .max(capacity, t('inquiry.form.errors.guests.max', { max: capacity })),
    budget: z.enum(BUDGETS, {
      errorMap: mapError({
        [ZodIssueCode.invalid_enum_value]: '',
      }),
    }),
    dates: DatesSchema,
    notes: z
      .string()
      .min(20, t('inquiry.form.errors.notes.min', { min: 20 }))
      .max(MAX_NOTES_LENGTH),
  })
}

export type VenueInquiryFormValues = z.infer<
  ReturnType<typeof VenueInquirySchema>
>
