import { Box } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const InquiryWrapper = styled(Box)`
  .page-title {
    line-height: 84px;
    font-weight: 600;
  }

  .page-subtitle {
    font-weight: 300;
    color: #9396a3;
  }

  .booking-top {
    display: flex;

    .inquiry-data {
      display: flex;
      flex-direction: column;
      font-family: Poppins;
      width: 100%;

      .addons {
        display: flex;
        flex-direction: column;
      }

      .addon {
        padding: 24px 32px;
        padding-left: 0;

        .MuiSvgIcon-root {
          font-size: 32px;
        }
      }
    }

    .message-icon-button {
      position: absolute;
      right: -70px;
      height: 40px;
      color: white;
      background: black;
    }

    .inquiry-params {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 5px;

      ${MEDIA_RULES.SMALL} {
        display: block;
      }

      .fields-container {
        margin-bottom: 60px;

        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
      }
    }
  }

  .booking-bottom {
    padding: 40px 100px 60px 100px;
  }
`
export const StyledListItem = styled(Box)`
  margin: 5px;
  padding: 25px;
  box-shadow: 0 4px 18px rgba(210, 210, 210, 0.4);
  border-radius: 2px;

  ${MEDIA_RULES.SMALL} {
    min-height: auto;
    box-shadow: none;
    padding: 32px 0;
    border-bottom: 1px solid rgba(132, 132, 132, 0.12);
  }
`

export const DatesStepContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const EditContainer = styled(Box)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
