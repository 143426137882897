import { Table, TableBody } from '@material-ui/core'
import React, { useState } from 'react'

import { CollapsibleDocumentRow } from '../../../../../../components/ui/CollapsibleDocumentRow'
import { Loader } from '../../../../../../components/ui/Loader'
import {
  InquiryInvoiceStatuses,
  InquiryStatuses,
} from '../../../../../../constants/inquiry'
import { useDownloadInvoice } from '../../../../../../hooks/useDownloadInvoice'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import {
  InquiryInvoiceResponse,
  Invoice,
} from '../../../../../../types/dtos/inquiry'
import { InquiryWithDetails } from '../../../../../../types/Inquiry'
import { StyledTableRow } from '../../../ServiceBookingDetails.styles'

import { InvoiceActions } from './InvoiceActions'
import { InvoicesTableHeader } from './InvoicesTableHeader'
import { InvoicesTableRow } from './InvoicesTableRow'

interface InvoicesProps {
  inquiryDetails?: InquiryWithDetails
  notOutdatedInvoices: Invoice[]
  outdatedInvoices: Invoice[]
  openMessengerHandler: () => void
  viewAndPay: (invoiceBundle: InquiryInvoiceResponse, invoice: Invoice) => void
}

export const InvoicesTable: React.FC<InvoicesProps> = ({
  inquiryDetails,
  notOutdatedInvoices,
  outdatedInvoices,
  openMessengerHandler,
  viewAndPay,
}) => {
  const { handleDownloadInvoice, isLoading } = useDownloadInvoice()
  const { isMobile } = useWindowSize()

  const isActionButtonsVisible = (invoice: Invoice) => {
    const invoicesFromTheSameBundle = notOutdatedInvoices.filter(
      (item) => item.invoice_bundle_id === invoice.invoice_bundle_id
    )
    const paidItem = invoicesFromTheSameBundle.find(
      (item) =>
        item.type === 'initial' &&
        item.status === InquiryInvoiceStatuses.STATUS_PAID
    )
    return paidItem || invoicesFromTheSameBundle.length === 1
      ? invoice.type === 'final' &&
          invoice.status === InquiryInvoiceStatuses.STATUS_PENDING
      : invoice.type === 'initial' &&
          invoice.status === InquiryInvoiceStatuses.STATUS_PENDING &&
          (inquiryDetails?.status === InquiryStatuses.STATUS_CONFIRMED ||
            inquiryDetails?.status === InquiryStatuses.STATUS_PRODUCTION)
  }

  const getCurrentInvoiceBundle = (invoice: Invoice) =>
    inquiryDetails?.invoice_bundles.find(
      (bundle) => bundle.id === invoice.invoice_bundle_id
    ) || ({} as InquiryInvoiceResponse)

  return (
    <>
      <Table>
        <InvoicesTableHeader />
        <TableBody>
          {notOutdatedInvoices.length &&
            notOutdatedInvoices.map((invoice: Invoice, index) => (
              <>
                <InvoicesTableRow
                  key={invoice.id}
                  index={index}
                  invoice={invoice}
                  onDownload={handleDownloadInvoice}
                />
                {isActionButtonsVisible(invoice) && (
                  <StyledTableRow>
                    <InvoiceActions
                      openMessengerHandler={openMessengerHandler}
                      viewAndPay={() =>
                        viewAndPay(getCurrentInvoiceBundle(invoice), invoice)
                      }
                    />
                  </StyledTableRow>
                )}
              </>
            ))}
          {!!outdatedInvoices.length && (
            <CollapsibleDocumentRow colSpan={isMobile ? 2 : 5}>
              {outdatedInvoices.map((invoice, index) => (
                <InvoicesTableRow
                  key={invoice.id}
                  index={index}
                  invoice={invoice}
                  onDownload={handleDownloadInvoice}
                />
              ))}
            </CollapsibleDocumentRow>
          )}
        </TableBody>
      </Table>
      {isLoading && <Loader position="fixed" />}
    </>
  )
}
