import { css } from '@emotion/react'

export const spaceX = (value: string) => css`
  & > * + * {
    margin-left: ${value};
  }
`

export const spaceY = (value: string) => css`
  & > * + * {
    margin-top: ${value};
  }
`
