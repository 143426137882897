import { List, ListItem, ListItemText, styled } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { Loader } from '../../components/ui/Loader'
import { ConfirmDialog } from '../../components/ui/Modals/ConfirmDialog'
import { dashboardProfilePath, profilePath } from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { LsGetLastPath, LsRemoveLastPath } from '../../utils/storage'

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    line-height: 28px;
    text-align: center;
  }
`

export const SuccessAuth = () => {
  const history = useHistory()
  const { isLoading, authUser } = useAuthData()
  const savedPath = LsGetLastPath()
  const { t } = useTranslation()
  const isBusinessUser = !isLoading && !!authUser?.role

  const onClose = () => {
    if (savedPath) {
      LsRemoveLastPath()
      return history.push(savedPath)
    }
    return history.push(isBusinessUser ? dashboardProfilePath : profilePath)
  }

  return (
    <ConfirmDialog
      confirmLabel={savedPath ? 'Go back' : 'Go to profile'}
      dialogTitle={'Successfully logged in'}
      cancelLabel={'label'}
      onClose={onClose}
      open={true}
    >
      <List>
        <ListItem disableGutters>
          <StyledListItemText
            primary={t(
              'messages.signIn.success',
              'You have been successfully sign in system. Now you can go to account profile to update info and set password'
            )}
          />
        </ListItem>
      </List>
      {isLoading && <Loader position="absolute" />}
    </ConfirmDialog>
  )
}
