import { Box } from '@material-ui/core'
import { groupBy, values } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { EventServiceProps } from '../../../../../../types/Event'
import { InquiryWithDetails } from '../../../../../../types/Inquiry'
import { TabsTypography } from '../../../ServiceBookingDetails.styles'

import { JotFormInformation } from './JotFormInformation'

export const AdditionalInfo: React.FC<{
  inquiryDetails?: InquiryWithDetails
  event: EventServiceProps
  jotForms: any[]
}> = ({ inquiryDetails, event, jotForms }) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  return (
    <Box display="flex" flexDirection="column" mx={4}>
      {!isMobile && (
        <Box display="flex" alignItems="flex-start" mb={4}>
          <TabsTypography>
            {t('inquiry.additionalInfo.title', 'Interview information')}
          </TabsTypography>
        </Box>
      )}
      <JotFormInformation
        event={event}
        jotForms={values(groupBy(jotForms, 'id'))}
      />
    </Box>
  )
}
