import { Box, styled } from '@material-ui/core'

export const TimePickerContainer = styled(Box)`
  .MuiFormControl-root {
    width: 48px;
    flex-shrink: 0;
  }

  .MuiMenuItem-root {
    min-height: auto;
  }

  fieldset {
    top: 0px;
  }

  .ampm {
    width: 80px;

    .MuiFormControl-root {
      width: 80px;
    }

    input {
      -webkit-text-fill-color: black;
    }

    &.gray input {
      -webkit-text-fill-color: #9396a3;
    }
  }

  input {
    &.MuiOutlinedInput-input {
      font-size: 16px;
      line-height: 28px;
      padding: 0;
      height: 40px;
      text-align: center;
    }
  }
`
