import { Box, Typography } from '@material-ui/core'
import * as React from 'react'

interface TabSubtitleProps {
  subtitle: string
}

export const TabSubtitle: React.FC<TabSubtitleProps> = ({ subtitle }) => {
  return (
    <Box mb={3}>
      <Typography variant="h2" mb={0} fontFamily="Poppins" fontWeight="500">
        {subtitle}
      </Typography>
    </Box>
  )
}
