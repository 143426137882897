import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import CompleteSmall from '../../components/svg/CompleteSmall'
import { ConfirmDialog } from '../../components/ui/Modals/ConfirmDialog'

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    line-height: 28px;
  }
`

export const SuccessDeactivate = () => {
  const { t, i18n } = useTranslation()

  return (
    <ConfirmDialog
      confirmLabel={'Close'}
      dialogTitle={t(
        'messages.account.deactivated.title',
        'Account deactivated'
      )}
      cancelLabel={'label'}
      onClose={() => console.log('close')}
      open={true}
    >
      <List>
        <ListItem disableGutters>
          <ListItemIcon>
            <CompleteSmall />
          </ListItemIcon>
          <StyledListItemText
            primary={t(
              'messages.account.deactivated.subtitle',
              'Your profile and listings are no longer visible.'
            )}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon>
            <CompleteSmall />
          </ListItemIcon>
          <StyledListItemText
            primary={t(
              'messages.account.deactivated.description',
              'You won’t be able to access this account or reservations associated with it.'
            )}
          />
        </ListItem>
      </List>
    </ConfirmDialog>
  )
}
