import { throttle } from 'lodash'
import { useEffect, useState } from 'react'

export const SCROLL_UP = 'up'
export const SCROLL_DOWN = 'down'

declare global {
  interface Window {
    myScrollTop: any
  }
}

const useScrollDirection = () => {
  const [scrollDir, setScrollDir] = useState(SCROLL_UP)
  const [scrollTop, setScrollTop] = useState(0)

  const onScroll = () => {
    const newScrollTop = Math.round(window.pageYOffset)
    setScrollDir(
      window.pageYOffset > window.myScrollTop ? SCROLL_DOWN : SCROLL_UP
    )
    setScrollTop(newScrollTop)
    window.myScrollTop = newScrollTop
  }

  useEffect(() => {
    window.myScrollTop = 0
    const throttled = throttle(onScroll, 100, {
      leading: false,
      trailing: true,
    })
    window.addEventListener('scroll', throttled)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return { scrollDir, scrollTop }
}

export default useScrollDirection
