import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePartner } from '../../../../hooks/usePartner'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { usePromotion } from '../../../../utils/api/admin'
import PromotionStar from '../../../svg/PromotionStar'

import { StyledPromotionLabel } from './PromotionLabel.styles'

export const PromotionLabel: React.FC<{ size?: 'small' | 'default' }> = ({
  size = 'default',
}) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const { isAbs } = usePartner()
  const { data } = usePromotion({ enabled: !isAbs && isAbs !== undefined })

  if (!data?.special_offers_switcher && !isAbs) return <></>

  return (
    <StyledPromotionLabel
      minWidth={size === 'default' ? (isAbs ? '190px' : '154px') : '134px'}
      p={size === 'default' ? '12px 18.5px' : '8px 12px'}
    >
      <PromotionStar
        size={isMobile || size === 'small' ? 20 : 24}
        scale={isMobile || size === 'small' ? 0.85 : 1}
      />
      <Typography
        mt="2px"
        ml={isMobile ? '4px' : '12px'}
        variant="body2"
        fontSize={size === 'default' ? '14px' : '12px'}
        fontWeight={size === 'default' ? 700 : 500}
        color={(theme) => theme.palette.common.promotionColor}
      >
        {isAbs
          ? t('partners.abs.advantage.title', 'ABS Advantage')
          : t('promotions.label', 'Special offer')}
      </Typography>
    </StyledPromotionLabel>
  )
}
