import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'

import JotformEmbed from '../../../../../../../components/ReactJotFormEmbed/ReactJotFormEmbed'
import { SimpleModal } from '../../../../../../../components/ui/Modals/SimpleModal'
import { useWizard } from '../../../../../../../components/Wizard'
import { useJotFormInitializer } from '../../../../../../../hooks/useJotFormInitializer'
import { useSimpleModal } from '../../../../../../../hooks/useSimpleModal'
import { useWindowSize } from '../../../../../../../hooks/useWindowSize'
import { InquiryCreationStep } from '../../../../../../../types/Inquiry'
import { getABSJotFormIdByCategory } from '../../../../../../../utils/helpers/events'
import { eventPath } from '../../../../../../../utils/paths'
import { useStorageEventsData } from '../../../../../../../utils/providers/EventsProvider'
import { LsSetJotFormValues } from '../../../../../../../utils/storage'

import { WizardStep } from './WizardStep'

const { REACT_APP_ENV: blaceenv } = process.env

export const JotFormStep: React.FC<{
  step: InquiryCreationStep
  isLoading: boolean
  isDuplicate?: boolean
}> = ({ step, isLoading, isDuplicate }) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const { wizardData, onFinish } = useWizard()
  const history = useHistory()
  const { JF } = useJotFormInitializer()
  const { getUserActiveEvent, updateUserActiveEvent } = useStorageEventsData()
  const { toggleModal: toggleThankYouModal, isOpen: isThankYouModalOpen } =
    useSimpleModal()
  const [open, setOpen] = useState<boolean>(true)
  const [jotFormVisibility, setJotFormVisibility] = useState<string>('visible')
  const activeEvent = getUserActiveEvent()
  const eventVendor = activeEvent?.vendors
    ? activeEvent?.vendors[0]
    : ({} as any)
  // @ts-ignore
  const jotFormId = getABSJotFormIdByCategory(eventVendor?.type || '')

  useEffect(() => {
    const listener = (evt: any) => {
      if (
        evt.origin.includes('submit.jotform') &&
        evt.data.formID == jotFormId &&
        evt.data.action === 'submission-completed'
      ) {
        if (JF.getFormSubmissions && eventVendor && Boolean(activeEvent?.id)) {
          JF.getFormSubmissions(
            jotFormId,
            { vendorTypeId: eventVendor?.type },
            (response: any) => {
              if (response[0]) {
                const updatedUserEventVendors = activeEvent?.vendors?.map(
                  (vendor) => {
                    if (vendor.type === eventVendor?.type) {
                      vendor.withSubmittedJotForm = true
                    }
                    return vendor
                  }
                )
                updateUserActiveEvent({
                  ...activeEvent,
                  withSubmittedJotForm: true,
                  vendors: updatedUserEventVendors,
                })
                LsSetJotFormValues({
                  ...response[0],
                  vendorTypeId: eventVendor?.type,
                  jotFormId,
                })
              }
            }
          )
          setJotFormVisibility('hidden')
          toggleThankYouModal()
          onFinish()
        }
      }
    }
    window.addEventListener('message', listener)
    return () => window.removeEventListener('message', listener)
  })

  return eventVendor ? (
    <WizardStep
      isLoading={isLoading}
      title={isDuplicate ? '' : step.name}
      index={step.index}
      buttonDisabled={false}
      stepData={wizardData}
    >
      <SimpleModal
        minWidth={isMobile ? 'unset' : 730}
        maxWidth="inherit"
        open={isThankYouModalOpen}
      >
        <Box
          display="flex"
          style={{
            backgroundColor: 'rgba(132, 132, 132, 0.12)',
            margin: '10px',
          }}
          justifyContent="center"
          flexDirection="column"
          width={isMobile ? 'unset' : 730}
          height={isMobile ? '100%' : 300}
        >
          <Box width={1} display="flex" justifyContent="center" mb={5}>
            <Typography variant="h1">
              {t('questionnaire.thankYouTitle', 'Thank you!')}
            </Typography>
          </Box>
          <Typography style={{ textAlign: 'center' }} mx={5}>
            {t(
              'questionnaire.thankYouDescription',
              'Success! Your inquiry has been submitted. We may need to contact you for further information before creating your proposal, so please keep an eye out for a message from us.\n'
            )}
          </Typography>
        </Box>
      </SimpleModal>
      {/*@ts-ignore*/}
      <SimpleModal
        visibility={jotFormVisibility as any}
        minWidth={isMobile ? 'unset' : 730}
        maxWidth="inherit"
        open={open}
      >
        {/*@ts-ignore*/}
        <JotformEmbed
          id={eventVendor?.type}
          src={`https://form.jotformeu.com/${jotFormId}?blaceeventid=${activeEvent?.id}&blaceenv=${blaceenv}`}
        />
      </SimpleModal>
    </WizardStep>
  ) : null
}
