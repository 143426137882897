import { Box, IconButton } from '@material-ui/core'
import GoogleMapReact from 'google-map-react'
import { ShowVenuesInThisAreaButton } from 'pages/marketplace/components/MapSearch/ShowVenuesInThisAreaButton'
import VenuesSlider from 'pages/venues/components/VenuesSlider/VenuesSlider'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import BackIcon from '../../components/svg/mobileMenu/Back'
import Filter from '../../components/svg/venues/Filter'
import { SimpleModal } from '../../components/ui/Modals/SimpleModal'
import { INITIAL_SEARCH_PARAMS } from '../../constants'
import { useSimpleModal } from '../../hooks/useSimpleModal'
import { useUrlParams } from '../../hooks/useUrlParams'
import { VenueLocation } from '../../types/Venue'
import { useVenues } from '../../utils/api/venues'
import { getQueryFromMoreFilters } from '../../utils/helpers/venue'
import {
  venueListQueryParams,
  venueListQueryParamsMapper,
} from '../../utils/mappers/venueListQueryParamsMapper'
import { marketplacePath, mobileMapPath } from '../../utils/paths'
import { mapStyles } from '../marketplace/components/MapSearch/mapStyles'
import Marker from '../marketplace/components/MapSearch/Marker'
import { MoreFiltersModal } from '../marketplace/components/VenuesFilter/MoreFilters'

const formatLocationPoint = (lat: number, lng: number) => {
  return `${lat},${lng}`
}

const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || ''

export const MobileMap: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { urlParams } = useUrlParams()
  const { toggleModal, isOpen } = useSimpleModal()

  const [queryParams, updateQueryParams] = useState<
    Partial<venueListQueryParams>
  >({
    ...INITIAL_SEARCH_PARAMS,
    ...urlParams,
  })

  const { data } = useVenues(venueListQueryParamsMapper(queryParams))
  const venues = data?.data || []
  const [map, setMap] = React.useState(null)

  const defaultCenter = {
      lat: 40.66545,
      lng: -74.0543,
    },
    defaultZoom = 11

  const doFetch = function (map: any) {
    if (map) {
      const ne = map.getBounds().getNorthEast()
      // @ts-ignore
      const sw = map.getBounds().getSouthWest()
      const params = {
        ...queryParams,
        zoom: map.getZoom(),
        perPage: 50,
        location: formatLocationPoint(
          map.getCenter().lat(),
          map.getCenter().lng()
        ),
      }
      updateQueryParams(params)
    }
  }

  const showResultsByMoreFilters = (moreFilterFormValues: any) => {
    const {
      labels,
      ceiling_height_min,
      ceiling_height_max,
      sq_footage_min,
      sq_footage_max,
    } = getQueryFromMoreFilters(moreFilterFormValues)
    const labelsQueryString = labels.join(',')
    updateQueryParams({
      ...queryParams,
      page: 1,
      labels: labelsQueryString,
      ceiling_height_min,
      ceiling_height_max,
      sq_footage_min,
      sq_footage_max,
    })
    toggleModal()
  }
  // @ts-ignore
  const onMapLoad = ({ map }) => {
    // @ts-ignore
    setMap(map)
  }

  const onRefreshVenues = () => {
    doFetch(map)
  }

  const goBack = () => {
    const queryParamsStr = venueListQueryParamsMapper(queryParams)
    history.push({
      pathname: marketplacePath,
      search: new URLSearchParams(queryParamsStr).toString(),
    })
  }

  const clearFilter = () => {
    updateQueryParams({
      ...queryParams,
      labels: '',
      page: 1,
      ceiling_height_min: '',
      ceiling_height_max: '',
      sq_footage_min: '',
      sq_footage_max: '',
    })
  }

  useEffect(() => {
    const queryParamsStr = venueListQueryParamsMapper(queryParams)
    history.push({
      pathname: mobileMapPath,
      search: new URLSearchParams(queryParamsStr).toString(),
    })
  }, [queryParams])

  return (
    <Box width="100%" height="100%">
      <Box
        position="absolute"
        zIndex={100}
        width="100%"
        padding="15px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <IconButton onClick={goBack} sx={{ padding: 0, margin: 0, height: 40 }}>
          <BackIcon />
        </IconButton>
        <Box margin={0}>
          <ShowVenuesInThisAreaButton onRefreshVenues={onRefreshVenues} />
        </Box>
        <Box
          onClick={toggleModal}
          sx={{
            cursor: 'pointer',
            '&:hover': { opacity: 0.8 },
          }}
        >
          <Filter />
        </Box>
      </Box>
      <SimpleModal
        minWidth="100%"
        height="100%"
        maxWidth="inherit"
        open={isOpen}
        onClose={toggleModal}
      >
        <MoreFiltersModal
          onClear={clearFilter}
          initialValues={queryParams}
          showResults={showResultsByMoreFilters}
          onCancel={toggleModal}
        />
      </SimpleModal>
      <GoogleMapReact
        options={{
          styles: mapStyles,
          fullscreenControl: false,
          disableDefaultUI: true,
          gestureHandling: 'greedy',
        }}
        yesIWantToUseGoogleMapApiInternals={true}
        bootstrapURLKeys={{ key: googleMapsKey }}
        defaultCenter={defaultCenter}
        onGoogleApiLoaded={onMapLoad}
        defaultZoom={defaultZoom}
      >
        {venues.map((item: VenueLocation, index: number) => {
          return (
            item.location_address && (
              // @ts-ignore
              <Marker
                id={item.id}
                markerInfo={item}
                key={index}
                lat={item.location_address.lat}
                lng={item.location_address.lng}
              />
            )
          )
        })}
      </GoogleMapReact>
      <Box
        width={'100%'}
        style={{ position: 'absolute', zIndex: 100, bottom: 0 }}
      >
        <VenuesSlider
          slideWidth={308}
          slideImageHeight={120}
          withoutEndFrame={true}
          flexItems={true}
        />
      </Box>
    </Box>
  )
}
