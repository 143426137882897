import { Box, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const LoginServiceContainer = styled(Box)(
  ({ theme }) => `
    cursor: pointer;
    padding: 16px;
    background: ${theme.palette.background.paper};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    
    .MuiSvgIcon-root {
        color: #20202C; 
        font-size: 28px; 
        margin-right: 10px;
    }
`
)

export const InputsWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 400px;

  ${MEDIA_RULES.SMALL} {
    min-width: auto;
  }

  .phone-input {
    padding: 12px;
    display: flex;
    flex: 1;
    margin-top: 5px;
    font-size: 16px;
    line-height: 28px;
    border: 1px solid #d7d7d7;
  }

  input::placeholder {
    color: #bdbdbd;
    opacity: 1;
  }
`

export const TabsContainer = styled(Box)`
  .tabs .MuiTabs-scroller,
  .tabs .MuiTabs-scroller .MuiTabs-flexContainer,
  .tabs .MuiTabs-scroller .MuiTabs-flexContainer .tab-item {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .tabs .tab-item {
    font-size: 14px;
  }

  .tabs .tab-item.Mui-selected {
    color: #2f54eb;
  }

  .tabs .MuiTabs-indicator {
    background-color: #2f54eb;
  }
`

export const PhoneInputWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }

  .MuiFormControl-root .MuiSelect-select {
    padding: 12px 14px;
  }

  .phone-input {
    padding: 12px;
    display: flex;
    flex: 1;
    margin-top: 5px;
    min-width: 400px;
    font-size: 16px;
    line-height: 28px;
    border: 1px solid #d7d7d7;

    ${MEDIA_RULES.SMALL} {
      min-width: auto;
      width: 100%;
    }
  }

  input::placeholder {
    color: #bdbdbd;
    opacity: 1;
  }
`
