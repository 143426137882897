/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Box, IconButton, Theme } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { ReactNode } from 'react'

const styles = {
  root: (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    padding: 1rem 16px;

    border-bottom: 1px solid;
    border-bottom-color: ${theme.palette.divider};
  `,
  closeButton: css`
    flex: 0 0 auto;

    margin-left: auto;
    /* reduce the size without cropping ripple effect */
    margin-top: -6px;
    margin-bottom: -6px;
  `,
}

export const Header = ({
  children,
  onClose,
}: {
  children: ReactNode
  onClose: () => void
}) => {
  const buttonSize = 32

  return (
    <Box css={styles.root}>
      <Box width={buttonSize} flex="0 0 auto" />
      <Box display="flex" flex="1" justifyContent="center">
        {children}
      </Box>
      <IconButton
        aria-label="close"
        color="inherit"
        css={styles.closeButton}
        sx={{
          width: buttonSize,
          height: buttonSize,
        }}
        onClick={onClose}
      >
        <CloseIcon width={24} />
      </IconButton>
    </Box>
  )
}
