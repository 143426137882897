const InstaSmallIcon = ({ fill = '#2F54EB' }) => {
  return (
    <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.997 5.356A5.636 5.636 0 0 0 5.353 11a5.636 5.636 0 0 0 5.644 5.644A5.636 5.636 0 0 0 16.641 11a5.636 5.636 0 0 0-5.644-5.644Zm0 9.312A3.676 3.676 0 0 1 7.329 11a3.676 3.676 0 0 1 3.668-3.668A3.676 3.676 0 0 1 14.665 11a3.676 3.676 0 0 1-3.668 3.668Zm5.875-10.859c-.73 0-1.318.59-1.318 1.318 0 .73.589 1.319 1.318 1.319a1.315 1.315 0 0 0 1.218-1.823 1.318 1.318 0 0 0-1.218-.814ZM22 11c0-1.518.014-3.023-.072-4.54-.085-1.76-.487-3.324-1.775-4.612-1.29-1.29-2.85-1.69-4.612-1.775C14.021-.013 12.516.001 11 .001 9.48 0 7.976-.013 6.46.072 4.697.158 3.134.56 1.846 1.847.557 3.137.157 4.698.072 6.46-.013 7.98.001 9.484.001 11c0 1.517-.014 3.025.071 4.541.086 1.761.487 3.325 1.775 4.612 1.29 1.29 2.851 1.69 4.612 1.775C7.98 22.012 9.484 22 11 22c1.52 0 3.025.014 4.541-.072 1.761-.085 3.325-.487 4.612-1.775 1.29-1.29 1.69-2.85 1.775-4.612.088-1.516.072-3.021.072-4.54Zm-2.422 6.49c-.2.5-.443.875-.83 1.26a3.52 3.52 0 0 1-1.261.83c-1.448.576-4.885.447-6.489.447s-5.044.13-6.491-.443c-.501-.201-.876-.443-1.26-.831a3.52 3.52 0 0 1-.832-1.26c-.572-1.45-.443-4.888-.443-6.492 0-1.605-.13-5.044.443-6.492.201-.5.443-.875.831-1.26.388-.385.76-.63 1.26-.831 1.448-.572 4.888-.443 6.492-.443s5.044-.13 6.492.443c.5.2.875.443 1.26.831.388.388.63.76.83 1.26.573 1.448.444 4.887.444 6.492 0 1.604.13 5.041-.446 6.489Z"
        fill={fill}
      />
    </svg>
  )
}

export default InstaSmallIcon
