import { ServerVenue, VenueDetails } from '../../types/Venue'
import { generateSpaceRules } from '../helpers/venue'

import { venueListQueryParams } from './venueListQueryParamsMapper'

export const venuesMapper = (item: ServerVenue): VenueDetails => ({
  name: item.name,
  slug: item.slug,
  description: item.description || '',
  address:
    item.address?.formatted_address || item.location_address?.formatted_address,
  cost: item.current_referral_fee,
  id: item.id,
  city_id: item.city_id,
  rooms: item.rooms,
  status: item.status,
  //required field for update
  params: item.params,
  floorplans: item.floorplans,
  pricing: item.params ? item.params.minimum_starting_at : 0,
  shortPricingText: item.params ? item.params.short_pricing_details || '' : '',
  shortPricingTextLabel: item.params
    ? item.params.short_pricing_details_label || ''
    : '',
  displayPricing: item.params ? item.params.display_pricing : false,
  salesNotes: item.params ? item.params.notes_to_sales : '',
  capacity: item.capacity,
  houseRules: generateSpaceRules(item.house_rules),
  cancellationPolicy: item.params ? item.params.cancellation_policy : '',
  mainImage: item.images?.[0],
  photos: item.images.map((img) => img.path),
  images: item.images,
  labels: item.labels,
  exclusiveVendors: item.exclusive_vendors,
  inhouseVendorTypes: item.params ? item.params.inhouse_vendor_types : [],
  aboutInfo: {
    sqFootage: item.params ? item.params.sq_footage : '',
    numberOfFloors: item.params ? item.params.number_of_floors_label : '',
    maxGuests: item.max_head_count,
    proTip: item.params ? item.params.things_you_should_know : '',
    about: item.params ? item.params.re_walkthrough : '',
  },
  features: item.features,
  accessabilities: item.access,
  calendar: item.calendar,
  is_exclusive: item.is_exclusive,
  contact: {
    name: item.primary_contact?.first_name,
    email: item.primary_contact?.email,
    phone: item.primary_contact?.phone_number,
    website: item.website || '',
    // facebook: item.params ? item.params.facebook : '',
    instagram: item.params ? item.params.instagram : '',
    address: {
      description: item.location_address?.formatted_address || '',
      place_id: '',
    },
  },
  paymentStructure: {
    pricingDetails: item.payment_structure?.pricing_details,
  },
  partner: item.partner || '',
  hasPromotion: item.has_promotion || false,
  promotionDescription: item.promotion_description || '',
})

export const mapGuestQueryParamsToFormValues = (
  params: venueListQueryParams | Partial<venueListQueryParams>
) => {
  switch (params.guests_max) {
    case '79':
      return 's80'
    case '150':
      return 's150'
    case '250':
      return 's250'
    case '400':
      return 's400'
  }
  if (params.guests_min === '401') {
    return 's401'
  } else {
    return ''
  }
}
