import i18n from 'i18next'
import * as yup from 'yup'

import { passwordRegex } from '../../../../constants'

export const createValidationSchema = () =>
  yup.object().shape({
    newPassword: yup
      .string()
      .min(
        6,
        i18n.t(
          'auth.password.newPasswordLength',
          'Your new password must be at least 6 characters long'
        )
      )
      .matches(
        passwordRegex,
        i18n.t(
          'auth.password.newPasswordRules',
          'Your new password must contain at least one capital letter, lower case letter, and number'
        )
      )
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        i18n.t('auth.password.newPasswordMustMatch', 'Passwords must match')
      ),
    twoFactorAuth: yup.boolean(),
    birthDate: yup.boolean(),
  })
