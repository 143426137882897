const Map = ({
  borderRadius = '0',
  stroke = '#2F54EB',
  fill = '#F5F5F8',
  width = 48,
  height = 48,
}) => {
  return (
    <svg
      viewBox="4 4 40 40"
      width={width}
      height={height}
      fill="none"
      style={{ borderRadius: borderRadius }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2a2 2 0 0 1 2-2h44a2 2 0 0 1 2 2v44a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Z"
        fill={fill}
      />
      <g
        clipPath="url(#a)"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M31.333 28V17.333L26.666 20l-5.333-2.667L16.666 20v10.667L21.333 28l5.333 2.667L31.333 28ZM26.667 30.667V20M21.333 28V17.333" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="rotate(-180 16 16)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Map
