import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@material-ui/core'
import { excludeProps } from 'utils/styles/styled'

export const StyledTextField = styled(TextField)`
  background-color: transparent;
  min-width: 100px;
  display: flex;
  align-content: baseline;
  margin-right: 15px;

  .MuiTypography-root {
    margin-right: 10px;
  }

  .search {
    background: #f5f5f8;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    .MuiDivider-root {
      background: black;
      width: 1px;
      height: 35px;
      margin-right: 10px;
      margin-left: 5px;
    }
  }

  .search:before {
    content: none;
  }

  input::placeholder {
    opacity: 1;
    color: black;
  }

  input {
    &.Mui-disabled {
      -webkit-text-fill-color: black;
      color: black;
      padding: 15px 15px 15px 15px;
    }
  }
`

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin-left: 15px;
  &:hover {
    background-color: transparent;
    opacity: 0.8;
  }
`

export const SectionHeader = styled(Typography)`
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
`

export const FieldLabel = styled(Typography)`
  text-align: left;
  margin-bottom: 10px;
`
export const FormContainer = styled('form')`
  display: flex;
  width: 100%;
`
export const ScrollContainer = styled(Box)`
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`
export const ScreenContent = styled(Box)`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding-left: 15px;
  padding-right: 15px;
`

interface StyledMoreFiltersButtonProps extends ButtonProps {
  isOpen: boolean
}

export const StyledMoreFiltersButton = styled(Button, {
  shouldForwardProp: excludeProps<StyledMoreFiltersButtonProps>(['isOpen']),
})<StyledMoreFiltersButtonProps>(
  ({ isOpen }) => `
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  color:  ${isOpen ? 'white' : 'black'};
  text-transform: none;
  background-color: ${isOpen ? 'black' : '#f5f5f8'};
  min-width: 100px;
  width: 100%;
  height: 48px;
  padding: 12px 20px 12px 10px;
  border-radius: 2px;
  align-content: baseline;
   &:hover {
    background-color: ${isOpen ? 'black' : 'rgba(20, 20, 20, 0.16)'};
    opacity: ${isOpen ? 1 : 0.9};
  }
  button {
    height: fit-content;
  }
`
)
