import { Box, Paper, styled } from '@material-ui/core'

export const StyledPaper = styled(Paper)`
  background: white;
  border-radius: 0;
  min-width: 280px;
  padding: 25px;
`

export const BlockContainer = styled(Box)`
  padding: 25px;
  box-shadow: 0 4px 18px rgba(210, 210, 210, 0.4);
`

export const CustomImage = styled('div')`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
