import { useMemo } from 'react'

import { InquiryInvoiceStatuses } from '../constants/inquiry'
import { Invoice } from '../types/dtos/inquiry'

interface InvoicesByStatus {
  pendingInvoices: Invoice[]
  waitingForPaidInvoices: Invoice[]
  paidInvoices: Invoice[]
}

export function useInvoicesByStatuses(invoices?: Invoice[]): InvoicesByStatus {
  const pendingInvoices = useMemo(() => {
    if (!invoices?.length) return []
    return invoices?.filter(
      (invoice) => invoice.status === InquiryInvoiceStatuses.STATUS_PENDING
    )
  }, [invoices])

  const waitingForPaidInvoices = useMemo(() => {
    if (!invoices?.length) return []
    return invoices?.filter(
      (invoice) => invoice.status === InquiryInvoiceStatuses.STATUS_WAITING
    )
  }, [invoices])

  const paidInvoices = useMemo(() => {
    if (!invoices?.length) return []
    return invoices?.filter(
      (invoice) => invoice.status === InquiryInvoiceStatuses.STATUS_PAID
    )
  }, [invoices])

  return {
    pendingInvoices,
    waitingForPaidInvoices,
    paidInvoices,
  }
}
