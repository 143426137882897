const ArrowBack = ({ fill = '#2F54EB' }: { fill?: string }) => {
  return (
    <svg width="8" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.41 1.41 2.83 6l4.58 4.59L6 12 0 6l6-6 1.41 1.41Z"
        fill={fill}
      />
    </svg>
  )
}

export default ArrowBack
