const FileText = () => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m16.691 5.637-4.203-4.203a.624.624 0 0 0-.441-.184H3.75a.624.624 0 0 0-.625.625v16.25c0 .346.28.625.625.625h12.5c.346 0 .625-.28.625-.625V6.08a.628.628 0 0 0-.184-.443Zm-1.257.73h-3.676V2.691l3.676 3.676Zm.035 10.977H4.53V2.656h5.899v4.219a.82.82 0 0 0 .82.82h4.219v9.649ZM9.844 12.07H6.25a.157.157 0 0 0-.156.157v.937c0 .086.07.156.156.156h3.594c.086 0 .156-.07.156-.156v-.937a.157.157 0 0 0-.156-.157Zm-3.75-2.5v.938c0 .086.07.156.156.156h7.5c.086 0 .156-.07.156-.156V9.57a.157.157 0 0 0-.156-.156h-7.5a.157.157 0 0 0-.156.156Z"
        fill="#37C0EB"
      />
    </svg>
  )
}

export default FileText
