import { isNil, unionBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { userDefaultEvent } from '../constants'
import { UserRoles } from '../constants/profile'
import { EventVenue, LocalEvent, userEventRecord } from '../types/Event'
import { InquiryEvent } from '../types/Inquiry'
import { useEventInquiriesStatuses } from '../utils/api/events'
import {
  createInquiry,
  createInquiryForDuplicate,
} from '../utils/api/inquiries'
import {
  generateEventName,
  removeVenueFromEvent,
  uid,
} from '../utils/helpers/events'
import { eventListQueryParams } from '../utils/mappers/eventListQueryParamsMapper'
import {
  convertInquiryEventToUserEvent,
  createInquiryMapper,
} from '../utils/mappers/inquiries'
import { userEventRecordMapper } from '../utils/mappers/userEvent'
import { useAuthData } from '../utils/providers/AuthProvider'
import {
  LsGetSearch,
  LsGetUserEvents,
  LsSetDefaultEvents,
  LsSetUserEvents,
} from '../utils/storage'

import { useEventList } from './useEventList'
import { usePartner } from './usePartner'
import { useUrlParams } from './useUrlParams'

export function useStorageEvents(
  queryParams: eventListQueryParams,
  chosenAbsVenue: EventVenue | null
): {
  //TODO for sorting need to get events from server instead of LocalStorage
  events: LocalEvent[]
  eventsList: InquiryEvent[] | undefined
  updateUserActiveEvent: (userEvent: Partial<LocalEvent>) => void
  updateEventById: (userEvent: Partial<LocalEvent>, id: string) => void
  removeEventById: (id: string) => void
  setActiveEvent: (id: string) => void
  getNewStatusByFlow: (status: string, isAccepted: boolean) => string
  getUserActiveEvent: () => LocalEvent
  createNewEvent: () => LocalEvent
  clearEventsFromStorage: () => void
  getEventById: (id: string) => LocalEvent
  duplicateEvent: (id: string) => LocalEvent
  isLoading: boolean
  setIsLoading: (loading: boolean) => void
} {
  const [events, setEvents] = React.useState<LocalEvent[]>([])
  const { authUser } = useAuthData()
  const location = useLocation()
  const { isAbs } = usePartner()
  const { urlParams } = useUrlParams()
  const { data: statusesData } = useEventInquiriesStatuses()
  const { isLoading: isLoadingEvents, eventsList } = useEventList()

  const [isLoading, setIsLoading] = useState(false)
  const LSEvents: LocalEvent[] = LsGetUserEvents()

  useEffect(() => {
    if (UserRoles.isClient(authUser?.role) && eventsList && eventsList.length) {
      const lastActiveEvent = LSEvents.find((item: LocalEvent) => !!item.active)
      const eventsFromBackend = eventsList.map(convertInquiryEventToUserEvent)
      const concatinatedEvents = unionBy(eventsFromBackend, LSEvents, 'id')

      const updatedEvents = concatinatedEvents.map((item: LocalEvent) => {
        if (item.id === lastActiveEvent?.id) {
          return { ...item, active: '1' }
        }
        return item
      })
      updateEvents(updatedEvents)
    } else {
      // setEvents(LSEvents)
      updateEvents(LSEvents)
    }
  }, [eventsList?.length, LSEvents?.length, authUser?.id])

  const submitInquiry = async (inquiryData: any) => {
    const inquiryFormValues =
      !isNil(localStorage.getItem('inquiryFormValues')) &&
      JSON.parse(localStorage.getItem('inquiryFormValues') as string)
    const convertedEvent = createInquiryMapper(
      { ...inquiryFormValues, ...inquiryData },
      authUser,
      inquiryData.venue
    )
    const { data } = inquiryData.original_event_id
      ? await createInquiryForDuplicate(convertedEvent)
      : await createInquiry(convertedEvent)
    const storageEvent = convertInquiryEventToUserEvent(data)

    updateUserActiveEvent({ ...storageEvent })

    localStorage.removeItem('inquiryFormValues')
    localStorage.removeItem('inquiryDetails')
    localStorage.setItem('newEventId', data.id)
  }

  useEffect(() => {
    if (location.search.includes('eventToSubmit')) {
      const eventToSubmitId = urlParams.eventToSubmit
      const eventsFromLS = LsGetUserEvents()
      const eventToSubmit = eventsFromLS.find(
        (event: userEventRecord) => event.id === eventToSubmitId
      )
      if (eventToSubmit && authUser?.id && !authUser?.role) {
        submitInquiry(eventToSubmit)
      }
    }
  }, [location, urlParams, authUser?.id, authUser?.role])

  const updateEvents = (updatedEvents: LocalEvent[]) => {
    LsSetUserEvents(updatedEvents.map(userEventRecordMapper))
    setEvents(updatedEvents)
  }

  const updateUserActiveEvent = (userEvent: Partial<LocalEvent>) => {
    let eventsList = events
    if (!eventsList.length) {
      eventsList = LsGetUserEvents()
    }
    const updatedEvents = eventsList.map((item: LocalEvent) => {
      if (item.active) {
        const name =
          userEvent?.name && !item.customName ? userEvent?.name : item.name
        return { ...item, ...userEvent, name }
      }

      return item
    })

    updateEvents(updatedEvents)
  }

  const getUserActiveEvent = () => {
    let eventsList = events
    if (!eventsList.length) {
      eventsList = LsGetUserEvents()
    }
    return eventsList.find((item: LocalEvent) => item.active) || eventsList[0]
  }

  const getEventById = (id: string) => {
    let eventsList = events
    if (!eventsList.length) {
      eventsList = LsGetUserEvents()
    }

    const event =
      eventsList.find((item: LocalEvent) => item.id === id) || eventsList[0]
    return Object.assign({}, event)
  }

  const updateEventById = (userEvent: Partial<LocalEvent>, eventId: string) => {
    const updatedEvents = events.map((item: LocalEvent) => {
      if (item.id === eventId) {
        return { ...item, ...userEvent }
      }

      return item
    })

    updateEvents(updatedEvents)
  }

  const removeEventById = (eventId: string) => {
    let isRemovedActive = false

    const updatedEvents = events.filter((item: LocalEvent) => {
      if (item.id === eventId && Boolean(item.active)) {
        isRemovedActive = true
      }
      return item.id !== eventId
    })

    if (isRemovedActive && updatedEvents[0]) {
      updatedEvents[0].active = '1'
    }

    updateEvents(updatedEvents)
  }

  const setActiveEvent = (eventId: string) => {
    const updatedEvents = events.map((item: LocalEvent) => {
      if (item.id === eventId) {
        return { ...item, active: '1' }
      }

      return { ...item, active: '' }
    })

    updateEvents(updatedEvents)
  }

  const getNewStatusByFlow = (status: string, isAccepted: boolean): string => {
    const flow = statusesData?.eventStatus.flow || {}
    const possibleStatuses = flow[status]

    if (!possibleStatuses) {
      throw new Error('Wrong event status')
    }
    return isAccepted ? possibleStatuses[0] : possibleStatuses[1]
  }

  const createNewEvent = () => {
    const heroSearchData = LsGetSearch()
    if (heroSearchData) localStorage.removeItem('heroSearch')

    const name = generateEventName(!!isAbs)
    const id = uid()
    const venue = isAbs ? chosenAbsVenue : null

    const event: LocalEvent = { ...userDefaultEvent, name, id, venue }

    const updatedEvents = events.map((item) => {
      delete item.active
      return item
    })

    updatedEvents.push(event)

    updateEvents(updatedEvents)
    return event
  }

  const duplicateEvent = (eventId: string) => {
    const duplicateEvent = getEventById(eventId)
    const name = `${duplicateEvent.name} (copy)`
    const id = uid()
    const eventWithoutVenue: LocalEvent =
      removeVenueFromEvent(duplicateEvent) || duplicateEvent
    const event: LocalEvent = { ...eventWithoutVenue, name, id }

    const updatedEvents = events.map((item) => {
      delete item.active
      return item
    })

    updatedEvents.push(event)

    updateEvents(updatedEvents)
    return event
  }

  const clearEventsFromStorage = () => {
    LsSetDefaultEvents()
    // updateEvents([userDefaultEvent])
  }

  return {
    events,
    eventsList,
    getNewStatusByFlow,
    createNewEvent,
    updateEventById,
    getEventById,
    duplicateEvent,
    removeEventById,
    setActiveEvent,
    updateUserActiveEvent,
    getUserActiveEvent,
    clearEventsFromStorage,
    isLoading: isLoading || isLoadingEvents,
    setIsLoading,
  }
}
