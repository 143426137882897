import { InquiryStatuses } from '../constants/inquiry'
import { t } from '../utils/i18n'

import { InquiryContractResponse, InquiryInvoiceResponse } from './dtos/inquiry'
import { ClientVenue, EventVendor } from './Event'
import { ImageItem, ServerCalendar } from './general'
import { VendorPackageData } from './Vendor'
import { VenueDetails, VenueItem } from './Venue'

export interface InquiryEvent {
  id: number
  guests: number
  notes?: string
  status: string
  name: string
  type: string
  start_date: string
  end_date: string
  inquiry: Inquiry
  inquiries_related: Inquiry[]
  style?: string
  calendar?: ServerCalendar
  company_name?: string
  load_in_end_date?: string | null
  load_in_start_date?: string | null
  load_out_end_date?: string | null
  load_out_start_date?: string | null
  is_flexible_date?: boolean
  user_name?: string
  phone?: string
  budgets?: BudgetItem[] | any[]
  unread_notifications_count?: number
  primary_contact?: any
  venues?: VenueItem[]
  partner?: string | null
}

export interface VenueRoom {
  id: number
  name: string
}

export interface Inquiry {
  user_files: any[]
  created_at: Date
  event_id: number
  event?: InquiryDetailsEvent
  app_fee: number
  files: any[]
  id: number
  exclusivity_type?: string
  status: InquiryStatuses
  updated_at: Date
  user_status: string
  vendor?: InquiryVendor
  package?: VendorPackageData
  vendor_id?: number
  venue?: VenueItem
  venue_id?: number
  contracts: Array<InquiryContractResponse>
  invoice_bundles: Array<InquiryInvoiceResponse>
  primary_contact?: any
  user_message_thread?: any
  venue_rooms?: VenueRoom[]
  initial_bundle_sent?: boolean
}

export interface InquiryVendor {
  id: number
  name: string
  slug?: string
  contact_name: string
  type: number
  phone: string
  website: string
  contact_email: string
  location?: {
    formatted_address: string
    street_number?: string
    route?: string
    city?: string
    city_long?: string
    zip?: number
  }
  payment_type: string
  exclusive_venue_id: number | null
  employees_number: number | null
  employees_string: string | null
  legal_entity: string
  office: string
  order_price_min: number | null
  images: ImageItem[]
  packages: any[]
}

export interface InquiryStatusesData {
  inquiryStatus: {
    flow: Record<string, string[]>
    stages: Record<string, StageInfo>
    status_info: Record<string, StatusInfo>
  }
  eventStatus: {
    labels: Record<string, string>
    flow: Record<string, string[]>
  }
}

export interface StatusInfo {
  label: string
  stage: string
  clientLabel: string
}

export interface StageInfo {
  label: string
  bg_color: string
  text_color: string
  border_color: string
}

export interface InquiryUserFile {
  filename: string
  id: number | string
  inquiry_id: number
  path: string
  user_id: number
  s3_path?: string
  is_validated?: boolean
}

export interface InquiryEventBudget {
  title: string
  value: string
}

export interface InquiryDetailsEvent {
  id: number
  guests: number
  notes?: string
  status: string
  name: string
  type: string
  style: string
  budgets: InquiryEventBudget[]
  start_date: string
  end_date: string
  load_in_start_date?: string
  load_in_end_date?: string
  load_out_start_date?: string
  load_out_end_date?: string
  is_flexible_date?: boolean
  user: {
    name: string
    phone: string
    email: string
  }
  partner?: string | null
}

type InquiryDetails = {
  contracts: Array<InquiryContractResponse>
  user_files: Array<InquiryUserFile>
  event: InquiryDetailsEvent
}

export type InquiryWithDetails = Inquiry & InquiryDetails

export const getInquirySteps = (isAbs?: boolean) => {
  enum InquirySteps {
    // @ts-ignore
    AdvancedInfo = isAbs
      ? t(
          'partners.abs.bookings.additionalProvide',
          'Please provide additional info about your booking'
        )
      : t(
          'events.additionalProvide',
          'Please provide additional info about your event'
        ),
    // @ts-ignore
    Dates = isAbs
      ? t(
          'partners.abs.bookings.datesProvide',
          'Please fill information about your booking dates'
        )
      : t(
          'events.dates.fill',
          'Please fill information about your event dates'
        ),
    // @ts-ignore
    Budget = isAbs
      ? t(
          'partners.abs.bookings.budgetProvide',
          'Please fill information about your booking budget'
        )
      : t(
          'events.budget.provide',
          'Please fill information about your event budget'
        ),
    // @ts-ignore
    JotForm = '',
  }

  return InquirySteps
}

export interface InquiryCreationStep {
  name: string
  index: number
}

export interface BudgetItem {
  title: string //250
  value: string //50
}

// TODO sync with LocalEvent
export interface InquiryFormPostRequest {
  name: string
  user_name?: string //max:50
  email?: string
  company_name?: string //max:100
  vendors: number[]
  packages: number[]
  rooms?: number[]
  venue_id?: number
  client_venue?:
    | { location: string; placeId: string; sq_footage?: number }
    | ClientVenue
  notes?: string //max:1024'
  start_date: string
  venue_type: string
  end_date: string
  type?: string
  phone?: string //max:20'
  guests: number //max:32767',
  load_in_start_date?: string
  load_in_end_date?: string
  load_out_start_date?: string
  load_out_end_date?: string
  style?: string
  is_flexible_date?: boolean
  budgets: any
  original_event_id?: number
  copy_questionary?: number
}

export interface InquiryDetailFormValues {
  name: string
  style: string
  typeId: number | string
  guests: number | null
  isMultipleDates: boolean
  isDatesDecided: boolean
  loadInDates?: string[] | string | null
  eventDates: string[] | string | null
  loadOutDates?: string[] | string | null
  flexibleDates?: string[] | string | null
  isOverAllBudget: boolean
  isAllocateBudget: boolean
  price: string
  priceRange: string
  venueBudget?: string
  vendorTypes: string[]
  notes: string
  id?: string | number
  // if event from LS
  type?: string
  client_venue?: {
    location: any
    sq_footage: number | string
    name: string
    partnerId: string
  }
  venue: VenueDetails
  vendors?: EventVendor[]
}
