import {
  Box,
  BoxProps,
  styled,
  TableCell,
  Typography,
  useTheme,
} from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import * as React from 'react'

import ActivityGray from '../../../../components/svg/clientDashboard/ActivityGray'
import { Image } from '../../../../components/ui/Image'
import { Loader } from '../../../../components/ui/Loader'
import { PopperMenu } from '../../../../components/ui/PopperMenu'
import { getMenuActionsByStatus } from '../../../../constants/events'
import { InquiryStages, InquiryStatuses } from '../../../../constants/inquiry'
import { COLUMN_WIDTH_300 } from '../../../../constants/tables'
import { LocalEvent } from '../../../../types/Event'
import { StageInfo } from '../../../../types/Inquiry'
import { useEventInquiriesStatuses } from '../../../../utils/api/events'
import {
  convertDateToShortString,
  convertDateToShortTimeString,
} from '../../../../utils/helpers/date'
import { getUserEventTotalPrice } from '../../../../utils/helpers/events'
import { formatToCurrency } from '../../../../utils/helpers/price'
import {
  BootstrapTooltip,
  NotificationButton,
  StyledTableRow,
} from '../../Event/components/EventServices/EventServices.styles'

type StatusButtonProps = BoxProps & {
  textColor?: string
}

export const StyledStatusBox = styled(Box)<StatusButtonProps>(
  (props) => `
  width: fit-content;
  position: relative;
  background: #F7F7F8;
  color: ${props.textColor};
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
`
)

export const EventListTableRow: React.FC<{
  data: LocalEvent
  isLoading: boolean
  handleOnEventSelect: (action: string, event: LocalEvent) => void
}> = ({ data, isLoading, handleOnEventSelect }) => {
  const { data: statusesData } = useEventInquiriesStatuses()
  const theme = useTheme()
  const stage =
    data.status === InquiryStatuses.STATUS_DRAFT
      ? InquiryStages.STAGE_PLANING
      : data.status
  const stageColors = statusesData?.inquiryStatus?.stages
    ? statusesData?.inquiryStatus?.stages[stage]
    : ({} as StageInfo)
  return (
    <StyledTableRow id={data.id?.toString()}>
      <Box maxWidth={COLUMN_WIDTH_300}>
        <TableCell>
          <Box display="flex" alignItems="center">
            <Image
              style={{ width: 56, height: 56 }}
              imageSize="original"
              staticImageType="venue"
            />
          </Box>
        </TableCell>
      </Box>
      <TableCell>
        <Typography fontWeight={500} variant="body2">
          {data.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          fontWeight={500}
          variant="body2"
          style={{ textTransform: 'capitalize' }}
        >
          {data.eventType}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight={600} variant="body2">
          {convertDateToShortString(data.start_date, true)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight={600} variant="body2">
          {convertDateToShortTimeString(data.start_date)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight={600} variant="body2">
          {data.guests}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight={600} variant="body2">
          {formatToCurrency(getUserEventTotalPrice(data))}
        </Typography>
      </TableCell>
      {/*<TableCell>*/}
      {/*  <Box display="flex">*/}
      {/*    <Typography variant="body2" fontWeight={600}>*/}
      {/*      3*/}
      {/*    </Typography>*/}
      {/*    <Typography variant="body2" color="#9396A3">*/}
      {/*      /12 Deals*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</TableCell>*/}
      <TableCell>
        <StyledStatusBox textColor={stageColors?.text_color || ''}>
          {stage}
        </StyledStatusBox>
      </TableCell>
      <TableCell>
        <BootstrapTooltip title="New activity" arrow placement="top">
          <NotificationButton>
            <ActivityGray />
            <Typography variant="caption">Notification</Typography>
          </NotificationButton>
        </BootstrapTooltip>
      </TableCell>
      <TableCell>
        <Typography color="textSecondary">
          <PopperMenu
            options={getMenuActionsByStatus(
              data.status,
              theme.palette.secondary.dark
            )}
            onSelectAction={(action) => handleOnEventSelect(action, data)}
          >
            <MoreHoriz className="event-action" />
          </PopperMenu>
        </Typography>
      </TableCell>
      {isLoading && <Loader position="fixed" />}
    </StyledTableRow>
  )
}
