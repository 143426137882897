import React from 'react'
import { useRouteMatch } from 'react-router'

import { InquiryDetailsProvider } from '../../../utils/providers/InqueryDetailsProvider'
import { MessageModalProvider } from '../../../utils/providers/MessageModalProvider'

import { BookingDetailsContent } from './BookingDetailsContent'

export const BookingDetails = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  return (
    <MessageModalProvider>
      <InquiryDetailsProvider id={id}>
        <BookingDetailsContent />
      </InquiryDetailsProvider>
    </MessageModalProvider>
  )
}
