import { Box, styled, Typography } from '@material-ui/core'

export const SectionHeader = styled(Typography)`
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`

export const FormContainer = styled('form')`
  display: flex;
  width: 100%;
`

export const FieldLabel = styled(Typography)`
  color: #000;
  text-align: left;
  margin-bottom: 15px;
`

export const FieldWrapper = styled(Box)`
  width: 100%;

  .MuiFormControl-root input,
  input {
    padding: 11px 16px;
    font-size: 16px;
    font-family: Poppins;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    &:hover {
      border: 1px solid rgba(0, 0, 0, 1);
    }

    &.error {
      border: 1px solid #f16547;
    }
  }

  .MuiFormControl-root .MuiSelect-select {
    padding: 11px 16px;
  }
`
