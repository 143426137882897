import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogContentTextProps,
  DialogProps,
  FormHelperText,
  Typography,
} from '@material-ui/core'
import * as React from 'react'
import styled from 'styled-components'

import Complete from '../../../svg/Complete'
import Decline from '../../../svg/Decline'
import WarningButton from '../../../WarningButton'
import GradientButton from '../../Buttons/GradientButton'

export enum Status {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface State {
  status: Status
  message?: string
}

export interface ConfirmDialogProps extends Omit<DialogProps, 'onClose'> {
  dialogTitle?: React.ReactNode
  confirmLabel?: React.ReactNode
  cancelLabel: React.ReactNode
  onConfirm?: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<any> | void
  onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCancel?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onSuccess?: () => void
  onError?: () => void
  isWarning?: boolean
  textProps?: DialogContentTextProps
}

const ConfirmDialogError = styled(FormHelperText).attrs({ error: true })`
  text-align: center;
`

const ConfirmDialogText = styled(DialogContentText)`
  margin-bottom: 0;
  text-align: center;
`

export const ConfirmDialog = ({
  maxWidth = 'sm',
  dialogTitle,
  children,
  confirmLabel,
  cancelLabel,
  onCancel,
  onClose,
  onConfirm = (): void => {},
  onSuccess,
  onError,
  isWarning,
  textProps,
  ...props
}: ConfirmDialogProps) => {
  const [{ status, message }, setState] = React.useState<State>({
    status: Status.SUCCESS,
  })

  const handleClose = React.useCallback(() => {
    if (typeof onClose === 'function') {
      onClose()
    }
    setState({ message: undefined, status: Status.SUCCESS })
  }, [onClose])

  const handleCancel = React.useCallback(() => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
    handleClose()
  }, [handleClose, onCancel])

  const handleConfirm = React.useCallback(() => {
    setState({ status: Status.REQUEST })
    Promise.resolve(onConfirm())
      .then(() => {
        setState({ status: Status.SUCCESS })
        handleClose()

        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      })
      .catch((error) => {
        setState({
          status: Status.ERROR,
          message: error,
        })
        if (typeof onError === 'function') {
          onError()
        }
      })
  }, [onConfirm, handleClose, onSuccess, onError])

  const confirmButtonProps = {
    onClick: handleConfirm,
    fullWidth: true,
    size: 'large',
    children: confirmLabel,
  }

  const getDialogActions = () =>
    !isWarning ? (
      <>
        {/*@ts-ignore*/}
        <GradientButton {...confirmButtonProps} />
      </>
    ) : (
      <>
        {/*@ts-ignore*/}
        <WarningButton {...confirmButtonProps} />
      </>
    )

  return (
    // @ts-ignore
    <Box maxWidth={390} onClose={handleCancel} {...props}>
      <Box display="flex" justifyContent="center" alignContent="center" my={10}>
        {isWarning ? <Decline /> : <Complete />}
      </Box>
      <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
        {dialogTitle}
      </Typography>
      <DialogContent sx={{ padding: 0 }}>
        <ConfirmDialogText {...textProps}>
          {children}
          {status === Status.ERROR && (
            <ConfirmDialogError error>{message}</ConfirmDialogError>
          )}
        </ConfirmDialogText>
      </DialogContent>
      {confirmLabel && (
        <DialogActions sx={{ '&:hover': { opacity: 0.95 } }}>
          {getDialogActions()}
        </DialogActions>
      )}
    </Box>
  )
}
