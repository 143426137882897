import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { Image } from '../../../../components/ui/Image'
import { Loader } from '../../../../components/ui/Loader'
import { usePartner } from '../../../../hooks/usePartner'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { VendorType } from '../../../../types/Vendor'
import { useVendorTypes } from '../../../../utils/api/vendors'
import { vendorsCategoryPath } from '../../../../utils/paths'

import {
  CategoryTitle,
  StyledCategoryBox,
  VendorsCategoriesContainer,
  VendorsCategoriesGrid,
} from './VendorsCategories.styles'

const categoriesOrder: Record<string, number> = {
  catering: 1,
  entertainment: 2,
  'av-lightning': 3,
  decor: 4,
  rentals: 5,
  design: 6,
  'photo-video': 7,
}

export const VendorsCategories: React.FC<{
  vendorTypes?: { isLoading: boolean; data: VendorType[] | undefined }
}> = ({ vendorTypes }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const { isLoading, data } = vendorTypes ? vendorTypes : useVendorTypes()

  const categoriesWithVendors = useMemo(() => {
    return data?.filter((category: VendorType) => !!category.vendors_count)
  }, [data?.length])

  const { isAbs } = usePartner()

  const randomCategories = useMemo(() => {
    if (categoriesWithVendors) {
      const shuffled = categoriesWithVendors.sort((a, b) => {
        const orderA = categoriesOrder[a.id] || 99
        const orderB = categoriesOrder[b.id] || 99

        return orderA - orderB
      })

      return shuffled.slice(0, 7)
    }
    return []
  }, [categoriesWithVendors?.length])

  return (
    <VendorsCategoriesContainer
      className="section-block"
      sx={{ background: (theme) => theme.palette.secondary.dark }}
    >
      {isLoading && <Loader size={30} position="fixed" />}
      <Box className="container">
        <Box display="flex" flexDirection="column" mb={isMobile ? 0 : 4}>
          <Typography
            sx={{ color: (theme) => theme.palette.common.white }}
            variant="h2"
            gutterBottom
            fontSize={isMobile ? '24px' : isAbs ? '47px' : '24px'}
            align={isMobile ? 'center' : 'left'}
          >
            {isAbs
              ? t('partners.abs.vendors.categoriesBlock', 'Vendors By Category')
              : t('home.venue.complete', 'Complete Event Booking')}
          </Typography>
          {!isAbs && (
            <Typography color="#fff" variant="body2">
              {t(
                'home.venue.compliment',
                'Compliment your space with the perfect vendors, all-in-one place'
              )}
            </Typography>
          )}
        </Box>
        <VendorsCategoriesGrid>
          {randomCategories.map((item, index) => (
            <Box
              key={item.id}
              my={isMobile ? '32px' : 0}
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              className={index === 0 ? 'first' : ''}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                history.push(
                  generatePath(vendorsCategoryPath, { category: item.id })
                )
              }
            >
              <StyledCategoryBox>
                <CategoryTitle>
                  <Typography variant="h3" color="white">
                    {item.name}
                  </Typography>
                </CategoryTitle>
                <Image
                  imageSize="original"
                  staticImageType="vendor"
                  vendorCategory={item.id}
                  style={{ maxHeight: isMobile ? '248px' : 'unset' }}
                  alt={item.name}
                />
              </StyledCategoryBox>
            </Box>
          ))}
        </VendorsCategoriesGrid>
      </Box>
    </VendorsCategoriesContainer>
  )
}
