import { EXTERNAL_LINKS } from 'constants/externalLinks'

import { flow } from 'lodash'
import * as React from 'react'
import { createElement } from 'react'
import {
  generatePath,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import { wrapWith } from 'utils/helpers/render'
import { Box, CircularProgress } from '@material-ui/core'

import { UserRoles } from '../constants/profile'
import { usePartner } from '../hooks/usePartner'
import { useVenueById } from '../hooks/useVenueById'
import { Home as AbsHome } from '../pages/abs'
import { ClientCalendarPage } from '../pages/clientCalendar'
import { Dashboard } from '../pages/dashboard'
import { BookingDetails } from '../pages/dashboard/BookingDetails'
import { Bookings } from '../pages/dashboard/Bookings'
import { BusinessDetails } from '../pages/dashboard/BusinessDetails'
import { NoBusiness } from '../pages/dashboard/components/NoBusiness'
import { GenerateDefaultInvoicesContainer } from '../pages/dashboard/DocumentForms/GenerateDefaultInvoices/GenerateDefaultInvoicesContainer'
import { DashboardFiles } from '../pages/dashboard/Files'
import { EventDetails as ClientEventDetails } from '../pages/events/Event'
import { EventList } from '../pages/events/EventsList'
import { Home } from '../pages/home'
import { Inquiry } from '../pages/inquiry/Inquiry'
import { Marketplace } from '../pages/marketplace/Marketplace'
import { Messages } from '../pages/messages'
import { MobileMap } from '../pages/mobileMap'
import { NotAvailablePage } from '../pages/NotAvailablePage'
import { ChooseRole } from '../pages/onboarding'
import { StepsConstructor } from '../pages/onboarding/StepsConstructor'
import { Profile } from '../pages/profile'
import { PromotionsList } from '../pages/promotions'
import { VendorContainer } from '../pages/vendors/Vendor/VendorContainer'
import { Vendors } from '../pages/vendors/Vendors'
import { VendorsListByCategory } from '../pages/vendors/Vendors/VendorsListByCategory'
import { VenueRoomDetails } from '../pages/venues/Room'
import { VenueRooms } from '../pages/venues/Rooms'
import { VenueContainer } from '../pages/venues/Venue/VenueContainer'
import { useVendorByIdNew } from '../utils/api/vendors'
import {
  absAdvantagePath,
  clientCalendarPath,
  dashboardBookingDetailsPath,
  dashboardBookingsPath,
  dashboardBusinessDetailsPath,
  dashboardBusinessFilesPath,
  dashboardListingPath,
  dashboardPath,
  dashboardProfilePath,
  eventPath,
  eventsListPath,
  generateInvoicesPath,
  inquiryPath,
  marketplacePath,
  marketplacePathOld,
  messagesPath,
  mobileMapPath,
  onboardingRolePath,
  onboardingVendorPath,
  onboardingVenuePath,
  profilePath,
  termsPathDeprecated,
  vendorPath,
  vendorPathOld,
  vendorsCategoryPath,
  vendorsPath,
  vendorsPromotionsPath,
  vendorsTreePath,
  venuePath,
  venuePathOld,
  venueRoomsDetailsPath,
  venueRoomsPath,
  venuesPath as rootPath,
  venuesPromotionsPath,
} from '../utils/paths'
import { useAuthData } from '../utils/providers/AuthProvider'
import { PortalDataProvider } from '../utils/providers/PortalProvider'

import { PageLayout } from './PageLayout'
import { Loader } from './ui/Loader'

const dashboardRoles = [UserRoles.Vendor, UserRoles.Landlord]

const CommonRoute: React.FC<RouteProps> = ({ children, ...props }) => (
  <Route {...props}>
    <PageLayout>{children}</PageLayout>
  </Route>
)

const AuthRoute: React.FC<
  RouteProps & { accessRoles: UserRoles[]; role?: UserRoles }
> = ({ accessRoles, role, children, ...props }) => {
  const isRouteAvailable = role !== undefined && accessRoles.includes(role)

  if (!isRouteAvailable) {
    console.log("will redirect to login");
    const replyTo = `${window.location.origin}${window.location.pathname}`;
    const callbackState = JSON.stringify({ val: replyTo, ts: (Date.now() + (7 * 24 * 60 * 60 * 1000)) })
    localStorage.setItem("AUTH_CALLBACK_PATH", callbackState);
    if (!window.location.origin.includes("localhost")) {
      window.open(`${window.location.origin}/d/en/auth`, "_self");
    }

    return (
      <Box sx={{
        position: "fixed",
        inset: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Box sx={{ marginTop: "-40%" }}>
          <CircularProgress size={80} color="secondary" />
        </Box>
      </Box>
    )
  }

  return (
    <CommonRoute {...props}>
      {children}
    </CommonRoute>
  )
}

const GuestAuthRoute: React.FC<
  RouteProps & {
    accessRoles: UserRoles[]
    role?: UserRoles
    isPartner?: boolean
    availableForPartners?: boolean
  }
> = ({
  accessRoles,
  role,
  isPartner,
  availableForPartners = true,
  children,
  ...props
}) => {
    const isRouteAvailable = !role || (role && accessRoles.includes(role))

    if (!isRouteAvailable) {
      return (
        <Box sx={{
          position: "fixed",
          inset: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10000,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <Box sx={{ marginTop: "-40%" }}>
            <CircularProgress size={80} color="secondary" />
          </Box>
        </Box>
      )
    }

    return isPartner && !availableForPartners ? (
      <NotPartnerRoute isPartner {...props}>
        {children}
      </NotPartnerRoute>
    ) : (
      <CommonRoute {...props}>
        {children}
      </CommonRoute>
    )
  }

const NotPartnerRoute: React.FC<RouteProps & { isPartner: boolean }> = ({
  isPartner,
  children,
  ...props
}) => {
  const isRouteAvailable = !isPartner

  return (
    <CommonRoute {...props}>
      {isRouteAvailable ? children : <NotAvailablePage type="403" />}
    </CommonRoute>
  )
}

const PartnerRoute: React.FC<RouteProps & { isPartner: boolean }> = ({
  isPartner,
  children,
  ...props
}) => {
  return (
    <CommonRoute {...props}>
      {isPartner ? children : <NotAvailablePage type="403" />}
    </CommonRoute>
  )
}

const page = flow(wrapWith(PageLayout), wrapWith(PortalDataProvider))

const Router = () => {
  const { role, loaded } = useAuthData()
  const { isAbs, isPartner } = usePartner()

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/a" />
      </Route>
      <Route
        exact
        path={rootPath}
        render={({ location: { search } }) => {
          const params = new URLSearchParams(search)

          if (isPartner && isAbs) {
            return createElement(page(AbsHome), {})
          } else if (params.has('action')) {
            return createElement(page(Home), {})
          }

          return <Redirect to={marketplacePath} />
        }}
      />
      <CommonRoute exact path={mobileMapPath}>
        <MobileMap />
      </CommonRoute>
      <CommonRoute exact path={venueRoomsPath}>
        <VenueRooms />
      </CommonRoute>
      <CommonRoute exact path={venueRoomsDetailsPath}>
        <VenueRoomDetails />
      </CommonRoute>
      <CommonRoute exact path={vendorsTreePath}>
        <Vendors />
      </CommonRoute>
      <Route path={onboardingRolePath}>
        <OnboardingRoutes role={role} isPartner={!!isPartner} />
      </Route>
      <CommonRoute exact path={[vendorsPath, vendorsCategoryPath]}>
        <VendorsListByCategory />
      </CommonRoute>
      <PartnerRoute exact path={absAdvantagePath} isPartner={!!isPartner}>
        <PromotionsList />
      </PartnerRoute>
      <NotPartnerRoute
        exact
        path={[vendorsPromotionsPath, venuesPromotionsPath]}
        isPartner={!!isPartner}
      >
        <PromotionsList />
      </NotPartnerRoute>
      <CommonRoute exact path={vendorPathOld}>
        <VendorRedirector />
      </CommonRoute>
      <CommonRoute exact path={vendorPath}>
        <VendorContainer />
      </CommonRoute>
      <NotPartnerRoute exact path={venuePathOld} isPartner={!!isPartner}>
        <VenueRedirector />
      </NotPartnerRoute>
      <NotPartnerRoute exact path={venuePath} isPartner={!!isPartner}>
        <VenueContainer />
      </NotPartnerRoute>
      <NotPartnerRoute exact path={marketplacePathOld} isPartner={!!isPartner}>
        <Redirect to={marketplacePath} />
      </NotPartnerRoute>
      <NotPartnerRoute exact path={marketplacePath} isPartner={!!isPartner}>
        <Marketplace />
      </NotPartnerRoute>
      <Route
        path={termsPathDeprecated}
        render={() =>
          (window.location.href = EXTERNAL_LINKS.TERMS_AND_CONDITIONS)
        }
      />
      <CommonRoute path={messagesPath}>
        <Messages />
      </CommonRoute>
      <GuestAuthRoute
        role={role}
        accessRoles={[UserRoles.Client]}
        path={inquiryPath}
      >
        <CreateInquiryRoutes isAbs={!!isAbs} />
      </GuestAuthRoute>
      <GuestAuthRoute
        role={role}
        accessRoles={[UserRoles.Client]}
        path={eventPath}
      >
        <ClientEventDetails />
      </GuestAuthRoute>
      <GuestAuthRoute
        role={role}
        accessRoles={[UserRoles.Client]}
        path={eventsListPath}
      >
        <EventList />
      </GuestAuthRoute>
      <CommonRoute path={clientCalendarPath}>
        <ClientCalendarPage />
      </CommonRoute>
      <AuthRoute
        role={role}
        accessRoles={[UserRoles.Client]}
        path={profilePath}
      >
        <Profile />
      </AuthRoute>
      <AuthRoute
        role={role}
        accessRoles={dashboardRoles}
        path={[dashboardPath, dashboardListingPath]}
      >
        <DashboardRoutes role={role} />
        {/*<PartnerRedirector />*/}
      </AuthRoute>
      <CommonRoute exact path="*">
        <div style={{ width: "100%", height: "calc(100vh - 240px)", background: "#FFF" }} />
      </CommonRoute>
    </Switch>
  )
}

const CreateInquiryRoutes: React.FC<{ isAbs: boolean }> = ({ isAbs }) => {
  return (
    <Switch>
      <Route
        exact
        path={inquiryPath}
        render={() => <Inquiry isAbs={isAbs} />}
      />
    </Switch>
  )
}

const DashboardRoutes: React.FC<{ role?: UserRoles }> = ({ role }) => {
  const { authUser } = useAuthData()
  const isLandlord = UserRoles.isLandlord(authUser?.role)
  const { listing_id } = authUser || { listing_id: null }
  const isRoutesAvailable = isLandlord
    ? authUser?.venues?.find((venue) => venue.id === listing_id)
    : authUser?.vendors?.find((vendor) => vendor.id === listing_id)

  if (listing_id && isRoutesAvailable)
    return (
      <Switch>
        <Route exact path={dashboardBookingsPath}>
          <Bookings />
        </Route>
        <Route path={dashboardBookingDetailsPath}>
          <BookingDetails />
        </Route>
        <Route path={dashboardBusinessDetailsPath}>
          <BusinessDetails role={role} />
        </Route>
        <Route exact path={generateInvoicesPath}>
          <GenerateDefaultInvoicesContainer />
        </Route>
        <Route exact path={dashboardBusinessFilesPath}>
          <DashboardFiles />
        </Route>
        <Route path={dashboardProfilePath}>
          <Profile />
        </Route>
        <Route exact path={[dashboardPath, dashboardListingPath]}>
          <Dashboard role={role} />
        </Route>
      </Switch>
    )
  else if (listing_id && !isRoutesAvailable) {
    return (
      <Switch>
        <Route exact path={dashboardPath}>
          <Dashboard role={role} />
        </Route>
        <Route path={dashboardProfilePath}>
          <Profile />
        </Route>
        <Route exact path={dashboardBusinessFilesPath}>
          <DashboardFiles />
        </Route>
        <Route exact path="*">
          <NotAvailablePage type="403" />
        </Route>
      </Switch>
    )
  } else
    return (
      <Switch>
        <Route path={dashboardProfilePath}>
          <Profile />
        </Route>
        <Route exact path={dashboardPath}>
          <NoBusiness role={role} />
        </Route>
      </Switch>
    )
}

const OnboardingRoutes: React.FC<{ role?: UserRoles; isPartner: boolean }> = ({
  role,
  isPartner,
}) => {
  return (
    <Switch>
      {/*<Route exact path={onboardingPath}>*/}
      {/*  <Onboarding />*/}
      {/*</Route>*/}
      <GuestAuthRoute
        exact
        path={onboardingRolePath}
        role={role}
        accessRoles={[UserRoles.Client]}
        isPartner={isPartner}
        availableForPartners={false}
      >
        <ChooseRole />
      </GuestAuthRoute>
      <GuestAuthRoute
        exact
        path={onboardingVendorPath}
        role={role}
        accessRoles={[UserRoles.Client, UserRoles.Vendor]}
        isPartner={isPartner}
        availableForPartners={false}
      >
        <StepsConstructor type="vendor" />
      </GuestAuthRoute>
      <GuestAuthRoute
        exact
        path={onboardingVenuePath}
        role={role}
        accessRoles={[UserRoles.Client, UserRoles.Landlord]}
        isPartner={isPartner}
        availableForPartners={false}
      >
        <StepsConstructor type="venue" />
      </GuestAuthRoute>
      <CommonRoute exact path="*">
        <NotAvailablePage />
      </CommonRoute>
    </Switch>
  )
}

export const VenueRedirector = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const { isLoading, venue } = useVenueById(id)
  if (isLoading) return <Loader />
  if (venue?.slug) {
    // console.log(venue.slug, 'redirect log 2')
    return (
      <Redirect
        to={generatePath(venuePath, {
          slug: venue.slug,
        })}
      />
    )
  }

  return <NotAvailablePage />
}

export const VendorRedirector = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const { isLoading, data } = useVendorByIdNew(id)
  if (isLoading) return <Loader />
  if (data?.slug) {
    // console.log(venue.slug, 'redirect log 3')
    return <Redirect to={generatePath(vendorPath, { slug: data.slug })} />
  }

  return <NotAvailablePage />
}

export default Router
