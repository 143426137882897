import { keys } from 'lodash/fp'
import { useState, useEffect, useCallback } from 'react'
import { usePrefetchedLocations } from 'utils/api/locations'

import { LsGetPreferredCity, LsSetPreferredCity } from '../utils/storage'

export function usePreferredCity(): {
  citySlug: string
  setPreferredCity: (citySlug: string) => void
} {
  const locations = usePrefetchedLocations()
  const availableCitySlugs = keys(locations.entities.cities)

  const savedCity = LsGetPreferredCity()
  const citySlug =
    savedCity && availableCitySlugs.includes(savedCity)
      ? savedCity
      : locations.defaultCity

  const setPreferredCity = useCallback((city: string) => {
    LsSetPreferredCity(city)
  }, [])

  return { citySlug, setPreferredCity }
}
