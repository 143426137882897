import { Loader } from 'components/ui/Loader'
import { isValid } from 'date-fns'
import { useCreateOrDuplicateEvent } from 'hooks/useCreateOrDuplicateEvent'
import { useSimpleModal } from 'hooks/useSimpleModal'
import { omit, pick } from 'lodash/fp'
import { useTranslation } from 'react-i18next'
import { LocalEvent } from 'types/Event'
import { ProfileData } from 'types/Profile'
import { VENUE_BUDGET } from 'utils/helpers/inquiries'
import {
  LsGetInquiryFormValues,
  LsSetInquiryDetails,
  LsSetInquiryFormValues,
} from 'utils/storage'
import { v4 as uuid } from 'uuid'

import { EventDraftStatus } from '../../../../../constants'
import { InquiryStatuses } from '../../../../../constants/inquiry'
import { Room } from '../../../../../types/Venue'
import { updateEventInquiries } from '../../../../../utils/api/marketplace'
import { convertDateTimeToServerFormat } from '../../../../../utils/helpers/date'
import {
  convertInquiryEventToUserEvent,
  prepareInquiryData,
  venueToEventVenueMapper,
} from '../../../../../utils/mappers/inquiries'
import { useAuthData } from '../../../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../../../utils/providers/EventsProvider'
import { useMessageModalData } from '../../../../../utils/providers/MessageModalProvider'
import tracking from '../../../../../utils/tracking'

import { InquiryCreateAuthModals } from './InquiryCreateAuthModals'
import { InquiryCreateForm } from './InquiryCreateForm'
import { VenueInquiryFormValues } from './validationSchema'

type InquiryCreateModalProps = {
  onClose: () => void
  venue: any
  isEdit?: boolean
  rooms?: Room[]
}

function buildInquiry(
  formValues: VenueInquiryFormValues,
  user: ProfileData | null,
  venue: any,
  rooms?: Room[]
) {
  return {
    ...pick(['name', 'guests', 'notes'], formValues),
    user_name: user?.name,
    email: user?.email,
    venue_id: venue?.id,
    venue_type: venue?.client_owned ? 'client' : 'blace-app',
    client_venue: venue?.client_venue
      ? {
          ...venue.client_venue,
          placeId: venue?.client_venue?.location.place_id,
          location: venue?.client_venue?.location.description,
        }
      : undefined,
    venue,
    rooms,
    start_date: formValues.dates.start,
    end_date: formValues.dates.end,
    is_flexible_date: formValues.dates.flexible,
    budgets: [
      {
        title: VENUE_BUDGET,
        value: formValues.budget,
      },
    ],
  }
}

export type VenueInquiry = ReturnType<typeof buildInquiry>

function storeValues(inquiry: VenueInquiry) {
  LsSetInquiryFormValues({
    ...inquiry,
    eventDates: [inquiry.start_date, inquiry.end_date],
    flexibleDates: inquiry.is_flexible_date,
    venueBudget: inquiry.budgets[0].value,
  })
}

const safeParseDate = (date: string | null | undefined) => {
  if (!date) return null
  const parsed = new Date(date)
  return isValid(parsed) ? parsed : null
}

function storedValues(): (VenueInquiry & VenueInquiryFormValues) | null {
  const inquiry = LsGetInquiryFormValues()
  if (!inquiry) return null

  return {
    ...inquiry,
    dates: {
      start: safeParseDate(inquiry.eventDates?.[0]),
      end: safeParseDate(inquiry.eventDates?.[1]),
      flexible: Boolean(inquiry.flexibleDates),
    },
    budget: inquiry.venueBudget,
  }
}

export function serializeInquiry(inquiry: VenueInquiry) {
  return {
    ...pick(
      [
        'user_name',
        'email',
        'client_venue',
        'budgets',
        'name',
        'guests',
        'is_flexible_date',
        'notes',
        'rooms',
        'venue',
        'venue_type',
        'venue_id',
        'packages',
        'vendors',
        'type',
      ],
      inquiry
    ),
    start_date: convertDateTimeToServerFormat(inquiry.start_date),
    end_date: convertDateTimeToServerFormat(inquiry.end_date),
  }
}

function shouldCreateUpdateRequest(
  userEvent: LocalEvent,
  inquiry: VenueInquiry
) {
  const vendors = userEvent?.vendors || []
  const inquiryHasAcceptedVendors = vendors.some(
    (vendor: any) =>
      vendor.status !== InquiryStatuses.STATUS_DRAFT &&
      vendor.status !== InquiryStatuses.STATUS_PENDING_SUBMIT &&
      vendor.status !== InquiryStatuses.STATUS_INQUIRING
  )
  const inquiryHasAcceptedVenue =
    inquiry.venue?.status !== InquiryStatuses.STATUS_DRAFT &&
    inquiry.venue?.status !== InquiryStatuses.STATUS_PENDING_SUBMIT &&
    inquiry.venue?.status !== InquiryStatuses.STATUS_INQUIRING
  const shouldCreateUpdateRequest =
    inquiryHasAcceptedVenue || inquiryHasAcceptedVendors
  return shouldCreateUpdateRequest
}

const useEventActions = (event: LocalEvent) => {
  const { handleCreateOrDuplicateEvent } = useCreateOrDuplicateEvent()

  const createEvent = async (inquiry: VenueInquiry) => {
    const inquiryData = prepareInquiryData(event, serializeInquiry(inquiry))
    await handleCreateOrDuplicateEvent(inquiryData)
    trackEventCreated(inquiry)
  }

  const trackEventCreated = (inquiry: VenueInquiry) => {
    const eventVenue = venueToEventVenueMapper(inquiry.venue)
    tracking.createdEvent({
      ...omit(['vendors', 'client_venue'], inquiry),
      id: uuid(),
      venue: { ...eventVenue, rooms: inquiry.rooms },
    })
  }

  const updateEvent = async (inquiry: VenueInquiry) => {
    const data = await updateEventInquiries({
      ...prepareInquiryData(event, serializeInquiry(inquiry)),
      id: event.id,
      status: event.status,
    })
    const updatedEvent = convertInquiryEventToUserEvent({
      ...data,
      inquiry: data.inquiries_related[0],
    })
    return updatedEvent
  }

  return {
    createEvent,
    updateEvent,
  }
}

export const InquiryCreateFormController = ({
  onClose,
  venue,
  isEdit,
  rooms,
}: InquiryCreateModalProps) => {
  const { t } = useTranslation()
  const { authUser: user } = useAuthData()
  const { updateUserActiveEvent, getUserActiveEvent, isLoading, setIsLoading } =
    useStorageEventsData()
  const { showMessage } = useMessageModalData()
  const userEvent = getUserActiveEvent()
  const { createEvent, updateEvent } = useEventActions(userEvent)

  const handleUpdate = async (inquiry: VenueInquiry) => {
    setIsLoading(true)
    const updatedEvent = await updateEvent(inquiry)
    updateUserActiveEvent({ changed: false, ...updatedEvent })
    setIsLoading(false)
  }

  const createUpdateRequest = async (inquiry: VenueInquiry) => {
    showMessage({
      title: t('messages.inquiry.request.update', 'Update your event details?'),
      text: t(
        'messages.inquiry.request.updateSend',
        'If you click "Yes" your change request will be submitted for review. You will be contacted if there are any issues with your changes.'
      ),
      onAccept: async () => {
        await handleUpdate(inquiry)
        showMessage({
          title: t(
            'messages.inquiry.request.sent',
            'Your request has been successfully sent!'
          ),
        })
      },
    })
  }

  const handleSubmit = async (inquiry: VenueInquiry) => {
    if (user) {
      setIsLoading(true)
      await createEvent(inquiry)
      setIsLoading(false)
    }
  }

  const offerToLoginModal = useSimpleModal()

  const saveInquiry = async (formValues: VenueInquiryFormValues) => {
    const inquiry = buildInquiry(formValues, user, venue, rooms)
    storeValues(inquiry)
    LsSetInquiryDetails(inquiry)

    if (!user) {
      offerToLoginModal.toggleModal()
    } else {
      try {
        const isNewEvent = !isEdit || userEvent?.status === EventDraftStatus
        if (isNewEvent) {
          await handleSubmit(inquiry)
        } else if (shouldCreateUpdateRequest(userEvent, inquiry)) {
          createUpdateRequest(inquiry)
        } else {
          await handleUpdate(inquiry)
          showMessage({
            title: t(
              'messages.events.updated',
              'Your event details have been updated!'
            ),
          })
        }
        onClose()
      } catch (e) {
        showMessage({
          type: 'error',
          title: t('common.error.title', 'Something went wrong.'),
          text: t(
            'common.error.subtitle',
            'We are looking to see what happened'
          ),
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  if (isLoading) return <Loader position="fixed" />
  const storedInquiry = storedValues()
  const initialValues =
    storedInquiry && storedInquiry.venue?.id === venue?.id ? storedInquiry : {}

  return (
    <>
      <InquiryCreateAuthModals
        {...offerToLoginModal}
        onGuestSubmit={() => setIsLoading(true)}
        onGuestError={() => {
          setIsLoading(false)
        }}
        onGuestSuccess={() => {
          setIsLoading(false)
          onClose()
        }}
        onLogin={async () => {
          setIsLoading(true)
          await createEvent(LsGetInquiryFormValues())
          setIsLoading(false)
        }}
      />
      <InquiryCreateForm
        initialValues={initialValues}
        onSubmit={saveInquiry}
        venue={venue}
        rooms={rooms}
      />
    </>
  )
}
