const NoActivity = () => {
  return (
    <svg width="68" height="63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="31.042" cy="30.55" fill="#F1F1F1" r="30.085" />
      <path
        d="M47.787 30.602c0 9.384-7.607 16.99-16.99 16.99-9.384 0-16.99-7.606-16.99-16.99 0-9.383 7.606-16.99 16.99-16.99 9.383 0 16.99 7.607 16.99 16.99Z"
        fill="#F1F1F1"
        stroke="#9396A3"
        strokeWidth="2"
      />
      <path
        d="M42.647 30.602c0 6.545-5.306 11.85-11.85 11.85-6.545 0-11.85-5.305-11.85-11.85s5.305-11.85 11.85-11.85c6.544 0 11.85 5.305 11.85 11.85Z"
        fill="#FFFEFF"
        stroke="#9396A3"
        strokeWidth="2"
      />
      <path
        d="M30.796 21.155a.333.333 0 0 0-.341-.336 9.789 9.789 0 0 0-9.398 8.795c-.019.188.124.351.313.363.188.013.35-.13.37-.317a9.105 9.105 0 0 1 8.715-8.157.352.352 0 0 0 .341-.348Z"
        fill="#9396A3"
      />
      <rect
        x="-1.414"
        width="7.03"
        height="18.767"
        rx="3"
        transform="rotate(-45 81.098 -29.584)"
        fill="#F1F1F1"
        stroke="#9396A3"
        strokeWidth="2"
      />
      <rect
        x="50.687"
        y="51.092"
        width=".817"
        height="6.848"
        rx=".408"
        transform="rotate(-45 50.687 51.092)"
        fill="#fff"
      />
      <path fill="#9396A3" d="m43.897 45.527.593-.593 2.37 2.371-.592.593z" />
      <rect
        x="52.792"
        y="28.491"
        width="8.383"
        height=".838"
        rx=".419"
        fill="#9396A3"
      />
      <rect
        x=".957"
        y="35.426"
        width="8.383"
        height=".838"
        rx=".419"
        fill="#9396A3"
      />
      <rect
        x="62.852"
        y="28.491"
        width="4.192"
        height=".838"
        rx=".419"
        fill="#9396A3"
      />
      <rect
        x="54.888"
        y="31.567"
        width="4.192"
        height=".838"
        rx=".419"
        fill="#9396A3"
      />
      <rect
        x="3.053"
        y="38.502"
        width="4.192"
        height=".838"
        rx=".419"
        fill="#9396A3"
      />
    </svg>
  )
}

export default NoActivity
