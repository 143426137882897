import {
  Button,
  IconButton,
  styled,
  Table,
  TableCell,
  TableRow,
} from '@material-ui/core'
import React from 'react'

export const StyledTable = styled(Table)`
  border-collapse: separate;
`

export const StyledHeaderCell = styled(TableCell)(
  ({ theme }) => `
  padding: 4px 9px;
  background: ${theme.palette.background.paper};
  border-bottom: none;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
)

export const StyledInvoiceHeaderFirstRowCell = styled(TableCell)`
  padding: 15px 10px;
  border: none;
  border-top: 2px solid #f0f0f0;

  &:first-of-type {
    border-left: 2px solid #f0f0f0;
    border-top-left-radius: 4px;
  }
  &:last-of-type {
    border-right: 2px solid #f0f0f0;
    border-top-right-radius: 4px;
  }
`

export const StyledInvoiceHeaderSecondRowCell = styled(TableCell)(
  ({ theme }) => `
  padding: 4px 10px;
  background: ${theme.palette.background.paper};
  color: ${theme.palette.grey[200]};
  border-bottom: none;
  
  &:first-of-type {
    border-left: 2px solid #f0f0f0;
    padding-left: 10px;
  }
  &:last-of-type {
    border-right: 2px solid #f0f0f0;
    padding-right: 10px;
  }
`
)

export const StyledFirstRow = styled(TableRow)`
  &.wide-bordered-top {
    td {
      border-top: 2px solid #f0f0f0;
    }
  }
  &.wide-bordered-bottom {
    td {
      border-bottom: 2px solid #f0f0f0;

      &:first-of-type {
        border-bottom-left-radius: 4px;
      }

      &:last-of-type {
        border-bottom-right-radius: 4px;
      }
    }
  }
`

export const StyledFirstRowCell = styled(TableCell)`
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;

  &:first-of-type {
    border-bottom: none;
    border-left: 2px solid #f0f0f0;
    border-top-left-radius: 4px;
  }
  &:last-of-type {
    border-right: 2px solid #f0f0f0;
    border-top-right-radius: 4px;
  }
`

export const StyledSecondRowCell = styled(TableCell)`
  padding: 10px;
  border-bottom: 2px solid #f0f0f0;

  &:first-of-type {
    border-left: 2px solid #f0f0f0;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-right: 2px solid #f0f0f0;
    border-bottom-right-radius: 4px;
  }
`

export const StyledInvoiceCell = styled(TableCell)`
  padding: 10px;

  &:first-of-type {
    border-left: 2px solid #f0f0f0;
  }
  &:last-of-type {
    border-right: 2px solid #f0f0f0;
  }
`

export const StyledInvoiceFooterCell = styled(TableCell)(
  ({ theme }) => `
  padding: 15px 10px;
  border-bottom: 2px solid #F0F0F0;
  color: ${theme.palette.common.black};
 
  &:first-of-type {
    border-left: 2px solid #F0F0F0;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-right: 2px solid #F0F0F0;
    border-bottom-right-radius: 4px;
  }
`
)

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  background: ${theme.palette.background.paper};
  width: 24px;
  height: 24px;
  margin-left: 16px;
  
  &:first-of-type {
    margin-left: 0;
  }
  
  &.Mui-disabled {
    background-color: ${theme.palette.background.paper};
  }

  &:hover {
    opacity: 0.8;
  }
`
)

export const StyledSignIconButton = styled(IconButton)(
  ({ theme }) => `
  padding: 0;
  margin-left: 8px;

  &.Mui-disabled {
    background-color: ${theme.palette.background.paper};
  }

  &:hover {
    opacity: 0.8;
  }
`
)
