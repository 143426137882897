const EventIcon = () => {
  return (
    <svg
      width="37"
      height="40"
      viewBox="0 0 37 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6167 22.3C28.6167 22.1343 28.4824 22 28.3167 22H18.9167C18.751 22 18.6167 22.1343 18.6167 22.3V31.7C18.6167 31.8657 18.751 32 18.9167 32H28.3167C28.4824 32 28.6167 31.8657 28.6167 31.7V22.3ZM26.9167 0C26.751 0 26.6167 0.134315 26.6167 0.3V3.7C26.6167 3.86569 26.4824 4 26.3167 4H10.9167C10.751 4 10.6167 3.86569 10.6167 3.7V0.3C10.6167 0.134315 10.4824 0 10.3167 0H6.9167C6.75101 0 6.6167 0.134315 6.6167 0.3V3.7C6.6167 3.86569 6.48238 4 6.3167 4H0.936533C0.770912 4 0.636625 4.13421 0.636533 4.29983L0.616866 39.6998C0.616774 39.8656 0.751115 40 0.916866 40H36.3167C36.4824 40 36.6167 39.8657 36.6167 39.7V4.3C36.6167 4.13431 36.4824 4 36.3167 4H30.9167C30.751 4 30.6167 3.86569 30.6167 3.7V0.3C30.6167 0.134315 30.4824 0 30.3167 0H26.9167ZM32.6167 35.7C32.6167 35.8657 32.4824 36 32.3167 36H4.9167C4.75101 36 4.6167 35.8657 4.6167 35.7V14.3C4.6167 14.1343 4.75101 14 4.9167 14H32.3167C32.4824 14 32.6167 14.1343 32.6167 14.3V35.7Z"
        fill="white"
      />
    </svg>
  )
}

export default EventIcon
