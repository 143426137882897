import { Box, styled, Typography } from '@material-ui/core'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentListItem } from '../../../components/Documents/DocumentListItem'
import NoDocuments from '../../../components/svg/NoDocuments'
import { SimpleButton } from '../../../components/ui/Buttons/SimpleButton'
import { DocumentsUploaderField } from '../../../components/ui/DocumentsUploaderField'
import { Loader } from '../../../components/ui/Loader'
import { DashboardTitle } from '../../../components/ui/PageTitle'
import { ALLOWED_DOCUMENT_FORMATS } from '../../../types/FileUploader'
import { DocumentFile } from '../../../types/general'
import { removeUserFile, uploadUserFile } from '../../../utils/api/profile'
import { downloadUserFile } from '../../../utils/helpers/files'
import { useAuthData } from '../../../utils/providers/AuthProvider'

const DocumentsContainer = styled(Box)`
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`

const FilesGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  width: 100%;
  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const DashboardFiles = () => {
  const { isLoading, authUser, updateUser } = useAuthData()
  const inputFileRef = React.useRef(null)
  const [documents, setDocuments] = React.useState<DocumentFile[]>([])
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()
  const onAddClick = () => {
    if (inputFileRef && inputFileRef.current) {
      // @ts-ignore
      inputFileRef.current.click()
    }
  }

  const handleDocumentsSet = (docs: DocumentFile[]) => {
    setDocuments([...docs].reverse())
  }

  useEffect(() => {
    if (authUser?.user_files) {
      handleDocumentsSet(authUser?.user_files)
    }
  }, [authUser?.user_files])

  const onUploadDocument = React.useCallback(
    async (event) => {
      setLoading(true)
      try {
        const attachment = event.target.files[0]
        const data = await uploadUserFile(attachment)

        updateUser({ user_files: [...(authUser?.user_files || []), data] })
        handleDocumentsSet([...(authUser?.user_files || []), data])
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [authUser?.user_files]
  )

  const onRemoveDocument = React.useCallback(
    async (fileID: number) => {
      setLoading(true)
      try {
        const data = await removeUserFile(fileID)

        updateUser({
          user_files: authUser?.user_files?.filter(
            (file) => file.id !== data.id
          ),
        })
        handleDocumentsSet(
          authUser?.user_files?.filter((file) => file.id !== data.id) || []
        )
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [authUser?.user_files]
  )

  const downloadDocument = async (item: DocumentFile) => {
    const response = await downloadUserFile(item.id)
    window.open(response, '_blank')
  }

  return (
    <Box
      width={1}
      minHeight={800}
      style={{ paddingLeft: 30, paddingRight: 30, position: 'relative' }}
    >
      <DashboardTitle mb={3} title="Files" showListingPicker={false} />
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <DocumentsUploaderField
          accept={ALLOWED_DOCUMENT_FORMATS}
          onChange={(e) => onUploadDocument(e)}
          inputFileRef={inputFileRef}
        >
          <Box>
            <SimpleButton
              onClick={onAddClick}
              style={{ fontSize: 16 }}
              variant="outlined"
            >
              {t('business.files.add', '+ Add File')}
            </SimpleButton>
          </Box>
        </DocumentsUploaderField>
      </Box>
      {documents.length > 0 ? (
        <FilesGrid>
          {documents.map((item: DocumentFile) => (
            <DocumentListItem
              key={item.id}
              document={item}
              onDownload={() => downloadDocument(item)}
              onRemove={() => onRemoveDocument(+item.id)}
            />
          ))}
        </FilesGrid>
      ) : (
        <>
          <DocumentsContainer>
            <NoDocuments />
          </DocumentsContainer>
          <Box display="flex" justifyContent="center" mt={3}>
            <Typography variant="body2" align="center" color="secondaryText">
              {t('business.files.empty', 'Here documents will be displayed')}
            </Typography>
          </Box>
        </>
      )}
      {(isLoading || loading) && <Loader position="absolute" />}
    </Box>
  )
}
