import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Question from '../../../../components/svg/Question'
import LinkText from '../../../../components/ui/Buttons/LinkText/LinkText'
import { TooltipComponent } from '../../../../components/ui/Tooltip'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import VendorsSlider from '../../../../pages/vendors/components/VendorsSlider/VendorsSlider'
import { Vendor } from '../../../../types/Vendor'

export const ExclusiveVendors: React.FC<{
  vendorsExclusive?: Vendor[]
  inhouseVendorTypes?: string[]
  vendorsPreferred?: Vendor[]
  openExclusiveInfo: (open: boolean, title: string, subtitle: string) => void
}> = ({
  vendorsExclusive,
  inhouseVendorTypes,
  vendorsPreferred,
  openExclusiveInfo,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  const tooltipBox = (title: string, subtitle: string) => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="body2">{title}</Typography>
      <LinkText
        style={{ fontSize: 14 }}
        variant="body2"
        onClick={() => openExclusiveInfo(true, title, subtitle)}
      >
        {t('common.button.more', 'Know more')}
      </LinkText>
    </Box>
  )

  return (
    <Box id="vendors">
      <Box className={isMobile ? 'container' : ''}>
        {inhouseVendorTypes && !!inhouseVendorTypes.length && (
          <Box id="in-house" className="section-block">
            <Box display="flex">
              <Typography
                style={{ textTransform: 'capitalize' }}
                fontWeight="bold"
                align="left"
                variant="h2"
              >
                {t('vendor.inhouses.name', 'In-house vendors')}
              </Typography>
              <TooltipComponent
                placement="top"
                title={tooltipBox(
                  t('vendor.inhouses.name', 'In-house vendors'),
                  t(
                    'vendor.inhouses.description',
                    'The vendors who are directly connected with this event. They are obliged to participate in the event. But if you want to replace them with others or refuse their services, you have to pay the Buy out fee'
                  )
                )}
              >
                <Box style={{ cursor: 'pointer' }} mt="-5px" ml="2px">
                  <Question fill="#9396A3" />
                </Box>
              </TooltipComponent>
            </Box>
            <Box sx={{ paddingLeft: 4 }}>
              {inhouseVendorTypes.map((type) => (
                <Typography
                  key={type}
                  sx={{
                    textTransform: 'capitalize',
                    display: 'list-item',
                    my: '5px',
                    mb: '10px',
                    fontWeight: 500,
                  }}
                  variant="body1"
                >
                  {type}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </Box>
      {vendorsExclusive && !!vendorsExclusive.length && (
        <Box id="standard" width={1} mt="56px">
          <VendorsSlider
            tooltipBox={tooltipBox(
              t('vendor.exclusives.name', 'Exclusive vendors'),
              t(
                'vendor.exclusives.description',
                'The vendors who are directly connected with this event. They are obliged to participate in the event. But if you want to replace them with others or refuse their services, you have to pay the Buy out fee'
              )
            )}
            withCategoryName={true}
            slidesPerView={!isMobile ? 4 : undefined}
            slideWidth={288}
            slideImageHeight={276}
            nextNavButtonClass="exclusive_next_button"
            prevNavButtonClass="exclusive_prev_button"
            data={vendorsExclusive}
            sliderTitle={t('vendor.exclusives.name', 'Exclusive vendors')}
            withoutEndFrame={!!isMobile}
          />
        </Box>
      )}
      {vendorsPreferred && !!vendorsPreferred.length && (
        <Box id="preferred" width={1} mt="18px">
          <VendorsSlider
            tooltipBox={tooltipBox(
              t('vendor.preferreds.name', 'Preferred vendors'),
              t(
                'vendor.preferreds.description',
                'The vendors who are directly connected with this event. They are obliged to participate in the event. But if you want to replace them with others or refuse their services, you have to pay the Buy out fee'
              )
            )}
            withCategoryName={true}
            slidesPerView={!isMobile ? 4 : undefined}
            slideWidth={288}
            slideImageHeight={276}
            nextNavButtonClass="preferred_next_button"
            prevNavButtonClass="preferred_prev_button"
            data={vendorsPreferred}
            sliderTitle={t('vendor.preferreds.name', 'Preferred vendors')}
            withoutEndFrame={!!isMobile}
          />
        </Box>
      )}
    </Box>
  )
}
