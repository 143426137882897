import React, { useState } from 'react'

import { BusinessContactData } from '../../../../../types/general'
import { BlockContainer } from '../../BusinessDetails.styles'

import { BusinessContactForm } from './BusinessContactForm'
import { BusinessContactInfo } from './BusinessContactInfo'

export const BusinessContact: React.FC<{
  onSave: (values: BusinessContactData) => void
  data: BusinessContactData
}> = ({ onSave, data }) => {
  const [isEditMode, setEditMode] = useState(false)

  return (
    <BlockContainer display="flex" flexDirection="column">
      {isEditMode ? (
        <BusinessContactForm
          onSave={onSave}
          data={data}
          onChangeMode={() => setEditMode(false)}
        />
      ) : (
        <BusinessContactInfo data={data} onEdit={() => setEditMode(true)} />
      )}
    </BlockContainer>
  )
}
