import { useEffect, useRef } from 'react'

export function useEventListener(
  eventName: any,
  handler: any,
  element = window
) {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return
    const eventListener = (event: any) =>
      // @ts-ignore
      savedHandler?.current ? savedHandler?.current(event) : () => {}
    element.addEventListener(eventName, eventListener)
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
