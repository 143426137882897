import i18n from 'i18next'

export enum InquiryStatuses {
  STATUS_DRAFT = 'draft',
  STATUS_PENDING_SUBMIT = 'pendingSubmit',
  STATUS_INQUIRING = 'inquiring',
  STATUS_ABANDONED = 'abandoned',
  STATUS_PROPOSING = 'proposing',
  STATUS_FEEASSESSMENT = 'feeAssessment',
  STATUS_PROPOSED = 'proposed',
  STATUS_DECLINED = 'declined',
  STATUS_TOUR = 'tour',
  STATUS_PAYMENT = 'payment',
  STATUS_ACCEPTED = 'accepted',
  STATUS_CONFIRMED = 'confirmed',
  STATUS_VENUE_CONTRACT_PENDING = 'venueContractPending',
  STATUS_VIEWED = 'viewed',
  STATUS_CHANGEORDER = 'changeOrder',
  STATUS_CANCELED = 'canceled',
  STATUS_PRODUCTION = 'production',
  STATUS_COMPLETED = 'completed',
}

export enum InquiryStages {
  STAGE_PLANING = 'planning',
  STAGE_PROPOSING = 'proposing',
  STAGE_INVOICING = 'invoicing',
  STAGE_PRODUCTION = 'production',
  STAGE_COMPLETED = 'completed',
}

export const STAGES_AFTER_CONTRATING = [
  InquiryStages.STAGE_PRODUCTION,
  InquiryStages.STAGE_COMPLETED,
]

export const isInquiryConfirmed = (status: InquiryStatuses) =>
  [
    InquiryStatuses.STATUS_CONFIRMED,
    InquiryStatuses.STATUS_PRODUCTION,
  ].includes(status)

export const UNLOCK_VENDOR_STATUSES = [
  InquiryStatuses.STATUS_PRODUCTION,
  InquiryStatuses.STATUS_CONFIRMED,
]

export enum InquiryContractStatuses {
  STATUS_COMPLETED = 'completed', // business and client signed a contract
  STATUS_OUTDATED = 'outdated', // new version was created, parent contract turns to this status
}

export enum InquiryInvoiceBundleStatuses {
  STATUS_DRAFT = 'draft', // bundle was created but not sent to the client yet
  STATUS_SENT = 'sent', // bundle was sent to the client to be paid
  STATUS_OUTDATED = 'outdated', // new version was created, parent bundle turns to this status
}

export enum InquiryInvoiceStatuses {
  STATUS_PENDING = 'pending', // invoice was generated to be paid by the client
  STATUS_WAITING = 'waiting', // client initiated payment process, payment gateway in progress
  STATUS_PAID = 'paid', // client successfully paid an invoice
}

export const INQUIRY_STAGE_PIPELINE = [
  InquiryStages.STAGE_PLANING,
  InquiryStages.STAGE_PROPOSING,
  InquiryStages.STAGE_INVOICING,
  InquiryStages.STAGE_PRODUCTION,
  InquiryStages.STAGE_COMPLETED,
]

export const EVENT_STAGES_PROGRESS = {
  [InquiryStages.STAGE_PLANING]: [
    InquiryStatuses.STATUS_DRAFT,
    InquiryStatuses.STATUS_PENDING_SUBMIT,
  ],
  [InquiryStages.STAGE_PROPOSING]: [
    InquiryStatuses.STATUS_PROPOSING,
    InquiryStatuses.STATUS_FEEASSESSMENT,
    InquiryStatuses.STATUS_PROPOSED,
  ],
  [InquiryStages.STAGE_INVOICING]: [
    InquiryStatuses.STATUS_ACCEPTED,
    InquiryStatuses.STATUS_CONFIRMED,
  ],
  [InquiryStages.STAGE_PRODUCTION]: [InquiryStatuses.STATUS_PRODUCTION],
  [InquiryStages.STAGE_COMPLETED]: [InquiryStatuses.STATUS_COMPLETED],
}
export const mapStageToTitle: Record<InquiryStages, string> = {
  [InquiryStages.STAGE_PLANING]: i18n.t(
    'business.inquiry.stageProgress.planing',
    'Inquiring'
  ),
  [InquiryStages.STAGE_PROPOSING]: i18n.t(
    'business.inquiry.stageProgress.proposing',
    'Proposing'
  ),
  [InquiryStages.STAGE_INVOICING]: i18n.t(
    'business.inquiry.stageProgress.invoicing',
    'Invoicing'
  ),
  [InquiryStages.STAGE_PRODUCTION]: i18n.t(
    'business.inquiry.stageProgress.production',
    'Production'
  ),
  [InquiryStages.STAGE_COMPLETED]: i18n.t(
    'business.inquiry.stageProgress.completed',
    'Event complete'
  ),
}

export enum InquiryExclusivityTypes {
  IN_HOUSE = 'in-house',
  STANDARD = 'standard',
  PREFERRED = 'preferred',
}

export const DEFAULT_LINE_ITEMS = [
  {
    value: '',
    quantity: 1,
    clarification: '',
    groupName: '',
    groupDescription: '',
  },
]
