export const MIN_COMMENT_LENGTH = 10
export const MAX_COMMENT_LENGTH = 500
export const MIN_NAME_LENGTH = 2
export const MAX_NAME_LENGTH = 22
export const INITIAL_SEARCH_PARAMS = {
  guests: null,
  startDate: null,
  endDate: null,
  labels: null,
  eventType: null,
  neighborhood: null,
  city: null,
  categories: null,
  map: false,
  mapExpanded: false,
  location: null,
  lat: null,
  lng: null,
  zoom: null,
  name: null,
  page: 1,
  per_page: 16,
  ceiling_height_min: null,
  ceiling_height_max: null,
  sq_footage_min: null,
  sq_footage_max: null,
  orderBy: 'name',
}

export const INITIAL_QUERY_PARAMS = {
  ...INITIAL_SEARCH_PARAMS,
  labels: '',
  page: 1,
  perPage: 16,
  ceiling_height_min: '',
  ceiling_height_max: '',
  sq_footage_min: '',
  sq_footage_max: '',
  price: '',
  orderDirection: '',
  categories: '',
  orderBy: '',
  guests_max: '',
  guests_min: '',
  location: '',
  guests: '',
}

export const MARKETPLACE_SORTING_FIELDS = ['Name', 'Price']
