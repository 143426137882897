/** @jsxImportSource @emotion/react */

import { Typography } from '@material-ui/core'
import { Selection, useCheckbox } from 'components/ui/Checkbox'
import { useSimpleModal } from 'hooks/useSimpleModal'
import { __, defaultTo, filter, lte, map, path, pipe } from 'lodash/fp'
import { City, District, Neighborhood } from 'utils/api/locations'
import { when } from 'utils/helpers/function'
import { propIs } from 'utils/helpers/object'

import { CityDialog, CitySelectToggle } from './CityDialog'
import { NeighborhoodDialog, NeighborhoodSelect } from './NeighborhoodDialog'

export type MobileVenueLocationFilterProps = {
  isOpen: boolean
  onClose: () => void

  options: City[]
  values: {
    city: string
    districts: Record<string, Selection>
    neighborhoods: Record<string, boolean>
  }
  getLocation: {
    city: (slug: string) => City
    district: (slug: string) => District
    neighborhood: (slug: string) => Neighborhood
  }
  selected: {
    city: City
    districts: District[]
    neighborhoods: Neighborhood[]
  }
  onChange: (key: 'city' | 'district' | 'neighborhood', slug: string) => void
  onClear: () => void
  onApply: () => void
}

export const MobileVenueLocationFilter = (
  props: MobileVenueLocationFilterProps
) => {
  const {
    isOpen,
    onClose,
    options,
    values,
    selected,
    onChange,
    onClear,
    onApply,
  } = props

  const cityModal = useSimpleModal()

  const makeNeighborhoodItem = (neighborhood: Neighborhood) => ({
    ...neighborhood,
    inputProps: useCheckbox({
      value: values.neighborhoods[neighborhood.slug],
      onChange: () => onChange('neighborhood', neighborhood.slug),
      name: 'neighborhood',
    }),
  })
  const makeDistrictItem = (district: District) => ({
    ...district,
    children: district.neighborhoods.map(makeNeighborhoodItem),
    inputProps: useCheckbox({
      value: values.districts[district.slug],
      onChange: () => onChange('district', district.slug),
      name: 'district',
    }),
  })

  const items = pipe(
    filter((district: District) => district.neighborhoods.length > 0),
    map(makeDistrictItem),
    when(propIs('length', lte(__, 1)), path([0, 'children'])),
    defaultTo([])
  )(selected.city.districts)

  return (
    <>
      <NeighborhoodDialog
        open={isOpen}
        onClose={onClose}
        transitionDuration={{ enter: 100, exit: 100 }}
        onClear={onClear}
        onApply={onApply}
      >
        <CitySelectToggle onClick={cityModal.toggleModal}>
          <Typography variant="body1">{selected.city.name}</Typography>
        </CitySelectToggle>
        <NeighborhoodSelect items={items} />
      </NeighborhoodDialog>

      <CityDialog
        options={options}
        selected={selected.city}
        open={cityModal.isOpen}
        onClose={cityModal.toggleModal}
        onCityClick={(slug: string) => {
          onChange('city', slug)
          cityModal.toggleModal()
        }}
        transitionDuration={{ enter: 0, exit: 0 }}
      />
    </>
  )
}
