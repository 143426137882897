import React, { useEffect } from 'react'

declare global {
  interface Window {
    flodesk: any
  }
}

type FlodeskProps = {
  formId: string
}

const scriptContent = `
(function(w, d, t, h, s, n) {
w.FlodeskObject = n;
var fn = function() {
  (w[n].q = w[n].q || []).push(arguments);
};
w[n] = w[n] || fn;
var f = d.getElementsByTagName(t)[0];
var v = '?v=' + Math.floor(new Date().getTime() / (120 * 1000)) * 60;
var sm = d.createElement(t);
sm.async = true;
sm.type = 'module';
sm.src = h + s + '.mjs' + v;
f.parentNode.insertBefore(sm, f);
var sn = d.createElement(t);
sn.async = true;
sn.noModule = true;
sn.src = h + s + '.js' + v;
f.parentNode.insertBefore(sn, f);
})(window, document, 'script', 'https://assets.flodesk.com', '/universal', 'flodesk');
`

const initialize = () => {
  const script = document.createElement('script')
  script.innerHTML = scriptContent
  document.body.appendChild(script)
}

const Flodesk = ({ formId }: FlodeskProps) => {
  const containerId = `fd-form-${formId}`

  const showSubscribtionForm = () => {
    if (!window.flodesk) throw new Error('Flodesk has not been initialized')

    window.flodesk('form', {
      formId: formId,
      containerEl: `#${containerId}`,
    })
  }

  useEffect(() => {
    if (typeof window.flodesk !== 'function') initialize()

    showSubscribtionForm()
  }, [])

  return <div id={containerId}></div>
}

export default Flodesk
