import { Box } from '@material-ui/core'
import { Field, FieldProps } from 'formik'
import * as React from 'react'

import { SelectField } from '../../../../components/ui/FormComponents/SelectField'

export const CompanySizeField: React.FC = () => {
  const variants = [
    { label: '<50', value: '<50' },
    { label: '51 - 100', value: '51-100' },
    { label: '101 - 200', value: '101-200' },
    { label: '201+', value: '201+' },
  ]
  return (
    <Box display="flex" alignItems="center" mb={7}>
      <Field name="companySize">
        {({ field, form: { setFieldValue, setFieldTouched } }: FieldProps) => {
          return (
            <SelectField
              sx={{ padding: '12.5px 0' }}
              label="Company size"
              options={variants}
              value={field.value}
              secondaryMode
              onOpen={() => setFieldTouched(field.name, true)}
              onChange={(value) => {
                setFieldValue(field.name, value, true)
              }}
            />
          )
        }}
      </Field>
    </Box>
  )
}
