import {
  LodashFilter,
  LodashIncludes,
  __,
  curry,
  filter,
  includes,
  pipe,
  size,
} from 'lodash/fp'

export const count = (
  pred: Parameters<LodashFilter>[0],
  collection: Parameters<LodashFilter>[1]
) => pipe(filter(pred), size)(collection)

interface IsOneOf {
  <T>(values: T[], value: T): boolean
  <T>(values: T[]): (value: T) => boolean
}

export const isOneOf: IsOneOf = curry(<T>(values: T[], value: T) =>
  includes(value, values)
)
