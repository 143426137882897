import { Box, Typography } from '@material-ui/core'
import { difference, uniq } from 'lodash'
import { useEffect, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { LocalEvent } from 'types/Event'

import ABSLogo from '../../../../assets/images/abs-logo.png'
import BlaceLogo from '../../../../assets/images/blace.png'
import { NavLink } from '../../../../components/NavLink'
import GradientButton from '../../../../components/ui/Buttons/GradientButton'
import { PopoverFieldRefProps } from '../../../../components/ui/PopoverField'
import { EventDraftStatus } from '../../../../constants'
import { usePartner } from '../../../../hooks/usePartner'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { useEventTypes } from '../../../../utils/api/events'
import { generateEventName } from '../../../../utils/helpers/events'
import { userEventRecordMapper } from '../../../../utils/mappers/userEvent'
import {
  getMarketplacePath,
  vendorsPath,
  venuesPath,
} from '../../../../utils/paths'
import { useStorageEventsData } from '../../../../utils/providers/EventsProvider'
import { LsGetSearch, LsSetSearch } from '../../../../utils/storage'
import tracking from '../../../../utils/tracking'
import VenuesSlider from '../../../venues/components/VenuesSlider/VenuesSlider'
import { AbsSearchForm } from '../AbsSearchForm'

import {
  BackgroundImage,
  BlackBlock,
  EventFormContainer,
  EventFormWrapper,
  StyledSliderBlock,
  StyledTypography,
} from './EventForm.styles'
import { EventTypeSelect } from './EventTypeSelect'
import GuestsInput from './GuestsInput'

interface SearchProps {
  onSearchVenues: (name: string) => void
}

const mainAbsSliderHeight = '(100vh - 100px)'

export const EventForm: React.FC<SearchProps> = () => {
  // Refs
  const eventTypeRef = React.useRef<PopoverFieldRefProps>(null)
  const locationRef = React.useRef<PopoverFieldRefProps>(null)
  const guestsInputRef = React.useRef<PopoverFieldRefProps>(null)

  // Hooks
  const history = useHistory()
  const { isMobile, windowSize } = useWindowSize()
  const { updateUserActiveEvent, createNewEvent, getUserActiveEvent } =
    useStorageEventsData()
  const { data: eventTypes } = useEventTypes()

  // State
  const [eventType, setEventType] = useState<string>('')
  const [neighborhood, setNeighborhood] = useState<string[]>([])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [guests, setGuests] = useState<string>('')
  const [activeVenueIndex, setActiveVenueIndex] = useState<number>(0)

  // Effects
  const userEvent: LocalEvent | null = getUserActiveEvent()
  const heroSearchData = LsGetSearch()

  const { t } = useTranslation()

  const { isAbs } = usePartner()
  const { isTablet } = useWindowSize()

  useEffect(() => {
    if (heroSearchData.eventType) setEventType(heroSearchData.eventType)
    if (heroSearchData.neighborhood)
      setNeighborhood([...heroSearchData.neighborhood])
    if (heroSearchData.start_date)
      setStartDate(new Date(heroSearchData.start_date))
    if (heroSearchData.end_date) setEndDate(new Date(heroSearchData.end_date))
    if (heroSearchData.guests) setGuests(heroSearchData.guests)
  }, [])

  const handleNeighborhoodChange = (value: string[], isChecked: boolean) => {
    const updatedNeighborhood = isChecked
      ? difference(neighborhood, value)
      : uniq([...neighborhood, ...value])
    setNeighborhood(updatedNeighborhood)
    LsSetSearch({ neighborhood: updatedNeighborhood })
  }

  const handleEventTypeChange = (value: string) => {
    setEventType(value)
    LsSetSearch({ eventType: value })
    eventTypeRef.current?.onClose()
    setTimeout(() => locationRef.current?.onOpen(), 100)
  }

  const handleGuestsChange = (value: string) => {
    setGuests(value)
    LsSetSearch({ guests: value })
    guestsInputRef.current?.onClose()
  }

  const resetEventType = () => {
    setEventType('')

    LsSetSearch({ eventType: '' })
  }

  const onChangeAbsSearchVenue = (activeVenueIndex: number) => {
    setActiveVenueIndex(activeVenueIndex)
  }

  // TODO resolve search context
  const onSubmit = function (e: { preventDefault: () => void }) {
    e.preventDefault()
    if (userEvent?.status !== EventDraftStatus) {
      createNewEvent()
    }
    const isNeedNewName = userEvent?.eventType !== eventType
    const name = isNeedNewName
      ? generateEventName(!!isAbs, eventType, eventTypes)
      : undefined
    const data = {
      eventType,
      neighborhood,
      start_date: startDate,
      end_date: endDate,
      guests,
      name,
    }
    tracking.homeSearch(data)
    updateUserActiveEvent(data)
    const heroSearchData = LsGetSearch()
    const userEventStr = userEventRecordMapper(heroSearchData)
    history.push(getMarketplacePath(userEventStr))
  }

  const mobileVh = windowSize.height * 0.01

  return (
    <>
      <EventFormWrapper
        display="flex"
        height={
          isMobile || isAbs ? `calc(100 * ${mobileVh}px - 100px)` : '40vw'
        }
      >
        <BlackBlock isMobile={Boolean(isMobile)} isAbs={Boolean(isAbs)}>
          <Box
            className="container"
            mt={
              isMobile
                ? isAbs
                  ? 0
                  : 5
                : isAbs
                ? `calc(${mainAbsSliderHeight} * 0.15)`
                : 20
            }
          >
            {isAbs && !isMobile && (
              <Box sx={{ position: 'absolute', top: '-75px' }}>
                <NavLink to={venuesPath} className="logo-wrapper">
                  <img
                    src={ABSLogo}
                    height={isTablet ? 77 : 105}
                    alt="ABS logo"
                  />
                </NavLink>
              </Box>
            )}
            <StyledTypography
              position={isAbs ? 'relative' : 'static'}
              zIndex={2}
              mt={isAbs && isMobile ? '27px' : 0}
              mb={
                isAbs
                  ? isMobile
                    ? '22px'
                    : `calc(${mainAbsSliderHeight} * 0.08)`
                  : '20px'
              }
              maxWidth={isAbs ? '476px' : 'unset'}
              variant="h1"
              fontSize={
                isMobile
                  ? isAbs
                    ? '32px'
                    : '24px'
                  : isAbs
                  ? `calc(${mainAbsSliderHeight} * 0.0875)`
                  : '68px'
              }
              fontWeight={isAbs && isMobile ? '400' : '700'}
              letterSpacing={isAbs ? 0 : '-0.03em'}
            >
              {!isAbs
                ? t('home.event.title', 'Book Unique Spaces')
                : t('partners.abs.services.book', 'Book Your Services')}
            </StyledTypography>
            {isAbs ? (
              <AbsSearchForm
                activeVenueIndex={activeVenueIndex}
                mainAbsSliderHeight={mainAbsSliderHeight}
                onChangeVenue={onChangeAbsSearchVenue}
              />
            ) : (
              <EventFormContainer onSubmit={onSubmit}>
                <Box
                  display="flex"
                  flexDirection={isMobile ? 'column' : 'row'}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    style={{
                      marginRight: isMobile ? 0 : 10,
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: 'center',
                      width: isMobile ? '100%' : 'fit-content',
                    }}
                  >
                    <EventTypeSelect
                      ref={eventTypeRef}
                      value={eventType}
                      setValue={handleEventTypeChange}
                      reset={resetEventType}
                    />
                    <GuestsInput
                      ref={guestsInputRef}
                      value={guests}
                      setValue={handleGuestsChange}
                    />
                  </Box>
                  <Box
                    width={isMobile ? '100%' : 'unset'}
                    padding={isMobile ? '0 10px' : 0}
                  >
                    <GradientButton type="submit" size="large">
                      {t('home.event.search', 'Search')}
                    </GradientButton>
                  </Box>
                </Box>
              </EventFormContainer>
            )}
            {!(isAbs && isMobile) && (
              <Box
                position={isAbs ? 'relative' : 'static'}
                zIndex={2}
                mt={
                  isAbs
                    ? `calc(${mainAbsSliderHeight} * 0.08)`
                    : isMobile
                    ? 0
                    : 3
                }
                padding={isMobile ? '10px' : 0}
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                sx={{ backgroundColor: isMobile ? '#20202c' : 'transparent' }}
              >
                {!isAbs ? (
                  <>
                    <Typography variant="body2" color="secondary">
                      {t('home.event.already', 'Already have your venue?')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="secondary"
                      style={{
                        cursor: 'pointer',
                        fontWeight: 600,
                        marginLeft: isMobile ? 0 : 5,
                        marginTop: isMobile ? 5 : 0,
                      }}
                      onClick={() => history.push(vendorsPath)}
                    >
                      {t('home.event.searchVendors', 'Search Vendors')}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    sx={{ color: (theme) => theme.palette.common.white }}
                    maxWidth={isAbs ? '476px' : 'unset'}
                    variant="body1"
                    fontSize={18}
                    lineHeight="36px"
                    letterSpacing={0}
                    fontWeight="500"
                  >
                    {t(
                      'partners.abs.vendors.discover',
                      "Discover new and interesting vendors for all of your in-office needs. From catering to events to flowers to massage therapists, we've got you covered."
                    )}
                  </Typography>
                )}
              </Box>
            )}
            {isAbs && !isMobile && (
              <Box sx={{ position: 'absolute', bottom: 40 }}>
                <Typography
                  variant="body2"
                  mb="8px"
                  sx={{
                    textTransform: 'uppercase',
                    color: (theme) => theme.palette.common.white,
                  }}
                >
                  Powered by
                </Typography>
                <img src={BlaceLogo} height={22} alt="Blace logo" />
              </Box>
            )}
          </Box>
          {isAbs ? (
            <StyledSliderBlock isMobile={Boolean(isMobile)}>
              <VenuesSlider
                isAbs
                withPagination
                activeVenueIndex={activeVenueIndex}
                handleActiveVenueIndex={(index) => setActiveVenueIndex(index)}
                mt={0}
                slidesPerView={1}
                withoutFooter
                absPosNavigation
                prevNavButtonClass="abs-venues-prev"
                nextNavButtonClass="abs-venues-next"
              />
            </StyledSliderBlock>
          ) : (
            <BackgroundImage isMobile={Boolean(isMobile)} />
          )}
        </BlackBlock>
      </EventFormWrapper>
      {isAbs && isMobile && (
        <Box
          padding="53px"
          textAlign="center"
          sx={{ background: (theme) => theme.palette.secondary.dark }}
        >
          <Typography
            variant="body2"
            mb="8px"
            sx={{
              textTransform: 'uppercase',
              color: (theme) => theme.palette.common.white,
            }}
          >
            Powered by
          </Typography>
          <img src={BlaceLogo} width={122} alt="Blace logo" />
        </Box>
      )}
    </>
  )
}
