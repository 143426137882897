import { EXTERNAL_LINKS } from 'constants/externalLinks'

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import LinkText from '../../ui/Buttons/LinkText/LinkText'

type TermsAndConditionsProps = {
  agreed: boolean
  setAgreed: any
}
export const TermsAndConditions: ({
  agreed,
  setAgreed,
}: TermsAndConditionsProps) => JSX.Element = ({
  agreed,
  setAgreed,
}: TermsAndConditionsProps) => {
  const { t } = useTranslation()
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={() => setAgreed(!agreed)}
          checked={agreed}
          name="agreed"
          color="primary"
        />
      }
      label={
        <Typography color="textPrimary" variant="body2">
          {t('termsAndConditions.agree', 'I have read and agreed to the ')}
          <LinkText
            fontSize="inherit"
            onClick={() => window.open(EXTERNAL_LINKS.TERMS_AND_CONDITIONS)}
            display="inline"
            text={t('termsAndConditions.link', 'terms and conditions')}
          />
        </Typography>
      }
      sx={{ mr: 0 }}
    />
  )
}
