const Exclamation = ({ size = 40 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#4091F0" />
      <circle cx="20" cy="20" r="20" fill="url(#a)" />
      <path
        d="M18.333 27.604a1.666 1.666 0 1 0 3.333 0 1.666 1.666 0 0 0-3.333 0Zm.834-4.375h1.666a.209.209 0 0 0 .209-.208V10.937a.209.209 0 0 0-.209-.208h-1.666a.209.209 0 0 0-.209.209V23.02c0 .114.094.208.209.208Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="a"
          x1="20"
          y1="0"
          x2="20"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4E4BEC" />
          <stop offset="1" stopColor="#3F91F0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Exclamation
