import { Box, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import * as React from 'react'
import { useMemo } from 'react'

import img from '../../../../../../assets/images/test-venue-item.jpg'
import { FastTextField } from '../../../../../../components/formik'
import GradientButton from '../../../../../../components/ui/Buttons/GradientButton'
import { simpleModalControl } from '../../../../../../hooks/useSimpleModal'
import { useUrlParams } from '../../../../../../hooks/useUrlParams'
import { useStorageEventsData } from '../../../../../../utils/providers/EventsProvider'
import tracking from '../../../../../../utils/tracking'
import { AddressField } from '../../../../../onboarding/Steps/FormComponents/AddressField'
import { AddVenueFormLabel } from '../SelectVenueType.styles'

import { addOwnVenueValidationSchema } from './validations'

export interface AddVenueFormValues {
  office: any
  sqFootage: number | string
  name: string
  partnerId: string
}

export const AddOwnVenue: React.FC<{ modalControl: simpleModalControl }> = ({
  modalControl,
}) => {
  const { urlParams } = useUrlParams()
  const { updateModalData } = modalControl
  const { updateUserActiveEvent, getUserActiveEvent } = useStorageEventsData()
  const userActiveEvent = getUserActiveEvent()

  const initialValues: AddVenueFormValues = useMemo(() => {
    return {
      office: '',
      sqFootage: urlParams.partnerArea || '',
      name: urlParams.partnerName || '',
      partnerId: urlParams.partnerId || '',
    }
  }, [urlParams])

  const onSubmit = (values: AddVenueFormValues) => {
    const client_venue = {
      location: values.office,
      name: values.name,
      sq_footage: +values.sqFootage || 0,
      partnerId: values.partnerId,
    }
    updateUserActiveEvent({ ...userActiveEvent, client_venue })
    const venue = {
      name: values.name || 'My own venue',
      id: undefined,
      address: values.office.description,
      pricing: '',
      photo: img,
      client_owned: true,
      client_venue,
    }
    tracking.pickedVenue(venue)
    updateModalData({ name: 'inquiryCreate', venue })
  }

  return (
    <Box
      display="flex"
      mb={4}
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={4}>
        <Typography variant="h3">Add your own venue</Typography>
      </Box>
      <Formik
        validationSchema={addOwnVenueValidationSchema()}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" mb={8}>
                  <AddVenueFormLabel>Venue address *</AddVenueFormLabel>
                  <AddressField initialLocation={urlParams.partnerLocation} />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" mb={8}>
                  <AddVenueFormLabel>Venue name (optional)</AddVenueFormLabel>
                  <FastTextField name="name" placeholder="Type name here" />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" mb={8}>
                <AddVenueFormLabel>Total area (optional)</AddVenueFormLabel>
                <FastTextField
                  type="number"
                  name="sqFootage"
                  placeholder="Enter square footage here"
                />
              </Box>
              <GradientButton
                type="submit"
                style={{ width: '100%', marginTop: 16 }}
              >
                Add own venue
              </GradientButton>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}
