import { Table, TableCell } from '@material-ui/core'
import React from 'react'

import ArrowDown from '../../svg/ArrowDown'
import ArrowUp from '../../svg/ArrowUp'
import {
  StyledIconButton,
  StyledTableRow,
} from '../CollapsibleDocumentRow/CollapsibleDocumentRow.styles'

interface CollapsibleDocumentRowProps {
  title: string
  colSpan: number
}

export const CollapsibleDocumentTable: React.FC<
  CollapsibleDocumentRowProps
> = ({ title, colSpan, children }) => {
  const [isTableExpanded, setIsTableExpanded] = React.useState<boolean>(false)

  return (
    <>
      <Table>
        <StyledTableRow sx={{ border: 'none' }}>
          <TableCell style={{ color: '#9396A3' }} colSpan={colSpan}>
            {title}
          </TableCell>
          <TableCell
            align="right"
            style={{ color: '#9396A3', marginLeft: '10px' }}
          >
            <StyledIconButton
              color="inherit"
              onClick={() => setIsTableExpanded(!isTableExpanded)}
            >
              {isTableExpanded ? <ArrowUp fill="#2D2D2D" /> : <ArrowDown />}
            </StyledIconButton>
          </TableCell>
        </StyledTableRow>
      </Table>
      {isTableExpanded && children}
    </>
  )
}
