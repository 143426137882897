import { Box, Typography, useTheme } from '@material-ui/core'
import { Star } from '@material-ui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { usePartner } from '../../../../hooks/usePartner'
import { usePromotion } from '../../../../utils/api/admin'
import {
  absAdvantagePath,
  vendorsPromotionsPath,
  venuesPromotionsPath,
} from '../../../../utils/paths'
import { NavLink } from '../../../NavLink'

export const PromotionsLink: React.FC<{ toVendors?: boolean }> = ({
  toVendors,
}) => {
  const { isAbs } = usePartner()
  const { t } = useTranslation()
  const theme = useTheme()
  const { data } = usePromotion({ enabled: !isAbs && isAbs !== undefined })

  if (!data?.special_offers_switcher && !isAbs) return <></>

  return (
    <NavLink
      to={
        isAbs
          ? absAdvantagePath
          : toVendors
          ? vendorsPromotionsPath
          : venuesPromotionsPath
      }
    >
      <Box
        display="flex"
        alignItems="center"
        minWidth={isAbs ? '197px' : '174px'}
        height="48px"
        pl="16px"
        sx={{ background: '#ffe870' }}
      >
        <Typography
          variant="body1"
          fontWeight={500}
          color={theme.palette.common.promotionColor}
          letterSpacing="0.01em"
          mr="5px"
        >
          {isAbs
            ? t('partners.abs.advantage.title', 'ABS Advantage')
            : t('promotions.offers', 'Special Offers')}
        </Typography>
        <Star sx={{ color: theme.palette.common.promotionColor }} />
      </Box>
    </NavLink>
  )
}
