import { Box, Link, Typography } from '@material-ui/core'
import { LanguageOutlined } from '@material-ui/icons'
import React from 'react'

import InstaSmallIcon from '../../../../../../components/svg/InstaSmallIcon'
import Mail from '../../../../../../components/svg/Mail'
import PhoneOutlined from '../../../../../../components/svg/PhoneOutlined'

export const InquiryContact: React.FC<{
  name: string
  phone: string
  email?: string
  website?: string
  instagram?: string
  isVendor: boolean
}> = ({ name, phone, email, website, instagram, isVendor }) => {
  const instaLinkName = instagram ? instagram.split('/')[3].toString() : ''
  const websiteLink = website
    ? website.includes('http')
      ? website
      : `http://${website}`
    : ''
  const websiteLinkName = websiteLink
    ? websiteLink.replace(/^https?:\/\//, '').replace(/\/$/, '')
    : ''

  return (
    <>
      {!!name && (
        <Box>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography fontWeight={500} variant="body2">
              {name}
            </Typography>
          </Box>
        </Box>
      )}
      <Box>
        {!!phone && (
          <Link underline="none" color="#20202C" mr={2} href={`tel:${phone}`}>
            <Box
              sx={{ '&:hover': { opacity: 0.8 } }}
              display="flex"
              alignItems="center"
              mb={1}
            >
              <Box width="12px">
                <PhoneOutlined fill="#9396A3" />
              </Box>
              <Typography ml="15px" fontFamily="Poppins">
                {phone}
              </Typography>
            </Box>
          </Link>
        )}
        {!!email && (
          <Link
            underline="none"
            color="#20202C"
            mr={2}
            href={`mailto:${email}`}
          >
            <Box
              display="flex"
              sx={{ '&:hover': { opacity: 0.8 } }}
              alignItems="center"
              mb={1}
            >
              <Box width="12px">
                <Mail fill="#9396A3" />
              </Box>
              <Typography
                ml="15px"
                color="#20202C"
                fontFamily="Poppins"
                sx={{ wordBreak: 'break-word' }}
              >
                {email}
              </Typography>
            </Box>
          </Link>
        )}
        {!!website && (
          <Link
            sx={{ textTransform: 'lowercase', '&:hover': { opacity: 0.8 } }}
            mr={2}
            color="#20202C"
            underline="none"
            href={websiteLink}
            target="_blank"
          >
            <Box display="flex" alignItems="center" mb={1}>
              <LanguageOutlined style={{ color: '#9396A3' }} />
              <Typography color="#20202C" ml="5px">
                {websiteLinkName}
              </Typography>
            </Box>
          </Link>
        )}
        {!!instagram && (
          <Link
            sx={{ textTransform: 'lowercase', '&:hover': { opacity: 0.8 } }}
            color="#20202C"
            mr={2}
            underline="none"
            href={instagram}
            target="_blank"
          >
            <Box display="flex" alignItems="center" mb={1}>
              <Box width="12px">
                <InstaSmallIcon fill="#9396A3" />
              </Box>
              <Typography color="#20202C" ml="15px">
                {instaLinkName}
              </Typography>
            </Box>
          </Link>
        )}
      </Box>
    </>
  )
}
