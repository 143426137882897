import { Box, IconButton, ImageListItemBar, styled } from '@material-ui/core'

import { BREAKPOINTS } from '../../../constants'

export const SwiperWrapper = styled(Box)<{
  withPagination?: boolean
  flexItems?: boolean
  withoutEndFrame?: boolean
  imageMaxHeight?: number
  imageMaxWidth?: number
  windowWidth?: number
  isMobile?: boolean
  isLessThenMaxContainerWidth?: boolean
  absPosNavigation: boolean
  navigationPosition: 'top' | 'bottom' | 'center'
}>`
  height: 100%;
  position: relative;
  padding: 0;

  ${(props) =>
    props.withoutEndFrame &&
    props.windowWidth &&
    `
      margin-left: ${
        !props.isLessThenMaxContainerWidth
          ? (props.windowWidth - 1224) / 2
          : props.isLessThenMaxContainerWidth && !props.isMobile
          ? 50
          : props.windowWidth > 600
          ? (props.windowWidth - 550) / 2
          : 24
      }px;
  `}
  .MuiImageListItem-root {
    width: 100%;

    ${(props) =>
      !props.imageMaxHeight &&
      `
          height: 100% !important;
      `}
  }

  .swiper-container {
    height: 100%;
  }

  .swiper-slide {
    height: auto;
    ${(props) => `
        padding: ${props.py} ${props.px};
     `}
  }

  ${(props) =>
    !!props.imageMaxHeight &&
    `
    .swiper-slide {
      li {
        width: 100%;
      }
      img {
        height: ${props.imageMaxHeight}px;
      }
    }  
  `}

  ${(props) =>
    !!props.imageMaxWidth &&
    `
    .swiper-slide {
      min-width: ${props.imageMaxWidth}px;
      max-width: ${props.imageMaxWidth}px;
    }
    .swiper-slide {
      img {
        width: 100%;
      }
    }  
  `}

  ${(props) =>
    props.flexItems &&
    `
    .MuiImageListItem-root {
      display: flex;
      height: 120px !important;
      border-radius: 2px;
      box-shadow: 4px 4px 31px rgb(0 0 0 / 5%);
    }
    .MuiImageListItemBar-root {
      position:static;
      width: 200px;
      background: ${props.theme.palette.common.white};
    }
    .MuiImageListItemBar {
      &-titleWrap {
        color: ${props.theme.palette.secondary.dark};
        height: 100%;
        position: relative;
      }
    }
    .swiper-slide img {
      width: 108px;
    }
  `}

  ${(props) =>
    !props.withPagination &&
    `
    .swiper-pagination {
      display: none;
     }
  `}

  ${(props) =>
    `
    .slider-navigation {
        position: ${
          props.absPosNavigation &&
          !props.isMobile &&
          props.navigationPosition !== 'center'
            ? 'absolute'
            : 'static'
        };
        z-index: 2;
        top: ${
          props.navigationPosition === 'top'
            ? 0
            : props.navigationPosition === 'center'
            ? '50%'
            : 'auto'
        };
        bottom: ${
          props.navigationPosition === 'bottom'
            ? 0
            : props.navigationPosition === 'center'
            ? '50%'
            : 'auto'
        };
        right: 0;
    }
    
    .swiper-pagination {
      text-align: ${props.isMobile ? 'center' : 'left'};
      
      &-bullets {
        left: ${props.isMobile ? 0 : '16px'};
        bottom: ${props.isMobile ? '30px' : '20px'};
      }
      
      &-bullet {
        border-radius: 2px;
        width: ${props.isMobile ? '8px' : '16px'};
        height: ${props.isMobile ? '8px' : '16px'};
        background: ${props.theme.palette.grey['200']};
        margin: 0 14px;
        opacity: 0.6;
  
        &-active {
          background: ${props.theme.palette.common.white};
          opacity: 1;
        }
      }
    }
  `}
`

export const SlideTitle = styled(ImageListItemBar)`
  background: rgba(32, 32, 44 б 0.7);
  cursor: pointer;
  margin-top: 32px;
  margin-left: 95px;

  .MuiImageListItemBar-title {
    font-weight: 600;
    font-size: 24px;
  }
`

export const SliderHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NavigationButton = styled(IconButton)<{
  absPosNavigation: boolean
  isMobile?: boolean
  navigationPosition: 'top' | 'bottom' | 'center'
  prevBtn?: boolean
  nextBtn?: boolean
  disabledPrev?: boolean
  disabledNext?: boolean
}>(
  ({
    absPosNavigation,
    isMobile,
    navigationPosition,
    prevBtn,
    nextBtn,
    disabledPrev,
    disabledNext,
    ...props
  }) => `
  border-radius: 0;
  z-index: 2;
  position: ${
    absPosNavigation && (isMobile || navigationPosition === 'center')
      ? 'absolute'
      : 'relative'
  };
  top: ${
    absPosNavigation && navigationPosition === 'top'
      ? 0
      : navigationPosition === 'center'
      ? isMobile
        ? '290px'
        : '50%'
      : 'auto'
  };
  bottom: ${
    absPosNavigation && navigationPosition === 'bottom' && isMobile
      ? '34px'
      : navigationPosition === 'center'
      ? '50%'
      : 'auto'
  };
  height: ${
    absPosNavigation
      ? isMobile
        ? navigationPosition === 'center'
          ? '40px'
          : '48px'
        : navigationPosition === 'center'
        ? '40px'
        : '88px'
      : 'unset'
  };
  width: ${
    absPosNavigation
      ? isMobile
        ? navigationPosition === 'center'
          ? '40px'
          : '48px'
        : navigationPosition === 'center'
        ? '40px'
        : '88px'
      : 'unset'
  };
  background: ${
    absPosNavigation
      ? navigationPosition === 'center'
        ? props.theme.palette.background.paper
        : (prevBtn && disabledPrev) || (nextBtn && disabledNext)
        ? 'rgba(147, 150, 163, 0.4)'
        : props.theme.palette.common.white
      : 'transparent'
  };
  transform: ${
    absPosNavigation &&
    (navigationPosition === 'center' ? 'translateY(-50%)' : 'none')
  };
  left: ${
    prevBtn && absPosNavigation
      ? navigationPosition === 'center'
        ? isMobile
          ? '-10px'
          : '-64px'
        : 0
      : 'auto'
  };
  right: ${
    nextBtn && absPosNavigation
      ? navigationPosition === 'center'
        ? isMobile
          ? '-10px'
          : '-64px'
        : 0
      : 'auto'
  };
  
  @media (max-width: calc(${BREAKPOINTS.LARGE}px + 100px)) {
    left: ${
      prevBtn && absPosNavigation
        ? navigationPosition === 'center'
          ? isMobile
            ? '-10px'
            : '-30px'
          : 0
        : 'auto'
    };
    right: ${
      nextBtn && absPosNavigation
        ? navigationPosition === 'center'
          ? isMobile
            ? '-10px'
            : '-30px'
          : 0
        : 'auto'
    };
  }
  
  &:hover {
      opacity: ${
        absPosNavigation &&
        !((prevBtn && disabledPrev) || (nextBtn && disabledNext))
          ? 0.8
          : 1
      };
      background: ${
        absPosNavigation
          ? navigationPosition === 'center'
            ? props.theme.palette.background.paper
            : (prevBtn && disabledPrev) || (nextBtn && disabledNext)
            ? 'rgba(147, 150, 163, 0.4)'
            : props.theme.palette.common.white
          : 'rgba(20, 20, 20, 0.05)'
      };
      cursor: ${
        (prevBtn && disabledPrev) || (nextBtn && disabledNext)
          ? 'default'
          : 'pointer'
      };
  }

  .prev,
  .next,
  .preferred_next_button,
  .preferred_prev_button,
  .exclusive_next_button,
  .exclusive_prev_button {
    color: inherit;
  }
`
)
