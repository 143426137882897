const Documents = ({ color = '#9396A3' }: { color?: string }) => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m16.691 5.637-4.203-4.203a.624.624 0 0 0-.441-.184H3.75a.624.624 0 0 0-.625.625v16.25c0 .346.28.625.625.625h12.5c.346 0 .625-.28.625-.625V6.08a.628.628 0 0 0-.184-.443Zm-1.257.73h-3.676V2.691l3.676 3.676Zm.035 10.977H4.53V2.656h5.899v4.219a.82.82 0 0 0 .82.82h4.219v9.649Z"
        fill="#9396A3"
      />
    </svg>
  )
}

export default Documents
