import { Box, useTheme } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import React, { ReactElement, RefObject } from 'react'

import { useWindowSize } from '../../../hooks/useWindowSize'

import { NavigationButton } from './SwipeSlider.styles'

interface NavigationButtonsProps {
  prevButton: RefObject<HTMLButtonElement>
  nextButton: RefObject<HTMLButtonElement>
  marginLeft?: number
  marginRight?: number
  marginBottom?: number | string
  absPosNavigation?: boolean
  nextNavButtonClass?: string
  prevNavButtonClass?: string
  disabledNext: boolean
  disabledPrev: boolean
  navigationPosition?: 'top' | 'bottom' | 'center'
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  disabledPrev,
  disabledNext,
  absPosNavigation = false,
  prevButton,
  nextButton,
  marginBottom,
  marginLeft,
  marginRight,
  nextNavButtonClass = 'next',
  prevNavButtonClass = 'prev',
  navigationPosition = 'bottom',
}): ReactElement => {
  const theme = useTheme()
  const { isMobile } = useWindowSize()

  return (
    <Box
      className="slider-navigation"
      ml={marginLeft}
      mr={marginRight}
      mb={marginBottom}
    >
      <NavigationButton
        ref={prevButton}
        className={prevNavButtonClass}
        color="inherit"
        absPosNavigation={absPosNavigation}
        isMobile={isMobile}
        navigationPosition={navigationPosition}
        prevBtn
        disabledPrev={disabledPrev}
      >
        <ArrowBack
          style={{
            color: disabledPrev ? theme.palette.grey['200'] : '#262626',
            fontSize: absPosNavigation && !isMobile ? '32px' : 'unset',
          }}
        />
      </NavigationButton>
      <NavigationButton
        ref={nextButton}
        className={nextNavButtonClass}
        color="inherit"
        absPosNavigation={absPosNavigation}
        isMobile={isMobile}
        navigationPosition={navigationPosition}
        nextBtn
        disabledNext={disabledNext}
      >
        <ArrowForward
          style={{
            color: disabledNext ? theme.palette.grey['200'] : '#262626',
            fontSize: absPosNavigation && !isMobile ? '32px' : 'unset',
          }}
        />
      </NavigationButton>
    </Box>
  )
}

export default NavigationButtons
