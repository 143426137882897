import {
  Box,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useWindowSize } from '../../hooks/useWindowSize'
import { GroupLineItemBundle, LineItemBundle } from '../../types/dtos/inquiry'
import {
  currencyConvertToDecimal,
  formatToCurrency,
} from '../../utils/helpers/price'

import { StyledTableCell, StyledTableRow } from './InvoiceDetailsModal.styles'
import { InvoiceModalContentProps } from './InvoiceModalContent'

export const LineItemsTable: React.FC<InvoiceModalContentProps> = ({
  invoice,
  paymentPercentAmount,
  amount,
  totalAmount,
}) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  return (
    <Box mt="24px" sx={{ overflowX: 'auto' }}>
      <Table>
        {!isMobile && (
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: '30px' }}>
                <Typography variant="body2" color="#9396A3">
                  {t('business.lineItems.number', '#')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="body2" color="#9396A3">
                  {t('business.lineItems.name4', 'Item')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography align="center" variant="body2" color="#9396A3">
                  {t('business.lineItems.quantity', 'Quantity')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography align="right" variant="body2" color="#9396A3">
                  {t('business.lineItems.price', 'Price')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell sx={{ paddingRight: 0 }}>
                <Typography align="right" variant="body2" color="#9396A3">
                  {t('business.lineItems.total', 'Total')}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {invoice?.groupedLineItems.map((group: GroupLineItemBundle) => (
            <>
              {/*<StyledTableRow style={{ background: '#fff' }}>*/}
              {/*  <StyledTableCell colSpan={5}>*/}
              {/*    <Box key={group.name}>*/}
              {/*      <Typography variant="body2">{group.name}</Typography>*/}
              {/*      {!!group.description && (*/}
              {/*        <Typography variant="body2" fontSize="12px" fontWeight={400} color="#b6c0c2">*/}
              {/*          {group.description}*/}
              {/*        </Typography>*/}
              {/*      )}*/}
              {/*    </Box>*/}
              {/*  </StyledTableCell>*/}
              {/*</StyledTableRow>*/}
              {group?.line_items.map((item: LineItemBundle, i: number) => (
                <>
                  <StyledTableRow key={item.line_item_id}>
                    <StyledTableCell sx={{ width: '30px' }}>
                      <Typography variant="body1" fontWeight={500}>
                        {i + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell colSpan={isMobile ? 2 : 1}>
                      <Box>
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          color="#2D2D2D"
                        >
                          {item?.name}
                        </Typography>
                        {!!item.clarification && (
                          <Typography
                            variant="body2"
                            fontSize="12px"
                            fontWeight={400}
                            color="#b6c0c2"
                          >
                            {item.clarification}
                          </Typography>
                        )}
                      </Box>
                    </StyledTableCell>
                    {!isMobile && (
                      <>
                        <StyledTableCell>
                          <Typography
                            align="center"
                            variant="body1"
                            fontWeight={500}
                            color="#2D2D2D"
                          >
                            {item?.quantity}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            align="right"
                            variant="body1"
                            fontWeight={500}
                            color="#2D2D2D"
                          >
                            {item?.price
                              ? formatToCurrency(item.price)
                              : '$0.00'}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            align="right"
                            variant="body1"
                            fontWeight={500}
                            color="#2D2D2D"
                          >
                            {item?.price
                              ? formatToCurrency(
                                  currencyConvertToDecimal(item.price) *
                                    item.quantity
                                )
                              : '$0.00'}
                          </Typography>
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                  {isMobile && (
                    <StyledTableRow key={item.line_item_id}>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          color="#2D2D2D"
                        >
                          {item?.quantity} x{' '}
                          {item?.price ? formatToCurrency(item.price) : '$0.00'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          align="right"
                          variant="body1"
                          fontWeight={600}
                          color="#2D2D2D"
                        >
                          {item?.price
                            ? formatToCurrency(
                                currencyConvertToDecimal(item.price) *
                                  item.quantity
                              )
                            : '$0.00'}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </>
              ))}
              {/*<StyledTableRow>*/}
              {/*  <StyledTableCell colSpan={isMobile ? 2 : 4}>*/}
              {/*    <Typography variant="body1" fontWeight={600} color="#2D2D2D">*/}
              {/*      {t('business.invoices.groupSubtotal','Subtotal')} */}
              {/*    </Typography>*/}
              {/*  </StyledTableCell>*/}
              {/*  <StyledTableCell align="right">*/}
              {/*    <Typography variant="body1" fontWeight={600} color="#2D2D2D">*/}
              {/*      {lineItemsGroupsAmounts.find((total) => total.groupName === group.name)?.amount ? formatToCurrency(lineItemsGroupsTotals.find((item) => item.groupName === group.name)?.amount) : '$0.00'}*/}
              {/*    </Typography>*/}
              {/*  </StyledTableCell>*/}
              {/*</StyledTableRow>*/}
            </>
          ))}
        </TableBody>
        {/*<TableFooter style={{ borderTop: '30px solid #fff' }}>*/}
        <TableFooter>
          <StyledTableRow>
            <StyledTableCell colSpan={isMobile ? 2 : 4}>
              <Typography variant="body1" fontWeight={600} color="#2D2D2D">
                {t('business.invoices.amount', 'Amount')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography
                align="right"
                variant="body1"
                fontWeight={600}
                color="#2D2D2D"
              >
                {formatToCurrency(amount)}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
          {!!invoice.paymentPercent && invoice?.paymentPercent !== 100 && (
            <StyledTableRow>
              <StyledTableCell colSpan={isMobile ? 2 : 4}>
                <Typography variant="body1" fontWeight={600} color="#2D2D2D">
                  {capitalize(invoice?.type)} Payment ({invoice?.paymentPercent}
                  %)
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  align="right"
                  variant="body1"
                  fontWeight={600}
                  color="#2D2D2D"
                >
                  {formatToCurrency(paymentPercentAmount)}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          )}
          {!!invoice?.securityDeposit?.price && (
            <StyledTableRow>
              <StyledTableCell colSpan={isMobile ? 2 : 4}>
                <Box>
                  <Typography variant="body1" fontWeight={600} color="#2D2D2D">
                    {t('business.invoices.deposit', 'Security Deposit')}
                  </Typography>
                  {!!invoice?.securityDeposit.clarification && (
                    <Typography
                      variant="body2"
                      fontSize="12px"
                      fontWeight={400}
                      color="#b6c0c2"
                    >
                      {invoice?.securityDeposit.clarification}
                    </Typography>
                  )}
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  align="right"
                  variant="body1"
                  fontWeight={600}
                  color="#2D2D2D"
                >
                  {formatToCurrency(invoice?.securityDeposit.price)}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          )}
          <StyledTableRow style={{ background: '#20202C' }}>
            <StyledTableCell colSpan={isMobile ? 2 : 4}>
              <Typography variant="body1" fontWeight={600} color="#fff">
                {t('business.invoices.totalAmount', 'Total amount')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography
                align="right"
                variant="body1"
                fontWeight={600}
                color="#fff"
              >
                {totalAmount}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
        </TableFooter>
      </Table>
    </Box>
  )
}
