import { Box, styled, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import Logo from '../../components/svg/Logo'
import GradientButton from '../../components/ui/Buttons/GradientButton/GradientButton'
import { Image } from '../../components/ui/Image'
import { useWindowSize } from '../../hooks/useWindowSize'
import { onboardingRolePath } from '../../utils/paths'

const OnboardingWrapper = styled(Box)`
  background: white;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  padding-bottom: 120px;
`
const BackgroundImageBox = styled(Box)`
  position: absolute;
  bottom: 10%;
  left: 15%;

  min-width: 900px;
`

const CTABox = styled(Box)`
  background: white;
  position: absolute;
  left: 0;
  bottom: 20%;
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 425px;
`

export const Onboarding: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  return (
    <OnboardingWrapper display="flex">
      <Box mt={12} display="flex" justifyContent="center" flex={0.2}>
        <Logo />
      </Box>
      <Box
        display="flex"
        flex={1}
        style={{ background: '#20202C', position: 'relative' }}
      >
        <BackgroundImageBox>
          <Image
            imageSize="original"
            staticImageType="onboarding"
            style={{ width: '95%' }}
          />
        </BackgroundImageBox>
        <Box
          className="cta-form"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <CTABox>
            <Typography
              align="center"
              variant="body1"
              fontSize={!isMobile ? '24px' : '18px'}
              gutterBottom
            >
              {t('onboarding.exclusive', 'Exclusive access to BLACE clientele')}
            </Typography>
            <GradientButton
              sx={{ mt: 5 }}
              fullWidth
              onClick={() => history.push(onboardingRolePath)}
            >
              {t('onboarding.partner', 'Become a partner')}
            </GradientButton>
          </CTABox>
        </Box>
      </Box>
    </OnboardingWrapper>
  )
}
