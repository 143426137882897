import { Box, BoxProps } from '@material-ui/core'
import * as React from 'react'

import EditIcon from '../../../../components/svg/EditIcon'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import {
  StyledCancelTypography,
  StyledEditTypography,
} from '../../Profile.styles'

interface ActionButtonsProps {
  onSwitchMode: (isViewMode: boolean) => void
  withSaveMode?: boolean
  field?: string
  value?: any
  setFieldValue?: (field: string, value: any) => void
}

export const ActionButtons: React.FC<ActionButtonsProps & BoxProps> = ({
  withSaveMode,
  field,
  value,
  setFieldValue,
  onSwitchMode,
  ...boxProps
}) => {
  const [isViewMode, setIsViewMode] = React.useState<boolean>(true)
  const switchMode = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    setIsViewMode(!isViewMode)
    onSwitchMode(!isViewMode)
  }

  const { isMobile } = useWindowSize()

  return (
    <Box display="flex" {...boxProps}>
      {!isViewMode ? (
        <Box display={isMobile ? 'flex' : 'block'}>
          {withSaveMode && (
            <StyledEditTypography
              sx={{
                mr: isMobile ? '15px' : 0,
                mb: '10px',
                '&:hover': { opacity: 0.8, cursor: 'pointer' },
              }}
              onClick={(e) => switchMode(e)}
            >
              Save
            </StyledEditTypography>
          )}
          <StyledCancelTypography
            sx={{ '&:hover': { opacity: 0.8, cursor: 'pointer' } }}
            onClick={(e) => {
              if (
                withSaveMode &&
                setFieldValue &&
                field &&
                (value || value === '')
              )
                setFieldValue(field, value)
              switchMode(e)
            }}
          >
            Cancel
          </StyledCancelTypography>
        </Box>
      ) : (
        <Box
          display="flex"
          sx={{ '&:hover': { opacity: 0.8, cursor: 'pointer' } }}
          onClick={(e) => switchMode(e)}
        >
          <StyledEditTypography>Edit</StyledEditTypography>
          <EditIcon />
        </Box>
      )}
    </Box>
  )
}
