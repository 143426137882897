import { Box, Typography } from '@material-ui/core'
import { ArrowBack, ArrowForward, Close } from '@material-ui/icons'
import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'

import { SimpleButton } from '../../../../../../../components/ui/Buttons/SimpleButton'
import { Loader } from '../../../../../../../components/ui/Loader'
import { useWizard } from '../../../../../../../components/Wizard'
import { usePartner } from '../../../../../../../hooks/usePartner'
import { useSimpleModal } from '../../../../../../../hooks/useSimpleModal'
import { useWindowSize } from '../../../../../../../hooks/useWindowSize'
import { FieldLabel, ScreenContent } from '../ABSInquiryCreate.styles'

import { JotFormStep } from './JotFormStep'

export interface WizardScreenProps {
  title: string
  index: number
  buttonLabel?: string
  buttonDisabled?: boolean
  stepData?: any
  isLoading?: boolean
}

export const WizardStep: React.FC<WizardScreenProps> = ({
  children,
  index,
  title,
  buttonLabel,
  buttonDisabled,
  isLoading,
  stepData,
}) => {
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { getStep, activeStep, maxSteps, onFinish, onNext } = useWizard()

  const stepNumber = activeStep + 1 // index to number
  const { nextStep, previousStep, isActive } = getStep(index)
  const loading = isLoading || isSubmitting

  const handleOnNext = async () => {
    setIsSubmitting(true)
    try {
      if (stepNumber === maxSteps) {
        await onFinish(stepData)
      } else {
        await onNext(stepData)
        nextStep()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleOnBack = () => {
    if (index === 0) {
      history.goBack()
    } else {
      previousStep()
    }
  }

  return isActive ? (
    <Box
      maxHeight={550}
      position={isMobile ? 'static' : 'relative'}
      paddingTop={isMobile ? '24px' : 0}
    >
      <ScreenContent>
        {title && <FieldLabel sx={{ mb: '10px' }}>{title}</FieldLabel>}
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          justifyContent={isMobile ? 'flex-start' : 'center'}
          alignItems="flex-start"
          style={{ animation: `fadeIn 2s` }}
        >
          {children}
        </Box>
      </ScreenContent>
      <Box
        display="flex"
        style={{
          background: 'white',
          height: isMobile ? '100px' : 'unset',
          padding: isMobile ? '16px 0 0 0' : '24px 0 0 0',
          paddingBottom: isMobile ? '40px' : 0,
          position: isMobile ? 'fixed' : 'sticky',
          bottom: 0,
          left: '24px',
          right: '24px',
          borderTop: '1px solid rgb(210 210 210 / 40%)',
          zIndex: 1300,
        }}
      >
        <Box
          onClick={handleOnBack}
          display="flex"
          flex={1}
          alignItems="center"
          position={isMobile ? 'fixed' : 'sticky'}
          top="32px"
        >
          {!isMobile ? (
            stepNumber === 1 ? (
              <Box
                display="flex"
                alignItems="center"
                sx={{ '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
              >
                <Close style={{ color: '#9396A3' }} />
                <Typography color="#9396A3" variant="body1" align="center">
                  Close
                </Typography>
              </Box>
            ) : (
              <ArrowBack style={{ color: '#000' }} />
            )
          ) : (
            <ArrowBack style={{ color: '#9396A3' }} />
          )}
        </Box>
        {maxSteps > 1 && stepNumber && (
          <Box
            display="flex"
            justifyContent="center"
            flex={1}
            style={{ alignSelf: 'end' }}
          >
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent={isMobile ? 'center' : 'flex-start'}
            >
              <Typography
                variant="h6"
                sx={{ color: (theme) => theme.palette.primary.light }}
                fontSize={32}
              >
                {stepNumber}
              </Typography>
              <Typography color="#9396A3" variant="caption" fontSize={16}>
                /{maxSteps}
              </Typography>
            </Box>
          </Box>
        )}
        <Box display="flex" flex={1} justifyContent="flex-end">
          <SimpleButton
            style={{ flex: 'initial' }}
            disabled={buttonDisabled}
            variant="contained"
            type="submit"
            onClick={handleOnNext}
          >
            {buttonLabel || stepNumber === maxSteps ? (
              <>Save</>
            ) : (
              <>
                Next <ArrowForward />
              </>
            )}
          </SimpleButton>
        </Box>
      </Box>
      {loading && <Loader position="absolute" />}
    </Box>
  ) : null
}
