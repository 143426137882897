import { Box, styled, Typography } from '@material-ui/core'
import React, { ChangeEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { RichInputFieldContainer } from '../../../../../../components/ui/FormComponents/RichInputField/RichInputFieldContainer'
import { SelectField } from '../../../../../../components/ui/FormComponents/SelectField'
import { CurrencyTextInput } from '../../../../../../components/ui/Inputs/CurrencyInput'
import { usePricingTypes } from '../../../../../../utils/api/business'
import { formatPricingTypes } from '../../../../../../utils/helpers/venue'

const PricingTypesWrapper = styled(Box)`
  .MuiFormControl-root .MuiSelect-select {
    padding: 13.5px 5px;
  }
`

const PricingWrapper = styled(Box)`
  input:disabled {
    color: #878a97;
    background: transparent;
  }
`

interface EditPricingProps {
  showPricing: boolean
  pricing: string
  handlePriceChange: (e: ChangeEvent<HTMLInputElement>) => void
  submitted?: boolean
  shortPricingTextValue: string
  setShortPricingTextValue: (value: string, label: string) => void
  longPricingText: string | null
  setLongPricingText: any
}

export const LandlordEditPricing: React.FC<EditPricingProps> = ({
  showPricing,
  pricing,
  handlePriceChange,
  submitted,
  shortPricingTextValue,
  setShortPricingTextValue,
  longPricingText,
  setLongPricingText,
}) => {
  const { data: pricingTypes } = usePricingTypes()
  const pricingTypesOptions = React.useMemo(
    () => formatPricingTypes(pricingTypes),
    [pricingTypes]
  )
  const { t } = useTranslation()
  const showErrorOnSubmit = useMemo(() => {
    return submitted !== undefined
  }, [submitted])

  const isDescriptionError =
    showPricing &&
    (showErrorOnSubmit
      ? submitted && !shortPricingTextValue
      : !shortPricingTextValue)

  return (
    <>
      <Box display="flex" width="100%">
        <PricingWrapper flex={1}>
          <CurrencyTextInput
            disabled={!showPricing}
            name="minPrice"
            label={t('business.pricing.landlord.value', 'Value')}
            placeholder={t(
              'business.pricing.landlord.valuePlaceholder',
              'Type price here'
            )}
            value={pricing}
            onChange={handlePriceChange}
            error={
              showPricing &&
              (showErrorOnSubmit
                ? submitted && (!pricing || pricing === '$0')
                : !pricing || pricing === '$0')
            }
          />
        </PricingWrapper>
        {!!pricingTypesOptions?.length && (
          <PricingTypesWrapper flex={1} ml={3}>
            <Typography
              textAlign="left"
              variant="body2"
              sx={{
                mb: 2,
                color: (theme) =>
                  isDescriptionError ? '#F16547' : theme.palette.grey[200],
              }}
            >
              {t('business.pricing.landlord.description', 'Description')}
            </Typography>
            <SelectField
              disabled={!showPricing}
              label=""
              placeholder={t(
                'business.pricing.landlord.descriptionPlaceholder',
                'Select description'
              )}
              options={pricingTypesOptions}
              value={shortPricingTextValue}
              onChange={(value) =>
                setShortPricingTextValue(
                  value,
                  pricingTypesOptions?.find((type) => type.value === value)
                    ?.label
                )
              }
              errorState={isDescriptionError}
            />
          </PricingTypesWrapper>
        )}
      </Box>
      <Box mt={3} mb={12}>
        <Typography
          variant="body2"
          sx={{ mb: 2, color: (theme) => theme.palette.grey[200] }}
        >
          {t('business.pricing.landlord.details', 'Pricing Details')}
        </Typography>
        <RichInputFieldContainer
          value={longPricingText}
          maxLength={500}
          setValue={setLongPricingText}
        />
      </Box>
    </>
  )
}
