import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { capitalize, countBy } from 'lodash'
import * as React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { generatePath } from 'react-router-dom'

import CheckCircle from '../../../../components/svg/CheckCircle'
import DollarCircle from '../../../../components/svg/DollarCircle'
import FileText from '../../../../components/svg/FileText'
import Recycle from '../../../../components/svg/Recycle'
import Schedule from '../../../../components/svg/Schedule'
import Send from '../../../../components/svg/Send'
import { StyledTableName } from '../../../../components/ui/TableComponent/TableComponent.styles'
import { INQUIRY_STAGE_PIPELINE } from '../../../../constants/inquiry'
import { InquiryEvent } from '../../../../types/Inquiry'
import { useEventInquiriesStatuses } from '../../../../utils/api/events'
import { dashboardBookingsPath } from '../../../../utils/paths'
import { useAuthData } from '../../../../utils/providers/AuthProvider'
import { StyledPaper } from '../../Dashboard.styles'

type SummaryListItemProps = ListItemProps & { button?: any }

export const StyledListItem: React.FC<SummaryListItemProps> = styled(
  ListItem
)<SummaryListItemProps>(
  ({ theme }) => `
  padding-left: ${theme.spacing(4)}px;
  margin-bottom: 10px;
  &:hover {
   background-color: inherit;
  }
`
)

export const Summary: React.FC<{
  inquiryEvents: InquiryEvent[] | undefined
}> = ({ inquiryEvents = [] }) => {
  const history = useHistory()
  const { data: statusesData } = useEventInquiriesStatuses()
  const stages = statusesData?.inquiryStatus?.stages || {}
  const getStageByStatus = (status: string) =>
    statusesData?.inquiryStatus?.status_info[status]?.stage
  const countedStagesMap = countBy(inquiryEvents, (event) =>
    getStageByStatus(event.inquiry.status)
  )
  const countedStages = Object.entries(countedStagesMap)
  const { authUser } = useAuthData()
  const {
    params: { listingId },
  } = useRouteMatch<{ listingId: string }>()

  const getStageIcon = (stageLabel: string) => {
    switch (stageLabel) {
      case 'planning':
        return <Send />
      case 'invoicing':
        return <FileText />
      case 'proposing':
        return <Schedule />
      case 'payment':
        return <DollarCircle fill={stages['payment']?.text_color} />
      case 'production':
        return <Recycle />
      case 'completed':
        return <CheckCircle />
    }
  }

  const getValue = (value: string) => {
    const currentStage = countedStages.find(([key]) => value === key)
    if (currentStage) {
      return currentStage[1]
    } else {
      return 0
    }
  }

  return (
    <Box ml={4} component={StyledPaper} data-testid="summary">
      <StyledTableName variant="h4">Summary</StyledTableName>
      <List>
        {INQUIRY_STAGE_PIPELINE.map((stage) => {
          const currentStage = stages[stage]
          return (
            <StyledListItem
              onClick={() =>
                history.push({
                  pathname: generatePath(dashboardBookingsPath, {
                    listingId: authUser?.listing_id || +listingId,
                  }),
                  hash: stage,
                  search: `?stage=${stage}&page=1`,
                })
              }
              secondaryAction={
                <Typography data-testid="summary-value">
                  {getValue(stage)}
                </Typography>
              }
              sx={{
                bgcolor: currentStage?.bg_color,
                color: currentStage?.text_color,
              }}
              button
              key={capitalize(stage)}
            >
              <ListItemIcon>{getStageIcon(stage)}</ListItemIcon>
              <ListItemText primary={capitalize(stage)} />
            </StyledListItem>
          )
        })}
      </List>
    </Box>
  )
}
