import { ProfileData } from './Profile'

export enum AuthMethods {
  Magic = 'magicLink',
  Simple = 'simple',
  Google = 'google',
  Facebook = 'facebook',
  Microsoft = 'microsoft',
}

export type AuthUser = ProfileData & {
  token: string
}

export enum RegistrationSteps {
  VendorInformation = 'vendorInformation',
  VendorCategory = 'vendorCategory',
  SpaceLocation = 'spaceLocation',
  BusinessDetails = 'BusinessDetails',
  CreateProfile = 'createProfile',
  VendorCongrats = 'vendorCongrats',
}

export interface OnboardingStep {
  name: RegistrationSteps
  label: string
  index: number
  fields?: string[] // TODO add fields constructor
}

export enum AccountMenuActions {
  Profile = 'profile',
  Dashboard = 'dashboard',
  AdminDashboard = 'admin_dashboard',
  Logout = 'logout',
  UserLogin = 'user_login',
  BecomeAPartner = 'partner',
}

export enum AuthMenuActions {
  Signin = 'signin',
  Signup = 'signup',
  Forgot = 'forgot',
  Reset = 'reset',
  Info = 'info',
  WelcomeBack = 'welcomeBack',
  Verification = 'verification',
}

export enum MobileMenuActions {
  AboutUs = 'about_us',
  Contact = 'contact',
  Blog = 'blogs',
}

export enum MobileMenuAccountActions {
  CreateNewEvent = 'create_new_event',
  // EventInOwnVenue = 'event_in_own_venue',
  Venues = 'venues',
  Vendors = 'vendors',
  MyEvents = 'my_events',
}
