import { Button, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const StyledButton = styled(Button)(
  ({ theme, disabled }) => `
  background: ${theme.palette.common.buttonBg};
  font-family: ${theme.typography.body1.fontFamily};
  color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  
  height: 48px;
  padding: 15px 40px;

  text-transform: none;

  &:hover {
    background: ${theme.palette.common.buttonBg};
    opacity: 0.95;
  }
  
  ${MEDIA_RULES.SMALL} {
    width: 100%;
    font-size: 14px;
    line-height: 1.2 !important;
    padding: 12px;
  }

  ${
    disabled &&
    `
    background: #D7D7D7;
    font-weight: 500;
    &.Mui-disabled {
        color: white; 
    }
  `
  }
`
)
