import { Box, ListItem, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const VenueDetailsWrapper = styled(Box)(
  ({ theme }) => `
        background-color: ${theme.palette.background.default};

        .content {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            ${MEDIA_RULES.SMALL} {
              flex-wrap: wrap
            }
        }
        
        .details-info {
            width: 70%;
            ${MEDIA_RULES.SMALL} {
              width: 100%
            }
        }
        
        .venue-inquiry {
            margin-left: 107px;
            display: flex;
            width: 30%;
            flex-direction: column;
            align-items: center;

            ${MEDIA_RULES.SMALL} {
              width: 100%;
              margin-left: 0;
              align-items: flex-start;
            }

            .message-icon-button {
                position: absolute;
                right: 50px;
                color: white;
                background: black;
            }

            .inquiry-form {
                max-width: 420px;
                background: #FFFFFF;
                box-shadow: 10px 10px 50px 3px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                text-align: center;
                padding-left: 25px;
                padding-right: 25px;

                width: 100%;
                height: auto;

                .header {
                    padding-top: 15px;
                    text-align: center;
                }

                .inquiry-input {
                    display: flex;
                    flex: 1;
                    margin-top: 25px;
                }

                .total-section {
                    display: flex;
                    flex-direction: column;
                    padding: 20px 0;
                    margin: 20px 0;

                    button {
                        margin: 20px 0;
                    }
                }

                .total-label {
                    font-size: 16px;
                    font-weight: 600;
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px
                }
            }
        }
    `
)

export const VenueTitle = styled(Typography)(
  ({ theme }) => `
    font-size: 40px;
    font-weight: 600
    `
)

export const IncludesWrapper = styled(Box)(
  ({ theme }) => `
  
    .info-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .disabled {
        text-decoration-line: line-through;
        color: #B5B5B5;
    }
`
)

export const StyledListItem = styled(ListItem)(
  ({ theme }) => `padding-left: ${theme.spacing(4)}px;`
)

export const AboutWrapper = styled(Box)`
  .param-box {
    margin-right: 24px;
    padding: 16px;
    color: black;
    background: #f7f7f8;

    .MuiTypography-root {
      margin-left: 10px;
    }
  }

  .feature-icon {
    padding: 10px;
    background: #f7f7f8;

    &.pro-tip {
      padding: 5px;
    }
  }

  .about {
    overflow: hidden;
    position: relative;

    &.possible-more {
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: -50%;
        ${MEDIA_RULES.SMALL} {
          background: linear-gradient(
            360deg,
            #ffffff 40.98%,
            rgba(255, 255, 255, 0) 132.79%
          );
        }
      }
    }

    &.read-more {
      height: 100%;
      &:after {
        background: none;
      }
    }
  }

  .less-more {
    font-size: 12px;
    font-weight: 600;
    color: #4e4bec;
    margin-top: 10px;
    cursor: pointer;
    width: fit-content;
  }

  .about-more .less-more {
    display: none;
  }
`

export const ExclusiveVendorsTextWrapper = styled(Box)`
  background: rgba(47, 84, 235, 0.1);
  padding: 8px 8px 8px 16px;
  margin-top: 32px;
  border: 1px solid rgba(47, 84, 235, 0.15);
  border-radius: 3px;
  width: 100%;
`
