import { isPartner } from 'constants/partners'

import { Loader } from 'components/ui/Loader'
import React, { Suspense } from 'react'

const AbsForm = React.lazy(() =>
  import('./ABS/ABSInquiryCreate').then(({ AbsInquiryCreateForm }) => ({
    default: AbsInquiryCreateForm,
  }))
)

const DefaultForm = React.lazy(() =>
  import('./InquiryCreate.controller').then(
    ({ InquiryCreateFormController }) => ({
      default: InquiryCreateFormController,
    })
  )
)

export const InquiryCreateForm = (
  props: React.ComponentProps<typeof AbsForm | typeof DefaultForm>
) => {
  const Form = isPartner() ? AbsForm : DefaultForm

  return (
    <Suspense fallback={<Loader />}>
      <Form {...props} />
    </Suspense>
  )
}
