import { GooglePlace } from '../components/ui/Inputs/GoogleLocationSelect'
import { SettingsData } from '../types/general'
import { t } from '../utils/i18n'
// @ts-ignore

export const getDefaultEventName = (isAbs: boolean): string => {
  return isAbs
    ? t('partners.abs.bookings.default', 'My booking')
    : t('events.default', 'My Event')
}

export const EventDraftStatus = 'draft'

export const userDefaultEvent: any = {
  status: EventDraftStatus,
  active: '1',
  name: '',
  startDate: null,
  endDate: null,
  neighborhood: '',
  location: '',
  guests: '',
  id: 'defaultEvent',
}

export const EmptyGooglePlace: GooglePlace = { place_id: '', description: '' }

export const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const guestsVariants = [
  { value: 's80', label: '<80' },
  { value: 's150', label: '80-150' },
  { value: 's250', label: '151-250' },
  { value: 's400', label: '251-400' },
  { value: 's401', label: '>400' },
]
export const priceVariants = [
  { label: '$', value: 1 },
  { label: '$$', value: 2 },
  { label: '$$$', value: 3 },
  { label: '$$$$', value: 4 },
]
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z$&+,:;=?@#|'<>.-^*()%!]{3,}$/
export const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/im
// export const websiteRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
export const websiteRegExp =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
export const pdfFileRegExp = /^[^+]+\.pdf$/

export const keyCodes = {
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  DELETE: 46,
}

export const initialSettings: SettingsData = {
  stripeFeePercent: 2.9,
  stripeFixTax: 0.3,
  defaultAppFee: 15,
}

export const filesMaxSize = 15048000
export const imagesMaxSize = 10240000

export const dayInSeconds = 86400
export const dayInMilliseconds = dayInSeconds * 1000
export const weekInMilliseconds = dayInMilliseconds * 7

export const SERVER_EVENTS_QUERY_KEY = 'userServerEvents'
export const ADMIN_USERS_QUERY_KEY = 'adminUsers'

export enum AssetLoadingStatus {
  STARTED = 'STARTED',
  SUCCEEDED = 'SUCCEEDED',
  ERRORED = 'ERRORED',
}

export enum AssetType {
  DASHBOARD_IMG = 'dashboardImg',
  PROFILE_IMG = 'profileImg',
  DEFAULT_PACKAGE_IMG = 'defaultPackageImg',
}

export type URLString = string

export interface WidthHeight {
  width?: number
  height?: number
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const TOAST_NOTIFICATION_CONTAINER_ID = 'toast-notification'

export const BREAKPOINTS = {
  SMALL: 767,
  MEDIUM: 991,
  LARGE: 1324,
}

export const NOTIFICATIONS_REFETCH_INTERVAL = 10000

export const DECIMAL_NUMBER_REGEXP =
  /^(\$|)(,*[0-9]+\d{0,2}(\d{3})*|(,*[0-9]+\d*))*(\.\d*)?$/
