import { Box, Typography } from '@material-ui/core'
import { Dispatch, SetStateAction, useEffect } from 'react'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router'

import ExclamationCircle from '../../../../../../components/svg/ExclamationCircle'
import {
  EVENT_SERVICE_TABS,
  EventServiceTab,
  VENDOR_SERVICE_TAB,
} from '../../../../../../constants/events'
import {
  InquiryContractStatuses,
  InquiryStatuses,
} from '../../../../../../constants/inquiry'
import { useUrlParams } from '../../../../../../hooks/useUrlParams'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { EventVendor, EventVenue } from '../../../../../../types/Event'
import { InquiryWithDetails } from '../../../../../../types/Inquiry'
import { getEventDetailsPath } from '../../../../../../utils/paths'
import { Tab, TabsWrapper } from '../../../ServiceBookingDetails.styles'

interface TabsNavigationProps {
  activeTab: EventServiceTab
  setActiveTab: Dispatch<SetStateAction<EventServiceTab>>
  isRowExpanded: boolean
  shouldShowAdditionalInfo: boolean
  inquiryDetails?: InquiryWithDetails | EventVendor | EventVenue
  setIsRowExpanded: (isExpanded: boolean) => void
  inquiryId: string
}

export const TabsNavigation: React.FC<TabsNavigationProps> = ({
  activeTab,
  inquiryId,
  setActiveTab,
  isRowExpanded,
  shouldShowAdditionalInfo,
  inquiryDetails,
  setIsRowExpanded,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { urlParams } = useUrlParams()
  const { isMobile } = useWindowSize()

  const onChangeNavValue = (value: EventServiceTab) => {
    setActiveTab(value)
    const params = inquiryId ? { inquiryId, tab: value } : {}
    history.push(
      getEventDetailsPath(
        location.pathname,
        // @ts-ignore
        params
      )
    )
  }

  const vendorTabs = [...EVENT_SERVICE_TABS]
  vendorTabs.splice(1, 0, VENDOR_SERVICE_TAB)

  const tabs = shouldShowAdditionalInfo ? vendorTabs : EVENT_SERVICE_TABS

  const hasUpdates = (tab: any) => {
    return (
      (inquiryDetails?.status === InquiryStatuses.STATUS_CONFIRMED &&
        tab.id === EventServiceTab.PAYMENTS &&
        // @ts-ignore
        inquiryDetails?.invoice_bundles?.length) ||
      ((inquiryDetails?.status ===
        InquiryStatuses.STATUS_VENUE_CONTRACT_PENDING ||
        inquiryDetails?.status === InquiryStatuses.STATUS_ACCEPTED) &&
        tab.id === EventServiceTab.CONTRACTS &&
        inquiryDetails.contracts?.length &&
        inquiryDetails.contracts.some(
          (item) => item.status === InquiryContractStatuses.STATUS_COMPLETED
        ))
    )
  }

  useEffect(() => {
    if (urlParams.tab && urlParams.inquiryId === inquiryId && !isMobile) {
      setActiveTab(urlParams.tab)
      setIsRowExpanded(true)
    }
  }, [urlParams.tab, urlParams.inquiryId, isMobile])

  return (
    <TabsWrapper>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          className={`${activeTab === tab.id && isRowExpanded ? 'active' : ''}`}
          onClick={() => onChangeNavValue(tab.id)}
        >
          <Box display="flex">
            {hasUpdates(tab) ? <ExclamationCircle size={20} /> : null}
            <Typography fontWeight={500} ml={1} fontSize="13px">
              {tab.label}
            </Typography>
          </Box>
        </Tab>
      ))}
    </TabsWrapper>
  )
}
