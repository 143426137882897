import { LocalEvent } from '../../types/Event'
import { convertDateTimeToServerFormat } from '../helpers/date'

export const userEventRecordMapper = (item: Partial<LocalEvent>) => {
  const result = <Record<string, string>>{}
  if (item.id) result.id = item.id.toString()
  if (item.eventType) result.eventType = item.eventType.toString()
  if (item.neighborhood) result.neighborhood = item.neighborhood.toString()
  if (item.start_date?.toLocaleDateString) {
    result.start_date = convertDateTimeToServerFormat(item.start_date)
    result.startDate = convertDateTimeToServerFormat(item.start_date)
  }
  if (item.end_date?.toLocaleDateString) {
    result.end_date = convertDateTimeToServerFormat(item.end_date)
    result.endDate = convertDateTimeToServerFormat(item.end_date)
  }
  if (item.guests) result.guests = item.guests.toString()
  if (item.active) result.active = item.active.toString()
  if (item.name) result.name = item.name.toString()
  if (item.venue) result.venue = JSON.stringify(item.venue)
  if (item.vendors) result.vendors = JSON.stringify(item.vendors)
  if (item.status) result.status = item.status.toString()
  if (item.budgets) result.budgets = JSON.stringify(item.budgets)
  if (item.client_venue) result.client_venue = JSON.stringify(item.client_venue)
  if (item.style) result.style = item.style
  if (item.type) result.type = item.type
  if (item.load_in_end_date?.toLocaleDateString)
    result.load_in_end_date = convertDateTimeToServerFormat(
      item.load_in_end_date
    )
  if (item.load_in_start_date?.toLocaleDateString)
    result.load_in_start_date = convertDateTimeToServerFormat(
      item.load_in_start_date
    )
  if (item.load_out_end_date?.toLocaleDateString)
    result.load_out_end_date = convertDateTimeToServerFormat(
      item.load_out_end_date
    )
  if (item.load_out_start_date?.toLocaleDateString)
    result.load_out_start_date = convertDateTimeToServerFormat(
      item.load_out_start_date
    )
  if (item.notes) result.notes = item.notes.toString()

  result.changed = item.changed ? '1' : '0'
  result.withSubmittedJotForm = item.withSubmittedJotForm ? '1' : '0'

  return result
}

export const userEventRecordBackMapper = (item: Record<string, string>) => {
  const result = <LocalEvent>{}
  if (item.id) result.id = item.id
  if (item.eventType) result.eventType = item.eventType
  if (item.neighborhood && item.neighborhood)
    result.neighborhood = item.neighborhood.split(',')
  if (item.startDate) result.start_date = new Date(item.start_date)
  if (item.endDate) result.end_date = new Date(item.end_date)
  if (item.guests) result.guests = item.guests.toString()
  if (item.active) result.active = item.active.toString()
  if (item.name) result.name = item.name.toString()
  if (item.venue) result.venue = JSON.parse(item.venue)
  if (item.vendors) result.vendors = JSON.parse(item.vendors)
  if (item.client_venue) result.client_venue = JSON.parse(item.client_venue)
  if (item.status) result.status = item.status
  if (item.budgets) result.budgets = JSON.parse(item.budgets)
  if (item.style) result.style = item.style
  if (item.type) result.type = item.type
  if (item.changed === '1') result.changed = true
  if (item.withSubmittedJotForm === '1') result.changed = true
  if (item.load_in_end_date)
    result.load_in_end_date = new Date(item.load_in_end_date)
  if (item.load_in_start_date)
    result.load_in_start_date = new Date(item.load_in_start_date)
  if (item.load_out_end_date)
    result.load_out_end_date = new Date(item.load_out_end_date)
  if (item.load_out_start_date)
    result.load_out_start_date = new Date(item.load_out_start_date)
  if (item.notes) result.notes = item.notes.toString()

  return result
}
