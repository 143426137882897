import { Box, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'

import { FullCalendarComponent } from '../../../../components/FullCalendar'
import FullCalendarView from '../../../../components/svg/dashboard/FullCalendarView'
import {
  BlockContainer,
  BlockTitle,
} from '../../BusinessDetails/BusinessDetails.styles'

const CalendarContainer = styled(Box)`
  margin-top: 20px;
  width: 350px;

  ${MEDIA_RULES.SMALL} {
    width: 100%;
  }
`

const StyledTypography = styled(Typography)`
  color: #2f54eb;
  margin-left: 10px;
`

const StyledBox = styled(Box)`
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

interface CalendarPageProps {
  calendarGoogleId: string
  onOpenFullCalendar: (calendarGoogleId: string) => void
}

export const DashboardCalendar: React.FC<CalendarPageProps> = ({
  calendarGoogleId,
  onOpenFullCalendar,
}) => {
  return (
    <BlockContainer display="flex" my={4}>
      <Box width="100%">
        <BlockTitle>Calendar</BlockTitle>
        <StyledBox
          ml={-20}
          display="flex"
          alignItems="center"
          onClick={() => onOpenFullCalendar(calendarGoogleId)}
        >
          <FullCalendarView />
          <StyledTypography>Full view</StyledTypography>
        </StyledBox>
        <FullCalendarComponent
          calendarGoogleId={calendarGoogleId}
          initialView="timeGridWeek"
        />
      </Box>
    </BlockContainer>
  )
}
