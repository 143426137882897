import * as React from 'react'

import { VendorType } from '../types/Vendor'
import { useVendorTypes } from '../utils/api/vendors'

export const useVendorCategory = (
  enabled = true
): {
  isLoading: boolean
  getVendorCategory: (vendorType: VendorType['name']) => {
    id: VendorType['id']
    name: VendorType['name']
  }
} => {
  const { isLoading, data: vendorTypes } = useVendorTypes({}, { enabled })

  const getVendorCategory = React.useCallback(
    (vendorType: VendorType['name']) => {
      if (!vendorTypes?.length) {
        return { id: '', name: '' }
      }
      return (
        vendorTypes?.find(
          (category: VendorType) => category.id === vendorType
        ) || { id: '', name: '' }
      )
    },
    [vendorTypes?.length]
  )

  return { isLoading, getVendorCategory }
}
