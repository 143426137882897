import { FilterDropdown } from 'components/Filters/FilterDropdown'
import { FilterButton } from 'components/ui/Buttons/FilterButton'
import { useLocationState } from 'hooks/useLocationState'
import { isNil } from 'lodash/fp'
import {
  Dropdown,
  Item,
} from 'pages/marketplace/components/VenuesFilter/components/VenueLocationFilter/Dropdown'
import { LocationButton } from 'pages/marketplace/components/VenuesFilter/components/VenueLocationFilter/LocationButton'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { City, LocationsQuery } from 'utils/api/locations'
import { usePrefetchedQuery } from 'utils/helpers/query'

export const VendorLocationFilter = () => {
  const { t } = useTranslation()

  const locations = usePrefetchedQuery(LocationsQuery)
  const state = useLocationState(locations)

  if (isNil(state)) return null

  const { options, values, onChange } = state
  const cities = options.filter((city) => city.districts.length > 0)

  if (cities.length <= 1) {
    return null
  }

  const isSelected = (city: City) => values.city === city.slug
  const label = state.getLocation.city(values.city!).shortName

  return (
    <FilterDropdown
      renderTrigger={({ isOpen, toggleOpen }) => (
        <LocationButton onClick={toggleOpen} isOpen={isOpen} label={label} />
      )}
    >
      <Dropdown sx={{ minWidth: 280, paddingBottom: '10px' }}>
        {cities.map((city) => (
          <Fragment key={city.slug}>
            <Item>
              <FilterButton
                active={isSelected(city)}
                onClick={() => onChange('city', city.slug)}
              >
                {city.name}
              </FilterButton>
            </Item>
          </Fragment>
        ))}
      </Dropdown>
    </FilterDropdown>
  )
}
