import { Box, Typography } from '@material-ui/core'
import { VisibilityOutlined } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import GradientButton from '../../../../../components/ui/Buttons/GradientButton'
import { simpleModalControl } from '../../../../../hooks/useSimpleModal'

export const statusInfo: Record<string, any> = {
  draft: {
    value: 'draft',
    label: 'Draft',
    color: '#9396A3',
  },
  active: {
    value: 'active',
    label: 'Active',
    color: '#34C759',
  },
  moderation: {
    value: 'moderation',
    label: 'Waiting for review',
    color: '#FFE870',
  },
  disabled: {
    value: 'disabled',
    label: 'Deactivated by admin',
  },
}

export const Status: React.FC<{
  status: string
  previewModal: simpleModalControl
  handleBusinessPublish: () => void
}> = ({ status, previewModal, handleBusinessPublish }) => {
  const { t } = useTranslation()

  return (
    <Box
      padding="0 16px"
      mb="29px"
      width={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        <Typography fontWeight={500} align="center">
          Status:
          <Typography
            component="span"
            style={{ textTransform: 'capitalize' }}
            color={statusInfo[status]?.color || '#FFE870'}
          >
            &nbsp;{statusInfo[status]?.label || '-'}
          </Typography>
        </Typography>
      </Box>
      <Box
        mt="22px"
        sx={{
          color: '#2F54EB',
          cursor: 'pointer',
          '&:hover': { opacity: 0.8 },
        }}
        display="flex"
        alignItems="center"
        onClick={() => previewModal.toggleModal()}
      >
        <VisibilityOutlined />
        <Typography style={{ marginLeft: 10 }}>
          {t('business.preview', 'Preview')}
        </Typography>
      </Box>
      {status === statusInfo.draft.value && (
        <Box mt="22px" width="100%">
          <GradientButton
            style={{
              width: '100%',
              maxWidth: '138px',
              margin: '0 auto',
              padding: '8px 20px',
              fontSize: 16,
              borderRadius: 2,
            }}
            onClick={handleBusinessPublish}
          >
            Publish
          </GradientButton>
        </Box>
      )}
    </Box>
  )
}
