import { Popper, styled, Tooltip, TooltipProps } from '@material-ui/core'
import * as React from 'react'

const StyledTooltipPopper = styled(Popper)`
  .MuiTooltip-tooltip {
    border-radius: 2px;
    padding: 10px 15px;
    font-family: Poppins;
    background: #20202c;
  }

  .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
    margin-bottom: 5px !important;
  }
`

export const TooltipComponent: React.FC<TooltipProps> = (props) => {
  return (
    <Tooltip
      {...props}
      enterTouchDelay={0}
      PopperComponent={StyledTooltipPopper}
    >
      {props.children}
    </Tooltip>
  )
}
