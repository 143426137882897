import { Button, ButtonProps, styled } from '@material-ui/core'
import React from 'react'

const StyledButton = styled(Button)(
  ({ theme }) => `
  background:  #F16547;
  font-family: ${theme.typography.body1.fontFamily};
  color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  padding: 15px 40px;
  text-transform: none;
  &:hover {
    background-color: #f7a28f;
  }
`
)

export default function WarningButton(props: ButtonProps) {
  return <StyledButton {...props} />
}
