import * as React from 'react'
import { useEffect } from 'react'

import { UserRoles } from '../constants/profile'
import { InquiryEvent } from '../types/Inquiry'
import { useServerEvents } from '../utils/api/events'
import { useAuthData } from '../utils/providers/AuthProvider'

export const useEventList = () => {
  const { authUser, isAuthorized } = useAuthData()
  const isClient = UserRoles.isClient(authUser?.role)
  const isInquiriesEnabled = isAuthorized && isClient
  const { isLoading, data } = useServerEvents(authUser, {
    enabled: isInquiriesEnabled,
  })

  const [eventsList, setEventList] = React.useState<InquiryEvent[]>([])

  useEffect(() => {
    if (!isInquiriesEnabled || !data?.length) return
    if (data && data.length) {
      const eventsWithInquiry = data.map((event: InquiryEvent) => ({
        ...event,
        inquiry: event.inquiries_related[0],
      }))
      setEventList(eventsWithInquiry)
    }
  }, [data?.length, isInquiriesEnabled])

  return { isLoading, eventsList }
}
