import { Box, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const VendorTypesContainer = styled(Box)`
  .vendor-type-item {
    background: transparent;
    margin: 10px;
    ${MEDIA_RULES.SMALL} {
      margin-right: 0;
      margin-left: 10px;
    }

    &:nth-of-type(3n) {
      margin-right: 0;
      ${MEDIA_RULES.SMALL} {
        margin-right: 0;
      }
    }

    &:nth-of-type(3n + 1) {
      margin-left: 0;
      ${MEDIA_RULES.SMALL} {
        margin-left: 10px;
      }
    }
  }

  .vendor-type-name {
    text-transform: capitalize;
  }

  .picked-vendors-block {
    display: contents;
  }

  .cards-container {
    display: block;
    padding: 8px;
    padding-top: 55px;
    background: white;
    height: 100%;
    position: absolute;
    border: 1px solid #f7f7f8;
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }

    /* Optional: show position indicator in red */

    &::-webkit-scrollbar-thumb {
      background: #ff0000;
    }
  }

  .vendor-card {
    background: black;
    display: flex;
    position: relative;
    align-items: center;
    padding: 8px;
    margin-bottom: 10px;
    margin-top: 10px;

    .MuiIconButton-root {
      color: white;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .add-more {
    box-sizing: border-box;
    border-radius: 8px;

    width: 100%;
    min-height: 85px;
    margin-top: 10px;
    color: rgba(130, 130, 130, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ListItemWrapper = styled(Box)`
  padding: 20px 0;
  display: flex;
  margin-right: 20px;
  flex-direction: column;
`
