import { toArray, uniqBy } from 'lodash'

export const jotFormsMapping = (response: any[]) => {
  const data: any[] = []
  response.map((item) => {
    toArray(item.data.answers).map((answer: any) => {
      data.push({
        title: answer.text,
        answer: answer.answer,
        questionnaire_type: item.questionnaire_type,
        vendor_type: item.vendor_type,
        id: item.id,
        order: answer.order,
      })
    })
  })
  return data
}
