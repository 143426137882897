import { GenerateInvoiceFormValues } from './GenerateInvoicesForm'

export enum InvoiceFormSteps {
  LineItems = 'lineItems',
  Payment = 'payment',
  AdditionalInfo = 'additional',
  Preview = 'preview',
}

export const FORM_STEPS: Array<{
  slug: InvoiceFormSteps
  name: string
  fields: Array<keyof GenerateInvoiceFormValues>
  hidden?: boolean
  lastStep?: boolean
  submit?: boolean
}> = [
  {
    slug: InvoiceFormSteps.LineItems,
    name: 'Line items',
    fields: ['lineItems'],
  },
  {
    slug: InvoiceFormSteps.Payment,
    name: 'Payment terms',
    fields: ['initialPaymentPercent', 'initialDueDate', 'finalDueDate'],
  },
  {
    slug: InvoiceFormSteps.AdditionalInfo,
    name: 'Additional Notes',
    fields: ['message', 'extra'],
  },
  {
    slug: InvoiceFormSteps.Preview,
    name: 'Preview',
    hidden: true,
    submit: true,
    fields: [],
  },
]
