import axios from 'axios'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { EventDraftStatus } from '../constants'
import { LocalEvent } from '../types/Event'
import { getEvent } from '../utils/api/events'
import { confirmInvoicePayment } from '../utils/api/inquiries'
import { getRequestError } from '../utils/helpers/validations'
import { convertInquiryEventToUserEvent } from '../utils/mappers/inquiries'
import { eventPath } from '../utils/paths'
import { useAuthData } from '../utils/providers/AuthProvider'
import { useStorageEventsData } from '../utils/providers/EventsProvider'
import { useMessageModalData } from '../utils/providers/MessageModalProvider'

import { useUrlParams } from './useUrlParams'

interface UseEventById {
  isDraftEvent: boolean
  fetchEvent: () => void
  event: LocalEvent
}

export function useEventById(eventId: string): UseEventById {
  const source = React.useMemo(() => axios.CancelToken.source(), [])
  const {
    updateUserActiveEvent,
    getUserActiveEvent,
    setActiveEvent,
    setIsLoading,
    isLoading,
  } = useStorageEventsData()
  const { logout, authUser } = useAuthData()
  const eventData = getUserActiveEvent()
  const [event, setEvent] = useState(eventData)
  const { showMessage } = useMessageModalData()
  const history = useHistory()
  const { t } = useTranslation()
  const { urlParams } = useUrlParams()

  const isDraftEvent = useMemo(() => {
    return eventData?.status === EventDraftStatus
  }, [eventData])

  const onConfirmInvoicePayment = async (
    checkout_id: string,
    order_id: string
  ) => {
    setIsLoading(true)

    try {
      await confirmInvoicePayment({ checkout_id, order_id })

      history.push(
        generatePath(eventPath, {
          id: eventId,
        })
      )
      showMessage({
        title: t(
          'business.payment.success.title',
          'Payment has been successfully accepted!'
        ),
      })
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    }
    setIsLoading(false)
  }

  const fetchEvent = async () => {
    if (!isNaN(parseInt(eventId))) {
      try {
        const result = await getEvent(eventId, source.token)
        if (result && result.data.id == eventId) {
          const updatedEvent = convertInquiryEventToUserEvent(result.data)
          updateUserActiveEvent(updatedEvent)
          setEvent(updatedEvent)
        }
      } catch (e) {
        console.log(e.response, e.data, e.status, e.code)
      }
    }
  }

  useEffect(() => {
    if (eventId && eventData?.id !== eventId) setActiveEvent(eventId)
  }, [eventData?.id, eventId, isLoading])

  useEffect(() => {
    if (
      !!authUser &&
      urlParams.success === 'payment' &&
      urlParams.checkoutId &&
      urlParams.transactionId
    ) {
      onConfirmInvoicePayment(urlParams.checkoutId, urlParams.transactionId)
    }
  }, [authUser])

  useEffect(() => {
    if (!authUser || isDraftEvent) return

    fetchEvent()

    const interval = setInterval(() => fetchEvent(), 20000)
    return () => {
      clearInterval(interval)
    }
  }, [eventId, authUser, isDraftEvent])

  return {
    fetchEvent,
    isDraftEvent,
    event,
  }
}
