import { Box, IconButton, Link as MuiLink, Typography } from '@material-ui/core'
import { LocationOnOutlined, OpenInNew } from '@material-ui/icons'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'

import { Loader } from '../../../../../../components/ui/Loader'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { InquiryWithDetails } from '../../../../../../types/Inquiry'
import { vendorPath, venuePath } from '../../../../../../utils/paths'
import {
  StyledInfoBox,
  TabsTypography,
} from '../../../ServiceBookingDetails.styles'

import { InquiryContact } from './InquiryContact'

export const BookingInfo: React.FC<{
  event: any
  inquiryDetails?: InquiryWithDetails
}> = ({ event, inquiryDetails }) => {
  const { isMobile } = useWindowSize()
  const contactName = inquiryDetails?.venue
    ? inquiryDetails.venue.primary_contact?.first_name &&
      inquiryDetails.venue.primary_contact?.last_name
      ? `${inquiryDetails.venue.primary_contact?.first_name} ${inquiryDetails.venue.primary_contact?.last_name}`
      : `${inquiryDetails.venue.primary_contact?.first_name}`
    : inquiryDetails?.vendor?.contact_name || ''
  const contactPhone = inquiryDetails?.venue
    ? inquiryDetails.venue.primary_contact?.phone_number
    : inquiryDetails?.vendor?.phone || ''
  const contactInstagram = inquiryDetails?.venue
    ? inquiryDetails.venue.params?.instagram || ''
    : ''
  const contactWebsite = inquiryDetails?.venue
    ? inquiryDetails?.venue.website || ''
    : ''
  const contactEmail = inquiryDetails?.venue
    ? inquiryDetails?.venue.primary_contact?.email || ''
    : ''
  const pathToBusiness = inquiryDetails?.vendor
    ? vendorPath
    : inquiryDetails?.venue?.slug
    ? generatePath(venuePath, { slug: inquiryDetails?.venue?.slug })
    : ''

  return (
    <Box display={isMobile ? 'block' : 'grid'} gridTemplateColumns="1fr 1fr">
      <Box display="flex" flexDirection="column" mr={isMobile ? 0 : 4} mb={2}>
        <TabsTypography>{event?.isVenue ? 'Venue' : 'Vendor'}</TabsTypography>
        <StyledInfoBox>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography mb={4} fontWeight={500} variant="body2">
              {event.name}
            </Typography>
            <MuiLink
              component={Link}
              key={inquiryDetails?.id}
              target="_blank"
              to={pathToBusiness}
              mb={4}
            >
              <IconButton
                style={{
                  backgroundColor: 'rgba(47, 84, 235, 0.07)',
                  borderRadius: '2px',
                  width: '30px',
                  height: '30px',
                }}
              >
                <OpenInNew style={{ color: '#2F54EB' }} />
              </IconButton>
            </MuiLink>
          </Box>
          {!!event.address_package && (
            <Box display="flex" alignItems="center" mr={3}>
              <LocationOnOutlined style={{ color: '#9396A3' }} />
              <Typography
                ml="15px"
                color="#20202C"
                fontFamily="Poppins"
                sx={{ wordBreak: 'break-word' }}
              >
                {event.address_package}
              </Typography>
            </Box>
          )}
        </StyledInfoBox>
        <Box display="flex" mt={6} justifyContent="space-between">
          <Typography color="#9FA2B4" sx={{ mr: 1 }}>
            Booking ID:
          </Typography>
          <Typography>{inquiryDetails?.id}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" mb={2}>
        <TabsTypography>
          {event?.isVenue ? 'Venue' : 'Vendor'} contact
        </TabsTypography>
        <StyledInfoBox>
          {inquiryDetails ? (
            <InquiryContact
              name={contactName}
              phone={contactPhone}
              email={contactEmail}
              website={contactWebsite}
              instagram={contactInstagram}
              isVendor={!event?.isVenue}
            />
          ) : (
            <Loader size={30} height={4} />
          )}
        </StyledInfoBox>
      </Box>
    </Box>
  )
}
