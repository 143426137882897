import { Box } from '@material-ui/core'
import { PopperField } from 'components/ui/PopoverField'
import { useWindowSize } from 'hooks/useWindowSize'
import { forwardRef, ReactNode, useState } from 'react'

export type FilterDropdownProps = {
  children: ReactNode
  className?: string

  renderTrigger: (props: {
    isOpen: boolean
    toggleOpen: () => void
  }) => ReactNode
}

export const FilterDropdown = forwardRef((props: FilterDropdownProps, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  const { renderTrigger } = props
  const { isMobile } = useWindowSize()

  const toggleOpen = () => setIsOpen(!isOpen)
  const onClose = () => setIsOpen(false)

  return (
    <PopperField
      width={isMobile ? '100%' : 'unset'}
      ref={ref}
      placement="bottom-start"
      input={renderTrigger({ isOpen, toggleOpen })}
      onClose={onClose}
      content={isOpen && props.children}
      className={props.className}
    />
  )
})

FilterDropdown.displayName = 'FilterDropdown'
