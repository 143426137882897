import { Box, Button, Chip, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { MEDIA_RULES } from 'hooks/useWindowSize'

import { poppinsFontFamily } from '../../../theme/createTypography'

export const MapContainer = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(2)}px;
`
)

export const VenuesContainer = styled(Box)(
  ({ theme }) => `
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 4px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }

    &::-webkit-scrollbar-thumb {
        background: #D7D7D7;
    }
`
)

export const VenuesFilterWrapper = styled(Box)`
  position: sticky;
  box-shadow: rgb(210 210 210 / 40%) 0 4px 18px;
  top: 0;
  z-index: 1000;
  background: white;
  padding: 25px 0;

  .MuiButton-root {
    padding: 0 15px;
    height: 48px;

    ${MEDIA_RULES.SMALL} {
      height: 40px;
    }
  }
`

export const StyledTypography = styled(Typography)(
  ({ theme }) => `
    margin-top: 32px;
    margin-bottom: 26px;
    color: ${theme.palette.secondary.dark};
`
)

export const StyledChip = styled(Chip)`
  border-radius: 2px;
  min-height: 48px;
  background-color: #ffffff;
  box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
  padding: 10px;

  .MuiChip-deleteIcon {
    color: black;
    margin: 2px 10px;
  }
  .MuiChip-label {
    font-family: ${poppinsFontFamily};
    font-size: 14px;
  }
`
