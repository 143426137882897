import { List, ListItem, ListItemText, styled } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { ConfirmDialog } from '../../components/ui/Modals/ConfirmDialog'
import { venuesPath } from '../../utils/paths'

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    line-height: 28px;
  }
`

export const SuccessBusinessMagicLink = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  return (
    <ConfirmDialog
      confirmLabel={t('messages.gotIt', 'Got it')}
      dialogTitle={t('messages.checkEmail', 'Check Your Email')}
      cancelLabel={'label'}
      onClose={() => history.push(venuesPath)}
      open={true}
    >
      <List>
        <ListItem disableGutters>
          <StyledListItemText
            primary={t(
              'messages.business.registration.register',
              'Your business has been successfully registered! We have sent an email verification to the address provided.  Visit your dashboard and complete your business profile after clicking through that link.'
            )}
          />
        </ListItem>
      </List>
    </ConfirmDialog>
  )
}
