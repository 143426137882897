import { Popper, styled } from '@material-ui/core'
import { excludeProps } from 'utils/styles/styled'

type PopoverContainerProps = {
  scrollable: boolean
}

export const PopoverContainer = styled(Popper, {
  shouldForwardProp: excludeProps<PopoverContainerProps>(['scrollable']),
})<PopoverContainerProps>`
  z-index: 1400;

  .MuiPaper-root {
    margin-top: 20px;
    background: white;
    overflow-y: auto;
    max-height: ${(props) => (!props.scrollable ? 'auto' : '600px')};
  }
`
