import { Box, Typography } from '@material-ui/core'
import { RoomOutlined } from '@material-ui/icons'
import React, { useState } from 'react'

import RemoveIcon from '../../../components/svg/RemoveIcon'
import LinkText from '../../../components/ui/Buttons/LinkText/LinkText'
import {
  geocodeLocation,
  GoogleLocationSelect,
  GooglePlace,
} from '../../../components/ui/Inputs/GoogleLocationSelect'
import { WizardStep } from '../../../components/Wizard'
import { EmptyGooglePlace } from '../../../constants'
import { OnboardingStep } from '../../../types/Auth'
import { removeElementFromArray } from '../../../utils/helpers/data'
import { useMessageModalData } from '../../../utils/providers/MessageModalProvider'

export const SpaceLocation: React.FC<{ step: OnboardingStep }> = ({ step }) => {
  const [locations, setLocations] = useState<GooglePlace[]>([EmptyGooglePlace])
  const [isGeocoding, setGeocoding] = useState(false)
  const { showMessage } = useMessageModalData()

  const handleAddLocation = (place: any, index: number) => {
    const updatedLocations = [...locations]
    updatedLocations[index] = place
    setLocations(updatedLocations)
  }

  const handleRemoveLocation = (index: number) => {
    const cloneLocations = [...locations]
    removeElementFromArray(cloneLocations, index)
    setLocations(cloneLocations)
  }

  const handleCurrentLocation = () => {
    setGeocoding(true)
    navigator.geolocation.getCurrentPosition(
      function (position) {
        geocodeLocation(
          position.coords.latitude,
          position.coords.longitude,
          (result) => {
            handleAddLocation(
              { ...result, description: result.formatted_address },
              0
            )
            setGeocoding(false)
          }
        )
      },
      (e) => {
        showMessage({
          title: e.message,
          type: 'error',
        })
        setGeocoding(false)
      }
    )
  }

  return (
    <WizardStep
      index={step.index}
      title={step.label}
      buttonDisabled={!locations[0]?.place_id}
      stepData={{ locations }}
    >
      <Box mb={6} display="flex" width="85%">
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          alignItems="flex-end"
          onClick={handleCurrentLocation}
          style={{
            padding: 20,
            color: '#2F54EB',
            border: '1px solid #2F54EB',
            cursor: 'pointer',
          }}
        >
          <Typography fontSize={16}>
            {isGeocoding ? 'Getting your location...' : 'Use current location'}
          </Typography>
          <RoomOutlined />
        </Box>
      </Box>
      <Box mb={5} display="flex" justifyContent="center" width="85%">
        <Typography variant="h4">Or</Typography>
      </Box>
      <Box width="85%">
        {locations.map((location, index) => (
          <Box
            key={location.place_id || index}
            style={{ position: 'relative' }}
          >
            <GoogleLocationSelect
              value={location}
              onChange={(place) => handleAddLocation(place, index)}
            />
            {locations.length > 1 && (
              <Box
                style={{
                  position: 'absolute',
                  right: -40,
                  top: 25,
                  cursor: 'pointer',
                }}
                onClick={() => handleRemoveLocation(index)}
              >
                <RemoveIcon />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </WizardStep>
  )
}
