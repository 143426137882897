import { Box, styled, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import DollarBlack from '../../../components/svg/DollarBlack'
import Email from '../../../components/svg/Email'
import HouseFilledSmall from '../../../components/svg/HouseFilledSmall'
import Idea from '../../../components/svg/Idea'
import Location from '../../../components/svg/Location'
import Phone from '../../../components/svg/Phone'
import Planet from '../../../components/svg/Planet'
import UserSmallFilled from '../../../components/svg/UserSmallFilled'
import { SimpleButton } from '../../../components/ui/Buttons/SimpleButton'
import { useWizard, WizardStep } from '../../../components/Wizard'
import { OnboardingStep } from '../../../types/Auth'
import {
  createNewVendorForClients,
  createNewVendorForGuests,
} from '../../../utils/api/profile'
import { getCostLevel } from '../../../utils/helpers/vendors'
import { createVendorMapper } from '../../../utils/mappers/vendors'
import {
  dashboardBusinessDetailsPath,
  messagesPath,
} from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'

import { SectionHeader } from './VendorInformationForm'

const StyledTypography = styled(Typography)`
  margin-left: 10px;
`

export const VendorCongrats: React.FC<{
  step: OnboardingStep
  isAuthUser: boolean
}> = ({ step, isAuthUser }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { wizardData } = useWizard()
  const { getProfile } = useAuthData()

  const prevStepData = wizardData.data
  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async () => {
    const data = createVendorMapper(prevStepData)
    try {
      setIsLoading(true)
      const response: any = await (isAuthUser
        ? createNewVendorForClients(data)
        : createNewVendorForGuests(data))
      const id = response.data.id

      if (isAuthUser) {
        await getProfile(id.toString())
        history.push(
          generatePath(dashboardBusinessDetailsPath, {
            listingId: id.toString(),
          })
        )
      } else {
        history.push(messagesPath + '/success-business-magic-link')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <WizardStep
      title={step.label}
      index={step.index}
      isLoading={isLoading}
      showStepButton={false}
    >
      <SectionHeader variant="body2">
        {t(
          'onboarding.vendor.final.heading',
          'Thanks For Providing Your Information'
        )}
      </SectionHeader>
      <Typography>
        {t(
          'onboarding.vendor.final.review',
          'We’ll review the information and get back to you in 1-3 working days.'
        )}
      </Typography>
      <Box display="flex" flexDirection="column" mt={8}>
        {prevStepData.name && (
          <SectionHeader variant="body2">{prevStepData.name}</SectionHeader>
        )}
        {prevStepData.description && (
          <Box display="flex" alignItems="center" mb={4}>
            <Typography>{prevStepData.description}</Typography>
          </Box>
        )}
        {prevStepData.office && (
          <Box display="flex" alignItems="center" mb={4}>
            <HouseFilledSmall />
            <StyledTypography>
              {prevStepData.office.description}
            </StyledTypography>
          </Box>
        )}
        {prevStepData.website && (
          <Box display="flex" alignItems="center" mb={4}>
            <Planet />
            <StyledTypography>{prevStepData.website}</StyledTypography>
          </Box>
        )}
        {prevStepData.location && (
          <Box display="flex" alignItems="center" mb={4}>
            <Location />
            <StyledTypography>{prevStepData.location}</StyledTypography>
          </Box>
        )}
        {prevStepData[prevStepData.type] && (
          <Box display="flex" alignItems="center" ml={-1} mb={4}>
            <Idea />
            <Typography sx={{ ml: 1 }}>
              {prevStepData[prevStepData.type]}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" mt={8}>
        <SectionHeader variant="body2">
          {t('onboarding.vendor.final.pricing', 'Pricing')}
        </SectionHeader>
        <Box display="flex" alignItems="center" mb={4}>
          <DollarBlack />
          <StyledTypography>
            {getCostLevel(prevStepData.costLevel)}
          </StyledTypography>
        </Box>
      </Box>
      {!isAuthUser && (
        <Box display="flex" flexDirection="column" mt={8}>
          <SectionHeader variant="body2">
            {t('onboarding.vendor.final.contact', 'Contact')}
          </SectionHeader>
          <Box display="flex" alignItems="center" mb={4}>
            <UserSmallFilled />
            <StyledTypography>{prevStepData.contactName}</StyledTypography>
          </Box>
          <Box display="flex" alignItems="center" mb={4}>
            <Email />
            <StyledTypography>{prevStepData.contactEmail}</StyledTypography>
          </Box>
          <Box display="flex" alignItems="center" mb={4}>
            <Phone />
            <StyledTypography>{prevStepData.phone}</StyledTypography>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="column" mt={4} width={0.8}>
        <SimpleButton
          disabled={Boolean(!prevStepData)}
          sx={{ mb: 5 }}
          fullWidth
          variant="contained"
          onClick={onSubmit}
        >
          {t('onboarding.vendor.final.register', 'Register')}
        </SimpleButton>
      </Box>
    </WizardStep>
  )
}
