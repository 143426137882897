import { merge } from 'lodash'
import * as React from 'react'
import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone'

import { AssetLoadingStatus, AssetType } from '../constants'
import {
  AssetRefDownload,
  ProgressPayload,
  UploadAction,
  UploadResponse,
} from '../types/FileUploadHandler'
import { generateFileMetadata } from '../utils/helpers/uploadFiles'

import { useLatestCallback } from './useLatestCallback'

interface UploaderProps extends DropzoneOptions {
  assetType: AssetType
  isValid?: (asset: AssetRefDownload) => boolean
  uploadFile?: (action: UploadAction) => Promise<UploadResponse>
  onChange?: (asset: AssetRefDownload, progress?: ProgressPayload) => void
}

interface UploaderState extends DropzoneState {
  cancelUpload: (fileId: string) => void
}

export function useUploader({
  assetType,
  isValid,
  uploadFile,
  onChange,
  ...dropzoneOptions
}: UploaderProps): UploaderState {
  const pendingFiles = React.useRef([] as string[])
  const removedFiles = React.useRef([] as string[])
  const fileBlobUrls = React.useRef([] as string[])
  const latestOnChange = useLatestCallback(onChange || function () {})

  React.useEffect(() => {
    const blobUrls = fileBlobUrls.current
    return () => {
      // Revoke blob data urls to avoid memory leaks
      blobUrls.forEach((blobUrl) => {
        URL.revokeObjectURL(blobUrl)
      })
    }
  }, [])

  function cancelUpload(fileId: string) {
    // remove from pendingFiles and add to removedFiles
    removedFiles.current.push(fileId)
    const index = pendingFiles.current.indexOf(fileId)
    if (index > -1) {
      pendingFiles.current.splice(index, 1)
    }
  }

  function hasBeenCanceled(fileId: string) {
    return removedFiles.current.indexOf(fileId) > -1
  }

  async function handleDrop<T extends File>(acceptedFiles: T[]): Promise<void> {
    // if we only support single uploads, then we need to cancel any other pending uploads
    if (!dropzoneOptions.multiple) {
      pendingFiles.current.map((fileId) => {
        cancelUpload(fileId)
      })
    }

    acceptedFiles.map(async (acceptedFile) => {
      const [asset] = generateFileMetadata([acceptedFile], assetType)

      // keep track of blob urls for deletion
      // asset.metadata.previewUrl && fileBlobUrls.current.push(asset.metadata.previewUrl)

      // keep track of pending file ids for overwriting
      pendingFiles.current.push(asset.$assetRef)

      // Update caller with initial state of asset w/ metadata
      latestOnChange(asset)

      // Check if we have an upload function
      // and if asset is valid before uploading
      // if a validate function has been provided
      if (uploadFile && (!isValid || isValid(asset))) {
        const response = await uploadFile({
          asset,
          progress: ({ status, progress }) => {
            const uploadCanceled = hasBeenCanceled(asset.$assetRef)
            !uploadCanceled && latestOnChange(asset, { status, progress })
          },
        })

        const uploadCanceled = hasBeenCanceled(asset.$assetRef)
        // If upload has failed, handle case
        if (response.status === AssetLoadingStatus.ERRORED) {
          latestOnChange({
            ...asset,
            metadata: {
              ...asset.metadata,
              uploadFailed: true,
            },
          })
        } else if (!uploadCanceled) {
          const mergedAsset = merge(asset, response.data)
          latestOnChange(mergedAsset)
        }
      }
    })
  }

  return {
    ...useDropzone({
      ...dropzoneOptions,
      onDrop: handleDrop,
    }),
    cancelUpload,
  }
}
