import { Box, BoxProps, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'

import { useWindowSize } from '../../../../hooks/useWindowSize'

import {
  CustomModalContentWrapper,
  SimpleModalContentWrapper,
  SimpleModalWrapper,
} from './SimpleModal.styles'

export const SimpleModal: React.FC<
  { title?: string; open: boolean; onClose?: () => void } & BoxProps
> = ({ open, title, onClose, children, minWidth, ...boxProps }) => {
  const { isMobile } = useWindowSize()

  return (
    // @ts-ignore
    <SimpleModalWrapper
      role="dialog"
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.4)' } }}
    >
      <SimpleModalContentWrapper
        maxWidth={!isMobile ? boxProps.maxWidth || 452 : '100%'}
        minWidth={minWidth || 300}
        {...boxProps}
      >
        {onClose && (
          <IconButton
            color="inherit"
            style={{
              padding: isMobile ? 0 : '8px',
              position: isMobile ? 'fixed' : 'absolute',
              right: isMobile ? '3px' : 0,
              top: 5,
              zIndex: 5,
            }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        )}
        {title && (
          <Box
            display="flex"
            width={1}
            justifyContent="center"
            alignItems="center"
            style={{ boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)' }}
          >
            <Box
              display="flex"
              justifyContent="center"
              style={{ paddingTop: 25, paddingBottom: 15 }}
            >
              <Typography fontWeight={600} fontFamily="Poppins" variant="h4">
                {title}
              </Typography>
            </Box>
          </Box>
        )}
        <Box minWidth={minWidth} style={{ padding: 24 }}>
          {children}
        </Box>
      </SimpleModalContentWrapper>
    </SimpleModalWrapper>
  )
}

export const CustomModal: React.FC<
  { open: boolean; onClose: () => void } & BoxProps
> = ({ open, title, onClose, children, minWidth, ...boxProps }) => {
  return (
    <SimpleModalWrapper
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.4)' } }}
    >
      <CustomModalContentWrapper {...boxProps}>
        <IconButton
          color="inherit"
          style={{ position: 'absolute', right: 10, top: 10, zIndex: 5 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        {children}
      </CustomModalContentWrapper>
    </SimpleModalWrapper>
  )
}
