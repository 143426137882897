import { Theme } from '@material-ui/core'
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@material-ui/lab'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import { styled } from '@material-ui/styles'
import { startOfDay } from 'date-fns'
import { useCallback } from 'react'

export const DatePicker = styled((props: MuiDatePickerProps<Date>) => {
  const handleChange = useCallback(
    (date: Date | null): void => props.onChange(date ? startOfDay(date) : null),
    [props.onChange]
  )
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        views={['year', 'day']}
        disablePast
        showToolbar={false}
        {...props}
        onChange={handleChange}
      />
    </LocalizationProvider>
  )
})(({ theme }) => ({
  maxHeight: 345,
  background: 'white',
  margin: 0,
  padding: '0 24px',
  '& .MuiPaper-root': {
    background: '#fff',
  },
  '& .MuiDateRangePickerDay-day': {
    width: '100%',
    border: '1px solid #f7f7f8',
    transform: 'none',
  },
}))
