import { Box, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const StyledPromotionItem = styled(Box)(
  ({ theme }) => `
    display: flex;
    background: ${theme.palette.common.white};
    color: ${theme.palette.common.black};
    box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
    height: 360px;
    
    ${MEDIA_RULES.SMALL} {
      flex-direction: column;
      height: 591px;
    }
  `
)

export const StyledNameTypography = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 13px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  ${MEDIA_RULES.SMALL} {
    font-size: 18px;
    margin-bottom: 16px;
  }
`

export const StyledPromotionDescriptionBlock = styled(Box)(
  ({ theme }) => `
  background: #FFFDF1;
  border: 1px solid #FFF9D7;
  backdrop-filter: blur(12px);
  border-radius: 1px;
  padding: 16px;
  display: flex;
  
  * {
    margin: 0;
  }
`
)

export const StyledDescriptionTypography = styled(Typography)`
  &.more:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(360deg, #ffffff 0, rgba(255, 255, 255, 0) 70%);
  }

  * {
    margin: 0;
  }
`

export const StyledFooterLine = styled(Box)`
  position: absolute;
  bottom: 44px;
  right: 44px;
  left: 44px;

  ${MEDIA_RULES.SMALL} {
    bottom: 24px;
    right: 16px;
    left: 16px;
  }
`

export const StyledButton = styled(Box)(
  ({ theme }) => `
  display: inline-block;
  background: ${theme.palette.primary.light};
  min-width: 160px;
  flexShrink: 0;
  padding: 12px 24px;
  color: #fff;
  
  ${MEDIA_RULES.SMALL} {
    width: 100px;
  }
`
)

export const StyledCategoryBlock = styled(Box)`
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 12px;
  background: rgba(0, 51, 102, 0.07);

  ${MEDIA_RULES.SMALL} {
    height: 36px;
    margin-bottom: 12px;
  }
`

export const StyledCategoryTypography = styled(Typography)(
  ({ theme }) => `
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: ${theme.palette.common.black};
  
  ${MEDIA_RULES.SMALL} {
    font-size: 14px;
  }
`
)
