const DeclineSmall = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1.5C6.202 1.5 1.5 6.202 1.5 12S6.202 22.5 12 22.5 22.5 17.798 22.5 12 17.798 1.5 12 1.5Zm0 19.219A8.72 8.72 0 0 1 3.281 12 8.72 8.72 0 0 1 12 3.281 8.72 8.72 0 0 1 20.719 12 8.72 8.72 0 0 1 12 20.719Z"
        fill="#262626"
      />
      <path
        d="M10.875 16.125a1.125 1.125 0 1 0 2.25 0 1.125 1.125 0 0 0-2.25 0Zm.563-2.625h1.124a.188.188 0 0 0 .188-.188V6.939a.188.188 0 0 0-.188-.188h-1.124a.188.188 0 0 0-.188.188v6.375c0 .103.084.187.188.187Z"
        fill="#262626"
      />
    </svg>
  )
}

export default DeclineSmall
