import { Box } from '@material-ui/core'
import * as React from 'react'

import EmptySearchIcon from '../../svg/EmptySearchIcon'

export const EmptySearchResults: React.FC = () => {
  return (
    <Box
      width="100%"
      height="500px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <EmptySearchIcon />
    </Box>
  )
}
