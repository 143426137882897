import {
  assign,
  defaultTo,
  find,
  join,
  keyBy,
  map,
  mapValues,
  pipe,
  prop,
  update,
} from 'lodash/fp'
import { useQuery } from 'react-query'
import { CamelizeKeys, camelizeKeys } from 'utils/helpers/camelize'
import { evolve } from 'utils/helpers/object'
import { createPrefetchedQuery } from 'utils/helpers/query'
import { locationsApiPath } from 'utils/paths'
import { z } from 'zod'

import { clearObject } from '../helpers/data'
import { usePrefetchedQuery } from '../helpers/query'

import { get } from './api'

export const FALLBACK_DISTRICT_SLUG = '__ALL__'
export const FALLBACK_DISTRICT_NAME = 'Neighborhoods'

const LocationResponseSchema = z.object({
  cities: z.array(
    z.object({
      name: z.string().min(1),
      slug: z.string().min(1),
      short_name: z.string(),
      default: z.boolean(),
      districts: z.array(
        z.object({
          name: z.string().min(1).catch(FALLBACK_DISTRICT_NAME),
          slug: z.string().min(1).catch(FALLBACK_DISTRICT_SLUG),
          neighborhoods: z.array(
            z.object({
              name: z.string().min(1),
              slug: z.string().min(1),
            })
          ),
        })
      ),
    })
  ),
})

export type LocationResponse = z.infer<typeof LocationResponseSchema>
export type CityResponse = LocationResponse['cities'][number]
export type DistrictResponse = CityResponse['districts'][number]
export type NeighborhoodResponse = DistrictResponse['neighborhoods'][number]

export type City = CamelizeKeys<CityResponse>
export type District = CamelizeKeys<DistrictResponse>
export type Neighborhood = CamelizeKeys<NeighborhoodResponse>

export type Locations = {
  cities: Record<string, City>
  districts: Record<string, District>
  neighborhoods: Record<string, Neighborhood>
}

type LocationSlugs = {
  cities: string[]
  districts: string[]
  neighborhoods: string[]
}

export type LocationData = {
  defaultCity: string
  cities: City[]
  result: LocationSlugs
  entities: Locations
}

const prependSlug = (parentSlug: string) => (slug: string) =>
  join('/', [parentSlug, slug])
const parseResponse = (response: LocationResponse): LocationResponse =>
  pipe(
    LocationResponseSchema.parse,
    update(
      'cities',
      map((city: CityResponse) =>
        assign(city, {
          districts: city.districts.map(
            evolve({ slug: prependSlug(city.slug) })
          ),
        })
      )
    )
  )(response)

function normalizeLocations(cities: City[]): {
  result: LocationSlugs
  entities: Locations
} {
  const allDistricts = cities.flatMap((c) => c.districts)
  const allNeighborhoods = allDistricts.flatMap((d) => d.neighborhoods)

  return {
    result: {
      cities: map(prop('slug'), cities),
      districts: map(prop('slug'), allDistricts),
      neighborhoods: map(prop('slug'), allNeighborhoods),
    },
    entities: {
      cities: keyBy('slug', cities),
      districts: keyBy('slug', allDistricts),
      neighborhoods: keyBy('slug', allNeighborhoods),
    },
  }
}

const getDefaultCity = (cities: City[]) =>
  pipe(find(prop('default')), defaultTo(cities[0]), prop('slug'))(cities)

export async function fetchLocations(): Promise<LocationData> {
  const data = await get(locationsApiPath)
  const parsed = pipe(parseResponse, camelizeKeys)(data)
  const normalized = normalizeLocations(parsed.cities)
  return {
    defaultCity: getDefaultCity(parsed.cities),
    cities: parsed.cities,
    ...normalized,
  }
}

export const LocationsQuery = createPrefetchedQuery('locations', () =>
  fetchLocations()
)

export const usePrefetchedLocations = () =>
  usePrefetchedQuery(LocationsQuery, { staleTime: Infinity })
