import * as React from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { PromotionLabel } from '../../../../components/cards/labels/PromotionLabel'
import { Image } from '../../../../components/ui/Image'
import { usePartner } from '../../../../hooks/usePartner'
import { EventVenue } from '../../../../types/Event'
import { VenueItem } from '../../../../types/Venue'
import { venuePath } from '../../../../utils/paths'

import { StyledListItem } from './VenueListItem.styles'
import VenueListItemFooter from './VenueListItemFooter'

interface Props {
  venue: VenueItem
  addMode?: boolean
  onVenueClick?: (item: VenueItem) => void
  showSnackbar?: (item: EventVenue) => void
}

export const VenueListItem: React.FC<Props> = ({
  venue,
  onVenueClick,
  showSnackbar,
  addMode = false,
}) => {
  const history = useHistory()
  const route = history.location.pathname + history.location.search
  const { isAbs } = usePartner()
  const navigateToVenue = () =>
    history.push(
      generatePath(venuePath, {
        slug: venue.slug,
      }),
      { breadcrumbs: [{ label: `Marketplace`, route }] }
    )

  const handleItemCLick = () => {
    if (onVenueClick) {
      onVenueClick(venue)
    } else {
      navigateToVenue()
    }
  }

  return (
    <StyledListItem key={venue.id} style={{ height: isAbs ? 232 : 400 }}>
      {venue?.has_promotion && <PromotionLabel />}
      <Image
        onClick={isAbs ? undefined : handleItemCLick}
        image={venue?.images?.[0]}
        imageSize="medium"
        staticImageType="venue"
        alt={venue.name}
      />
      <VenueListItemFooter
        addMode={addMode}
        onClick={isAbs ? undefined : navigateToVenue}
        showSnackbar={showSnackbar}
        data={venue}
      />
    </StyledListItem>
  )
}
