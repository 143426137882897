import { CancelToken } from 'axios'
import { useQuery } from 'react-query'

import { getPartnerParams } from '../../constants/partners'
import { VendorUpdateRequest } from '../../types/dtos/vendors'
import { Vendor, VendorType, VendorTypesQueryOptions } from '../../types/Vendor'
import { clearObject } from '../helpers/data'
import {
  getVendorApiPath,
  getVendorSlugApiPath,
  vendorsApiPath,
  vendorTypesApiPath,
  vendorTypesWithVendorsApiPath,
} from '../paths'

import { get, post, put } from './api'

async function fetchVendors(options: any = {}): Promise<any> {
  return await get(vendorsApiPath, {
    ...clearObject({
      ...options,
      ...getPartnerParams(),
      includeRelations: true,
    }),
  })
}

// TODO add vendor type
export function useVendors(
  options: any = {},
  fetchOptions: { enabled: boolean } = { enabled: true }
) {
  return useQuery<any, Error>(
    ['vendors', ...Object.values(options)],
    () => fetchVendors(options),
    { ...fetchOptions }
  )
}

async function fetchVendorTypes(
  options: VendorTypesQueryOptions = {}
): Promise<VendorType[]> {
  const categories = await get(vendorTypesApiPath(), {
    ...clearObject(options),
  })
  return categories.map(
    (category: {
      slug: string
      type: string
      description: string
      vendors_count: string
    }) => ({
      id: category.slug,
      name: category.type,
      description: category.description,
      vendors_count: category.vendors_count,
    })
  )
}

async function fetchVendorTypesWithVendors(
  options: VendorTypesQueryOptions = {}
): Promise<VendorType[]> {
  const categories = await get(vendorTypesWithVendorsApiPath(), {
    ...clearObject(options),
  })
  return categories.map(
    (category: {
      slug: string
      type: string
      description: string
      vendors_count: string
      vendors: any
    }) => ({
      id: category.slug,
      name: category.type,
      description: category.description,
      vendors_count: category.vendors_count,
      vendors: category.vendors,
    })
  )
}

export function useVendorTypes(
  options: VendorTypesQueryOptions = {},
  fetchOptions: { enabled: boolean } = { enabled: true }
) {
  return useQuery<VendorType[], Error>(
    ['vendors-types', ...Object.values(options)],
    () => fetchVendorTypes(options),
    { ...fetchOptions }
  )
}

export function getVendorTypesWithVendors(
  options: VendorTypesQueryOptions = {},
  fetchOptions: { enabled: boolean } = { enabled: true }
) {
  return useQuery<VendorType[], Error>(
    ['vendor-types-with-vendors', ...Object.values(options)],
    () => fetchVendorTypesWithVendors(options),
    { ...fetchOptions }
  )
}

export function useVendor(slug: string) {
  return useQuery<Vendor, Error>(['vendors', slug], () =>
    get(getVendorSlugApiPath(slug))
  )
}

export function useVendorByIdNew(id: string) {
  return useQuery<Vendor, Error>(['vendors', id], () =>
    get(getVendorApiPath(id))
  )
}

export function getVendorById(
  vendorId: string,
  cancelToken: CancelToken,
  params: any = {}
): Promise<Vendor> {
  return get(getVendorApiPath(vendorId), params, { cancelToken })
}

export async function updateVendor(
  vendorId: string,
  data: VendorUpdateRequest
): Promise<any> {
  return put(getVendorApiPath(vendorId), data)
}

export async function updateBusinessImages(
  vendorId: string,
  images: Array<{ id: number; order: number }>
): Promise<any> {
  return put(getVendorApiPath(vendorId), { images })
}

export async function addVendor(data: VendorUpdateRequest): Promise<any> {
  return post(vendorsApiPath, data)
}
