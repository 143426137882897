import { BoxProps } from '@material-ui/core'
import * as React from 'react'

import { StatusBox } from '../../../../components/ui/StatusBox'
import { UserRoles } from '../../../../constants/profile'
import { StageInfo } from '../../../../types/Inquiry'
import { useEventInquiriesStatuses } from '../../../../utils/api/events'
import { useAuthData } from '../../../../utils/providers/AuthProvider'

export const InquiryStatus: React.FC<{ status: string } & BoxProps> = ({
  status,
  ...boxProps
}) => {
  const { data: statusesData } = useEventInquiriesStatuses()
  const { authUser } = useAuthData()
  const isClient = UserRoles.isClient(authUser?.role)

  const colorsInfo = statusesData?.inquiryStatus?.stages
  const statusInfo = statusesData?.inquiryStatus?.status_info[status]
  const stage = statusInfo?.stage
  const stageInfo = stage && colorsInfo ? colorsInfo[stage] : ({} as StageInfo)

  return stageInfo?.label ? (
    <StatusBox
      {...boxProps}
      bgColor={stageInfo?.bg_color}
      textColor={stageInfo.text_color}
      borderColor={stageInfo.border_color}
    >
      {isClient ? statusInfo?.clientLabel || stageInfo.label : stageInfo.label}
    </StatusBox>
  ) : (
    <StatusBox
      {...boxProps}
      bgColor="rgba(189, 189, 189, 0.2)"
      textColor="#BDBDBD"
      borderColor="rgba(189, 189, 189, 0.3)"
    >
      Draft
    </StatusBox>
  )
}
