import { VendorInformationFormValues } from '../../pages/onboarding/Steps/VendorInformation'
import { Vendor } from '../../types/Vendor'
import { getCostLevel } from '../helpers/vendors'

export const vendorsMapper = (item: any): Vendor => ({
  name: item?.name,
  slug: item?.slug,
  description: item?.description,
  id: item.id,
  status: item.status,
  orderPrice: getCostLevel(item.order_price_min),
  exclusiveVenueId: item.exclusive_venue_id,
  exclusive: item.exclusive,
  type: item.type,
  form_images: item.form_images,
  images: item.images,
  packages: item.packages,
  labels: item.labels,
  contact: {
    name: item.contact_name,
    email: item.contact_email,
    website: item.website,
    // facebook: item.params ? item.params.facebook : '',
    instagram: item.params ? item.params.instagram : '',
    phone: item.phone,
    address: { place_id: '', description: item.location?.formatted_address },
  },
  info: {
    about: item.params?.about || item.description,
    proTip: item.params?.pro_tip,
    specialFeatures: item.params?.special_features,
    exclusiveVendors: item.params?.exclusive_vendors,
  },
  calendar: item.calendar,
  hasAdvantages: item.has_advantages || false,
  advantageDescription: item.advantages_description || '',
  hasPromotion: item.has_promotion || false,
  promotionDescription: item.promotion_description || '',
})

const getMinHeadcount = (size: string) => {
  switch (size) {
    case '<100':
      return 0
    case '100-300':
      return 100
    case '>300':
      return 301
  }
}

const getMaxHeadcount = (size: string) => {
  switch (size) {
    case '<100':
      return 101
    case '100-300':
      return 300
    case '>300':
      return 10000000
  }
}

export const createVendorMapper = (item: VendorInformationFormValues): any => ({
  phone: item.phone,
  website: item.website,
  type: item.type,
  name: item.name,
  description: item.description,
  contact_email: item.contactEmail,
  contact_name: item.contactName,
  office: item.office,
  order_price_min: item.costLevel,
  headcount_max: getMaxHeadcount(item.companySize),
  headcount_min: getMinHeadcount(item.companySize),
})
