const FullCalendarView = ({ fill = '#2F54EB' }) => {
  return (
    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.48 13.239a.156.156 0 0 0-.037.161m.037-.161.11.11m-.11-.11.11.11-.11-.11Zm-.037.161a.157.157 0 0 0 .128.104m-.128-.104.147-.05m-.147.05.147-.05m-.019.155.197.023-.178-.178-.019.155Zm0 0 .019-.155m0 0 .18.178.106.012a.342.342 0 0 0-.07-.458l-.215.267Zm0 0 .031-.253.075-.075c.04.013.078.034.11.06l-.215.267Zm.745-.259-.45.45 2.815.333m-2.365-.783 1.992.235m-1.992-.235.356-.356.353-.353-.353-.354L9.323 9.66l.341-.34m1.67 3.771L9.665 9.32m3.663 4.006.05.431m-.05-.43-.235-1.993m.235 1.992.432.051m-.382.38v.001l.342-.04-.019.156m-.323-.117a.343.343 0 0 1 .382-.38m-.382.38.342-.04.04-.34m-.059.497.019-.156m-.019.156c.1.011.186-.073.174-.174m-.155.018-1.333-2.385.63-.631.076.632m.627 2.384.155-.018m-.155.018L9.664 9.32m4.21 4.38-.332-2.816-.45.45m.782 2.366-.155.018.04-.34m-.667-2.044-.353.352-.353.354-.354-.354L9.664 9.32m3.359 1.376c.068.216.33.307.518.18l-.013-.108-.023-.197a.157.157 0 0 0-.266-.092l-.216.217Zm0 0 .075-.076-.075.076ZM2.312 1.262l-.354.354.354.353 2.367 2.368-.34.34-2.37-2.366-.353-.353-.353.353-.352.352L.676.671l1.992.235-.356.356ZM4.095 4.92Zm.486 0Zm8.158-2.609-.353-.353-.354.353-2.368 2.366-.341-.341 2.367-2.364.354-.354-.353-.354-.357-.356 1.993-.235-.235 1.99-.353-.352ZM2.312 12.735l.355.354-1.991.235.235-1.99.352.352.353.354.354-.354L4.338 9.32l.342.342-2.368 2.366-.354.354.354.353Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  )
}

export default FullCalendarView
