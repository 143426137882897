const CompleteSmall = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.382 8.273h-1.1a.746.746 0 0 0-.606.312l-3.684 5.11-1.67-2.316a.75.75 0 0 0-.606-.312h-1.1c-.152 0-.24.174-.152.298l2.92 4.05a.745.745 0 0 0 1.212 0l4.936-6.844a.187.187 0 0 0-.15-.298Z"
        fill="#262626"
      />
      <path
        d="M12 1.5C6.202 1.5 1.5 6.202 1.5 12S6.202 22.5 12 22.5 22.5 17.798 22.5 12 17.798 1.5 12 1.5Zm0 19.219A8.72 8.72 0 0 1 3.281 12 8.72 8.72 0 0 1 12 3.281 8.72 8.72 0 0 1 20.719 12 8.72 8.72 0 0 1 12 20.719Z"
        fill="#262626"
      />
    </svg>
  )
}

export default CompleteSmall
