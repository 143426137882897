import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps as MuiAccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
  styled,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useState } from 'react'

const Accordion = styled((props: MuiAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: 'none',
  // remove the default border
  '&:before': {
    display: 'none',
  },
}))

const Summary = styled((props: MuiAccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fill: 'black' }} />}
    {...props}
  />
))({
  minHeight: 0,
  borderBottom: 'none !important',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
})

const Details = styled(MuiAccordionDetails)({
  padding: 0,
})

export const UnstyledAccordion = Object.assign(Accordion, {
  Summary,
  Details,
})

export const useCollapsibleTree = () => {
  const [expanded, setExpanded] = useState<Record<number, string | false>>({})

  const handleChange =
    (level: number, panel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((current) => ({
        ...current,
        [level]: isExpanded ? panel : false,
      }))
    }

  return { expanded, onChange: handleChange }
}
