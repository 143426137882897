import { Typography, useTheme } from '@material-ui/core'
import * as React from 'react'

import Download from '../../../../../components/svg/clientDashboard/Download'
import { Loader } from '../../../../../components/ui/Loader'
import { StatusBox } from '../../../../../components/ui/StatusBox'
import { useDownloadContract } from '../../../../../hooks/useDownloadContract'
import { InquiryContractResponse } from '../../../../../types/dtos/inquiry'
import { getShortDateRange } from '../../../../../utils/helpers/date'
import {
  StyledFirstRow,
  StyledFirstRowCell,
  StyledIconButton,
} from '../InquiryTables.styles'

interface ContractsTableBodyInfoRowProps {
  withSecondRow: boolean
  number: number
  contract: InquiryContractResponse
}

export const ContractInfoRow: React.FC<ContractsTableBodyInfoRowProps> = ({
  withSecondRow,
  number,
  contract,
}) => {
  const theme = useTheme()
  const { isLoading, handleDownloadContract } = useDownloadContract()

  return (
    <>
      <StyledFirstRow
        className={`${number === 1 ? 'wide-bordered-top' : ''} ${
          !withSecondRow ? 'wide-bordered-bottom' : ''
        }`}
      >
        <StyledFirstRowCell>
          <Typography variant="body2">{number}.</Typography>
        </StyledFirstRowCell>
        <StyledFirstRowCell>
          <Typography variant="body2">
            {getShortDateRange(contract.created_at)}
          </Typography>
        </StyledFirstRowCell>
        <StyledFirstRowCell align="center">
          <Typography variant="body2">{contract.version}</Typography>
        </StyledFirstRowCell>
        <StyledFirstRowCell align="right">
          <StatusBox
            borderColor={contract.status_info.border_color}
            textColor={contract.status_info.text_color}
            bgColor={contract.status_info.bg_color}
          >
            {contract.status_info.label}
          </StatusBox>
        </StyledFirstRowCell>
        <StyledFirstRowCell align="right">
          <StyledIconButton onClick={() => handleDownloadContract(contract.id)}>
            <Download fill={theme.palette.primary.light} />
          </StyledIconButton>
        </StyledFirstRowCell>
      </StyledFirstRow>
      {isLoading && <Loader position="fixed" />}
    </>
  )
}
