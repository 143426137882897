import { Box, InputAdornment, Typography } from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import * as React from 'react'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath, useRouteMatch } from 'react-router-dom'

import { Image } from '../../../../components/ui/Image'
import { Loader } from '../../../../components/ui/Loader'
import {
  PopoverFieldRefProps,
  PopperField,
} from '../../../../components/ui/PopoverField'
import { UserRoles } from '../../../../constants/profile'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { ProfileVendor, ProfileVenue } from '../../../../types/Profile'
import { formatAuthUser } from '../../../../utils/helpers/auth'
import {
  dashboardBookingsPath,
  dashboardListingPath,
  dashboardPath,
} from '../../../../utils/paths'
import { useAuthData } from '../../../../utils/providers/AuthProvider'

import { ListingsInput, Pointer, PopoverWrapper } from './ListingPicker.styles'
import { ListingRow } from './ListingRow'

export const ListingPicker: React.FC<{}> = () => {
  const {
    path,
    params: { listingId },
  } = useRouteMatch<{ listingId: string }>()

  const { authUser, updateUser } = useAuthData()
  const isVendor = UserRoles.isVendor(authUser?.role)

  const popoverRef = useRef<PopoverFieldRefProps>(null)
  const history = useHistory()
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  const allListings: (ProfileVenue | ProfileVendor)[] =
    (isVendor ? authUser?.vendors : authUser?.venues) || []
  const { listing_id } = authUser || { listing_id: null }

  useEffect(() => {
    if (!(+listingId === listing_id) && authUser)
      updateUser(formatAuthUser(authUser, listingId))
  }, [listingId, listing_id])

  const activeBusiness = useMemo(() => {
    if (!allListings?.length) return
    return (
      allListings?.find((listing) => listing.id === listing_id) ||
      allListings?.[0]
    )
  }, [allListings, listing_id])

  const activeBusinessImage = useMemo(() => {
    if (!activeBusiness) return

    return activeBusiness?.images?.[0]
  }, [activeBusiness, isVendor])

  const pushToListing = (id: number) => {
    popoverRef.current?.onClose()
    history.push(
      path === dashboardPath ||
        (path
          .replace(`${listingId}`, ':listingId')
          .indexOf(dashboardBookingsPath) !== -1 &&
          path.replace(`${listingId}`, ':listingId') !== dashboardBookingsPath)
        ? generatePath(dashboardListingPath, { listingId: id })
        : generatePath(path, { listingId: id })
    )
  }

  const listingsInput = (
    <ListingsInput
      startAdornment={
        <InputAdornment position={'start'}>
          <Image
            image={activeBusinessImage}
            imageSize="small"
            staticImageType={isVendor ? 'vendor' : 'venue'}
            vendorCategory={
              isVendor && activeBusiness && 'type' in activeBusiness
                ? activeBusiness?.type
                : undefined
            }
            style={{
              borderRadius: '50%',
              width: isMobile ? 24 : 32,
              height: isMobile ? 24 : 32,
            }}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position={'end'}>
          <ExpandMoreOutlined sx={{ color: 'secondary.dark' }} />
        </InputAdornment>
      }
      placeholder={activeBusiness?.name}
      readOnly
    />
  )

  const popover = (
    <Box>
      <Pointer />
      <PopoverWrapper>
        <Typography
          align="center"
          variant="h4"
          fontWeight={600}
          fontFamily="Poppins"
          mb="22px"
        >
          {t('business.listings.picker.title', 'Your Business profiles')}
        </Typography>
        {allListings.map((listing) => (
          <ListingRow
            isActive={listing.id === activeBusiness?.id}
            isVendor={isVendor}
            name={listing.name}
            partners={
              isVendor && 'partners' in listing
                ? listing.partners
                : 'partner' in listing
                ? [listing.partner || 'blace']
                : []
            }
            image={listing.images?.[0]}
            imageSize="small"
            staticImageType={isVendor ? 'vendor' : 'venue'}
            vendorCategory={
              isVendor && 'type' in listing ? listing.type : undefined
            }
            key={listing.id}
            handleClick={() => pushToListing(listing.id)}
          />
        ))}
      </PopoverWrapper>
    </Box>
  )

  if (!activeBusiness?.id) return <Loader position="fixed" />
  return (
    <PopperField
      scrollable={false}
      ref={popoverRef}
      input={listingsInput}
      content={popover}
    />
  )
}
