import { Box, TableCell, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import ChatBubbleGray from '../../../../../components/svg/clientDashboard/ChatBubbleGray'
import ChatBubbleUnread from '../../../../../components/svg/clientDashboard/ChatBubbleUnread'
import RemoveIcon from '../../../../../components/svg/RemoveIcon'
import { InquiryStatuses } from '../../../../../constants/inquiry'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { EventServiceProps, EventVendor } from '../../../../../types/Event'
import { useStorageEventsData } from '../../../../../utils/providers/EventsProvider'
import { useMessageModalData } from '../../../../../utils/providers/MessageModalProvider'
import { InquiryActionButton } from '../../../../dashboard/components/InquiryActionButton'
import { InquiryStatus } from '../../../../dashboard/components/InquiryStatus'

import { BootstrapTooltip, NotificationButton } from './EventServices.styles'
import { ServiceItemName } from './ServiceItemName'

export const ServiceItem: React.FC<{
  isEventDraft: boolean
  data: EventServiceProps
  onSubmitInquiry: (e: React.MouseEvent) => Promise<void>
  isVendorsLocked?: boolean
  unreadCount?: number
  openExclusiveInfo: () => void
  onToggleMessenger: () => void
  toggleLockedVendorModal: (e: React.MouseEvent) => void
  onRemove?: (removedVendor?: EventVendor) => void
}> = ({
  data,
  isEventDraft,
  isVendorsLocked,
  unreadCount,
  openExclusiveInfo,
  toggleLockedVendorModal,
  onRemove,
  onSubmitInquiry,
  onToggleMessenger,
}) => {
  const { showMessage, closeMessageDialog } = useMessageModalData()
  const isNotInProductionStage = !data.isVenue && isVendorsLocked
  const { getUserActiveEvent } = useStorageEventsData()
  const userEvent = getUserActiveEvent()

  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const vendorIsRemovable =
    !data.isVenue &&
    (!data.status ||
      data.status === InquiryStatuses.STATUS_DRAFT ||
      data.status === InquiryStatuses.STATUS_PENDING_SUBMIT ||
      data.status === InquiryStatuses.STATUS_INQUIRING) &&
    onRemove

  const onServiceRemove = async (e: React.MouseEvent) => {
    e.stopPropagation()
    const vendorsList = userEvent?.vendors || []
    const vendor = vendorsList.find(
      (item) => item.inquiryId?.toString() === data.inquiry_id?.toString()
    )

    showMessage({
      type: 'warning',
      style: { minHeight: 'auto' },
      title: t(
        'vendor.delete.title',
        'Are you sure you want to delete this vendor?'
      ),
      buttons: [
        {
          label: t('vendor.delete.no', 'No, back to details'),
          variant: 'outlined',
          action: () => {
            closeMessageDialog()
          },
        },
        {
          label: t('vendor.delete.yes', 'Yes'),
          action: () => {
            if (onRemove) {
              onRemove(vendor)
            }
            closeMessageDialog()
          },
        },
      ],
    })
  }

  const onActionButtonClick = (e: React.MouseEvent) => {
    switch (data.status) {
      case InquiryStatuses.STATUS_PENDING_SUBMIT:
        onSubmitInquiry(e)
        break
    }
  }

  return (
    <>
      <TableCell style={{ opacity: '100%', width: isMobile ? 'auto' : 250 }}>
        <ServiceItemName
          data={data}
          isVendorsLocked={isVendorsLocked}
          toggleLockedVendorModal={toggleLockedVendorModal}
          openExclusiveInfo={openExclusiveInfo}
        />
      </TableCell>
      {!isMobile && (
        <>
          <TableCell style={{ width: 180 }}>
            {data.address_package && (
              <Typography fontWeight={500} variant="body2">
                {data.address_package}
              </Typography>
            )}
          </TableCell>
          <TableCell style={{ minWidth: 70 }}>
            <Typography fontWeight={600} variant="body2">
              {data.displayPricing
                ? data.price
                : data.pricingDetails
                ? ''
                : t(
                    'business.pricing.landlord.notDisplay',
                    'Inquire for Pricing'
                  )}
            </Typography>
          </TableCell>
          {!isEventDraft && (
            <TableCell style={{ width: 130 }}>
              {!isNotInProductionStage && (
                <BootstrapTooltip title="New message" arrow placement="top">
                  {/*@ts-ignore*/}
                  <NotificationButton
                    onClick={(e) => {
                      e.stopPropagation()
                      if (!isNotInProductionStage) {
                        onToggleMessenger()
                      }
                    }}
                  >
                    {unreadCount ? <ChatBubbleUnread /> : <ChatBubbleGray />}
                    <Typography variant="caption">
                      {t('common.message', 'Message')}
                    </Typography>
                  </NotificationButton>
                </BootstrapTooltip>
              )}
            </TableCell>
          )}
        </>
      )}
      {data.status !== InquiryStatuses.STATUS_PENDING_SUBMIT && (
        <TableCell
          sx={{ paddingLeft: isMobile ? 0 : '16px' }}
          colSpan={!isMobile && !data.isVenue && !vendorIsRemovable ? 2 : 1}
        >
          <InquiryStatus status={data.status} />
        </TableCell>
      )}
      {vendorIsRemovable && (
        <TableCell
          sx={{ paddingLeft: isMobile ? 0 : '16px' }}
          colSpan={
            isMobile && data.status === InquiryStatuses.STATUS_PENDING_SUBMIT
              ? 2
              : 1
          }
        >
          <Box
            display="flex"
            style={{ cursor: 'pointer' }}
            onClick={onServiceRemove}
          >
            <RemoveIcon fill="#E04E2F" />
          </Box>
        </TableCell>
      )}
      {!isMobile &&
        (data.status === InquiryStatuses.STATUS_PROPOSED ||
          data.status === InquiryStatuses.STATUS_PENDING_SUBMIT) && (
          <TableCell>
            <InquiryActionButton
              onClick={onActionButtonClick}
              disabled={isNotInProductionStage}
              sx={{ '&:hover': { opacity: 0.95 } }}
              status={data.status}
            />
          </TableCell>
        )}
    </>
  )
}
