import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import LinkedInTag from 'react-linkedin-insight'
import Heap from 'utils/vendor/heap'

import config from '../utils/config'

import { consentCookie } from './helpers/cookie'

const gtmPush = (event: string, options?: object) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...options,
    },
  })
}

const track = (event: string, options?: object) => {
  if (!consentCookie.isTrackingEnabled()) {
    return
  }
  if (config.tracking.gtm.id) {
    gtmPush(event, { ...options })
  }
  if (config.tracking.ga.id) {
    ReactGA.event({ category: 'User', action: event })
  }
  if (config.tracking.facebook.id) {
    ReactPixel.trackCustom(event, { ...options })
  }
}

const tracking = {
  init: () => {
    if (!consentCookie.isTrackingEnabled()) {
      return
    }

    if (config.tracking.ga.id) {
      ReactGA.initialize(config.tracking.ga.id)
    }

    if (config.tracking.gtm.id) {
      const tagManagerArgs = {
        gtmId: config.tracking.gtm.id,
        events: {
          init: 'init',
          preview: config.tracking.gtm.env,
          auth: config.tracking.gtm.auth,
        },
      }
      TagManager.initialize(tagManagerArgs)
    }

    if (config.tracking.heap.id) {
      Heap.initialize(config.tracking.heap.id)
    }

    if (config.tracking.facebook.id) {
      const options = {
        autoConfig: true,
        debug: false,
      }
      ReactPixel.init(config.tracking.facebook.id, undefined, options)
    }

    if (config.tracking.linkedin.id) {
      LinkedInTag.init(config.tracking.linkedin.id)
    }

    tracking.pageView()
  },
  pageView: () => {
    if (!consentCookie.isTrackingEnabled()) {
      return
    }

    const path = window.location.pathname + window.location.search
    if (config.tracking.gtm.id) {
      gtmPush('pageView', { path })
    }
    if (config.tracking.ga.id) {
      ReactGA.send({ hitType: 'pageview', page: path })
    }
    if (config.tracking.facebook.id) {
      ReactPixel.pageView()
    }
  },
  homeSearch: (data?: object) => track('homeSearch', data),
  pickedVenue: (venue?: object) => track('pickedVenue', venue),
  createdEvent: (event?: object) => track('createdEvent', event),
  submittedInquiry: (inquiry?: object) =>
    track('submittedInquiryVenue', inquiry),
  pickedVendor: (eventVendor?: object) => track('pickedVendor', eventVendor),
  filledQuestionary: (questionary?: object) =>
    track('filledQuestionarity', questionary),
  paidInvoice: (invoice?: object) => track('paidInvoice', invoice),
}

export default tracking
