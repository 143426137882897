const SquareArea = ({ color = '#9396A3' }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6875 0.75H1.75C1.19687 0.75 0.75 1.19687 0.75 1.75V8.75C0.75 9.025 0.975 9.25 1.25 9.25H2.75C3.025 9.25 3.25 9.025 3.25 8.75V3.25H8.6875C8.9625 3.25 9.1875 3.025 9.1875 2.75V1.25C9.1875 0.975 8.9625 0.75 8.6875 0.75ZM26.75 18.75H25.25C24.975 18.75 24.75 18.975 24.75 19.25V24.75H19.3125C19.0375 24.75 18.8125 24.975 18.8125 25.25V26.75C18.8125 27.025 19.0375 27.25 19.3125 27.25H26.25C26.8031 27.25 27.25 26.8031 27.25 26.25V19.25C27.25 18.975 27.025 18.75 26.75 18.75ZM8.6875 24.75H3.25V19.25C3.25 18.975 3.025 18.75 2.75 18.75H1.25C0.975 18.75 0.75 18.975 0.75 19.25V26.25C0.75 26.8031 1.19687 27.25 1.75 27.25H8.6875C8.9625 27.25 9.1875 27.025 9.1875 26.75V25.25C9.1875 24.975 8.9625 24.75 8.6875 24.75ZM26.25 0.75H19.3125C19.0375 0.75 18.8125 0.975 18.8125 1.25V2.75C18.8125 3.025 19.0375 3.25 19.3125 3.25H24.75V8.75C24.75 9.025 24.975 9.25 25.25 9.25H26.75C27.025 9.25 27.25 9.025 27.25 8.75V1.75C27.25 1.19687 26.8031 0.75 26.25 0.75Z"
        fill={color}
      />
    </svg>
  )
}

export default SquareArea
