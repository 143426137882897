const PromotionStar = ({ size = 24, scale = 1 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} rx={12} fill="#003366" />
      <path
        transform={`scale(${scale})`}
        d="M20 10.1241H13.8933L12 4L10.1067 10.1241H4L8.93333 13.9034L7.06667 20L12 16.2207L16.9333 20L15.04 13.8759L20 10.1241Z"
        fill="#FFE870"
      />
    </svg>
  )
}

export default PromotionStar
