import { Button, Paper, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const EventInfoToggleButton = styled(Button)<{ isOpen: boolean }>(
  ({ isOpen }) => `
  display: flex;
  justify-content: start;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  color: black;
  text-transform: none;
  background-color: transparent;
  min-width: 100px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  padding: 24px 20px;
  border-radius: 2px;
  box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
  align-content: baseline;
     &:hover, &:focus, &:active {
    background-color: transparent;
  }
  button {
    height: fit-content;
  }
`
)

export const StyledPaper = styled(Paper)`
  background: white;
  border-radius: 0;
  width: 400px;

  ${MEDIA_RULES.SMALL} {
    min-width: unset;
    width: 100%;
  }
`
