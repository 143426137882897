import { Box, Typography } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import React from 'react'

import OpenWindow from '../../../../components/svg/OpenWindow'
import GradientButton from '../../../../components/ui/Buttons/GradientButton'
import { StepNameContainer } from '../DocumentForms.styles'

export const StepsColumn: React.FC<{
  withTemplates?: boolean
  onApplyTemplate?: () => void
  templateBtnCopy?: string
  title: string
  steps: any[]
  activeStep: number
}> = ({
  withTemplates,
  onApplyTemplate,
  templateBtnCopy,
  title,
  steps,
  activeStep,
}) => {
  return (
    <Box
      minWidth="230px"
      minHeight="595px"
      display="flex"
      p="24px"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ background: (theme) => theme.palette.background.paper }}
    >
      <Box>
        <Box display="flex" mb="26px">
          <Typography
            color="#0F123F"
            fontFamily="Poppins"
            fontWeight={600}
            variant="h4"
          >
            {title}
          </Typography>
        </Box>
        {steps.map((step, index) =>
          step.hidden ? null : (
            <StepNameContainer
              key={index}
              display="flex"
              alignItems="center"
              mb={6}
              className={`${index === activeStep ? 'active-step' : ''}`}
            >
              <Box mr={2} className="step-number">
                <Typography
                  lineHeight={index < activeStep ? '14px' : undefined}
                  fontWeight={600}
                  color="white"
                  variant="body2"
                >
                  {index < activeStep ? <Check fontSize="small" /> : index + 1}
                </Typography>
              </Box>
              <Typography
                className="step-name"
                fontWeight={600}
                color={(theme) => theme.palette.primary.light}
              >
                {step.name}
              </Typography>
            </StepNameContainer>
          )
        )}
      </Box>
      {withTemplates && onApplyTemplate && templateBtnCopy && (
        <GradientButton
          style={{
            height: 'auto',
            minHeight: '37px',
            justifyContent: 'flex-start',
            padding: '6.5px 19.5px',
            background:
              'linear-gradient(0deg, rgba(47, 84, 235, 0.07), rgba(47, 84, 235, 0.07)), #FFFFFF',
          }}
          onClick={onApplyTemplate}
        >
          <Typography
            mr="10.5px"
            variant="body2"
            component="span"
            color={(theme) => theme.palette.primary.light}
          >
            {templateBtnCopy}
          </Typography>
          <OpenWindow />
        </GradientButton>
      )}
    </Box>
  )
}
