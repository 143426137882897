import { Box, styled, Typography } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import React from 'react'

import { DocumentsUploaderField } from '../../../../../components/ui/DocumentsUploaderField'
import { Image } from '../../../../../components/ui/Image'
import { Loader } from '../../../../../components/ui/Loader'
import { ImageFormat } from '../../../../../types/FileUploader'
import { AssetRefDownload } from '../../../../../types/FileUploadHandler'
import { ImageItem } from '../../../../../types/general'
import { uploadImage } from '../../../../../utils/api/images'
import { getRequestError } from '../../../../../utils/helpers/validations'
import { useMediaModalData } from '../../../../../utils/providers/MediaLibraryProvider'
import { useMessageModalData } from '../../../../../utils/providers/MessageModalProvider'

const LibraryContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 25px;
`

const AddButton = styled(Box)`
  background: linear-gradient(180deg, #4e4bec 0%, #3f91f0 100%);
  color: white;
  font-size: 16px;
  font-weight: 600;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GridItemContainer = styled(Box)`
  background: rgba(132, 132, 132, 0.12);
  border: 1.5px solid transparent;
  display: flex;
  margin-right: 25px;
  margin-left: 25px;
  justify-content: center;
  align-items: center;
  height: 90px;
  cursor: pointer;
  position: relative;
`

export const MediaLibraryTab: React.FC<{
  images: Array<ImageItem>
  pickedPhotos: Array<AssetRefDownload>
  onPickImage: (picked: ImageItem) => void
}> = ({ images, pickedPhotos, onPickImage }) => {
  const inputFileRef = React.useRef(null)
  const { addToMediaLibrary } = useMediaModalData()
  const { showMessage } = useMessageModalData()
  const [uploading, setUploading] = React.useState(false)

  const onAddClick = () => {
    if (inputFileRef && inputFileRef.current) {
      // @ts-ignore
      inputFileRef.current.click()
    }
  }

  const uploadImageToLibrary = async (event: React.ChangeEvent) => {
    try {
      const input = event.target as HTMLInputElement
      if (input?.files?.[0]) {
        setUploading(true)
        const uploadedFile = await uploadImage(input?.files?.[0])
        addToMediaLibrary(uploadedFile)
      }
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    }
    setUploading(false)
  }

  const renderImageItem = (image: ImageItem) => {
    const selected = pickedPhotos.find(
      (picked) => picked.metadata.imageId === image.id
    )

    return (
      <Box
        key={image.id}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={1} height={1}>
          <GridItemContainer
            style={{ border: selected ? '1.5px solid #2F54EB' : '' }}
            onClick={() => onPickImage(image)}
          >
            {selected && (
              <Box
                style={{ position: 'absolute', top: 0, right: 0 }}
                width={24}
                height={24}
                bgcolor="#2F54EB"
              >
                <Check style={{ color: 'white' }} />
              </Box>
            )}
            {!!image?.path && (
              <Image image={image} imageSize="small" style={{ height: 90 }} />
            )}
          </GridItemContainer>
          <Box
            display="flex"
            mt={1}
            justifyContent="center"
            style={{
              cursor: 'pointer',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography
              fontWeight={500}
              textAlign="center"
              fontSize={12}
              style={{
                maxWidth: 150,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {image.filename}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={3} mt={3}>
        <Typography variant="body2" color="#9396A3">
          All Photos
        </Typography>
      </Box>
      <LibraryContainer>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box width={1} height={1}>
            <DocumentsUploaderField
              accept={[ImageFormat.JPG, ImageFormat.JPEG, ImageFormat.PNG]}
              onChange={uploadImageToLibrary}
              inputFileRef={inputFileRef}
            >
              <GridItemContainer onClick={onAddClick}>
                <AddButton>+</AddButton>
              </GridItemContainer>
              <Box
                display="flex"
                mt={1}
                justifyContent="center"
                style={{ cursor: 'pointer' }}
                onClick={onAddClick}
              >
                <Typography fontWeight={500} fontSize={12} color="#2F54EB">
                  Upload more files
                </Typography>
              </Box>
            </DocumentsUploaderField>
          </Box>
        </Box>
        {images.map(renderImageItem)}
      </LibraryContainer>
      {uploading && <Loader position="absolute" />}
    </Box>
  )
}
