import * as React from 'react'

export function useLatestCallback(callback: (...args: any[]) => any) {
  const noop = (..._args: any[]) => {}
  const savedCallback = React.useRef(noop)

  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  function callCallback(...args: any[]) {
    savedCallback.current && savedCallback.current(...args)
  }

  return callCallback
}
