import { Box } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import React from 'react'
import { useHistory } from 'react-router'

import { loginOptions, mobileOptions } from '../../constants'
import { usePartner } from '../../hooks/usePartner'
import { useWindowSize } from '../../hooks/useWindowSize'
import {
  AccountMenuActions,
  AuthMenuActions,
  MobileMenuAccountActions,
} from '../../types/Auth'
import {
  eventsListPath,
  marketplacePath,
  onboardingRolePath,
  vendorsPath,
} from '../../utils/paths'
import BurgerMobile from '../svg/BurgerMobile'
import LinkText from '../ui/Buttons/LinkText/LinkText'
import { PopperMenu } from '../ui/PopperMenu'

const AuthMenu: React.FC<{
  setRenderSearch?: (renderSearch: boolean) => void
}> = ({ setRenderSearch }) => {
  const history = useHistory()
  const { isPartner } = usePartner()
  const { isMobile } = useWindowSize()
  const { isAbs } = usePartner();

  const handleLoginMenuSelect = (value: string) => {
    switch (value) {
      case MobileMenuAccountActions.CreateNewEvent:
      case MobileMenuAccountActions.MyEvents:
        history.push(eventsListPath)
        break
      case MobileMenuAccountActions.Venues:
        history.push(marketplacePath)
        break
      case MobileMenuAccountActions.Vendors:
        history.push(vendorsPath)
        break
      case AuthMenuActions.Signin:
      case AuthMenuActions.Signup:
        break
      default:
        history.push(onboardingRolePath)
        break
    }
  }

  const authOptions = isMobile
    ? isPartner
      ? mobileOptions.filter(
          (item) =>
            item.value !== AccountMenuActions.BecomeAPartner &&
            item.value !== MobileMenuAccountActions.Venues
        )
      : mobileOptions
    : isPartner
    ? loginOptions.filter(
        (item) => item.value !== AccountMenuActions.BecomeAPartner
      )
    : loginOptions

  if(!isAbs){
    return(
      <div />
    )
  }

  return (
    <Box sx={{ zIndex: 1001 }}>
      <PopperMenu
        withSearch={true}
        setRenderSearch={setRenderSearch}
        darkMode
        withPointer={false}
        placement="bottom-end"
        options={authOptions}
        onSelectAction={handleLoginMenuSelect}
      >
        {!isMobile ? (
          <LinkText fontSize={16} minWidth="70px">
            <Box display="flex" alignItems="center" sx={{ color: "#FFF" }}>
              Log in
              <Box display="flex" ml={1}>
                <Menu />
              </Box>
            </Box>
          </LinkText>
        ) : (
          <BurgerMobile />
        )}
      </PopperMenu>
    </Box>
  )
}

export default AuthMenu
