import { Box } from '@material-ui/core'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'

import Food from '../../../../components/svg/Food'
import Home from '../../../../components/svg/Home'
import GradientButton from '../../../../components/ui/Buttons/GradientButton'
import { SelectField } from '../../../../components/ui/FormComponents/SelectField'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { VendorType } from '../../../../types/Vendor'
import { VenueItem } from '../../../../types/Venue'
import { useVendorTypes } from '../../../../utils/api/vendors'
import { useVenues } from '../../../../utils/api/venues'
import { vendorsCategoryPath } from '../../../../utils/paths'
import { useStorageEventsData } from '../../../../utils/providers/EventsProvider'
import { useMessageModalData } from '../../../../utils/providers/MessageModalProvider'
import { AbsFormContainer } from '../EventForm/EventForm.styles'

interface SearchProps {
  onChangeVenue: (venueId: number) => void
  mainAbsSliderHeight: string
  activeVenueIndex: number
}

export const AbsSearchForm: React.FC<SearchProps> = ({
  activeVenueIndex,
  onChangeVenue,
  mainAbsSliderHeight,
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { showMessage } = useMessageModalData()
  const { isMobile } = useWindowSize()
  const { data: categories } = useVendorTypes()
  const { getUserActiveEvent } = useStorageEventsData()
  const activeEvent = getUserActiveEvent()
  const { data } = useVenues({})
  const venues = data?.data || []

  const [categoriesForSelectField, setCategoriesForSelectField] =
    React.useState<any[]>([])
  const [venuesForSelectField, setVenuesForSelectField] = React.useState<any[]>(
    []
  )
  const [vendorType, setVendorType] = useState<string>('')
  const [currentVenue, setCurrentVenue] = useState<string>('')

  const activeEventVenue = useMemo(() => {
    if (!activeEvent?.venue) return
    return activeEvent?.venue
  }, [activeEvent])

  React.useEffect(() => {
    if (categories?.length) {
      setCategoriesForSelectField(
        categories
          ?.filter((category) => category.vendors_count)
          .map((item: VendorType) => ({
            ...item,
            label: item.name,
            value: item.id,
          }))
      )
    }
  }, [categories?.length])

  React.useEffect(() => {
    if (!venues?.length) return
    setVenuesForSelectField(
      venues?.map((item: VenueItem) => ({
        label: item.name,
        value: item.id.toString(),
      }))
    )
  }, [venues?.length])

  React.useEffect(() => {
    if (!venuesForSelectField?.length) return
    setCurrentVenue(venuesForSelectField[activeVenueIndex]?.value)
  }, [activeVenueIndex, venuesForSelectField])

  React.useEffect(() => {
    if (!activeEventVenue?.id) return
    handleVenueChange(activeEventVenue?.id?.toString())
  }, [venues, activeEventVenue?.id])

  const handleVendorTypeChange = (value: any) => {
    setVendorType(value)
  }

  const handleVenueChange = (value: string) => {
    onChangeVenue(
      venues.findIndex((venue: VenueItem) => venue.id === Number(value))
    )
    setCurrentVenue(value)
  }

  const onSubmit = function (e: { preventDefault: () => void }) {
    e.preventDefault()

    if (!vendorType)
      showMessage({
        type: 'warning',
        title: t(
          'partners.abs.vendors.search.title',
          'Please choose vendor type to continue'
        ),
      })
    else {
      history.push(generatePath(vendorsCategoryPath, { category: vendorType }))
    }
  }

  return (
    <AbsFormContainer onSubmit={onSubmit}>
      <Box
        display="flex"
        width={1}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <Box
          sx={{
            marginRight: isMobile ? 0 : '38px',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            width: isMobile ? '100%' : 'fit-content',
            padding: isMobile ? '20px 25px' : 0,
          }}
        >
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            width="100%"
            pr={isMobile ? 0 : '27px'}
            pb={isMobile ? '20px' : 0}
            sx={{
              borderRight: isMobile ? 'none' : '1px solid #d7d7d7',
              borderBottom: isMobile ? '1px solid #d7d7d7' : 'none',
            }}
          >
            <Box pr="17px" sx={{ position: 'absolute', left: 25 }}>
              <Home />
            </Box>
            <SelectField
              sx={{ width: isMobile ? '100%' : '269px' }}
              dropdownHorizontalPosition="left"
              selectPaddingLeft={isMobile ? '37px' : '64px'}
              dropdownHeight={isMobile ? '60%' : '40%'}
              selectHeight={
                isMobile ? 'unset' : `calc(${mainAbsSliderHeight} * 0.09)`
              }
              maxSelectHeight="70px"
              selectPaddingVertical={
                isMobile
                  ? '4px'
                  : ` min(35px, calc((${mainAbsSliderHeight} * 0.09) / 2))`
              }
              dropdownWidth={isMobile ? '100%' : '296px'}
              optionsWithoutPlaceholder
              withRadio
              variant="standard"
              placeholder={t('home.venues', 'Venues')}
              options={venuesForSelectField}
              value={currentVenue}
              onChange={handleVenueChange}
            />
          </Box>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            pt={isMobile ? '20px' : 0}
            width="100%"
          >
            {isMobile && (
              <Box pr="16px">
                <Food />
              </Box>
            )}
            <SelectField
              sx={{ width: isMobile ? '100%' : '206px' }}
              dropdownHorizontalPosition="left"
              selectPaddingLeft={isMobile ? '0px' : '32px'}
              dropdownHeight={isMobile ? '60%' : '40%'}
              selectHeight={
                isMobile ? 'unset' : `calc(${mainAbsSliderHeight} * 0.09)`
              }
              maxSelectHeight="70px"
              selectPaddingVertical={
                isMobile
                  ? '4px'
                  : `min(35px, calc((${mainAbsSliderHeight} * 0.09) / 2))`
              }
              dropdownWidth={isMobile ? '100%' : '244px'}
              optionsWithoutPlaceholder
              withRadio
              variant="standard"
              placeholder={t('home.vendorType', 'Vendor Type')}
              options={categoriesForSelectField}
              value={vendorType}
              onChange={handleVendorTypeChange}
            />
          </Box>
        </Box>
        <GradientButton
          type="submit"
          size="large"
          fullWidth={true}
          style={{
            width: isMobile ? '100%' : '208px',
            fontSize: '18px',
            height: isMobile ? '55px' : `calc(${mainAbsSliderHeight} * 0.09)`,
            maxHeight: '70px',
          }}
        >
          {t('home.event.search', 'Search')}
        </GradientButton>
      </Box>
    </AbsFormContainer>
  )
}
