import Button from '@material-ui/core/Button'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

interface ShowVenuesInThisAreaButtonProps {
  onRefreshVenues: (param: any) => void
}

export const ShowVenuesInThisAreaButton: React.FC<
  ShowVenuesInThisAreaButtonProps
> = ({ onRefreshVenues }) => {
  const { t } = useTranslation()

  // The show more venues button stopped working when TN "fixed" it in this story: https://trello.com/c/UlkhAIsA/3216-map-click-on-show-venues-makes-browser-unresponsive
  // by disabling location area parameters in commits fa9abdcecc4d5f45e5e635f85009eadd6e110f22 and ebfc7280f078728daec3676fa8a38b493bac2e71
  // and so we'd like to disable it temporarily until we fix it

  return null

  return (
    <Button
      style={{
        color: 'black',
        borderColor: '#9396A3',
        borderRadius: 2,
        background: 'white',
        textTransform: 'none',
        fontFamily: 'Poppins',
        lineHeight: '28px',
      }}
      variant="outlined"
      onClick={onRefreshVenues}
    >
      {t('map.searchThisArea', 'Show venues in this area')}
    </Button>
  )
}
