/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Box,
  Checkbox,
  CheckboxProps,
  DialogProps,
  FormControlLabel,
  Stack,
  Theme,
  Typography,
} from '@material-ui/core'
import { UnstyledAccordion, useCollapsibleTree } from 'components/ui/Accordion'
import { SimpleButton } from 'components/ui/Buttons/SimpleButton'
import { Dialog } from 'components/ui/Modals/Dialog'
import { ReactNode } from 'react'
import { wFull } from 'utils/styles'

import { DropdownTitle, SelectableItem } from '../Dropdown'

const styles = {
  body: css`
    --component-padding: 24px;
    overflow: auto;
    flex: 1;
    padding: var(--component-padding);
  `,
  list: css`
    margin: 0;
    padding: 0;
  `,
  footer: (theme: Theme) => css`
    flex: 0 0 auto;
    margin-top: auto;

    padding: 16px 24px;

    border-top: 1px solid;
    border-top-color: ${theme.palette.divider};

    display: flex;
    justify-content: space-between;
  `,
  clearButton: (theme: Theme) => css`
    display: block;

    padding: 0;
    min-width: 0;

    color: ${theme.palette.text.primary};
    text-decoration: underline;
  `,
}

export type Item = {
  slug: string
  name: string
  children?: Item[]
  inputProps: CheckboxProps
}

export const NeighborhoodSelect = ({ items }: { items: Item[] }) => {
  const { expanded, onChange } = useCollapsibleTree()

  const renderItem = (item: Item, level = 1) => {
    const itemStyles = css`
      /* allow the ripple effect to overflow */
      margin: 0 calc(var(--component-padding) * -1);
      padding-right: var(--component-padding);
      padding-left: calc(var(--component-padding) * ${level});
    `

    if (!item.children) {
      return (
        <SelectableItem key={item.slug} css={itemStyles}>
          <FormControlLabel
            css={wFull}
            control={<Checkbox disableRipple {...item.inputProps} />}
            label={item.name}
          />
        </SelectableItem>
      )
    }

    return (
      <UnstyledAccordion
        key={item.slug}
        expanded={expanded[level] === item.slug}
        onChange={onChange(level, item.slug)}
      >
        <SelectableItem css={itemStyles}>
          <UnstyledAccordion.Summary css={wFull}>
            <FormControlLabel
              css={wFull}
              control={
                <Checkbox
                  disableRipple
                  {...item.inputProps}
                  // prevent toggling accordion when clicking on checkbox
                  onClick={(e) => e.stopPropagation()}
                />
              }
              // prevent toggling checkbox when clicking on label (toggles the accordion instead)
              onClick={(e) => e.preventDefault()}
              label={item.name}
            />
          </UnstyledAccordion.Summary>
        </SelectableItem>

        <UnstyledAccordion.Details as="ul">
          {item.children.map((child) => renderItem(child, level + 1))}
        </UnstyledAccordion.Details>
      </UnstyledAccordion>
    )
  }

  return (
    <Stack spacing="8px" flex="1">
      <DropdownTitle>Neighborhoods</DropdownTitle>
      <Stack component="ul" css={styles.list}>
        {items.map((item) => renderItem(item))}
      </Stack>
    </Stack>
  )
}

type NeighborhoodDialogProps = {
  children: ReactNode
  onApply: () => void
  onClear: () => void
  onClose: () => void
} & DialogProps

export const NeighborhoodDialog = ({
  children,
  onApply,
  onClear,
  onClose,
  ...props
}: NeighborhoodDialogProps) => {
  return (
    <Dialog
      fullScreen
      onClose={onClose}
      {...props}
      data-testid="venue-location-dialog"
      aria-labelledby="venue-location-dialog-title"
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Dialog.Header onClose={onClose}>
          <Typography
            variant="title"
            align="center"
            id="venue-location-dialog-title"
          >
            Location
          </Typography>
        </Dialog.Header>

        <Stack direction="column" spacing="24px" css={styles.body}>
          {children}
        </Stack>

        <Box css={styles.footer}>
          <Box flex="1">
            <SimpleButton
              size="small"
              variant="text"
              css={styles.clearButton}
              onClick={onClear}
            >
              Clear all
            </SimpleButton>
          </Box>
          <Box flex="1">
            <SimpleButton
              size="small"
              variant="contained"
              css={wFull}
              onClick={onApply}
            >
              Show Results
            </SimpleButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
