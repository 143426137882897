import * as React from 'react'
import { useState } from 'react'

import { BusinessDetailsInfo } from '../../../../../types/general'
import { BlockContainer } from '../../BusinessDetails.styles'

import { DetailsForm } from './components/DetailsForm'
import { DetailsPreview } from './components/DetailsPreview'

export const Details: React.FC<{
  onSave: (data: BusinessDetailsInfo) => void
  entity?: any
  attachLabelMethod: any
}> = ({ onSave, entity, attachLabelMethod }) => {
  const [isEditMode, setEditMode] = useState(false)
  const onEdit = () => {
    if (entity) {
      setEditMode(true)
    }
  }

  return (
    <BlockContainer display="flex" flexDirection="column" flex={1}>
      {isEditMode ? (
        <DetailsForm
          onSave={onSave}
          entity={entity}
          attachLabelMethod={attachLabelMethod}
          onCancel={() => setEditMode(false)}
        />
      ) : (
        <DetailsPreview entity={entity} onEdit={onEdit} />
      )}
    </BlockContainer>
  )
}
