import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'

import { VenueUpdateRequest } from '../types/dtos/venues'
import { Status } from '../types/general'
import { VenueDetails } from '../types/Venue'
import { getVenueById, updateVenueById } from '../utils/api/venues'
import { venuesMapper } from '../utils/mappers/venues'

// TODO: rework to useQuery + useMutation
export interface VenueByIdHookData {
  isLoading: boolean
  venue: VenueDetails | null
  updateVenue: (venue: VenueUpdateRequest, onSuccess?: () => void) => void
  setVenue: (venue: VenueDetails) => void
}

export function useVenueById(
  venueId: string,
  params: Record<string, any> = {}
): VenueByIdHookData {
  const [isInit, setIsInit] = useState(false)
  const [status, setStatus] = useState<Status>(Status.REQUEST)
  const [venue, setVenue] = useState<VenueDetails | null>(null)
  const source = useMemo(() => axios.CancelToken.source(), [])
  const isLoading = status === Status.REQUEST || !isInit

  useEffect(() => {
    if (venueId) {
      setStatus(Status.REQUEST)
      getVenueById(venueId, params, source.token)
        .then((fetchedVenue) => {
          setVenue(venuesMapper(fetchedVenue))
          setStatus(Status.SUCCESS)
        })
        .catch((e) => {
          console.log(e)
          if (!axios.isCancel(e)) {
            setStatus(Status.ERROR)
          }
        })
        .finally(() => {
          setIsInit(true)
        })
    }
  }, [venueId])

  const updateVenue = async (
    data: VenueUpdateRequest,
    onSuccess: () => void = () => {}
  ) => {
    setStatus(Status.REQUEST)

    try {
      const updatedVenue = await updateVenueById(venueId, data)
      const venueLocationAddress = data.location_address?.formatted_address
        ? { formatted_address: data.location_address.formatted_address }
        : updatedVenue.location_address
      const venueData = {
        ...updatedVenue,
        location_address: venueLocationAddress,
      }

      setVenue(venuesMapper(venueData))
      setStatus(Status.SUCCESS)
      onSuccess()
    } catch (e) {
      setStatus(Status.ERROR)
    }
  }

  return { isLoading, venue, updateVenue, setVenue }
}
