import { Box } from '@material-ui/core'
import * as React from 'react'

import CeilingHeight from '../../../../components/svg/dashboard/CeilingHeight'
import PeopleOutlined from '../../../../components/svg/PeopleOutlined'
import Floor from '../../../../components/svg/venues/Floor'
import SquareArea from '../../../../components/svg/venues/SquareArea'
import { InfoBox } from '../../../../components/ui/InfoBox'
import { VenueDetails } from '../../../../types/Venue'
import { AboutWrapper } from '../Venue.styles'

export const VenueAbout: React.FC<{ venue?: VenueDetails | null }> = ({
  venue,
}) => {
  return (
    <AboutWrapper id="about" mt="16px">
      {(venue?.aboutInfo.sqFootage ||
        venue?.aboutInfo.numberOfFloors ||
        venue?.aboutInfo.maxGuests) && (
        <Box display="flex" flexWrap="wrap">
          {!!venue?.aboutInfo.numberOfFloors && (
            <Box
              display="flex"
              flex="0 0 auto"
              mt="16px"
              maxWidth="100%"
              flexDirection="column"
            >
              <Box display="flex">
                <InfoBox
                  mr="27px"
                  color="#9396A3"
                  icon={<Floor />}
                  text={venue?.aboutInfo.numberOfFloors}
                />
              </Box>
            </Box>
          )}
          {!!venue?.aboutInfo.sqFootage && (
            <Box
              display="flex"
              flex="0 0 auto"
              mt="16px"
              maxWidth="100%"
              flexDirection="column"
            >
              <Box display="flex">
                <InfoBox
                  mr="27px"
                  color="#9396A3"
                  icon={<SquareArea />}
                  text={`${venue?.aboutInfo.sqFootage} SqFt`}
                />
              </Box>
            </Box>
          )}
          {!!venue?.aboutInfo.maxGuests && (
            <Box
              display="flex"
              flex="0 0 auto"
              mt="16px"
              maxWidth="100%"
              flexDirection="column"
            >
              <Box display="flex">
                <InfoBox
                  mr="27px"
                  color="#9396A3"
                  icon={<PeopleOutlined width={30} height={28} scale={1.4} />}
                  text={`${venue?.aboutInfo.maxGuests} guest max`}
                />
              </Box>
            </Box>
          )}
          {!!venue?.params.ceiling_height && (
            <Box
              display="flex"
              flex="0 0 auto"
              mt="16px"
              maxWidth="100%"
              flexDirection="column"
            >
              <Box display="flex">
                <InfoBox
                  mr="27px"
                  color="#9396A3"
                  icon={<CeilingHeight width={30} height={28} scale={1.4} />}
                  text={`${venue?.params.ceiling_height} Ft`}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </AboutWrapper>
  )
}
