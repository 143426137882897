import { Box } from '@material-ui/core'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { Breadcrumbs, BreadcrumbsProps } from '../../components/ui/Breadcrumbs'
import { SimpleButton } from '../../components/ui/Buttons/SimpleButton'
import { MultiSelectField } from '../../components/ui/FormComponents/MultiSelectField'
import { Loader } from '../../components/ui/Loader'
import { DashboardTitle } from '../../components/ui/PageTitle'
import { BOOKING_INITIAL_SEARCH_PARAMS } from '../../constants/bookings'
import { CALENDAR_VIEW_BY, getCalendarUrl } from '../../constants/events'
import { InquiryStatuses } from '../../constants/inquiry'
import { UserRoles } from '../../constants/profile'
import { useBookings } from '../../hooks/useBookings'
import { useCurrentBusinessListingId } from '../../hooks/useCurrentBusinessListingId'
import { usePartner } from '../../hooks/usePartner'
import { useUrlParams } from '../../hooks/useUrlParams'
import { useVendorById } from '../../hooks/useVendorById'
import { useVenueById } from '../../hooks/useVenueById'
import { InquiryEvent } from '../../types/Inquiry'
import { goToBlaceDashboard } from '../../utils/helpers/goToBlaceDashboard'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { CalendarPage } from '../calendar'

import { CopyUrlComponent } from './BusinessDetails/components/CopyUrlComponent'
import { DashboardCalendar } from './components/DashboardCalendar'
import { InquiryEventTable } from './components/InquiryTable'
import { Summary } from './components/Summary'

export const Dashboard: React.FC<{ role?: UserRoles }> = ({ role }) => {
  const { magicLinkLogin } = useAuthData()
  const { urlParams } = useUrlParams()
  const history = useHistory()
  const { businessId } = useCurrentBusinessListingId()
  const { isPartner, partnerSlug } = usePartner()
  const {
    bookings: eventsList,
    isListLoading: loading,
    fetchBookings,
  } = useBookings(businessId?.toString() || '', BOOKING_INITIAL_SEARCH_PARAMS)
  const { t } = useTranslation()

  const business = UserRoles.isLandlord(role)
    ? useVenueById(businessId?.toString() || '')
    : useVendorById(businessId?.toString() || '')

  const calendarId = UserRoles.isLandlord(role)
    ? // @ts-ignore
      business?.venue?.calendar?.google_id
    : // @ts-ignore
      business?.vendor?.calendar?.google_id
  const inquiryEvents = useMemo(
    () => getOnlyNewInquiries(eventsList),
    [eventsList]
  )
  const [fullCalendarGoogleId, setFullCalendarGoogleId] =
    React.useState<string>('')
  const [viewBy, setViewBy] = React.useState(CALENDAR_VIEW_BY[0])
  const calendarUrl = getCalendarUrl(calendarId)
  const breadcrumbs: BreadcrumbsProps = {
    steps: [
      {
        label: 'Dashboard',
        route: '',
        onClick: () => setFullCalendarGoogleId(''),
      },
    ],
    current: 'Full calendar view',
  }

  React.useEffect(() => {
    if (urlParams.hash) {
      magicLinkLogin(urlParams.hash)
    }
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('hash')) {
      queryParams.delete('hash')
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, [])

  return isPartner ? (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={10}
      margin="auto"
      textAlign="center"
      minHeight="80vh"
    >
      <Box mt={5} display="flex">
        <SimpleButton
          variant="outlined"
          onClick={() => goToBlaceDashboard(partnerSlug)}
        >
          {t('partners.abs.goToBlace', 'Go to Blace Dashboard')}
        </SimpleButton>
      </Box>
    </Box>
  ) : (
    <Box
      width={1}
      style={{ paddingLeft: 30, paddingRight: 30, position: 'relative' }}
    >
      <DashboardTitle title={'Dashboard'} />
      {fullCalendarGoogleId ? (
        <>
          <Box display="flex" justifyContent="space-between">
            <Breadcrumbs columnStyle {...breadcrumbs} />
            <Box display="flex" alignItems="center">
              <MultiSelectField
                id="viewBy"
                sx={{ minWidth: 120 }}
                defaultLabel={`View by: ${viewBy}`}
                options={CALENDAR_VIEW_BY}
                onChange={(value: any) => setViewBy(value)}
              />
              <CopyUrlComponent
                id="calendar-link"
                calendarUrl={calendarUrl}
                onCopyCalendarLink={() => {
                  navigator.clipboard.writeText(calendarUrl)
                }}
              />
            </Box>
          </Box>
          <CalendarPage
            withBackButton={false}
            viewBy={viewBy}
            calendarGoogleId={fullCalendarGoogleId}
            onCloseFullCalendar={() => setFullCalendarGoogleId('')}
          />
        </>
      ) : (
        <>
          <Box display="flex" mb={4} width={1}>
            <InquiryEventTable
              onChangeBookingStatus={() => fetchBookings()}
              count={eventsList?.length}
              data={inquiryEvents}
            />
            <Summary inquiryEvents={eventsList} />
          </Box>
          {calendarId ? (
            <DashboardCalendar
              calendarGoogleId={calendarId}
              onOpenFullCalendar={(id: string) => setFullCalendarGoogleId(id)}
            />
          ) : null}
        </>
      )}
      {loading && <Loader position="absolute" />}
    </Box>
  )
}

const getOnlyNewInquiries = (events?: InquiryEvent[]) =>
  events?.filter((event) => {
    const inquiry = event.inquiry

    if (!inquiry) return false

    return (
      inquiry.status === InquiryStatuses.STATUS_INQUIRING ||
      inquiry.status === InquiryStatuses.STATUS_DRAFT
    )
  }) || []
