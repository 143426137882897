import { Box, Typography } from '@material-ui/core'
import Map from 'components/svg/Map'
import { Loader } from 'components/ui/Loader'
import { TooltipComponent } from 'components/ui/Tooltip'
import { locationFiltersSchema, useLocationState } from 'hooks/useLocationState'
import { usePreferredCity } from 'hooks/usePreferredCity'
import {
  requireParams,
  requireValidParams,
  useSearchParams,
} from 'hooks/useSearchParams'
import {
  compose,
  defaultTo,
  join,
  keys,
  map,
  pick,
  pipe,
  prop,
  reverse,
  slice,
} from 'lodash/fp'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  City,
  District,
  LocationsQuery,
  Neighborhood,
  usePrefetchedLocations,
} from 'utils/api/locations'
import { waitForQueries } from 'utils/helpers/query'
import { withHooks } from 'utils/helpers/render'
import { Param } from 'utils/helpers/searchParams'
import { issueKeys, mapError } from 'utils/helpers/zod'
import { mobileMapPath } from 'utils/paths'

import Filter from '../../../components/svg/venues/Filter'
import {
  INITIAL_SEARCH_PARAMS,
  MARKETPLACE_SORTING_FIELDS,
} from '../../../constants'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useUrlParams } from '../../../hooks/useUrlParams'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { useVenueCategories, useVenues } from '../../../utils/api/venues'
import { mapQueryParams } from '../../../utils/helpers/common'
import { when } from '../../../utils/helpers/function'
import { SortOrder } from '../../../utils/helpers/sort'
import { venueListQueryParamsMapper } from '../../../utils/mappers/venueListQueryParamsMapper'
import { LikesDataProvider } from '../../../utils/providers/LikesProvider'
import { VenueListController as VenueList } from '../../venues/Venues/VenueList.controller'
import { MapSearch } from '../components/MapSearch/MapSearch'
import { SortingSelect } from '../components/SortingSelect'
import { VenuesFilter } from '../components/VenuesFilter'
import { StyledIconButton } from '../components/VenuesFilter/VenuesFilter.styles'

import {
  MapContainer,
  StyledTypography,
  VenuesContainer,
} from './Marketplace.styles'

const locationTitle = (selectedValues: {
  city: City
  districts: District[]
  neighborhoods: Neighborhood[]
}) => {
  const { neighborhoods } = selectedValues
  return pipe(
    reverse,
    slice(0, 3),
    map(prop('name')),
    join(', '),
    when(
      () => neighborhoods.length > 3,
      (title: string) => title + '...'
    )
  )(neighborhoods)
}
const venueSearchParams = pick(keys(INITIAL_SEARCH_PARAMS))

export const Marketplace: React.FC = compose(
  waitForQueries([LocationsQuery], Loader),
  withHooks({
    preferredCity: usePreferredCity,
    locations: usePrefetchedLocations,
  }),
  requireValidParams(({ locations }) =>
    pipe(
      locationFiltersSchema(locations).partial().safeParse,
      mapError(issueKeys),
      defaultTo([])
    )
  ),
  requireParams(
    ['city', 'page', 'per_page', 'orderBy'],
    ({ preferredCity }) => ({
      city: preferredCity.citySlug,
      ...pick(['page', 'per_page', 'orderBy'], INITIAL_SEARCH_PARAMS),
    })
  )
)(function Marketplace() {
  const history = useHistory()
  const params = useSearchParams()
  const { urlParams } = useUrlParams()
  const { t } = useTranslation()
  const { isMobile, isLessThenMaxContainerWidth, windowSize } = useWindowSize()
  const { width: windowWidth } = windowSize
  const { toggleModal, isOpen } = useSimpleModal()

  const { data } = useVenues(venueSearchParams(urlParams))
  const { data: categories } = useVenueCategories()
  const locations = usePrefetchedLocations()
  const locationState = useLocationState(locations)
  const locationName = locationState
    ? locationTitle(locationState.selected)
    : ''
  const [isMapVisible, setMapVisibility] = useState<boolean>(
    urlParams.map === 'true'
  )
  const [isExpandedMap, setExpandedMap] = useState<boolean>(
    urlParams.mapExpanded === 'true'
  )
  const [orderDirection, setOrderDirection] = useState<SortOrder>('asc')

  const updateQueryParams = (newParams: Record<string, Param>) => {
    params.setParams(newParams)
  }

  const doExpandMap = function (status: boolean) {
    setExpandedMap(status)
    params.setParam('mapExpanded', status)
  }

  const onSort = (field: string) => {
    const orderBy = mapQueryParams(field)
    params.setParam('orderBy', orderBy)
  }

  const onChangeDirection = (direction: SortOrder) => {
    setOrderDirection(direction)
    params.setParam('orderDirection', direction)
  }

  const toggleMapView = () => {
    const newIsMapVisible = !isMapVisible
    setMapVisibility(newIsMapVisible)
    if (isMobile) {
      const queryParamsStr = venueListQueryParamsMapper(urlParams)
      history.push({
        pathname: mobileMapPath,
        search: new URLSearchParams(queryParamsStr).toString(),
      })
    } else {
      params.setParams({
        ...urlParams,
        map: newIsMapVisible,
        perPage: newIsMapVisible ? 100 : 16,
      })
    }
  }

  return (
    <LikesDataProvider>
      <Box justifyContent="center" alignItems="center">
        {isMobile ? (
          <VenuesContainer className="container">
            <Box
              display="flex"
              component="div"
              alignItems="flex-start"
              flexDirection="column"
              justifyContent="flex-start"
              alignContent="center"
            >
              <Box
                width={1}
                alignContent="center"
                display="flex"
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
              >
                <StyledTypography
                  sx={{ textTransform: 'capitalize' }}
                  variant="h1"
                >
                  {`${locationName} ${t('venues.title', 'Venues')}`}
                </StyledTypography>
                <Box display="flex" gap="8px">
                  <TooltipComponent title="Map view" placement="top">
                    <StyledIconButton
                      onClick={toggleMapView}
                      sx={{
                        margin: 0,
                        cursor: 'pointer',
                        '&:hover': { opacity: 0.8 },
                      }}
                    >
                      <Map width={40} height={40} stroke="#000" />
                    </StyledIconButton>
                  </TooltipComponent>
                  <TooltipComponent title="Filtes" placement="top">
                    <StyledIconButton
                      onClick={toggleModal}
                      sx={{
                        margin: 0,
                        cursor: 'pointer',
                        '&:hover': { opacity: 0.8 },
                      }}
                    >
                      <Filter />
                    </StyledIconButton>
                  </TooltipComponent>
                </Box>
              </Box>
              <VenuesFilter
                isOpen={isOpen}
                toggleModal={toggleModal}
                categories={categories}
                initialValues={urlParams}
                updateQueryParams={updateQueryParams}
                isMapVisible={Boolean(isMapVisible)}
                toggleMapView={toggleMapView}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignContent="bottom"
              alignItems="center"
            >
              <Typography variant="caption">
                {data?.total || 0} results
              </Typography>
              <Box>
                <SortingSelect
                  sortingFields={MARKETPLACE_SORTING_FIELDS}
                  orderBy={urlParams.orderBy}
                  orderDirection={orderDirection}
                  onChangeDirection={onChangeDirection}
                  onSort={onSort}
                />
              </Box>
            </Box>
            <VenueList direction="grid" />
          </VenuesContainer>
        ) : (
          <>
            <VenuesFilter
              isOpen={isOpen}
              toggleModal={toggleModal}
              categories={categories}
              initialValues={urlParams}
              updateQueryParams={updateQueryParams}
              isMapVisible={Boolean(isMapVisible)}
              toggleMapView={toggleMapView}
            />
            {isMapVisible ? (
              <Box width={1} height={1}>
                <Box
                  display="flex"
                  flexDirection={'row'}
                  height={1}
                  sx={{
                    marginLeft: !isLessThenMaxContainerWidth
                      ? `${(windowWidth - 1224) / 2}px`
                      : isLessThenMaxContainerWidth && !isMobile
                      ? '50px'
                      : windowWidth > 600
                      ? `${(windowWidth - 550) / 2}px`
                      : '24px',
                  }}
                >
                  {!isExpandedMap && (
                    <VenuesContainer
                      pt={5}
                      style={{ overflowY: 'scroll', maxWidth: '50%' }}
                      order={0}
                      maxHeight={'80vh'}
                      display="flex"
                      flexDirection="column"
                    >
                      <VenueList direction="vertical" />
                    </VenuesContainer>
                  )}
                  <MapContainer
                    display="flex"
                    flex={1}
                    width="100%"
                    height="80vh"
                  >
                    <MapSearch
                      venues={data?.data || []}
                      initialValues={urlParams}
                      updateQueryParams={updateQueryParams}
                      isExpandedMap={isExpandedMap}
                      doExpandMap={doExpandMap}
                    />
                  </MapContainer>
                </Box>
              </Box>
            ) : (
              <VenuesContainer className="container">
                <Box
                  display="flex"
                  component="div"
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignContent="center"
                >
                  <Box
                    width={1}
                    alignContent="center"
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                  >
                    <StyledTypography
                      sx={{ textTransform: 'capitalize' }}
                      variant="h1"
                    >
                      {`${locationName} ${t('venues.title', 'Venues')}`}
                    </StyledTypography>
                  </Box>
                  <Box mt={5}>
                    <SortingSelect
                      sortingFields={MARKETPLACE_SORTING_FIELDS}
                      orderBy={urlParams.orderBy}
                      orderDirection={orderDirection}
                      onChangeDirection={onChangeDirection}
                      onSort={onSort}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignContent="bottom"
                  alignItems="center"
                >
                  <Typography variant="caption">
                    {data?.total || 0} results
                  </Typography>
                </Box>
                <VenueList direction="grid" />
              </VenuesContainer>
            )}
          </>
        )}
      </Box>
    </LikesDataProvider>
  )
})
