import { Box, styled, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import GradientButton from '../../../../../components/ui/Buttons/GradientButton/GradientButton'
import { MultiImageUploaderField } from '../../../../../components/ui/MultiImageUploader'
import { FullLineTabs } from '../../../../../components/ui/Tabs/FullLineTabs'
import { AssetLoadingStatus, AssetType } from '../../../../../constants'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { ImageFormat } from '../../../../../types/FileUploader'
import {
  AssetRefDownload,
  getEmptyImageAsset,
  UploadAction,
  UploadResponse,
} from '../../../../../types/FileUploadHandler'
import { ImageItem } from '../../../../../types/general'
import { uploadImage } from '../../../../../utils/api/images'
import { getRequestError } from '../../../../../utils/helpers/validations'
import {
  MediaModalConfig,
  useMediaModalData,
} from '../../../../../utils/providers/MediaLibraryProvider'
import { useMessageModalData } from '../../../../../utils/providers/MessageModalProvider'

import { MediaLibraryTab } from './LibraryTab'

export const MEDIA_MODAL_TABS = [
  { value: 'upload', label: 'Upload new' },
  { value: 'library', label: 'Library' },
]

const MediaContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
`
const MediaFooterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  z-index: 2;
  box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
`

export const MediaSelectModal: React.FC<{
  images: Array<ImageItem>
  config: MediaModalConfig
}> = ({ images, config }) => {
  const history = useHistory()
  const { initialImages, onSave, isMulti = true } = config
  const [photos, setPhotos] = React.useState<AssetRefDownload[]>(
    initialImages || []
  )
  const { addToMediaLibrary, closeMediaModal } = useMediaModalData()
  const { showMessage } = useMessageModalData()
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()

  const initialTab = history.location.hash
    ? history.location.hash.replace('#', '')
    : MEDIA_MODAL_TABS[0].value

  const [tab, setTab] = React.useState(initialTab)

  const onTabSelect = (pickedTab: string) => {
    if (tab !== pickedTab) {
      setTab(pickedTab)
    }
  }

  const onPickImage = (image: ImageItem) => {
    const photo = getEmptyImageAsset(image)

    if (!isMulti) {
      setPhotos([photo])
      return
    }

    const isPicked = photos.find((photo) => photo.metadata.imageId === image.id)

    if (isPicked) {
      setPhotos(photos.filter((photo) => photo.metadata.imageId !== image.id))
    } else {
      setPhotos([...photos, photo])
    }
  }

  const uploadImageToLibrary = async (
    action: UploadAction
  ): Promise<UploadResponse> => {
    const { asset, progress } = action

    try {
      if (progress) {
        progress({ status: AssetLoadingStatus.STARTED, progress: 10 })
      }

      if (!asset.metadata?.fileBlob) {
        return { status: AssetLoadingStatus.ERRORED, data: asset }
      }

      const uploadedFile = await uploadImage(asset.metadata?.fileBlob)
      addToMediaLibrary(uploadedFile)

      if (progress) {
        progress({ status: AssetLoadingStatus.SUCCEEDED, progress: 100 })
      }

      return {
        status: AssetLoadingStatus.SUCCEEDED,
        data: {
          ...asset,
          metadata: { ...asset.metadata, imageId: uploadedFile.id },
        },
      }
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
      return { status: AssetLoadingStatus.ERRORED, data: asset }
    }
  }

  const onUploadError = (rejection: FileRejection) => {
    if (rejection.errors.some((error) => error.code === 'file-too-large')) {
      showMessage({
        title: t(
          'dashboard.validation.someOfImagesTooLarge',
          'Some images is too large for uploading. Image size must be not greater than 10MB'
        ),
        type: 'error',
      })
    } else {
      showMessage({
        title: rejection.errors?.[0]?.message,
        type: 'error',
      })
    }
  }

  const handleSave = () => {
    if (onSave) {
      onSave(photos)
    }
    closeMediaModal()
  }

  return (
    <Box
      height={isMobile ? '100%' : 600}
      width={isMobile ? '100%' : 625}
      display="flex"
      flexDirection="column"
    >
      <Box width={1}>
        <Typography fontWeight={600}>Photo Gallery</Typography>
        <FullLineTabs
          tabProps={{ style: { padding: '5px 16px' } }}
          tabs={MEDIA_MODAL_TABS}
          onChange={onTabSelect}
        />
      </Box>
      <MediaContentContainer>
        {tab === MEDIA_MODAL_TABS[0].value && (
          <>
            <Box mb={3} mt={3}>
              <Typography variant="body2" color="#9396A3">
                Current {isMulti ? 'Photos' : 'Photo'}
              </Typography>
            </Box>
            <MultiImageUploaderField
              onError={onUploadError}
              isMulti={isMulti}
              uploadFile={(action) => uploadImageToLibrary(action)}
              width={0}
              height={160}
              images={photos || []}
              accept={[ImageFormat.JPG, ImageFormat.JPEG, ImageFormat.PNG]}
              assetType={AssetType.DASHBOARD_IMG}
              onChange={setPhotos}
              name="photo"
            />
          </>
        )}
        {tab === MEDIA_MODAL_TABS[1].value && (
          <MediaLibraryTab
            pickedPhotos={photos}
            onPickImage={onPickImage}
            images={images}
          />
        )}
      </MediaContentContainer>
      <MediaFooterContainer>
        <Box
          onClick={closeMediaModal}
          display="flex"
          alignItems="center"
          style={{ cursor: 'pointer', padding: '15px 40px' }}
        >
          <Close style={{ color: '#9396A3' }} />
          <Typography color="#9396A3" variant="body1" align="center">
            Cancel
          </Typography>
        </Box>
        <Box>
          <GradientButton onClick={handleSave}>Save changes</GradientButton>
        </Box>
      </MediaFooterContainer>
    </Box>
  )
}
