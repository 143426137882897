import { Box, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const StyledPromotionsVendorsBlock = styled(Box)(
  ({ theme }) => `
  .swiper-container {
    .swiper-pagination-bullets {
      width: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      ${MEDIA_RULES.SMALL} {
        left: 45px;
        bottom: 3px;
      }
    }

    .swiper-pagination-bullet {
      background: ${theme.palette.grey[200]};
      width: 8px;
      height: 8px;
      
      &-active {
        background: ${theme.palette.primary.main};
      }
    }
  }
`
)
