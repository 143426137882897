import { Box, Typography } from '@material-ui/core'
import { startOfDay } from 'date-fns'
import { sum, prop, map, flow, groupBy, mapValues, values } from 'lodash/fp'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { UserRoles } from '../../constants/profile'
import { useWindowSize } from '../../hooks/useWindowSize'
import { TotalChip } from '../../pages/events/ServiceBookingDetails/components/Tabs/PaymentInvoices/TotalChip'
import { Invoice } from '../../types/dtos/inquiry'
import {
  InvoicePaymentDueState,
  invoicePaymentDueState,
} from '../../utils/helpers/invoices'
import { formatToCurrency } from '../../utils/helpers/price'
import { useAuthData } from '../../utils/providers/AuthProvider'

import { TotalBlock, TotalsWrapper } from './InvoicesTotals.styles'
import { TotalDot } from './TotalDot'

export const InvoicesTotals: React.FC<{ invoices?: Invoice[] }> = ({
  invoices = [],
} = {}) => {
  const { t } = useTranslation()
  const { authUser } = useAuthData()
  const isClient = UserRoles.isClient(authUser?.role)
  const { isMobile } = useWindowSize()

  const totals = useMemo(() => {
    const today = startOfDay(new Date())

    return flow(
      groupBy(invoicePaymentDueState(today)),
      mapValues(flow(map(prop('price')), sum)),
      (totals) =>
        ({
          ...totals,
          all: flow(values, sum)(totals),
        } as Record<'all' | InvoicePaymentDueState, number>)
    )(invoices)
  }, [invoices])

  if (isClient)
    return isMobile ? (
      <Box
        display="grid"
        style={{ gridTemplateColumns: '1fr 1fr' }}
        alignItems="center"
        justifyContent="space-between"
        width={1}
        mb={6}
      >
        <TotalDot
          total={totals.all}
          bgColor="#2F54EB"
          title={t('business.invoices.totalAmount', 'Total amount')}
        />
        <TotalDot
          total={totals.paid}
          bgColor="#27AE60"
          title={t('business.invoices.totalPaid', 'Total paid')}
        />
        <TotalDot
          total={totals.pending}
          bgColor="#F3BD00"
          title={t('business.invoices.totalPending', 'Total pending')}
        />
        <TotalDot
          total={totals['pass-due']}
          bgColor="#E04E2F"
          title={t('business.invoices.totalPastDue', 'Total past due')}
        />
      </Box>
    ) : (
      <Box
        display={isMobile ? 'grid' : 'flex'}
        style={{ gridTemplateColumns: '1fr 1fr' }}
        alignItems="center"
        justifyContent="space-between"
        width={1}
        mb={6}
      >
        <TotalChip
          total={totals.all}
          color="#2F54EB"
          bgColor="#fff"
          title={t('business.invoices.totalAmount', 'Total amount')}
        />
        <TotalChip
          total={totals.paid}
          color="rgba(39, 174, 96, 0.15)"
          bgColor="rgba(39, 174, 96, 0.1)"
          title={t('business.invoices.totalPaid', 'Total paid')}
        />
        <TotalChip
          total={totals.pending}
          color="rgba(243, 189, 0, 0.15)"
          bgColor="rgba(243, 189, 0, 0.1)"
          title={t('business.invoices.totalPending', 'Total pending')}
        />
        <TotalChip
          total={totals['pass-due']}
          color="rgba(224, 78, 47, 0.15)"
          bgColor="rgba(224, 78, 47, 0.1)"
          title={t('business.invoices.totalPastDue', 'Total past due')}
        />
      </Box>
    )

  return (
    <TotalsWrapper>
      <TotalBlock
        style={{
          background: '#2F54EB',
          borderColor: 'rgba(39, 174, 96, 0.15)',
        }}
      >
        <Box mb={1} display="flex" alignItems="center">
          <Typography whiteSpace="nowrap" variant="body2" color="#fff">
            {t('business.invoices.totalAmount', 'Total amount')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" fontWeight={600} color="#fff">
            {formatToCurrency(totals.all)}
          </Typography>
        </Box>
      </TotalBlock>
      <TotalBlock
        style={{
          background: 'rgba(39, 174, 96, 0.1)',
          borderColor: 'rgba(39, 174, 96, 0.15)',
        }}
      >
        <Box mb={1} display="flex" alignItems="center">
          <Typography whiteSpace="nowrap" variant="body2" color="#9396A3">
            {t('business.invoices.totalPaid', 'Total paid')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="#9396A3" fontWeight={600}>
            {formatToCurrency(totals.paid)}
          </Typography>
        </Box>
      </TotalBlock>
      <TotalBlock
        style={{
          background: 'rgba(243, 189, 0, 0.1)',
          borderColor: 'rgba(243, 189, 0, 0.15)',
        }}
      >
        <Box mb={1} display="flex" alignItems="center">
          <Typography whiteSpace="nowrap" variant="body2" color="#9396A3">
            {t('business.invoices.totalPending', 'Total pending')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="#9396A3" fontWeight={600}>
            {formatToCurrency(totals.pending)}
          </Typography>
        </Box>
      </TotalBlock>
      <TotalBlock
        style={{
          background: 'rgba(224, 78, 47, 0.1)',
          borderColor: 'rgba(224, 78, 47, 0.15)',
        }}
      >
        <Box mb={1} display="flex" alignItems="center">
          <Typography whiteSpace="nowrap" variant="body2" color="#9396A3">
            {t('business.invoices.totalPastDue', 'Total past due')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="#9396A3" fontWeight={600}>
            {formatToCurrency(totals['pass-due'])}
          </Typography>
        </Box>
      </TotalBlock>
    </TotalsWrapper>
  )
}
