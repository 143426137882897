import { Box, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const SectionHeader = styled(Typography)`
  font-weight: 600;
  text-align: center;
`

export const FormContainer = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 448px;
  max-height: min-content;
`

export const ScreenContent = styled(Box)`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;

  ${MEDIA_RULES.SMALL} {
    overflow: auto;
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 24px;
    top: 80px;
    bottom: 100px;
  }
`
export const ScrollContainer = styled(Box)`
  ${MEDIA_RULES.SMALL} {
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`
