import { TableBody } from '@material-ui/core'
import React from 'react'

import { CollapsibleDocumentRow } from '../../../../../components/ui/CollapsibleDocumentRow'
import { useContractsByStatuses } from '../../../../../hooks/useContractsByStatus'
import { InquiryContractResponse } from '../../../../../types/dtos/inquiry'
import { StyledTable } from '../InquiryTables.styles'

import { ContractRows } from './ContractRows'
import { Header } from './Header'

export interface ContractsTableProps {
  contracts: InquiryContractResponse[]
  files?: any[]
}

export const ContractsTable: React.FC<ContractsTableProps> = ({
  contracts,
  files,
}) => {
  const { outdatedContracts, notOutdatedContract } =
    useContractsByStatuses(contracts)

  return (
    <StyledTable>
      <Header />
      <TableBody>
        {notOutdatedContract && (
          <ContractRows
            contract={notOutdatedContract}
            number={1}
            files={files}
          />
        )}
        {!!outdatedContracts?.length && (
          <CollapsibleDocumentRow colSpan={4}>
            {outdatedContracts.map((contract, i) => (
              <ContractRows
                key={contract.id}
                contract={contract}
                number={i + 1}
                files={files}
              />
            ))}
          </CollapsibleDocumentRow>
        )}
      </TableBody>
    </StyledTable>
  )
}
