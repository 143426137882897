import { Box, styled, Typography } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import GradientButton from '../../../components/ui/Buttons/GradientButton'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { WarningBox } from '../../../components/ui/WarningBox'
import { getDefaultEventName } from '../../../constants'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { LocalEvent } from '../../../types/Event'
import { getDifferenceInDays } from '../../../utils/helpers/date'
import { formatToCurrency } from '../../../utils/helpers/price'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import {
  LsGetInquiryDetails,
  LsGetInquiryFormValues,
} from '../../../utils/storage'
import PersonalInfoModal from '../../dashboard/components/modals/PersonalInfo/PersonalInfo'
import { OfferToLoginModal } from '../components/OfferToLoginModal'

import { InquiryDetailCard } from './InquiryDetailCard'
import { InquiryListingCard } from './InquiryListingCard'

const StyledGradientButton = styled(GradientButton)`
  &:hover {
    opacity: 0.95;
  }
`

export const InquiryForm: React.FC<{
  isAbs: boolean
  setLoading: (isLoading: boolean) => void
  event: LocalEvent
  submitInquiry: () => void
}> = ({ isAbs, setLoading, submitInquiry, event }) => {
  const { authUser } = useAuthData()
  const { isMobile } = useWindowSize()

  const { t } = useTranslation()

  const [isChooseLoginOpened, setIsChooseLoginOpened] = useState<boolean>(false)
  const [isPersonalInfoOpened, setIsPersonalInfoOpened] =
    useState<boolean>(false)
  const initialFormValues = LsGetInquiryFormValues()
  const inquiryDetails = LsGetInquiryDetails()

  const onSubmit = () => {
    if (!authUser) {
      setIsChooseLoginOpened(true)
      return
    }
    submitInquiry()
  }

  return (
    <>
      <Box className="container booking-top">
        <Box className="inquiry-data">
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={!isMobile ? 'center' : 'flex-start'}
            mb={6}
          >
            <Typography className="page-title" variant="h1">
              {!inquiryDetails?.id && !initialFormValues?.id
                ? isAbs
                  ? t(
                      'partners.abs.bookings.inquiry.details',
                      'Review Inquiry Details'
                    )
                  : t('inquiry.details', 'Inquiry Details')
                : inquiryDetails.name || getDefaultEventName(isAbs)}
            </Typography>
            <Box display="flex" justifyContent="center">
              <WarningBox
                type="info"
                maxWidth={741}
                ml={isMobile ? 0 : 10}
                mt="8px"
              >
                <Typography variant="body2">
                  {t(
                    'inquiry.check',
                    'Check the info below and then press the “submit inquiry” button'
                  )}
                </Typography>
              </WarningBox>
            </Box>
          </Box>
          <Box className="inquiry-params">
            <InquiryListingCard
              inquiryDetails={inquiryDetails}
              rooms={inquiryDetails?.rooms}
              listing={isAbs ? event?.vendors?.[0] : inquiryDetails?.venue}
            />
            <InquiryDetailCard />
          </Box>
        </Box>
        <SimpleModal
          maxWidth="inherit"
          open={isChooseLoginOpened}
          onClose={() => setIsChooseLoginOpened(false)}
        >
          <OfferToLoginModal
            onSignup={() => setIsPersonalInfoOpened(true)}
            onLogin={() => setIsChooseLoginOpened(false)}
            onLoginSuccess={submitInquiry}
          />
        </SimpleModal>
        <SimpleModal
          maxWidth="inherit"
          open={isPersonalInfoOpened}
          onClose={() => setIsPersonalInfoOpened(false)}
        >
          <PersonalInfoModal
            onError={() => {
              setLoading(false)
              setIsPersonalInfoOpened(false)
            }}
            onSuccess={() => {
              setLoading(false)
              setIsPersonalInfoOpened(false)
            }}
            onSubmit={() => setLoading(true)}
          />
        </SimpleModal>
      </Box>
      <Box
        className="container section-block"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {!isAbs && (
          <Typography sx={{ mr: '40px' }}>
            {inquiryDetails?.venue?.displayPricing
              ? `${t(
                  'events.starting',
                  'Event starting at'
                )} ${formatToCurrency(
                  +inquiryDetails?.venue?.pricing *
                    (getDifferenceInDays(
                      inquiryDetails.start_date,
                      inquiryDetails?.end_date
                    ) +
                      1)
                )}`
              : inquiryDetails?.venue?.pricingDetails ||
                inquiryDetails?.venue?.paymentStructure?.pricingDetails
              ? ''
              : t(
                  'business.pricing.landlord.notDisplay',
                  'Inquire for Pricing'
                )}
          </Typography>
        )}
        <StyledGradientButton onClick={onSubmit}>
          {t('inquiry.submit', 'Submit inquiry')}
        </StyledGradientButton>
      </Box>
    </>
  )
}
