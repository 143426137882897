import { cancelable } from 'cancelable-promise'
import { isNil } from 'lodash/fp'
import { ComponentType, useEffect, useState } from 'react'
import {
  QueryClient,
  QueryFunction,
  QueryKey,
  QueryStatus,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
  useQueryClient,
} from 'react-query'

import { assert } from './assert'
import { renderNothing } from './render'

export const isLoading = (queryResult: {
  status: QueryStatus
}): queryResult is { status: 'loading' } => queryResult.status === 'loading'
export const isIdle = (queryResult: {
  status: QueryStatus
}): queryResult is { status: 'idle' } => queryResult.status === 'idle'
export const isError = (queryResult: {
  status: QueryStatus
}): queryResult is { status: 'error' } => queryResult.status === 'error'
export const isSuccess = (queryResult: {
  status: QueryStatus
}): queryResult is { status: 'success' } => queryResult.status === 'success'

type PrefetchedQuery<
  TData extends any = unknown,
  TQueryKey extends QueryKey = QueryKey
> = {
  key: TQueryKey
  load: QueryFunction<TData, TQueryKey>
  prefetch: (client: QueryClient) => Promise<void>
}

export const createPrefetchedQuery = <
  TData extends any = unknown,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TData, TQueryKey>
): PrefetchedQuery<TData, TQueryKey> => {
  return {
    key: queryKey,
    load: queryFn,
    prefetch: (client: QueryClient) => {
      return client.fetchQuery(queryKey, queryFn, { cacheTime: Infinity })
    },
  }
}

export const usePrefetchedQuery = <
  TData extends any = unknown,
  TQueryKey extends QueryKey = QueryKey
>(
  query: PrefetchedQuery<TData, TQueryKey>,
  options?: UseQueryOptions<TData, unknown, TData, TQueryKey>
) => {
  const { key, load } = query
  const queryClient = useQueryClient()

  const data = queryClient.getQueryData<TData>(key)
  assert(
    !isNil(data),
    `Query data for ${key} was not prefetched. You need to call the 'prefetch' function for this query.`
  )

  const result = useQuery(key, load, { initialData: data, ...options }) as Omit<
    UseQueryResult<TData>,
    'data'
  > & { data: TData }

  return result.data
}

const prefetchQueries = (client: QueryClient, queries: PrefetchedQuery[]) => {
  return Promise.all(queries.map((query) => query.prefetch(client)))
}

export const waitForQueries =
  <TProps extends {} = {}>(
    queries: PrefetchedQuery<any, any>[],
    Fallback: ComponentType<TProps> = renderNothing
  ) =>
  (Component: ComponentType<TProps>) =>
    function WaitForQueries(props: TProps) {
      const queryClient = useQueryClient()
      const [status, setStatus] = useState<QueryStatus>('idle')

      useEffect(() => {
        const promise = cancelable(prefetchQueries(queryClient, queries)).then(
          () => {
            setStatus('success')
          }
        )
        return promise.cancel
      }, [queryClient])

      if (isSuccess({ status })) {
        return <Component {...props} />
      }
      return <Fallback {...props} />
    }
