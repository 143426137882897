import { Box } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

export const StyledAddedLabel = styled(Box)`
  padding: 2.5px 8px;
  width: 79px;
  height: 21px;
  position: absolute;
  right: 12px;
  bottom: 12px;
  background: linear-gradient(
      0deg,
      rgba(47, 84, 235, 0.05),
      rgba(47, 84, 235, 0.05)
    ),
    #ffffff;
  border: 1px solid rgba(47, 84, 235, 0.2);
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: default;
`
