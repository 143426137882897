import {
  Box,
  BoxProps,
  ClickAwayListener,
  MenuItemProps,
  Popper,
  PopperProps,
} from '@material-ui/core'
import { Modifier } from '@popperjs/core'
import { Dispatch, SetStateAction } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { isProductionServer } from '../../../utils/helpers/envr'
import Search from '../../svg/mobileMenu/Search'

import {
  Pointer,
  PointerTop,
  PopperContentWrapper,
  StyledDivider,
  StyledMenuItem,
  StyledMenuItemWithLinkAction,
  StyledNavLink,
  StyledSimpleButton,
} from './PopperMenu.styles'

export interface PopperMenuItem extends MenuItemProps {
  label: string
  value: string
  className?: string
  secondary?: boolean
  disabled?: boolean
  dev?: boolean
  isButton?: boolean
  link?: string
  linkAction?: string
  icon?: React.ReactElement
  color?: string
}

interface PopperMenuProps {
  onSelectAction: (value: string) => void
  options: PopperMenuItem[] | any
  setRenderSearch?: (renderSearch: boolean) => void
  withPointer?: boolean
  withSearch?: boolean
  darkMode?: boolean
  containerProps?: BoxProps
  renderOptions?: any
  rightPointer?: boolean
  isFullScreen?: boolean
  setIsPopperOpen?: Dispatch<SetStateAction<boolean>>
}

const standardContainerProps = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
}

export const PopperMenu: React.FC<Partial<PopperProps> & PopperMenuProps> = ({
  containerProps = standardContainerProps,
  options = [],
  className,
  onSelectAction,
  children,
  placement = 'top',
  withPointer = true,
  darkMode = false,
  renderOptions,
  rightPointer,
  isFullScreen = true,
  setRenderSearch,
  withSearch = false,
  setIsPopperOpen,
  ...props
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [menuPlacement, setMenuPlacement] = React.useState(placement)
  const { isMobile } = useWindowSize()
  const open = Boolean(anchorEl)
  const isPlacementTop = menuPlacement === 'top'

  const handlePointerPositionModifier: Partial<Modifier<any, any>> = {
    enabled: true,
    name: 'placementTracker',
    phase: 'main',
    fn: ({ state }) => {
      if (state.placement !== menuPlacement) {
        setMenuPlacement(state.placement)
      }
    },
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
    if (setIsPopperOpen) setIsPopperOpen(!anchorEl)
  }

  const handleSelect = (value: string) => {
    onSelectAction(value)
    setAnchorEl(null)
    if (setIsPopperOpen) setIsPopperOpen(false)
  }

  const renderMenuOption = (option: PopperMenuItem) => {
    const { dev, value, secondary, label, ...rest } = option
    if (option.dev && isProductionServer()) {
      return null
    }

    return (
      <Box key={option.value} style={{ zIndex: 10000 }}>
        {option.secondary && <StyledDivider />}
        {option.linkAction ? (
          <StyledMenuItemWithLinkAction
            disabled={option.disabled}
            className={className || option.secondary ? 'secondary-item' : ''}
            onClick={() => handleSelect(option.value)}
            {...rest}
          >
            <StyledNavLink
              style={{ color: '#ffffff' }}
              to={
                option.linkAction
                  ? `?action=${option.linkAction}`
                  : option.link || ''
              }
            >
              {option.icon ? <Box mr={3}>{option.icon}</Box> : null}
              <Box component="span">{option.label}</Box>
            </StyledNavLink>
          </StyledMenuItemWithLinkAction>
        ) : option.isButton ? (
          <StyledSimpleButton
            fullWidth={Boolean(isMobile)}
            onClick={() => handleSelect(option.value)}
            variant="outlined"
          >
            {option.label}
          </StyledSimpleButton>
        ) : (
          <StyledMenuItem
            style={{ color: option.color || '#ffffff' }}
            disabled={option.disabled}
            className={className || option.secondary ? 'secondary-item' : ''}
            onClick={() => handleSelect(option.value)}
            {...rest}
          >
            {option.icon ? <Box mr={3}>{option.icon}</Box> : null}
            <Box component="span">{option.label}</Box>
          </StyledMenuItem>
        )}
      </Box>
    )
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setAnchorEl(null)
        if (setIsPopperOpen) setIsPopperOpen(false)
      }}
    >
      <Box {...containerProps}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ cursor: 'pointer' }}
          onClick={handleClick}
        >
          {children}
        </Box>
        {/*@ts-ignore*/}
        <Popper
          id="popper-menu"
          style={{
            zIndex: 1000,
            width: isMobile && isFullScreen ? '100%' : 'unset',
          }}
          open={open}
          placement={placement}
          anchorEl={anchorEl}
          modifiers={[handlePointerPositionModifier]}
          {...props}
        >
          <PopperContentWrapper
            className={(darkMode && 'dark') || ''}
            isPlacementTop={isPlacementTop}
            isMobile={isMobile}
            isFullScreen={isFullScreen}
          >
            <Box className={isMobile && isFullScreen ? 'container' : ''}>
              {withPointer ? (
                isPlacementTop ? (
                  <Pointer />
                ) : (
                  <PointerTop rightPointer={true} />
                )
              ) : null}
              {!renderOptions ? options.map(renderMenuOption) : renderOptions}
            </Box>
          </PopperContentWrapper>
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}
