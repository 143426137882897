import { Box, Typography } from '@material-ui/core'
import * as React from 'react'

import LinkText from '../../../../../components/ui/Buttons/LinkText/LinkText'
import {
  BlockContainer,
  NoteBox,
  NotesContainer,
} from '../../BookingDetails.styles'

interface NotesProps {}

export const Notes: React.FC<NotesProps> = () => {
  return (
    <Box display="flex" width={1} flexDirection="column">
      <BlockContainer
        style={{ paddingLeft: 10, paddingRight: 10 }}
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          mb={2}
          ml={3}
          mr={3}
        >
          <Typography variant="h4">Notes</Typography>
          <LinkText onClick={() => null} variant="body2" text="+ Add note" />
        </Box>
        <NotesContainer>
          <NoteBox>
            <Typography variant="body2" lineHeight="24px">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
            </Typography>
          </NoteBox>
          <NoteBox>
            <Typography variant="body2" lineHeight="24px">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </Typography>
          </NoteBox>
          <NoteBox>
            <Typography variant="body2" lineHeight="24px">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
            </Typography>
          </NoteBox>
          <NoteBox>
            <Typography variant="body2" lineHeight="24px">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </Typography>
          </NoteBox>
          <NoteBox>
            <Typography variant="body2" lineHeight="24px">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
            </Typography>
          </NoteBox>
          <NoteBox>
            <Typography variant="body2" lineHeight="24px">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </Typography>
          </NoteBox>
        </NotesContainer>
      </BlockContainer>
    </Box>
  )
}
