import { Box, Typography } from '@material-ui/core'
import { Star } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import LinkText from '../../../../../components/ui/Buttons/LinkText/LinkText'
import { TooltipComponent } from '../../../../../components/ui/Tooltip'
import { IconContainer } from '../EventServices/EventServices.styles'

export const ExclusiveTooltip: React.FC<{ openExclusiveInfo: () => void }> = ({
  openExclusiveInfo,
}) => {
  const { t } = useTranslation()

  const exclusiveTooltipBox = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="body2">
        {t('vendor.exclusive', 'Exclusive vendor')}
      </Typography>
      <LinkText
        style={{ fontSize: 14 }}
        variant="body2"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          openExclusiveInfo()
        }}
      >
        {t('common.buttons.more', 'Know more')}
      </LinkText>
    </Box>
  )
  return (
    <TooltipComponent arrow={true} placement="top" title={exclusiveTooltipBox}>
      <IconContainer
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          openExclusiveInfo()
        }}
      >
        <Star style={{ fontSize: 18, color: '#F0B440' }} />
      </IconContainer>
    </TooltipComponent>
  )
}
