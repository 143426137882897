import { Box, OutlinedInput, styled } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const TemplatesInput = styled(OutlinedInput)(
  ({ theme }) => `
  background: rgba(47, 84, 235, 0.05);
  color:  ${theme.palette.primary.main};
  cursor: pointer;
  width: 171px;
  padding: 6px 8px;
  height: 36px;
  
  ${MEDIA_RULES.SMALL} {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  fieldset {
    border: none;
  }

  input {
    padding: 0;
    cursor: pointer;

    &::placeholder {
        opacity: 1;
        font-size: 14px;
    }
  }

  .MuiInputAdornment-root {
    height: 100%;
  }
`
)

export const PopoverWrapper = styled(Box)(
  ({ theme }) => `
      max-height: 265px;
      overflow-y: auto;
      margin-bottom: 5px;
      width: 284px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      background: ${theme.palette.common.white};
      
      ${MEDIA_RULES.SMALL} {
        width: 100%;
      }
    `
)
