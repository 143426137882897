import {
  accountOptionsByRole,
  adminAccountOptions,
  getMobileAccountOptionsByRole,
} from '../../constants'
import { UserRoles } from '../../constants/profile'
import { AccountMenuActions, AuthUser } from '../../types/Auth'
import { ProfileData } from '../../types/Profile'

export const getUserAccountOptions = (
  user: ProfileData | null,
  isMobile: boolean | undefined,
  isPartner: boolean,
  isAbs: boolean
) => {
  if (!user) return []

  const accountOptions = !isMobile
    ? accountOptionsByRole[user.role]
    : getMobileAccountOptionsByRole(isAbs)[user.role] || []
  const filteredAccountOptions = isPartner
    ? accountOptions.filter(
        (item) => item.value !== AccountMenuActions.BecomeAPartner
      )
    : accountOptions

  if (user?.is_admin && !isMobile) {
    return [...adminAccountOptions, ...filteredAccountOptions]
  }

  return filteredAccountOptions
}

export const formatAuthUser = (
  user: ProfileData | AuthUser,
  id?: string
): ProfileData => {
  const listing_id =
    (id && +id) ||
    (user.venues?.length ? user.venues?.[0]?.id : user.vendors?.[0]?.id)

  return {
    id: user.id,
    name: user.name,
    email: user.email,
    is_admin: user.is_admin,
    phone: user.phone,
    google_id: user.google_id,
    stripe_business_id: user.stripe_business_id,
    vendors: user.vendors,
    venues: user.venues,
    user_files: user.user_files,
    payment_info: user.payment_info,
    default_client_venue_id: user.default_client_venue_id,
    listing_id,
    role: user.role || UserRoles.Client,
    partner_source: user.partner_source || '',
  }
}
