import { Box, Typography } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { NavLink } from 'components/NavLink'
import * as React from 'react'
import { generatePath } from 'react-router-dom'

import { PromotionLabel } from '../../../../components/cards/labels/PromotionLabel'
import Guests from '../../../../components/svg/Guests'
import Location from '../../../../components/svg/Location'
import RoomsIcon from '../../../../components/svg/venues/Rooms'
import { Image } from '../../../../components/ui/Image'
import { VenueItem } from '../../../../types/Venue'
import { formatToCurrency } from '../../../../utils/helpers/price'
import { getAddressString } from '../../../../utils/helpers/venue'
import { venuePath } from '../../../../utils/paths'

import {
  StyledLocationTypography,
  StyledVerticalItemBottomBar,
} from './VenueListItem.styles'

interface Props {
  venue: VenueItem
}

export const VenueListItemVertical: React.FC<Props> = ({ venue }) => {
  const { slug } = venue
  const venueUrl = generatePath(venuePath, { slug })

  const addressString = getAddressString(
    venue.location_address?.formatted_address ||
      venue.address?.formatted_address
  )

  const listItemTitle = (
    <Box whiteSpace="normal">
      <Typography variant="h3">
        <NavLink to={venueUrl}>{venue.name}</NavLink>
      </Typography>
      {venue.location ? (
        <Typography color="#9396a3" variant="body2">
          {venue.location}
        </Typography>
      ) : (
        <Box display="flex" alignItems="center" mt={2} mb={2}>
          <Location fill="#9396A3" />
          <StyledLocationTypography color="#9396a3" variant="body2">
            {addressString}
          </StyledLocationTypography>
        </Box>
      )}
      {!!venue.rooms?.length && (
        <Box display="flex" mt={2} mb={2} alignItems="center">
          <RoomsIcon />
          <Typography color="#9396a3" style={{ marginLeft: 6 }} variant="body2">
            {venue.rooms.length} rooms
          </Typography>
        </Box>
      )}
      {venue.max_head_count && (
        <Box display="flex" mt={2} mb={2} alignItems="center">
          <Guests />
          <Typography color="#9396a3" style={{ marginLeft: 6 }} variant="body2">
            {venue.max_head_count} people max
          </Typography>
        </Box>
      )}
      <Typography fontWeight={500}>
        From {formatToCurrency(venue.params?.minimum_starting_at)}
        <span style={{ color: '#9396a3', fontSize: 14, fontWeight: 400 }}>
          /day
        </span>
      </Typography>
    </Box>
  )

  return (
    <ListItem alignItems="flex-start" key={venue.id} sx={{ paddingLeft: 0 }}>
      <ListItemAvatar>
        {venue.has_promotion && <PromotionLabel size="small" />}
        <NavLink to={venueUrl}>
          <Image
            image={venue?.images?.[0]}
            imageSize="medium"
            style={{ width: 250, height: 180 }}
            alt={venue.name}
          />
        </NavLink>
      </ListItemAvatar>
      <StyledVerticalItemBottomBar title={listItemTitle} position="below" />
    </ListItem>
  )
}
