import { capitalize, findKey, pickBy, startsWith } from 'lodash'

import { EVENT_STAGES_PROGRESS, InquiryStatuses } from '../../constants/inquiry'
import { isPartner } from '../../constants/partners'
import { InquiryInvoiceResponse, Invoice } from '../../types/dtos/inquiry'
import {
  BudgetItem,
  Inquiry,
  InquiryDetailFormValues,
  InquiryEvent,
} from '../../types/Inquiry'

export const VENUE_BUDGET = 'venue'
export const TARGET_BUDGET = 'Target budget'
export const INITIAL_BUDGET = 'Initial range'

export const mergeInquiries = (
  data: InquiryEvent,
  previousInquiries: InquiryEvent[]
) => {
  return (
    previousInquiries?.map((inquiry) => {
      if (inquiry.id === data.id) {
        return {
          ...inquiry,
          ...data,
        }
      }
      return inquiry
    }) || [data]
  )
}

export const getInquiryActionText = (status: string): string => {
  switch (status) {
    case InquiryStatuses.STATUS_INQUIRING:
      return 'Accept'
    case InquiryStatuses.STATUS_PENDING_SUBMIT:
      return 'Submit'
    default:
      return 'Details'
  }
}

export const isChecked = (stage: string, status: string) => {
  const currentStage = findKey(EVENT_STAGES_PROGRESS, (stageStatuses) =>
    stageStatuses.includes(status as InquiryStatuses)
  )
  const stageIndex = Object.keys(EVENT_STAGES_PROGRESS).findIndex(
    (eventStage) => eventStage === stage
  )
  const currentStageIndex = Object.keys(EVENT_STAGES_PROGRESS).findIndex(
    (eventStage) => eventStage === currentStage
  )
  // @ts-ignore
  const statusIndexInCurrentStage = EVENT_STAGES_PROGRESS[stage].findIndex(
    // @ts-ignore
    (stageStatus) => stageStatus === status
  )
  return (
    stageIndex < currentStageIndex ||
    // @ts-ignore
    statusIndexInCurrentStage === EVENT_STAGES_PROGRESS[stage].length - 1
  )
}

export const isStageColored = (
  currentStage: string,
  stage: string
): boolean => {
  const stageIndex = Object.keys(EVENT_STAGES_PROGRESS).findIndex(
    (eventStage) => eventStage === stage
  )
  const currentStageIndex = Object.keys(EVENT_STAGES_PROGRESS).findIndex(
    (eventStage) => eventStage === currentStage
  )
  return stageIndex <= currentStageIndex
}

type Budget = {
  title: string
  value: string
}

const getAbsBudget = (inquiryDetails: InquiryDetailFormValues): Budget[] => {
  const budget: any = []
  const budgetFields: Partial<
    InquiryDetailFormValues & { [TARGET_BUDGET]: string }
  > = pickBy(inquiryDetails, (value, key) => {
    // const formattedKey = key.toLowerCase().replace(/[^A-Z0-9]+/gi, '-')
    return (
      !!inquiryDetails.vendorTypes?.find((type) => key === type) ||
      startsWith(key, capitalize(key))
    )
  })

  budgetFields[TARGET_BUDGET] = inquiryDetails.price

  for (const key in budgetFields) {
    const budgetValue =
      budgetFields[key as keyof Partial<InquiryDetailFormValues>]?.toString()
    if (budgetValue) {
      budget.push({
        title: key.toString(),
        value:
          budgetFields[
            key as keyof Partial<InquiryDetailFormValues>
          ]?.toString(),
      })
    }
  }

  return budget
}

const buildAbsBudgets = (inquiryDetails: InquiryDetailFormValues): Budget[] => {
  if (
    (!inquiryDetails.isAllocateBudget || !inquiryDetails.isOverAllBudget) &&
    inquiryDetails.priceRange
  ) {
    return [
      {
        title: INITIAL_BUDGET,
        value: inquiryDetails.priceRange,
      },
    ]
  }

  return getAbsBudget(inquiryDetails)
}

export const buildBudgets = (
  inquiryDetails: InquiryDetailFormValues
): Budget[] => {
  if (isPartner()) {
    return buildAbsBudgets(inquiryDetails)
  }

  return [
    {
      title: VENUE_BUDGET,
      value: inquiryDetails.price,
    },
  ]
}

export const getVendorTypesAndPriceByBudget = (budgets?: BudgetItem[]) => {
  const vendorTypes: string[] = []
  let priceRange = ''
  if (budgets && budgets.length) {
    budgets.map((budget) => {
      if (budget.title !== INITIAL_BUDGET) {
        vendorTypes.push(budget.title)
      } else {
        priceRange = budget.value
      }
    })
  }
  return { vendorTypes, priceRange }
}

export const getInquiryBusinessAddress = (inquiryDetails?: Inquiry) => {
  return {
    address1: inquiryDetails?.venue
      ? `${
          inquiryDetails?.venue.location_address?.route
            ? inquiryDetails?.venue.location_address?.route
            : ''
        } ${
          inquiryDetails?.venue.location_address?.street_number
            ? inquiryDetails?.venue.location_address?.street_number
            : ''
        }`
      : inquiryDetails?.vendor
      ? `${
          inquiryDetails?.vendor.location?.route
            ? inquiryDetails?.vendor.location?.route
            : ''
        } ${
          inquiryDetails?.vendor.location?.street_number
            ? inquiryDetails?.vendor.location?.street_number
            : ''
        }`
      : '',
    address2: inquiryDetails?.venue
      ? `${
          inquiryDetails?.venue.location_address?.city_long
            ? inquiryDetails?.venue.location_address?.city_long
            : ''
        } ${
          inquiryDetails?.venue.location_address?.city
            ? inquiryDetails?.venue.location_address?.city
            : ''
        } ${
          inquiryDetails?.venue.location_address?.zip
            ? inquiryDetails?.venue.location_address?.zip
            : ''
        }`
      : inquiryDetails?.vendor
      ? `${
          inquiryDetails?.vendor.location?.city_long
            ? inquiryDetails?.vendor.location?.city_long
            : ''
        } ${
          inquiryDetails?.vendor.location?.city
            ? inquiryDetails?.vendor.location?.city
            : ''
        } ${
          inquiryDetails?.vendor.location?.zip
            ? inquiryDetails?.vendor.location?.zip
            : ''
        }`
      : '',
  }
}

export const getInquiryBusinessContact = (inquiryDetails?: Inquiry) => {
  const address = getInquiryBusinessAddress(inquiryDetails)
  return {
    type: inquiryDetails?.venue
      ? 'venue'
      : inquiryDetails?.vendor
      ? 'vendor'
      : '',
    name: inquiryDetails?.venue
      ? inquiryDetails?.venue.name
      : inquiryDetails?.vendor
      ? inquiryDetails?.vendor.name
      : '',
    address: address,
    phone: inquiryDetails?.venue
      ? inquiryDetails?.venue.primary_contact.phone
      : inquiryDetails?.vendor
      ? inquiryDetails?.vendor.phone
      : '',
    email: inquiryDetails?.venue
      ? inquiryDetails?.venue.primary_contact.email
      : inquiryDetails?.vendor
      ? inquiryDetails?.vendor.contact_email
      : '',
  }
}

export const getInvoiceFromBundleAndInvoice = (
  bundle: InquiryInvoiceResponse,
  invoice: Invoice
) => ({
  ...invoice,
  ...bundle,
  id: invoice.id,
  status: invoice.status,
  status_info: invoice.status_info,
  expiration_date: invoice.expiration_date,
  grouped_line_items_bundle: invoice.grouped_line_items_bundle,
})
