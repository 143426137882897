import { isPartner } from '../../constants/partners'
import absPalette from '../../theme/absTheme'
import { createBlaceTheme } from '../../theme/createBlaceTheme'

export const setTheme = () => {
  if (isPartner()) {
    return createBlaceTheme(absPalette)
  } else {
    return createBlaceTheme()
  }
}
