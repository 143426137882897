import { TextField, TextFieldProps } from '@material-ui/core'
import { FastField, FieldProps, getIn } from 'formik'
import * as React from 'react'

export const FastTextField: React.FC<TextFieldProps> = ({
  name,
  placeholder,
  ...props
}) => {
  return (
    <FastField name={name}>
      {({
        field,
        form: { isSubmitting, touched, errors, handleBlur, setFieldValue },
      }: FieldProps) => {
        const fieldError = getIn(errors, field.name)
        const showError = getIn(touched, field.name) && Boolean(fieldError)

        return (
          <TextField
            {...field}
            {...props}
            error={showError}
            helperText={showError && fieldError}
            placeholder={placeholder}
            disabled={isSubmitting}
            fullWidth
            onBlur={(e) => {
              handleBlur(e)
              const { name, value } = e.currentTarget
              setFieldValue(name, value.trim())
            }}
          />
        )
      }}
    </FastField>
  )
}
