/** @jsxImportSource @emotion/react */

import { FormControl, FormControlProps } from '@material-ui/core'
import { forwardRef } from 'react'
import { centerX } from 'utils/styles/layout'
import { spaceX, spaceY } from 'utils/styles/space'

export type FormFieldProps = FormControlProps
export const FormField = forwardRef(function FormField(
  props: FormControlProps,
  ref: FormControlProps['ref']
) {
  return (
    <FormControl variant="outlined" css={spaceY('8px')} {...props} ref={ref} />
  )
})

export const FormFieldInline = forwardRef(function FormFieldInline(
  props: FormControlProps,
  ref: FormControlProps['ref']
) {
  return (
    <FormControl
      variant="outlined"
      css={[spaceX('8px'), centerX]}
      {...props}
      ref={ref}
    />
  )
})
