const Planet = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 .8a7.2 7.2 0 1 0 0 14.4A7.2 7.2 0 0 0 8 .8Zm6 5.185a2.704 2.704 0 0 1-1.4 1.64 4.512 4.512 0 0 0-2.615-3 1.6 1.6 0 0 1 .632-.872c-.344-.224-.8-.336-1.072.056-.424.552 0 1.288.168 1.6v.112a2.456 2.456 0 0 1-.992-1.152 4.16 4.16 0 0 0-2.208.552 2.752 2.752 0 0 1 .128-1.344 1.768 1.768 0 0 0 1.536-.64c.368-.416-.104-.944-.472-1.264h.288a6.288 6.288 0 0 1 3.112.8A4.49 4.49 0 0 1 12.92 5.88c.192 0 .56-.44.728-.736.137.271.255.552.352.84Zm-6 8.288c-1.64-1.664.2-3-.8-4.192-.735-.68-1.831-.208-2.487-.984a3.264 3.264 0 0 1 1.144-3.144c.416-.352 3.2-.8 4.336.176a4.177 4.177 0 0 1 1.336 2.192 1.88 1.88 0 0 0 1.056-.232c.328 2.384-2.52 5.392-4.584 6.184Zm-3.08-11.8A1.472 1.472 0 0 1 6.65 3a2.34 2.34 0 0 1-1.2.576 2.4 2.4 0 0 1 .152-.688l-.68-.416Z"
        fill="#20202C"
      />
    </svg>
  )
}

export default Planet
