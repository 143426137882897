const Calendar = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.625 20.8372C2.625 21.252 2.96016 21.5872 3.375 21.5872H20.625C21.0398 21.5872 21.375 21.252 21.375 20.8372V10.9934H2.625V20.8372ZM20.625 4.52466H16.6875V3.02466C16.6875 2.92153 16.6031 2.83716 16.5 2.83716H15.1875C15.0844 2.83716 15 2.92153 15 3.02466V4.52466H9V3.02466C9 2.92153 8.91563 2.83716 8.8125 2.83716H7.5C7.39687 2.83716 7.3125 2.92153 7.3125 3.02466V4.52466H3.375C2.96016 4.52466 2.625 4.85981 2.625 5.27466V9.39966H21.375V5.27466C21.375 4.85981 21.0398 4.52466 20.625 4.52466Z"
        fill="#F7F7F8"
      />
    </svg>
  )
}

export default Calendar
