// @ts-ignore
import Calendar, { CalendarProps } from '@ericz1803/react-google-calendar'
// eslint-disable-next-line import/order
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Box, Link, Typography } from '@material-ui/core'
import * as React from 'react'

import { SimpleModal } from '../ui/Modals/SimpleModal'

import { CalendarStyles } from './GoogleCalendar.styles'

const { REACT_APP_GOOGLE_CALENDAR_API_KEY: apiKey } = process.env

interface CalendarComponentProps extends CalendarProps {
  calendarGoogleId: string | string[]
}

export const CalendarComponent: React.FC<CalendarComponentProps> = (
  calendarProps
) => {
  const { calendarGoogleId } = calendarProps
  const calendarIds = Array.isArray(calendarGoogleId)
    ? calendarGoogleId
    : [calendarGoogleId]
  const calendars = calendarIds.map((googleCalendarId) => ({
    googleCalendarId,
    textColor: 'white',
  }))
  // const calendarIds = Array.isArray(calendarGoogleId) ? calendarGoogleId : [calendarGoogleId]
  // const calendars = calendarIds.map((calendarId) => ({ calendarId }))
  const calendarRef = React.createRef<FullCalendar>()

  if (calendars && calendars.length) {
    return (
      <div
        style={{
          height: '30%',
          width: '300px',
        }}
      >
        <FullCalendar
          plugins={[dayGridPlugin, googleCalendarPlugin, listPlugin]}
          initialView={'dayGridMonth'}
          ref={calendarRef}
          contentHeight="auto"
          dayMaxEventRows={1}
          dayHeaderFormat={{ weekday: 'short', omitCommas: true }}
          slotLabelFormat={[{ timeStyle: 'short' }]}
          googleCalendarApiKey={apiKey}
          eventSources={calendars}
        />
        {/*<Calendar showFooter={false} apiKey={apiKey} styles={CalendarStyles} {...calendarProps} calendars={calendars} />*/}
      </div>
    )
  }
  return null
}
