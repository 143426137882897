import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'

import { InquiryFormPostRequest } from '../types/Inquiry'
import { postJotForm } from '../utils/api/events'
import {
  createInquiry,
  createInquiryForDuplicate,
} from '../utils/api/inquiries'
import { getRequestError } from '../utils/helpers/validations'
import { convertInquiryEventToUserEvent } from '../utils/mappers/inquiries'
import { eventPath } from '../utils/paths'
import { useAuthData } from '../utils/providers/AuthProvider'
import { useStorageEventsData } from '../utils/providers/EventsProvider'
import { useMessageModalData } from '../utils/providers/MessageModalProvider'
import {
  LsGetJotFormValues,
  LsRemoveInquiryDetails,
  LsRemoveInquiryFormValues,
  LsRemoveJotFormValues,
} from '../utils/storage'

import { usePartner } from './usePartner'
import { useSnackbar } from './useSnackbar'

export const useCreateOrDuplicateEvent = (): {
  handleCreateOrDuplicateEvent: (inquiryData: InquiryFormPostRequest) => void
} => {
  const { t } = useTranslation()
  const { authUser } = useAuthData()
  const history = useHistory()
  const { updateUserActiveEvent } = useStorageEventsData()
  const showSnackbar = useSnackbar()
  const { showMessage } = useMessageModalData()
  const { isAbs } = usePartner()

  const submitJotForm = (submittedJotForm: any, event_id: number) => {
    const data = {
      questionnaire_type: 'vendor',
      form_id: submittedJotForm.jotFormId,
      event_id,
      vendor_type: submittedJotForm.vendorTypeId,
      data: JSON.stringify(submittedJotForm),
      submission_id: submittedJotForm.id,
    }
    postJotForm(data, Boolean(authUser))
      .then((response: any) => {
        console.log(response, 'save JF')
      })
      .catch((e: Error) => {
        console.log(e, 'error')
      })
  }

  const handleCreateOrDuplicateEvent = async (
    inquiryData: InquiryFormPostRequest
  ) => {
    try {
      const { data } = inquiryData.original_event_id
        ? await createInquiryForDuplicate(inquiryData)
        : await createInquiry(inquiryData)
      const storageEvent = convertInquiryEventToUserEvent(data)
      updateUserActiveEvent({ ...storageEvent })
      const submittedJotForm = LsGetJotFormValues()
      if (submittedJotForm && submittedJotForm.vendorTypeId) {
        submitJotForm(submittedJotForm, data.id)
      }
      LsRemoveInquiryFormValues()
      LsRemoveInquiryDetails()
      LsRemoveJotFormValues()
      showSnackbar({
        severity: 'success',
        message: isAbs
          ? t(
              'partners.abs.bookings.created',
              'Booking has been successfully created'
            )
          : t('inquiry.created', 'Event has been successfully created'),
      })
      history.push(
        generatePath(eventPath, {
          id: data?.id,
        })
      )
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    }
  }

  return { handleCreateOrDuplicateEvent }
}
