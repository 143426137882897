import { Box, Paper, styled, Typography } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const Dropdown = styled(Paper)`
  background: white;
  border-radius: 0;
  height: fit-content;

  ${MEDIA_RULES.MEDIUM} {
    min-width: 350px;
  }
`
export const DropdownTitle = styled(Typography)`
  color: #9396a3;
  text-transform: uppercase;
`
export const SelectableItem = styled(MenuItem)`
  margin: 0;
  padding: 0;
  width: auto;

  /* increase specificity to override global styles */
  &&& {
    display: flex;
    align-items: center;
  }

  @media (hover: hover) {
    &:hover {
      background: #f7f7f8;
    }
  }
`

export const Item = styled('li')`
  list-style: none;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
`

export const ItemText = styled(Box)`
  margin: 0 24px;
  width: 100%;
`
