import {
  InputProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core'
import { uniqueId } from 'lodash'
import * as React from 'react'
import { ChangeEvent } from 'react'

import { StyledFormControl } from '../Components.styles'

type Modify<T, R> = Omit<T, keyof R> & R

interface TextInputProps {
  label?: string
  value?: string
  onChange: (value: string, name: string) => void
  name?: string
  disabled?: boolean
  secondaryMode?: boolean
  thirdlyMode?: boolean
  placeholder?: string
  InputProps?: InputProps
  endAdornment?: any
  className?: string
  ContainerProps?: any
}

export const TextInput: React.FC<
  Modify<Partial<TextFieldProps>, TextInputProps>
> = ({
  value,
  name,
  label,
  onChange,
  endAdornment,
  className,
  secondaryMode,
  thirdlyMode,
  ContainerProps,
  ...rest
}) => {
  const colorClass = secondaryMode ? 'secondary' : thirdlyMode ? 'thirdly' : ''
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as string, name || '')
  }
  const id = uniqueId(name)

  return (
    <StyledFormControl
      className={`${className || ''} ${colorClass}`}
      {...ContainerProps}
    >
      {label && (
        <Typography
          color="#000"
          textAlign="left"
          variant="body2"
          sx={{ mb: 2 }}
          component="label"
          htmlFor={id}
        >
          {label}
        </Typography>
      )}
      <TextField
        {...rest}
        InputProps={{ ...rest.InputProps, endAdornment }}
        id={id}
        name={name}
        variant="outlined"
        label={null}
        value={value}
        onChange={handleChange}
      />
    </StyledFormControl>
  )
}
