import { Box, styled, Typography } from '@material-ui/core'

export const DocumentsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const FileName = styled(Typography)`
  max-width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const ActionsContainer = styled(Box)`
  .MuiSvgIcon-root {
    cursor: pointer;
    color: #9396a3;

    &:hover {
      color: #4e4bec;
    }
  }
`
