import { Box, styled, TableCell, TableRow } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const StyledTableCell = styled(TableCell)`
  padding: 10px;
  border-bottom: none;
  margin-right: 20px;
`

export const StyledTableRow = styled(TableRow)`
  color: inherit;
  background: #f7f7f8;
  display: table-row;
  vertical-align: middle;
  outline: 0;
  border-bottom: 2px solid #fff;

  &:first-of-type {
    ${MEDIA_RULES.SMALL} {
      border-bottom: 0;
    }
  }
`

export const StyledBox = styled(Box)`
  color: #2f54eb;
  cursor: pointer;
`

export const ModalContentWrapper = styled(Box)`
  background: white;
  padding: 48px 0;
  position: absolute;
  bottom: 100px;
  right: 0;
  left: 0;
  top: 75px;
  overflow-y: auto;

  ${MEDIA_RULES.SMALL} {
    padding: 24px 0;
  }
`

export const Footer = styled(Box)`
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  z-index: 2;
  display: flex;
`
