const ExclamationCircle = ({
  size = 40,
  fill = '#FFE870',
}: {
  size?: number
  fill?: string
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M20 0C8.95536 0 0 8.95536 0 20C0 31.0446 8.95536 40 20 40C31.0446 40 40 31.0446 40 20C40 8.95536 31.0446 0 20 0ZM20 36.6071C10.8304 36.6071 3.39286 29.1696 3.39286 20C3.39286 10.8304 10.8304 3.39286 20 3.39286C29.1696 3.39286 36.6071 10.8304 36.6071 20C36.6071 29.1696 29.1696 36.6071 20 36.6071Z"
          fill={fill}
        />
        <path
          d="M17.8564 27.8571C17.8564 28.4255 18.0822 28.9705 18.4841 29.3724C18.8859 29.7742 19.431 30 19.9993 30C20.5676 30 21.1127 29.7742 21.5145 29.3724C21.9164 28.9705 22.1422 28.4255 22.1422 27.8571C22.1422 27.2888 21.9164 26.7438 21.5145 26.3419C21.1127 25.9401 20.5676 25.7143 19.9993 25.7143C19.431 25.7143 18.8859 25.9401 18.4841 26.3419C18.0822 26.7438 17.8564 27.2888 17.8564 27.8571ZM18.9279 22.8571H21.0707C21.2672 22.8571 21.4279 22.6964 21.4279 22.5V10.3571C21.4279 10.1607 21.2672 10 21.0707 10H18.9279C18.7314 10 18.5707 10.1607 18.5707 10.3571V22.5C18.5707 22.6964 18.7314 22.8571 18.9279 22.8571Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ExclamationCircle
