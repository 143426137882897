import { Box, ClickAwayListener, Tooltip } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import React, { ReactElement } from 'react'

import { TooltipComponent } from '../../../../components/ui/Tooltip'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { VenueListItem } from '../../../venues/components/VenueListItem/VenueListItem'

export const StyledMarker = styled(Box)(
  ({ theme }) => `
  height: 12px;
  width: 12px;
  background-color: #4091F0;
  border: solid white 2px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
`
)

export const StyledContent = styled(Box)`
  width: 220px;
  font-size: 14px;
  height: 400px;
  z-index: 100;

  li {
    margin: 0;
  }
`

const InfoWindow = (props: { markerInfo: any }) => {
  const { markerInfo } = props

  return (
    <StyledContent>
      <VenueListItem key={markerInfo.id} venue={markerInfo} />
    </StyledContent>
  )
}

export default function Marker(props: {
  id: any
  markerInfo?: any
  lat: any
  lng: any
  handleClickAway: () => void
}): ReactElement {
  const { markerInfo } = props
  const { isMobile } = useWindowSize()
  const [open, setOpen] = React.useState<boolean>(false)

  return isMobile ? (
    <ClickAwayListener
      touchEvent="onTouchStart"
      onClickAway={() => setOpen(false)}
    >
      <Box onClick={() => setOpen(true)}>
        <TooltipComponent
          open={Boolean(open)}
          TransitionProps={{
            style: { padding: 0, margin: 0, marginBottom: 5 },
          }}
          leaveTouchDelay={0}
          enterTouchDelay={0}
          arrow
          title={<InfoWindow markerInfo={markerInfo} />}
          placeholder="top"
        >
          <StyledMarker style={{ width: '25px', height: '25px' }} />
        </TooltipComponent>
      </Box>
    </ClickAwayListener>
  ) : (
    <Tooltip
      TransitionProps={{ style: { padding: 0, margin: 0, marginBottom: 5 } }}
      arrow
      title={<InfoWindow markerInfo={markerInfo} />}
      placeholder="top"
    >
      <StyledMarker />
    </Tooltip>
  )
}
