import {
  Box,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

export const StyledListItem = styled(ImageListItem)`
  margin: 5px;
  position: relative;

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
`

export const StyledAdvantageLabel = styled(Box)(
  ({ theme }) => `
  width: 122px;
  height: 32px;
  background: ${theme.palette.common.buttonBg};
  position: absolute;
  top: 16px;
  left: 0;
`
)

export const StyledAdvantageLabelTypography = styled(Typography)(
  ({ theme }) => `
  font-family: 'Frank Ruhl Libre';
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  color: ${theme.palette.common.white};
  text-align: center;
`
)

export const StyledItemBottomBar = styled(ImageListItemBar)(
  ({ theme }) => `
    background-color: ${theme.palette.background.default};
    padding: 10px;
    padding-right: 0;
    padding-left: 0;
`
)

export const StyledVerticalItemBottomBar = styled(ImageListItemBar)(
  ({ theme }) => `
    background-color: ${theme.palette.background.default};
    margin: 15px;
    margin-right: 0;
`
)

export const SubtitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-top: 8px;
`

export const TitleWrapper = styled('div')`
  display: flex;
  font-size: 16px;
  align-items: flex-start;
`

export const TitleTextWrapper = styled('div')`
  margin-bottom: 5px;
  padding-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Footer = styled(ImageListItemBar)`
  background: rgba(32, 32, 44, 0.88);
  align-items: center;
  flex: 1;

  .MuiImageListItemBar-title {
    font-weight: 600;
  }

  .MuiImageListItemBar-subtitle {
    font-size: 14px;
  }
`

export const StyledLocationTypography = styled(Typography)`
  margin-left: 10px;
`
export const StyledDepositTypography = styled(Typography)`
  color: #9396a3;
`
