const Email = ({ fill = '#262626' }) => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 2.5h-13A.5.5 0 0 0 1 3v10a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5Zm-1.262 1.702-4.93 3.835a.336.336 0 0 1-.414 0L2.962 4.202A.112.112 0 0 1 3.032 4h10.137a.113.113 0 0 1 .069.202Z"
        fill={fill}
      />
    </svg>
  )
}

export default Email
