import { isFunction, mapValues } from 'lodash'

function isGenerator(fn: any): fn is Generator {
  return isFunction((fn as Generator).next)
}

type AttributeGenerator<T> = T | Generator<T> | (() => T)

/**
 * Factory function to generate objects
 * @param builder - Object with the attributes to generate that can be static values, functions or generators
 *
 * @example
 * const user = factory({
 *  id: sequence(),
 *  name: nameSequence('User'),
 *  age: 20,
 *  email: () => Faker.internet.email()
 * })
 */
export function factory<T>(builder: {
  [K in keyof T]: AttributeGenerator<T[K]>
}) {
  const buildAttribute = (value: AttributeGenerator<T[keyof T]>) => {
    if (isGenerator(value)) return value.next().value
    if (isFunction(value)) return value()
    return value
  }

  return function* generator(overrides: Partial<T> = {}): Generator<T> {
    while (true) {
      yield { ...mapValues(builder, buildAttribute), ...overrides } as T
    }
  }
}

/**
 * Build a single object from a factory
 * @param factory - Generator function that accepts overrides
 * @param overrides - Object with the attributes to override
 */
export function build<T extends object>(
  factory: (overrides: Partial<T>) => Generator<T>,
  overrides: Partial<T> = {}
) {
  return factory(overrides).next().value
}

/**
 * Build multiple objects from a factory
 * @param count - Number of objects to generate
 * @param factory - Generator function that accepts overrides
 * @param overrides - Object with the attributes to override
 */
export function buildMany<T extends object>(
  count: number,
  factory: (overrides: Partial<T>) => Generator<T>,
  overrides: Partial<T> = {}
) {
  return Array.from({ length: count }, () => build(factory, overrides))
}

/**
 * Generate a sequence of numbers
 * @param start
 * @param step
 */
export function* sequence(start: number = 1, step: number = 1) {
  let i = start
  while (true) {
    yield i
    i += step
  }
}

/**
 * Generate a sequence of strings prefixed with the same name
 * @param prefix
 * @param start
 * @param step
 */
export function* nameSequence(
  prefix: string = '',
  start: number = 1,
  step: number = 1
) {
  for (const i of sequence(start, step)) {
    yield `${prefix} #${i}`
  }
}
