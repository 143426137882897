import { Box, styled, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { Field, FieldProps, useFormikContext } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { FastTextField } from '../../../components/formik'
import { SelectField } from '../../../components/ui/FormComponents/SelectField'
import { CostLevelInput } from '../../../components/ui/Inputs/CostLevelInput'
import { PhoneInput } from '../../../components/ui/Inputs/PhoneInput'
import { Loader } from '../../../components/ui/Loader'
import { priceVariants } from '../../../constants'
import { VendorType } from '../../../types/Vendor'

import { AddressField } from './FormComponents/AddressField'
import { CompanySizeField } from './FormComponents/CompanySizeField'

export const SectionHeader = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`

const FieldLabel = styled(Typography)`
  color: #bdbdbd;
  text-align: left;
  margin-bottom: 10px;
`

export const VendorInformationForm: React.FC<{
  categories?: VendorType[]
  isAuthUser: boolean
}> = React.memo(function VendorInformationFormComponent({
  categories = [],
  isAuthUser,
}) {
  const { t } = useTranslation()
  const {
    isValidating,
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext<{
    contactEmail: string
    type: string
    costLevel: number
  }>()
  const onCostChange = (e: any) => {
    console.log(e.target.value)
    const existingPriceVariant = priceVariants.find(
      (variant) => variant.label === e.target.value
    )
    if (existingPriceVariant) {
      setFieldValue('costLevel', existingPriceVariant.value)
    } else {
      setFieldValue('costLevel', 0)
    }
  }

  return (
    <>
      <Box mb={4} mr={7}>
        <SectionHeader variant="body2">
          {t('onboarding.vendor.form.heading', 'Vendor information')}
        </SectionHeader>
        <Field name="type">
          {({ field, form: { setFieldValue } }: FieldProps) => {
            return (
              <SelectField
                sx={{ padding: '12.5px 0' }}
                label={t('onboarding.vendor.form.type', '*Type of service')}
                options={categories}
                value={field.value}
                secondaryMode
                error={(touched.type && errors.type) || ''}
                onOpen={() => setFieldTouched(field.name, true)}
                onChange={(value) => {
                  setFieldValue(field.name, value, true)
                }}
              />
            )
          }}
        </Field>
        <Box display="flex" flexDirection="column" mt={2}>
          <FieldLabel variant="body2">
            {t('onboarding.vendor.businessName', '*Listing name')}
          </FieldLabel>
          <FastTextField name="name" />
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <FieldLabel variant="body2">
            {t('onboarding.vendor.businessDescription', '*Listing description')}
          </FieldLabel>
          <FastTextField name="description" />
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <FieldLabel variant="body2">
            {t('onboarding.vendor.businessAddress', 'Listing address')}
          </FieldLabel>
          <AddressField />
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <FieldLabel variant="body2">
            {t(
              'onboarding.vendor.businessWebsite',
              'Vendor website (if applicable)'
            )}
          </FieldLabel>
          <FastTextField name="website" />
        </Box>
      </Box>
      <Box mb={4} mr={7}>
        <SectionHeader variant="body2">
          {t('onboarding.vendor.businessPricing', 'Listing Pricing')}
        </SectionHeader>
        <Box mt={2} style={{ zIndex: 1400 }}>
          <CostLevelInput
            value={values.costLevel}
            renderInput={(fieldValue) => (
              <Box display="flex" flexDirection="column">
                <FieldLabel variant="body2">
                  {t(
                    'onboarding.vendor.businessPricingLevel',
                    '*Pricing level'
                  )}
                </FieldLabel>
                <TextField
                  placeholder={t(
                    'onboarding.vendor.businessPricingPlaceholder',
                    '$$$'
                  )}
                  onChange={onCostChange}
                  value={fieldValue}
                />
              </Box>
            )}
            setValue={(value) => {
              setFieldValue('costLevel', value)
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <CompanySizeField />
        </Box>
      </Box>
      {!isAuthUser && (
        <Box mb={4} mr={7}>
          <SectionHeader variant="body2">
            {t('onboarding.vendor.form.contacts', 'Contact Information')}
          </SectionHeader>
          <Box display="flex" flexDirection="column" mt={2}>
            <FieldLabel variant="body2">
              {t(
                'onboarding.vendor.primaryContactName',
                '*Primary Contact Name'
              )}
            </FieldLabel>
            <FastTextField name="contactName" />
          </Box>
          <Box display="flex" flexDirection="column" mt={2}>
            <FieldLabel variant="body2">
              {t(
                'onboarding.vendor.primaryContactEmail',
                '*Primary Contact Email'
              )}
            </FieldLabel>
            <Box display="flex" style={{ position: 'relative' }}>
              <FastTextField name="contactEmail" />
              {isValidating && (
                <Loader
                  size={20}
                  position="absolute"
                  background="transparent"
                />
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" mt={2}>
            <FieldLabel variant="body2">
              {t(
                'onboarding.vendor.primaryContactPhone',
                '*Primary Contact Phone'
              )}
            </FieldLabel>
            <PhoneInput name="phone" />
          </Box>
        </Box>
      )}
    </>
  )
})
