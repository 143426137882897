import { Box, styled, Typography } from '@material-ui/core'
import { uniq, uniqBy } from 'lodash'
import * as React from 'react'
import { useEffect } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { FileRejection } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'

import {
  AssetLoadingStatus,
  AssetType,
  imagesMaxSize,
} from '../../../constants'
import { useUploader } from '../../../hooks/useUploader'
import { ImageFormat } from '../../../types/FileUploader'
import {
  AssetRefDownload,
  isEmptyAsset,
  ProgressPayload,
  UploadAction,
  UploadResponse,
} from '../../../types/FileUploadHandler'
import { removeElementFromArray } from '../../../utils/helpers/data'
import { generateFileMetadata } from '../../../utils/helpers/uploadFiles'
import FullCalendarView from '../../svg/dashboard/FullCalendarView'
import PictureOutlined from '../../svg/PictureOutlined'
import RemoveIcon from '../../svg/RemoveIcon'
import { reorder } from '../DragAndDrop'
import { Image } from '../Image'
import { DefaultMessage } from '../ImageUploaderField/ImageUploaderField.styles'
import { Loader } from '../Loader'

const UploadContainer = styled(Box)`
  width: 100%;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ActionsWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;

  svg:hover {
    path {
      fill: #2f54eb;
      stroke: #2f54eb;
    }
  }
`

interface DropzoneProps {
  isDragActive: boolean
}

interface UploaderState extends DropzoneProps {
  hasFiles: boolean
  disabled?: boolean
  height?: number
  width?: number
}

export const UploadField = styled('div')<UploaderState>(
  (props) => `
    ${
      props.height
        ? `
      margin-top: ${
        !props.hasFiles ? props.height - (props.height / 2 + 35) + 'px' : '0'
      };
      margin-bottom: ${
        !props.hasFiles ? props.height - (props.height / 2 + 35) + 'px' : '0'
      };
    `
        : ''
    }
    
    width: 100%;
    ${props.width && `width: ${props.width}px`}
`
)

const ImagesGrid = styled('div')<{ count: number }>(
  (props) => `
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: ${props.count > 0 ? '25px' : `${0}px`};
    grid-gap: 20px;
`
)

export interface ImageUploaderFieldProps {
  images: AssetRefDownload[]
  name: string
  isMulti?: boolean
  label?: string
  error?: string
  accept: ImageFormat[]
  assetType: AssetType
  schema?: any
  hasMetadataError?: boolean
  previewUrl?: string

  onChange(asset: AssetRefDownload[]): void

  onFocus?(): void

  onDragEnter?(event: React.DragEvent<HTMLElement>): void

  onMouseEnter?(): void

  onMouseLeave?(): void

  onError?(data: FileRejection): void

  onDelete?: (e: React.MouseEvent) => void
  uploadFile?: (asset: UploadAction) => Promise<UploadResponse>
  disabled?: boolean
  width: number
  height: number
  maxPreviewItemsNumber?: number
  movable?: boolean
  hasReplaceIcon?: boolean
}

const isInProgress = (progress: ProgressPayload): boolean =>
  progress.status === AssetLoadingStatus.STARTED

interface UploadsInProgress {
  [key: string]: ProgressPayload
}

const initialUploadsInProgressState = {} as UploadsInProgress

export const MultiImageUploaderField: React.FC<ImageUploaderFieldProps> = ({
  onError,
  isMulti = true,
  onDelete,
  images,
  name,
  label,
  error,
  accept,
  assetType,
  schema,
  onChange,
  onFocus,
  onDragEnter,
  uploadFile,
  disabled = false,
  width,
  height,
  previewUrl,
}) => {
  const [uploadsInProgress, setUploadsInProgress] = React.useState(
    initialUploadsInProgressState
  )
  const [initialPreviewUrl, setInitialPreviewUrl] = React.useState(previewUrl)
  const [pendingAssets, setPendingAssets] = React.useState<any[]>([])
  const { t } = useTranslation()

  const multiImageChange = (
    asset: AssetRefDownload,
    progress?: ProgressPayload
  ) => {
    setPendingAssets([...pendingAssets, asset])
    progress &&
      setUploadsInProgress({
        ...uploadsInProgress,
        [asset.$assetRef]: progress,
      })
    const updated = uniqBy([...images, ...pendingAssets], 'metadata.fileName')
    onChange(updated)
  }

  const singleImageChange = (
    asset: AssetRefDownload,
    progress?: ProgressPayload
  ) => {
    progress &&
      setUploadsInProgress({
        ...uploadsInProgress,
        [asset.$assetRef]: progress,
      })

    onChange([asset])
  }

  const hasFile = !!images.length && !isEmptyAsset(images[0])
  const {
    isDragActive,
    acceptedFiles,
    getRootProps,
    getInputProps,
    fileRejections,
  } = useUploader({
    accept,
    multiple: !!isMulti,
    assetType,
    maxSize: imagesMaxSize,
    uploadFile,
    isValid: (asset: AssetRefDownload) => {
      return true
    },
    onDropRejected: (e) => {
      if (onError) {
        onError(e[0])
      }
    },
    onChange: isMulti ? multiImageChange : singleImageChange,
    onDragEnter,
  })

  const handleRemoveItem = (e: React.MouseEvent, index: number) => {
    // Prevent bubbling so clicking the clear button doesn't trigger the upload file dialog
    e.stopPropagation()
    setInitialPreviewUrl('')
    if (onDelete) {
      onDelete(e)
    } else {
      const newImages = [...images]
      removeElementFromArray(newImages, index)
      onChange(newImages)
    }
  }

  useEffect(() => {
    setInitialPreviewUrl(previewUrl)
  }, [previewUrl])

  const uploader = (
    <UploadContainer height={height || '100%'} minHeight={height || undefined}>
      <UploadField
        disabled={disabled}
        aria-labelledby={`${name}-label`}
        aria-describedby={`${name}-desc`}
        isDragActive={isDragActive}
        width={width}
        height={height}
        hasFiles={
          hasFile ||
          (images.length &&
            (images[0].metadata.image?.path ||
              images[0].metadata.previewUrl)) ||
          initialPreviewUrl
        }
        {...getRootProps({
          onFocus: (event: React.FocusEvent<HTMLDivElement>) => {
            onFocus && onFocus()
          },
        })}
      >
        <input {...getInputProps()} />
        <Box display="flex" flexDirection="column" alignItems="center">
          <PictureOutlined />
          <DefaultMessage>
            {label || (
              <Typography variant="caption">
                {!isMulti
                  ? t('gallery.dropImage', 'Drop your image here')
                  : t('gallery.dropImages', 'Drop your images here')}
                ,
                <br />
                <Trans i18nKey="gallery.browse">
                  <span>or</span> <a> select click to browse</a>
                </Trans>
              </Typography>
            )}
          </DefaultMessage>
        </Box>
      </UploadField>
    </UploadContainer>
  )

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const reorderedItems = reorder(
      images,
      result.source.index,
      result.destination.index
    )

    // @ts-ignore
    onChange(reorderedItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`list`}>
        {/* @ts-ignore */}
        {(provided) => (
          <ImagesGrid
            count={images.length}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {images?.map((value, ind) => (
              <Draggable key={ind} draggableId={`${ind}`} index={ind}>
                {/* @ts-ignore */}
                {(provided) => (
                  <Box
                    display="flex"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <Box
                      display="flex"
                      flex={1}
                      style={{ position: 'relative' }}
                    >
                      {uploadsInProgress[
                        value.metadata.image?.path || value.metadata.previewUrl
                      ] &&
                        isInProgress(
                          uploadsInProgress[
                            value.metadata.image?.path ||
                              value.metadata.previewUrl
                          ]
                        ) && <Loader position="absolute" size={25} />}
                      <Image
                        image={
                          value.metadata.image || value.metadata.previewUrl
                        }
                        imageSize={value.metadata.image ? 'medium' : 'original'}
                        style={{ maxHeight: height }}
                      />
                      <ActionsWrapper>
                        <Box
                          mb={2}
                          onClick={() =>
                            window.open(value.metadata.image?.path, '_blank')
                          }
                        >
                          <FullCalendarView fill="#fff" />
                        </Box>
                        <Box onClick={(e) => handleRemoveItem(e, ind)}>
                          <RemoveIcon fill="#fff" />
                        </Box>
                      </ActionsWrapper>
                    </Box>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ImagesGrid>
        )}
      </Droppable>
      {uploader}
    </DragDropContext>
  )
}
