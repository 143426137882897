import {
  BoxProps,
  IconButton,
  IconButtonProps,
  styled,
} from '@material-ui/core'
import * as React from 'react'
import { Ref, forwardRef } from 'react'

type StyledIconButtonProps = BoxProps &
  IconButtonProps & {
    ml?: string
    p?: string
  }

export const StyledIconButton = styled(IconButton)(
  (props: StyledIconButtonProps) => `
    background: rgb(245, 245, 248);
    padding: ${props.p ? props.p : '0px'};
    margin-left:  ${props.ml || props.ml === '0' ? props.ml : '10px'};
    border-radius: 2px;

    & :hover {
      background-color: transparent;
    }
`
)

type ActionButtonProps = StyledIconButtonProps & {
  icon: React.ReactElement
  onClick: () => void
}

export const ActionButton = forwardRef(function ActionButton(
  props: ActionButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <StyledIconButton ref={ref} {...props}>
      {props.icon}
    </StyledIconButton>
  )
})
