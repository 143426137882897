import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import GradientButton from '../../../../components/ui/Buttons/GradientButton'
import { SimpleButton } from '../../../../components/ui/Buttons/SimpleButton'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { AuthMenuActions } from '../../../../types/Auth'
import { useAuthData } from '../../../../utils/providers/AuthProvider'

interface OfferToLoginModalProps {
  onLogin: () => void
  onLoginSuccess: () => void
  onSignup: () => void
}

export const OfferToLoginModal: React.FC<OfferToLoginModalProps> = ({
  onLogin,
  onLoginSuccess,
  onSignup,
}) => {
  const { authModalControl } = useAuthData()
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  return (
    <Box maxWidth={460}>
      <Box my={5} mx={4}>
        <Typography fontWeight={500} variant="body1">
          {t(
            'auth.logIn.continuePlanning',
            'To continue with your booking, please log in to an existing account, or create a new one.'
          )}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-around"
        position={isMobile ? 'absolute' : 'static'}
        bottom="42px"
        left="24px"
        right="24px"
      >
        <Box>
          <SimpleButton
            onClick={onSignup}
            style={{ width: isMobile ? '100%' : 200, fontSize: 16 }}
            variant="outlined"
          >
            I&apos;m new here
          </SimpleButton>
        </Box>
        <Box display="flex" justifyContent="center">
          <GradientButton
            style={{ width: isMobile ? '100%' : 200 }}
            onClick={() => {
              onLogin()
              authModalControl.toggleModal({
                step: AuthMenuActions.Signin,
                onLoginSuccess,
              })
            }}
          >
            Log In
          </GradientButton>
        </Box>
      </Box>
    </Box>
  )
}
