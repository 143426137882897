import {
  FormControl,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  styled,
} from '@material-ui/core'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import { RangeInput } from '@material-ui/lab/DateRangePicker/RangeTypes'
import { ParseableDate } from '@material-ui/lab/internal/pickers/constants/prop-types'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import StaticDateRangePicker from '@material-ui/lab/StaticDateRangePicker'
import React, {
  Dispatch,
  forwardRef,
  ReactElement,
  SetStateAction,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { useWindowSize } from '../../../../hooks/useWindowSize'
import {
  convertDateRangeToShortString,
  convertDateToShortString,
  dateHasTime,
} from '../../../../utils/helpers/date'
import CloseIcon from '../../../svg/CloseIcon'
import GradientButton from '../../Buttons/GradientButton'
import { TimePicker } from '../TimePicker'

import {
  DatesField,
  DateRangeContainer,
  FooterContainer,
  ResetButtonContainer,
} from './DateRangePicker.styles'

declare type ParseableDateRange = null | undefined | RangeInput<Date>

interface props {
  value: ParseableDateRange
  timeValue?: [Date, Date]
  calendars?: 1 | 2 | 3
  setValue: (value: RangeInput<Date>) => void
  setTimeValue?: (value: RangeInput<Date>) => void
  reset?: () => void
  renderDateInput?: (value: string) => ReactElement
  className?: string
  endAdornment?: React.ReactNode
  isDatePickerOpened?: boolean
  closeButton?: boolean
  isCleanable?: boolean
  setIsDatePickerOpened?: Dispatch<SetStateAction<boolean>>
  startDate?: Date | null
}

const EmptyDateRange: RangeInput<Date> = [null, null]

const DateRangePicker = forwardRef((props: props, ref) => {
  const { isMobile } = useWindowSize()
  const {
    renderDateInput,
    value,
    calendars,
    setValue,
    endAdornment,
    setTimeValue,
    timeValue,
    isDatePickerOpened,
    setIsDatePickerOpened,
    isCleanable,
  } = props
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [showTime, setShowTime] = useState(
    !!setTimeValue && !!dateHasTime(value?.[1])
  )
  const [dateRange, setDateRange] = useState<any>(value)
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [startTime, setStartTime] = useState<Date | null>(null)
  const datePickerContainerRef = useRef(null)

  useImperativeHandle(ref, () => ({
    onClose: handleClose,
    onOpen: () => setAnchorEl(datePickerContainerRef.current),
  }))

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
    if (setIsDatePickerOpened) {
      setIsDatePickerOpened(!isDatePickerOpened)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setValue(dateRange)
    if (setIsDatePickerOpened) {
      setIsDatePickerOpened(false)
    }
  }

  const handleReset = () => {
    handleOnChangeTime([null, null])
  }

  const getValueSafely = (): RangeInput<Date> => {
    if (value && typeof value !== 'string') {
      return value as RangeInput<Date>
    }

    return EmptyDateRange
  }

  const getHumanReadableString = () => {
    const value = getValueSafely()

    return convertDateRangeToShortString(value)
  }

  const handleOnChangeDate = (dates: RangeInput<Date>) => {
    let start = dates[0] as Date
    let end = dates[1] as Date
    if (endTime && end) {
      end = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate(),
        endTime.getHours(),
        endTime.getMinutes(),
        endTime.getSeconds()
      )
    }
    if (startTime && start) {
      start = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
        startTime.getSeconds()
      )
    }
    if (start) {
      setValue([start, null])
    }

    if (end) {
      setValue([start, end])
    }
  }

  const handleOnChangeTime = (dates: RangeInput<Date>) => {
    if (setTimeValue) {
      const start = dates[0] as Date
      const end = dates[1] as Date
      setEndTime(end)
      setStartTime(start)

      setTimeValue([start, end])
    }
  }

  const getDateString = (dateValue?: ParseableDate<Date>) => {
    const date = dateValue || new Date()
    const shortDate = convertDateToShortString(date, true)
    const parts = shortDate.split(' ')

    if (!dateValue) {
      parts[1] = '__'
    }
    return (
      <Typography
        variant="body2"
        fontSize={12}
        color={showTime ? '#000' : '#9396A3'}
        style={{ textTransform: 'uppercase' }}
      >
        {parts[0]}{' '}
        <span
          style={{
            color: showTime ? '#2F54EB' : '#9396A3',
            fontSize: 24,
            fontFamily: 'Poppins',
          }}
        >
          {parts[1]}
        </span>{' '}
        {parts[2]}
      </Typography>
    )
  }

  const onSave = () => {
    setAnchorEl(null)
    setDateRange(value)
    if (setIsDatePickerOpened) {
      setIsDatePickerOpened(false)
    }
  }

  return (
    <>
      <FormControl
        ref={datePickerContainerRef}
        className={props.className}
        onClick={handleClick}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {renderDateInput ? (
            renderDateInput(getHumanReadableString())
          ) : (
            <DatesField
              value={getHumanReadableString()}
              placeholder={'Dates'}
              InputProps={{
                readOnly: true,
                endAdornment,
              }}
              InputLabelProps={{ shrink: !!getHumanReadableString() }}
            />
          )}
        </LocalizationProvider>
      </FormControl>
      <DateRangeContainer
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          style={{ background: 'white' }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDateRangePicker
              className="date-range-calendar"
              calendars={calendars}
              value={getValueSafely()}
              onChange={handleOnChangeDate}
              disablePast
              displayStaticWrapperAs="desktop"
              renderInput={() => {
                return (
                  <DatesField
                    value={getHumanReadableString()}
                    label={'Dates'}
                    disabled
                    placeholder={'Add dates'}
                  />
                )
              }}
            />
          </LocalizationProvider>
          {setTimeValue && (
            <Box
              display="flex"
              flexDirection="column"
              pr={isMobile ? '24px' : 5}
              pb={4}
              pl={isMobile ? '24px' : 5}
              pt={2}
              style={{ borderLeft: '1px solid #F7F7F8' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showTime}
                    onChange={() => setShowTime(!showTime)}
                  />
                }
                label={
                  <Typography
                    sx={{ '&:hover': { opacity: 0.8 } }}
                    color="textPrimary"
                    variant="body2"
                  >
                    Set time
                  </Typography>
                }
              />
              <Typography
                variant="body2"
                fontSize={12}
                color="#9396A3"
                style={{ marginTop: 10, textTransform: 'uppercase' }}
              >
                From
              </Typography>
              <Box>{getDateString(value?.[0])}</Box>
              <TimePicker
                disabled={!showTime}
                time={timeValue?.[0] || null}
                onTimeChange={(startTime) =>
                  handleOnChangeTime([startTime, timeValue?.[1]])
                }
              />
              <Typography
                variant="body2"
                fontSize={12}
                color="#9396A3"
                style={{ marginTop: 15, textTransform: 'uppercase' }}
              >
                to
              </Typography>
              <Box>{getDateString(value?.[1])}</Box>
              <TimePicker
                disabled={!showTime}
                time={timeValue?.[1] || null}
                onTimeChange={(endTime) =>
                  handleOnChangeTime([timeValue?.[0], endTime])
                }
              />
            </Box>
          )}
        </Box>
        <FooterContainer>
          <ResetButtonContainer onClick={handleReset}>
            <CloseIcon />
            <Typography
              sx={{ '&:hover': { opacity: 0.8 } }}
              variant="caption"
              fontWeight={500}
            >
              Reset Dates
            </Typography>
          </ResetButtonContainer>
          <GradientButton
            disabled={
              isCleanable
                ? Boolean(
                    !isCleanable || (value && !value[0]) || (value && !value[1])
                  )
                : !value || (value && !value[0]) || (value && !value[1])
            }
            style={{ padding: '4px 20px', height: isMobile ? '50px' : 'auto' }}
            onClick={onSave}
          >
            Submit
          </GradientButton>
        </FooterContainer>
      </DateRangeContainer>
    </>
  )
})

DateRangePicker.displayName = 'DateRangePicker'

export default DateRangePicker
