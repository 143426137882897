/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Select, SelectProps, styled } from '@material-ui/core'

export type FormSelectProps = SelectProps

export const FormSelect = styled(Select)(css`
  & .MuiOutlinedInput-notchedOutline {
    border-color: #d3d3d3;
  }
`)
