import * as yup from 'yup'

import { t } from '../../../../../utils/i18n'

export const createRoomsValidationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(t('business.rooms.roomName.required', 'Name is required')),
    capacity: yup
      .number()
      .min(1)
      .max(50000)
      .required(
        t('business.rooms.maxCapacity.required', 'Capacity is required')
      ),
    floors: yup
      .number()
      .min(1)
      .max(255)
      .required(
        t('business.rooms.floorNumber.required', 'Floor number is required')
      ),
    space: yup
      .number()
      .min(1)
      .max(4294967295)
      .required(
        t(
          'business.rooms.totalSquare.required',
          'Total square footage is required'
        )
      ),
    details: yup.string().trim().max(65535).nullable(true),
    price: yup.string().trim().when('display_pricing', {
      is: true,
      then: yup.string().trim().required(),
      otherwise: yup.string().optional(),
    }),
    short_pricing_details: yup.string().trim().when('display_pricing', {
      is: true,
      then: yup.string().trim().required(),
      otherwise: yup.string().optional(),
    }),
    pricing_details: yup.string().trim().max(500).nullable(true),
    display_pricing: yup.boolean(),
  })
