import {
  AppBar,
  Box,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { MEDIA_RULES } from 'hooks/useWindowSize'

export const HeaderBar = styled(Box)(`
  flex-grow: 1;

  ${MEDIA_RULES.SMALL} {
    z-index: 100;
    left: 0;
    right: 0;
    bottom: auto;
    top: 0;
    transition: transform 100ms ease-out, position 100ms ease-out;

    .MuiIconButton-root {
      padding: 0;
    }
  }
`)

export const StyledAppBar = styled(AppBar)<{ isAbs?: boolean }>(
  (props) => `
  background-color: ${props.theme.palette.secondary.dark};
  color: ${props.theme.palette.text};
  box-shadow: none;
  position: static;
  padding-right: 0 !important;
    
  .my-event-picker {
      font-size: 14px;
      .MuiInputAdornment-positionEnd {
          color: ${props.theme.palette.common.white}
      }
      path {
        fill: #ffff;
      }
  }
    
  .contrast-text {
      color: ${props.theme.palette.common.white}
  }
      
  .MuiToolbar-root {
    min-height: 100px;
    padding: 0
  }

  .logo-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
  }
  
  .search { 
      background: ${props.theme.palette.secondary.dark};
      padding: 15px;
      font-size: 14px;
      line-height: 21px;
      max-width: 250px;
  }
  
  .search input::placeholder {
      opacity: 1;
   }
      
  .header-button {
      display: flex;
      align-items: end;
      text-transform: none;
      .MuiTypography-root {
         color: ${props.theme.palette.secondary.main};
      }
      
      &.disabled {
         cursor: not-allowed;
         .MuiTypography-root {
           color: #9396a3;
         }
      }
      &.active {
         .MuiTypography-root {
           color: ${
             props.isAbs
               ? props.theme.palette.common.linkLight
               : props.theme.palette.primary.light
           };
         }
      }        
  }`
)

export const StyledVersion = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  color: #adadad;
`

export const MyEventsInput = styled(OutlinedInput)`
  background: #84848436;
  color: white;
  cursor: pointer;
  margin-right: 30px;
  margin-left: 30px;
  padding: 10px 16px;
  width: 224px;

  ${MEDIA_RULES.SMALL} {
    margin-left: 15px;
    margin-right: 8px;
    padding: 12px;
    width: 180px;
  }

  &:hover {
    opacity: 0.8;
  }

  input {
    cursor: pointer;
    padding: 0;
  }

  input::placeholder {
    opacity: 1;
  }

  fieldset {
    border: none;
  }

  .MuiInputAdornment-root {
    height: 32px;
    max-height: 32px;

    ${MEDIA_RULES.SMALL} {
      height: 24px;
    }
  }
`

export const PopoverWrapper = styled(Paper)`
  padding: 24px;
  min-width: 400px;
  background: white;
  max-height: 400px;
  overflow: auto;
  position: relative;
  z-index: 1;

  ${MEDIA_RULES.SMALL} {
    min-width: auto;
    width: 100%;
  }

  .my-events-link {
    color: #2f54eb;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
`

export const MyEventsTitle = styled(Box)`
  text-align: center;
  margin-bottom: 24px;

  .MuiTypography-subtitle1 {
    font-size: 18px;
  }
`

export const NameField = styled(TextField)`
  border: none;

  input {
    font-size: 16px;
    color: white;
    height: 28px;
    padding: 0;
  }

  & fieldset {
    border: none;
  }
`

export const EventCardWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: 0 4px 18px rgba(122, 120, 120, 0.15);
  margin-bottom: 16px;
  cursor: pointer;

  position: relative;

  ${(p: { active?: boolean }) => p.active && `background: #2F54EB;`}
  &:hover {
    background: #4e4bec1c;
    ${(p: { active?: boolean }) => p.active && `background: #2F54EB;`}
  }

  .MuiTypography-root {
    ${(p: { active?: boolean }) => p.active && `color: #FFF;`}
  }

  .info {
    width: 80%;
    min-height: 28px;
    display: flex;
    align-items: center;

    .MuiTypography-subtitle1 {
      margin-right: 15px;
    }
  }

  .checker-wrapper {
    min-height: 24px;
    min-width: 24px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-right: 20px;
  }

  .check-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

export const NewEventCardWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  padding: 22px;
  color: #9396a3;
  box-shadow: 0 4px 18px rgb(122 120 120 / 15%);
  margin-bottom: 25px;
`

export const EventCardContent = styled(Box)`
  border-radius: 0;
  display: flex;
  align-items: center;

  .event-img {
    cursor: pointer;
    background: #2f54eb;
    margin: 10px 15px;
    width: 80px;
    height: 80px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    object-fit: cover;
  }
`
export const Pointer = styled('div')`
  left: 48%;
  top: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
  border-top: 20px solid transparent;
  transform: rotate(25deg);
`

export const NotificationIndicator = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #f16547;
  position: absolute;
  border: 1.5px solid #20202c;
`
