import { styled, Tabs } from '@material-ui/core'

import { poppinsFontFamily } from '../../../../theme/createTypography'

export const StyledTabs = styled(Tabs)`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d7d7d7;
  min-height: 0;

  .MuiTabs-scroller {
    flex: inherit;
    width: fit-content;
  }

  .MuiTabs-indicator {
    background-color: black;
  }

  a {
    min-height: 0;
    color: #9396a3;
    font-weight: 600;
    font-size: 12px;
    font-family: ${poppinsFontFamily};

    &.Mui-selected {
      color: black;
    }
  }
`
