import React from 'react'

import { InquiryContractResponse } from '../../../../../types/dtos/inquiry'

import { ContractAddendumRow } from './ContractAddendumRow'
import { ContractInfoRow } from './ContractInfoRow'

interface ContractRowsProps {
  contract: InquiryContractResponse
  files?: any[]
  number: number
}

export const ContractRows: React.FC<ContractRowsProps> = ({
  contract,
  files,
  number,
}) => {
  return (
    <>
      <ContractInfoRow
        withSecondRow={
          !!files?.find((file) => file.contract_id === contract.id)
        }
        contract={contract}
        number={number}
      />
      <ContractAddendumRow
        addendum={files?.find((file) => file.contract_id === contract.id)}
      />
    </>
  )
}
