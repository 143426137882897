import React from 'react'
import { useTranslation } from 'react-i18next'

import { CollapsibleDocumentTable } from '../../../../../components/ui/CollapsibleDocumentTable'
import { useInvoicesBundlesByStatuses } from '../../../../../hooks/useInvoicesBunslesByStatuses'
import { InquiryInvoiceResponse } from '../../../../../types/dtos/inquiry'

import { InvoicesBundleTable } from './InvoicesBundleTable'

interface InvoicesBundlesTablesProps {
  invoicesBundles: InquiryInvoiceResponse[]
  onEdit: () => void
  onSend: (invoiceBundleId: number) => void
}

export const InvoicesBundlesTables: React.FC<InvoicesBundlesTablesProps> = ({
  invoicesBundles,
  onEdit,
  onSend,
}) => {
  const { outdatedInvoicesBundles, notOutdatedInvoicesBundles } =
    useInvoicesBundlesByStatuses(invoicesBundles)
  const { t } = useTranslation()

  return (
    <>
      {notOutdatedInvoicesBundles?.length &&
        notOutdatedInvoicesBundles.map((bundle) => (
          <InvoicesBundleTable
            key={bundle.id}
            invoicesBundle={bundle}
            onEdit={onEdit}
            onSend={onSend}
          />
        ))}
      {!!outdatedInvoicesBundles?.length && (
        <CollapsibleDocumentTable
          colSpan={4}
          title={t('inquiry.versions.previous.show', 'Show old versions')}
        >
          {outdatedInvoicesBundles.map((bundle) => (
            <InvoicesBundleTable
              key={bundle.id}
              invoicesBundle={bundle}
              onEdit={onEdit}
              onSend={onSend}
            />
          ))}
        </CollapsibleDocumentTable>
      )}
    </>
  )
}
