/* eslint-disable no-undef */
/* global google */
import {
  Box,
  ClickAwayListener,
  Divider,
  styled,
  Typography,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { FormikErrors } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

import Location from '../../../svg/Location'

declare const google: any

export interface GoogleSelectProps {
  value?: GooglePlace
  onChange: (value: GooglePlace) => void
}

export interface GooglePlace {
  place_id: string
  description: string
}

const MenuContainer = styled(Box)`
  z-index: 2;
  padding: 24px;
  position: absolute;
  right: 0;
  left: 0;
  top: 110%;
  background: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
`

const StyledMenuItem = styled(MenuItem)`
  border-radius: 1px;
  padding-left: 0px;
  width: 100%;
  color: black;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black;
  }

  &:hover {
    background-color: #ceced4;
  }
`

export const StyledTextField = styled(TextField)`
  background-color: transparent;
  min-width: 100px;
  display: flex;
  align-content: baseline;
  font-size: 14px;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    border-radius: 2px;
    background: #f5f5f8;
    width: 100%;
  }

  .locationField {
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiDivider-root {
      background: black;
      width: 1px;
      height: 35px;
      margin-right: 10px;
      margin-left: 5px;
    }
  }

  .inputField {
    background: white;
  }
`

interface AddressInputProps {
  error?: boolean
  helperText?: string | FormikErrors<GooglePlace>
  label?: string
  placeholder?: string
  name?: string
  withAdornment?: boolean
  FieldComponent?: typeof StyledTextField
  initialLocation?: string
}

export const geocodeLocation = (
  lat: string | number,
  lng: string | number,
  callback: (data: any) => void
) => {
  if (typeof google !== 'undefined') {
    const geocoder = new google.maps.Geocoder()
    const latlng = new google.maps.LatLng(lat, lng)

    geocoder.geocode(
      {
        latLng: latlng,
      },
      function (results: any, status: any) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            callback(results[1])
          } else {
            console.warn('No results found')
          }
        } else {
          console.warn('Geocoder failed due to: ' + status)
        }
      }
    )
  }

  return null
}

export const GoogleLocationSelect: React.FC<
  GoogleSelectProps & AddressInputProps
> = ({
  FieldComponent,
  helperText,
  error,
  withAdornment = true,
  label,
  name,
  placeholder = 'Neighborhoods, address or name',
  onChange,
  value,
  initialLocation,
}) => {
  const {
    placePredictions,
    getPlacePredictions,
    placesService,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    language: 'en',
  })
  let googleScriptChecker: ReturnType<typeof setInterval> | null = null
  const [touched, setTouched] = useState(false)
  const [search, setSearch] = useState(value?.description || '')
  const [isGoogleInit, setIsGoogleInit] = useState(false)
  const isOpen = placePredictions.length > 0 && touched

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched(true)
    }
    getPlacePredictions({ input: event.target.value })
    setSearch(event.target.value)
  }

  const handleSelect = (place: GooglePlace) => {
    onChange(place)
    setSearch(place.description)
    setTouched(false)
  }

  const Field = FieldComponent || StyledTextField

  const parseLocationByGoogleApi = () => {
    if (initialLocation) {
      const coords = initialLocation.split(',')
      geocodeLocation(coords[0], coords[1], (result) => {
        const place: GooglePlace = {
          description: result.formatted_address,
          place_id: result.place_id,
        }
        onChange(place)
        setSearch(place.description)
      })
    }
    !!googleScriptChecker && clearInterval(googleScriptChecker)
  }

  useEffect(() => {
    googleScriptChecker = setInterval(() => {
      if (typeof google !== 'undefined' && !isGoogleInit) {
        parseLocationByGoogleApi()
      }
    }, 1000)
  }, [])

  const StartAdornment = () => {
    return (
      <Box display="flex" alignItems="center">
        <Location />
        <Box ml={2}>
          <Typography>NY</Typography>
        </Box>
        <Divider
          variant="middle"
          orientation="vertical"
          style={{ borderRightWidth: 'inherit' }}
        />
      </Box>
    )
  }
  return (
    <FormControl
      margin="dense"
      sx={{ mt: 0, width: '100%', position: 'relative' }}
    >
      {label && (
        <Typography
          color="#BDBDBD"
          textAlign="left"
          variant="body2"
          sx={{ mb: 2 }}
        >
          {label}
        </Typography>
      )}
      <Field
        placeholder={placeholder}
        value={search}
        name={name}
        error={error}
        helperText={helperText}
        onChange={handleChange}
        inputProps={{
          autoComplete: 'off',
        }}
        InputProps={{
          startAdornment: withAdornment ? <StartAdornment /> : null,
          classes: {
            root: withAdornment ? 'locationField' : 'inputField',
          },
        }}
      />
      {isOpen && (
        <ClickAwayListener
          onClickAway={() => {
            setSearch(value?.description || '')
            setTouched(false)
          }}
        >
          <MenuContainer>
            <Typography variant="body2" color="#9396A3">
              Chose address
            </Typography>
            {placePredictions.map((place) => (
              <StyledMenuItem
                key={place.place_id}
                onClick={() => handleSelect(place)}
              >
                <Typography>{place.description}</Typography>
              </StyledMenuItem>
            ))}
          </MenuContainer>
        </ClickAwayListener>
      )}
    </FormControl>
  )
}
