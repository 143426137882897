import * as React from 'react'

import { ErrorCrash } from './ErrorCrash'

interface MaintenanceProps {
  children: React.ReactNode
}

interface MaintenanceState {
  hasError: boolean
}

export class Maintenance extends React.Component<any> {
  state: MaintenanceState = { hasError: false }

  componentDidCatch(): void {
    this.setState({ hasError: true })
  }

  render(): React.ReactNode {
    const { hasError } = this.state
    const { children } = this.props

    return <ErrorCrash />
  }
}
