import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { EditButton } from '../../../../../../components/ui/Buttons/EditButton'
import { isServiceVendor } from '../../../../../../utils/helpers/vendors'
import { BlockTitle } from '../../../BusinessDetails.styles'
import { LabelsSelect } from '../../Details/components/LabelsSelect'

export const GeneralInfoPreview: React.FC<{
  entity?: any & { name: string; description: string }
  onEdit: () => void
}> = ({ entity, onEdit }) => {
  const { t } = useTranslation()
  const labels = entity?.labels || []
  const labelsControl = React.useState(labels)

  const specialFeatures = labels.length
    ? labels.filter((label: any) => label.type === 'special features')
    : []

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <BlockTitle>
          {t('dashboard.business.description.title', 'Business Description')}
        </BlockTitle>
        <Box>
          <EditButton onClick={onEdit} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box display="flex" flexDirection="column">
          <Typography sx={{ mb: 3 }} color="#9396A3" variant="body2">
            {t('dashboard.business.description.description', 'Description')}
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
            {entity?.description || '-'}
          </Typography>
        </Box>
      </Box>
      {entity ? (
        <Box display="flex" mt={5}>
          {isServiceVendor(entity) && (
            <LabelsSelect
              previewMode={true}
              labels={specialFeatures}
              entity={entity}
              labelsControl={labelsControl}
              attachLabelMethod={() => console.log('preview ')}
              type="special features"
            />
          )}
        </Box>
      ) : null}
    </>
  )
}
