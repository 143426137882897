const Phone = () => {
  return (
    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.838 2.597 11.172.934a1.262 1.262 0 0 0-1.792 0l-1.79 1.791a1.263 1.263 0 0 0 0 1.792L8.9 5.827A6.153 6.153 0 0 1 7.642 7.64a6.177 6.177 0 0 1-1.814 1.262l-1.31-1.31a1.263 1.263 0 0 0-1.791 0L.934 9.384a1.264 1.264 0 0 0 0 1.794l1.661 1.66a2.054 2.054 0 0 0 1.78.572c2.106-.347 4.195-1.467 5.881-3.151 1.685-1.683 2.803-3.77 3.155-5.883a2.056 2.056 0 0 0-.573-1.778Z"
        fill="#262626"
      />
    </svg>
  )
}

export default Phone
