import React from 'react'

import {
  ConfirmModal,
  ConfirmModalProps,
} from '../../components/ui/Modals/ConfirmModal'

export const MessageModalContext = React.createContext<{
  showMessage: (confirmData: Partial<ConfirmModalProps>) => void
  closeMessageDialog: () => void
  toggleModal: () => void
}>({
  showMessage: () => {},
  closeMessageDialog: () => {},
  toggleModal: () => {},
})

export const MessageModalProvider: React.FC = ({ children }) => {
  const [isOpen, setOpen] = React.useState(false)
  const [modalData, setExtraData] = React.useState<any>(null)

  function toggleModal(extra: any = null) {
    setExtraData(extra)
    setOpen(!isOpen)
  }

  const showMessage = (confirmData: Partial<ConfirmModalProps>) => {
    toggleModal(confirmData)
  }

  const closeMessageDialog = () => {
    setOpen(false)
  }

  const context = React.useMemo(
    () => ({
      showMessage,
      closeMessageDialog,
      toggleModal,
    }),
    [showMessage, closeMessageDialog, toggleModal, modalData]
  )

  return (
    <MessageModalContext.Provider value={context}>
      {children}
      <ConfirmModal
        style={modalData?.style}
        open={isOpen && !!modalData?.title}
        onAccept={modalData?.onAccept}
        onClose={() => {
          if (modalData?.onClose) {
            modalData.onClose()
          }
          toggleModal()
        }}
        title={modalData?.title}
        text={modalData?.text}
        type={modalData?.type || 'success'}
        buttons={modalData?.buttons}
      />
    </MessageModalContext.Provider>
  )
}

export const useMessageModalData = () => {
  const context = React.useContext(MessageModalContext)
  if (context === undefined) {
    throw new Error(
      'useMessageModalData must be used within an MessageModalContext'
    )
  }
  return context
}
