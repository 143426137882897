import { Box, Fade, Popper, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { FieldArray } from 'formik'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ArrowDown from '../../../../../../components/svg/ArrowDown'
import ArrowUp from '../../../../../../components/svg/ArrowUp'
import { EMPTY_LINE_ITEM } from '../../../../../../constants/business'
import { useEventList } from '../../../../../../hooks/useEventList'
import { useInquiryDetailsData } from '../../../../../../utils/providers/InqueryDetailsProvider'
import { GeneralEventInfo } from '../../../../BookingDetails/components/GeneralEventInfo'

import { EventInfoToggleButton, StyledPaper } from './LineItems.styles'
import { LineItemsTable } from './LineItemsTable'

export const LineItems: React.FC<{ shouldBlockRental?: boolean }> = ({
  shouldBlockRental = true,
}) => {
  const { t } = useTranslation()
  const { inquiryDetails } = useInquiryDetailsData()
  const { eventsList } = useEventList()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const vendorInquiryEventVenue = useMemo(() => {
    if (inquiryDetails?.venue_id) return
    return eventsList?.find((event) => event.id === inquiryDetails?.event_id)
      ?.venues?.[0]
  }, [eventsList, inquiryDetails?.event_id])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(!isOpen)
  }

  return (
    <Box>
      <Box pb="24px" display="flex" width={1} justifyContent="space-between">
        <Typography variant="body1" fontWeight={600}>
          {t('business.lineItems.title2', 'Line items')}
        </Typography>
        <Box>
          <EventInfoToggleButton
            disableRipple
            isOpen={isOpen}
            endIcon={isOpen ? <ArrowUp fill="#000" /> : <ArrowDown />}
            onClick={handleClick}
          >
            <Typography fontSize={18} fontWeight={600} flex="1">
              {t('business.event.info.heading', 'Event Info')}
            </Typography>
          </EventInfoToggleButton>
          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            placement="bottom"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <StyledPaper>
                  <GeneralEventInfo
                    inquiryDetails={inquiryDetails}
                    vendorInquiryEventVenue={vendorInquiryEventVenue}
                    withMessage
                  />
                </StyledPaper>
              </Fade>
            )}
          </Popper>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" mx="-24px">
        <FieldArray
          name="lineItems"
          render={(arrayHelpers) => (
            <>
              <LineItemsTable
                arrayHelpers={arrayHelpers}
                shouldBlockRental={shouldBlockRental}
              />
              <Box
                px="24px"
                sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                display="flex"
                width={1}
                justifyContent="flex-end"
                alignItems="center"
                onClick={() => {
                  arrayHelpers.push(EMPTY_LINE_ITEM)
                }}
              >
                <Add style={{ color: '#2F54EB', marginRight: 5 }} />
                <Box>
                  <Typography color="#2F54EB" variant="body2">
                    {t('business.lineItems.add5', 'Add more')}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        />
      </Box>
    </Box>
  )
}
