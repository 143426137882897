import { Slider, SliderProps, styled } from '@material-ui/core'
import { FastField, FieldProps } from 'formik'
import * as React from 'react'

const StyledSlider = styled(Slider)`
  margin-left: 5px;
  margin-right: 40px;
  .MuiSlider-track {
    color: #2f54eb;
  }

  .MuiSlider-rail {
    color: #d7d7d7;
  }

  .MuiSlider-thumb {
    background-color: #ffffff;
    border: 2px solid #2f54eb;
  }

  .MuiSlider-valueLabelOpen {
    background-color: #ffffff;
    margin-right: 30px;
    border-radius: 2px;
    color: black;
    box-shadow: 0 4px 18px rgba(210, 210, 210, 0.4);
  }
`

export const SliderField: React.FC<SliderProps> = ({ name, ...props }) => {
  return (
    <FastField name={name}>
      {({ field, form: { setFieldValue } }: FieldProps) => {
        const handleChange = (event: Event, newValue: number | number[]) => {
          setFieldValue(field.name, newValue as number[])
        }
        return (
          <StyledSlider
            step={100}
            valueLabelFormat={(label) => (label === 100000 ? '100000+' : label)}
            size="small"
            min={0}
            max={100000}
            value={field.value}
            onChange={handleChange}
            valueLabelDisplay="on"
          />
        )
      }}
    </FastField>
  )
}
