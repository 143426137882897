import { Box, styled, Typography } from '@material-ui/core'
import React from 'react'

import { Loader } from '../../../../../components/ui/Loader'
import { SimpleModal } from '../../../../../components/ui/Modals/SimpleModal'
import { StyledAdd } from '../../../../../components/ui/TableComponent/TableComponent.styles'
import { useSimpleModal } from '../../../../../hooks/useSimpleModal'
import { getEmptyImageAsset } from '../../../../../types/FileUploadHandler'
import { Room } from '../../../../../types/Venue'
import {
  deleteRoom,
  useRoomsUpdate,
  useVenueRooms,
} from '../../../../../utils/api/venues'
import { currencyConvertToDecimal } from '../../../../../utils/helpers/price'
import { getRequestError } from '../../../../../utils/helpers/validations'
import { useMessageModalData } from '../../../../../utils/providers/MessageModalProvider'
import { BlockTitle } from '../../BusinessDetails.styles'

import { RoomItem } from './RoomItem'
import { RoomsForm } from './RoomsForm'

export const SectionHeader = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`

const RoomsContainer = styled(Box)`
  padding: 25px;
  min-width: 250px;
  overflow: auto;
  box-shadow: 0 4px 18px rgba(210, 210, 210, 0.4);
  position: relative;

  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: #d7d7d7;
  }
`

export interface RoomsFormValues {
  name: string
  floors?: number
  capacity?: number
  space?: number
  images?: Array<any>
  details?: string
  price?: string | number
  short_pricing_details?: string
  pricing_details?: string
  display_pricing: boolean | number
}

const initialValues: RoomsFormValues = {
  name: '',
  floors: undefined,
  capacity: undefined,
  space: undefined,
  details: '',
  price: '',
  short_pricing_details: '',
  pricing_details: '',
  display_pricing: false,
}

export const Rooms: React.FC<{ venueId: number }> = ({ venueId }) => {
  const [editingRoomId, setEditingRoomId] = React.useState<number | null>(null)
  const [initialRoomValues, setInitialRoomValues] =
    React.useState(initialValues)
  const [photos, setPhotos] = React.useState<any[] | undefined>(undefined)
  const { data, isLoading: isRoomsLoading } = useVenueRooms(venueId)
  const [rooms, setRooms] = React.useState<Room[]>([])
  const { isOpen, toggleModal } = useSimpleModal()
  const { showMessage } = useMessageModalData()
  const roomsMutation = useRoomsUpdate()

  const onEditRoom = (room: Room) => {
    setEditingRoomId(room.id)
    if (room.images?.length) {
      setPhotos(room.images.map(getEmptyImageAsset))
    }
    toggleModal({ room })
    setInitialRoomValues({
      name: room.name,
      floors: room.floors,
      space: room.space,
      capacity: room.capacity,
      details: room.details,
      price: room.price ? `${room.price}` : '',
      short_pricing_details: room.short_pricing_details || '',
      pricing_details: room.pricing_details || '',
      display_pricing: room.display_pricing || false,
    })
  }

  const onDeleteRoom = async (roomId: number) => {
    const res = await deleteRoom(venueId, roomId)
    if (res.id) {
      setRooms(rooms.filter((room: Room) => room.id !== roomId))
    }
  }

  const onSubmit = React.useCallback(
    async (values: RoomsFormValues, { setSubmitting, resetForm }) => {
      const {
        name,
        floors,
        capacity,
        space,
        details,
        display_pricing,
        pricing_details,
      } = values
      const roomData: RoomsFormValues = {
        name,
        floors,
        capacity,
        space,
        details: details || '',
        display_pricing: +display_pricing,
        pricing_details: pricing_details || '',
      }

      if (
        values.display_pricing &&
        values.price &&
        values.short_pricing_details
      ) {
        roomData.price = currencyConvertToDecimal(values.price)
        roomData.short_pricing_details = values.short_pricing_details
      }

      if (photos) {
        roomData.images = photos.map((photo, index) => {
          if (photo.metadata.imageId) {
            return { id: photo.metadata.imageId, order: index + 1 }
          }

          return { file: photo.metadata.fileBlob, order: index + 1 }
        })
      }

      try {
        const response = await roomsMutation.mutateAsync({
          venueId,
          data: roomData,
          roomId: editingRoomId,
        })
        const roomImage = response.images?.find((img) => img.order === 1)
        const newRoom = { ...response, image: roomImage }
        if (editingRoomId) {
          const updatedRooms = [...rooms]
          const roomIndex = rooms.findIndex((item) => item.id === newRoom.id)

          updatedRooms[roomIndex] = newRoom

          setRooms(updatedRooms)
          setEditingRoomId(null)
          setPhotos(undefined)
          setInitialRoomValues(initialValues)
        } else {
          setRooms([newRoom, ...rooms])
        }
        toggleModal()
        resetForm()
      } catch (e) {
        setSubmitting(false)
        showMessage({
          title: getRequestError(e),
          type: 'error',
        })
      } finally {
        console.log('room saved')
      }
    },
    [venueId, editingRoomId, rooms, photos]
  )

  const onAddNewRoom = () => {
    setEditingRoomId(null)
    setPhotos(undefined)
    setInitialRoomValues(initialValues)
    toggleModal()
  }

  React.useEffect(() => {
    if (data && data.length) {
      setRooms(data)
    }
  }, [data])

  return (
    <RoomsContainer
      mb={5}
      height={1}
      display="flex"
      flexDirection="column"
      flex={1}
      ml={5}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <BlockTitle>Add Separate Rooms</BlockTitle>
        <StyledAdd color="primary" onClick={onAddNewRoom}>
          + Add
        </StyledAdd>
      </Box>
      {isRoomsLoading ? (
        <Box>
          <Loader position="absolute" />
        </Box>
      ) : rooms && rooms.length ? (
        rooms.map((room: any) => (
          <RoomItem
            room={room}
            key={room.id}
            onEditRoom={onEditRoom}
            onDeleteRoom={onDeleteRoom}
          />
        ))
      ) : null}
      <SimpleModal
        maxWidth={470}
        title={!editingRoomId ? 'Add Room' : 'Edit Room'}
        open={isOpen}
        onClose={toggleModal}
      >
        <RoomsForm
          isEditing={!!editingRoomId}
          onCancel={toggleModal}
          photos={photos}
          setPhotos={setPhotos}
          onSubmit={onSubmit}
          initialValues={initialRoomValues}
        />
      </SimpleModal>
    </RoomsContainer>
  )
}
