import { Typography } from '@material-ui/core'
import * as React from 'react'
import { Trans } from 'react-i18next'
import config from 'utils/config'

interface StageHintProps {
  stage: string
}

export const StageHint: React.FC<StageHintProps> = ({ stage }) => {
  switch (stage) {
    case 'planning':
      return (
        <Typography mt={4} variant="body1" lineHeight="24px">
          <Trans i18nKey="business.stages.planning">
            You can either accept or reject the proposal from the client. You
            can reject if the date is unavailable, the budget is too low, the
            ask is outside of your scope of expertise, etc. If you accept, you
            will move into the proposal stage.
          </Trans>
        </Typography>
      )
    case 'proposing':
      const email = config.venuesEmail

      return (
        <>
          <Typography mt={4} variant="body1" lineHeight="24px">
            <Trans i18nKey="business.stages.proposing_1">
              You’ve accepted this inquiry.
            </Trans>
          </Typography>
          <Typography mt={4} variant="body1" lineHeight="24px">
            <Trans i18nKey="business.stages.proposing_2" values={{ email }}>
              Please connect with the client via your preferred method of
              communication to negotiate pricing and contracting. If you choose
              to reach out to the client via email, please CC
              <a href={`mailto:${email}`}>{email}</a> for assistance and
              tracking purposes.
            </Trans>
          </Typography>
          <Typography mt={4} variant="body1" lineHeight="24px">
            <Trans i18nKey="business.stages.proposing_3" values={{ email }}>
              Once the client is ready to make payment, please send a fully
              executed contract and the agreed-upon invoice(s) to
              <a href={`mailto:${email}`}>{email}</a>. This inquiry will then
              move to the &quot;Invoicing&quot; stage, where you&apos;ll be able
              to upload new contracts and edit or create invoices.
            </Trans>
          </Typography>
        </>
      )
    case 'contracting':
      return (
        <Typography mt={4} variant="body1" lineHeight="24px">
          <Trans i18nKey="business.stages.contracting">
            The client has accepted your proposal and now it is time for you to
            prepare the contract for the event and send it to the client. We ask
            that you send the contract within 5 business days of the proposal
            approval.
          </Trans>
        </Typography>
      )
    case 'payment':
      return (
        <Typography mt={4} variant="body1" lineHeight="24px">
          <Trans i18nKey="business.stages.payment">
            This event is now in Payment. You may to create invoices and give
            payments from client.
          </Trans>
        </Typography>
      )
    case 'production':
      return (
        <Typography mt={4} variant="body1" lineHeight="24px">
          <Trans i18nKey="business.stages.production">
            This event is now in Production. You may continue to message back
            and forth with the client with any questions.
          </Trans>
        </Typography>
      )
    case 'complete':
      return (
        <Typography mt={4} variant="body1" lineHeight="24px">
          <Trans i18nKey="business.stages.complete">
            Your event has ended. If you&apos;ve collected a security deposit,
            this will now be returned to the client.
          </Trans>
        </Typography>
      )
    default:
      return <></>
  }
}
