import { Box, capitalize, styled, Typography } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import { uniq } from 'lodash'
import * as React from 'react'

import { useWindowSize } from '../../../../hooks/useWindowSize'
import { AboutWrapper } from '../Venue.styles'

const StyledTypography = styled(Typography)`
  margin-top: 10px;
  word-wrap: break-word;
  text-align: center;
  ${MEDIA_RULES.SMALL} {
    margin-left: 16px;
    text-align: left;
  }
`

export const VenueLabels: React.FC<{ labels: any[] }> = ({ labels }) => {
  const labelTypes = uniq(labels.map((label) => label.type))
  const { isMobile } = useWindowSize()

  return (
    <AboutWrapper id="amenities">
      {labelTypes.map((type, index) => (
        <Box
          display="flex"
          key={index}
          flexDirection="column"
          className="section-block"
        >
          <Typography variant="h2">{capitalize(type)}</Typography>
          <Box
            display="grid"
            gridTemplateColumns={isMobile ? 'repeat(2, 1fr)' : 'repeat(6, 1fr)'}
            rowGap="20px"
            columnGap="10px"
          >
            {labels
              .filter((label) => label.type === type)
              .map((label) => (
                <Box
                  key={label.id}
                  display="flex"
                  alignItems="center"
                  flexDirection={isMobile ? 'row' : 'column'}
                >
                  <i className={label.icon_class}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                    <span className="path10" />
                  </i>
                  <StyledTypography>{label.name}</StyledTypography>
                </Box>
              ))}
          </Box>
        </Box>
      ))}
    </AboutWrapper>
  )
}
