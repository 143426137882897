import { FormikErrors, FormikProps } from 'formik'
import { anyPass, isNil, isObject, mapValues, omit, prop } from 'lodash/fp'
import { useEffect } from 'react'
import { ZodFormattedError } from 'zod'

import { ZodObjectAny } from './zod'

export const isFieldValid = <V extends {}>(
  errors: FormikErrors<V>,
  path: string
) => anyPass([isNil, isObject])(prop(path, errors))

export const useSyncedField = <V extends {}>(
  form: FormikProps<V>,
  pathFrom: string,
  pathTo: string
) => {
  const { values, setFieldValue } = form
  const value = prop(pathFrom, values)
  const currentValue = prop(pathTo, values)

  useEffect(() => {
    const fromIsValid = value && isFieldValid(form.errors, pathFrom)
    const toIsValid = currentValue && isFieldValid(form.errors, pathTo)
    if (fromIsValid && !toIsValid) {
      setFieldValue(pathTo, value)
    }
  }, [form.errors, pathFrom, pathTo, value])
}

type Errors = Record<string, string | NestedErrors>
interface NestedErrors extends Record<string, Errors> {}

const flattenErrors = <V extends {}>(
  errors: ZodFormattedError<V>
): string | Errors | undefined =>
  prop(['_errors', 'length'], errors) > 0
    ? errors._errors[0]
    : isObject(errors)
    ? (mapValues<object, string | Errors | undefined>(
        flattenErrors,
        omit('_errors', errors)
      ) as Errors)
    : errors

export const validateFromZodSchema =
  <V extends {}, S extends ZodObjectAny<V>>(schema: S) =>
  async (values: V): Promise<FormikErrors<V>> => {
    const result = await schema.safeParseAsync(values)
    if (result.success) return {}
    return mapValues(flattenErrors, result.error.format()) as any
  }

export const shouldDisplayError = <V extends {}>(
  form: FormikProps<V>,
  path: string
): boolean => {
  return (
    Boolean(prop(path, form.touched)) &&
    !isFieldValid(form.errors, path) &&
    prop(path, form.errors) !== ''
  )
}
