import * as React from 'react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { MessageAttachment } from '../../../../types/dtos/messages'
import { ALLOWED_DOCUMENT_FORMATS } from '../../../../types/FileUploader'
import { AssetRefDownload } from '../../../../types/FileUploadHandler'
import { convertFileSizeToReadableSize } from '../../../../utils/helpers/files'
import { useMediaModalData } from '../../../../utils/providers/MediaLibraryProvider'
import { useMessageModalData } from '../../../../utils/providers/MessageModalProvider'

import RichInputField from './RichInputField'

const fileLimit = '15 MB'

export interface RichInputProps {
  value: string | null
  maxLength: number
  setValue: Dispatch<SetStateAction<string | null>>
  attachment?: MessageAttachment | File | null
  setAttachment?: Dispatch<SetStateAction<MessageAttachment | File | null>>
}

export const RichInputFieldContainer: React.FC<RichInputProps> = ({
  value,
  maxLength,
  setValue,
  attachment,
  setAttachment,
}) => {
  const [isFileLimitError, setIsFileLimitError] = useState<boolean>(false)
  const { showMessage } = useMessageModalData()
  const { openMediaModal } = useMediaModalData()

  const addFileHandler = () => {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', `${ALLOWED_DOCUMENT_FORMATS.join()}`)
    input.click()

    input.onchange = async () => {
      const file = input?.files?.[0]
      if (
        file?.size &&
        convertFileSizeToReadableSize(file.size).includes(
          fileLimit.slice(-2)
        ) &&
        +convertFileSizeToReadableSize(file.size).slice(0, -3) >
          +fileLimit.slice(0, -3)
      ) {
        setIsFileLimitError(true)
      } else setAttachment && setAttachment(file || null)
    }
  }

  const addImageHandler = () => {
    openMediaModal({
      initialImages: [],
      isMulti: false,
      onSave: (images: AssetRefDownload<any>[]) => {
        const image = images[0].metadata.fileBlob
          ? images[0].metadata.fileBlob
          : {
              name: images[0].metadata.fileName,
              id: images[0].metadata.imageId,
              type: 'image',
              url: images[0].metadata.image.path,
              image: images[0].metadata.image,
            }
        if (
          image.size &&
          convertFileSizeToReadableSize(image.size).includes(
            fileLimit.slice(-2)
          ) &&
          +convertFileSizeToReadableSize(image.size).slice(0, -3) >
            +fileLimit.slice(0, -3)
        ) {
          setIsFileLimitError(true)
        } else setAttachment && setAttachment(image)
      },
    })
  }

  useEffect(() => {
    if (!isFileLimitError) return
    setIsFileLimitError(false)
    return showMessage({
      title: `Please load file less than ${fileLimit}`,
      type: 'error',
    })
  }, [isFileLimitError])

  return (
    <RichInputField
      value={value}
      maxLength={maxLength}
      setValue={setValue}
      attachment={attachment}
      setAttachment={setAttachment}
      fileLimit={fileLimit}
      setIsFileLimitError={setIsFileLimitError}
      onAddFile={addFileHandler}
      onAddImage={addImageHandler}
    />
  )
}
