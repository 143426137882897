import { Box, Typography } from '@material-ui/core'
import { flatten } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoicesTotals } from '../../../components/InvoicesTotals/InvoicesTotals'
import { useInvoicesBundlesByStatuses } from '../../../hooks/useInvoicesBunslesByStatuses'
import { usePossibleInvoiceGeneration } from '../../../hooks/usePossibleInvoiceGeneration'
import { InquiryWithDetails } from '../../../types/Inquiry'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { InquiryFlowTableWarning } from '../BookingDetails/components/InquiryFlowTableWarning'
import { AddNewInquiryFlowElementButton } from '../components/InquiryFlowTables/AddNewInquiryFlowElementButton'
import { InvoicesBundlesTables } from '../components/InquiryFlowTables/InvoicesTables'

interface BookingInvoicesProps {
  inquiryDetails?: InquiryWithDetails
  onGenerate: () => void
  onSend: (invoiceBundleId: number) => void
}

export const BookingInvoices: React.FC<BookingInvoicesProps> = ({
  inquiryDetails,
  onGenerate,
  onSend,
}) => {
  const { authUser } = useAuthData()
  const { t } = useTranslation()

  const isPossibleInvoicesGeneration =
    usePossibleInvoiceGeneration(inquiryDetails)
  const { notOutdatedInvoicesBundles } = useInvoicesBundlesByStatuses(
    inquiryDetails?.invoice_bundles
  )

  const isExclusiveVenue = useMemo(() => {
    return !!inquiryDetails?.venue?.is_exclusive
  }, [inquiryDetails?.venue?.is_exclusive])

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        mb="24px"
      >
        <Typography variant="h4" fontFamily="Poppins" fontWeight={600}>
          {t('business.invoices.list', 'Invoices List')}
          {!authUser?.payment_info && !isExclusiveVenue && (
            <Typography component="span" fontSize={14} color="#E04E2F">
              &nbsp;{' '}
              {t(
                'business.invoices.connectWarning',
                '*For generating invoices you need to connect Square account in your profile!'
              )}
            </Typography>
          )}
        </Typography>
      </Box>
      <InvoicesTotals
        invoices={flatten(
          notOutdatedInvoicesBundles.map((bundle) => bundle.invoices)
        )}
      />
      {!inquiryDetails?.invoice_bundles?.length ? (
        <InquiryFlowTableWarning
          isGenerateBtnDisabled={!isPossibleInvoicesGeneration}
          onGenerate={onGenerate}
          type="invoice"
        />
      ) : (
        <>
          <InvoicesBundlesTables
            invoicesBundles={inquiryDetails.invoice_bundles}
            onEdit={onGenerate}
            onSend={onSend}
          />
          {isPossibleInvoicesGeneration && (
            <AddNewInquiryFlowElementButton
              onClick={onGenerate}
              btnText={t('business.invoices.addNew', 'Generate new invoice')}
            />
          )}
        </>
      )}
    </>
  )
}
