const DollarBlack = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1a7 7 0 1 0 .001 14.001A7 7 0 0 0 8 1Zm.348 10.394.004.495a.127.127 0 0 1-.125.127h-.444a.125.125 0 0 1-.125-.125V11.4c-1.388-.103-2.04-.894-2.111-1.753a.125.125 0 0 1 .125-.136h.722c.06 0 .114.044.123.103.08.495.466.866 1.158.958v-2.23l-.386-.098c-.817-.196-1.595-.705-1.595-1.761 0-1.14.865-1.752 1.972-1.86v-.515c0-.069.056-.125.125-.125h.439c.068 0 .125.056.125.125v.51c1.07.109 1.873.734 1.967 1.707a.125.125 0 0 1-.125.138h-.702a.125.125 0 0 1-.123-.108c-.063-.457-.428-.828-1.024-.91v2.099l.397.092c1.013.25 1.702.734 1.702 1.819 0 1.176-.875 1.832-2.099 1.939ZM6.666 6.41c0 .397.245.705.773.895.074.03.147.053.234.078V5.447c-.576.073-1.007.397-1.007.964Zm1.82 2.112a2.212 2.212 0 0 1-.138-.034v2.09c.666-.06 1.125-.426 1.125-1.038 0-.48-.248-.793-.987-1.018Z"
        fill="#262626"
      />
    </svg>
  )
}

export default DollarBlack
