import { InquiryStatuses } from 'constants/inquiry'

import { Inquiry } from 'types/Inquiry'

import { build, factory } from './factory'
import { vendorFactory } from './vendor'
import { venueFactory } from './venue'

export const inquiryFactory = () => {
  const vendor = build(vendorFactory())
  const venue = build(venueFactory())

  return factory<Inquiry>({
    id: 1,
    event_id: 1,
    status: InquiryStatuses.STATUS_INQUIRING,
    created_at: new Date('2020-01-01'),
    updated_at: new Date('2020-01-01'),
    user_status: 'new',
    app_fee: 0,
    vendor_id: vendor.id,
    venue_id: venue.id,
    initial_bundle_sent: false,
    user_files: [],
    files: [],
    contracts: [],
    invoice_bundles: [],
    vendor,
    venue,
  })
}
