import { Box, TableCell } from '@material-ui/core'
import React, { Dispatch, SetStateAction } from 'react'
import { useHistory, useLocation } from 'react-router'

import CloseIcon from '../../../components/svg/CloseIcon'
import { EventServiceTab } from '../../../constants/events'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { EventServiceProps } from '../../../types/Event'
import { useInquiryContractsById } from '../../../utils/api/inquiries'
import { useInquiryDetailsData } from '../../../utils/providers/InqueryDetailsProvider'

import { MobileServiceBookingDetails } from './components/MobileServiceBookingDetails'
import { AdditionalInfo } from './components/Tabs/AdditionalInfo'
import { BookingInfo } from './components/Tabs/BookingInfo'
import { Contracts } from './components/Tabs/Contracts'
import { PaymentInvoices } from './components/Tabs/PaymentInvoices'
import {
  ScrollContainer,
  StyledIconButton,
} from './ServiceBookingDetails.styles'

export interface ServiceBookingDetailsProps {
  data: EventServiceProps
  activeTab: EventServiceTab
  setIsRowExpanded: (isExpanded: boolean) => void
  setActiveTab: Dispatch<SetStateAction<EventServiceTab>>
  jotForms: any[]
  fetchEvent: () => void
  onToggleMessenger: () => void
}

export const ServiceBookingDetails: React.FC<ServiceBookingDetailsProps> = ({
  data,
  activeTab,
  setActiveTab,
  setIsRowExpanded,
  jotForms,
  fetchEvent,
  onToggleMessenger,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { isMobile } = useWindowSize()

  const { inquiryDetails, refetch } = useInquiryDetailsData()

  const { data: contracts } = useInquiryContractsById(inquiryDetails?.id || 0, {
    enabled: !!inquiryDetails?.id,
    staleTime: 5000,
  })

  const openMessengerHandler = () => {
    if (!data?.inquiry_id) return

    const paramsWithChat = {
      inquiryId: data?.inquiry_id?.toString(),
      openChat: true.toString(),
    }

    history.push({
      pathname: location.pathname,
      search: new URLSearchParams(paramsWithChat).toString(),
    })
  }

  return !isMobile ? (
    <TableCell colSpan={8}>
      <Box pb={2} sx={{ position: 'relative' }}>
        <StyledIconButton
          sx={{ float: 'right', position: 'absolute', right: 0 }}
          onClick={() => setIsRowExpanded(false)}
        >
          <CloseIcon />
        </StyledIconButton>
      </Box>
      <ScrollContainer height={300}>
        {activeTab === EventServiceTab.BOOKING_INFO && (
          <BookingInfo inquiryDetails={inquiryDetails} event={data} />
        )}
        {activeTab === EventServiceTab.CONTRACTS && (
          <Contracts
            files={inquiryDetails?.files}
            openMessengerHandler={openMessengerHandler}
            contracts={contracts}
            setActiveTab={setActiveTab}
          />
        )}
        {activeTab === EventServiceTab.ADDITIONAL_INFO && (
          <AdditionalInfo
            event={data}
            jotForms={jotForms}
            inquiryDetails={inquiryDetails}
          />
        )}
        {activeTab === EventServiceTab.PAYMENTS && (
          <PaymentInvoices
            fetchEvent={fetchEvent}
            openMessengerHandler={openMessengerHandler}
            refetch={refetch}
            inquiryDetails={inquiryDetails}
          />
        )}
      </ScrollContainer>
    </TableCell>
  ) : (
    <MobileServiceBookingDetails
      jotForms={jotForms}
      inquiryDetails={inquiryDetails}
      openMessengerHandler={openMessengerHandler}
      refetch={refetch}
      contracts={contracts}
      activeTab={activeTab}
      data={data}
      fetchEvent={fetchEvent}
      onToggleMessenger={onToggleMessenger}
      setActiveTab={setActiveTab}
    />
  )
}
