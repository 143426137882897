import { Pagination, PaginationProps, styled } from '@material-ui/core'
import * as React from 'react'

export const StyledPagination = styled(Pagination)(
  ({ theme }) => `
      .MuiPaginationItem-root {
        &:hover {
            opacity: 0.4;
            border-radius: 0;
        }
      }
    .MuiPaginationItem-root.Mui-selected {
     background: #20202C;
     color: white;
     border-radius: 0;
     font-weight: bold;
    }
    .MuiSvgIcon-root {
        font-size: 40px;
    }
`
)

export const PaginationComponent: React.FC<PaginationProps> = ({
  ...props
}) => {
  return props?.count && props?.count > 1 ? (
    <StyledPagination
      {...props}
      siblingCount={3}
      boundaryCount={2}
      showFirstButton
      showLastButton
    />
  ) : null
}
