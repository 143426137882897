import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { FastField, FieldProps } from 'formik'
import * as React from 'react'

export const CheckBoxField: React.FC<{
  name: string
  label: string
  checked?: boolean
}> = ({ name, label, checked = false }) => {
  const onChange = (e: any, setFieldValue: any) => {
    if (checked) {
      setFieldValue(name, '')
    } else if (e.target.checked) {
      setFieldValue(name, name)
    } else {
      setFieldValue(name, '')
    }
  }
  return (
    <FastField name={name}>
      {({ field, form: { setFieldValue } }: FieldProps) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={checked || field.value === field.name}
                onChange={(e) => onChange(e, setFieldValue)}
              />
            }
            label={
              <Typography color="textPrimary" variant="body2">
                {label}
              </Typography>
            }
          />
        )
      }}
    </FastField>
  )
}
