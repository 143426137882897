import * as React from 'react'

import { EventVenue } from '../types/Event'
import { Vendor } from '../types/Vendor'
import {
  LsGetLikedVenues,
  LsGetLikedVendors,
  LsSetLikedVendors,
  LsSetLikedVenues,
} from '../utils/storage'

export function useLikes(): {
  likedVendors: Array<Vendor>
  likedVenues: Array<EventVenue>
  toggleVendorLike: (vendor: Vendor) => void
  toggleVenueLike: (venue: EventVenue) => void
} {
  const [likedVendors, setLikedVendors] = React.useState<Array<Vendor>>([])
  const [likedVenues, setLikedVenues] = React.useState<Array<EventVenue>>([])

  React.useEffect(() => {
    setLikedVendors(LsGetLikedVendors())
    setLikedVenues(LsGetLikedVenues())
  }, [])

  const toggleVendorLike = (vendor: Vendor) => {
    let updatedVendors = []

    if (likedVendors.find((item) => vendor.id === item.id)) {
      updatedVendors = likedVendors.filter((item) => item.id !== vendor.id)
    } else {
      updatedVendors = [...likedVendors, vendor]
    }

    LsSetLikedVendors(updatedVendors)
    setLikedVendors(updatedVendors)
  }

  const toggleVenueLike = (venue: EventVenue) => {
    let updatedVenues = []

    if (likedVenues.find((item) => venue.id === item.id)) {
      updatedVenues = likedVenues.filter((item) => item.id !== venue.id)
    } else {
      updatedVenues = [...likedVenues, venue]
    }
    LsSetLikedVenues(updatedVenues)
    setLikedVenues(updatedVenues)
  }

  return { likedVendors, likedVenues, toggleVendorLike, toggleVenueLike }
}
