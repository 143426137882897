import { Box, Modal, Typography } from '@material-ui/core'
import * as React from 'react'

import ArrowBack from '../../../../components/svg/ArrowBack'
import { useWindowSize } from '../../../../hooks/useWindowSize'

import { CloseBox, ModalContentWrapper } from './RightSideModal.styles'

export const RightSideModal: React.FC<{
  open: boolean
  onClose: () => void
  withCloseButton?: boolean
}> = ({ children, onClose, open, withCloseButton = true }) => {
  const { isMobile } = useWindowSize()

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.7)' } }}
    >
      <ModalContentWrapper id="right-modal">
        <Box
          display="flex"
          flex={1}
          width={1}
          height={1}
          style={{ position: 'relative', zIndex: 10000 }}
        >
          {children}
          {withCloseButton && (
            <CloseBox onClick={onClose}>
              <Box>
                {!isMobile ? (
                  <Typography variant="body1" color="#9396A3">
                    Cancel
                  </Typography>
                ) : (
                  <ArrowBack />
                )}
              </Box>
            </CloseBox>
          )}
        </Box>
      </ModalContentWrapper>
    </Modal>
  )
}
