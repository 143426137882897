import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useSimpleModal } from '../../../../hooks/useSimpleModal'
import {
  MessageAttachment,
  MessageTemplateData,
  MessageTemplateItem,
} from '../../../../types/dtos/messages'
import { getRequestError } from '../../../../utils/helpers/validations'
import { useMessageModalData } from '../../../../utils/providers/MessageModalProvider'
import GradientButton from '../../../ui/Buttons/GradientButton'
import { TextInput } from '../../../ui/FormComponents/TextInput'
import { SimpleModal } from '../../../ui/Modals/SimpleModal'
import { StyledSaveTemplateButton } from '../../ChatBox.styles'
import { TemplatePicker } from '../TemplatePicker'

interface TemplatesRowProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>
  templates?: MessageTemplateItem[]
  templateName: string
  setTemplateName: Dispatch<SetStateAction<string>>
  onSaveTemplate: (template: MessageTemplateData) => Promise<void>
  message: string | null
  setMessage: Dispatch<SetStateAction<string | null>>
  attachment: MessageAttachment | File | null
  setAttachment: Dispatch<SetStateAction<MessageAttachment | File | null>>
}

export const TemplatesRow: React.FC<TemplatesRowProps> = ({
  setIsLoading,
  templates,
  templateName,
  setTemplateName,
  onSaveTemplate,
  message,
  setMessage,
  attachment,
  setAttachment,
}) => {
  const { isOpen, toggleModal } = useSimpleModal()
  const { showMessage } = useMessageModalData()
  const { t } = useTranslation()

  const insertMessageFromTemplate = (templateId: number) => {
    const template = templates?.find((template) => template.id === templateId)
    setMessage(template?.message || null)
    const file = template?.files?.[0] || null
    const image = template?.images?.[0] || undefined

    if (file || image) {
      const attachment = {
        id: image?.id || file?.id,
        url: image?.path || file?.s3_path,
        name: image?.filename || file?.filename,
        type: file ? 'file' : 'image',
        image,
      } as MessageAttachment
      setAttachment(attachment)
    }
  }

  const saveTemplate = async () => {
    if (templates?.find((template) => template.name === templateName))
      showMessage({
        title: t(
          'chat.templates.warning.title',
          'Template with the same name already exists'
        ),
        text: t('chat.templates.warning.text', 'Please, choose another name'),
        type: 'error',
      })
    else {
      toggleModal()
      setIsLoading(true)

      try {
        await onSaveTemplate({ name: templateName, message, attachment })
      } catch (e) {
        showMessage({
          title: getRequestError(e),
          type: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <Box display="flex">
        <StyledSaveTemplateButton
          disabled={!message}
          onClick={() => {
            toggleModal()
          }}
        >
          <Typography variant="body2">
            {t('chat.templates.save', 'Save as template')}
          </Typography>
        </StyledSaveTemplateButton>
        {!!templates?.length && (
          <TemplatePicker
            templates={templates}
            onSelect={insertMessageFromTemplate}
          />
        )}
      </Box>
      <SimpleModal open={isOpen} onClose={toggleModal}>
        <Typography
          fontSize={18}
          fontWeight={600}
          align="center"
          mb="24px"
          sx={{ textTransform: 'capitalize' }}
        >
          {t('chat.templates.name.heading', 'Name message template')}
        </Typography>
        <TextInput
          label={t('chat.templates.name.label', 'Template name')}
          placeholder={t('chat.templates.name.placeholder', 'Type here')}
          value={templateName}
          onChange={setTemplateName}
        />
        <GradientButton
          style={{ width: '100%', margin: '32px auto', marginBottom: '8px' }}
          disabled={!templateName}
          onClick={saveTemplate}
        >
          {t('chat.templates.new.save', 'Save and close')}
        </GradientButton>
      </SimpleModal>
    </>
  )
}
