import { Box, Divider, styled, Typography } from '@material-ui/core'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { FiberManualRecord } from '@material-ui/icons'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { VendorCategoryLabel } from '../../../components/cards/labels/VendorCategoryLabel'
import DollarCircle from '../../../components/svg/DollarCircle'
import Marker from '../../../components/svg/Marker'
import { Image } from '../../../components/ui/Image'
import { Loader } from '../../../components/ui/Loader'
import { usePartner } from '../../../hooks/usePartner'
import { useVendorCategory } from '../../../hooks/useVendorCategory'
import { EventVendor, EventVenue } from '../../../types/Event'
import { Room } from '../../../types/Venue'
import { getDifferenceInDays } from '../../../utils/helpers/date'
import { formatToCurrency } from '../../../utils/helpers/price'

const StyledListItem = styled(Box)`
  margin: 5px;
  padding: 25px;
  box-shadow: 0 4px 18px rgba(210, 210, 210, 0.4);
  border-radius: 2px;

  ${MEDIA_RULES.SMALL} {
    min-height: auto;
    box-shadow: none;
    padding: 32px 0;
    padding-top: 0;
    border-bottom: 1px solid rgba(132, 132, 132, 0.12);
  }
`

export const InquiryListingCard: React.FC<{
  inquiryDetails: any
  listing?: EventVenue | EventVendor
  rooms?: Room[]
}> = ({ inquiryDetails, listing, rooms = [] }) => {
  const { t } = useTranslation()
  const { isAbs } = usePartner()
  const { isLoading, getVendorCategory } = useVendorCategory(isAbs)

  if (isLoading || !listing) return <Loader />

  return (
    <StyledListItem>
      <Typography variant="h3" color="textPrimary" gutterBottom>
        {isAbs
          ? t('events.vendors.heading', 'Vendor')
          : t('events.venue.heading', 'Venue')}
      </Typography>
      <Box display="flex" mb={5}>
        <ListItemAvatar>
          <Image
            style={{ width: 100, height: 100 }}
            image={
              listing?.photo ||
              (listing && 'mainImage' in listing ? listing?.mainImage : '')
            }
            imageSize="small"
            staticImageType="venue"
            alt={listing?.name}
          />
        </ListItemAvatar>
        <Box
          ml={4}
          mb={2}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography variant="h4" sx={{ mb: '25px' }}>
            {listing?.name}
          </Typography>
          {isAbs && listing && 'type' in listing && (
            <VendorCategoryLabel
              vendorCategory={getVendorCategory(listing?.type.toString())?.name}
            />
          )}
          {!isAbs && (
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <Box>
                  <Marker />
                </Box>
                <Typography
                  variant="body2"
                  sx={{ mb: '5px', ml: '10px', color: '#9396A3' }}
                >
                  {listing && 'address' in listing ? listing?.address : ''}
                </Typography>
              </Box>
              {'pricing' in listing &&
                'pricingDetails' in listing &&
                !listing?.pricingDetails && (
                  <Box display="flex">
                    <Box>
                      <DollarCircle fill="#9396A3" />
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ color: '#9396A3', ml: '10px' }}
                    >
                      {'displayPricing' in listing && !listing?.displayPricing
                        ? t(
                            'business.pricing.landlord.notDisplay',
                            'Inquire for Pricing'
                          )
                        : `${t('events.from', 'From')} $ ${
                            listing?.pricing
                          } ${t('events.perDay', '/day')}`}
                    </Typography>
                  </Box>
                )}
            </Box>
          )}
        </Box>
      </Box>
      {!!rooms?.length && (
        <Box mb={2} display="flex" flexDirection="column">
          <Typography variant="h4" sx={{ mb: '15px' }}>
            {t('events.rooms', 'Rooms in inquiry')}
          </Typography>
          {rooms?.map((room: Room) => (
            <Box
              key={room.id}
              display="flex"
              alignItems="center"
              alignContent="center"
            >
              <FiberManualRecord sx={{ width: '8px', mr: 2, mb: 1 }} />
              <Typography sx={{ mb: '5px' }} variant="body2">
                {room.name}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      {!isAbs &&
        listing &&
        'params' in listing &&
        listing?.params?.display_pricing &&
        listing?.params?.short_pricing_details === 'per-day' && (
          <Box mb={2} display="flex" flexDirection="column">
            <Typography variant="h4" sx={{ mb: '15px' }}>
              {t('events.price', 'Price detail')}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ mb: '13px' }}
            >
              <Typography variant="body2">{`${formatToCurrency(
                listing?.pricing
              )} / ${listing?.params?.short_pricing_details_label} x ${
                getDifferenceInDays(
                  inquiryDetails.start_date,
                  inquiryDetails?.end_date
                ) + 1
              } ${t('events.days', 'days')}`}</Typography>
              <Typography variant="body2">
                {listing?.pricing
                  ? formatToCurrency(
                      +listing?.pricing *
                        (getDifferenceInDays(
                          inquiryDetails.start_date,
                          inquiryDetails?.end_date
                        ) +
                          1)
                    )
                  : '$0'}
              </Typography>
            </Box>
            <Divider sx={{ mb: '13px' }} />
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ mb: '5px' }}
            >
              <Typography variant="body2">
                {t('events.total', 'Total')}
              </Typography>
              <Typography variant="body2">
                {listing?.pricing
                  ? formatToCurrency(
                      +listing?.pricing *
                        (getDifferenceInDays(
                          inquiryDetails.start_date,
                          inquiryDetails?.end_date
                        ) +
                          1)
                    )
                  : '$0'}
              </Typography>
            </Box>
          </Box>
        )}
    </StyledListItem>
  )
}
