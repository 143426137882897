export const UserIconOutlined = ({ fill = '#fff' }) => {
  return (
    <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.474 20.88a10.228 10.228 0 0 0-2.204-3.268 10.27 10.27 0 0 0-3.267-2.204l-.033-.013A6.78 6.78 0 0 0 14 3.118a6.78 6.78 0 0 0-6.782 6.78 6.776 6.776 0 0 0 2.811 5.5l-.033.013a10.184 10.184 0 0 0-3.267 2.204 10.273 10.273 0 0 0-2.204 3.267 10.164 10.164 0 0 0-.807 3.777.219.219 0 0 0 .22.224h1.64c.12 0 .216-.096.218-.213a8.152 8.152 0 0 1 2.401-5.587A8.149 8.149 0 0 1 14 16.68c2.192 0 4.252.853 5.802 2.403a8.152 8.152 0 0 1 2.4 5.587c.003.12.1.213.22.213h1.64a.22.22 0 0 0 .219-.224 10.189 10.189 0 0 0-.807-3.78ZM14 14.602a4.673 4.673 0 0 1-3.325-1.379 4.672 4.672 0 0 1-1.379-3.325c0-1.255.49-2.436 1.379-3.325A4.673 4.673 0 0 1 14 5.195c1.255 0 2.436.49 3.325 1.378a4.672 4.672 0 0 1 1.378 3.325c0 1.255-.49 2.437-1.378 3.325A4.673 4.673 0 0 1 14 14.602Z"
        fill={fill}
      />
    </svg>
  )
}
