import axios from 'axios'
import * as React from 'react'
import { useQueryClient } from 'react-query'

import { InquiryWithDetails } from '../types/Inquiry'
import { useInquiryDetails } from '../utils/api/inquiries'
import { useAuthData } from '../utils/providers/AuthProvider'

interface InquiryDetailsHookData {
  loading: boolean
  inquiryDetails?: InquiryWithDetails
  updateInquiryDetails?: (details: Partial<InquiryWithDetails>) => void
  refetch: () => void
}

export function useInquiryById(
  inquiryId: string,
  options?: any
): InquiryDetailsHookData {
  const { authUser } = useAuthData()

  if (!inquiryId) {
    return { loading: false, inquiryDetails: undefined, refetch: () => {} }
  }
  const source = React.useMemo(() => axios.CancelToken.source(), [])
  const queryKey = ['inquiry', inquiryId]

  const queryClient = useQueryClient()
  const { isLoading: authLoading } = useAuthData()
  const params = options ? options : { inquiryId }
  const {
    isLoading,
    data: inquiryDetails,
    refetch,
    isFetching,
  } = useInquiryDetails(inquiryId, source.token, {
    enabled: !!authUser,
    ...params,
  })

  const updateInquiryDetails = (details: Partial<InquiryWithDetails>) => {
    queryClient.setQueryData(queryKey, { ...inquiryDetails, ...details })
  }

  const loading = authLoading || isLoading || isFetching

  return { loading, inquiryDetails, updateInquiryDetails, refetch }
}
