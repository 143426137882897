import { Box, Typography, useTheme } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import * as React from 'react'

import { StyledDocumentsIconButton } from '../../pages/events/ServiceBookingDetails/ServiceBookingDetails.styles'
import { convertDateToShortString } from '../../utils/helpers/date'
import { downloadUserFile, getDocumentIcon } from '../../utils/helpers/files'
import Download from '../svg/clientDashboard/Download'
import RemoveIcon from '../svg/RemoveIcon'
import { TooltipComponent } from '../ui/Tooltip'

import { ActionsContainer, FileName } from './Documents.styles'

export const DocumentListItem: React.FC<{
  withDownload?: boolean
  document: any
  onDownload?: () => void
  onSelect?: () => void
  onRemove?: () => void
  removeIsPossible?: boolean
}> = ({
  document,
  onSelect,
  onDownload,
  onRemove,
  withDownload = true,
  removeIsPossible = !!onRemove,
}) => {
  const name = document.filename.split('.')[0]
  const documentType = document.filename.slice(-3)

  const lastUpdated = convertDateToShortString(
    new Date(document.updated_at),
    true
  )

  const handleDownload = async () => {
    try {
      if (onDownload) {
        await onDownload()
      } else {
        const response = await downloadUserFile(document.id)
        window.open(response, '_blank')
      }
    } catch (e) {
      console.error('ERROR', e)
    }
  }

  return (
    <DocumentPreview
      name={name}
      documentType={documentType}
      lastUpdated={lastUpdated}
      onSelect={onSelect}
      onRemove={onRemove}
      removeIsPossible={removeIsPossible}
      onDownload={withDownload ? handleDownload : undefined}
    />
  )
}

export const DocumentPreview: React.FC<{
  name: string
  documentType: string
  lastUpdated: string
  onDownload?: () => void
  onSelect?: () => void
  onRemove?: () => void
  removeIsPossible?: boolean
}> = ({
  name,
  documentType,
  lastUpdated,
  onDownload,
  onSelect,
  onRemove,
  removeIsPossible = !!onRemove,
}) => {
  const theme = useTheme()

  return (
    <Box
      display="flex"
      alignItems="center"
      mb={5}
      justifyContent="space-between"
      style={{ padding: 12, background: '#F7F7F8' }}
    >
      <Box display="flex" alignItems="center">
        <Box mr={4}>{getDocumentIcon(documentType)}</Box>
        <Box display="flex" flexDirection="column" width="fit-content">
          <FileName variant="body2" gutterBottom>
            {name}
          </FileName>
          <Typography color="#9FA2B4" fontSize={12}>
            Formed: {lastUpdated}
          </Typography>
        </Box>
      </Box>
      <ActionsContainer display="flex" alignItems="center">
        {onDownload && (
          <TooltipComponent title="Download document">
            <StyledDocumentsIconButton onClick={onDownload}>
              <Download fill={theme.palette.primary.light} />
            </StyledDocumentsIconButton>
          </TooltipComponent>
        )}
        {onSelect && (
          <TooltipComponent title="Pick document">
            <StyledDocumentsIconButton onClick={onSelect}>
              <Add style={{ color: theme.palette.primary.light }} />
            </StyledDocumentsIconButton>
          </TooltipComponent>
        )}
        {onRemove && removeIsPossible && (
          <TooltipComponent title="Remove document">
            <StyledDocumentsIconButton onClick={onRemove}>
              <RemoveIcon fill={theme.palette.primary.light} />
            </StyledDocumentsIconButton>
          </TooltipComponent>
        )}
      </ActionsContainer>
    </Box>
  )
}
