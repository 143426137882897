import React, { lazy, Suspense } from 'react'

import { Loader } from '../../../../../components/ui/Loader'
import { useIsAbsUser } from '../../../../../hooks/useIsAbsUser'

const EventInfo = lazy(() =>
  import('./GeneralEventInfo').then(({ GeneralEventInfo }) => ({
    default: GeneralEventInfo,
  }))
)
const AbsEventInfo = lazy(() =>
  import('./AbsGeneralEventInfo').then(({ AbsGeneralEventInfo }) => ({
    default: AbsGeneralEventInfo,
  }))
)

export const GeneralEventInfo = (
  props: React.ComponentProps<typeof EventInfo | typeof AbsEventInfo>
) => {
  const isAbsUser = useIsAbsUser()
  const EventInfoComponent = isAbsUser ? AbsEventInfo : EventInfo

  return (
    <Suspense fallback={<Loader />}>
      <EventInfoComponent {...props} />
    </Suspense>
  )
}
