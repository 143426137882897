import { CancelToken } from 'axios'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'

import { getPartnerParams } from '../../constants/partners'
import { RoomsFormValues } from '../../pages/dashboard/BusinessDetails/components/Rooms'
import { VenueUpdateRequest } from '../../types/dtos/venues'
import {
  Room,
  ServerVenue,
  VenueCategoriesOptions,
  VenueItem,
  VenuesQueryOptions,
} from '../../types/Venue'
import { clearObject } from '../helpers/data'
import { getFormData } from '../helpers/objectToFormData'
import {
  getVenueApiPath,
  getVenueMapApiPath,
  getVenueRoomApiPath,
  getVenueRoomsApiPath,
  getVenueSlugApiPath,
  venueCategoriesApiPath,
  venuesApiPath,
} from '../paths'

import { deleteRequest, get, postFormData, put, putFormData } from './api'

const queryKey = ['venue-rooms']

async function fetchVenues(options: VenuesQueryOptions = {}): Promise<any> {
  return await get(venuesApiPath, {
    ...clearObject({
      ...options,
      ...getPartnerParams(),
      includeRelations: true,
    }),
  })
}

export function useVenues(
  options: VenuesQueryOptions = {},
  fetchOptions: { enabled: boolean } = { enabled: true }
) {
  if (options.scope === null) {
    return { isLoading: true, data: null }
  }

  const params = { ...options }
  if (options.orderBy === 'price') {
    params.orderBy = 'params.minimum_starting_at'
  }

  return useQuery<any, Error>(
    ['venues', ...Object.values(params)],
    () => fetchVenues(params),
    { ...fetchOptions }
  )
}

export function useVenue(venueSlug: string, options: object) {
  return useQuery<ServerVenue, Error>(['venues', venueSlug], () =>
    getVenueBySlug(venueSlug, options)
  )
}

export function getVenueById(
  venueId: string,
  options: object,
  cancelToken: CancelToken
): Promise<ServerVenue> {
  return get(getVenueApiPath(venueId), { ...options }, { cancelToken })
}

export function getVenueBySlug(
  venueSlug: string,
  options: object
): Promise<ServerVenue> {
  return get(getVenueSlugApiPath(venueSlug), { ...options })
}

export function updateVenueById(
  venueId: string,
  data: VenueUpdateRequest
): Promise<ServerVenue> {
  return put(getVenueApiPath(venueId), data)
}

export async function updateBusinessImages(
  venueId: string,
  images: Array<{ id: number; order: number }>
): Promise<any> {
  return put(getVenueApiPath(venueId), { images })
}

export function getVenuesByLocation(
  options: object,
  cancelToken: CancelToken
): Promise<VenueItem[]> {
  return get(
    venuesApiPath,
    { ...options, ...getPartnerParams() },
    { cancelToken }
  )
}

export function fetchVenueCategories(
  options: VenueCategoriesOptions | undefined
): Promise<any[]> {
  return get(venueCategoriesApiPath, options)
}

export function useVenueCategories(
  options: VenueCategoriesOptions = {} as any
) {
  return useQuery<any, Error>(
    ['venueCategories', ...Object.values(options)],
    () => fetchVenueCategories(options)
  )
}

export function getVenueMap(venueId: string): Promise<string> {
  return get(getVenueMapApiPath(venueId))
}

export function useVenueMap(venueId: string) {
  return useQuery<any, Error>(venueId, () => getVenueMap(venueId))
}

export function getVenueRooms(venueId: number): Promise<string> {
  return get(getVenueRoomsApiPath(venueId.toString()))
}

export function useVenueRooms(venueId: number) {
  return useQuery<any, Error>(['venue-rooms', venueId], () =>
    getVenueRooms(venueId)
  )
}

export async function addRoom(
  venueId: number,
  data: RoomsFormValues
): Promise<any> {
  const formData = getFormData(data)
  return postFormData(getVenueRoomsApiPath(venueId.toString()), formData)
}

export async function editRoom(
  venueId: number,
  data: RoomsFormValues,
  roomId: number
): Promise<any> {
  const formData = getFormData(data)
  return putFormData(
    getVenueRoomApiPath(venueId.toString(), roomId.toString()),
    formData
  )
}

export async function deleteRoom(
  venueId: number,
  roomId: number
): Promise<any> {
  return deleteRequest(
    getVenueRoomApiPath(venueId.toString(), roomId.toString())
  )
}

const onMutationSuccess = (queryClient: QueryClient, updatedRoom: Room) => {
  const previousRooms = queryClient.getQueryData<Room[]>(queryKey)
  let isUpdated = false
  const mergedRooms = previousRooms
    ? previousRooms.map((item) => {
        if (item.id === updatedRoom.id) {
          isUpdated = true
          return updatedRoom
        }
        return item
      })
    : [updatedRoom]

  if (previousRooms && !isUpdated) {
    mergedRooms.unshift(updatedRoom)
  }

  queryClient.setQueryData(queryKey, mergedRooms)
}

export async function updateOrCreateRoom(values: any) {
  const { roomId, venueId, data } = values
  const formData = getFormData(data)
  if (roomId) {
    return putFormData(
      getVenueRoomApiPath(venueId.toString(), roomId.toString()),
      formData,
      undefined
    )
  }

  return postFormData(getVenueRoomsApiPath(venueId.toString()), formData)
}

export function useRoomsUpdate() {
  const queryClient = useQueryClient()

  return useMutation(queryKey, updateOrCreateRoom, {
    onSuccess: (updatedRoom: Room) =>
      onMutationSuccess(queryClient, updatedRoom),
  })
}

export async function addLabelToVenues(
  venueId: string,
  data: { labels: any[] }
): Promise<any> {
  return put(getVenueApiPath(venueId), data)
}
