import { Box, IconButton, Typography, useTheme } from '@material-ui/core'
import { NotificationsOutlined } from '@material-ui/icons'
import { useEffect, useMemo, useRef, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { NOTIFICATIONS_REFETCH_INTERVAL } from '../../../constants'
import { UserRoles } from '../../../constants/profile'
import { useCurrentBusinessListingId } from '../../../hooks/useCurrentBusinessListingId'
import { usePartner } from '../../../hooks/usePartner'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { ServerNotification } from '../../../types/dtos/Notifications'
import { ProfileVendor, ProfileVenue } from '../../../types/Profile'
import {
  readNotifications,
  useNotifications,
} from '../../../utils/api/notifications'
import { dashboardListingPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { NavLink } from '../../NavLink'
import ListingIcon from '../../svg/dashboard/ListingIcon'
import { Loader } from '../../ui/Loader'
import { PopperMenu } from '../../ui/PopperMenu'
import { NotificationIndicator } from '../Header.styles'

import { NotificationItem } from './NotificationItem'
import {
  BlockTitle,
  NotificationsContainer,
  SeeAllContainer,
} from './Notifications.styles'

export const Notifications: React.FC<{ color?: any }> = ({ color }) => {
  const { authUser } = useAuthData()
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const isClient = UserRoles.isClient(authUser?.role)
  const isVendor = UserRoles.isVendor(authUser?.role)

  const { businessId } = useCurrentBusinessListingId()
  const [page, setPage] = useState<number>(1)
  const [isShowMoreClicked, setIsShowMoreClicked] = useState<boolean>(false)
  const { data } = businessId
    ? useNotifications(
        !!authUser,
        isClient,
        isShowMoreClicked ? page : 1,
        NOTIFICATIONS_REFETCH_INTERVAL,
        businessId.toString()
      )
    : useNotifications(
        !!authUser,
        isClient,
        isShowMoreClicked ? page : 1,
        NOTIFICATIONS_REFETCH_INTERVAL
      )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<ServerNotification[]>([])
  const [wasOpened, setWasOpened] = useState<boolean>(false)
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false)
  const newNotifications = useMemo(
    () =>
      notifications && notifications.length
        ? notifications.filter((item: any) => !item.read_at)
        : [],
    [notifications]
  )
  const viewedNotifications = useMemo(
    () =>
      notifications && notifications.length
        ? notifications.filter((item: any) => item.read_at)
        : [],
    [notifications]
  )
  const readAllNotifications = async () => {
    setWasOpened(true)
    await readNotifications()
  }

  const theme = useTheme()
  const { isAbs } = usePartner()
  const allListings: (ProfileVenue | ProfileVendor)[] =
    (isVendor ? authUser?.vendors : authUser?.venues) || []

  const activeBusiness = useMemo(() => {
    if (!allListings?.length) return
    return (
      allListings?.find((listing) => listing.id === businessId) ||
      allListings?.[0]
    )
  }, [allListings, businessId])

  const showMore = () => {
    setIsLoading(true)
    setPage(page + 1)
    setIsShowMoreClicked(true)
  }

  useEffect(() => {
    const nextPageNotification = data?.data || []
    const oldNotification = [...notifications]
    oldNotification.splice(0, 5, ...nextPageNotification)
    setNotifications(
      isShowMoreClicked
        ? [...notifications, ...nextPageNotification]
        : oldNotification
    )
    setIsLoading(false)
  }, [data?.data])

  useEffect(() => {
    if (isShowMoreClicked) {
      setTimeout(() => {
        setIsShowMoreClicked(false)
      }, 5000)
    }
  }, [isShowMoreClicked, data?.data])

  return (
    <PopperMenu
      placement="bottom"
      options={[]}
      withPointer={false}
      setIsPopperOpen={setIsPopperOpen}
      renderOptions={
        notifications && notifications.length ? (
          <Box>
            <NotificationsContainer>
              <Box display="flex" alignItems="center">
                <BlockTitle>
                  {t('notifications.notifications', 'Notifications')}
                </BlockTitle>
                {!isClient && (
                  <NavLink
                    to={generatePath(dashboardListingPath, {
                      listingId: businessId,
                    })}
                    style={{
                      marginLeft: '13px',
                      marginBottom: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.light,
                    }}
                  >
                    <ListingIcon />
                    <Typography ml={2}>{activeBusiness?.name}</Typography>
                  </NavLink>
                )}
              </Box>
              {newNotifications && newNotifications.length ? (
                <>
                  <Typography
                    sx={{
                      color: '#2F54EB',
                      marginBottom: '20px',
                      fontSize: '14px',
                    }}
                  >
                    {t('notifications.new', 'New')}
                  </Typography>
                  {newNotifications.map((item) => (
                    <NotificationItem key={item.id} item={item} />
                  ))}
                </>
              ) : null}
              {viewedNotifications && viewedNotifications.length ? (
                <>
                  <Typography
                    sx={{
                      color: '#9396A3',
                      marginBottom: '20px',
                      fontSize: '14px',
                    }}
                  >
                    {t('notifications.viewed', 'Viewed')}
                  </Typography>
                  {viewedNotifications.map((item) => (
                    <NotificationItem key={item.id} item={item} />
                  ))}
                </>
              ) : null}
            </NotificationsContainer>
            {!data?.total ||
              (data?.total && notifications.length < data?.total && (
                <SeeAllContainer onClick={showMore}>
                  <Typography>
                    {' '}
                    {t('notifications.showMore', 'Show more notifications')}
                  </Typography>
                  {isLoading ? <Loader size={40} position="absolute" /> : null}
                </SeeAllContainer>
              ))}
          </Box>
        ) : (
          <Box display="flex" justifyContent="center">
            <Typography
              sx={{
                color: '#9396A3',
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '14px',
              }}
            >
              {t('notifications.noNew', 'No new notifications')}
            </Typography>
          </Box>
        )
      }
      onSelectAction={(item) => console.log(item)}
    >
      <Box sx={{ position: 'relative' }}>
        <IconButton
          sx={{
            width: isAbs && !isMobile ? '44px' : 'unset',
            height: isAbs && !isMobile ? '44px' : 'unset',
            background: isMobile ? 'none' : theme.palette.common.headerIconBg,
            '&:hover': {
              background: isAbs
                ? theme.palette.common.headerIconHover
                : theme.palette.action.hover,
            },
          }}
          onClick={readAllNotifications}
        >
          <NotificationsOutlined
            sx={{
              color:
                color ||
                (isMobile
                  ? theme.palette.common.white
                  : theme.palette.common.headerIconColor),
            }}
          />
          {newNotifications && newNotifications?.length && !wasOpened ? (
            <NotificationIndicator
              style={{
                right: isMobile ? '3px' : '10px',
                top: isMobile ? '6px' : '12px',
              }}
            />
          ) : null}
          {isPopperOpen && (
            <Box
              sx={{
                width: 0,
                height: 0,
                position: 'absolute',
                bottom: '-15px',
                borderLeft: '15px solid transparent',
                borderRight: '15px solid transparent',
                borderBottom: '15px solid #fff',
              }}
            />
          )}
        </IconButton>
      </Box>
    </PopperMenu>
  )
}
