import { isArray, isString, split } from 'lodash/fp'
import {
  SafeParseReturnType,
  UnknownKeysParam,
  ZodError,
  ZodObject,
  ZodRawShape,
  ZodString,
  ZodTypeAny,
  z,
} from 'zod'

export type ZodObjectAny<Output = any, Input = any> = ZodObject<
  ZodRawShape,
  UnknownKeysParam,
  ZodTypeAny,
  Output,
  Input
>

export const coerceToArray = <I extends ZodTypeAny>(elementSchema: I) =>
  z.preprocess((arg) => {
    if (isArray(arg)) return arg
    if (isString(arg)) return split(',', arg)
    return undefined
  }, elementSchema.array())

export const issueKeys = (error: ZodError) =>
  error.issues
    .filter((issue) => issue.path.length)
    .map((issue) => issue.path[0])

export const mapError =
  <Input, Output, Result>(callback: (e: ZodError) => Result) =>
  (result: SafeParseReturnType<Input, Output>) =>
    result.success ? null : callback(result.error)
