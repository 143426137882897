import { Box, Typography } from '@material-ui/core'
import * as React from 'react'

import AscSort from '../../../../components/svg/AscSort'
import AscSortMobile from '../../../../components/svg/AscSortMobile'
import DescSort from '../../../../components/svg/DescSort'
import DescSortMobile from '../../../../components/svg/DescSortMobile'
import { ActionButton } from '../../../../components/ui/Buttons/ActionButton'
import { MultiSelectField } from '../../../../components/ui/FormComponents/MultiSelectField'
import { bookingQueryParams } from '../../../../constants/bookings'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { SortOrder } from '../../../../utils/helpers/sort'
import { eventListQueryParams } from '../../../../utils/mappers/eventListQueryParamsMapper'
import { venueListQueryParams } from '../../../../utils/mappers/venueListQueryParamsMapper'
import { BootstrapTooltip } from '../../../events/Event/components/EventServices/EventServices.styles'
import { VendorsListQueryParams } from '../../../inquiry/components/InquiryBottomModal'

interface SortingSelectProps {
  onSort: (field: string) => void
  sortingFields: Array<{ label: string; value: string }> | string[]
  orderBy?: string
  orderDirection: SortOrder
  onChangeDirection?: (direction: SortOrder) => void
  size?: 'small' | 'default'
}

export const SortingSelect: React.FC<SortingSelectProps> = ({
  size = 'default',
  onSort,
  orderDirection,
  onChangeDirection,
  sortingFields,
  orderBy,
}) => {
  const { isMobile } = useWindowSize()
  const handleChange = (value: string) => {
    onSort(value)
  }

  return (
    <Box display="flex" alignItems="center">
      <MultiSelectField
        selectSize={size}
        sx={{ minWidth: 150 }}
        id="sort-by"
        defaultLabel="Sort by"
        selectedOptions={orderBy}
        options={sortingFields}
        onChange={handleChange}
      />
      {onChangeDirection && (
        <Box
          className="sortDirection"
          sx={{ height: isMobile ? 40 : size === 'default' ? 53 : 48 }}
          display="inline-flex"
        >
          {orderDirection === 'asc' ? (
            <BootstrapTooltip
              title={
                <Box
                  my={2}
                  mx={3}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography>Sort order value</Typography>
                  <Typography color="#F7F7F8" sx={{ mt: '5px' }}>
                    from the lowest to the highest
                  </Typography>
                </Box>
              }
              arrow
              placement="top"
            >
              <ActionButton
                onClick={() => onChangeDirection('desc')}
                icon={
                  isMobile ? (
                    <AscSortMobile fill="none" />
                  ) : (
                    <AscSort fill="none" />
                  )
                }
              />
            </BootstrapTooltip>
          ) : (
            <BootstrapTooltip
              title={
                <Box
                  my={2}
                  mx={3}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography>Sort order value</Typography>
                  <Typography color="#F7F7F8" sx={{ mt: '5px' }}>
                    from the highest to the lowest
                  </Typography>
                </Box>
              }
              arrow
              placement="top"
            >
              <ActionButton
                onClick={() => onChangeDirection('asc')}
                icon={
                  isMobile ? (
                    <DescSortMobile fill="none" />
                  ) : (
                    <DescSort fill="none" />
                  )
                }
              />
            </BootstrapTooltip>
          )}
        </Box>
      )}
    </Box>
  )
}
