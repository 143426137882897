import { FormControlLabel, Paper, styled } from '@material-ui/core'
import { Radio, RadioGroup } from '@material-ui/core'
import { MEDIA_RULES } from 'hooks/useWindowSize'
import * as React from 'react'
import { ChangeEvent, forwardRef } from 'react'
import { useMemo } from 'react'

import { PopperField } from '../../../../components/ui/PopoverField'
import { guestsVariants } from '../../../../constants'
import { useWindowSize } from '../../../../hooks/useWindowSize'

import EventFormField from './EventFormField'

interface props {
  value?: string
  setValue: (value: string) => void
}

const GuestsPopoverWrapper = styled(Paper)`
  background: white;
  border-radius: 0;
  min-width: 350px;

  .MuiFormControlLabel-root {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .MuiFormGroup-root {
    margin-left: 24px;
    margin-right: 24px;
  }

  ${MEDIA_RULES.SMALL} {
    min-width: unset;
    width: 100%;
  }
`

const GuestsInput = forwardRef((props: props, ref) => {
  const { isMobile } = useWindowSize()
  const onGuestsChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^\d+$/) || e.target.value === '') {
      props.setValue(e.target.value)
    }
  }

  const setValueFromVariants = (e: ChangeEvent<HTMLInputElement>) => {
    props.setValue(e.target.value)
  }

  const fieldValue = useMemo(() => getFieldValue(props.value), [props.value])

  const popover = (
    <GuestsPopoverWrapper>
      <RadioGroup
        aria-label="event-guests"
        name="guests"
        value={props.value}
        onChange={setValueFromVariants}
      >
        {guestsVariants.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio color="primary" />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </GuestsPopoverWrapper>
  )

  const input = (
    <EventFormField
      onChange={onGuestsChange}
      value={fieldValue}
      autoComplete="off"
      label="Guests"
      placeholder="Add Guests"
      InputProps={{ readOnly: false }}
    />
  )

  return (
    <PopperField
      width={isMobile ? '100%' : 'unset'}
      ref={ref}
      input={input}
      placement="bottom-start"
      content={popover}
    />
  )
})

const getFieldValue = function (selectedValue: string | undefined) {
  if (!selectedValue) return ''

  return (
    guestsVariants?.find((item) => item.value === selectedValue)?.label ||
    selectedValue
  )
}

GuestsInput.displayName = 'GuestsInput'

export default GuestsInput
