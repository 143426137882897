import { Box, ImageList } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'

import { EmptySearchResults } from '../../../components/ui/EmptySearchResults'
import { PaginationComponent } from '../../../components/ui/Pagination'
import { CustomSnackbar } from '../../../components/ui/Snackbar'
import { EventVenue } from '../../../types/Event'
import { VenueItem } from '../../../types/Venue'
import { VenueListItem } from '../components/VenueListItem/VenueListItem'

interface VenueListProps {
  venues: VenueItem[]
  addMode?: boolean
  page?: number | string
  lastPage: number
  onPaginationChange: (event: object, page: number) => void
  onVenueClick?: (item: VenueItem) => void
  venueAction?: 'like' | 'add'
}

export const VenueList: React.FC<VenueListProps> = ({
  venues,
  page = 1,
  lastPage,
  onPaginationChange,
  onVenueClick,
  addMode,
}) => {
  const [snackbarData, setSnackbarData] = useState<EventVenue | null>(null)
  const pageNumber = typeof page === 'string' ? parseInt(page) : page

  return (
    <>
      {!venues.length ? (
        <EmptySearchResults />
      ) : (
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
          mt={3}
          mx={-1}
        >
          <ImageList
            style={{
              width: '100%',
              gridTemplateColumns: 'repeat(auto-fill, minmax(288px, 1fr))',
            }}
          >
            {venues?.map((item: VenueItem) => (
              <VenueListItem
                key={item.id}
                venue={item}
                showSnackbar={setSnackbarData}
                onVenueClick={onVenueClick}
                addMode={addMode}
              />
            ))}
          </ImageList>
          <PaginationComponent
            page={pageNumber}
            size="large"
            onChange={onPaginationChange}
            count={lastPage}
            shape="rounded"
          />
          {snackbarData && (
            <CustomSnackbar
              text={`${snackbarData?.name} has been successfully picked`}
              image={snackbarData?.photo}
              open={Boolean(snackbarData)}
              onClose={() => setSnackbarData(null)}
            />
          )}
        </Box>
      )}
    </>
  )
}
