import { List, ListItem, ListItemText, styled } from '@material-ui/core'
import * as React from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { Loader } from '../../components/ui/Loader'
import { ConfirmDialog } from '../../components/ui/Modals/ConfirmDialog'
import { EventDraftStatus } from '../../constants'
import { UserRoles } from '../../constants/profile'
import { useUrlParams } from '../../hooks/useUrlParams'
import { t } from '../../utils/i18n'
import {
  dashboardBusinessDetailsPath,
  dashboardPath,
  eventPath,
  eventsListPath,
  profilePath,
} from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../utils/providers/EventsProvider'

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    line-height: 28px;
  }
`

export const SuccessRegistered = () => {
  const history = useHistory()
  const { events } = useStorageEventsData()
  const { isLoading, authUser } = useAuthData()
  const { urlParams } = useUrlParams()
  const isBusinessUser = !isLoading && !!authUser?.role

  const activeEvent = events[0]

  const handleGoToEvent = () => {
    if (activeEvent?.status !== EventDraftStatus) {
      history.push(
        generatePath(eventPath, {
          id: activeEvent?.id,
        })
      )
    } else {
      history.push(eventsListPath)
    }
  }

  if (urlParams.toEvent) {
    return (
      <ConfirmDialog
        confirmLabel={t('common.button.toEvent', 'Go to event')}
        dialogTitle={t(
          'messages.registration.success.title',
          'Account registered'
        )}
        cancelLabel={'label'}
        onClose={handleGoToEvent}
        open={true}
      >
        <List>
          <ListItem disableGutters>
            <StyledListItemText
              primary={t(
                'messages.registration.success.toEvent',
                'Your account has been successfully created. You may now continue planning your event.'
              )}
            />
          </ListItem>
        </List>
        {isLoading && <Loader position="absolute" />}
      </ConfirmDialog>
    )
  }

  const handleGoProfileAction = () => {
    if (UserRoles.isClient(authUser?.role)) {
      history.push(profilePath)
    } else {
      history.push(
        authUser?.listing_id
          ? generatePath(dashboardBusinessDetailsPath, {
              listingId: authUser?.listing_id,
            })
          : dashboardPath
      )
    }
  }

  return (
    <ConfirmDialog
      confirmLabel={t('common.button.toProfile', 'Go to profile')}
      dialogTitle={t(
        'messages.registration.success.title',
        'Account registered'
      )}
      cancelLabel={'label'}
      onClose={handleGoProfileAction}
      open={true}
    >
      <List>
        <ListItem disableGutters>
          <StyledListItemText
            primary={
              isBusinessUser
                ? t(
                    'messages.registration.success.business',
                    'Your account has been successfully created. You may now create your business listing.'
                  )
                : t(
                    'messages.registration.success.client',
                    'Your account has been successfully created. You may now fill out your profile and set a password.'
                  )
            }
          />
        </ListItem>
      </List>
      {isLoading && <Loader position="absolute" />}
    </ConfirmDialog>
  )
}
