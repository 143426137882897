import * as yup from 'yup'

import { DECIMAL_NUMBER_REGEXP } from '../../../constants'

export const generateInvoiceValidationSchema = () =>
  yup.object().shape({
    initialDueDate: yup
      .date()
      .when('initialPaymentPercent', (initialPaymentPercent) => {
        if (+initialPaymentPercent === 0 || +initialPaymentPercent === 100) {
          return yup.string()
        } else {
          return yup.string().required()
        }
      }),
    finalDueDate: yup.date().required(),
    initialPaymentPercent: yup
      .string()
      .required('Initial payment percent is required'),
    lineItems: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required('Line item title is required'),
          quantity: yup
            .number()
            .min(0.01, 'Minimal quantity is 0.01')
            .required(),
          price: yup
            .string()
            .trim()
            .matches(
              DECIMAL_NUMBER_REGEXP,
              '5 digits before and 2 digits after decimal separator are allowed'
            )
            .required('Price is required'),
          clarification: yup.string().max(255),
          groupDescription: yup.string().max(255),
          groupName: yup
            .string()
            .max(100)
            .when('groupDescription', (groupDescriptionValue) => {
              if (!groupDescriptionValue) {
                return yup.string().max(100)
              } else {
                return yup.string().max(100).required()
              }
            }),
        })
      )
      .min(1)
      .required('Line items is required for invoice'),
    message: yup.string(),
    extra: yup.string(),
    paymentTerms: yup.string(),
    pricingCanevas: yup.string(),
  })
