import { ForwardedRef, forwardRef } from 'react'

import { FormHelperText, FormHelperTextProps } from './FormHelperText'

type FormErrorProps = FormHelperTextProps & {
  visible?: boolean
}

export const FormError = forwardRef(function FormError(
  { visible = true, children, ...props }: FormErrorProps,
  ref: FormHelperTextProps['ref']
) {
  return (
    <FormHelperText error sx={{ marginLeft: 0 }} {...props} ref={ref}>
      {visible && children}
    </FormHelperText>
  )
})
