import List from '@material-ui/core/List'
import * as React from 'react'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { VenueItem } from '../../../types/Venue'
import { LikesDataProvider } from '../../../utils/providers/LikesProvider'
import { VenueListItemVertical } from '../components/VenueListItem/VenueListItemVertical'

interface VenueListProps {
  venues: VenueItem[]
}

export const VenueListVertical: React.FC<VenueListProps> = ({ venues }) => {
  const { isMobile } = useWindowSize()

  return (
    <List dense>
      {venues.map((item: VenueItem) => (
        <VenueListItemVertical key={item.id} venue={item} />
      ))}
    </List>
  )
}
