import { Box, IconButton, InputAdornment } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Field, useFormikContext } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { TextField as FormikTextField } from '../../../../components/formik'
import {
  MIN_PASSWORD_LENGTH,
  SECURITY_FORM_VIEW_STATE,
} from '../../../../constants/profile'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { messagesWarningDeactivatePath } from '../../../../utils/paths'
import {
  FieldNameTypography,
  FormWrapper,
  ProfileDivider,
  StyledCancelTypography,
  StyledGradientButton,
} from '../../Profile.styles'
import { ActionButtons } from '../ui/ActionButtons'

export const SecurityForm: React.FC = () => {
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const [showPassword, setShowPassword] = React.useState(false)
  const [formFieldsViewState, setFormFieldsViewState] = React.useState(
    SECURITY_FORM_VIEW_STATE
  )
  const { isValid } = useFormikContext<any>()
  const { t } = useTranslation()

  const onSwitchMode = (fieldName: string, isViewMode: boolean) => {
    setFormFieldsViewState({ ...formFieldsViewState, [fieldName]: isViewMode })
  }

  const deactivate = () => {
    history.push(messagesWarningDeactivatePath)
  }

  return (
    <FormWrapper>
      <Box
        display="flex"
        my={5}
        justifyContent="space-between"
        position="relative"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <FieldNameTypography>
          {t('profile.password.field', 'Password')}
        </FieldNameTypography>
        {!formFieldsViewState.currentPassword && (
          <Box>
            <Box
              mb={4}
              mt={isMobile ? '16px' : 0}
              width={isMobile ? '100%' : 'auto'}
            >
              <InputLabel sx={{ marginBottom: isMobile ? '16px' : 0 }}>
                {t('auth.password.newPassword', 'New password')}
              </InputLabel>
              <Field
                name="newPassword"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => {
                        event.preventDefault()
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                component={FormikTextField}
                inputProps={{
                  minLength: MIN_PASSWORD_LENGTH,
                }}
              />
            </Box>
            <Box
              mb={4}
              mt={isMobile ? '16px' : 0}
              width={isMobile ? '100%' : 'auto'}
            >
              <InputLabel sx={{ marginBottom: isMobile ? '16px' : 0 }}>
                {t('auth.password.confirmPassword', 'Confirm password')}
              </InputLabel>
              <Field
                name="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => {
                        event.preventDefault()
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                component={FormikTextField}
                inputProps={{
                  minLength: MIN_PASSWORD_LENGTH,
                }}
              />
            </Box>
            <StyledGradientButton type="submit" disabled={!isValid}>
              {t('profile.password.update', 'Update password')}
            </StyledGradientButton>
          </Box>
        )}
        <ActionButtons
          className="action-btn"
          onSwitchMode={(isViewMode) =>
            onSwitchMode('currentPassword', isViewMode)
          }
        />
      </Box>
      <ProfileDivider />
      {/*<Box display="flex" justifyContent="space-between" my={5}>*/}
      {/*  <Box display="flex">*/}
      {/*    <FieldNameTypography>Two-factor authentication</FieldNameTypography>*/}
      {/*    <Typography variant="body1" color="textSecondary">*/}
      {/*      Set up two-factor authentication*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*  <ActionButtons  className="action-btn" onSwitchMode={(isViewMode) => onSwitchMode('twoFactorAuth', isViewMode)} />*/}
      {/*</Box>*/}
      {/*<Divider />*/}
      <Box display="flex" justifyContent="space-between" my={5}>
        <FieldNameTypography>
          {t('profile.account.deactivate.field', 'Deactivate account')}
        </FieldNameTypography>
        {formFieldsViewState.isActive && (
          <Box display="flex">
            <StyledCancelTypography
              sx={{ '&:hover': { opacity: 0.8 } }}
              onClick={deactivate}
            >
              {t('profile.account.deactivate.action', 'Deactivate')}
            </StyledCancelTypography>
          </Box>
        )}
      </Box>
      <ProfileDivider />
    </FormWrapper>
  )
}
