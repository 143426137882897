const Copy = ({ fill = '#9396A3' }: { fill?: string }) => {
  return (
    <svg width="18" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 21.5h12.563a.188.188 0 0 0 .187-.188V20a.188.188 0 0 0-.188-.188H2.438V3.688A.188.188 0 0 0 2.25 3.5H.937a.188.188 0 0 0-.187.188V20.75c0 .415.335.75.75.75Zm3-3h12a.75.75 0 0 0 .75-.75V5.312c0-.2-.08-.39-.22-.53L12.968.72a.777.777 0 0 0-.174-.129V.547h-.098A.739.739 0 0 0 12.438.5H4.5a.75.75 0 0 0-.75.75v16.5c0 .415.335.75.75.75Zm8.297-15.567 2.018 2.02h-2.018v-2.02Zm-7.36-.745h5.86v3.328c0 .518.42.937.937.937h3.329v10.36H5.437V2.188Z"
        fill={fill}
      />
    </svg>
  )
}

export default Copy
