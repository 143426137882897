import * as React from 'react'

import DOCDocument from '../../components/svg/dashboard/DOCDocument'
import TXTDocument from '../../components/svg/dashboard/TXTDocument'
import XLSDocument from '../../components/svg/dashboard/XLSDocument'
import XLSXDocument from '../../components/svg/dashboard/XLSXDocument'
import PDFDocument from '../../components/svg/PDFDocument'
import { getPartnerParams } from '../../constants/partners'
import { post } from '../api/api'
import { getDownloadFileApiPath } from '../paths'

export const convertFileSizeToReadableSize = (size: number) => {
  const units = ['Bytes', 'KB', 'MB', 'GB']
  let i = 0

  while (size >= 1024) {
    size /= 1024
    i++
  }
  return Math.round(size * 100) / 100 + units[i]
}

export const getDocumentIcon = (documentType: string) => {
  switch (documentType) {
    case 'pdf':
      return <PDFDocument />
    case 'doc' || 'docx':
      return <DOCDocument />
    case 'xlsx':
    case 'lsx':
      return <XLSXDocument />
    case 'xls':
      return <XLSDocument />
    default:
      return <TXTDocument />
  }
}

export function downloadUserFile(fileId: number) {
  const apiPath = getDownloadFileApiPath(fileId)
  return post(apiPath, getPartnerParams())
}
