import { Box, Typography } from '@material-ui/core'
import { flatten } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InquiryInvoiceStatuses } from '../../../constants/inquiry'
import { useInvoicesBundlesByStatuses } from '../../../hooks/useInvoicesBunslesByStatuses'
import { InquiryInvoiceResponse } from '../../../types/dtos/inquiry'
import GradientButton from '../../ui/Buttons/GradientButton'

import { InvoiceDocument } from './InvoiceDocument'

export const InvoicesDocumentsSection: React.FC<{
  bundles: InquiryInvoiceResponse[]
}> = ({ bundles }) => {
  const { t } = useTranslation()
  const { notOutdatedInvoicesBundles, outdatedInvoicesBundles } =
    useInvoicesBundlesByStatuses(bundles)
  const [showAll, setShowAll] = useState(false)

  const notOutdatedInvoices = useMemo(() => {
    if (!notOutdatedInvoicesBundles?.length) return

    return flatten(
      notOutdatedInvoicesBundles.map((bundle) =>
        bundle.invoices.map((invoice) => {
          return { created_at: bundle.created_at, ...invoice }
        })
      )
    )
  }, [notOutdatedInvoicesBundles])

  const pendingInvoices = useMemo(() => {
    if (!notOutdatedInvoices?.length) return

    return notOutdatedInvoices?.filter(
      (invoice) => invoice.status === InquiryInvoiceStatuses.STATUS_PENDING
    )
  }, [notOutdatedInvoices])

  const waitingForPaidInvoices = useMemo(() => {
    if (!notOutdatedInvoices?.length) return

    return notOutdatedInvoices?.filter(
      (invoice) => invoice.status === InquiryInvoiceStatuses.STATUS_WAITING
    )
  }, [notOutdatedInvoices])

  const paidInvoices = useMemo(() => {
    if (!notOutdatedInvoices?.length) return

    return notOutdatedInvoices?.filter(
      (invoice) => invoice.status === InquiryInvoiceStatuses.STATUS_PAID
    )
  }, [notOutdatedInvoices])

  const outdatedInvoices = useMemo(() => {
    if (!outdatedInvoicesBundles?.length) return

    return flatten(
      outdatedInvoicesBundles.map((bundle) =>
        bundle.invoices.map((invoice) => {
          return { created_at: bundle.created_at, ...invoice }
        })
      )
    )
  }, [outdatedInvoicesBundles])

  return (
    <Box>
      <Typography>
        {t('inquiry.documents.invoices.section', 'Invoices')}
      </Typography>
      {!!pendingInvoices?.length && (
        <Box mt={4}>
          <Typography variant="body2" mb={2}>
            {t('inquiry.invoices.pending', 'Pending')}
          </Typography>
          {pendingInvoices.map((invoice) => (
            <InvoiceDocument
              key={invoice.id}
              invoice={invoice}
              date={invoice.created_at}
            />
          ))}
        </Box>
      )}
      {!!waitingForPaidInvoices?.length && (
        <Box mt={4}>
          <Typography variant="body2" mb={2}>
            {t('inquiry.invoices.processing', 'Processing')}
          </Typography>
          {waitingForPaidInvoices.map((invoice) => (
            <InvoiceDocument
              key={invoice.id}
              invoice={invoice}
              date={invoice.created_at}
            />
          ))}
        </Box>
      )}
      {!!paidInvoices?.length && (
        <Box mt={4}>
          <Typography variant="body2" mb={2}>
            {t('inquiry.invoices.paid', 'Paid')}
          </Typography>
          {paidInvoices.map((invoice) => (
            <InvoiceDocument
              key={invoice.id}
              invoice={invoice}
              date={invoice.created_at}
            />
          ))}
        </Box>
      )}
      {!!outdatedInvoices?.length && (
        <>
          <Box display="flex" justifyContent="flex-end" mb={5}>
            <GradientButton onClick={() => setShowAll(!showAll)}>
              {showAll
                ? t('inquiry.versions.previous.hide', 'Hide old versions')
                : t('inquiry.versions.previous.show', 'Show old versions')}
            </GradientButton>
          </Box>
          {showAll && (
            <>
              <Typography variant="body2" mb={2}>
                {t('inquiry.invoices.outdated', 'Outdated')}
              </Typography>{' '}
              {outdatedInvoices.map((invoice) => (
                <InvoiceDocument
                  key={invoice.id}
                  invoice={invoice}
                  date={invoice.created_at}
                />
              ))}
            </>
          )}
        </>
      )}
    </Box>
  )
}
