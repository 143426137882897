import { ButtonProps, styled, Typography, useTheme } from '@material-ui/core'
import * as React from 'react'

import EditIcon from '../../../svg/EditIcon'
import { SimpleButton } from '../SimpleButton'

const StyledButton = styled(SimpleButton)(
  ({ theme }) => `
  padding: 10px 0;
  line-height: 30px;
  border-color: ${theme.palette.primary.light};
  color: ${theme.palette.common.white};

  &:hover {
    background: transparent;
    opacity: 0.8;
  }

  .MuiTouchRipple-root {
    display: none;
  }
`
)

export const EditButton: React.FC<
  ButtonProps & { text?: string; textColor?: string }
> = ({ onClick = () => {}, text = 'Edit', textColor, ...props }) => {
  const { variant } = props
  const theme = useTheme()

  return (
    <StyledButton sx={props.sx} variant={variant} onClick={onClick}>
      <EditIcon fill={textColor || theme.palette.primary.light} />
      <Typography
        ml={2}
        sx={{ color: (theme) => textColor || theme.palette.primary.light }}
      >
        {text}
      </Typography>
    </StyledButton>
  )
}
