import { CancelToken } from 'axios'
import { get as lodashGet, has as lodashHas, set as lodashSet } from 'lodash'
import { QueryClient, useMutation, useQuery } from 'react-query'

import { SERVER_EVENTS_QUERY_KEY } from '../../constants'
import { EventType, EventTypesRecord } from '../../types/Event'
import {
  Inquiry,
  InquiryEvent,
  InquiryStatusesData,
  InquiryWithDetails,
} from '../../types/Inquiry'
import { ProfileData } from '../../types/Profile'
import { clearObject } from '../helpers/data'
import { mergeInquiries } from '../helpers/inquiries'
import { jotFormsMapping } from '../mappers/events'
import {
  businessEventsApiPath,
  clientEventsApiPath,
  eventStylesApiPath,
  getEventAddVendorApiPath,
  getEventApiPath,
  getEventTypesApiPath,
  getInquiryApiPath,
  guestJotFormsPath,
  inquiryStatusesApiPath,
  jotFormsPath,
  updateEventInquiriesApiPath,
} from '../paths'

import { deleteRequest, get, post, put } from './api'

async function getUserTypes(partner?: string): Promise<EventTypesRecord> {
  const rawEventTypes: EventType[] = await get(getEventTypesApiPath(partner))
  if (Array.isArray(rawEventTypes)) {
    const eventTypes: EventTypesRecord = {}
    rawEventTypes.forEach((item) => {
      eventTypes[item.slug] = item
    })

    return eventTypes
  } else return rawEventTypes
}

async function getStyles(): Promise<any> {
  return await get(eventStylesApiPath())
}

export function useEventTypes(
  partner?: string,
  fetchOptions: { enabled: boolean } = { enabled: true }
) {
  const key = partner ? ['eventTypes', partner] : 'eventTypes'
  return useQuery<EventTypesRecord, Error>(key, () => getUserTypes(partner), {
    ...fetchOptions,
  })
}

export function useEventStyles() {
  return useQuery<EventTypesRecord, Error>({
    queryKey: 'eventStyles',
    queryFn: getStyles,
  })
}

export async function fetchBusinessBookings(
  businessId: string,
  options: any = {}
): Promise<any> {
  return await get(businessEventsApiPath(businessId), {
    ...clearObject(options),
  })
}

export async function fetchClientEvents(): Promise<any> {
  return await get(clientEventsApiPath)
}

export function useServerEvents(
  user: ProfileData | null,
  fetchOptions: { enabled: boolean } = { enabled: false }
) {
  const queryKey = [SERVER_EVENTS_QUERY_KEY, user?.id]

  return useQuery<InquiryEvent[], Error>(queryKey, () => fetchClientEvents(), {
    ...fetchOptions,
  })
}

export async function updateServerEvent(data: Partial<InquiryEvent>) {
  if (data?.id && Object.keys(data).length === 1) {
    return await get(
      getInquiryApiPath(data.id.toString() || ''),
      {},
      { forceUpdate: true }
    )
  }
  return await put(getInquiryApiPath(data?.id?.toString() || ''), data)
}

export async function getEvent(id: string, cancelToken?: CancelToken) {
  return await get(getEventApiPath(id || ''), undefined, {
    cancelToken,
    forceUpdate: true,
  })
}

export function useServerEventUpdate(
  user: ProfileData | null,
  queryClient: QueryClient
) {
  const queryKey = [SERVER_EVENTS_QUERY_KEY, user?.id]

  return useMutation([SERVER_EVENTS_QUERY_KEY], updateServerEvent, {
    onSuccess: (data: Inquiry) => {
      const previousData = queryClient.getQueryData<
        InquiryEvent[] | { data: InquiryEvent[] }
      >(queryKey)
      const isPaginated = !Array.isArray(previousData)
      let previousEvents = previousData

      if (!Array.isArray(previousEvents) && previousEvents?.data) {
        previousEvents = previousEvents.data as InquiryEvent[]
      } else {
        previousEvents = previousData as InquiryEvent[]
      }

      const updatedEvent = previousEvents?.find(
        (event) => event.id === data.event_id
      )

      if (!updatedEvent) {
        queryClient.setQueryData(queryKey, [])
      } else {
        const inquiryDetailsKey = ['inquiry', data.id.toString()]
        updatedEvent.inquiry = data
        updatedEvent.inquiries_related = [data]

        const mergedInquiries = mergeInquiries(
          updatedEvent,
          previousEvents || []
        )
        if (isPaginated) {
          queryClient.setQueryData(queryKey, {
            ...previousData,
            data: mergedInquiries,
          })
        } else {
          queryClient.setQueryData(queryKey, mergedInquiries)
        }

        const previousInquiry =
          queryClient.getQueryData<InquiryWithDetails>(inquiryDetailsKey)

        if (previousInquiry) {
          queryClient.setQueryData(inquiryDetailsKey, {
            ...previousInquiry,
            status: data.status,
          })
        }
      }
    },
  })
}

async function fetchInquiryStatuses(): Promise<any> {
  const data = await get(inquiryStatusesApiPath)

  // An override required for release compatibility
  // TODO: Remove this in BTT-503
  if (!lodashHas(data, 'inquiryStatus.stages.invoicing')) {
    return lodashSet(
      data,
      'inquiryStatus.stages.invoicing',
      lodashGet(data, 'inquiryStatus.stages.contracting', {})
    )
  }

  return data
}

export function useEventInquiriesStatuses() {
  return useQuery<InquiryStatusesData, Error>(['inquiryStatuses'], () =>
    fetchInquiryStatuses()
  )
}

export async function removeEvent(eventId: string) {
  return deleteRequest(updateEventInquiriesApiPath(eventId))
}

export async function postJotForm(data: any, isAuth: boolean): Promise<any> {
  const apiPath = isAuth ? jotFormsPath : guestJotFormsPath
  return post(apiPath, data)
}

export async function fetchJotForms(event_id: number): Promise<any> {
  const response = await get(jotFormsPath, { event_id }, { forceUpdate: true })
  const preparedJotFormAnswers = jotFormsMapping(response)
  return preparedJotFormAnswers || []
}

export function useJotForms(eventId: number, enabled: boolean) {
  return useQuery<any, Error>(['jotForms'], () => fetchJotForms(eventId), {
    enabled,
    cacheTime: 0,
  })
}

export function addVendorToServerEvent(
  eventId: string,
  vendor_id?: number,
  package_id?: number
) {
  return post(getEventAddVendorApiPath(eventId), { vendor_id, package_id })
}
