import { Box, styled, Typography, useTheme } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import * as React from 'react'

import PictureOutlined from '../../../../components/svg/PictureOutlined'
import { Image } from '../../../../components/ui/Image'
import { Loader } from '../../../../components/ui/Loader'
import { ConfirmModal } from '../../../../components/ui/Modals/ConfirmModal'
import { PopperMenu } from '../../../../components/ui/PopperMenu'
import { getMenuActionsByStatus } from '../../../../constants/events'
import { useConfirmModal } from '../../../../hooks/useConfirmModal'
import { LocalEvent } from '../../../../types/Event'
import { useEventInquiriesStatuses } from '../../../../utils/api/events'
import { convertDateToShortString } from '../../../../utils/helpers/date'
import { PhotoPlaceholderContainer } from '../EventItem/EventsItem.styles'

export const EventContainer = styled(Box)<any>(
  ({ theme }) => `
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    background-color: #fffff;
    box-shadow: 0px 4px 18px rgba(210, 210, 210, 0.4);
`
)

export const EventStatus = styled(Box)<{ borderColor: boolean }>(
  (props) => `
  background: #f7f7f8;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 8px;
  height: 20px;
  border: 1px solid ${props.borderColor};
  border-radius: 2px;
  margin-right: 20px;
`
)

export const EventListMobileTableViewItem: React.FC<{
  event: LocalEvent
  isLoading: boolean
  handleOnEventSelect: (action: string, event: LocalEvent) => void
  handleEventClick: (event: LocalEvent) => void
}> = ({ event, isLoading, handleOnEventSelect, handleEventClick }) => {
  const { data: statusesData } = useEventInquiriesStatuses()
  const inquiryStatuses = statusesData?.inquiryStatus?.stages || {}
  const {
    isOpen: isChooseVenueModalOpen,
    toggleModal: toggleChooseVenueModel,
    modalData: chooseVenueModalData,
  } = useConfirmModal()
  const theme = useTheme()

  return (
    <EventContainer>
      <Box
        display="flex"
        justifyContent="flex-start"
        mb={2}
        mr={3}
        onClick={() => handleEventClick(event)}
      >
        {event.venue?.photo?.path ? (
          <Image
            style={{ width: 48, height: 48 }}
            image={event.venue?.photo}
            imageSize="small"
            alt=""
          />
        ) : (
          <PhotoPlaceholderContainer sx={{ width: 48, height: 48 }}>
            <PictureOutlined />
          </PhotoPlaceholderContainer>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" width={1}>
        <Box
          display="flex"
          flexDirection="column"
          onClick={() => handleEventClick(event)}
        >
          <Typography
            fontWeight={600}
            fontFamily="Poppins"
            fontSize={14}
            mb={2}
          >
            {event.name}
          </Typography>
          <Typography style={{ textTransform: 'uppercase' }} fontSize={14}>
            {event.start_date
              ? convertDateToShortString(event.start_date, true)
              : 'MM DD YYYY'}
          </Typography>
        </Box>
        {/*@ts-ignore*/}
        <EventStatus borderColor={inquiryStatuses[event.status]?.border_color}>
          <Typography
            fontSize={12}
            color={inquiryStatuses[event.status]?.text_color}
          >
            {inquiryStatuses[event.status]?.label}
          </Typography>
        </EventStatus>
      </Box>
      <Box display="flex">
        <PopperMenu
          options={getMenuActionsByStatus(
            event.status,
            theme.palette.secondary.dark
          )}
          isFullScreen={false}
          onSelectAction={(action) => handleOnEventSelect(action, event)}
        >
          <MoreHoriz className="event-action" />
        </PopperMenu>
      </Box>
      <ConfirmModal
        buttons={[
          { label: 'Venue listing', action: chooseVenueModalData.onAccept },
        ]}
        open={isChooseVenueModalOpen}
        onClose={toggleChooseVenueModel}
        type={chooseVenueModalData.type}
        title={chooseVenueModalData.title}
        text={chooseVenueModalData.text}
        onAccept={chooseVenueModalData.onAccept}
      />
      {isLoading && <Loader position="fixed" />}
    </EventContainer>
  )
}
