import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePossibleContractUpload } from '../../../hooks/usePossibleContractUpload'
import { InquiryContractResponse } from '../../../types/dtos/inquiry'
import { InquiryWithDetails } from '../../../types/Inquiry'
import { InquiryFlowTableWarning } from '../BookingDetails/components/InquiryFlowTableWarning'
import { AddNewInquiryFlowElementButton } from '../components/InquiryFlowTables/AddNewInquiryFlowElementButton'
import { ContractsTable } from '../components/InquiryFlowTables/ContractsTable'

interface BookingContractsProps {
  inquiryDetails?: InquiryWithDetails
  contracts?: InquiryContractResponse[]
  onGenerate: () => void
}

export const BookingContracts: React.FC<BookingContractsProps> = ({
  contracts,
  inquiryDetails,
  onGenerate,
}) => {
  const { t } = useTranslation()
  const isPossibleContractUpload = usePossibleContractUpload(inquiryDetails)

  return (
    <>
      <Typography variant="h4" fontFamily="Poppins" fontWeight={600} mb="24px">
        {t('business.contracts.list', 'Contracts & Documents')}
      </Typography>
      {!contracts?.length ? (
        <InquiryFlowTableWarning
          isGenerateBtnDisabled={true}
          onGenerate={onGenerate}
          type="contract"
        />
      ) : (
        <>
          <ContractsTable contracts={contracts} files={inquiryDetails?.files} />
          {isPossibleContractUpload && (
            <AddNewInquiryFlowElementButton
              onClick={onGenerate}
              btnText={t('business.contracts.addNew', 'Add new contract')}
            />
          )}
        </>
      )}
    </>
  )
}
