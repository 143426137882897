import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import logo from '../../assets/images/logo.png'
import img from '../../assets/images/test-venue-item.jpg'
import { MessagesHeaderBox } from '../../pages/events/Event/components/EventServices/EventServices.styles'
import {
  MessageItem,
  MessagesThreadData,
  MessageTemplateData,
} from '../../types/dtos/messages'
import {
  createMessagesThread,
  downloadThreadFile,
  saveTemplate,
  updateMessagesThread,
  useMessagesByThread,
  useMessagesTemplates,
  useThreads,
} from '../../utils/api/messages'
import { messagesMapping } from '../../utils/mappers/messages'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { MediaLibraryProvider } from '../../utils/providers/MediaLibraryProvider'
import { ChatBox } from '../ChatBox'
import CloseIcon from '../svg/CloseIcon'
import { Image } from '../ui/Image'

// TODO: refactor sales + use messagging logic
export const Messenger: React.FC<{
  data: any
  onToggleMessenger?: () => void
  isSales?: boolean
  isModal?: boolean
}> = ({ data, onToggleMessenger, isSales = false, isModal = true }) => {
  const [messages, setMessages] = React.useState<MessageItem[]>([])
  const { authUser, isLoading: authLoading } = useAuthData()
  const isBusinessUser = !authLoading && !!authUser?.role
  const { t } = useTranslation()
  const contextEventId = isSales && !isBusinessUser ? data?.id : undefined
  const contextInquiryId =
    isBusinessUser || (!isBusinessUser && !isSales)
      ? data?.inquiry_id
        ? Number(data?.inquiry_id)
        : Number(data?.id)
      : undefined
  const contextId = contextEventId || contextInquiryId

  const threadParams = {
    event_id: contextEventId,
    inquiry_id: contextInquiryId,
    isSales,
  }

  const {
    data: threads,
    isLoading,
    refetch: refreshThreads,
  } = useThreads(!!authUser && !!contextId, threadParams)
  const { data: currentThread, refetch: refreshThread } = useMessagesByThread(
    Boolean(
      !isLoading && !!authUser && threads && threads[0] && threads[0]?.id
    ),
    threads && threads[0]?.id,
    isSales
  )
  const { data: templates, refetch: refreshTemplates } = useMessagesTemplates(
    !!authUser && isBusinessUser
  )

  const onSendMessage = async (messageData: MessagesThreadData) => {
    const { message, attachment: file } = messageData
    const attachment = {
      attachment: !file || (file && 'id' in file && file?.id) ? null : file,
      attachment_id: (file && 'id' in file && file?.id) || null,
      attachment_type: file && 'id' in file && file?.id ? file?.type : null,
    }

    if (!threads?.length) {
      const threadData = {
        message,
        ...attachment,
        event_id: contextEventId,
        inquiry_id: contextInquiryId,
      }
      await createMessagesThread(threadData, isSales)
      await refreshThreads()
    } else {
      const data = {
        message,
        ...attachment,
      }
      await updateMessagesThread(currentThread?.id, isSales, data)
      await refreshThread()
    }
  }

  const onSaveTemplate = async (template: MessageTemplateData) => {
    const { name, message, attachment: file } = template
    const attachment = {
      attachment: !file || (file && 'id' in file && file?.id) ? null : file,
      attachment_id: (file && 'id' in file && file?.id) || '',
      attachment_type: file && 'id' in file && file?.id ? file?.type : '',
    }
    const data = { name, message, ...attachment }
    await saveTemplate(data)
    await refreshTemplates()
  }

  const handleDocumentDownload = async (fileId: string) => {
    const response = await downloadThreadFile(
      currentThread?.id,
      isSales,
      fileId
    )
    window.open(response, '_blank')
  }

  useEffect(() => {
    if (!!authUser && currentThread && currentThread.messages?.length) {
      const preparedMessages = messagesMapping(
        currentThread.messages,
        authUser?.id
      )
      setMessages(preparedMessages)
    }
  }, [authUser, currentThread])

  const toggleMessenger = (e: any) => {
    e.stopPropagation()
    onToggleMessenger && onToggleMessenger()
  }

  return (
    <MediaLibraryProvider>
      <Box width={1} height={1}>
        <MessagesHeaderBox>
          <Box display="flex" width={1}>
            {isSales ? (
              <img src={logo} width="60px" height="60px" alt="" />
            ) : (
              <Image
                image={data?.image || data?.venue?.images?.[0]}
                imageSize="small"
                staticImageType="venue"
                style={{ width: 60, height: 60 }}
              />
            )}
            <Box ml={6} width={1}>
              {isModal && (
                <Box width={1} display="flex" justifyContent="space-between">
                  {isSales ? (
                    <Typography fontWeight={600}>
                      {t('chat.concierge', 'Concierge support')}
                    </Typography>
                  ) : (
                    <Typography fontWeight={600}>
                      {data?.name || data?.vendor?.name || data?.venue?.name}
                    </Typography>
                  )}
                  <Box
                    sx={{ '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                    onClick={toggleMessenger}
                  >
                    <CloseIcon />
                  </Box>
                </Box>
              )}
              <Typography color="textSecondary" style={{ marginTop: '10px' }}>
                {authUser?.role
                  ? data?.event?.user_name
                  : data?.vendor?.contact_name}
              </Typography>
            </Box>
          </Box>
        </MessagesHeaderBox>
        <ChatBox
          contextId={contextId}
          onFileDownload={handleDocumentDownload}
          isModal={isModal}
          messages={messages}
          templates={templates}
          onRefreshThread={refreshThread}
          unread={currentThread?.last_unread || 0}
          onSendMessage={onSendMessage}
          onSaveTemplate={onSaveTemplate}
        />
      </Box>
    </MediaLibraryProvider>
  )
}
