import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useWindowSize } from '../../../../hooks/useWindowSize'
import RoomsSlider from '../../../../pages/venues/components/RoomsSlider/RoomsSlider'
import { Room } from '../../../../types/Venue'

export const RoomsList: React.FC<{ rooms: Room[] }> = ({ rooms }) => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  return (
    <RoomsSlider
      slidesPerView={!isMobile ? 4 : undefined}
      slideWidth={288}
      slideImageHeight={276}
      data={rooms}
      sliderTitle={t('venue.rooms', 'Venue rooms')}
      withoutEndFrame={!!isMobile}
    />
  )
}
