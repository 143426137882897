import { Box, BoxProps } from '@material-ui/core'
import * as React from 'react'
import { MutableRefObject, useRef, useState } from 'react'

export const PortalDataContext = React.createContext<{
  setBoxProps: (boxProps: BoxProps) => void
  container: MutableRefObject<Element | (() => Element | null) | null>
}>({
  setBoxProps: () => {},
  container: {
    current: null,
  },
})

export const PortalDataProvider: React.FC = ({ children }) => {
  const [boxProps, setBoxProps] = useState<BoxProps>({})
  const container = useRef<Element | (() => Element | null) | null>(null)

  const context = React.useMemo(
    () => ({
      container,
      setBoxProps,
    }),
    [container, setBoxProps]
  )

  return (
    <PortalDataContext.Provider value={context}>
      {children}
      <Box className="portal" {...boxProps} ref={container} />
    </PortalDataContext.Provider>
  )
}

export const usePortalData = (boxProps: BoxProps = {}) => {
  const context = React.useContext(PortalDataContext)
  if (context === undefined) {
    throw new Error('Error using portalProvider')
  }
  context.setBoxProps(boxProps)

  return context
}
