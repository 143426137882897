import { Box, ClickAwayListener, styled } from '@material-ui/core'
import { ArrowUpward } from '@material-ui/icons'
import { toArray } from 'lodash'
import * as React from 'react'
import { useEffect, useLayoutEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { UserRoles } from '../../constants/profile'
import { usePartner } from '../../hooks/usePartner'
import useScrollDirection, { SCROLL_UP } from '../../hooks/useScrollDirection'
import { useWindowSize } from '../../hooks/useWindowSize'
import {
  clientCalendarPath,
  dashboardPath,
  eventPath,
  eventsListPath,
  maintenancePath,
  mobileMapPath,
} from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'
import tracking from '../../utils/tracking'
import Footer from '../Footer'
import Header from '../Header'
import { GlobalSearch } from '../Header/GlobalSearch'
import { PartnerHeader } from '../Header/PartnerHeader'
import { PromotionBanner } from '../PromotionBanner'
import { SideNav } from '../SideNav'

import { StyledSearchContainer } from './PageLayout.styles'

export const GoUpButton = styled(Box)<{ opacity: number }>`
  position: fixed;
  right: 24px;
  bottom: 125px;
  width: 48px;
  height: 48px;
  background: black;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
  transition: opacity 200ms ease-out;
  ${(props) =>
    props.opacity &&
    `
    opacity: 1;
  `}
`

export const PageLayout: React.FC = ({ children }) => {
  const { scrollDir, scrollTop } = useScrollDirection()
  const history = useHistory()
  const { authUser } = useAuthData()
  const isClient = !authUser?.role || UserRoles.isClient(authUser?.role)
  const { isMobile, windowSize } = useWindowSize()
  const { isAbs } = usePartner()
  const [shouldRenderHeader, setShouldRenderHeader] = React.useState<boolean>()
  const [shouldShowPromotion, setShouldShowPromotion] =
    React.useState<boolean>()
  const [renderSearch, setRenderSearch] = React.useState<boolean>(false)
  const formatPath = (path: string) => path.replace(/\/(\d+)\//, '/:id/')

  useLayoutEffect(() => {
    if (!isMobile) {
      if (isClient)
        setShouldRenderHeader(
          !formatPath(history.location.pathname).includes(maintenancePath)
        )
      else
        setShouldRenderHeader(
          !formatPath(history.location.pathname).includes(dashboardPath) &&
            !formatPath(history.location.pathname).includes(maintenancePath)
        )
    } else
      setShouldRenderHeader(
        !formatPath(history.location.pathname).includes(maintenancePath) &&
          !formatPath(history.location.pathname).includes(mobileMapPath)
      )
  }, [isMobile, isClient])

  useEffect(() => {
    setShouldShowPromotion(
      !isAbs &&
        isAbs !== undefined &&
        !formatPath(history.location.pathname).includes(dashboardPath) &&
        !formatPath(history.location.pathname).includes(eventsListPath) &&
        !formatPath(history.location.pathname).includes(eventPath) &&
        !formatPath(history.location.pathname).includes(clientCalendarPath) &&
        !formatPath(history.location.pathname).includes(mobileMapPath)
    )
  }, [isAbs])

  useEffect(() => {
    const unregister = history.listen(() => {
      tracking.pageView()
    })
    return () => unregister()
  }, [history])

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, -100)
  }, [pathname])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {shouldShowPromotion && <PromotionBanner />}
      {formatPath(history.location.pathname).includes(mobileMapPath) ? (
        <>{children}</>
      ) : !shouldRenderHeader ? (
        <Box display="flex">
          {!isMobile &&
          formatPath(history.location.pathname).includes(dashboardPath) &&
          !isAbs ? (
            <SideNav />
          ) : null}
          <Box display="flex" flexDirection="column" width={1}>
            <main
              style={{
                display: 'flex',
                width: '100%',
                paddingBottom: isAbs ? 0 : isMobile ? '160px' : '180px',
              }}
            >
              {children}
            </main>
            {!formatPath(history.location.pathname).includes(
              maintenancePath
            ) && <Footer />}
          </Box>
        </Box>
      ) : !isMobile ? (
        <>
          {isAbs ? <PartnerHeader /> : <Header />}
          <main
            style={{ paddingBottom: isAbs ? 0 : isMobile ? '160px' : '180px' }}
          >
            {children}
          </main>
          <Footer />
        </>
      ) : (
        <>
          {!renderSearch && (
            <Header
              setRenderSearch={setRenderSearch}
              transform={`translateY(${
                scrollDir === SCROLL_UP || scrollTop < 100 ? 0 : '-100%'
              })`}
              position="sticky"
            />
          )}
          {renderSearch && (
            <ClickAwayListener onClickAway={() => setRenderSearch(false)}>
              <StyledSearchContainer>
                <GlobalSearch setRenderSearch={setRenderSearch} />
              </StyledSearchContainer>
            </ClickAwayListener>
          )}
          <main
            style={{ paddingBottom: isAbs ? 0 : isMobile ? '160px' : '180px' }}
          >
            {children}
          </main>
          <Footer />
        </>
      )}
      {isMobile && document.body.scrollHeight > windowSize.height && (
        <GoUpButton onClick={scrollToTop} opacity={scrollTop > 200 ? 1 : 0}>
          <ArrowUpward />
        </GoUpButton>
      )}
    </>
  )
}
