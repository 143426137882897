import { Box, ListItemIcon, Typography } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { UserRoles } from '../../../constants/profile'
import { simpleModalControl } from '../../../hooks/useSimpleModal'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { Status } from '../../../pages/dashboard/BusinessDetails/components/Status'
import { onboardingVendorPath, onboardingVenuePath } from '../../../utils/paths'
import { NavLink } from '../../NavLink'
import Question from '../../svg/Question'
import { TooltipComponent } from '../Tooltip'

import { StyledList, StyledListItem } from './SideMenu.styles'

export interface SideMenuItem {
  path: string
  name: string
  icon: React.ReactElement
}

interface SideMenuProps {
  items: SideMenuItem[]
  className?: string
  businessType?: UserRoles
  businessStatus?: string
  handleBusinessPublish?: () => void
  previewModal?: simpleModalControl
  onItemClick?: (path: string) => void
  activeItem?: string
  withAddListing?: boolean
}

export const SideMenu: React.FC<SideMenuProps> = ({
  items,
  className,
  businessType,
  businessStatus,
  handleBusinessPublish,
  previewModal,
  onItemClick,
  activeItem,
  withAddListing,
}) => {
  const history = useHistory()

  const isActive = (value: string) => {
    return history.location.pathname === value || activeItem === value
  }
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  const getNavLinkProps = (path: string) => {
    return {
      onClick: () => {
        if (onItemClick) onItemClick(path)
        history.push(path)
      },
      className: isActive(path) ? 'active' : '',
    }
  }

  return (
    <Box
      style={{
        background: isMobile ? 'none' : '#f7f7f8',
        width: isMobile ? '100%' : '215px',
      }}
      display="flex"
      height="inherit"
      flexDirection="column"
      justifyContent="space-between"
    >
      <StyledList className={className}>
        {!isMobile && (
          <Typography
            sx={{ marginLeft: '20px', marginTop: '20px', marginBottom: '27px' }}
            color="#9396A3"
            fontSize={18}
            fontWeight={600}
          >
            Menu
          </Typography>
        )}
        {items.map((item) => (
          <StyledListItem
            button
            key={item.name}
            {...getNavLinkProps(item.path)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <Typography
              style={{ fontSize: isMobile ? 16 : 14 }}
              fontWeight={500}
            >
              {item.name}
            </Typography>
            {isMobile && (
              <NavigateNextIcon
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
            )}
          </StyledListItem>
        ))}
      </StyledList>
      {businessStatus && handleBusinessPublish && previewModal && (
        <Status
          status={businessStatus || ''}
          handleBusinessPublish={handleBusinessPublish}
          previewModal={previewModal}
        />
      )}
      {withAddListing && (
        <Box
          display="flex"
          justifyContent={isMobile ? 'flex-start' : 'center'}
          padding={isMobile ? 0 : '0 16px'}
          mt={isMobile ? '25px' : 0}
          mb={isMobile ? 0 : '65px'}
        >
          <NavLink
            to={
              businessType === UserRoles.Landlord
                ? onboardingVenuePath
                : onboardingVendorPath
            }
            sx={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <AddCircle sx={{ color: (theme) => theme.palette.primary.light }} />
            <Typography
              variant="body1"
              fontWeight="500"
              letterSpacing="0.01em"
              component="span"
              ml="11px"
              color="primary.light"
            >
              {t('business.listings.add.link', 'Add new listing')}
            </Typography>
          </NavLink>
          <TooltipComponent
            placement="top"
            title={
              businessType === UserRoles.Landlord
                ? t(
                    'business.listings.add.tooltip.venue',
                    'Want to list more than one space? Create another listing!'
                  )
                : t(
                    'business.listings.add.tooltip.vendor',
                    'Want to list another service? Create another listing!'
                  )
            }
          >
            <Box style={{ cursor: 'pointer' }} mt="-10px">
              <Question fill="#9396A3" />
            </Box>
          </TooltipComponent>
        </Box>
      )}
    </Box>
  )
}
