const TwitterIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 6.55556V15.4444C21 16.9179 20.4147 18.3309 19.3728 19.3728C18.3309 20.4147 16.9179 21 15.4444 21H6.55556C5.08213 21 3.66905 20.4147 2.62718 19.3728C1.58532 18.3309 1 16.9179 1 15.4444V6.55556C1 5.08213 1.58532 3.66905 2.62718 2.62718C3.66905 1.58532 5.08213 1 6.55556 1H15.4444C16.9179 1 18.3309 1.58532 19.3728 2.62718C20.4147 3.66905 21 5.08213 21 6.55556Z"
        stroke="#F7F7F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 7.94701C15.632 8.10701 15.237 8.21701 14.822 8.26501C15.246 8.01501 15.57 7.61901 15.724 7.14801C15.32 7.38348 14.8791 7.54913 14.42 7.63801C14.2269 7.43558 13.9945 7.27462 13.7372 7.16493C13.4798 7.05524 13.2028 6.99913 12.923 7.00001C11.79 7.00001 10.872 7.90501 10.872 9.02001C10.872 9.17801 10.89 9.33201 10.925 9.48001C10.1123 9.44142 9.31662 9.23385 8.58865 8.87056C7.86068 8.50726 7.21643 7.99621 6.697 7.37001C6.51456 7.67711 6.41851 8.02781 6.419 8.38501C6.419 9.08501 6.782 9.70501 7.332 10.066C7.00644 10.0558 6.68786 9.96913 6.402 9.81301V9.83801C6.40509 10.3075 6.5708 10.7613 6.87092 11.1224C7.17103 11.4834 7.587 11.7292 8.048 11.818C7.74536 11.898 7.42869 11.9096 7.121 11.852C7.25513 12.2559 7.51167 12.608 7.85502 12.8594C8.19837 13.1108 8.61148 13.2491 9.037 13.255C8.30696 13.8176 7.41064 14.1215 6.489 14.119C6.324 14.119 6.161 14.109 6 14.091C6.94047 14.6863 8.03096 15.0016 9.144 15C12.918 15 14.981 11.922 14.981 9.25201L14.974 8.99001C15.3763 8.70729 15.7239 8.3539 16 7.94701Z"
        fill="#F7F7F8"
      />
    </svg>
  )
}

export default TwitterIcon
