const Description = ({ fill = '#9396A3' }) => {
  return (
    <svg width="14" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.691 4.637 9.488.434A.624.624 0 0 0 9.047.25H.75a.624.624 0 0 0-.625.625v16.25c0 .346.28.625.625.625h12.5c.346 0 .625-.28.625-.625V5.08a.628.628 0 0 0-.184-.443Zm-1.257.73H8.758V1.691l3.676 3.676Zm.035 10.977H1.53V1.656H7.43v4.219a.82.82 0 0 0 .82.82h4.219v9.649ZM6.844 11.07H3.25a.157.157 0 0 0-.156.157v.937c0 .086.07.156.156.156h3.594c.086 0 .156-.07.156-.156v-.937a.157.157 0 0 0-.156-.157Zm-3.75-2.5v.938c0 .086.07.156.156.156h7.5c.086 0 .156-.07.156-.156V8.57a.157.157 0 0 0-.156-.156h-7.5a.157.157 0 0 0-.156.156Z"
        fill={fill}
      />
    </svg>
  )
}

export default Description
