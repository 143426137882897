import { Box, Typography } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { difference } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import GradientButton from '../../../../../../../components/ui/Buttons/GradientButton'
import { Loader } from '../../../../../../../components/ui/Loader'
import { useWindowSize } from '../../../../../../../hooks/useWindowSize'
import { VenueItem } from '../../../../../../../types/Venue'
import { StyledTextField } from '../../../../../../events/EventsList/EventsList.styles'
import { ModalWrapper } from '../../SelectAbsVenue.styles'

import { PartnerItem } from './PartnerItem'
import { ActionButtonsContainer } from './PartnersProperties.styles'

export const PartnersProperties: React.FC<{
  handleContinue: () => void
  venues: VenueItem[]
  isLoading: boolean
  currentVenue: VenueItem | null
  updateCurrentVenue: Dispatch<SetStateAction<VenueItem | null>>
}> = ({
  handleContinue,
  venues,
  isLoading,
  currentVenue,
  updateCurrentVenue,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const [filteredVenues, setFilteredVenues] = useState<VenueItem[]>([])
  const [query, setQuery] = React.useState('')

  const searchByName = (query: string) => {
    setQuery(query)
    if (query.length >= 1) {
      setFilteredVenues(
        filteredVenues.filter((venue) =>
          venue.name.toLowerCase().includes(query.toLocaleLowerCase())
        )
      )
    } else {
      setFilteredVenues([...venues])
    }
  }

  useEffect(() => {
    if (venues && venues.length) {
      setFilteredVenues(venues)
    }
  }, [venues.length])

  return (
    <ModalWrapper
      height={isMobile ? 'calc(100vh - 100px)' : '552px'}
      width="100%"
      maxWidth="1032px"
      padding={isMobile ? 0 : '12px 40px '}
    >
      <Typography variant="h1" lineHeight={1.32}>
        {t(
          'partners.abs.vendors.partnersProperties.title',
          'Partners properties'
        )}
      </Typography>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent={isMobile ? 'flex-start' : 'space-between'}
        sx={{ marginBottom: isMobile ? '10px' : 0 }}
      >
        <Typography
          variant="body1"
          fontSize={isMobile ? '16px' : '18px'}
          lineHeight={1.5}
          fontWeight={500}
          mt="16px"
          mb="24px"
        >
          {t(
            'partners.abs.vendors.partnersProperties.description',
            ' Please, Choose property for service ordering or event planing'
          )}
        </Typography>
        <Box
          display="flex"
          width={1}
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent={isMobile ? 'flex-start' : 'flex-end'}
        >
          <StyledTextField
            variant="standard"
            color="secondary"
            InputProps={{
              className: 'input-text',
              disableUnderline: true,
              startAdornment: <Search />,
            }}
            onChange={(e) => searchByName(e.target.value)}
            sx={{
              mr: isMobile ? 0 : 5,
              width: isMobile ? '100%' : 400,
              mb: isMobile ? 3 : 0,
            }}
            placeholder={t(
              'partners.abs.vendors.partnersProperties.searchPlaceholder',
              'Name of property'
            )}
          />
        </Box>
      </Box>
      {isLoading ? (
        <div>
          <Loader size={30} />
        </div>
      ) : (
        <>
          <Box
            display={isMobile ? 'flex' : 'grid'}
            width={1}
            flexDirection="column"
            gridTemplateColumns="auto auto auto auto"
          >
            {filteredVenues.map((item: VenueItem) => (
              <PartnerItem
                onSelectPartner={updateCurrentVenue}
                item={item}
                key={item.slug}
                query={query}
                color={
                  currentVenue?.id === item.id
                    ? '#2F54EB'
                    : currentVenue?.id && !query
                    ? '#9396A3'
                    : '#003366'
                }
              />
            ))}
            {difference(venues, filteredVenues).map((item: VenueItem) => (
              <PartnerItem
                onSelectPartner={updateCurrentVenue}
                item={item}
                key={item.slug}
                query={query}
                color={
                  currentVenue?.id === item.id
                    ? '#2F54EB'
                    : !filteredVenues.length && !query
                    ? '#003366'
                    : '#9396A3'
                }
              />
            ))}
          </Box>
          <ActionButtonsContainer isMobile={Boolean(isMobile)}>
            <Box
              height="100%"
              className="container"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box
                justifyContent={!isMobile ? 'flex-end' : 'space-between'}
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={isMobile ? 1 : '0 0 auto'}
              >
                <Typography
                  variant="body1"
                  fontSize="18px"
                  fontWeight={600}
                  pr={isMobile ? '16px' : '44px'}
                  width={isMobile ? '50%' : 'unset'}
                >
                  {currentVenue?.name}
                </Typography>
                <GradientButton
                  onClick={handleContinue}
                  style={{ width: isMobile ? '50%' : '250px' }}
                >
                  {t('common.buttons.continue', 'Continue')}
                </GradientButton>
              </Box>
            </Box>
          </ActionButtonsContainer>
        </>
      )}
    </ModalWrapper>
  )
}
