/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  FormLabelProps as MuiFormLabelProps,
  FormLabel as MuiFormLabel,
} from '@material-ui/core'
import { ForwardedRef, forwardRef } from 'react'
import { centerX } from 'utils/styles/layout'
import { spaceX } from 'utils/styles/space'

const styles = css`
  ${centerX}
  ${spaceX('8px')}
  color: #000;
`

export type FormLabelProps = MuiFormLabelProps

export const FormLabel = forwardRef(function FormLabel(
  props: FormLabelProps,
  ref: FormLabelProps['ref']
) {
  return <MuiFormLabel {...props} css={styles} ref={ref} />
})
