import { Box, Typography } from '@material-ui/core'
import React, { ChangeEvent, useEffect, useState } from 'react'

import { Status } from '../../../types/general'
import { validateEmail } from '../../../utils/helpers/validations'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { SimpleButton } from '../../ui/Buttons/SimpleButton'
import { TextInput } from '../../ui/FormComponents/TextInput'
import { Loader } from '../../ui/Loader'
import { InputsWrapper } from '../Auth.styles'

const ForgotPassword: React.FC<{ onComplete?: () => void }> = ({
  onComplete = () => {},
}) => {
  const { forgotPasswordRequest, isLoading, status, error } = useAuthData()
  const [phone, setPhone] = useState('')
  const replacedPhoneNumber = phone.replace(/\D+/g, '')

  const [email, setEmail] = useState('')
  const isEmailValid = validateEmail(email)
  const showEmailError = Boolean(email && !isEmailValid)

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value)
  }

  useEffect(() => {
    if (status === Status.SUCCESS) {
      onComplete()
    }
  }, [status])

  const handleForgotPasswordSubmit = () => {
    forgotPasswordRequest(email)
  }

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={5}>
        <Typography variant="h3">Forgot password</Typography>
      </Box>
      <Box display="flex" mb={4} flexDirection="column">
        <Typography textAlign="left" variant="body2">
          To reset password enter your email
        </Typography>
      </Box>
      <Box display="flex" mb={5}>
        <InputsWrapper>
          <TextInput
            error={showEmailError}
            name="email"
            placeholder="alex@gmail.com"
            value={email}
            onChange={setEmail}
          />
          <Box display="flex" justifyContent="center" mt={3}>
            <Typography color="#F52222" variant="body2">
              {error}
            </Typography>
          </Box>
        </InputsWrapper>
      </Box>

      <SimpleButton
        disabled={!isEmailValid}
        onClick={handleForgotPasswordSubmit}
        sx={{ mb: 1 }}
        variant="contained"
      >
        Continue
      </SimpleButton>
      {isLoading && <Loader position="absolute" />}
    </Box>
  )
}

export default ForgotPassword
