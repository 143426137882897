const User = ({ color = '#9396A3' }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7671 12.9141C14.3987 12.0414 13.864 11.2486 13.1929 10.5801C12.5238 9.90959 11.7313 9.37501 10.8589 9.00586C10.8511 9.00195 10.8433 9 10.8355 8.99609C12.0523 8.11719 12.8433 6.68555 12.8433 5.07031C12.8433 2.39453 10.6753 0.226562 7.99956 0.226562C5.32378 0.226562 3.15581 2.39453 3.15581 5.07031C3.15581 6.68555 3.94683 8.11719 5.16362 8.99805C5.15581 9.00195 5.148 9.00391 5.14019 9.00781C4.26519 9.37695 3.48003 9.90625 2.8062 10.582C2.13571 11.2511 1.60113 12.0437 1.23198 12.916C0.86933 13.77 0.673744 14.6856 0.655811 15.6133C0.655289 15.6341 0.658946 15.6549 0.666564 15.6743C0.674183 15.6937 0.685609 15.7114 0.700171 15.7263C0.714732 15.7412 0.732134 15.7531 0.75135 15.7612C0.770566 15.7693 0.791209 15.7734 0.812061 15.7734H1.98394C2.06987 15.7734 2.13823 15.7051 2.14019 15.6211C2.17925 14.1133 2.78472 12.7012 3.85503 11.6309C4.96245 10.5234 6.43316 9.91406 7.99956 9.91406C9.56597 9.91406 11.0367 10.5234 12.1441 11.6309C13.2144 12.7012 13.8199 14.1133 13.8589 15.6211C13.8609 15.707 13.9292 15.7734 14.0152 15.7734H15.1871C15.2079 15.7734 15.2286 15.7693 15.2478 15.7612C15.267 15.7531 15.2844 15.7412 15.299 15.7263C15.3135 15.7114 15.3249 15.6937 15.3326 15.6743C15.3402 15.6549 15.3438 15.6341 15.3433 15.6133C15.3238 14.6797 15.1304 13.7715 14.7671 12.9141ZM7.99956 8.42969C7.10308 8.42969 6.25933 8.08008 5.62456 7.44531C4.9898 6.81055 4.64019 5.9668 4.64019 5.07031C4.64019 4.17383 4.9898 3.33008 5.62456 2.69531C6.25933 2.06055 7.10308 1.71094 7.99956 1.71094C8.89605 1.71094 9.7398 2.06055 10.3746 2.69531C11.0093 3.33008 11.3589 4.17383 11.3589 5.07031C11.3589 5.9668 11.0093 6.81055 10.3746 7.44531C9.7398 8.08008 8.89605 8.42969 7.99956 8.42969Z"
        fill={color}
      />
    </svg>
  )
}

export default User
