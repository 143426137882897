const Idea = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4573 21.3904H12.6244C12.3949 21.39 12.175 21.2987 12.0128 21.1363C11.8506 20.974 11.7594 20.754 11.7592 20.5245L11.5989 18.33C10.7185 17.8778 9.97938 17.1921 9.46256 16.348C8.94574 15.5039 8.6711 14.5339 8.6687 13.5441C8.6687 10.5817 11.0788 8.1709 14.0419 8.1709C17.005 8.1709 19.4151 10.581 19.4151 13.5441C19.4123 14.5338 19.1375 15.5037 18.6207 16.3477C18.104 17.1918 17.3651 17.8775 16.4849 18.33L16.3232 20.5497C16.3225 21.0019 15.9347 21.3904 15.4573 21.3904ZM14.0412 8.8709C12.8022 8.87238 11.6145 9.36521 10.7384 10.2413C9.86231 11.1174 9.36948 12.3051 9.368 13.5441C9.37015 14.4358 9.62637 15.3084 10.1067 16.0597C10.5869 16.811 11.2714 17.4098 12.0798 17.7861L12.2667 17.8729L12.4578 20.4993C12.4585 20.6162 12.5327 20.6904 12.6244 20.6904H15.4573C15.5483 20.6904 15.6225 20.6162 15.6225 20.5245L15.8143 17.8729L16.0019 17.7868C16.8102 17.4102 17.4945 16.8112 17.9748 16.0598C18.4551 15.3084 18.7113 14.4358 18.7137 13.5441C18.7122 12.3053 18.2195 11.1176 17.3436 10.2415C16.4676 9.36548 15.28 8.87257 14.0412 8.8709Z"
        fill="black"
      />
      <path
        d="M15.5352 23.7257H12.5469C12.3176 23.7253 12.0978 23.634 11.9357 23.4718C11.7737 23.3096 11.6826 23.0898 11.6824 22.8605V22.0359C11.6824 21.5585 12.0702 21.1714 12.5469 21.1714H15.5352C16.0126 21.1714 16.4004 21.5592 16.4004 22.0359V22.8605C16.4002 23.0899 16.309 23.3099 16.1468 23.4721C15.9845 23.6343 15.7646 23.7255 15.5352 23.7257ZM12.5476 21.8707C12.5039 21.8707 12.4621 21.888 12.4313 21.9189C12.4004 21.9497 12.3831 21.9916 12.3831 22.0352V22.8598C12.3831 22.9508 12.4566 23.025 12.5476 23.025H15.5359C15.6269 23.025 15.7011 22.9508 15.7011 22.8598V22.0359C15.7009 21.9922 15.6834 21.9504 15.6524 21.9195C15.6215 21.8887 15.5796 21.8714 15.5359 21.8714H12.5476V21.8707Z"
        fill="black"
      />
      <path
        d="M13.6914 2.87402H14.3914V7.20212H13.6914V2.87402Z"
        fill="black"
      />
      <path
        d="M21.6832 6.42236L22.1781 6.91726L19.1177 9.97696L18.6228 9.48206L21.6832 6.42236Z"
        fill="black"
      />
      <path
        d="M20.6394 13.7573H24.9675V14.4573H20.6394V13.7573Z"
        fill="black"
      />
      <path
        d="M6.31961 6.4209L9.37931 9.4806L8.88441 9.9755L5.82471 6.9165L6.31961 6.4209Z"
        fill="black"
      />
      <path
        d="M3.03247 13.7573H7.36127V14.4573H3.03247V13.7573Z"
        fill="black"
      />
    </svg>
  )
}

export default Idea
