import * as React from 'react'
import { generatePath, Redirect, useRouteMatch } from 'react-router-dom'

import { Loader } from '../../../components/ui/Loader'
import { useVendor } from '../../../utils/api/vendors'
import { vendorsMapper } from '../../../utils/mappers/vendors'
import { vendorPath } from '../../../utils/paths'
import { NotAvailablePage } from '../../NotAvailablePage'

import { VendorDetails } from './Vendor'

export const VendorContainer: React.FC = () => {
  const {
    params: { slug },
  } = useRouteMatch<{ slug: string }>()

  const { isLoading, data } = useVendor(slug)

  if (isLoading) return <Loader />

  if (data) {
    if (data.slug !== slug) {
      // console.log(data.slug, 'redirect log')
      return <Redirect to={generatePath(vendorPath, { slug: data.slug })} />
    }

    return <VendorDetails vendorInput={vendorsMapper(data)} />
  }

  return <NotAvailablePage />
}
