import { TableBody } from '@material-ui/core'
import React, { useMemo } from 'react'

import { InquiryInvoiceBundleStatuses } from '../../../../../../constants/inquiry'
import { useInvoicesByStatuses } from '../../../../../../hooks/useInvoicesByStatuses'
import { InquiryInvoiceResponse } from '../../../../../../types/dtos/inquiry'
import { StyledTable } from '../../InquiryTables.styles'

import { Footer } from './Footer'
import { Header } from './Header'
import { InvoiceRow } from './InvoiceRow'

export interface BundleInvoicesTableProps {
  invoicesBundle: InquiryInvoiceResponse
  onEdit: (invoiceBundleId: number) => void
  onSend: (invoiceBundleId: number) => void
}

export const InvoicesBundleTable: React.FC<BundleInvoicesTableProps> = ({
  invoicesBundle,
  onEdit,
  onSend,
}) => {
  const total = useMemo(() => {
    return invoicesBundle.invoices.reduce((sum: number, invoice) => {
      return sum + invoice.price
    }, 0)
  }, [invoicesBundle.invoices])

  const { paidInvoices } = useInvoicesByStatuses(invoicesBundle.invoices)

  return (
    <StyledTable sx={{ marginTop: '15px' }}>
      <Header invoicesBundle={invoicesBundle} total={total} />
      <TableBody>
        {invoicesBundle.invoices.map((invoice, index) => (
          <InvoiceRow
            key={invoice.id}
            number={index + 1}
            invoicesBundle={invoicesBundle}
            invoice={invoice}
          />
        ))}
      </TableBody>
      <Footer
        invoicesBundle={invoicesBundle}
        isPossibleEdit={
          invoicesBundle.status !==
            InquiryInvoiceBundleStatuses.STATUS_OUTDATED &&
          !paidInvoices?.length
        }
        onEdit={onEdit}
        onSend={onSend}
      />
    </StyledTable>
  )
}
